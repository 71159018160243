import { ApiActionPayload, MessagingActionPayload, SchedulingActionPayload } from '@sigmail/app-state';
import { AppException, Constants, EventMessageStatus, ReadonlyMessageBodyEvent, SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  CalendarEventRecord,
  DataObjectCalendarEvent,
  DataObjectCalendarEventValue,
  DataObjectMsgBody,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  IDataObject,
  IUserObject,
  MessageFolderListItem,
  MessageTimestampRecord,
  UserObjectSchedule,
  UserObjectScheduleValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { isBefore } from 'date-fns';
import _ from 'lodash';
import { AppThunk } from '../..';
import { CIRCLE_OF_CARE } from '../../../constants/medical-institute-user-group-type-identifier';
import { EventFlags } from '../../../utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { calendarEventToMetadata } from '../../../utils/calendar-event-to-metadata';
import { dateToUtcValues } from '../../../utils/date-to-utc-values';
import { EMPTY_ARRAY } from '../../constants';
import { addEventToDismissedList, queueReminderNotification } from '../../reminder-notification-slice';
import * as DataObjectSelectors from '../../selectors/data-object';
import * as GroupObjectSelectors from '../../selectors/group-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { AUTH_STATE_RESPOND_TO_CALENDAR_EVENT } from '../constants/auth-state-identifier';
import { sendMessageAction } from '../messaging/send-message-action';
import { updateMessageFolderAction } from '../messaging/update-msg-folder-action';
import { cancelNotificationAction } from '../notifications/cancel-notification-action';

const EVENT_MESSAGE_STATUS_ACCEPTED: Extract<EventMessageStatus, 'accepted'> = 'accepted';
const EVENT_MESSAGE_STATUS_DECLINED: Extract<EventMessageStatus, 'declined'> = 'declined';

type Payload = SchedulingActionPayload.RespondToCalendarEvent;

interface State extends AuthenticatedActionState {
  batchUpdateClaims: Array<string>;
  dtServer: Date;
  msgMetadata: DataObjectMsgMetadataValue;
  msgBody: ReadonlyMessageBodyEvent;
  calendarEventObject: IDataObject<DataObjectCalendarEventValue>;
  requestBody: BatchUpdateRequestBuilder;
  requestBodyMsgFolderUpdate: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
  reminderIdListToCancel: ReadonlyArray<SigmailObjectId>;
}

class RespondToCalendarEventAction extends AuthenticatedAction<Payload, State> {
  protected async preExecute() {
    const result = await super.preExecute();

    const { folderKey, parentFolderKey, msgMetadataId, msgBodyId, response } = this.payload;

    if (
      Utils.isNotNil(folderKey) &&
      folderKey !== Constants.MessageFolderKey.Inbox &&
      parentFolderKey !== Constants.MessageFolderKey.Inbox
    ) {
      throw new AppException(
        Constants.Error.S_ERROR,
        `Operation is not supported for this folder. (folderKey=${folderKey}, parentFolderKey=${String(parentFolderKey)})`
      );
    }

    if (!DataObjectMsgMetadata.isValidId(msgMetadataId)) {
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Message metadata ID is either missing or invalid.');
    }

    if (!DataObjectMsgBody.isValidId(msgBodyId)) {
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Message body ID is either missing or invalid.');
    }

    if (response !== EVENT_MESSAGE_STATUS_ACCEPTED && response !== EVENT_MESSAGE_STATUS_DECLINED) {
      throw new AppException(
        Constants.Error.S_ERROR,
        `<response> must be one of: ${EVENT_MESSAGE_STATUS_ACCEPTED}, ${EVENT_MESSAGE_STATUS_DECLINED}`
      );
    }

    return result;
  }

  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      this.state.batchUpdateClaims = [];

      try {
        const { msgMetadataId, msgBodyId } = this.payload;

        const msgMetadata = await this.getDataObjectValue<DataObjectMsgMetadataValue>(msgMetadataId);
        const msgBody = (await this.getDataObjectValue<DataObjectMsgBodyValue>(msgBodyId)) as ReadonlyMessageBodyEvent | undefined;
        if (Utils.isNil(msgMetadata) || Utils.isNil(msgBody)) {
          throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Message metadata and/or body is either missing or invalid.');
        }

        const { eventObjectId } = msgBody.messageForm.value;
        const { claims } = await this.dispatchFetchObjects({
          authState: this.state.roleAuthClaim,
          dataObjects: { ids: [eventObjectId] },
          expectedCount: { claims: 1 }
        });

        const dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
        const calendarEventObject = dataObjectByIdSelector<DataObjectCalendarEventValue>(eventObjectId);
        if (Utils.isNil(calendarEventObject)) {
          throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Calendar event object is either missing or invalid.');
        }

        if (Utils.isNonEmptyArray(claims)) {
          this.state.batchUpdateClaims.push(...claims);
        }

        this.state.dtServer = await this.dispatchFetchServerDateAndTime();
        this.state.msgMetadata = msgMetadata;
        this.state.msgBody = msgBody;
        this.state.calendarEventObject = calendarEventObject;

        await this.generateRequestBody();

        const { roleAuthClaim: authState, requestBody, successPayload } = this.state;

        const mutations = requestBody.build();
        const msgFolderMutations = this.state.requestBodyMsgFolderUpdate.build();
        if (Utils.isNonEmptyArray(msgFolderMutations.dataObjects)) {
          (mutations.dataObjects || (mutations.dataObjects = [])).push(...msgFolderMutations.dataObjects);
        }

        const request: Api.EnterStateRequestData = {
          authState,
          state: AUTH_STATE_RESPOND_TO_CALENDAR_EVENT
        };

        const { authState: batchUpdateAuthState } = await this.dispatchEnterState(request);
        let batchUpdateClaims: Api.BatchUpdateRequestData['claims'] = undefined;
        if (Utils.isNonEmptyArray<string>(this.state.batchUpdateClaims)) {
          batchUpdateClaims = this.state.batchUpdateClaims;
        }
        await this.dispatchBatchUpdateData({ authState: batchUpdateAuthState, claims: batchUpdateClaims, ...mutations });

        try {
          await this.dispatchBatchQueryDataSuccess(successPayload);
        } catch (error) {
          this.logger.warn('Error manually updating app state:', error);
          /* ignore */
        }

        if (Utils.isNonEmptyArray<SigmailObjectId>(this.state.reminderIdListToCancel)) {
          try {
            const ids = this.state.reminderIdListToCancel as Array<SigmailObjectId>;
            await this.dispatch(cancelNotificationAction({ ids }));
          } catch (error) {
            this.logger.warn('Error cancelling reminder(s):', error);
            /* ignore */
          }
        }

        try {
          await this.sendNotificationMessage();
        } catch (error) {
          this.logger.warn('Error sending notification:', error);
          /* ignore */
        }

        if (this.payload.response === 'accepted') {
          const calendarValue = await calendarEventObject.decryptedValue();

          if (!calendarValue.allDay && isBefore(this.state.dtServer.getTime(), calendarValue.end)) {
            this.dispatch(
              queueReminderNotification([
                {
                  eventObjectId: msgBody.messageForm.value.eventObjectId,
                  start: calendarValue.start,
                  title: calendarValue.title
                }
              ])
            );
          }
        }

        if (this.payload.response === 'declined') {
          this.dispatch(addEventToDismissedList([msgBody.messageForm.value.eventObjectId]));
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();
    this.state.requestBodyMsgFolderUpdate = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };

    await this.addUpdateOperationForCalendarEvent(); // 310
    await this.addUpdateOperationForUserSchedule(); // 412

    const { folderKey, parentFolderKey } = this.payload;
    const { requestBodyMsgFolderUpdate, successPayload } = this.state;

    if (Utils.isNotNil(folderKey)) {
      await this.dispatch(
        updateMessageFolderAction({
          folderKey,
          parentFolderKey,
          requestBody: requestBodyMsgFolderUpdate,
          successPayload,
          applyUpdate: this.applyMessageFolderUpdate.bind(this)
        })
      );
    } else {
      await this.dispatch(
        updateMessageFolderAction({
          folderKey: Constants.MessageFolderKey.Inbox,
          requestBody: requestBodyMsgFolderUpdate,
          successPayload,
          applyUpdate: this.applyMessageFolderUpdate.bind(this)
        })
      );

      // look in ARCHIVED folder only if message wasn't found in INBOX
      if (!Utils.isNonEmptyArray(requestBodyMsgFolderUpdate.build().dataObjects)) {
        await this.dispatch(
          updateMessageFolderAction({
            folderKey: Constants.MessageSubFolderKey.Archived,
            parentFolderKey: Constants.MessageFolderKey.Inbox,
            requestBody: requestBodyMsgFolderUpdate,
            successPayload,
            applyUpdate: this.applyMessageFolderUpdate.bind(this)
          })
        );
      }
    }
  }

  private async addUpdateOperationForCalendarEvent(): Promise<void> {
    this.logger.info('Adding an update operation to request body for calendar event object.');

    const { response, videoConsent: consent } = this.payload;
    const { currentUser, calendarEventObject, requestBody, successPayload } = this.state;

    const dtServer = this.state.dtServer.getTime();

    const applyUpdate = async (
      calendarEventObject: IDataObject<DataObjectCalendarEventValue>
    ): Promise<IDataObject<DataObjectCalendarEventValue>> => {
      const calendarEvent = await calendarEventObject.decryptedValue();
      const { hasResponded, isVideoMeeting } = EventFlags(calendarEvent);

      const attendeeList = calendarEvent.extendedProps.attendeeList.slice();
      const index = attendeeList.findIndex(({ id: attendeeId }) => attendeeId === currentUser.id);
      if (index !== -1) {
        const attendee = attendeeList[index];

        if (!hasResponded(attendee)) {
          const timestamp = { ...attendee.timestamp };
          let { reminderList } = attendee;

          if (response === 'accepted') {
            timestamp.acceptedAt = dtServer;

            if (isVideoMeeting && consent === true) {
              timestamp.videoConsentAt = dtServer;
            }
          } else if (response === 'declined') {
            if (Utils.isNonEmptyArray<NonNullable<typeof reminderList>>(reminderList)) {
              this.state.reminderIdListToCancel = reminderList.map(({ id }) => id);
              reminderList = EMPTY_ARRAY;
            }
            timestamp.declinedAt = dtServer;
          }

          attendeeList[index] = { ...attendee, timestamp, reminderList };
        }
      }

      const updatedValue: DataObjectCalendarEventValue = {
        ...calendarEvent,
        extendedProps: { ...calendarEvent.extendedProps, attendeeList }
      };

      const updatedObject = await calendarEventObject.updateValue(updatedValue);
      return updatedObject;
    };

    const calendarEventObjectKey = calendarEventObject[Constants.$$CryptographicKey];
    const dataUpdater: Api.DataUpdater<IDataObject<any>> = async (calendarEventJson, { dataObjects }) => {
      let index = dataObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === calendarEventJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; calendar event could not be found in request body.');
      }

      const key = calendarEventObjectKey === null ? null : calendarEventObjectKey.toApiFormatted();
      const calendarEventObject = new DataObjectCalendarEvent({ ...calendarEventJson, key });
      const updatedObject = await applyUpdate(calendarEventObject);
      dataObjects![index].data = updatedObject;

      index = successPayload.response.dataObjects!.findIndex(({ id }) => id === calendarEventJson.id);
      if (index !== -1) successPayload.response.dataObjects![index] = updatedObject.toApiFormatted();
    };

    const updatedObject = await applyUpdate(calendarEventObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.dataObjects!.ids.push(updatedObject.id);
    successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForUserSchedule(): Promise<void> {
    this.logger.info('Adding an update operation to request body for user schedule object.');

    const { roleAuthClaim: authState, currentUser, calendarEventObject, requestBody, successPayload } = this.state;
    const { eventObjectId } = this.state.msgBody.messageForm.value;

    const { claims } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [{ userId: currentUser.id, type: process.env.USER_OBJECT_TYPE_SCHEDULE }],
      expectedCount: { claims: 1 }
    });

    const scheduleObject = UserObjectSelectors.scheduleObjectSelector(this.getRootState())(/***/);
    if (Utils.isNil(scheduleObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Schedule object is either missing or invalid.');
    }

    if (Utils.isNonEmptyArray(claims)) {
      this.state.batchUpdateClaims.push(...claims);
    }

    const applyUpdate = async (scheduleObject: IUserObject<UserObjectScheduleValue>): Promise<IUserObject<UserObjectScheduleValue>> => {
      const calendarEvent = await calendarEventObject.decryptedValue();
      const scheduleValue = await scheduleObject.decryptedValue();

      const [utcYear, utcMonth, utcDate] = dateToUtcValues(calendarEvent.allDay ? calendarEvent.date : calendarEvent.start);
      const updatedEventRecord = { ...(scheduleValue.events as CalendarEventRecord) };
      updatedEventRecord[utcYear] = { ...updatedEventRecord[utcYear] };
      updatedEventRecord[utcYear]![utcMonth] = { ...updatedEventRecord[utcYear]![utcMonth] };
      const dayEventList = Utils.arrayOrDefault(updatedEventRecord[utcYear]![utcMonth]![utcDate], EMPTY_ARRAY).filter(
        ({ eventObjectId: objectId }) => objectId !== eventObjectId
      );
      updatedEventRecord[utcYear]![utcMonth]![utcDate] = dayEventList;

      if (this.payload.response === EVENT_MESSAGE_STATUS_ACCEPTED) {
        dayEventList.push({ ...calendarEventToMetadata(calendarEvent), eventObjectId, accepted: true });
      }

      const updatedValue: UserObjectScheduleValue = { ...scheduleValue, events: updatedEventRecord };
      const updatedObject = await scheduleObject.updateValue(updatedValue);
      return updatedObject;
    };

    const scheduleObjectKey = scheduleObject[Constants.$$CryptographicKey];
    const dataUpdater: Api.DataUpdater<IUserObject<any>> = async (scheduleJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === scheduleJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; schedule object could not be found in request body.');
      }

      const key = scheduleObjectKey === null ? null : scheduleObjectKey.toApiFormatted();
      const scheduleObject = new UserObjectSchedule({ ...scheduleJson, key });
      const updatedObject = await applyUpdate(scheduleObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === scheduleJson.id);
      if (index !== -1) successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
    };

    const updatedObject = await applyUpdate(scheduleObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(updatedObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private applyMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    _UNUSED_itemCount: any,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { msgMetadataId, msgBodyId, response } = this.payload;
    const { dtServer } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    this.logger.info(
      `Locating item with message metadata ID <${msgMetadataId}> and message body ID <${msgBodyId}> in ${folderOrExt} <${meta.folderOrExtId}>.`
    );

    const index = folderData.findIndex(({ header, body }) => header === msgMetadataId && body === msgBodyId);
    if (index === -1) return result;

    const message = folderData[index];
    let timestamp: MessageTimestampRecord;
    if (Utils.isInteger(message.timestamp)) {
      timestamp = { createdAt: message.timestamp };
    } else {
      timestamp = { ...message.timestamp };
    }

    timestamp[response === EVENT_MESSAGE_STATUS_ACCEPTED ? 'acceptedAt' : 'declinedAt'] = dtServer.getTime();

    folderData[index] = { ...message, timestamp };

    result.updated = true;
    result.done = true;

    return result;
  }

  private async sendNotificationMessage(): Promise<void> {
    this.logger.info('Sending response status notification to organizer');

    const { response } = this.payload;
    const { calendarEventObject, currentUser, msgBody, msgMetadata } = this.state;

    const calendarEvent = await calendarEventObject.decryptedValue();

    const replyTo = msgMetadata.replyTo?.filter((contact) => contact.type === 'group');
    if (Utils.isNil(replyTo) || replyTo.length === 0) return;

    const [{ id: groupId }] = replyTo;

    const groupContactInfo = await this.getUserObjectValue(GroupObjectSelectors.contactInfoObjectSelector, {
      type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO,
      userId: groupId
    });
    if (Utils.isNil(groupContactInfo)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, "Failed to fetch reply to group's contact info object.");
    }

    const { value: eventValue } = msgBody.messageForm;
    const userPersonName = UserObjectSelectors.personNameSelector(this.getRootState())(/***/);

    this.dispatch(
      sendMessageAction({
        flags: {
          doNotReply: true
        },
        messageBody: {
          messageForm: {
            name: Constants.MessageFormName.Event,
            value: {
              eventObjectId: eventValue.eventObjectId,
              response: {
                status: response,
                userId: currentUser.id
              }
            }
          }
        },
        primaryRecipientList: [
          {
            id: groupId,
            type: 'group',
            groupType: CIRCLE_OF_CARE,
            groupData: Utils.pick(groupContactInfo, ['groupName'])
          }
        ],
        sender: {
          id: currentUser.id,
          type: currentUser.type,
          ...Utils.pick(userPersonName, Constants.PERSON_NAME_KEY_LIST)
        },
        secondaryRecipientList: EMPTY_ARRAY,
        subjectLine: `[${_.capitalize(this.payload.response)}] ${calendarEvent.title} (${Utils.joinPersonName(userPersonName)})`
      })
    ).catch(Utils.noop);
  }
}

export const respondToCalendarEventAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'respondToCalendarEventAction:');

    const action = new RespondToCalendarEventAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
