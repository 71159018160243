import { AccountActionPayload } from '@sigmail/app-state';
import { Constants } from '@sigmail/common';
import React from 'react';
import { sendAccountInvitationAction } from '../../../app-state/actions/account/send-account-invitation-action';
import { useAppState } from '../../../app-state/hooks';
import { ActionData } from '../types';

export type ActionDataSendCaregiverAccountInvitation = ActionData &
  Omit<
    Exclude<
      AccountActionPayload.SendAccountInvitation,
      AccountActionPayload.SendGuestAccountInvitation | AccountActionPayload.SendNonGuestAccountInvitation
    >,
    'role'
  >;

const ROLE_ID_CAREGIVER = Constants.ROLE_ID_CAREGIVER as AccountActionPayload.SendCaregiverAccountInvitation['role'];

export const useSendCaregiverAccountInvitationActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({ failCallback, successCallback, ...actionPayload }: ActionDataSendCaregiverAccountInvitation) => {
      let value: any;

      try {
        value = await appDispatch(sendAccountInvitationAction({ ...actionPayload, role: ROLE_ID_CAREGIVER }));
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
