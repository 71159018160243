import { Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import { selectFullName } from '.';
import { EMPTY_PLAIN_OBJECT } from '../../app-state/constants';

export const selectJoinedFullName = (...args: Parameters<typeof selectFullName>): string => {
  const fullName = selectFullName(...args);
  const { title: prefix, ...name } = (Utils.isNonArrayObjectLike(fullName)
    ? fullName
    : EMPTY_PLAIN_OBJECT) as Api.AccuroEMRPersonNameDTO;
  return Utils.joinPersonName({ ...name, prefix });
};
