import { ApiActionPayload } from '@sigmail/app-state';
import { SigmailAuditId, SigmailClientId, SigmailKeyId, SigmailObjectId, SigmailUserId, Utils } from '@sigmail/common';
import { Algorithm, getAlgorithm } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ClientObjectContactList,
  ClientObjectContactListValue,
  ClientObjectProfile,
  ClientObjectProfileValue,
  ClientObjectUserList,
  ClientObjectUserListValue,
  CryptographicKey,
  CryptographicKeyEncapsulated,
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  GroupObjectProfileBasic,
  GroupObjectServerRights,
  IUserObject
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, BaseActionState, FetchObjectsRequestData } from '../base-action';
import { AUTH_STATE_MULTI_GROUP_RELEASE_MIGRATION } from '../constants/auth-state-identifier';

interface Payload {
  accessToken: string;
  auditId: SigmailAuditId;
  clientId: SigmailClientId;
  globalContactListId: SigmailObjectId;
  roleAuthClaim: string;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
  userId: SigmailUserId;
}

interface State extends BaseActionState {
  clientProfileObject: IUserObject<ClientObjectProfileValue>;
  clientProfile: ClientObjectProfileValue;
  clientContactList: ClientObjectContactListValue;
  userList: ReadonlyArray<ClientObjectUserListValue['active'][0] | ClientObjectUserListValue['inactive'][0]>;
  publicKeyListToClear: Array<SigmailKeyId>;
  requestBody: BatchUpdateRequestBuilder;
  batchUpdateAuthState: string;
  batchUpdateClaims: Array<string>;
}

class MigrationMultiGroupRelease extends BaseAction<Payload, State> {
  private readonly publicKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC);

  /** @override */
  protected async onExecute(): Promise<void> {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchRequiredClientObjects();

        const { $$formatver } = this.state.clientProfile as ClientObjectProfileValue<any>;
        if ($$formatver > 2) {
          this.logger.info(`Call ignored; migration is not required. ($$formatver = ${$$formatver})`);
          return;
        }

        const { accessToken, roleAuthClaim, successPayload } = this.payload;

        this.state.batchUpdateAuthState = (
          await this.enterState(accessToken, {
            authState: roleAuthClaim,
            state: AUTH_STATE_MULTI_GROUP_RELEASE_MIGRATION
          })
        ).authState;

        try {
          this.state.publicKeyListToClear = [];

          await this.fetchPublicKeysForActiveInactiveUserList();
          await this.generateRequestBody();

          const { batchUpdateAuthState: authState, batchUpdateClaims: claims, requestBody, clientProfileObject } = this.state;
          await this.batchUpdateData(accessToken, { authState, claims, ...requestBody.build() });

          // manually patch successPayload's data with updated objects
          do {
            const clientObjectList = [clientProfileObject];
            const { userObjectsByType } = successPayload.response;
            for (const obj of clientObjectList) {
              const index = this.findUserObjectIndex(userObjectsByType!, { type: obj.type, id: obj.id });
              if (index > -1) userObjectsByType![index] = obj.toApiFormatted();
            }
          } while (false);

          break;
        } finally {
          const { publicKeyListToClear } = this.state;
          if (Utils.isNonEmptyArray<typeof publicKeyListToClear[0]>(publicKeyListToClear)) {
            publicKeyListToClear.forEach((keyId) => CryptographicKey.clearPublicKey(keyId));
            publicKeyListToClear.length = 0;
          }
        }
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchRequiredClientObjects(): Promise<void> {
    this.logger.info('Fetching the latest client profile, client contact list, and client user list.');

    const { accessToken, clientId: userId, roleAuthClaim: authState } = this.payload;

    const { userObjectList: clientObjectList } = await this.fetchObjects(accessToken, {
      authState,
      userObjectsByType: [
        { type: process.env.CLIENT_OBJECT_TYPE_PROFILE, userId: userId },
        { type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST, userId: userId },
        { type: process.env.CLIENT_OBJECT_TYPE_USER_LIST, userId: userId }
      ]
    });

    // this.state.dtServer = this.deserializeServerDateTime(serverDateTime);

    const profileObject = this.findAndCreateUserObject(clientObjectList, ClientObjectProfile, { userId });
    const contactListObject = this.findAndCreateUserObject(clientObjectList, ClientObjectContactList, { userId });
    const userListObject = this.findAndCreateUserObject(clientObjectList, ClientObjectUserList, { userId });
    if (Utils.isNil(profileObject)) {
      throw new Api.MalformedResponseException('No matching client profile object could be found.');
    } else if (Utils.isNil(contactListObject)) {
      throw new Api.MalformedResponseException('No matching client contact list object could be found.');
    } else if (Utils.isNil(userListObject)) {
      throw new Api.MalformedResponseException('No matching client user list object could be found.');
    }

    this.state.clientProfileObject = profileObject;
    this.state.clientProfile = await this.state.clientProfileObject.decryptedValue();
    this.state.clientContactList = await contactListObject.decryptedValue();
    const userList = await userListObject.decryptedValue();
    this.state.userList = userList.active.concat(userList.inactive);
  }

  private async fetchPublicKeysForActiveInactiveUserList(): Promise<void> {
    this.logger.info('Fetching public keys of all active and inactive contacts in client user list.');

    const { accessToken, userId: currentUserId } = this.payload;
    const { batchUpdateAuthState: authState, userList } = this.state;

    const { keyList } = await this.fetchObjects(accessToken, {
      authState,
      keysByType: userList.map(({ id }) => ({ type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id }))
    });

    for (const keyJson of keyList) {
      if (keyJson.id === currentUserId) continue;

      const userPublicKey = new CryptographicKeyPublic(keyJson);
      await CryptographicKey.cache(userPublicKey);

      this.state.publicKeyListToClear.push(userPublicKey.id);
    }
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    await this.encryptContactInfoObjectsForGlobalContactList(); // 151s (for 405s)
    await this.encryptGroupKeysWithMemberIds(); // 151s (for 101s)
    // await this.addInsertOperationsForEncounterObject(); // 470s (and their 151s)
    await this.addUpdateOperationForClientProfile(); // 460
  }

  private async encryptContactInfoObjectsForGlobalContactList(): Promise<void> {
    this.logger.info(
      'Adding an insert operation to request body to encrypt user contact info object of each active and inactive user using the global contact list key.'
    );

    const { accessToken, globalContactListId } = this.payload;
    const { batchUpdateAuthState: authState, userList, requestBody } = this.state;

    const { userObjectList } = await this.fetchObjects(accessToken, {
      authState,
      userObjectsByType: Utils.filterMap(userList, ({ id: userId, role: roleId }) => {
        return Utils.isPhysicianRole(roleId) && { type: process.env.USER_OBJECT_TYPE_CONTACT_INFO, userId };
      })
    });

    const keyList = await Promise.all(
      userObjectList.map((json) =>
        Algorithm.isValidNonEncryptedObjectCode(json.code)
          ? null
          : new CryptographicKeyEncapsulated(json.key!).encryptFor(globalContactListId)
      )
    );

    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async encryptGroupKeysWithMemberIds(): Promise<void> {
    this.logger.info("Adding an insert operation to request body for encryption of groups' private key with member IDs.");

    const { accessToken } = this.payload;
    const { batchUpdateAuthState: authState, clientContactList, userList, requestBody } = this.state;

    const groupIdList = Utils.filterMap(clientContactList.list, ({ type, id: groupId }) => type === 'group' && groupId);

    let query: FetchObjectsRequestData = {
      authState: this.payload.roleAuthClaim,
      userObjectsByType: groupIdList.map((groupId) => ({ type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS, userId: groupId }))
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const claims = await Promise.all(
      userObjectList.map((serverRightsJson) =>
        new GroupObjectServerRights(serverRightsJson).decryptedValue().then(({ groupClaim }) => groupClaim)
      )
    );

    query = {
      authState,
      claims,
      ...groupIdList.reduce<Omit<FetchObjectsRequestData, 'authState'>>(
        (request, groupId) => {
          request.keysByType!.push({ type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: groupId });
          request.userObjectsByType!.push({ type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC, userId: groupId });
          return request;
        },
        { keysByType: [], userObjectsByType: [] }
      )
    };

    const { keyList: groupKeyList, userObjectList: groupObjectList } = await this.fetchObjects(accessToken, query);
    if (groupKeyList.length !== groupIdList.length) {
      throw new Api.MalformedResponseException('Private key of one or more groups could not be fetched.');
    } else if (groupObjectList.length !== groupIdList.length) {
      throw new Api.MalformedResponseException('Profile object of one or more groups could not be fetched.');
    }

    for (const groupId of groupIdList) {
      const groupProfileObject = this.findAndCreateUserObject(groupObjectList, GroupObjectProfileBasic, { userId: groupId });
      if (Utils.isNil(groupProfileObject)) {
        throw new Api.MalformedResponseException(`Group profile object could not be fetched. <groupId=${groupId}>`);
      }

      const { memberList } = await groupProfileObject.decryptedValue();
      const memberIdList = Utils.filterMap(memberList, ({ id: memberId }) => userList.some(({ id }) => id === memberId) && memberId);

      const keyJson = this.findKey(groupKeyList, { id: groupId })!;
      const groupKeyPrivate = new CryptographicKeyPrivate(keyJson);
      const keyList = await Promise.all(memberIdList.map((memberId) => groupKeyPrivate.encryptFor(memberId)));
      requestBody.insert(keyList);
    }
  }

  // private async addInsertOperationsForEncounterObject(): Promise<void> {
  //   this.logger.info('Adding an insert encounter object operation each to request body for every active and inactive user.');

  //   const { accessToken, roleAuthClaim, auditId, clientId } = this.payload;
  //   const { batchUpdateAuthState: authState, clientContactList, userList, dtServer, requestBody } = this.state;

  //   let query: FetchObjectsRequestData;

  //   //
  //   //#region build a list of all client groups
  //   const clientGroupIdList = clientContactList.list.reduce((list, { type, id: groupId }) => {
  //     if (type === 'group') list.push(groupId);
  //     return list;
  //   }, [] as Array<SigmailGroupId>);
  //   // #endregion
  //   //

  //   //
  //   //#region fetch guest list object of every client group
  //   query = {
  //     authState,
  //     userObjectsByType: clientGroupIdList.map((groupId) => ({ type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST, userId: groupId }))
  //   };

  //   const { userObjectList: groupObjectList } = await this.fetchObjects(accessToken, query);
  //   if (groupObjectList.length !== clientGroupIdList.length) {
  //     throw new Api.MalformedResponseException('One or more group guest list objects could not be fetched.');
  //   }
  //   // #endregion
  //   //

  //   //
  //   //#region build a map of each group ID to it's guest user list
  //   const groupGuestLists = await Promise.all(groupObjectList.map((json) => new GroupObjectGuestList(json).decryptedValue()));
  //   const groupGuestListMap = new Map<SigmailGroupId, ReadonlyArray<SigmailUserId>>(
  //     groupGuestLists.map(({ list }, index) => [
  //       groupObjectList[index].id,
  //       list.reduce((guestUserIdList, contact) => {
  //         if (contact.type === 'user' && !Utils.isString(contact.userData.memberType) && userList.some(({ id }) => id === contact.id)) {
  //           guestUserIdList.push(contact.id);
  //         }
  //         return guestUserIdList;
  //       }, [] as Array<SigmailUserId>)
  //     ])
  //   );
  //   // #endregion
  //   //

  //   //
  //   //#region build a list containing IDs of all non-guest users
  //   const nonGuestUserIdList = userList.reduce((list, { id: userId, role: roleId }) => {
  //     if (Utils.isNonGuestRole(roleId)) list.push(userId);
  //     return list;
  //   }, [] as Array<SigmailUserId>);
  //   // #endregion
  //   //

  //   //
  //   //#region make sure no existing encounter objects already exist
  //   query = {
  //     authState,
  //     userObjectsByType: userList.map(({ id: userId }) => ({ type: process.env.USER_OBJECT_TYPE_ENCOUNTER, userId })),
  //     expectedCount: { userObjectsByType: 0 }
  //   };

  //   const { userObjectList } = await this.fetchObjects(accessToken, query);
  //   if (userObjectList.length > 0) {
  //     throw new AppException(
  //       Constants.Error.S_ERROR,
  //       `One or more existing encounter objects were found.\nUser IDs: ${userObjectList.map(({ userId }) => userId).join(', ')}`
  //     );
  //   }
  //   // #endregion
  //   //

  //   //
  //   //#region get a list of new IDs from API server and create encounter objects
  //   const { authState: batchUpdateAuthState, claims: batchUpdateClaims, ids: idRecord } = await this.fetchIdsByUsage(accessToken, {
  //     authState: roleAuthClaim,
  //     state: AUTH_STATE_CREATE_ENCOUNTER_OBJECT_MIGRATION,
  //     ids: {
  //       ids: [{ type: process.env.USER_OBJECT_TYPE_ENCOUNTER, count: userList.length }]
  //     }
  //   });

  //   this.state.batchUpdateAuthState = batchUpdateAuthState;
  //   this.state.batchUpdateClaims = batchUpdateClaims.slice();

  //   const value: UserObjectEncounterValue = { $$formatver: 1, $encounterIndex: {} };
  //   const idSequence = Utils.makeSequence(idRecord[process.env.USER_OBJECT_TYPE_ENCOUNTER]);

  //   //
  //   // create an encounter object each for all users whose role is not GUEST
  //   //
  //   for (const userId of nonGuestUserIdList) {
  //     const { value: id } = idSequence.next();
  //     const encounterObject = await UserObjectEncounter.create(id, undefined, 1, value, userId, userId, dtServer);
  //     const keyList = await encounterObject.generateKeysEncryptedFor(auditId, clientId);
  //     keyList.push(encounterObject[Constants.$$CryptographicKey]);
  //     requestBody.insert(encounterObject);
  //     requestBody.insert(keyList.filter(Utils.isNotNil));
  //   }

  //   //
  //   // create an encounter object each for users of role GUEST
  //   //
  //   // NOTE: only difference here is that the object created for a guest user is
  //   // also encrypted for group ID
  //   //
  //   for (const [groupId, guestUserIdList] of groupGuestListMap.entries()) {
  //     for (const userId of guestUserIdList) {
  //       const { value: id } = idSequence.next();
  //       const encounterObject = await UserObjectEncounter.create(id, undefined, 1, value, userId, userId, dtServer);
  //       const keyList = await encounterObject.generateKeysEncryptedFor(auditId, clientId, groupId);
  //       keyList.push(encounterObject[Constants.$$CryptographicKey]);
  //       requestBody.insert(encounterObject);
  //       requestBody.insert(keyList.filter(Utils.isNotNil));
  //     }
  //   }
  //   // #endregion
  //   //
  // }

  private async addUpdateOperationForClientProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for client profile object.');

    const { clientProfile, clientProfileObject, requestBody } = this.state;

    const updatedValue: typeof clientProfile = { ...clientProfile, $$formatver: 3 };
    const updatedObject = await clientProfileObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.clientProfileObject = updatedObject;
  }
}

export const migration_multiGroupRelease = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'multiGroupRelease:');

    const migration = new MigrationMultiGroupRelease({ payload, dispatch, getState, apiService, logger: Logger });
    return migration.execute();
  };
};
