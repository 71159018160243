import { ActionPayloadSignIn, SignInSuccessAuthOnly } from '@sigmail/app-state';
import { CancelablePromise, Utils } from '@sigmail/common';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { signInAction } from '../auth-slice/sign-in-action';
import { useAppState } from './use-app-state';
import { useInterval } from './use-interval';

export interface CredentialsAuthenticationResource extends ISuspenseResource<SignInSuccessAuthOnly | undefined> {}

export interface UseCredentialsAuthenticationResourceParams
  extends Pick<ActionPayloadSignIn, 'credentialHash' | 'password' | 'username'> {
  /**
   * Interval, in milliseconds, before resource value is refreshed.
   * Default value is `null` i.e. never.
   */
  refreshInterval?: unknown | null | undefined;
}

const MAX_VALUE_REFRESH_INTERVAL_MS = 86400000;

export const useCredentialsAuthenticationResource = (params?: UseCredentialsAuthenticationResourceParams) => {
  const { credentialHash, password, refreshInterval: interval, username } = Utils.defaults(
    {},
    params as unknown,
    {} as UseCredentialsAuthenticationResourceParams
  );

  const [, appDispatch] = useAppState();
  const [resource, setResource] = React.useState<CredentialsAuthenticationResource>(UNRESOLVED_RESOURCE);
  const [firstRunComplete, setFirstRunComplete] = React.useState(false);

  const authPromise = React.useRef<CancelablePromise<SignInSuccessAuthOnly | undefined>>();
  const authenticateCredentials = React.useCallback(() => {
    authPromise.current?.cancel();
    setFirstRunComplete(true);

    const wrappedPromise = Utils.makeCancelablePromise(
      appDispatch(signInAction({ credentialHash, password, username, authOnly: true })) as Promise<
        SignInSuccessAuthOnly | undefined
      >
    );
    authPromise.current = wrappedPromise;

    wrappedPromise.promise.then(
      (response) => {
        authPromise.current = undefined;
        setResource({ value: () => response });
      },
      (error) => {
        if (Utils.isNonArrayObjectLike<{ isCanceled: boolean }>(error) && error.isCanceled === true) return;

        authPromise.current = undefined;
        setResource({ value: () => undefined });
      }
    );
  }, [appDispatch, credentialHash, password, username]);

  let delay: number | null = null;
  let refreshInterval = Utils.isInteger(interval) ? interval : null;
  if (refreshInterval !== null) {
    delay = Math.max(0, Math.min(refreshInterval, MAX_VALUE_REFRESH_INTERVAL_MS));
  }

  const isParamsValid = Utils.isNotNil(params);
  useInterval(authenticateCredentials, firstRunComplete && isParamsValid ? delay : null);
  if (!firstRunComplete && isParamsValid) authenticateCredentials();

  return resource;
};
