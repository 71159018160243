import { MultiFactorAuthI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MultiFactorAuthI18n.Dialog.MFADisabled = {
  // Translators: (HTML) Title for MFA Disabled dialog
  title: gettext('mfa', 'mfaDisabledDialog', 'title', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of MFA Disabled \
  // dialog informing user that their MFA was successfully disabled.
  body: gettext('mfa', 'mfaDisabledDialog', 'body', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Dismiss Dialog action
      label: gettext('mfa', 'mfaDisabledDialog', 'actionLabelDismiss')
    }
  }
};

export default i18n;
