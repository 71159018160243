/**
 * @type {{
 *  BreakpointKeys: Array<import("@material-ui/core/styles/createBreakpoints").Breakpoint>;
 *  BreakpointValues: Array<number>
 * }}
 */
module.exports = {
  BreakpointKeys: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'],
  BreakpointValues: [0, 360, 600, 960, 1280, 1920]
};
