import { CircularProgress as MuiCircularProgress, CircularProgressProps, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexFlow: 'row nowrap',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export interface Props extends Omit<CircularProgressProps, 'ref' | 'classes'> {}

interface ComponentProps extends Props, WithStyles<typeof styles> {}

function CircularProgressComponent({ classes, style, ...props }: ComponentProps) {
  return (
    <div className={classes.root} style={style}>
      <MuiCircularProgress {...props} />
    </div>
  );
}

export const CircularProgress = withStyles(styles)(CircularProgressComponent);
CircularProgress.displayName = 'CircularProgress';
