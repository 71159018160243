import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: ContactListI18n.Form.ContactListGridFilter = {
  fieldsetDefault: {
    formField: {
      search: {
        // Translators: Label for the Search filter input
        label: gettext('contact-list', 'search', 'contactListGridFilterFormInputLabel'),
        // Translators: Placeholder for the Search filter input
        placeholder: gettext('contact-list', 'search', 'contactListGridFilterFormInputHint')
      },
      institutionName: {
        // Translators: Label for the Institution Name filter input
        label: gettext('contact-list', 'institutionName', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any Institution Name drop-down option
        placeholder: gettext('contact-list', 'anyInstitutionName', 'contactListGridFilterFormOptionLabel')
      },
      group: {
        // Translators: Label for the Group Name filter input
        label: gettext('contact-list', 'group', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any Group drop-down option
        placeholder: gettext('contact-list', 'anyGroup', 'contactListGridFilterFormOptionLabel')
      },
      license: {
        // Translators: Label for the License filter input
        label: gettext('contact-list', 'license', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any License drop-down option
        placeholder: gettext('contact-list', 'anyLicense', 'contactListGridFilterFormOptionLabel')
      },
      role: {
        // Translators: Label for the Role filter input
        label: gettext('contact-list', 'role', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any Role drop-down option
        placeholder: gettext('contact-list', 'anyRole', 'contactListGridFilterFormOptionLabel')
      },
      specialty: {
        // Translators: Label for the Specialty filter input
        label: gettext('contact-list', 'specialty', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any Specialty drop-down option
        placeholder: gettext('contact-list', 'anySpecialty', 'contactListGridFilterFormOptionLabel')
      },
      accountStatus: {
        // Translators: Label for the Account Status filter input
        label: gettext('contact-list', 'accountStatus', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any Account Status Status drop-down option
        placeholder: gettext('contact-list', 'anyAccountStatus', 'contactListGridFilterFormOptionLabel')
      },
      addressLevel2: {
        // Translators: Label for the City filter input
        label: gettext('contact-list', 'city', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any City Status Status drop-down option
        placeholder: gettext('contact-list', 'anyCity', 'contactListGridFilterFormOptionLabel')
      }
    }
  },
  fieldsetGenderHPJ: {
    label: gettext('contact-list', 'genderHPJ', 'contactListGridFilterFormFieldsetLabel'),
    formField: {
      gender: {
        // Translators: Label for the Gender input
        label: gettext('contact-list', 'gender', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any Gender Status Status drop-down option
        placeholder: gettext('contact-list', 'anyGender', 'contactListGridFilterFormOptionLabel')
      },
      hpj: {
        // Translators: Label for the Health Plan Jurisdiction input
        label: gettext('contact-list', 'hpj', 'contactListGridFilterFormInputLabel'),
        // Translators: Label for the Any Health Plan Jurisdiction drop-down \
        // option
        placeholder: gettext('contact-list', 'anyHPJ', 'contactListGridFilterFormOptionLabel'),
      }
    }
  },
  fieldsetAge: {
    // Translators: Label for the Age Range fieldset
    label: gettext('contact-list', 'age', 'contactListGridFilterFormFieldsetLabel'),
    formField: {
      ageFrom: {
        // Translators: Label for the Minimum Age filter input
        label: gettext('contact-list', 'minAge', 'contactListGridFilterFormInputLabel'),
        error: {
          // Translators: Error message displayed when Minimum Age is not \
          // within the valid range
          range: gettext('contact-list', 'minAgeRange', 'contactListGridFilterFormError')
        }
      },
      ageTo: {
        // Translators: Label for the Maximum Age filter input
        label: gettext('contact-list', 'maxAge', 'contactListGridFilterFormInputLabel'),
        error: {
          // Translators: Error message displayed when Maximum Age is not \
          // within the valid range
          range: gettext('contact-list', 'maxAgeRange', 'contactListGridFilterFormError')
        }
      }
    }
  },
  fieldsetBirthDate: {
    // Translators: Label for the Birth Date Range fieldset
    label: gettext('contact-list', 'birthDate', 'contactListGridFilterFormFieldsetLabel'),
    formField: {
      birthDateFrom: {
        // Translators: Label for the Minimum Birth Date filter input
        label: gettext('contact-list', 'minBirthDate', 'contactListGridFilterFormInputLabel'),
        error: {
          // Translators: Error message displayed when Minimum Birth \
          // Date is not within the valid range
          range: gettext('contact-list', 'minBirthDateRange', 'contactListGridFilterFormError'),
          // Translators: Error message displayed when Minimum Birth \
          // Date is not a valid date (for e.x. 2021-02-30)
          badInput: gettext('contact-list', 'minBirthDateBadInput', 'contactListGridFilterFormError')
        }
      },
      birthDateTo: {
        // Translators: Label for the Maximum Birth Date filter input
        label: gettext('contact-list', 'maxBirthDate', 'contactListGridFilterFormInputLabel'),
        error: {
          // Translators: Error message displayed when Maximum Birth \
          // Date is not within the valid range
          range: gettext('contact-list', 'maxBirthDateRange', 'contactListGridFilterFormError'),
          // Translators: Error message displayed when Maximum Birth \
          // Date is not a valid date (for e.x. 2021-02-30)
          badInput: gettext('contact-list', 'maxBirthDateBadInput', 'contactListGridFilterFormError')
        }
      }
    }
  },
  fieldsetRegDate: {
    // Translators: Label for the Registration Date Range fieldset
    label: gettext('contact-list', 'regDate', 'contactListGridFilterFormFieldsetLabel'),
    formField: {
      regDateFrom: {
        // Translators: Label for the Minimum Registration Date filter input
        label: gettext('contact-list', 'minRegDate', 'contactListGridFilterFormInputLabel'),
        error: {
          // Translators: Error message displayed when Minimum Registration \
          // Date is not within the valid range
          range: gettext('contact-list', 'minRegDateRange', 'contactListGridFilterFormError'),
          // Translators: Error message displayed when Minimum Registration \
          // Date is not a valid date (for e.x. 2021-02-30)
          badInput: gettext('contact-list', 'minRegDateBadInput', 'contactListGridFilterFormError')
        }
      },
      regDateTo: {
        // Translators: Label for the Maximum Registration Date filter input
        label: gettext('contact-list', 'maxRegDate', 'contactListGridFilterFormInputLabel'),
        error: {
          // Translators: Error message displayed when Maximum Registration \
          // Date is not within the valid range
          range: gettext('contact-list', 'maxRegDateRange', 'contactListGridFilterFormError'),
          // Translators: Error message displayed when Maximum Registration \
          // Date is not a valid date (for e.x. 2021-02-30)
          badInput: gettext('contact-list', 'maxRegDateBadInput', 'contactListGridFilterFormError')
        }
      }
    }
  }
};

export default i18n;
