import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { GroupObject } from '.';
import type { GroupObjectProfileBasicValue as DV } from '../types';

const TYPE = process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC;

/** @public */
export class GroupObjectProfileBasic extends GroupObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isString(dv.name) && Utils.isArray(dv.memberList);
  }

  public updateValue(newValue: DV): Promise<GroupObjectProfileBasic> {
    const Class = this.constructor as typeof GroupObjectProfileBasic;
    return Class.updateValue(this, newValue);
  }
}
