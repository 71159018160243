import { Utils } from '@sigmail/common';
import {
  CarePlan,
  CarePlanArrayListSectionItem,
  CarePlanSectionIdAllergyList,
  CarePlanSectionIdCareTeam,
  CarePlanSectionIdGoalList,
  CarePlanSectionIdHealthAssessmentList,
  CarePlanSectionIdHealthIssueList,
  CarePlanSectionIdMedicationList
} from '@sigmail/objects';
import { FormValues } from '../../app/shared/form/edit-care-plan/types';
import { fromCarePlanDate } from './from-care-plan-date';
import { getCarePlanSectionLastUpdate } from './get-care-plan-section-last-update';

type CarePlanAllergy = CarePlanArrayListSectionItem<CarePlanSectionIdAllergyList>;
type CarePlanGoal = CarePlanArrayListSectionItem<CarePlanSectionIdGoalList>;
type CarePlanHealthAssessment = CarePlanArrayListSectionItem<CarePlanSectionIdHealthAssessmentList>;
type CarePlanHealthIssue = CarePlanArrayListSectionItem<CarePlanSectionIdHealthIssueList>;
type CarePlanMedication = CarePlanArrayListSectionItem<CarePlanSectionIdMedicationList>;
type CareTeamMember = CarePlanArrayListSectionItem<CarePlanSectionIdCareTeam>;

function toAllergyList(list: ReadonlyArray<CarePlanAllergy>): FormValues['allergyList'] {
  return list.map<FormValues['allergyList'][0]>((allergy) => ({
    deleted: allergy.deleted,
    allergyIdentifier: Utils.stringOrDefault(allergy.identifier),
    allergySymptoms: Utils.stringOrDefault(allergy.symptoms)
  }));
}

function toCareTeam(list: ReadonlyArray<CareTeamMember>): FormValues['careTeam'] {
  return list.map<FormValues['careTeam'][0]>((member) => ({
    deleted: member.deleted,
    memberContact: Utils.stringOrDefault(member.contact),
    memberFirstName: Utils.stringOrDefault(member.firstName),
    memberLastName: Utils.stringOrDefault(member.lastName),
    memberOrganization: Utils.stringOrDefault(member.organization),
    memberRole: Utils.stringOrDefault(member.role)
  }));
}

export function toCarePlanFormValues(plan: CarePlan): Partial<FormValues> {
  const initialValues: Partial<FormValues> = {};

  initialValues.planDescription = Utils.stringOrDefault(plan.description);
  initialValues.planTitle = Utils.stringOrDefault(plan.title);

  if (Utils.isNonEmptyArray(plan.sectionList)) {
    for (const section of plan.sectionList) {
      const [, lastChange] = getCarePlanSectionLastUpdate(plan, section.sectionId);
      if (lastChange?.op === 'remove') continue;

      if (section.sectionId === 'allergyList') {
        initialValues.allergyList = toAllergyList(section.list);
      } else if (section.sectionId === 'careTeam') {
        initialValues.careTeam = toCareTeam(section.list);
      } else if (section.sectionId === 'goalList') {
        initialValues.goalList = toGoalList(section.list);
      } else if (section.sectionId === 'healthAssessmentList') {
        initialValues.healthAssessmentList = toHealthAssessmentList(section.list);
      } else if (section.sectionId === 'healthIssueList') {
        initialValues.healthIssueList = toHealthIssueList(section.list);
      } else if (section.sectionId === 'medicationCoordination') {
        initialValues.medAids = Utils.stringOrDefault(section.aids);
        initialValues.medChallenges = Utils.stringOrDefault(section.challenges);
        initialValues.medHelper = Utils.stringOrDefault(section.helper);
        initialValues.medSource = Utils.stringOrDefault(section.source);
      } else if (section.sectionId === 'medicationList') {
        initialValues.medicationList = toMedicationList(section.list);
      } else if (section.sectionId === 'personalInfo') {
        initialValues.aboutCaregiverIssues = Utils.stringOrDefault(section.caregiverIssues);
        initialValues.aboutEmployment = Utils.stringOrDefault(section.employment);
        initialValues.aboutFoodSecurity = Utils.stringOrDefault(section.foodSecurity);
        initialValues.aboutHealthKnowledge = Utils.stringOrDefault(section.healthKnowledge);
        initialValues.aboutHousing = Utils.stringOrDefault(section.housing);
        initialValues.aboutIncome = Utils.stringOrDefault(section.income);
        initialValues.aboutLegalConcerns = Utils.stringOrDefault(section.legalConcerns);
        initialValues.aboutNewInCountry = Utils.stringOrDefault(section.newInCountry);
        initialValues.aboutOther = Utils.stringOrDefault(section.other);
        initialValues.aboutSocialNetwork = Utils.stringOrDefault(section.socialNetwork);
        initialValues.aboutSpiritualAffiliation = Utils.stringOrDefault(section.spiritualAffiliation);
        initialValues.aboutTransportation = Utils.stringOrDefault(section.transportation);
      } else if (section.sectionId === 'recentHospitalVisit') {
        initialValues.visitComments = Utils.stringOrDefault(section.comments);
        initialValues.visitDate = fromCarePlanDate(section.date);
        initialValues.visitDescription = Utils.stringOrDefault(section.description);
        initialValues.visitDischarge = fromCarePlanDate(section.discharge);
        initialValues.visitDuration = Utils.stringOrDefault(section.duration);
        initialValues.visitHospitalName = Utils.stringOrDefault(section.hospitalName);
        initialValues.visitReason = Utils.stringOrDefault(section.reason);
      } else if (section.sectionId === 'subject') {
        initialValues.addressLevel1 = Utils.stringOrDefault<FormValues['addressLevel1']>(section.addressLevel1);
        initialValues.addressLevel2 = Utils.stringOrDefault(section.addressLevel2);
        initialValues.addressLine1 = Utils.stringOrDefault(section.addressLine1);
        initialValues.addressLine2 = Utils.stringOrDefault(section.addressLine2);
        initialValues.ancestry = Utils.stringOrDefault(section.ancestry);
        initialValues.birthDate = fromCarePlanDate(section.birthDate);
        initialValues.communication = Utils.stringOrDefault(section.communication);
        initialValues.firstName = Utils.stringOrDefault(section.firstName);
        initialValues.gender = Utils.stringOrDefault<FormValues['gender']>(section.gender);
        initialValues.healthPlanJurisdiction = Utils.stringOrDefault<FormValues['healthPlanJurisdiction']>(
          section.healthPlanJurisdiction
        );
        initialValues.healthPlanNumber = Utils.stringOrDefault(section.healthPlanNumber);
        initialValues.indigenous = Utils.stringOrDefault(section.indigenous);
        initialValues.language = Utils.stringOrDefault(section.language);
        initialValues.lastName = Utils.stringOrDefault(section.lastName);
        initialValues.postalCode = Utils.stringOrDefault(section.postalCode);
        initialValues.preferredName = Utils.stringOrDefault(section.preferredName);
        initialValues.preferredPronoun = Utils.stringOrDefault(section.preferredPronoun);
      } else if (section.sectionId === 'supportingInfo') {
        initialValues.healthConcern = Utils.stringOrDefault(section.healthConcern);
        initialValues.priority = Utils.stringOrDefault(section.priority);
      }
    }
  }

  return initialValues;
}

function toGoalList(list: ReadonlyArray<CarePlanGoal>): FormValues['goalList'] {
  return list.map<FormValues['goalList'][0]>((goal) => ({
    deleted: goal.deleted,
    goalActionPlan: Utils.stringOrDefault(goal.actionPlan),
    goalCoordinator: Utils.stringOrDefault(goal.coordinator),
    goalDate: fromCarePlanDate(goal.date),
    goalDetails: Utils.stringOrDefault(goal.details),
    goalIdentifier: Utils.stringOrDefault(goal.identifier)
  }));
}

function toHealthAssessmentList(list: ReadonlyArray<CarePlanHealthAssessment>): FormValues['healthAssessmentList'] {
  return list.map<FormValues['healthAssessmentList'][0]>((assessment) => ({
    deleted: assessment.deleted,
    healthAssessmentDate: fromCarePlanDate(assessment.date),
    healthAssessmentIdentifier: Utils.stringOrDefault(assessment.identifier),
    healthAssessmentNotes: Utils.stringOrDefault(assessment.notes)
  }));
}

function toHealthIssueList(list: ReadonlyArray<CarePlanHealthIssue>): FormValues['healthIssueList'] {
  return list.map<FormValues['healthIssueList'][0]>((issue) => ({
    deleted: issue.deleted,
    healthIssueDetails: Utils.stringOrDefault(issue.details),
    healthIssueIdentifier: Utils.stringOrDefault(issue.identifier)
  }));
}

function toMedicationList(list: ReadonlyArray<CarePlanMedication>): FormValues['medicationList'] {
  return list.map<FormValues['medicationList'][0]>((drug) => ({
    deleted: drug.deleted,
    drugDose: Utils.stringOrDefault(drug.dose),
    drugFrequency: Utils.stringOrDefault(drug.frequency),
    drugName: Utils.stringOrDefault(drug.name),
    drugNotes: Utils.stringOrDefault(drug.notes),
    drugPrescriber: Utils.stringOrDefault(drug.prescriber),
    drugReason: Utils.stringOrDefault(drug.reason),
    drugStart: fromCarePlanDate(drug.start)
  }));
}
