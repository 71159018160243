import { MessagingI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import { DialogConfirmDeleteMessage } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.Confirmation.DeleteMessage = {
  // Translators: (HTML) Title for the Confirm Delete Message dialog
  title: gettext('messaging', 'title', 'dialogConfirmDeleteMessage', 'safe-html'),
  // Translators: (HTML) Confirmation message to be displayed in the body of \
  // Confirm Delete Message dialog
  body: ngettext('messaging', 'body', 'dialogConfirmDeleteMessage', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Confirm Delete Message \
      // dialog
      label: gettext('messaging', 'reject', 'dialogConfirmDeleteMessageActionLabel'),
      context: DialogConfirmDeleteMessage
    },
    accept: {
      // Translators: Label for the Proceed action of Confirm Delete Message \
      // dialog
      label: gettext('messaging', 'accept', 'dialogConfirmDeleteMessageActionLabel'),
      context: DialogConfirmDeleteMessage
    }
  }
};

export default i18n;
