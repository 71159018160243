import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.Form.VitalsHealthDataParameters = {
  fieldsetDateRange: {
    formField: {
      from: {
        // Translators: Label for the From Date input in Vitals Health Data \
        // Parameters form
        label: gettext('health-data', 'from', 'vitalsHealthDataParametersFormInputLabel'),
        error: {
          // Translators: Error displayed when no date is selected for From \
          // Date input in Vitals Health Data Parameters form
          valueMissing: gettext('health-data', 'fromRequired', 'vitalsHealthDataParametersFormError'),
          // Translators: Error displayed when an invalid date is entered for \
          // From Date input in Vitals Health Data Parameters form
          badInput: gettext('health-data', 'fromInvalid', 'vitalsHealthDataParametersFormError')
        }
      },
      to: {
        // Translators: Label for the To Date input in Vitals Health Data \
        // Parameters form
        label: gettext('health-data', 'to', 'vitalsHealthDataParametersFormInputLabel'),
        error: {
          // Translators: Error displayed when no date is selected for To Date \
          // input in Vitals Health Data Parameters form
          valueMissing: gettext('health-data', 'toRequired', 'vitalsHealthDataParametersFormError'),
          // Translators: Error displayed when an invalid date is entered for \
          // To Date input in Vitals Health Data Parameters form
          badInput: gettext('health-data', 'toInvalid', 'vitalsHealthDataParametersFormError')
        }
      }
    }
  }
};

export default i18n;
