import { GroupsI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';

const i18n: GroupsI18n.Dialog.AssignUsersToGroup = {
  // Translators: (HTML) Title for the Assign Users To Group dialog
  title: gettext('groups', 'title', 'assignUsersToGroupDialog', 'safe-html'),
  // Translators: (HTML) Body for the Assign Users To Group dialog
  body: ngettext('groups', 'body', 'assignUsersToGroupDialog', 'safe-html'),
  note: {
    // Translators: (HTML) A note explaining the Assign Patients To Group
    // functionality
    guest: ngettext('groups', 'noteGuest', 'assignUsersToGroupDialog', 'safe-html'),
    // Translators: (HTML) A note explaining the Assign Physician/Staff Members
    // To Group functionality
    nonGuest: ngettext('groups', 'noteNonGuest', 'assignUsersToGroupDialog', 'safe-html')
  },
  action: {
    reject: {
      // Translators: Label for the Cancel action of Assign Users To Group \
      // dialog
      label: gettext('groups', 'reject', 'assignUsersToGroupDialogActionLabel')
    },
    accept: {
      // Translators: Label for the Submit action of Assign Users To Group \
      // dialog
      label: gettext('groups', 'accept', 'assignUsersToGroupDialogActionLabel')
    },
    dismiss: {
      // Translators: Label for the Close action of Assign Users To Group \
      // dialog
      label: gettext('groups', 'dismiss', 'assignUsersToGroupDialogActionLabel')
    }
  }
};

export default i18n;
