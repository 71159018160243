import { Utils } from '@sigmail/common';

export const SUNDAY = 1 << 0; // 1
export const MONDAY = 1 << 1; // 2
export const TUESDAY = 1 << 2; // 4
export const WEDNESDAY = 1 << 3; // 8
export const THURSDAY = 1 << 4; // 16
export const FRIDAY = 1 << 5; // 32
export const SATURDAY = 1 << 6; // 64

export const ALL = SATURDAY * 2 - 1;

export const LIST = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];

export function isValid(flag?: any): flag is number {
  return Utils.isInteger(flag) && flag >= 1 && flag <= 127;
}
