export function readBlobAsDataUri(blob: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('error', () => reject(reader.error));
    reader.addEventListener('load', () => reader.readyState === FileReader.DONE && resolve(reader.result as string));
    reader.readAsDataURL(blob);
  });
}

/** @deprecated - use {@link readBlobAsDataUri} instead. */
export function readFileAsDataUri(file: File): Promise<string> {
  return readBlobAsDataUri(file);
}
