import { Constants, ReadonlyMessageBodyEConsult, Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { sendMessageAction } from '../../../../app-state/actions/messaging/send-message-action';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../../../../app-state/constants';
import { useAppState, useCurrentUser } from '../../../../app-state/hooks';
import { profileObjectSelector as clientProfileObjectSelector } from '../../../../app-state/selectors/client-object';
import { protectedProfileObjectSelector as userProtectedProfileObjectSelector } from '../../../../app-state/selectors/user-object';
import { UserObjectCache } from '../../../../app-state/user-objects-slice/cache';
import { UseAttachedDocumentListStateResult } from '../../hooks';
import { ActionData } from '../../types';
import { FormValues } from '../../types/consultation-request';

export interface ActionDataSendConsultationRequest
  extends ActionData,
    Omit<FormValues, 'documentList' | 'officeNumberExt' | 'otherSpecialties' | 'searchByHpn' | 'specialty'>,
    Pick<UseAttachedDocumentListStateResult, 'documentList'> {}

type ReadonlyMessageBodyConsultationRequestValue = ReadonlyMessageBodyEConsult['messageForm']['value'];

export const useSendConsultationRequestActionHandler = (currentUser: ReturnType<typeof useCurrentUser>) => {
  const [, appDispatch] = useAppState();

  const clientProfileObject = useSelector(clientProfileObjectSelector)(/***/);
  const instituteName = UserObjectCache.getValue(clientProfileObject)?.name!;

  const protectedProfileObject = useSelector(userProtectedProfileObjectSelector)(/***/);
  const protectedProfile = UserObjectCache.getValue(protectedProfileObject);
  const ohipBillingNumber = protectedProfile?.ohipBillingNumber!;

  const referrer = React.useMemo<ReadonlyMessageBodyConsultationRequestValue['referrer']>(
    () =>
      Utils.isNil(currentUser)
        ? (EMPTY_PLAIN_OBJECT as ReadonlyMessageBodyConsultationRequestValue['referrer'])
        : {
            ...Utils.pick(currentUser, [...Constants.PERSON_NAME_KEY_LIST, ...Constants.POSTAL_ADDRESS_KEY_LIST]),
            cellNumber: currentUser.cellNumber,
            faxNumber: currentUser.faxNumber,
            id: currentUser.id,
            instituteName,
            officeNumber: currentUser.officeNumber,
            ohipBillingNumber,
            type: 'user'
          },
    [currentUser, instituteName, ohipBillingNumber]
  );

  return React.useCallback(
    async ({
      addressType,
      billingNumber,
      birthDate,
      cellNumber,
      consultant,
      consultantAddress,
      consultantAddressLevel1,
      consultantAddressLevel2,
      consultantFirstName,
      consultantLastName,
      consultantPostalCode,
      contactNumber,
      contactType,
      diagnosticCode,
      documentList,
      failCallback,
      faxNumber,
      gender,
      hpj,
      hpn,
      message,
      officeNumber,
      patientAddressLevel1,
      patientAddressLevel2,
      patientAddressLine1,
      patientAddressLine2,
      patientFirstName,
      patientLastName,
      patientPostalCode,
      reason: consultationReason,
      serviceCode,
      successCallback
    }: ActionDataSendConsultationRequest) => {
      const patient: ReadonlyMessageBodyConsultationRequestValue['patient'] = {
        addressLevel1: patientAddressLevel1,
        addressLevel2: patientAddressLevel2,
        addressLine1: patientAddressLine1,
        addressLine2: patientAddressLine2,
        addressType,
        birthDate: birthDate.toISOString().slice(0, 10),
        contactNumber,
        contactType,
        firstName: patientFirstName,
        gender,
        healthPlanJurisdiction: hpj,
        healthPlanNumber: hpn,
        lastName: patientLastName,
        postalCode: patientPostalCode
      };

      const messageBody: ReadonlyMessageBodyEConsult = {
        messageForm: {
          name: Constants.MessageFormName.EConsult,
          value: {
            caseId: 0, // this value will be set to a valid ID by sendMessageAction
            consultant: {
              addressLevel1: consultantAddressLevel1,
              addressLevel2: consultantAddressLevel2,
              addressLine1: consultantAddress,
              cellNumber,
              faxNumber,
              firstName: consultantFirstName,
              id: consultant.id,
              lastName: consultantLastName,
              officeNumber,
              ohipBillingNumber: billingNumber,
              postalCode: consultantPostalCode,
              type: 'user'
            },
            consultationReason,
            diagnosticCode,
            message,
            patient,
            referrer,
            serviceCode
          }
        }
      };

      let value: any;

      try {
        value = await appDispatch(
          sendMessageAction({
            sender: {
              type: 'user',
              id: referrer.id,
              prefix: referrer.prefix,
              firstName: referrer.firstName,
              middleName: referrer.middleName,
              lastName: referrer.lastName,
              suffix: referrer.suffix
            },
            subjectLine: `${serviceCode === 'K730' ? 'Phone consult' : 'E-Consult'} for ${Utils.joinPersonName(
              patient
            )}`,
            primaryRecipientList: [consultant],
            secondaryRecipientList: EMPTY_ARRAY,
            documentList,
            messageBody
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch, referrer]
  );
};
