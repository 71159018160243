import { Nullable, ReadonlyPartialRecord, Utils, Writeable } from '@sigmail/common';
import { HealthDataI18n } from '@sigmail/i18n';
import { UserObjectHealthDataValue, VitalsFormData } from '@sigmail/objects';
import { endOfDay } from 'date-fns';
import { TOptions, WithT } from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { SANITIZER_OPTIONS } from '../../../app-state/actions/constants';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import {
  DataFormCssClassPrefix,
  DataFormNameVitals,
  VITALS_FORM_SLIDER_INPUT_STEP
} from '../../../app/health-data/constants';
import { FieldName, FIELD_SET_LIST, FormValues } from '../../../app/health-data/forms/vitals-questionnaire.component';
import { MAX_SAFE_TIMESTAMP, MIN_SAFE_TIMESTAMP } from '../../../constants';
import {
  VitalsFormBPDiastolic,
  VitalsFormBPSystolic,
  VitalsFormHeartRate,
  VitalsFormPulseOximeter
} from '../../../constants/form-input-constraint';
import { EnglishCanada } from '../../../constants/language-codes';
import healthDataI18n from '../../../i18n/health-data';
import { dateToUtcValues } from '../../date-to-utc-values';

const { vitalsQuestionnaire: formI18n } = healthDataI18n.form;
const { dataValueNotSet } = healthDataI18n.dataViewer.vitalsQuestionnaire;

const RE_RANGE_PROCESSOR = /\((\S*)\).*?\[((.|\n)*)\]/;
const rangeMatches = (rangeLabel: string, rangeValue: number): boolean => {
  if (rangeLabel.indexOf('-') > -1) {
    const [from, to] = rangeLabel.split('-').map((value) => (value === 'inf' ? Infinity : parseInt(value, 10)));
    return rangeValue >= from && rangeValue <= to;
  } else {
    const match = parseInt(rangeLabel, 10);
    return match === rangeValue;
  }
};

export type VitalsGridData = VitalsFormData &
  VitalsFormData['bloodPressure'] &
  VitalsFormData['symptoms'] & { timestamp: number };

export class VitalsDataUtil {
  private readonly data: VitalsFormData | undefined;

  public constructor(data?: Nullable<VitalsFormData>) {
    if (Utils.isNil(data)) return;

    const Class = this.constructor as typeof VitalsDataUtil;
    if (!Class.isValid(data)) throw new TypeError('Invalid vitals data.');

    this.data = data;
  }

  public static buildGridData(
    data: UserObjectHealthDataValue,
    params?: ReadonlyPartialRecord<'from' | 'to', Date>
  ): Readonly<[ReadonlyArray<number>, ReadonlyArray<VitalsGridData>]> {
    let from = params?.from?.getTime() as number;
    from = Math.min(
      Math.max(MIN_SAFE_TIMESTAMP, Utils.numberOrDefault(Utils.isInteger(from) && from, MIN_SAFE_TIMESTAMP)),
      MAX_SAFE_TIMESTAMP
    );

    let to = params?.to?.getTime() as number;
    to = endOfDay(
      Math.min(Math.max(from, Utils.numberOrDefault(Utils.isInteger(to) && to, MAX_SAFE_TIMESTAMP)), MAX_SAFE_TIMESTAMP)
    ).getTime();

    const timestampSet = new Set<number>();
    for (const requestId in data.$index) {
      const [indexOfRequest, responseList] = data.$index[+requestId]!;
      if (data.requestList![indexOfRequest].form !== DataFormNameVitals) continue;

      responseList.filter((ts) => ts >= from && ts <= to).forEach((ts) => timestampSet.add(ts));
    }

    const timestampList = Array.from(timestampSet.values());
    const dataList: Array<VitalsGridData> = [];

    if (Utils.isNotNil(data.vitals) && timestampList.length > 0) {
      for (const ts of timestampList) {
        const [utcYear, utcMonth, utcDate] = dateToUtcValues(ts);
        const vitalsData = data.vitals![utcYear]![utcMonth]![utcDate]!;

        if (vitalsData) {
          dataList.push({ ...vitalsData, ...vitalsData.symptoms, ...vitalsData.bloodPressure, timestamp: ts });
        }
      }
      timestampList.sort((a, b) => b - a);
    }

    return [timestampList, dataList];
  }

  public static createDataFromFormValues({
    alcohol,
    diastolic,
    diet,
    dyspnea,
    heartRate,
    heightToggle,
    notes,
    otherActivity,
    otherChangeInWeight,
    otherDiet,
    pulseOximeter,
    swelling,
    systolic,
    weightToggle,
    wellBeing,
    ...values
  }: Partial<FormValues>): VitalsFormData {
    const activity = values.activity === 'other' ? otherActivity : values.activity;
    const bloodPressure = Utils.isNotNil(diastolic) || Utils.isNotNil(systolic) ? { diastolic, systolic } : undefined;
    const changeInWeight = values.changeInWeight === 'other' ? otherChangeInWeight : values.changeInWeight;

    if (Utils.isNotNil(diet) && diet.includes('other')) {
      diet = diet.filter((diet) => diet !== 'other');
      const other = Utils.trimOrDefault(otherDiet);
      if (other.length > 0) diet = [...diet, other];
    }

    let height: VitalsFormData['height'];
    if (Utils.isString(values.height)) {
      const [feet, ...inches] = heightToggle === true ? values.height.match(/\d/g)! : (EMPTY_ARRAY as Array<string>);
      height = { feet: +feet, inches: +inches.join('') || 0 };
    }

    let weight: VitalsFormData['weight'];
    if (Utils.isString(values.weight)) {
      weight = { [weightToggle === true ? 'lbs' : 'kgs']: +values.weight };
    }

    const symptoms =
      Utils.isNotNil(dyspnea) || Utils.isNotNil(swelling) || Utils.isNotNil(wellBeing)
        ? { dyspnea, swelling, wellBeing }
        : undefined;

    const formData: VitalsFormData = {
      activity,
      alcohol,
      bloodPressure,
      changeInWeight,
      diet,
      heartRate,
      height,
      notes,
      pulseOximeter,
      symptoms,
      weight
    };

    return (Object.keys(formData) as Array<keyof typeof formData>).reduce<Writeable<VitalsFormData>>((data, key) => {
      let value = formData[key] as unknown;
      if (Utils.isString(value)) value = value.trim();
      if ((!Utils.isString(value) && !Utils.isArray(value)) || value.length > 0) {
        data[key] = value as undefined;
      }
      return data;
    }, {});
  }

  public static formatDataValue(
    data: VitalsGridData,
    fieldName: FieldName,
    t: WithT['t'],
    language?: string
  ): string | undefined {
    let tOptions: TOptions | undefined;
    if (Utils.isString(language)) tOptions = { language };

    switch (fieldName) {
      case 'activity':
      case 'alcohol':
      case 'changeInWeight':
      case 'dyspnea':
      case 'swelling':
      case 'wellBeing': {
        const [fieldset] = FIELD_SET_LIST.find(([, fieldNameList]) => fieldNameList.includes(fieldName))!;

        const dataValue = data[fieldName] as number | string | undefined;
        if (Utils.isNil(dataValue)) return undefined;

        const options = (formI18n[fieldset] as any).formField[fieldName].options as Array<
          HealthDataI18n.Form.VitalsFormInputOption<number>
        >;

        return Utils.isString(dataValue)
          ? dataValue
          : t(options.find(({ codedValue }) => codedValue === dataValue)!.label, tOptions);
      }
      case 'diastolic':
      case 'heartRate':
      case 'pulseOximeter':
      case 'systolic': {
        const dataValue = data[fieldName] as number | undefined;
        return Utils.isNil(dataValue) ? undefined : this.getDataRangeLabel(fieldName, dataValue, t);
      }
      case 'diet': {
        const dataValue = data[fieldName] as VitalsFormData['diet'];
        if (Utils.isNil(dataValue)) return undefined;

        const { options } = formI18n.fieldsetDiet.formField.diet;
        return `${dataValue
          .map((diet) => {
            return `${
              Utils.isString(diet) ? diet : t(options.find(({ codedValue }) => codedValue === diet)!.label, tOptions)
            }`;
          })
          .join(', ')}`;
      }
      case 'height': {
        const dataValue = data[fieldName] as VitalsFormData['height'];
        if (Utils.isNil(dataValue)) return undefined;

        const { height: fieldI18n } = formI18n.fieldsetHeight.formField;
        const heightUnitCMS = t(fieldI18n.placeholderCMS, tOptions);

        return Utils.isInteger(dataValue) ? `${dataValue} ${heightUnitCMS}` : `${dataValue.feet}' ${dataValue.inches}"`;
      }
      case 'notes': {
        return Utils.isNil(data.notes) ? undefined : sanitizeHtml(Utils.trimOrDefault(data.notes), SANITIZER_OPTIONS);
      }
      case 'weight': {
        const dataValue = data[fieldName] as VitalsFormData['weight'];
        if (Utils.isNil(dataValue)) return undefined;
        if (Object.values(dataValue).every(Utils.isNil)) return undefined;

        const { weight: fieldI18n } = formI18n.fieldsetWeight.formField;
        const weightUnitKGS = t(fieldI18n.placeholderKGS, tOptions);
        const weightUnitLBS = t(fieldI18n.placeholderLBS, tOptions);

        let value: string | undefined;
        if ('kgs' in dataValue) {
          value = `${dataValue.kgs} ${weightUnitKGS}`;
        } else if ('lbs' in dataValue) {
          value = `${dataValue.lbs} ${weightUnitLBS}`;
        }

        return value;
      }
      default:
        return;
    }
  }

  public static getDataRange(
    fieldName: Extract<FieldName, 'diastolic' | 'heartRate' | 'pulseOximeter' | 'systolic'>
  ): Record<'max' | 'min', number> {
    // prettier-ignore
    switch (fieldName) {
      case 'diastolic': return VitalsFormBPDiastolic;
      case 'heartRate': return VitalsFormHeartRate;
      case 'pulseOximeter': return VitalsFormPulseOximeter;
      case 'systolic': return VitalsFormBPSystolic;
      default:
        throw new Error(`Unhandled case - ${String(fieldName)}`);
    }
  }

  public static getDataRangeLabel(
    fieldName: Extract<FieldName, 'diastolic' | 'heartRate' | 'pulseOximeter' | 'systolic'>,
    fieldValue: number,
    t: WithT['t']
  ): string {
    let rangeLabelI18n = '';
    if (fieldName === 'diastolic') {
      rangeLabelI18n = formI18n.fieldsetBloodPressure.formField.diastolic.rangeLabel;
    } else if (fieldName === 'heartRate') {
      rangeLabelI18n = formI18n.fieldsetHeartRate.formField.heartRate.rangeLabel;
    } else if (fieldName === 'pulseOximeter') {
      rangeLabelI18n = formI18n.fieldsetPulseOximeter.formField.pulseOximeter.rangeLabel;
    } else if (fieldName === 'systolic') {
      rangeLabelI18n = formI18n.fieldsetBloodPressure.formField.systolic.rangeLabel;
    }

    let rangeLabel = t(rangeLabelI18n, {
      LBOUND: fieldValue,
      UBOUND: fieldValue + VITALS_FORM_SLIDER_INPUT_STEP
    });

    for (const range of rangeLabel.split(';')) {
      const match = RE_RANGE_PROCESSOR.exec(range);
      if (match !== null && rangeMatches(match[1], fieldValue)) {
        rangeLabel = match[2];
        break;
      }
    }

    return rangeLabel;
  }

  public static isValid(data?: null | undefined | unknown): data is VitalsFormData {
    return (
      Utils.isNonArrayObjectLike<VitalsFormData>(data) &&
      (Utils.isNil(data.activity) || Utils.isInteger(data.activity) || Utils.isString(data.activity)) &&
      (Utils.isNil(data.alcohol) || Utils.isInteger(data.alcohol)) &&
      (Utils.isNil(data.bloodPressure) || Utils.isNonArrayObjectLike(data.bloodPressure)) &&
      (Utils.isNil(data.changeInWeight) ||
        Utils.isInteger(data.changeInWeight) ||
        Utils.isString(data.changeInWeight)) &&
      (Utils.isNil(data.diet) || Utils.isArray(data.diet)) &&
      (Utils.isNil(data.heartRate) || Utils.isInteger(data.heartRate)) &&
      (Utils.isNil(data.height) || Utils.isInteger(data.height) || Utils.isNonArrayObjectLike(data.height)) &&
      (Utils.isNil(data.notes) || Utils.isString(data.notes)) &&
      (Utils.isNil(data.pulseOximeter) || Utils.isInteger(data.pulseOximeter)) &&
      (Utils.isNil(data.symptoms) || Utils.isNonArrayObjectLike(data.symptoms)) &&
      (Utils.isNil(data.weight) || Utils.isNonArrayObjectLike(data.weight))
    );
  }

  // @ts-expect-error TS2394
  public toHtml(t: WithT['t']): string;
  public toHtml(data: VitalsFormData, t: WithT['t']): string;
  public toHtml(arg0: unknown, arg1: WithT['t']): string {
    let data: VitalsGridData;
    let t: typeof arg1;

    if (typeof arg0 === 'function') {
      data = this.data as typeof data;
      t = arg0 as typeof t;
    } else {
      data = arg0 as typeof data;
      t = arg1;
    }

    data = { ...data, ...data.bloodPressure, ...data.symptoms };

    const tbodyList = FIELD_SET_LIST.map<string>(([fieldsetName, fieldNameList]) => {
      const columnList = (fieldNameList as ReadonlyArray<FieldName>)
        .filter((fieldName) => !fieldName.endsWith('Toggle') && !fieldName.startsWith('other'))
        .map<string>((fieldName) => {
          const header = t((formI18n[fieldsetName] as any).formField[fieldName].label, { language: EnglishCanada });

          const Class = this.constructor as typeof VitalsDataUtil;
          let value = Class.formatDataValue(data, fieldName, t, EnglishCanada);
          if (Utils.isString(value)) {
            if (fieldName === 'diet') {
              value = `<ul>${value!
                .split(', ')
                .map((diet) => `<li>${diet}</li>`)
                .join('')}</ul>`;
            }
          } else {
            value = t(dataValueNotSet);
          }

          return `<th scope="row">${header}</th><td>${value}</td>`;
        });

      if (fieldsetName === 'fieldsetBloodPressure' || fieldsetName === 'fieldsetSymptoms') {
        const header = t(formI18n[fieldsetName].label!, { language: EnglishCanada });
        const rowList = `<tr class="indent-1">${columnList.join('</tr><tr class="indent-1">')}</tr>`;
        return `<tbody><tr><th scope="col">${header}</th><td/></tr>${rowList}</tbody>`;
      } else {
        const rowList = `<tr>${columnList.join('</tr><tr>')}</tr>`;
        return `<tbody>${rowList}</tbody>`;
      }
    }).join('');

    const caption = '<caption>Questionnaire</caption>';
    const thead = '<thead><th>Question</th><th>Answer</th></thead>';

    return `<table class="${DataFormCssClassPrefix}${DataFormNameVitals}">${caption}${thead}${tbodyList}</table>`;
  }
}
