import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { ClientObject } from '.';
import { DataObject } from '../data-object';
import type { ClientObjectConfigValue as DV } from '../types';

const TYPE = process.env.CLIENT_OBJECT_TYPE_CONFIGURATION;

/** @public */
export class ClientObjectConfiguration extends ClientObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      DataObject.isValidId(dv.ownerId) &&
      Utils.isNonArrayObjectLike(dv.licenses) &&
      DataObject.isValidId(dv.globalContactListId)
    );
  }

  public updateValue(newValue: DV): Promise<ClientObjectConfiguration> {
    const Class = this.constructor as typeof ClientObjectConfiguration;
    return Class.updateValue(this, newValue);
  }
}
