import { AppUser, AppUserGroup, NonArrayObjectLike, Utils, ValueObject } from '@sigmail/common';
import {
  ClientObject,
  ClientObjectUserListValue,
  ContactListItem,
  DataObjectSigmailGlobalContactList,
  DataObjectSigmailGlobalContactListValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { OrderedSet } from 'immutable';
import { DataObjectCache } from '../app-state/data-objects-slice/cache';
import { RootState } from '../app-state/root-reducer';
import { activeGroupIdSelector } from '../app-state/selectors';
import * as AuthSelectors from '../app-state/selectors/auth';
import * as ClientObjectSelectors from '../app-state/selectors/client-object';
import * as DataObjectSelectors from '../app-state/selectors/data-object';
import * as GroupObjectSelectors from '../app-state/selectors/group-object';
import * as UserObjectSelectors from '../app-state/selectors/user-object';
import { UserObjectCache } from '../app-state/user-objects-slice/cache';

export type ContactListValueObject = ValueObject & ContactListItem;

export interface GenerateContactListParams {
  include?: {
    otherContactList?: ReadonlyArray<ContactListItem> | null | undefined;
    groupContactList?: boolean | 'active' | 'inactive';
    clientContactList?: boolean | { user?: boolean | 'active' | 'inactive'; group?: boolean };
    groupGuestContactList?: boolean | { user?: boolean | 'active' | 'inactive'; group?: boolean };
    circleOfCareContactList?: boolean;
    globalContactList?: boolean | { user?: boolean; group?: boolean };
  } | null;
  exclude?: Array<Pick<ContactListItem, 'type' | 'id'> | null | undefined> | null;
  unsorted?: boolean;
}

export interface GenerateContactListResult {
  contactList: Array<ContactListValueObject>;
  query: Pick<Required<Api.BatchQueryRequestData>, 'authState' | 'dataObjects' | 'userObjectsByType'>;
}

function toContactListValueObject(listItem: ContactListItem): ContactListValueObject {
  return {
    ...listItem,

    equals: (other: any) =>
      Utils.isNonArrayObjectLike<ContactListItem>(other) && listItem.type === other.type && listItem.id === other.id,

    hashCode: () => {
      let hashed = 0;
      hashed = (31 * hashed + Utils.hashString(listItem.type)) | 0;
      hashed = (31 * hashed + Utils.hashNumber(listItem.id)) | 0;
      return hashed;
    }
  };
}

export const getContactListItemLabel = (contact: ContactListItem) =>
  contact.type === 'group' ? contact.groupData.groupName : Utils.joinPersonName(contact.userData);

const EMPTY_CLIENT_USER_LIST: ClientObjectUserListValue = {
  $$formatver: undefined,
  active: [],
  inactive: [],
  pending: []
};

export const isActiveClientUser = (
  clientUserList: ClientObjectUserListValue,
  contact: Pick<ContactListItem, 'type' | 'id'>
) => contact.type === 'user' && clientUserList.active.some(({ id: userId }) => userId === contact.id);

export const isInactiveClientUser = (
  clientUserList: ClientObjectUserListValue,
  contact: Pick<ContactListItem, 'type' | 'id'>
) => contact.type === 'user' && clientUserList.inactive.some(({ id: userId }) => userId === contact.id);

export const generateContactList = (
  state: RootState,
  params?: GenerateContactListParams
): GenerateContactListResult => {
  const dataObjectIdList: Array<Required<Api.BatchQueryRequestData>['dataObjects']['ids'][0]> = [];
  const userObjectListByType: Array<Required<Api.BatchQueryRequestData>['userObjectsByType'][0]> = [];

  const result: GenerateContactListResult = {
    contactList: [],
    query: {
      authState: '',
      dataObjects: { ids: dataObjectIdList },
      userObjectsByType: userObjectListByType
    }
  };

  const currentUserId = AuthSelectors.currentUserIdSelector(state);
  if (!AppUser.isValidId(currentUserId)) return result;
  result.query = { ...result.query, authState: AuthSelectors.authClaimSelector(state) };

  let list: Array<ContactListItem> = [];

  let isUserRoleGuest = false;
  let isUserRoleNonGuest = false;
  let isUserRolePhysician = false;
  let isUserRoleStaff = false;
  const basicProfileObject = UserObjectSelectors.basicProfileObjectSelector(state)(/***/);
  const basicProfile = UserObjectCache.getValue(basicProfileObject);
  if (Utils.isNil(basicProfile)) {
    userObjectListByType.push({ userId: currentUserId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC });
  } else {
    isUserRoleGuest = Utils.isGuestRole(basicProfile.role);
    isUserRoleNonGuest = !isUserRoleGuest && Utils.isNonGuestRole(basicProfile.role);
    isUserRolePhysician = isUserRoleNonGuest && Utils.isPhysicianRole(basicProfile.role);
    isUserRoleStaff = isUserRoleNonGuest && Utils.isStaffRole(basicProfile.role);
  }

  let excludeList: Array<NonNullable<NonNullable<GenerateContactListParams['exclude']>[0]>> = [];
  let unsorted = false;

  let groupContactList: boolean | 'active' | 'inactive' = false;
  let clientUserContactList: boolean | 'active' | 'inactive' = false;
  let clientGroupContactList = false;
  let circleOfCareGuestUserContactList: boolean | 'active' | 'inactive' = false;
  let circleOfCareGuestGroupContactList = false;
  let circleOfCareContactList = false;
  let globalUserContactList = false;
  let globalGroupContactList = false;
  let otherContactList: NonNullable<GenerateContactListParams['include']>['otherContactList'] = undefined;

  const clientId = UserObjectSelectors.clientIdSelector(state);
  const clientUserListObject = ClientObjectSelectors.userListObjectSelector(state)(/***/);
  const clientUserList = UserObjectCache.getValue(clientUserListObject);
  const groupId = activeGroupIdSelector(state);
  const globalContactListId = ClientObjectSelectors.globalContactListIdSelector(state);
  let isGroupIdNeeded = false;
  let isClientUserListNeeded = false;
  let isClientIdNeeded = false;

  let activeContactFilter = isActiveClientUser.bind(null, EMPTY_CLIENT_USER_LIST);
  let inactiveContactFilter = isInactiveClientUser.bind(null, EMPTY_CLIENT_USER_LIST);

  if (Utils.isNonArrayObjectLike<GenerateContactListParams>(params)) {
    const { include, exclude } = params;
    if (Utils.isNonArrayObjectLike<NonNullable<GenerateContactListParams>['include']>(include)) {
      if (isUserRoleNonGuest) {
        if (
          include.groupContactList === true ||
          include.groupContactList === 'active' ||
          include.groupContactList === 'inactive'
        ) {
          groupContactList = include.groupContactList;
          isGroupIdNeeded = true;
          isClientIdNeeded = true;
          isClientUserListNeeded = include.groupContactList === 'active' || include.groupContactList === 'inactive';
        }

        //
        // client contact list (466)
        //
        if (include.clientContactList === true) {
          clientUserContactList = clientGroupContactList = true;
          isClientIdNeeded = true;
        } else if (
          Utils.isNonArrayObjectLike<NonNullable<typeof include.clientContactList>>(include.clientContactList)
        ) {
          const isValueActiveOrInactive =
            include.clientContactList.user === 'active' || include.clientContactList.user === 'inactive';
          clientUserContactList =
            include.clientContactList.user === true || (isValueActiveOrInactive && include.clientContactList!.user!);
          clientGroupContactList = include.clientContactList.group === true;
          if (!isClientUserListNeeded) isClientUserListNeeded = isValueActiveOrInactive;
          if (!isClientIdNeeded) isClientIdNeeded = Boolean(clientUserContactList) || clientGroupContactList;
        }

        //
        // group contact list (468)
        //
        if (include.groupGuestContactList === true) {
          circleOfCareGuestUserContactList = circleOfCareGuestGroupContactList = true;
          isGroupIdNeeded = true;
        } else if (
          Utils.isNonArrayObjectLike<NonNullable<typeof include.groupGuestContactList>>(include.groupGuestContactList)
        ) {
          const isValueActiveOrInactive =
            include.groupGuestContactList.user === 'active' || include.groupGuestContactList.user === 'inactive';
          circleOfCareGuestUserContactList =
            include.groupGuestContactList.user === true ||
            (isValueActiveOrInactive && include.groupGuestContactList!.user!);
          circleOfCareGuestGroupContactList = include.groupGuestContactList.group === true;
          if (!isGroupIdNeeded)
            isGroupIdNeeded = Boolean(circleOfCareGuestUserContactList) || circleOfCareGuestGroupContactList;
          if (!isClientUserListNeeded) isClientUserListNeeded = isValueActiveOrInactive;
          if (!isClientIdNeeded) isClientIdNeeded = isValueActiveOrInactive;
        }

        //
        // global contact list (360)
        //
        if (isUserRolePhysician || isUserRoleStaff) {
          const { globalContactList } = include;
          if (globalContactList === true) {
            globalUserContactList = globalGroupContactList = true;
          } else if (Utils.isNonArrayObjectLike<NonNullable<typeof include.globalContactList>>(globalContactList)) {
            globalUserContactList = globalContactList.user === true;
            globalGroupContactList = globalContactList.group === true;
          }

          if (!isClientIdNeeded) {
            isClientIdNeeded =
              (globalUserContactList || globalGroupContactList) &&
              !DataObjectSigmailGlobalContactList.isValidId(globalContactListId);
          }
        }

        if (!isClientIdNeeded && isClientUserListNeeded && Utils.isNil(clientUserList)) {
          isClientIdNeeded = true;
        }
      }

      circleOfCareContactList = isUserRoleGuest && include.circleOfCareContactList === true;

      if (Utils.isNonEmptyArray(include.otherContactList)) {
        otherContactList = include.otherContactList;
      }
    }

    if (Utils.isNonEmptyArray(exclude)) {
      excludeList.push(
        ...exclude.filter((item): item is NonArrayObjectLike<typeof item> => Utils.isNonArrayObjectLike(item))
      );
    }

    unsorted = params!.unsorted === true;
  }

  if (isGroupIdNeeded && !AppUserGroup.isValidId(groupId)) {
    userObjectListByType.push({ userId: currentUserId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC });
  }

  if (isClientIdNeeded && !ClientObject.isValidId(clientId)) {
    userObjectListByType.push({ userId: currentUserId, type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE });
  }

  if (ClientObject.isValidId(clientId)) {
    if (isClientUserListNeeded) {
      if (Utils.isNil(clientUserList)) {
        userObjectListByType.push({ userId: clientId!, type: process.env.CLIENT_OBJECT_TYPE_USER_LIST });
      } else {
        activeContactFilter = isActiveClientUser.bind(null, clientUserList);
        inactiveContactFilter = isInactiveClientUser.bind(null, clientUserList);
      }
    }

    //
    //#region client contact list (466)
    //
    if (
      (!!clientUserContactList || clientGroupContactList) &&
      (!isClientUserListNeeded || Utils.isNotNil(clientUserList))
    ) {
      const contactListObject = ClientObjectSelectors.contactListObjectSelector(state)(/***/);
      const contactList = UserObjectCache.getValue(contactListObject);
      if (Utils.isNil(contactList)) {
        userObjectListByType.push({ userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST });
      } else if (clientUserContactList === true && clientGroupContactList === true) {
        list.push(...contactList.list);
      } else {
        list.push(
          ...contactList.list.filter((contact) => {
            if (contact.type === 'group') return clientGroupContactList;
            if (typeof clientUserContactList === 'boolean') return clientUserContactList;
            return clientUserContactList === 'active' ? activeContactFilter(contact) : inactiveContactFilter(contact);
          })
        );
      }
    }
    //#endregion
    //

    //
    //#region group contact list
    //
    if (!!groupContactList && AppUserGroup.isValidId(groupId)) {
      const groupProfileObject = GroupObjectSelectors.basicProfileObjectSelector(state)(groupId);
      const groupProfile = UserObjectCache.getValue(groupProfileObject);
      if (Utils.isNil(groupProfile)) {
        userObjectListByType.push({ userId: groupId, type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC });
      } else {
        const isGroupMember = ({ type: contactType, id: contactId }: ContactListItem) =>
          groupProfile.memberList.some(({ type, id }) => type === contactType && id === contactId);

        const contactListObject = ClientObjectSelectors.contactListObjectSelector(state)(/***/);
        const contactList = UserObjectCache.getValue(contactListObject);
        if (Utils.isNil(contactList)) {
          if (
            !userObjectListByType.find(
              ({ userId, type }) => userId === clientId && type === process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST
            )
          ) {
            userObjectListByType.push({ userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST });
          }
        } else if (groupContactList === true) {
          list.push(...contactList.list.filter(isGroupMember));
        } else {
          list.push(
            ...contactList.list.filter((contact) => {
              if (!isGroupMember(contact)) return false;
              return groupContactList === 'active' ? activeContactFilter(contact) : inactiveContactFilter(contact);
            })
          );
        }
      }
    }
    //#endregion
    //

    //
    //#region group guest list (468)
    //
    if (
      (!!circleOfCareGuestUserContactList || circleOfCareGuestGroupContactList) &&
      (!isClientUserListNeeded || Utils.isNotNil(clientUserList))
    ) {
      if (AppUserGroup.isValidId(groupId)) {
        const contactListObject = GroupObjectSelectors.guestListObjectSelector(state)(/***/);
        const contactList = UserObjectCache.getValue(contactListObject);
        if (Utils.isNil(contactList)) {
          userObjectListByType.push({ userId: groupId, type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST });
        } else if (circleOfCareGuestUserContactList === true && circleOfCareGuestGroupContactList === true) {
          list.push(...contactList.list);
        } else {
          list.push(
            ...contactList.list.filter((contact) => {
              if (contact.type === 'group') return circleOfCareGuestGroupContactList;
              if (typeof circleOfCareGuestUserContactList === 'boolean') return circleOfCareGuestUserContactList;
              if (Utils.isString(contact.userData.memberType)) return true;

              return circleOfCareGuestUserContactList === 'active'
                ? activeContactFilter(contact)
                : inactiveContactFilter(contact);
            })
          );
        }
      }
    }
    //#endregion
    //
  }

  //
  //#region global contact list (360)
  //
  if (globalUserContactList || globalGroupContactList) {
    if (!DataObjectSigmailGlobalContactList.isValidId(globalContactListId)) {
      if (ClientObject.isValidId(clientId)) {
        userObjectListByType.push({ userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONFIGURATION });
      }
    } else {
      const dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(state);
      const contactListObject = dataObjectByIdSelector<DataObjectSigmailGlobalContactListValue>(globalContactListId);
      const contactList = DataObjectCache.getValue(contactListObject);
      if (Utils.isNil(contactList)) {
        dataObjectIdList.push(globalContactListId);
      } else if (globalUserContactList === true && globalGroupContactList === true) {
        list.push(...contactList.list);
      } else {
        list.push(
          ...contactList.list.filter(
            ({ type }) => (type === 'group' && globalGroupContactList) || (type === 'user' && globalUserContactList)
          )
        );
      }
    }
  }
  //#endregion
  //

  if (circleOfCareContactList) {
    const contactListObject = UserObjectSelectors.circleOfCareObjectSelector(state)(/***/);
    const contactList = UserObjectCache.getValue(contactListObject);
    if (Utils.isNil(contactList)) {
      userObjectListByType.push({ userId: currentUserId, type: process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE });
    } else {
      list.push(...contactList.list);
    }
  }

  if (Utils.isNonEmptyArray(otherContactList)) {
    list.push(...otherContactList);
  }

  if (list.length > 0) {
    if (excludeList.length > 0) {
      list = list.filter(({ id, type }) => !excludeList.some((item) => item.id === id && item.type === type));
    }

    if (!unsorted) {
      list = list.sort((contact1, contact2) => {
        const name1 = getContactListItemLabel(contact1);
        const name2 = getContactListItemLabel(contact2);
        return name1.localeCompare(name2);
      });
    }
  }

  return {
    contactList: OrderedSet(list.map(toContactListValueObject)).toArray(),
    query: {
      authState: state.auth.authClaim,
      dataObjects: { ids: dataObjectIdList },
      userObjectsByType: userObjectListByType
    }
  };
};
