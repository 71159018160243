import { AppUser, MembershipStatus, Nullable, Utils } from '@sigmail/common';
import { ClientObject, ContactListItemExt } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFlattenedClientUserList } from '.';
import membershipStatusI18n from '../../i18n/global/membership-status';
import {
  BatchQueryRequest,
  compareContactListItem,
  ExcludeListItem,
  filterContactList,
  FilterFunction
} from '../../utils/contact-list';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { currentUserIdSelector, selectAccessRight } from '../selectors/auth';
import { contactListObjectSelector } from '../selectors/client-object';
import { clientIdSelector } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';

export interface UseClientContactListParams {
  disableMembershipList?: Nullable<boolean>;
  exclude?: Nullable<ReadonlyArray<Nullable<ExcludeListItem>>>;
  filterFn?: Nullable<FilterFunction<ContactListItemExt>>;
  forceFetch?: Nullable<boolean>;
  groups?: Nullable<boolean>;
  unsorted?: Nullable<boolean>;
  users?: Nullable<boolean | MembershipStatus>;
}

export type UseClientContactListResult = [list: ReadonlyArray<ContactListItemExt>, query: BatchQueryRequest];

const isValidMembershipStatusKey = (value?: any): value is MembershipStatus =>
  Object.keys(membershipStatusI18n).includes(value);

export const useClientContactList = (params?: Nullable<UseClientContactListParams>) => {
  const canAccessContactList = useSelector(selectAccessRight)('accessClientContacts');
  const currentUserId = useSelector(currentUserIdSelector);
  const clientContactListSelector = useSelector(contactListObjectSelector);
  const contactListObject = clientContactListSelector(/***/);
  const clientContactList = params?.forceFetch === true ? undefined : UserObjectCache.getValue(contactListObject);

  const disabledMembershipList = params?.disableMembershipList === true;
  const includeGroupList = params?.groups === true;
  const includeUserList = params?.users === true || isValidMembershipStatusKey(params?.users) ? params!.users! : false;
  const exclude = params?.exclude;
  const filterFn = typeof params?.filterFn === 'function' ? params!.filterFn! : filterContactList;
  const unsorted = params?.unsorted === true;

  const clientId = useSelector(clientIdSelector);
  const isClientIdNeeded = !ClientObject.isValidId(clientId) && Utils.isNil(clientContactList);

  const [flattenedUserList, userListQuery] = useFlattenedClientUserList({
    disabledMembershipList,
    exclude,
    filterFn,
    users: includeUserList
  });

  return React.useMemo((): UseClientContactListResult => {
    let contactList: UseClientContactListResult[0] = EMPTY_ARRAY;

    const userObjects: Required<UseClientContactListResult[1]>['userObjectsByType'] = [];
    if (Utils.isArray(userListQuery.userObjectsByType)) {
      userObjects.push(...userListQuery.userObjectsByType);
    }

    do {
      if (!canAccessContactList) break;
      if (!includeGroupList && !includeUserList) break;
      if (!AppUser.isValidId(currentUserId)) break;
      if (userObjects.length > 0) break;

      if (isClientIdNeeded) {
        userObjects.push({ type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId: currentUserId });
        break;
      }

      if (Utils.isNil(clientContactList)) {
        userObjects.push({ type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST, userId: clientId! });
        break;
      }

      const { list } = clientContactList;
      contactList = list.reduce((result, contact) => {
        if (contact.type === 'group') {
          if (includeGroupList) {
            result.push(contact);
          }
        } else {
          const index = flattenedUserList!.findIndex(({ id }) => id === contact.id);
          if (index > -1) {
            const { timestamp: accountTimestamp, ...extUserData } = flattenedUserList![index];
            if (includeUserList === true || extUserData.accountStatus === includeUserList) {
              result.push({
                ...contact,
                userData: {
                  ...contact.userData,
                  ...extUserData,
                  accountTimestamp
                }
              });
            }
          }
        }
        return result;
      }, [] as Array<typeof contactList[0]>);

      contactList = filterFn(contactList, { exclude });

      if (!unsorted && contactList.length > 1) {
        contactList = contactList.slice().sort(compareContactListItem);
      }
    } while (false);

    let query: UseClientContactListResult[1] = EMPTY_PLAIN_OBJECT;
    if (userObjects.length > 0) query = { userObjectsByType: userObjects };

    return [contactList, query];
  }, [
    canAccessContactList,
    clientId,
    clientContactList,
    currentUserId,
    exclude,
    filterFn,
    flattenedUserList,
    includeGroupList,
    includeUserList,
    isClientIdNeeded,
    unsorted,
    userListQuery.userObjectsByType
  ]);
};
