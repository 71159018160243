import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogContactListGridFilter } from '../../constants/action-context';

const i18n: ContactListI18n.View.ContactListView['dialog']['gridFilter'] = {
  // Translators: (HTML) Title for the Contact List Grid Filter dialog
  title: gettext('contact-list', 'title', 'contactListGridFilterDialog', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Contact List Grid Filter \
      // dialog
      label: gettext('contact-list', 'reject', 'contactListGridFilterDialogActionLabel'),
      context: DialogContactListGridFilter
    },
    accept: {
      // Translators: Label for the Apply action of Contact List Grid Filter \
      // dialog
      label: gettext('contact-list', 'accept', 'contactListGridFilterDialogActionLabel'),
      context: DialogContactListGridFilter
    },
    reset: {
      // Translators: Label for the Reset Filter action of Contact List Grid \
      // Filter dialog
      label: gettext('contact-list', 'reset', 'contactListGridFilterDialogActionLabel'),
      context: DialogContactListGridFilter
    }
  }
};

export default i18n;
