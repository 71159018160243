import { MessagingI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import { DialogEMRUploadProgress } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.EMR.UploadProgress = {
  action: {
    accept: {
      // Translators: Label for the Close action of EMR Upload Progress dialog
      label: gettext('messaging', 'accept', 'dialogEMRUploadProgressActionLabel'),
      context: DialogEMRUploadProgress
    }
  },
  message: {
    error: {
      // Translators: (HTML) Message to be displayed in EMR Upload Progress \
      // dialog when one or more documents were rejected with a duplicate status
      // Translators: Token(s) available: {{DOC_LIST}}
      duplicateDocument: ngettext('messaging', 'errorDuplicateDocument', 'dialogEMRUploadProgressMessage', 'safe-html'),
      // Translators: (HTML) Message to be displayed in EMR Upload Progress \
      // dialog when one or more documents were of an unsupported file type
      // Translators: Token(s) available: {{DOC_LIST}}
      unsupportedDocument: ngettext('messaging', 'errorUnsupportedDocument', 'dialogEMRUploadProgressMessage', 'safe-html'),
      // Translators: (HTML) Message to be displayed in EMR Upload Progress \
      // dialog when body upload could not succeed
      uploadBody: gettext('messaging', 'errorUploadBody', 'dialogEMRUploadProgressMessage', 'safe-html'),
      // Translators: (HTML) Message to be displayed in EMR Upload Progress \
      // dialog when one or more documents could not be uploaded successfully
      // Translators: Token(s) available: {{DOC_LIST}}
      uploadDocument: ngettext('messaging', 'errorUploadDocument', 'dialogEMRUploadProgressMessage', 'safe-html')
    },
    progress: {
      // Translators: (HTML) Message to be displayed in EMR Upload Progress \
      // dialog when body upload is in progress
      uploadBody: gettext('messaging', 'uploadingBody', 'dialogEMRUploadProgressMessage', 'safe-html'),
      // Translators: (HTML) Message to be displayed in EMR Upload Progress \
      // dialog when document upload is in progress
      // Translators: Token(s) available: {{DOC_NAME}}
      uploadDocument: gettext('messaging', 'uploadingDocument', 'dialogEMRUploadProgressMessage', 'safe-html')
    },
    // Translators: (HTML) Message to be displayed in EMR Upload Progress \
    // dialog when the operation completes with no errors
    success: gettext('messaging', 'success', 'dialogEMRUploadProgressMessage')
  },
  // Translators: (HTML) Title for the EMR Upload Progress dialog
  title: gettext('messaging', 'title', 'dialogEMRUploadProgress', 'safe-html')
};

export default i18n;
