import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import type { Api, Http } from '../types';
import { URL_GET_GEO_LOCATION as API_URL } from './constants';
import { MalformedResponseException } from './malformed-response-exception';
import { ServiceException as ApiServiceException } from './service-exception';

const GEO_LOCATION_KEY_LIST: ReadonlyArray<keyof Api.GeoLocationResponseData> = [
  'city',
  'country',
  'hostname',
  'ip',
  'loc',
  'org',
  'postal',
  'region',
  'timezone'
];

const Logger = getLoggerWithPrefix('ApiService', 'getGeoLocation:');

export async function apiGetGeoLocation(
  this: Api.Service,
  accessToken: string,
  init?: Http.RequestInit
): Promise<Api.GeoLocationResponseData> {
  try {
    Logger.info('== BEGIN ==');

    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.get<Api.GeoLocationResponseData>(requestUrl, {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status === 200) {
          const json = await Utils.tryGetResponseJson<Api.GeoLocationResponseData>(response, undefined);
          if (Utils.isNonArrayObjectLike<Record<string, unknown>>(json)) {
            return Object.fromEntries(
              Utils.filterMap(GEO_LOCATION_KEY_LIST, (key) => {
                const value = Utils.trimOrDefault(json[key]);
                return value.length > 0 && ([key, value] as const);
              })
            );
          }

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status >= 400 && response.status < 500) {
          const responseBody = await response.clone().text();
          throw new ApiServiceException(response, responseBody);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
