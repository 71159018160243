import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.DataViewer.VitalsQuestionnaire = {
  columnHeader: {
    activity: {
      // Translators: (HTML) Table for Activity column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'activity', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Activity column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'activity', 'vitalsDataViewerColumnHeaderMD')
    },
    alcohol: {
      // Translators: (HTML) Table for Alcohol column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'alcohol', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Alcohol column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'alcohol', 'vitalsDataViewerColumnHeaderMD')
    },
    changeInWeight: {
      // Translators: (HTML) Table for Change in weight column header of \
      // Vitals data viewer small screen
      sm: gettext('health-data', 'changeInWeight', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Change in weight column header of \
      // Vitals data viewer medium screen
      md: gettext('health-data', 'changeInWeight', 'vitalsDataViewerColumnHeaderMD')
    },
    diastolic: {
      // Translators: (HTML) Table for Diastolic column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'diastolic', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Diastolic column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'diastolic', 'vitalsDataViewerColumnHeaderMD')
    },
    diet: {
      // Translators: (HTML) Table for Diet column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'diet', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Diet column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'diet', 'vitalsDataViewerColumnHeaderMD')
    },
    dyspnea: {
      // Translators: (HTML) Table for Dyspnea column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'dyspnea', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Dyspnea column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'dyspnea', 'vitalsDataViewerColumnHeaderMD')
    },
    fieldsetBloodPressure: {
      // Translators: (HTML) Table for Fieldset blood pressure column header \
      // of Vitals data viewer small screen
      sm: gettext('health-data', 'fieldsetBloodPressure', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Fieldset blood pressure column header \
      // of Vitals data viewer medium screen
      md: gettext('health-data', 'fieldsetBloodPressure', 'vitalsDataViewerColumnHeaderMD')
    },
    fieldsetSymptoms: {
      // Translators: (HTML) Table for Fieldset symptoms column header of \
      // Vitals data viewer small screen
      sm: gettext('health-data', 'fieldsetSymptoms', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Fieldset symptoms column header of \
      // Vitals data viewer medium screen
      md: gettext('health-data', 'fieldsetSymptoms', 'vitalsDataViewerColumnHeaderMD')
    },
    heartRate: {
      // Translators: (HTML) Table for Heart rate column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'heartRate', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Heart rate column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'heartRate', 'vitalsDataViewerColumnHeaderMD')
    },
    height: {
      // Translators: (HTML) Table for Height column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'height', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Height column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'height', 'vitalsDataViewerColumnHeaderMD')
    },
    notes: {
      // Translators: (HTML) Table for Notes column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'notes', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Notes column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'notes', 'vitalsDataViewerColumnHeaderMD')
    },
    pulseOximeter: {
      // Translators: (HTML) Table for Pulse oximeter column header of Vitals \
      // data viewer small screen
      sm: gettext('health-data', 'pulseOximeter', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Pulse oximeter column header of Vitals \
      // data viewer medium screen
      md: gettext('health-data', 'pulseOximeter', 'vitalsDataViewerColumnHeaderMD')
    },
    question: {
      // Translators: (HTML) Table for Question column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'question', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Question column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'question', 'vitalsDataViewerColumnHeaderMD')
    },
    swelling: {
      // Translators: (HTML) Table for Swelling column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'swelling', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Swelling column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'swelling', 'vitalsDataViewerColumnHeaderMD')
    },
    systolic: {
      // Translators: (HTML) Table for Systolic column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'systolic', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Systolic column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'systolic', 'vitalsDataViewerColumnHeaderMD')
    },
    weight: {
      // Translators: (HTML) Table for Weight column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'weight', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Weight column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'weight', 'vitalsDataViewerColumnHeaderMD')
    },
    wellBeing: {
      // Translators: (HTML) Table for Well being column header of Vitals data \
      // viewer small screen
      sm: gettext('health-data', 'wellBeing', 'vitalsDataViewerColumnHeader'),
      // Translators: (HTML) Table for Well being column header of Vitals data \
      // viewer medium screen
      md: gettext('health-data', 'wellBeing', 'vitalsDataViewerColumnHeaderMD')
    }
  },
  // Translators: Message displayed in the Data viewer section of Health Data \
  // view when no data is available to display for the selected contact
  dataNotAvailable: gettext('health-data', 'dataNotAvailable', 'vitalsDataViewer'),
  // Translators: Cell value displayed instead in the Data viewer section of \
  // Health Data view when the actual value of a data element is missing
  dataValueNotSet: gettext('health-data', 'dataValueNotSet', 'vitalsDataViewer'),
  fieldTimestamp: {
    // Translators: Hint displayed in Timestamp input of Vitals data viewer
    placeholder: gettext('health-data', 'timestamp', 'vitalsDataViewerInputHint')
  }
};

export default i18n;
