import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ManageAccountSecurity } from '../../constants/action-context';

const i18n: AccountI18n.View.Security = {
  action: {
    changePassword: {
      // Translators: Label for the Change Password action of Account Security \
      // view
      label: gettext('account', 'changePassword', 'accountSecurityActionLabel'),
      context: ManageAccountSecurity
    },
    disableMfa: {
      // Translators: Label for the Disable MFA action of Account Security \
      // view
      label: gettext('account', 'disableMfa', 'accountSecurityActionLabel'),
      context: ManageAccountSecurity
    },
    enableMfa: {
      // Translators: Label for the Enable MFA action of Account Security \
      // view
      label: gettext('account', 'enableMfa', 'accountSecurityActionLabel'),
      context: ManageAccountSecurity
    }
  },
  // Translators: Label for the Security View under My Account
  label: gettext('account', 'security', 'accountViewLabel'),
  option: {
    mfa: {
      // Translators: Label for the 2-Step Verification option of Account \
      // Security view
      label: gettext('account', 'mfa', 'accountSecurityOptionLabel'),
      // Translators: Description for the 2-Step Verification option of \
      // Account Security view
      description: gettext('account', 'mfa', 'accountSecurityOptionDescription')
    },
    password: {
      // Translators: Label for the Password option of Account Security view
      label: gettext('account', 'password', 'accountSecurityOptionLabel'),
      // Translators: Description for the Password option of Account Security \
      // view
      description: gettext('account', 'password', 'accountSecurityOptionDescription')
    }
  }
};

export default i18n;
