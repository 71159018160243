/* eslint-disable no-redeclare */
import { SignInSuccessAuthOnly } from '@sigmail/app-state';
import {
  Constants,
  ReadonlyMessageBodyConsultation,
  ReadonlyMessageBodyDefault,
  ReadonlyMessageBodyEncounter,
  ReadonlyMessageBodyEvent,
  ReadonlyMessageBodyEventAttendance,
  ReadonlyMessageBodyHealthDataRequest,
  ReadonlyMessageBodyHrm,
  ReadonlyMessageBodyJoinGroupInvitation,
  ReadonlyMessageBodyReferral,
  SigmailObjectId,
  Utils
} from '@sigmail/common';
import {
  DataObjectCalendarEvent,
  DataObjectCalendarEventValue,
  DataObjectMsgBody,
  DataObjectMsgBodyValue
} from '@sigmail/objects';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { DEFAULT_ACCESS_CODE_JOIN_GROUP_INVITATION } from '../../../../app-state/actions/constants';
import { EMPTY_ARRAY } from '../../../../app-state/constants';
import { DataObjectCache } from '../../../../app-state/data-objects-slice/cache';
import {
  useCredentialsAuthenticationResource,
  UseCredentialsAuthenticationResourceParams,
  useDataObjectByIdSelector,
  useDispatchFetchDataObjects,
  useServerDateTimeResource
} from '../../../../app-state/hooks';
import { UNRESOLVED_RESOURCE } from '../../../../constants';
import { useThrowAsyncError } from '../../../../hooks';
import { EventFlags, EventFlagsResult } from '../../../../utils';
import { generateCredentialHash } from '../../../../utils/generate-credential-hash';
import { MessageFlags, MessageFlagsKey } from '../../utils';

type ReadonlyMessageBodyConsultationRequestValue = ReadonlyMessageBodyConsultation['messageForm']['value'];
type ReadonlyMessageBodyDefaultValue = ReadonlyMessageBodyDefault['data'];
type ReadonlyMessageBodyEncounterValue = ReadonlyMessageBodyEncounter;
type ReadonlyMessageBodyEventValue = ReadonlyMessageBodyEvent['messageForm']['value'] &
  DataObjectCalendarEventValue & {
    readonly flags: EventFlagsResult;
  };
type ReadonlyMessageBodyEventAttendanceValue = ReadonlyMessageBodyEventAttendance['messageForm']['value'] &
  DataObjectCalendarEventValue & {
    readonly flags: EventFlagsResult;
  };
type ReadonlyMessageBodyHealthDataRequestValue = ReadonlyMessageBodyHealthDataRequest['messageForm']['value'];
type ReadonlyMessageBodyHRMValue = ReadonlyMessageBodyHrm['messageForm']['value'];
type ReadonlyMessageBodyJoinGroupInvitationValue = Readonly<
  [body: ReadonlyMessageBodyJoinGroupInvitation['messageForm']['value'], expired: boolean]
>;
type ReadonlyMessageBodyReferralValue = ReadonlyMessageBodyReferral['messageForm']['value'];

type MessageBodyValue =
  | ReadonlyMessageBodyConsultationRequestValue
  | ReadonlyMessageBodyDefaultValue
  | ReadonlyMessageBodyEncounterValue
  | ReadonlyMessageBodyEventValue
  | ReadonlyMessageBodyEventAttendanceValue
  | ReadonlyMessageBodyHealthDataRequestValue
  | ReadonlyMessageBodyHRMValue
  | ReadonlyMessageBodyJoinGroupInvitationValue
  | ReadonlyMessageBodyReferralValue;

const MSG_FLAG_KEY_LIST: ReadonlyArray<Extract<MessageFlagsKey, `isMessageForm${string}`>> = [
  'isMessageFormDefault',
  'isMessageFormEConsult',
  'isMessageFormEncounter',
  'isMessageFormEvent',
  'isMessageFormEventAttendance',
  'isMessageFormHRM',
  'isMessageFormHealthDataRequest',
  'isMessageFormJoinGroupInvitation',
  'isMessageFormReferral'
];

export type MessageBodyResource<T extends MessageBodyValue = MessageBodyValue> = ISuspenseResource<T>;

export type ConsultationRequestMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyConsultationRequestValue>;
export type DefaultMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyDefaultValue>;
export type EncounterMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyEncounterValue>;
export type EventMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyEventValue>;
export type EventAttendanceMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyEventAttendanceValue>;
export type HealthDataRequestMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyHealthDataRequestValue>;
export type HrmMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyHRMValue>;
export type JoinGroupInvitationMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyJoinGroupInvitationValue>;
export type ReferralMessageBodyResource = MessageBodyResource<ReadonlyMessageBodyReferralValue>;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.Default,
  messageBodyId?: unknown
): DefaultMessageBodyResource;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.EConsult,
  messageBodyId?: unknown
): ConsultationRequestMessageBodyResource;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.Encounter,
  messageBodyId?: unknown
): EncounterMessageBodyResource;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.Event,
  messageBodyId?: unknown
): EventMessageBodyResource;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.EventAttendance,
  messageBodyId?: unknown
): EventAttendanceMessageBodyResource;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.HealthDataRequest,
  messageBodyId?: unknown
): HealthDataRequestMessageBodyResource;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.HRM,
  messageBodyId?: unknown
): HrmMessageBodyResource;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.JoinGroupInvitation,
  messageBodyId?: unknown
): JoinGroupInvitationMessageBodyResource;

export function useMessageBodyResource(
  messageFormName: typeof Constants.MessageFormName.Referral,
  messageBodyId: unknown
): ReferralMessageBodyResource;

export function useMessageBodyResource(messageFormName?: unknown | null, messageBodyId?: unknown): MessageBodyResource;

export function useMessageBodyResource(messageFormName?: unknown | null, messageBodyId?: unknown): MessageBodyResource {
  const isValidMessageBodyId = DataObjectMsgBody.isValidId(messageBodyId);
  const dataObjectSelector = useDataObjectByIdSelector();
  const messageBody = DataObjectCache.getValue(
    dataObjectSelector<DataObjectMsgBodyValue>(Utils.numberOrDefault(isValidMessageBodyId && messageBodyId))
  );
  const fetchDataObjects = useDispatchFetchDataObjects();
  const throwAsyncError = useThrowAsyncError();
  const [eventObjectId, setEventObjectId] = React.useState<SigmailObjectId>();
  const [resource, setResource] = React.useState<MessageBodyResource>(UNRESOLVED_RESOURCE);

  const {
    isMessageFormDefault,
    isMessageFormEConsult,
    isMessageFormEncounter,
    isMessageFormEvent,
    isMessageFormEventAttendance,
    isMessageFormHRM,
    isMessageFormHealthDataRequest,
    isMessageFormJoinGroupInvitation,
    isMessageFormReferral
  } = React.useMemo(() => {
    return Utils.mapValues(
      Utils.pick(MessageFlags({ messageForm: { name: messageFormName } }), MSG_FLAG_KEY_LIST),
      (flag) => Utils.isNil(messageFormName) || flag
    );
  }, [messageFormName]);

  type UseMemoResult = Readonly<
    [
      msgBody: MessageBodyValue | undefined,
      isCached: boolean | undefined,
      isEventMsgBody: boolean,
      isEventAttendanceMsgBody: boolean,
      isJoinGroupInvitationMsgBody: boolean
    ]
  >;

  const [
    msgBody,
    isCached,
    isEventMsgBody,
    isEventAttendanceMsgBody,
    isJoinGroupInvitationMsgBody
  ] = React.useMemo((): UseMemoResult => {
    if (!isValidMessageBodyId) {
      return (EMPTY_ARRAY as unknown) as UseMemoResult;
    }

    let isEventMsgBody = false;
    let isEventAttendanceMsgBody = false;
    let isJoinGroupInvitationMsgBody = false;
    let value: UseMemoResult[0];

    const isCached = Utils.isNotNil(messageBody);
    if (isCached) {
      const msgBodyFlags = MessageFlags(messageBody);
      if (isMessageFormEConsult && msgBodyFlags.isMessageFormEConsult) {
        value = (messageBody as ReadonlyMessageBodyConsultation).messageForm.value;
      } else if (isMessageFormDefault && msgBodyFlags.isMessageFormDefault) {
        value = (messageBody as ReadonlyMessageBodyDefault).data;
      } else if (isMessageFormEncounter && msgBodyFlags.isMessageFormEncounter) {
        value = messageBody as ReadonlyMessageBodyEncounter;
      } else if (isMessageFormEvent && msgBodyFlags.isMessageFormEvent) {
        isEventMsgBody = true;
        value = (messageBody as ReadonlyMessageBodyEvent).messageForm.value as ReadonlyMessageBodyEventValue;
      } else if (isMessageFormEventAttendance && msgBodyFlags.isMessageFormEventAttendance) {
        isEventAttendanceMsgBody = true;
        value = (messageBody as ReadonlyMessageBodyEventAttendance).messageForm
          .value as ReadonlyMessageBodyEventAttendanceValue;
      } else if (isMessageFormHealthDataRequest && msgBodyFlags.isMessageFormHealthDataRequest) {
        value = (messageBody as ReadonlyMessageBodyHealthDataRequest).messageForm.value;
      } else if (isMessageFormHRM && msgBodyFlags.isMessageFormHRM) {
        value = (messageBody as ReadonlyMessageBodyHrm).messageForm.value;
      } else if (isMessageFormJoinGroupInvitation && msgBodyFlags.isMessageFormJoinGroupInvitation) {
        isJoinGroupInvitationMsgBody = true;
        value = [(messageBody as ReadonlyMessageBodyJoinGroupInvitation).messageForm.value, true];
      } else if (isMessageFormReferral && msgBodyFlags.isMessageFormReferral) {
        value = (messageBody as ReadonlyMessageBodyReferral).messageForm.value;
      }
    }

    return [value, isCached, isEventMsgBody, isEventAttendanceMsgBody, isJoinGroupInvitationMsgBody];
  }, [
    isMessageFormDefault,
    isMessageFormEConsult,
    isMessageFormEncounter,
    isMessageFormEvent,
    isMessageFormEventAttendance,
    isMessageFormHRM,
    isMessageFormHealthDataRequest,
    isMessageFormJoinGroupInvitation,
    isMessageFormReferral,
    isValidMessageBodyId,
    messageBody
  ]);

  const serverDateTimeResource = useServerDateTimeResource({ refreshInterval: !isJoinGroupInvitationMsgBody && null });
  const [credentialAuthParams, setCredentialAuthParams] = React.useState<UseCredentialsAuthenticationResourceParams>();
  const credentialAuthResource = useCredentialsAuthenticationResource(credentialAuthParams);

  const isExpired = React.useMemo(() => {
    if (isJoinGroupInvitationMsgBody) {
      try {
        const { expiredAtUtc } = (msgBody as ReadonlyMessageBodyJoinGroupInvitationValue)[0];

        if (Utils.isInteger(expiredAtUtc)) {
          const dtServer = serverDateTimeResource.value();
          if (dtServer.getTime() > expiredAtUtc) {
            return true;
          }
        }

        const authResponse = credentialAuthResource.value();
        return !Utils.isNonArrayObjectLike<SignInSuccessAuthOnly>(authResponse);
      } catch {
        /* ignore */
      }
    }
    return true;
  }, [credentialAuthResource, isJoinGroupInvitationMsgBody, msgBody, serverDateTimeResource]);

  const prevMsgBody = React.useRef<typeof msgBody>();
  React.useEffect(() => {
    if (isCached === true) {
      let body = msgBody;
      if (isEventMsgBody === true || isEventAttendanceMsgBody === true) {
        return setEventObjectId((body as ReadonlyMessageBodyEventValue).eventObjectId);
      }

      setEventObjectId(undefined);
      if (isJoinGroupInvitationMsgBody) {
        const { token } = (body as ReadonlyMessageBodyJoinGroupInvitationValue)[0];
        const credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, { token });
        setCredentialAuthParams({
          credentialHash,
          password: DEFAULT_ACCESS_CODE_JOIN_GROUP_INVITATION,
          username: token
        });

        body = [(msgBody as ReadonlyMessageBodyJoinGroupInvitationValue)[0], isExpired];
      } else {
        setCredentialAuthParams(undefined);
      }

      if (prevMsgBody.current !== body) {
        prevMsgBody.current = body;
        return setResource(Utils.isNotNil(body) ? { value: () => body } : UNRESOLVED_RESOURCE);
      }
    } else {
      prevMsgBody.current = undefined;
      setResource(UNRESOLVED_RESOURCE);
      return void (isValidMessageBodyId && fetchDataObjects({ ids: [messageBodyId] }).catch(throwAsyncError));
    }
  }, [
    fetchDataObjects,
    isCached,
    isEventMsgBody,
    isEventAttendanceMsgBody,
    isExpired,
    isJoinGroupInvitationMsgBody,
    isValidMessageBodyId,
    messageBodyId,
    msgBody,
    throwAsyncError
  ]);

  const prevCalendarEvent = React.useRef<DataObjectCalendarEventValue>();
  const isEventObjectIdValid = DataObjectCalendarEvent.isValidId(eventObjectId);
  const calendarEventObject = dataObjectSelector<DataObjectCalendarEventValue>(
    Utils.numberOrDefault(isEventObjectIdValid && eventObjectId)
  );
  const calendarEvent = DataObjectCache.getValue(calendarEventObject);

  React.useEffect(() => {
    if (!isEventObjectIdValid) return void (prevCalendarEvent.current = undefined);

    if (Utils.isNil(calendarEvent)) {
      prevCalendarEvent.current = undefined;
      setResource(UNRESOLVED_RESOURCE);
      return void fetchDataObjects({ ids: [eventObjectId] }).catch(throwAsyncError);
    }

    if (prevCalendarEvent.current !== calendarEvent) {
      prevCalendarEvent.current = calendarEvent;

      const flags = EventFlags(calendarEvent);
      const value: ReadonlyMessageBodyEventValue = { ...calendarEvent, eventObjectId, flags };
      return setResource({ value: () => value });
    }
  }, [calendarEvent, eventObjectId, fetchDataObjects, isEventObjectIdValid, throwAsyncError]);

  return resource;
}
