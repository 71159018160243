import React from 'react';
import { Context } from './meeting-room.context';
import style from './participant-list.module.css';
import { Participant } from './participant.component';

export interface Props {
  onClickAction?: (actionId: string) => any;
}

class ParticipantListComponent extends React.PureComponent<Props> {
  public static contextType = Context;
  context!: React.ContextType<typeof Context>;

  public render(): React.ReactNode {
    const { onClickAction } = this.props;
    const { mainParticipant, localParticipant, remoteParticipantList } = this.context;

    const isMainParticipantLocal = mainParticipant !== null && mainParticipant === localParticipant;

    return (
      <React.Fragment>
        {mainParticipant !== null && (
          <Participant
            participant={mainParticipant}
            isMainParticipant={true}
            isLocalParticipant={isMainParticipantLocal}
            videoPriority={!isMainParticipantLocal ? 'high' : null}
            videoOnly={isMainParticipantLocal}
            onClickAction={onClickAction}
          />
        )}

        <aside className={style.root}>
          <div styleName="style.scroll-container">
            <div styleName="style.inner-scroll-container">
              {localParticipant !== null && <Participant participant={localParticipant} isLocalParticipant={true} />}

              {remoteParticipantList?.map((remoteParticipant) =>
                remoteParticipant === mainParticipant ? null : (
                  <Participant key={remoteParticipant.sid} participant={remoteParticipant} />
                )
              )}
            </div>
          </div>
        </aside>
      </React.Fragment>
    );
  }
}

export const ParticipantList = ParticipantListComponent;
(ParticipantList as any).displayName = 'ParticipantList';
