import { MessagingActionPayload } from '@sigmail/app-state';
import {
  AppException,
  Constants,
  MessageSenderUser,
  MessagingException,
  ReadonlyMessageBodyHealthDataRequest,
  Utils
} from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  BPReadingFormData as BaseBPReadingFormData,
  CardiacIntakeFormData,
  CopdAssessmentFormData,
  DataObjectMsgBodyValue,
  KCCQFormData,
  UserObjectProfileBasicValue,
  VitalsFormData
} from '@sigmail/objects';
import { AppThunk } from '../../..';
import * as HealthDataConstants from '../../../../app/health-data/constants';
import {
  BPReadingDataUtil,
  CardiacIntakeDataUtil,
  CopdAssessmentDataUtil,
  KCCQDataUtil,
  VitalsDataUtil
} from '../../../../utils/health-data';
import { EMPTY_PLAIN_OBJECT } from '../../../constants';
import { DataObjectCache } from '../../../data-objects-slice/cache';
import { dataObjectByIdSelector } from '../../../selectors/data-object';
import { basicProfileObjectSelector, personNameSelector } from '../../../selectors/user-object';
import { UserObjectCache } from '../../../user-objects-slice/cache';
import { AuthenticatedAction } from '../../authenticated-action';
import { ActionInitParams } from '../../base-action';
import { BaseSubmitHealthDataAction, BaseSubmitHealthDataActionPayload } from './base';
import { SubmitKccqHealthDataAction } from './kccq';

const Logger = getLoggerWithPrefix('Action', 'submitHealthDataAction:');

type BPReadingFormData = BaseBPReadingFormData & { requestTimestamp: number };
type Payload = MessagingActionPayload.SubmitHealthData;

class SubmitHealthDataAction extends AuthenticatedAction<Payload> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params);

    const { recipientList, sourceMessage } = this.payload;

    if (recipientList.length !== 1) {
      throw new MessagingException(`Expected recipient list to be of length 1; was <${recipientList.length}>.`);
    }

    const [recipient] = recipientList;
    if (recipient.entity.type !== 'group') {
      throw new MessagingException(`Expected primary recipient to be of type <group>; was <${recipient.entity.type}>.`);
    }

    if (Utils.isNotNil(sourceMessage)) {
      if (!Utils.isHealthDataRequestMessageForm(sourceMessage.messageForm)) {
        throw new MessagingException(`Expected <sourceMessage> to be of form <${Constants.MessageFormName.HealthDataRequest}>.`);
      }
    }
  }

  /** @override */
  protected async onExecute() {
    const { apiService, dispatch, logger } = this;
    const { folderKey, guestContact, parentFolderKey, recipientList, sourceMessage, t } = this.payload;
    const { currentUser, roleAuthClaim: authState } = this.state;

    let { dataForm } = this.payload;
    if (Utils.isNotNil(sourceMessage)) {
      await this.dispatchFetchObjects({
        authState,
        dataObjects: { ids: [sourceMessage.body] },
        userObjectsByType: [
          { type: process.env.USER_OBJECT_TYPE_HEALTH_DATA, userId: currentUser.id },
          { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId: currentUser.id }
        ]
      });

      const dataObjectSelector = dataObjectByIdSelector(this.getRootState());
      const msgBodyObject = dataObjectSelector<DataObjectMsgBodyValue>(sourceMessage.body);
      const msgBody = DataObjectCache.getValue(msgBodyObject);
      dataForm = (msgBody as ReadonlyMessageBodyHealthDataRequest | undefined)?.messageForm.value.form;

      const basicProfileObject = basicProfileObjectSelector(this.getRootState())(/***/);
      const { role } = UserObjectCache.getValue(basicProfileObject, EMPTY_PLAIN_OBJECT as UserObjectProfileBasicValue);
      if (!Utils.isGuestRole(role)) {
        throw new MessagingException(`Expected current user role to be <${Constants.ROLE_ID_GUEST}>.`);
      }
    }

    if (Utils.isNil(dataForm)) {
      throw new MessagingException('Unable to determine health data form name.');
    }

    const healthData = this.payload[dataForm] as unknown;

    if (
      (dataForm === HealthDataConstants.DataFormNameBPReading && !BPReadingDataUtil.isValid(healthData)) ||
      (dataForm === HealthDataConstants.DataFormNameCardiacIntake && !CardiacIntakeDataUtil.isValid(healthData)) ||
      (dataForm === HealthDataConstants.DataFormNameCopdAssessment && !CopdAssessmentDataUtil.isValid(healthData)) ||
      (dataForm === HealthDataConstants.DataFormNameKCCQ && !KCCQDataUtil.isValid(healthData)) ||
      (dataForm === HealthDataConstants.DataFormNameVitals && !VitalsDataUtil.isValid(healthData))
    ) {
      throw new MessagingException('Invalid payload; health data is either missing or invalid.');
    }

    let onBehalfOf: MessageSenderUser | undefined;
    if (Utils.isNotNil(guestContact)) {
      const guestContactData = await this.getUserObjectValue(basicProfileObjectSelector, {
        fetch: true,
        type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC,
        userId: guestContact.id
      });

      if (Utils.isNil(guestContactData)) {
        throw new AppException(
          Constants.Error.E_DATA_MISSING_OR_INVALID,
          `Failed to fetch basic profile object. (userId=${guestContact.id})`
        );
      }

      onBehalfOf = {
        id: guestContact.id,
        type: guestContact.type,
        ...Utils.pick(guestContactData, Constants.PERSON_NAME_KEY_LIST)
      };
    }

    let actionPayload: BaseSubmitHealthDataActionPayload = {
      dataForm,
      flags: EMPTY_PLAIN_OBJECT as BaseSubmitHealthDataActionPayload['flags'],
      folderKey,
      recipientList,
      messageBody: { data: '' },
      messageFormName: Constants.MessageFormName.Default,
      onBehalfOf,
      parentFolderKey,
      sender: {
        id: currentUser.id,
        type: 'user',
        ...Utils.pick(personNameSelector(this.getRootState())(/***/), Constants.PERSON_NAME_KEY_LIST)
      },
      sensitivity: 'normal',
      sourceMessage,
      subjectLine: 'Questionnaire submission',
      [dataForm]: healthData
    };

    let ActionClass: typeof BaseSubmitHealthDataAction = BaseSubmitHealthDataAction;
    switch (dataForm) {
      case HealthDataConstants.DataFormNameBPReading: {
        const data = new BPReadingDataUtil(healthData as BPReadingFormData).toHtml(t);
        actionPayload = { ...actionPayload, messageBody: { data } };
        break;
      }
      case HealthDataConstants.DataFormNameCardiacIntake: {
        const data = new CardiacIntakeDataUtil(healthData as CardiacIntakeFormData).toHtml(t);
        actionPayload = { ...actionPayload, messageBody: { data } };
        break;
      }
      case HealthDataConstants.DataFormNameCopdAssessment: {
        const data = new CopdAssessmentDataUtil(healthData as CopdAssessmentFormData).toHtml(t);
        actionPayload = { ...actionPayload, messageBody: { data } };
        break;
      }
      case HealthDataConstants.DataFormNameKCCQ: {
        ActionClass = SubmitKccqHealthDataAction;
        const data = new KCCQDataUtil(healthData as KCCQFormData).toHtml(t);
        actionPayload = { ...actionPayload, messageBody: { data } };
        break;
      }
      case HealthDataConstants.DataFormNameVitals: {
        const data = new VitalsDataUtil(healthData as VitalsFormData).toHtml(t);
        actionPayload = { ...actionPayload, messageBody: { data } };
        break;
      }

      default: {
        if (process.env.REACT_APP_ENV === 'local') {
          throw new Error(`Unhandled case - ${dataForm}`);
        }
        return;
      }
    }

    return new ActionClass({
      getState: this.getRootState,
      logger,
      payload: actionPayload,
      apiService,
      dispatch
    }).execute();
  }
}

export const submitHealthDataAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const action = new SubmitHealthDataAction({ apiService, dispatch, getState, logger: Logger, payload });
    return action.execute();
  };
};
