import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { NotificationObject } from '.';
import { DataObjectMsgBody } from '../data-object/msg-body';
import { DataObjectMsgMetadata } from '../data-object/msg-metadata';
import type { NotificationObjectIncomingMessageValue as DV } from '../types';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE;

/** @public */
export class NotificationObjectIncomingMessage extends NotificationObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      DataObjectMsgMetadata.isValidId(dv.header) &&
      DataObjectMsgBody.isValidId(dv.body) &&
      (Utils.isUndefined(dv.messageForm) || Utils.isNonArrayObjectLike(dv.messageForm))
    );
  }
}
