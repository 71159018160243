import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogBillingFile } from '../../constants/action-context';

const i18n: GlobalI18n['dialog']['billingFile'] = {
  // Translators: (HTML) Title of the Billing File dialog
  title: gettext('global', 'title', 'dialogBillingFile', 'safe-html'),
  // Translators: (HTML) Body of the Billing File dialog
  body: gettext('global', 'body', 'dialogBillingFile', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Billing File dialog
      label: gettext('global', 'accept', 'dialogBillingFileActionLabel'),
      context: DialogBillingFile
    },
    reject: {
      // Translators: Label for the Send To DoctorCare action of Billing \
      // File dialog
      label: gettext('global', 'reject', 'dialogBillingFileActionLabel'),
      context: DialogBillingFile
    }
  }
};

export default i18n;
