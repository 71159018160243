import { createSlice } from '@reduxjs/toolkit';
import { StoreStateCaregiver } from '@sigmail/app-state';

export const INITIAL_STATE: StoreStateCaregiver = {
  authClaim: '',
  mode: false
};

const caregiverSlice = createSlice({
  name: 'caregiver',
  initialState: INITIAL_STATE,
  reducers: {
    setCaregiverAuthClaim: (state, { payload }) => ({ ...state, authClaim: payload }),
    setCaregiverMode: (state) => ({ ...state, mode: !state.mode })
  }
});

export const { setCaregiverAuthClaim, setCaregiverMode } = caregiverSlice.actions;
export const { reducer: caregiverReducer } = caregiverSlice;
