import { Chip } from '@material-ui/core';
import { MessagingActionPayload } from '@sigmail/app-state';
import { Constants, Nullable, Utils } from '@sigmail/common';
import { DataObjectMsgMetadataValue } from '@sigmail/objects';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { ISuspenseResource } from 'sigmail';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import { selectMessageAttachmentRights } from '../../../app-state/selectors/auth';
import { FrenchCanada } from '../../../constants/language-codes';
import globalI18n from '../../../i18n/global';
import messagingI18n from '../../../i18n/messaging';
import { detectFileType } from '../../../utils/detect-file-type';
import { readFileAsArrayBuffer } from '../../../utils/read-file-as-array-buffer';
import sharedStyle from '../compose-view-shared.module.css';

export interface UseAttachedDocumentListStateParams extends Pick<WithTranslation, 'i18n' | 't'> {
  readonly className?: Nullable<string>;
  readonly initialValueResource?: ISuspenseResource<{
    initialDocumentList?: DataObjectMsgMetadataValue['documentList'];
  }>;
  readonly rights: ReturnType<typeof selectMessageAttachmentRights>;
  readonly setSnackbarMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export type DocumentListItem = NonNullable<MessagingActionPayload.SendMessage['documentList']>[0];

export interface UseAttachedDocumentListStateResult {
  readonly DocumentListNode: React.ReactNode;
  readonly documentList: ReadonlyArray<DocumentListItem>;
  readonly isAttachInProgress: boolean;
  readonly onAttachDocument: React.ChangeEventHandler<HTMLInputElement>;
}

const { errorMessage: errorMessageI18n } = messagingI18n;

export const ACCEPTABLE_FILE_EXT: ReadonlyArray<string> = ['jpg', 'jpeg', 'pdf', 'png', 'csv', 'txt'];

export const ACCEPTABLE_MIME_LIST: ReadonlyArray<string> = [
  // Constants.MimeType.APNG,
  // Constants.MimeType.BMP,
  // Constants.MimeType.GIF,
  // Constants.MimeType.ICO,
  Constants.MimeType.JPEG,
  // Constants.MimeType.MS_EXCEL_XLS,
  // Constants.MimeType.MS_EXCEL_XLSX,
  // Constants.MimeType.MS_WORD_DOC,
  // Constants.MimeType.MS_WORD_DOCX,
  // Constants.MimeType.OPEN_DOC_SPREADSHEET,
  // Constants.MimeType.OPEN_DOC_TEXT,
  Constants.MimeType.PDF,
  Constants.MimeType.PNG,
  // Constants.MimeType.SVG,
  Constants.MimeType.TEXT_CALENDAR,
  Constants.MimeType.TEXT_CSV,
  Constants.MimeType.TEXT_PLAIN,
  Constants.MimeType.TEXT_VCARD
  // Constants.MimeType.WEBP
];

export async function isAcceptableFile(
  file: File,
  acceptableMimeList?: Nullable<ReadonlyArray<string>>
): Promise<boolean> {
  if (!Utils.arrayOrDefault<string>(acceptableMimeList, ACCEPTABLE_MIME_LIST as Array<string>).includes(file.type)) {
    return false;
  }

  const isTextFile = file.type.startsWith('text/');
  const buffer = await readFileAsArrayBuffer(file.slice(0, 512));
  const detectedFileType = detectFileType(new Uint8Array(buffer));
  return detectedFileType === file.type || (isTextFile && detectedFileType.startsWith('text/'));
}

export const useAttachedDocumentListState = ({
  className: classNameParam,
  initialValueResource,
  i18n: { language: locale },
  rights,
  setSnackbarMessage,
  t
}: UseAttachedDocumentListStateParams): UseAttachedDocumentListStateResult => {
  const [documentList, setDocumentList] = React.useState<ReadonlyArray<DocumentListItem>>(null!);
  const [isAttachInProgress, setAttachInProgress] = React.useState(false);

  React.useEffect(() => {
    if (Utils.isArray(documentList)) return;

    try {
      const { initialDocumentList: list } = initialValueResource!.value();
      setDocumentList(Utils.arrayOrDefault<NonNullable<typeof list>[0]>(list, EMPTY_ARRAY));
    } catch (error) {
      if (error instanceof Promise) return;
      setDocumentList(EMPTY_ARRAY);
    }
  }, [documentList, initialValueResource]);

  const { maxCount, maxPerFileSize, maxTotalSize } = rights;
  const onAttachDocument = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    ({ currentTarget }) => {
      const elFileInput = currentTarget as HTMLInputElement;
      const file = elFileInput && elFileInput.files && elFileInput.files.length === 1 && elFileInput.files[0];
      if (maxCount === 0 || !(file instanceof File) || !Utils.isArray(documentList)) return;

      setAttachInProgress((prevValue) => {
        if (prevValue) return prevValue;

        if (documentList.length >= maxCount) {
          setSnackbarMessage(t(errorMessageI18n.maxAttachmentLimitExceeded, { count: maxCount }));
          return false;
        }

        if (!ACCEPTABLE_MIME_LIST.includes(file.type)) {
          setSnackbarMessage(t(errorMessageI18n.unsupportedFileType));
          return false;
        }

        const fileSize = file.size;
        if (fileSize > maxPerFileSize) {
          setSnackbarMessage(
            t(errorMessageI18n.attachmentSizeLimitExceeded, {
              MAX: Utils.formatBytes(maxPerFileSize, 0, locale === FrenchCanada ? 'fr' : 'en')
            })
          );
          return false;
        }

        const totalFileSize = documentList.reduce((count, doc) => count + doc.size, fileSize);
        if (totalFileSize > maxTotalSize) {
          setSnackbarMessage(
            t(errorMessageI18n.attachmentTotalSizeLimitExceeded, {
              MAX: Utils.formatBytes(maxTotalSize, 0, locale === FrenchCanada ? 'fr' : 'en')
            })
          );
          return false;
        }

        isAcceptableFile(file)
          .then(
            (isAcceptable) => {
              if (isAcceptable) {
                setDocumentList((documentList) => documentList.concat(file));
              } else {
                setSnackbarMessage(t(errorMessageI18n.unsupportedFileType));
              }
            },
            () => setSnackbarMessage(t(globalI18n.errorOccurredTryAgainMessageGeneric))
          )
          .finally(() => setAttachInProgress(false));

        return true;
      });
    },
    [documentList, locale, maxCount, maxPerFileSize, maxTotalSize, setSnackbarMessage, t]
  );

  const onRemoveDocument = React.useCallback(
    (chipIndex: number): void => {
      setDocumentList((prevDocumentList) => {
        const documentList: Array<DocumentListItem> = [];
        for (let index = 0; index < prevDocumentList.length; index++) {
          const doc = prevDocumentList[index];
          if (index === chipIndex) {
            if (doc instanceof File) continue;
            documentList.push({ ...doc, deleted: true });
          } else {
            documentList.push(doc);
          }
        }
        return documentList;
      });
    },
    [setDocumentList]
  );

  let className: string | undefined;
  if (classNameParam !== null) {
    className = Utils.stringOrDefault(classNameParam, sharedStyle['document-list']);
  }

  const DocumentListNode = React.useMemo<React.ReactNode>(() => {
    if (!Utils.isArray(documentList)) return null;

    const count = documentList.reduce((count, doc) => count + Number(doc instanceof File || doc.deleted !== true), 0);
    if (count === 0) return null;

    return (
      <div className={className}>
        {documentList.map((doc, index) => {
          if (!(doc instanceof File) && doc.deleted === true) return null;

          return (
            <Chip
              key={`__${index}_${doc.name}__`}
              variant="outlined"
              color="secondary"
              label={doc.name}
              onDelete={() => onRemoveDocument(index)}
            />
          );
        })}
      </div>
    );
  }, [className, documentList, onRemoveDocument]);

  return React.useMemo<UseAttachedDocumentListStateResult>(
    () => ({ DocumentListNode, documentList, isAttachInProgress, onAttachDocument }),
    [DocumentListNode, documentList, isAttachInProgress, onAttachDocument]
  );
};
