import { IconButton } from '@material-ui/core';
import VisibilityIconOff from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIconOn from '@material-ui/icons/VisibilityOutlined';
import React from 'react';

/** `IconButtonTogglePasswordVisibility` component's props. */
export interface Props {
  /** Label (available to screen readers) for the icon. */
  ariaLabel: string;

  /**
   * `true` to show the visibility on icon; `false` to show the visibility
   * off icon.
   */
  visibility: boolean;

  /** Event handler to be invoked when the icon is clicked. */
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * {@link IconButton} component to render as an endAdornment for Password input.
 */
export const IconButtonTogglePasswordVisibility: React.FC<Props> = React.memo(({ ariaLabel, visibility, onClick }) => (
  <IconButton
    aria-label={ariaLabel}
    onClick={onClick}
    disableRipple={true}
    disableFocusRipple={true}
    disableTouchRipple={true}
  >
    {visibility ? <VisibilityIconOn color="disabled" /> : <VisibilityIconOff color="disabled" />}
  </IconButton>
));
