import {
  FormControl,
  FormControlClassKey,
  FormControlProps,
  FormHelperText,
  IconButton,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps
} from '@material-ui/core';
import { Close as CloseIcon, Search as SearchIcon } from '@material-ui/icons';
import { PartialRecord, Utils } from '@sigmail/common';
import { SearchPatientListFormI18n } from '@sigmail/i18n';
import clsx from 'clsx';
import React from 'react';
import MaskedInput from 'react-text-mask';
import { DeepPartial } from 'redux';
import { MutableRef } from 'sigmail';
import { MaskedFieldProps } from '.';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import {
  SearchPatientListResourceParams,
  SearchPatientListResourceValue,
  SearchPatientRecord,
  useSearchPatientListResource
} from '../../../app-state/hooks';
import { useNextId } from '../../../hooks';
import { useTranslation } from '../../../i18n';
import { I18N_NS_GLOBAL } from '../../../i18n/config/namespace-identifiers';
import globalI18n from '../../../i18n/global';
import { BrowserWindow } from '../browser-window.component';
import { CircularProgress } from '../circular-progress.component';
import style from './search-patient-list-field.module.css';

export interface Props
  extends Omit<React.PropsWithoutRef<FormControlProps>, 'classes' | 'onChange'>,
    Partial<Record<`disable${Capitalize<keyof SearchPatientListResourceParams>}`, boolean>> {
  children?: never;
  classes?: PartialRecord<FormControlClassKey | 'expandedInput' | 'iconLabel' | 'input' | 'inputLabel', string>;
  I18N?: DeepPartial<SearchPatientListFormI18n>;
  initialValue?: string;
  MaskedInputProps?: MaskedFieldProps;
  minQueryLength?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onSearchClear?: () => void;
  onSearchEnd?: (data: Pick<SearchPatientListResourceValue, 'list'>) => void;
  onSearchStart?: () => void;
}

interface State {
  readonly authURL: string;
  readonly expanded: boolean;
  readonly recordList: ReadonlyArray<SearchPatientRecord> | null;
  readonly query: string;
  readonly value: string;
}

interface Refs extends Pick<Props, `disable${'Accuro' | 'Oscar'}EMR`> {
  authURL: State['authURL'];
  recordList: State['recordList'];
}

const INITIAL_STATE: State = {
  authURL: '',
  expanded: false,
  recordList: EMPTY_ARRAY,
  query: '',
  value: ''
};

interface RootProps extends Omit<Props, 'I18N' | 'minQueryLength'> {
  innerRef?: MutableRef<HTMLDivElement>;
  I18N: SearchPatientListFormI18n;
  minQueryLength: number;
}

const DEFAULT_CLASSES: Props['classes'] = {
  expandedInput: clsx(style.input, style.expanded),
  iconLabel: style['icon-label'],
  input: style.input,
  inputLabel: style.label
};

const FieldSearchPatientListRoot: React.FC<RootProps> = ({
  children,
  classes: classesFromProps,
  disableAccuroEMR,
  disableCollection,
  disableOscarEMR,
  innerRef,
  I18N,
  initialValue,
  MaskedInputProps,
  minQueryLength,
  onChange: changeHandler,
  onSearchClear,
  onSearchEnd,
  onSearchStart,
  ...rootProps
}) => {
  const {
    iconLabel: iconLabelClassName,
    input: inputClassName,
    expandedInput: expandedInputClassName,
    inputLabel: inputLabelClassName,
    ...classes
  } = Utils.defaults({}, classesFromProps as unknown, DEFAULT_CLASSES);
  const isMaskedInput =
    Utils.isNonArrayObjectLike<typeof MaskedInputProps>(MaskedInputProps) && MaskedInputProps.mask !== false;

  const fieldId = useNextId('field-search-', 16);
  const progressId = useNextId(`${fieldId}-progress-`, 16);
  const fieldRef = React.useRef<HTMLInputElement | null>(null);
  const refs = React.useRef<Refs>({ authURL: INITIAL_STATE.authURL, recordList: INITIAL_STATE.recordList });
  const maskedInputRefCallback = React.useRef<React.RefCallback<HTMLElement>>();
  initialValue = Utils.trimOrDefault(initialValue, INITIAL_STATE.value);
  const [{ authURL, expanded: isExpanded, recordList, query, value }, setState] = React.useState<State>({
    ...INITIAL_STATE,
    expanded: initialValue.length > 0,
    value: initialValue
  });
  const isQueryEmpty = query.length === 0;

  const valueSyncDone = React.useRef(false);
  React.useEffect(() => {
    if (valueSyncDone.current) return;
    valueSyncDone.current = true;

    const { current: instance } = fieldRef;
    if (Utils.isNil(instance)) return;

    setState((state) => {
      if (instance.value !== state.value) {
        return { ...state, value: instance.value };
      }
      return state;
    });
  }, []);

  const listResource = useSearchPatientListResource(
    React.useMemo<Parameters<typeof useSearchPatientListResource>[0]>(() => {
      return (
        !isQueryEmpty && {
          accuroEMR: disableAccuroEMR !== true && { healthPlanNumber: query },
          collection: disableCollection !== true && { healthPlanNumber: query },
          oscarEMR: disableOscarEMR !== true && { healthPlanNumber: query }
        }
      );
    }, [disableAccuroEMR, disableCollection, disableOscarEMR, isQueryEmpty, query])
  );

  React.useEffect(() => {
    const searchCompleted = refs.current.recordList === null && recordList !== null;

    if (refs.current.recordList !== recordList) {
      refs.current.recordList = recordList;
      if (searchCompleted && typeof onSearchEnd === 'function') {
        onSearchEnd({ list: recordList });
      }
    }
  }, [onSearchEnd, recordList]);

  React.useEffect(() => {
    const isBrowserWindowOpen = authURL.length > 0;
    const prevAuthURL = Utils.trimOrDefault(refs.current.authURL);
    const disableAccuroEMRChanged = refs.current.disableAccuroEMR !== disableAccuroEMR;
    const disableOscarEMRChanged = refs.current.disableOscarEMR !== disableOscarEMR;
    refs.current = { ...refs.current, authURL, disableAccuroEMR, disableOscarEMR };

    if (isBrowserWindowOpen) {
      if (
        (disableAccuroEMRChanged && disableAccuroEMR === true && prevAuthURL.length > 0) ||
        (disableOscarEMRChanged && disableOscarEMR === true && prevAuthURL.length > 0)
      ) {
        return setState(({ authURL, ...state }) => ({ ...INITIAL_STATE, ...state }));
      }
      return;
    }

    try {
      const { accuroEMR, list, oscarEMR } = listResource.value();

      if (disableAccuroEMR !== true) {
        const { requestKey: authRequestKey, recheckAuth, status: isAuthenticated, url } = accuroEMR.auth;

        const authWindowClosed = !isBrowserWindowOpen && prevAuthURL.length > 0;
        if (authWindowClosed) {
          recheckAuth();
          return;
        } else if (!isAuthenticated && url.length > 0 && authRequestKey === 1) {
          setState((state) => ({ ...state, authURL: url }));
          return;
        }
      }

      if (disableOscarEMR !== true) {
        const { requestKey: authRequestKey, recheckAuth, status: isAuthenticated, url } = oscarEMR.auth;

        const authWindowClosed = !isBrowserWindowOpen && prevAuthURL.length > 0;
        if (authWindowClosed) {
          recheckAuth();
          return;
        } else if (!isAuthenticated && url.length > 0 && authRequestKey === 1) {
          setState((state) => ({ ...state, authURL: url }));
          return;
        }
      }

      setState((state) => ({ ...state, recordList: list }));
    } catch (error) {
      if (!(error instanceof Promise)) {
        setState((state) => ({ ...state, recordList: EMPTY_ARRAY }));
      }
    }
  }, [authURL, disableAccuroEMR, disableOscarEMR, listResource]);

  const clearSearch = React.useCallback(() => {
    setState(INITIAL_STATE);
    if (typeof onSearchClear === 'function') {
      onSearchClear();
    }
  }, [onSearchClear]);

  const closeBrowserWindow = React.useCallback(
    () => setState(({ authURL, ...state }) => ({ ...INITIAL_STATE, ...state })),
    []
  );

  const onBlur = React.useCallback<React.FocusEventHandler<HTMLInputElement>>(() => {
    if (Utils.trimOrDefault(value).length === 0) {
      setState(({ recordList }) => ({ ...INITIAL_STATE, recordList }));
    }
  }, [value]);

  const onChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      if (typeof changeHandler === 'function') {
        changeHandler(event);
      }

      const { value } = event.target;
      setState(({ expanded, ...state }) => {
        if (state.query.length > 0) {
          state = INITIAL_STATE;
        }
        return { ...state, expanded, value };
      });
    },
    [changeHandler]
  );

  const onFocus = React.useCallback<React.FocusEventHandler<HTMLInputElement>>(
    () => setState((state) => (state.expanded ? state : { ...state, expanded: true })),
    []
  );

  const startSearch = React.useCallback(
    (query: string): void => {
      refs.current.recordList = null;
      setState((state) => ({ ...state, recordList: null, query }));
      if (typeof onSearchStart === 'function') {
        onSearchStart();
      }
    },
    [onSearchStart]
  );

  const onClickAdornment = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const closeIconClicked = !isQueryEmpty;
      if (closeIconClicked) {
        clearSearch();
      } else if (isExpanded) {
        const query = Utils.trimOrDefault(value);
        if (query.length >= minQueryLength) {
          startSearch(query);
        }
      } else {
        setState((state) => ({ ...state, expanded: true }));
      }

      window.setTimeout(() => fieldRef.current?.focus(), 100);
    },
    [clearSearch, isExpanded, isQueryEmpty, minQueryLength, startSearch, value]
  );

  const iconLabel = I18N.fieldsetSearch.formField.search.iconLabel[isQueryEmpty ? 'search' : 'clear'];
  const { label: inputLabel } = I18N.fieldsetSearch.formField.search;
  const { placeholder } = I18N.fieldsetSearch.formField.search;
  const noResultsMessage = I18N.fieldsetSearch.formField.search.error!.noResults;

  const setFieldRef = React.useCallback<React.RefCallback<HTMLInputElement>>((instance) => {
    fieldRef.current = instance;

    if (typeof maskedInputRefCallback.current === 'function') {
      maskedInputRefCallback.current(instance);
    }
  }, []);

  const renderMaskedInput = React.useCallback(
    (refCallback: (inputElement: HTMLElement) => void, { inputRef: _omitted$1, ...props }: any) => {
      maskedInputRefCallback.current = refCallback;
      return <input ref={setFieldRef} {...props} />;
    },
    [setFieldRef]
  );

  const InputProps = React.useMemo<OutlinedInputProps | undefined>(() => {
    if (!isMaskedInput) {
      maskedInputRefCallback.current = undefined;
      return undefined;
    }

    return {
      inputComponent: MaskedInput,
      inputProps: { ...MaskedInputProps, render: renderMaskedInput }
    };
  }, [MaskedInputProps, isMaskedInput, renderMaskedInput]);

  return (
    <React.Fragment>
      <FormControl classes={classes} ref={innerRef} {...rootProps}>
        <InputLabel classes={{ root: inputLabelClassName }} htmlFor={fieldId}>
          {inputLabel}
        </InputLabel>

        <OutlinedInput
          aria-busy={recordList === null}
          aria-describedby={recordList === null ? progressId : `${fieldId}-helper-text`}
          autoComplete="off"
          classes={{ root: clsx(!isExpanded && inputClassName, isExpanded && expandedInputClassName) }}
          endAdornment={
            recordList === null ? (
              <CircularProgress id={progressId} size={12} />
            ) : (
              Utils.trimOrDefault(value).length >= minQueryLength && (
                <IconButton
                  classes={{ label: iconLabelClassName }}
                  disableTouchRipple
                  onClick={onClickAdornment}
                  size="small"
                  title={iconLabel}
                >
                  <span>{iconLabel}</span>
                  {isQueryEmpty ? <SearchIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
                </IconButton>
              )
            )
          }
          id={fieldId}
          inputMode="search"
          inputRef={setFieldRef}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder!.length > 0 ? placeholder : undefined}
          readOnly={recordList === null}
          value={value}
          {...InputProps}
        />

        {!isQueryEmpty && recordList?.length === 0 && (
          <FormHelperText id={`${fieldId}-helper-text`} style={{ margin: '4px 0 0' }} variant="outlined">
            {noResultsMessage}
          </FormHelperText>
        )}
      </FormControl>

      {authURL.length > 0 && (
        <BrowserWindow
          innerHeight={640}
          innerWidth={480}
          onBlock={closeBrowserWindow}
          onClose={closeBrowserWindow}
          popup
          url={authURL}
        />
      )}
    </React.Fragment>
  );
};

const { search: fieldI18n } = globalI18n.form.searchPatientList.fieldsetSearch.formField;

export const FieldSearchPatientList = React.forwardRef<HTMLDivElement, Props>(
  ({ I18N: i18n, minQueryLength, ...props }, ref) => {
    const { t } = useTranslation([I18N_NS_GLOBAL]);
    const disableAccuroEMR = props.disableAccuroEMR === true;
    const disableCollection = props.disableCollection === true;
    const disableOscarEMR = !disableAccuroEMR || props.disableOscarEMR === true;
    const i18nProp = i18n?.fieldsetSearch?.formField?.search;

    const I18N = React.useMemo<RootProps['I18N']>(() => {
      return {
        fieldsetSearch: {
          formField: {
            search: {
              error: {
                noResults: t(Utils.stringOrDefault(i18nProp?.error?.noResults, fieldI18n.error!.noResults))
              },
              iconLabel: {
                clear: t(Utils.stringOrDefault(i18nProp?.iconLabel?.clear, fieldI18n.iconLabel.clear)),
                search: t(Utils.stringOrDefault(i18nProp?.iconLabel?.search, fieldI18n.iconLabel.search))
              },
              label: t(Utils.stringOrDefault(i18nProp?.label, fieldI18n.label)),
              placeholder: t(Utils.stringOrDefault(i18nProp?.placeholder, fieldI18n.placeholder))
            }
          }
        }
      };
    }, [i18nProp, t]);

    return disableAccuroEMR && disableCollection && disableOscarEMR ? null : (
      <FieldSearchPatientListRoot
        disableAccuroEMR={disableAccuroEMR}
        disableCollection={disableCollection}
        disableOscarEMR={disableOscarEMR}
        innerRef={ref}
        I18N={I18N}
        minQueryLength={Math.min(Math.max(Utils.numberOrDefault(minQueryLength), 0), Number.MAX_SAFE_INTEGER)}
        {...props}
      />
    );
  }
);

FieldSearchPatientList.displayName = 'FieldSearchPatientList';

FieldSearchPatientList.defaultProps = {
  minQueryLength: 0,
  size: 'small',
  variant: 'outlined'
};
