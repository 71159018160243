import { ApiActionPayload } from '@sigmail/app-state';
import {
  AppException,
  AppUser,
  AppUserGroup,
  Constants,
  SigmailClientId,
  SigmailGroupId,
  SigmailObjectId,
  SigmailObjectTypeCode,
  SigmailUserId,
  Utils
} from '@sigmail/common';
import { IDataObject, IUserObject, ValueFormatVersion } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { NIL as NIL_UUID } from 'uuid';
import { AuthenticationData } from '../../core/authentication-data';
import { DataObjectCache } from '../data-objects-slice/cache';
import { RootState } from '../root-reducer';
import { dataObjectByIdSelector } from '../selectors/data-object';
import { privateProfileObjectSelector as userPrivateProfileObjectSelector } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';
import { BaseAction, BaseActionState, FetchObjectsRequestData, FetchObjectsResponseData } from './base-action';
// import { batchQueryDataAction } from './batch-query-data-action';
import { batchQuerySuccessAction } from './batch-query-success-action';
import { fetchDataObjectsAction } from './fetch-data-objects-action';
import { fetchUserObjectsAction } from './fetch-user-objects-action';

export type AuthenticatedActionState = BaseActionState;

type GetDataObjectOptions = Omit<ApiActionPayload.FetchDataObjects, 'cache' | 'objectId'>;

interface GetUserObjectOptions extends Omit<ApiActionPayload.FetchUserObjects, 'cache' | 'objectByType'> {
  type?: SigmailObjectTypeCode;
  userId?: SigmailClientId | SigmailGroupId | SigmailUserId;
}

export abstract class AuthenticatedAction<P, S extends AuthenticatedActionState = AuthenticatedActionState, R = void> extends BaseAction<
  P,
  S,
  R
> {
  /** @override */
  protected get accessToken() {
    const token = super.accessToken;
    if (!Utils.isValidJwtToken(token, 'bearer')) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Access token is either missing or invalid.');
    }
    return token;
  }

  /** @override */
  protected get activeGroupId() {
    const id = super.activeGroupId;
    if (!AppUserGroup.isValidId(id)) {
      throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID, 'Active group ID is either missing or invalid.');
    }
    return id;
  }

  /** @override */
  protected get auditId() {
    const id = super.auditId;
    if (!AppUser.isValidId(id)) {
      throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID, 'Audit ID is either missing or invalid.');
    }
    return id;
  }

  /** @override */
  protected get authState() {
    const claim = super.authState;
    if (!AuthenticationData.isValidAuthClaim(claim)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Role authorization claim is either missing or invalid.');
    }
    return claim;
  }

  /** @override */
  protected get clientId() {
    const id = super.clientId;
    if (!AppUser.isValidId(id)) {
      throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID, 'Client ID is either missing or invalid.');
    }
    return id;
  }

  /** @override */
  protected get currentUser() {
    const user = super.currentUser;
    if (Utils.isNil(user)) {
      throw new AppException(Constants.Error.E_AUTH_FAIL, 'No user is currently logged-in; or user data is either missing or invalid.');
    }
    return user;
  }

  /** @override */
  protected get ownerId() {
    const id = super.ownerId;
    if (!AppUser.isValidId(id)) {
      throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID, 'Owner ID is either missing or invalid.');
    }
    return id;
  }

  protected get sessionId() {
    const id = super.sessionId;
    if (id === NIL_UUID) {
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Session ID is invalid.');
    }
    return id;
  }

  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();

    await this.getUserObject(userPrivateProfileObjectSelector, { type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE });
    this.state.auditId = this.auditId;
    this.state.clientId = this.clientId;
    this.state.ownerId = this.ownerId;

    return result;
  }

  // protected dispatchBatchQueryData(payload: Omit<ApiActionPayload.BatchQueryData, 'accessToken'>): Promise<Api.BatchQueryResponseData> {
  //   return this.dispatch(batchQueryDataAction({ accessToken: this.state.accessToken, ...payload }));
  // }

  protected dispatchFetchObjects(request: FetchObjectsRequestData): Promise<FetchObjectsResponseData> {
    const cache = arguments.length < 2 || arguments[1];

    return this.fetchObjects(
      this.state.accessToken,
      request,
      // @ts-ignore - this argument is not exposed in function's signature
      cache
    );
  }

  protected dispatchBatchQueryDataSuccess(payload: ApiActionPayload.BatchQueryDataSuccess): Promise<void> {
    return this.dispatch(batchQuerySuccessAction(payload));
  }

  protected dispatchBatchUpdateData(mutations: ApiActionPayload.BatchUpdateData['mutations']): Promise<Api.BatchUpdateResponseData> {
    return this.batchUpdateData(this.state.accessToken, mutations);
  }

  protected dispatchFetchServerDateAndTime(): Promise<Date> {
    return this.fetchServerDateAndTime(this.state.accessToken, this.state.roleAuthClaim);
  }

  protected dispatchFetchIds(count: number): Promise<Generator<number, number>> {
    return this.fetchIds(this.state.accessToken, count);
  }

  protected dispatchFetchIdsByUsage(request: Api.GetIdsRequestData): Promise<Api.GetIdsResponseData> {
    return this.fetchIdsByUsage(this.state.accessToken, request);
  }

  protected dispatchEnterState(request: Api.EnterStateRequestData): Promise<Api.EnterStateResponseData> {
    return this.enterState(this.state.accessToken, request);
  }

  protected async getDataObject<DV extends ValueFormatVersion>(
    objectId: SigmailObjectId,
    options?: GetDataObjectOptions
  ): Promise<IDataObject<DV> | undefined> {
    await this.dispatch(fetchDataObjectsAction({ logger: this.logger, ...options, objectId }));

    const dataObjectSelector = dataObjectByIdSelector(this.getRootState());
    return dataObjectSelector<DV>(objectId);
  }

  protected async getDataObjectValue<DV extends ValueFormatVersion>(
    objectId: SigmailObjectId,
    options?: Parameters<typeof this.getDataObject>[1]
  ): Promise<DV | undefined> {
    const dataObject = await this.getDataObject<DV>(objectId, options);
    return DataObjectCache.getValue<IDataObject<DV>>(dataObject);
  }

  protected async getUserObject<DV extends ValueFormatVersion>(
    selector: (state: RootState) => (userId?: GetUserObjectOptions['userId']) => IUserObject<DV> | undefined,
    options?: GetUserObjectOptions
  ): Promise<ReturnType<ReturnType<typeof selector>>> {
    let fetch: GetUserObjectOptions['fetch'] = 'cacheMiss';
    let userId: NonNullable<GetUserObjectOptions['userId']> = this.state.currentUser.id;
    let type: SigmailObjectTypeCode | undefined;

    if (Utils.isNotNil(options)) {
      const { type: objType, userId: objUserId, ...opts } = options;
      if (typeof options.fetch === 'boolean') fetch = options.fetch;
      if (Utils.isInteger(objType)) type = objType;
      if (Utils.isInteger(objUserId)) userId = objUserId;
      options = opts;
    }

    let userObject: IUserObject<DV> | undefined;
    if (fetch !== true) {
      userObject = selector(this.getRootState())(userId);
      if (Utils.isNotNil(userObject)) return userObject;
    }

    if (fetch !== false) {
      if (Utils.isInteger(type)) {
        await this.dispatch(fetchUserObjectsAction({ logger: this.logger, ...options, objectByType: [[{ type, userId }, true]] }));
        userObject = selector(this.getRootState())(userId);
      }
    }

    return userObject;
  }

  protected async getUserObjectValue<DV extends ValueFormatVersion>(
    selector: (state: RootState) => (userId?: GetUserObjectOptions['userId']) => IUserObject<DV> | undefined,
    options?: Parameters<typeof this.getUserObject>[1]
  ): Promise<DV | undefined> {
    const userObject = await this.getUserObject<DV>(selector, options);
    return UserObjectCache.getValue<IUserObject<DV>>(userObject);
  }
}
