import { Snackbar } from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import { Alert } from '@material-ui/lab';
import { Nullable, SigmailObjectId, Utils } from '@sigmail/common';
import { differenceInMinutes, format, set } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { recordCalendarEventAttendanceAction } from '../../../app-state/actions/scheduling/record-calendar-event-attendance-action';
import { useAppState, useInterval } from '../../../app-state/hooks';
import {
  closeReminderNotification,
  dismissReminderNotification,
  processReminderNotification
} from '../../../app-state/reminder-notification-slice';
import {
  activeReminderNotificationSelector,
  eventOnGoingReminderNotificationSelector
} from '../../../app-state/selectors/reminder-notification';
import { SET_MILLISECONDS_SECONDS_TO_NULL } from '../../../constants';
import { useTranslation } from '../../../i18n';
import { I18N_NS_SCHEDULING } from '../../../i18n/config/namespace-identifiers';
import schedulingI18n from '../../../i18n/scheduling';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { MeetingRoomContextProvider } from '../meeting-room-dialog/meeting-room-context-provider.component';
import { MeetingRoomDialog } from '../meeting-room-dialog/meeting-room-dialog.component';
import { MeetingRoomErrorSnackbar } from '../meeting-room-dialog/meeting-room-error-snackbar.component';
import style from './reminder-notification.module.css';

const { reminderNotification: i18n } = schedulingI18n;

const OwnScheduleReminderNotificationComponent = React.forwardRef(() => {
  const { t } = useTranslation([I18N_NS_SCHEDULING]);
  const [, appDispatch] = useAppState();
  const [meetingRoomOpen, setMeetingRoomOpen] = React.useState(false);
  const reminderActive = useSelector(activeReminderNotificationSelector);
  const isEventOnGoing = useSelector(eventOnGoingReminderNotificationSelector);

  const prevEventObjectId = React.useRef<Nullable<SigmailObjectId>>(null);

  useInterval(
    React.useCallback(() => appDispatch(processReminderNotification()), [appDispatch]),
    15000 // 15 seconds
  );

  const toggleMeetingRoom = React.useCallback(() => setMeetingRoomOpen((prev) => !prev), []);
  const onReminderClose = React.useCallback(() => appDispatch(closeReminderNotification()), [appDispatch]);
  const onReminderDismissed = React.useCallback(() => appDispatch(dismissReminderNotification()), [appDispatch]);
  const onReminderJoin = React.useCallback(() => {
    prevEventObjectId.current = reminderActive!.eventObjectId;

    toggleMeetingRoom();
    onReminderDismissed();
  }, [onReminderDismissed, reminderActive, toggleMeetingRoom]);

  const onMeetingRoomConnect = React.useCallback(() => {
    void appDispatch(
      recordCalendarEventAttendanceAction({ action: 'connect', eventObjectId: prevEventObjectId.current! })
    ).catch(Utils.noop);
  }, [appDispatch]);

  const onMeetingRoomDisconnect = React.useCallback(() => {
    void appDispatch(
      recordCalendarEventAttendanceAction({ action: 'disconnect', eventObjectId: prevEventObjectId.current! })
    ).catch(Utils.noop);

    prevEventObjectId.current = null;
    toggleMeetingRoom();
  }, [appDispatch, toggleMeetingRoom]);

  const snackbarMessage = React.useMemo(() => {
    if (Utils.isNil(reminderActive)) return null;

    const diffInTime = differenceInMinutes(reminderActive.start, set(Date.now(), SET_MILLISECONDS_SECONDS_TO_NULL));

    let contentI18n = i18n.message;
    if (diffInTime < 1) contentI18n = i18n.messageBelow1Min;
    if (diffInTime < 0) contentI18n = i18n.messageBelow0Min;

    return t(contentI18n, {
      count: diffInTime,
      TIME: format(reminderActive.start, 'H:mm'),
      TITLE: reminderActive.title
    });
  }, [reminderActive, t]);

  const reminderDismissActionLabel = resolveActionLabel(i18n.action.dismiss);
  const reminderJoinActionLabel = resolveActionLabel(i18n.action.join);

  return (
    <React.Fragment>
      <Snackbar
        autoHideDuration={30000}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        classes={{ root: style.root }}
        ClickAwayListenerProps={{ onClickAway: () => null }}
        open={Utils.isNotNil(reminderActive)}
        onClose={onReminderClose}
      >
        <Alert icon={<AlarmIcon />} onClose={onReminderClose}>
          <p>{snackbarMessage}</p>

          <div className={style['action']}>
            {!isEventOnGoing && (
              <button className={style['btn-submit']} onClick={onReminderJoin}>
                {t(reminderJoinActionLabel)}
              </button>
            )}

            <button className={style['btn-cancel']} onClick={onReminderDismissed}>
              {t(reminderDismissActionLabel)}
            </button>
          </div>
        </Alert>
      </Snackbar>

      <MeetingRoomContextProvider
        eventObjectId={prevEventObjectId.current!}
        onConnect={onMeetingRoomConnect}
        onDisconnect={onMeetingRoomDisconnect}
      >
        <MeetingRoomErrorSnackbar onError={toggleMeetingRoom}>
          <MeetingRoomDialog open={meetingRoomOpen} />
        </MeetingRoomErrorSnackbar>
      </MeetingRoomContextProvider>
    </React.Fragment>
  );
});

export const OwnScheduleReminderNotification = React.memo(OwnScheduleReminderNotificationComponent);
OwnScheduleReminderNotification.displayName = 'OwnScheduleReminderNotification';
