import { Utils } from '@sigmail/common';
import React from 'react';

export const useInterval = (callback: (...args: any[]) => any, delay: number | null): void => {
  const savedCallback = React.useRef<typeof callback>(Utils.noop);

  React.useEffect(() => {
    savedCallback.current = typeof callback === 'function' ? callback : Utils.noop;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      const { current: handler } = savedCallback;
      handler();
    }

    if (delay !== null) {
      let id = window.setInterval(tick, delay);
      return () => window.clearInterval(id);
    }
  }, [delay]);
};
