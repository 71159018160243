import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AppThunk } from '../..';

export const cancelNotificationAction = ({ accessToken, ...payload }: ApiActionPayload.CancelNotification): AppThunk<Promise<void>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'cancelNotificationAction:');

    Logger.info('== BEGIN ==');
    try {
      const apiAccessToken = Utils.isString(accessToken) ? accessToken : getState().auth.accessToken;
      await apiService.cancelNotification(apiAccessToken, payload);
    } finally {
      Logger.info('== END ==');
    }
  };
};
