import { TextField, TextFieldProps } from '@material-ui/core';
import { FieldState } from 'final-form';
import React from 'react';
import Popover, { PopoverProps } from 'react-popover';
import MaskedInput from 'react-text-mask';
import {
  FaxNumber as FaxNumberMask,
  PhoneNumber as PhoneNumberMask,
  PostalCode as PostalCodeMask
} from '../../../constants/form-input-mask';
import * as Utils from './utils';

export type PredefinedInputMask = 'faxNumber' | 'phoneNumber' | 'postalCode';
export type InputMask = false | Array<string | RegExp>;

export interface MaskedFieldProps {
  mask?: PredefinedInputMask | InputMask | ((value: string) => InputMask);
  guide?: boolean;
  placeholderChar?: string;
  keepCharPositions?: boolean;
  pipe?: (
    conformedValue: string,
    config: MaskedFieldProps
  ) => false | string | { value: string; indexesOfPipedChars: number[] };
  showMask?: boolean;
}

export interface FieldProps extends Omit<TextFieldProps, 'defaultValue' | 'innerRef' | 'ref' | 'value'> {
  inputState: FieldState<any>;
  innerRef?: React.Ref<HTMLDivElement> | undefined;
  maskedInputProps?: MaskedFieldProps | undefined;
  popoverProps?: PopoverProps | undefined;
}

interface State {
  isMaskedInput: boolean;
}

class FieldComponent extends React.PureComponent<FieldProps, State> {
  private maskedInputChangeHandler: FieldProps['onChange'] = undefined;
  private maskedInputRefCallback: React.RefCallback<HTMLElement> | undefined = undefined;
  private inputElementRef: any = undefined;

  public static getDerivedStateFromProps(nextProps: Readonly<FieldProps>, prevState: State): Partial<State> | null {
    const { select, multiline, maskedInputProps } = nextProps;

    return {
      isMaskedInput:
        select !== true &&
        multiline !== true &&
        Utils.isNonArrayObjectLike(maskedInputProps) &&
        maskedInputProps.mask !== false
    };
  }

  public constructor(props: FieldProps) {
    super(props);

    this.state = {
      isMaskedInput: false
    };

    this.renderMaskedInput = this.renderMaskedInput.bind(this);
    this.onChange = this.onChange.bind(this);
    this.refCallbackHandler = this.refCallbackHandler.bind(this);
  }

  public componentDidUpdate(_: Readonly<FieldProps>, prevState: Readonly<State>): void {
    if (!this.state.isMaskedInput && prevState.isMaskedInput) {
      this.maskedInputChangeHandler = undefined;
      this.maskedInputRefCallback = undefined;
      this.inputElementRef = undefined;
    }
  }

  public render(): React.ReactNode {
    const { popoverProps } = this.props;

    const childNode = this.renderTextField();
    return Utils.isNonArrayObjectLike(popoverProps) ? <Popover {...popoverProps}>{childNode}</Popover> : childNode;
  }

  protected renderTextField(): React.ReactElement {
    const { inputState, maskedInputProps, popoverProps, innerRef, onChange, ...props } = this.props;
    const { isMaskedInput } = this.state;

    let { InputProps = {}, error, helperText } = props;
    const hasErrorMessage = Utils.isNotNil(inputState.error);
    error = error !== false && (error === true || hasErrorMessage);
    if (!Utils.has(props, 'helperText') && hasErrorMessage) helperText = inputState.error;

    if (isMaskedInput) {
      let { mask } = maskedInputProps!;
      switch (mask) {
        case 'faxNumber':
          mask = FaxNumberMask;
          break;
        case 'phoneNumber':
          mask = PhoneNumberMask;
          break;
        case 'postalCode':
          mask = PostalCodeMask;
          break;
        default:
          break;
      }

      InputProps = {
        ...InputProps,
        inputComponent: MaskedInput,
        inputProps: {
          ...InputProps.inputProps,
          ...maskedInputProps,
          mask,
          render: this.renderMaskedInput
        }
      };
    }

    const { InputLabelProps = {} } = props;
    if (typeof InputLabelProps.shrink === 'undefined') {
      const hasNonEmptyValue = Utils.isString(inputState.value) && inputState.value.length > 0;
      const hasNonEmptyPlaceholder = Utils.isString(props.placeholder) && props.placeholder.trim().length > 0;
      if (hasNonEmptyValue || hasNonEmptyPlaceholder) {
        InputLabelProps.shrink = true;
      }
    }

    return (
      <TextField
        error={error}
        name={inputState.name}
        onBlur={inputState.blur}
        onFocus={inputState.focus}
        ref={innerRef}
        {...props}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        helperText={helperText}
        onChange={isMaskedInput ? Utils.noop : this.onChange}
        value={inputState.value}
      />
    );
  }

  protected renderMaskedInput(ref: React.RefCallback<HTMLElement>, { inputRef, ...props }: any): React.ReactNode {
    this.maskedInputChangeHandler = props.onChange;
    this.maskedInputRefCallback = ref;
    this.inputElementRef = inputRef;

    return <input ref={this.refCallbackHandler} {...props} onChange={this.onChange} />;
  }

  protected onChange(event: React.ChangeEvent<any>): void {
    const { onChange: changeEventHandler, inputState } = this.props;
    const { isMaskedInput } = this.state;

    let isDefaultPrevented = false;
    if (typeof changeEventHandler === 'function') {
      changeEventHandler(event);
      isDefaultPrevented = event.isDefaultPrevented();
    }

    if (isDefaultPrevented === false) {
      const { maskedInputChangeHandler } = this;
      if (typeof maskedInputChangeHandler === 'function') {
        maskedInputChangeHandler(event);
      }

      let { value } = event.target;
      inputState.change(value);
    } else if (isMaskedInput) {
      event.target.value = inputState.value || '';
    }
  }

  protected refCallbackHandler(instance: HTMLElement | null): void {
    const { maskedInputRefCallback, inputElementRef } = this;
    if (typeof maskedInputRefCallback === 'function') {
      maskedInputRefCallback(instance);
    }

    if (typeof inputElementRef === 'function') {
      inputElementRef(instance);
    } else if (Utils.isNonArrayObjectLike<any>(inputElementRef)) {
      inputElementRef.current = instance;
    }
  }
}

export const Field = React.forwardRef<HTMLDivElement, FieldProps>((props, ref) => (
  <FieldComponent innerRef={ref} {...props} />
));

Field.displayName = 'Field';
