import { Nullable, PartialRecord, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { v4 as uuid } from 'uuid';
import {
  SearchAccuroEMRPatientListResourceParams,
  SearchAccuroEMRPatientListResourceValue,
  SearchOscarEMRPatientListResourceParams,
  SearchOscarEMRPatientListResourceValue,
  SearchPatientListCollectionResourceParams,
  useSearchAccuroEMRPatientListResource,
  useSearchOscarEMRPatientListResource,
  useSearchPatientListCollectionResource
} from '.';
import { PENDING_PROMISE, UNRESOLVED_RESOURCE } from '../../constants';
import { toAccuroEMRPatientRecord } from '../../utils/accuro-emr';
import { getRandomBytes } from '../../utils/get-random-bytes';
import { EMPTY_ARRAY } from '../constants';

export interface Params {
  readonly accuroEMR?: Nullable<SearchAccuroEMRPatientListResourceParams> | false;
  readonly collection?: Nullable<SearchPatientListCollectionResourceParams> | false;
  readonly oscarEMR?: Nullable<SearchOscarEMRPatientListResourceParams> | false;
}

export type Resource = ISuspenseResource<ResourceValue>;

export interface SearchPatientRecord {
  readonly record: Omit<Api.AccuroEMRPatientRecord, 'uuid'> &
    Readonly<{
      uuid: string;
    }>;
  readonly source: 'accuroEMR' | 'collection' | 'oscarEMR' | (string & {});
}

export interface ResourceValue {
  readonly accuroEMR: Omit<SearchAccuroEMRPatientListResourceValue, 'list'>;
  readonly list: ReadonlyArray<SearchPatientRecord>;
  readonly oscarEMR: Omit<SearchOscarEMRPatientListResourceValue, 'list'>;
}

const DEFAULT_RESOURCE_VALUE: ResourceValue = {
  accuroEMR: {
    auth: { config: null, recheckAuth: Utils.noop, requestKey: 1, status: false, url: '' }
  },
  list: EMPTY_ARRAY,
  oscarEMR: {
    auth: { config: null, recheckAuth: Utils.noop, requestKey: 1, status: false, url: '' }
  }
};

const newUUID = uuid.bind(null, { rng: () => getRandomBytes(16) });

export const useSearchPatientListResource = (params?: Nullable<Params> | false): Resource => {
  let accuroParams: Params['accuroEMR'];
  let collectionParams: Params['collection'];
  let oscarParams: Params['oscarEMR'];

  if (Utils.isNonArrayObjectLike<NonNullable<Exclude<typeof params, false>>>(params)) {
    accuroParams = params.accuroEMR;
    collectionParams = params.collection;
    oscarParams = params.oscarEMR;
  }

  const accuroEMRDisabled = !Utils.isNonArrayObjectLike<SearchAccuroEMRPatientListResourceParams>(accuroParams);
  const collectionDisabled = !Utils.isNonArrayObjectLike<SearchPatientListCollectionResourceParams>(collectionParams);
  const oscarEMRDisabled = !Utils.isNonArrayObjectLike<SearchOscarEMRPatientListResourceParams>(oscarParams);

  const accuroEMRListResource = useSearchAccuroEMRPatientListResource(accuroParams);
  const collectionListResource = useSearchPatientListCollectionResource(collectionParams);
  const oscarEMRListResource = useSearchOscarEMRPatientListResource(oscarParams);

  return React.useMemo<Resource>(() => {
    let accuroEMRResourceValue = DEFAULT_RESOURCE_VALUE.accuroEMR;
    let oscarEMRResourceValue = DEFAULT_RESOURCE_VALUE.oscarEMR;

    try {
      if (accuroEMRDisabled && collectionDisabled && oscarEMRDisabled) throw PENDING_PROMISE;

      const list: Array<SearchPatientRecord> = [];

      const uuidUsed: PartialRecord<string, boolean> = {};
      const getUUID = (): string => {
        let id: string;
        do {
          id = newUUID();
        } while (uuidUsed[id] === true);
        uuidUsed[id] = true;
        return id;
      };

      if (!accuroEMRDisabled) {
        const { list: accuroEMRList, ...resourceValue } = accuroEMRListResource.value();
        accuroEMRResourceValue = resourceValue;

        list.push(
          ...accuroEMRList.map<typeof list[0]>((record) => ({
            record: { ...record, uuid: getUUID() },
            source: 'accuroEMR'
          }))
        );
      }

      if (!collectionDisabled) {
        list.push(
          ...collectionListResource.value().list.map<SearchPatientRecord>((record) => ({
            record: toAccuroEMRPatientRecord(record, getUUID()) as SearchPatientRecord['record'],
            source: 'collection'
          }))
        );
      }

      if (!oscarEMRDisabled) {
        const { list: oscarEMRList, ...resourceValue } = oscarEMRListResource.value();
        oscarEMRResourceValue = resourceValue;

        list.push(
          ...oscarEMRList.map<SearchPatientRecord>((record) => ({
            record: toAccuroEMRPatientRecord(record, getUUID()) as SearchPatientRecord['record'],
            source: 'oscarEMR'
          }))
        );
      }

      return {
        value: (): ResourceValue => ({
          accuroEMR: accuroEMRResourceValue,
          list,
          oscarEMR: oscarEMRResourceValue
        })
      };
    } catch (error) {
      return UNRESOLVED_RESOURCE;
    }
  }, [
    accuroEMRDisabled,
    accuroEMRListResource,
    collectionDisabled,
    collectionListResource,
    oscarEMRDisabled,
    oscarEMRListResource
  ]);
};
