import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogNewVersionAvailable } from '../../constants/action-context';

const i18n: GlobalI18n['dialog']['newVersionAvailable'] = {
  action: {
    accept: {
      // Translators: Label for the Proceed action of New Version Available \
      // dialog
      label: gettext('global', 'accept', 'dialogNewVersionAvailableActionLabel'),
      context: DialogNewVersionAvailable
    },
    reject: {
      // Translators: Label for the Cancel action of New Version Available \
      // dialog
      label: gettext('global', 'reject', 'dialogNewVersionAvailableActionLabel'),
      context: DialogNewVersionAvailable
    }
  },
  // Translators: (HTML) Message displayed in New Version Available dialog
  body: gettext('global', 'body', 'dialogNewVersionAvailable', 'safe-html'),
  // Translators: (HTML) Title of New Version Available dialog
  title: gettext('global', 'title', 'dialogNewVersionAvailable', 'safe-html')
};

export default i18n;
