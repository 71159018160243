import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { GroupObject } from '.';
import type { GroupObjectFolderListValue as DV } from '../types';

const TYPE = process.env.GROUP_OBJECT_TYPE_FOLDER_LIST;

/** @public */
export class GroupObjectFolderList extends GroupObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    // @ts-expect-error TS2367
    if (Utils.isUndefined(dv.$$formatver) || dv.$$formatver === 1) {
      // @ts-expect-error TS2339
      return Utils.isNonArrayObjectLike(dv.$group_inbox);
    }

    return dv.$$formatver >= 2 && Utils.isNonArrayObjectLike(dv.msg) && Utils.isNonArrayObjectLike(dv.doc);
  }

  public updateValue(newValue: DV): Promise<GroupObjectFolderList> {
    const Class = this.constructor as typeof GroupObjectFolderList;
    return Class.updateValue(this, newValue);
  }
}
