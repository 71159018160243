import type { SigmailUserId } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import type { Http } from 'types';
import { ServiceException as HttpServiceException } from '../../http/service-exception';
import type { ApiService } from '../api-service';
import { URL_UNSUBSCRIBE_WEB_PUSH as API_URL } from '../constants';
import { ServiceException as ApiServiceException } from '../service-exception';

const Logger = getLoggerWithPrefix('ApiService', 'unsubscribeWebPush:');

export async function apiUnsubscribeWebPush(
  this: ApiService,
  accessToken: string,
  subscriberId: SigmailUserId,
  pushSubscription: PushSubscriptionJSON,
  init?: Http.RequestInit
): Promise<void> {
  try {
    Logger.info('== BEGIN ==');
    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    return await this.httpService.post<void>(requestUrl, JSON.stringify({ pushSubscription, subscriberId }), {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status !== 200) {
          Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
          if (response.status >= 400 && response.status < 500) {
            const responseBody = await response.clone().text();
            throw new ApiServiceException(response, responseBody);
          }

          throw new HttpServiceException(response);
        }
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
