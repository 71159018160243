import { AccountActionPayload } from '@sigmail/app-state';
import React from 'react';
import { sendAccountInvitationAction } from '../../../app-state/actions/account/send-account-invitation-action';
import { useAppState } from '../../../app-state/hooks';
import { ActionData } from '../types';

export type ActionDataSendNonGuestAccountInvitation = ActionData &
  Exclude<
    AccountActionPayload.SendAccountInvitation,
    AccountActionPayload.SendGuestAccountInvitation | AccountActionPayload.SendCaregiverAccountInvitation
  >;

export const useSendNonGuestAccountInvitationActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({ failCallback, successCallback, ...actionPayload }: ActionDataSendNonGuestAccountInvitation) => {
      let value: any;

      try {
        value = await appDispatch(sendAccountInvitationAction(actionPayload));
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
