import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core';
import { Check as IconCheckmark, Language as IconLanguageToggle } from '@material-ui/icons';
import { Utils } from '@sigmail/common';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { EMPTY_PLAIN_OBJECT } from '../../app-state/constants';
import { useCurrentUser } from '../../app-state/hooks';
import { homeRoutePathSelector } from '../../app-state/selectors';
import flagCanada from '../../assets/images/flag-ca.svg';
import { AppHeader as ActionContextAppHeader } from '../../constants/action-context';
import { Home as ActionIdHome, LanguageToggle, UserAccountMenu } from '../../constants/action-ids';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { resolveActionLabel } from '../../utils/resolve-action-label';
import { resolveActionPath } from '../../utils/resolve-action-path';
import { AccountNavActionList, Props as AccountNavActionListProps } from '../account/nav-action-list.component';
import { MemberAvatar } from '../shared/member-avatar.component';
import { MenuButton } from '../shared/menu-button.component';
import { NavAction, NavActionList } from '../shared/nav-action-list.component';
import navStyle from '../shared/nav.module.css';
import { RouterNavLink } from '../shared/router-nav-link.component';
import style from './app-header.module.css';
import { useLayoutContextStrict } from './context';

type HtmlHeaderElementProps = Omit<React.ComponentPropsWithoutRef<'header'>, 'children'>;

export interface Props extends HtmlHeaderElementProps {
  actionIdList?: ReadonlyArray<string>;
  appDrawerButton?: React.ReactNode;
  children?: never;
  onClickNavLink?: React.MouseEventHandler<HTMLAnchorElement>;
  variant: 'default' | 'titlebar';
}

export const AppHeader = React.forwardRef<HTMLDivElement, Props>(
  ({ actionIdList, appDrawerButton, children, onClickNavLink, variant, ...rootProps }, ref) => {
    // prettier-ignore
    const { i18n: { language: locale }, t } = useTranslation([I18N_NS_GLOBAL]);

    const [elMenuAnchor, setMenuAnchorEl] = React.useState<Record<string, HTMLElement | null>>(EMPTY_PLAIN_OBJECT);
    const isAccountMenuOpen = Utils.isNotNil(elMenuAnchor[UserAccountMenu]);
    const isLanguageMenuOpen = Utils.isNotNil(elMenuAnchor[LanguageToggle]);
    const closeMenu = React.useCallback(() => setMenuAnchorEl(EMPTY_PLAIN_OBJECT), []);

    const onNavActionClick = React.useCallback<React.MouseEventHandler<HTMLElement>>(
      (event) => {
        setMenuAnchorEl(EMPTY_PLAIN_OBJECT);
        onClickNavLink?.(event as React.MouseEvent<HTMLAnchorElement>);
      },
      [onClickNavLink]
    );

    const renderAccountAction = React.useCallback<NonNullable<AccountNavActionListProps['renderAction']>>(
      ({ action: { actionId, children, href: hrefProp, label, render, renderLabel, to, ...linkProps } }) => {
        const href =
          Utils.isString(hrefProp) || typeof hrefProp === 'function'
            ? t(resolveActionPath({ href: hrefProp, context: ActionContextAppHeader }))
            : undefined;

        return (
          <MenuItem
            classes={{ root: style['menu-item'] }}
            data-action-id={actionId}
            key={actionId}
            onClick={onNavActionClick}
          >
            <RouterNavLink
              data-action-id={actionId}
              href={href}
              to={t(resolveActionPath({ to, context: ActionContextAppHeader }))}
              {...linkProps}
              onClick={onNavActionClick}
            >
              {typeof renderLabel === 'function'
                ? renderLabel(actionId, label)
                : t(resolveActionLabel({ label }, actionId, ActionContextAppHeader))}
            </RouterNavLink>
          </MenuItem>
        );
      },
      [onNavActionClick, t]
    );

    const currentUser = useCurrentUser();
    const renderAccountMenuButton = React.useCallback<NonNullable<NavAction['render']>>(
      ({ action: { actionId } }) => {
        return (
          <li className={navStyle.item} key={actionId}>
            <MenuButton
              anchorElProps={{
                children: (
                  <React.Fragment>
                    <MemberAvatar
                      firstName={currentUser!.firstName}
                      lastName={currentUser!.lastName}
                      license={Utils.getLicenseTypeFromRole(currentUser!.role)}
                    />
                    <span>{currentUser!.fullName}</span>
                  </React.Fragment>
                ),
                className: clsx(style.anchor, style['menu-button'])
              }}
              key={actionId}
              open={isAccountMenuOpen}
              onClick={({ currentTarget: el }) => setMenuAnchorEl({ [UserAccountMenu]: el })}
              onClose={closeMenu}
            >
              <AccountNavActionList className="" onNavLinkClick={onNavActionClick} renderAction={renderAccountAction} />
            </MenuButton>
          </li>
        );
      },
      [currentUser, isAccountMenuOpen, onNavActionClick, renderAccountAction, closeMenu]
    );

    const renderLanguageToggle = React.useCallback<NonNullable<NavAction['render']>>(
      ({ action: { actionId, label } }) => {
        const actionLabel = t(resolveActionLabel({ label }, actionId));

        return (
          <li className={navStyle.item} key={actionId}>
            <MenuButton
              anchorElProps={{
                children: (
                  <React.Fragment>
                    <IconLanguageToggle color="inherit" />
                    <span className="sr-only">{actionLabel}</span>
                  </React.Fragment>
                ),
                className: style['language-toggle'],
                title: actionLabel
              }}
              open={isLanguageMenuOpen}
              onClick={({ currentTarget: el }) => setMenuAnchorEl({ [LanguageToggle]: el })}
              onClose={closeMenu}
            >
              {globalI18n.languageName.map(({ code, label }) => (
                <MenuItem data-action-id={LanguageToggle} data-lang={code} key={code} onClick={onNavActionClick}>
                  <ListItemIcon aria-hidden={true} style={{ minWidth: 32 }}>
                    {code === locale ? (
                      <IconCheckmark />
                    ) : (
                      <img
                        alt=""
                        aria-hidden={true}
                        role="presentation"
                        src={flagCanada}
                        style={{ height: 20, width: 24 }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText>{t(label)}</ListItemText>
                </MenuItem>
              ))}
            </MenuButton>
          </li>
        );
      },
      [closeMenu, isLanguageMenuOpen, locale, onNavActionClick, t]
    );

    const navActionList = React.useMemo<ReadonlyArray<NavAction> | undefined>(() => {
      return actionIdList?.map<NavAction>((actionId) => {
        let render: NavAction['render'];
        if (actionId === LanguageToggle) {
          render = renderLanguageToggle;
        } else if (actionId === UserAccountMenu) {
          render = renderAccountMenuButton;
        }

        return {
          ...globalI18n.action[actionId],
          actionId,
          exact: true,
          onClick: onNavActionClick,
          render
        };
      });
    }, [actionIdList, onNavActionClick, renderAccountMenuButton, renderLanguageToggle]);

    const homeRoutePath = useSelector(homeRoutePathSelector);
    const layoutContext = useLayoutContextStrict();
    const isDisplayModeStandalone = layoutContext.displayMode === 'standalone';
    const isDisplayModeWindowControlsOverlay =
      !isDisplayModeStandalone && layoutContext.displayMode === 'window-controls-overlay';

    if (variant === 'titlebar') {
      return (
        <div aria-hidden="true" className="titlebar-area" {...rootProps} ref={ref}>
          <div styleName="style.logo">{t(globalI18n.logoText)}</div>
        </div>
      );
    }

    return (
      <header className={style.root} ref={ref} {...rootProps}>
        <div className="container-fluid">
          {appDrawerButton}

          {isDisplayModeStandalone || isDisplayModeWindowControlsOverlay ? (
            Utils.isNotNil(currentUser) && <div aria-hidden={true} id="sm-route-title" />
          ) : (
            <div styleName="style.logo">
              <RouterNavLink data-action-id={ActionIdHome} exact={true} to={homeRoutePath} onClick={onNavActionClick}>
                {t(globalI18n.logoText)}
              </RouterNavLink>
            </div>
          )}

          <div styleName="style.a11y"></div>

          {Utils.isNotNil(navActionList) && (
            <NavActionList actionList={navActionList} classes={{ anchor: style.anchor }} styleName="style.ul" />
          )}
        </div>
      </header>
    );
  }
);

AppHeader.displayName = 'AppHeader';
