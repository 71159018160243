import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['fhirRelationshipList'] = [
  {
    code: 'AUNT',
    // Translators: Label for the FHIR Relationship - Aunt
    label: gettext('global', 'AUNT', 'fhirRelationshipLabel')
  },
  {
    code: 'BROINLAW',
    // Translators: Label for the FHIR Relationship - Bother-in-law
    label: gettext('global', 'BROINLAW', 'fhirRelationshipLabel')
  },
  {
    code: 'CHILD',
    // Translators: Label for the FHIR Relationship - Child
    label: gettext('global', 'CHILD', 'fhirRelationshipLabel')
  },
  {
    code: 'COUSN',
    // Translators: Label for the FHIR Relationship - Cousin
    label: gettext('global', 'COUSN', 'fhirRelationshipLabel')
  },
  {
    code: 'DAUC',
    // Translators: Label for the FHIR Relationship - Daughter
    label: gettext('global', 'DAUC', 'fhirRelationshipLabel')
  },
  {
    code: 'EXT',
    // Translators: Label for the FHIR Relationship - Extended family member
    label: gettext('global', 'EXT', 'fhirRelationshipLabel')
  },
  {
    code: 'FAMMEMB',
    // Translators: Label for the FHIR Relationship - Family member
    label: gettext('global', 'FAMMEMB', 'fhirRelationshipLabel')
  },
  {
    code: 'FTH',
    // Translators: Label for the FHIR Relationship - Father
    label: gettext('global', 'FTH', 'fhirRelationshipLabel')
  },
  {
    code: 'FTHINLAW',
    // Translators: Label for the FHIR Relationship - Father-in-law
    label: gettext('global', 'FTHINLAW', 'fhirRelationshipLabel')
  },
  {
    code: 'GRFTH',
    // Translators: Label for the FHIR Relationship - Grandfather
    label: gettext('global', 'GRFTH', 'fhirRelationshipLabel')
  },
  {
    code: 'GRMTH',
    // Translators: Label for the FHIR Relationship - Grandmother
    label: gettext('global', 'GRMTH', 'fhirRelationshipLabel')
  },
  {
    code: 'GRNDCHILD',
    // Translators: Label for the FHIR Relationship - Grandchild
    label: gettext('global', 'GRNDCHILD', 'fhirRelationshipLabel')
  },
  {
    code: 'GRNDDAU',
    // Translators: Label for the FHIR Relationship - Granddaughter
    label: gettext('global', 'GRNDDAU', 'fhirRelationshipLabel')
  },
  {
    code: 'GRNDSON',
    // Translators: Label for the FHIR Relationship - Grandson
    label: gettext('global', 'GRNDSON', 'fhirRelationshipLabel')
  },
  {
    code: 'GRPRN',
    // Translators: Label for the FHIR Relationship - Grandparent
    label: gettext('global', 'GRPRN', 'fhirRelationshipLabel')
  },
  {
    code: 'HUSB',
    // Translators: Label for the FHIR Relationship - Husband
    label: gettext('global', 'HUSB', 'fhirRelationshipLabel')
  },
  {
    code: 'MTH',
    // Translators: Label for the FHIR Relationship - Mother
    label: gettext('global', 'MTH', 'fhirRelationshipLabel')
  },
  {
    code: 'MTHINLAW',
    // Translators: Label for the FHIR Relationship - Mother-in-law
    label: gettext('global', 'MTHINLAW', 'fhirRelationshipLabel')
  },
  {
    code: 'NBOR',
    // Translators: Label for the FHIR Relationship - Neighbor
    label: gettext('global', 'NBOR', 'fhirRelationshipLabel')
  },
  {
    code: 'NEPHEW',
    // Translators: Label for the FHIR Relationship - Nephew
    label: gettext('global', 'NEPHEW', 'fhirRelationshipLabel')
  },
  {
    code: 'NIECE',
    // Translators: Label for the FHIR Relationship - Niece
    label: gettext('global', 'NIECE', 'fhirRelationshipLabel')
  },
  {
    code: 'PRN',
    // Translators: Label for the FHIR Relationship - Parent
    label: gettext('global', 'PRN', 'fhirRelationshipLabel')
  },
  {
    code: 'ROOM',
    // Translators: Label for the FHIR Relationship - Roommate
    label: gettext('global', 'ROOM', 'fhirRelationshipLabel')
  },
  {
    code: 'SIB',
    // Translators: Label for the FHIR Relationship - Sibling
    label: gettext('global', 'SIB', 'fhirRelationshipLabel')
  },
  {
    code: 'SIBINLAW',
    // Translators: Label for the FHIR Relationship - Sibling-in-law
    label: gettext('global', 'SIBINLAW', 'fhirRelationshipLabel')
  },
  {
    code: 'SIS',
    // Translators: Label for the FHIR Relationship - Sister
    label: gettext('global', 'SIS', 'fhirRelationshipLabel')
  },
  {
    code: 'SISINLAW',
    // Translators: Label for the FHIR Relationship - Sister-in-law
    label: gettext('global', 'SISINLAW', 'fhirRelationshipLabel')
  },
  {
    code: 'SONC',
    // Translators: Label for the FHIR Relationship - Son
    label: gettext('global', 'SONC', 'fhirRelationshipLabel')
  },
  {
    code: 'SPS',
    // Translators: Label for the FHIR Relationship - Spouse
    label: gettext('global', 'SPS', 'fhirRelationshipLabel')
  },
  {
    code: 'UNCLE',
    // Translators: Label for the FHIR Relationship - Uncle
    label: gettext('global', 'UNCLE', 'fhirRelationshipLabel')
  },
  {
    code: 'WIFE',
    // Translators: Label for the FHIR Relationship - Wife
    label: gettext('global', 'WIFE', 'fhirRelationshipLabel')
  }
];

export default i18n;
