import { StoreStateReminderNotification } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { compareAsc, isAfter } from 'date-fns';

const Logger = getLoggerWithPrefix('Reducer', 'processReminderNotificationReducer:');

export const processReminderNotificationReducer = (state: StoreStateReminderNotification) => {
  try {
    Logger.info('== BEGIN ==');

    if (!Utils.isNonEmptyArray(state.queueList) || Utils.isNotNil(state.active)) return state;

    const reminder = [...state.queueList]
      .filter(({ remindAt }) => isAfter(Date.now(), remindAt))
      .sort((a, b) => compareAsc(a.remindAt, b.remindAt))
      .shift();

    if (Utils.isNil(reminder)) return state;
    if (state.dismissedList.includes(reminder.eventObjectId)) {
      return {
        ...state,
        queueList: [...state.queueList].filter(({ eventObjectId }) => eventObjectId !== reminder.eventObjectId)
      };
    }

    return { ...state, active: reminder };
  } finally {
    Logger.info('== END ==');
  }
};
