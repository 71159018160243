import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../../http/service-exception';
import type { Api, Http } from '../../types';
import { URL_OTP_VERIFY as API_URL } from '../constants';
import { ServiceException as ApiServiceException } from '../service-exception';

const Logger = getLoggerWithPrefix('ApiService', 'verifyOTP:');

export async function apiVerifyOTP(
  this: Api.Service,
  accessToken: string,
  authState: string,
  code: string,
  init?: Http.RequestInit
): Promise<void> {
  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.otpApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.otpApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.otpApi);
    }

    Logger.info('Initiating HTTP request.');
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    return await this.httpService.post<void>(requestUrl, JSON.stringify({ authState, code }), {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status !== 200) {
          Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
          if (response.status >= 400 && response.status < 500) {
            const responseBody = await response.clone().text();
            throw new ApiServiceException(response, responseBody);
          }

          throw new HttpServiceException(response);
        }
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
