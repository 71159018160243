import { AppException, Constants, PersonName, PostalAddress, SigmailUserId, Utils } from '@sigmail/common';
import type {
  GroupGuestUserContactListItem,
  NotificationObjectGuestProfileUpdateValue,
  UserContactListItem,
  UserObjectContactInfoValue
} from '@sigmail/objects';
import { NotificationObjectGuestProfileUpdate } from '@sigmail/objects';
import { guestListObjectSelector as groupGuestListSelector } from '../../../selectors/group-object';
import { contactInfoObjectSelector as userContactInfoSelector } from '../../../selectors/user-object';
import { UserObjectCache } from '../../../user-objects-slice/cache';
import type { ActionInitParams } from '../../base-action';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../../constants';
import { AUTH_STATE_PROCESS_GUEST_PROFILE_UPDATES } from '../../constants/auth-state-identifier';
import type { BaseProcessNotificationsPayload, BaseProcessNotificationsState } from './base';
import { BaseProcessNotificationsAction } from './base';

export type Payload = BaseProcessNotificationsPayload;

type UpdatedUserDataKey = Extract<
  keyof UserContactListItem['userData'],
  | keyof PersonName
  | keyof PostalAddress
  | 'avatar'
  | 'birthDate'
  | `${'cell' | 'healthCard' | 'home'}Number`
  | 'emailAddress'
  | 'gender'
  | 'healthPlanJurisdiction'
  | 'languagePreference'
  | 'noNotifyOnNewMessage'
>;

const UPDATED_USER_DATA_KEY_LIST: ReadonlyArray<UpdatedUserDataKey> = [
  ...Constants.PERSON_NAME_KEY_LIST,
  ...Constants.POSTAL_ADDRESS_KEY_LIST,
  'avatar',
  'birthDate',
  'cellNumber',
  'emailAddress',
  'gender',
  'healthCardNumber',
  'healthPlanJurisdiction',
  'homeNumber',
  'languagePreference',
  'noNotifyOnNewMessage'
];

interface State extends BaseProcessNotificationsState {
  copyOfRoleAuthClaim: string;
}

export class ProcessGuestProfileUpdateNotificationsAction extends BaseProcessNotificationsAction<
  NotificationObjectGuestProfileUpdateValue,
  Payload,
  State
> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params, NotificationObjectGuestProfileUpdate);

    this.state.successPayload = {
      request: { userObjects: { ids: [] } },
      response: { userObjects: [], serverDateTime: '' }
    };
  }

  /** @override */
  protected async getBatchUpdateAuthState() {
    const { accessToken, roleAuthClaim: authState } = this.state;

    this.state.copyOfRoleAuthClaim = authState;
    const { authState: batchUpdateAuthState } = await this.enterState(accessToken, {
      authState,
      state: AUTH_STATE_PROCESS_GUEST_PROFILE_UPDATES
    });

    return batchUpdateAuthState;
  }

  /** @override */
  protected async fetchNotificationObjectList(): Promise<void> {
    const { activeGroupId, copyOfRoleAuthClaim: authState } = this.state;

    await this.getUserObject(groupGuestListSelector, {
      authState,
      fetch: true,
      type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST,
      userId: activeGroupId
    });

    return super.fetchNotificationObjectList();
  }

  /** @override */
  protected async processNotificationList() {
    this.logger.info('Processing guest profile update notification objects.');

    const { activeGroupId, copyOfRoleAuthClaim: authState, dtServer, notificationObjectList, requestBody, successPayload } = this.state;

    const contactListObject = await this.getUserObject(groupGuestListSelector, { userId: activeGroupId });
    const contactList = UserObjectCache.getValue(contactListObject);
    if (Utils.isNil(contactListObject) || Utils.isNil(contactList)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, `Failed to fetch group guest list. <groupId=${activeGroupId}>`);
    }

    let updatedList: Array<typeof contactList.list[0]> | undefined;
    const userIdSet = new Set<SigmailUserId>();
    for (let index = 0; index < notificationObjectList.length; index++) {
      const [notificationObject] = notificationObjectList[index];
      const { sendingUserId: userId } = notificationObject;

      // more than one notification from same user?
      if (userIdSet.has(userId)) {
        requestBody.expire(notificationObject);
        continue;
      }

      let userContactInfo: UserObjectContactInfoValue;
      try {
        userContactInfo = (await this.getUserObjectValue(userContactInfoSelector, {
          authState,
          fetch: true,
          type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
          userId
        }))!;
      } catch {
        userContactInfo = undefined!;
        /* ignore */
      }

      try {
        if (Utils.isNil(userContactInfo)) {
          this.logger.warn(`Failed to fetch user contact info. <userId=${userId}>`);
          continue;
        }

        if (Utils.isNil(updatedList)) {
          updatedList = contactList.list.slice();
        }

        const indexOfContact = updatedList.findIndex(({ id, type }) => type === 'user' && id === userId);
        if (indexOfContact > -1) {
          const contact = updatedList[indexOfContact] as GroupGuestUserContactListItem;
          updatedList[indexOfContact] = {
            ...contact,
            userData: {
              ...UPDATED_USER_DATA_KEY_LIST.reduce<typeof contact.userData>(
                (userData, key) => {
                  let value = userContactInfo[key];

                  if (key === 'healthPlanJurisdiction') {
                    //
                    // GK@20230516: Although it doesn't matter if health plan jurisdiction is not set because
                    // in that case, we always fall back to Ontario as the default BUT team believes that it's
                    // more clear if the value is present and that's what this code will fix.
                    //
                    // Before release v1.3.1398+20210315, there was no concept of health plan jurisdictions (and
                    // that's why `undefined` is considered as `Ontario`).
                    //
                    value = Utils.stringOrDefault(userContactInfo.healthPlanJurisdiction, DEFAULT_HEALTH_PLAN_JURISDICTION);
                  } else if (key === 'healthCardNumber' && Utils.isString(userContactInfo.healthCardNumber)) {
                    //
                    // GK@20230516: in release v1.4.1491+20210413, we started saving health plan numbers masked
                    // but there may still be some very old records which have the number unmasked; applying the
                    // maskHealthPlanNumber method will fix it. Note that applying masking on an already masked
                    // number, as we're doing here, doesn't cause any issues.
                    //
                    value = Utils.maskHealthPlanNumber(userContactInfo.healthCardNumber);
                  }

                  (userData as any)[key] = value;
                  return userData;
                },
                { ...contact.userData }
              )
            }
          };
        } else {
          this.logger.warn(`User with ID <${userId}> could not be found in group guest list.`);

          // we don't return/continue here because if there's no such user in
          // the list anymore than we would like the notification to expire
        }

        userIdSet.add(userId);
        requestBody.expire(notificationObject, dtServer);
      } catch (error) {
        this.logger.warn(`Error processing guest profile update notification from user with ID <${userId}>.`);
        /* ignore */
      }
    }

    if (Utils.isNotNil(updatedList)) {
      const updatedValue: typeof contactList = { ...contactList, list: updatedList };
      const updatedObject = await contactListObject.updateValue(updatedValue);

      requestBody.update(updatedObject);

      successPayload.request.userObjects!.ids.push(updatedObject.id);
      successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
    }
  }
}
