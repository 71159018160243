import React from 'react';
import { deleteMessageAction } from '../../../../app-state/actions/messaging/delete-message-action';
import { useAppState } from '../../../../app-state/hooks';
import { ActionData } from '../../types';
import { BaseContextValue } from '../context';

export interface ActionDataDeleteMessage extends ActionData {
  currentFolder?: BaseContextValue['currentFolder'];
  messageList: ReturnType<BaseContextValue['getSelectedMessageList']>;
}

export const useDeleteMessageActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({ currentFolder, failCallback, messageList, successCallback }: ActionDataDeleteMessage) => {
      let value: any;

      try {
        value = await appDispatch(
          deleteMessageAction({
            folderKey: currentFolder?.folderKey!,
            parentFolderKey: currentFolder?.parentFolderKey,
            msgMetadataId: messageList.map(({ header }) => header)
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
