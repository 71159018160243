import { ApiActionPayload, SchedulingActionPayload } from '@sigmail/app-state';
import { AppException, Constants, Json, Nullable, SigmailKeyId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { DataObjectCalendarEventValue, IDataObject, UserObjectContactInfoValue } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { EMPTY_ARRAY } from '../../constants';
import * as DataObjectSelectors from '../../selectors/data-object';
import {
  basicProfileObjectSelector as selectUserProfileBasic,
  contactInfoObjectSelector as selectUserContactInfo
} from '../../selectors/user-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { AUTH_STATE_RECORD_EVENT_ATTENDANCE } from '../constants/auth-state-identifier';
import { sendMessageAction } from '../messaging/send-message-action';

type CalendarEventAttendee = DataObjectCalendarEventValue['extendedProps']['attendeeList'][0];

type Payload = SchedulingActionPayload.RecordCalendarEventAttendance;

interface State extends AuthenticatedActionState {
  batchUpdateClaims: Array<string>;
  calendarEvent: DataObjectCalendarEventValue;
  calendarEventObject: IDataObject<DataObjectCalendarEventValue>;
  dtServer: Date;
  geoLocation: Nullable<Json>;
  masterKeyId: SigmailKeyId;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

const MAX_ATTEMPTS_GEO_LOCATION_API = 3;

class RecordCalendarEventAttendanceAction extends AuthenticatedAction<Payload, State> {
  /** @override */
  protected async onExecute(): Promise<void> {
    this.state.batchUpdateClaims = [];
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };

    this.state.dtServer = await this.dispatchFetchServerDateAndTime();

    await this.fetchGeoLocation();
    await this.fetchCalendarEvent();
    await this.generateRequestBody();

    const { roleAuthClaim: authState, requestBody, successPayload } = this.state;

    const query: Api.EnterStateRequestData = {
      authState,
      state: AUTH_STATE_RECORD_EVENT_ATTENDANCE
    };

    const { batchUpdateClaims } = this.state;
    const { authState: batchUpdateAuthState } = await this.dispatchEnterState(query);

    try {
      await this.dispatchBatchUpdateData({
        authState: batchUpdateAuthState,
        claims: batchUpdateClaims,
        ...requestBody.build()
      });
    } catch (error) {
      this.logger.warn('Error updating data:', error);
      /* ignore */
    }

    try {
      await this.dispatchBatchQueryDataSuccess(successPayload);

      try {
        await this.sendAttendeeListMessage();
      } catch (error) {
        this.logger.warn('Error sending attendance report:', error);
        /* ignore */
      }
    } catch (error) {
      this.logger.warn('Error manually updating app state:', error);
      /* ignore */
    }
  }

  private async generateRequestBody(): Promise<void> {
    const { action } = this.payload;

    if (action === 'connect') {
      await this.addUpdateOperationForJoinMeeting();
    } else if (action === 'disconnect') {
      await this.addUpdateOperationForLeaveMeeting();
    }
  }

  private async fetchGeoLocation(): Promise<void> {
    this.logger.info("Fetching current user's geo location.");

    const { accessToken, currentUser } = this.state;

    for (let attempt = 1; attempt <= MAX_ATTEMPTS_GEO_LOCATION_API; attempt++) {
      try {
        if (attempt > 1) {
          await Utils.sleep(1000);
        }

        this.state.geoLocation = await this.apiService.getGeoLocation(accessToken);
      } catch (error) {
        this.logger.warn(
          `Failed fetching user's geoLocation <ID=${currentUser.id}>. (Attempt ${attempt}/${MAX_ATTEMPTS_GEO_LOCATION_API})`,
          error
        );
      }
    }

    this.logger.warn(`Unable to fetch geolocation for user <${currentUser.id}>.`);
  }

  private async fetchCalendarEvent(): Promise<void> {
    const { eventObjectId } = this.payload;
    this.logger.info(`Fetching the calendar event object. <ID=${eventObjectId}>`);

    const { claims } = await this.dispatchFetchObjects({
      authState: this.state.roleAuthClaim,
      dataObjects: { ids: [eventObjectId] },
      expectedCount: { claims: 1 }
    });

    const dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
    const calendarEventObject = dataObjectByIdSelector<DataObjectCalendarEventValue>(eventObjectId);
    if (Utils.isNil(calendarEventObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Calendar event object is either missing or invalid.');
    }

    this.state.batchUpdateClaims.push(...claims);
    this.state.calendarEventObject = calendarEventObject;
    this.state.calendarEvent = await calendarEventObject.decryptedValue();
  }

  private async addUpdateOperationForJoinMeeting(): Promise<void> {
    if (this.payload.action !== 'connect') return;
    this.logger.info('Adding an update operation to request body for calendar event object.');

    const { calendarEvent, calendarEventObject, currentUser, dtServer, geoLocation, requestBody, successPayload } = this.state;

    let attendeeList = calendarEvent.extendedProps.attendeeList.slice();
    const indexOfAttendee = attendeeList.findIndex(({ id }) => id === currentUser.id);

    const attendee = attendeeList[indexOfAttendee]!;
    let { timestamp } = attendee;
    if (timestamp !== null) {
      timestamp = { ...timestamp, droppedAt: undefined, joinedAt: dtServer.getTime() };
    } else {
      timestamp = { joinedAt: dtServer.getTime() };
    }

    attendeeList[indexOfAttendee] = {
      ...attendee,
      geoLocation: geoLocation as CalendarEventAttendee['geoLocation'],
      timestamp
    };

    const updatedValue: DataObjectCalendarEventValue = {
      ...calendarEvent,
      extendedProps: {
        ...calendarEvent.extendedProps,
        attendeeList
      }
    };

    const updatedObject = await calendarEventObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    successPayload.request.dataObjects!.ids.push(updatedObject.id);
    successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForLeaveMeeting(): Promise<void> {
    const { action } = this.payload;
    const { calendarEvent, calendarEventObject, currentUser, dtServer, requestBody, successPayload } = this.state;

    const activeNonGuestAttendeeList: Array<CalendarEventAttendee> = [];
    let indexOfCurrentAttendee = -1;
    let didCurrentUserDrop = false;
    for (let index = 0; index < calendarEvent.extendedProps.attendeeList.length; index++) {
      const attendee = calendarEvent.extendedProps.attendeeList[index];
      const { id: attendeeId, timestamp, userData } = attendee;

      const didAttendeeJoin = Utils.isNotNil(timestamp) && Utils.isInteger(timestamp.joinedAt);
      const didAttendeeDrop = didAttendeeJoin && Utils.isInteger(timestamp.droppedAt);

      if (attendeeId === currentUser.id) {
        indexOfCurrentAttendee = index;
        didCurrentUserDrop = didAttendeeDrop;
      }

      const isNonGuestRole = Utils.isNonGuestRole(userData.role) && !Utils.isCaregiverRole(userData.role);
      if (isNonGuestRole && !didAttendeeDrop) {
        activeNonGuestAttendeeList.push(attendee);
      }
    }

    if (action !== 'disconnect' || didCurrentUserDrop) return;
    this.logger.info('Adding an update operation to request body for calendar event object.');

    const attendeeList = calendarEvent.extendedProps.attendeeList.slice();
    const droppedAt = dtServer.getTime();
    attendeeList[indexOfCurrentAttendee] = {
      ...attendeeList[indexOfCurrentAttendee],
      timestamp: { ...attendeeList[indexOfCurrentAttendee].timestamp, droppedAt }
    };

    if (activeNonGuestAttendeeList.length === 1) {
      attendeeList.forEach((attendee, index) => {
        if (Utils.isGuestRole(attendee.userData.role) || Utils.isCaregiverRole(attendee.userData.role)) {
          attendeeList[index] = { ...attendee, timestamp: { ...attendee.timestamp, droppedAt } };
        }
      });
    }

    const updatedValue: DataObjectCalendarEventValue = {
      ...calendarEvent,
      extendedProps: { ...calendarEvent.extendedProps, attendeeList }
    };

    const updatedObject = await calendarEventObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    successPayload.request.dataObjects!.ids.push(updatedObject.id);
    successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
  }

  private async fetchOrganizerContactInfo(): Promise<UserObjectContactInfoValue> {
    this.logger.info("Fetching the organizer's contact info.");

    const { calendarEvent, roleAuthClaim: authState } = this.state;

    const { id: organizerId } = calendarEvent.extendedProps.createdBy;
    await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_CONTACT_INFO, userId: organizerId }]
    });

    const contactInfo = await this.getUserObjectValue(selectUserContactInfo, {
      fetch: true,
      type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
      userId: organizerId
    });

    if (Utils.isNil(contactInfo)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Organizer contact info is either missing or invalid');
    }

    return contactInfo;
  }

  private async sendAttendeeListMessage(): Promise<void> {
    this.logger.info('Sending an attendance report to the event organizer.');

    const { action } = this.payload;
    const { calendarEvent, currentUser } = this.state;
    const { id: eventObjectId } = this.state.calendarEventObject;

    const basicProfile = await this.getUserObjectValue(selectUserProfileBasic, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC });
    if (Utils.isNil(basicProfile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Basic profile object could not be fetched.');
    }

    const {
      id: calendarEventId,
      extendedProps: { attendeeList, createdBy }
    } = calendarEvent;

    const activeNonGuestAttendeeList = attendeeList.filter(
      ({ userData: { role }, timestamp }) =>
        Utils.isNonGuestRole(role) &&
        !Utils.isCaregiverRole(role) &&
        Utils.isNotNil(timestamp) &&
        Utils.isInteger(timestamp.joinedAt) &&
        !Utils.isInteger(timestamp.droppedAt)
    );

    const isNonGuestRole = Utils.isNonGuestRole(basicProfile.role) && !Utils.isCaregiverRole(basicProfile.role);
    if (action !== 'disconnect' || !isNonGuestRole || activeNonGuestAttendeeList.length > 1) return;

    const { $$formatver, ...organizer } = await this.fetchOrganizerContactInfo();

    this.dispatch(
      sendMessageAction({
        flags: {
          doNotReply: true
        },
        messageBody: {
          messageForm: {
            name: Constants.MessageFormName.EventAttendance,
            value: { eventObjectId }
          }
        },
        primaryRecipientList: [{ id: createdBy.id, type: 'user', userData: organizer }],
        sender: {
          id: currentUser.id,
          type: currentUser.type,
          ...Utils.pick(basicProfile, Constants.PERSON_NAME_KEY_LIST)
        },
        secondaryRecipientList: EMPTY_ARRAY,
        subjectLine: `[${calendarEventId}]`
      })
    ).catch(Utils.noop);
  }
}

export const recordCalendarEventAttendanceAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'recordCalendarEventAttendanceAction:');

    const action = new RecordCalendarEventAttendanceAction({
      payload,
      dispatch,
      getState,
      apiService,
      logger: Logger
    });

    return action.execute();
  };
};
