import { ICollectionObjectCache, ICollectionObjectValueType } from '@sigmail/app-state';
import { SigmailObjectId, Utils } from '@sigmail/common';
import { CollectionObject, ICollectionObject } from '@sigmail/objects';

const isCollectionObjectLike = (obj: any) =>
  Utils.isNonArrayObjectLike<{ id: SigmailObjectId }>(obj) && CollectionObject.isValidId(obj.id);

function equals(obj1: any, obj2: any): boolean {
  if (!isCollectionObjectLike(obj1)) return false;
  if (!isCollectionObjectLike(obj2)) return false;

  // consider two entries to be equals if their IDs are equal
  return obj1 === obj2 || obj1.id === obj2.id;
}

export class Cache implements ICollectionObjectCache {
  // private readonly INDEX = new WeakMap<ICollectionObject<any>, number>();
  private readonly DATA: Array<[ICollectionObject<any>, any]> = [];

  public dumpToConsoleLog(): void {
    if (process.env.REACT_APP_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(this.DATA);
    }
  }

  public add<T extends ICollectionObject<any>>(object: T, decryptedValue: ICollectionObjectValueType<T>): void {
    const index = this.findIndex(object);
    if (typeof index !== 'undefined') {
      // this.INDEX.delete(this.DATA[index][0]);
      this.DATA[index][0] = object;
      this.DATA[index][1] = decryptedValue;
    } else {
      // const length = this.DATA.push([object, decryptedValue]);
      // this.INDEX.set(object, length - 1);
      this.DATA.push([object, decryptedValue]);
    }
  }

  public clear(): void {
    // this.DATA.forEach(([obj]) => this.INDEX.delete(obj));
    this.DATA.length = 0;
  }

  public delete(object: number | ICollectionObject<any> | undefined): void {
    const index = this.findIndex(object);
    if (typeof index !== 'undefined') {
      // this.INDEX.delete(this.DATA[index][0]);
      this.DATA.splice(index, 1);
    }
  }

  public find<T extends ICollectionObject<any>>(
    object: number | T | ((value: ICollectionObject<any>) => boolean) | undefined
  ): [T, ICollectionObjectValueType<T>] | undefined {
    const index = this.findIndex(object);
    if (typeof index !== 'undefined') {
      const entry = this.DATA[index];
      // NOTE: create a new tuple and return it
      return [entry[0], entry[1]] as [T, ICollectionObjectValueType<T>];
    }
    return undefined;
  }

  public findIndex(
    object: number | ICollectionObject<any> | ((value: ICollectionObject<any>) => boolean) | undefined
  ): number | undefined {
    let index: number | undefined = undefined;
    // let addToIndex: boolean = false;

    if (CollectionObject.isValidId(object)) {
      index = this.DATA.findIndex(([obj]) => obj.id === object);
    } else {
      // index = this.INDEX.get(object);
      // if (typeof index === 'undefined') {
      const predicate: (value: [ICollectionObject<any>, any]) => boolean =
        typeof object === 'function' ? ([obj]) => object(obj) : ([obj]) => equals(obj, object);
      index = this.DATA.findIndex(predicate);
      //   addToIndex = true;
      // }
    }

    if (typeof index === 'number' && index >= 0 && index < this.DATA.length) {
      // if (addToIndex) {
      //   this.INDEX.set(this.DATA[index][0], index);
      // }
      return index;
    }

    return undefined;
  }

  public getValue<T extends ICollectionObject<any>>(
    object: number | T | undefined
  ): ICollectionObjectValueType<T> | undefined;
  public getValue<T extends ICollectionObject<any>>(
    object: number | T | undefined,
    notSetValue: ICollectionObjectValueType<T>
  ): ICollectionObjectValueType<T>;
  public getValue<T extends ICollectionObject<any>>(
    object: number | T | undefined,
    notSetValue?: ICollectionObjectValueType<T>
  ): ICollectionObjectValueType<T> | undefined {
    const entry = this.find(object);
    return entry?.[1] || notSetValue;
  }

  public has(object: number | ICollectionObject<any> | undefined): boolean {
    const index = this.findIndex(object);
    return typeof index !== 'undefined';
  }
}

export const CollectionObjectCache: ICollectionObjectCache = new Cache();
