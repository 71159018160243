import {
  Checkbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  FormHelperText,
  InputBaseComponentProps,
  InputBaseProps
} from '@material-ui/core';
import { Utils as CommonUtils } from '@sigmail/common';
import clsx from 'clsx';
import { FieldState, FieldSubscription, FormState, FormSubscription, Unsubscribe } from 'final-form';
import React from 'react';
import { MutableRef } from 'sigmail';
import { createForm, FieldConfig, FieldProps as BaseFieldProps, FormApi, FormConfig } from '.';
import { getNextId } from '../../../hooks';
import { focusFirstInvalidInputElement } from '../../../utils/focus-first-invalid-input-element';
import { DatePickerField, DatePickerFieldProps as MuiDatePickerProps } from './date-picker-field.component';
import {
  DateTimePickerField,
  DateTimePickerFieldProps as MuiDateTimePickerProps
} from './date-time-picker-field.component';
import { Field } from './field.component';
import { SelectField, SelectFieldProps } from './select-field.component';
import { TimePickerField, TimePickerFieldProps as MuiTimePickerProps } from './time-picker-field.component';
import * as Utils from './utils';

/* eslint-disable no-console */
// const DEBUG = console.debug.bind(console, '[FormComponent]');
// const INFO = console.info.bind(console, '[FormComponent]');
// const WARN = console.warn.bind(console, '[FormComponent]');
const WARN = Utils.noop;
/* eslint-enable no-console */

type HtmlFormElementProps = Omit<React.ComponentPropsWithoutRef<'form'>, 'children' | 'onSubmit'>;

export interface FormStateChangeEvent<
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>,
  InitialFormValues = FormValues
> {
  formState: FormState<FormValues, InitialFormValues>;
}

export interface FormReadyEvent<
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>,
  InitialFormValues = FormValues
> {
  api: FormApi<FieldName, FormValues, InitialFormValues>;
}

export interface FieldStateChangeEvent<FieldName extends string = string> {
  fieldName: FieldName;
  fieldState: FieldState<any>;
}

export interface FieldProps extends Omit<BaseFieldProps, 'inputState'> {
  config?: FieldConfig<any> | undefined;
  render?: ((props: BaseFieldProps) => React.ReactNode) | undefined;
}

export interface CheckboxFieldProps extends Omit<MuiCheckboxProps, 'checked' | 'value'> {
  config?: FieldConfig<boolean> | undefined;
  error?: boolean | undefined;
  helperText?: React.ReactNode;
  label: React.ReactNode;
  render?: ((props: MuiCheckboxProps) => React.ReactNode) | undefined;
}

export type DatePickerFieldProps = Partial<
  Omit<MuiDatePickerProps, 'value'> & {
    config?: FieldConfig<Date | null> | undefined;
    render?: ((props: MuiDatePickerProps) => React.ReactNode) | undefined;
  }
>;

export type TimePickerFieldProps = Partial<
  Omit<MuiTimePickerProps, 'value'> & {
    config?: FieldConfig<Date | null> | undefined;
    render?: ((props: MuiTimePickerProps) => React.ReactNode) | undefined;
  }
>;

export type DateTimePickerFieldProps = Partial<
  Omit<MuiDateTimePickerProps, 'value'> & {
    config?: FieldConfig<Date | null>;
    render?: (props: MuiDateTimePickerProps) => React.ReactNode;
  }
>;

export interface FormComponentProps<
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>,
  InitialFormValues = FormValues
> extends HtmlFormElementProps {
  children?: React.ReactNode | undefined;
  disabled?: boolean | undefined;
  excludedFields?:
    | ReadonlyArray<FieldName | boolean | null | undefined>
    | Readonly<Partial<Record<FieldName, boolean | null | undefined>>>
    | undefined;
  form?: string | undefined;
  innerRef?: MutableRef<HTMLFormElement> | undefined;
  onFormStateChange?: ((event: FormStateChangeEvent<FieldName, FormValues, InitialFormValues>) => any) | undefined;
  onFieldStateChange?: ((event: FieldStateChangeEvent<FieldName>) => any) | undefined;
  onReady?: ((event: FormReadyEvent<FieldName, FormValues, InitialFormValues>) => any) | undefined;
  onSubmit?: FormConfig<FieldName, FormValues, InitialFormValues>['onSubmit'] | undefined;
  readOnly?: boolean | undefined;
}

export type FormComponentState<
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>,
  InitialFormValues = FormValues
> = Record<FieldName, FieldState<any>> & {
  formState: FormState<FormValues, InitialFormValues>;
  areValidationErrorsVisible: boolean;
};

const DEFAULT_FORM_SUBSCRIPTION: FormSubscription = { hasValidationErrors: true, submitting: true };
const DEFAULT_FIELD_SUBSCRIPTION: FieldSubscription = { error: true, value: true };
const EMPTY_FIELD_SET_LIST: ReadonlyArray<Readonly<[any, ReadonlyArray<any>]>> = [];
const NULL_FIELD_SET_LABEL = null;
const NULL_FIELD_TYPE = null;

const DEFAULT_PROPS_TO_OMIT: ReadonlyArray<keyof Omit<FormComponentProps, keyof HtmlFormElementProps> | 'styleName'> = [
  'children',
  'disabled',
  'excludedFields',
  'form',
  'innerRef',
  'onFormStateChange',
  'onFieldStateChange',
  'onReady',
  'onSubmit',
  'readOnly',
  'styleName'
];

export const memoizedExcludedFieldList = CommonUtils.memoize(
  <FieldName extends string = string>(list: FormComponentProps<FieldName>['excludedFields']): Array<FieldName> => {
    let memoizedList = list;

    if (Utils.isNonArrayObjectLike<Partial<Record<FieldName, boolean>>>(memoizedList)) {
      memoizedList = (Object.keys(memoizedList) as Array<FieldName>).map(
        (fieldName) => (list as Partial<Record<FieldName, boolean>>)[fieldName] === true && fieldName
      );
    } else if (!Utils.isArray<FieldName>(memoizedList)) {
      memoizedList = [];
    }

    return memoizedList.filter(Utils.isString as (value?: any) => value is FieldName);
  }
);

const isFieldStateLike = (value?: any): value is FieldState<any> =>
  Utils.isNonArrayObjectLike<FieldState<any>>(value) &&
  Utils.isString(value.name) &&
  typeof value.blur === 'function' &&
  typeof value.change === 'function' &&
  typeof value.focus === 'function';

export abstract class FormComponent<
  FieldsetName extends string = string,
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>,
  InitialFormValues = FormValues,
  P extends FormComponentProps<FieldName, FormValues, InitialFormValues> = FormComponentProps<
    FieldName,
    FormValues,
    InitialFormValues
  >,
  S extends FormComponentState<FieldName, FormValues, InitialFormValues> = FormComponentState<
    FieldName,
    FormValues,
    InitialFormValues
  >,
  SS = any
> extends React.PureComponent<P, S, SS> {
  // @ts-ignore
  protected readonly propsToOmit: Array<keyof Omit<P, keyof HtmlFormElementProps>> = [...DEFAULT_PROPS_TO_OMIT];

  private _form: FormApi<FieldName, FormValues, InitialFormValues> = undefined!;
  protected readonly formRef: React.MutableRefObject<HTMLFormElement | null> = { current: null };
  protected unsubscribeForm: Unsubscribe | undefined = undefined;
  protected unsubscribeField: Partial<Record<FieldName, Unsubscribe>> = {};

  protected readonly formIdSuffix = getNextId().toString(16);
  protected readonly fieldIdSuffix = getNextId().toString(16);

  private readonly checkboxFieldChangeHandler: { [fieldName: string]: NonNullable<MuiCheckboxProps['onChange']> } = {};
  private readonly dateFieldChangeHandler: { [fieldName: string]: MuiDatePickerProps['onChange'] } = {};
  private readonly timeFieldChangeHandler: { [fieldName: string]: MuiTimePickerProps['onChange'] } = {};
  private readonly dateTimeFieldChangeHandler: { [fieldName: string]: MuiDateTimePickerProps['onChange'] } = {};

  protected constructor(props: P) {
    super(props);

    this.state = {
      areValidationErrorsVisible: false
    } as S;

    this.isFieldIncluded = this.isFieldIncluded.bind(this);
    this.registerField = this.registerField.bind(this);
    this.unregisterField = this.unregisterField.bind(this);
    this.renderField = this.renderField.bind(this);
    this.defaultFieldRenderer = this.defaultFieldRenderer.bind(this);
    this.selectFieldRenderer = this.selectFieldRenderer.bind(this);
    this.checkboxFieldRenderer = this.checkboxFieldRenderer.bind(this);
    this.datePickerFieldRenderer = this.datePickerFieldRenderer.bind(this);
    this.timePickerFieldRenderer = this.timePickerFieldRenderer.bind(this);
    this.dateTimePickerFieldRenderer = this.dateTimePickerFieldRenderer.bind(this);
    this.setFormRef = this.setFormRef.bind(this);
    this.onFormStateChange = this.onFormStateChange.bind(this);
    this.onFieldStateChange = this.onFieldStateChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  protected abstract getFieldConfig(fieldName: FieldName, ...args: any[]): FieldConfig<any> | undefined;

  protected abstract getFieldProps(
    fieldName: FieldName,
    defaultProps: { [prop: string]: any },
    ...args: any[]
  ): Readonly<{ [prop: string]: any }>;

  protected createForm(formConfig: FormConfig<FieldName, FormValues, InitialFormValues>): void {
    const { submit, ...form } = createForm({
      destroyOnUnregister: true,
      ...formConfig,
      onSubmit: this.formSubmitHandler
    });

    this._form = {
      ...form,
      submit: () => {
        if (this.state.formState.hasValidationErrors === true) {
          this.setState(({ areValidationErrorsVisible }) => {
            if (areValidationErrorsVisible) {
              focusFirstInvalidInputElement(this.formRef.current, /* scrollIntoView := */ true);
            }
            return { areValidationErrorsVisible: true } as Pick<S, 'areValidationErrorsVisible'>;
          });
          return;
        }
        return submit();
      }
    };
  }

  protected get form(): FormApi<FieldName, FormValues, InitialFormValues> {
    return this._form;
  }

  protected get formId(): string {
    return Utils.isString(this.props.id) ? this.props.id : `form-${this.formIdSuffix}`;
  }

  protected getFormSubscription(...args: any[]): Readonly<FormSubscription> {
    return DEFAULT_FORM_SUBSCRIPTION;
  }

  protected getFieldSubscription(fieldName: FieldName, ...args: any[]): Readonly<FieldSubscription> {
    return DEFAULT_FIELD_SUBSCRIPTION;
  }

  protected getFieldsetList(...args: any[]): ReadonlyArray<Readonly<[FieldsetName, ReadonlyArray<FieldName>]>> {
    return EMPTY_FIELD_SET_LIST;
  }

  protected getFieldsetLabel(fieldsetName: FieldsetName, ...args: any[]): string | null | undefined {
    return NULL_FIELD_SET_LABEL;
  }

  protected getFieldType(
    fieldName: FieldName,
    ...args: any[]
  ): 'checkbox' | 'date' | 'datetime' | 'select' | 'time' | null {
    return NULL_FIELD_TYPE;
  }

  protected getFieldNameList(
    fieldsetName?: FieldsetName | undefined,
    ...args: any[]
  ): ReadonlyArray<FieldName> | undefined {
    const fieldsetList = this.getFieldsetList(...args);

    const fieldNameList = fieldsetList.reduce((list, [fieldset, fieldNames]) => {
      if (Utils.isNotNil(fieldsetName) && fieldsetName !== fieldset) {
        return list;
      }

      list.push(...fieldNames);
      return list;
    }, [] as Array<FieldName>);

    return fieldNameList;
  }

  protected isFieldIncluded(fieldName: FieldName): boolean {
    const excludedFields = memoizedExcludedFieldList<FieldName>(this.props.excludedFields);
    return !excludedFields.includes(fieldName);
  }

  protected registerField(fieldName: FieldName, ...args: any[]): Unsubscribe | undefined {
    if (!this.isFieldIncluded(fieldName)) return undefined;

    if (process.env.NODE_ENV === 'development') {
      const registeredFields = this.form.getRegisteredFields();
      if (registeredFields.includes(fieldName)) {
        WARN(`WARNING: Attempt to register an already registered field. (FieldName="${fieldName}")`);
      }
    }

    // INFO(`INFO: Registering field "${fieldName}".`);

    return this.form.registerField(
      fieldName,
      (fieldState) => this.setFieldState(fieldName, fieldState),
      this.getFieldSubscription(fieldName),
      this.getFieldConfig(fieldName)
    );
  }

  protected unregisterField(fieldName: FieldName, ...args: any[]): void {
    // INFO(`INFO: Unregistering field "${fieldName}".`);

    if (process.env.NODE_ENV === 'development') {
      const registeredFields = this.form.getRegisteredFields();
      if (!registeredFields.includes(fieldName)) {
        WARN(`WARNING: Attempt to unregister a non-registered field. (FieldName="${fieldName}")`);
      }
    }

    const unsubscribe = this.unsubscribeField[fieldName];
    if (typeof unsubscribe === 'function') {
      unsubscribe();
    } else if (process.env.NODE_ENV === 'development') {
      WARN(`WARNING: Expected <unsubscribe> to be a function. (FieldName="${fieldName}")`);
    }

    this.unsubscribeField = CommonUtils.omit(this.unsubscribeField, fieldName) as typeof this.unsubscribeField;
  }

  public componentDidMount(): void {
    this.unsubscribeForm = this.form.subscribe((formState) => this.setFormState(formState), this.getFormSubscription());

    const fieldsetList = this.getFieldsetList();
    for (const [, fieldNameList] of fieldsetList) {
      for (const fieldName of fieldNameList) {
        const unsubscribe = this.registerField(fieldName);
        if (typeof unsubscribe === 'function') {
          this.unsubscribeField[fieldName] = unsubscribe;
        }
      }
    }

    const { onReady } = this.props;
    if (typeof onReady === 'function') {
      onReady({ api: this.form });
    }
  }

  public componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>): void {
    const { excludedFields } = this.props;
    const { areValidationErrorsVisible } = this.state;

    const prevExcludedFieldList = memoizedExcludedFieldList<FieldName>(prevProps.excludedFields);
    const excludedFieldList = memoizedExcludedFieldList<FieldName>(excludedFields);

    if (prevExcludedFieldList.length > 0 || excludedFieldList.length > 0) {
      const fieldsToUnregister = excludedFieldList.filter((fieldName) => !prevExcludedFieldList.includes(fieldName));

      // unregister fields (if any) which are excluded now but previously weren't
      if (fieldsToUnregister.length > 0) {
        const nextState = this.state as S;
        fieldsToUnregister.forEach((fieldName) => {
          this.unregisterField(fieldName);

          if (isFieldStateLike(this.state[fieldName])) {
            nextState[fieldName] = undefined!;
          } else if (process.env.NODE_ENV === 'development') {
            WARN(`WARNING: Expected <this.state.${fieldName}> to be of shape FieldState.`);
          }
        });

        if (nextState !== this.state) {
          this.setState(nextState);
        }
      }

      // register fields (if any) which were excluded previously but aren't now
      let fieldsToRegister: Array<FieldName> = [];
      if (prevExcludedFieldList.length > 0) {
        let fieldNameList = this.getFieldNameList();
        if (!Utils.isArray<FieldName>(fieldNameList)) fieldNameList = [];

        fieldsToRegister = prevExcludedFieldList.filter(
          (fieldName) => !excludedFieldList.includes(fieldName) && fieldNameList!.includes(fieldName)
        );

        fieldsToRegister.forEach((fieldName) => {
          const unsubscribe = this.registerField(fieldName);
          if (typeof unsubscribe === 'function') {
            this.unsubscribeField[fieldName] = unsubscribe;
          }
        });
      }
    }

    // const { t } = this.props as P & WithTranslation;
    // const { t: prevT } = prevProps as P & WithTranslation;
    // if (t !== prevT) {
    //   (this.form.getRegisteredFields() as Array<FieldName>).forEach((fieldName) =>
    //     this.setFieldState(fieldName, this.form.getFieldState(fieldName)!)
    //   );
    // }

    if (!prevState.areValidationErrorsVisible && areValidationErrorsVisible) {
      focusFirstInvalidInputElement(this.formRef.current, /* scrollIntoView := */ true);
    }
  }

  public componentWillUnmount(): void {
    (this.form.getRegisteredFields() as Array<FieldName>).forEach(this.unregisterField);

    if (typeof this.unsubscribeForm === 'function') {
      this.unsubscribeForm();
    }

    this.unsubscribeForm = undefined;
    this.unsubscribeField = {};
  }

  public render(): React.ReactNode {
    if (Utils.isNil(this.state.formState)) return null;

    return this.renderForm();
  }

  protected renderForm(...args: any[]): React.ReactNode {
    const { children, className } = this.props;
    const { areValidationErrorsVisible } = this.state;

    const rootProps = CommonUtils.omit(this.props, this.propsToOmit);
    const formClassName = clsx(areValidationErrorsVisible && 'was-validated', className);

    return (
      <form
        {...rootProps}
        id={this.formId}
        className={formClassName}
        ref={this.setFormRef}
        onSubmit={this.onFormSubmit}
      >
        {this.renderFieldsetList(this.getFieldsetList(), ...args)}
        {children}
      </form>
    );
  }

  protected renderFieldsetList(
    fieldsetList: ReadonlyArray<Readonly<[FieldsetName, ReadonlyArray<FieldName>]>>,
    ...args: any[]
  ): Array<React.ReactNode> {
    return CommonUtils.filterMap(fieldsetList, ([fieldsetName]) => {
      const fieldsetNode = this.renderFieldset(fieldsetName, ...args);
      return Utils.isNotNil(fieldsetNode) && fieldsetNode;
    });
  }

  protected renderFieldset(fieldsetName: FieldsetName, ...args: any[]): React.ReactNode {
    const fieldNameList = this.getFieldNameList(fieldsetName)?.filter(this.isFieldIncluded);

    if (Utils.isNonEmptyArray(fieldNameList)) {
      const fieldNodeList = fieldNameList
        .map((fieldName) => this.renderField(fieldName, ...args))
        .filter((node) => Utils.isNotNil(node) && node !== false);

      if (fieldNodeList.length > 0) {
        const fieldsetLabel = this.getFieldsetLabel(fieldsetName);
        return (
          <fieldset key={fieldsetName} name={fieldsetName} className={fieldsetName}>
            {fieldsetLabel && <legend>{fieldsetLabel}</legend>}
            {fieldNodeList}
          </fieldset>
        );
      }
    }

    return null;
  }

  protected renderField(fieldName: FieldName, ...args: any[]): React.ReactNode {
    if (!this.form.getRegisteredFields().includes(fieldName)) return null;

    const { formState } = this.state;
    if (Utils.isNil(formState)) return null;

    const inputState: FieldState<any> = this.state[fieldName]!;
    if (Utils.isNil(inputState)) return null;

    const disabled = this.props.disabled === true;
    const readOnly = this.props.readOnly === true;
    const hasErrorMessage = Utils.isString(inputState.error);

    const defaultProps: { [prop: string]: any } = {
      disabled: formState.submitting || disabled,
      error: hasErrorMessage,
      helperText: hasErrorMessage && inputState.error,
      id: this.formId.concat('-', fieldName, '-', this.fieldIdSuffix),
      inputProps: { form: this.props.form, readOnly },
      name: fieldName,
      onBlur: inputState.blur,
      onFocus: inputState.focus
    };

    const fieldType = this.getFieldType(fieldName, ...args);
    if (fieldType === 'checkbox') {
      defaultProps.checked = inputState.value === true;
      defaultProps.color = 'primary';
      defaultProps.readOnly = readOnly;

      let eventHandler = this.checkboxFieldChangeHandler[fieldName];
      if (!eventHandler) {
        eventHandler = this.onChangeCheckboxField.bind(this, fieldName);
        this.checkboxFieldChangeHandler[fieldName] = eventHandler;
      }

      defaultProps.onChange = eventHandler;
      defaultProps.render = this.checkboxFieldRenderer;
    } else if (fieldType === 'date') {
      defaultProps.inputVariant = 'outlined';
      defaultProps.InputLabelProps = { shrink: inputState.value instanceof Date };
      defaultProps.openTo = Utils.isValidDate(inputState.value) ? 'date' : 'year';
      defaultProps.readOnly = readOnly;
      defaultProps.value = inputState.value;

      if (disabled || readOnly) {
        defaultProps.InputAdornmentProps = {
          'aria-hidden': true,
          style: { display: 'none' }
        };
      }

      let eventHandler = this.dateFieldChangeHandler[fieldName];
      if (!eventHandler) {
        eventHandler = this.onChangeDateField.bind(this, fieldName);
        this.dateFieldChangeHandler[fieldName] = eventHandler;
      }

      defaultProps.onChange = eventHandler;
      defaultProps.render = this.datePickerFieldRenderer;
    } else if (fieldType === 'time') {
      defaultProps.ampm = false;
      defaultProps.inputVariant = 'outlined';
      defaultProps.InputLabelProps = { shrink: inputState.value instanceof Date };
      defaultProps.readOnly = readOnly;
      defaultProps.value = inputState.value;

      if (disabled || readOnly) {
        defaultProps.InputAdornmentProps = {
          'aria-hidden': true,
          style: { display: 'none' }
        };
      }

      let eventHandler = this.timeFieldChangeHandler[fieldName];
      if (!eventHandler) {
        eventHandler = this.onChangeTimeField.bind(this, fieldName);
        this.timeFieldChangeHandler[fieldName] = eventHandler;
      }

      defaultProps.onChange = eventHandler;
      defaultProps.render = this.timePickerFieldRenderer;
    } else if (fieldType === 'datetime') {
      defaultProps.ampm = false;
      defaultProps.InputLabelProps = { shrink: inputState.value instanceof Date };
      defaultProps.openTo = Utils.isValidDate(inputState.value) ? 'date' : 'year';
      defaultProps.readOnly = readOnly;
      defaultProps.value = inputState.value;

      if (disabled || readOnly) {
        defaultProps.InputAdornmentProps = {
          'aria-hidden': true,
          style: { display: 'none' }
        };
      }

      let eventHandler = this.dateTimeFieldChangeHandler[fieldName];
      if (!eventHandler) {
        eventHandler = this.onChangeDateTimeField.bind(this, fieldName);
        this.dateTimeFieldChangeHandler[fieldName] = eventHandler;
      }

      defaultProps.onChange = eventHandler;
      defaultProps.render = this.dateTimePickerFieldRenderer;
    } else if (fieldType === 'select') {
      defaultProps.inputState = inputState;
      defaultProps.variant = 'outlined';

      defaultProps.render = this.selectFieldRenderer;
    } else {
      defaultProps.inputState = inputState;
      defaultProps.value = inputState.value;
      defaultProps.variant = 'outlined';

      defaultProps.render = this.defaultFieldRenderer;
    }

    const { config, render, ...fieldProps } = this.getFieldProps(fieldName, defaultProps, ...args);
    return typeof render === 'function' && render(fieldProps);
  }

  private getInputProps<T extends Pick<InputBaseProps, 'disabled' | 'inputProps'>>(
    props: T
  ): InputBaseComponentProps | undefined {
    if (Utils.isNonArrayObjectLike<T>(props)) {
      const { inputProps: baseInputProps } = props;
      const inputProps = { ...baseInputProps };
      if (Utils.isNonArrayObjectLike<InputBaseComponentProps>(baseInputProps)) {
        if (!CommonUtils.has(inputProps, 'aria-readonly')) {
          inputProps['aria-readonly'] = inputProps.readOnly === true;
        }

        if (!CommonUtils.has(inputProps, 'aria-disabled')) {
          inputProps['aria-disabled'] = props.disabled === true;
        }
      }
      return inputProps;
    }
    return undefined;
  }

  protected defaultFieldRenderer(fieldProps: BaseFieldProps): React.ReactNode {
    const inputProps = this.getInputProps(fieldProps);
    return <Field {...fieldProps} inputProps={inputProps} />;
  }

  protected selectFieldRenderer(fieldProps: SelectFieldProps<unknown, boolean>): React.ReactNode {
    const inputProps = this.getInputProps(fieldProps);
    return <SelectField {...fieldProps} inputProps={inputProps} />;
  }

  protected checkboxFieldRenderer({
    error,
    helperText,
    label,
    ...props
  }: MuiCheckboxProps & Pick<CheckboxFieldProps, 'error' | 'helperText' | 'label'>): React.ReactNode {
    const inputProps = this.getInputProps(props);
    return (
      <React.Fragment>
        <FormControlLabel control={<Checkbox {...props} inputProps={inputProps} />} label={label} />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </React.Fragment>
    );
  }

  protected datePickerFieldRenderer(fieldProps: MuiDatePickerProps): React.ReactNode {
    const inputProps = this.getInputProps(fieldProps);
    return <DatePickerField {...fieldProps} inputProps={inputProps} />;
  }

  protected timePickerFieldRenderer(fieldProps: MuiTimePickerProps): React.ReactNode {
    const inputProps = this.getInputProps(fieldProps);
    return <TimePickerField {...fieldProps} inputProps={inputProps} />;
  }

  protected dateTimePickerFieldRenderer(fieldProps: MuiDateTimePickerProps): React.ReactNode {
    const inputProps = this.getInputProps(fieldProps);
    return <DateTimePickerField {...fieldProps} inputProps={inputProps} />;
  }

  protected setFormRef(instance: HTMLFormElement | null): void {
    const { innerRef } = this.props;

    this.formRef.current = instance;

    if (typeof innerRef === 'function') {
      innerRef(instance);
    } else if (Utils.isNonArrayObjectLike(innerRef)) {
      innerRef.current = instance;
    }
  }

  protected setFormState(formState: FormState<FormValues, InitialFormValues>): void {
    this.setState({ formState } as Pick<S, 'formState'>, () => this.onFormStateChange({ formState }));
  }

  protected setFieldState(fieldName: FieldName, fieldState: FieldState<any>): void {
    this.setState({ [fieldName]: fieldState } as Pick<S, FieldName>, () => {
      this.onFieldStateChange({ fieldName, fieldState });
    });
  }

  protected onChangeCheckboxField(
    fieldName: FieldName,
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    event.stopPropagation();

    if (event.target.readOnly) return;
    this.state[fieldName].change(checked === true);
  }

  protected onChangeDateField(fieldName: FieldName, value: Date | null): void {
    this.state[fieldName].change(value);
  }

  protected onChangeTimeField(fieldName: FieldName, value: Date | null): void {
    this.state[fieldName].change(value);
  }

  protected onChangeDateTimeField(fieldName: FieldName, value: Date | null): void {
    this.state[fieldName].change(value);
  }

  protected onFormStateChange(event: FormStateChangeEvent<FieldName, FormValues, InitialFormValues>): void {
    const { onFormStateChange: formStateChangeHandler } = this.props;

    if (typeof formStateChangeHandler === 'function') {
      formStateChangeHandler(event);
    }
  }

  protected onFieldStateChange(event: FieldStateChangeEvent<FieldName>): void {
    const { onFieldStateChange: fieldStateChangeHandler } = this.props;

    if (typeof fieldStateChangeHandler === 'function') {
      fieldStateChangeHandler(event);
    }
  }

  protected onFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    event.stopPropagation();

    this.form.submit();
  }

  protected formSubmitHandler(
    values: FormValues
  ): ReturnType<NonNullable<FormConfig<FieldName, FormValues, InitialFormValues>['onSubmit']>> {
    const { onSubmit } = this.props;
    if (typeof onSubmit === 'function') {
      return onSubmit(values);
    }
  }
}
