import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import React from 'react';
import { useSelector } from 'react-redux';
import { batchQueryDataAction } from '../actions/batch-query-data-action';
import { authClaimSelector } from '../selectors/auth';
import { useAppState } from './use-app-state';

export interface UseDispatchFetchObjectsParams extends Omit<Api.BatchQueryRequestData, 'authState'> {
  authState?: string | undefined;
}

export const useDispatchFetchObjects = () => {
  const [, appDispatch] = useAppState();
  const authClaim = useSelector(authClaimSelector);

  return React.useCallback(
    (query: UseDispatchFetchObjectsParams, cache?: ApiActionPayload.BatchQueryData['cache']) => {
      let authState: string = undefined!;
      if (Utils.isString(query.authState)) authState = query.authState;
      else if (query.authState !== null) authState = authClaim;

      // return new Promise<Api.BatchQueryResponseData>((resolve, reject) => {
      //   setTimeout(() => {
      //     dispatch(batchQueryDataAction({ query: { authState, ...query } })).then(resolve, reject);
      //   }, 2500);
      // });
      return appDispatch(batchQueryDataAction({ query: { authState, ...query }, cache }));
    },
    [authClaim, appDispatch]
  );
};
