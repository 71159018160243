import { Utils } from '@sigmail/common';
import React from 'react';
import { MutableRef } from 'sigmail';
import { IAudioTrack } from './types';

export interface Props {
  children?: never;
  innerRef?: MutableRef<HTMLAudioElement>;
  track: IAudioTrack;
}

function attachElement(el: HTMLAudioElement, track: IAudioTrack): void {
  el.setAttribute('data-cy-audio-track-name', track.name);
  document.body.appendChild(el);
}

function detachElement(el: HTMLAudioElement, track: IAudioTrack): void {
  track.detach(el);
  el.remove();
  el.srcObject = null;
}

class AudioTrackComponent extends React.PureComponent<Props> {
  private ref: React.MutableRefObject<HTMLAudioElement | null> = { current: null };

  public constructor(props: Props) {
    super(props);

    this.componentDidUpdate = Utils.noop;
    this.setRef = this.setRef.bind(this);
  }

  public componentDidMount(): void {
    const { track } = this.props;

    this.setRef(track.attach() as HTMLAudioElement);
  }

  public getSnapshotBeforeUpdate(prevProps: Readonly<Props>): any {
    const { track } = this.props;
    const { track: prevTrack } = prevProps;

    const { current: el } = this.ref;
    if (prevTrack !== track && el !== null) {
      detachElement(el, prevTrack);
      attachElement(el, track);
    }

    return null;
  }

  public componentWillUnmount(): void {
    this.setRef(null);
  }

  public render(): React.ReactNode {
    return null;
  }

  protected setRef(instance: HTMLAudioElement | null): void {
    const { track, innerRef } = this.props;
    const { current: prevInstance } = this.ref;

    this.ref.current = instance;

    if (instance === null) {
      if (prevInstance !== null) {
        detachElement(prevInstance, track);
      }
    } else if (instance !== prevInstance) {
      attachElement(instance, track);
    }

    if (typeof innerRef === 'function') {
      innerRef(instance);
    } else if (typeof innerRef === 'object' && innerRef != null) {
      innerRef.current = instance;
    }
  }
}

export const AudioTrack = React.forwardRef<HTMLAudioElement, Props>((props, ref) => (
  <AudioTrackComponent innerRef={ref} {...props} />
));

AudioTrack.displayName = 'AudioTrack';
