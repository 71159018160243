/* eslint-disable no-unused-vars */

import type {
  AlgorithmCode,
  EncapsulatedKey,
  Nullable,
  SigmailKeyId,
  SigmailObjectId,
  SigmailObjectTypeCode
} from '@sigmail/common';
import { AppException, Constants, Utils } from '@sigmail/common';
import { Algorithm, Constants as CryptoConstants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import { CryptographicKey } from '.';
import type { ICryptographicKeyEncapsulated, ISigmailObject, ValueFormatVersion } from '../types';

const ErrorConstants = { ...Constants.Error, ...CryptoConstants.Error };

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_ENCAPSULATED;

/** @public */
export class CryptographicKeyEncapsulated
  extends CryptographicKey<EncapsulatedKey>
  implements ICryptographicKeyEncapsulated
{
  protected static override get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_ENCAPSULATED_KEY as AlgorithmCode;
  }

  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  public static override isAssignableFrom(value: unknown): value is ICryptographicKeyEncapsulated {
    if (!super.isAssignableFrom(value)) return false;

    const obj = value as ICryptographicKeyEncapsulated;
    return typeof obj.encryptFor === 'function';
  }

  public static async createForObject<DV extends ValueFormatVersion>(
    obj: ISigmailObject<DV>,
    encryptedFor: SigmailKeyId,
    createdAtUtc: Date
  ): Promise<CryptographicKeyEncapsulated | null> {
    const { id: keyId, code: objectCode } = obj;

    if (Algorithm.isValidNonEncryptedObjectCode(objectCode)) {
      return null;
    }

    if (!Algorithm.isValidEncryptObjectCode(objectCode)) {
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    }

    if (!this.isValidId(encryptedFor)) {
      throw new AppException(ErrorConstants.S_ERROR, 'Invalid ID. (Parameter: encryptedFor)');
    }

    const algorithm = getAlgorithm(objectCode);
    const key = await algorithm.generateKey();

    return this.create(keyId, undefined, 0, key, encryptedFor, createdAtUtc);
  }

  public static async create(
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number,
    key: EncapsulatedKey,
    encryptedFor: SigmailKeyId,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  ): Promise<CryptographicKeyEncapsulated> {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;
    const value = await this.encryptEncapsulatedKey(key, { encryptedFor, keyCode, keyVersion: version });

    const args = [id, keyCode, version, value, encryptedFor, createdAtUtc, expiredAtUtc];
    return Reflect.construct(this, args) as CryptographicKeyEncapsulated;
  }

  public static async encryptFor(key: null, id: SigmailKeyId, createdAtUtc?: Date): Promise<null>;

  public static async encryptFor(
    key: ICryptographicKeyEncapsulated,
    id: SigmailKeyId,
    createdAtUtc?: Date
  ): Promise<ICryptographicKeyEncapsulated>;

  public static async encryptFor(
    key: unknown,
    id: SigmailKeyId,
    createdAtUtc?: Date
  ): Promise<ICryptographicKeyEncapsulated | null> {
    if (key === null) return null;

    const { id: keyId, createdAtUtc: dtCreate } = key as ICryptographicKeyEncapsulated;
    const encapsulatedKey = await (key as ICryptographicKeyEncapsulated).decryptedValue();

    return this.create(keyId, undefined, 0, encapsulatedKey, id, Utils.dateOrDefault(createdAtUtc, dtCreate));
  }

  public encryptFor(id: SigmailKeyId, createdAtUtc?: Date): Promise<ICryptographicKeyEncapsulated> {
    const Class = this.constructor as typeof CryptographicKeyEncapsulated;
    return Class.encryptFor(this, id, createdAtUtc);
  }

  public decryptedValue(): Promise<EncapsulatedKey> {
    if (!Algorithm.isValidEncryptEncapsulatedKeyCode(this.code)) {
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    }

    const algorithm = getAlgorithm(this.code);
    const Class = this.constructor as typeof CryptographicKeyEncapsulated;
    const cryptoKey = Class.getPrivateKey(this.encryptedForId);

    return algorithm.decrypt({ privateKey: cryptoKey }, this.value, this.version);
  }
}
