import { Utils } from '@sigmail/common';
import { isCarePlanDateLike } from './is-care-plan-date-like';

export function fromCarePlanDate(value: unknown): Date | null {
  let dt: Date | null = null;
  if (isCarePlanDateLike(value)) {
    dt = new Date(value.Y!, value.M! - 1, value.D);
  }
  return Utils.isValidDate(dt) ? dt : null;
}
