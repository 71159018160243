import { E_FAIL, E_FAIL_RNG } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';
import type { RandomNumberGenerator } from '../types';

type TypedArray =
  | Int8Array
  | Int16Array
  | Int32Array
  | Uint8Array
  | Uint8ClampedArray
  | Uint16Array
  | Uint32Array
  | Float32Array
  | Float64Array;

// eslint-disable-next-line no-unused-vars
type TypedArrayConstructor<T extends TypedArray> = new (length: number) => T;

function getRandomValues<T extends TypedArray>(TypedArrayClass: TypedArrayConstructor<T>, length: number): T {
  if (typeof crypto?.getRandomValues !== 'function') {
    throw new SigmailCryptoException(E_FAIL, 'Not supported.');
  }

  try {
    return crypto.getRandomValues(new TypedArrayClass(length));
  } catch {
    throw new SigmailCryptoException(E_FAIL_RNG);
  }
}

function createRNG<T extends TypedArray>(
  name: string,
  TypedArrayClass: TypedArrayConstructor<T>
): RandomNumberGenerator<T> {
  return Object.assign(getRandomValues.bind<null, typeof TypedArrayClass, Array<number>, T>(null, TypedArrayClass), {
    NAME: name
  });
}

/** @public */
export const Int8 = createRNG('Int8', Int8Array);

/** @public */
export const Int16 = createRNG('Int16', Int16Array);

/** @public */
export const Int32 = createRNG('Int32', Int32Array);

/** @public */
export const Uint8 = createRNG('Uint8', Uint8Array);

/** @public */
export const Uint8Clamped = createRNG('Uint8Clamped', Uint8ClampedArray);

/** @public */
export const Uint16 = createRNG('Uint16', Uint16Array);

/** @public */
export const Uint32 = createRNG('Uint32', Uint32Array);

/** @public */
export const Float32 = createRNG('Float32', Float32Array);

/** @public */
export const Float64 = createRNG('Float64', Float64Array);
