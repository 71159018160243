import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['grid']['selectPatientRecord'] = {
  columnHeader: {
    // Translators: Label for the Birth Date column header of Select EMR \
    // Patient Record grid
    birthDate: gettext('global', 'birthDate', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Cell Phone column header of Select EMR \
    // Patient Record grid
    cellPhone: gettext('global', 'cellPhone', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the First Name column header of Select EMR \
    // Patient Record grid
    firstName: gettext('global', 'firstName', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Full Name column header of Select EMR \
    // Patient Record grid
    fullName: gettext('global', 'fullName', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Gender column header of Select EMR Patient \
    // Record grid
    gender: gettext('global', 'gender', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Health Insurance column header of Select \
    // EMR Patient Record grid
    healthInsurance: gettext('global', 'healthInsurance', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Health Plan Jurisdiction column header of \
    // Select EMR Patient Record grid
    healthPlanJurisdiction: gettext('global', 'healthPlanJurisdiction', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Health Plan Number column header of Select \
    // EMR Patient Record grid
    healthPlanNumber: gettext('global', 'healthPlanNumber', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Home Phone column header of Select EMR \
    // Patient Record grid
    homePhone: gettext('global', 'homePhone', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Last Name column header of Select EMR \
    // Patient Record grid
    lastName: gettext('global', 'lastName', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Last Name column header of Select EMR \
    // Patient Record grid
    phone: gettext('global', 'phone', 'selectEMRPatientRecordGridColumnHeader'),
    // Translators: Label for the Record Source column header of Select \
    // EMR Patient Record grid
    source: gettext('global', 'recordSource', 'selectEMRPatientRecordGridColumnHeader')
  },
  localeText: {
    // Translators: Message, for screen readers, explaining which key can be \
    // pressed to deselect a row in the Select EMR Patient Record grid
    ariaRowDeselect: gettext('global', 'ariaRowDeselect', 'selectEMRPatientRecordGridLocaleText'),
    // Translators: Message, for screen readers, explaining which key can be \
    // pressed to select a row in the Select EMR Patient Record grid
    ariaRowSelect: gettext('global', 'ariaRowSelect', 'selectEMRPatientRecordGridLocaleText'),
    // Translators: Message, for screen readers, explaining which key can be \
    // pressed to toggle all rows selection in the Select EMR Patient Record grid
    ariaRowSelectAll: gettext('global', 'ariaRowSelectAll', 'selectEMRPatientRecordGridLocaleText'),
    // Translators: Message, for screen readers, explaining which key can be \
    // pressed to toggle a single row selection in the Select EMR Patient \
    // Record grid
    ariaRowToggleSelection: gettext('global', 'ariaRowToggleSelection', 'selectEMRPatientRecordGridLocaleText'),
    // Translators: Message displayed in the grid when there are no rows \
    // available to show in the Select EMR Patient Record grid
    noRowsToShow: gettext('global', 'noRowsToShow', 'selectEMRPatientRecordGridLocaleText')
  }
};

export default i18n;
