import { DataObject, IDataObject, ValueFormatVersion } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { DataObjectCache } from '../data-objects-slice/cache';
import { dataObjectMapSelector } from '../selectors/data-object';

export const useDataObjectByIdSelector = () => {
  const dataObjectMap = useSelector(dataObjectMapSelector);

  return React.useCallback(
    <DV extends ValueFormatVersion>(objectId: number) => {
      if (!DataObject.isValidId(objectId)) return undefined;

      const objectIdKey = objectId.toString(10);
      return dataObjectMap.has(objectIdKey) ? DataObjectCache.find<IDataObject<DV>>(objectId)?.[0] : undefined;
    },
    [dataObjectMap]
  );
};
