import { SchedulingI18n } from "@sigmail/i18n";
import { gettext } from "..";
import { DialogMeetingRoom } from "../../constants/action-context";

const i18n: SchedulingI18n.Dialog.MeetingRoom = {
  // Translators: (HTML) Title for the Meeting Room dialog
  title: gettext('scheduling', 'meetingRoomDialog', 'title', 'safe-html'),
  // Translators: ARIA label for a participant whose audio is turned off \
  // (for screen readers)
  ariaLabelMuted: gettext('scheduling', 'meetingRoomDialog', 'ariaLabelAudioMuted'),
  action: {
    toggleAudioOff: {
      // Translators: Label for the Toggle Audio Off action
      label: gettext('scheduling', 'meetingRoomDialog', 'actionLabelToggleAudioOff'),
      context: DialogMeetingRoom
    },
    toggleAudioOn: {
      // Translators: Label for the Toggle Audio On action
      label: gettext('scheduling', 'meetingRoomDialog', 'actionLabelToggleAudioOn'),
      context: DialogMeetingRoom
    },
    toggleVideoOff: {
      // Translators: Label for the Toggle Video Off action
      label: gettext('scheduling', 'meetingRoomDialog', 'actionLabelToggleVideoOff'),
      context: DialogMeetingRoom
    },
    toggleVideoOn: {
      // Translators: Label for the Toggle Video On action
      label: gettext('scheduling', 'meetingRoomDialog', 'actionLabelToggleVideoOn'),
      context: DialogMeetingRoom
    },
    leaveMeeting: {
      // Translators: Label for the Leave Meeting action
      label: gettext('scheduling', 'meetingRoomDialog', 'actionLabelLeaveMeeting'),
      context: DialogMeetingRoom
    }
  }
};

export default i18n;
