import { Constants, Utils } from '@sigmail/common';

const SEVERITY_ERROR = Constants.Error.SEVERITY_ERROR;
const FACILITY_CRYPTO = Constants.Error.FACILITY_CRYPTO;

/** Generic error code for Cryptography related exceptions. */
export const E_FAIL = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 0);

/** Generic error code for Cryptography related exceptions. */
export const E_METHOD_NOT_IMPL = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 1);

/** Error code to indicate the given key is invalid. */
export const E_INVALID_KEY = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 2);

/** Error code to indicate that the key required for an operation is missing. */
export const E_KEY_MISSING = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 3);

/** Error code to indicate the given algorithm code is unknown. */
export const E_UNKNOWN_ALGORITHM_CODE = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 4);

/** Error code to indicate the given algorithm is of unexpected type. */
export const E_UNEXPECTED_ALGORITHM = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 5);

/** Error code to indicate that an error occurred while generating a key. */
export const E_FAIL_GENERATE_KEY = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 6);

/** Error code to indicate that an error occurred while deriving a key. */
export const E_FAIL_DERIVE_KEY = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 7);

/** Error code to indicate that an error occurred while importing a key. */
export const E_FAIL_IMPORT_KEY = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 8);

/** Error code to indicate that an error occurred while exporting a key. */
export const E_FAIL_EXPORT_KEY = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 9);

/** Error code to indicate that an error occurred while encrypting data. */
export const E_FAIL_ENCRYPT = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 10);

/** Error code to indicate that an error occurred while decrypting data. */
export const E_FAIL_DECRYPT = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 11);

/** Error code to indicate that an error occurred while generating a digest. */
export const E_FAIL_DIGEST = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 12);

/** Error code to indicate that an error occurred while generating random values. */
export const E_FAIL_RNG = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_CRYPTO, 13);
