import type { SigmailObjectTypeCode } from '@sigmail/common';
import { AppException, Constants, Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectHrmSenderListValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_HRM_SENDER_LIST;

/** @public */
export class DataObjectHrmSenderList extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.list);
  }

  public updateValue(_newValue: DV): Promise<DataObjectHrmSenderList> {
    //
    // XXX this object is updated by Admin Portal and never by the client-side
    //

    // const Class = this.constructor as typeof DataObjectHrmSenderList;
    // return Class.updateValue(this, newValue);
    throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE);
  }
}
