import Base64Enc from 'crypto-js/enc-base64';
import HexEnc from 'crypto-js/enc-hex';
import SHA512 from 'crypto-js/sha512';

export function sha512Hash(data: string, encoding: 'hex' | 'base64' = 'hex'): string {
  if (typeof data !== 'string') {
    throw new TypeError('Argument <data> must be of type string.');
  }

  const enc = encoding || 'hex';
  if (enc !== 'hex' && enc !== 'base64') {
    throw new TypeError("Argument <encoder> must be either 'hex' or 'base64'.");
  }

  const encoder = enc === 'hex' ? HexEnc : Base64Enc;
  return encoder.stringify(SHA512(data));
}
