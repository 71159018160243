import { AccountI18n } from '@sigmail/i18n';
import accountToolbarAction from './account-toolbar-action';
import dialogChangePassword from './dialog-change-password';
import dialogEditAccuroEMRProviderId from './dialog-edit-accuro-emr-provider-id';
import dialogEditMessageSignature from './dialog-edit-message-signature';
import dialogEditProfile from './dialog-edit-profile';
import dialogEmrConfiguration from './dialog-emr-configuration';
import dialogSendCaregiverAccountInvitation from './dialog-send-caregiver-account-invitation';
import dialogSendCaregiverAccountReachedLimit from './dialog-send-caregiver-account-reached-limit';
import dialogSendGuestAccountInvitation from './dialog-send-guest-account-invitation';
import dialogSendNonGuestAccountInvitation from './dialog-send-non-guest-account-invitation';
import dialogVerifyIdentity from './dialog-verify-identity';
import dialogWorkSchedule from './dialog-work-schedule';
import formEditProfile from './form-edit-profile';
import formEmrConfiguration from './form-emr-configuration';
import formNewPassword from './form-new-password';
import formSelectEMR from './form-select-emr';
import formSendCaregiverAccountInvitation from './form-send-caregiver-account-invitation';
import formSendGuestAccountInvitation from './form-send-guest-account-invitation';
import formSendNonGuestAccountInvitation from './form-send-non-guest-account-invitation';
import formWorkSchedule from './form-work-schedule';
import viewAccountIntegrations from './view-account-integrations';
import viewAccountPreferences from './view-account-preferences';
import viewAccountSchedule from './view-account-schedule';
import viewAccountSecurity from './view-account-security';
import viewActivity from './view-activity';
import viewUserProfile from './view-user-profile';

const i18n: AccountI18n.Root = {
  accountToolbarAction,
  dialog: {
    changePassword: dialogChangePassword,
    editAccuroEMRProviderId: dialogEditAccuroEMRProviderId,
    editMessageSignature: dialogEditMessageSignature,
    editProfile: dialogEditProfile,
    emrConfiguration: dialogEmrConfiguration,
    sendCaregiverAccountInvitation: dialogSendCaregiverAccountInvitation,
    sendCaregiverAccountReachedLimit: dialogSendCaregiverAccountReachedLimit,
    sendGuestAccountInvitation: dialogSendGuestAccountInvitation,
    sendNonGuestAccountInvitation: dialogSendNonGuestAccountInvitation,
    workSchedule: dialogWorkSchedule,
    verifyIdentity: dialogVerifyIdentity
  },
  form: {
    editProfile: formEditProfile,
    emrConfiguration: formEmrConfiguration,
    newPassword: formNewPassword,
    selectEMR: formSelectEMR,
    sendCaregiverAccountInvitation: formSendCaregiverAccountInvitation,
    sendGuestAccountInvitation: formSendGuestAccountInvitation,
    sendNonGuestAccountInvitation: formSendNonGuestAccountInvitation,
    workSchedule: formWorkSchedule
  },
  view: {
    activity: viewActivity,
    integrations: viewAccountIntegrations,
    preferences: viewAccountPreferences,
    schedule: viewAccountSchedule,
    security: viewAccountSecurity,
    userProfile: viewUserProfile
  }
};

export default i18n;
