import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Constants, Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectProfileBasicValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_PROFILE_BASIC;

/** @public */
export class UserObjectProfileBasic extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    const { firstName, lastName, role: roleId } = dv;
    return Utils.isString(firstName) && Utils.isString(lastName) && Constants.ROLE_ID_LIST.includes(roleId);
  }

  public override updateValue(newValue: DV): Promise<UserObjectProfileBasic> {
    const Class = this.constructor as typeof UserObjectProfileBasic;
    return Class.updateValue(this, newValue);
  }
}
