import { Utils } from '@sigmail/common';

/** @public */
export function decode(value: string): Uint8Array {
  return Utils.uint8ArrayFromHex(value);
}

/** @public */
export function encode(bytes: Uint8Array): string {
  return Utils.uint8ArrayToHex(bytes);
}
