import { getLoggerWithPrefix } from '@sigmail/logging';
import { CaseReducerSetUserObjectsState, StoreStateUserObjects } from '@sigmail/app-state';

const Logger = getLoggerWithPrefix('Reducer', 'setUserObjectsStateReducer:');

export const setStateReducer: CaseReducerSetUserObjectsState = (
  state,
  { payload: nextState }
): StoreStateUserObjects => {
  try {
    Logger.info('== BEGIN ==');
    // @ts-ignore TS2322
    return state.equals(nextState) ? state : nextState;
  } finally {
    Logger.info('== END ==');
  }
};
