import { Avatar, Badge } from '@material-ui/core';
import { Nullable, Utils } from '@sigmail/common';
import React from 'react';
import ProMemberBadge from '../../assets/images/badge-pro-member.min.svg';
import StandardMemberBadge from '../../assets/images/badge-std-member.min.svg';
import { PersonNameAvatar } from './person-name-avatar.component';

export interface Props {
  avatar?: string;
  backgroundColor?: string;
  children?: never;
  className?: string;
  firstName?: string;
  lastName?: string;
  license?: Nullable<string>;
}

const LicenseBadgeAvatar = React.memo((({ license }) => (
  <Avatar
    alt=""
    role="presentation"
    aria-hidden={true}
    src={Utils.isProLicense(license) ? ProMemberBadge : StandardMemberBadge}
  />
)) as React.FC<{ license: string }>);

const MemberAvatarComponent: React.FC<Props> = ({
  avatar,
  backgroundColor,
  children,
  className,
  firstName,
  lastName,
  license
}) => {
  const trimmedAvatar = Utils.trimOrDefault(avatar);
  const isNonGuestLicense = Utils.isProLicense(license) || Utils.isStandardLicense(license);

  let memberAvatar: React.ReactElement;
  if (trimmedAvatar.length > 0) {
    memberAvatar = <Avatar alt="" aria-hidden={true} className={className} role="presentation" src={trimmedAvatar} />;
  } else {
    memberAvatar = (
      <PersonNameAvatar
        backgroundColor={backgroundColor}
        className={className}
        firstName={Utils.stringOrDefault(isNonGuestLicense && firstName)}
        lastName={Utils.stringOrDefault(isNonGuestLicense && lastName)}
      />
    );
  }

  if (isNonGuestLicense) {
    return (
      <Badge
        overlap="circle"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        badgeContent={<LicenseBadgeAvatar license={license} />}
      >
        {memberAvatar}
      </Badge>
    );
  }

  return memberAvatar;
};

export const MemberAvatar = React.memo(MemberAvatarComponent);
MemberAvatar.displayName = 'MemberAvatar';
