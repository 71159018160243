import { Nullable, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';

export const selectFullName = (record: Nullable<Api.AccuroEMRPersonNameDTO>): Api.AccuroEMRPersonNameDTO | null =>
  Utils.isNonArrayObjectLike<Api.AccuroEMRPersonNameDTO>(record)
    ? Utils.mapValues(
        {
          firstName: record.firstName,
          lastName: record.lastName,
          middleName: record.middleName,
          title: record.title,
          suffix: record.suffix
        },
        (value) => (Utils.isString(value) ? value : null)
      )
    : null;
