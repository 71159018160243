import {
  AppUser,
  Nullable,
  SigmailClientId,
  SigmailGroupId,
  SigmailObjectId,
  SigmailUserId,
  Utils
} from '@sigmail/common';
import { DataObjectEventLogValue, EventLogRecord, UserObjectEventLogValue, ValueFormatVersion } from '@sigmail/objects';
import { enCA, frCA } from 'date-fns/locale';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { FrenchCanada } from '../../constants/language-codes';
import { useTranslation } from '../../i18n';
import { createDateRange, CreateDateRangeOptions } from '../../utils/date/create-date-range';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { eventLogObjectSelector as selectEventLogObject } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';
import { DataObjectResource, useDataObjectResource } from './use-data-object-resource';
import { useDispatchFetchServerDateTime } from './use-dispatch-fetch-server-date-time';
import { useDispatchFetchUserObjects } from './use-dispatch-fetch-user-objects';

type EventLogClassKey = Exclude<keyof UserObjectEventLogValue, keyof ValueFormatVersion>;

export type EventLogDataResource = ISuspenseResource<EventLogDataResourceValue>;

export type EventLogDataResourceValue = [
  list: Array<DataObjectEventLogValue['list'][0]>,
  tsExtent: [min: number, max: number]
];

type Params = UseEventLogDataResourceParams;

type ResolvedParams = {
  [K in Exclude<keyof Params, `${string}Date`>]: K extends 'dateRange'
    ? [minTime: number, maxTime: number]
    : Exclude<NonNullable<Params[K]>, false> | undefined;
};

type Resource = EventLogDataResource;
type ResourceValue = EventLogDataResourceValue;

interface State {
  readonly dtServer?: Date;
  readonly logIndexObjectId?: SigmailObjectId | undefined;
}

export interface UseEventLogDataResourceParams {
  /** @see {@link createDateRange} */
  readonly dateRange?: Parameters<typeof createDateRange>[0];

  /** Resource will never resolve if `eventClass` is `false`, `null`, or `undefined`. */
  readonly eventClass?: Nullable<false | EventLogClassKey>;

  /** An optional callback you can provide to further filter records found within the specified date range. */
  readonly filter?: Nullable<false | ((item: EventLogRecord) => boolean)>;

  /** Resource will never resolve if `userId` is `false`, `null`, or `undefined`. */
  readonly userId?: Nullable<false | SigmailClientId | SigmailGroupId | SigmailUserId>;

  /** Default value `1` (Monday) will be used when `false`, `null`, or `undefined`. */
  readonly weekStartsOn?: Nullable<false | CreateDateRangeOptions['weekStartsOn']>;
}

const NULL_EVENT_LOG_RESOURCE: DataObjectResource<DataObjectEventLogValue> = {
  value: (): DataObjectEventLogValue => ({ $$formatver: 1, list: EMPTY_ARRAY })
};

const VALID_EVENT_CLASS_LIST: ReadonlyArray<EventLogClassKey> = [
  'account',
  'eConsult',
  'message',
  'referral',
  'schedule',
  'session'
];

function resolveParams(
  params: Nullable<false | Params>,
  dtToday?: Parameters<typeof createDateRange>[1],
  locale?: CreateDateRangeOptions['locale']
): ResolvedParams {
  const { dateRange, eventClass, filter, userId, weekStartsOn: weekStart } = (Utils.isNonArrayObjectLike(params)
    ? params
    : EMPTY_PLAIN_OBJECT) as Params;

  const weekStartsOn = Utils.numberOrDefault(weekStart, 1 /* Monday */);
  const [dtStart, dtEnd] = createDateRange(dateRange, dtToday, { locale, weekStartsOn });

  let maxTime = Number.NaN;
  let minTime = Number.NaN;
  if (Utils.isValidDate(dtStart) && Utils.isValidDate(dtEnd)) {
    minTime = dtStart.getTime();
    maxTime = dtEnd.getTime();
  }

  return {
    dateRange: [minTime, maxTime],
    eventClass: Utils.stringOrDefault<EventLogClassKey>(eventClass, undefined),
    filter: typeof filter === 'function' ? filter : undefined,
    userId: Utils.numberOrDefault(userId, undefined),
    weekStartsOn
  };
}

export function useEventLogDataResource(params?: Nullable<false | Params>): Resource {
  const [, { language }] = useTranslation();
  const locale = language === FrenchCanada ? frCA : enCA;

  const [{ dtServer, logIndexObjectId }, setState] = React.useState<State>(EMPTY_PLAIN_OBJECT);

  // prettier-ignore
  const { dateRange: [tsMin, tsMax], eventClass, filter, userId } = resolveParams(params, dtServer, locale);
  const fetchUserObjects = useDispatchFetchUserObjects();
  const eventLogIndex = UserObjectCache.getValue(
    useSelector(selectEventLogObject)(Utils.numberOrDefault(Utils.isInteger(logIndexObjectId) && userId))
  );

  let logObjectId: SigmailObjectId | undefined;
  if (Utils.isNotNil(eventLogIndex) && VALID_EVENT_CLASS_LIST.includes(eventClass!)) {
    logObjectId = Utils.numberOrDefault(eventLogIndex[eventClass!], null!);
  }

  let eventLogResource = useDataObjectResource<DataObjectEventLogValue>({ objectId: logObjectId, onError: Utils.noop });
  if (logObjectId === null) eventLogResource = NULL_EVENT_LOG_RESOURCE;

  //#region fetch log index object 425 whenever <userId> changes
  React.useEffect((): ReturnType<React.EffectCallback> => {
    if (!AppUser.isValidId(userId)) return;

    let ignore = false;

    const TYPE_CODE = process.env.USER_OBJECT_TYPE_EVENT_LOG;
    async function fetchAndUpdateState(): Promise<void> {
      try {
        const response = await fetchUserObjects({ userObjects: [{ type: TYPE_CODE, userId: userId! }] });
        const { serverDateTime, userObjectsByType: list } = response;
        const id = list!.find((obj) => obj.type === TYPE_CODE && obj.userId === userId)?.id;

        return void (
          !ignore &&
          setState((state): State => ({ ...state, dtServer: new Date(serverDateTime), logIndexObjectId: id }))
        );
      } catch {
        /* ignore */
      }
    }

    void fetchAndUpdateState();
    setState(({ logIndexObjectId, ...state }): State => state);
    return () => void (ignore = true);
  }, [fetchUserObjects, userId]);
  //#endregion

  // fetch API server's date-time whenever <eventLog> changes
  const fetchServerDateTime = useDispatchFetchServerDateTime();
  React.useEffect((): ReturnType<React.EffectCallback> => {
    let ignore = false;
    void fetchServerDateTime().then(
      (dtServer) => void (!ignore && setState((state): State => ({ ...state, dtServer }))),
      Utils.noop
    );
    return () => void (ignore = true);
  }, [fetchServerDateTime, eventLogResource]);

  return React.useMemo((): Resource => {
    try {
      const eventLog = eventLogResource.value();

      const filterFn = typeof filter === 'function' ? filter : () => true;
      const list = Utils.arrayOrDefault<EventLogRecord>(eventLog?.list, EMPTY_ARRAY).filter((item) => {
        const ts = new Date(item.ts).getTime();
        return ts >= tsMin && ts <= tsMax && filterFn(item);
      });

      return { value: (): ResourceValue => [list, [tsMin, tsMax]] };
    } catch {
      /* ignore */
    }
    return UNRESOLVED_RESOURCE;
  }, [eventLogResource, filter, tsMax, tsMin]);
}
