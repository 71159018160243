import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { accessTokenSelector, authClaimSelector } from '../../selectors/auth';

const Logger = getLoggerWithPrefix('Action', 'hrmGetUserList:');

let userListPromise: ReturnType<Api.Service['hrmGetUserList']> | undefined;
export const hrmGetUserListAction = (): AppThunk<NonNullable<typeof userListPromise>> => {
  return (_D, getState, { apiService }) => {
    if (Utils.isNil(userListPromise)) {
      userListPromise = new Promise((resolve) => {
        Logger.info('== BEGIN ==');

        const state = getState();
        const accessToken = accessTokenSelector(state);
        const authState = authClaimSelector(state);

        void apiService
          .hrmGetUserList(accessToken, authState)
          .then(
            (list) => {
              const timeoutId = window.setTimeout(() => {
                userListPromise = undefined;
                window.clearTimeout(timeoutId);
              }, 60 /* minutes */ * 60 /* seconds */ * 1000 /* milliseconds */);
              resolve(list);
            },
            () => (userListPromise = undefined)
          )
          .finally(() => Logger.info('== END =='));
      });
    }
    return userListPromise;
  };
};
