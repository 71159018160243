import { createAction } from '@reduxjs/toolkit';
import { Constants, SigmailUserId, Utils } from '@sigmail/common';
import { CarePlanI18n } from '@sigmail/i18n';
import { UserObjectCarePlansValue } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { SendDataToEmrDialogProps } from '../../app/shared/dialog/send-data-to-emr-dialog.component';
import { PdfDocument } from '../../core/pdf-document';
import { useTranslation } from '../../i18n';
import { I18N_NS_CARE_PLAN } from '../../i18n/config/namespace-identifiers';
import { EMPTY_PLAIN_OBJECT } from '../constants';
import { currentUserIdSelector as selectCurrentUserId } from '../selectors/auth';
import { useCarePlanListResource } from './use-care-plan-list-resource';

export type CarePlansViewDispatch = React.Dispatch<AnyAction>;

export interface CarePlansViewState {
  readonly editMode?: 'create' | 'update';
  readonly emrDialogProps?: SendDataToEmrDialogProps;
  readonly maximized: boolean;
  readonly planId: string;
}

interface DispatchDataRef {
  readonly I18NLabelContext: CarePlanI18n.I18NLabelContext;
  readonly guestUserId: SigmailUserId;
  readonly listResource: ReturnType<typeof useCarePlanListResource>;
  readonly locale: string;
  readonly t: ReturnType<typeof useTranslation>['t'];
}

const INITIAL_VIEW_STATE: CarePlansViewState = { maximized: false, planId: '' };

export const closeEditModeAction = createAction('closeEditMode');
export const createCarePlanAction = createAction('createCarePlan');
export const downloadCarePlanAction = createAction<string>('downloadCarePlan');
export const editCarePlanAction = createAction<string>('editCarePlan');
export const printCarePlanAction = createAction('printCarePlan');
export const sendCarePlanToEmrAction = createAction<string>('sendCarePlanToEmr');
export const setSelectedCarePlanAction = createAction<string>('setSelectedCarePlan');
export const toggleMaximizedAction = createAction('toggleMaximized');

export const useCarePlansViewReducer = (guestUserId: SigmailUserId): [CarePlansViewState, CarePlansViewDispatch] => {
  const [t, { language: locale }] = useTranslation(I18N_NS_CARE_PLAN);

  const [state, setState] = React.useState(INITIAL_VIEW_STATE);

  const dispatchDataRef = React.useRef(EMPTY_PLAIN_OBJECT as DispatchDataRef);
  dispatchDataRef.current = {
    I18NLabelContext: useSelector(selectCurrentUserId) === guestUserId ? 'self' : 'other',
    guestUserId,
    listResource: useCarePlanListResource({ userId: guestUserId }),
    locale,
    t
  };

  const dispatch = React.useCallback<React.Dispatch<AnyAction>>((action) => {
    if (closeEditModeAction.match(action)) {
      return setState(({ editMode, ...state }): CarePlansViewState => state);
    } else if (createCarePlanAction.match(action)) {
      return setState((state): CarePlansViewState => ({ ...state, editMode: 'create' }));
    } else if (editCarePlanAction.match(action)) {
      return setState((state): CarePlansViewState => ({ ...state, editMode: 'update', planId: action.payload }));
    } else if (printCarePlanAction.match(action)) {
      return void setTimeout(() => window.print());
    } else if (setSelectedCarePlanAction.match(action)) {
      return setState((state): CarePlansViewState => ({ ...state, maximized: false, planId: action.payload }));
    } else if (toggleMaximizedAction.match(action)) {
      return setState(({ maximized, ...state }): CarePlansViewState => ({ ...state, maximized: !maximized }));
    }

    if (downloadCarePlanAction.match(action) || sendCarePlanToEmrAction.match(action)) {
      const isActionTypeDownload = action.type === downloadCarePlanAction.type;
      const { I18NLabelContext, guestUserId, listResource, locale, t } = dispatchDataRef.current;

      let carePlanList: UserObjectCarePlansValue['list'];

      // prettier-ignore
      try { carePlanList = listResource.value()[0] }
      catch { /* ignore */ }

      const plan = carePlanList?.find(({ id }) => id === action.payload);
      if (Utils.isNil(plan)) return;

      const pdfDocument = PdfDocument.createWithHeader(
        `This document has been ${
          isActionTypeDownload ? 'generated' : 'sent'
        } using {{PRODUCT_NAME}} powered by {{COMPANY_NAME}}.`
      );

      const planTitle = Utils.trimOrDefault(plan.title);
      if (planTitle.length > 0) pdfDocument.info.title = planTitle;
      pdfDocument.addCarePlan(plan, { I18NLabelContext, locale, t });

      if (isActionTypeDownload) {
        return void pdfDocument.getBuffer().then((buffer) => {
          window.open(URL.createObjectURL(new Blob([buffer], { type: Constants.MimeType.PDF })));
        });
      }

      void pdfDocument.getBase64().then((content) =>
        setState(
          (state): CarePlansViewState => ({
            ...state,
            emrDialogProps: {
              data: {
                content,
                fileName: `SigMail_care_plan_${action.payload}_${Date.now()}.pdf`,
                mime: Constants.MimeType.PDF
              },
              guestUserId,
              onClose: () => setState(({ emrDialogProps, ...state }): CarePlansViewState => state),
              open: true
            }
          })
        )
      );
    }
  }, []);

  return React.useMemo((): [CarePlansViewState, CarePlansViewDispatch] => [state, dispatch], [dispatch, state]);
};
