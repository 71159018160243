import type { UsaStateCode } from '../types';

export const Alabama: Extract<UsaStateCode, 'AL'> = 'AL';
export const Alaska: Extract<UsaStateCode, 'AK'> = 'AK';
export const Arizona: Extract<UsaStateCode, 'AZ'> = 'AZ';
export const Arkansas: Extract<UsaStateCode, 'AR'> = 'AR';
export const California: Extract<UsaStateCode, 'CA'> = 'CA';
export const Colorado: Extract<UsaStateCode, 'CO'> = 'CO';
export const Connecticut: Extract<UsaStateCode, 'CT'> = 'CT';
export const Delaware: Extract<UsaStateCode, 'DE'> = 'DE';
export const DistrictOfColumbia: Extract<UsaStateCode, 'DC'> = 'DC';
export const Florida: Extract<UsaStateCode, 'FL'> = 'FL';
export const Georgia: Extract<UsaStateCode, 'GA'> = 'GA';
export const Hawaii: Extract<UsaStateCode, 'HI'> = 'HI';
export const Idaho: Extract<UsaStateCode, 'ID'> = 'ID';
export const Illinois: Extract<UsaStateCode, 'IL'> = 'IL';
export const Indiana: Extract<UsaStateCode, 'IN'> = 'IN';
export const Iowa: Extract<UsaStateCode, 'IA'> = 'IA';
export const Kansas: Extract<UsaStateCode, 'KS'> = 'KS';
export const Kentucky: Extract<UsaStateCode, 'KY'> = 'KY';
export const Louisiana: Extract<UsaStateCode, 'LA'> = 'LA';
export const Maine: Extract<UsaStateCode, 'ME'> = 'ME';
export const Maryland: Extract<UsaStateCode, 'MD'> = 'MD';
export const Massachusetts: Extract<UsaStateCode, 'MA'> = 'MA';
export const Michigan: Extract<UsaStateCode, 'MI'> = 'MI';
export const Minnesota: Extract<UsaStateCode, 'MN'> = 'MN';
export const Mississippi: Extract<UsaStateCode, 'MS'> = 'MS';
export const Missouri: Extract<UsaStateCode, 'MO'> = 'MO';
export const Montana: Extract<UsaStateCode, 'MT'> = 'MT';
export const Nebraska: Extract<UsaStateCode, 'NE'> = 'NE';
export const Nevada: Extract<UsaStateCode, 'NV'> = 'NV';
export const NewHampshire: Extract<UsaStateCode, 'NH'> = 'NH';
export const NewJersey: Extract<UsaStateCode, 'NJ'> = 'NJ';
export const NewMexico: Extract<UsaStateCode, 'NM'> = 'NM';
export const NewYork: Extract<UsaStateCode, 'NY'> = 'NY';
export const NorthCarolina: Extract<UsaStateCode, 'NC'> = 'NC';
export const NorthDakota: Extract<UsaStateCode, 'ND'> = 'ND';
export const Ohio: Extract<UsaStateCode, 'OH'> = 'OH';
export const Oklahoma: Extract<UsaStateCode, 'OK'> = 'OK';
export const Oregon: Extract<UsaStateCode, 'OR'> = 'OR';
export const Pennsylvania: Extract<UsaStateCode, 'PA'> = 'PA';
export const RhodeIsland: Extract<UsaStateCode, 'RI'> = 'RI';
export const SouthCarolina: Extract<UsaStateCode, 'SC'> = 'SC';
export const SouthDakota: Extract<UsaStateCode, 'SD'> = 'SD';
export const Tennessee: Extract<UsaStateCode, 'TN'> = 'TN';
export const Texas: Extract<UsaStateCode, 'TX'> = 'TX';
export const Utah: Extract<UsaStateCode, 'UT'> = 'UT';
export const Vermont: Extract<UsaStateCode, 'VT'> = 'VT';
export const Virginia: Extract<UsaStateCode, 'VA'> = 'VA';
export const Washington: Extract<UsaStateCode, 'WA'> = 'WA';
export const WestVirginia: Extract<UsaStateCode, 'WV'> = 'WV';
export const Wisconsin: Extract<UsaStateCode, 'WI'> = 'WI';
export const Wyoming: Extract<UsaStateCode, 'WY'> = 'WY';
