import { AccountI18n, ContactListI18n, GroupsI18n, MembersI18n, MessagingI18n } from '@sigmail/i18n';

/*
 +------------------------------------------------------------------------------
 |
 | Action IDs mapping to routes
 |
 +------------------------------------------------------------------------------
*/
export const Home = 'home';

export const SignIn = 'signIn';
export const ChangeUsername = 'changeUsername';
export const ResetPassword = 'resetPassword';
export const SignOut = 'signOut';

export const AccountManageCaregivers = 'accountManageCaregivers';
export const AccountManageCarePlans = 'accountManageCarePlans';
export const AccountManageGroups = 'accountManageGroups';
export const AccountManageMembers = 'accountManageMembers';
export const AccountManageSelf = 'accountManageSelf';

export const AccountSetupCredentials = 'accountSetupCredentials';
export const AccountSetupRecovery = 'accountSetupRecovery';
export const AccountSetupProfileInfo = 'accountSetupProfileInfo';
export const AccountSetupSuccess = 'accountSetupSuccess';
export const AccountSetupSecurity = 'accountSetupSecurity';

export const BillingFile = 'billingFile';

type ContactListNavActionId = ContactListI18n.NavActionId;
export const ContactList = 'contactList';
export const ContactListGlobalUsers: Extract<ContactListNavActionId, 'contactListGlobalUsers'> =
  'contactListGlobalUsers';
export const ContactListGlobalGroups: Extract<ContactListNavActionId, 'contactListGlobalGroups'> =
  'contactListGlobalGroups';
export const ContactListClient: Extract<ContactListNavActionId, 'contactListClient'> = 'contactListClient';
export const ContactListGroupGuest: Extract<ContactListNavActionId, 'contactListGroupGuest'> = 'contactListGroupGuest';

type MessagingNavActionId = MessagingI18n.NavActionId;
export const ArchivedMessages: Extract<MessagingNavActionId, 'archivedMessages'> = 'archivedMessages';
export const ArchivedMessagesShared: Extract<MessagingNavActionId, 'archivedMessagesShared'> = 'archivedMessagesShared';
export const ComposeConsultationRequest: Extract<MessagingNavActionId, 'composeConsultationRequest'> =
  'composeConsultationRequest';
export const ComposeHrmMessage: Extract<MessagingNavActionId, 'composeHrmMessage'> = 'composeHrmMessage';
export const ComposeMessage: Extract<MessagingNavActionId, 'composeMessage'> = 'composeMessage';
export const ConsultationMessages: Extract<MessagingNavActionId, 'consultationMessages'> = 'consultationMessages';
export const DeletedMessages: Extract<MessagingNavActionId, 'deletedMessages'> = 'deletedMessages';
export const DeletedMessagesShared: Extract<MessagingNavActionId, 'deletedMessagesShared'> = 'deletedMessagesShared';
export const DraftMessages: Extract<MessagingNavActionId, 'draftMessages'> = 'draftMessages';
export const ImportantMessages: Extract<MessagingNavActionId, 'importantMessages'> = 'importantMessages';
export const ImportantMessagesShared: Extract<MessagingNavActionId, 'importantMessagesShared'> =
  'importantMessagesShared';
export const Mailbox = 'mailbox';
export const ReceivedMessages: Extract<MessagingNavActionId, 'receivedMessages'> = 'receivedMessages';
export const ReceivedMessagesShared: Extract<MessagingNavActionId, 'receivedMessagesShared'> = 'receivedMessagesShared';
export const ReferralMessages: Extract<MessagingNavActionId, 'referralMessages'> = 'referralMessages';
export const ReferralMessagesShared: Extract<MessagingNavActionId, 'referralMessagesShared'> = 'referralMessagesShared';
export const ReminderMessages: Extract<MessagingNavActionId, 'reminderMessages'> = 'reminderMessages';
export const SelectFilters = 'selectFilters';
export const SentMessages: Extract<MessagingNavActionId, 'sentMessages'> = 'sentMessages';

export const Schedule = 'schedule';
export const ScheduleOwn = 'scheduleOwn';
export const ScheduleGroup = 'scheduleGroup';

export const DocumentList = 'documentList';

export const LanguageToggle = 'toggleLanguage';
export const TermsAndConditions = 'termsAndConditions';
export const PrivacyPolicy = 'privacyPolicy';
export const FAQ = 'faq';
export const ContactUs = 'contactUs';

/*
 +------------------------------------------------------------------------------
 |
 | Action IDs mapping to UI elements
 |
 +------------------------------------------------------------------------------
*/

//
// Account actions
//
type AccountToolbarActionId = AccountI18n.AccountToolbarActionId;
export const ActivateAccount: Extract<AccountToolbarActionId, 'activateAccount'> = 'activateAccount';
export const ChangePassword: Extract<keyof AccountI18n.View.Security['action'], 'changePassword'> = 'changePassword';
export const DeactivateAccount: Extract<AccountToolbarActionId, 'deactivateAccount'> = 'deactivateAccount';
export const DisableMfa: Extract<keyof AccountI18n.View.Security['action'], 'disableMfa'> = 'disableMfa';
export const EditAccuroEMRProviderID: Extract<
  keyof AccountI18n.View.Integrations['action'],
  'editAccuroEMRProviderId'
> = 'editAccuroEMRProviderId';
export const EditEMRConfig: Extract<keyof AccountI18n.View.Integrations['action'], 'editEMRConfig'> = 'editEMRConfig';
export const EditGroupInboxNotification = 'editGroupInboxNotification';
export const EditInboxNotification = 'editInboxNotification';
export const EditMessageSignature: Extract<keyof AccountI18n.View.Preferences['action'], 'editMessageSignature'> =
  'editMessageSignature';
export const EditProfile: Extract<keyof AccountI18n.View.UserProfile['action'], 'editProfile'> = 'editProfile';
export const EnableMfa: Extract<keyof AccountI18n.View.Security['action'], 'enableMfa'> = 'enableMfa';
export const SendCaregiverAccountInvitation: Extract<AccountToolbarActionId, 'sendCaregiverAccountInvitation'> =
  'sendCaregiverAccountInvitation';
export const SendGuestAccountInvitation: Extract<AccountToolbarActionId, 'sendGuestAccountInvitation'> =
  'sendGuestAccountInvitation';
export const SendNonGuestAccountInvitation: Extract<AccountToolbarActionId, 'sendNonGuestAccountInvitation'> =
  'sendNonGuestAccountInvitation';
export const RemoveAccountInvitation: Extract<AccountToolbarActionId, 'removeAccountInvitation'> =
  'removeAccountInvitation';
export const ResendAccountInvitation: Extract<AccountToolbarActionId, 'resendAccountInvitation'> =
  'resendAccountInvitation';
export const RevokeAccountInvitation: Extract<AccountToolbarActionId, 'revokeAccountInvitation'> =
  'revokeAccountInvitation';

//
// Contact list actions
//
type ContactToolbarActionId = ContactListI18n.ContactToolbarActionId;
export const CreateGuestContactGroup: Extract<ContactToolbarActionId, 'createGuestContactGroup'> =
  'createGuestContactGroup';
export const EditGuestProfile: Extract<ContactToolbarActionId, 'editGuestProfile'> = 'editGuestProfile';
export const Filter: Extract<ContactToolbarActionId, 'filter'> = 'filter';
export const FillOnBehalfHealthData = 'fillOnBehalfHealthData';
export const RequestHealthData: Extract<ContactToolbarActionId, 'requestHealthData'> = 'requestHealthData';
export const ResetFilter: Extract<ContactToolbarActionId, 'resetFilter'> = 'resetFilter';
export const ViewCarePlans: Extract<ContactToolbarActionId, 'viewCarePlans'> = 'viewCarePlans';
export const ViewContactDetails: Extract<ContactToolbarActionId, 'viewContactDetails'> = 'viewContactDetails';
export const ViewHealthData: Extract<ContactToolbarActionId, 'viewHealthData'> = 'viewHealthData';
export const CloseCarePlanView = 'closeCarePlansView';
export const CloseHealthDataView = 'closeHealthDataView';

//
// Messaging actions
//
type MessageToolbarActionId = MessagingI18n.MessageToolbarActionId;
export const AcceptReferral = 'acceptReferral';
export const AssignCategory: Extract<MessageToolbarActionId, 'assignCategory'> = 'assignCategory';
export const AssignMessageTo: Extract<MessageToolbarActionId, 'assignMessageTo'> = 'assignMessageTo';
export const AttachDocuments = 'attachDocuments';
export const CancelHrmMessage = 'cancelHrmMessage';
export const CloseMessage: Extract<MessageToolbarActionId, 'closeMessage'> = 'closeMessage';
export const ComposeReferral: Extract<MessageToolbarActionId, 'composeReferral'> = 'composeReferral';
export const CorrectHrmMessage = 'correctHrmMessage';
export const DeclineReferral = 'declineReferral';
export const DeleteMessage: Extract<MessageToolbarActionId, 'deleteMessage'> = 'deleteMessage';
export const DiscardDraft: Extract<MessageToolbarActionId, 'discardDraft'> = 'discardDraft';
export const DownloadDocument = 'downloadDocument';
export const Forward: Extract<MessageToolbarActionId, 'forward'> = 'forward';
export const MarkAsRead: Extract<MessageToolbarActionId, 'markRead'> = 'markRead';
export const MarkAsUnread: Extract<MessageToolbarActionId, 'markUnread'> = 'markUnread';
export const MoveToArchive: Extract<MessageToolbarActionId, 'archive'> = 'archive';
export const OpenDraftMessage = 'openDraftMessage';
export const Print: Extract<MessageToolbarActionId, 'print'> = 'print';
export const Recall: Extract<MessageToolbarActionId, 'recall'> = 'recall';
export const Reminder = 'reminder';
export const Reply: Extract<MessageToolbarActionId, 'reply'> = 'reply';
export const ReplyAll: Extract<MessageToolbarActionId, 'replyAll'> = 'replyAll';
export const SaveAsDraft = 'saveAsDraft';
export const SendConsultationRequest = 'sendConsultationRequest';
export const SelectDocumentToPreview = 'selectDocumentToPreview';
export const SendHrmMessage = 'sendHrmMessage';
export const SendMessage = 'sendMessage';
export const SendMessageToDoctorCare = 'sendMessageToDoctorCare';
export const SendMessageToEMR: Extract<MessageToolbarActionId, 'sendMessageToEMR'> = 'sendMessageToEMR';
export const SendReferral = 'sendReferral';
export const SubmitHealthData = 'submitHealthData';
export const OneTimeMessageVerifyPhoneNumber = 'oneTimeMessageVerifyPhoneNumber';

//
// Member actions
//
export const BulkInviteGuests: Extract<keyof MembersI18n.Root['action'], 'bulkInviteGuests'> = 'bulkInviteGuests';
export const UpgradeLicense: Extract<keyof MembersI18n.Root['action'], 'upgradeLicense'> = 'upgradeLicense';

//
// Scheduling actions
//
export const AcceptEvent = 'acceptEvent';
export const CreateEvent = 'createEvent';
export const DeclineEvent = 'declineEvent';
export const EditWorkSchedule = 'editWorkSchedule';
export const JoinEvent = 'joinEvent';
export const LeaveMeeting = 'leaveMeeting';
export const NewEvent = 'newEvent';
export const RecallEvent = 'recallEvent';
export const RemoveEventFromCalendar = 'removeEventFromCalendar';
export const ToggleAudioOff = 'toggleAudioOff';
export const ToggleAudioOn = 'toggleAudioOn';
export const ToggleVideoOff = 'toggleVideoOff';
export const ToggleVideoOn = 'toggleVideoOn';
export const ViewEvent = 'viewEvent';

//
// Group actions
//
export const AcceptJoinGroupInvitation = 'acceptJoinGroupInvitation';
export const DeclineJoinGroupInvitation = 'declineJoinGroupInvitation';
export const InviteUsersToGroup: Extract<GroupsI18n.GroupToolbarActionId, 'inviteUsersToGroup'> = 'inviteUsersToGroup';
export const AssignUsersToGroup: Extract<GroupsI18n.GroupToolbarActionId, 'assignUsersToGroup'> = 'assignUsersToGroup';
export const TransferUsersToGroup: Extract<GroupsI18n.GroupToolbarActionId, 'transferUsersToGroup'> =
  'transferUsersToGroup';
export const RemoveUsersFromGroup: Extract<GroupsI18n.GroupToolbarActionId, 'removeUsersFromGroup'> =
  'removeUsersFromGroup';

//
// Other actions
//
export const AddMember = 'addMember';
export const DialogAccept = 'dialogAccept';
export const DialogReject = 'dialogReject';
export const FormCancel = 'formCancel';
export const FormSubmit = 'formSubmit';
export const FormSubmitEditProfile = 'formSubmitEditProfile';
export const FormSubmitNewPassword = 'formSubmitNewPassword';
export const FormSubmitSecurityChallenge = 'formSubmitSecurityChallenge';
export const FormSubmitSignInCredentials = 'formSubmitSignInCredentials';
export const FormSubmitSelectMfaMethod = 'formSubmitSelectMfaMethod';
export const FormSubmitVerifyMfaCode = 'formSubmitVerifyMfaCode';
export const FormSubmitVerifyUsername = 'formSubmitVerifyUsername';
export const FormSubmitChoosePassword = 'formSubmitChoosePassword';
export const SkipToMainContent = 'skipToMainContent';
export const SkipToNavMenu = 'skipToNavMenu';
export const UserAccountMenu = 'userAccountMenu';
