import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['staffSpecialtyList'] = [
  {
    code: 'aab',
    rcpsCode: '00',
    // Translators: Label for the Administrative staff specialty (Staff)
    label: gettext('global', 'adminStaff', 'staffSpecialty')
  },
  {
    code: 'ab4',
    rcpsCode: '00',
    // Translators: Label for the Office Manager specialty
    label: gettext('global', 'officeManager', 'staffSpecialty')
  },
  {
    code: 'abe',
    rcpsCode: '00',
    // Translators: Label for the Secretary specialty
    label: gettext('global', 'secretary', 'staffSpecialty')
  },
  {
    code: 'ac8',
    rcpsCode: '00',
    // Translators: Label for the Nurse specialty
    label: gettext('global', 'nurse', 'staffSpecialty')
  }
];

export default i18n;
