import { PartialRecord, Utils } from '@sigmail/common';
import { MessagingI18n } from '@sigmail/i18n';
import React from 'react';
import { useLocation } from 'react-router';
import { MutableRef } from 'sigmail';
import { useTranslation } from '../../../i18n';
import { I18N_NS_MESSAGING } from '../../../i18n/config/namespace-identifiers';
import { processImgProps } from '../../../utils/process-img-props';
import { resolveActionIcon } from '../../../utils/resolve-action-icon';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { resolveActionPath } from '../../../utils/resolve-action-path';
import layoutStyle from '../../layout/layout.module.css';
import { RouterNavLink } from '../../shared/router-nav-link.component';

export type MessageFolderListItemClassKey = 'folderName' | 'folderItemCount';

export interface Props extends React.ComponentPropsWithoutRef<'li'> {
  AnchorProps?: Omit<React.ComponentPropsWithoutRef<'a'>, 'children'> & {
    children?: never;
    disabled?: boolean | undefined;
    ref?: MutableRef<HTMLAnchorElement> | null | undefined;
  };
  action: MessagingI18n.Root['navAction'][Exclude<MessagingI18n.NavActionId, `compose${string}`>] & { id: string };
  classes?: PartialRecord<MessageFolderListItemClassKey, string> | undefined;
  folderItemCount: number;
}

const DEFAULT_CLASSES: Record<MessageFolderListItemClassKey, string> = {
  folderName: layoutStyle['span-primary'],
  folderItemCount: layoutStyle['span-secondary']
};

function MessageFolderListItemComponent(
  { AnchorProps, action, children, classes: classesProp, className, folderItemCount, ...props }: Props,
  ref: MutableRef<HTMLLIElement>
) {
  // prettier-ignore
  const { t, i18n: { language: locale } } = useTranslation([I18N_NS_MESSAGING]);
  const location = useLocation();

  const { label, to, image } = React.useMemo(() => {
    const label = t(resolveActionLabel(action, action.id));
    const pathname = t(resolveActionPath(action, action.id));
    const iconPrimary = resolveActionIcon({ icon: action.iconPrimary }, action.id);
    const iconSecondary = resolveActionIcon({ icon: action.iconSecondary }, action.id);
    const actionIcon = location.pathname === pathname ? iconPrimary : iconSecondary;

    return {
      label,
      to: pathname,
      image: processImgProps({ ...actionIcon, src: actionIcon.src[locale] })
    };
  }, [location.pathname, action, t, locale]);

  const classes = React.useMemo(() => Utils.defaults({}, classesProp as unknown, DEFAULT_CLASSES), [classesProp]);

  return (
    <li className={className} ref={ref} {...props}>
      <RouterNavLink data-action-id={action.id} exact={true} to={to} image={image} {...AnchorProps}>
        <span className={classes.folderName} title={label}>
          {label}
        </span>

        {folderItemCount > 0 && <span className={classes.folderItemCount}>{folderItemCount}</span>}
      </RouterNavLink>

      {children}
    </li>
  );
}

export const MessageFolderListItem = React.forwardRef(MessageFolderListItemComponent);
MessageFolderListItem.displayName = 'MessageFolderListItem';
