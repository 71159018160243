import { KeyboardDatePicker as DatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';
import React from 'react';

export interface DatePickerFieldProps extends KeyboardDatePickerProps {}

const DatePickerFieldComponent = React.memo<DatePickerFieldProps>((props) => <DatePicker {...props} />);

export const DatePickerField = React.forwardRef<HTMLDivElement, DatePickerFieldProps>((props, ref) => (
  <DatePickerFieldComponent innerRef={ref} {...props} />
));

DatePickerField.displayName = 'DatePickerField';

DatePickerField.defaultProps = {
  autoOk: true,
  disableFuture: true,
  disableToolbar: true,
  format: 'yyyy-MM-dd',
  fullWidth: true,
  inputVariant: 'standard',
  margin: 'none',
  openTo: 'year',
  strictCompareDates: true,
  variant: 'inline'
};
