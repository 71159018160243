import { Nullable, Utils, Writeable } from '@sigmail/common';
import { Api } from '@sigmail/services';
import React from 'react';
import { useAccuroEMRProviderFolderListResource } from '.';
import { CircularProgress } from '../../app/shared/circular-progress.component';
import { DialogActionsAcceptReject } from '../../app/shared/dialog/actions/accept-reject.component';
import { DialogBoxProps } from '../../app/shared/dialog/dialog-box.component';
import dialogBoxStyle from '../../app/shared/dialog/dialog-box.module.css';
import { SelectAccuroEMRProviderFolder } from '../../app/shared/select-accuro-emr-provider-folder.component';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { DialogAccept as ActionIdDialogAccept, DialogReject as ActionIdDialogReject } from '../../constants/action-ids';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { resolveActionLabel } from '../../utils/resolve-action-label';
import { EMPTY_ARRAY } from '../constants';

export interface UseSelectAccuroEmrProviderFolderDialogPropsParams {
  readonly listResource?: ReturnType<typeof useAccuroEMRProviderFolderListResource>;
  readonly onAccept?: (
    event: React.MouseEvent<HTMLButtonElement>,
    record: Nullable<Api.AccuroEMRProviderFolderListItem>
  ) => void;
  readonly onReject?: React.MouseEventHandler<HTMLButtonElement>;
  readonly open: boolean;
}

export type SelectAccuroEmrProviderFolderDialogProps = Pick<
  DialogBoxProps,
  | 'ContentProps'
  | 'TitleProps'
  | 'actions'
  | 'body'
  | 'disableBackdropClick'
  | 'disableEscapeKeyDown'
  | 'maxWidth'
  | 'open'
>;

const DEFAULT_PROPS: SelectAccuroEmrProviderFolderDialogProps = {
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
  maxWidth: 'sm',
  open: false
};

export const useSelectAccuroEmrProviderFolderDialogProps = ({
  listResource: folderListResource,
  onAccept,
  onReject,
  open
}: UseSelectAccuroEmrProviderFolderDialogPropsParams): SelectAccuroEmrProviderFolderDialogProps => {
  const { t } = useTranslation(I18N_NS_GLOBAL);

  const listResource = (Utils.isNil(folderListResource) ? UNRESOLVED_RESOURCE : folderListResource) as NonNullable<
    typeof folderListResource
  >;

  const [selectedFolder, setSelectedFolder] = React.useState('');

  let isListLoading = false;
  let [authStatus, list]: ReturnType<typeof listResource.value> = [false, EMPTY_ARRAY];
  try {
    [authStatus, list] = listResource.value();
  } catch (error) {
    isListLoading = error instanceof Promise;
  }

  const onClickAction = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const actionId = Utils.stringOrDefault(event.currentTarget.getAttribute('data-action-id'));
      const isActionDialogAccept = actionId === ActionIdDialogAccept;
      const isActionDialogReject = !isActionDialogAccept && actionId === ActionIdDialogReject;

      let folder: Writeable<Api.AccuroEMRProviderFolderListItem> | undefined;
      if (isActionDialogAccept) {
        const [folderId, subFolderId] = selectedFolder.split(',');

        folder = list.find(({ id }) => id.toString(10) === folderId);
        if (Utils.isNotNil(folder)) {
          let { subFolders } = folder;

          if (Utils.isString(subFolderId) && Utils.isArray(subFolders)) {
            const subFolder = subFolders.find(({ id }) => id.toString(10) === subFolderId);
            if (Utils.isNotNil(subFolder)) {
              subFolders = [{ ...subFolder, subFolders: undefined }];
            }
          } else {
            subFolders = EMPTY_ARRAY;
          }

          return onAccept?.(event, { ...folder, subFolders });
        }
      } else if (isActionDialogReject) {
        return onReject?.(event);
      }
    },
    [list, onAccept, onReject, selectedFolder]
  );

  React.useEffect(() => void (!open && setSelectedFolder('')), [open]);

  return React.useMemo((): SelectAccuroEmrProviderFolderDialogProps => {
    const isFolderSelected = /^\d+/.test(selectedFolder);

    const { selectEMRProviderFolder: i18n } = globalI18n.dialog;
    const actionLabelAccept = t(resolveActionLabel(i18n.action.accept, ActionIdDialogAccept));
    const actionLabelReject = t(resolveActionLabel(i18n.action.reject, ActionIdDialogReject));

    let ContentProps: SelectAccuroEmrProviderFolderDialogProps['ContentProps'];
    let actions: React.ReactNode;
    let body: React.ReactNode;

    if (open) {
      body = (
        <React.Suspense fallback={<CircularProgress />}>
          <SelectAccuroEMRProviderFolder
            listResource={listResource}
            multiSelect={false}
            onFolderSelect={(_: unknown, folderId: string) => setSelectedFolder(folderId)}
            selected={selectedFolder}
          />
        </React.Suspense>
      );

      if (authStatus) {
        actions = (
          <DialogActionsAcceptReject
            AcceptActionProps={{ children: actionLabelAccept, disabled: !isFolderSelected, type: 'button' }}
            RejectActionProps={{ children: actionLabelReject }}
            onAccept={onClickAction}
            onReject={onClickAction}
          />
        );
      } else {
        actions = (
          <button
            className={dialogBoxStyle['action-reject']}
            data-action-id={ActionIdDialogReject}
            disabled={isListLoading}
            onClick={onClickAction}
            type="button"
          >
            {actionLabelReject}
          </button>
        );

        if (!isListLoading) {
          ContentProps = { dangerouslySetInnerHTML: { __html: t(i18n.body.tokenExpired) } };
          body = undefined;
        }
      }
    }

    return {
      ...DEFAULT_PROPS,

      ContentProps,
      TitleProps: { dangerouslySetInnerHTML: { __html: t(i18n.title) } },
      actions,
      body,
      open
    };
  }, [authStatus, isListLoading, listResource, onClickAction, open, selectedFolder, t]);
};
