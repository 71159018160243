import { PayloadAction } from '@reduxjs/toolkit';
import { StoreStateReminderNotification } from '@sigmail/app-state';
import { SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { REMINDER_NOTIFICATION_STORAGE_KEY_DISMISSED } from '../../constants';

const Logger = getLoggerWithPrefix('Reducer', 'addEventToDismissedListReducer:');

export const addEventToDismissedListReducer = (
  state: StoreStateReminderNotification,
  action: PayloadAction<Array<SigmailObjectId>>
) => {
  try {
    Logger.info('== BEGIN ==');

    if (!Utils.isArray(action.payload)) {
      Logger.warn('Invalid payload.', action.payload);
      return state;
    }

    const dismissedList = [...state.dismissedList, ...action.payload];
    window.sessionStorage.setItem(REMINDER_NOTIFICATION_STORAGE_KEY_DISMISSED, JSON.stringify(dismissedList));

    return { ...state, dismissedList };
  } finally {
    Logger.info('== END ==');
  }
};
