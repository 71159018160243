import { Utils } from '@sigmail/common';
import React from 'react';
import { Switch } from 'react-router';
import { RouteProps } from 'sigmail';
import {
  ROUTE_ACCOUNT,
  ROUTE_APP,
  ROUTE_APP_ROOT,
  ROUTE_CHANGE_USERNAME,
  ROUTE_CONTACT_LIST,
  ROUTE_CONTACT_US,
  ROUTE_FAQ,
  ROUTE_MAIL,
  ROUTE_PRIVACY_POLICY,
  ROUTE_RESET_PASSWORD,
  ROUTE_SCHEDULE,
  ROUTE_SIGN_IN,
  ROUTE_TERMS_AND_CONDITIONS
} from '../constants/route-identifiers';
import { SignInPage } from './account/sign-in-page/sign-in-page.component';
import { PublicHome } from './public-home/public-home.component';
import { getRouteProps } from './routes';
import { Route } from './shared/route';

const AsyncResetPasswordRoot = React.lazy(() =>
  import('./reset-password/root.component').then(({ ResetPasswordRoot }) => ({
    default: ResetPasswordRoot
  }))
);

const AsyncFAQ = React.lazy(() => import('./faq/faq.component').then(({ FAQ }) => ({ default: FAQ })));

const AsyncTermsAndConditions = React.lazy(() =>
  import('./terms-and-conditions/terms-and-conditions.component').then(({ TermsAndConditions }) => ({
    default: TermsAndConditions
  }))
);

const AsyncPrivacyPolicy = React.lazy(() =>
  import('./privacy-policy/privacy-policy.component').then(({ PrivacyPolicy }) => ({
    default: PrivacyPolicy
  }))
);

const AsyncContactUsForm = React.lazy(() =>
  import('./contact-us-form/contact-us-form.component').then(({ ContactUsForm }) => ({ default: ContactUsForm }))
);

/** TODO document */
const AsyncMessagingRoot = React.lazy(() =>
  import('./messaging/root.component').then(({ MessagingRoot }) => ({ default: MessagingRoot }))
);

const AsyncSchedulingRoot = React.lazy(() =>
  import('./scheduling/root.component').then(({ SchedulingRoot }) => ({ default: SchedulingRoot }))
);

const AsyncContactListRoot = React.lazy(() =>
  import('./contact-list/root.component').then(({ ContactListRoot }) => ({ default: ContactListRoot }))
);

const AsyncAccountRoot = React.lazy(() =>
  import('./account/account-root.component').then(({ AccountRoot }) => ({ default: AccountRoot }))
);

const AsyncAccountSetupRoot = React.lazy(() =>
  import('./account/setup/root.component').then(({ AccountSetupRoot }) => ({ default: AccountSetupRoot }))
);

const withSuspense = (
  Component: React.ComponentType<any>
): React.FC<Parameters<NonNullable<RouteProps['render']>>[0]> => ({
  history,
  location,
  match,
  staticContext,
  ...props
}) => (
  <React.Suspense fallback={null}>
    <Component {...props} />
  </React.Suspense>
);

export const AppRouterOutlet: React.FC<{ children?: never }> = () => {
  const childRoutes = Utils.arrayOrDefault<RouteProps>(getRouteProps(ROUTE_APP)?.childRoutes);

  return (
    <Switch>
      {childRoutes.map((route) => {
        const { routeId, actionId, childRoutes, ...routeProps } = route;
        let { render } = routeProps;

        switch (routeId) {
          case ROUTE_APP_ROOT:
            render = () => <PublicHome />;
            break;
          case ROUTE_SIGN_IN:
            render = () => <SignInPage />;
            break;
          case ROUTE_CHANGE_USERNAME:
            render = withSuspense(AsyncAccountSetupRoot);
            break;
          case ROUTE_RESET_PASSWORD:
            render = withSuspense(AsyncResetPasswordRoot);
            break;
          case ROUTE_FAQ:
            render = withSuspense(AsyncFAQ);
            break;
          case ROUTE_TERMS_AND_CONDITIONS:
            render = withSuspense(AsyncTermsAndConditions);
            break;
          case ROUTE_PRIVACY_POLICY:
            render = withSuspense(AsyncPrivacyPolicy);
            break;
          case ROUTE_CONTACT_US:
            render = withSuspense(AsyncContactUsForm);
            break;
          default: {
            if (process.env.REACT_APP_ENV === 'production') {
              render = () => null;
              break;
            }

            throw new Error(`Unhandled case - ${routeId}`);
          }
        }

        return <Route key={routeId} routeId={routeId} {...routeProps} render={render} />;
      })}

      <Route routeId={ROUTE_MAIL} {...getRouteProps(ROUTE_MAIL)} render={withSuspense(AsyncMessagingRoot)} />
      <Route routeId={ROUTE_SCHEDULE} {...getRouteProps(ROUTE_SCHEDULE)} render={withSuspense(AsyncSchedulingRoot)} />
      <Route
        routeId={ROUTE_CONTACT_LIST}
        {...getRouteProps(ROUTE_CONTACT_LIST)}
        render={withSuspense(AsyncContactListRoot)}
      />
      <Route routeId={ROUTE_ACCOUNT} {...getRouteProps(ROUTE_ACCOUNT)} render={withSuspense(AsyncAccountRoot)} />
    </Switch>
  );
};

AppRouterOutlet.displayName = 'AppRouterOutlet';
