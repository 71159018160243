import {
  Dialog,
  DialogActions,
  DialogActionsProps,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  DialogTitleProps
} from '@material-ui/core';
import { Utils } from '@sigmail/common';
import clsx from 'clsx';
import React from 'react';
import { getNextId } from '../../../hooks';
import style from './dialog-box.module.css';

type MuiDialogProps = Omit<DialogProps, 'ref' | 'children' | 'onClose' | 'title'>;

export interface DialogBoxProps extends MuiDialogProps {
  ActionsProps?: DialogActionsProps;
  actions?: React.ReactNode;
  body?: React.ReactNode;
  ContentProps?: DialogContentProps;
  children?: never;
  onClose?: ((event: {}, reason: string) => void) | undefined;
  TitleProps?: DialogTitleProps;
  title?: React.ReactNode;
  titleId?: string;
}

const PROP_TITLE_ID: Extract<keyof DialogBoxProps, 'titleId'> = 'titleId';

const PROPS_TO_OMIT: ReadonlyArray<Exclude<keyof DialogBoxProps, keyof MuiDialogProps>> = [
  'children',
  'title',
  'titleId',
  'body',
  'actions',
  'TitleProps',
  'ContentProps',
  'ActionsProps'
];

const isTruthyReactNode = (node?: any): node is Exclude<NonNullable<React.ReactNode>, false> =>
  Utils.isNotNil(node) || node === true;

class DialogBoxComponent extends React.PureComponent<DialogBoxProps> {
  private readonly dialogTitleId = `dialog-title-${getNextId().toString(16)}`;

  public render(): React.ReactNode {
    const { title, body, actions } = this.props;

    const rootProps = Utils.omit(this.props, PROPS_TO_OMIT);
    const titleId = Utils.has(this.props, PROP_TITLE_ID) ? this.props.titleId : this.dialogTitleId;
    const TitleProps: DialogTitleProps = { id: titleId, disableTypography: true, ...this.props.TitleProps };
    const ContentProps: DialogContentProps = { ...this.props.ContentProps };
    const ActionsProps: DialogActionsProps = { ...this.props.ActionsProps };

    return (
      <Dialog aria-labelledby={titleId} {...rootProps} className={clsx(style.root, rootProps.className)}>
        {(isTruthyReactNode(title) || Utils.isString(TitleProps.dangerouslySetInnerHTML?.__html)) && (
          <DialogTitle id={titleId} disableTypography={true} {...TitleProps}>
            {title}
          </DialogTitle>
        )}

        {(isTruthyReactNode(body) || Utils.isString(ContentProps.dangerouslySetInnerHTML?.__html)) && (
          <DialogContent {...ContentProps}>{body}</DialogContent>
        )}

        {(isTruthyReactNode(actions) || Utils.isString(ActionsProps.dangerouslySetInnerHTML?.__html)) && (
          <DialogActions {...ActionsProps}>{actions}</DialogActions>
        )}
      </Dialog>
    );
  }
}

export const DialogBox = React.forwardRef<HTMLDivElement, DialogBoxProps>((props, ref) => (
  <DialogBoxComponent innerRef={ref} {...props} />
));

DialogBox.displayName = 'DialogBox';
