import { Action, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ThunkExtraArg } from '@sigmail/app-state';
import { SigmailObjectId, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import { ThunkAction as ReduxThunkAction } from 'redux-thunk';
import { REMINDER_NOTIFICATION_STORAGE_KEY_DISMISSED } from '../constants';
import { rootReducer, RootState } from './root-reducer';
import { INITIAL_STATE as REMINDER_NOTIFICATION_INITIAL_STATE } from './reminder-notification-slice';

declare const module: any;

const apiService = new Api.ApiService({
  coreApi: process.env.API_BASE_URL,
  emailApi: process.env.API_BASE_URL,
  authyApi: process.env.API_BASE_URL,
  otpApi: process.env.API_BASE_URL
});

export const store = configureStore({
  devTools: process.env.REACT_APP_ENV !== 'production',
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    thunk: {
      extraArgument: {
        apiService
      }
    },
    serializableCheck: false,
    immutableCheck: false
  }),
  preloadedState: {
    reminderNotification: {
      ...REMINDER_NOTIFICATION_INITIAL_STATE,
      dismissedList: Utils.arrayOrDefault<SigmailObjectId>(
        JSON.parse(window.sessionStorage.getItem(REMINDER_NOTIFICATION_STORAGE_KEY_DISMISSED) as string)
      )
    }
  }
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./root-reducer', () => {
    const newRootReducer = require('./root-reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk<R> = ReduxThunkAction<R, RootState, ThunkExtraArg, Action<string>>;
