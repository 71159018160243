import { BATCH_UPDATE_ERROR_CODE_VERSION_CONFLICT } from './constants';
import * as ErrorCode from './constants/error';

export * from './api-service';
export * from './malformed-response-exception';
export * from './service-exception';
export * from './version-conflict-exception';

/** @public */
export const Constants = {
  Error: {
    ...ErrorCode
  },
  BATCH_UPDATE_ERROR_CODE_VERSION_CONFLICT
} as const;
