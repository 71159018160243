import type { Nullable } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { E_FAIL } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';
import type { KeyDerivationFunction as SigmailCryptoKeyDerivationFunction } from '../types';

/** @internal */
export abstract class KeyDerivationFunction implements SigmailCryptoKeyDerivationFunction {
  public readonly NAME: string;

  protected constructor(name: string) {
    const trimmedName = Utils.trimOrDefault(name);
    if (trimmedName.length === 0) {
      throw new SigmailCryptoException(E_FAIL, 'Invalid name.');
    }

    this.NAME = name;
  }

  /** @inheritdoc */
  // eslint-disable-next-line no-unused-vars
  public abstract derive(seed: string | Uint8Array, version?: Nullable<number | Uint8Array>): Promise<Uint8Array>;
}
