import { ApiActionPayload } from '@sigmail/app-state';
import { AppException, Constants, SigmailGroupId, SigmailUserId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  CryptographicKey,
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  DataObjectMsgFolder,
  GroupContactListItem,
  UserObjectContactList,
  UserObjectFolderList,
  UserObjectFolderListValue,
  UserObjectPreferences,
  UserObjectServerRights
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { CIRCLE_OF_CARE } from '../../../constants/medical-institute-user-group-type-identifier';
import { setActiveGroup } from '../../active-group-slice';
import { authSuccessActOnBehalfFor } from '../../auth-slice';
import { setCaregiverMode } from '../../caregiver-slice';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { ActionInitParams, FetchObjectsRequestData } from '../base-action';
import { batchQuerySuccessAction } from '../batch-query-success-action';
import { AUTH_STATE_AUTHORIZED_AS_ROLE } from '../constants/auth-state-identifier';

export interface Payload {
  actOnBehalfFor: { id: SigmailUserId };
}

export interface State extends AuthenticatedActionState {
  activeGroupId: SigmailGroupId;
  actOnBehalfForUserClaim: string;
  actOnBehalfForCurrentVersionClaim: string;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

export class ActOnBehalfForCareRecipientAction extends AuthenticatedAction<Payload, State> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params);

    this.state.roleAuthClaim = params.getState().caregiver.authClaim;
  }

  /** @override */
  protected get activeGroupId() {
    return null!;
  }

  protected async onExecute() {
    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjectsByType: [] },
      response: { dataObjects: [], userObjectsByType: [], serverDateTime: '' }
    };

    await this.fetchUserPrivateKey(); // 111
    await this.fetchUserClaimToActOnBehalfFor(); // 411
    await this.enterStateForAuthorizedAsGuest();

    await this.fetchUserPublicKey();
    await this.fetchCircleOfCareGroupListAndKeys();
    await this.preloadUserMessageFolders();

    try {
      await this.dispatch(batchQuerySuccessAction(this.state.successPayload));
    } catch (error) {
      this.logger.warn('batchQuerySuccessAction failed with an error', error);
      throw error;
    }

    const { activeGroupId, roleAuthClaim } = this.state;

    this.dispatch(setActiveGroup(activeGroupId));
    this.dispatch(authSuccessActOnBehalfFor(roleAuthClaim));
    this.dispatch(setCaregiverMode());
  }

  private async fetchUserClaimToActOnBehalfFor(): Promise<void> {
    const { actOnBehalfFor } = this.payload;
    const { roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS, userId: actOnBehalfFor.id }]
    };

    const { claims, userObjectList } = await this.dispatchFetchObjects(query);

    const currentVersionClaim = this.findClaim(claims, { name: 'currentVersion' });
    if (Utils.isNil(currentVersionClaim)) {
      throw new AppException(Constants.Error.E_CLAIM_MISSING_OR_INVALID);
    }

    const serverRightsJson = this.findUserObject(userObjectList, {
      type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS,
      userId: actOnBehalfFor.id
    });
    if (Utils.isNil(serverRightsJson)) {
      throw new Api.MalformedResponseException("Member's server rights object could not be fetched.");
    }

    const serverRightsObject = new UserObjectServerRights(serverRightsJson);
    const { userClaim } = await serverRightsObject.decryptedValue();

    this.state.actOnBehalfForUserClaim = userClaim;
    this.state.actOnBehalfForCurrentVersionClaim = currentVersionClaim;
  }

  private async enterStateForAuthorizedAsGuest(): Promise<void> {
    const { accessToken, roleAuthClaim, actOnBehalfForCurrentVersionClaim, actOnBehalfForUserClaim } = this.state;

    const { authState } = await this.enterState(accessToken, {
      authState: roleAuthClaim,
      claims: [actOnBehalfForCurrentVersionClaim, actOnBehalfForUserClaim],
      state: AUTH_STATE_AUTHORIZED_AS_ROLE[Constants.ROLE_ID_GUEST]!
    });

    this.state.roleAuthClaim = authState;
  }

  private async preloadUserMessageFolders() {
    this.logger.info('Trying to preload user message folder data.');

    const { actOnBehalfFor } = this.payload;
    const { roleAuthClaim: authState, successPayload } = this.state;

    let query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        { type: process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS, userId: actOnBehalfFor.id },
        { type: process.env.USER_OBJECT_TYPE_FOLDER_LIST, userId: actOnBehalfFor.id }
      ]
    };

    const { userObjectList } = await this.dispatchFetchObjects(query);

    const folderListJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_FOLDER_LIST });
    if (Utils.isNil(folderListJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'No matching user folder list object could be found.');
    }

    const folderListObject = new UserObjectFolderList(folderListJson);
    const folderList: UserObjectFolderListValue<1 | 2> = await folderListObject.decryptedValue();
    let messageFolderIds: Array<number> = [];
    if (Utils.isUndefined(folderList.$$formatver) || folderList.$$formatver === 1) {
      messageFolderIds = Object.values(folderList)
        .map((folder) => folder?.id)
        .filter(DataObjectMsgFolder.isValidId);
    } else if (folderList.$$formatver >= 2) {
      messageFolderIds = Utils.flatten(
        Object.values(folderList.msg).map(({ id, children }) => {
          const ids: Array<number | undefined> = [id];
          if (!Utils.isUndefined(children)) {
            ids.push(...Object.values(children).map((folder) => folder?.id));
          }
          return ids.filter(DataObjectMsgFolder.isValidId);
        })
      );
    }

    query = {
      authState,
      dataObjects: { ids: messageFolderIds },
      expectedCount: { dataObjects: null }
    };

    const { dataObjectList } = await this.dispatchFetchObjects(query);

    successPayload.request.dataObjects!.ids.push(...messageFolderIds);
    successPayload.response.dataObjects!.push(...dataObjectList);
  }

  private async fetchUserPrivateKey(): Promise<void> {
    this.logger.info('Fetching user private key encrypted for care giver.');

    const { id: userId } = this.payload.actOnBehalfFor;
    const { currentUser, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [{ id: userId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, encryptedForId: currentUser.id }]
    };

    const { keyList } = await this.dispatchFetchObjects(query);

    const userPrivateKeyJson = this.findKey(keyList, {
      id: userId,
      type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE,
      encryptedForId: currentUser.id
    });
    if (Utils.isNil(userPrivateKeyJson)) {
      throw new Api.MalformedResponseException('User private key could not be fetched.');
    } else {
      const userPrivate = new CryptographicKeyPrivate(userPrivateKeyJson);
      await CryptographicKey.cache(userPrivate);
    }
  }

  private async fetchUserPublicKey() {
    this.logger.info('Fetching user public key.');

    const { id: userId } = this.payload.actOnBehalfFor;
    const { roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [{ id: userId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }]
    };

    const { keyList } = await this.dispatchFetchObjects(query);

    const userPublicKeyJson = this.findKey(keyList, {
      id: userId,
      type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC
    });
    if (Utils.isNil(userPublicKeyJson)) {
      throw new Api.MalformedResponseException('User public key could not be fetched.');
    } else {
      const userKeyPublic = new CryptographicKeyPublic(userPublicKeyJson);
      await CryptographicKey.cache(userKeyPublic);
    }
  }

  private async fetchCircleOfCareGroupListAndKeys() {
    this.logger.info('Fetching private and public keys of all circle of care groups user is a member of.');

    const { id: userId } = this.payload.actOnBehalfFor;
    const { roleAuthClaim: authState, successPayload } = this.state;

    let query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        { userId, type: process.env.USER_OBJECT_TYPE_CONTACT_LIST },
        { userId, type: process.env.USER_OBJECT_TYPE_PREFERENCES }
      ]
    };

    const { userObjectList } = await this.dispatchFetchObjects(query);

    const contactListJson = this.findUserObject(userObjectList, {
      userId,
      type: process.env.USER_OBJECT_TYPE_CONTACT_LIST
    });
    if (Utils.isNil(contactListJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'No matching user contact list object could be found.');
    }

    const contactListObject = new UserObjectContactList(contactListJson);
    const { contacts: contactList } = await contactListObject.decryptedValue();
    const groupList = contactList.filter(
      (contact): contact is GroupContactListItem => contact.type === 'group' && contact.groupType === CIRCLE_OF_CARE
    );

    if (groupList.length === 0) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, "User's circle of care group list is empty.");
    }

    this.state.activeGroupId = groupList[0].id;

    successPayload.request.userObjectsByType!.push(...query.userObjectsByType!);
    successPayload.response.userObjectsByType!.push(...userObjectList);

    const userPreferencesJson = this.findUserObject(userObjectList, {
      userId,
      type: process.env.USER_OBJECT_TYPE_PREFERENCES
    });
    if (Utils.isNotNil(userPreferencesJson)) {
      try {
        const userPreferencesObject = new UserObjectPreferences(userPreferencesJson);
        const { lastActiveGroupId } = await userPreferencesObject.decryptedValue();
        if (Utils.isInteger(lastActiveGroupId) && groupList.some(({ id }) => id === lastActiveGroupId)) {
          this.state.activeGroupId = lastActiveGroupId;
        }
      } catch (error) {
        this.logger.warn('Error determining last active group ID:', error);
        /* ignore */
      }
    }

    query = {
      authState,
      keysByType: Utils.flatten(
        groupList.map(({ id }) => [
          { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id },
          { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id, encryptedForId: id }
        ])
      )
    };

    const { keyList } = await this.dispatchFetchObjects(query);
    for (const groupKeyJson of keyList) {
      if (groupKeyJson.type === process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE) {
        const groupKeyPrivate = new CryptographicKeyPrivate(groupKeyJson);
        await CryptographicKey.cache(groupKeyPrivate);
      } else if (groupKeyJson.type === process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC) {
        const groupKeyPublic = new CryptographicKeyPublic(groupKeyJson);
        await CryptographicKey.cache(groupKeyPublic);
      } else {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Invalid/unknown object type.');
      }
    }
  }
}

export const actOnBehalfForCareRecipientAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'actOnBehalfForCareRecipientAction:');

    const action = new ActOnBehalfForCareRecipientAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
