import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioProps,
  Slider,
  Switch,
  Tooltip
} from '@material-ui/core';
import { PartialRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n, HealthDataI18n } from '@sigmail/i18n';
import { VitalsFormData as BaseVitalsFormData, VitalsSymptomsData as BaseVitalsSymptomsData } from '@sigmail/objects';
import { FieldState } from 'final-form';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import {
  VitalsFormBPDiastolic,
  VitalsFormBPSystolic,
  VitalsFormHeartRate,
  VitalsFormPulseOximeter
} from '../../../constants/form-input-constraint';
import * as FormInputMask from '../../../constants/form-input-mask';
import { withTranslation } from '../../../i18n';
import { I18N_NS_HEALTH_DATA } from '../../../i18n/config/namespace-identifiers';
import healthDataI18n from '../../../i18n/health-data';
import { VitalsDataUtil } from '../../../utils/health-data';
import { ValidationErrorResult } from '../../shared/form/field-validator';
import { FieldProps, FormComponent, FormComponentProps, FormComponentState } from '../../shared/form/form.component';
import { VITALS_FORM_SLIDER_INPUT_STEP } from '../constants';
import style from './vitals-questionnaire.module.css';

const { vitalsQuestionnaire: i18n } = healthDataI18n.form;

export type FieldsetName = keyof typeof i18n;

export type FieldNameActivity = keyof typeof i18n.fieldsetActivity.formField;
export type FieldNameAlcohol = keyof typeof i18n.fieldsetAlcohol.formField;
export type FieldNameBloodPressure = keyof typeof i18n.fieldsetBloodPressure.formField;
export type FieldNameChangeInWeight = keyof typeof i18n.fieldsetChangeInWeight.formField;
export type FieldNameDiet = keyof typeof i18n.fieldsetDiet.formField;
export type FieldNameHeight = keyof typeof i18n.fieldsetHeight.formField;
export type FieldNameHeartRate = keyof typeof i18n.fieldsetHeartRate.formField;
export type FieldNameNotes = keyof typeof i18n.fieldsetNotes.formField;
export type FieldNamePulseOximeter = keyof typeof i18n.fieldsetPulseOximeter.formField;
export type FieldNameSymptoms = keyof typeof i18n.fieldsetSymptoms.formField;
export type FieldNameWeight = keyof typeof i18n.fieldsetWeight.formField;

export type FieldName =
  | FieldNameActivity
  | FieldNameAlcohol
  | FieldNameBloodPressure
  | FieldNameChangeInWeight
  | FieldNameDiet
  | FieldNameHeight
  | FieldNameHeartRate
  | FieldNameNotes
  | FieldNamePulseOximeter
  | FieldNameSymptoms
  | FieldNameWeight;

type VitalsFormData<T extends keyof BaseVitalsFormData> = NonNullable<BaseVitalsFormData[T]>;
type VitalsSymptomsData<T extends keyof BaseVitalsSymptomsData> = NonNullable<BaseVitalsSymptomsData[T]>;

type ActivityFormValues = Record<Extract<FieldName, 'activity'>, VitalsFormData<'activity'>>;
type AlcoholFormValues = Record<Extract<FieldName, 'alcohol'>, VitalsFormData<'alcohol'>>;
type BooleanFormValues = Record<Extract<FieldName, 'heightToggle' | 'weightToggle'>, boolean>;
type ChangeInWeightFormValues = Record<Extract<FieldName, 'changeInWeight'>, VitalsFormData<'changeInWeight'>>;
type DietFormValues = Record<Extract<FieldName, 'diet'>, NonNullable<BaseVitalsFormData['diet']>>;
type NumberFormValues = Record<FieldNameHeartRate | FieldNameBloodPressure | FieldNamePulseOximeter, number>;
type StringFormValues = Record<Extract<FieldName, 'height' | 'notes' | `other${string}` | 'weight'>, string>;
type SymptomsFormValues = Record<Extract<FieldName, 'wellBeing'>, VitalsSymptomsData<'wellBeing'>> &
  Record<Extract<FieldName, 'dyspnea'>, VitalsSymptomsData<'dyspnea'>> &
  Record<Extract<FieldName, 'swelling'>, VitalsSymptomsData<'swelling'>>;

export type FormValues = ActivityFormValues &
  AlcoholFormValues &
  BooleanFormValues &
  ChangeInWeightFormValues &
  DietFormValues &
  NumberFormValues &
  StringFormValues &
  SymptomsFormValues;

export const FIELD_SET_LIST: ReadonlyArray<[FieldsetName, ReadonlyArray<FieldName>]> = [
  ['fieldsetSymptoms', ['wellBeing', 'dyspnea', 'swelling']],
  ['fieldsetHeartRate', ['heartRate']],
  ['fieldsetBloodPressure', ['systolic', 'diastolic']],
  ['fieldsetHeight', ['heightToggle', 'height']],
  ['fieldsetWeight', ['weightToggle', 'weight']],
  ['fieldsetChangeInWeight', ['changeInWeight', 'otherChangeInWeight']],
  ['fieldsetActivity', ['activity', 'otherActivity']],
  ['fieldsetAlcohol', ['alcohol']],
  ['fieldsetDiet', ['diet', 'otherDiet']],
  ['fieldsetPulseOximeter', ['pulseOximeter']],
  ['fieldsetNotes', ['notes']]
];

type FieldPropsRecord = Record<FieldName, FieldProps>;

const FIELD_PROPS: Readonly<FieldPropsRecord> = {
  ...FIELD_SET_LIST.reduce(
    (passDownProps, [fieldsetKey, fieldNameList]) =>
      fieldNameList.reduce((fieldProps, fieldName) => {
        const fieldsetI18n = i18n[fieldsetKey] as any;

        let label: string = fieldsetI18n.formField[fieldName].label;
        const placeholder: string | undefined = fieldsetI18n.formField[fieldName].placeholder;
        if (label.length === 0) label = undefined!;

        fieldProps[fieldName] = { label, placeholder, ...fieldProps[fieldName] };
        return fieldProps;
      }, passDownProps),
    ({
      activity: {
        config: {
          validateFields: ['otherActivity']
        }
      },
      changeInWeight: {
        config: {
          validateFields: ['otherChangeInWeight']
        }
      },
      diet: {
        config: { validateFields: ['otherDiet'] }
      },
      height: {
        maskedInputProps: { guide: false }
      },
      notes: {
        multiline: true,
        rows: 8,
        rowsMax: 8
      },
      otherActivity: {
        config: {
          required: (_: unknown, { activity }: FormValues) => activity === 'other'
        }
      },
      otherChangeInWeight: {
        config: {
          required: (_: unknown, { changeInWeight }: FormValues) => changeInWeight === 'other'
        }
      },
      otherDiet: {
        config: {
          required: (_: unknown, { diet }: FormValues) => Utils.isArray(diet) && diet.includes('other')
        }
      },
      weight: {
        maskedInputProps: { guide: false, mask: FormInputMask.Weight }
      }
    } as unknown) as FieldPropsRecord
  )
};

const INITIAL_VALUES: FormValues = {
  activity: null!,
  alcohol: null!,
  changeInWeight: null!,
  diastolic: VitalsFormBPDiastolic.min,
  diet: EMPTY_ARRAY,
  dyspnea: null!,
  heartRate: VitalsFormHeartRate.min,
  height: '',
  heightToggle: false,
  notes: '',
  otherActivity: '',
  otherChangeInWeight: '',
  otherDiet: '',
  pulseOximeter: VitalsFormPulseOximeter.min,
  swelling: null!,
  systolic: VitalsFormBPSystolic.min,
  weight: '',
  weightToggle: false,
  wellBeing: null!
};

export type VitalsQuestionnaireFormClassKey =
  | 'inputRoot'
  | `radio${'Control' | 'ControlLabel' | 'Group' | 'Icon' | 'Label'}`
  | `slider${'Control' | 'ControlLabel' | 'Group' | 'RangeLabel'}`;

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<VitalsQuestionnaireFormClassKey, string> | undefined;
}

type ComponentProps = Props & WithTranslation;
type State = FormComponentState<FieldName, FormValues>;

class VitalsQuestionnaireFormComponent extends FormComponent<
  FieldsetName,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes'
    );

    this.createForm({ initialValues: INITIAL_VALUES });
  }

  public componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<State>): void {
    super.componentDidUpdate(prevProps, prevState);

    if (prevState.heightToggle?.value !== this.state.heightToggle?.value) {
      this.form.change('height', '');
    }

    if (prevState.weightToggle?.value !== this.state.weightToggle?.value) {
      this.form.change('weight', '');
    }
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-vitals-${this.formIdSuffix}`;
  }

  // /** @override */
  // protected getFormSubscription(...args: any[]) {
  //   return { ...super.getFormSubscription(...args), hasValidationErrors: true };
  // }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetName: FieldsetName) {
    const label = Utils.trimOrDefault(i18n[fieldsetName].label);
    return label.length > 0 ? this.props.t(label) : null;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldType(fieldName: FieldName, ...args: any[]) {
    return fieldName === 'diet' ? 'checkbox' : super.getFieldType(fieldName, ...args);
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { t } = this.props;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    switch (fieldName) {
      case 'height': {
        const { value: heightToggle } = this.state.heightToggle;
        const fieldI18n = i18n.fieldsetHeight.formField.height;
        const maskedInputProps = { ...FIELD_PROPS[fieldName].maskedInputProps };

        if (heightToggle === true) {
          placeholder = t(fieldI18n.placeholderFeet);
          maskedInputProps.mask = FormInputMask.Height.FEET;
        } else {
          placeholder = t(fieldI18n.placeholderCMS);
          maskedInputProps.mask = FormInputMask.Height.CMS;
        }

        fieldProps = { ...fieldProps, maskedInputProps };
        break;
      }
      case 'weight': {
        const { value: weightToggle } = this.state.weightToggle;
        const fieldI18n = i18n.fieldsetWeight.formField.weight;

        placeholder = t(weightToggle === true ? fieldI18n.placeholderLBS : fieldI18n.placeholderKGS);
        break;
      }
      default:
        break;
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];

        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    return Utils.isString(errorMessageI18n) ? t(errorMessageI18n) : error;
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]): React.ReactNode {
    const { classes, t } = this.props;
    const { change, value } = this.state[fieldName];

    if (
      (fieldName === 'otherActivity' && this.state.activity.value !== 'other') ||
      (fieldName === 'otherChangeInWeight' && this.state.changeInWeight.value !== 'other') ||
      (fieldName === 'otherDiet' && !Utils.arrayOrDefault(this.state.diet.value).includes('other'))
    ) {
      return;
    }

    const fieldsetIndex = FIELD_SET_LIST.findIndex(([_, field]) => field.includes(fieldName));
    if (fieldsetIndex === -1) return null;

    const [fieldsetName] = FIELD_SET_LIST[fieldsetIndex];

    let fieldNode: React.ReactNode;
    switch (fieldName) {
      case 'wellBeing':
      case 'dyspnea':
      case 'swelling':
        fieldNode = this.radioFieldRenderer(fieldName, i18n.fieldsetSymptoms.formField[fieldName].options);
        break;
      case 'heightToggle':
      case 'weightToggle': {
        const inputLabel = (i18n[fieldsetName] as any).formField[fieldName];

        fieldNode = (
          <FormLabel component="div">
            <span>{t(inputLabel[fieldName === 'heightToggle' ? 'labelCMS' : 'labelKGS'])}</span>

            <Switch
              checked={value}
              color="default"
              disableFocusRipple={true}
              disableRipple={true}
              disableTouchRipple={true}
              onChange={(_, value) => change(value)}
            />

            <span>{t(inputLabel[fieldName === 'heightToggle' ? 'labelFeet' : 'labelLBS'])}</span>
          </FormLabel>
        );
        break;
      }
      case 'heartRate':
      case 'systolic':
      case 'diastolic':
      case 'pulseOximeter':
        fieldNode = this.sliderFieldRenderer(fieldName);
        break;
      case 'changeInWeight': {
        const { options } = i18n.fieldsetChangeInWeight.formField.changeInWeight;
        fieldNode = this.radioFieldRenderer(fieldName, options);
        break;
      }
      case 'activity': {
        const { options } = i18n.fieldsetActivity.formField.activity;
        fieldNode = this.radioFieldRenderer(fieldName, options);
        break;
      }
      case 'alcohol': {
        const { options } = i18n.fieldsetAlcohol.formField.alcohol;
        fieldNode = this.radioFieldRenderer(fieldName, options);
        break;
      }
      default:
        fieldNode = super.renderField(fieldName, ...args);
        break;
    }

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  /** @override */
  protected checkboxFieldRenderer(_: unknown): React.ReactNode {
    const { options } = i18n.fieldsetDiet.formField.diet;

    const { disabled, readOnly, t } = this.props;
    const { error, ...inputState } = this.state.diet;
    const value = inputState.value as FormValues['diet'];

    return options.map(({ codedValue, label }) => (
      <div key={codedValue}>
        {super.checkboxFieldRenderer({
          checked: value.includes(codedValue),
          color: 'primary',
          'data-value': codedValue,
          disabled: disabled === true,
          readOnly: readOnly === true,
          error,
          label: t(label),
          onChange: () => {
            const inputState = this.state.diet;

            let value = inputState.value as FormValues['diet'];
            if (value.includes(codedValue)) {
              value = value.filter((option) => option !== codedValue);
            } else {
              value = [...value, codedValue].sort();
            }

            inputState.change(value);
          }
        })}
      </div>
    ));
  }

  private radioFieldRenderer(
    fieldName: Extract<FieldName, 'activity' | 'alcohol' | 'changeInWeight' | 'dyspnea' | 'swelling' | 'wellBeing'>,
    options: ReadonlyArray<HealthDataI18n.Form.VitalsFormInputOption<number | string>>
  ): React.ReactNode {
    const { classes, disabled, t } = this.props;
    const { change, error, value } = this.state[fieldName];

    const isSymptomsField = fieldName === 'dyspnea' || fieldName === 'swelling' || fieldName === 'wellBeing';
    const radioNodeList = options.map(({ codedValue, icon: optionIcon, label: optionLabel }) => {
      const radioProps: RadioProps = { color: 'primary', name: fieldName, size: 'small' };
      let label: string | undefined;

      if (isSymptomsField) {
        const IconComponent = optionIcon!;
        radioProps.inputProps = { 'aria-label': t(optionLabel) };
        radioProps.icon = <IconComponent fontSize="inherit" />;
        radioProps.checkedIcon = <IconComponent fontSize="inherit" color="primary" />;
      } else {
        label = t(optionLabel);
      }

      return (
        <FormControlLabel
          classes={{ root: classes!.radioLabel }}
          control={<Radio classes={{ root: classes!.radioIcon }} {...radioProps} />}
          key={codedValue}
          label={label}
          value={codedValue}
        />
      );
    });

    return (
      <FormControl
        aria-disabled={disabled}
        aria-invalid={Utils.isString(error)}
        aria-required="true"
        classes={{ root: classes!.radioControl }}
        key={fieldName}
        disabled={disabled}
      >
        <FormLabel classes={{ root: classes!.radioControlLabel }} component="div">
          {t(FIELD_PROPS[fieldName].label as string)}
        </FormLabel>

        <RadioGroup
          classes={{ root: classes!.radioGroup }}
          onChange={(_, newValue) => change(newValue === 'other' ? 'other' : Number(newValue))}
          row={isSymptomsField}
          value={value}
        >
          {radioNodeList}
        </RadioGroup>

        {Utils.isString(error) && <FormHelperText error={true}>{error}</FormHelperText>}
      </FormControl>
    );
  }

  private sliderFieldRenderer(
    fieldName: Extract<FieldName, 'diastolic' | 'heartRate' | 'pulseOximeter' | 'systolic'>
  ): React.ReactNode {
    const { classes, disabled, t } = this.props;
    const { change, value } = this.state[fieldName];

    const rangeLabel = VitalsDataUtil.getDataRangeLabel(fieldName, value, t);

    return (
      <FormControl
        aria-disabled={disabled}
        aria-required="true"
        classes={{ root: classes!.sliderControl }}
        key={fieldName}
        disabled={disabled}
      >
        <FormLabel classes={{ root: classes!.sliderControlLabel }} component="div">
          {t(FIELD_PROPS[fieldName].label as string)}
        </FormLabel>

        <div className={classes!.sliderGroup}>
          <Slider
            {...VitalsDataUtil.getDataRange(fieldName)}
            onChange={(_, value) => change(value)}
            step={VITALS_FORM_SLIDER_INPUT_STEP}
            ValueLabelComponent={({ children, open }) => (
              <Tooltip enterTouchDelay={0} open={open} placement="top" title={rangeLabel}>
                {children}
              </Tooltip>
            )}
            value={value}
            valueLabelDisplay="auto"
          />

          <span className={classes!.sliderRangeLabel}>{rangeLabel}</span>
        </div>
      </FormControl>
    );
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<any>) {
    let { error } = fieldState;

    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    return super.setFieldState(fieldName, { ...fieldState, error });
  }

  /** @override */
  protected formSubmitHandler(values: FormValues) {
    const formValues = Utils.mapValues(values, (value, fieldName: FieldName) => {
      if (Utils.isString(value)) value = value.trim();

      if (fieldName !== 'heightToggle' && fieldName !== 'weightToggle') {
        if (
          value === INITIAL_VALUES[fieldName] ||
          ((Utils.isString(value) || Utils.isArray(value)) && value.length === 0)
        ) {
          value = undefined!;
        }
      }

      return value;
    });

    return super.formSubmitHandler((formValues as unknown) as FormValues);
  }
}

const ns = [I18N_NS_HEALTH_DATA];

export const VitalsQuestionnaireForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['form-input-group'],
      radioControl: style.radio,
      radioControlLabel: style['group-label'],
      radioGroup: style.group,
      radioIcon: style.icon,
      radioLabel: style.label,
      sliderControl: style.slider,
      sliderControlLabel: style.label,
      sliderGroup: style.group,
      sliderRangeLabel: style['range-label']
    } as ComponentProps['classes']);

    return <VitalsQuestionnaireFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

VitalsQuestionnaireForm.displayName = 'VitalsQuestionnaireForm';
VitalsQuestionnaireForm.defaultProps = { className: style.form };
