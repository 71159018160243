import { AppUser, MembershipStatus, Nullable, Utils } from '@sigmail/common';
import { ClientObject, ClientUserListItem, ContactListItemExt, UserContactListItemExtData } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatchFetchServerDateTime, useDispatchFetchUserObjects, UseDispatchFetchUserObjectsParams } from '.';
import membershipStatusI18n from '../../i18n/global/membership-status';
import { BatchQueryRequest, ExcludeListItem, FilterFunction } from '../../utils/contact-list';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { currentUserIdSelector, selectAccessRight } from '../selectors/auth';
import { userListObjectSelector } from '../selectors/client-object';
import { basicProfileObjectSelector, clientIdSelector } from '../selectors/user-object';
import { Cache as ScopedUserObjectCache, UserObjectCache } from '../user-objects-slice/cache';

export interface UseFlattenedClientUserListParams {
  disabledMembershipList?: Nullable<boolean>;
  exclude?: Nullable<ReadonlyArray<Nullable<ExcludeListItem>>>;
  filterFn?: Nullable<FilterFunction<ContactListItemExt>>;
  forceFetch?: Nullable<boolean>;
  users?: Nullable<boolean | MembershipStatus>;
}

export type FlattenedClientUserListItem = ClientUserListItem &
  Pick<UserContactListItemExtData, 'accessCode' | 'accountStatus' | 'credentialExpiry' | 'memberOf'>;

export type UseFlattenedClientUserListResult = [
  list: ReadonlyArray<FlattenedClientUserListItem>,
  query: BatchQueryRequest
];

const isValidMembershipStatusKey = (value?: any): value is MembershipStatus =>
  Object.keys(membershipStatusI18n).includes(value);

const TYPE_PROFILE_BASIC = process.env.USER_OBJECT_TYPE_PROFILE_BASIC;
const REGEX_NON_HPN_ACCESS_CODE = /^[A-Z]+$/;

export const useFlattenedClientUserList = (params?: Nullable<UseFlattenedClientUserListParams>) => {
  const canAccessUserList = useSelector(selectAccessRight)('accessClientUserList');
  const currentUserId = useSelector(currentUserIdSelector);
  const clientUserListSelector = useSelector(userListObjectSelector);
  const userListObject = clientUserListSelector(/***/);
  const userList = params?.forceFetch === true ? undefined : UserObjectCache.getValue(userListObject);

  const disabledMembershipList = params?.disabledMembershipList === true;
  // const exclude = params?.exclude;
  // const filterFn = typeof params?.filterFn === 'function' ? params!.filterFn! : filterContactList;
  const includeUserList = params?.users === true || isValidMembershipStatusKey(params?.users) ? params!.users! : false;

  const clientId = useSelector(clientIdSelector);
  const isUserListNeeded = includeUserList !== false && Utils.isNil(userList);
  const isClientIdNeeded = isUserListNeeded && !ClientObject.isValidId(clientId);

  const [dtServer, setServerDateTime] = React.useState(Date.now());
  const dispatchFetchServerDateTime = useDispatchFetchServerDateTime();
  const dispatchFetchUserObjects = useDispatchFetchUserObjects();
  const basicProfileSelector = useSelector(basicProfileObjectSelector);
  const scopedCache = React.useRef<ScopedUserObjectCache>();
  React.useEffect(() => {
    if (includeUserList === false || Utils.isNil(userList)) return;

    let cache = scopedCache.current!;
    if (Utils.isNil(cache)) {
      cache = scopedCache.current = new ScopedUserObjectCache();
    }

    const promise = Utils.makeCancelablePromise(
      dispatchFetchServerDateTime()
        .then((value) => setServerDateTime(value.getTime()), Utils.noop)
        .then(() => {
          let userObjects: NonNullable<UseDispatchFetchUserObjectsParams['userObjects']> = [];

          if (!disabledMembershipList && (includeUserList === true || includeUserList === 'active')) {
            userObjects = Utils.filterMap(userList.active, ({ id: userId, role }) => {
              if (Utils.isNonGuestRole(role)) {
                let profileObject = basicProfileSelector(userId);
                let profile = UserObjectCache.getValue(profileObject);
                if (Utils.isNil(profileObject)) {
                  profileObject = cache.find((obj) => obj.type === TYPE_PROFILE_BASIC && obj.userId === userId)?.[0];
                  profile = UserObjectCache.getValue(profileObject);
                }

                if (Utils.isNil(profileObject) || Utils.isNil(profile)) {
                  return { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId };
                }

                cache.add(profileObject, profile);
              }
              return false;
            });
          }

          // dispatch request even if userObjects array is empty; least it would
          // respond back with current server date and time
          return dispatchFetchUserObjects({ userObjects }, cache);
        }, Utils.noop)
        .then((response) => {
          if (Utils.isNonArrayObjectLike<Api.BatchQueryResponseData>(response)) {
            setServerDateTime(new Date(response.serverDateTime).getTime() + 1000);
            // + 1000 because during testing, we found out that sometimes API
            // server is responding with the same timestamp as the previous one.
            // if we don't add one second then the flattenedUserList's update
            // won't trigger
          }
        }, Utils.noop)
    );

    return () => promise.cancel();
  }, [
    basicProfileSelector,
    disabledMembershipList,
    dispatchFetchServerDateTime,
    dispatchFetchUserObjects,
    includeUserList,
    userList
  ]);

  return React.useMemo((): UseFlattenedClientUserListResult => {
    let flattenedUserList: Array<UseFlattenedClientUserListResult[0][0]> = EMPTY_ARRAY;
    const userObjects: Required<UseFlattenedClientUserListResult[1]>['userObjectsByType'] = [];

    do {
      if (!canAccessUserList) break;
      if (includeUserList === false) break;
      if (!AppUser.isValidId(currentUserId)) break;
      if (userObjects.length > 0) break;

      if (isClientIdNeeded) {
        userObjects.push({ type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId: currentUserId });
        break;
      }

      if (isUserListNeeded) {
        userObjects.push({ type: process.env.CLIENT_OBJECT_TYPE_USER_LIST, userId: clientId! });
        break;
      }

      const { active, inactive, pending } = userList!;
      const { current: cache } = scopedCache;
      const combinedUserList = flattenedUserList.slice();
      for (const list of [active, inactive, pending]) {
        let accountStatus: MembershipStatus = list === active ? 'active' : list === pending ? 'pending' : 'inactive';

        for (const { accessCode, credentialId, ...user } of list as typeof pending) {
          if (
            includeUserList !== true &&
            includeUserList !== accountStatus &&
            includeUserList === 'expired' &&
            accountStatus !== 'pending'
          ) {
            break;
          }

          const invitationAccessCode =
            Utils.isGuestRole(user.role) && REGEX_NON_HPN_ACCESS_CODE.test(accessCode) ? accessCode : undefined!;
          const isExpired = list === pending && dtServer > user.credentialExpiry;

          const profile = cache?.find((obj) => obj.type === TYPE_PROFILE_BASIC && obj.userId === user.id)?.[1];
          combinedUserList.push({
            ...user,
            accessCode: invitationAccessCode,
            accountStatus: isExpired ? 'expired' : accountStatus,
            memberOf: profile?.memberOf
          });
        }
      }

      flattenedUserList = combinedUserList;
      // flattenedUserList = filterFn(flattenedUserList, { exclude });
    } while (false);

    let query: UseFlattenedClientUserListResult[1] = EMPTY_PLAIN_OBJECT;
    if (userObjects.length > 0) query = { userObjectsByType: userObjects };

    return [flattenedUserList, query];
  }, [
    canAccessUserList,
    clientId,
    currentUserId,
    dtServer,
    includeUserList,
    isClientIdNeeded,
    isUserListNeeded,
    userList
  ]);
};
