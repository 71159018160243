import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { EnglishCanada, FrenchCanada } from '../../constants/language-codes';
import addressLevel1 from './address-level1';
import billingFileDialog from './dialog-billing-file';
import newVersionAvailableDialog from './dialog-new-version-available';
import selectEMRProviderFolderDialog from './dialog-select-emr-provider-folder';
import selectPatientRecordDialog from './dialog-select-patient-record';
import documentTitle from './document-title';
import dropzoneInput from './dropzone-input';
import fhirRelationshipList from './fhir-relationship-list';
import billingFileForm from './form-billing-file';
import searchPatientListForm from './form-search-patient-list.component';
import signInCredentialsForm from './form-sign-in-credentials';
import genderList from './gender-list';
import selectPatientRecordGrid from './grid-select-patient-record';
import healthPlanJurisdictionList from './health-plan-jurisdiction-list';
import memberLicense from './member-license';
import memberRole from './member-role';
import membershipStatus from './membership-status';
import navAction from './nav-action';
import physicianSpecialtyList from './physician-specialty-list';
import securityQuestion from './security-question';
import signInPage from './sign-in-page';
import staffSpecialtyList from './staff-specialty-list';

const i18n: GlobalI18n = {
  appLayout: {
    // Translators: (HTML) text displayed when care recipient list is empty.
    emptyCareRecipientList: gettext('global', 'globalAppLayout', 'emptyCareRecipientList'),
    // Translators: (HTML) text displayed when no care recipient is selected.
    noCareRecipientSelected: gettext('global', 'globalAppLayout', 'noCareRecipientSelected')
  },
  // Translators: Product name (currently SigMail)
  productName: gettext('global', 'global', 'productName'),
  // Translators: Logo text
  logoText: gettext('global', 'global', 'logoText'),
  contactUsNumber: {
    // Translators: Phone Number to be displayed for Contact Us information (e.g. 1-888-555-1234)
    label: gettext('global', 'global', 'contactUsNumberLabel'),
    // Translators: Phone Number URL (typically a telephone link, e.g. tel:+1-888-555-1234)
    href: gettext('global', 'global', 'contactUsNumberUrl', 'url')
  },
  // Translators: (HTML) Copyright Notice
  copyrightNotice: gettext('global', 'global', 'copyrightNotice', 'safe-html'),
  // Translators: Label for the application-wide Group Selector input
  inputLabelGroupSelector: gettext('global', 'global', 'inputLabelGroupSelector'),
  // Translators: Generic message to be displayed if an operation fails with an error
  errorOccurredMessageGeneric: gettext('global', 'global', 'errorOccurredMessageGeneric'),
  // Translators: Generic message to be displayed if an operation (which can be retried) fails with an error
  errorOccurredTryAgainMessageGeneric: gettext('global', 'global', 'errorOccurredTryAgainMessageGeneric'),
  // Translators: Generic message to be displayed if a data load operation fails with an error
  errorOccurredLoadingDataMessageGeneric: gettext('global', 'global', 'errorOccurredLoadingDataMessageGeneric'),
  // Translators: Generic message to be displayed if a data load operation (which can be retried) fails with an error
  errorOccurredLoadingDataTryAgainMessageGeneric: gettext('global', 'global', 'errorOccurredLoadingDataTryAgainMessageGeneric'),
  // Translators: Label for the Open Drawer Menu button (available to screen readers only)
  stoppedEncounterMessageForPatients: gettext('global', 'global', 'stoppedEncounterMessageForPatients'),
  // Translators: Label for the Open Drawer Menu button (available to screen readers only)
  stoppedEncounterMessageForPhysicians: gettext('global', 'global', 'stoppedEncounterMessageForPhysicians'),
  // Translators: Label for the Open Drawer Menu button (available to screen readers only)
  ariaLabelOpenDrawer: gettext('global', 'global', 'ariaLabelOpenDrawer'),
  // Translators: Label for the Close Drawer Menu button (available to screen readers only)
  ariaLabelCloseDrawer: gettext('global', 'global', 'ariaLabelCloseDrawer'),
  // Translators: Label for the Close button of a popup (dialog, alert, etc.) (available to screen readers only)
  ariaLabelClosePopup: gettext('global', 'global', 'ariaLabelClosePopup'),
  // Translators: Message displayed when user does not have sufficient rights to perform an operation
  insufficientRightsMessage: gettext('global', 'global', 'insufficientRightsMessage'),
  invalidEmailLinkAlert: {
    // Translators: Title of the alert displayed when an email link is no longer valid
    title: gettext('global', 'global', 'alertTitleInvalidEmailLink'),
    // Translators: (HTML) Body of the alert displayed when an email link is no longer valid
    body: gettext('global', 'global', 'alertBodyInvalidEmailLink', 'safe-html')
  },
  action: navAction,
  signInPage,
  addressLevel1,
  documentTitle,
  dropzoneInput,
  dialog: {
    billingFile: billingFileDialog,
    newVersionAvailable: newVersionAvailableDialog,
    appOffline: {
      // Translators: (HTML) Body displayed in the Application Offline dialog
      body: gettext('global', 'body', 'dialogAppOffline', 'safe-html')
    },
    selectPatientRecord: selectPatientRecordDialog,
    selectEMRProviderFolder: selectEMRProviderFolderDialog
  },
  fhirRelationshipList,
  form: {
    billingFile: billingFileForm,
    searchPatientList: searchPatientListForm,
    signInCredentials: signInCredentialsForm
  },
  grid: {
    selectPatientRecord: selectPatientRecordGrid
  },
  genderList,
  healthPlanJurisdictionList,
  memberLicense,
  memberRole,
  membershipStatus,
  pdfViewer: {
    message: {
      // Translators: (HTML) Message to be displayed in case of a document error \
      // in PDF Viewer component
      documentError: gettext('global', 'documentError', 'pdfViewerMessage', 'safe-html'),
      // Translators: (HTML) Message to be displayed in case of a page error in \
      // PDF Viewer component
      pageError: gettext('global', 'pageError', 'pdfViewerMessage', 'safe-html'),
      // Translators: (HTML) Message to be displayed in case a document is not \
      // specified for PDF Viewer component to load
      selectDocument: gettext('global', 'selectDocument', 'pdfViewerMessage', 'safe-html'),
      // Translators: (HTML) Message to be displayed in case a page is not \
      // specified for PDF Viewer component to load
      selectPage: gettext('global', 'selectPage', 'pdfViewerMessage', 'safe-html')
    }
  },
  physicianSpecialtyList,
  securityQuestion,
  staffSpecialtyList,
  languageName: [
    {
      code: EnglishCanada,
      // Translators: Label for language name: English (Canada)
      label: gettext('global', 'enCA', 'languageName')
    },
    {
      code: FrenchCanada,
      // Translators: Label for language name: French (Canada)
      label: gettext('global', 'frCA', 'languageName')
    }
  ]
};

export default i18n;
