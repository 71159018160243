export const CalendarFileName = 'ics_file_name';
export const CalendarLink = 'calendar_link';
export const ClinicName = 'clinic_name';
export const CaregiverFirstName = 'caregiver_first_name';
export const CaregiverLastName = 'caregiver_last_name';
export const FirstName = 'first_name';
export const LastName = 'last_name';
export const LoginLink = 'login_link';
export const MessageLink = 'message_link';
export const MessageValidPeriod = 'message_valid_period';
export const MessageValidPeriodFr = 'message_valid_period_fr';
export const PhoneNumberEnd = 'phone_number_end';
export const RegistrationLink = 'registration_link';
export const ResetLink = 'reset_link';
