import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Constants, Utils } from '@sigmail/common';
import { UserCredentials } from '..';
import type {
  EmailTokenResponseType,
  ServerParamsEmailToken as DVServer,
  SharedParamsEmailTokenUserRegistration as DVShared
} from '../../types';

const RESPONSE_TYPE_DECLINE_INVITATION: Extract<EmailTokenResponseType, 'declineInvitation'> = 'declineInvitation';
const RESPONSE_TYPE_USER_REGISTRATION: Extract<EmailTokenResponseType, 'userRegistration'> = 'userRegistration';
const SERVER_PARAMS_PROPS: ReadonlyArray<keyof DVServer> = ['verifier'];
const SHARED_PARAMS_PROPS: ReadonlyArray<keyof DVShared> = ['response', 'salt', 'nvac', 'nphi'];
const TYPE = process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN;

/** @public */
export class UserRegistration extends UserCredentials<DVShared, DVServer> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  public static override isValidServerParameters(value: unknown): value is DVServer {
    if (!super.isValidServerParameters(value)) return false;

    const dv = value as DVServer;
    return Utils.every(SERVER_PARAMS_PROPS, Utils.partial(Utils.has, dv)) && Utils.isString(dv.verifier);
  }

  public static override isValidSharedParameters(value: unknown): value is DVShared {
    if (!super.isValidSharedParameters(value)) return false;

    const dv = value as DVShared;
    return (
      Utils.every(SHARED_PARAMS_PROPS, Utils.partial(Utils.has, dv)) &&
      (dv.response === RESPONSE_TYPE_DECLINE_INVITATION || dv.response === RESPONSE_TYPE_USER_REGISTRATION) &&
      (Utils.isUndefined(dv.caregiver) || typeof dv.caregiver === 'boolean') &&
      Utils.isString(dv.salt) &&
      typeof dv.nvac === 'boolean' &&
      typeof dv.nphi === 'boolean' &&
      (Utils.isUndefined(dv.role) || Constants.ROLE_ID_LIST.includes(dv.role))
    );
  }
}
