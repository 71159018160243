import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import {
  AppException,
  Constants,
  IAppUser,
  InstituteInfo,
  MessageSender,
  NotImplementedException,
  Nullable,
  SigmailKeyId,
  SigmailObjectId,
  SigmailUserId,
  Utils,
  ValueObject
} from '@sigmail/common';
import { EncryptWithParametersAlgorithmParams, getAlgorithm } from '@sigmail/crypto';
import {
  ClientObjectUserList,
  ClientObjectUserListValue,
  CryptographicKey,
  CryptographicKeyAudit,
  CryptographicKeyMaster,
  CryptographicKeyPrivate,
  GroupContactListItem,
  ICryptographicKeyPrivate,
  IUserObject,
  UserCredentialsEmailToken,
  UserObjectAccessRights,
  UserObjectContactInfo,
  UserObjectContactInfoValue,
  UserObjectPreferences,
  UserObjectPreferencesValue,
  UserObjectProfileBasic,
  UserObjectProfileBasicValue,
  UserObjectProfilePrivate,
  UserObjectProfilePrivateValue,
  UserObjectProfileProtected,
  UserObjectProfileProtectedValue,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { DEFAULT_CLIENT_RIGHTS_BY_ROLE } from '../../../../constants/default-client-rights';
import * as EmailTemplateParams from '../../../../constants/email-template-params';
import { BatchUpdateRequestBuilder } from '../../../../utils/batch-update-request-builder';
import { EMPTY_ARRAY } from '../../../constants';
import { userListObjectSelector as clientUserListSelector } from '../../../selectors/client-object';
import { basicProfileObjectSelector as basicProfileSelector } from '../../../selectors/user-object';
import { ActionInitParams, FetchObjectsRequestData } from '../../base-action';
import { AUTH_STATE_CREATE_AS_ROLE } from '../../constants/auth-state-identifier';
import { sendMessageAction } from '../../messaging/send-message-action';
import {
  AccountInvitationActionPayload,
  AccountInvitationActionState,
  BaseAccountInvitationAction
} from '../base-account-invitation-action';

export type UserObjectDataUpdater = Api.DataUpdater<IUserObject<any>>;

export interface BaseSendAccountInvitationPayload extends AccountInvitationActionPayload {
  readonly groupClaimList: ReadonlyArray<string>;
  readonly groupList: ReadonlyArray<GroupContactListItem>;
  readonly institute: InstituteInfo;
}

export interface BaseSendAccountInvitationState extends AccountInvitationActionState {
  adminUser: Omit<IAppUser, keyof ValueObject>;
  basicProfileAdmin: UserObjectProfileBasicValue;
  basicProfileInvitee: UserObjectProfileBasicValue;
  batchUpdateAuthState: string;
  batchUpdateClaims: Array<string>;
  credentialId: SigmailObjectId;
  idRecord: Api.GetIdsResponseData['ids'];
  inviteeId: SigmailUserId;
  masterKeyId: SigmailKeyId;
  protectedProfileInvitee: UserObjectProfileProtectedValue;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
  exportedPrivateKey: JsonWebKey;
}

export const HTML_BLANK_LINE = '<p><br></p>';
export const HTML_WELCOME_MSG_BODY_P1: string = [
  '<p>You are now using the next generation secure messaging platform. All',
  'your information is protected when in-transit, at-rest and on the network.',
  'We respect customer privacy and follow guidelines of HIPAA<sup>1</sup>,',
  'PHIPA<sup>2</sup> and Canadian privacy law.</p>'
].join(' ');
export const HTML_WELCOME_MSG_BODY_P2: string = [
  '<p>Feel free to communicate with your healthcare provider and the circle',
  'of care with your healthcare questions.</p>'
].join(' ');
export const HTML_WELCOME_MSG_FOOTER: string = '<p>Thank you<br><br>{{SENDER_NAME}}</p>';
export const HTML_WELCOME_MSG_FOOTNOTES: string = [
  '<p><sup>1</sup> Health Insurance Portability and Accountability Act</p>',
  '<p><sup>2</sup> Personal Health Information Protection Act</p>'
].join('');
export const HTML_WELCOME_MSG_HEADER: string = '<p>Welcome to SigMail!</p>';

export class BaseSendAccountInvitationAction<
  P extends BaseSendAccountInvitationPayload = BaseSendAccountInvitationPayload,
  S extends BaseSendAccountInvitationState = BaseSendAccountInvitationState
> extends BaseAccountInvitationAction<P, S, string> {
  private readonly asymmetricKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);

  public constructor(params: ActionInitParams<P>) {
    super(params);

    this.state.adminUser = this.state.currentUser;
  }

  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();

    this.generateSharedParameters();
    this.generateEmailToken();
    this.generateAccessCode();
    await this.generateServerParameters();

    return result;
  }

  /** @override */
  protected async onExecute() {
    await this.fetchClientAdminData();
    await this.generateIdSequence();

    try {
      await this.generateRequestBody();

      const { batchUpdateAuthState: authState, batchUpdateClaims: claims, requestBody, successPayload } = this.state;
      await this.dispatchBatchUpdateData({
        authState,
        claims,
        ...requestBody.build()
      });

      try {
        await this.dispatchBatchQueryDataSuccess(successPayload);
      } catch (error) {
        this.logger.warn('Error manually updating app state:', error);
        /* ignore */
      }

      const welcomeMessage = await this.generateSendWelcomeMessagePayload();
      if (Utils.isNotNil(welcomeMessage)) {
        await this.dispatch(sendMessageAction(welcomeMessage));
      }

      try {
        const { institute, role } = this.payload;
        const { cellNumber, emailAddress, firstName, lastName } = this.state.basicProfileInvitee;

        await this.sendInvitationEmail(role, emailAddress, {
          [EmailTemplateParams.ClinicName]: institute.name,
          [EmailTemplateParams.FirstName]: firstName,
          [EmailTemplateParams.LastName]: lastName
        });

        await this.sendInvitationSMS(role, cellNumber);
      } catch (error) {
        this.logger.warn('Error sending invitation:', error);
        /* ignore */
      }
    } finally {
      const { masterKeyId } = this.state;
      if (CryptographicKeyMaster.isValidId(masterKeyId)) {
        CryptographicKey.clearPrivateKey(masterKeyId);
        CryptographicKey.clearPublicKey(masterKeyId);
      }
    }

    return this.state.accessCode;
  }

  protected async fetchClientAdminData(): Promise<void> {
    this.logger.info('Fetching latest client admin data.');

    const { clientId, currentUser, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId: currentUser.id },
        { type: process.env.CLIENT_OBJECT_TYPE_USER_LIST, userId: clientId }
      ]
    };

    const { serverDateTime } = await this.dispatchFetchObjects(query);

    const basicProfile = await this.getUserObjectValue(basicProfileSelector);
    if (Utils.isNil(basicProfile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, "Inviter's basic profile data is either missing or invalid.");
    }

    this.state.basicProfileAdmin = basicProfile;
    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
  }

  protected async createIdsRequestData(): Promise<Api.GetIdsRequestData> {
    const { groupClaimList, groupList, role } = this.payload;
    const { roleAuthClaim: authState } = this.state;

    const state = AUTH_STATE_CREATE_AS_ROLE[role];
    if (!Utils.isString(state)) {
      throw new AppException(Constants.Error.S_ERROR, '<createAs> state could not be determined from role.');
    }

    const { queryIds, updateIds, expireIds } = Utils.decodeIdToken(authState) as { [prop: string]: Array<number> };
    if (!Utils.isArray(queryIds) || !Utils.isArray(updateIds) || !Utils.isArray(expireIds)) {
      throw new AppException(Constants.Error.S_ERROR, 'Unexpected data format.');
    }

    return {
      authState,
      claims: [...groupClaimList],
      state,
      ids: {
        usages: [{ usage: 'userId' }],
        ids: [
          { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER },

          { type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN },

          { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC },
          { type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE },
          { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED },
          { type: process.env.USER_OBJECT_TYPE_CONTACT_INFO },
          { type: process.env.USER_OBJECT_TYPE_PREFERENCES },
          { type: process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS },
          { type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS }
        ]
      },
      groupIdList: groupList.map(({ id }) => id),
      queryIds,
      updateIds,
      expireIds
    };
  }

  protected async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const query = await this.createIdsRequestData();
    const { authState: batchUpdateAuthState, claims: batchUpdateClaims, ids: idRecord } = await this.dispatchFetchIdsByUsage(query);

    this.state.batchUpdateAuthState = batchUpdateAuthState;
    this.state.batchUpdateClaims = batchUpdateClaims.slice();
    this.state.idRecord = idRecord;
    [this.state.masterKeyId] = idRecord[process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER];
    [this.state.credentialId] = idRecord[process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN];
    [this.state.inviteeId] = idRecord['userId'];

    this.logger.debug({
      masterKeyId: this.state.masterKeyId,
      credentialId: this.state.credentialId,
      inviteeId: this.state.inviteeId
    });
  }

  protected async generateRequestBody(): Promise<void> {
    this.initializeRequestBodyAndSuccessPayload();

    await this.addInsertOperationForTemporaryUserKey(); // 112
    await this.addInsertOperationForClientPrivateKey(); // 111 (Client)

    await this.addInsertOperationForUserProfileBasic(); // 401
    await this.addInsertOperationForUserProfileProtected(); // 402
    await this.addInsertOperationForUserProfilePrivate(); // 403
    await this.addInsertOperationForUserContactInfo(); // 405
    await this.addInsertOperationForUserPreferences(); // 408
    await this.addInsertOperationForUserAccessRights(); // 410
    await this.addInsertOperationForUserServerRights(); // 411

    await this.addUpdateOperationForClientUserList(); // 467

    await this.addInsertOperationForEmailTokenAndAuditKey(); // 871
  }

  protected initializeRequestBodyAndSuccessPayload(): void {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };
  }

  protected async addInsertOperationForTemporaryUserKey(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for a temporary user key.');

    const { token, accessCode, sharedParameters, masterKeyId: keyId, credentialId, dtServer, requestBody } = this.state;

    const { privateKey, exportedPrivateKey, publicKey, exportedPublicKey } = await this.asymmetricKeyAlgo.generateKey();
    this.logger.debug('User private JWK', JSON.stringify(exportedPrivateKey));
    this.logger.debug('User public JWK', JSON.stringify(exportedPublicKey));
    CryptographicKey.setPrivateKey(keyId, privateKey!);
    CryptographicKey.setPublicKey(keyId, publicKey!);

    const params: EncryptWithParametersAlgorithmParams = {
      type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN,
      parameter1: token,
      parameter2: accessCode,
      hexSalt: sharedParameters.salt
    };

    const masterKey = await CryptographicKeyMaster.create(keyId, undefined, 0, exportedPrivateKey!, credentialId, params, dtServer);
    requestBody.insert(masterKey);

    this.state.exportedPrivateKey = exportedPrivateKey!;
  }

  protected async addInsertOperationForClientPrivateKey(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for client private key.');

    const { roleAuthClaim: authState, clientId, masterKeyId, requestBody } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [{ id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE }]
    };

    const { keyList } = await this.dispatchFetchObjects(query);

    const clientPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: clientId });
    if (Utils.isNil(clientPrivateKeyJson)) {
      throw new Api.MalformedResponseException('Client private key is either missing or invalid.');
    } else {
      let clientPrivateKey: ICryptographicKeyPrivate = new CryptographicKeyPrivate(clientPrivateKeyJson);
      clientPrivateKey = await CryptographicKeyPrivate.encryptFor(clientPrivateKey, masterKeyId);
      requestBody.insert(clientPrivateKey);
    }
  }

  protected createBasicProfileValue(): UserObjectProfileBasicValue {
    throw new NotImplementedException();
  }

  protected async addInsertOperationForUserProfileBasic(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for basic profile.');

    const { auditId, clientId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_PROFILE_BASIC];
    const value = this.createBasicProfileValue();
    this.logger.debug({ id, ...value });

    const basicProfileObject = await UserObjectProfileBasic.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await basicProfileObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(basicProfileObject[Constants.$$CryptographicKey]);
    requestBody.insert(basicProfileObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.basicProfileInvitee = value;
  }

  protected createProtectedProfileValue(): UserObjectProfileProtectedValue {
    throw new NotImplementedException();
  }

  protected async addInsertOperationForUserProfileProtected(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for protected profile.');

    const { auditId, clientId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED];
    const value = this.createProtectedProfileValue();
    this.logger.debug({ id, ...value });

    const protectedProfileObject = await UserObjectProfileProtected.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await protectedProfileObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(protectedProfileObject[Constants.$$CryptographicKey]);
    requestBody.insert(protectedProfileObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.protectedProfileInvitee = value;
  }

  protected createPrivateProfileValue(): UserObjectProfilePrivateValue {
    const { adminUser, auditId, clientId, globalContactListId, ownerId } = this.state;

    return { $$formatver: 1, adminId: adminUser.id, auditId, clientId, globalContactListId, ownerId };
  }

  protected async addInsertOperationForUserProfilePrivate(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for private profile.');

    const { auditId, clientId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE];
    const value = this.createPrivateProfileValue();
    this.logger.debug({ id, ...value });

    const privateProfileObject = await UserObjectProfilePrivate.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await privateProfileObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(privateProfileObject[Constants.$$CryptographicKey]);
    requestBody.insert(privateProfileObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  protected createContactInfoValue(): UserObjectContactInfoValue {
    throw new NotImplementedException();
  }

  protected async addInsertOperationForUserContactInfo(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for contact info.');

    const { auditId, clientId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_CONTACT_INFO];
    const value = this.createContactInfoValue();

    this.logger.debug({ id, ...value });

    const contactInfoObject = await UserObjectContactInfo.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await contactInfoObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(contactInfoObject[Constants.$$CryptographicKey]);
    requestBody.insert(contactInfoObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  protected async addInsertOperationForUserPreferences(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for user preferences.');

    const { auditId, clientId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_PREFERENCES];
    const value: UserObjectPreferencesValue = { $$formatver: 1, noNotifyOnNewMessage: ['webPush'] };
    this.logger.debug({ id, ...value });

    const preferencesObject = await UserObjectPreferences.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await preferencesObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(preferencesObject[Constants.$$CryptographicKey]);
    requestBody.insert(preferencesObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  protected async addInsertOperationForUserAccessRights(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for user access rights.');

    const { auditId, clientId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS];
    const value = DEFAULT_CLIENT_RIGHTS_BY_ROLE[this.payload.role];
    if (!Utils.isNonArrayObjectLike<typeof value>(value)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Unable to determine initial access rights to assign.');
    }
    this.logger.debug({ id, ...value });

    const accessRightsObject = await UserObjectAccessRights.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await accessRightsObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(accessRightsObject[Constants.$$CryptographicKey]);
    requestBody.insert(accessRightsObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  protected async addInsertOperationForUserServerRights(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for user server rights.');

    const { auditId, batchUpdateClaims, clientId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const userClaim = this.findClaim(batchUpdateClaims, { name: 'user', userId: this.state.inviteeId });
    if (!Utils.isValidJwtToken(userClaim, 'id')) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'User claim is either missing or invalid.');
    }

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_SERVER_RIGHTS];
    const value: UserObjectServerRightsValue = { $$formatver: 1, userClaim };
    this.logger.debug({ id, ...value });

    const serverRightsObject = await UserObjectServerRights.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await serverRightsObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(serverRightsObject[Constants.$$CryptographicKey]);
    requestBody.insert(serverRightsObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  protected async addUpdateOperationForClientUserList(): Promise<void> {
    const { role: inviteeRoleId, notifyBy } = this.payload;
    const { adminUser, clientId, credentialId, dtServer, inviteeId, isAccessCodeRandom, requestBody, successPayload } = this.state;

    const accessCode = Utils.stringOrDefault(isAccessCodeRandom && this.state.accessCode, undefined!);

    const applyUpdate = async (userListObject: IUserObject<ClientObjectUserListValue>) => {
      const userList = await userListObject.decryptedValue();

      const updatedValue: ClientObjectUserListValue = {
        ...userList,
        pending: userList.pending.concat({
          accessCode,
          credentialId,
          credentialExpiry: this.credentialExpiry.getTime(),
          id: inviteeId,
          invitedBy: adminUser.id,
          role: inviteeRoleId,
          timestamp: { start: dtServer.getTime(), end: null },
          notifyBy
        })
      };
      this.logger.debug(updatedValue);

      return userListObject.updateValue(updatedValue);
    };

    const userListObject = await this.getUserObject(clientUserListSelector, { userId: clientId });
    if (Utils.isNil(userListObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Client user list is either missing or invalid.');
    }

    const userListObjectKey = userListObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (userListJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === userListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; client user list could not be found in request body.');
      }

      const key = userListObjectKey === null ? null : userListObjectKey.toApiFormatted();
      const userListObject = new ClientObjectUserList({ ...userListJson, key });
      const updatedObject = await applyUpdate(userListObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === userListJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(userListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(userListObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  protected async addInsertOperationForEmailTokenAndAuditKey(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for email token credentials and its audit key.');

    const {
      accessCode,
      auditId,
      credentialHash,
      credentialId,
      dtServer,
      inviteeId,
      masterKeyId,
      requestBody,
      serverParameters,
      sharedParameters,
      token
    } = this.state;

    const emailTokenCredentials = await UserCredentialsEmailToken.UserRegistration.create(
      credentialId,
      undefined,
      inviteeId,
      masterKeyId,
      credentialHash,
      sharedParameters,
      serverParameters,
      0, // encryptedFor
      dtServer,
      this.credentialExpiry
    );
    requestBody.insert(emailTokenCredentials);

    const params: EncryptWithParametersAlgorithmParams = {
      type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN,
      parameter1: token,
      parameter2: accessCode,
      hexSalt: sharedParameters.salt
    };

    const auditKey = await CryptographicKeyAudit.createForCredential(credentialId, params, auditId, dtServer);
    requestBody.insert(auditKey);
  }

  protected getWelcomeMessageSender(): MessageSender {
    const { adminUser, basicProfileAdmin } = this.state;

    return {
      ...adminUser,
      firstName: basicProfileAdmin.firstName,
      lastName: basicProfileAdmin.lastName
    };
  }

  protected getWelcomeMessageHtml(htmlFor: 'body' | 'footer' | 'footnotes' | 'greeting' | 'header'): string | false {
    switch (htmlFor) {
      case 'body':
        return `${HTML_WELCOME_MSG_BODY_P1}${HTML_BLANK_LINE}${HTML_WELCOME_MSG_BODY_P2}`;
      case 'footnotes':
        return HTML_WELCOME_MSG_FOOTNOTES;
      case 'footer': {
        const { prefix, firstName, lastName } = this.state.basicProfileAdmin;
        return HTML_WELCOME_MSG_FOOTER.replace('{{SENDER_NAME}}', Utils.joinPersonName({ prefix, firstName, lastName }));
      }
      case 'greeting': {
        const { firstName, lastName } = this.state.basicProfileInvitee;
        return ['<p>Hi', firstName, lastName].filter(Utils.isString).join(' ').concat(',</p>');
      }
      case 'header':
        return HTML_WELCOME_MSG_HEADER;
      default:
        break;
    }
    return '';
  }

  protected async generateSendWelcomeMessagePayload(): Promise<Nullable<MessagingActionPayload.SendMessage>> {
    const { role: inviteeRoleId } = this.payload;
    const { basicProfileInvitee, inviteeId, masterKeyId } = this.state;

    return {
      sender: this.getWelcomeMessageSender(),
      subjectLine: 'Welcome',
      primaryRecipientList: [
        {
          id: inviteeId,
          keyId: masterKeyId,
          type: 'user',
          userData: {
            // @ts-expect-error TS2322: Property does not exist in type
            accountStatus: 'pending',
            role: inviteeRoleId,
            firstName: basicProfileInvitee.firstName,
            lastName: basicProfileInvitee.lastName,
            emailAddress: ''
          }
        }
      ],
      secondaryRecipientList: EMPTY_ARRAY,
      messageBody: {
        data: [
          this.getWelcomeMessageHtml('greeting'),
          HTML_BLANK_LINE,
          this.getWelcomeMessageHtml('header'),
          HTML_BLANK_LINE,
          this.getWelcomeMessageHtml('body'),
          HTML_BLANK_LINE,
          HTML_BLANK_LINE,
          this.getWelcomeMessageHtml('footer'),
          HTML_BLANK_LINE,
          HTML_BLANK_LINE,
          this.getWelcomeMessageHtml('footnotes')
        ]
          .filter(Utils.isString)
          .join('\n')
      }
    };
  }
}
