import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['securityQuestion'] = {
  e7ae957c1afc4b44bd3d3973187a60b9: {
    // Translators: Security Question #1: What is your mother's maiden name?
    label: gettext('global', 'maidenNameOfMom', 'securityQuestion')
  },
  fda0f7ed329d491db8a8c398224a5b3e: {
    // Translators: Security Question #2: In what city was your father born?
    label: gettext('global', 'cityFatherWasBorn', 'securityQuestion')
  },
  ebecc9ffb3dd4eb4b64e7a17edb2cc3f: {
    // Translators: Security Question #3: What is the name of your first pet?
    label: gettext('global', 'nameOfFirstPet', 'securityQuestion')
  },
  e40622e2bd72411faca8e976fa1d289d: {
    // Translators: Security Question #4: What was your childhood nickname?
    label: gettext('global', 'childhoodNickname', 'securityQuestion')
  },
  c2459413471b4829bd57ca3603eaf326: {
    // Translators: Security Question #5: In what city did you meet your
    // spouse/significant other?
    label: gettext('global', 'citySpouseWasMet', 'securityQuestion')
  },
  e5d04b45df0e4475a22e56cece8e311e: {
    // Translators: Security Question #6: What is the name of your favorite
    // childhood friend?
    label: gettext('global', 'nameOfFavoriteChildhoodFriend', 'securityQuestion')
  },
  a1226790633a4b8db0281c028ade2d3b: {
    // Translators: Security Question #7: What school did you attend for sixth
    // grade?
    label: gettext('global', 'nameOfSixthGradeSchool', 'securityQuestion')
  },
  d73d2eb354474521809497133476d663: {
    // Translators: Security Question #8: What is your oldest cousin's first
    // and last name?
    label: gettext('global', 'nameOfOldestCousin', 'securityQuestion')
  },
  b80d79244ddb4d14b68ea28a57dee492: {
    // Translators: Security Question #9: In what city does your nearest sibling
    // live?
    label: gettext('global', 'cityNearestSiblingLives', 'securityQuestion')
  },
  f1706d0454424d4da5cacb648b0d5e84: {
    // Translators: Security Question #10: What is your maternal grandmother's
    // maiden name?
    label: gettext('global', 'nameOfMaternalGrandma', 'securityQuestion')
  },
  e531627d9d1e4663b17dcc6816ce6146: {
    // Translators: Security Question #11: In what city or town was your first
    // job?
    label: gettext('global', 'cityOfFirstJob', 'securityQuestion')
  },
  ccc8336c9479487ba5490ccbf339daa1: {
    // Translators: Security Question #12: What was the name of your
    // elementary/primary school?
    label: gettext('global', 'nameOfPrimarySchool', 'securityQuestion')
  },
  c594741469ed471ea31ca51364840045: {
    // Translators: Security Question #13: What is the name of the company of
    // your first job?
    label: gettext('global', 'nameOfFirstJobCompany', 'securityQuestion')
  },
  a9d46e2cfe6e4c1389d19b620dadb538: {
    // Translators: Security Question #14: What was your favorite place to visit
    // as a child?
    label: gettext('global', 'favoritePlaceAsChild', 'securityQuestion')
  },
  c1131c6576a847718bdb52a261c0c81d: {
    // Translators: Security Question #15: What is your spouse's mother's maiden
    // name?
    label: gettext('global', 'maidenNameOfInLawMom', 'securityQuestion')
  },
  f895799718e140368ec1aae938c934c3: {
    // Translators: Security Question #16: What is the country of your ultimate
    // dream vacation?
    label: gettext('global', 'nameOfDreamVacationCountry', 'securityQuestion')
  },
  de9eb1394a4c4e46860d1f47313d89d2: {
    // Translators: Security Question #17: What is the name of your favorite
    // childhood teacher?
    label: gettext('global', 'nameOfFavoriteChildhoodTeacher', 'securityQuestion')
  },
  d671071339024ca9828d90962a31a573: {
    // Translators: Security Question #18: To what city did you go on your
    // honeymoon?
    label: gettext('global', 'cityOfHoneymoon', 'securityQuestion')
  },
  b70375dbaf8c44a7ab995e178a329cf1: {
    // Translators: Security Question #19: What was your dream job as a child?
    label: gettext('global', 'dreamJobAsChild', 'securityQuestion')
  },
  f3180338bcf84637b1050ea8a7ad46f5: {
    // Translators: Security Question #20: What is the street number of the
    // house you grew up in?
    label: gettext('global', 'streetNumberOfHouseGrewUpIn', 'securityQuestion')
  },
  c8c9b2c7689b4cfda1d156166235ffaf: {
    // Translators: Security Question #21: Who was your childhood hero?
    label: gettext('global', 'nameOfChildhoodHero', 'securityQuestion')
  },
  e5abe2955aeb43d78168a1b7e2d8654e: {
    // Translators: Security Question #22: What was the first concert you
    // attended?
    label: gettext('global', 'firstConcert', 'securityQuestion')
  },
  d39346891a74476ab95fea8edcd3f211: {
    // Translators: Security Question #23: What month and day is your
    // anniversary?
    label: gettext('global', 'monthAndDayOfAnniversary', 'securityQuestion')
  },
  c04eae43c72b40d9b1fb583b122ae011: {
    // Translators: Security Question #24: What is your grandmother's first
    // name?
    label: gettext('global', 'firstNameOfGrandma', 'securityQuestion')
  },
  d535c3678a364d95b05743937c7c037c: {
    // Translators: Security Question #25: What is your mother's middle name?
    label: gettext('global', 'middleNameOfMom', 'securityQuestion')
  },
  f9453c59fb714e5e9534b8ba704056a8: {
    // Translators: Security Question #26: What is the last name of your
    // favorite high school teacher?
    label: gettext('global', 'lastNameOfFavoriteHighSchoolTeacher', 'securityQuestion')
  },
  bd4d6d8857664783986127b290344a2a: {
    // Translators: Security Question #27: What was the make and model of your
    // first car?
    label: gettext('global', 'makeAndModelOfFirstCar', 'securityQuestion')
  },
  d075cc384a4d48feadc3e03e2cb84cf2: {
    // Translators: Security Question #28: What is your preferred musical genre?
    label: gettext('global', 'preferredMusicalGenre', 'securityQuestion')
  },
  d32f409f2d2d43939b14d92c45b55b62: {
    // Translators: Security Question #29: In what city and country do you want
    // to retire?
    label: gettext('global', 'desiredPlaceToRetire', 'securityQuestion')
  },
  ef281faf0ee4455d91928852e7136c83: {
    // Translators: Security Question #30: What is the name of the first
    // undergraduate college you attended?
    label: gettext('global', 'nameOfFirstUndergraduateCollege', 'securityQuestion')
  },
  c729d14235694f2d8a96f16dd1661b71: {
    // Translators: Security Question #31: What was your high school mascot?
    label: gettext('global', 'highSchoolMascot', 'securityQuestion')
  },
  e8581e4baca6f40eaa40e7d246fe423d7: {
    // Translators: Security Question #32: What year did you graduate from high
    // school?
    label: gettext('global', 'yearOfHighSchoolGraduation', 'securityQuestion')
  },
  d8d215f4732f4091bc24c376948a4fad: {
    // Translators: Security Question #33: What is the name of the first school
    // you attended?
    label: gettext('global', 'nameOfFirstSchool', 'securityQuestion')
  }
};

export default i18n;
