import { Utils } from '@sigmail/common';
import { DataObjectMsgMetadataValue, DataObjectMsgReadReceiptValue } from '@sigmail/objects';
import React from 'react';
import CheckmarkIcon from '../../../../assets/images/icon-check-success.min.svg';
import { processImgProps } from '../../../../utils/process-img-props';

export interface UseRecipientListItemRendererParams {
  locale: string;
  readReceiptData?: DataObjectMsgReadReceiptValue['data'] | undefined;
  variant?: 'primary' | 'secondary' | undefined;
}

export const useRecipientListItemRenderer = ({
  variant,
  readReceiptData,
  locale
}: UseRecipientListItemRendererParams) => {
  const isVariantPrimary = variant !== 'secondary';

  return React.useCallback(
    ({ entity: recipient }: DataObjectMsgMetadataValue['recipientList'][0]) => {
      if (
        (isVariantPrimary && recipient.entityType !== 'primary') ||
        (!isVariantPrimary && recipient.entityType !== 'secondary') ||
        recipient.isConcealed === true
      ) {
        return null;
      }

      const readAtUtc = readReceiptData?.find(({ recipientId }) => recipientId === recipient.id)?.readAtUtc;

      return (
        <li
          key={recipient.id}
          title={Utils.isNil(readAtUtc) ? undefined : Utils.DATE_FORMAT_FULL(locale).format(readAtUtc)}
        >
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          {!Utils.isNil(readAtUtc) && <img {...processImgProps({ src: CheckmarkIcon })} />}

          <span>{recipient.type === 'group' ? recipient.groupName : Utils.joinPersonName(recipient)}</span>
        </li>
      );
    },
    [isVariantPrimary, readReceiptData, locale]
  );
};
