import { Constants } from '@sigmail/common';
import { UserObjectAccessRightsValue as DV } from '@sigmail/objects';

const EIGHT_MB = 8_388_608;
const TEN_MB = 10_485_760;
const TWENTY_FIVE_MB = 26_214_400;

export const DEFAULT_CLIENT_RIGHTS_BY_ROLE: Readonly<Record<string, DV>> = {
  [Constants.ROLE_ID_ADMIN_MESSAGING_USER]: {
    $$formatver: 1,
    clientRights: {
      accessClientContacts: true,
      accessClientUserList: true,
      accessGlobalContacts: true,
      accessGroupMailbox: true,
      accessMailbox: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      activateMember: [Constants.ROLE_ID_ADMIN_MESSAGING_USER, Constants.ROLE_ID_MESSAGING_USER],
      archiveMessage: true,
      assignGroupMessageCategory: true,
      assignMessageCategory: true,
      attachDocumentsToMessage: 8,
      composeMessage: true,
      createGroupMessageCategory: true,
      createMessageCategory: true,
      deactivateMember: [Constants.ROLE_ID_ADMIN_MESSAGING_USER, Constants.ROLE_ID_MESSAGING_USER],
      deleteGroupMessageCategory: true,
      deleteMessageCategory: true,
      editGroupInboxNotification: true,
      editGroupMessageCategory: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      inviteMember: [Constants.ROLE_ID_ADMIN_MESSAGING_USER, Constants.ROLE_ID_MESSAGING_USER],
      markMessageImportant: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      replyToMessage: true,
      replyToSentMessage: true,
      saveMessageDraft: true,
      sendMessage: true,
      setMessageReminder: true,
      updateClientContacts: true,
      updateGlobalContacts: true
    }
  },
  [Constants.ROLE_ID_ADMIN_PHYSICIAN]: {
    $$formatver: 1,
    clientRights: {
      // accessBilling: true,
      accessCircleOfCare: true,
      accessClientCollectionList: true,
      accessClientContacts: true,
      accessClientUserList: true,
      accessGlobalContacts: true,
      accessGroupMailbox: true,
      accessGroupManagement: true,
      accessGroupSchedule: true,
      accessMailbox: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessOwnSchedule: true,
      activateMember: [
        Constants.ROLE_ID_ADMIN_PHYSICIAN,
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_GUEST,
        Constants.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.ROLE_ID_STAFF
      ],
      archiveMessage: true,
      assignGroupMessageCategory: true,
      assignMessageCategory: true,
      assignUsersToGroup: true,
      attachDocumentsToMessage: 8,
      canUpdatePatientProfile: true,
      // canStartEncounter: true,
      // canStopEncounter: true,
      composeEConsult: true,
      composeHrmMessage: true,
      composeMessage: true,
      composeReferral: true,
      createCalendarEvent: true,
      createCarePlan: true,
      createGroupMessageCategory: true,
      createGuestContactGroup: true,
      createMessageCategory: true,
      deactivateMember: [
        Constants.ROLE_ID_ADMIN_PHYSICIAN,
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_GUEST,
        Constants.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.ROLE_ID_STAFF
      ],
      deleteCarePlan: true,
      deleteGroupMessageCategory: true,
      deleteMessageCategory: true,
      editCarePlan: true,
      editGroupInboxNotification: true,
      editGroupMessageCategory: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      inviteMember: [
        Constants.ROLE_ID_ADMIN_PHYSICIAN,
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_GUEST,
        Constants.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.ROLE_ID_STAFF
      ],
      inviteUsersToGroup: true,
      markCalendarEventBillable: true,
      markCalendarEventImportant: true,
      markCalendarEventVideo: true,
      markMessageBillable: true,
      markMessageConfidential: true,
      markMessageDoNotReply: true,
      markMessageImportant: true,
      markMessagePersonal: true,
      markMessagePrivate: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      removeInvitation: true,
      removeUsersFromGroup: true,
      replyToMessage: true,
      replyToSentMessage: true,
      requestHealthData: true,
      resendInvitation: true,
      revokeInvitation: true,
      saveMessageDraft: true,
      sendHrmMessage: true,
      sendMessage: true,
      sendMessageToEMR: true,
      sendOneTimeMessage: 1,
      setCalendarEventReminder: true,
      setMessageReminder: true,
      transferUsersToGroup: true,
      updateClientContacts: true,
      updateGlobalContacts: true,
      updateGroupPrefs: true,
      updateWorkSchedule: true,
      viewCarePlans: true,
      viewConsultationActivity: true,
      viewHealthData: true,
      viewIntegrations: true
    }
  },
  [Constants.ROLE_ID_ADMIN_STAFF]: {
    $$formatver: 1,
    clientRights: {
      accessCircleOfCare: true,
      accessClientCollectionList: true,
      accessClientContacts: true,
      accessClientUserList: true,
      accessGlobalContacts: true,
      accessGroupMailbox: true,
      accessGroupManagement: true,
      accessGroupSchedule: true,
      accessMailbox: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessOwnSchedule: true,
      activateMember: [
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_GUEST,
        Constants.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.ROLE_ID_STAFF
      ],
      archiveMessage: true,
      assignGroupMessageCategory: true,
      assignMessage: true,
      assignMessageCategory: true,
      assignUsersToGroup: true,
      attachDocumentsToMessage: 8,
      // canStartEncounter: true,
      // canStopEncounter: true,
      canUpdatePatientProfile: true,
      composeHrmMessage: true,
      composeMessage: true,
      composeReferral: true,
      createCalendarEvent: true,
      createCarePlan: true,
      createGroupMessageCategory: true,
      createGuestContactGroup: true,
      createMessageCategory: true,
      deactivateMember: [
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_GUEST,
        Constants.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.ROLE_ID_STAFF
      ],
      deleteCarePlan: true,
      deleteGroupMessageCategory: true,
      deleteMessageCategory: true,
      editCarePlan: true,
      editGroupInboxNotification: true,
      editGroupMessageCategory: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      inviteMember: [
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_GUEST,
        Constants.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.ROLE_ID_STAFF
      ],
      inviteUsersToGroup: true,
      markCalendarEventImportant: true,
      markCalendarEventVideo: true,
      markMessageDoNotReply: true,
      markMessageImportant: true,
      markMessagePrivate: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      removeInvitation: true,
      removeUsersFromGroup: true,
      replyToMessage: true,
      replyToSentMessage: true,
      requestHealthData: true,
      resendInvitation: true,
      revokeInvitation: true,
      saveMessageDraft: true,
      sendHrmMessage: true,
      sendMessage: true,
      sendMessageToEMR: true,
      sendOneTimeMessage: 1,
      setCalendarEventReminder: true,
      setMessageReminder: true,
      transferUsersToGroup: true,
      updateClientContacts: true,
      updateGlobalContacts: true,
      updateGroupPrefs: true,
      updateWorkSchedule: true,
      viewCarePlans: true,
      viewHealthData: true,
      viewIntegrations: true
    }
  },
  [Constants.ROLE_ID_BASIC_PHYSICIAN]: {
    $$formatver: 1,
    clientRights: {
      accessCircleOfCare: true,
      accessClientContacts: true,
      accessClientUserList: true,
      accessGlobalContacts: true,
      accessGroupMailbox: true,
      accessMailbox: true,
      accessOwnAccount: true,
      archiveMessage: true,
      assignMessageCategory: true,
      attachDocumentsToMessage: 8,
      canUpdatePatientProfile: true,
      composeMessage: true,
      composeReferral: true,
      createCarePlan: true,
      createMessageCategory: true,
      deleteCarePlan: true,
      deleteMessageCategory: true,
      editCarePlan: true,
      editGroupInboxNotification: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      inviteMember: [Constants.ROLE_ID_GUEST],
      markMessageBillable: true,
      markMessageDoNotReply: true,
      markMessageImportant: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      removeInvitation: true,
      replyToMessage: true,
      replyToSentMessage: true,
      resendInvitation: true,
      revokeInvitation: true,
      saveMessageDraft: true,
      sendMessage: true,
      sendOneTimeMessage: 1,
      setMessageReminder: true,
      updateClientContacts: true,
      updateGlobalContacts: true,
      viewCarePlans: true
    }
  },
  [Constants.ROLE_ID_CAREGIVER]: {
    $$formatver: 1,
    clientRights: {
      accessOwnAccount: true
    }
  },
  [Constants.ROLE_ID_GUEST]: {
    $$formatver: 1,
    clientRights: {
      accessMailbox: true,
      accessOwnAccount: true,
      accessOwnSchedule: true,
      activateMember: [Constants.ROLE_ID_CAREGIVER],
      addOwnCaregiver: 2,
      archiveMessage: true,
      assignMessageCategory: true,
      attachDocumentsToMessage: 2,
      composeMessage: true,
      createCarePlan: true,
      createMessageCategory: true,
      deactivateMember: [Constants.ROLE_ID_CAREGIVER],
      deleteCarePlan: true,
      deleteMessageCategory: true,
      editCarePlan: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      markMessageImportant: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TEN_MB,
      recallMessageWindow: 7,
      removeInvitation: 'invitedByMe',
      removeOwnCaregiver: true,
      replyMessageWindow: 30,
      resendInvitation: 'invitedByMe',
      revokeInvitation: 'invitedByMe',
      replyToMessage: true,
      sendMessage: true,
      viewCarePlans: true
    }
  },
  [Constants.ROLE_ID_JUNIOR_PHYSICIAN]: {
    $$formatver: 1,
    clientRights: {
      // accessBilling: true,
      accessCircleOfCare: true,
      accessClientCollectionList: true,
      accessClientContacts: true,
      accessClientUserList: true,
      accessGlobalContacts: true,
      accessGroupMailbox: true,
      accessGroupSchedule: true,
      accessMailbox: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessOwnSchedule: true,
      archiveMessage: true,
      assignGroupMessageCategory: true,
      assignMessageCategory: true,
      attachDocumentsToMessage: 4,
      // canStartEncounter: true,
      // canStopEncounter: true,
      canUpdatePatientProfile: true,
      composeEConsult: true,
      composeHrmMessage: true,
      composeMessage: true,
      composeReferral: true,
      createCalendarEvent: true,
      createCarePlan: true,
      createGuestContactGroup: true,
      createMessageCategory: true,
      deleteCarePlan: true,
      deleteMessageCategory: true,
      editCarePlan: true,
      editGroupInboxNotification: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      inviteMember: [Constants.ROLE_ID_JUNIOR_PHYSICIAN, Constants.ROLE_ID_STAFF, Constants.ROLE_ID_GUEST],
      markCalendarEventBillable: true,
      markCalendarEventImportant: true,
      markCalendarEventVideo: true,
      markMessageBillable: true,
      markMessageDoNotReply: true,
      markMessageImportant: true,
      markMessagePrivate: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      removeInvitation: true,
      replyToMessage: true,
      replyToSentMessage: true,
      requestHealthData: true,
      resendInvitation: true,
      revokeInvitation: true,
      saveMessageDraft: true,
      sendHrmMessage: true,
      sendMessage: true,
      sendMessageToEMR: true,
      sendOneTimeMessage: 1,
      setCalendarEventReminder: true,
      setMessageReminder: true,
      updateClientContacts: true,
      updateGlobalContacts: true,
      updateWorkSchedule: true,
      viewCarePlans: true,
      viewConsultationActivity: true,
      viewHealthData: true,
      viewIntegrations: true
    }
  },
  [Constants.ROLE_ID_MESSAGING_USER]: {
    $$formatver: 1,
    clientRights: {
      accessClientContacts: true,
      accessClientUserList: true,
      accessGlobalContacts: true,
      accessGroupMailbox: true,
      accessMailbox: true,
      accessOwnAccount: true,
      archiveMessage: true,
      assignGroupMessageCategory: true,
      assignMessageCategory: true,
      attachDocumentsToMessage: 8,
      composeMessage: true,
      createMessageCategory: true,
      deleteMessageCategory: true,
      editGroupInboxNotification: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      markMessageImportant: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      replyToMessage: true,
      replyToSentMessage: true,
      saveMessageDraft: true,
      sendMessage: true,
      setMessageReminder: true,
      updateClientContacts: true,
      updateGlobalContacts: true
    }
  },
  [Constants.ROLE_ID_SENIOR_PHYSICIAN]: {
    $$formatver: 1,
    clientRights: {
      // accessBilling: true,
      accessCircleOfCare: true,
      accessClientCollectionList: true,
      accessClientContacts: true,
      accessClientUserList: true,
      accessGlobalContacts: true,
      accessGroupMailbox: true,
      accessGroupSchedule: true,
      accessMailbox: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessOwnSchedule: true,
      archiveMessage: true,
      assignGroupMessageCategory: true,
      assignMessageCategory: true,
      attachDocumentsToMessage: 8,
      // canStartEncounter: true,
      // canStopEncounter: true,
      canUpdatePatientProfile: true,
      composeEConsult: true,
      composeHrmMessage: true,
      composeMessage: true,
      composeReferral: true,
      createCalendarEvent: true,
      createCarePlan: true,
      createGuestContactGroup: true,
      createMessageCategory: true,
      deleteCarePlan: true,
      editCarePlan: true,
      editGroupInboxNotification: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      inviteMember: [
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_GUEST,
        Constants.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.ROLE_ID_STAFF
      ],
      markCalendarEventBillable: true,
      markCalendarEventImportant: true,
      markCalendarEventVideo: true,
      markMessageBillable: true,
      markMessageDoNotReply: true,
      markMessageImportant: true,
      markMessagePrivate: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      removeInvitation: true,
      replyToMessage: true,
      replyToSentMessage: true,
      requestHealthData: true,
      resendInvitation: true,
      revokeInvitation: true,
      saveMessageDraft: true,
      sendHrmMessage: true,
      sendMessage: true,
      sendMessageToEMR: true,
      setMessageReminder: true,
      sendOneTimeMessage: 1,
      updateClientContacts: true,
      updateGlobalContacts: true,
      updateWorkSchedule: true,
      viewCarePlans: true,
      viewConsultationActivity: true,
      viewHealthData: true,
      viewIntegrations: true
    }
  },
  [Constants.ROLE_ID_SIGMAIL_ADMIN]: {
    $$formatver: 1,
    clientRights: {
      accessClientContacts: true,
      accessGroupMailbox: true,
      accessMailbox: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      activateMember: [
        Constants.ROLE_ID_ADMIN_MESSAGING_USER,
        Constants.ROLE_ID_ADMIN_PHYSICIAN,
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_MESSAGING_USER,
        Constants.ROLE_ID_SIGMAIL_ADMIN,
        Constants.ROLE_ID_STAFF
      ],
      archiveMessage: true,
      assignMessageCategory: true,
      attachDocumentsToMessage: true,
      composeMessage: true,
      createMessageCategory: true,
      deactivateMember: [
        Constants.ROLE_ID_ADMIN_MESSAGING_USER,
        Constants.ROLE_ID_ADMIN_PHYSICIAN,
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_MESSAGING_USER,
        Constants.ROLE_ID_SIGMAIL_ADMIN,
        Constants.ROLE_ID_STAFF
      ],
      deleteMessageCategory: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      inviteInstitute: true,
      inviteMember: [
        Constants.ROLE_ID_ADMIN_MESSAGING_USER,
        Constants.ROLE_ID_ADMIN_PHYSICIAN,
        Constants.ROLE_ID_ADMIN_STAFF,
        Constants.ROLE_ID_MESSAGING_USER,
        Constants.ROLE_ID_SIGMAIL_ADMIN,
        Constants.ROLE_ID_STAFF
      ],
      markMessageImportant: true,
      markMessagePrivate: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      replyToMessage: true,
      replyToSentMessage: true,
      saveMessageDraft: true,
      sendMessage: true,
      sendOneTimeMessage: true,
      setMessageReminder: true,
      updateClientContacts: true
    }
  },
  [Constants.ROLE_ID_STAFF]: {
    $$formatver: 1,
    clientRights: {
      accessCircleOfCare: true,
      accessClientCollectionList: true,
      accessClientContacts: true,
      accessClientUserList: true,
      accessGlobalContacts: true,
      accessGroupMailbox: true,
      accessGroupSchedule: true,
      accessMailbox: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessOwnSchedule: true,
      archiveMessage: true,
      assignGroupMessageCategory: true,
      assignMessage: true,
      assignMessageCategory: true,
      attachDocumentsToMessage: 4,
      canUpdatePatientProfile: true,
      composeHrmMessage: true,
      composeMessage: true,
      createCalendarEvent: true,
      createCarePlan: true,
      createGuestContactGroup: true,
      createMessageCategory: true,
      deleteCarePlan: true,
      deleteMessageCategory: true,
      editCarePlan: true,
      editGroupInboxNotification: true,
      editInboxNotification: true,
      editMessageCategory: true,
      editMessageSignature: true,
      forwardMessage: true,
      forwardSentMessage: true,
      inviteMember: [
        Constants.ROLE_ID_GUEST,
        Constants.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.ROLE_ID_STAFF
      ],
      markCalendarEventImportant: true,
      markCalendarEventVideo: true,
      markMessageDoNotReply: true,
      markMessageImportant: true,
      markMessagePrivate: true,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      multipleReplyMessage: true,
      recallMessageWindow: 7,
      removeInvitation: true,
      replyToMessage: true,
      replyToSentMessage: true,
      requestHealthData: true,
      resendInvitation: true,
      revokeInvitation: true,
      saveMessageDraft: true,
      sendHrmMessage: true,
      sendMessage: true,
      setCalendarEventReminder: true,
      setMessageReminder: true,
      sendOneTimeMessage: 1,
      updateClientContacts: true,
      updateGlobalContacts: true,
      updateWorkSchedule: true,
      viewCarePlans: true,
      viewHealthData: true
    }
  }
};
