import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogSendGuestAccountInvitation } from '../../constants/action-context';

const i18n: AccountI18n.Dialog.SendGuestAccountInvitation = {
  title: {
    // Translators: (HTML) Title for Send Guest Account Invitation dialog
    invite: gettext('account', 'titleInvite', 'sendGuestAccountInvitationDialog', 'safe-html'),
    // Translators: (HTML) Title for Select Patient Record dialog
    select: gettext('account', 'titleSelect', 'sendGuestAccountInvitationDialog', 'safe-html')
  },
  action: {
    accept: {
      // Translators: Label for the Submit action of Send Guest Account \
      // Invitation dialog
      label: gettext('account', 'accept', 'sendGuestAccountInvitationDialogActionLabel'),
      context: DialogSendGuestAccountInvitation
    },
    reject: {
      // Translators: Label for the Cancel action of Send Guest Account \
      // Invitation dialog
      label: gettext('account', 'reject', 'sendGuestAccountInvitationDialogActionLabel'),
      context: DialogSendGuestAccountInvitation
    },
    select: {
      // Translators: Label for the Select action of Select Patient Record \
      // dialog
      label: gettext('account', 'select', 'sendGuestAccountInvitationDialogActionLabel'),
      context: DialogSendGuestAccountInvitation
    },
  }
};

export default i18n;
