import { AppUserOrUserGroup } from './app-user-or-group';
import type { IAppUser, SigmailUserId } from './types';

/** @public */
export class AppUser extends AppUserOrUserGroup<'user'> implements IAppUser {
  public static override isValidType(value: unknown): value is 'user' {
    return value === 'user';
  }

  /* eslint-disable no-unused-vars */
  public constructor(id: SigmailUserId);

  public constructor(...args: Array<unknown>);
  /* eslint-enable no-unused-vars */

  public constructor(...args: Array<unknown>) {
    super(...(args.length === 1 && typeof args[0] !== 'number' ? args : ['user', ...args]));
    Object.freeze(this);
  }
}
