import { Constants, GenderIdentity, Nullable, Utils } from '@sigmail/common';
import globalI18n from '../../i18n/global';

interface AccuroEMRGenderRecord {
  readonly gender?: Nullable<string>;
}

export const selectGender = (record: Nullable<AccuroEMRGenderRecord>): GenderIdentity => {
  const gender = Utils.trimOrDefault(
    Utils.isNonArrayObjectLike<AccuroEMRGenderRecord>(record) && record?.gender
  ) as GenderIdentity;
  return globalI18n.genderList.some(({ code }) => code === gender) ? gender : Constants.Gender.Unknown;
};
