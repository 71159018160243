export const tokenExpiryToDays = (tokenExpiry?: unknown): number => {
  // prettier-ignore
  switch (tokenExpiry) {
    case '1D': return 1;
    case '1W': return 7;
    case '1M': return 30;
    case '3M': return 90;
    case '6M': return 180;
    case '1Y': return 365;
    default: return 0;
  }
};
