import { AppUser, Constants, HealthDataFormName, MessageBodyHealthDataRequest, Utils } from '@sigmail/common';
import { UserContactListItem, UserObjectHealthData, UserObjectHealthDataValue } from '@sigmail/objects';
import { endOfDay, startOfDay } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { HealthDataRequestConflictException } from '../../../app-state/actions/messaging/send-message-action/health-data-request';
import { healthDataRequestConflictException } from '../../../app-state/actions/messaging/send-message-action/utils/health-data-request-conflict-exception';
import { EMPTY_PLAIN_OBJECT } from '../../../app-state/constants';
import { useActiveGroup, useDispatchFetchUserObjects } from '../../../app-state/hooks';
import { currentUserIdSelector } from '../../../app-state/selectors/auth';
import { healthDataObjectSelector, personNameSelector } from '../../../app-state/selectors/user-object';
import { UserObjectCache } from '../../../app-state/user-objects-slice/cache';
import { FillOnBehalfHealthData, RequestHealthData } from '../../../constants/action-ids';
import { ActionDataSendHealthDataRequest } from '../../messaging/folder-view/hooks';
import { FormValues, Props as FormProps } from '../forms/health-data-request.component';
import { OnClickActionHandler } from '../types';

export interface UseHealthDataRequestFormStateParams {
  actionClickHandler?: OnClickActionHandler | null | undefined;
  guestContact: UserContactListItem;
}

export interface UseHealthDataRequestFormStateResult {
  conflictSource: (NonNullable<UserObjectHealthDataValue['requestList']>[0] & { isOnBehalf?: boolean }) | undefined;
  formId: string | undefined;
  formSubmitting: boolean;
  props: FormProps;
  setFormRef: React.RefCallback<HTMLFormElement | null> | undefined;
  dataForm: HealthDataFormName;
}

type IConflictSource = UseHealthDataRequestFormStateResult['conflictSource'] & { isOnBehalf?: boolean };

export const useHealthDataRequestFormState = (
  params?: UseHealthDataRequestFormStateParams | undefined
): UseHealthDataRequestFormStateResult => {
  const senderId = useSelector(currentUserIdSelector);
  const senderName = useSelector(personNameSelector)(/***/);
  const noop = !AppUser.isValidId(senderId) || Utils.isNil(params);
  const { actionClickHandler, guestContact } = noop ? (EMPTY_PLAIN_OBJECT as NonNullable<typeof params>) : params;
  const [formId, setFormId] = React.useState<string>();
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [conflictSource, setConflictSource] = React.useState<IConflictSource>();
  const dataForm = React.useRef<HealthDataFormName>(null!);
  const values = React.useRef<FormValues>(EMPTY_PLAIN_OBJECT as FormValues);

  const guestContactId = Utils.isNotNil(params) ? params.guestContact.id : undefined;

  const [, forceUpdate] = React.useReducer<React.ReducerWithoutAction<number>>((x) => x + 1, 0);
  const forceFetch = React.useRef(true);
  const healthDataObject = useSelector(healthDataObjectSelector)((!forceFetch.current && guestContactId) as number);
  const healthData = UserObjectCache.getValue(healthDataObject);
  const fetchUserObjects = useDispatchFetchUserObjects();

  const prevUserId = React.useRef<typeof guestContactId>();
  React.useEffect(() => {
    if (prevUserId.current === guestContactId && !forceFetch.current) return;
    prevUserId.current = guestContactId;

    if (Utils.isNotNil(healthData)) return;
    if (!AppUser.isValidId(guestContactId)) return;

    forceFetch.current = false;
    fetchUserObjects({ userObjects: [{ type: UserObjectHealthData.TYPE, userId: guestContactId }] }).then(
      () => forceUpdate(),
      Utils.noop
    );
  }, [fetchUserObjects, healthData, guestContactId]);

  React.useEffect(() => setConflictSource(undefined), [noop]);

  const onFormStateChange = React.useMemo<FormProps['onFormStateChange']>(() => {
    if (!noop) {
      return ({ formState: { submitting, values: formValues } }) => {
        setFormSubmitting(submitting === true);

        if (Utils.isNotNil(conflictSource)) {
          const { current: prevValues } = values;
          const fieldNameList = Object.keys(formValues) as ReadonlyArray<keyof typeof formValues>;
          if (
            fieldNameList.some((key) => {
              const value1 = key.endsWith('Date') ? (prevValues[key] as Date).getTime() : prevValues[key];
              const value2 = key.endsWith('Date') ? (formValues[key] as Date).getTime() : formValues[key];
              return value1 !== value2;
            })
          ) {
            setConflictSource(undefined);
          }
        }

        values.current = formValues;
      };
    }
  }, [conflictSource, noop]);

  const activeGroup = useActiveGroup();
  const onClickFillOnBehalfOf = React.useCallback<NonNullable<FormProps['onClickFillOnBehalfOf']>>(
    (_, value) => {
      if (!noop && typeof actionClickHandler === 'function') {
        if (Utils.isNotNil(healthData)) {
          const startDate = startOfDay(Date.now());
          const currentRequest: MessageBodyHealthDataRequest['messageForm']['value'] = {
            form: value,
            replyToGroupId: activeGroup.id,
            start: startDate.getTime(),
            until: endOfDay(startDate).getTime(),
            weekdays: 0
          };

          healthDataRequestConflictException({
            currentRequest,
            healthData,
            onConflict: () =>
              setConflictSource({
                form: value,
                isOnBehalf: true,
                start: Date.now(),
                weekdays: 0
              })
          });

          dataForm.current = value;
          actionClickHandler(FillOnBehalfHealthData);
        }
      }
    },
    [actionClickHandler, activeGroup.id, healthData, noop]
  );

  const onSubmit = React.useMemo<FormProps['onSubmit']>(() => {
    if (!noop && typeof actionClickHandler === 'function') {
      return async (values) => {
        setConflictSource(undefined);

        const actionData: ActionDataSendHealthDataRequest = {
          recipient: guestContact,
          sender: {
            type: 'user',
            id: senderId,
            ...Utils.pick(senderName, Constants.PERSON_NAME_KEY_LIST)
          },
          ...values
        };

        const result = await Promise.resolve(actionClickHandler(RequestHealthData, actionData));
        if (Utils.isNonArrayObjectLike<PromiseSettledResult<void>>(result) && result.status === 'rejected') {
          if (result.reason instanceof HealthDataRequestConflictException) {
            setConflictSource(result.reason.conflictSource);
          }
        }

        return result;
      };
    }
  }, [actionClickHandler, guestContact, noop, senderId, senderName]);

  const setFormRef = React.useMemo<React.RefCallback<HTMLFormElement> | undefined>(() => {
    if (!noop) {
      return (form) => setFormId(form?.id);
    }
  }, [noop]);

  return React.useMemo(
    (): UseHealthDataRequestFormStateResult => ({
      conflictSource,
      dataForm: dataForm.current,
      formId,
      formSubmitting: !noop && formSubmitting,
      props: {
        disabled: noop || formSubmitting,
        guestContact,
        onClickFillOnBehalfOf,
        onFormStateChange,
        onSubmit
      },
      setFormRef
    }),
    [
      conflictSource,
      formId,
      formSubmitting,
      guestContact,
      noop,
      onClickFillOnBehalfOf,
      onFormStateChange,
      onSubmit,
      setFormRef
    ]
  );
};
