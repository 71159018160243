import { WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Utils } from '@sigmail/common';
import clsx from 'clsx';
import React from 'react';
import { MutableRef } from 'sigmail';
import { DialogAccept, DialogReject } from '../../../../constants/action-ids';
import { SubmitButton } from '../../submit-button.component';
import style from '../dialog-box.module.css';

export interface AcceptActionProps extends React.ComponentPropsWithoutRef<typeof SubmitButton> {
  actionId?: string | undefined;
  innerRef?: MutableRef<HTMLButtonElement> | undefined;
}

export interface RejectActionProps extends React.ComponentPropsWithoutRef<'button'> {
  actionId?: string | undefined;
  innerRef?: MutableRef<HTMLButtonElement> | undefined;
}

export type DialogActionsAcceptRejectClassKey = 'actionAccept' | 'actionReject';

const styles = createStyles<DialogActionsAcceptRejectClassKey, {}>({
  actionAccept: {},
  actionReject: {}
});

export interface DialogActionsAcceptRejectProps extends WithStyles<typeof styles> {
  AcceptActionProps?: AcceptActionProps | undefined;
  children?: never;
  onAccept?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onReject?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  RejectActionProps?: RejectActionProps | undefined;
}

class DialogActionsAcceptRejectComponent extends React.PureComponent<DialogActionsAcceptRejectProps> {
  public render(): React.ReactNode {
    const { AcceptActionProps, classes, onAccept, onReject, RejectActionProps } = this.props;

    let { actionId: actionIdReject, innerRef: refReject, ...rejectActionProps } = RejectActionProps || {};
    let { actionId: actionIdAccept, innerRef: refAccept, ...acceptActionProps } = AcceptActionProps || {};
    if (!Utils.has(RejectActionProps, 'actionId')) actionIdReject = DialogReject;
    if (!Utils.has(AcceptActionProps, 'actionId')) actionIdAccept = DialogAccept;

    rejectActionProps = {
      className: clsx(style['action-reject'], classes.actionReject),
      onClick: onReject,
      ...rejectActionProps
    };

    acceptActionProps = {
      className: clsx(style['action-accept'], classes.actionAccept),
      onClick: onAccept,
      ...acceptActionProps
    };

    return (
      <React.Fragment>
        <button data-action-id={actionIdReject} ref={refReject} {...rejectActionProps} />
        <SubmitButton data-action-id={actionIdAccept} ref={refAccept} {...acceptActionProps} />
      </React.Fragment>
    );
  }
}

export const DialogActionsAcceptReject = withStyles(styles)(DialogActionsAcceptRejectComponent);
DialogActionsAcceptReject.displayName = 'DialogActionsAcceptReject';
