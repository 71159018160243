import { WithT } from 'i18next';
import React from 'react';
import { DialogAccept, DialogReject } from '../../../constants/action-ids';
import accountI18n from '../../../i18n/account';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { DialogActionsAcceptReject } from '../../shared/dialog/actions/accept-reject.component';
import { DialogBoxProps } from '../../shared/dialog/dialog-box.component';
import { DIALOG_SEND_NON_GUEST_ACCOUNT_INVITATION } from '../constants';

const { dialog: accountDialogI18n } = accountI18n;

export interface UseDialogPropsParams extends WithT {
  body?: DialogBoxProps['body'] | undefined;
  formId?: string | undefined;
  formLoading?: boolean | undefined;
  formSubmitting?: boolean | undefined;
  onClose?: DialogBoxProps['onClose'] | undefined;
  onDialogActionClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  open: boolean;
  openedDialog?: string | null | undefined;
}

export const useDialogProps = ({
  body,
  formId,
  formLoading,
  formSubmitting,
  onClose,
  onDialogActionClick: onClick,
  open,
  openedDialog,
  t
}: UseDialogPropsParams) => {
  const isFormLoading = formLoading === true;
  const isFormSubmitting = formSubmitting === true;

  return React.useMemo(() => {
    const result: DialogBoxProps = { open, onClose };
    if (open !== true) return result;

    switch (openedDialog) {
      case DIALOG_SEND_NON_GUEST_ACCOUNT_INVITATION: {
        const i18n = accountDialogI18n[openedDialog];

        const actionLabelAccept = t(resolveActionLabel(i18n.action.accept), DialogAccept);
        const actionLabelReject = t(resolveActionLabel(i18n.action.reject), DialogReject);

        result.disableBackdropClick = true;
        result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };
        result.body = body;
        result.actions = (
          <DialogActionsAcceptReject
            AcceptActionProps={{
              children: actionLabelAccept,
              disabled: isFormLoading || isFormSubmitting,
              form: formId,
              progress: isFormSubmitting
            }}
            RejectActionProps={{ children: actionLabelReject, disabled: isFormSubmitting, onClick }}
          />
        );

        break;
      }
      default: {
        break;
      }
    }

    return result;
  }, [body, formId, isFormLoading, isFormSubmitting, onClick, onClose, open, openedDialog, t]);
};
