import { SchedulingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { OwnSchedule } from '../../constants/action-context';

const i18n: SchedulingI18n.OwnSchedule = {
  // Translators: (HTML) Heading for the Own Schedule page
  heading1: gettext('scheduling', 'scheduleOwn', 'h1', 'safe-html'),
  action: {
    goToToday: {
      // Translators: Label for the Go To Today action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToToday'),
      context: OwnSchedule
    },
    goToNextDay: {
      // Translators: Label for the Go To Next Day action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToNextDay'),
      context: OwnSchedule
    },
    goToNextWeek: {
      // Translators: Label for the Go To Next Week action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToNextWeek'),
      context: OwnSchedule
    },
    goToNextMonth: {
      // Translators: Label for the Go To Next Month action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToNextMonth'),
      context: OwnSchedule
    },
    goToNextYear: {
      // Translators: Label for the Go To Next Year action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToNextYear'),
      context: OwnSchedule
    },
    goToPreviousDay: {
      // Translators: Label for the Go To Previous Day action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToPreviousDay'),
      context: OwnSchedule
    },
    goToPreviousWeek: {
      // Translators: Label for the Go To Previous Week action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToPreviousWeek'),
      context: OwnSchedule
    },
    goToPreviousMonth: {
      // Translators: Label for the Go To Previous Month action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToPreviousMonth'),
      context: OwnSchedule
    },
    goToPreviousYear: {
      // Translators: Label for the Go To Previous Year action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelGoToPreviousYear'),
      context: OwnSchedule
    },
    viewByDay: {
      // Translators: Label for the View By Day action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelViewByDay'),
      context: OwnSchedule
    },
    viewByWeek: {
      // Translators: Label for the View By Week action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelViewByWeek'),
      context: OwnSchedule
    },
    viewByMonth: {
      // Translators: Label for the View By Month action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelViewByMonth'),
      context: OwnSchedule
    },
    viewByYear: {
      // Translators: Label for the View By Year action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelViewByYear'),
      context: OwnSchedule
    },
    newEvent: {
      // Translators: Label for the New Event action
      label: gettext('scheduling', 'scheduleOwn', 'actionLabelNewEvent'),
      context: OwnSchedule
    }
  }
};

export default i18n;
