import {
  CalendarEventExtendedProps,
  EventMessageStatus,
  IAppUser,
  Nullable,
  Utils,
  ValueObject,
  Writeable
} from '@sigmail/common';
import { EMPTY_PLAIN_OBJECT } from '../app-state/constants';

export type EventFlagKey =
  | 'isAllDayEvent'
  | 'isImportant'
  | 'isBillable'
  | 'isVideoMeeting'
  | 'isPrivate'
  | 'isRecurring'
  | 'isTransparent'
  | 'isCancelled';

const returnNull = () => null;

const isEventCreatedBy = (calendarEvent?: any): ((user?: any) => boolean | null) => {
  if (!Utils.isNonArrayObjectLike<{ extendedProps: unknown }>(calendarEvent)) return returnNull;

  return (user) => {
    if (Utils.isNonArrayObjectLike<Omit<IAppUser, keyof ValueObject>>(user)) {
      const { createdBy } = (Utils.isNonArrayObjectLike(calendarEvent!.extendedProps)
        ? calendarEvent!.extendedProps
        : {}) as CalendarEventExtendedProps;

      if (Utils.isNonArrayObjectLike<CalendarEventExtendedProps['createdBy']>(createdBy)) {
        return createdBy.id === user.id;
      }
    }
    return false;
  };
};

const isUserInEventAttendeeList = (calendarEvent?: any): ((user?: any) => boolean | null) => {
  if (!Utils.isNonArrayObjectLike<{ extendedProps: unknown }>(calendarEvent)) return returnNull;

  return (user) => {
    if (Utils.isNonArrayObjectLike<Omit<IAppUser, keyof ValueObject>>(user)) {
      const { attendeeList } = (Utils.isNonArrayObjectLike(calendarEvent!.extendedProps)
        ? calendarEvent!.extendedProps
        : {}) as CalendarEventExtendedProps;

      if (Utils.isNonEmptyArray<CalendarEventExtendedProps['attendeeList'][0]>(attendeeList)) {
        return attendeeList.some((item) => Utils.isNonArrayObjectLike(item) && item.id === user.id);
      }
    }
    return false;
  };
};

const hasUserRespondedToEvent = (calendarEvent?: any): ((user?: any) => boolean | null) => {
  if (!Utils.isNonArrayObjectLike<{ extendedProps: unknown }>(calendarEvent)) return returnNull;

  return (user) => {
    if (Utils.isNonArrayObjectLike<Omit<IAppUser, keyof ValueObject>>(user)) {
      const { attendeeList } = (Utils.isNonArrayObjectLike(calendarEvent!.extendedProps)
        ? calendarEvent!.extendedProps
        : {}) as CalendarEventExtendedProps;

      if (Utils.isNonEmptyArray<CalendarEventExtendedProps['attendeeList'][0]>(attendeeList)) {
        const attendee = attendeeList.find((item) => Utils.isNonArrayObjectLike(item) && item.id === user.id);
        return (
          Utils.isNotNil(attendee?.timestamp) &&
          (Utils.isInteger(attendee!.timestamp!.acceptedAt) || Utils.isInteger(attendee!.timestamp!.declinedAt))
        );
      }
    }
    return false;
  };
};

const matchEventStatusResponse = (
  response: EventMessageStatus,
  calendarEvent?: any
): ((user?: any) => boolean | null) => {
  if (!Utils.isNonArrayObjectLike<{ extendedProps: unknown }>(calendarEvent)) return returnNull;

  return (user) => {
    if (Utils.isNonArrayObjectLike<Omit<IAppUser, keyof ValueObject>>(user)) {
      const { attendeeList } = (Utils.isNonArrayObjectLike(calendarEvent!.extendedProps)
        ? calendarEvent!.extendedProps
        : {}) as CalendarEventExtendedProps;

      if (Utils.isNonEmptyArray<CalendarEventExtendedProps['attendeeList'][0]>(attendeeList)) {
        const attendee = attendeeList.find((item) => Utils.isNonArrayObjectLike(item) && item.id === user.id);
        return (
          (response === 'accepted' && Utils.isInteger(attendee?.timestamp?.acceptedAt)) ||
          (response === 'declined' && Utils.isInteger(attendee?.timestamp?.declinedAt))
        );
      }
    }
    return false;
  };
};

const getTimestamp = (
  calendarEvent?: any
): ((
  timestamp?: keyof NonNullable<CalendarEventExtendedProps['attendeeList'][0]['timestamp']>,
  user?: any
) => Nullable<number>) => {
  if (!Utils.isNonArrayObjectLike<{ extendedProps: unknown }>(calendarEvent)) return returnNull;

  return (timestamp, user) => {
    if (Utils.isNonArrayObjectLike<Omit<IAppUser, keyof ValueObject>>(user) && Utils.isString(timestamp)) {
      const { attendeeList } = (Utils.isNonArrayObjectLike(calendarEvent!.extendedProps)
        ? calendarEvent!.extendedProps
        : EMPTY_PLAIN_OBJECT) as CalendarEventExtendedProps;

      if (Utils.isNonEmptyArray<CalendarEventExtendedProps['attendeeList'][0]>(attendeeList)) {
        const attendee = attendeeList.find((item) => Utils.isNonArrayObjectLike(item) && item.id === user.id);
        if (
          Utils.isNotNil(attendee) &&
          Utils.isNotNil(attendee.timestamp) &&
          Utils.isInteger(attendee.timestamp[timestamp])
        ) {
          return attendee.timestamp[timestamp];
        }
      }
    }
    return undefined;
  };
};

const hasVideoConsent = (calendarEvent?: any): ((user?: any) => boolean | null) => {
  const getVideoConsentAt = getTimestamp(calendarEvent).bind(null, 'videoConsentAt');
  return (user) => Utils.isInteger(getVideoConsentAt(user));
};

const hasReminder = (calendarEvent?: any): ((user?: any) => boolean | null) => {
  if (!Utils.isNonArrayObjectLike<{ extendedProps: unknown }>(calendarEvent)) return returnNull;

  return (user) => {
    if (Utils.isNonArrayObjectLike<Omit<IAppUser, keyof ValueObject>>(user)) {
      const { attendeeList } = (Utils.isNonArrayObjectLike(calendarEvent!.extendedProps)
        ? calendarEvent!.extendedProps
        : {}) as CalendarEventExtendedProps;

      if (Utils.isNonEmptyArray<CalendarEventExtendedProps['attendeeList'][0]>(attendeeList)) {
        const attendee = attendeeList.find((item) => Utils.isNonArrayObjectLike(item) && item.id === user.id);
        return Utils.isNonEmptyArray(attendee?.reminderList);
      }
    }
    return false;
  };
};

export type EventFlagsResult = Readonly<
  Record<EventFlagKey, boolean | null> & {
    getTimestamp: ReturnType<typeof getTimestamp>;
    isCreatedBy: ReturnType<typeof isEventCreatedBy>;
    isInvited: ReturnType<typeof isUserInEventAttendeeList>;
    hasResponded: ReturnType<typeof hasUserRespondedToEvent>;
    hasAccepted: ReturnType<typeof matchEventStatusResponse>;
    hasDeclined: ReturnType<typeof matchEventStatusResponse>;
    hasVideoConsent: ReturnType<typeof hasVideoConsent>;
    hasReminder: ReturnType<typeof hasReminder>;
  }
>;

export function EventFlags(calendarEvent?: any): EventFlagsResult {
  const result: Writeable<EventFlagsResult> = {
    getTimestamp: getTimestamp(calendarEvent),
    isCreatedBy: isEventCreatedBy(calendarEvent),
    isInvited: isUserInEventAttendeeList(calendarEvent),
    hasResponded: hasUserRespondedToEvent(calendarEvent),
    hasAccepted: matchEventStatusResponse('accepted', calendarEvent),
    hasDeclined: matchEventStatusResponse('declined', calendarEvent),
    hasVideoConsent: hasVideoConsent(calendarEvent),
    hasReminder: hasReminder(calendarEvent),

    isAllDayEvent: null,
    isImportant: null,
    isBillable: null,
    isVideoMeeting: null,
    isPrivate: null,
    isRecurring: null,
    isTransparent: null,
    isCancelled: null
  };

  if (Utils.isNonArrayObjectLike<{ allDay: boolean; extendedProps: unknown }>(calendarEvent)) {
    result.isAllDayEvent = calendarEvent.allDay === true;

    const { extendedProps } = calendarEvent;
    if (Utils.isNonArrayObjectLike<CalendarEventExtendedProps>(extendedProps)) {
      const { importance, billable, meetingType, eventClass, recurrence, transparent, dtCancelled } = extendedProps;

      result.isImportant = importance === 'high';
      result.isBillable = billable === true;
      result.isVideoMeeting = meetingType === 'video';
      result.isPrivate = eventClass === 'PRIVATE';
      result.isRecurring = Utils.isString(recurrence);
      result.isTransparent = transparent === true;
      result.isCancelled = Utils.isInteger(dtCancelled) && Utils.isValidDate(new Date(dtCancelled));
    }
  }

  return result;
}
