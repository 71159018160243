import { MessagingActionPayload } from '@sigmail/app-state';
import {
  Constants,
  HealthDataFormName,
  MessageFormNameDefault,
  MessageSensitivity,
  MessagingException,
  ReadonlyMessageBodyDefault,
  Utils
} from '@sigmail/common';
import { IUserObject, UserObjectHealthData, UserObjectHealthDataValue } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { startOfDay } from 'date-fns';
import { WithT } from 'i18next';
import { dateToUtcValues } from '../../../../utils/date-to-utc-values';
import { EMPTY_ARRAY } from '../../../constants';
import { healthDataObjectSelector } from '../../../selectors/user-object';
import { ActionInitParams, FetchObjectsRequestData } from '../../base-action';
import { deleteMessageAction } from '../delete-message-action';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from '../send-message-action/base';

type KeysToOmit = Extract<
  keyof BaseSendMessagePayload,
  | 'documentList'
  | 'folderKey'
  | `message${'Body' | 'FormName' | 'Kind'}`
  | 'parentFolderKey'
  | 'recipientList'
  | 'sensitivity'
  | 'sourceMessage'
>;

export interface BaseSubmitHealthDataActionPayload
  extends Omit<BaseSendMessagePayload, KeysToOmit>,
    Omit<MessagingActionPayload.SubmitHealthData, keyof WithT> {
  readonly dataForm: HealthDataFormName;
  readonly messageBody: ReadonlyMessageBodyDefault;
  readonly messageFormName: MessageFormNameDefault;
  readonly sensitivity: Extract<MessageSensitivity, 'normal'>;
}

export interface BaseSubmitHealthDataActionState extends BaseSendMessageState {
  healthDataObject: IUserObject<UserObjectHealthDataValue>;
}

export class BaseSubmitHealthDataAction<
  P extends BaseSubmitHealthDataActionPayload = BaseSubmitHealthDataActionPayload,
  S extends BaseSubmitHealthDataActionState = BaseSubmitHealthDataActionState
> extends BaseSendMessageAction<P, S> {
  public constructor(params: ActionInitParams<P>) {
    const payload = Utils.omit(params.payload, ['documentList']) as P;

    super({
      ...params,
      payload: {
        ...payload,
        flags: { billable: false, important: false },
        messageFormName: Constants.MessageFormName.Default,
        sensitivity: 'normal'
      }
    });
  }

  /** override */
  protected async generateRequestBody(): Promise<void> {
    const { folderKey, onBehalfOf, parentFolderKey, sender, sourceMessage } = this.payload;
    const { batchUpdateAuthState, batchUpdateClaims, requestBody, successPayload } = this.state;

    const { id: userId } = Utils.isNil(onBehalfOf) ? sender : onBehalfOf;
    const query: FetchObjectsRequestData = {
      authState: batchUpdateAuthState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_HEALTH_DATA, userId }],
      expectedCount: { claims: 1 }
    };

    const { claims } = await this.dispatchFetchObjects(query);
    if (!Utils.isNonEmptyArray<string>(claims)) {
      throw new Api.MalformedResponseException(`Expected <claims> to be a non-empty array.`);
    }

    batchUpdateClaims.push(...claims);
    this.state.healthDataObject = (await this.getUserObject(healthDataObjectSelector, { userId }))!;

    // if (Utils.isNil(sourceMessage)) {
    //   await this.fetchSenderPublicKey();
    // }

    await super.generateRequestBody();

    await this.addUpdateOperationForHealthData(); // 421

    if (Utils.isNotNil(sourceMessage)) {
      await this.dispatch(
        deleteMessageAction({
          folderKey,
          msgMetadataId: sourceMessage.header,
          parentFolderKey,
          requestBody,
          successPayload
        })
      );
    }
  }

  // private async fetchSenderPublicKey(): Promise<void> {
  //   const { sender } = this.payload;
  //   const { roleAuthClaim: authState } = this.state;

  //   const query: FetchObjectsRequestData = {
  //     authState,
  //     keysByType: [{ type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: sender.id }]
  //   };
  //   if (query.keysByType!.length === 0) return;

  //   this.logger.info('Fetching public key of sender.');
  //   const { keyList } = await this.dispatchFetchObjects(query);
  //   await Promise.all(keyList.map((keyJson) => CryptographicKey.cache(new CryptographicKeyPublic(keyJson))));
  // }

  protected async updateHealthDataObject(healthDataObject: IUserObject<UserObjectHealthDataValue>): Promise<typeof healthDataObject> {
    const { dataForm, sourceMessage } = this.payload;
    const { dtServer, msgMetadataId } = this.state;

    let healthData = await healthDataObject.decryptedValue();

    let requestId = msgMetadataId;
    if (Utils.isNotNil(sourceMessage)) {
      requestId = sourceMessage.extraData!.requestId as number;
    } else {
      const updatedRequestList = [
        ...Utils.arrayOrDefault<NonNullable<UserObjectHealthDataValue['requestList']>[0]>(healthData.requestList),
        { form: dataForm, start: startOfDay(dtServer).getTime(), weekdays: 0 }
      ];

      const index = updatedRequestList.length - 1;
      healthData = {
        ...healthData,
        $index: { ...healthData.$index, [requestId]: [index, EMPTY_ARRAY] },
        requestList: updatedRequestList
      };
    }

    const [utcYear, utcMonth, utcDate] = dateToUtcValues(dtServer);
    const isDataPresent = Utils.isNotNil(healthData[dataForm]?.[utcYear]?.[utcMonth]?.[utcDate]);
    if (isDataPresent) {
      throw new MessagingException(`Health data is already present under ${utcYear} -> ${utcMonth} - > ${utcDate}`);
    }

    const updatedResponseList = [dtServer.getTime(), ...healthData.$index[requestId]![1]];
    const updatedValue: UserObjectHealthDataValue = {
      ...healthData,
      $index: {
        ...healthData.$index,
        [requestId]: [healthData.$index[requestId]![0], updatedResponseList]
      },
      [dataForm]: {
        ...healthData[dataForm],
        [utcYear]: {
          ...healthData[dataForm]?.[utcYear],
          [utcMonth]: {
            ...healthData[dataForm]?.[utcYear]?.[utcMonth],
            [utcDate]: this.payload[dataForm]
          }
        }
      }
    };

    this.logger.debug('updatedValue', updatedValue);
    return healthDataObject.updateValue(updatedValue);
  }

  protected async getHealthDataUpdater(): Promise<Api.DataUpdater<IUserObject<UserObjectHealthDataValue>>> {
    const { healthDataObject, successPayload } = this.state;

    const healthDataObjectKey = healthDataObject[Constants.$$CryptographicKey];
    return async (obj, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === obj.id);
      if (index === -1) {
        throw new MessagingException('Unexpected error; health data object could not be found in request body.');
      }

      const key = healthDataObjectKey === null ? null : healthDataObjectKey.toApiFormatted();
      const healthDataObject = new UserObjectHealthData({ ...obj, key });
      const updatedObject = await this.updateHealthDataObject(healthDataObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === obj.id);
      if (index !== -1) successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
    };
  }

  protected async addUpdateOperationForHealthData(): Promise<void> {
    this.logger.info('Adding an update operation to request body for health data object.');

    const { healthDataObject, requestBody, successPayload } = this.state;

    const updatedObject = await this.updateHealthDataObject(healthDataObject);
    const dataUpdater = await this.getHealthDataUpdater();
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(updatedObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }
}
