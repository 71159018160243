import { Utils } from '@sigmail/common';
import { E_FAIL } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';
import type { IEncryptor } from '../types';

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
const crypto = globalThis.crypto || (globalThis as any).msCrypto;

/** @public */
export abstract class Encryptor implements IEncryptor {
  public readonly NAME: string;

  protected constructor(name: string) {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
      globalThis.isSecureContext !== true ||
      typeof crypto?.getRandomValues !== 'function' ||
      !(crypto?.subtle instanceof SubtleCrypto)
    ) {
      throw new SigmailCryptoException(E_FAIL, 'Error initializing crypto API.');
    }

    const trimmedName = Utils.trimOrDefault(name);
    if (trimmedName.length === 0) {
      throw new SigmailCryptoException(E_FAIL, 'Invalid name.');
    }

    this.NAME = name;
  }
}
