import { Utils } from '@sigmail/common';
import { MessageCategory } from '@sigmail/objects';
import { Style as PdfMakeStyle } from 'pdfmake/interfaces';
import { ISuspenseResource } from 'sigmail';
import { ACCESSIBLE_COLOR_PALETTE } from './color-palette';
import { EnglishCanada } from './language-codes';

export const DEFAULT_MESSAGE_CATEGORY_LIST: ReadonlyArray<MessageCategory> = [
  {
    color: ACCESSIBLE_COLOR_PALETTE[19][0],
    id: process.env.PREDEFINED_MSG_CATEGORY_ID_BLUE,
    label: 'Blue',
    textColor: ACCESSIBLE_COLOR_PALETTE[19][1]
  },
  {
    color: ACCESSIBLE_COLOR_PALETTE[10][0],
    id: process.env.PREDEFINED_MSG_CATEGORY_ID_GREEN,
    label: 'Green',
    textColor: ACCESSIBLE_COLOR_PALETTE[10][1]
  },
  {
    color: ACCESSIBLE_COLOR_PALETTE[4][0],
    id: process.env.PREDEFINED_MSG_CATEGORY_ID_ORANGE,
    label: 'Orange',
    textColor: ACCESSIBLE_COLOR_PALETTE[4][1]
  },
  {
    color: ACCESSIBLE_COLOR_PALETTE[3][0],
    id: process.env.PREDEFINED_MSG_CATEGORY_ID_PURPLE,
    label: 'Purple',
    textColor: ACCESSIBLE_COLOR_PALETTE[3][1]
  },
  {
    color: ACCESSIBLE_COLOR_PALETTE[0][0],
    id: process.env.PREDEFINED_MSG_CATEGORY_ID_RED,
    label: 'Red',
    textColor: ACCESSIBLE_COLOR_PALETTE[0][1]
  }
];

export const DEFAULT_MESSAGE_SIGNATURE = '<p><br></p>';
export const DEFAULT_PREFERRED_LANGUAGE_CODE = EnglishCanada;
export const DEFAULT_REFERRAL_EXPIRY_IN_DAYS = 30;
export const DEFAULT_JOIN_GROUP_INVITATION_EXPIRY_IN_DAYS = 30;

/** Twilio's Group Rooms support a maximum of 50 participants. */
export const MAX_EVENT_ATTENDEE_LIST_COUNT = 50;

export const PENDING_PROMISE = new Promise<any>(Utils.noop);
export const UNRESOLVED_RESOURCE: ISuspenseResource<any> = Utils.makeCancelablePromise(PENDING_PROMISE);

/** Maximum value, in milliseconds, of a safe timestamp. */
export const MAX_SAFE_TIMESTAMP: number = Date.UTC(275760, 8, 11, 23, 59, 59, 999);

/** Minimum value, in milliseconds, of a safe timestamp. */
export const MIN_SAFE_TIMESTAMP = Date.UTC(-271821, 3, 21);

export const HEALTH_INSURANCE_PLAN_NUMBER_PLACEHOLDER: Readonly<
  Record<string, Readonly<Record<string, string | undefined>>>
> = {
  CAN: {
    AB: '111-111-111-11',
    BC: '9111-111-111',
    MB: '111-111-111',
    NB: '111-111-111',
    NL: '111-111-111-111',
    NS: '1111-111-111',
    NT: 'X1111111',
    NU: '111111111',
    ON: '1111-111-111-XX',
    PE: '111111111',
    QC: 'XXXX-1111-1111',
    SK: '111-111-111',
    YT: '111-111-111'
  }
};

export const HTML_TO_PDF_MAKE_DEFAULT_STYLES_OVERRIDE: Readonly<Record<string, Readonly<PdfMakeStyle>>> = {
  a: { color: '#0074a6' },
  blockquote: { marginBottom: 0 },
  h1: { marginBottom: 0 },
  h2: { marginBottom: 0 },
  h3: { marginBottom: 0 },
  h4: { marginBottom: 0 },
  h5: { marginBottom: 0 },
  h6: { marginBottom: 0 },
  ol: { margin: [18, 0, 0, 0] },
  p: { margin: 0 },
  pre: { margin: 0 },
  td: { margin: 4 },
  tfoot: { fillColor: '#f5f7f9' },
  th: { margin: 4 },
  thead: { fillColor: '#f5f7f9' },
  ul: { margin: [18, 0, 0, 0] }
};

export const QUILL_TO_PDF_MAKE_STYLE_MAP: Readonly<Record<string, Readonly<PdfMakeStyle>>> = {
  'ql-align-center': { alignment: 'center' },
  'ql-align-justify': { alignment: 'justify' },
  'ql-align-left': { alignment: 'left' },
  'ql-align-right': { alignment: 'right' },
  'ql-bg-blue': { background: '#06c' },
  'ql-bg-green': { background: '#008a00' },
  'ql-bg-orange': { background: '#f90' },
  'ql-bg-purple': { background: '#93f' },
  'ql-bg-red': { background: '#e60000' },
  'ql-bg-white': { background: '#fff' },
  'ql-bg-yellow': { background: '#ff0' },
  'ql-color-blue': { color: '#06c' },
  'ql-color-green': { color: '#008a00' },
  'ql-color-orange': { color: '#f90' },
  'ql-color-purple': { color: '#93f' },
  'ql-color-red': { color: '#e60000' },
  'ql-color-white': { color: '#fff' },
  'ql-color-yellow': { color: '#ff0' },
  'ql-indent-1': { margin: [36, 0, 0, 0] },
  'ql-indent-2': { margin: [72, 0, 0, 0] },
  'ql-indent-3': { margin: [108, 0, 0, 0] },
  'ql-indent-4': { margin: [144, 0, 0, 0] },
  'ql-indent-5': { margin: [180, 0, 0, 0] },
  'ql-indent-6': { margin: [216, 0, 0, 0] },
  'ql-indent-7': { margin: [252, 0, 0, 0] },
  'ql-indent-8': { margin: [288, 0, 0, 0] },
  'ql-indent-9': { margin: [324, 0, 0, 0] }
};

export const REMINDER_NOTIFICATION_REMIND_INTERVAL = 900000; // 15 minutes
export const REMINDER_NOTIFICATION_RETRY_DELAY = 300000; // 5 minutes
export const REMINDER_NOTIFICATION_STORAGE_KEY_DISMISSED = '__ed__';

export const SET_MILLISECONDS_SECONDS_TO_NULL = { milliseconds: 0, seconds: 0 };
