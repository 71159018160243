import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['addressLevel1'] = {
  CAN: [
    {
      code: 'AB',
      // Translators: Full name of Canadian province (Alberta)
      name: gettext('global', 'CAN$AB', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Alberta)
      abbr: gettext('global', 'CAN$AB', 'addressLevel1Abbr')
    },
    {
      code: 'BC',
      // Translators: Full name of Canadian province (British Columbia)
      name: gettext('global', 'CAN$BC', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (British Columbia)
      abbr: gettext('global', 'CAN$BC', 'addressLevel1Abbr')
    },
    {
      code: 'MB',
      // Translators: Full name of Canadian province (Manitoba)
      name: gettext('global', 'CAN$MB', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Manitoba)
      abbr: gettext('global', 'CAN$MB', 'addressLevel1Abbr')
    },
    {
      code: 'NB',
      // Translators: Full name of Canadian province (New Brunswick)
      name: gettext('global', 'CAN$NB', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (New Brunswick)
      abbr: gettext('global', 'CAN$NB', 'addressLevel1Abbr')
    },
    {
      code: 'NL',
      // Translators: Full name of Canadian province (Newfoundland and Labrador)
      name: gettext('global', 'CAN$NL', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Newfoundland and Labrador)
      abbr: gettext('global', 'CAN$NL', 'addressLevel1Abbr')
    },
    {
      code: 'NS',
      // Translators: Full name of Canadian province (Nova Scotia)
      name: gettext('global', 'CAN$NS', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Nova Scotia)
      abbr: gettext('global', 'CAN$NS', 'addressLevel1Abbr')
    },
    {
      code: 'NT',
      // Translators: Full name of Canadian province (Northwest Territories)
      name: gettext('global', 'CAN$NT', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Northwest Territories)
      abbr: gettext('global', 'CAN$NT', 'addressLevel1Abbr')
    },
    {
      code: 'NU',
      // Translators: Full name of Canadian province (Nunavut)
      name: gettext('global', 'CAN$NU', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Nunavut)
      abbr: gettext('global', 'CAN$NU', 'addressLevel1Abbr')
    },
    {
      code: 'ON',
      // Translators: Full name of Canadian province (Ontario)
      name: gettext('global', 'CAN$ON', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Ontario)
      abbr: gettext('global', 'CAN$ON', 'addressLevel1Abbr')
    },
    {
      code: 'PE',
      // Translators: Full name of Canadian province (Prince Edward Island)
      name: gettext('global', 'CAN$PE', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Prince Edward Island)
      abbr: gettext('global', 'CAN$PE', 'addressLevel1Abbr')
    },
    {
      code: 'QC',
      // Translators: Full name of Canadian province (Quebec)
      name: gettext('global', 'CAN$QC', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Quebec)
      abbr: gettext('global', 'CAN$QC', 'addressLevel1Abbr')
    },
    {
      code: 'SK',
      // Translators: Full name of Canadian province (Saskatchewan)
      name: gettext('global', 'CAN$SK', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Saskatchewan)
      abbr: gettext('global', 'CAN$SK', 'addressLevel1Abbr')
    },
    {
      code: 'YT',
      // Translators: Full name of Canadian province (Yukon)
      name: gettext('global', 'CAN$YT', 'addressLevel1Name'),
      // Translators: Abbreviation of Canadian province (Yukon)
      abbr: gettext('global', 'CAN$YT', 'addressLevel1Abbr')
    }
  ]
};

export default i18n;
