import { Dialog, DialogContent, DialogProps as MuiDialogProps, DialogTitle } from '@material-ui/core';
import { CancelablePromise, Utils } from '@sigmail/common';
import React from 'react';
import * as ActionId from '../../../constants/action-ids';
import { getNextId } from '../../../hooks';
import { CircularProgress } from '../../shared/circular-progress.component';
import style from './meeting-room-dialog.module.css';
import { Context } from './meeting-room.context';
import { ParticipantList } from './participant-list.component';

type DialogProps = Omit<MuiDialogProps, 'ref' | 'children'>;

export interface Props extends DialogProps {
  children?: never;
}

interface State {
  batchQueryDataPromise: CancelablePromise<any> | null;
}

const PROPS_TO_OMIT: ReadonlyArray<keyof Omit<Props, keyof DialogProps>> = [
  // Props
  'children'
];

class MeetingRoomDialogComponent extends React.PureComponent<Props, State> {
  public static defaultProps: Partial<Props> = {
    className: style.root,
    disableBackdropClick: true,
    maxWidth: 'lg'
  };

  public static contextType = Context;
  context!: React.ContextType<typeof Context>;

  private readonly dialogTitleId = `meeting-room-dialog-title-${getNextId().toString(16)}`;

  public constructor(props: Props) {
    super(props);

    this.state = {
      batchQueryDataPromise: Utils.makeCancelablePromise(Promise.resolve())
    };

    this.componentDidUpdate = Utils.noop;
    this.onClickAction = this.onClickAction.bind(this);
    this.onDialogEntered = this.onDialogEntered.bind(this);
  }

  public getSnapshotBeforeUpdate(prevProps: Readonly<Props>): any {
    const { open: isOpen } = this.props;
    const { open: prevIsOpen } = prevProps;

    if (!isOpen && prevIsOpen) {
      this.context.disconnect();
    }

    return null;
  }

  public render(): React.ReactNode {
    let dialogTitle = this.context.calendarEvent?.title;
    if (!Utils.isString(dialogTitle)) dialogTitle = '';

    const rootProps = Utils.omit(this.props, PROPS_TO_OMIT);
    return (
      <Dialog {...rootProps} onEntered={this.onDialogEntered}>
        <DialogTitle id={this.dialogTitleId}>
          <div dangerouslySetInnerHTML={{ __html: dialogTitle }} />
        </DialogTitle>
        <DialogContent>{this.renderDialogContent()}</DialogContent>
      </Dialog>
    );
  }

  private renderDialogContent(): React.ReactNode {
    if (this.context.isConnecting) return <CircularProgress />;
    if (this.context.room === null) return null;

    return (
      <div styleName="style.room">
        <ParticipantList onClickAction={this.onClickAction} />
      </div>
    );
  }

  private onDialogEntered(...args: Parameters<NonNullable<Props['onEntered']>>): void {
    const { onEntered } = this.props;

    const connectPromise = this.context.connect();

    if (typeof onEntered === 'function') {
      connectPromise.then(() => onEntered(...args));
    }

    connectPromise.catch((error) =>
      // prettier-ignore
      this.setState(() => { throw error; })
    );
  }

  private onClickAction(actionId: string): void {
    const { disconnect, toggleAudioEnabled, toggleVideoEnabled } = this.context;

    switch (actionId) {
      case ActionId.LeaveMeeting:
        return disconnect();
      case ActionId.ToggleAudioOff:
      case ActionId.ToggleAudioOn:
        return toggleAudioEnabled();
      case ActionId.ToggleVideoOff:
      case ActionId.ToggleVideoOn:
        return toggleVideoEnabled();
      default:
        break;
    }
  }
}

export const MeetingRoomDialog = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <MeetingRoomDialogComponent innerRef={ref} {...props} />
));

MeetingRoomDialog.displayName = 'MeetingRoomDialog';
