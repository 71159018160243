import {
  Config as BaseFormConfig,
  createForm as baseCreateForm,
  FieldConfig as BaseFieldConfig,
  FieldState,
  FieldSubscriber,
  FieldSubscription,
  FieldValidator,
  FormApi as BaseFormApi,
  FormSubscriber,
  FormSubscription,
  SubmissionErrors,
  Unsubscribe
} from 'final-form';
import * as _Validator from './field-validator';
import { isDate, isNonArrayObjectLike, isNotNil, isPromiseLike } from './utils';

export * from './field.component';
export const Validator = _Validator;

export interface FormConfig<
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>,
  InitialFormValues = FormValues
> extends Omit<BaseFormConfig<FormValues, InitialFormValues>, 'onSubmit'> {
  onSubmit?: (values: FormValues) => SubmissionErrors | Promise<SubmissionErrors> | void;
}

export type FieldConstraint<
  ConstraintValue extends boolean | number | string | Date | RegExp | {},
  FieldName extends string = string,
  FieldValue = any
> =
  | ConstraintValue
  | ((
      value: FieldValue,
      allValues: Record<FieldName, any>,
      meta?: FieldState<FieldValue>
    ) => ConstraintValue | FieldValidator<FieldValue>);

export interface FieldValidityConfig<FieldName extends string = string> {
  required?: FieldConstraint<boolean | { typeOf: 'string' | 'boolean' | 'date' }>;
  length?: FieldConstraint<{
    minLength: number | ((value: any, allValues: Record<FieldName, any>, meta?: FieldState<any>) => number);
    maxLength: number | ((value: any, allValues: Record<FieldName, any>, meta?: FieldState<any>) => number);
  }>;
  minLength?: FieldConstraint<number>;
  maxLength?: FieldConstraint<number>;
  pattern?: FieldConstraint<string | RegExp>;
  range?: FieldConstraint<{
    min: number | Date | ((value: any, allValues: Record<FieldName, any>, meta?: FieldState<any>) => number | Date);
    max: number | Date | ((value: any, allValues: Record<FieldName, any>, meta?: FieldState<any>) => number | Date);
  }>;
  min?: FieldConstraint<number | Date>;
  max?: FieldConstraint<number | Date>;
}

export interface FieldConfig<FieldValue = string, FieldName extends string = string>
  extends BaseFieldConfig<FieldValue>,
    FieldValidityConfig<FieldName> {}

export type RegisterField<
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>
> = <F extends FieldName>(
  name: F,
  subscriber: FieldSubscriber<FormValues[F]>,
  subscription: FieldSubscription,
  config?: FieldConfig<FormValues[F], F>
) => Unsubscribe;

export interface FormApi<
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>,
  InitialFormValues = FormValues
> extends Omit<BaseFormApi<FormValues, FormValues>, 'registerField' | 'subscribe'> {
  subscribe: (subscriber: FormSubscriber<FormValues, InitialFormValues>, subscription: FormSubscription) => Unsubscribe;
  registerField: RegisterField<FieldName, FormValues>;
}

const NULL_VALIDATOR = Validator.nullValidator();

function getRequiredConstraintValidator(
  constraint: FieldValidityConfig['required'],
  ...args: Parameters<FieldValidator<any>>
): FieldValidator<any> {
  let constraintValue: any = constraint;

  if (typeof constraintValue === 'function') {
    constraintValue = constraintValue(...args);
  }

  if (isNonArrayObjectLike<any>(constraintValue)) {
    const { typeOf } = constraintValue;
    if (typeOf === 'boolean') return Validator.requiredTrue();
    else if (typeOf === 'date') return Validator.requiredDate();
    else if (typeOf === 'string') constraintValue = true;
  }

  if (constraintValue === true) return Validator.required();
  return typeof constraintValue === 'function' ? constraintValue : NULL_VALIDATOR;
}

function getLengthConstraintValidator<
  ConstraintType extends keyof Pick<FieldValidityConfig, 'length' | 'minLength' | 'maxLength'>
>(
  constraintType: ConstraintType,
  constraint: FieldValidityConfig[ConstraintType],
  ...args: Parameters<FieldValidator<any>>
): FieldValidator<any> {
  let constraintValue: any = constraint;

  if (typeof constraintValue === 'function') {
    constraintValue = constraintValue(...args);
  }

  if (constraintType === 'length') {
    if (isNonArrayObjectLike<any>(constraintValue)) {
      let { minLength, maxLength } = constraintValue;
      if (typeof minLength === 'function') minLength = minLength(...args);
      if (typeof maxLength === 'function') maxLength = maxLength(...args);

      if (typeof minLength === 'number') {
        return Validator.length(minLength, maxLength);
      }
    }
  } else if (constraintType === 'minLength') {
    if (typeof constraintValue === 'number') return Validator.minLength(constraintValue);
  } else if (constraintType === 'maxLength') {
    if (typeof constraintValue === 'number') return Validator.maxLength(constraintValue);
  }

  return typeof constraintValue === 'function' ? constraintValue : NULL_VALIDATOR;
}

function getPatternConstraintValidator(
  constraint: FieldValidityConfig['pattern'],
  ...args: Parameters<FieldValidator<any>>
): FieldValidator<any> {
  let constraintValue: any = constraint;

  if (typeof constraintValue === 'function') {
    constraintValue = constraintValue(...args);
  }

  if (typeof constraintValue === 'string' || constraintValue instanceof RegExp) {
    return Validator.pattern(constraintValue);
  }

  return typeof constraintValue === 'function' ? constraintValue : NULL_VALIDATOR;
}

function getRangeConstraintValidator<ConstraintType extends keyof Pick<FieldValidityConfig, 'range' | 'min' | 'max'>>(
  constraintType: ConstraintType,
  constraint: FieldValidityConfig[ConstraintType],
  ...args: Parameters<FieldValidator<any>>
): FieldValidator<any> {
  let constraintValue: any = constraint;

  if (typeof constraintValue === 'function') {
    constraintValue = constraintValue(...args);
  }

  if (constraintType === 'range') {
    if (isNonArrayObjectLike<any>(constraintValue)) {
      let { min: minValue, max: maxValue } = constraintValue;
      if (typeof minValue === 'function') minValue = minValue(...args);
      if (typeof maxValue === 'function') maxValue = maxValue(...args);

      if (/\[object (Number|Date)\]/.test(Object.prototype.toString.call(minValue))) {
        return Validator.range(minValue, maxValue);
      }
    }
  } else if (constraintType === 'min') {
    if (typeof constraintValue === 'number') return Validator.min(constraintValue);
    if (isDate(constraintValue)) return Validator.minDate(constraintValue);
  } else if (constraintType === 'max') {
    if (typeof constraintValue === 'number') return Validator.max(constraintValue);
    if (isDate(constraintValue)) return Validator.maxDate(constraintValue);
  }

  return typeof constraintValue === 'function' ? constraintValue : NULL_VALIDATOR;
}

function runCustomValidations(config: FieldValidityConfig, ...args: Parameters<FieldValidator<any>>) {
  const { required, length, minLength, maxLength, pattern, range, min, max } = config;

  let validator: FieldValidator<any>;
  let error: any;

  validator = getRequiredConstraintValidator(required, ...args);
  error = validator(...args);
  if (error != null) return error;

  if (isNotNil(length)) {
    validator = getLengthConstraintValidator('length', length, ...args);
  } else {
    validator = getLengthConstraintValidator('minLength', minLength, ...args);
    error = validator(...args);
    if (error != null) return error;

    validator = getLengthConstraintValidator('maxLength', maxLength, ...args);
  }
  error = validator(...args);
  if (error != null) return error;

  validator = getPatternConstraintValidator(pattern, ...args);
  error = validator(...args);
  if (error != null) return error;

  if (isNotNil(range)) {
    validator = getRangeConstraintValidator('range', range, ...args);
  } else {
    validator = getRangeConstraintValidator('min', min, ...args);
    error = validator(...args);
    if (error != null) return error;

    validator = getRangeConstraintValidator('max', max, ...args);
  }
  error = validator(...args);

  return error;
}

export function createForm<
  FieldName extends string = string,
  FormValues extends Record<FieldName, any> = Record<FieldName, any>,
  InitialFormValues = FormValues
>(config: FormConfig<FieldName, FormValues, InitialFormValues>): FormApi<FieldName, FormValues, InitialFormValues> {
  const { registerField, ...apiProps } = baseCreateForm<FormValues, InitialFormValues>(config as any);

  const formApi: FormApi<FieldName, FormValues, InitialFormValues> = {
    ...(apiProps as any),

    registerField: (fieldName, subscriber, subscription, config) => {
      const { getValidator, required, length, minLength, maxLength, pattern, range, min, max, ...baseConfig } =
        config || {};

      const isGetValidatorSet = typeof getValidator !== 'undefined';

      const isRequiredConstraintSet = typeof required !== 'undefined';
      const isLengthConstraintSet = typeof length !== 'undefined';
      const isMinLengthConstraintSet = typeof minLength !== 'undefined';
      const isMaxLengthConstraintSet = typeof maxLength !== 'undefined';
      const isPatternConstraintSet = typeof pattern !== 'undefined';
      const isRangeConstraintSet = typeof range !== 'undefined';
      const isMinValueConstraintSet = typeof min !== 'undefined';
      const isMaxValueConstraintSet = typeof max !== 'undefined';

      const isAnyConstraintSet =
        isRequiredConstraintSet ||
        isLengthConstraintSet ||
        isMinLengthConstraintSet ||
        isMaxLengthConstraintSet ||
        isPatternConstraintSet ||
        isRangeConstraintSet ||
        isMinValueConstraintSet ||
        isMaxValueConstraintSet;

      if (typeof baseConfig.validateFields === 'undefined') {
        baseConfig.validateFields = [];
      }

      const unsubscribe = registerField(fieldName, subscriber, subscription, {
        ...baseConfig,

        getValidator:
          !isAnyConstraintSet && !isGetValidatorSet
            ? undefined
            : () => (...args: Parameters<FieldValidator<any>>) => {
                let validationResult: any = undefined;
                if (typeof getValidator === 'function') {
                  const validator = getValidator();
                  if (typeof validator === 'function') {
                    validationResult = validator(...args);
                  }
                }

                if (isPromiseLike(validationResult)) {
                  return validationResult.then((result) =>
                    result != null ? result : runCustomValidations(config!, ...args)
                  );
                } else if (validationResult != null) {
                  return validationResult;
                }

                return runCustomValidations(config!, ...args);
              }
      });

      return unsubscribe;
    }
  };

  return formApi;
}
