import { SigmailObjectId } from '@sigmail/common';
import { EventLogRecordConsultation, UserObjectConsultationValue } from '@sigmail/objects';

type ConsultationMsgBodyValue = Omit<
  UserObjectConsultationValue['list'][0],
  'birthDate' | 'body' | 'header' | `healthPlan${'Jurisdiction' | 'Number'}`
>;

type EventLogRecordValueEConsult = EventLogRecordConsultation['value'];

export type Params = Readonly<
  [msgMetadataId: SigmailObjectId, msgBodyId: SigmailObjectId, msgBody: ConsultationMsgBodyValue]
>;

export const newEventLogRecordValueConsultation = (...params: Params): EventLogRecordValueEConsult => {
  const [msgMetadataId, msgBodyId, { consultant, diagnosticCode, referrer, serviceCode, timestamp }] = params;

  return {
    body: msgBodyId,
    consultantId: consultant.id,
    diagnosticCode,
    header: msgMetadataId,
    referrerId: referrer.id,
    serviceCode,
    timestamp
  };
};
