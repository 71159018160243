import { MessagingI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import { DialogConfirmRecallMessage } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.Confirmation.RecallMessage = {
  // Translators: (HTML) Title for the Confirm Recall Message dialog
  title: gettext('messaging', 'title', 'dialogConfirmRecallMessage', 'safe-html'),
  // Translators: (HTML) Confirmation message to be displayed in the body of \
  // Confirm Recall Message dialog
  body: ngettext('messaging', 'body', 'dialogConfirmRecallMessage', 'safe-html'),
  // Translators: (HTML) Note displayed under Confirm Recall Message dialog's \
  // body to inform that messages may not be recalled if recall window is \
  // already closed
  // Translators: Token(s) available: {{count}}
  note: ngettext('messaging', 'note', 'dialogConfirmRecallMessage', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Confirm Recall Message \
      // dialog
      label: gettext('messaging', 'reject', 'dialogConfirmRecallMessageActionLabel'),
      context: DialogConfirmRecallMessage
    },
    accept: {
      // Translators: Label for the Proceed action of Confirm Recall Message \
      // dialog
      label: gettext('messaging', 'accept', 'dialogConfirmRecallMessageActionLabel'),
      context: DialogConfirmRecallMessage
    }
  }
};

export default i18n;
