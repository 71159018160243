import type {
  AlgorithmCode,
  EncryptWithParametersAlgorithmCode,
  Nullable,
  SigmailKeyId,
  SigmailObjectId,
  SigmailObjectTypeCode
} from '@sigmail/common';
import { Utils } from '@sigmail/common';
import type { EncryptWithParametersAlgorithmParams } from '@sigmail/crypto';
import { Algorithm, Constants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import { CryptographicKey } from '.';

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER;

/** @public */
export class CryptographicKeyMaster extends CryptographicKey<JsonWebKey> {
  protected static override get DEFAULT_CODE(): EncryptWithParametersAlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS as EncryptWithParametersAlgorithmCode;
  }

  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  public static async create(
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number,
    value: JsonWebKey,
    encryptedFor: SigmailKeyId,
    params: EncryptWithParametersAlgorithmParams,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  ): Promise<CryptographicKeyMaster> {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;
    if (!Algorithm.isValidEncryptWithParametersCode(keyCode)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }

    const algorithm = getAlgorithm(keyCode);
    const encapsulatedKey = await algorithm.generateKey(params);
    const encryptedValue = await algorithm.encrypt(encapsulatedKey, value, version);

    const args = [id, keyCode, version, encryptedValue, encryptedFor, createdAtUtc, expiredAtUtc];
    return Reflect.construct(this, args) as CryptographicKeyMaster;
  }

  public async decryptedValue(params: EncryptWithParametersAlgorithmParams): Promise<JsonWebKey> {
    if (!Algorithm.isValidEncryptWithParametersCode(this.code)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }

    const algorithm = getAlgorithm(this.code);
    const encapsulatedKey = await algorithm.generateKey(params);

    return algorithm.decrypt(encapsulatedKey, this.value, this.version) as JsonWebKey;
  }
}
