import { Constants, NonNullableProps, Nullable, SigmailClientId, SigmailGroupId, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { useAppState, useDispatchFetchUserObjects } from '.';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { tokenExpiryToDays } from '../../utils/accuro-emr';
import { accuroEMRSearchPatient } from '../actions/EMR/accuro/search-patient';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { activeGroupIdSelector } from '../selectors';
import { preferencesObjectSelector as groupPreferencesSelector } from '../selectors/group-object';
import { clientIdSelector } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';

export interface Params {
  readonly healthPlanNumber?: Nullable<string>;
}

const HEALTH_PLAN_JURISDICTION_LIST = Object.values(Constants.HealthPlanJurisdiction);
const cancelable = Utils.makeCancelablePromise;

export type Resource = ISuspenseResource<ResourceValue>;

export interface ResourceValue {
  readonly auth: Readonly<{
    requestKey: number;
    config: Nullable<Api.AccuroEMROAuthParams>;
    recheckAuth: () => void;
    status: boolean;
    url: string;
  }>;
  readonly list: Api.AccuroEMRSearchPatientResponse;
}

interface State {
  readonly authRequestKey: ResourceValue['auth']['requestKey'];
  readonly authStatus: ResourceValue['auth']['status'];
  readonly authUrl: ResourceValue['auth']['url'];
  readonly resourceValue?: ResourceValue['list'];
}

interface Refs extends Readonly<Partial<NonNullableProps<Params>>> {
  readonly authCheckCompleteForClientId?: SigmailClientId;
  readonly authRequestKey?: number;
  readonly groupId?: SigmailGroupId;
}

const DEFAULT_RESOURCE_VALUE: NonNullable<State['resourceValue']> = EMPTY_ARRAY;
// const INITIAL_STATE: State = { authRequestKey: 1, authStatus: false, authUrl: '' };

// ==
// GK@20230223: As per new business requirements, auth status has to be assumed
// as always authenticated.
const INITIAL_STATE: State = { authRequestKey: 1, authStatus: true, authUrl: '' };
// ==

export const useSearchAccuroEMRPatientListResource = (params?: Nullable<Params> | false): Resource => {
  const noop = !Utils.isNonArrayObjectLike<Params>(params);
  const healthPlanNumber = Utils.stringOrDefault(!noop && params.healthPlanNumber).replaceAll(/\D/g, '');

  const groupId = useSelector(activeGroupIdSelector);
  const groupPrefsObject = useSelector(groupPreferencesSelector)(Utils.numberOrDefault(!noop && groupId));
  const groupPrefs = UserObjectCache.getValue(groupPrefsObject);
  const isGroupPrefsAvailable = Utils.isNotNil(groupPrefsObject) && Utils.isNotNil(groupPrefs);
  const emrConfig = groupPrefs?.integrations?.accuroEMR!;
  const oauthParams = React.useMemo<Api.AccuroEMROAuthParams | null>(() => {
    if (Utils.isNonArrayObjectLike(emrConfig) && Utils.isNonArrayObjectLike(emrConfig.oauthParams)) {
      const { uriBase: baseUri, tokenExpiry, ...authParams } = emrConfig.oauthParams!;
      return { baseUri, tokenExpiry: tokenExpiryToDays(tokenExpiry), ...authParams };
    }
    return null;
  }, [emrConfig]);

  const clientId = useSelector(clientIdSelector)!;
  const [, appDispatch] = useAppState();
  const fetchObjects = useDispatchFetchUserObjects();

  const [{ authRequestKey, authStatus, authUrl, resourceValue }, setState] = React.useState(INITIAL_STATE);
  const refs = React.useRef<Refs>(EMPTY_PLAIN_OBJECT);

  React.useEffect(() => {
    if (noop) {
      refs.current = EMPTY_PLAIN_OBJECT;
      return setState(INITIAL_STATE);
    }

    if (refs.current.groupId !== groupId) {
      refs.current = { groupId };

      if (!isGroupPrefsAvailable) {
        const promise = cancelable(
          fetchObjects({
            userObjects: [{ type: process.env.GROUP_OBJECT_TYPE_PREFERENCES, userId: groupId }]
          })
        );
        promise.promise.catch(Utils.noop);
        return () => promise.cancel();
      }
    }

    const isEMRConfigAvailable = Utils.isNotNil(oauthParams);
    if (!isEMRConfigAvailable) {
      return setState({ ...INITIAL_STATE, resourceValue: DEFAULT_RESOURCE_VALUE });
    }

    // ==
    // GK@20230223: The following code has been disabled because as per new
    // business requirements, auth status has to be assumed as always
    // authenticated.
    // ==
    // const clientIdChanged = refs.current.authCheckCompleteForClientId !== clientId;
    // if (clientIdChanged || refs.current.authRequestKey !== authRequestKey) {
    //   let requestKey = clientIdChanged ? 1 : authRequestKey;
    //   refs.current = { ...refs.current, authCheckCompleteForClientId: clientId, authRequestKey: requestKey };
    //   setState({ ...INITIAL_STATE, authRequestKey: requestKey });

    //   const checkAuthStatus = async (): Promise<void> => {
    //     let isAuthenticated = false;
    //     let url = '';

    //     try {
    //       isAuthenticated = await appDispatch(accuroEMRCheckAuthStatus({ clientId }));
    //       if (!isAuthenticated) {
    //         url = await appDispatch(accuroEMRGetOAuthUrl({ oauthParams, clientId }));
    //       }
    //     } catch {
    //       /* ignore */
    //     }

    //     let resourceValue: State['resourceValue'];
    //     if (!isAuthenticated) {
    //       resourceValue = DEFAULT_RESOURCE_VALUE;
    //     }

    //     setState((state) => ({ ...state, authStatus: isAuthenticated, authUrl: url, resourceValue }));
    //   };

    //   const promise = cancelable(checkAuthStatus());
    //   promise.promise.catch(Utils.noop);
    //   return () => promise.cancel();
    // }
    // ==

    if (authStatus && refs.current.healthPlanNumber !== healthPlanNumber) {
      refs.current = { ...refs.current, healthPlanNumber };

      if (healthPlanNumber.length === 0) {
        return setState((state) => ({ ...state, resourceValue: DEFAULT_RESOURCE_VALUE }));
      }

      const searchPatientList = (patientList: Api.AccuroEMRSearchPatientResponse): void => {
        try {
          const list: Array<typeof patientList[0]> = [];
          for (const record of patientList) {
            try {
              if (Utils.isNil(record.demographics)) continue;
              if (!Utils.isInteger(record.patientId)) continue;

              const { healthCard } = record.demographics;
              if (!healthCard?.phn?.startsWith(healthPlanNumber)) continue;

              let jurisdiction: Nullable<string> = Utils.trimOrDefault(healthCard.location);
              if (jurisdiction.length === 0) {
                jurisdiction = null;
              } else {
                const match = HEALTH_PLAN_JURISDICTION_LIST.find((item) => {
                  const [, code] = item.split('$').map((value) => value.trim());
                  return jurisdiction === Utils.stringOrDefault(Utils.isString(code) && code.length > 0 && code, item);
                });
                if (Utils.isString(match)) jurisdiction = match;
              }

              let birthday: Nullable<string> = Utils.trimOrDefault(record.demographics.birthday);
              const dt = Utils.dateOrDefault(birthday.length > 0 && new Date(birthday), null!);
              if (!Utils.isValidDate(dt)) birthday = null;

              list.push({
                ...record,
                demographics: {
                  ...record.demographics,
                  birthday,
                  healthCard: { ...healthCard, location: jurisdiction }
                }
              });
            } catch {
              /* ignore */
            }
          }
          setState((state) => ({ ...state, resourceValue: list }));
        } catch {
          setState((state) => ({ ...state, resourceValue: DEFAULT_RESOURCE_VALUE }));
        }
      };

      const promise = cancelable(
        appDispatch(accuroEMRSearchPatient({ clientId, hpn: healthPlanNumber })).catch(() => DEFAULT_RESOURCE_VALUE)
      );

      promise.promise.then(searchPatientList, Utils.noop);
      return () => promise.cancel();
    }
  }, [
    appDispatch,
    authRequestKey,
    authStatus,
    clientId,
    fetchObjects,
    groupId,
    healthPlanNumber,
    isGroupPrefsAvailable,
    noop,
    oauthParams
  ]);

  return React.useMemo<Resource>(() => {
    if (Utils.isNil(resourceValue)) return UNRESOLVED_RESOURCE;

    let recheckAuth: ResourceValue['auth']['recheckAuth'] = Utils.noop;
    if (!authStatus) {
      recheckAuth = () => {
        setState(({ authRequestKey, resourceValue, ...state }) => ({
          ...state,
          authRequestKey: authRequestKey + 1
        }));
      };
    }

    return {
      value: (): ResourceValue => ({
        auth: {
          config: oauthParams,
          requestKey: authRequestKey,
          recheckAuth,
          status: authStatus,
          url: authUrl
        },
        list: resourceValue
      })
    };
  }, [authRequestKey, authStatus, authUrl, oauthParams, resourceValue]);
};
