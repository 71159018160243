import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import type { Api, Http } from '../types';
import { URL_ENTER_STATE as API_URL } from './constants';
import { MalformedResponseException } from './malformed-response-exception';
import { ServiceException as ApiServiceException } from './service-exception';

const ERROR_RESPONSE_BAD_REQUEST = new Response(JSON.stringify({ message: 'Bad request.' }), {
  status: 400,
  statusText: 'Bad Request',
  headers: { 'Content-Type': 'application/json' }
});

const REGEX_VALID_ID_TOKEN = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

const Logger = getLoggerWithPrefix('ApiService', 'apiGetIDsByUsage:');

export async function apiGetIDsByUsage(
  this: Api.Service,
  accessToken: string,
  query: Api.GetIdsRequestData,
  init?: Http.RequestInit
): Promise<Api.GetIdsResponseData> {
  try {
    Logger.info('== BEGIN ==');
    if (!isValidRequestBody(query)) {
      throw new ApiServiceException(ERROR_RESPONSE_BAD_REQUEST);
    }

    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.GetIdsResponseData>(requestUrl, JSON.stringify(query), {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status === 200) {
          const json = await Utils.tryGetResponseJson<Api.EnterStateResponseData>(response, undefined);
          if (isValidSuccessResponse(json)) {
            let idsClaimIndex = -1;
            const idsClaimDataList = json.claims
              .map((claim, index) => {
                const claimData = Utils.decodeIdToken(claim);

                const responseHasUsagesList = Utils.isNonEmptyArray(claimData['usages']);
                const responseHasIdsList = Utils.isNonEmptyArray(claimData['ids']);
                if (responseHasUsagesList || responseHasIdsList) {
                  idsClaimIndex = index;

                  const requestHasUsagesList = Utils.isNonEmptyArray(query.ids.usages);
                  const requestHasIdsList = Utils.isNonEmptyArray(query.ids.ids);
                  if ((requestHasUsagesList && !responseHasUsagesList) || (requestHasIdsList && !responseHasIdsList)) {
                    throw new MalformedResponseException(response);
                  }

                  const ids: Record<string | number, Array<number>> = {};
                  if (requestHasUsagesList) {
                    const isValidEntryData = (entry: unknown): entry is { id: number; usage: string } =>
                      Utils.isNonArrayObjectLike<{ id: number; usage: string }>(entry) &&
                      Utils.isString(entry.usage) &&
                      Utils.isNumber(entry.id);

                    Utils.transform(
                      claimData['usages'] as ReadonlyArray<unknown>,
                      (obj, entry) => {
                        if (
                          isValidEntryData(entry) &&
                          // prettier-ignore
                          query.ids.usages!.some((item) => Utils.isNonArrayObjectLike(item) && item.usage === entry.usage) &&
                          !Utils.isArray(obj[entry.usage])
                        ) {
                          obj[entry.usage] = [entry.id];
                          return;
                        }

                        throw new MalformedResponseException(response);
                      },
                      ids
                    );
                  }

                  if (requestHasIdsList) {
                    const isValidEntryData = (entry: unknown): entry is { id: Array<number>; type: number } =>
                      Utils.isNonArrayObjectLike<{ id: Array<number>; type: number }>(entry) &&
                      Utils.isInteger(entry.type) &&
                      Utils.isArray(entry.id);

                    Utils.transform(
                      claimData['ids'] as Array<unknown>,
                      (obj, entry) => {
                        if (isValidEntryData(entry)) {
                          // prettier-ignore
                          const item = query.ids.ids!.find((item) => Utils.isNonArrayObjectLike(item) && item.type === entry.type);
                          if (Utils.isNonArrayObjectLike<NonNullable<typeof item>>(item)) {
                            const count = !Utils.isInteger(item.count) || item.count === 0 ? 1 : item.count;
                            if (entry.id.length === count && !Utils.isArray(obj[entry.type])) {
                              // eslint-disable-next-line no-param-reassign
                              obj[entry.type] = entry.id;
                              return;
                            }
                          }
                        }

                        throw new MalformedResponseException(response);
                      },
                      ids
                    );
                  }

                  return ids as Api.GetIdsResponseData['ids'];
                }

                return undefined;
              })
              .filter(Utils.isNotNil);

            if (idsClaimDataList.length === 1 && idsClaimIndex >= 0 && idsClaimIndex <= json.claims.length) {
              // eslint-disable-next-line no-shadow
              const response: Api.GetIdsResponseData = {
                ...json,
                idsClaim: json.claims[idsClaimIndex]!,
                ids: idsClaimDataList[0]!
              };
              return response;
            }
          }
          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status >= 400 && response.status < 500) {
          const responseBody = await response.clone().text();
          throw new ApiServiceException(response, responseBody);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}

function isValidIdToken(value: unknown): value is string {
  return Utils.isString(value) && REGEX_VALID_ID_TOKEN.test(value);
}

function isValidRequestBody(query: unknown): query is Api.GetIdsRequestData {
  const isValidUsagesEntry = (entry: unknown): boolean =>
    Utils.isNonArrayObjectLike<Record<'usage', unknown>>(entry) && Utils.isString(entry.usage);

  const isValidIdsEntry = (entry: unknown): boolean =>
    Utils.isNonArrayObjectLike<Record<'count' | 'type', unknown>>(entry) &&
    Utils.isInteger(entry.type) &&
    (Utils.isUndefined(entry.count) || (Utils.isInteger(entry.count) && entry.count > 0));

  let isValid =
    Utils.isNonArrayObjectLike<Api.GetIdsRequestData>(query) &&
    Utils.isValidJwtToken(query.authState, 'id') &&
    (Utils.isUndefined(query.claims) || (Utils.isArray(query.claims) && query.claims.every(isValidIdToken))) &&
    Utils.trimOrDefault(query.state).length > 0;

  if (isValid) {
    const { ids } = query as Api.GetIdsRequestData;
    if (isValid && Utils.isNonArrayObjectLike(ids)) {
      const hasUsages = !Utils.isUndefined(ids.usages);
      const hasIds = !Utils.isUndefined(ids.ids);

      isValid = hasUsages || hasIds;
      if (isValid) {
        isValid = !hasUsages || (Utils.isNonEmptyArray(ids.usages) && ids.usages.every(isValidUsagesEntry));
        isValid = isValid && (!hasIds || (Utils.isNonEmptyArray(ids.ids) && ids.ids.every(isValidIdsEntry)));
      }
    }
  }

  return isValid;
}

function isValidSuccessResponse(json: unknown): json is Api.EnterStateResponseData {
  return (
    Utils.isNonArrayObjectLike<Api.EnterStateResponseData>(json) &&
    Utils.every(['authState', 'claims'], Utils.partial(Utils.has, json)) &&
    Utils.isValidJwtToken(json.authState, 'id') &&
    Utils.isNonEmptyArray(json.claims) &&
    json.claims.every(isValidIdToken)
  );
}
