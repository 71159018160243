import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.Form.CopdAssessment = {
  fieldsetActivities: {
    formField: {
      activities: {
        error: {
          valueMissing: gettext('health-data', 'activitiesRequired', 'copdAssessmentFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'not', 'copdAssessmentFormActivitiesOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'verySlightly', 'copdAssessmentFormActivitiesOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'slightly', 'copdAssessmentFormActivitiesOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'moderately', 'copdAssessmentFormActivitiesOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'very', 'copdAssessmentFormActivitiesOptionLabel')
          },
          {
            codedValue: 5,
            label: gettext('health-data', 'extremely', 'copdAssessmentFormActivitiesOptionLabel')
          }
        ]
      }
    },
    label: gettext('health-data', 'activities', 'copdAssessmentFormFieldsetLabel')
  },
  fieldsetChestTightness: {
    formField: {
      chestTightness: {
        error: {
          valueMissing: gettext('health-data', 'chestTightnessRequired', 'copdAssessmentFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'no', 'copdAssessmentFormChestTightnessOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'verySlightly', 'copdAssessmentFormChestTightnessOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'slightly', 'copdAssessmentFormChestTightnessOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'moderately', 'copdAssessmentFormChestTightnessOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'very', 'copdAssessmentFormChestTightnessOptionLabel')
          },
          {
            codedValue: 5,
            label: gettext('health-data', 'extremely', 'copdAssessmentFormChestTightnessOptionLabel')
          }
        ]
      }
    },
    label: gettext('health-data', 'chestTightness', 'copdAssessmentFormFieldsetLabel')
  },
  fieldsetConfidence: {
    formField: {
      confidence: {
        error: {
          valueMissing: gettext('health-data', 'confidenceRequired', 'copdAssessmentFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'confident', 'copdAssessmentFormConfidenceOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'very', 'copdAssessmentFormConfidenceOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'slightly', 'copdAssessmentFormConfidenceOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'moderately', 'copdAssessmentFormConfidenceOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'verySlightly', 'copdAssessmentFormConfidenceOptionLabel')
          },
          {
            codedValue: 5,
            label: gettext('health-data', 'notAtAll', 'copdAssessmentFormConfidenceOptionLabel')
          }
        ]
      }
    },
    label: gettext('health-data', 'confidence', 'copdAssessmentFormFieldsetLabel')
  },
  fieldsetCough: {
    formField: {
      cough: {
        error: {
          valueMissing: gettext('health-data', 'coughRequired', 'copdAssessmentFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'never', 'copdAssessmentFormCoughOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'hardlyEver', 'copdAssessmentFormCoughOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'fewTimes', 'copdAssessmentFormCoughOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'severalTimes', 'copdAssessmentFormCoughOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'manyTimes', 'copdAssessmentFormCoughOptionLabel')
          },
          {
            codedValue: 5,
            label: gettext('health-data', 'allTime', 'copdAssessmentFormCoughOptionLabel')
          }
        ]
      }
    },
    label: gettext('health-data', 'cough', 'copdAssessmentFormFieldsetLabel')
  },
  fieldsetDyspnea: {
    formField: {
      dyspnea: {
        error: {
          valueMissing: gettext('health-data', 'dyspneaRequired', 'copdAssessmentFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'never', 'copdAssessmentFormDyspneaOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'hardlyEver', 'copdAssessmentFormDyspneaOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'fewTimes', 'copdAssessmentFormDyspneaOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'severalTimes', 'copdAssessmentFormDyspneaOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'manyTimes', 'copdAssessmentFormDyspneaOptionLabel')
          },
          {
            codedValue: 5,
            label: gettext('health-data', 'allTime', 'copdAssessmentFormDyspneaOptionLabel')
          }
        ]
      }
    },
    label: gettext('health-data', 'dyspnea', 'copdAssessmentFormFieldsetLabel')
  },
  fieldsetEnergy: {
    formField: {
      energy: {
        error: {
          valueMissing: gettext('health-data', 'energyRequired', 'copdAssessmentFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'lots', 'copdAssessmentFormEnergyOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'veryHigh', 'copdAssessmentFormEnergyOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'high', 'copdAssessmentFormEnergyOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'moderate', 'copdAssessmentFormEnergyOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'low', 'copdAssessmentFormEnergyOptionLabel')
          },
          {
            codedValue: 5,
            label: gettext('health-data', 'notAtAll', 'copdAssessmentFormEnergyOptionLabel')
          }
        ]
      }
    },
    label: gettext('health-data', 'energy', 'copdAssessmentFormFieldsetLabel')
  },
  fieldsetSleep: {
    formField: {
      sleep: {
        error: {
          valueMissing: gettext('health-data', 'sleepRequired', 'copdAssessmentFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'extremelyWell', 'copdAssessmentFormSleepOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'veryWell', 'copdAssessmentFormSleepOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'moderatelyWell', 'copdAssessmentFormSleepOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'slightlyWell', 'copdAssessmentFormSleepOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'verySlightlyWell', 'copdAssessmentFormSleepOptionLabel')
          },
          {
            codedValue: 5,
            label: gettext('health-data', 'notWellAtAll', 'copdAssessmentFormSleepOptionLabel')
          }
        ]
      }
    },
    label: gettext('health-data', 'sleep', 'copdAssessmentFormFieldsetLabel')
  },
  fieldsetSputum: {
    formField: {
      sputum: {
        error: {
          valueMissing: gettext('health-data', 'sputumRequired', 'copdAssessmentFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'notAttAll', 'copdAssessmentFormSputumOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'hardlyEver', 'copdAssessmentFormSputumOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'fewTimes', 'copdAssessmentFormSputumOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'severalTimes', 'copdAssessmentFormSputumOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'manyTimes', 'copdAssessmentFormSputumOptionLabel')
          },
          {
            codedValue: 5,
            label: gettext('health-data', 'completelyFull', 'copdAssessmentFormSputumOptionLabel')
          }
        ]
      }
    },
    label: gettext('health-data', 'sputum', 'copdAssessmentFormFieldsetLabel')
  }
};

export default i18n;
