import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '../../..';
import { accessTokenSelector } from '../../../selectors/auth';

export const oscarEMRSearchPatient = (
  payload: ApiActionPayload.OscarEMRSearchPatient
): AppThunk<Promise<Api.OscarEMRSearchPatientResponse>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'oscarEMRSearchPatient:');

    Logger.info('== BEGIN ==');
    try {
      let { accessToken, oauthParams, ...query } = payload;
      accessToken = Utils.stringOrDefault(accessToken, accessTokenSelector(getState()));
      return await apiService.oscarEMRSearchPatient(accessToken, { ...query, oscar: oauthParams });
    } finally {
      Logger.info('== END ==');
    }
  };
};
