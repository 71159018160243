import React from 'react';

export const useAppOfflineQuery = (): boolean => {
  const [isAppOffline, setAppOffline] = React.useState(false);

  React.useEffect(() => {
    const offlineListener = () => {
      window.addEventListener('online', () => setAppOffline(false), {
        once: true
      });

      setAppOffline(true);
    };

    window.addEventListener('offline', offlineListener);
    return () => window.removeEventListener('offline', offlineListener);
  }, []);

  return isAppOffline;
};
