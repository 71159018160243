import sanitizeHtml, { IOptions } from 'sanitize-html';

////////////////////////////////////////////////////////////////////////////////
//                              !!! STOP !!!
////////////////////////////////////////////////////////////////////////////////
// DO NOT CHANGE ANY OF THESE VALUES UNLESS YOU'RE ABSOLUTELY SURE OF WHAT
// YOU"RE DOING!!!
////////////////////////////////////////////////////////////////////////////////
export const DEFAULT_ACCESS_CODE_DECLINE_INVITATION = 'DECLINE';
export const DEFAULT_ACCESS_CODE_CLIENT_REGISTRATION = 'WELCOME';
export const DEFAULT_ACCESS_CODE_MEMBER_REGISTRATION = 'WELCOME';
export const DEFAULT_ACCESS_CODE_JOIN_GROUP_INVITATION = 'WELCOME';
export const DEFAULT_ACCESS_CODE_PASSWORD_RESET = 'PWD_RESET';
export const DEFAULT_ACCESS_CODE_PASSWORD_RESET_TOKEN = 'RESET_PWD';
export const DEFAULT_PASSWORD_MFA_LOGIN_CREDENTIAL = 'MFA_LOGIN_AUTH';
export const GROUP_MESSAGE_FOLDER_PREFIX = '$group_';
export const USER_DEFINED_MESSAGE_FOLDER_PREFIX = '$u_';
export const DEFAULT_HEALTH_PLAN_JURISDICTION = 'CAN$ON';
////////////////////////////////////////////////////////////////////////////////

export const DEFAULT_GROUP_NAME = 'Clinic';
export const MAX_MESSAGE_FOLDER_ITEM_COUNT = process.env.REACT_APP_ENV === 'local' ? 10 : 1000;

const REGEX_CSS_COLOR_HEX = /^#(0x)?[0-9a-f]+$/i;
const REGEX_CSS_COLOR_RGB = /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
const REGEX_CSS_MARGIN_PADDING = /^(\s*(\d*(\.\d+)?(em|rem|px)|auto)){1,4}$/;

export const SANITIZER_OPTIONS: IOptions = {
  allowedTags: Array.from(new Set([...sanitizeHtml.defaults.allowedTags, 'u', 'sub', 'sup', 'span', 'hr', 'tfoot'])),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    '*': ['aria-hidden', 'style'],
    a: ['href', 'name', 'rel', 'target'],
    td: ['colspan', 'rowspan'],
    th: ['colspan', 'rowspan', 'scope']
  },
  allowedClasses: {
    p: [
      'print-only',
      'ql-align-center',
      'ql-align-right',
      'ql-align-justify',
      'ql-indent-1',
      'ql-indent-2',
      'ql-indent-3',
      'ql-indent-4',
      'ql-indent-5',
      'ql-indent-6',
      'ql-indent-7',
      'ql-indent-8'
    ],
    table: [
      'data-form-bpReading',
      'data-form-cardiacIntake',
      'data-form-copdAssessment',
      'data-form-kccq',
      'data-form-vitals',
      'data-form-kccq-score'
    ],
    tr: ['indent-1'],
    th: ['indent-2'],
    td: ['lowNormal', 'normal', 'highNormal', 'hypertension1', 'hypertension2', 'hypertension3', 'low', 'medium', 'high', 'veryHigh'],
    tfoot: ['fair', 'good', 'poor']
  },
  allowedStyles: {
    '*': {
      // Match HEX and RGB
      'background-color': [REGEX_CSS_COLOR_HEX, REGEX_CSS_COLOR_RGB],
      'border-collapse': [/^collapse$/, /^separate$/],
      // Match HEX and RGB
      'border-color': [REGEX_CSS_COLOR_HEX, REGEX_CSS_COLOR_RGB],
      'border-style': [/^none$/, /^hidden$/, /^dotted$/, /^dashed$/, /^solid$/, /^double$/, /^groove$/, /^ridge$/, /^inset$/, /^outset$/],
      // Match any number with px
      'border-width': [/^\d+(?:px)$/],
      // Match HEX and RGB
      color: [REGEX_CSS_COLOR_HEX, REGEX_CSS_COLOR_RGB],
      // Match any number with px, em, or %
      'font-size': [/^\d+(?:px|em|%)$/],
      // Match 100 to 900, "normal", "bold", "lighter", "bolder"
      'font-weight': [/^[1-9]00$/, /^normal$/, /^bold$/, /^lighter$/, /^bolder$/],
      margin: [REGEX_CSS_MARGIN_PADDING],
      padding: [REGEX_CSS_MARGIN_PADDING],
      'text-align': [/^left$/, /^right$/, /^center$/]
    }
  }
};
