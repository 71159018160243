import type { EncapsulatedKey } from '@sigmail/common';
import { Algorithm } from '.';
import * as Encoder from '../encoder';
import { AES_GCM } from '../encryptor/symmetric/AES_GCM';
import { MGFV } from '../key-derivation-function/MGFV';
import type { EncryptObjectAlgorithm, SymmetricEncryptor, SymmetricKey } from '../types';

/**
 * Define the algorithm EncryptObjectAlgorithm. This is used to encrypt normal
 * data objects (DataObjects, UserObjects, Notification)
 *
 * - An encapsulated key (EK, 800 bits) is created for each object
 * - An AES key (AK, 256 bits), initialization vector (IV, 128 bits), and
 *   additional authentication data (AAD, 128 bits) are derived from the EK and
 *   the version number of the object
 * - These three parameters are used to encrypt the object using AES in GCM mode
 *
 * @author Kim Birchard <kbirchard@sigmahealthtech.com>
 * @public
 */
export class EncryptObjectAlgorithmImpl
  extends Algorithm<SymmetricEncryptor, unknown>
  implements EncryptObjectAlgorithm
{
  public constructor() {
    super(
      'EncryptObjectAlgorithm',
      // create an instance of AES_GCM, with required parameters and key
      // derivation primitives
      //
      // create instances of MGFV for deriving AES key, IV, and AAD
      //   - each uses a different initialCounter, so the derived values
      //     are unrelated
      //   - each of these values must not overlap
      //   - they are from the value of pi, starting at digit 5001 after the
      //     decimal point, 5695 1623 9658 64573021631...
      new AES_GCM({
        encapsulatedKeyLength: 800,
        tagLength: 128,
        keyDf: new MGFV({ initialCounter: 5695, outLength: 256 }),
        ivDf: new MGFV({ initialCounter: 1623, outLength: 128 }),
        adDf: new MGFV({ initialCounter: 9658, outLength: 128 })
      })
    );
  }

  public async decrypt(encapsulatedKey: EncapsulatedKey, data: string, version: number): Promise<unknown> {
    const key = await this.deriveKey(encapsulatedKey, version);
    const encryptedValue = Encoder.Base64.decode(data);
    const decryptedValue = await this.encryptor.decrypt(key, encryptedValue);
    return JSON.parse(Encoder.UTF8.decode(decryptedValue)) as unknown;
  }

  public deriveKey(encapsulatedKey: EncapsulatedKey, version: number): Promise<SymmetricKey> {
    return this.encryptor.deriveKey(encapsulatedKey, version);
  }

  public async encrypt(encapsulatedKey: EncapsulatedKey, data: unknown, version: number): Promise<string> {
    const key = await this.deriveKey(encapsulatedKey, version);
    const decryptedValue = Encoder.UTF8.encode(JSON.stringify(data));
    const encryptedValue = await this.encryptor.encrypt(key, decryptedValue);
    return Encoder.Base64.encode(encryptedValue);
  }

  public generateKey(): Promise<EncapsulatedKey> {
    return this.encryptor.generateKey();
  }
}
