import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PartialRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n } from '@sigmail/i18n';
import { startOfDay } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import { FieldState } from 'final-form';
import { TOptions } from 'i18next';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { store } from '../../../app-state';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import { FrenchCanada } from '../../../constants/language-codes';
import { ContactListResource } from '../../../hooks';
import { withTranslation } from '../../../i18n';
import { I18N_NS_MESSAGING } from '../../../i18n/config/namespace-identifiers';
import messagingI18n from '../../../i18n/messaging';
import { FieldConfig } from '../../shared/form';
import {
  AutocompleteContactListField,
  AutoCompleteContactListFieldProps as BaseAutocompleteContactListFieldProps
} from '../../shared/form/autocomplete-contact-list-field.component';
import * as FieldValidators from '../../shared/form/field-validator';
import { ERROR_RESULT_VALUE_MISSING } from '../../shared/form/field-validator';
import {
  DatePickerFieldProps,
  FormComponent,
  FormComponentProps,
  FormComponentState
} from '../../shared/form/form.component';
import style from './accept-referral-form.module.css';

const { form: i18n } = messagingI18n.dialog.referral.accept;

type ContactListOption = ReturnType<ContactListResource['value']>[0];

export type FieldsetKey = keyof typeof i18n;
export type FieldNameConsultDateFieldset = keyof typeof i18n.fieldsetConsultDate.formField;
export type FieldNameGroupFieldset = keyof typeof i18n.fieldsetGroup.formField;
export type FieldName = FieldNameConsultDateFieldset | FieldNameGroupFieldset;
export type FormValues = Record<FieldNameConsultDateFieldset, Date | null> &
  Record<FieldNameGroupFieldset, ContactListOption>;

const FIELD_SET_LIST: ReadonlyArray<Readonly<[FieldsetKey, ReadonlyArray<FieldName>]>> = [
  ['fieldsetGroup', ['group']],
  ['fieldsetConsultDate', ['consultDate']]
];

interface AutocompleteContactListFieldProps
  extends Omit<BaseAutocompleteContactListFieldProps<false, true>, 'inputState'> {
  config?: FieldConfig<ContactListOption> | undefined;
  render?: ((props: BaseAutocompleteContactListFieldProps<false, true>) => React.ReactNode) | undefined;
}

const FIELD_PROPS: Record<FieldNameConsultDateFieldset, DatePickerFieldProps> &
  Record<FieldNameGroupFieldset, AutocompleteContactListFieldProps> = {
  consultDate: {
    autoComplete: 'off',
    disableFuture: false,
    disablePast: true,
    label: i18n.fieldsetConsultDate.formField.consultDate.label,
    placeholder: i18n.fieldsetConsultDate.formField.consultDate.placeholder,
    config: {
      min: startOfDay(Date.now())
    }
  },
  group: {
    classes: {
      option: style['recipient-list-option'],
      tag: style['recipient-list-tag']
    },
    disableClearable: true,
    disablePortal: false,
    includeInputInList: false,
    label: i18n.fieldsetGroup.formField.group.label,
    ListboxProps: {
      style: { maxHeight: '30vh' }
    },
    multiple: false,
    openOnFocus: false,
    options: EMPTY_ARRAY,
    placeholder: i18n.fieldsetGroup.formField.group.placeholder,
    config: {
      required: () => (value) => (value !== null ? null : ERROR_RESULT_VALUE_MISSING)
    }
  }
};

export type AcceptReferralFormClassKey = 'inputRoot';

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<AcceptReferralFormClassKey, string> | undefined;
  contactListResource: ContactListResource;
}

interface ComponentProps extends Props, WithTranslation {}
interface State extends FormComponentState<FieldName, FormValues> {}

class AcceptReferralFormComponent extends FormComponent<
  FieldsetKey,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes',
      'contactListResource'
    );

    let group: FormValues['group'] = null!;
    if (this.isFieldIncluded('group')) {
      const contactList = this.props.contactListResource.value();
      const { activeGroup: activeGroupId } = store.getState();
      const index = contactList.findIndex((contact) => contact.type === 'group' && contact.id === activeGroupId);
      if (index > -1) group = contactList[index]!;
    }

    this.createForm({ initialValues: { consultDate: null, group } });

    this.onChangeGroup = this.onChangeGroup.bind(this);
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-accept-referral-date-${this.formIdSuffix}`;
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldType(fieldName: FieldName, ...args: any[]) {
    return fieldName === 'consultDate' ? 'date' : super.getFieldType(fieldName, ...args);
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { contactListResource: contactList, t } = this.props;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    if (fieldName === 'group') {
      const { [fieldName]: fieldI18n } = i18n.fieldsetGroup.formField;

      const autocompleteFieldProps: AutocompleteContactListFieldProps = {
        closeText: t(fieldI18n.localeText.closePopup),
        inputProps: {
          ...fieldProps.inputProps,
          'aria-label': t(fieldI18n.label)
        },
        noOptionsText: t(fieldI18n.localeText.noOptions),
        onChange: this.onChangeGroup,
        openText: t(fieldI18n.localeText.openPopup),
        options: contactList.value(),
        render: this.renderGroupField
      };

      fieldProps = { ...fieldProps, ...autocompleteFieldProps };
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;
    const { language: locale } = this.props.i18n;

    let errorMessageI18n: string | undefined = undefined;
    if (Utils.isNonArrayObjectLike<FieldValidators.ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    if (!Utils.isString(errorMessageI18n)) return error;

    let tOptions: TOptions = undefined!;
    if (error.key === FieldValidators.VALIDATION_ERROR_TOO_SHORT && fieldName === 'consultDate') {
      tOptions = {
        MIN_DATE: Utils.DATE_FORMAT_FULL_NO_TIME(locale).format(error[error.key].min)
      };
    }

    return t(errorMessageI18n, tOptions);
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]) {
    const { classes } = this.props;
    const { language: locale } = this.props.i18n;

    let fieldNode = super.renderField(fieldName, ...args);
    if (Utils.isNil(fieldNode) || fieldNode === false) return null;

    if (fieldName === 'consultDate') {
      fieldNode = (
        <MuiPickersUtilsProvider key={fieldName} locale={locale === FrenchCanada ? frCA : enCA} utils={DateFnsUtils}>
          {fieldNode}
        </MuiPickersUtilsProvider>
      );
    }

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  private renderGroupField(fieldProps: BaseAutocompleteContactListFieldProps<false, true>): React.ReactNode {
    return <AutocompleteContactListField {...fieldProps} />;
  }

  private onChangeGroup(...args: Parameters<NonNullable<AutocompleteContactListFieldProps['onChange']>>): void {
    const [, value] = args;
    this.form.change('group', value);
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<any>) {
    let { error } = fieldState;

    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    return super.setFieldState(fieldName, { ...fieldState, error });
  }
}

const ns = [I18N_NS_MESSAGING];

export const AcceptReferralForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['form-input-group']
    } as ComponentProps['classes']);

    return <AcceptReferralFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

AcceptReferralForm.displayName = 'AcceptReferralForm';
AcceptReferralForm.defaultProps = { className: style.form };
