import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Form.SendGuestContactInvitation = {
  fieldsetBirthDateGender: {
    formField: {
      birthDate: {
        // Translators: Label for the Birth Date input
        label: gettext('account', 'birthDate', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Birth Date is left blank
          valueMissing: gettext('account', 'birthDateRequired', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when an invalid Birth Date \
          // is entered e.x. 2021-02-31
          badInput: gettext('account', 'birthDateBadInput', 'sendGuestAccountInvitationFormError')
        }
      },
      gender: {
        // Translators: Label for the Gender input
        label: gettext('account', 'gender', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Gender is not selected
          valueMissing: gettext('account', 'genderRequired', 'sendGuestAccountInvitationFormError')
        }
      }
    }
  },
  fieldsetContact: {
    formField: {
      homeNumber: {
        // Translators: Label for the Home Number input
        label: gettext('account', 'homeNumber', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Home Number doesn't \
          // match the expected format
          patternMismatch: gettext('account', 'homeNumberPattern', 'sendGuestAccountInvitationFormError')
        }
      },
      cellNumber: {
        // Translators: Label for the Cell Number input
        label: gettext('account', 'cellNumber', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Notify By is left blank
          valueMissing: gettext('account', 'cellNumberRequired', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Cell Number doesn't \
          // match the expected format
          patternMismatch: gettext('account', 'cellNumberPattern', 'sendGuestAccountInvitationFormError')
        }
      },
      emailAddress: {
        // Translators: Label for the Email Address input
        label: gettext('account', 'emailAddress', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Email Address is \
          // left blank
          valueMissing: gettext('account', 'emailAddressRequired', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Email Address \
          // doesn't satisfy minimum/maximum length requirement
          // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
          length: gettext('account', 'emailAddressLength', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Email Address contains \
          // invalid characters
          patternMismatch: gettext('account', 'emailAddressPattern', 'sendGuestAccountInvitationFormError')
        }
      }
    }
  },
  fieldsetHealthInsurance: {
    // Translators: Label for the Health Insurance fieldset
    label: gettext('account', 'healthInsurance', 'sendGuestAccountInvitationFormFieldsetLabel'),
    formField: {
      jurisdiction: {
        // Translators: Label for the Health Insurance Jurisdiction input
        label: gettext('account', 'healthInsuranceJurisdiction', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Health Insurance \
          // Jurisdiction is not selected
          valueMissing: gettext('account', 'healthInsuranceJurisdictionRequired', 'sendGuestAccountInvitationFormError')
        }
      },
      planNumber: {
        // Translators: Label for the Health Insurance Plan Number input
        label: gettext('account', 'healthInsurancePlanNumber', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Health Insurance Plan \
          // Number is left blank
          valueMissing: gettext('account', 'healthInsurancePlanNumberRequired', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Health Insurance Plan \
          // Number doesn't match the expected format
          patternMismatch: gettext('account', 'healthInsurancePlanNumberPattern', 'sendGuestAccountInvitationFormError')
        }
      }
    }
  },
  fieldsetName: {
    formField: {
      firstName: {
        // Translators: Label for the First Name input
        label: gettext('account', 'firstName', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when First Name is left \
          // blank
          valueMissing: gettext('account', 'firstNameRequired', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when First Name doesn't \
          // satisfy minimum/maximum length requirement
          // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
          length: gettext('account', 'firstNameLength', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when First Name contains \
          // invalid characters
          patternMismatch: gettext('account', 'firstNamePattern', 'sendGuestAccountInvitationFormError')
        }
      },
      lastName: {
        // Translators: Label for the Last Name input
        label: gettext('account', 'lastName', 'sendGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Last Name is left \
          // blank
          valueMissing: gettext('account', 'lastNameRequired', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Last Name doesn't \
          // satisfy minimum/maximum length requirement
          // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
          length: gettext('account', 'lastNameLength', 'sendGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Last Name contains \
          // invalid characters
          patternMismatch: gettext('account', 'lastNamePattern', 'sendGuestAccountInvitationFormError')
        }
      }
    }
  },
  fieldsetNotifyBy: {
    formField: {
      notifyBy: {
        // Translators: Label for the Notify By input
        label: gettext('account', 'notifyBy', 'sendGuestAccountInvitationFormInputLabel'),
        options: [
          {
            codedValue: 'both',
            // Translators: Label for the Both option of Notify By \
            // input
            label: gettext('account', 'both', 'sendGuestAccountInvitationFormNotifyByOptionLabel')
          },
          {
            codedValue: 'email',
            // Translators: Label for the Email message option of Notify By \
            // input
            label: gettext('account', 'email', 'sendGuestAccountInvitationFormNotifyByOptionLabel')
          },
          {
            codedValue: 'sms',
            // Translators: Label for the Text message option of Notify By \
            // input
            label: gettext('account', 'sms', 'sendGuestAccountInvitationFormNotifyByOptionLabel')
          }
        ]
      }
    }
  }
};

export default i18n;
