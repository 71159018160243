/* eslint-disable jsx-a11y/alt-text */
import { Utils } from '@sigmail/common';
import * as React from 'react';
import { useMemo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { MutableRef } from 'sigmail';

export interface RouterNavLinkProps extends NavLinkProps {
  disabled?: boolean | undefined;
  image?: React.ImgHTMLAttributes<HTMLImageElement>;
}

const preventDefault = (event: { preventDefault: () => void }) => event.preventDefault();

function RouterNavLinkComponent(
  {
    activeClassName,
    activeStyle,
    children,
    disabled,
    exact,
    href,
    image: imageProps,
    innerRef,
    isActive,
    location,
    onClick,
    replace,
    strict,
    to,
    ...anchorPros
  }: RouterNavLinkProps,
  ref: MutableRef<HTMLAnchorElement>
) {
  const isDisabled = disabled === true;
  const clickHandler = isDisabled ? preventDefault : onClick;

  const childNode = useMemo(
    () => (
      <React.Fragment>
        {Utils.isNotNil(imageProps) && <img {...imageProps} />}
        {children}
      </React.Fragment>
    ),
    [imageProps, children]
  );

  if (Utils.isString(href)) {
    return (
      <a aria-disabled={isDisabled} children={childNode} href={href} onClick={clickHandler} ref={ref} {...anchorPros} />
    );
  }

  return (
    <NavLink
      activeClassName={activeClassName}
      activeStyle={activeStyle}
      aria-disabled={isDisabled}
      children={childNode}
      exact={exact}
      innerRef={ref}
      isActive={isActive}
      location={location}
      onClick={clickHandler}
      replace={replace}
      strict={strict}
      to={to}
      {...anchorPros}
    />
  );
}

export const RouterNavLink = React.forwardRef(RouterNavLinkComponent);
RouterNavLink.displayName = 'RouterNavLink';
