import { Nullable, Utils } from '@sigmail/common';
import { CopdAssessmentCategory, CopdAssessmentFormData, UserObjectHealthDataValue } from '@sigmail/objects';
import { WithT } from 'i18next';
import {
  DataCopdAssessmentCategory,
  DataFormCssClassPrefix,
  DataFormNameCopdAssessment
} from '../../../app/health-data/constants';
import { FIELD_SET_LIST, FormValues } from '../../../app/health-data/forms/copd-assessment.component';
import { EnglishCanada } from '../../../constants/language-codes';
import healthDataI18n from '../../../i18n/health-data';
import { dateToUtcValues } from '../../date-to-utc-values';

const { copdAssessment: dataViewerI18n } = healthDataI18n.dataViewer;
const { copdAssessment: formI18n } = healthDataI18n.form;

export type CopdAssessmentGridData = CopdAssessmentFormData & Record<'timestamp', number>;

export class CopdAssessmentDataUtil {
  private readonly data: CopdAssessmentFormData | undefined;

  public constructor(data?: Nullable<CopdAssessmentFormData>) {
    if (Utils.isNil(data)) return;

    const Class = this.constructor as typeof CopdAssessmentDataUtil;
    if (!Class.isValid(data)) throw new TypeError('Invalid Copd Assessment data.');

    this.data = data;
  }

  public static buildGridData(
    data: UserObjectHealthDataValue
  ): Readonly<[ReadonlyArray<number>, ReadonlyArray<CopdAssessmentGridData>]> {
    const timestampSet = new Set<number>();
    for (const requestId in data.$index) {
      const [indexOfRequest, responseList] = data.$index[+requestId]!;
      if (data.requestList![indexOfRequest].form !== DataFormNameCopdAssessment) continue;

      responseList.forEach((ts) => timestampSet.add(ts));
    }

    const timestampList = Array.from(timestampSet.values());
    const dataList: Array<CopdAssessmentGridData> = [];

    if (Utils.isNotNil(data.copdAssessment) && timestampList.length > 0) {
      for (const ts of timestampList) {
        const [utcYear, utcMonth, utcDate] = dateToUtcValues(ts);
        let copdAssessmentData = data.copdAssessment![utcYear]![utcMonth]![utcDate]!;
        if (copdAssessmentData) {
          dataList.push({ ...copdAssessmentData, timestamp: ts });
        }
      }

      timestampList.sort((a, b) => b - a);
    }

    return [timestampList, dataList];
  }

  public static createDataFromFormValues({
    activities,
    chestTightness,
    confidence,
    cough,
    dyspnea,
    energy,
    sleep,
    sputum
  }: FormValues): CopdAssessmentFormData {
    return {
      activities,
      chestTightness,
      confidence,
      cough,
      dyspnea,
      energy,
      sleep,
      sputum
    };
  }

  public static getCopdAssessmentCategory(score: number): Readonly<CopdAssessmentCategory> {
    if (score >= 31) return 'veryHigh';
    else if (score >= 21) return 'high';
    else if (score >= 10) return 'medium';
    else return 'low';
  }

  public static isValid(data?: null | undefined | unknown): data is CopdAssessmentFormData {
    return (
      Utils.isNonArrayObjectLike<CopdAssessmentFormData>(data) &&
      Utils.isNumber(data.activities) &&
      Utils.isNumber(data.chestTightness) &&
      Utils.isNumber(data.confidence) &&
      Utils.isNumber(data.cough) &&
      Utils.isNumber(data.dyspnea) &&
      Utils.isNumber(data.energy) &&
      Utils.isNumber(data.sleep) &&
      Utils.isNumber(data.sputum)
    );
  }

  public toNoteHtml(t: WithT['t']): string {
    const tbodyList = DataCopdAssessmentCategory.map((category) => {
      const categoryLabel = t(dataViewerI18n.category[category].label, { lng: EnglishCanada });
      const meaningLabel = t(dataViewerI18n.category[category].meaning, { lng: EnglishCanada });
      const scoreLabel = t(dataViewerI18n.category[category].score, { lng: EnglishCanada });

      return `<tbody><tr><td>${scoreLabel}</td><td>${categoryLabel}</td><td>${meaningLabel}</td></tr></tbody>`;
    }).join('');

    const caption = '<caption>CAT score</caption>';
    const thead = '<thead><th>Score</th><th>Impact</th><th>Meaning</th></thead>';

    return `<table class="${DataFormCssClassPrefix}${DataFormNameCopdAssessment}">${caption}${thead}${tbodyList}</table>`;
  }

  public toQuestionnaireHtml(data: CopdAssessmentFormData, t: WithT['t']): string {
    const Class = this.constructor as typeof CopdAssessmentDataUtil;

    const tbodyList = FIELD_SET_LIST.map(([fieldsetName, fieldNameList]) => {
      const columnList = fieldNameList.map((fieldName) => {
        const header = t(formI18n[fieldsetName].label!, { lng: EnglishCanada });
        return `<th scope="row">${header}</th><td>${data[fieldName]}</td>`;
      });

      const rowList = `<tr>${columnList.join('</tr><tr>')}</tr>`;
      return `<tbody>${rowList}</tbody>`;
    }).join('');

    const score = Object.values(data).reduce((accumulator, score) => accumulator + score, 0);
    const category = Class.getCopdAssessmentCategory(score);

    const scoreLabel = t(dataViewerI18n.columnHeader.score, { lng: EnglishCanada });
    const tFooter = `<tfoot><tr><th>${scoreLabel}</th><td class="${category}">${score}</td></tr></tfoot>`;

    const caption = '<caption>Questionnaire</caption>';
    const thead = '<thead><th>Question</th><th>Answer</th></thead>';

    return `<table class="${DataFormCssClassPrefix}${DataFormNameCopdAssessment}">${caption}${thead}${tbodyList}${tFooter}</table>`;
  }

  // @ts-expect-error TS2394
  public toHtml(t: WithT['t']): string;
  public toHtml(data: CopdAssessmentFormData, t: WithT['t']): string;
  public toHtml(arg0: unknown, arg1: WithT['t']): string {
    let data: CopdAssessmentFormData;
    let t: typeof arg1;

    if (typeof arg0 === 'function') {
      data = this.data as typeof data;
      t = arg0 as typeof t;
    } else {
      data = arg0 as typeof data;
      t = arg1;
    }

    const noteHtml = this.toNoteHtml(t);
    const questionnaireHtml = this.toQuestionnaireHtml(data, t);

    return noteHtml.concat(questionnaireHtml);
  }
}
