import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: ContactListI18n.View.ContactListView['dialog']['createGuestContactGroup'] = {
  // Translators: (HTML) Title for the Create Guest Contact Group dialog
  title: gettext('contact-list', 'title', 'createGuestContactGroupDialog', 'safe-html'),
  // Translators: (HTML) Body for the Create Guest Contact Group dialog
  body: gettext('contact-list', 'body', 'createGuestContactGroupDialog', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Create Guest Contact \
      // Group dialog
      label: gettext('contact-list', 'reject', 'createGuestContactGroupDialogActionLabel')
    },
    accept: {
      // Translators: Label for the Create action of Create Guest Contact \
      // Group dialog
      label: gettext('contact-list', 'accept', 'createGuestContactGroupDialogActionLabel')
    }
  }
};

export default i18n;
