import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogSelectEMRProviderFolder } from '../../constants/action-context';

const i18n: GlobalI18n['dialog']['selectEMRProviderFolder'] = {
  action: {
    accept: {
      // Translators: Label for the Select action of Select EMR Provider \
      // Folder dialog
      label: gettext('global', 'accept', 'selectEMRProviderFolderDialogActionLabel'),
      context: DialogSelectEMRProviderFolder
    },
    reject: {
      // Translators: Label for the Cancel action of Select EMR Provider \
      // Folder dialog
      label: gettext('global', 'reject', 'selectEMRProviderFolderDialogActionLabel'),
      context: DialogSelectEMRProviderFolder
    }
  },
  body: {
    // Translators: (HTML) Message displayed in the body of Select EMR \
    // Provider Folder dialog when access token is either invalid or expired
    tokenExpired: gettext('global', 'tokenExpired', 'selectEMRProviderFolderDialogBody', 'safe-html')
  },
  // Translators: (HTML) Title of the Select EMR Provider Folder dialog
  title: gettext('global', 'title', 'selectEMRProviderFolderDialog', 'safe-html')
};

export default i18n;
