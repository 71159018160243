import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { Overrides } from '@material-ui/core/styles/overrides';
import * as Constants from './constants';
import * as tailwindConfig from './tailwind/tailwind.config';

const resolveTailwindConfig = require('tailwindcss/resolveConfig');
const { theme } = resolveTailwindConfig(tailwindConfig);

export let UITheme = createMuiTheme({
  palette: {
    primary: {
      light: theme.colors.primaryLight,
      main: theme.colors.primary,
      dark: theme.colors.primaryDark
    },
    secondary: {
      light: theme.colors.secondaryLight,
      main: theme.colors.secondary,
      dark: theme.colors.secondaryDark
    },
    success: {
      main: theme.colors.success
    },
    error: {
      main: theme.colors.danger
    },
    text: {
      primary: theme.colors.textPrimary,
      secondary: theme.colors.textSecondary,
      disabled: theme.colors.textDisabled
    }
  },
  breakpoints: {
    keys: Constants.BreakpointKeys,
    values: Constants.BreakpointKeys.reduce((breakpoint, key, index) => {
      breakpoint[key] = Constants.BreakpointValues[index];
      return breakpoint;
    }, {} as BreakpointValues)
  },
  props: {
    MuiDialog: {
      fullWidth: true,
      maxWidth: 'sm'
    },
    MuiDialogTitle: {
      disableTypography: true
    },
    MuiFormControl: {
      fullWidth: true,
      size: 'small',
      variant: 'outlined'
    },
    MuiSelect: {
      fullWidth: true,
      variant: 'outlined'
    },
    MuiTextField: {
      fullWidth: true,
      size: 'small',
      variant: 'outlined'
    }
  }
});

const overrides: Overrides = {
  MuiFormControl: {
    root: {
      '&:hover .MuiFormLabel-root:not(.Mui-error)': {
        color: UITheme.palette.text.primary
      }
    }
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        backgroundColor: UITheme.palette.grey[200]
      }
    },
    marginDense: {
      fontSize: theme.fontSize.sm
    }
  },
  MuiInputLabel: {
    formControl: {
      overflow: 'hidden',
      right: 16,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  MuiOutlinedInput: {
    root: {
      '& $notchedOutline': {
        borderWidth: '1px'
      },
      '&$focused $notchedOutline': {
        borderWidth: '1px'
      },
      '&$error:hover $notchedOutline': {
        borderWidth: theme.borderWidth[2]
      }
    }
  },
  MuiInputAdornment: {
    root: {
      '& > .MuiButtonBase-root': {
        padding: 0,

        '&:hover': {
          backgroundColor: theme.colors.transparent
        }
      }
    }
  },
  MuiFormHelperText: {
    root: {
      '&$error': {
        display: 'none',
        fontSize: theme.fontSize.sm,
        marginLeft: theme.margin[0],
        marginRight: theme.margin[0],
        width: theme.width.full
      },
      '&:empty': {
        display: 'none'
      }
    }
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: theme.colors.transparent
      }
    }
  },
  MuiDialogTitle: {
    root: {
      fontSize: theme.fontSize.xl,
      fontWeight: theme.fontWeight.medium,
      padding: `${theme.padding[5]} ${theme.padding[6]}`
    }
  },
  MuiDialogContent: {
    root: {
      marginBottom: theme.padding[5],
      paddingLeft: theme.padding[6],
      paddingRight: theme.padding[6]
    }
  },
  MuiDialogActions: {
    root: {
      padding: `0 ${theme.padding[6]} ${theme.padding[5]}`
    }
  }
};

overrides.MuiInputLabel = {
  ...overrides.MuiInputLabel,
  marginDense: overrides.MuiInputBase!.marginDense
};

UITheme = createMuiTheme(UITheme, { overrides });
