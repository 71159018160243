const COLOR_PALETTE_CONTRASTING: ReadonlyArray<Readonly<[color: string, textColor: string]>> = [
  /* 0 */ ['#88190f', '#ffffff'],
  /* 1 */ ['#b53100', '#ffffff'],
  /* 2 */ ['#87aac9', '#000000'],
  /* 3 */ ['#0074a6', '#ffffff'],
  /* 4 */ ['#024e7c', '#ffffff'],
  /* 5 */ ['#526601', '#ffffff'],
  /* 6 */ ['#7a8e00', '#000000'],
  /* 7 */ ['#0074a6', '#ffffff'],
  /* 8 */ ['#a6237e', '#ffffff'],
  /* 9 */ ['#791057', '#ffffff']
];

const COLOR_PALETTE_DARK_TO_LIGHT: ReadonlyArray<Readonly<[color: string, textColor: string]>> = [
  /* 0 */ ['#00561d', '#ffffff'],
  /* 1 */ ['#007062', '#ffffff'],
  /* 2 */ ['#0074a6', '#ffffff'],
  /* 3 */ ['#5a73bd', '#000000'],
  /* 4 */ ['#8281c8', '#000000']
];

const COLOR_PALETTE_MONOCHROMATIC: ReadonlyArray<Readonly<[color: string, textColor: string]>> = [
  /* 0 */ ['#afc7dd', '#000000'],
  /* 1 */ ['#87aac9', '#000000'],
  /* 2 */ ['#0074a6', '#ffffff'],
  /* 3 */ ['#026191', '#ffffff'],
  /* 4 */ ['#024e7c', '#ffffff']
];

const COLOR_PALETTE_PASTEL: ReadonlyArray<Readonly<[color: string, textColor: string]>> = [
  /* 0 */ ['#88b090', '#000000'],
  /* 1 */ ['#87b4ab', '#000000'],
  /* 2 */ ['#87aac9', '#000000'],
  /* 3 */ ['#8797cd', '#000000'],
  /* 4 */ ['#8281c8', '#000000']
];

const COLOR_PALETTE_PASTEL_CONTRASTING: ReadonlyArray<Readonly<[color: string, textColor: string]>> = [
  /* 0 */ ['#d28e87', '#000000'],
  /* 1 */ ['#e4b5b1', '#000000'],
  /* 2 */ ['#d6e5f4', '#000000'],
  /* 3 */ ['#afc7dd', '#000000'],
  /* 4 */ ['#87aac9', '#000000'],
  /* 5 */ ['#afba8a', '#000000'],
  /* 6 */ ['#c9d1b1', '#000000'],
  /* 7 */ ['#afc7dd', '#000000'],
  /* 8 */ ['#dcb3cb', '#000000'],
  /* 9 */ ['#c78aaf', '#000000']
];

const COLOR_PALETTE_VIBRANT: ReadonlyArray<Readonly<[color: string, textColor: string]>> = [
  /* 0 */ ['#007f38', '#ffffff'],
  /* 1 */ ['#008575', '#ffffff'],
  /* 2 */ ['#0074a6', '#ffffff'],
  /* 3 */ ['#1b51b0', '#ffffff'],
  /* 4 */ ['#251dad', '#ffffff'],
  /* 5 */ ['#e60000', '#ffffff']
];

export const ACCESSIBLE_COLOR_PALETTE: ReadonlyArray<Readonly<[color: string, textColor: string]>> = [
  COLOR_PALETTE_VIBRANT[5],
  COLOR_PALETTE_CONTRASTING[9],
  COLOR_PALETTE_CONTRASTING[0],
  COLOR_PALETTE_CONTRASTING[8],
  COLOR_PALETTE_CONTRASTING[1],
  COLOR_PALETTE_PASTEL_CONTRASTING[9],
  COLOR_PALETTE_PASTEL_CONTRASTING[0],
  COLOR_PALETTE_PASTEL_CONTRASTING[8],
  COLOR_PALETTE_PASTEL_CONTRASTING[1],

  COLOR_PALETTE_DARK_TO_LIGHT[0],
  COLOR_PALETTE_DARK_TO_LIGHT[1],
  COLOR_PALETTE_VIBRANT[0],
  COLOR_PALETTE_VIBRANT[1],
  COLOR_PALETTE_CONTRASTING[5],
  COLOR_PALETTE_CONTRASTING[6],
  COLOR_PALETTE_PASTEL[1],
  COLOR_PALETTE_PASTEL[0],
  COLOR_PALETTE_PASTEL_CONTRASTING[5],
  COLOR_PALETTE_PASTEL_CONTRASTING[6],

  COLOR_PALETTE_MONOCHROMATIC[2],
  COLOR_PALETTE_MONOCHROMATIC[4],
  COLOR_PALETTE_MONOCHROMATIC[3],
  COLOR_PALETTE_VIBRANT[3],
  COLOR_PALETTE_VIBRANT[4],
  COLOR_PALETTE_DARK_TO_LIGHT[3],
  COLOR_PALETTE_PASTEL[4],
  COLOR_PALETTE_PASTEL[3],
  COLOR_PALETTE_MONOCHROMATIC[1],
  COLOR_PALETTE_MONOCHROMATIC[0],
  COLOR_PALETTE_PASTEL_CONTRASTING[2]
];
