import { CaseReducerSetCollectionObjectsState, StoreStateCollectionObjects } from '@sigmail/app-state';
import { getLoggerWithPrefix } from '@sigmail/logging';

const Logger = getLoggerWithPrefix('Reducer', 'setCollectionObjectsStateReducer:');

export const setStateReducer: CaseReducerSetCollectionObjectsState = (
  state,
  { payload: nextState }
): StoreStateCollectionObjects => {
  try {
    Logger.info('== BEGIN ==');
    // @ts-ignore TS2322
    return state.equals(nextState) ? state : nextState;
  } finally {
    Logger.info('== END ==');
  }
};
