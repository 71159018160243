const INVALID_INPUT_ELEMENT_SELECTORS = [
  'input[aria-invalid="true"]',
  'select[aria-invalid="true"]',
  'textarea[aria-invalid="true"]',
  '.MuiCheckbox-root[aria-invalid="true"]',
  '.MuiFormControl-root[aria-invalid="true"]',
  '.MuiSelect-root[aria-invalid="true"]',
  '.MuiAutocomplete-root[aria-invalid="true"]'
].join(',');

export function getInvalidInputElementList(parentNode?: ParentNode | null | undefined): HTMLElement[] {
  return Array.from((parentNode || document).querySelectorAll<HTMLElement>(INVALID_INPUT_ELEMENT_SELECTORS));
}
