import { Utils } from '@sigmail/common';

export async function subscribeToPushService(): Promise<PushSubscription | null> {
  try {
    if ('serviceWorker' in window.navigator) {
      const swReg = await window.navigator.serviceWorker.getRegistration();
      if (Utils.isNotNil(swReg) && 'pushManager' in swReg) {
        return swReg.pushManager.subscribe({
          applicationServerKey: process.env.WEB_PUSH_APP_SERVER_KEY,
          userVisibleOnly: true
        });
      }
    }
  } catch {
    /* ignore */
  }
  return null;
}
