import { IDataObjectCache } from '@sigmail/app-state';
import React from 'react';
import { useDispatchFetchObjects, UseDispatchFetchObjectsParams } from './use-dispatch-fetch-objects';

export interface UseDispatchFetchDataObjectsParam
  extends Pick<UseDispatchFetchObjectsParams, 'authState' | 'claims'>,
    Pick<Required<UseDispatchFetchObjectsParams>['dataObjects'], 'ids'> {}

export const useDispatchFetchDataObjects = () => {
  const dispatchFetchObjects = useDispatchFetchObjects();

  return React.useCallback(
    ({ ids, ...query }: UseDispatchFetchDataObjectsParam, cache?: IDataObjectCache | null | undefined) => {
      // return new Promise<Api.BatchQueryResponseData>((resolve, reject) => {
      //   setTimeout(() => {
      //     dispatchFetchObjects({ ...query, dataObjects: { ids } }).then(resolve, reject);
      //   }, 1000);
      // });
      return dispatchFetchObjects(
        { ...query, dataObjects: { ids } },
        cache === null ? null : { DataObjectCache: cache }
      );
    },
    [dispatchFetchObjects]
  );
};
