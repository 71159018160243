import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { GroupObject } from '.';
import type { GroupObjectGuestListValue as DV } from '../types';

const TYPE = process.env.GROUP_OBJECT_TYPE_GUEST_LIST;

/** @public */
export class GroupObjectGuestList extends GroupObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.list);
  }

  public updateValue(newValue: DV): Promise<GroupObjectGuestList> {
    const Class = this.constructor as typeof GroupObjectGuestList;
    return Class.updateValue(this, newValue);
  }
}
