import {
  Constants,
  EventLogRecordCodeConsultationResponded,
  EventLogRecordCodeConsultationSent,
  HRM,
  MessagingException,
  ReadonlyMessageBodyConsultation,
  Utils
} from '@sigmail/common';
import { DataObjectMsgBodyValue, DataObjectMsgMetadataValue, UserObjectConsultationValue, ValueFormatVersion } from '@sigmail/objects';
import sanitizeHtml from 'sanitize-html';
import { profileObjectSelector as clientProfileObjectSelector } from '../../../selectors/client-object';
import { consultationObjectSelector, contactInfoObjectSelector, protectedProfileObjectSelector } from '../../../selectors/user-object';
import { UserObjectCache } from '../../../user-objects-slice/cache';
import { ActionInitParams } from '../../base-action';
import { SANITIZER_OPTIONS } from '../../constants';
import { AUTH_STATE_LOG_EVENT, AUTH_STATE_SEND_CONSULTATION_REQUEST } from '../../constants/auth-state-identifier';
import { logEventAction } from '../../log-event-action';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';

type ConsultationListItem = UserObjectConsultationValue['list'][0];
type ConsultationReferrer = ConsultationListItem['referrer'];
type ConsultationSpecialist = ConsultationListItem['consultant'];

export interface Payload extends BaseSendMessagePayload {
  readonly isReplyToConsultationRequest?: boolean | undefined;
}

interface State extends BaseSendMessageState {
  consultationValue: ConsultationListItem;
  isFirstReply: true | undefined;
  msgBody: ReadonlyMessageBodyConsultation;
}

const CONSULTANT_KEY_LIST: ReadonlyArray<keyof ConsultationSpecialist> = [
  'id',
  'ohipBillingNumber',
  'type',
  ...Constants.PERSON_NAME_KEY_LIST
];

const REFERRER_KEY_LIST: ReadonlyArray<keyof ConsultationReferrer> = ['id', 'ohipBillingNumber', 'type', ...Constants.PERSON_NAME_KEY_LIST];

export class SendConsultationRequestAction extends BaseSendMessageAction<Payload, State> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params);

    const { recipientList } = params.payload;
    if (recipientList.length !== 1) {
      throw new MessagingException('Expected at most one recipient entry.');
    }
  }

  /** @override */
  protected validateMessageFormName() {
    const { isReplyToConsultationRequest, messageFormName } = this.payload;

    const isConsultationRequest = Utils.isMessageFormNameConsultation(messageFormName);
    if (!isConsultationRequest && !isReplyToConsultationRequest) {
      throw new MessagingException(
        `Expected message form name to be <${Constants.MessageFormName.Consultation}>; was <${messageFormName}>`
      );
    } else if (isConsultationRequest && isReplyToConsultationRequest) {
      throw new MessagingException('Attempt to reply to a consultation message with another consultation message.');
    }
  }

  /** @override */
  protected async createIdsRequestData() {
    const { isReplyToConsultationRequest } = this.payload;

    const data = await super.createIdsRequestData();
    return isReplyToConsultationRequest === true ? data : { ...data, state: AUTH_STATE_SEND_CONSULTATION_REQUEST };
  }

  /** @override */
  protected async generateRequestBody() {
    await super.generateRequestBody();

    await this.addUpdateOperationForConsultation(); // 424
    await this.addUpdateOperationForUserEventLog(); // 425
  }

  /** @override */
  protected async getGuestUserForMetadataValue(
    ...args: Parameters<BaseSendMessageAction['getGuestUserForMetadataValue']>
  ): Promise<DataObjectMsgMetadataValue['guestUser']> {
    const { isReplyToConsultationRequest, messageBody } = this.payload;
    if (isReplyToConsultationRequest) {
      return super.getGuestUserForMetadataValue(...args);
    }

    const { patient } = (messageBody as ReadonlyMessageBodyConsultation).messageForm.value;
    const { addressType, contactNumber, contactType, healthPlanNumber: healthCardNumber } = patient;
    const cellNumber = Utils.stringOrDefault(contactType === 'mobile' && contactNumber);
    const homeNumber = Utils.stringOrDefault(contactType === 'home' && contactNumber);

    let guestUser = await super.getGuestUserForMetadataValue({
      ...patient,
      cellNumber,
      healthCardNumber,
      homeNumber,
      role: Constants.ROLE_ID_GUEST
    });

    if (Utils.isNotNil(guestUser) && Utils.isNotNil(guestUser.address)) {
      guestUser = {
        ...guestUser,
        address: { ...guestUser.address, type: addressType as HRM.AddressType }
      };
    }

    return guestUser;
  }

  /** @override */
  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    const { isReplyToConsultationRequest, messageBody } = this.payload;
    const { msgMetadataId } = this.state;

    if (isReplyToConsultationRequest === true) {
      return super.createMessageBodyValue();
    }

    let body = messageBody as ReadonlyMessageBodyConsultation;

    //
    // consultant
    //
    let { ohipBillingNumber: billingNumberConsultant, id: consultantId } = body.messageForm.value.consultant;
    if (!Utils.isString(billingNumberConsultant) || billingNumberConsultant.trim().length === 0) {
      const contactInfo = await this.getUserObjectValue(contactInfoObjectSelector, {
        type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
        userId: consultantId
      });

      if (Utils.isNotNil(contactInfo)) {
        const { ohipBillingNumber: billingNumber } = contactInfo;
        billingNumberConsultant = (Utils.isString(billingNumber) && billingNumber.trim()) || '';
      }
    }

    if (billingNumberConsultant.length === 0) {
      throw new MessagingException("Consultant's billing number is either missing or invalid.");
    }

    //
    // referrer
    //
    let { instituteName, ohipBillingNumber: billingNumberReferrer, id: referrerId } = body.messageForm.value.referrer;
    if (!Utils.isString(instituteName)) {
      const { clientId } = this.state;
      const clientProfile = await this.getUserObjectValue(clientProfileObjectSelector, {
        type: process.env.CLIENT_OBJECT_TYPE_PROFILE,
        userId: clientId
      });
      if (Utils.isNotNil(clientProfile)) {
        instituteName = clientProfile.name.trim();
      }
    }

    if (!Utils.isString(billingNumberReferrer) || billingNumberReferrer.trim().length === 0) {
      const protectedProfile = await this.getUserObjectValue(protectedProfileObjectSelector, {
        type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
        userId: referrerId
      });

      if (Utils.isNotNil(protectedProfile)) {
        const { ohipBillingNumber: billingNumber } = protectedProfile;
        billingNumberReferrer = (Utils.isString(billingNumber) && billingNumber.trim()) || '';
      }
    }

    if (billingNumberReferrer.length === 0) {
      throw new MessagingException("Referrer's billing number is either missing or invalid.");
    }

    body = {
      ...body,
      messageForm: {
        ...body.messageForm,
        value: {
          ...body.messageForm.value,
          caseId: msgMetadataId,
          consultant: {
            ...body.messageForm.value.consultant,
            ohipBillingNumber: billingNumberConsultant
          },
          referrer: {
            ...body.messageForm.value.referrer,
            instituteName,
            ohipBillingNumber: billingNumberReferrer
          },
          message: sanitizeHtml(body.messageForm.value.message, SANITIZER_OPTIONS)
        }
      }
    };

    this.state.msgBody = body;
    return { $$formatver: 1, ...body };
  }

  private async createConsultationValue(): Promise<ConsultationListItem> {
    const { isReplyToConsultationRequest, sourceMessage, subjectLine: subject } = this.payload;
    const { msgBodyId, msgMetadataId, dtServer } = this.state;

    let msgBody: ReadonlyMessageBodyConsultation['messageForm']['value'];
    if (isReplyToConsultationRequest === true) {
      const sourceMsgBody = await this.getDataObjectValue<ValueFormatVersion & ReadonlyMessageBodyConsultation>(sourceMessage!.body);
      if (Utils.isNil(sourceMsgBody)) {
        throw new MessagingException('Source message body could not be found.');
      }
      msgBody = sourceMsgBody.messageForm.value;
    } else {
      msgBody = this.state.msgBody.messageForm.value;
    }

    let { serviceCode } = msgBody;
    if (isReplyToConsultationRequest === true) {
      switch (serviceCode) {
        case 'K738':
          serviceCode = 'K739';
          break;
        case 'K730':
          serviceCode = 'K731';
          break;
        default:
          throw new MessagingException('Invalid/unknown service code.');
      }
    }

    const {
      caseId,
      consultant,
      diagnosticCode,
      patient: { birthDate, healthPlanJurisdiction, healthPlanNumber },
      referrer
    } = msgBody;

    return {
      birthDate,
      body: msgBodyId,
      caseId,
      consultant: Utils.pick(consultant, CONSULTANT_KEY_LIST),
      diagnosticCode,
      header: msgMetadataId,
      healthPlanJurisdiction,
      healthPlanNumber,
      referrer: Utils.pick(referrer, REFERRER_KEY_LIST),
      serviceCode,
      subject,
      timestamp: dtServer.getTime()
    };
  }

  private async addUpdateOperationForConsultation(): Promise<void> {
    this.logger.info('Adding an update operation to request body for consultation object.');

    const { isReplyToConsultationRequest, sender } = this.payload;
    const { requestBody, successPayload } = this.state;

    const consultationObject = await this.getUserObject(consultationObjectSelector, {
      fetch: true,
      type: process.env.USER_OBJECT_TYPE_CONSULTATION
    });
    const consultation = UserObjectCache.getValue(consultationObject);

    if (Utils.isNil(consultationObject) || Utils.isNil(consultation)) {
      throw new MessagingException('Consultation object could not be fetched.');
    }

    const consultationValue = await this.createConsultationValue();
    this.state.consultationValue = consultationValue;

    if (isReplyToConsultationRequest === true) {
      // make sure consultant's ID matches sender's ID; otherwise, return
      if (consultationValue.consultant.id !== sender.id) return;

      // an e-consult reply already exists with the same case ID?
      const index = consultation.list.findIndex(({ caseId }) => caseId === consultationValue.caseId);
      if (index > -1) return;

      this.state.isFirstReply = true;
    }

    const updatedList = consultation.list.concat(consultationValue);
    const updatedObject = await consultationObject.updateValue({ ...consultation, list: updatedList });
    requestBody.update(updatedObject);

    successPayload.request.userObjects!.ids.push(updatedObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  /** @override */
  protected async addUpdateOperationForUserEventLog(): Promise<void> {
    if (Utils.isNil(this.state.consultationValue)) {
      return;
    }

    this.logger.info('Adding an update operation to request body for user event log.');

    const { isReplyToConsultationRequest } = this.payload;
    const { roleAuthClaim: authState, consultationValue, currentUser, dtServer, isFirstReply, requestBody, successPayload } = this.state;

    let code: EventLogRecordCodeConsultationResponded | EventLogRecordCodeConsultationSent = Constants.EventLogCode.ConsultationSent;
    if (isFirstReply === true) code = Constants.EventLogCode.ConsultationResponded;
    else if (isReplyToConsultationRequest === true) return;

    const claims = await this.dispatch(
      logEventAction({
        dtServer,
        fetchIds: (count) => {
          return this.dispatchFetchIdsByUsage({
            authState,
            state: AUTH_STATE_LOG_EVENT,
            ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
          });
        },
        logger: this.logger,
        record: this.newEventLogRecordValue(dtServer, code, consultationValue.header, consultationValue.body, consultationValue),
        requestBody,
        successPayload,
        userId: currentUser.id,
        userIdType: 'user'
      })
    );

    this.state.batchUpdateClaims.push(...claims);
  }
}
