import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.Root['messageFolderGridLocaleText'] = {
  // Translators: Message, for screen readers, explaining which key can be \
  // pressed to deselect a row in the Message Folder grid
  ariaRowDeselect: gettext('messaging', 'ariaRowDeselect', 'messageFolderGridLocaleText'),
  // Translators: Message, for screen readers, explaining which key can be \
  // pressed to select a row in the Message Folder grid
  ariaRowSelect: gettext('messaging', 'ariaRowSelect', 'messageFolderGridLocaleText'),
  // Translators: Message, for screen readers, explaining which key can be \
  // pressed to toggle all rows selection in the Message Folder grid
  ariaRowSelectAll: gettext('messaging', 'ariaRowSelectAll', 'messageFolderGridLocaleText'),
  // Translators: Message, for screen readers, explaining which key can be \
  // pressed to toggle a single row selection in the Message Folder grid
  ariaRowToggleSelection: gettext('messaging', 'ariaRowToggleSelection', 'messageFolderGridLocaleText'),
  // Translators: Message displayed in the grid when there are no rows \
  // available to show in the Message Folder grid
  noRowsToShow: gettext('messaging', 'noRowsToShow', 'messageFolderGridLocaleText')
};

export default i18n;
