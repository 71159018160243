import { verifySession } from 'secure-remote-password/client';
import type { SrpSession } from '../types';

export function srpVerifySession(
  clientPublicEphemeral: string,
  clientSession: SrpSession,
  serverSessionProof: string
): void {
  verifySession(clientPublicEphemeral, clientSession, serverSessionProof);
}
