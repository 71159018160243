import { SchedulingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { getRoutePath } from '../../app/routes';
import IconGlobalGroupContactListPrimary from '../../assets/images/icon-global-group-contact-list-primary.min.svg';
import IconGlobalGroupContactListSecondary from '../../assets/images/icon-global-group-contact-list-secondary.min.svg';
import IconGlobalUserContactListPrimary from '../../assets/images/icon-global-user-contact-list-primary.min.svg';
import IconGlobalUserContactListSecondary from '../../assets/images/icon-global-user-contact-list-secondary.min.svg';
import { ScheduleGroup, ScheduleOwn } from '../../constants/action-ids';
import { EnglishCanada, FrenchCanada } from '../../constants/language-codes';
import { ROUTE_SCHEDULE_GROUP, ROUTE_SCHEDULE_OWN } from '../../constants/route-identifiers';
import confirmEventRecall from './dialog-confirm-event-recall';
import meetingRoom from './dialog-meeting-room';
import newEvent from './dialog-new-event';
import videoConsentDialog from './dialog-video-consent';
import viewEvent from './dialog-view-event';
import errorNameToMsgMap from './error-message';
import groupSchedule from './group-schedule';
import messageViewEvent from './message-view-event';
import ownSchedule from './own-schedule';
import reminderNotification from './reminder-notification';

interface I18N {
  navAction: SchedulingI18n.Root['navAction'];
  ownSchedule: SchedulingI18n.OwnSchedule;
  groupSchedule: SchedulingI18n.GroupSchedule;
  messageViewEvent: SchedulingI18n.MessageViewEvent;
  dialog: {
    newEvent: SchedulingI18n.Dialog.NewEvent;
    viewEvent: SchedulingI18n.Dialog.ViewEvent;
    confirmEventRecall: SchedulingI18n.Dialog.ConfirmEventRecall;
    videoConsentDialog: SchedulingI18n.Dialog.VideoConsent;
    meetingRoom: SchedulingI18n.Dialog.MeetingRoom;
  };
  errorNameToMsgMap: SchedulingI18n.ErrorMessage;
  reminderNotification: SchedulingI18n.ReminderNotification;
}

const i18n: I18N = {
  navAction: {
    [ScheduleOwn]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGlobalUserContactListPrimary,
          [FrenchCanada]: IconGlobalUserContactListPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGlobalUserContactListSecondary,
          [FrenchCanada]: IconGlobalUserContactListSecondary
        }
      },
      // Translators: Label for the My Schedule navigation action
      label: gettext('scheduling', 'scheduleOwn', 'navActionLabel'),
      to: getRoutePath(ROUTE_SCHEDULE_OWN)
    },
    [ScheduleGroup]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGlobalGroupContactListPrimary,
          [FrenchCanada]: IconGlobalGroupContactListPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGlobalGroupContactListSecondary,
          [FrenchCanada]: IconGlobalGroupContactListSecondary
        }
      },
      // Translators: Label for the Group Schedule navigation action
      label: gettext('scheduling', 'scheduleGroup', 'navActionLabel'),
      to: getRoutePath(ROUTE_SCHEDULE_GROUP)
    }
  },
  ownSchedule,
  groupSchedule,
  messageViewEvent,
  dialog: {
    newEvent,
    viewEvent,
    confirmEventRecall,
    videoConsentDialog,
    meetingRoom
  },
  errorNameToMsgMap,
  reminderNotification
};

export default i18n;
