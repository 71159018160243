import { HRM, ReadonlyMessageBodyHrm, ReadonlyRecord, Utils } from '@sigmail/common';
import { DataObjectHrmUserListValue, HrmActiveUserListItem } from '@sigmail/objects';
import { WithT } from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { SANITIZER_OPTIONS } from '../../../app-state/actions/constants';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import { EnglishCanada } from '../../../constants/language-codes';
import globalI18n from '../../../i18n/global';
import dropdownListI18n from '../../../i18n/HRM/drop-down-list';
import { FieldName } from '../types/HRM';

export const findActiveHrmUserIndex = (
  hrmUserList: DataObjectHrmUserListValue['list'],
  clinicianId: string
): number => {
  return hrmUserList.findIndex((user) => user.active && user.id === clinicianId);
};

export const findActiveHrmUser = (
  ...args: Parameters<typeof findActiveHrmUserIndex>
): HrmActiveUserListItem | undefined => {
  const index = findActiveHrmUserIndex(...args);
  return index > -1 ? (args[0][index] as HrmActiveUserListItem) : undefined;
};

export interface GetDropdownListValueParams extends Readonly<WithT> {
  readonly I18N: Array<ReadonlyRecord<'key' | 'value', string>>;
  readonly key: string;
}

export function getDropDownListValue({ I18N, key, t }: GetDropdownListValueParams): string {
  const entry = I18N.find((item) => item.key === key);
  return Utils.isNil(entry) ? '' : t(entry.value);
}

export interface GetFieldValueParams extends Readonly<WithT> {
  readonly fieldName: FieldName | `full${'Address' | 'Name'}`;
  readonly locale?: string;
  readonly msgBody: ReadonlyMessageBodyHrm['messageForm']['value'];
}

export function getFieldValue({ fieldName, locale, msgBody, t }: GetFieldValueParams): string {
  const address = Utils.arrayOrDefault<HRM.AddressPoint>(msgBody.patient.address, EMPTY_ARRAY)[0];
  const telecom = Utils.arrayOrDefault<HRM.ContactPoint>(msgBody.patient.telecom, EMPTY_ARRAY)[0];

  if (!Utils.isString(locale)) {
    locale = EnglishCanada;
  }

  switch (fieldName) {
    case 'address': {
      if (!Utils.isArray(address?.line)) break;
      return Utils.stringOrDefault(address.line[0]);
    }
    case 'addressType': {
      const addressType = Utils.stringOrDefault(address?.use);
      return getDropDownListValue({ I18N: dropdownListI18n.addressType, key: addressType, t });
    }
    case 'birthDate':
      return Utils.maskBirthDate(msgBody.patient.birthDate, locale);
    case 'category': {
      const entry = dropdownListI18n.diagnosisCategory.find(({ code }) => code === msgBody.diagnostic.categoryCode);
      return Utils.isNil(entry) ? msgBody.diagnostic.categoryCode : t(entry.name);
    }
    case 'city':
      return Utils.stringOrDefault(address?.city);
    case 'conclusion':
      return msgBody.diagnostic.conclusion;
    case 'contactNumber':
      return Utils.stringOrDefault(telecom?.phoneNumber);
    case 'contactType': {
      const contactType = Utils.stringOrDefault(telecom?.use);
      return getDropDownListValue({ I18N: dropdownListI18n.contactType, key: contactType, t });
    }
    case 'deceased':
      return msgBody.patient.deceasedBoolean ? 'Yes' : 'No';
    case 'diagnosisStatus':
      return getDropDownListValue({ I18N: dropdownListI18n.diagnosisStatus, key: msgBody.diagnostic.status, t });
    case 'effectiveDate':
      return Utils.DATE_FORMAT_FULL(locale).format(new Date(msgBody.diagnostic.effectiveDateTime));
    case 'encounterClass':
      return getDropDownListValue({ I18N: dropdownListI18n.encounterClass, key: msgBody.encounter.encounterClass, t });
    case 'encounterStatus':
      return getDropDownListValue({ I18N: dropdownListI18n.encounterStatus, key: msgBody.encounter.status, t });
    case 'endDate':
      return Utils.DATE_FORMAT_FULL(locale).format(new Date(msgBody.encounter.endDt));
    case 'firstName':
      return msgBody.patient.name.firstName;
    case 'fullAddress':
      return Utils.joinPostalAddress({
        addressLine1: getFieldValue({ fieldName: 'address', locale, msgBody, t }),
        addressLevel1: getFieldValue({ fieldName: 'province', locale, msgBody, t }),
        addressLevel2: getFieldValue({ fieldName: 'city', locale, msgBody, t }),
        postalCode: getFieldValue({ fieldName: 'postalCode', locale, msgBody, t })
      });
    case 'fullName':
      return Utils.joinPersonName({
        firstName: getFieldValue({ fieldName: 'firstName', locale, msgBody, t }),
        lastName: getFieldValue({ fieldName: 'lastName', locale, msgBody, t })
      });
    case 'gender': {
      const entry = globalI18n.genderList.find(({ code }) => code === msgBody.patient.gender);
      return Utils.isNil(entry) ? msgBody.patient.gender : t(entry.label);
    }
    case 'hpn':
      return Utils.maskHealthPlanNumber(`${msgBody.patient.hcnNumber}${msgBody.patient.hcnVersionNumber}`);
    case 'issuedDate':
      return Utils.DATE_FORMAT_FULL(locale).format(new Date(msgBody.diagnostic.issuedDateTime));
    case 'lastName':
      return msgBody.patient.name.lastName;
    case 'loincType':
      return getDropDownListValue({ I18N: dropdownListI18n.diagnosisLoincType, key: msgBody.diagnostic.loincType, t });
    case 'notes': {
      let notes = Utils.trimOrDefault(msgBody.notes);
      if (notes.length > 0) {
        if (!notes.startsWith('<p>')) {
          notes = notes.replaceAll('\n', '<br/>');
        }
        notes = sanitizeHtml(notes, SANITIZER_OPTIONS);
      }
      return msgBody.notes.replaceAll('\n', '<br/>');
    }
    case 'postalCode':
      return Utils.stringOrDefault(address?.postalCode);
    case 'province':
      return Utils.stringOrDefault(address?.province);
    case 'startDate':
      return Utils.DATE_FORMAT_FULL(locale).format(new Date(msgBody.encounter.startDt));
    default:
      break;
  }

  return '';
}
