import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogHrmInactiveLicense } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeHrmMessage['dialog']['inactiveLicense'] = {
  // Translators: (HTML) Title for the Inactive License dialog in Compose \
  // HRM Message view
  title: gettext('messaging', 'title', 'dialogHrmInactiveLicense', 'safe-html'),
  // Translators: (HTML) Message displayed in the body of Inactive License \
  // dialog in Compose HRM Message view
  body: gettext('messaging', 'body', 'dialogHrmInactiveLicense', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Inactive License dialog in \
      // Compose HRM Message view
      label: gettext('messaging', 'accept', 'dialogHrmInactiveLicenseActionLabel'),
      context: DialogHrmInactiveLicense
    }
  }
};

export default i18n;
