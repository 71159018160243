import { GroupsI18n } from '@sigmail/i18n';
import { gettext } from '..';
import assignUsersToGroupDialog from './dialog-assign-users-to-group';
import inviteUsersToGroupDialog from './dialog-invite-users-to-group';
import removeUsersFromGroupDialog from './dialog-remove-users-from-group';
import transferUsersToGroupDialog from './dialog-transfer-users-to-group';
import inviteUsersToGroupForm from './form-invite-users-to-group';
import groupToolbarAction from './group-toolbar-action';

const i18n: GroupsI18n.Root = {
  groupToolbarAction,
  dialog: {
    assignUsersToGroup: assignUsersToGroupDialog,
    inviteUsersToGroup: inviteUsersToGroupDialog,
    removeUsersFromGroup: removeUsersFromGroupDialog,
    transferUsersToGroup: transferUsersToGroupDialog
  },
  form: {
    inviteUsersToGroup: inviteUsersToGroupForm
  },
  gridLocaleText: {
    // Translators: Message, for screen readers, explaining which key can be \
    // pressed to deselect a row in the Group Member List grid
    ariaRowDeselect: gettext('groups', 'ariaRowDeselect', 'gridLocaleText'),
    // Translators: Message, for screen readers, explaining which key can be \
    // pressed to select a row in the Group Member List grid
    ariaRowSelect: gettext('groups', 'ariaRowSelect', 'gridLocaleText'),
    // Translators: Message, for screen readers, explaining which key can be \
    // pressed to toggle all rows selection in the Group Member List grid
    ariaRowSelectAll: gettext('groups', 'ariaRowSelectAll', 'gridLocaleText'),
    // Translators: Message, for screen readers, explaining which key can be \
    // pressed to toggle a single row selection in the Group Member List grid
    ariaRowToggleSelection: gettext('groups', 'ariaRowToggleSelection', 'gridLocaleText'),
    // Translators: Message displayed in the grid when there are no rows \
    // available to show in the Group Member List grid
    noRowsToShow: gettext('groups', 'noRowsToShow', 'gridLocaleText')
  }
};

export default i18n;
