import { Menu, MenuProps } from '@material-ui/core';
import { ReadonlyPartialRecord, Utils } from '@sigmail/common';
import React from 'react';

export type MenuButtonClassKey = 'anchor' | 'paper' | 'list';

export interface Props extends Omit<MenuProps, 'anchorEl' | 'classes' | 'onClick' | 'ref'> {
  readonly anchorElProps?: React.ComponentPropsWithoutRef<'button'> | undefined;
  readonly classes?: ReadonlyPartialRecord<MenuButtonClassKey, string> | undefined;
  readonly onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const isTruthyReactNode = (node?: any): node is Exclude<NonNullable<React.ReactNode>, false> =>
  Utils.isNotNil(node) && node !== false;

class MenuButtonComponent extends React.PureComponent<Props> {
  private readonly anchorElRef = React.createRef<HTMLButtonElement>();

  public constructor(props: Props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  public render(): React.ReactNode {
    let {
      anchorOrigin,
      anchorElProps,
      children,
      classes,
      getContentAnchorEl,
      id: menuId,
      onClick,
      transformOrigin,
      ...menuProps
    } = this.props;

    const { anchor: anchorClassName, ...menuClasses } = classes || {};

    const buttonProps: NonNullable<typeof anchorElProps> = { type: 'button' };
    if (Utils.isString(menuId)) {
      buttonProps['aria-controls'] = menuId;
      buttonProps['aria-haspopup'] = 'menu';
    }
    if (Utils.isString(anchorClassName)) buttonProps.className = anchorClassName;
    if (typeof onClick === 'function') buttonProps.onClick = onClick;

    if (!Utils.has(menuProps, 'getContentAnchorEl')) getContentAnchorEl = null;
    if (!Utils.has(menuProps, 'anchorOrigin')) anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
    if (!Utils.has(menuProps, 'transformOrigin')) transformOrigin = { horizontal: 'right', vertical: 'top' };

    return (
      <React.Fragment>
        <button ref={this.anchorElRef} {...buttonProps} {...anchorElProps} />

        {isTruthyReactNode(children) && (
          <Menu
            anchorEl={this.anchorElRef.current}
            {...menuProps}
            anchorOrigin={anchorOrigin}
            classes={menuClasses}
            getContentAnchorEl={getContentAnchorEl}
            id={menuId}
            onClose={this.onClose}
            transformOrigin={transformOrigin}
          >
            {children}
          </Menu>
        )}
      </React.Fragment>
    );
  }

  private onClose(...args: Parameters<NonNullable<MenuProps['onClose']>>): void {
    const { onClose: closeEventHandler } = this.props;

    if (typeof closeEventHandler === 'function') {
      closeEventHandler(...args);
    }
  }
}

export const MenuButton = MenuButtonComponent;
