import { MFAActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AppThunk } from '../..';
import * as AuthSelectors from '../../selectors/auth';

export const sendOTPAction = (payload: MFAActionPayload.SendOTP): AppThunk<Promise<void>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'sendOTPAction:');

    Logger.info('== BEGIN ==');
    try {
      const accessToken = Utils.isString(payload.accessToken) ? payload.accessToken : AuthSelectors.accessTokenSelector(getState());
      return await apiService.sendOTP(accessToken, payload.authState);
    } finally {
      Logger.info('== END ==');
    }
  };
};
