import { AppException, Utils } from '@sigmail/common';
import { getLogger } from '@sigmail/logging';
import type { Http } from '../types';
import { E_METHOD_NOT_IMPL } from './constants/error';

const Logger = getLogger('BaseHttpService');

export abstract class BaseHttpService implements Http.Service {
  public delete(url: string, init?: Http.RequestInit): Promise<Response> {
    logHttpRequest('delete:', url, init);
    throw new AppException(E_METHOD_NOT_IMPL, 'Method not implemented.');
  }

  public get(url: string, init?: Http.RequestInit): Promise<Response> {
    logHttpRequest('get:', url, init);
    throw new AppException(E_METHOD_NOT_IMPL, 'Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public post(url: string, body: string, init?: Http.RequestInit): Promise<Response> {
    logHttpRequest('post:', url, init);
    throw new AppException(E_METHOD_NOT_IMPL, 'Method not implemented.');
  }
}

export function logHttpError(error: unknown): void {
  Logger.error(error);
}

export function logHttpRequest(functionName: string, url: string, init?: RequestInit): void {
  if (Utils.isNonArrayObjectLike<typeof init & Http.RequestInitWithResponseParser>(init)) {
    const { method, body, signal, window, responseParser, headers: requestHeaders, ...params } = init;
    Logger.info(functionName, `HTTP ${Utils.stringOrDefault(method, 'GET').toUpperCase()}`, url);

    if (Logger.getLevel() <= 1 /* DEBUG */) {
      let headers: Record<string, string> = {};
      if (Utils.isArray(requestHeaders)) {
        // eslint-disable-next-line no-return-assign, no-param-reassign
        Utils.transform(requestHeaders, (header, [name, value]) => (header[name] = value), headers);
      } else if (Utils.isObjectLike(requestHeaders)) {
        if (requestHeaders instanceof Headers) {
          requestHeaders.forEach((value, name) => void (headers[name] = value));
        } else {
          headers = requestHeaders!;
        }
      }
      Logger.debug(functionName, 'Request Parameters', JSON.stringify({ ...params, headers }, undefined, 2));
    }
  } else {
    Logger.info(functionName, 'HTTP GET', url);
  }
}
