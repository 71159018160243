import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectContactInfoValue as DV, UserObjectContactInfoValue_v2 } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_CONTACT_INFO;

/** @public */
export class UserObjectContactInfo extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    let result = Utils.isString(dv.firstName) && Utils.isString(dv.lastName) && Utils.isString(dv.emailAddress);
    if (result) {
      // eslint-disable-next-line camelcase
      const { role: roleId } = value as UserObjectContactInfoValue_v2;

      const isRoleGuest = Utils.isGuestRole(roleId);
      const isRoleNonGuest = !isRoleGuest && Utils.isNonGuestRole(roleId);
      result = isRoleGuest || isRoleNonGuest;

      if (result) {
        if (isRoleGuest) {
          result = Utils.isString(dv.healthCardNumber);
        } else if (Utils.isPhysicianRole(roleId) || Utils.isStaffRole(roleId)) {
          result = Utils.isString(dv.specialty);
        }
      }
    }

    return result;
  }

  public override updateValue(newValue: DV): Promise<UserObjectContactInfo> {
    const Class = this.constructor as typeof UserObjectContactInfo;
    return Class.updateValue(this, newValue);
  }
}
