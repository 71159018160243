import { IUserObjectCache } from '@sigmail/app-state';
import React from 'react';
import { useDispatchFetchObjects, UseDispatchFetchObjectsParams } from './use-dispatch-fetch-objects';

export interface UseDispatchFetchUserObjectsParams extends Pick<UseDispatchFetchObjectsParams, 'authState' | 'claims'> {
  userObjects?: UseDispatchFetchObjectsParams['userObjectsByType'];
}

export const useDispatchFetchUserObjects = () => {
  const dispatchFetchObjects = useDispatchFetchObjects();

  return React.useMemo(() => {
    return (
      { userObjects: userObjectsByType, ...query }: UseDispatchFetchUserObjectsParams,
      cache?: IUserObjectCache | null | undefined
    ) => {
      // return new Promise<Api.BatchQueryResponseData>((resolve, reject) => {
      //   setTimeout(() => {
      //     dispatchFetchObjects(
      //       { ...query, userObjectsByType },
      //       cache === null ? null : { UserObjectCache: cache }
      //     ).then(resolve, reject);
      //   }, 1000);
      // });
      return dispatchFetchObjects({ ...query, userObjectsByType }, cache === null ? null : { UserObjectCache: cache });
    };
  }, [dispatchFetchObjects]);
};
