/* eslint-disable @typescript-eslint/no-inferrable-types */

import { isInteger } from '.';

/**
 * @param bytes - Number of bytes. Must be a positive finite number.
 * @param precision - Number of digits after the decimal point. Must be between
 * `0` and `6`; default is `2`. Any value less than zero will be treated as
 * zero. Similarly, any value greater than `6` will be treated as `6`.
 * @param locale - Locale to be used for formatting. Default is `en`.
 */
export function formatBytes(bytes: number, precision: number = 2, locale: 'en' | 'fr' = 'en'): string {
  if (!isInteger(bytes) || bytes < 0) {
    throw new Error('Argument <bytes> must be a positive finite number.');
  }

  if (bytes === 0) return locale === 'fr' ? '0 octet' : '0 Bytes';
  if (bytes === 1) return locale === 'fr' ? '1 octet' : '1 Byte';

  const sizes =
    locale === 'fr'
      ? ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo']
      : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const K = 1024;
  const fractionDigits = Math.max(0, Math.min(precision || 0, 6));
  const i = Math.floor(Math.log(bytes) / Math.log(K));

  return `${Number.parseFloat((bytes / K ** i).toFixed(fractionDigits))} ${sizes[i]}`;
}
