import { createAction as rtkCreateAction } from '@reduxjs/toolkit';
import {
  BaseContextValue,
  ComposeConsultationRequestViewState,
  ComposeHrmMessageViewState,
  ComposeMessageViewState,
  ComposeReferralViewState,
  MessageKind
} from '.';
import * as ActionId from '../../../../constants/action-ids';
import { FilterCodeMessageType } from '../../types';
import { MessageFolderGridItem } from '../folder-item-grid/types';

type PayloadSetCurrentFolder = BaseContextValue['currentFolder'];
type PayloadSetSearchFilter = string;
type PayloadSetMessageTypeFilter = Array<FilterCodeMessageType>;
type PayloadSetTotalMessageCount = number;
type PayloadSetSelectedMessageListGetter = BaseContextValue['getSelectedMessageList'];
type PayloadOpenDialog = NonNullable<BaseContextValue['openedDialog']>;
type PayloadOpenMessage = MessageFolderGridItem | undefined;
type PayloadComposeConsultationRequest = Pick<ComposeConsultationRequestViewState, 'guestUserId' | 'nonGuestUserId'>;
type PayloadComposeHrmMessage = Pick<ComposeHrmMessageViewState, 'guestUserId'>;
type PayloadComposeMessage = Pick<ComposeMessageViewState, 'initialValues'>;
type PayloadComposeReferral = Pick<ComposeReferralViewState, 'guestUserId'>;
type PayloadReplyToMessage = Extract<MessageKind, 'replyAll'> | undefined;

const createAction = (type: string) => rtkCreateAction(`messageFolderView/${type}`);
const createActionWithPayload = <P>(type: string) => rtkCreateAction<P>(`messageFolderView/${type}`);

export const setCurrentFolderAction = createActionWithPayload<PayloadSetCurrentFolder>('setCurrentFolder');
export const setSearchFilterAction = createActionWithPayload<PayloadSetSearchFilter>('setSearchFilter');
export const setMessageTypeFilterAction = createActionWithPayload<PayloadSetMessageTypeFilter>('setMessageTypeFilter');
export const setTotalMessageCountAction = createActionWithPayload<PayloadSetTotalMessageCount>('setTotalMessageCount');
export const setSelectedMessageListGetterAction = createActionWithPayload<PayloadSetSelectedMessageListGetter>(
  'setSelectedMessageListGetter'
);
export const openDialogAction = createActionWithPayload<PayloadOpenDialog>('openDialog');
export const closeDialogAction = createAction('closeDialog');
export const openMessageAction = createActionWithPayload<PayloadOpenMessage>('openMessage');
export const openDraftMessageAction = createAction(ActionId.OpenDraftMessage);
export const closeMessageAction = createAction(ActionId.CloseMessage);
export const composeConsultationRequestAction = createActionWithPayload<PayloadComposeConsultationRequest>(
  ActionId.ComposeConsultationRequest
);
export const composeHrmMessageAction = createActionWithPayload<PayloadComposeHrmMessage>(ActionId.ComposeHrmMessage);
export const composeMessageAction = createActionWithPayload<PayloadComposeMessage>(ActionId.ComposeMessage);
export const composeReferralAction = createActionWithPayload<PayloadComposeReferral>(ActionId.ComposeReferral);
export const replyToMessageAction = createActionWithPayload<PayloadReplyToMessage>(ActionId.Reply);
export const forwardMessageAction = createAction(ActionId.Forward);
