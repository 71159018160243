import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../../../http/service-exception';
import type { Api, Http } from '../../../types';
import { URL_ACCURO_EMR_GET_OAUTH_URL as API_URL } from '../../constants';
import { MalformedResponseException } from '../../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../../service-exception';

type ApiResponse = string;

interface Response {
  accuroEndpoint: string;
}

const Logger = getLoggerWithPrefix('ApiService', 'accuroEMRGetOAuthUrl:');

export async function apiAccuroEMRGetOAuthUrl(
  this: Api.Service,
  accessToken: string,
  clientId: number,
  oauthParams: Api.AccuroEMROAuthParams,
  init?: Http.RequestInit
): Promise<ApiResponse> {
  try {
    Logger.info('== BEGIN ==');
    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<ApiResponse>(
      requestUrl,
      JSON.stringify({ accuro: oauthParams, userId: clientId }),
      {
        ...init,
        cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
        headers: requestHeaders,

        async responseParser(response) {
          if (response.status === 200) {
            const json = await Utils.tryGetResponseJson<Response>(response, undefined);
            if (Utils.isNonArrayObjectLike(json) && Utils.isString(json.accuroEndpoint)) {
              return json.accuroEndpoint;
            }

            Logger.warn('Operation failed. (Malformed/unexpected response data)');
            throw new MalformedResponseException(response);
          }

          Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
          if (response.status >= 400 && response.status < 500) {
            const responseBody = await response.clone().text();
            throw new ApiServiceException(response, responseBody);
          }

          throw new HttpServiceException(response);
        }
      }
    );
  } finally {
    Logger.info('== END ==');
  }
}
