import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogAssignMessage } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.AssignMessage = {
  action: {
    accept: {
      // Translators: Label for the Accept action of Assign Message dialog
      label: gettext('messaging', 'accept', 'assignMessageDialogActionLabel'),
      context: DialogAssignMessage
    },
    reject: {
      // Translators: Label for the Reject action of Assign Message dialog
      label: gettext('messaging', 'reject', 'assignMessageDialogActionLabel'),
      context: DialogAssignMessage
    }
  },
  // Translators: (HTML) Lead text displayed in the Assign Message dialog
  body: gettext('messaging', 'body', 'assignMessageDialog', 'safe-html'),
  // Translators: (HTML) Title of the Assign Message dialog
  title: gettext('messaging', 'title', 'assignMessageDialog', 'safe-html')
};

export default i18n;
