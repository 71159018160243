import { Utils } from '@sigmail/common';
import { ContactListItem } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actOnBehalfForCareRecipientAction } from '../../../app-state/actions/account/act-on-behalf-for-care-recipient';
import { setActiveGroup } from '../../../app-state/active-group-slice';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import { useAppState, useCurrentUser } from '../../../app-state/hooks';
import { activeGroupIdSelector } from '../../../app-state/selectors';
import { ContactListResource } from '../../../hooks';
import { CircularProgress } from '../../shared/circular-progress.component';
import { FormApi } from '../../shared/form';
import style from './active-group-selection-bar.module.css';
import {
  FieldName as FormFieldName,
  FormValues,
  GroupSelectionForm as Form,
  Props as FormProps
} from './group-selection-form.component';

type HtmlDivElementProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>;

export interface Props extends HtmlDivElementProps {
  children?: never;
  contactListResource: ContactListResource;
}

const INITIAL_GROUP_LIST_STATE: ReadonlyArray<ContactListItem> = EMPTY_ARRAY;

export const ActiveGroupSelectionBar = React.forwardRef<HTMLDivElement, Props>(
  ({ children, contactListResource, ...rootProps }, ref) => {
    const [, appDispatch] = useAppState();
    const router = useHistory();
    const activeGroupId = useSelector(activeGroupIdSelector);
    const roleId = useCurrentUser()?.role;
    const isUserRoleNonGuest = Utils.isNonGuestRole(roleId);
    const isUserRoleCaregiver = Utils.isCaregiverRole(roleId);
    const [formApi, setFormApi] = React.useState<FormApi<FormFieldName, FormValues>>();
    const [contactList, setContactList] = React.useState(INITIAL_GROUP_LIST_STATE);
    const [selectedContact, setSelectedContact] = React.useState<typeof contactList[0] | null>(null);

    const resetSelectedContact = React.useCallback(
      (api: FormApi<FormFieldName, FormValues>) => {
        if (Utils.isNil(api) || isUserRoleCaregiver) return;

        let index = contactList.findIndex(({ id }) => id === activeGroupId);
        if (index === -1 && contactList.length > 0) index = 0;

        const selectedContact = index > -1 ? contactList[index] : null!;
        api.change('group', selectedContact);
      },
      [activeGroupId, contactList, isUserRoleCaregiver]
    );

    const onFormReady = React.useMemo<FormProps['onReady']>(() => {
      return !isUserRoleNonGuest && !isUserRoleCaregiver
        ? undefined
        : ({ api }) => {
            resetSelectedContact(api);
            setFormApi(api);
          };
    }, [isUserRoleCaregiver, isUserRoleNonGuest, resetSelectedContact]);

    const onFieldStateChange = React.useMemo<FormProps['onFieldStateChange']>(() => {
      return !isUserRoleNonGuest && !isUserRoleCaregiver
        ? undefined
        : ({ fieldName, fieldState: { value } }) => {
            if (fieldName !== 'group') return;

            if (Utils.isNotNil(value)) {
              if (isUserRoleNonGuest) {
                appDispatch(setActiveGroup(value.id));
              }

              setSelectedContact(value);

              if (isUserRoleCaregiver) {
                appDispatch(actOnBehalfForCareRecipientAction({ actOnBehalfFor: { id: value.id } }));
                router.push('/');
              }
            } else {
              setSelectedContact(null);
            }
          };
    }, [appDispatch, isUserRoleCaregiver, isUserRoleNonGuest, router]);

    React.useEffect(() => {
      if (isUserRoleNonGuest || isUserRoleCaregiver) {
        let contactList = INITIAL_GROUP_LIST_STATE;
        try {
          contactList = contactListResource.value();
        } catch (error) {
          /* ignore */
        }

        setContactList(contactList);
        resetSelectedContact(formApi!);
      }
    }, [formApi, contactListResource, isUserRoleCaregiver, isUserRoleNonGuest, resetSelectedContact]);

    const fallbackNode = React.useMemo(() => <CircularProgress />, []);

    return (!isUserRoleNonGuest && !isUserRoleCaregiver) ||
      (Utils.isArray(contactList) && contactList.length < 1) ? null : (
      <div ref={ref} {...rootProps}>
        {Utils.isNil(contactList) ? (
          fallbackNode
        ) : (
          <React.Suspense fallback={fallbackNode}>
            {selectedContact?.type === 'group' && (
              <div styleName="style.institute">{selectedContact.groupData.institute?.name}</div>
            )}

            <Form
              groupList={contactList}
              onFieldStateChange={onFieldStateChange}
              onReady={onFormReady}
              styleName="style.form"
              isCaregiver={Utils.isCaregiverRole(roleId)}
            />
          </React.Suspense>
        )}
      </div>
    );
  }
);

ActiveGroupSelectionBar.displayName = 'ActiveGroupSelectionBar';
ActiveGroupSelectionBar.defaultProps = { className: style.root };
