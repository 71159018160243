import { MessagingException, Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { recallMessagesAction } from '../../../../app-state/actions/messaging/recall-message-action';
import { useAppState } from '../../../../app-state/hooks';
import { useDispatchFetchServerDateTime } from '../../../../app-state/hooks/use-dispatch-fetch-server-date-time';
import { selectCanRecallMessage } from '../../../../app-state/selectors/auth';
import { ActionData } from '../../types';
import { BaseContextValue } from '../context';
import { MessageFolderGridItem } from '../folder-item-grid/types';

export interface ActionDataRecallMessage extends ActionData {
  currentFolder?: BaseContextValue['currentFolder'];
  messageList: ReturnType<BaseContextValue['getSelectedMessageList']>;
}

export const useRecallMessageActionHandler = () => {
  const [, appDispatch] = useAppState();
  const dispatchFetchServerDateTime = useDispatchFetchServerDateTime();
  const canRecallMessage = useSelector(selectCanRecallMessage);

  return React.useCallback(
    async ({ currentFolder, failCallback, messageList, successCallback }: ActionDataRecallMessage) => {
      let value: any;

      try {
        const dtServer = await dispatchFetchServerDateTime();

        const isWithinRecallWindow = ({ timestamp }: MessageFolderGridItem) => {
          const sentAt = Utils.isInteger(timestamp) ? timestamp : timestamp.createdAt!;
          return canRecallMessage(dtServer, sentAt);
        };

        if (!messageList.every(isWithinRecallWindow)) {
          const errorRecallWindowClosed = new MessagingException();
          errorRecallWindowClosed.name = 'RecallWindowClosedError';
          throw errorRecallWindowClosed;
        }

        value = await appDispatch(
          recallMessagesAction({
            folderKey: currentFolder!.folderKey,
            parentFolderKey: currentFolder!.parentFolderKey,
            msgMetadataId: messageList.map(({ header }) => header)
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch, canRecallMessage, dispatchFetchServerDateTime]
  );
};
