import {
  SentimentDissatisfied as IconSentimentDissatisfied,
  SentimentSatisfied as IconSentimentSatisfied,
  SentimentSatisfiedAlt as IconSentimentSatisfiedAlt,
  SentimentVeryDissatisfied as IconSentimentVeryDissatisfied,
  SentimentVerySatisfied as IconSentimentVerySatisfied
} from '@material-ui/icons';
import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.Form.VitalsQuestionnaire = {
  fieldsetActivity: {
    formField: {
      activity: {
        // Translators: Label for the Activity input of Vitals form
        label: gettext('health-data', 'activity', 'vitalsFormInputLabel'),
        options: [
          {
            codedValue: 0,
            // Translators: Label for the Minimal Physical Activity option of \
            // Activity input in Vitals form
            label: gettext('health-data', 'minimal', 'vitalsFormActivityOptionLabel')
          },
          {
            codedValue: 1,
            // Translators: Label for the Some Walking At Home option of \
            // Activity input in Vitals form
            label: gettext('health-data', 'someWalking', 'vitalsFormActivityOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Regular Activities At Home option of \
            // Activity input in Vitals form
            label: gettext('health-data', 'regular', 'vitalsFormActivityOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Walking A Few Times Per Week Plus \
            // Activities At Home option of Activity input in Vitals form
            label: gettext('health-data', 'walkingFewTimes', 'vitalsFormActivityOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Walking Every Day option of Activity \
            // input in Vitals form
            label: gettext('health-data', 'walkingEveryDay', 'vitalsFormActivityOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Regular Vigorous Physical Activity \
            // option of Activity input in Vitals form
            label: gettext('health-data', 'vigorous', 'vitalsFormActivityOptionLabel')
          },
          {
            codedValue: 'other',
            // Translators: Label for the Other option of Activity input in \
            // Vitals form
            label: gettext('health-data', 'other', 'vitalsFormActivityOptionLabel')
          }
        ]
      },
      otherActivity: {
        label: '',
        // Translators: Hint displayed in Other Activity input of Vitals form
        placeholder: gettext('health-data', 'otherActivity', 'vitalsFormInputHint'),
        error: {
          // Translators: Error displayed when no option is selected for Other \
          // Activity input of Vitals form
          valueMissing: gettext('health-data', 'otherActivityRequired', 'vitalsFormError')
        }
      }
    }
  },
  fieldsetAlcohol: {
    formField: {
      alcohol: {
        // Translators: Label for the Alcohol input of Vitals form
        label: gettext('health-data', 'alcohol', 'vitalsFormInputLabel'),
        options: [
          {
            codedValue: 0,
            // Translators: Label for the Never option of Alcohol input in \
            // Vitals form
            label: gettext('health-data', 'never', 'vitalsFormAlcoholOptionLabel')
          },
          {
            codedValue: 1,
            // Translators: Label for the Monthly option of Alcohol input in \
            // Vitals form
            label: gettext('health-data', 'monthly', 'vitalsFormAlcoholOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Two Per Month option of Alcohol \
            // input in Vitals form
            label: gettext('health-data', 'twoPerMonth', 'vitalsFormAlcoholOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Two Per Week option of Alcohol \
            // input in Vitals form
            label: gettext('health-data', 'twoPerWeek', 'vitalsFormAlcoholOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Four Per Week option of Alcohol \
            // input in Vitals form
            label: gettext('health-data', 'fourPerWeek', 'vitalsFormAlcoholOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Every Day option of Alcohol \
            // input in Vitals form
            label: gettext('health-data', 'everyDay', 'vitalsFormAlcoholOptionLabel')
          }
        ]
      }
    }
  },
  fieldsetBloodPressure: {
    // Translators: Label for the Blood Pressure fieldset of Vitals form
    label: gettext('health-data', 'bloodPressure', 'vitalsFormFieldsetLabel'),
    formField: {
      systolic: {
        // Translators: Label for the Systolic input of Vitals form
        label: gettext('health-data', 'systolic', 'vitalsFormInputLabel'),
        // Translators: Label for the range values of Systolic input in Vitals \
        // form
        rangeLabel: gettext('health-data', 'systolic', 'vitalsFormInputRangeLabel')
      },
      diastolic: {
        // Translators: Label for the Diastolic input of Vitals form
        label: gettext('health-data', 'diastolic', 'vitalsFormInputLabel'),
        // Translators: Label for the range values of Diastolic input in Vitals\
        // form
        rangeLabel: gettext('health-data', 'diastolic', 'vitalsFormInputRangeLabel')
      }
    }
  },
  fieldsetChangeInWeight: {
    formField: {
      changeInWeight: {
        // Translators: Label for the Change in Weight input of Vitals form
        label: gettext('health-data', 'changeInWeight', 'vitalsFormInputLabel'),
        options: [
          {
            codedValue: 0,
            // Translators: Label for the No Change option of Change in Weight \
            // input in Vitals form
            label: gettext('health-data', 'noChange', 'vitalsFormWeightOptionLabel')
          },
          {
            codedValue: 1,
            // Translators: Label for the Increased option of Change in Weight \
            // input in Vitals form
            label: gettext('health-data', 'increased', 'vitalsFormWeightOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Decreased option of Change in Weight \
            // input in Vitals form
            label: gettext('health-data', 'decreased', 'vitalsFormWeightOptionLabel')
          },
          {
            codedValue: 'other',
            // Translators: Label for the Other option of Change in Weight \
            // input in Vitals form
            label: gettext('health-data', 'other', 'vitalsFormWeightOptionLabel')
          }
        ]
      },
      otherChangeInWeight: {
        label: '',
        // Translators: Hint displayed in Other Change in Weight input of \
        // Vitals form
        placeholder: gettext('health-data', 'otherChangeInWeight', 'vitalsFormInputHint'),
        error: {
          // Translators: Error displayed when Other Change in Weight input \
          //  is left blank in Vitals form
          valueMissing: gettext('health-data', 'otherChangeInWeightRequired', 'vitalsFormError')
        }
      }
    }
  },
  fieldsetDiet: {
    // Translators: Label for the Diet fieldset of Vitals form
    label: gettext('health-data', 'diet', 'vitalsFormFieldsetLabel'),
    formField: {
      diet: {
        // Translators: Label for the Diet input of Vitals form
        label: gettext('health-data', 'diet', 'vitalsFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Balanced option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'balanced', 'vitalsFormDietOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Vegetarian option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'vegetarian', 'vitalsFormDietOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Vegan option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'vegan', 'vitalsFormDietOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Low Carb option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'lowCarb', 'vitalsFormDietOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Low Fat option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'lowFat', 'vitalsFormDietOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Low Sodium option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'lowSodium', 'vitalsFormDietOptionLabel')
          },
          {
            codedValue: 7,
            // Translators: Label for the Average option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'average', 'vitalsFormDietOptionLabel')
          },
          {
            codedValue: 8,
            // Translators: Label for the Better option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'better', 'vitalsFormDietOptionLabel')
          },
          {
            codedValue: 'other',
            // Translators: Label for the Other option of Diet input in \
            // Vitals form
            label: gettext('health-data', 'other', 'vitalsFormDietOptionLabel')
          }
        ]
      },
      otherDiet: {
        label: '',
        // Translators: Hint displayed in Other Diet input of Vitals form
        placeholder: gettext('health-data', 'otherDiet', 'vitalsFormInputHint'),
        error: {
          // Translators: Error displayed when Other Diet input is left blank \
          // in Vitals form
          valueMissing: gettext('health-data', 'otherDietRequired', 'vitalsFormError')
        }
      }
    }
  },
  fieldsetHeartRate: {
    formField: {
      heartRate: {
        // Translators: Label for the Heart Rate input of Vitals form
        label: gettext('health-data', 'heartRate', 'vitalsFormInputLabel'),
        // Translators: Label for the range values of Heart Rate input in \
        // Vitals form
        rangeLabel: gettext('health-data', 'heartRate', 'vitalsFormInputRangeLabel')
      }
    }
  },
  fieldsetHeight: {
    // Translators: Label for the Height fieldset of Vitals form
    label: gettext('health-data', 'height', 'vitalsFormFieldsetLabel'),
    formField: {
      height: {
        // Translators: Label for the Height input of Vitals form
        label: gettext('health-data', 'height', 'vitalsFormInputLabel'),
        // Translators: Hint displayed in Height input of Vitals form when \
        // CMS is selected as the unit of value
        placeholderCMS: gettext('health-data', 'heightInCMS', 'vitalsFormInputHint'),
        // Translators: Hint displayed in Height input of Vitals form when \
        // Feet is selected as the unit of value
        placeholderFeet: gettext('health-data', 'heightInFeet', 'vitalsFormInputHint')
      },
      heightToggle: {
        // Translators: Label for screen-readers when Feet is selected as the \
        // unit of height
        ariaLabelCMS: gettext('health-data', 'heightInCMS', 'vitalsFormToggleInputAriaLabel'),
        // Translators: Label for screen-readers when Centimeters is selected \
        // as the unit of height
        ariaLabelFeet: gettext('health-data', 'heightInFeet', 'vitalsFormToggleInputAriaLabel'),
        // Translators: Label for the Height Toggle Centimeters input of \
        // Vitals form
        labelCMS: gettext('health-data', 'heightInCMS', 'vitalsFormToggleInputLabel'),
        // Translators: Label for the Height Toggle Feet input of Vitals form
        labelFeet: gettext('health-data', 'heightInFeet', 'vitalsFormToggleInputLabel'),
        label: ''
      }
    }
  },
  fieldsetNotes: {
    // Translators: Label for the Notes fieldset of Vitals form
    label: gettext('health-data', 'notes', 'vitalsFormFieldsetLabel'),
    formField: {
      notes: {
        // Translators: Label for the Notes input of Vitals form
        label: gettext('health-data', 'notes', 'vitalsFormInputLabel'),
        // Translators: Hint for the Notes input of Vitals form
        placeholder: gettext('health-data', 'notes', 'vitalsFormInputHint')
      }
    }
  },
  fieldsetPulseOximeter: {
    formField: {
      pulseOximeter: {
        // Translators: Label for the Pulse Oximeter input of Vitals form
        label: gettext('health-data', 'pulseOximeter', 'vitalsFormInputLabel'),
        // Translators: Label for the range values of Pulse Oximeter input in \
        // Vitals form
        rangeLabel: gettext('health-data', 'pulseOximeter', 'vitalsFormInputRangeLabel')
      }
    }
  },
  fieldsetSymptoms: {
    // Translators: Label for the Symptoms fieldset of Vitals form
    label: gettext('health-data', 'symptoms', 'vitalsFormFieldsetLabel'),
    formField: {
      wellBeing: {
        // Translators: Label for the Well Being input of Vitals form
        label: gettext('health-data', 'wellBeing', 'vitalsFormInputLabel'),
        options: [
          {
            codedValue: 0,
            icon: IconSentimentVerySatisfied,
            // Translators: Label for the Positive option of Well Being input \
            // in Vitals form
            label: gettext('health-data', 'positive', 'vitalsFormWellBeingOptionLabel')
          },
          {
            codedValue: 1,
            icon: IconSentimentSatisfiedAlt,
            // Translators: Label for the Low Positive option of Well Being \
            // input in Vitals form
            label: gettext('health-data', 'lowPositive', 'vitalsFormWellBeingOptionLabel')
          },
          {
            codedValue: 2,
            icon: IconSentimentSatisfied,
            // Translators: Label for the Distress option of Well Being input \
            // in Vitals form
            label: gettext('health-data', 'distress', 'vitalsFormWellBeingOptionLabel')
          },
          {
            codedValue: 3,
            icon: IconSentimentDissatisfied,
            // Translators: Label for the Serious option of Well Being input \
            // in Vitals form
            label: gettext('health-data', 'serious', 'vitalsFormWellBeingOptionLabel')
          },
          {
            codedValue: 4,
            icon: IconSentimentVeryDissatisfied,
            // Translators: Label for the Severe option of Well Being input \
            // in Vitals form
            label: gettext('health-data', 'severe', 'vitalsFormWellBeingOptionLabel')
          }
        ]
      },
      dyspnea: {
        // Translators: Label for the Dyspnea input of Vitals form
        label: gettext('health-data', 'dyspnea', 'vitalsFormInputLabel'),
        options: [
          {
            codedValue: 0,
            icon: IconSentimentVerySatisfied,
            // Translators: Label for the Controlled option of Dyspnea input \
            // in Vitals form
            label: gettext('health-data', 'controlled', 'vitalsFormDyspneaOptionLabel')
          },
          {
            codedValue: 1,
            icon: IconSentimentSatisfiedAlt,
            // Translators: Label for the Mild option of Dyspnea input in \
            // Vitals form
            label: gettext('health-data', 'mild', 'vitalsFormDyspneaOptionLabel')
          },
          {
            codedValue: 2,
            icon: IconSentimentSatisfied,
            // Translators: Label for the Moderate option of Dyspnea input in \
            // Vitals form
            label: gettext('health-data', 'moderate', 'vitalsFormDyspneaOptionLabel')
          },
          {
            codedValue: 3,
            icon: IconSentimentDissatisfied,
            // Translators: Label for the Severe option of Dyspnea input in \
            // Vitals form
            label: gettext('health-data', 'severe', 'vitalsFormDyspneaOptionLabel')
          },
          {
            codedValue: 4,
            icon: IconSentimentVeryDissatisfied,
            // Translators: Label for the Acute option of Dyspnea input in \
            // Vitals form
            label: gettext('health-data', 'acute', 'vitalsFormDyspneaOptionLabel')
          }
        ]
      },
      swelling: {
        // Translators: Label for the Swelling input of Vitals form
        label: gettext('health-data', 'swelling', 'vitalsFormInputLabel'),
        options: [
          {
            codedValue: 0,
            icon: IconSentimentVerySatisfied,
            // Translators: Label for the None option of Swelling input in \
            // Vitals form
            label: gettext('health-data', 'none', 'vitalsFormSwellingOptionLabel')
          },
          {
            codedValue: 1,
            icon: IconSentimentSatisfiedAlt,
            // Translators: Label for the Trace option of Swelling input in \
            // Vitals form
            label: gettext('health-data', 'trace', 'vitalsFormSwellingOptionLabel')
          },
          {
            codedValue: 2,
            icon: IconSentimentSatisfied,
            // Translators: Label for the MIld option of Swelling input in \
            // Vitals form
            label: gettext('health-data', 'mild', 'vitalsFormSwellingOptionLabel')
          },
          {
            codedValue: 3,
            icon: IconSentimentDissatisfied,
            // Translators: Label for the Moderate option of Swelling input in \
            // Vitals form
            label: gettext('health-data', 'moderate', 'vitalsFormSwellingOptionLabel')
          },
          {
            codedValue: 4,
            icon: IconSentimentVeryDissatisfied,
            // Translators: Label for the Severe option of Swelling input in \
            // Vitals form
            label: gettext('health-data', 'severe', 'vitalsFormSwellingOptionLabel')
          }
        ]
      }
    }
  },
  fieldsetWeight: {
    // Translators: Label for the Weight fieldset of Vitals form
    label: gettext('health-data', 'weight', 'vitalsFormFieldsetLabel'),
    formField: {
      weightToggle: {
        // Translators: Label for screen-readers when KGS is selected as the \
        // unit of weight
        ariaLabelKGS: gettext('health-data', 'weightInKGS', 'vitalsFormInputAriaLabel'),
        // Translators: Label for screen-readers when LBS is selected as the \
        // unit of weight
        ariaLabelLBS: gettext('health-data', 'weightInLBS', 'vitalsFormInputAriaLabel'),
        label: '',
        // Translators: Label for the Weight Toggle KGS input of Vitals form
        labelKGS: gettext('health-data', 'weightInKGS', 'vitalsFormToggleInputLabel'),
        // Translators: Label for the Weight Toggle LBS input of Vitals form
        labelLBS: gettext('health-data', 'weightInLBS', 'vitalsFormToggleInputLabel')
      },
      weight: {
        // Translators: Label for the Weight input of Vitals form
        label: gettext('health-data', 'weight', 'vitalsFormInputLabel'),
        // Translators: Hint displayed in Weight input of Vitals form when \
        // KGS is selected as the unit of value
        placeholderKGS: gettext('health-data', 'weightInKGS', 'vitalsFormInputHint'),
        // Translators: Hint displayed in Weight input of Vitals form when \
        // LBLS is selected as the unit of value
        placeholderLBS: gettext('health-data', 'weightInLBS', 'vitalsFormInputHint')
      }
    }
  }
};

export default i18n;
