import { MFAActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AppThunk } from '../..';
import * as AuthSelectors from '../../selectors/auth';

export const verifyOTPAction = (payload: MFAActionPayload.VerifyOTP): AppThunk<Promise<void>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'verifyOTPAction:');

    Logger.info('== BEGIN ==');
    try {
      const accessToken = Utils.isString(payload.accessToken) ? payload.accessToken : AuthSelectors.accessTokenSelector(getState());
      return await apiService.verifyOTP(accessToken, payload.authState, payload.code);
    } finally {
      Logger.info('== END ==');
    }
  };
};
