import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: ContactListI18n.View.ContactListView['contactCardDataLabel'] = {
  // Translators: Label for the Academic Degrees data item in Contact Card view
  academicDegrees: gettext('contact-list', 'academicDegrees', 'contactCardDataLabel'),
  // Translators: Label for the Address data item in Contact Card view
  address: gettext('contact-list', 'address', 'contactCardDataLabel'),
  // Translators: Label for the Birth Date data item in Contact Card view
  birthDate: gettext('contact-list', 'birthDate', 'contactCardDataLabel'),
  // Translators: Label header for the Caregiver list in Contact Card view
  caregiverList: gettext('contact-list', 'caregiverList', 'contactCardDataLabel'),
  // Translators: Label for the Cell Number data item in Contact Card view
  cellNumber: gettext('contact-list', 'cellNumber', 'contactCardDataLabel'),
  // Translators: Label for the Email Address data item in Contact Card view
  emailAddress: gettext('contact-list', 'emailAddress', 'contactCardDataLabel'),
  // Translators: Label for the Name data item in Contact Card view
  entity: gettext('contact-list', 'entity', 'contactCardDataLabel'),
  // Translators: Label for the Fax Number data item in Contact Card view
  faxNumber: gettext('contact-list', 'faxNumber', 'contactCardDataLabel'),
  // Translators: Label for the Gender data item in Contact Card view
  gender: gettext('contact-list', 'gender', 'contactCardDataLabel'),
  // Translators: Label for the Health Plan data item in Contact Card view
  healthPlan: gettext('contact-list', 'healthPlan', 'contactCardDataLabel'),
  // Translators: Label for the Home Number data item in Contact Card view
  homeNumber: gettext('contact-list', 'homeNumber', 'contactCardDataLabel'),
  // Translators: Label for the Institute data item in Contact Card view
  institute: gettext('contact-list', 'institute', 'contactCardDataLabel'),
  // Translators: Label for the Institute Address data item in Contact Card view
  instituteAddress: gettext('contact-list', 'instituteAddress', 'contactCardDataLabel'),
  // Translators: Label for the Institute Email Address data item in Contact \
  // Card view
  instituteEmailAddress: gettext('contact-list', 'instituteEmailAddress', 'contactCardDataLabel'),
  // Translators: Label for the Institute Fax Number data item in Contact \
  // Card view
  instituteFaxNumber: gettext('contact-list', 'instituteFaxNumber', 'contactCardDataLabel'),
  // Translators: Label for the Institute Phone Number data item in Contact \
  // Card view
  institutePhoneNumber: gettext('contact-list', 'institutePhoneNumber', 'contactCardDataLabel'),
  // Translators: Label for the License Number data item in Contact Card view
  licenseNumber: gettext('contact-list', 'licenseNumber', 'contactCardDataLabel'),
  // Translators: Label for the Member Count data item in Contact Card view
  memberCount: gettext('contact-list', 'memberCount ', 'contactCardDataLabel'),
  // Translators: Label header for the Member list in Contact Card view
  memberList: gettext('contact-list', 'memberList ', 'contactCardDataLabel'),
  // Translators: Label for the Office Number data item in Contact Card view
  officeNumber: gettext('contact-list', 'officeNumber', 'contactCardDataLabel'),
  // Translators: Label for the Other Specialties data item in Contact Card view
  otherSpecialties: gettext('contact-list', 'otherSpecialties', 'contactCardDataLabel'),
  // Translators: Label for the Specialty data item in Contact Card view
  specialty: gettext('contact-list', 'specialty', 'contactCardDataLabel')
};

export default i18n;
