import { Button, ButtonGroup } from '@material-ui/core';
import CallEnd from '@material-ui/icons/CallEnd';
import MicOn from '@material-ui/icons/Mic';
import MicOff from '@material-ui/icons/MicOff';
import CameraOn from '@material-ui/icons/Videocam';
import CameraOff from '@material-ui/icons/VideocamOff';
import { Utils } from '@sigmail/common';
// import { getLoggerWithPrefix } from '@sigmail/logging';
// import { Logger } from 'loglevel';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { Participant, Track } from 'twilio-video';
import * as ActionId from '../../../constants/action-ids';
import { withTranslation } from '../../../i18n';
import { I18N_NS_SCHEDULING } from '../../../i18n/config/namespace-identifiers';
import schedulingI18n from '../../../i18n/scheduling';
import { EventFlags } from '../../../utils';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { PersonNameAvatar } from '../../shared/person-name-avatar.component';
import { Context } from './meeting-room.context';
import style from './participant-info.module.css';
import { IAudioTrack, ITrackPublication, IVideoTrack } from './types';

const { meetingRoom: i18n } = schedulingI18n.dialog;

export interface Props {
  participant: Participant;
  isMainParticipant?: boolean | null | undefined;
  onClickAction?: (actionId: string) => any;
}

interface State {
  publicationList: ReadonlyArray<ITrackPublication>;
  audioPublication?: ITrackPublication;
  audioTrack?: IAudioTrack;
  videoPublication?: ITrackPublication;
  videoTrack?: IVideoTrack;
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
  isVideoSwitchedOff: boolean;
  isReconnecting: boolean;
}

function findPublicationByKind(
  list: ReadonlyArray<ITrackPublication>,
  kind: Track.Kind
): ITrackPublication | undefined {
  return list.find(
    ({ kind: trackKind, trackName }) => trackKind === kind && (kind !== 'video' || !trackName.includes('screen'))
  );
}

interface AddRemoveEventListener {
  (eventName: 'trackPublished', participant: Participant | undefined): void;
  (eventName: 'trackUnpublished', participant: Participant | undefined): void;
  (eventName: 'trackSubscribed', publication: ITrackPublication | undefined): void;
  (eventName: 'trackUnsubscribed', publication: ITrackPublication | undefined): void;
  (eventName: 'audioEnabled', track: IAudioTrack | undefined): void;
  (eventName: 'audioDisabled', track: IAudioTrack | undefined): void;
  (eventName: 'videoSwitchedOn', track: IVideoTrack | undefined): void;
  (eventName: 'videoSwitchedOff', track: IVideoTrack | undefined): void;
}

interface ComponentProps extends Props, WithTranslation {}

class ParticipantInfoComponent extends React.PureComponent<ComponentProps, State> {
  public static contextType = Context;
  context!: React.ContextType<typeof Context>;

  public static getDerivedStateFromProps(
    { participant }: Readonly<ComponentProps>,
    prevState: State
  ): Partial<State> | null {
    const publicationList = Array.from(participant.tracks.values() as IterableIterator<ITrackPublication>);

    let { audioTrack, videoTrack } = prevState;

    const audioPublication = findPublicationByKind(publicationList, 'audio');
    let { isAudioEnabled } = prevState;
    if (audioPublication !== prevState.audioPublication) {
      audioTrack = audioPublication?.track as IAudioTrack | undefined;
      isAudioEnabled = audioTrack?.isEnabled === true;
    }

    const videoPublication = findPublicationByKind(publicationList, 'video');
    const isVideoEnabled = Boolean(videoPublication);
    if (videoPublication !== prevState.videoPublication) {
      videoTrack = videoPublication?.track as IVideoTrack | undefined;
    }
    const isVideoSwitchedOff = videoTrack?.isSwitchedOff === true;

    return {
      publicationList,
      audioPublication,
      audioTrack,
      videoPublication,
      videoTrack,
      isAudioEnabled,
      isVideoEnabled,
      isVideoSwitchedOff
    };
  }

  // private readonly logger: Logger;
  protected readonly addEventListener: AddRemoveEventListener;
  protected readonly removeEventListener: AddRemoveEventListener;
  protected readonly setAudioTrack: (track: IAudioTrack) => void;
  protected readonly removeAudioTrack: () => void;
  protected readonly setVideoTrack: (track: IAudioTrack) => void;
  protected readonly removeVideoTrack: () => void;
  protected readonly setVideoSwitchedOn: () => void;
  protected readonly setVideoSwitchedOff: () => void;

  public constructor(props: ComponentProps) {
    super(props);

    // this.logger = getLoggerWithPrefix(
    //   'MeetingRoomParticipantInfo',
    //   `<${props.isMainParticipant === true ? 'Main' : ''}Participant$${props.participant.identity}>`
    // );

    this.state = {
      publicationList: [],
      isAudioEnabled: false,
      isVideoEnabled: false,
      isVideoSwitchedOff: true,
      isReconnecting: false
    };

    this.onClickAction = this.onClickAction.bind(this);
    this.onTrackPublished = this.onTrackPublished.bind(this);
    this.onTrackUnpublished = this.onTrackUnpublished.bind(this);
    this.setAudioTrack = this.setTrack.bind(this, 'audio');
    this.removeAudioTrack = this.removeTrack.bind(this, 'audio');
    this.toggleAudioEnabled = this.toggleAudioEnabled.bind(this);
    this.setVideoTrack = this.setTrack.bind(this, 'video');
    this.removeVideoTrack = this.removeTrack.bind(this, 'video');
    this.setVideoSwitchedOn = this.toggleVideoSwitchedOff.bind(this, false);
    this.setVideoSwitchedOff = this.toggleVideoSwitchedOff.bind(this, true);

    this.addEventListener = (eventName, ...args: any[]) => {
      if (args.length === 0 || Utils.isNil(args[0])) return;

      // this.logger.info('addEventListener:', `eventName=${eventName},`, args);
      switch (eventName) {
        case 'trackPublished': {
          const participant = args[0] as Participant;
          participant.on('trackPublished', this.onTrackPublished);
          break;
        }
        case 'trackUnpublished': {
          const participant = args[0] as Participant;
          participant.on('trackUnpublished', this.onTrackUnpublished);
          break;
        }
        case 'trackSubscribed': {
          const publication = args[0] as ITrackPublication;
          if (publication.kind !== 'data') {
            publication.on('subscribed', publication.kind === 'audio' ? this.setAudioTrack : this.setVideoTrack);
          }
          break;
        }
        case 'trackUnsubscribed': {
          const publication = args[0] as ITrackPublication;
          if (publication.kind !== 'data') {
            publication.on(
              'unsubscribed',
              publication.kind === 'audio' ? this.removeAudioTrack : this.removeVideoTrack
            );
          }
          break;
        }
        case 'audioEnabled':
        case 'audioDisabled': {
          const track = args[0] as IAudioTrack;
          track.on(eventName === 'audioEnabled' ? 'enabled' : 'disabled', this.toggleAudioEnabled);
          break;
        }
        case 'videoSwitchedOn': {
          const track = args[0] as IVideoTrack;
          track.on('switchedOn', this.setVideoSwitchedOn);
          break;
        }
        case 'videoSwitchedOff': {
          const track = args[0] as IVideoTrack;
          track.on('switchedOff', this.setVideoSwitchedOff);
          break;
        }
        default: {
          break;
        }
      }
    };

    this.removeEventListener = (eventName, ...args: any[]) => {
      if (args.length === 0 || Utils.isNil(args[0])) return;

      // this.logger.info('removeEventListener:', `eventName=${eventName},`, args);
      switch (eventName) {
        case 'trackPublished': {
          const participant = args[0] as Participant;
          participant.off('trackPublished', this.onTrackPublished);
          break;
        }
        case 'trackUnpublished': {
          const participant = args[0] as Participant;
          participant.off('trackUnpublished', this.onTrackUnpublished);
          break;
        }
        case 'trackSubscribed': {
          const publication = args[0] as ITrackPublication;
          if (publication.kind !== 'data') {
            publication.off('subscribed', publication.kind === 'audio' ? this.setAudioTrack : this.setVideoTrack);
          }
          break;
        }
        case 'trackUnsubscribed': {
          const publication = args[0] as ITrackPublication;
          if (publication.kind !== 'data') {
            publication.off(
              'unsubscribed',
              publication.kind === 'audio' ? this.removeAudioTrack : this.removeVideoTrack
            );
          }
          break;
        }
        case 'audioEnabled':
        case 'audioDisabled': {
          const track = args[0] as IAudioTrack;
          track.off(eventName === 'audioEnabled' ? 'enabled' : 'disabled', this.toggleAudioEnabled);
          break;
        }
        case 'videoSwitchedOn': {
          const track = args[0] as IVideoTrack;
          track.off('switchedOn', this.setVideoSwitchedOn);
          break;
        }
        case 'videoSwitchedOff': {
          const track = args[0] as IVideoTrack;
          track.off('switchedOff', this.setVideoSwitchedOff);
          break;
        }
        default: {
          break;
        }
      }
    };

    this.addEventListener('trackPublished', props.participant);
    this.addEventListener('trackUnpublished', props.participant);
  }

  public componentDidMount(): void {
    const { audioTrack, videoTrack } = this.state;

    this.addEventListener('audioEnabled', audioTrack);
    this.addEventListener('audioDisabled', audioTrack);

    this.addEventListener('videoSwitchedOn', videoTrack);
    this.addEventListener('videoSwitchedOff', videoTrack);
  }

  public getSnapshotBeforeUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<State>): any {
    const { participant } = this.props;
    const { participant: prevParticipant } = prevProps;
    const { audioPublication, audioTrack, videoPublication, videoTrack } = this.state;

    const {
      audioPublication: prevAudioPublication,
      audioTrack: prevAudioTrack,
      videoPublication: prevVideoPublication,
      videoTrack: prevVideoTrack
    } = prevState;

    if (prevParticipant !== participant) {
      // this.logger.info(`Participant changed: identity=${participant.identity}, sid=${participant.sid}`);
      this.removeEventListener('trackPublished', prevParticipant);
      this.removeEventListener('trackUnpublished', prevParticipant);
    }

    if (audioPublication !== prevAudioPublication) {
      // this.logger.info(
      //   `Audio publication changed:`,
      //   `trackName={current:${audioPublication?.trackName}, previous:${prevAudioPublication?.trackName}}`
      // );
      this.removeEventListener('trackSubscribed', prevAudioPublication);
      this.removeEventListener('trackUnsubscribed', prevAudioPublication);
    }

    if (audioTrack !== prevAudioTrack) {
      // this.logger.info(
      //   `Audio track changed: trackName={current:${audioTrack?.name}, previous:${prevAudioTrack?.name}}`
      // );
      this.removeEventListener('audioEnabled', prevAudioTrack);
      this.removeEventListener('audioDisabled', prevAudioTrack);
    }

    if (videoPublication !== prevVideoPublication) {
      // this.logger.info(
      //   `Video publication changed:`,
      //   `trackName={current:${videoPublication?.trackName}, previous:${prevVideoPublication?.trackName}}`
      // );
      this.removeEventListener('trackSubscribed', prevVideoPublication);
      this.removeEventListener('trackUnsubscribed', prevVideoPublication);
    }

    if (videoTrack !== prevVideoTrack) {
      // this.logger.info(
      //   `Video track changed: trackName={current:${videoTrack?.name}, previous:${prevVideoTrack?.name}}`
      // );
      this.removeEventListener('videoSwitchedOn', prevVideoTrack);
      this.removeEventListener('videoSwitchedOff', prevVideoTrack);
    }

    return null;
  }

  public componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<State>): void {
    const { participant } = this.props;
    const { participant: prevParticipant } = prevProps;
    const { audioPublication, audioTrack, videoPublication, videoTrack } = this.state;

    const {
      audioPublication: prevAudioPublication,
      audioTrack: prevAudioTrack,
      videoPublication: prevVideoPublication,
      videoTrack: prevVideoTrack
    } = prevState;

    if (prevParticipant !== participant) {
      this.addEventListener('trackPublished', participant);
      this.addEventListener('trackUnpublished', participant);
    }

    if (audioPublication !== prevAudioPublication) {
      this.addEventListener('trackSubscribed', audioPublication);
      this.addEventListener('trackUnsubscribed', audioPublication);
    }

    if (audioTrack !== prevAudioTrack) {
      this.addEventListener('audioEnabled', audioTrack);
      this.addEventListener('audioDisabled', audioTrack);
    }

    if (videoPublication !== prevVideoPublication) {
      this.addEventListener('trackSubscribed', videoPublication);
      this.addEventListener('trackUnsubscribed', videoPublication);
    }

    if (videoTrack !== prevVideoTrack) {
      this.addEventListener('videoSwitchedOn', videoTrack);
      this.addEventListener('videoSwitchedOff', videoTrack);
    }
  }

  public componentWillUnmount(): void {
    const { participant } = this.props;
    const { audioPublication, audioTrack, videoPublication, videoTrack } = this.state;

    this.removeEventListener('trackPublished', participant);
    this.removeEventListener('trackUnpublished', participant);

    this.removeEventListener('trackSubscribed', audioPublication);
    this.removeEventListener('trackUnsubscribed', audioPublication);

    this.removeEventListener('audioEnabled', audioTrack);
    this.removeEventListener('audioDisabled', audioTrack);

    this.removeEventListener('trackSubscribed', videoPublication);
    this.removeEventListener('trackUnsubscribed', videoPublication);

    this.removeEventListener('videoSwitchedOn', videoTrack);
    this.removeEventListener('videoSwitchedOff', videoTrack);
  }

  public render(): React.ReactNode {
    const { participant, isMainParticipant, t, children } = this.props;
    const { isLocalVideoEnabled, isLocalAudioEnabled } = this.context;
    const { extendedProps } = this.context.calendarEvent!;
    const { isVideoMeeting, hasVideoConsent } = EventFlags({ extendedProps });
    const { isAudioEnabled, isVideoEnabled, isVideoSwitchedOff } = this.state;

    // this.logger.info(`isAudioEnabled=${isAudioEnabled}`);

    const attendee = extendedProps.attendeeList.find(({ id }) => id === +participant.identity);
    let identity = Utils.isNotNil(attendee) ? Utils.joinPersonName(attendee.userData) : '';
    if (identity.length === 0) identity = `user${participant.identity}`;

    const rootClassName = [style.root, isMainParticipant === true && style['main-participant']]
      .filter(Boolean)
      .join(' ');

    let actionLabelToggleVideo = i18n.action.toggleVideoOn;
    let actionIdToggleVideo = ActionId.ToggleVideoOn;
    if (isLocalVideoEnabled === true) {
      actionLabelToggleVideo = i18n.action.toggleVideoOff;
      actionIdToggleVideo = ActionId.ToggleVideoOff;
    }

    let actionLabelToggleAudio = i18n.action.toggleAudioOn;
    let actionIdToggleAudio = ActionId.ToggleAudioOn;
    if (isLocalAudioEnabled === true) {
      actionLabelToggleAudio = i18n.action.toggleAudioOff;
      actionIdToggleAudio = ActionId.ToggleAudioOff;
    }

    const ariaLabelToggleVideo = t(resolveActionLabel(actionLabelToggleVideo, actionIdToggleVideo));
    const ariaLabelToggleAudio = t(resolveActionLabel(actionLabelToggleAudio, actionIdToggleAudio));

    return (
      <div className={rootClassName} data-cy-participant={participant.identity}>
        <div styleName="style.info-container">
          <div styleName="style.info-row-bottom">
            <div styleName="style.identity">
              <span>{identity}</span>
              {isAudioEnabled !== true && <MicOff aria-label={t(i18n.ariaLabelMuted)} />}
            </div>
          </div>
        </div>

        <div styleName="style.inner-container">
          {(!isVideoEnabled || isVideoSwitchedOff) && (
            <div styleName="style.avatar-container">
              <PersonNameAvatar firstName={attendee?.userData?.firstName} lastName={attendee?.userData?.lastName} />
            </div>
          )}

          {children}
        </div>

        {isMainParticipant && (
          <ButtonGroup variant="outlined">
            {isVideoMeeting && hasVideoConsent(attendee) && (
              <Button
                data-action-id={actionIdToggleVideo}
                aria-label={ariaLabelToggleVideo}
                disabled={isLocalVideoEnabled === null}
                onClick={this.onClickAction}
              >
                {isLocalVideoEnabled === true ? <CameraOn /> : <CameraOff />}
              </Button>
            )}

            <Button
              data-action-id={actionIdToggleAudio}
              aria-label={ariaLabelToggleAudio}
              disabled={isLocalAudioEnabled === null}
              onClick={this.onClickAction}
            >
              {isLocalAudioEnabled === true ? <MicOn /> : <MicOff />}
            </Button>

            <Button data-action-id={ActionId.LeaveMeeting} startIcon={<CallEnd />} onClick={this.onClickAction}>
              {t(resolveActionLabel(i18n.action.leaveMeeting, ActionId.LeaveMeeting))}
            </Button>
          </ButtonGroup>
        )}
      </div>
    );
  }

  protected onClickAction(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    event.stopPropagation();

    const { onClickAction: eventHandler } = this.props;
    if (typeof eventHandler !== 'function') return;

    const actionId = event.currentTarget.getAttribute('data-action-id');
    if (Utils.isString(actionId)) {
      eventHandler(actionId);
    }
  }

  protected onTrackPublished(publication: ITrackPublication): void {
    this.setState(({ publicationList }) => ({
      publicationList: publicationList.concat(publication)
    }));
  }

  protected onTrackUnpublished(publication: ITrackPublication): void {
    this.setState(({ publicationList }) => {
      const index = publicationList.indexOf(publication);
      if (index !== -1) {
        const newPublicationList = publicationList.slice();
        newPublicationList.splice(index, 1);
        return { publicationList: newPublicationList };
      }
      return null;
    });
  }

  protected setTrack(kind: Exclude<Track.Kind, 'data'>, track: IAudioTrack | IVideoTrack): void {
    this.setState(({ audioTrack, videoTrack }) => ({
      audioTrack: kind === 'audio' ? (track as IAudioTrack) : audioTrack,
      videoTrack: kind === 'video' ? (track as IVideoTrack) : videoTrack
    }));
  }

  protected removeTrack(kind: Exclude<Track.Kind, 'data'>): void {
    this.setState(({ audioTrack, videoTrack }) => ({
      audioTrack: kind === 'audio' ? undefined : audioTrack,
      videoTrack: kind === 'video' ? undefined : videoTrack
    }));
  }

  protected toggleAudioEnabled({ isEnabled: isAudioEnabled }: IAudioTrack): void {
    // this.logger.info(`new enabled = ${isAudioEnabled}`);
    this.setState({ isAudioEnabled });
  }

  protected toggleVideoSwitchedOff(switchedOff: boolean): void {
    this.setState({ isVideoSwitchedOff: switchedOff });
  }
}

export const ParticipantInfo = withTranslation(I18N_NS_SCHEDULING)(ParticipantInfoComponent);
(ParticipantInfo as any).displayName = 'ParticipantInfo';
