import { ArrowDropDown as CollapseIcon, ArrowRight as ExpandIcon, Folder as FolderIcon } from '@material-ui/icons';
import { SingleSelectTreeViewProps, TreeItem, TreeView, TreeViewProps } from '@material-ui/lab';
import { NonNullableProps, ReadonlyPartialRecord, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import React from 'react';
import { EMPTY_ARRAY } from '../../app-state/constants';
import { AccuroEMRProviderFolderListResource } from '../../app-state/hooks';
import style from './select-accuro-emr-provider-folder.module.css';

export type ClassKey = `label${'Icon' | 'Root' | 'Text'}` | `listItem${'' | 'Group' | 'Label'}` | 'root';

export interface Props
  extends Omit<React.PropsWithoutRef<TreeViewProps>, 'children' | 'classes' | 'onNodeSelect' | 'onNodeToggle'> {
  children?: never;
  classes?: ReadonlyPartialRecord<ClassKey, string>;
  listResource: AccuroEMRProviderFolderListResource;
  onFolderSelect?: TreeViewProps['onNodeSelect'];
  onFolderToggle?: TreeViewProps['onNodeToggle'];
}

export const DEFAULT_CLASSES: Readonly<NonNullableProps<NonNullable<Props['classes']>>> = {
  labelIcon: style['label-icon'],
  labelRoot: style['label-root'],
  labelText: style['label-text'],
  listItem: style['list-item'],
  listItemGroup: style['list-item-group'],
  listItemLabel: style['list-item-label'],
  root: style.root
};

const nodeIdSet = new Set<string>();

export const SelectAccuroEMRProviderFolder = React.forwardRef<HTMLUListElement, Props>(
  ({ children, classes: classesFromProps, listResource, onFolderSelect, onFolderToggle, ...rootProps }, ref) => {
    nodeIdSet.clear();

    const { root: viewRoot, ...classes } = Utils.defaults({}, classesFromProps, DEFAULT_CLASSES);
    const [, folderList] = listResource.value();
    const [expandedNodeList, setExpandedNodeList] = React.useState<ReadonlyArray<string>>(EMPTY_ARRAY);

    const onNodeToggle = React.useCallback<NonNullable<TreeViewProps['onNodeToggle']>>(
      (event, nodeIdList) => {
        setExpandedNodeList(nodeIdList);
        onFolderToggle?.(event, nodeIdList);
      },
      [onFolderToggle]
    );

    const renderFolderItemList = React.useCallback(
      (
        list: ReadonlyArray<Api.AccuroEMRProviderFolderListItem>,
        parentNodeId?: string
      ): ReadonlyArray<React.ReactNode> =>
        list.map(({ id: folderId, name: folderName, subFolders }) => {
          let nodeId = folderId.toString(10);
          parentNodeId = Utils.trimOrDefault(parentNodeId);
          if (parentNodeId.length > 0) nodeId = `${parentNodeId},${nodeId}`;

          const isDuplicate = nodeIdSet.has(nodeId);
          if (isDuplicate) {
            if (process.env.REACT_APP_ENV !== 'production') {
              // eslint-disable-next-line no-console
              console.warn(`Duplicate folder ID <${folderId}> found in Accuro's API response.`);
            }
          } else {
            nodeIdSet.add(nodeId);
          }

          return isDuplicate ? null : (
            <TreeItem
              classes={{ group: classes.listItemGroup, root: classes.listItem }}
              key={nodeId}
              label={
                <div className={classes.labelRoot}>
                  <FolderIcon className={classes.labelIcon} color="inherit" />
                  <p className={classes.labelText}>{folderName}</p>
                </div>
              }
              nodeId={nodeId}
            >
              {Utils.isArray(subFolders) && renderFolderItemList(subFolders, nodeId)}
            </TreeItem>
          );
        }),
      [classes.labelIcon, classes.labelRoot, classes.labelText, classes.listItem, classes.listItemGroup]
    );

    return (
      <TreeView
        classes={{ root: viewRoot }}
        expanded={expandedNodeList as Array<string>}
        onNodeSelect={onFolderSelect as SingleSelectTreeViewProps['onNodeSelect']}
        onNodeToggle={onNodeToggle}
        ref={ref}
        {...(rootProps as SingleSelectTreeViewProps)}
      >
        {renderFolderItemList(folderList)}
      </TreeView>
    );
  }
);

SelectAccuroEMRProviderFolder.displayName = 'SelectAccuroEMRProviderFolder';

SelectAccuroEMRProviderFolder.defaultProps = {
  defaultCollapseIcon: <CollapseIcon />,
  defaultExpandIcon: <ExpandIcon />,
  multiSelect: false
};
