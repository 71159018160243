import { AppUser, Nullable, Utils } from '@sigmail/common';
import {
  ClientObject,
  ContactListItem,
  ContactListItemExt,
  DataObjectSigmailGlobalContactList,
  DataObjectSigmailGlobalContactListValue
} from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDataObjectByIdSelector } from '.';
import { CIRCLE_OF_CARE } from '../../constants/medical-institute-user-group-type-identifier';
import {
  BatchQueryRequest,
  compareContactListItem,
  ExcludeListItem,
  filterContactList,
  FilterFunction
} from '../../utils/contact-list';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { DataObjectCache } from '../data-objects-slice/cache';
import { currentUserIdSelector, selectAccessRight } from '../selectors/auth';
import { globalContactListIdSelector } from '../selectors/client-object';
import { clientIdSelector } from '../selectors/user-object';

export interface UseGlobalContactListParams {
  exclude?: Nullable<ReadonlyArray<Nullable<ExcludeListItem>>>;
  filterFn?: Nullable<FilterFunction<ContactListItem>>;
  forceFetch?: Nullable<boolean>;
  groups?: Nullable<boolean>;
  unsorted?: Nullable<boolean>;
  users?: Nullable<boolean>;
}

export type UseGlobalContactListResult = [list: ReadonlyArray<ContactListItemExt>, query: BatchQueryRequest];

export const useGlobalContactList = (params?: Nullable<UseGlobalContactListParams>) => {
  const canAccessContactList = useSelector(selectAccessRight)('accessGlobalContacts');
  const currentUserId = useSelector(currentUserIdSelector);
  const contactListId = useSelector(globalContactListIdSelector);
  const dataObjectSelector = useDataObjectByIdSelector();

  let contactListObjectValue: DataObjectSigmailGlobalContactListValue | undefined = undefined;
  if (DataObjectSigmailGlobalContactList.isValidId(contactListId)) {
    const contactListObject = dataObjectSelector<DataObjectSigmailGlobalContactListValue>(contactListId);
    contactListObjectValue = params?.forceFetch === true ? undefined : DataObjectCache.getValue(contactListObject);
  }

  const includeGroupList = params?.groups === true;
  const includeUserList = params?.users === true;
  const exclude = params?.exclude;
  const filterFn = typeof params?.filterFn === 'function' ? params!.filterFn! : filterContactList;
  const unsorted = params?.unsorted === true;

  const clientId = useSelector(clientIdSelector);
  const isClientIdNeeded =
    !ClientObject.isValidId(clientId) && !DataObjectSigmailGlobalContactList.isValidId(contactListId);

  return React.useMemo((): UseGlobalContactListResult => {
    let contactList: ReadonlyArray<ContactListItem> = EMPTY_ARRAY;
    const dataObjects: Required<BatchQueryRequest>['dataObjects']['ids'] = [];
    const userObjects: Required<BatchQueryRequest>['userObjectsByType'] = [];

    do {
      if (!canAccessContactList) break;
      if (!includeGroupList && !includeUserList) break;
      if (!AppUser.isValidId(currentUserId)) break;
      if (dataObjects.length > 0 || userObjects.length > 0) break;

      if (!DataObjectSigmailGlobalContactList.isValidId(contactListId)) {
        if (isClientIdNeeded) {
          userObjects.push({ type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId: currentUserId });
        } else {
          userObjects.push({ type: process.env.CLIENT_OBJECT_TYPE_CONFIGURATION, userId: clientId! });
        }
        break;
      }

      if (Utils.isNil(contactListObjectValue)) {
        dataObjects.push(contactListId);
        break;
      }

      const { list } = contactListObjectValue;
      if (includeGroupList && includeUserList) {
        contactList = list;
      } else {
        contactList = list.filter(
          ({ type }) => (includeGroupList && type === 'group') || (includeUserList && type === 'user')
        );
      }

      contactList.filter((contact) => contact.type === 'user' || contact.groupType !== CIRCLE_OF_CARE);

      contactList = filterFn(contactList, { exclude }).map<ContactListItemExt>((contact) =>
        contact.type === 'group'
          ? contact
          : {
              ...contact,
              userData: { ...contact.userData, accountStatus: 'active' }
            }
      );

      if (!unsorted && contactList.length > 1) {
        contactList = contactList.slice().sort(compareContactListItem);
      }
    } while (false);

    let query: UseGlobalContactListResult[1] = EMPTY_PLAIN_OBJECT;
    if (dataObjects.length > 0 || userObjects.length > 0) {
      query = {
        dataObjects: { ids: dataObjects },
        userObjectsByType: userObjects
      };
    }

    return [contactList, query];
  }, [
    canAccessContactList,
    clientId,
    contactListId,
    contactListObjectValue,
    currentUserId,
    exclude,
    filterFn,
    includeGroupList,
    includeUserList,
    isClientIdNeeded,
    unsorted
  ]);
};
