import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['dropzoneInput'] = {
  // Translators: (HTML) Overlay text displayed within the Dropzone Input when \
  // drag is active
  overlayText: gettext('global', 'overlayText', 'dropzoneInput', 'safe-html')
};

export default i18n;
