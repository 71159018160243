import { PayloadAction } from '@reduxjs/toolkit';
import { StoreStateReminderNotification } from '@sigmail/app-state';
import { SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { subMilliseconds, set } from 'date-fns';
import { REMINDER_NOTIFICATION_REMIND_INTERVAL, SET_MILLISECONDS_SECONDS_TO_NULL } from '../../constants';

const Logger = getLoggerWithPrefix('Reducer', 'queueReminderNotificationReducer:');

interface Payload {
  eventObjectId: SigmailObjectId;
  start: number;
  title: string;
}

export const queueReminderNotificationReducer = (
  state: StoreStateReminderNotification,
  action: PayloadAction<Array<Payload>>
) => {
  try {
    Logger.info('== BEGIN ==');

    if (!Utils.isObject(action.payload)) {
      Logger.warn('Invalid payload.', action.payload);
      return state;
    }

    const queueList = [...state.queueList];
    for (const reminder of action.payload) {
      const existsInQueue = queueList.find(({ eventObjectId }) => eventObjectId === reminder.eventObjectId);
      if (state.dismissedList.includes(reminder.eventObjectId) || existsInQueue) {
        continue;
      }

      const start = set(reminder.start, SET_MILLISECONDS_SECONDS_TO_NULL).getTime();
      const remindAt = set(
        subMilliseconds(start, REMINDER_NOTIFICATION_REMIND_INTERVAL),
        SET_MILLISECONDS_SECONDS_TO_NULL
      ).getTime();

      queueList.push({ ...reminder, remindAt, start });
    }

    return { ...state, queueList };
  } finally {
    Logger.info('== END ==');
  }
};
