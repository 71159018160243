import type { ReadonlyPartialRecord } from '@sigmail/common';
import { AppException, Utils } from '@sigmail/common';
import * as ErrorCode from './constants';

const DEFAULT_ERROR_MESSAGE_MAP: ReadonlyPartialRecord<number, string> = {
  [ErrorCode.E_INVALID_KEY]: 'Invalid/unknown cryptographic key.',
  [ErrorCode.E_UNKNOWN_ALGORITHM_CODE]: 'Invalid/unknown algorithm code.',
  [ErrorCode.E_UNEXPECTED_ALGORITHM]: 'Unexpected algorithm type.',

  [ErrorCode.E_FAIL_GENERATE_KEY]: 'Error generating a key or key pair.',
  [ErrorCode.E_FAIL_DERIVE_KEY]: 'Error deriving a key.',
  [ErrorCode.E_FAIL_IMPORT_KEY]: 'Error importing a key.',
  [ErrorCode.E_FAIL_EXPORT_KEY]: 'Error exporting a key.',
  [ErrorCode.E_FAIL_ENCRYPT]: 'Error while encrypting data.',
  [ErrorCode.E_FAIL_DECRYPT]: 'Error while decrypting data.',
  [ErrorCode.E_FAIL_DIGEST]: 'Error generating a digest.',
  [ErrorCode.E_FAIL_RNG]: 'Error generating random values.'
};

/** @public */
export class SigmailCryptoException extends AppException {
  public constructor(errorCode: number, message?: string) {
    super(errorCode, Utils.isString(message) ? message : DEFAULT_ERROR_MESSAGE_MAP[errorCode]);

    this.name = 'SigmailCryptoException';
  }
}
