import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ComposeHrmMessageView } from '../../constants/action-context';
import dialogHrmInactiveLicense from './dialog-hrm-inactive-license';
import dialogHrmMissingCellNumber from './dialog-hrm-missing-cell-number';
import dialogHrmMissingLicenseNumber from './dialog-hrm-missing-license-number';
import dialogHrmNonOntarioHpn from './dialog-hrm-non-ontario-hpn';
import dialogHrmUnauthorizedSender from './dialog-hrm-unauthorized-sender';

const i18n: MessagingI18n.View.ComposeHrmMessage = {
  attachmentNode: {
    // Translators: Label for the Attachment Section in Compose HRM Message view
    label: gettext('messaging', 'label', 'composeHrmMessageViewAttachmentNode'),
    // Translators: Lead text for the Attachment Section in Compose HRM \
    // Message view
    leadText: gettext('messaging', 'leadText', 'composeHrmMessageViewAttachmentNode'),
    action: {
      attach: {
        // Translators: Label for the Attach Documents action in Compose HRM \
        // Message view
        label: gettext('messaging', 'attach', 'composeHrmMessageViewAttachmentNodeActionLabel')
      }
    }
  },
  action: {
    submit: {
      // Translators: Label for the Submit action in Compose HRM Message view
      label: gettext('messaging', 'submit', 'composeHrmMessageViewActionLabel'),
      context: ComposeHrmMessageView
    },
    close: {
      // Translators: Label for the Close action in Compose HRM Message view
      label: gettext('messaging', 'close', 'composeHrmMessageViewActionLabel'),
      context: ComposeHrmMessageView
    }
  },
  dialog: {
    inactiveLicense: dialogHrmInactiveLicense,
    missingCellNumber: dialogHrmMissingCellNumber,
    missingLicenseNumber: dialogHrmMissingLicenseNumber,
    nonOntarioHpn: dialogHrmNonOntarioHpn,
    unauthorizedHrmSender: dialogHrmUnauthorizedSender,
    unsupportedDocRemoved: dialogHrmUnauthorizedSender
  }
};

export default i18n;
