import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.DataViewer.BPReading = {
  categoryLabel: {
    // Translators: Label for the High (Stage 1) category on Blood Pressure \
    // Reading data viewer
    hypertension1: gettext('health-data', 'hypertension1', 'bpReadingDataViewerCategoryLabel'),
    // Translators: Label for the High (Stage 2) category on Blood Pressure \
    // Reading data viewer
    hypertension2: gettext('health-data', 'hypertension2', 'bpReadingDataViewerCategoryLabel'),
    // Translators: Label for the High (Stage 3) Crisis category on Blood \
    // Pressure Reading data viewer
    hypertension3: gettext('health-data', 'hypertension3', 'bpReadingDataViewerCategoryLabel'),
    // Translators: Label for the High Normal category on Blood Pressure \
    // Reading data viewer
    highNormal: gettext('health-data', 'highNormal', 'bpReadingDataViewerCategoryLabel'),
    // Translators: Label for the Low Normal category on Blood Pressure \
    // Reading data viewer
    lowNormal: gettext('health-data', 'lowNormal', 'bpReadingDataViewerCategoryLabel'),
    // Translators: Label for the Normal category on Blood Pressure Reading \
    // data viewer
    normal: gettext('health-data', 'normal', 'bpReadingDataViewerCategoryLabel')
  },
  columnHeader: {
    // Translators: Label for the Arm Used column header on Blood Pressure \
    // Reading data viewer
    armUsed: gettext('health-data', 'armUsed', 'bpReadingDataViewerColumnHeader'),
    // Translators: Label for the BP Reading column header on Blood Pressure \
    // Reading data viewer
    bpReading: gettext('health-data', 'bpReading', 'bpReadingDataViewerColumnHeader'),
    // Translators: Label for the Comments column header on Blood Pressure \
    // Reading data viewer
    comments: gettext('health-data', 'comments', 'bpReadingDataViewerColumnHeader'),

    // Translators: Label for the Heart Rate column header on Blood Pressure \
    // Reading data viewer
    heartRate: gettext('health-data', 'heartRate', 'bpReadingDataViewerColumnHeader'),
    // Translators: Label for the Date column header on Blood Pressure Reading \
    // reading data viewer
    readingDate: gettext('health-data', 'readingDate', 'bpReadingDataViewerColumnHeader'),
    // Translators: Label for the Time column header on Blood Pressure Reading \
    // reading time viewer
    readingTime: gettext('health-data', 'readingTime', 'bpReadingDataViewerColumnHeader'),
    // Translators: Label for the Date column header on Blood Pressure Reading \
    // request data viewer
    requestDate: gettext('health-data', 'requestDate', 'bpReadingDataViewerColumnHeader')
  },
  // Translators: Message displayed on Blood Pressure Data viewer when data \
  // for selected date range is not available
  dataNotAvailable: gettext('health-data', 'dataNotAvailable', 'bpReadingDataViewer'),
  // Translators: Formatted value for blood pressure data on Blood Pressure \
  // Data viewer
  dataValueBPReading: gettext('health-data', 'dataValueBPReading', 'bpReadingDataViewer'),
  // Translators: Text displayed on Blood Pressure Data viewer when data for \
  // the given column is not available
  dataValueNotSet: gettext('health-data', 'dataValueNotSet', 'bpReadingDataViewer')
};

export default i18n;
