import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { AccountActionToolbar } from '../../constants/action-context';

const i18n: AccountI18n.AccountToolbarActionI18n = {
  activateAccount: {
    // Translators: Label for the Activate Account action
    label: gettext('account', 'activateAccount', 'accountToolbarActionLabel'),
    context: AccountActionToolbar
  },
  deactivateAccount: {
    // Translators: Label for the Deactivate Account action
    label: (_, context) => {
      if (context === 'manageCaregivers') return 'Remove caregiver';
      return gettext('account', 'deactivateAccount', 'accountToolbarActionLabel');
    }
  },
  sendCaregiverAccountInvitation: {
    // Translators: Label for the Send Guest Account Invitation action
    label: gettext('account', 'sendCaregiverAccountInvitation', 'accountToolbarActionLabel'),
    context: AccountActionToolbar
  },
  sendGuestAccountInvitation: {
    // Translators: Label for the Send Guest Account Invitation action
    label: gettext('account', 'sendGuestAccountInvitation', 'accountToolbarActionLabel'),
    context: AccountActionToolbar
  },
  sendNonGuestAccountInvitation: {
    // Translators: Label for the Send Non-Guest Account Invitation action
    label: gettext('account', 'sendNonGuestAccountInvitation', 'accountToolbarActionLabel'),
    context: AccountActionToolbar
  },
  removeAccountInvitation: {
    // Translators: Label for the Remove AccountInvitation action
    label: gettext('account', 'removeAccountInvitation', 'accountToolbarActionLabel'),
    context: AccountActionToolbar
  },
  resendAccountInvitation: {
    // Translators: Label for the Resend Account Invitation action
    label: gettext('account', 'resendAccountInvitation', 'accountToolbarActionLabel'),
    context: AccountActionToolbar
  },
  revokeAccountInvitation: {
    // Translators: Label for the Revoke Account Invitation action
    label: gettext('account', 'revokeAccountInvitation', 'accountToolbarActionLabel'),
    context: AccountActionToolbar
  }
};

export default i18n;
