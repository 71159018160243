import { Alert } from '@material-ui/lab';
import { ReadonlyRecord, Utils } from '@sigmail/common';
import { EmailTokenResponseType } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { srpExchangeCredentialAction } from '../../app-state/actions/SRP/srp-exchange-credential-action';
import { EMPTY_PLAIN_OBJECT } from '../../app-state/constants';
import { useAppState } from '../../app-state/hooks';
import { homeRoutePathSelector } from '../../app-state/selectors';
import { isUserLoggedInSelector } from '../../app-state/selectors/auth';
import {
  ROUTE_ACCOUNT_CAREGIVER_SETUP,
  ROUTE_ACCOUNT_SETUP,
  ROUTE_CHANGE_USERNAME,
  ROUTE_MAIL_SECURE,
  ROUTE_RESET_PASSWORD
} from '../../constants/route-identifiers';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { generateCredentialHash } from '../../utils/generate-credential-hash';
import { getRoutePath } from '../routes';
import { CircularProgress } from '../shared/circular-progress.component';
import style from './public-home.module.css';

type State = ReadonlyRecord<`alert${'Body' | 'Title'}`, string>;
const INITIAL_STATE = EMPTY_PLAIN_OBJECT as State;

export const PublicHome: React.FC<{ children?: never }> = () => {
  const [, appDispatch] = useAppState();
  const { search } = useLocation();
  const history = useHistory();
  const { t } = useTranslation([I18N_NS_GLOBAL]);
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  const homeRoutePath = useSelector(homeRoutePathSelector);
  const [{ alertBody, alertTitle }, setState] = React.useState(INITIAL_STATE);

  let token = '';
  const previousToken = React.useRef<string>();
  if (search.length > 7 /* '?token='.length */ && search.startsWith('?')) {
    const params = search.slice(1).split('&');
    for (const param of params) {
      const pair = param.split('=');
      if (pair.length === 2 && pair[0].toLowerCase() === 'token') {
        token = pair[1];
        break;
      }
    }
  }

  React.useEffect(() => {
    if (isUserLoggedIn) {
      previousToken.current = undefined;
      return setState(INITIAL_STATE);
    }

    const { current: prevToken } = previousToken;
    if (prevToken === token) return;
    previousToken.current = token;

    if (token.length === 0) return setState(INITIAL_STATE);

    const verifyToken = async (): Promise<void> => {
      let isCaregiverAccountSetup = false;
      let response = '' as EmailTokenResponseType;

      try {
        const credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, { token });
        const { shared } = await appDispatch(srpExchangeCredentialAction({ credentialHash }));
        const sharedParameters = JSON.parse(shared);

        if (
          Utils.isNonArrayObjectLike<{ caregiver?: true; response: string }>(sharedParameters) &&
          Utils.isString(sharedParameters.response)
        ) {
          response = sharedParameters.response as typeof response;
          if (response === 'userRegistration') {
            isCaregiverAccountSetup = sharedParameters.caregiver === true;
          }
        }
      } catch (error) {
        /* ignore */
      }

      if (response === 'resetUsername') {
        history.replace(getRoutePath(ROUTE_CHANGE_USERNAME), { token });
      } else if (response === 'userRegistration') {
        history.replace(getRoutePath(isCaregiverAccountSetup ? ROUTE_ACCOUNT_CAREGIVER_SETUP : ROUTE_ACCOUNT_SETUP), {
          token
        });
      } else if (response === 'passwordReset') {
        history.replace(getRoutePath(ROUTE_RESET_PASSWORD), { token });
      } else if (response === 'oneTimeMessage') {
        history.replace(getRoutePath(ROUTE_MAIL_SECURE), { token });
      } else {
        setState({
          alertBody: t(globalI18n.invalidEmailLinkAlert.body),
          alertTitle: t(globalI18n.invalidEmailLinkAlert.title)
        });
      }
    };

    void verifyToken();
  }, [appDispatch, history, isUserLoggedIn, t, token]);

  return Utils.isString(alertBody) && Utils.isString(alertTitle) ? (
    <div className={style.page}>
      <div className="container-fluid">
        <div className="row">
          <div styleName="alert">
            <h1 styleName="style.title">{alertTitle}</h1>
            <Alert styleName="style.body" severity="error">
              <div dangerouslySetInnerHTML={{ __html: alertBody }} />
            </Alert>
          </div>
        </div>
      </div>
    </div>
  ) : token.length > 0 ? (
    <CircularProgress />
  ) : (
    <Redirect to={homeRoutePath} />
  );
};

PublicHome.displayName = 'PublicHome';
