import { InstituteInfo, Utils, ValueObject } from '@sigmail/common';
import { DataObjectSigmailGlobalContactListValue, GroupContactListItem } from '@sigmail/objects';
import { hash } from 'immutable';
import React from 'react';
import { useSelector } from 'react-redux';
import { CIRCLE_OF_CARE } from '../../constants/medical-institute-user-group-type-identifier';
import { DataObjectCache } from '../data-objects-slice/cache';
import { activeGroupIdSelector } from '../selectors';
import { globalContactListIdSelector } from '../selectors/client-object';
import { dataObjectByIdSelector } from '../selectors/data-object';
import { basicProfileObjectSelector, contactInfoObjectSelector } from '../selectors/group-object';
import { UserObjectCache } from '../user-objects-slice/cache';

export const useActiveGroup = (): GroupContactListItem & ValueObject => {
  const groupId = useSelector(activeGroupIdSelector);
  const groupProfileObject = useSelector(basicProfileObjectSelector)(groupId);
  const groupContactInfoObject = useSelector(contactInfoObjectSelector)(groupId);
  const globalContactListId = useSelector(globalContactListIdSelector);
  const dataObjectSelector = useSelector(dataObjectByIdSelector);
  const globalContactListObject = dataObjectSelector<DataObjectSigmailGlobalContactListValue>(globalContactListId!);

  return React.useMemo(() => {
    let groupName: string = '';
    let institute: InstituteInfo | undefined = undefined;

    if (Utils.isNotNil(groupProfileObject)) {
      const profile = UserObjectCache.getValue(groupProfileObject);
      if (Utils.isNotNil(profile)) {
        groupName = profile.name;
      }
    } else if (Utils.isNotNil(groupContactInfoObject)) {
      const contactInfo = UserObjectCache.getValue(groupContactInfoObject);
      if (Utils.isNotNil(contactInfo)) {
        groupName = contactInfo.groupName;
        institute = contactInfo.institute;
      }
    } else if (Utils.isNotNil(globalContactListObject)) {
      const { list } = DataObjectCache.getValue(globalContactListObject, {} as DataObjectSigmailGlobalContactListValue);
      if (Utils.isNonEmptyArray<typeof list[0]>(list)) {
        const index = list.findIndex(
          (contact) => contact.type === 'group' && contact.groupType === CIRCLE_OF_CARE && contact.id === groupId
        );

        if (index > -1) {
          const { groupData } = list[index] as GroupContactListItem;
          groupName = groupData.groupName;
          institute = groupData.institute;
        }
      }
    }

    return {
      type: 'group',
      groupType: CIRCLE_OF_CARE,
      id: groupId,
      groupData: { groupName, institute },
      equals: (other) =>
        Utils.isNonArrayObjectLike<GroupContactListItem>(other) && other.type === 'group' && other.id === groupId,
      hashCode: () => hash('group') * 31 + hash(groupId)
    };
  }, [globalContactListObject, groupContactInfoObject, groupId, groupProfileObject]);
};
