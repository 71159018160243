import { Utils } from '@sigmail/common';
import type { AsymmetricKey } from '@sigmail/crypto';
import { EncryptForServerAlgorithmImpl } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import type { Api, Http } from '../types';
import { URL_GET_PUBLIC_KEY, URL_SEND_EMAIL_MESSAGE_ENCRYPTED as API_URL } from './constants';
import { MalformedResponseException } from './malformed-response-exception';
import { ServiceException as ApiServiceException } from './service-exception';

let SPKI_RESPONSE: Response;
const Logger = getLoggerWithPrefix('ApiService', 'sendEmailMessage:');

export async function apiSendEmailMessage(
  this: Api.Service,
  accessToken: string,
  message: Api.EmailMessage,
  init?: Http.RequestInit
): Promise<void> {
  try {
    Logger.info('== BEGIN ==');
    const requestUrl = this.baseUrl.emailApi.concat(API_URL);
    const spkiRequestUrl = this.baseUrl.emailApi.concat(URL_GET_PUBLIC_KEY);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.emailApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.emailApi);
    }

    const { from, to, subject, body } = message;
    const requestBody = { from, to, subject, htmlContent: body };
    const encryptedRequestBody: Api.EncryptedEmailMessage = { data: '', opType: 'simple' };

    Logger.info('Initiating HTTP request.');
    let response = await (Utils.isUndefined(SPKI_RESPONSE)
      ? this.httpService.get(spkiRequestUrl, {
          ...init,
          cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
          headers: requestHeaders
        })
      : Promise.resolve(SPKI_RESPONSE));

    if (response.status === 200) {
      const json = await Utils.tryGetResponseJson<{ pkey: string }>(response);
      const spki = Utils.trimOrDefault(Utils.isNonArrayObjectLike(json) && json.pkey);
      if (spki.length === 0) throw new MalformedResponseException(response);

      if (Utils.isUndefined(SPKI_RESPONSE)) {
        SPKI_RESPONSE = new Response(JSON.stringify({ pkey: spki }), {
          headers: { 'Content-Type': 'application/json' },
          status: 200,
          statusText: 'OK'
        });
      }

      const algorithm = new EncryptForServerAlgorithmImpl();
      const asymmetricKey: AsymmetricKey = { publicKey: await algorithm.importKey(spki) };
      encryptedRequestBody.data = await algorithm.encrypt(asymmetricKey, requestBody, 0);

      response = await this.httpService.post(requestUrl, JSON.stringify(encryptedRequestBody), {
        ...init,
        cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
        headers: requestHeaders
      });
    }

    if (response.status !== 200) {
      Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
      if (response.status >= 400 && response.status < 500) {
        const responseBody = await response.clone().text();
        throw new ApiServiceException(response, responseBody);
      }

      throw new HttpServiceException(response);
    }
  } finally {
    Logger.info('== END ==');
  }
}
