import { AllDayCalendarEvent, CalendarEvent, ReadonlyCalendarEventWithProps, Utils } from '@sigmail/common';
import { CalendarEventMetadata } from '@sigmail/objects';

type CommonProps = keyof Pick<ReadonlyCalendarEventWithProps<CalendarEvent | AllDayCalendarEvent>, 'allDay' | 'title'>;
type AllDayEventProps = keyof Pick<ReadonlyCalendarEventWithProps<AllDayCalendarEvent>, 'date' | 'duration'>;
type CalendarEventProps = keyof Pick<ReadonlyCalendarEventWithProps<CalendarEvent>, 'start' | 'end'>;

const PROPS_COMMON: ReadonlyArray<CommonProps> = ['allDay', 'title'];
const PROPS_ALL_DAY_EVENT: ReadonlyArray<CommonProps | AllDayEventProps> = [...PROPS_COMMON, 'date', 'duration'];
const PROPS_CALENDAR_EVENT: ReadonlyArray<CommonProps | CalendarEventProps> = [...PROPS_COMMON, 'start', 'end'];

export function calendarEventToMetadata(
  calendarEvent: ReadonlyCalendarEventWithProps<CalendarEvent> | ReadonlyCalendarEventWithProps<AllDayCalendarEvent>
): (CalendarEvent | AllDayCalendarEvent) & Omit<CalendarEventMetadata, 'eventObjectId' | 'accepted'> {
  return calendarEvent.allDay
    ? Utils.pick(calendarEvent, PROPS_ALL_DAY_EVENT)
    : Utils.pick(calendarEvent, PROPS_CALENDAR_EVENT);
}
