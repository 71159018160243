import { createSelector } from '@reduxjs/toolkit';
import { StoreStateUserObjects } from '@sigmail/app-state';
import { SigmailObjectTypeCode, SigmailUserId, Utils } from '@sigmail/common';
import { IUserObject, ValueFormatVersion } from '@sigmail/objects';
import { defaultMemoize } from 'reselect';
import { USER_OBJECT_TYPE_CODE_LIST as BASE_USER_OBJECT_TYPE_CODE_LIST } from '../constants';
import { RootState } from '../root-reducer';
import { UserObjectCache } from '../user-objects-slice/cache';

const USER_OBJECT_TYPE_CODE_LIST: ReadonlyArray<string> = BASE_USER_OBJECT_TYPE_CODE_LIST.map(String);

const userObjectMapSelector = createSelector<RootState, StoreStateUserObjects, StoreStateUserObjects>(
  (state) => state.userObjects,
  (userObjectMap) => userObjectMap.filter((_, key) => USER_OBJECT_TYPE_CODE_LIST.includes(key))
);

const createUserObjectsByTypeSelector = createSelector(userObjectMapSelector, (userObjectMap) =>
  defaultMemoize((objectType: SigmailObjectTypeCode) => userObjectMap.get(objectType.toString(10)))
);

export const createUserObjectSelector = <DV extends ValueFormatVersion>(objectType: SigmailObjectTypeCode) => {
  return createSelector<
    RootState,
    ReturnType<ReturnType<typeof createUserObjectsByTypeSelector>>,
    SigmailUserId | undefined,
    (userId?: SigmailUserId) => IUserObject<DV> | undefined
  >(
    (state) => createUserObjectsByTypeSelector(state)(objectType),
    (state) => {
      const roleAuthClaim = state.auth.authClaim;
      try {
        const decodedToken = Utils.decodeIdToken(roleAuthClaim);
        return decodedToken.userId;
      } catch {
        /* ignore */
      }
      return undefined;
    },
    (userIdMap, currentUserId) => {
      return Utils.memoize((userId?: typeof currentUserId) => {
        const key = Utils.isInteger(userId) ? userId : currentUserId;
        if (!Utils.isInteger(key)) return undefined;

        const objectId = userIdMap?.get(key.toString())?.keySeq().first();
        return UserObjectCache.find<IUserObject<DV>>(Number(objectId))?.[0];
      });
    }
  );
};
