import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['genderList'] = [
  {
    code: 'unknown',
    // Translators: Label for the Unknown gender
    label: gettext('global', 'unknown', 'gender')
  },
  {
    code: 'male',
    // Translators: Label for the Male gender
    label: gettext('global', 'male', 'gender')
  },
  {
    code: 'female',
    // Translators: Label for the Female gender
    label: gettext('global', 'female', 'gender')
  },
  {
    code: 'other',
    // Translators: Label for the Other gender
    label: gettext('global', 'other', 'gender')
  }
];

export default i18n;
