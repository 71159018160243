import { SigmailGroupId, Utils } from '@sigmail/common';
import { GroupObjectFolderListValue } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { EMPTY_PLAIN_OBJECT } from '../../../app-state/constants';
import { useCurrentUser, useDispatchFetchUserObjects } from '../../../app-state/hooks';
import { activeGroupIdSelector } from '../../../app-state/selectors';
import { selectAccessRight } from '../../../app-state/selectors/auth';
import {
  folderListObjectSelector,
  messageFolderMapSelector as groupMessageFolderMapSelector
} from '../../../app-state/selectors/group-object';
import { UserObjectCache } from '../../../app-state/user-objects-slice/cache';
import { UNRESOLVED_RESOURCE } from '../../../constants';

export interface GroupMessageFolderMapResource
  extends ISuspenseResource<ReturnType<ReturnType<typeof groupMessageFolderMapSelector>>> {}

export const useGroupMessageFolderMapResource = (groupId?: SigmailGroupId | undefined) => {
  const currentUser = useCurrentUser();
  const [resource, setResource] = React.useState<GroupMessageFolderMapResource>(UNRESOLVED_RESOURCE);
  const canAccessGroupMailbox = useSelector(selectAccessRight)('accessGroupMailbox');
  const isUserRoleNonGuest = Utils.isNonGuestRole(currentUser?.role);

  const activeGroupId = useSelector(activeGroupIdSelector);
  const folderListObject = useSelector(folderListObjectSelector)(groupId);
  const folderMap =
    canAccessGroupMailbox && isUserRoleNonGuest
      ? UserObjectCache.getValue(folderListObject)?.msg
      : (EMPTY_PLAIN_OBJECT as GroupObjectFolderListValue['msg']);
  const dispatchFetchUserObjects = useDispatchFetchUserObjects();

  const prevFolderMap = React.useRef<typeof folderMap>();
  const isFetchDispatched = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (Utils.isNil(folderMap)) {
      prevFolderMap.current = undefined;
      if (isFetchDispatched.current === false) {
        isFetchDispatched.current = true;
        const userId = Utils.isInteger(groupId) ? groupId : activeGroupId;
        dispatchFetchUserObjects({
          userObjects: [{ type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST, userId }]
        }).finally(() => {
          isFetchDispatched.current = false;
        });
      }
      return setResource(UNRESOLVED_RESOURCE);
    }

    if (prevFolderMap.current !== folderMap) {
      prevFolderMap.current = folderMap;
      setResource({ value: () => folderMap });
    }
  }, [activeGroupId, dispatchFetchUserObjects, folderMap, groupId]);
  return resource;
};
