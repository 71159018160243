import { AccountActionPayload } from '@sigmail/app-state';
import { AppException, Constants, MessageSender, PersonName, Utils } from '@sigmail/common';
import {
  GroupObjectGuestList,
  GroupObjectGuestListValue,
  IUserObject,
  UserObjectCircleOfCare,
  UserObjectCircleOfCareValue,
  UserObjectContactInfoValue,
  UserObjectContactList,
  UserObjectContactListValue,
  UserObjectProfileBasicValue,
  UserObjectProfilePrivateValue,
  UserObjectProfileProtectedValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { userListObjectSelector as clientUserListSelector } from '../../../selectors/client-object';
import { guestListObjectSelector as groupGuestListObjectSelector } from '../../../selectors/group-object';
import { ActionInitParams, FetchObjectsRequestData } from '../../base-action';
import {
  BaseSendAccountInvitationAction,
  BaseSendAccountInvitationPayload,
  BaseSendAccountInvitationState,
  UserObjectDataUpdater
} from './base';

export interface ContactInfoBasic
  extends Pick<UserObjectContactInfoValue, keyof PersonName | `${'cell' | 'home'}Number` | 'emailAddress' | 'role'> {}

export interface ContactInfoProtected
  extends Pick<UserObjectContactInfoValue, 'birthDate' | 'gender' | 'healthCardNumber' | 'healthPlanJurisdiction'> {}

export interface Payload extends BaseSendAccountInvitationPayload {
  readonly maskedBirthDate: AccountActionPayload.SendGuestAccountInvitation['maskedBirthDate'];
  readonly profileData: Readonly<{
    basic: ContactInfoBasic;
    protected: ContactInfoProtected;
  }>;
}

interface State extends BaseSendAccountInvitationState {
  maskedHealthPlanNumber: string;
}

export class SendGuestAccountInvitationAction extends BaseSendAccountInvitationAction<Payload, State> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params);

    let { healthCardNumber } = params.payload.profileData.protected;
    if (Utils.isString(healthCardNumber)) {
      this.state.maskedHealthPlanNumber = Utils.maskHealthPlanNumber(healthCardNumber);
    }
  }

  /** override */
  protected generateAccessCode(hpj?: string, hpn?: string) {
    const { healthCardNumber, healthPlanJurisdiction } = this.payload.profileData.protected;

    return super.generateAccessCode(healthPlanJurisdiction, healthCardNumber);
  }

  /** override */
  protected async fetchClientAdminData(): Promise<void> {
    await super.fetchClientAdminData();

    const { clientId, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ type: process.env.CLIENT_OBJECT_TYPE_USER_LIST, userId: clientId }]
    };

    await this.dispatchFetchObjects(query);

    const clientUserList = await this.getUserObjectValue(clientUserListSelector, { userId: clientId });
    if (Utils.isNil(clientUserList)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch client user list.');
    }
  }

  /** override */
  protected async createIdsRequestData(): Promise<Api.GetIdsRequestData> {
    const { expireIds, queryIds, updateIds, ...data } = await super.createIdsRequestData();

    return {
      ...data,
      ids: {
        ...data.ids,
        ids: [...data.ids.ids!, { type: process.env.USER_OBJECT_TYPE_CONTACT_LIST }, { type: process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE }]
      }
    };
  }

  /** override */
  protected createBasicProfileValue(): UserObjectProfileBasicValue {
    return { $$formatver: 5, ...this.payload.profileData.basic };
  }

  /** override */
  protected createProtectedProfileValue(): UserObjectProfileProtectedValue {
    return { $$formatver: 2, ...this.payload.profileData.protected };
  }

  /** override */
  protected createPrivateProfileValue(): UserObjectProfilePrivateValue {
    return { ...super.createPrivateProfileValue(), globalContactListId: undefined! };
  }

  /** override */
  protected createContactInfoValue(): UserObjectContactInfoValue {
    const { maskedBirthDate: birthDate, profileData } = this.payload;
    const { maskedHealthPlanNumber: healthCardNumber } = this.state;

    return {
      $$formatver: 2,
      ...profileData.basic,
      ...profileData.protected,
      birthDate,
      healthCardNumber,
      noNotifyOnNewMessage: ['webPush']
    };
  }

  /** override */
  protected async generateRequestBody() {
    await super.generateRequestBody();

    await this.addInsertOperationForUserContactList(); // 406
    await this.addInsertOperationForCircleOfCare(); // 420
    await this.addUpdateOperationForGroupGuestList(); // 468
  }

  private async addInsertOperationForUserContactList(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for contact list.');

    const { groupList } = this.payload;
    const { auditId, clientId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_CONTACT_LIST];
    const value: UserObjectContactListValue = { $$formatver: 1, recent: groupList, contacts: groupList };
    this.logger.debug({ id, ...value });

    const contactListObject = await UserObjectContactList.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await contactListObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(contactListObject[Constants.$$CryptographicKey]);
    requestBody.insert(contactListObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addInsertOperationForCircleOfCare(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for circle of care.');

    const { groupList } = this.payload;
    const { auditId, dtServer, idRecord, inviteeId, masterKeyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE];
    const value: UserObjectCircleOfCareValue = {
      $$formatver: 1,
      list: groupList.map((contact) => ({ ...contact, sendAllowed: true, replyAllowed: true }))
    };
    this.logger.debug({ id, ...value });

    const circleOfCareObject = await UserObjectCircleOfCare.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await circleOfCareObject.generateKeysEncryptedFor(auditId, ...groupList.map(({ id }) => id));
    keyList.push(circleOfCareObject[Constants.$$CryptographicKey]);
    requestBody.insert(circleOfCareObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addUpdateOperationForGroupGuestList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group guest list.');

    const { groupList, profileData } = this.payload;
    const { inviteeId, maskedHealthPlanNumber: healthCardNumber, requestBody, successPayload } = this.state;

    const applyUpdate = async (guestListObject: IUserObject<GroupObjectGuestListValue>): Promise<typeof guestListObject> => {
      const guestList = await guestListObject.decryptedValue();

      const updatedValue: GroupObjectGuestListValue = {
        ...guestList,
        list: guestList.list.concat({
          type: 'user',
          id: inviteeId,
          userData: {
            ...profileData.basic,
            ...profileData.protected,
            healthCardNumber,
            noNotifyOnNewMessage: ['webPush']
          }
        })
      };
      this.logger.debug(updatedValue);

      return guestListObject.updateValue(updatedValue);
    };

    const { id: groupId } = groupList[0];
    const guestListObject = await this.getUserObject(groupGuestListObjectSelector, {
      type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST,
      userId: groupId
    });

    if (Utils.isNil(guestListObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Group guest list is either missing or invalid.');
    }

    const guestListObjectKey = guestListObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (guestListJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === guestListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; group guest list could not be found in request body.');
      }

      const key = guestListObjectKey === null ? null : guestListObjectKey.toApiFormatted();
      const guestListObject = new GroupObjectGuestList({ ...guestListJson, key });
      const updatedObject = await applyUpdate(guestListObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === guestListJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(guestListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(guestListObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  /** @override */
  protected getWelcomeMessageSender(): MessageSender {
    const [{ groupData, ...group }] = this.payload.groupList;
    return { ...group, groupName: groupData.groupName };
  }
}
