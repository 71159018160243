import { Constants, Utils } from '@sigmail/common';
import { UserObjectEConsultValue } from '@sigmail/objects';
import { WithT } from 'i18next';
import React from 'react';
import { jsonToCSV } from 'react-papaparse';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { BillingNumberMissingError } from '../../../app-state/actions/messaging/send-message-action';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../../../app-state/constants';
import { useCurrentUser, useDispatchFetchUserObjects } from '../../../app-state/hooks';
import {
  eConsultObjectSelector,
  preferencesObjectSelector as userPreferencesObjectSelector,
  preferencesSelector as userPreferencesSelector
} from '../../../app-state/selectors/user-object';
import { UserObjectCache } from '../../../app-state/user-objects-slice/cache';
import { DEFAULT_MESSAGE_SIGNATURE, UNRESOLVED_RESOURCE } from '../../../constants';
import { SendMessageToDoctorCare } from '../../../constants/action-ids';
import { ContactListResource, useContactListResource } from '../../../hooks';
import globalI18n from '../../../i18n/global';
import messagingI18n from '../../../i18n/messaging';
import { ActionDataSendMessage } from '../../messaging/folder-view/hooks';
import { OnClickActionHandler } from '../../messaging/types';
import { CircularProgress } from '../../shared/circular-progress.component';
import { FormValues, INITIAL_VALUES, Props as FormProps } from '../form-billing-file.component';

const {
  options: diagnosticCodeOptionList
} = messagingI18n.form.composeConsultationRequest.fieldsetGroup.service.fieldsetService.formField.diagnosticCode;

const { fileType: fileTypeFieldI18n } = globalI18n.form.billingFile.fieldsetFileType.formField;
const VALID_FILE_TYPE_LIST = fileTypeFieldI18n.options.map(({ code }) => code);

export interface UseBillingFormStateParams extends WithT {
  actionClickHandler?: OnClickActionHandler | null | undefined;
}

export interface UseBillingFileFormStateResult {
  formId?: string | undefined;
  formSubmitting: boolean;
  props: FormProps;
  setFormRef?: React.RefCallback<HTMLFormElement> | undefined;
}

function formatDateToYYYYMMDD(value: Date | number | string, delimiter = ''): string {
  const dt = Utils.isDate(value) ? value : new Date(value);

  const year = dt.getFullYear().toString(10);
  const month = (dt.getMonth() + 1).toString(10).padStart(2, '0');
  const dayOfMonth = dt.getDate().toString(10).padStart(2, '0');

  return `${year}${delimiter}${month}${delimiter}${dayOfMonth}`;
}

interface GenerateConsultationBillingFileParams {
  consultationList: UserObjectEConsultValue['list'];
  formData: FormValues;
  sender: ReturnType<ContactListResource['value']>[0];
}

function generateConsultationBillingFile({
  consultationList,
  formData,
  sender
}: GenerateConsultationBillingFileParams): File | undefined {
  const { endDate, fileType, startDate } = formData;

  const jsonData = consultationList
    .filter(
      ({ healthPlanJurisdiction, timestamp }) =>
        healthPlanJurisdiction === Constants.HealthPlanJurisdiction.Ontario &&
        timestamp >= startDate.getTime() &&
        timestamp <= endDate.getTime()
    )
    .map(
      ({
        birthDate,
        consultant,
        diagnosticCode: diagnosticCodeKey,
        healthPlanNumber,
        referrer,
        serviceCode,
        timestamp
      }) => {
        const providerNumber = referrer.id === sender.id ? referrer.ohipBillingNumber : consultant.ohipBillingNumber;
        const referringProviderNumber =
          consultant.id === sender.id ? referrer.ohipBillingNumber : consultant.ohipBillingNumber;
        const serviceDate = formatDateToYYYYMMDD(timestamp);

        let diagnosticCode = diagnosticCodeOptionList.find(({ key }) => key === diagnosticCodeKey)?.code!;
        if (!Utils.isString(diagnosticCode)) diagnosticCode = '';

        let specialtyCode = '00';
        if (sender.type === 'user') {
          const specialty = globalI18n.physicianSpecialtyList.find(({ code }) => code === sender.userData.specialty);
          if (Utils.isNotNil(specialty)) {
            specialtyCode = specialty.rcpsCode;
          }
        }

        return {
          diagnostic_code: diagnosticCode,
          health_card_number: healthPlanNumber.replaceAll(/\D/g, ''),
          service_code: `${serviceCode}A`,
          service_fee: '',
          version_code: healthPlanNumber.replaceAll(/[^a-z]/gi, ''),
          provider_number: providerNumber,
          master_number: '',
          number_of_services: 1,
          payee: '',
          payment_program: 'HCP',
          provider_group_number: '4262', // SigmaHealth's group number
          referring_lab_license: '',
          referring_provider_number: referringProviderNumber,
          service_location_indicator: '',
          specialty_code: specialtyCode,
          date_of_birth: birthDate.slice(0, 10).replaceAll(/\D/g, ''),
          patient_admission_date: '',
          service_date: serviceDate
        };
      }
    );

  if (jsonData.length > 0) {
    const csvData = jsonToCSV(jsonData, { newline: '\n' });

    let filenamePrefix = '';
    if (Utils.isNotNil(sender) && sender.type === 'user') {
      const { firstName, lastName } = sender.userData;
      filenamePrefix = `${firstName}-${lastName}-`.replace(/[^a-z0-9_-]/gi, '').toLowerCase();
    }

    const filename = `${filenamePrefix}${fileType}-${formatDateToYYYYMMDD(startDate)}-${formatDateToYYYYMMDD(
      endDate
    )}-${Date.now()}.csv`;
    return new File([csvData], filename, { type: 'text/csv' });
  }

  return undefined;
}

const DEFAULT_CONSULTATION_COUNTS: Readonly<[countOfSent: number, countOfReceived: number]> = [0, 0];

export const useBillingFileFormState = (
  params?: UseBillingFormStateParams | undefined
): UseBillingFileFormStateResult => {
  const noop = Utils.isNil(params);
  const currentUser = useCurrentUser();
  const currentUserId = currentUser?.id;
  const eConsultObject = useSelector(eConsultObjectSelector)(/***/);
  const fetchUserObjects = useDispatchFetchUserObjects();
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formId, setFormId] = React.useState<string>();
  const [formValues, setFormValues] = React.useState<FormValues>(INITIAL_VALUES);
  const [formError, setFormError] = React.useState<string>();
  const { endDate, fileType, startDate } = formValues;
  const [submitComplete, setSubmitComplete] = React.useState<typeof Utils.noop>();

  const userPreferencesObject = useSelector(userPreferencesObjectSelector)(/***/);
  const userPreferencesObjectValue = UserObjectCache.getValue(userPreferencesObject);
  const userPreferences = useSelector(userPreferencesSelector);

  const messageSignature = React.useMemo<string>(() => {
    let { messageSignature: signature } = userPreferences;
    const isDefaultMessageSignature = signature === DEFAULT_MESSAGE_SIGNATURE;

    if (isDefaultMessageSignature) {
      signature = Utils.createMessageSignature(
        currentUser,
        currentUser,
        currentUser?.officeNumber,
        currentUser?.officeNumberExt
      );
    }

    return signature;
  }, [currentUser, userPreferences]);

  const dataResource = React.useMemo<ISuspenseResource<UserObjectEConsultValue>>(() => {
    const consultationData = UserObjectCache.getValue(eConsultObject);
    return Utils.isNil(consultationData) ? UNRESOLVED_RESOURCE : { value: () => consultationData };
  }, [eConsultObject]);

  React.useEffect(() => {
    if (noop || Utils.isNil(currentUserId) || formSubmitting) return;

    if (dataResource === UNRESOLVED_RESOURCE) {
      fetchUserObjects({ userObjects: [{ type: process.env.USER_OBJECT_TYPE_E_CONSULT, userId: currentUserId }] }).then(
        Utils.noop,
        Utils.noop
      );
    }

    if (Utils.isNil(userPreferencesObjectValue)) {
      fetchUserObjects({
        userObjects: [{ type: process.env.USER_OBJECT_TYPE_PREFERENCES, userId: currentUserId }]
      }).then(Utils.noop, Utils.noop);
    }
  }, [currentUserId, dataResource, fetchUserObjects, formSubmitting, noop, userPreferencesObjectValue]);

  const contactListResource = useContactListResource({
    include: { globalContactList: { groups: !noop, users: !noop } }
  });

  const getConsultationCounts = React.useCallback(
    (consultationList: UserObjectEConsultValue['list']): typeof DEFAULT_CONSULTATION_COUNTS => {
      setFormError(undefined);
      if (fileType === 'e-consult') {
        try {
          return consultationList.reduce(
            (counts, { consultant, healthPlanJurisdiction, referrer, timestamp }) => {
              if (
                healthPlanJurisdiction === Constants.HealthPlanJurisdiction.Ontario &&
                timestamp >= startDate.getTime() &&
                timestamp <= endDate.getTime()
              ) {
                counts[0] += Number(Utils.isNonArrayObjectLike(referrer) && referrer.id === currentUserId);
                counts[1] += Number(Utils.isNonArrayObjectLike(consultant) && consultant.id === currentUserId);
              }
              return counts;
            },
            [0, 0]
          );
        } catch {
          /* ignore */
        }
      }
      return DEFAULT_CONSULTATION_COUNTS;
    },
    [currentUserId, endDate, fileType, startDate]
  );

  const SummaryNode = React.useMemo<React.ReactNode>(() => {
    if (
      noop ||
      Utils.isNil(currentUserId) ||
      !Utils.isValidDate(startDate) ||
      !Utils.isValidDate(endDate) ||
      startDate > endDate ||
      !VALID_FILE_TYPE_LIST.includes(fileType)
    ) {
      return null;
    }

    if (fileType === 'e-consult') {
      let countOfSent: React.ReactNode = <CircularProgress />;
      let countOfReceived: React.ReactNode = <CircularProgress />;

      try {
        const { list: consultationList } = dataResource.value();
        [countOfSent, countOfReceived] = getConsultationCounts(consultationList);
      } catch (error) {
        if (!(error instanceof Promise)) {
          countOfSent = countOfReceived = 'n/a';
        }
      }

      return (
        <table>
          <tbody>
            <tr>
              <th>Service requests:</th>
              <td>{countOfSent}</td>
            </tr>
            <tr>
              <th>Service responses:</th>
              <td>{countOfReceived}</td>
            </tr>
          </tbody>
        </table>
      );
    }

    return null;
  }, [currentUserId, dataResource, endDate, fileType, getConsultationCounts, noop, startDate]);

  const onFormStateChange = React.useMemo<FormProps['onFormStateChange']>(() => {
    return noop
      ? undefined
      : ({ formState: { submitting, values } }) => {
          setFormSubmitting(submitting === true);
          setFormValues(values);
        };
  }, [noop]);

  const { actionClickHandler } = (noop ? EMPTY_PLAIN_OBJECT : params) as NonNullable<typeof params>;
  const onSubmit = React.useMemo<FormProps['onSubmit']>(() => {
    return noop
      ? undefined
      : () => {
          if (typeof submitComplete === 'function') return;
          if (typeof actionClickHandler !== 'function') return;

          return new Promise<typeof submitComplete>((resolve) => {
            setSubmitComplete(() => resolve);
          }).then(() => setSubmitComplete(() => undefined));
        };
  }, [actionClickHandler, noop, submitComplete]);

  const prevFormId = React.useRef<string | null | undefined>();
  const setFormRef = React.useMemo<React.RefCallback<HTMLFormElement> | undefined>(
    () =>
      noop
        ? undefined
        : (form) => {
            const formId = form?.id;
            setFormId(formId);
            if (prevFormId.current !== formId) {
              prevFormId.current = formId;
              setFormError(undefined);
            }
          },
    [noop]
  );

  const t = params?.t;
  React.useEffect(() => {
    if (typeof submitComplete !== 'function') return;
    if (typeof actionClickHandler !== 'function') return;

    const actionData: ActionDataSendMessage = {
      documentList: EMPTY_ARRAY,
      flags: {
        // @ts-expect-error
        billing: true
      },
      messageBody: DEFAULT_MESSAGE_SIGNATURE,
      primary: [undefined!],
      secondary: EMPTY_ARRAY,
      sensitivity: 'normal',
      subjectLine: ''
    };

    const counts = {
      consultation: DEFAULT_CONSULTATION_COUNTS
    };

    try {
      const contactList = contactListResource.value();
      const sender = contactList.find(({ type, id }) => type === 'user' && id === currentUserId);
      const recipient = contactList.find(({ id }) => id === process.env.ACCOUNT_ID_BILLING_PROVIDER)!;

      if (fileType === 'e-consult') {
        const { list: consultationList } = dataResource.value();

        counts.consultation = getConsultationCounts(consultationList);
        if (counts.consultation.every((count) => count < 1)) {
          setFormError(t!(fileTypeFieldI18n.error!.noBillingRecordFound!));
          submitComplete();
          return;
        }

        const billingFile = generateConsultationBillingFile({
          consultationList,
          formData: { endDate, fileType, startDate },
          sender: sender!
        });

        if (Utils.isNotNil(billingFile)) {
          actionData.documentList = [billingFile];
          actionData.primary = [recipient];
          actionData.subjectLine = `Consultation billing (${formatDateToYYYYMMDD(
            startDate,
            '-'
          )} to ${formatDateToYYYYMMDD(endDate, '-')})`;
          actionData.messageBody = [
            `<p><strong>Service requests:</strong> ${counts.consultation[0]}</p>`,
            `<p><strong>Service responses:</strong> ${counts.consultation[1]}</p>`,
            '<p><br/></p>',
            messageSignature
          ].join('');
        }
      }
    } catch (error) {
      if (error instanceof Promise) return;

      /* otherwise, ignore */
    }

    Promise.resolve(actionClickHandler(SendMessageToDoctorCare, actionData)).then((result) => {
      if (Utils.isNonArrayObjectLike<PromiseSettledResult<void>>(result) && result.status === 'rejected') {
        if (result.reason === BillingNumberMissingError) {
          setFormError(t!(fileTypeFieldI18n.error!.billingNumberMissing!));
        }
      }

      submitComplete();
    });
  }, [
    actionClickHandler,
    contactListResource,
    currentUserId,
    dataResource,
    endDate,
    fileType,
    getConsultationCounts,
    messageSignature,
    startDate,
    submitComplete,
    t
  ]);

  return React.useMemo<UseBillingFileFormStateResult>(
    () => ({
      formId,
      formSubmitting,
      props: {
        disabled: formSubmitting,
        formError,
        onFormStateChange,
        onSubmit,
        SummaryNode
      },
      setFormRef
    }),
    [formError, formId, formSubmitting, onFormStateChange, onSubmit, SummaryNode, setFormRef]
  );
};
