import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ManageAccountProfile } from '../../constants/action-context';

const i18n: AccountI18n.View.UserProfile = {
  action: {
    editProfile: {
      // Translators: Label for the Edit Profile action of User Profile view
      label: gettext('account', 'editProfile', 'userProfileActionLabel'),
      context: ManageAccountProfile
    }
  },
  columnHeader: {
    // Translators: Label for the Academic Degrees column header of User \
    // Profile view
    academicDegrees: gettext('account', 'academicDegrees', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Address column header of User Profile view
    address: gettext('account', 'address', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Birth Date column header of User Profile view
    birthDate: gettext('account', 'birthDate', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Cell Number column header of User Profile view
    cellNumber: gettext('account', 'cellNumber', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Email Address column header of User Profile \
    // view
    emailAddress: gettext('account', 'emailAddress', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Fax Number column header of User Profile view
    faxNumber: gettext('account', 'faxNumber', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Health Plan Jurisdiction column header of \
    // User Profile view
    healthPlanJurisdiction: gettext('account', 'hpj', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Health Plan Number column header of User \
    // Profile view
    healthPlanNumber: gettext('account', 'hpn', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Home Number column header of User Profile view
    homeNumber: gettext('account', 'homeNumber', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the License Number column header of User Profile \
    // view
    licenseNumber: gettext('account', 'licenseNumber', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Name column header of User Profile view
    name: gettext('account', 'name', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Office Number column header of User Profile \
    // view
    officeNumber: gettext('account', 'officeNumber', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the OHIP Billing Number column header of User \
    // Profile view
    ohipBillingNumber: gettext('account', 'ohipBillingNumber', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Other Specialties column header of User \
    // Profile view
    otherSpecialties: gettext('account', 'otherSpecialties', 'userProfileColumnHeaderLabel'),
    // Translators: Label for the Specialty column header of User Profile view
    specialty: gettext('account', 'specialty', 'userProfileColumnHeaderLabel')
  },
  // Translators: Label for the User Profile View under My Account
  label: gettext('account', 'userProfile', 'accountViewLabel')
};

export default i18n;
