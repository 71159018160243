/* eslint-disable no-redeclare */

import {
  Constants,
  EventLogRecordCodeMessageAssigned,
  EventLogRecordCodeMessageForwarded,
  EventLogRecordCodeMessageResponded,
  EventLogRecordCodeMessageSent,
  HRM,
  SigmailObjectId,
  Utils,
  Writeable
} from '@sigmail/common';
import {
  DataObjectMsgMetadataValue,
  EventLogRecordValueMessageAssigned,
  EventLogRecordValueMessageCategoryChanged,
  EventLogRecordValueMessageForwarded,
  EventLogRecordValueMessageMoved,
  EventLogRecordValueMessageRecalled,
  EventLogRecordValueMessageReceived,
  EventLogRecordValueMessageResponded,
  EventLogRecordValueMessageSent,
  EventLogRecordValueMessageSentToHRM
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import {
  BaseSendMessagePayload,
  BaseSendMessageState
} from '../../app-state/actions/messaging/send-message-action/base';

type Message = Pick<BaseSendMessageState, 'documentList' | `msg${'Body' | 'Metadata'}Id` | 'recipientList'> &
  Pick<BaseSendMessagePayload, 'messageFormName' | 'onBehalfOf'>;
type MessageFlags = BaseSendMessagePayload['flags'] & Pick<BaseSendMessagePayload, 'sensitivity'>;
type SourceMessage = BaseSendMessagePayload['sourceMessage'];

export type CategoryChangedParams = Readonly<
  [
    sourceMessage: NonNullable<SourceMessage>,
    categoryListCurrent: ReadonlyArray<number>,
    categoryListPrevious: ReadonlyArray<number>,
    folderId: SigmailObjectId
  ]
>;

export type MoveMessageParams = Readonly<
  [sourceMessage: NonNullable<SourceMessage>, folderIdCurrent: SigmailObjectId, folderIdPrevious: SigmailObjectId]
>;

export type RecallMessageParams = Readonly<[sourceMessage: NonNullable<SourceMessage>]>;
export type ReceivedMessageParams = Readonly<
  [msgBodyId: SigmailObjectId, msgMetadataId: SigmailObjectId, msgMetadata: DataObjectMsgMetadataValue]
>;

export type SendMessageParams = Readonly<
  [flags: MessageFlags, message: Message, sourceMessage?: SourceMessage, folderId?: SigmailObjectId]
>;

export type SendToHrmParams = Readonly<
  [
    requestId: number,
    patient: Api.HrmSendReportRequestData['patient'],
    practitioners: Api.HrmSendReportRequestData['practitioners'],
    sourceMessage?: SourceMessage
  ]
>;

export function newEventLogRecordValueCategoryChanged(
  ...params: CategoryChangedParams
): EventLogRecordValueMessageCategoryChanged {
  const [message, categoryListPrevious, categoryListCurrent, folderId] = params;

  return {
    src: [message.header, message.body, folderId],
    list: [categoryListPrevious, categoryListCurrent]
  };
}

export function newEventLogRecordValueMoveMessage(...params: MoveMessageParams): EventLogRecordValueMessageMoved {
  const [message, folderIdCurrent, folderIdPrevious] = params;

  return {
    folder: [folderIdCurrent, folderIdPrevious],
    src: [message.header, message.body],
    srcForm: Utils.isDefaultMessageForm(message.messageForm)
      ? Constants.MessageFormName.Default
      : message.messageForm.name
  };
}

export function newEventLogRecordValueRecallMessage(
  ...params: RecallMessageParams
): EventLogRecordValueMessageRecalled {
  const [message] = params;

  return {
    src: [message.header, message.body],
    srcForm: Utils.isDefaultMessageForm(message.messageForm)
      ? Constants.MessageFormName.Default
      : (message.messageForm.name as EventLogRecordValueMessageRecalled['srcForm'])
  };
}

export function newEventLogRecordValueReceiveMessage(
  ...params: ReceivedMessageParams
): EventLogRecordValueMessageReceived {
  const [
    msgBodyId,
    msgMetadataId,
    { assignedBy, documentList, importance, messageForm, recipientList, replyTo, sensitivity }
  ] = params;

  return {
    dnr: Utils.isArray(replyTo) && replyTo.length === 0,
    form: Utils.isDefaultMessageForm(messageForm) ? Constants.MessageFormName.Default : messageForm.name,
    imp: importance === 'high',
    msg: [msgMetadataId, msgBodyId],
    to: recipientList.map(({ entity: { id } }) => id),
    assignedBy,
    docs: documentList?.map(({ body, metadata }) => [metadata, body]),
    snvt: sensitivity
  };
}

export function newEventLogRecordValueSendMessage(
  code: EventLogRecordCodeMessageAssigned,
  ...params: SendMessageParams
): EventLogRecordValueMessageAssigned;

export function newEventLogRecordValueSendMessage(
  code: EventLogRecordCodeMessageForwarded,
  ...params: SendMessageParams
): EventLogRecordValueMessageForwarded;

export function newEventLogRecordValueSendMessage(
  code: EventLogRecordCodeMessageResponded,
  ...params: SendMessageParams
): EventLogRecordValueMessageResponded;

export function newEventLogRecordValueSendMessage(
  code: EventLogRecordCodeMessageSent,
  ...params: SendMessageParams
): EventLogRecordValueMessageSent;

export function newEventLogRecordValueSendMessage(
  code:
    | EventLogRecordCodeMessageAssigned
    | EventLogRecordCodeMessageForwarded
    | EventLogRecordCodeMessageResponded
    | EventLogRecordCodeMessageSent,
  ...params: SendMessageParams
): EventLogRecordValueMessageSent;

export function newEventLogRecordValueSendMessage(
  code:
    | EventLogRecordCodeMessageAssigned
    | EventLogRecordCodeMessageForwarded
    | EventLogRecordCodeMessageResponded
    | EventLogRecordCodeMessageSent,
  ...params: SendMessageParams
): unknown {
  const [flags, message, sourceMessage, folderId] = params;

  const baseResult: Writeable<EventLogRecordValueMessageSent> = {
    dnr: flags.doNotReply,
    docs: message.documentList.map(({ body, metadata }) => [metadata, body]),
    form: message.messageFormName,
    imp: flags.important,
    msg: [message.msgMetadataId, message.msgBodyId],
    obo: message.onBehalfOf?.id,
    to: message.recipientList.map(({ entity: { id } }) => id),
    snvt: flags.sensitivity,
    svc: flags.billable ? 'K303' : undefined
  };

  switch (code) {
    case Constants.EventLogCode.MessageAssigned: {
      const result: Writeable<EventLogRecordValueMessageAssigned> = {
        msg: baseResult.msg,
        src: [sourceMessage!.header, sourceMessage!.body, folderId!],
        to: baseResult.to
      };
      return result;
    }
    case Constants.EventLogCode.MessageForwarded:
    case Constants.EventLogCode.MessageResponded: {
      const result: Writeable<EventLogRecordValueMessageForwarded> = {
        ...baseResult,
        src: [sourceMessage!.header, sourceMessage!.body, folderId!],
        srcForm: Utils.isNil(sourceMessage!.messageForm)
          ? Constants.MessageFormName.Default
          : (sourceMessage!.messageForm.name as EventLogRecordValueMessageForwarded['srcForm'])
      };
      return result;
    }
    case Constants.EventLogCode.MessageSent: {
      return baseResult;
    }
    default: {
      throw new Error(`Unhandled case - ${code}`);
    }
  }
}

export function newEventLogRecordValueSendToHRM(...params: SendToHrmParams): EventLogRecordValueMessageSentToHRM {
  const [requestId, patient, practitioners, message] = params;

  let src: EventLogRecordValueMessageSentToHRM['src'];
  let srcForm: EventLogRecordValueMessageSentToHRM['srcForm'];
  if (Utils.isNotNil(message)) {
    src = [message.header, message.body, 0];
    srcForm = Utils.isDefaultMessageForm(message.messageForm)
      ? Constants.MessageFormName.Default
      : (message.messageForm.name as typeof srcForm);
  }

  let phoneNumber: string | undefined;
  const { telecom } = patient;
  if (Utils.isNonEmptyArray<HRM.ContactPoint>(telecom)) {
    phoneNumber = telecom[0].phoneNumber;
  }

  return {
    guest: {
      dob: patient.birthDate,
      fn: patient.name.firstName,
      hpj: Constants.HealthPlanJurisdiction.Ontario,
      hpn: patient.hcnNumber,
      id: (patient.id as unknown) as number,
      ln: patient.name.lastName,
      ph: phoneNumber,
      sex: patient.gender
    },
    rqid: requestId,
    src,
    srcForm,
    to: practitioners.map(({ practitionerId }) => practitionerId)
  };
}
