import { SchedulingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogNewEvent } from '../../constants/action-context';

const i18n: SchedulingI18n.Dialog.NewEvent = {
  // Translators: (HTML) Title for the New Event dialog
  title: gettext('scheduling', 'newEventDialog', 'title', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Close Dialog action
      label: gettext('scheduling', 'newEventDialog', 'actionLabelReject'),
      context: DialogNewEvent
    },
    accept: {
      // Translators: Label for the Submit New Event dialog action
      label: gettext('scheduling', 'newEventDialog', 'actionLabelAccept'),
      context: DialogNewEvent
    }
  }
};

export default i18n;
