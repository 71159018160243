import { MessagingException, Utils } from '@sigmail/common';
import { DataObjectMsgMetadataValue, DataObjectMsgReadReceiptValue } from '@sigmail/objects';
import { ContactListItemUtil } from '../../../../utils/contact-list';
import { EMPTY_ARRAY } from '../../../constants';
import {
  basicProfileObjectSelector as groupBasicProfileObjectSelector,
  guestListObjectSelector as groupGuestListObjectSelector
} from '../../../selectors/group-object';
import { FetchObjectsRequestData } from '../../base-action';
import { BaseSendMessageAction, BaseSendMessageState } from './base';

export class SendMessageToMailingListAction extends BaseSendMessageAction {
  /** @override */
  protected async buildRecipientList() {
    const { recipientList } = this.payload;
    const { activeGroupId, roleAuthClaim: authState } = this.state;

    const { id: guestContactGroupId } = recipientList[0].entity;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        { type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC, userId: guestContactGroupId },
        { type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST, userId: activeGroupId }
      ]
    };

    await this.dispatchFetchObjects(query);

    const groupProfileBasic = await this.getUserObjectValue(groupBasicProfileObjectSelector, { userId: guestContactGroupId });
    if (Utils.isNil(groupProfileBasic)) {
      throw new MessagingException('Guest contact group profile could not be fetched.');
    }

    const groupGuestList = await this.getUserObjectValue(groupGuestListObjectSelector, { userId: activeGroupId });
    if (Utils.isNil(groupGuestList)) {
      throw new MessagingException('Group guest list could not be fetched.');
    }

    return Utils.filterMap<typeof groupProfileBasic.memberList[0], BaseSendMessageState['recipientList'][0]>(
      groupProfileBasic.memberList,
      ({ id: memberId }) => {
        const entry = groupGuestList.list.find(({ type, id }) => type === 'user' && id === memberId);
        if (Utils.isNil(entry)) {
          this.logger.warn(`User entry with ID <${memberId}> could not be found in group guest list.`);
          return false;
        }
        return { entity: ContactListItemUtil.toMessageRecipient(entry, 'primary') };
      }
    );
  }

  /** @override */
  protected async generateIdSequence() {
    await super.generateIdSequence();

    this.state.msgReadReceiptId = 0;
  }

  /** @override */
  protected async createMessageReadReceiptValue(): Promise<DataObjectMsgReadReceiptValue> {
    return { $$formatver: 1, data: EMPTY_ARRAY };
  }

  /** @override */
  protected addInsertOperationForMessageReadReceipt(): Promise<void> {
    return Promise.resolve();
  }

  /** @override */
  protected getGuestUserForMetadataValue(): Promise<DataObjectMsgMetadataValue['guestUser']> {
    return Promise.resolve<DataObjectMsgMetadataValue['guestUser']>(undefined);
  }

  /** @override */
  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    const value = await super.createMessageMetadataValue();

    const {
      accountStatus,
      emailAddress,
      keyId,
      languagePreference,
      role,
      ...entity
    } = this.payload.recipientList[0].entity;

    return { ...value, recipientList: [{ entity }] };
  }

  /** @override */
  protected createSentMessage() {
    const msgFolderItem = super.createSentMessage();

    const { entity: guestContactGroup } = this.payload.recipientList[0];
    return {
      ...msgFolderItem,
      entity: [Utils.stringOrDefault(guestContactGroup.type === 'group' && guestContactGroup.groupName)]
    };
  }
}
