import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import { Constants, SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { EventLogRecord, MessageFolderItemCount, MessageFolderListItem } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { AUTH_STATE_ASSIGN_MESSAGE_CATEGORY, AUTH_STATE_LOG_EVENT } from '../constants/auth-state-identifier';
import { logEventAction, Payload as LogEventActionPayload } from '../log-event-action';
import { updateMessageFolderAction } from './update-msg-folder-action';

type Payload = MessagingActionPayload.AssignMessageCategory;

interface State extends AuthenticatedActionState {
  batchUpdateClaims: Array<string>;
  dtServer: Date;
  metadataIdSet: Set<SigmailObjectId>;
  metadataIdSetCount: number;
  logRecordList: Array<EventLogRecord>;
  requestBody: BatchUpdateRequestBuilder;
  sourceFolderId: SigmailObjectId;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

class AssignMessageCategoryAction extends AuthenticatedAction<Payload, State> {
  /** @override */
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.generateRequestBody();

        const { batchUpdateClaims: claims, requestBody, successPayload } = this.state;
        const mutations = requestBody.build();
        if (Utils.isArray(mutations.dataObjects) || Utils.isArray(mutations.userObjects)) {
          const { authState: batchUpdateAuthState } = await this.dispatchEnterState({
            authState: this.state.roleAuthClaim,
            state: AUTH_STATE_ASSIGN_MESSAGE_CATEGORY
          });

          await this.dispatchBatchUpdateData({ authState: batchUpdateAuthState, claims, ...mutations });

          try {
            await this.dispatchBatchQueryDataSuccess(successPayload);
          } catch (error) {
            this.logger.warn('Error manually updating app state:', error);
            /* ignore */
          }
        } else {
          this.logger.warn('Call ignored; nothing to update.');
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; batch update operation will be retried.');
      }
    }
  }

  private async generateRequestBody(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;

    this.state.batchUpdateClaims = [];
    this.state.dtServer = await this.dispatchFetchServerDateAndTime();
    this.state.logRecordList = [];
    this.state.metadataIdSet = new Set(this.payload.msgMetadataId);
    this.state.metadataIdSetCount = this.payload.msgMetadataId.length;

    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };

    await this.dispatch(
      updateMessageFolderAction({
        folderKey,
        parentFolderKey,
        requestBody: this.state.requestBody,
        successPayload: this.state.successPayload,
        applyUpdate: this.applyMessageFolderUpdate.bind(this)
      })
    );

    await this.addInsertOperationForUserEventLog(); // 425
  }

  private applyMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    _: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { categoryId } = this.payload;
    const { dtServer, logRecordList, metadataIdSet, metadataIdSetCount } = this.state;

    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };
    if (metadataIdSet.size === 0) {
      result.done = true;
      return result;
    }

    let folderOrExt = 'message folder';
    if (meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER) {
      this.state.sourceFolderId = meta.folderOrExtId;
    } else {
      folderOrExt = `${folderOrExt} extension`;
    }

    for (const msgMetadataId of metadataIdSet) {
      this.logger.info(`Locating item with message metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);

      const index = folderData.findIndex(({ header }) => header === msgMetadataId);
      if (index === -1) return result;

      const message = folderData[index];

      const categoryListPrevious = Utils.arrayOrDefault<number>(message.categoryList);
      const categoryIndex = categoryListPrevious.findIndex((id) => id === categoryId);
      const categoryList = categoryListPrevious.slice();
      if (categoryIndex === -1) {
        categoryList.unshift(categoryId);
      } else if (metadataIdSetCount === 1) {
        categoryList.splice(categoryIndex, 1);
      }

      folderData[index] = { ...message, categoryList: categoryList };
      result.updated = true;

      metadataIdSet.delete(msgMetadataId);

      logRecordList.push(
        this.newEventLogRecordValue(
          dtServer,
          Constants.EventLogCode.MessageCategoryChanged,
          message,
          categoryList,
          categoryListPrevious,
          this.state.sourceFolderId
        )
      );
    }

    return result;
  }

  private async addInsertOperationForUserEventLog(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;
    const {
      activeGroupId,
      batchUpdateClaims,
      currentUser,
      dtServer,
      logRecordList,
      requestBody,
      roleAuthClaim: authState,
      successPayload
    } = this.state;

    if (!Utils.isNonEmptyArray<EventLogRecord>(logRecordList)) return;
    this.logger.info('Adding an insert operation to request body for user event log.');

    const idList = [currentUser.id];
    if (folderKey === Constants.MessageFolderKey.GroupInbox || parentFolderKey === Constants.MessageFolderKey.GroupInbox) {
      idList.push(activeGroupId);
    }

    const actionPayload: LogEventActionPayload = {
      dtServer,
      fetchIds: (count) => {
        return this.dispatchFetchIdsByUsage({
          authState,
          state: AUTH_STATE_LOG_EVENT,
          ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
        });
      },
      logger: this.logger,
      record: logRecordList,
      requestBody,
      successPayload,
      userId: 0,
      userIdType: 'user'
    };

    for (const userId of idList) {
      let { userIdType } = actionPayload;
      if (userId === activeGroupId) userIdType = 'group';
      const claims = await this.dispatch(logEventAction({ ...actionPayload, userId, userIdType }));
      batchUpdateClaims.push(...claims);
    }
  }
}

export const assignMessageCategoryAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'assignMessageCategoryAction:');

    const action = new AssignMessageCategoryAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
