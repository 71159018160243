import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { CollectionObject } from '.';
import type { CollectionObjectPatientRecordListValue as DV } from '../types';

const TYPE = process.env.COLLECTION_OBJECT_TYPE_PATIENT_RECORD_LIST;

/** @public */
export class CollectionObjectPatientRecordList extends CollectionObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.list);
  }

  public updateValue(newValue: DV): Promise<CollectionObjectPatientRecordList> {
    const Class = this.constructor as typeof CollectionObjectPatientRecordList;
    return Class.updateValue(this, newValue);
  }
}
