import { Constants, MembershipStatus, Nullable, SigmailUserId, Utils } from '@sigmail/common';
import { ContactListItemExt } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { BatchQueryRequest, compareContactListItem } from '../../utils/contact-list';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { protectedProfileObjectSelector } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';
import { useCurrentUser } from './use-current-user';
import { UseGuestContactListResult } from './use-guest-contact-list';

export interface UseLinkedContactListParams {
  forceFetch?: Nullable<boolean>;
  unsorted?: Nullable<boolean>;
  users?: Nullable<boolean>;
  userId?: SigmailUserId;
}

type UseLinkedContactListResult = [list: ReadonlyArray<ContactListItemExt>, query: BatchQueryRequest];

export const useLinkedContactList = (params: UseLinkedContactListParams) => {
  const currentUser = useCurrentUser();
  const protectedProfileSelector = useSelector(protectedProfileObjectSelector);
  const protectedProfileObj = protectedProfileSelector(params.userId);
  const protectedProfile = params?.forceFetch === true ? undefined : UserObjectCache.getValue(protectedProfileObj);

  const includeUserList = params?.users === true;
  const unsorted = params?.unsorted === true;

  return React.useMemo((): UseLinkedContactListResult => {
    let contactList: ReadonlyArray<ContactListItemExt> = EMPTY_ARRAY;
    const userObjects: Required<BatchQueryRequest>['userObjectsByType'] = [];

    do {
      if (!includeUserList) break;
      if (Utils.isNil(currentUser)) break;
      if (userObjects.length > 0) break;

      if (Utils.isNil(protectedProfile)) {
        userObjects.push({
          type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
          userId: Utils.numberOrDefault(params.userId, currentUser.id)
        });
        break;
      }

      const { linkedContactList: list } = protectedProfile;

      contactList = !Utils.isNonEmptyArray(list)
        ? []
        : list.reduce((result, contact) => {
            if (contact.type === 'careRecipient') {
              result.push({
                id: contact.id,
                type: 'user',
                userData: {
                  emailAddress: '',
                  role: contact.type,
                  ...Utils.pick(contact, Constants.PERSON_NAME_KEY_LIST)
                }
              });
            }

            if (contact.type === 'caregiver') {
              let accountStatus: MembershipStatus = 'pending';
              if (Utils.isInteger(contact.dtEnd)) {
                accountStatus = 'inactive';
              } else if (Utils.isInteger(contact.dtStart)) {
                accountStatus = 'active';
              }

              result.push({
                id: contact.id,
                type: 'user',
                userData: {
                  accountTimestamp: { activated: contact.dtStart, start: contact.dtInvited, end: contact.dtEnd },
                  accountStatus,
                  accessCode: contact.accessCode,
                  cellNumber: contact.cellNumber,
                  emailAddress: contact.emailAddress!,
                  relationship: contact.relationship,
                  role: contact.type,
                  ...Utils.pick(contact, Constants.PERSON_NAME_KEY_LIST)
                }
              });
            }

            return result;
          }, [] as Array<typeof contactList[0]>);

      if (!unsorted && contactList.length > 1) {
        contactList = contactList.slice().sort((a, b) => {
          const dtEnd1 = a.type === 'user' && a.userData.accountTimestamp?.end;
          const dtEnd2 = b.type === 'user' && b.userData.accountTimestamp?.end;

          if (Utils.isNil(dtEnd1) && Utils.isNil(dtEnd2)) {
            return compareContactListItem(a, b);
          } else if (Utils.isNil(dtEnd1)) {
            return -1;
          } else if (Utils.isNil(dtEnd2)) {
            return 1;
          }

          return compareContactListItem(a, b);
        });
      }
    } while (false);

    let query: UseGuestContactListResult[1] = EMPTY_PLAIN_OBJECT;
    if (userObjects.length > 0) query = { userObjectsByType: userObjects };

    return [contactList, query];
  }, [currentUser, includeUserList, params.userId, protectedProfile, unsorted]);
};
