import { AccountI18n } from '@sigmail/i18n';
import { DialogEditMessageSignature } from '../../constants/action-context';

const i18n: AccountI18n.Dialog.EditMessageSignature = {
  action: {
    accept: { label: 'Done', context: DialogEditMessageSignature },
    reject: { label: 'Cancel', context: DialogEditMessageSignature }
  },
  formField: {
    editor: {
      label: 'Signature',
      placeholder: 'Signature'
    }
  },
  title: 'Edit message signature'
};

export default i18n;
