import { decodeBase64, genSaltSync } from 'bcryptjs';
import { stringOrDefault } from '.';
import { uint8ArrayToHex } from './uint8-array-to-hex';

const ROUNDS = 10; // IMPORTANT: value must be between 10 and 31
export const PREFIX = ['$2a$', ROUNDS.toString(10).padStart(2, '0'), '$'].join('');

/**
 * Generates a salt value.
 *
 * @param encoder - Valid values are `hex` and `base64`. Default is `hex`.
 */
export function generateSalt(encoder: 'hex' | 'base64' = 'hex'): string {
  const enc = stringOrDefault(encoder === 'base64' && encoder, 'hex') as typeof encoder;

  const b64Salt = genSaltSync(ROUNDS); // generates a 128-bit salt value
  const nonPrefixedB64Salt = b64Salt.substring(PREFIX.length);
  if (enc === 'base64') return nonPrefixedB64Salt;

  const byteArray = decodeBase64(nonPrefixedB64Salt, 16);
  return uint8ArrayToHex(new Uint8Array(byteArray));
}
