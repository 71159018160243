import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.DataViewer.CardiacIntake = {
  columnHeader: {
    question: gettext('health-data', 'question', 'cardiacIntakeDataViewerColumnHeader')
  },
  dataNotAvailable: gettext('health-data', 'dataNotAvailable', 'cardiacIntakeDataViewer'),
  dataValueNotSet: gettext('health-data', 'dataValueNotSet', 'cardiacIntakeDataViewer')
};

export default i18n;
