import {
  LocalAudioTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteTrackPublication,
  RemoteVideoTrack
} from 'twilio-video';

declare module 'twilio-video' {
  interface LocalVideoTrack {
    isSwitchedOff: undefined;
    setPriority: undefined;
  }
}

export type ITrackPublication = LocalTrackPublication | RemoteTrackPublication;
export type ILocalTrack = LocalAudioTrack | LocalVideoTrack;
export type IRemoteTrack = RemoteAudioTrack | RemoteVideoTrack;
export type IAudioTrack = LocalAudioTrack | RemoteAudioTrack;
export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

export type PermissionName =
  | 'accelerometer'
  | 'ambient-light-sensor'
  | 'background-fetch'
  | 'background-sync'
  | 'bluetooth'
  | 'camera'
  | 'display-capture'
  | 'geolocation'
  | 'gyroscope'
  | 'magnetometer'
  | 'microphone'
  | 'midi'
  | 'nfc'
  | 'notifications'
  | 'persistent-storage'
  | 'push'
  | 'screen-wake-lock'
  | 'speaker-selection'
  | 'xr-spatial-tracking';

function createError(name: string): Error {
  const error = new Error();
  error.name = name;
  return error;
}

export const AlreadyConnectedError = createError('AlreadyConnected');
export const EventObjectFetchFailureError = createError('EventObjectFetchFailure');
export const MeetingNotStartedError = createError('MeetingNotStarted');
export const ExpiredMeetingError = createError('ExpiredMeeting');
export const MeetingAccessTokenError = createError('AccessTokenFetchFailure');
export const AudioInputDeviceMissingError = createError('AudioInputDeviceMissing');
export const MicrophonePermissionDeniedError = createError('MicrophonePermissionDenied');
// export const VideoInputDeviceMissingError = createError('VideoInputDeviceMissing');
export const CameraPermissionDeniedError = createError('CameraPermissionDenied');
export const MediaPermissionDeniedError = createError('MediaPermissionDenied');
