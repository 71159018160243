import { Nullable, Utils } from '@sigmail/common';
import React from 'react';
import { SearchPatientRecord } from '.';
import { DialogActionsAcceptReject } from '../../app/shared/dialog/actions/accept-reject.component';
import { DialogBoxProps } from '../../app/shared/dialog/dialog-box.component';
import {
  Props as GridProps,
  SelectPatientRecordGrid as Grid
} from '../../app/shared/select-patient-record-grid.component';
import { DialogAccept as ActionIdDialogAccept, DialogReject as ActionIdDialogReject } from '../../constants/action-ids';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { resolveActionLabel } from '../../utils/resolve-action-label';

export interface UseSelectPatientRecordDialogPropsParams {
  readonly list?: ReadonlyArray<SearchPatientRecord>;
  readonly onAccept?: (event: React.MouseEvent<HTMLButtonElement>, record: Nullable<SearchPatientRecord>) => void;
  readonly onReject?: React.MouseEventHandler<HTMLButtonElement>;
  readonly open: boolean;
}

export type SelectPatientRecordDialogProps = Pick<
  DialogBoxProps,
  'TitleProps' | 'actions' | 'body' | 'disableBackdropClick' | 'disableEscapeKeyDown' | 'open'
>;

const DEFAULT_PROPS: SelectPatientRecordDialogProps = {
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
  open: false
};

export const useSelectPatientRecordDialogProps = ({
  list,
  onAccept,
  onReject,
  open
}: UseSelectPatientRecordDialogPropsParams): SelectPatientRecordDialogProps => {
  const { t } = useTranslation(I18N_NS_GLOBAL);
  const [selectedRecord, setSelectedRecord] = React.useState<Nullable<SearchPatientRecord>>(null);

  const onGridRowSelected = React.useCallback<NonNullable<NonNullable<GridProps['GridProps']>['onRowSelected']>>(
    ({ api }) => {
      const [selectedNode] = api.getSelectedRows();
      return setSelectedRecord(Utils.isNil(selectedNode) ? null : selectedNode);
    },
    []
  );

  const onClickAction = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      const actionId = Utils.stringOrDefault(event.currentTarget.getAttribute('data-action-id'));
      const isActionDialogAccept = actionId === ActionIdDialogAccept;
      const isActionDialogReject = !isActionDialogAccept && actionId === ActionIdDialogReject;

      if (isActionDialogAccept && Utils.isNotNil(selectedRecord)) {
        onAccept?.(event, selectedRecord);
      } else if (isActionDialogReject) {
        onReject?.(event);
      }
    },
    [onAccept, onReject, selectedRecord]
  );

  React.useEffect(() => void (!open && setSelectedRecord(null)), [open]);

  return React.useMemo((): SelectPatientRecordDialogProps => {
    const { selectPatientRecord: i18n } = globalI18n.dialog;

    return {
      ...DEFAULT_PROPS,

      TitleProps: {
        dangerouslySetInnerHTML: { __html: t(i18n.title) }
      },
      actions: open && (
        <DialogActionsAcceptReject
          AcceptActionProps={{
            children: t(resolveActionLabel(i18n.action.accept, ActionIdDialogAccept)),
            disabled: Utils.isNil(selectedRecord),
            type: 'button'
          }}
          RejectActionProps={{ children: t(resolveActionLabel(i18n.action.reject, ActionIdDialogReject)) }}
          onAccept={onClickAction}
          onReject={onClickAction}
        />
      ),
      body: open && <Grid GridProps={{ onRowSelected: onGridRowSelected }} rowData={list} />,
      open
    };
  }, [list, onClickAction, onGridRowSelected, open, selectedRecord, t]);
};
