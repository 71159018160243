import { MAKE_ERROR_CODE } from '../../utils/make-error-code';
import * as Facility from './facility';
import { SEVERITY_ERROR, SEVERITY_FATAL, SEVERITY_SUCCESS, SEVERITY_WARN } from './severity';

// +----------------------------------------------------------------------------
// |
// | General error codes
// |
// +----------------------------------------------------------------------------

/** Code indicating that an operation succeeded without any errors. */
export const S_OK = MAKE_ERROR_CODE(SEVERITY_SUCCESS, Facility.FACILITY_NULL, 0);

/**
 * Generic error code indicating that an operation failed due to a severe error.
 */
export const S_FATAL = MAKE_ERROR_CODE(SEVERITY_FATAL, Facility.FACILITY_NULL, 100);

/**
 * Generic error code indicating that an operation failed with one or more
 * errors.
 */
export const S_ERROR = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 200);

/**
 * Generic error code indicating that an operation completed but with one
 * or more warnings.
 */
export const S_WARN = MAKE_ERROR_CODE(SEVERITY_WARN, Facility.FACILITY_NULL, 300);

/** Generic error code to indicate a missing implementation. */
export const E_NOT_IMPLEMENTED = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 2);

/** Error code to indicate that a given object ID is invalid. */
export const E_INVALID_OBJECT_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 3);

/** Error code to indicate that a given object type is invalid. */
export const E_UNKNOWN_OBJECT_TYPE = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 4);

/** Error code to indicate that a given object version is invalid. */
export const E_INVALID_OBJECT_VERSION = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 5);

/** Error code to indicate that a given object value is invalid. */
export const E_INVALID_OBJECT_VALUE = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 6);

/** Error code to indicate that a given user or group ID is invalid. */
export const E_INVALID_USER_OR_GROUP_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 7);

/** Error code to indicate that a given object's creation date is invalid. */
export const E_INVALID_CREATION_DATE = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 8);

/** Error code to indicate that a given object's expiry date is invalid. */
export const E_INVALID_EXPIRY_DATE = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 9);

/**
 * Generic error code to indicate that either required data is missing or has
 * an unexpected format.
 */
export const E_DATA_MISSING_OR_INVALID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 10);

/** Error code to indicate that a user group's type is invalid. */
export const E_INVALID_GROUP_TYPE = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 11);

/** Error code to indicate that a claim is either missing or invalid. */
export const E_CLAIM_MISSING_OR_INVALID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_NULL, 12);

// +----------------------------------------------------------------------------
// |
// | Authentication error codes
// |
// +----------------------------------------------------------------------------

/** Generic error code for Authentication failure scenarios. */
export const E_AUTH_FAIL = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 0);

/** Error code to indicate that salt value is either missing or invalid. */
export const E_AUTH_FAIL_SALT = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 1);

/** Error code indicating failure to decode a JWT ID token. */
export const E_AUTH_FAIL_DECODE_ID_TOKEN = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 2);

/** Error code to indicate that user ID is either missing or invalid. */
export const E_AUTH_FAIL_USER_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 3);

/** Error code to indicate that key ID is either missing or invalid. */
export const E_AUTH_FAIL_KEY_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 4);

/** Error code to indicate that credential ID is either missing or invalid. */
export const E_AUTH_FAIL_CREDENTIAL_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 5);

/** Error code to indicate that auth state is either missing or invalid. */
export const E_AUTH_FAIL_AUTH_STATE = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 6);

/**
 * Error code indicating that a credential's shared parameters are either
 * missing or invalid.
 */
export const E_AUTH_FAIL_SHARED_PARAMS = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 7);

/** Error code to indicate that credential type is either missing or invalid. */
export const E_AUTH_FAIL_CREDENTIAL_TYPE = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_AUTH, 8);

// +----------------------------------------------------------------------------
// |
// | Messaging error codes
// |
// +----------------------------------------------------------------------------

/** Generic error code for Messaging related exceptions. */
export const E_MESSAGING_FAIL = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_MESSAGING, 0);

/** Error code indicating failure to retrieve a valid owner ID. */
export const E_MESSAGING_FAIL_OWNER_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_MESSAGING, 1);

/** Error code indicating failure to retrieve a valid folder ID. */
export const E_MESSAGING_FAIL_FOLDER_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_MESSAGING, 2);

/** Error code indicating failure due to missing or invalid folder data. */
export const E_MESSAGING_FAIL_FOLDER_DATA_INVALID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_MESSAGING, 3);

/** Error code indicating failure due to an invalid message metadata ID. */
export const E_MESSAGING_FAIL_MSG_METADATA_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_MESSAGING, 4);

/** Error code indicating failure due to an invalid message body ID. */
export const E_MESSAGING_FAIL_MSG_BODY_ID = MAKE_ERROR_CODE(SEVERITY_ERROR, Facility.FACILITY_MESSAGING, 5);
