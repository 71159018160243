/* eslint-disable no-unused-vars */

import type {
  AlgorithmCode,
  EncapsulatedKey,
  EncryptWithParametersAlgorithmCode,
  Nullable,
  SigmailKeyId,
  SigmailObjectId,
  SigmailObjectTypeCode
} from '@sigmail/common';
import { Utils } from '@sigmail/common';
import type { EncryptWithParametersAlgorithmParams } from '@sigmail/crypto';
import { Algorithm, Constants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import { CryptographicKey } from '.';
import type { ICryptographicKeyAudit } from '../types';

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_AUDIT;

/** @public */
export class CryptographicKeyAudit extends CryptographicKey<EncapsulatedKey> implements ICryptographicKeyAudit {
  protected static override get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_ENCAPSULATED_KEY as AlgorithmCode;
  }

  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  public static async createForCredential(
    credentialId: SigmailObjectId,
    params: EncryptWithParametersAlgorithmParams,
    encryptedFor: SigmailKeyId,
    createdAtUtc: Date
  ): Promise<CryptographicKeyAudit> {
    const algorithm = getAlgorithm(
      process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS as EncryptWithParametersAlgorithmCode
    );

    const key = await algorithm.generateKey(params);
    return this.create(credentialId, undefined, 0, key, encryptedFor, createdAtUtc);
  }

  public static async create(
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number,
    key: EncapsulatedKey,
    encryptedFor: SigmailKeyId,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  ): Promise<CryptographicKeyAudit> {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;
    const value = await this.encryptEncapsulatedKey(key, { encryptedFor, keyCode, keyVersion: version });

    const args = [id, keyCode, version, value, encryptedFor, createdAtUtc, expiredAtUtc];
    return Reflect.construct(this, args) as CryptographicKeyAudit;
  }

  public static async encryptFor(key: null, id: number, createdAtUtc?: Date): Promise<null>;

  public static async encryptFor(
    key: ICryptographicKeyAudit,
    id: SigmailKeyId,
    createdAtUtc?: Date
  ): Promise<ICryptographicKeyAudit>;

  public static async encryptFor(
    key: unknown,
    id: SigmailKeyId,
    createdAtUtc?: Date
  ): Promise<ICryptographicKeyAudit | null> {
    if (key === null) return null;

    const { id: keyId, createdAtUtc: dtCreate } = key as ICryptographicKeyAudit;
    const encapsulatedKey = await (key as ICryptographicKeyAudit).decryptedValue();

    return this.create(keyId, undefined, 0, encapsulatedKey, id, Utils.dateOrDefault(createdAtUtc, dtCreate));
  }

  public encryptFor(id: SigmailKeyId, createdAtUtc?: Date): Promise<ICryptographicKeyAudit> {
    const Class = this.constructor as typeof CryptographicKeyAudit;
    return Class.encryptFor(this, id, createdAtUtc);
  }

  public override decryptedValue(): Promise<EncapsulatedKey> {
    if (!Algorithm.isValidEncryptEncapsulatedKeyCode(this.code)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }

    const algorithm = getAlgorithm(this.code);
    const Class = this.constructor as typeof CryptographicKeyAudit;
    const cryptoKey = Class.getPrivateKey(this.encryptedForId);

    return algorithm.decrypt({ privateKey: cryptoKey }, this.value, this.version);
  }
}
