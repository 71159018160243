import { Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectAccessRight } from '../../app-state/selectors/auth';
import { ScheduleGroup, ScheduleOwn } from '../../constants/action-ids';
import { useTranslation } from '../../i18n';
import { I18N_NS_SCHEDULING } from '../../i18n/config/namespace-identifiers';
import schedulingI18n from '../../i18n/scheduling';
import { processImgProps } from '../../utils/process-img-props';
import { resolveActionIcon } from '../../utils/resolve-action-icon';
import { resolveActionPath } from '../../utils/resolve-action-path';
import layoutStyle from '../layout/layout.module.css';
import {
  NavAction,
  NavActionList,
  NavActionListClassKey,
  Props as NavActionListProps
} from '../shared/nav-action-list.component';

const { navAction: i18n } = schedulingI18n;

export interface Props extends Omit<NavActionListProps, 'actionList' | 'ref'> {
  onNavLinkClick?: NavAction['onClick'] | undefined;
  renderAction?: NavAction['render'] | undefined;
  renderActionLabel?: NavAction['renderLabel'] | undefined;
}

const DEFAULT_CLASSES: Record<NavActionListClassKey, string> = {
  anchor: layoutStyle.anchor,
  listItem: layoutStyle.li
};

export const SchedulingNavActionList = React.forwardRef<HTMLUListElement, Props>(
  ({ children, classes: classesProp, onNavLinkClick, renderAction, renderActionLabel, ...rootProps }, ref) => {
    // prettier-ignore
    const { t, i18n: { language: locale } } = useTranslation([I18N_NS_SCHEDULING]);
    const { pathname: currentPathname } = useLocation();

    const hasAccessRight = useSelector(selectAccessRight);
    const canAccessOwnSchedule = hasAccessRight('accessOwnSchedule');
    const canAccessGroupSchedule = hasAccessRight('accessGroupSchedule');

    const navActionList = React.useMemo<ReadonlyArray<NavAction>>(() => {
      return [canAccessOwnSchedule && ScheduleOwn, canAccessGroupSchedule && ScheduleGroup]
        .filter(Utils.isString)
        .map<NavAction>((actionId) => {
          let { iconPrimary, iconSecondary, ...action } = i18n[actionId];
          iconPrimary = resolveActionIcon({ icon: iconPrimary }, actionId);
          iconSecondary = resolveActionIcon({ icon: iconSecondary }, actionId);
          const pathname = t(resolveActionPath(action, actionId));
          const actionIcon = currentPathname === pathname ? iconPrimary : iconSecondary;
          const image = processImgProps({ ...actionIcon, src: actionIcon.src[locale] });

          return {
            ...action,
            actionId,
            exact: true,
            image,
            onClick: onNavLinkClick,
            render: renderAction,
            renderLabel: renderActionLabel
          };
        });
    }, [
      canAccessGroupSchedule,
      canAccessOwnSchedule,
      currentPathname,
      locale,
      onNavLinkClick,
      renderAction,
      renderActionLabel,
      t
    ]);

    const classes = React.useMemo(() => Utils.defaults({}, classesProp as unknown, DEFAULT_CLASSES), [classesProp]);
    return <NavActionList actionList={navActionList} classes={classes} ref={ref} {...rootProps} />;
  }
);

SchedulingNavActionList.displayName = 'SchedulingNavActionList';
SchedulingNavActionList.defaultProps = { className: layoutStyle.ul };
