import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['form']['signInCredentials'] = {
  fieldsetCredentials: {
    formField: {
      username: {
        // Translators: Label for the Username input
        label: gettext('global', 'signInCredentialsForm', 'inputLabelUsername'),
        error: {
          // Translators: Error displayed when username is left blank
          valueMissing: gettext('global', 'signInCredentialsForm', 'errorUsernameRequired')
        }
      },
      password: {
        // Translators: Label for the Password input
        label: gettext('global', 'signInCredentialsForm', 'inputLabelPassword'),
        // Translators: ARIA-label for the Show Password icon
        ariaLabelShowPassword: gettext('global', 'signInCredentialsForm', 'ariaLabelShowPassword'),
        // Translators: ARIA-label for the Hide Password icon
        ariaLabelHidePassword: gettext('global', 'signInCredentialsForm', 'ariaLabelHidePassword'),
        error: {
          // Translators: Error displayed when password is left blank
          valueMissing: gettext('global', 'signInCredentialsForm', 'errorPasswordRequired')
        }
      }
    }
  }
};

export default i18n;
