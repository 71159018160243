import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogEditProfile, DialogEditProfileSignIn, DialogVersionConflict } from '../../constants/action-context';

const i18n: AccountI18n.Dialog.EditProfile = {
  title: {
    // Translators: (HTML) Title for Edit Profile dialog's Edit Profile step
    editProfile: gettext('account', 'profile', 'editProfileDialogTitle', 'safe-html'),
    // Translators: (HTML) Title for Edit Profile dialog's Verify ID step
    signIn: gettext('account', 'signIn', 'editProfileDialogTitle', 'safe-html'),
    // Translators: (HTML) Title for Version Conflict Error dialog
    versionConflict: gettext('account', 'versionConflict', 'editProfileDialogTitle', 'safe-html')
  },
  body: {
    // Translators: (HTML) Body for Edit Profile dialog's Verify ID step
    signIn: gettext('account', 'signIn', 'editProfileDialogBody', 'safe-html'),
    // Translators: (HTML) Body for Version Conflict Error dialog
    versionConflict: gettext('account', 'versionConflict', 'editProfileDialogBody', 'safe-html')
  },
  action: {
    editProfile: {
      accept: {
        // Translators: Label for the Submit action of Edit Profile dialog's \
        // Edit Profile step
        label: gettext('account', 'accept', 'editProfileDialogActionLabel'),
        context: DialogEditProfile
      },
      reject: {
        // Translators: Label for the Cancel action of Edit Profile dialog's \
        // Edit Profile step
        label: gettext('account', 'reject', 'editProfileDialogActionLabel'),
        context: DialogEditProfile
      }
    },
    signIn: {
      accept: {
        // Translators: Label for the Submit action of Edit Profile dialog's \
        // Verify ID step
        label: gettext('account', 'acceptSignIn', 'editProfileDialogActionLabel'),
        context: DialogEditProfileSignIn
      },
      reject: {
        // Translators: Label for the Cancel action of Edit Profile dialog's \
        // Verify ID step
        label: gettext('account', 'rejectSignIn', 'editProfileDialogActionLabel'),
        context: DialogEditProfileSignIn
      }
    },
    versionConflict: {
      accept: {
        // Translators: Label for the Dismiss action of Version Conflict Error \
        // dialog
        label: gettext('account', 'rejectVersionConflict', 'editProfileDialogActionLabel'),
        context: DialogVersionConflict
      }
    }
  }
};

export default i18n;
