import { Utils } from '@sigmail/common';
import { Algorithm } from '.';
import { E_FAIL, E_FAIL_IMPORT_KEY } from '../constants';
import * as Encoder from '../encoder';
import { Encryptor } from '../encryptor';
import { RSA_OAEP } from '../encryptor/asymmetric/RSA_OAEP';
import { AES_GCM } from '../encryptor/symmetric/AES_GCM';
import { TKDF } from '../key-derivation-function/TKDF';
import { SigmailCryptoException } from '../SigmailCryptoException';
import type { AsymmetricEncryptor, AsymmetricKey, EncryptForServerAlgorithm, SymmetricEncryptor } from '../types';

/**
 * Define the algorithm EncryptForServerAlgorithm. This is used to encrypt
 * a request to the server API, using a public key for the server
 * another public key (UK).
 *
 * - An encapsulated key (352 bits) is created for each use (C)
 * - An AES key (AK, 256 bits) and initialization vector (IV, 96 bits)
 *   are derived from the encapsulated key by extracting the first 256 bits and the last 128 bits
 * - The encapsulated key is encrypted using a public key provided by the server
 * - The key to encrypt is encrypted using AES in GCM mode
 * - The encrypted encapsulated key and encrypted key are concatenated and
 *   sent to the server API as the request
 *
 * @author Kim Birchard <kbirchard@sigmahealthtech.com>
 * @public
 */
export class EncryptForServerAlgorithmImpl
  extends Algorithm<AsymmetricEncryptor, JsonWebKey>
  implements EncryptForServerAlgorithm
{
  protected readonly encapsulatedKeyEncryptor: SymmetricEncryptor;

  public constructor(encapsulatedKeyEncryptor?: SymmetricEncryptor) {
    // create an instance of RSA_OAEP, with all default parameters
    super('EncryptForServerAlgorithm', new RSA_OAEP());

    this.encapsulatedKeyEncryptor =
      encapsulatedKeyEncryptor instanceof Encryptor
        ? encapsulatedKeyEncryptor
        : // create an instance of AES_GCM, with required parameters and
          // key derivation primitives
          //
          // create instances of TKDF for deriving AES key, IV, and AAD
          //  the iv is changed to 96, as .NET only supports 96 bit IV values
          //   this is also the recommended behavior by standards (only accept 96 bit IV)
          new AES_GCM({
            encapsulatedKeyLength: 352,
            tagLength: 128,
            keyDf: new TKDF({ startOffset: 0, outLength: 256 }),
            ivDf: new TKDF({ startOffset: 256, outLength: 96 }),
            adDf: new TKDF({ startOffset: 0, outLength: 0 })
          });
  }

  // version is not included, as the symmetric key is only used once
  public async decrypt(key: AsymmetricKey, data: string, __UNUSED_version: number): Promise<JsonWebKey> {
    // split given data to get the encrypted encapsulated key and the
    // encrypted data
    const b64Encoded = data.split('.') as [encapsulatedKey: string, encryptedValue: string];
    if (b64Encoded.length !== 2 || b64Encoded[0].trim().length === 0 || b64Encoded[1].trim().length === 0) {
      throw new SigmailCryptoException(E_FAIL, 'Unexpected data format.');
    }

    // decrypt the encapsulated key
    let encryptedValue = Encoder.Base64.decode(b64Encoded[0]);
    const encapsulatedKey = await this.encryptor.decrypt(key, encryptedValue);

    // decrypt data using a key derived from the encapsulated key and version of 0
    encryptedValue = Encoder.Base64.decode(b64Encoded[1]);
    const symmetricKey = await this.encapsulatedKeyEncryptor.deriveKey(encapsulatedKey, 0);
    const utf8Encoded = await this.encapsulatedKeyEncryptor.decrypt(symmetricKey, encryptedValue);
    const decryptedValue = Encoder.UTF8.decode(utf8Encoded);
    return JSON.parse(decryptedValue) as JsonWebKey;
  }

  // version is not included, as the symmetric key is only used once
  public async encrypt(key: AsymmetricKey, data: unknown, __UNUSED_version: number): Promise<string> {
    // generate a new encapsulated key for each encryption
    const encapsulatedKey = await this.encapsulatedKeyEncryptor.generateKey();
    let encryptedValue = await this.encryptor.encrypt(key, encapsulatedKey);
    const b64Encoded = [Encoder.Base64.encode(encryptedValue)];

    // encrypt data using a key derived from the encapsulated key and version of 0
    const symmetricKey = await this.encapsulatedKeyEncryptor.deriveKey(encapsulatedKey, 0);
    const utf8EncodedData = Encoder.UTF8.encode(JSON.stringify(data));
    encryptedValue = await this.encapsulatedKeyEncryptor.encrypt(symmetricKey, utf8EncodedData);
    b64Encoded.push(Encoder.Base64.encode(encryptedValue));

    // concatenate the encrypted encapsulated key and the encrypted data
    return b64Encoded.map((encoded) => `${encoded}${'='.repeat((4 - (encoded.length % 4)) % 4)}`).join('.');
  }

  // this generates a new key pair, so is not useful for this implementation (the key pair is generated on the server)
  public generateKey(): Promise<AsymmetricKey> {
    return this.encryptor.generateKey();
  }

  // this imports an asymmetric public key in SPKI(PEM) format into internal format
  //  required as .NET does not support JWK
  //  it can also handle JWK format public or private keys
  public async importKey(key: JsonWebKey | string): Promise<CryptoKey> {
    try {
      // these are known fixed values
      const algo: RsaHashedImportParams = { name: 'RSA-OAEP', hash: 'SHA-256' };
      if (Utils.isString(key)) {
        // convert the b64 string into a binary array
        const binKey = Encoder.Base64.decode(key);
        return await crypto.subtle.importKey('spki', binKey, algo, /* extractable := */ false, ['encrypt']);
      } else {
        // handle the JsonWebKey, it could be a public or private key
        return await crypto.subtle.importKey(
          'jwk',
          key,
          algo,
          /* extractable := */ false,
          key.key_ops as Array<KeyUsage>
        );
      }
    } catch {
      throw new SigmailCryptoException(E_FAIL_IMPORT_KEY);
    }
  }
}
