import { MessagingI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import { DialogReplyWindowClosed } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeMessage['dialog']['replyWindowClosed'] = {
  // Translators: (HTML) Title for the Reply Window Closed dialog
  title: gettext('messaging', 'title', 'dialogReplyWindowClosed', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of Reply Window \
  // Closed dialog
  body: ngettext('messaging', 'body', 'dialogReplyWindowClosed', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Proceed action of Reply Window Closed \
      // dialog
      label: gettext('messaging', 'accept', 'dialogReplyWindowClosedActionLabel'),
      context: DialogReplyWindowClosed
    }
  }
};

export default i18n;
