import { PartialRecord, Utils } from '@sigmail/common';
import { ContactListItem } from '@sigmail/objects';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import { FrenchCanada } from '../../../constants/language-codes';
import { withTranslation } from '../../../i18n';
import { I18N_NS_GLOBAL } from '../../../i18n/config/namespace-identifiers';
import { FieldConfig } from '../../shared/form';
import { FormComponent, FormComponentProps, FormComponentState } from '../../shared/form/form.component';
import { SelectFieldProps as BaseSelectFieldProps } from '../../shared/form/select-field.component';
import sharedFormStyle from '../../shared/forms.module.css';
import style from './group-selection-form.module.css';

export type FieldsetKey = 'fieldsetDefault';
export type FieldNameDefaultFieldset = 'group';
export type FieldName = FieldNameDefaultFieldset;

export type FormValues = Record<Extract<FieldName, 'group'>, ContactListItem>;

interface SelectFieldProps extends Omit<BaseSelectFieldProps<ContactListItem, false>, 'inputState'> {
  config?: FieldConfig<ContactListItem> | undefined;
  render?: ((props: BaseSelectFieldProps<ContactListItem, false>) => React.ReactNode) | undefined;
}

const FIELD_SET_LIST: ReadonlyArray<Readonly<[FieldsetKey, ReadonlyArray<FieldName>]>> = [
  ['fieldsetDefault', ['group']]
];

const FIELD_PROPS: Record<Extract<FieldName, 'group'>, SelectFieldProps> = {
  group: {
    getOptionKey: ({ id }) => id,
    getOptionLabel: (contact) =>
      contact.type === 'group' ? contact.groupData.groupName : Utils.joinPersonName(contact.userData),
    label: 'Group',
    multiple: false,
    options: EMPTY_ARRAY
  }
};

export type GroupSelectionFormClassKey = 'inputRoot';

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<GroupSelectionFormClassKey, string> | undefined;
  groupList: ReadonlyArray<ContactListItem>;
  isCaregiver: boolean;
}

interface ComponentProps extends Props, WithTranslation {}
interface State extends FormComponentState<FieldName, FormValues> {}

class GroupSelectionFormComponent extends FormComponent<
  FieldsetKey,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes',
      'groupList',
      'isCaregiver'
    );

    this.createForm({ initialValues: { group: null! } });
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-select-group-${this.formIdSuffix}`;
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldType(fieldName: 'group', ...args: any[]) {
    return fieldName === 'group' ? 'select' : super.getFieldType(fieldName, ...args);
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { groupList, i18n, isCaregiver, t } = this.props;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    if (fieldName === 'group') {
      label = i18n.language === FrenchCanada ? 'Groupe' : 'Group';
      if (isCaregiver) label = 'Care recipient';
      fieldProps = { ...fieldProps, options: groupList };
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]) {
    const { classes } = this.props;

    const fieldNode = super.renderField(fieldName, ...args);
    if (Utils.isNil(fieldNode) || fieldNode === false) return null;

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }
}

const ns = [I18N_NS_GLOBAL];

export const GroupSelectionForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['form-input-group']
    } as ComponentProps['classes']);

    return <GroupSelectionFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

GroupSelectionForm.displayName = 'GroupSelectionForm';
GroupSelectionForm.defaultProps = { className: sharedFormStyle['form-base'] };
