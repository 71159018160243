import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Form.EMRConfiguration = {
  accuro: {
    fieldsetDefault: {
      formField: {
        baseUri: {
          // Translators: Label for the Base URI input of Accuro EMR \
          // Configuration form
          label: gettext('account', 'baseUri', 'accuroEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Base URI is left \
            // blank on Accuro EMR Configuration form
            valueMissing: gettext('account', 'baseUriRequired', 'accuroEMRConfigFormError'),
            // Translators: Error message displayed when Base URI contains \
            // invalid characters on Oscar EMR Configuration form
            patternMismatch: gettext('account', 'baseUriPattern', 'accuroEMRConfigFormError'),
            // Translators: Error message displayed when Base URI doesn't \
            // start with HTTPS on Oscar EMR Configuration form
            requireHttpsUri: gettext('account', 'baseUriRequireHttps', 'accuroEMRConfigFormError')
          }
        },
        clientId: {
          // Translators: Label for the Client ID input of Accuro EMR \
          // Configuration form
          label: gettext('account', 'clientId', 'accuroEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Client ID is left \
            // blank on Accuro EMR Configuration form
            valueMissing: gettext('account', 'clientIdRequired', 'accuroEMRConfigFormError'),
            // Translators: Error message displayed when Client Key contains \
            // invalid characters on Accuro EMR Configuration form
            patternMismatch: gettext('account', 'clientIdPattern', 'accuroEMRConfigFormError')
          }
        },
        clientSecret: {
          // Translators: Label for the Client Secret input of Accuro EMR \
          // Configuration form
          label: gettext('account', 'clientSecret', 'accuroEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Client Secret is left \
            // blank on Accuro EMR Configuration form
            valueMissing: gettext('account', 'clientSecretPattern', 'accuroEMRConfigFormError'),
            // Translators: Error message displayed when Client Secret contains \
            // invalid characters on Accuro EMR Configuration form
            patternMismatch: gettext('account', 'clientSecretPattern', 'accuroEMRConfigFormError')
          }
        },
        uuid: {
          // Translators: Label for the UUID input of Accuro EMR Configuration \
          // form
          label: gettext('account', 'UUID', 'accuroEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when UUID is left blank on \
            // Accuro EMR Configuration form
            valueMissing: gettext('account', 'UUIDRequired', 'accuroEMRConfigFormError'),
            // Translators: Error message displayed when UUID contains invalid \
            // characters on Accuro EMR Configuration form
            patternMismatch: gettext('account', 'UUIDPattern', 'accuroEMRConfigFormError')
          }
        }
      }
    },
    fieldsetExpiry: {
      formField: {
        tokenExpiry: {
          error: {
            // Translators: Error message displayed when no option is selected \
            // for Token Expiry input in Accuro EMR Configuration form
            valueMissing: gettext('account', 'tokenExpiryRequired', 'accuroEMRConfigFormError')
          },
          // Translators: Label for the Token Expiry input of Accuro EMR \
          // Configuration form
          label: gettext('account', 'tokenExpiry', 'accuroEMRConfigFormInputLabel'),
          options: [
            {
              code: '',
              // Translators: Label for the Never option of Token Expiry input \
              // in Accuro EMR Configuration form
              label: gettext('account', 'tokenExpiryNever', 'accuroEMRConfigFormOptionLabel')
            },
            {
              code: '1D',
              // Translators: Label for the One Day option of Token Expiry \
              // input in Accuro EMR Configuration form
              label: gettext('account', 'tokenExpiry1D', 'accuroEMRConfigFormOptionLabel')
            },
            {
              code: '1W',
              // Translators: Label for the One Week option of Token Expiry \
              // input in Accuro EMR Configuration form
              label: gettext('account', 'tokenExpiry1W', 'accuroEMRConfigFormOptionLabel')
            },
            {
              code: '1M',
              // Translators: Label for the One Month option of Token Expiry \
              // input in Accuro EMR Configuration form
              label: gettext('account', 'tokenExpiry1M', 'accuroEMRConfigFormOptionLabel')
            },
            {
              code: '3M',
              // Translators: Label for the Three Months option of Token \
              // Expiry input in Accuro EMR Configuration form
              label: gettext('account', 'tokenExpiry3M', 'accuroEMRConfigFormOptionLabel')
            },
            {
              code: '6M',
              // Translators: Label for the Six Months option of Token Expiry \
              // input in Accuro EMR Configuration form
              label: gettext('account', 'tokenExpiry6M', 'accuroEMRConfigFormOptionLabel')
            },
            {
              code: '1Y',
              // Translators: Label for the One Year option of Token Expiry \
              // input in Accuro EMR Configuration form
              label: gettext('account', 'tokenExpiry1Y', 'accuroEMRConfigFormOptionLabel')
            }
          ]
        }
      }
    }
  },
  oscar: {
    fieldsetDefault: {
      formField: {
        authUri: {
          // Translators: Label for the Authorization URI input of Oscar EMR \
          // Configuration form
          label: gettext('account', 'authUri', 'oscarEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Authorization URI is \
            // left blank on Oscar EMR Configuration form
            valueMissing: gettext('account', 'authUriRequired', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Authorization URI \
            // contains invalid characters on Oscar EMR Configuration form
            patternMismatch: gettext('account', 'authUriPattern', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Authorization URI \
            // doesn't start with HTTPS on Oscar EMR Configuration form
            requireHttpsUri: gettext('account', 'authUriRequireHttps', 'oscarEMRConfigFormError')
          }
        },
        clientKey: {
          // Translators: Label for the Client Key input of Oscar EMR \
          // Configuration form
          label: gettext('account', 'clientKey', 'oscarEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Client Key is left \
            // blank on Oscar EMR Configuration form
            valueMissing: gettext('account', 'clientKeyRequired', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Client Key contains \
            // invalid characters on Oscar EMR Configuration form
            patternMismatch: gettext('account', 'clientKeyPattern', 'oscarEMRConfigFormError')
          }
        },
        clientName: {
          // Translators: Label for the Client Name input of Oscar EMR \
          // Configuration form
          label: gettext('account', 'clientName', 'oscarEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Client Name is left \
            // blank on Oscar EMR Configuration form
            valueMissing: gettext('account', 'clientNameRequired', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Client Name contains \
            // invalid characters on Oscar EMR Configuration form
            patternMismatch: gettext('account', 'clientNamePattern', 'oscarEMRConfigFormError')
          }
        },
        clientSecret: {
          // Translators: Label for the Client Secret input of Oscar EMR \
          // Configuration form
          label: gettext('account', 'clientSecret', 'oscarEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Client Secret is left \
            // blank on Oscar EMR Configuration form
            valueMissing: gettext('account', 'clientSecretPattern', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Client Secret contains \
            // invalid characters on Oscar EMR Configuration form
            patternMismatch: gettext('account', 'clientSecretPattern', 'oscarEMRConfigFormError')
          }
        },
        tempUri: {
          // Translators: Label for the Temporary Credential Request URI input \
          // of Oscar EMR Configuration form
          label: gettext('account', 'tempUri', 'oscarEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Temporary Credential \
            // Request URI is left blank on Oscar EMR Configuration form
            valueMissing: gettext('account', 'tempUriRequired', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Temporary Credential \
            // Request URI contains invalid characters on Oscar EMR \
            // Configuration form
            patternMismatch: gettext('account', 'tempUriPattern', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Temporary Credential \
            // Request URI doesn't start with HTTPS on Oscar EMR Configuration \
            // form
            requireHttpsUri: gettext('account', 'tempUriRequireHttps', 'oscarEMRConfigFormError')
          }
        },
        tokenUri: {
          // Translators: Label for the Token Request URI input of Oscar EMR \
          // Configuration form
          label: gettext('account', 'tokenUri', 'oscarEMRConfigFormInputLabel'),
          error: {
            // Translators: Error message displayed when Token Request URI is \
            // left blank on Oscar EMR Configuration form
            valueMissing: gettext('account', 'tokenUriRequired', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Token Request URI \
            // contains invalid characters on Oscar EMR Configuration form
            patternMismatch: gettext('account', 'tokenUriPattern', 'oscarEMRConfigFormError'),
            // Translators: Error message displayed when Token Request URI \
            // doesn't start with HTTPS on Oscar EMR Configuration form
            requireHttpsUri: gettext('account', 'tokenUriRequireHttps', 'oscarEMRConfigFormError')
          }
        }
      }
    }
  }
};

export default i18n;
