import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ManageAccountSchedule } from '../../constants/action-context';

const i18n: AccountI18n.View.Schedule = {
  action: {
    editWorkSchedule: {
      // Translators: Label for the Edit Work Schedule action
      label: gettext('account', 'editWorkSchedule', 'accountScheduleActionLabel'),
      context: ManageAccountSchedule
    }
  },
  // Translators: Label for the Schedule View under My Account
  label: gettext('account', 'schedule', 'accountViewLabel'),
  option: {
    workSchedule: {
      // Translators: Label for the Work Schedule option
      label: gettext('account', 'workSchedule', 'accountScheduleOptionLabel'),
      // Translators: Description for the Work Schedule option
      description: gettext('account', 'workSchedule', 'accountScheduleOptionDescription')
    }
  }
};

export default i18n;
