import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import type { ApiFormattedDataObject, DataObjectHrmUserListValue } from '@sigmail/objects';
import { DataObjectHrmUserList } from '@sigmail/objects';
import type { Api, Http } from '../../types';
import { MalformedResponseException } from '../malformed-response-exception';

const OBJECT_ID_HRM_USER_LIST = 3201;
const OBJECT_TYPE_HRM_USER_LIST = process.env.DATA_OBJECT_TYPE_HRM_USER_LIST;

const Logger = getLoggerWithPrefix('ApiService', 'hrmGetUserList:');

export async function apiHrmGetUserList(
  this: Api.Service,
  accessToken: string,
  authState: string,
  init?: Http.RequestInit
): Promise<DataObjectHrmUserListValue['list']> {
  try {
    Logger.info('== BEGIN ==');

    Logger.info('Initiating HTTP request.');
    const { dataObjects } = await this.batchQueryData(
      accessToken,
      { authState, dataObjects: { ids: [OBJECT_ID_HRM_USER_LIST] } },
      init
    );

    let json: ApiFormattedDataObject | undefined;
    if (Utils.isNonEmptyArray(dataObjects)) {
      json = dataObjects.find(({ id, type }) => id === OBJECT_ID_HRM_USER_LIST && type === OBJECT_TYPE_HRM_USER_LIST);
    }

    if (Utils.isNil(json)) {
      Logger.warn('Operation failed. (Malformed/unexpected response data)');
      throw new MalformedResponseException('Failed to fetch HRM user list data object.');
    }

    const hrmUserListObject = new DataObjectHrmUserList(json);
    const { list: hrmUserList } = await hrmUserListObject.decryptedValue();
    return hrmUserList;
  } finally {
    Logger.info('== END ==');
  }
}
