import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.Form.KCCQHealthDataParameters = {
  fieldsetChartType: {
    formField: {
      chartType: {
        // Translators: Label for the Chart Type input in KCCQ Health Data \
        // Parameters form
        label: gettext('health-data', 'chartType', 'kccqHealthDataParametersFormInputLabel'),
        options: [
          {
            code: 'domain',
            // Translators: Label for the Domain Chart dropdown option of \
            // Chart Type input in KCCQ Health Data Parameters form
            label: gettext('health-data', 'domainChart', 'kccqHealthDataParametersFormOptionLabel')
          },
          {
            code: 'summary',
            // Translators: Label for the Summary Chart dropdown option of \
            // Chart Type input in KCCQ Health Data Parameters form
            label: gettext('health-data', 'summaryChart', 'kccqHealthDataParametersFormOptionLabel')
          },
          {
            code: 'questionnaire',
            // Translators: Label for the Questionnaire Chart dropdown option \
            // of Chart Type input in KCCQ Health Data Parameters form
            label: gettext('health-data', 'questionnaireChart', 'kccqHealthDataParametersFormOptionLabel')
          },
          {
            code: 'all',
            // Translators: Label for the All Charts dropdown option of Chart \
            // Type input in KCCQ Health Data Parameters form
            label: gettext('health-data', 'allCharts', 'kccqHealthDataParametersFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error displayed when an invalid option is selected \
          // for Chart Type input in KCCQ Health Data Parameters form
          patternMismatch: gettext('health-data', 'chartTypePattern', 'kccqHealthDataParametersFormError')
        }
      }
    }
  },
  fieldsetDateRange: {
    formField: {
      from: {
        // Translators: Label for the From Date input in KCCQ Health Data \
        // Parameters form
        label: gettext('health-data', 'from', 'kccqHealthDataParametersFormInputLabel'),
        error: {
          // Translators: Error displayed when no date is selected for From \
          // Date input in KCCQ Health Data Parameters form
          valueMissing: gettext('health-data', 'fromRequired', 'kccqHealthDataParametersFormError'),
          // Translators: Error displayed when an invalid date is entered for \
          // From Date input in KCCQ Health Data Parameters form
          badInput: gettext('health-data', 'fromInvalid', 'kccqHealthDataParametersFormError')
        }
      },
      to: {
        // Translators: Label for the To Date input in KCCQ Health Data \
        // Parameters form
        label: gettext('health-data', 'to', 'kccqHealthDataParametersFormInputLabel'),
        error: {
          // Translators: Error displayed when no date is selected for To Date \
          // input in KCCQ Health Data Parameters form
          valueMissing: gettext('health-data', 'toRequired', 'kccqHealthDataParametersFormError'),
          // Translators: Error displayed when the value entered in To Date \
          // input in KCCQ Health Data Parameters form is out of valid range
          range: gettext('health-data', 'toRange', 'kccqHealthDataParametersFormError'),
          // Translators: Error displayed when an invalid date is entered for \
          // To Date input in KCCQ Health Data Parameters form
          badInput: gettext('health-data', 'toInvalid', 'kccqHealthDataParametersFormError')
        }
      }
    }
  }
};

export default i18n;
