import { ActionPayloadAuthFailure, CaseReducerAuthFailure } from '@sigmail/app-state';
import { Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { CryptographicKey } from '@sigmail/objects';
import { INITIAL_STATE } from '.';
import { CollectionObjectCache } from '../collection-objects-slice/cache';
import { DataObjectCache } from '../data-objects-slice/cache';
import { UserObjectCache } from '../user-objects-slice/cache';

const Logger = getLoggerWithPrefix('Reducer', 'authFailureReducer:');

function isPayloadValid(payload?: any): payload is ActionPayloadAuthFailure {
  if (Utils.isNonArrayObjectLike<ActionPayloadAuthFailure>(payload)) {
    return Utils.isInteger(payload.errorCode) && payload.errorCode !== Constants.Error.S_OK;
  }
  return false;
}

export const authFailureReducer: CaseReducerAuthFailure = (state, action) => {
  try {
    Logger.info('== BEGIN ==');

    if (!isPayloadValid(action.payload)) {
      Logger.warn('Invalid payload.');
      return state;
    }

    CollectionObjectCache.clear();
    DataObjectCache.clear();
    UserObjectCache.clear();

    CryptographicKey.clearAllPrivateKeys();
    CryptographicKey.clearAllPublicKeys();

    Logger.info('lastAuthErrorCode', Utils.jsonStringifyErrorCode(action.payload.errorCode));
    return { ...INITIAL_STATE, lastAuthErrorCode: action.payload.errorCode };
  } finally {
    Logger.info('== END ==');
  }
};
