import { MultiFactorAuthI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MultiFactorAuthI18n.Form.VerifyCode = {
  fieldsetDefault: {
    formField: {
      code: {
        // Translators: Label for the Code input
        label: gettext('mfa', 'verifyCodeForm', 'inputLabelCode'),
        error: {
          // Translators: Error message displayed when Code is left blank
          valueMissing: gettext('mfa', 'verifyCodeForm', 'errorMessageCodeRequired'),
          // Translators: Error message displayed when Code entered by user \
          // does not match the code sent by SigMail.
          codeMismatch: gettext('mfa', 'verifyCodeForm', 'errorMessageCodeMismatch')
        }
      }
    }
  }
};

export default i18n;
