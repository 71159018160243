import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.View.ReferralMessage = {
  action: {
    accept: {
      // Translators: Label for the Accept Referral action in Referral Message \
      // view
      label: gettext('messaging', 'accept', 'referralMessageViewActionLabel')
    },
    decline: {
      // Translators: Label for the Decline Referral action in Referral \
      // Message view
      label: gettext('messaging', 'decline', 'referralMessageViewActionLabel')
    }
  },
  dataGroup: {
    response: {
      // Translators: Label for the Response data group in Referral Message \
      // view
      label: gettext('messaging', 'response', 'referralMessageViewDataGroupLabel'),
      status: {
        // Translators: Label for an Accepted Referral status
        accepted: gettext('messaging', 'accepted', 'referralMessageViewStatus'),
        // Translators: Label for a Declined Referral status
        declined: gettext('messaging', 'declined', 'referralMessageViewStatus'),
        // Translators: Text displayed for an Expired Referral
        expired: gettext('messaging', 'expired', 'referralMessageViewStatus')
      },
      item: {
        // Translators: Label for the Referral Status data group item in \
        // Referral Message view
        status: gettext('messaging', 'referralStatus', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referral Accepted By data group item in \
        // Referral Message view
        acceptedBy: gettext('messaging', 'referralAcceptedBy', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Proposed Consult Date data group item in \
        // Referral Message view
        consultDate: gettext('messaging', 'referralConsultDate', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referral Declined By data group item in \
        // Referral Message view
        declinedBy: gettext('messaging', 'referralDeclinedBy', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referral Decline Reason By data group \
        // item in Referral Message view
        declineReason: gettext('messaging', 'referralDeclineReason', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referral Expired At data group item in \
        // Referral Message view
        expiredAt: gettext('messaging', 'referralExpiredAt', 'referralMessageViewDataGroupItemLabel')
      }
    },
    referral: {
      // Translators: Label for the Referral Details data group in Referral \
      // Message view
      label: gettext('messaging', 'referral', 'referralMessageViewDataGroupLabel'),
      item: {
        // Translators: Label for the Urgent data group item in Referral \
        // Message view
        urgent: gettext('messaging', 'referralUrgent', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Reason For Referral data group item in \
        // Referral Message view
        reason: gettext('messaging', 'referralReason', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Department Or Specialty Area data group \
        // item in Referral Message view
        department: gettext('messaging', 'referralDepartment', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Medical History data group item in \
        // Referral Message view
        notes: gettext('messaging', 'referralNotes', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the ICD 9/10 Code data group item in \
        // Referral Message view
        icdCode: gettext('messaging', 'referralIcdCode', 'referralMessageViewDataGroupItemLabel')
      }
    },
    guest: {
      // Translators: Label for the Patient Details data group in Referral \
      // Message view
      label: gettext('messaging', 'guest', 'referralMessageViewDataGroupLabel'),
      item: {
        // Translators: Label for the Patient's Full Name data group item in \
        // Referral Message view
        fullName: gettext('messaging', 'guestFullName', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Patient's Birth Date data group item in \
        // Referral Message view
        birthDate: gettext('messaging', 'guestBirthDate', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Patient's Health Plan Number data group \
        // item in Referral Message view
        healthCardNumber: gettext('messaging', 'guestHealthPlanNumber', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Patient's Cell Number data group item \
        // in Referral Message view
        cellNumber: gettext('messaging', 'guestCellNumber', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Patient's Home Number data group item \
        // in Referral Message view
        homeNumber: gettext('messaging', 'guestHomeNumber', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Patient's Address data group item in \
        // Referral Message view
        address: gettext('messaging', 'guestAddress', 'referralMessageViewDataGroupItemLabel')
      }
    },
    referrer: {
      // Translators: Label for the Referrer Details data group in Referral \
      // Message view
      label: gettext('messaging', 'referrer', 'referralMessageViewDataGroupLabel'),
      item: {
        // Translators: Label for the Referrer's Full Name data group item in \
        // Referral Message view
        fullName: gettext('messaging', 'referrerFullName', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referrer's Institute data group item in \
        // Referral Message view
        institute: gettext('messaging', 'referrerInstitute', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referrer's Office Number data group item \
        // in Referral Message view
        officeNumber: gettext('messaging', 'referrerOfficeNumber', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referrer's Fax Number data group item \
        // in Referral Message view
        faxNumber: gettext('messaging', 'referrerFaxNumber', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referrer's License Number data group \
        // item in Referral Message view
        licenseNumber: gettext('messaging', 'referrerLicenseNumber', 'referralMessageViewDataGroupItemLabel'),
        // Translators: Label for the Referrer's Address data group item in \
        // Referral Message view
        address: gettext('messaging', 'referrerAddress', 'referralMessageViewDataGroupItemLabel')
      }
    }
  }
};

export default i18n;
