import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['physicianSpecialtyList'] = [
  {
    code: 'aaa',
    rcpsCode: '00',
    // Translators: Label for the Administrative Medicine specialty
    label: gettext('global', 'adminMedicine', 'physicianSpecialty')
  },
  {
    code: 'aab',
    rcpsCode: '00',
    // Translators: Label for the Alternate Health Care Professional specialty
    label: gettext('global', 'alternateHealthCareProfessional', 'physicianSpecialty')
  },
  {
    code: 'ab4',
    rcpsCode: '01',
    // Translators: Label for the Anesthesiology specialty
    label: gettext('global', 'anesthesiology', 'physicianSpecialty')
  },
  {
    code: 'abe',
    rcpsCode: '00',
    // Translators: Label for the Biochemistry — Medical specialty
    label: gettext('global', 'medicalBiochemistry', 'physicianSpecialty')
  },
  {
    code: 'ac8',
    rcpsCode: '60',
    // Translators: Label for the Cardiology specialty
    label: gettext('global', 'cardiology', 'physicianSpecialty')
  },
  {
    code: 'ad2',
    rcpsCode: '00',
    // Translators: Label for the Clinical Associates And Hospitalists specialty
    label: gettext('global', 'clinicalAssociatesAndHospitalists', 'physicianSpecialty')
  },
  {
    code: 'adc',
    rcpsCode: '62',
    // Translators: Label for the Clinical Immunology And Allergy specialty
    label: gettext('global', 'clinicalImmunologyAndAllergy', 'physicianSpecialty')
  },
  {
    code: 'ae6',
    rcpsCode: '00',
    // Translators: Label for the Critical/Intensive Care Medicine specialty
    label: gettext('global', 'intensiveCareMedicine', 'physicianSpecialty')
  },
  {
    code: 'ae7',
    rcpsCode: '49',
    // Translators: Label for the Dental Surgery specialty
    label: gettext('global', 'dentalSurgery', 'physicianSpecialty')
  },
  {
    code: 'af0',
    rcpsCode: '02',
    // Translators: Label for the Dermatology specialty
    label: gettext('global', 'dermatology', 'physicianSpecialty')
  },
  {
    code: 'afa',
    rcpsCode: '33',
    // Translators: Label for the Diagnostic Radiology specialty
    label: gettext('global', 'diagnosticRadiology', 'physicianSpecialty')
  },
  {
    code: 'b04',
    rcpsCode: '12',
    // Translators: Label for the Emergency Medicine specialty
    label: gettext('global', 'emergencyMedicine', 'physicianSpecialty')
  },
  {
    code: 'b0e',
    rcpsCode: '15',
    // Translators: Label for the Endocrinology And Metabolism specialty
    label: gettext('global', 'endocrinologyAndMetabolism', 'physicianSpecialty')
  },
  {
    code: 'b1d',
    rcpsCode: '00',
    // Translators: Label for the Family Medicine specialty
    label: gettext('global', 'familyMedicine', 'physicianSpecialty')
  },
  {
    code: 'b18',
    rcpsCode: '41',
    // Translators: Label for the Gastroenterology specialty
    label: gettext('global', 'gastroenterology', 'physicianSpecialty')
  },
  {
    code: 'b22',
    rcpsCode: '22',
    // Translators: Label for the Genetics — Medical specialty
    label: gettext('global', 'geneticsMedical', 'physicianSpecialty')
  },
  {
    code: 'b2c',
    rcpsCode: '07',
    // Translators: Label for the Geriatric Medicine specialty
    label: gettext('global', 'geriatricMedicine', 'physicianSpecialty')
  },
  {
    code: 'b36',
    rcpsCode: '20',
    // Translators: Label for the Gynecology/Obstetrics specialty
    label: gettext('global', 'gynecology', 'physicianSpecialty')
  },
  {
    code: 'b40',
    rcpsCode: '61',
    // Translators: Label for the Haematology specialty
    label: gettext('global', 'haematology', 'physicianSpecialty')
  },
  {
    code: 'b41',
    rcpsCode: '90',
    // Translators: Label for the IHF Non-Medical Practitioner specialty
    label: gettext('global', 'IHFNonMedicalPractitioner', 'physicianSpecialty')
  },
  {
    code: 'b4a',
    rcpsCode: '46',
    // Translators: Label for the Infectious Diseases specialty
    label: gettext('global', 'infectiousDiseases', 'physicianSpecialty')
  },
  {
    code: 'b54',
    rcpsCode: '13',
    // Translators: Label for the Internal Medicine specialty
    label: gettext('global', 'internalMedicine', 'physicianSpecialty')
  },
  {
    code: 'b5e',
    rcpsCode: '29',
    // Translators: Label for the Microbiology — Medical specialty
    label: gettext('global', 'microbiologyMedical', 'physicianSpecialty')
  },
  {
    code: 'b68',
    rcpsCode: '00',
    // Translators: Label for the Neonatal-perinatal Medicine specialty
    label: gettext('global', 'neonatalPerinatalMedicine', 'physicianSpecialty')
  },
  {
    code: 'b72',
    rcpsCode: '16',
    // Translators: Label for the Nephrology specialty
    label: gettext('global', 'nephrology', 'physicianSpecialty')
  },
  {
    code: 'b7c',
    rcpsCode: '18',
    // Translators: Label for the Neurology specialty
    label: gettext('global', 'neurology', 'physicianSpecialty')
  },
  {
    code: 'b86',
    rcpsCode: '63',
    // Translators: Label for the Nuclear Medicine specialty
    label: gettext('global', 'nuclearMedicine', 'physicianSpecialty')
  },
  {
    code: 'b87',
    rcpsCode: '76',
    // Translators: Label for the Nurse Practitioner specialty
    label: gettext('global', 'nursePractitioner', 'physicianSpecialty')
  },
  {
    code: 'b9a',
    rcpsCode: '20',
    // Translators: Label for the Obstetrics specialty
    label: gettext('global', 'obstetrics', 'physicianSpecialty')
  },
  {
    code: 'ba4',
    rcpsCode: '905',
    // Translators: Label for the Occupational Medicine specialty
    label: gettext('global', 'occupationalMedicine', 'physicianSpecialty')
  },
  {
    code: 'bae',
    rcpsCode: '44',
    // Translators: Label for the Oncology — Medical specialty
    label: gettext('global', 'oncologyMedical', 'physicianSpecialty')
  },
  {
    code: 'bb8',
    rcpsCode: '34',
    // Translators: Label for the Oncology — Radiation specialty
    label: gettext('global', 'oncologyRadiation', 'physicianSpecialty')
  },
  {
    code: 'bb9',
    rcpsCode: '70',
    // Translators: Label for the Oral Radiology specialty
    label: gettext('global', 'oralRadiology', 'physicianSpecialty')
  },
  {
    code: 'bba',
    rcpsCode: '50',
    // Translators: Label for the Oral Surgery specialty
    label: gettext('global', 'oralSurgery', 'physicianSpecialty')
  },
  {
    code: 'bc2',
    rcpsCode: '00',
    // Translators: Label for the Pain Medicine specialty
    label: gettext('global', 'painMedicine', 'physicianSpecialty')
  },
  {
    code: 'bcc',
    rcpsCode: '00',
    // Translators: Label for the Palliative Medicine specialty
    label: gettext('global', 'palliativeMedicine', 'physicianSpecialty')
  },
  {
    code: 'bd6',
    rcpsCode: '00',
    // Translators: Label for the Pathology — Anatomical or General specialty
    label: gettext('global', 'pathologyAnatomicalOrGeneral', 'physicianSpecialty')
  },
  {
    code: 'be0',
    rcpsCode: '00',
    // Translators: Label for the Pathology — Hematological specialty
    label: gettext('global', 'pathologyHematological', 'physicianSpecialty')
  },
  {
    code: 'bea',
    rcpsCode: '00',
    // Translators: Label for the Pathology — Neuropathology specialty
    label: gettext('global', 'pathologyNeuropathology', 'physicianSpecialty')
  },
  {
    code: 'bf4',
    rcpsCode: '26',
    // Translators: Label for the Paediatrics specialty
    label: gettext('global', 'paediatrics', 'physicianSpecialty')
  },
  {
    code: 'bf5',
    rcpsCode: '80',
    // Translators: Label for the Private Physiotherapy Facility specialty
    label: gettext('global', 'privatePhysiotherapy', 'physicianSpecialty')
  },
  {
    code: 'bfe',
    rcpsCode: '31',
    // Translators: Label for the Physical Medicine And Rehabilitation specialty
    label: gettext('global', 'physicalMedicineAndRehabilitation', 'physicianSpecialty')
  },
  {
    code: 'c08',
    rcpsCode: '19',
    // Translators: Label for the Psychiatry And Addiction Medicine specialty
    label: gettext('global', 'psychiatryAndAddictionMedicine', 'physicianSpecialty')
  },
  {
    code: 'c12',
    rcpsCode: '00',
    // Translators: Label for the Public Health And Preventive Medicine specialty
    label: gettext('global', 'publicHealthAndPreventiveMedicine', 'physicianSpecialty')
  },
  {
    code: 'c1c',
    rcpsCode: '47',
    // Translators: Label for the Respirology specialty
    label: gettext('global', 'respirology', 'physicianSpecialty')
  },
  {
    code: 'c26',
    rcpsCode: '48',
    // Translators: Label for the Rheumatology specialty
    label: gettext('global', 'rheumatology', 'physicianSpecialty')
  },
  {
    code: 'c30',
    rcpsCode: '00',
    // Translators: Label for the Sport Medicine specialty
    label: gettext('global', 'sportMedicine', 'physicianSpecialty')
  },
  {
    code: 'c3a',
    rcpsCode: '00',
    // Translators: Label for the Assistance At Surgery specialty
    label: gettext('global', 'assistanceAtSurgery', 'physicianSpecialty')
  },
  {
    code: 'c44',
    rcpsCode: '09',
    // Translators: Label for the Cardiac Surgery specialty
    label: gettext('global', 'cardiacSurgery', 'physicianSpecialty')
  },
  {
    code: 'c4e',
    rcpsCode: '03',
    // Translators: Label for the General Surgery specialty
    label: gettext('global', 'generalSurgery', 'physicianSpecialty')
  },
  {
    code: 'c58',
    rcpsCode: '00',
    // Translators: Label for the Gynecologic Surgery specialty
    label: gettext('global', 'gynecologicSurgery', 'physicianSpecialty')
  },
  {
    code: 'c62',
    rcpsCode: '04',
    // Translators: Label for the Neurosurgery specialty
    label: gettext('global', 'neurosurgery', 'physicianSpecialty')
  },
  {
    code: 'c6c',
    rcpsCode: '23',
    // Translators: Label for the Ophthalmology specialty
    label: gettext('global', 'ophthalmology', 'physicianSpecialty')
  },
  {
    code: 'c76',
    rcpsCode: '06',
    // Translators: Label for the Orthopedic Surgery specialty
    label: gettext('global', 'orthopedicSurgery', 'physicianSpecialty')
  },
  {
    code: 'c80',
    rcpsCode: '24',
    // Translators: Label for the Otolaryngology specialty
    label: gettext('global', 'otolaryngology', 'physicianSpecialty')
  },
  {
    code: 'c8a',
    rcpsCode: '00',
    // Translators: Label for the Paediatric Surgery specialty
    label: gettext('global', 'paediatricSurgery', 'physicianSpecialty')
  },
  {
    code: 'c94',
    rcpsCode: '08',
    // Translators: Label for the Plastic Surgery specialty
    label: gettext('global', 'plasticSurgery', 'physicianSpecialty')
  },
  {
    code: 'c9e',
    rcpsCode: '00',
    // Translators: Label for the Surgical Consultations/Office Surgical Practice specialty
    label: gettext('global', 'surgicalConsultationsOrOfficeSurgicalPractice', 'physicianSpecialty')
  },
  {
    code: 'ca8',
    rcpsCode: '64',
    // Translators: Label for the Thoracic Surgery specialty
    label: gettext('global', 'thoracicSurgery', 'physicianSpecialty')
  },
  {
    code: 'cb2',
    rcpsCode: '35',
    // Translators: Label for the Urology specialty
    label: gettext('global', 'urology', 'physicianSpecialty')
  },
  {
    code: 'cbc',
    rcpsCode: '17',
    // Translators: Label for the Vascular Surgery specialty
    label: gettext('global', 'vascularSurgery', 'physicianSpecialty')
  },
];

export default i18n;
