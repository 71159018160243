import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AppThunk } from '..';

export const getVideoTokenAction = ({ accessToken, data }: ApiActionPayload.GetVideoToken): AppThunk<Promise<string>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'getVideoTokenAction:');

    Logger.info('== BEGIN ==');
    try {
      const apiAccessToken = Utils.isString(accessToken) ? accessToken : getState().auth.accessToken;
      return await apiService.getVideoToken(apiAccessToken, data);
    } finally {
      Logger.info('== END ==');
    }
  };
};
