import { isNonArrayObjectLike, trimOrDefault } from '.';
import { PERSON_NAME_KEY_LIST } from '../constants/person-name-key-list';
import type { NullableProps, PersonName } from '../types';

export function joinPersonName(props?: unknown): string {
  if (!isNonArrayObjectLike<NullableProps<PersonName>>(props)) return '';

  return PERSON_NAME_KEY_LIST.reduce<Array<string>>((values, key) => {
    const value = trimOrDefault(props[key]);
    if (value.length > 0) values.push(value);
    return values;
  }, []).join('\x20');
}
