import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import subjectLine from './subject-line';

const i18n: MessagingI18n.View.MessageMetadata = {
  alert: {
    // Translators: (HTML) Note displayed in Message Metadata view when the \
    // message being viewed is marked as Confidential.
    confidential: '',
    doNotReply: {
      // Translators: (HTML) Note displayed in Message Metadata view when the \
      // message being viewed is marked as Do Not Reply and is opened from \
      // INBOX folder.
      // Translators: IMPORTANT: This note is used for users of role GUEST.
      guest_inbox: gettext('messaging', 'doNotReplyGuestInbox', 'messageMetadataAlert', 'safe-html'),
      // Translators: (HTML) Note displayed in Message Metadata view when the \
      // message being viewed is marked as Do Not Reply and is opened from \
      // SENT folder.
      // Translators: IMPORTANT: This note is used for users of role NON-GUEST.
      nonGuest_sent: gettext('messaging', 'doNotReplyNonGuestSent', 'messageMetadataAlert', 'safe-html'),
    },
    // Translators: (HTML) Note displayed in Message Metadata view when the \
    // message being viewed is marked as Personal.
    personal: '',
    private: {
      // Translators: (HTML) Note displayed in Message Metadata view when the \
      // message being viewed is marked as Private and is opened from INBOX \
      // folder.
      // Translators: IMPORTANT: This note is used for users of role GUEST.
      guest_inbox: gettext('messaging', 'privateGuestInbox', 'messageMetadataAlert', 'safe-html'),
      // Translators: (HTML) Note displayed in Message Metadata view when the \
      // message being viewed is marked as Private and is opened from SENT \
      // folder.
      // Translators: IMPORTANT: This note is used for users of role GUEST.
      guest_sent: gettext('messaging', 'privateGuestSent', 'messageMetadataAlert', 'safe-html'),
      // Translators: (HTML) Note displayed in Message Metadata view when the \
      // message being viewed is marked as Private and is opened from INBOX \
      // folder.
      // Translators: IMPORTANT: This note is used for users of role NON-GUEST.
      nonGuest_inbox: gettext('messaging', 'privateNonGuestInbox', 'messageMetadataAlert', 'safe-html'),
      // Translators: (HTML) Note displayed in Message Metadata view when the \
      // message being viewed is marked as Private and is opened from SENT \
      // folder.
      // Translators: IMPORTANT: This note is used for users of role NON-GUEST.
      nonGuest_sent: gettext('messaging', 'privateNonGuestSent', 'messageMetadataAlert', 'safe-html'),
    }
  },
  dataItemLabel: {
    // Translators: Label for Sender in Message Metadata view
    from: gettext('messaging', 'from', 'messageMetadataItemLabel'),
    // Translators: Label for Primary Recipient List in Message Metadata view
    to: gettext('messaging', 'to', 'messageMetadataItemLabel'),
    // Translators: Label for Secondary Recipient List in Message Metadata view
    cc: gettext('messaging', 'cc', 'messageMetadataItemLabel'),
    // Translators: Label for Concealed Recipient List in Message Metadata view
    bcc: gettext('messaging', 'bcc', 'messageMetadataItemLabel'),
    // Translators: Label for Subject Line in Message Metadata view
    subject: gettext('messaging', 'subject', 'messageMetadataItemLabel'),
    timestamp: {
      // Translators: Label for Sent Timestamp in Message Metadata view
      sent: gettext('messaging', 'timestampSent', 'messageMetadataItemLabel'),
      // Translators: Label for Received Timestamp in Message Metadata view
      received: gettext('messaging', 'timestampReceived', 'messageMetadataItemLabel'),
      // Translators: Label for Sent To EMR timestamp in Message Metadata view
      sentToEMR: gettext('messaging', 'timestampEMR', 'messageMetadataItemLabel'),
      // Translators: Label for HRM Correction timestamp in Message Metadata view
      hrmCorrection: gettext('messaging', 'timestampHrmCorrect', 'messageMetadataItemLabel'),
      // Translators: Label for HRM Cancellation timestamp in Message Metadata view
      hrmCancellation: gettext('messaging', 'timestampHrmCancel', 'messageMetadataItemLabel')
    }
  },
  subjectLine
};

export default i18n;
