import { Constants } from '@sigmail/common';

export const AUTH_STATE_ACCOUNT_SETUP = 'accountSetup';
export const AUTH_STATE_ASSIGN_MESSAGE_CATEGORY = 'sendMessage';
export const AUTH_STATE_ASSIGN_USERS_TO_GROUP = 'assignUserToGroup';
export const AUTH_STATE_CAREGIVER_DECLINE_INVITATION = 'updateUser';
export const AUTH_STATE_CHANGE_PASSWORD = 'changePassword';
export const AUTH_STATE_CHANGE_USERNAME = 'changeUsername';
export const AUTH_STATE_CREATE_CIRCLE_OF_CARE_GROUP = 'createCircleOfCareGroup';
// export const AUTH_STATE_CREATE_ENCOUNTER_OBJECT_MIGRATION = 'createEncounterObjectMigration';
export const AUTH_STATE_CREATE_GUEST_CONTACT_GROUP = 'createGuestContactGroup';
export const AUTH_STATE_CREATE_MESSAGE_FOLDER_EXT = 'sendMessage';
export const AUTH_STATE_CREATE_SIGMAIL_GROUP = 'createCircleOfCareGroup';
export const AUTH_STATE_CREATE_E_CONSULT_OBJECT_MIGRATION = 'createEconsultObjectMigration';
export const AUTH_STATE_CREATE_SCHEDULE_OBJECT_MIGRATION = 'createScheduleObjectMigration';
export const AUTH_STATE_MULTI_GROUP_RELEASE_MIGRATION = 'createEncounterObjectMigration';
export const AUTH_STATE_EVENT_LOG_INITIALIZATION_MIGRATION = 'updateUser';
export const AUTH_STATE_DEACTIVATE_ACCOUNT = 'manageMembers';
export const AUTH_STATE_DELETE_MESSAGE = 'sendMessage';
export const AUTH_STATE_DISABLE_MFA = 'processMfa';
export const AUTH_STATE_ENABLE_MFA = 'processMfa';
export const AUTH_STATE_INVITE_USERS_TO_GROUP = 'assignUserToGroup';
export const AUTH_STATE_LOG_EVENT = 'updateUser';
export const AUTH_STATE_MOVE_MESSAGE = 'sendMessage';
export const AUTH_STATE_PASSWORD_RESET = 'passwordReset';
export const AUTH_STATE_PROCESS_GUEST_PROFILE_UPDATES = 'incomingMessage';
export const AUTH_STATE_PROCESS_CAREGIVER_UPDATES = 'incomingMessage';
export const AUTH_STATE_PROCESS_INCOMING_MESSAGES = 'incomingMessage';
export const AUTH_STATE_PROCESS_READ_RECEIPTS = 'incomingMessage';
export const AUTH_STATE_PROCESS_RECALL_MESSAGES = 'incomingMessage';
export const AUTH_STATE_ONE_TIME_MESSAGE = 'oneTimeMessage';
export const AUTH_STATE_REMOVE_CAREGIVER = 'updateUser';
export const AUTH_STATE_RECALL_MESSAGE = 'sendMessage';
export const AUTH_STATE_RECORD_EVENT_ATTENDANCE = 'sendMessage';
export const AUTH_STATE_REMOVE_EVENT_FROM_CALENDAR = 'updateUser';
export const AUTH_STATE_REMOVE_ACCOUNT_INVITATION = 'updateMemberInvitation';
export const AUTH_STATE_RESEND_ACCOUNT_INVITATION = 'updateMemberInvitation';
export const AUTH_STATE_RESET_PASSWORD = 'changePassword';
export const AUTH_STATE_RESPOND_TO_CALENDAR_EVENT = 'sendMessage';
export const AUTH_STATE_RESPOND_TO_JOIN_GROUP_INVITATION = 'acceptInvitationToGroup';
export const AUTH_STATE_REVOKE_ACCOUNT_INVITATION = 'updateMemberInvitation';
export const AUTH_STATE_SAVE_MESSAGE_AS_DRAFT = 'sendMessage';
export const AUTH_STATE_SEND_CLIENT_INVITATION = 'sendClientInvitation';
export const AUTH_STATE_SEND_CONSULTATION_REQUEST = 'sendMessage';
export const AUTH_STATE_SEND_EVENT_MESSAGE = 'sendMessage';
export const AUTH_STATE_SEND_HEALTH_DATA_REQUEST_MESSAGE = 'sendMessage';
export const AUTH_STATE_SEND_HRM_MESSAGE = 'sendMessage';
export const AUTH_STATE_SEND_MESSAGE = 'sendMessage';
export const AUTH_STATE_SEND_PASSWORD_RESET_LINK = 'forgotPasswordLink';
export const AUTH_STATE_SEND_REFERRAL = 'sendMessage';
export const AUTH_STATE_SEND_REFERRAL_RESPONSE = 'sendMessage';
export const AUTH_STATE_SET_LAST_SENT_TO_EMR_FLAG = 'sendMessage';
export const AUTH_STATE_UPDATE_CARE_PLANS = 'updateUser';
export const AUTH_STATE_UPDATE_EVENT_REMINDER = 'sendMessage';
export const AUTH_STATE_UPDATE_GROUP_PREFERENCES = 'updateGroup';
export const AUTH_STATE_UPDATE_MESSAGE_READ_STATE = 'sendMessage';
export const AUTH_STATE_UPDATE_PROFILE_INFO = 'updateUser';
export const AUTH_STATE_UPDATE_USER_PREFERENCES = 'updateUser';
export const AUTH_STATE_UPDATE_WORK_SCHEDULE = 'updateUser';

type ServerRightsRoleIdentifier =
  | 'AdminMessagingUser'
  | 'AdminPhysician'
  | 'AdminStaff'
  | 'BasicPhysician'
  | 'Caregiver'
  | 'MessagingUser'
  | 'Patient'
  | 'Physician'
  | 'Physician+'
  | 'SigMailAdmin'
  | 'Staff';

type PrefixedServerRightsRoleIdentifier<Str extends string> = `${Str}${ServerRightsRoleIdentifier}`;

export const AUTH_STATE_AUTHORIZED_AS_ROLE: Readonly<Record<string, PrefixedServerRightsRoleIdentifier<'authorizedAs'> | undefined>> = {
  [Constants.ROLE_ID_ADMIN_MESSAGING_USER]: 'authorizedAsAdminMessagingUser',
  [Constants.ROLE_ID_ADMIN_PHYSICIAN]: 'authorizedAsAdminPhysician',
  [Constants.ROLE_ID_ADMIN_STAFF]: 'authorizedAsAdminStaff',
  [Constants.ROLE_ID_BASIC_PHYSICIAN]: 'authorizedAsBasicPhysician',
  [Constants.ROLE_ID_CAREGIVER]: 'authorizedAsCaregiver',
  [Constants.ROLE_ID_GUEST]: 'authorizedAsPatient',
  [Constants.ROLE_ID_JUNIOR_PHYSICIAN]: 'authorizedAsPhysician',
  [Constants.ROLE_ID_MESSAGING_USER]: 'authorizedAsMessagingUser',
  [Constants.ROLE_ID_SENIOR_PHYSICIAN]: 'authorizedAsPhysician+',
  [Constants.ROLE_ID_SIGMAIL_ADMIN]: 'authorizedAsSigMailAdmin',
  [Constants.ROLE_ID_STAFF]: 'authorizedAsStaff'
};

export const AUTH_STATE_CREATE_AS_ROLE: Readonly<Record<string, PrefixedServerRightsRoleIdentifier<'createAs'> | undefined>> = {
  [Constants.ROLE_ID_ADMIN_MESSAGING_USER]: 'createAsAdminMessagingUser',
  [Constants.ROLE_ID_ADMIN_PHYSICIAN]: 'createAsAdminPhysician',
  [Constants.ROLE_ID_ADMIN_STAFF]: 'createAsAdminStaff',
  [Constants.ROLE_ID_BASIC_PHYSICIAN]: 'createAsBasicPhysician',
  [Constants.ROLE_ID_CAREGIVER]: 'createAsCaregiver',
  [Constants.ROLE_ID_GUEST]: 'createAsPatient',
  [Constants.ROLE_ID_JUNIOR_PHYSICIAN]: 'createAsPhysician',
  [Constants.ROLE_ID_MESSAGING_USER]: 'createAsMessagingUser',
  [Constants.ROLE_ID_SENIOR_PHYSICIAN]: 'createAsPhysician+',
  [Constants.ROLE_ID_SIGMAIL_ADMIN]: 'createAsSigMailAdmin',
  [Constants.ROLE_ID_STAFF]: 'createAsStaff'
};
