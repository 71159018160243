import { ReadonlyPartialRecord, Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { caregiverModeSelector, selectAccessRight, selectRawAccessRightValue } from '../../app-state/selectors/auth';
import { userRoleSelector } from '../../app-state/selectors/user-object';
import NavIconManageCarePlans from '../../assets/images/action-view-care-plans-enabled.svg';
import NavIconBilling from '../../assets/images/nav-icon-billing-secondary.min.svg';
import NavIconContactUs from '../../assets/images/nav-icon-contact-us-secondary.min.svg';
import NavIconFAQ from '../../assets/images/nav-icon-faq-secondary.min.svg';
import NavIconLegalDocs from '../../assets/images/nav-icon-legal-docs-secondary.min.svg';
import NavIconManageCaregivers from '../../assets/images/nav-icon-manage-caregivers-secondary.min.svg';
import NavIconManageMembers from '../../assets/images/nav-icon-manage-members-secondary.min.svg';
import NavIconMyAccount from '../../assets/images/nav-icon-my-account-secondary.min.svg';
import NavIconSignOut from '../../assets/images/nav-icon-sign-out-secondary.min.svg';
import {
  AccountManageCaregivers,
  AccountManageCarePlans,
  AccountManageGroups,
  AccountManageMembers,
  AccountManageSelf,
  BillingFile,
  ContactUs,
  FAQ,
  PrivacyPolicy,
  SignOut,
  TermsAndConditions
} from '../../constants/action-ids';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { processImgProps } from '../../utils/process-img-props';
import layoutStyle from '../layout/layout.module.css';
import {
  NavAction,
  NavActionList,
  NavActionListClassKey,
  Props as NavActionListProps
} from '../shared/nav-action-list.component';

const { action: i18n } = globalI18n;

export interface Props extends Omit<NavActionListProps, 'actionList' | 'ref'> {
  actionIdList?: ReadonlyArray<string> | undefined;
  onNavLinkClick?: NavAction['onClick'] | undefined;
  renderAction?: NavAction['render'] | undefined;
  renderActionLabel?: NavAction['renderLabel'] | undefined;
}

const DEFAULT_CLASSES: Record<NavActionListClassKey, string> = {
  anchor: layoutStyle.anchor,
  listItem: layoutStyle.li
};

const NAV_ICON_IMAGE_MAP: ReadonlyPartialRecord<string, string> = {
  [AccountManageSelf]: NavIconMyAccount,
  [AccountManageCaregivers]: NavIconManageCaregivers,
  [AccountManageCarePlans]: NavIconManageCarePlans,
  [AccountManageMembers]: NavIconManageMembers,
  [AccountManageGroups]: NavIconManageMembers,
  [BillingFile]: NavIconBilling,
  [FAQ]: NavIconFAQ,
  [TermsAndConditions]: NavIconLegalDocs,
  [PrivacyPolicy]: NavIconLegalDocs,
  [ContactUs]: NavIconContactUs,
  [SignOut]: NavIconSignOut
};

export const AccountNavActionList = React.forwardRef<HTMLUListElement, Props>(
  ({ actionIdList, classes: classesProp, onNavLinkClick, renderAction, renderActionLabel, ...rootProps }, ref) => {
    useTranslation([I18N_NS_GLOBAL]);

    const getAccessRightValue = useSelector(selectRawAccessRightValue);
    const hasAccessRight = useSelector(selectAccessRight);
    const isCaregiverMode = useSelector(caregiverModeSelector);
    const canAccessCaregiverManagement =
      !isCaregiverMode && (getAccessRightValue('addOwnCaregiver')! > 0 || hasAccessRight('removeOwnCaregiver'));
    const canManageCarePlans =
      Utils.isGuestRole(useSelector(userRoleSelector)(/***/)) &&
      (hasAccessRight('createCarePlan') ||
        hasAccessRight('deleteCarePlan') ||
        hasAccessRight('editCarePlan') ||
        hasAccessRight('viewCarePlans'));
    const canAccessOwnAccount = !isCaregiverMode && hasAccessRight('accessOwnAccount');
    const canAccessMemberManagement = hasAccessRight('accessMemberManagement');
    const canAccessGroupManagement = hasAccessRight('accessGroupManagement');
    const canAccessBilling = hasAccessRight('accessBilling');

    const navActionList = React.useMemo((): ReadonlyArray<NavAction> => {
      const isIncluded = Utils.isArray(actionIdList)
        ? (actionId: string) => actionIdList.includes(actionId)
        : () => true;

      return Utils.filterMap<false | string, NavAction>(
        [
          canAccessOwnAccount && AccountManageSelf,
          canAccessCaregiverManagement && AccountManageCaregivers,
          canManageCarePlans && AccountManageCarePlans,
          canAccessMemberManagement && AccountManageMembers,
          canAccessGroupManagement && AccountManageGroups,
          canAccessBilling && BillingFile,
          FAQ,
          TermsAndConditions,
          PrivacyPolicy,
          ContactUs,
          SignOut
        ],
        (actionId) =>
          Utils.isString(actionId) &&
          isIncluded(actionId) && {
            ...i18n[actionId],
            actionId,
            exact: true,
            image: processImgProps({ src: NAV_ICON_IMAGE_MAP[actionId] }),
            onClick: onNavLinkClick,
            render: renderAction,
            renderLabel: renderActionLabel
          }
      );
    }, [
      actionIdList,
      canAccessBilling,
      canAccessCaregiverManagement,
      canAccessGroupManagement,
      canAccessMemberManagement,
      canAccessOwnAccount,
      canManageCarePlans,
      onNavLinkClick,
      renderAction,
      renderActionLabel
    ]);

    const classes = React.useMemo(() => Utils.defaults({}, classesProp as unknown, DEFAULT_CLASSES), [classesProp]);
    return <NavActionList actionList={navActionList} classes={classes} ref={ref} {...rootProps} />;
  }
);

AccountNavActionList.displayName = 'AccountNavActionList';
AccountNavActionList.defaultProps = { className: layoutStyle.ul };
