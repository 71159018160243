import { MultiFactorAuthI18n } from '@sigmail/i18n';
import { gettext } from '..';
import {
  DialogEnableMfaFormSelectMfaMethod,
  DialogEnableMfaFormSignIn,
  DialogEnableMfaFormVerifyMfaCode
} from '../../constants/action-context';

const i18n: MultiFactorAuthI18n.Dialog.EnableMFA = {
  title: {
    // Translators: (HTML) Title for Enable MFA dialog's Verify ID step
    signIn: gettext('mfa', 'enableMfaDialog', 'titleSignIn', 'safe-html'),
    // Translators: (HTML) Title for Enable MFA dialog's Select Method step
    selectMfa: gettext('mfa', 'enableMfaDialog', 'titleSelectMfa', 'safe-html'),
    // Translators: (HTML) Title for Enable MFA dialog's Verify Code step
    verifyMfa: gettext('mfa', 'enableMfaDialog', 'titleVerifyMfa', 'safe-html')
  },
  body: {
    // Translators: (HTML) Body for Enable MFA dialog's Verify ID step
    signIn: gettext('mfa', 'enableMfaDialog', 'bodySignIn', 'safe-html'),
    // Translators: (HTML) Body for Enable MFA dialog's Select Method step
    selectMfa: gettext('mfa', 'enableMfaDialog', 'bodySelectMfa', 'safe-html')
  },
  // Translators: Error message displayed when cell number is not set in \
  // user's profile which is required to enable MFA via text message
  errorCellNumberRequired: gettext('mfa', 'enableMfaDialog', 'errorCellNumberRequired'),
  // Translators: Error message displayed when an email address is not set in \
  // user's profile which is required to enable MFA via email
  errorEmailRequired: gettext('mfa', 'enableMfaDialog', 'errorEmailRequired'),
  action: {
    signIn: {
      reject: {
        // Translators: Label for the Cancel action of Enable MFA dialog's \
        // Verify ID step
        label: gettext('mfa', 'enableMfaDialog', 'actionLabelCancelSignIn'),
        context: DialogEnableMfaFormSignIn
      },
      accept: {
        // Translators: Label for the Submit action of Enable MFA dialog's \
        // Verify ID step
        label: gettext('mfa', 'enableMfaDialog', 'actionLabelSubmitSignIn'),
        context: DialogEnableMfaFormSignIn
      }
    },
    selectMfa: {
      reject: {
        // Translators: Label for the Cancel action of Enable MFA dialog's \
        // Select Method step
        label: gettext('mfa', 'enableMfaDialog', 'actionLabelCancelSelectMfa'),
        context: DialogEnableMfaFormSelectMfaMethod
      },
      accept: {
        // Translators: Label for the Submit action of Enable MFA dialog's \
        // Select Method step
        label: gettext('mfa', 'enableMfaDialog', 'actionLabelSubmitSelectMfa'),
        context: DialogEnableMfaFormSelectMfaMethod
      }
    },
    verifyMfa: {
      reject: {
        // Translators: Label for the Back action of Enable MFA dialog's \
        // Verify Code step
        label: gettext('mfa', 'enableMfaDialog', 'actionLabelBackVerifyMfa'),
        context: DialogEnableMfaFormVerifyMfaCode
      },
      accept: {
        // Translators: Label for the Submit action of Enable MFA dialog's \
        // Verify Code step
        label: gettext('mfa', 'enableMfaDialog', 'actionLabelSubmitVerifyMfa'),
        context: DialogEnableMfaFormVerifyMfaCode
      }
    }
  }
};

export default i18n;
