import { Constants, Utils } from '@sigmail/common';

const SEVERITY_ERROR = Constants.Error.SEVERITY_ERROR;
const FACILITY_API_SERVICE = Constants.Error.FACILITY_API_SERVICE;

/** Generic error code for API service exceptions. */
export const E_FAIL = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_API_SERVICE, 0);

/**
 * Error code indicating that the response body returned by the API has an
 * unexpected data format.
 */
export const E_MALFORMED_RESPONSE = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_API_SERVICE, 1);

/**
 * Error code indicating a version conflict error for one or more update
 * operations present in the request body.
 */
export const E_VERSION_CONFLICT = Utils.MAKE_ERROR_CODE(SEVERITY_ERROR, FACILITY_API_SERVICE, 2);
