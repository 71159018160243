import type { SigmailObjectTypeCode } from '@sigmail/common';
import { NotificationObject } from '.';
import type { NotificationObjectGuestProfileUpdateValue as DV } from '../types';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_GUEST_PROFILE_UPDATE;

/** @public */
export class NotificationObjectGuestProfileUpdate extends NotificationObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }
}
