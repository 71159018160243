/* eslint-disable no-redeclare */

import { ReadonlyPartialRecord, Utils } from '@sigmail/common';
import {
  CarePlanArrayListSection,
  CarePlanDateValue,
  CarePlanNonArrayListSection,
  CarePlanSection,
  CarePlanSectionIdSubject
} from '@sigmail/objects';
import { WithT } from 'i18next';
import { applyPatches, Patch } from 'immer';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../../app-state/constants';
import { formatCarePlanSectionFieldValue as formatFieldValue } from './format-care-plan-section-field-value';
import { isArrayListSectionId } from './is-array-list-section-id';

type CarePlanSectionSubject = Extract<CarePlanNonArrayListSection, { sectionId: CarePlanSectionIdSubject }>;

export interface GetCarePlanSectionFieldValueOptions extends Partial<WithT> {
  readonly changesetIndex?: number;
}

type SectionFieldValueType<Section extends CarePlanSection> = Section extends CarePlanArrayListSection
  ? Section['list'][0]
  : Section extends CarePlanNonArrayListSection
  ? CarePlanDateValue | string | undefined
  : never;

type SectionPathType<Section extends CarePlanSection> = Section extends CarePlanArrayListSection
  ? number
  : Section extends CarePlanNonArrayListSection
  ? Section extends Extract<CarePlanNonArrayListSection, CarePlanSectionIdSubject>
    ? Exclude<keyof Section, 'changeset'> | 'address' | 'healthPlan'
    : Exclude<keyof Section, 'changeset'>
  : never;

export function getCarePlanSectionFieldValue<Section extends CarePlanSection>(
  section: Section,
  path: SectionPathType<Section>,
  options?: GetCarePlanSectionFieldValueOptions
): SectionFieldValueType<Section>;

export function getCarePlanSectionFieldValue(
  section: CarePlanArrayListSection,
  arrayIndex: number,
  options?: GetCarePlanSectionFieldValueOptions
): typeof section['list'][0];

export function getCarePlanSectionFieldValue(
  section: CarePlanSectionSubject,
  fieldName: Exclude<keyof CarePlanSectionSubject, 'changeset'> | 'address' | 'healthPlan',
  options?: GetCarePlanSectionFieldValueOptions
): CarePlanDateValue | string | undefined;

export function getCarePlanSectionFieldValue(section: CarePlanSection, path: unknown, options?: unknown): unknown {
  type TOptions = Required<GetCarePlanSectionFieldValueOptions>;

  let { changesetIndex, t } = (Utils.isNonArrayObjectLike(options) ? options : EMPTY_PLAIN_OBJECT) as TOptions;
  if (!Utils.isInteger(changesetIndex)) changesetIndex = 1;
  if (typeof t !== 'function') t = Utils.stringOrDefault;

  let changesetList = Utils.arrayOrDefault<Array<Patch>>(section.changeset, EMPTY_ARRAY);
  if (changesetIndex > 0) changesetList = changesetList.slice(changesetIndex);

  let value: unknown;
  switch (section.sectionId) {
    case 'medicationCoordination':
    case 'personalInfo':
    case 'recentHospitalVisit':
    case 'subject':
    case 'supportingInfo': {
      if (!Utils.isString(path)) break;

      let data: ReadonlyPartialRecord<string, CarePlanDateValue | string> = EMPTY_PLAIN_OBJECT;
      if (changesetIndex === 0) {
        data = Utils.omit(section, ['changeset', 'sectionId'] as const);
      } else {
        for (let index = changesetList.length - 1; index >= 0; index--) {
          const patchList = changesetList[index];
          data = applyPatches(data, patchList);
        }
      }

      value = data[path];
      if (section.sectionId === 'subject') {
        const isAddressField = path === 'address';
        const isHealthPlanField = !isAddressField && path === 'healthPlan';

        if (isAddressField || isHealthPlanField) {
          if (isAddressField) {
            const { addressLine1, addressLine2 } = data;
            value = Utils.joinPostalAddress({ addressLine1, addressLine2 });
          } else if (isHealthPlanField) {
            const hpj = formatFieldValue(data.healthPlanJurisdiction, 'healthPlanJurisdiction', t);
            const hpn = Utils.trimOrDefault(data.healthPlanNumber);
            value = hpj.length > 0 ? (hpn.length === 0 ? hpj : `${hpn} (${hpj})`) : hpn;
          }
        }
      }

      break;
    }
    default: {
      if (!isArrayListSectionId(section.sectionId) || !Utils.isInteger(path)) break;

      let list: CarePlanArrayListSection['list'] = EMPTY_ARRAY;
      if (changesetIndex === 0) {
        list = section.list;
      } else {
        for (let index = changesetList.length - 1; index >= 0; index--) {
          const patchList = changesetList[index];
          list = applyPatches(list, patchList);
        }
      }

      value = list[path];
      break;
    }
  }

  return value;
}
