import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { Utils } from '@sigmail/common';
import { WithT } from 'i18next';
import React from 'react';
import { useSendGuestAccountInvitationActionHandler, useSendNonGuestAccountInvitationActionHandler } from '.';
import * as ActionId from '../../../constants/action-ids';
import globalI18n from '../../../i18n/global';
import { DIALOG_SEND_GUEST_ACCOUNT_INVITATION, DIALOG_SEND_NON_GUEST_ACCOUNT_INVITATION } from '../constants';
import { closeDialogAction, openDialogAction } from '../constants/actions';
import { ActionData, OnClickActionHandler } from '../types';

export interface UseActionClickHandlerParams extends WithT {
  dispatch: React.Dispatch<AnyAction>;
  openedDialog?: string | undefined;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export type UseActionClickHandlerResult = [
  action: PayloadAction<ActionData> | undefined,
  actionClickHandler: OnClickActionHandler
];

export const useActionClickHandler = ({
  dispatch,
  openedDialog,
  setSnackbarMessage,
  t
}: UseActionClickHandlerParams): UseActionClickHandlerResult => {
  const [action, setAction] = React.useState<PayloadAction<ActionData>>();
  const prevAction = React.useRef<PayloadAction<ActionData>>();

  const actionHandlerSendGuestAccountInvitation = useSendGuestAccountInvitationActionHandler();
  const actionHandlerSendNonGuestAccountInvitation = useSendNonGuestAccountInvitationActionHandler();

  //
  //#region actionClickHandler
  const actionClickHandler = React.useCallback<OnClickActionHandler>(
    (actionId, baseActionData) => {
      if (!Utils.isString(actionId)) {
        if (process.env.REACT_APP_ENV === 'local') {
          throw new Error('typeof actionId !== "string"');
        }
        return;
      }

      let actionData: ActionData = { ...baseActionData };
      if (Utils.isNotNil(openedDialog) && (actionId === ActionId.DialogAccept || actionId === ActionId.DialogReject)) {
        dispatch(closeDialogAction());
        if (actionId === ActionId.DialogReject) return;

        switch (openedDialog) {
          case DIALOG_SEND_GUEST_ACCOUNT_INVITATION: {
            actionId = ActionId.SendGuestAccountInvitation;
            break;
          }
          case DIALOG_SEND_NON_GUEST_ACCOUNT_INVITATION: {
            actionId = ActionId.SendNonGuestAccountInvitation;
            break;
          }
          default: {
            const error = new Error(`Unhandled case - ${openedDialog}`);
            if (process.env.REACT_APP_ENV === 'local') throw error;

            return;
          }
        }
      }

      switch (actionId) {
        case ActionId.SendGuestAccountInvitation: {
          if (Utils.isNil(baseActionData)) {
            dispatch(openDialogAction(DIALOG_SEND_GUEST_ACCOUNT_INVITATION));
            return;
          }
          break;
        }
        case ActionId.SendNonGuestAccountInvitation: {
          if (Utils.isNil(baseActionData)) {
            dispatch(openDialogAction(DIALOG_SEND_NON_GUEST_ACCOUNT_INVITATION));
            return;
          }
          break;
        }
        default: {
          const error = new Error(`Unhandled case - ${actionId}`);
          if (process.env.REACT_APP_ENV === 'local') throw error;

          return;
        }
      }

      return new Promise<PromiseSettledResult<any>>((resolve) => {
        const handlerActionData = Object.assign<ActionData, ActionData, ActionData>({}, actionData, {
          successCallback: (value?: any): void => {
            dispatch(closeDialogAction());
            setAction(undefined);
            resolve({ status: 'fulfilled', value });
          },
          failCallback: (error?: any): void => {
            setSnackbarMessage(t(globalI18n.errorOccurredTryAgainMessageGeneric));
            setAction(undefined);
            resolve({ status: 'rejected', reason: error });
          }
        });

        setAction({ type: actionId, payload: handlerActionData });
      });
    },
    [dispatch, openedDialog, setSnackbarMessage, t]
  );
  //#endregion
  //

  React.useEffect(() => {
    const { type: actionId, payload: actionData } = (action || {}) as PayloadAction<any>;
    const ignoreAction =
      !Utils.isString(actionId) ||
      (Utils.isNotNil(prevAction.current) &&
        actionId === prevAction.current.type &&
        actionData === prevAction.current.payload);
    prevAction.current = action;
    if (ignoreAction) return;

    switch (actionId) {
      case ActionId.SendGuestAccountInvitation: {
        actionHandlerSendGuestAccountInvitation(actionData);
        return;
      }
      case ActionId.SendNonGuestAccountInvitation: {
        actionHandlerSendNonGuestAccountInvitation(actionData);
        return;
      }
      default: {
        break;
      }
    }
  }, [action, actionHandlerSendGuestAccountInvitation, actionHandlerSendNonGuestAccountInvitation, dispatch]);

  return React.useMemo(() => [action, actionClickHandler], [action, actionClickHandler]);
};
