export const APNG = 'image/apng';
export const BMP = 'image/bmp';
export const GIF = 'image/gif';
export const ICO = 'image/x-icon';
export const JPEG = 'image/jpeg';
export const MS_EXCEL_XLS = 'application/vnd.ms-excel';
export const MS_EXCEL_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const MS_POWERPOINT_PPT = 'application/vnd.ms-powerpoint';
export const MS_POWERPOINT_PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const MS_WORD_DOC = 'application/msword';
export const MS_WORD_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const OPEN_DOC_PRESENTATION = 'application/vnd.oasis.opendocument.presentation';
export const OPEN_DOC_SPREADSHEET = 'application/vnd.oasis.opendocument.spreadsheet';
export const OPEN_DOC_TEXT = 'application/vnd.oasis.opendocument.text';
export const PDF = 'application/pdf';
export const TEXT_CALENDAR = 'text/calendar';
export const TEXT_CSV = 'text/csv';
export const TEXT_HTML = 'text/html';
export const TEXT_MARKDOWN = 'text/markdown';
export const TEXT_PLAIN = 'text/plain';
export const TEXT_VCARD = 'text/vcard';
export const TEXT_XML = 'text/xml';
export const PNG = 'image/png';
export const RTF = 'application/rtf';
export const SVG = 'image/svg+xml';
/** @deprecated use TEXT_PLAIN instead. */
export const TXT = 'text/plain';
export const WEBP = 'image/webp';
