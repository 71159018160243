/* eslint-disable @typescript-eslint/no-inferrable-types */

export const BATCH_UPDATE_ERROR_CODE_VERSION_CONFLICT: number = 30001;

//
// IMPORTANT all URLs must end with a forward-slash
//
export const URL_ACCURO_EMR_ADD_DOCUMENT: string = 'api/accuro/document/add/';
export const URL_ACCURO_EMR_CHECK_AUTH_STATUS: string = 'api/accuro/checkIt/';
export const URL_ACCURO_EMR_DOCUMENT_LIST: string = 'api/accuro/document/list/';
export const URL_ACCURO_EMR_FETCH_DOCUMENT: string = 'api/accuro/document/fetch/';
export const URL_ACCURO_EMR_GET_OAUTH_URL: string = 'api/accuro/url/';
export const URL_ACCURO_EMR_PROVIDER_FOLDER_LIST: string = 'api/accuro/provider/folder/';
export const URL_ACCURO_EMR_PROVIDER_LIST: string = 'api/accuro/provider/list/';
export const URL_ACCURO_EMR_SEARCH_PATIENT: string = 'api/accuro/search/';

export const URL_AUTHY_REGISTER_ACCOUNT: string = 'api/authy/registration/';
export const URL_AUTHY_SEND_MFA_CODE: string = 'api/authy/:mfaMethod/';
export const URL_AUTHY_UNREGISTER_ACCOUNT: string = 'api/authy/remove/:accountId/';
export const URL_AUTHY_UPDATE_EMAIL: string = 'api/authy/update/';
export const URL_AUTHY_VERIFY_MFA_CODE: string = 'api/authy/verify/';

export const URL_BATCH_QUERY_DATA: string = 'api/batch/query/';
export const URL_BATCH_UPDATE_DATA: string = 'api/batch/update/';

export const URL_ENTER_STATE: string = 'api/enterState/';

export const URL_GENERATE_ICS_URL: string = 'api/calendar/';

export const URL_GET_GEO_LOCATION: string = 'api/geo/ipinfo/';
export const URL_GET_IDS: string = 'api/getids/:count/';
export const URL_GET_PUBLIC_KEY: string = 'api/sigmail/JSC9tygyNdnsbcXe98MH1hj4RZOnIar1/public/';
export const URL_GET_VIDEO_SIGNATURE: string = 'api/video/signature/';
export const URL_GET_VIDEO_TOKEN: string = 'api/video/token/';

export const URL_HRM_SEND_REPORT: string = 'api/hrm/report/';

export const URL_ISAAC_OBSERVATION: string = 'api/isaac/observation/';

export const URL_OSCAR_EMR_CHECK_AUTH_STATUS: string = 'api/oscar/checkIt/';
export const URL_OSCAR_EMR_GET_OAUTH_URL: string = 'api/oscar/url/';
export const URL_OSCAR_EMR_GET_PROVIDER_NUMBER: string = 'api/oscar/providerNo/';
export const URL_OSCAR_EMR_SEARCH_PATIENT: string = 'api/oscar/match/';
export const URL_OSCAR_EMR_ADD_DOCUMENT: string = 'api/oscar/addDoc/';

export const URL_NOTIFICATION_CANCEL: string = 'api/notification/cancel/';
export const URL_NOTIFICATION_NEW: string = 'api/notification/new/';
export const URL_NOTIFICATION_UPDATE: string = 'api/notification/update/';

export const URL_OTP_SEND: string = 'api/otp/send/';
export const URL_OTP_VERIFY: string = 'api/otp/verify/';

export const URL_SEND_EMAIL_MESSAGE: string = 'api/email/simple/';
export const URL_SEND_EMAIL_MESSAGE_ENCRYPTED: string = 'api/email/encrypted/';
export const URL_SEND_TEMPLATED_EMAIL_MESSAGE: string = 'api/email/template/';
export const URL_SEND_TEMPLATED_EMAIL_MESSAGE_ENCRYPTED: string = URL_SEND_EMAIL_MESSAGE_ENCRYPTED;

export const URL_SRP_EXCHANGE_CREDENTIALS: string = 'connect/exchange/';
export const URL_SRP_VERIFY_CREDENTIALS: string = 'connect/verification/';

export const URL_SUBSCRIBE_WEB_PUSH: string = 'api/webpush/register';
export const URL_UNSUBSCRIBE_WEB_PUSH: string = 'api/webpush/unregister';
