import DateFnsUtils from '@date-io/date-fns';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioProps
} from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PartialRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n } from '@sigmail/i18n';
import { BPReadingArmUsed } from '@sigmail/objects';
import { startOfDay } from 'date-fns';
import { enCA, frCA } from 'date-fns/locale';
import { FieldState } from 'final-form';
import { TOptions } from 'i18next';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import * as FormInputConstraint from '../../../constants/form-input-constraint';
import {
  BPReadingFormDiastolic,
  BPReadingFormHeartRate,
  BPReadingFormSystolic
} from '../../../constants/form-input-constraint';
import * as FormInputMask from '../../../constants/form-input-mask';
import { FrenchCanada } from '../../../constants/language-codes';
import { withTranslation } from '../../../i18n';
import { I18N_NS_HEALTH_DATA } from '../../../i18n/config/namespace-identifiers';
import healthDataI18n from '../../../i18n/health-data';
import {
  nullValidator,
  range as rangeValidator,
  requiredDate as requiredDateValidator, ValidationErrorResult
} from '../../shared/form/field-validator';
import {
  DatePickerFieldProps,
  DateTimePickerFieldProps,
  FieldProps,
  FormComponent,
  FormComponentProps,
  FormComponentState,
  TimePickerFieldProps
} from '../../shared/form/form.component';
import style from './bp-reading.module.css';

const { bpReading: i18n } = healthDataI18n.form;

export type FieldsetName = keyof typeof i18n;

export type FieldNameAdditionalReading = keyof typeof i18n.fieldsetAdditionalReading.formField;
export type FieldNameArmUsed1 = keyof typeof i18n.fieldsetArmUsed1.formField;
export type FieldNameArmUsed2 = keyof typeof i18n.fieldsetArmUsed2.formField;
export type FieldNameBloodPressure1 = keyof typeof i18n.fieldsetBloodPressure1.formField;
export type FieldNameBloodPressure2 = keyof typeof i18n.fieldsetBloodPressure2.formField;
export type FieldNameComment1 = keyof typeof i18n.fieldsetComment1.formField;
export type FieldNameComment2 = keyof typeof i18n.fieldsetComment2.formField;
export type FieldNameHeartRate1 = keyof typeof i18n.fieldsetHeartRate1.formField;
export type FieldNameHeartRate2 = keyof typeof i18n.fieldsetHeartRate2.formField;
export type FieldNameTimestampDate1 = keyof typeof i18n.fieldsetTimestampDate1.formField;
export type FieldNameTimestampTime1 = keyof typeof i18n.fieldsetTimestampTime1.formField;
export type FieldNameTimestampDate2 = keyof typeof i18n.fieldsetTimestampDate2.formField;
export type FieldNameTimestampTime2 = keyof typeof i18n.fieldsetTimestampTime2.formField;

export type FieldName =
  | FieldNameAdditionalReading
  | FieldNameArmUsed1
  | FieldNameArmUsed2
  | FieldNameBloodPressure1
  | FieldNameBloodPressure2
  | FieldNameComment1
  | FieldNameComment2
  | FieldNameHeartRate1
  | FieldNameHeartRate2
  | FieldNameTimestampDate1
  | FieldNameTimestampTime1
  | FieldNameTimestampDate2
  | FieldNameTimestampTime2;

type ArmUsedFormValues = Record<Extract<FieldName, `armUsed${string}`>, BPReadingArmUsed>;
type BinaryFormValues = Record<Extract<FieldName, 'additionalReading'>, 0 | 1>;
type DateFormValues = Record<Extract<FieldName, `timestamp${string}`>, Date>;
type NumberFormValues = Record<Extract<FieldName, `${`diastolic` | `heartRate` | `systolic`}${string}`>, number>;
type StringFormValues = Record<Extract<FieldName, `comment${string}`>, string>;

export type FormValues = ArmUsedFormValues & BinaryFormValues & DateFormValues & NumberFormValues & StringFormValues;

type FieldsetList = ReadonlyArray<[FieldsetName, ReadonlyArray<FieldName>]>;
export const FIELD_SET_LIST: FieldsetList = [
  ['fieldsetArmUsed1', ['armUsed1']],
  ['fieldsetTimestampDate1', ['timestampDate1']],
  ['fieldsetTimestampTime1', ['timestampTime1']],
  ['fieldsetBloodPressure1', ['systolic1', 'diastolic1']],
  ['fieldsetHeartRate1', ['heartRate1']],
  ['fieldsetComment1', ['comment1']],
  ['fieldsetAdditionalReading', ['additionalReading']],
  ['fieldsetArmUsed2', ['armUsed2']],
  ['fieldsetTimestampDate2', ['timestampDate2']],
  ['fieldsetTimestampTime2', ['timestampTime2']],
  ['fieldsetBloodPressure2', ['systolic2', 'diastolic2']],
  ['fieldsetHeartRate2', ['heartRate2']],
  ['fieldsetComment2', ['comment2']]
];

const diastolicValidator = rangeValidator(BPReadingFormDiastolic.min, BPReadingFormDiastolic.max, {
  ignoreNil: false,
  ignoreEmptyString: false
});

const heartRateValidator = rangeValidator(BPReadingFormHeartRate.min, BPReadingFormHeartRate.max, {
  ignoreNil: false,
  ignoreEmptyString: false
});

const systolicValidator = rangeValidator(BPReadingFormSystolic.min, BPReadingFormSystolic.max, {
  ignoreNil: false,
  ignoreEmptyString: false
});

type FieldPropsRecord = Record<Exclude<FieldName, `timestamp${string}`>, FieldProps> &
  Record<Extract<FieldName, `timestampDate${string}`>, DatePickerFieldProps> &
  Record<Extract<FieldName, `timestampTime${string}`>, TimePickerFieldProps>;

const FIELD_PROPS: Readonly<FieldPropsRecord> = {
  ...FIELD_SET_LIST.reduce(
    (passDownProps, [fieldset, fieldNameList]) =>
      fieldNameList.reduce((fieldProps, fieldName) => {
        const fieldsetI18n = i18n[fieldset] as any;

        let label: string = fieldsetI18n.formField[fieldName].label;
        const placeholder: string | undefined = fieldsetI18n.formField[fieldName].placeholder;
        if (
          label.length === 0 ||
          ([
            'comment1',
            'comment2',
            'heartRate1',
            'heartRate2',
            'timestampDate1',
            'timestampDate2',
            'timestampDate2',
            'timestampTime1',
            'timestampTime2'
          ] as ReadonlyArray<FieldName>).includes(fieldName)
        ) {
          label = undefined!;
        }

        (fieldProps as any)[fieldName] = { label, placeholder, ...fieldProps[fieldName] };
        return fieldProps;
      }, passDownProps),
    ({
      additionalReading: {
        config: {
          validateFields: ['armUsed2', 'diastolic2', 'heartRate2', 'systolic2', 'timestampDate2', 'timestampTime2']
        }
      },
      armUsed1: {
        config: {
          required: (value: unknown) => Utils.isNil(value)
        }
      },
      armUsed2: {
        config: {
          required: (value: unknown, { additionalReading }: FormValues) => additionalReading === 1 && Utils.isNil(value)
        }
      },
      comment1: {
        multiline: true,
        rows: 8,
        rowsMax: 8
      },
      comment2: {
        multiline: true,
        rows: 8,
        rowsMax: 8
      },
      diastolic1: {
        config: {
          required: true,
          range: () => diastolicValidator
        },
        inputMode: 'numeric',
        maskedInputProps: { guide: false, mask: FormInputMask.BloodPressure }
      },
      diastolic2: {
        config: {
          required: (_: unknown, { additionalReading }: FormValues) => additionalReading === 1,
          range: (_: unknown, { additionalReading }: FormValues) =>
            additionalReading === 1 ? diastolicValidator : nullValidator()
        },
        inputMode: 'numeric',
        maskedInputProps: { guide: false, mask: FormInputMask.BloodPressure }
      },
      heartRate1: {
        config: {
          required: true,
          range: () => heartRateValidator
        },
        inputMode: 'numeric',
        maskedInputProps: { guide: false, mask: FormInputMask.HeartRate }
      },
      heartRate2: {
        config: {
          required: (_: unknown, { additionalReading }: FormValues) => additionalReading === 1,
          range: (_: unknown, { additionalReading }: FormValues) =>
            additionalReading === 1 ? heartRateValidator : nullValidator()
        },
        inputMode: 'numeric',
        maskedInputProps: { guide: false, mask: FormInputMask.HeartRate }
      },
      systolic1: {
        config: {
          required: true,
          range: () => systolicValidator
        },
        inputMode: 'numeric',
        maskedInputProps: { guide: false, mask: FormInputMask.BloodPressure }
      },
      systolic2: {
        config: {
          required: (_: unknown, { additionalReading }: FormValues) => additionalReading === 1,
          range: (_: unknown, { additionalReading }: FormValues) =>
            additionalReading === 1 ? systolicValidator : nullValidator()
        },
        inputMode: 'numeric',
        maskedInputProps: { guide: false, mask: FormInputMask.BloodPressure }
      },
      timestampDate1: {
        config: {
          required: { typeOf: 'date' },
          range: FormInputConstraint.BPReadingFormDate
        }
      },
      timestampDate2: {
        disabled: true
      },
      timestampTime1: {
        config: {
          required: () => requiredDateValidator({ ignoreNil: false }),
          range: () =>
            rangeValidator(FormInputConstraint.BPReadingFormTime.min, FormInputConstraint.BPReadingFormTime.max, {
              timeOnly: true
            })
        }
      },
      timestampTime2: {
        config: {
          required: (_: Date, { additionalReading }: FormValues) =>
            additionalReading === 1 ? requiredDateValidator({ ignoreNil: false }) : nullValidator(),
          range: (_: Date, { additionalReading }: FormValues) =>
            additionalReading === 1
              ? rangeValidator(FormInputConstraint.BPReadingFormTime.min, FormInputConstraint.BPReadingFormTime.max, {
                  timeOnly: true
                })
              : nullValidator()
        }
      }
    } as unknown) as FieldPropsRecord
  )
};

export type BPReadingFormClassKey =
  | 'inputRoot'
  | `control${`Group` | `GroupLabel` | `Label` | `Root`}`
  | `slider${'Group' | 'Label' | 'RangeLabel' | 'Root'}`;

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<BPReadingFormClassKey, string> | undefined;
}

type ComponentProps = Props & WithTranslation;
type State = FormComponentState<FieldName, FormValues>;

const BLOOD_PRESSURE_DEFAULT_VALUE = ('' as unknown) as number;
const TIME_STAMP_DATE_DEFAULT_VALUE = startOfDay(Date.now());

class BPReadingFormComponent extends FormComponent<
  FieldsetName,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes'
    );

    this.createForm({
      initialValues: {
        additionalReading: 0,
        armUsed1: null!,
        armUsed2: null!,
        comment1: '',
        comment2: '',
        diastolic1: BLOOD_PRESSURE_DEFAULT_VALUE,
        diastolic2: BLOOD_PRESSURE_DEFAULT_VALUE,
        heartRate1: BLOOD_PRESSURE_DEFAULT_VALUE,
        heartRate2: BLOOD_PRESSURE_DEFAULT_VALUE,
        systolic1: BLOOD_PRESSURE_DEFAULT_VALUE,
        systolic2: BLOOD_PRESSURE_DEFAULT_VALUE,
        timestampDate1: TIME_STAMP_DATE_DEFAULT_VALUE,
        timestampDate2: TIME_STAMP_DATE_DEFAULT_VALUE,
        timestampTime1: null!,
        timestampTime2: null!
      }
    });
  }

  public componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<State>): void {
    super.componentDidUpdate(prevProps, prevState);

    if (prevState.timestampDate1?.value !== this.state.timestampDate1?.value) {
      this.form.change('timestampDate2', this.state.timestampDate1?.value);
    }
  }

  /** @override */
  protected get formId() {
    return Utils.stringOrDefault(this.props.id, `form-bp-reading-${this.formIdSuffix}`);
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetName: FieldsetName) {
    const label = Utils.trimOrDefault(i18n[fieldsetName].label);
    return label.length > 0 ? this.props.t(label) : null;
  }

  /** @override */
  protected getFieldType(fieldName: FieldName, ...args: any[]) {
    switch (fieldName) {
      case 'timestampDate1':
      case 'timestampDate2':
        return 'date';
      case 'timestampTime1':
      case 'timestampTime2':
        return 'time';
      default:
        return super.getFieldType(fieldName, ...args);
    }
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { t } = this.props;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    if (fieldName === 'timestampTime1' || fieldName === 'timestampTime2') {
      const timePickerFieldProps: DateTimePickerFieldProps = {
        keyboardIcon: <AccessTime />
      };

      fieldProps = {
        ...fieldProps,
        ...timePickerFieldProps
      };
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    if (!Utils.isString(errorMessageI18n)) return error;

    let tOptions: TOptions | undefined;
    if (error.key === 'range') {
      if (
        ([
          'diastolic1',
          'diastolic2',
          'heartRate1',
          'heartRate2',
          'systolic1',
          'systolic2'
        ] as ReadonlyArray<FieldName>).includes(fieldName)
      ) {
        const { min: MIN, max: MAX } = error.range;
        tOptions = { MIN, MAX };
      }
    }

    return t(errorMessageI18n, tOptions);
  }

  /** @override */
  protected renderForm(...args: any[]): React.ReactNode {
    const { language: locale } = this.props.i18n;

    return (
      <MuiPickersUtilsProvider locale={locale === FrenchCanada ? frCA : enCA} utils={DateFnsUtils}>
        {super.renderForm(...args)}
      </MuiPickersUtilsProvider>
    );
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]): React.ReactNode {
    const { value: additionalReadingValue } = this.state.additionalReading;
    if (
      ['armUsed2', 'comment2', 'diastolic2', 'heartRate2', 'systolic2', 'timestampDate2', 'timestampTime2'].includes(
        fieldName
      ) &&
      additionalReadingValue !== 1
    )
      return null;

    const { classes } = this.props;
    const [fieldsetName] = FIELD_SET_LIST.find(([, fieldNameList]) => fieldNameList.includes(fieldName))!;

    let fieldNode: React.ReactNode;
    switch (fieldName) {
      case 'additionalReading':
      case 'armUsed1':
      case 'armUsed2': {
        const { options } = (i18n as any)[fieldsetName].formField[fieldName];
        fieldNode = this.radioFieldRenderer(fieldName, options);
        break;
      }
      default: {
        fieldNode = super.renderField(fieldName, ...args);
        break;
      }
    }

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  private radioFieldRenderer(
    fieldName: Extract<FieldName, 'additionalReading' | `armUsed${`1` | `2`}`>,
    options: any
  ): React.ReactNode {
    const { classes, disabled, t } = this.props;
    const { change, error, value } = this.state[fieldName];

    const radioNodeList = options.map(({ codedValue, label: optionLabel }: any) => {
      const radioProps: RadioProps = { color: 'primary', name: fieldName, size: 'small' };
      const label = t(optionLabel);

      return <FormControlLabel control={<Radio {...radioProps} />} key={codedValue} label={label} value={codedValue} />;
    });

    return (
      <FormControl
        aria-disabled={disabled}
        aria-invalid={Utils.isString(error)}
        aria-required="true"
        classes={{ root: classes!.controlRoot }}
        key={fieldName}
        disabled={disabled}
      >
        <FormLabel classes={{ root: classes!.controlLabel }} component="div">
          {t(FIELD_PROPS[fieldName].label as string)}
        </FormLabel>

        <RadioGroup
          classes={{ root: classes!.controlGroup }}
          onChange={(_, newValue) => change(fieldName === 'additionalReading' ? Number(newValue) : newValue)}
          value={value}
        >
          {radioNodeList}
        </RadioGroup>

        {Utils.isString(error) && <FormHelperText error={true}>{error}</FormHelperText>}
      </FormControl>
    );
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<any>) {
    let { error } = fieldState;

    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    return super.setFieldState(fieldName, { ...fieldState, error });
  }

  /** @override */
  protected onChangeDateField(fieldName: FieldName, value: Date | null) {
    let newValue = value;
    if (Utils.isValidDate(newValue)) {
      if (fieldName === 'timestampDate1') {
        newValue = startOfDay(newValue);
      }
    }

    this.form.change(fieldName, newValue!);
  }

  protected formSubmitHandler(values: FormValues) {
    const formValues = Utils.mapValues(values, (value) => {
      if ((Utils.isString(value) && value.length === 0) || Utils.isNil(value)) {
        value = undefined!;
      }

      return value;
    });

    return super.formSubmitHandler((formValues as unknown) as FormValues);
  }
}

const ns = [I18N_NS_HEALTH_DATA];

export const BPReadingForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['form-input-group'],
      controlRoot: style['form-control'],
      controlLabel: style.label,
      controlGroup: style['group'],
      periodLabel: style['period-label'],
      sliderRoot: style.slider,
      sliderLabel: style.label,
      sliderGroup: style.group,
      sliderRangeLabel: style['range-label']
    });

    return <BPReadingFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

BPReadingForm.displayName = 'BPReadingForm';
BPReadingForm.defaultProps = { className: style.form };
