import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Form.SelectEMR = {
  fieldsetDefault: {
    formField: {
      emr: {
        error: {
          // Translators: Error message displayed when no option is selected \
          // for the Select EMR input in EMR Configuration dialog
          valueMissing: gettext('account', 'emrRequired', 'selectEMRFormError')
        },
        // Translators: Label for the Select EMR input in EMR Configuration \
        // dialog
        label: gettext('account', 'emr', 'selectEMRFormInputLabel'),
        options: [
          {
            code: 'accuro',
            // Translators: Label displayed for the Accuro EMR drop-down \
            // option of Select EMR input in EMR Configuration dialog
            label: gettext('account', 'accuro', 'selectEMRFormOptionLabel')
          },
          {
            code: 'oscar',
            // Translators: Label displayed for the Oscar EMR drop-down \
            // option of Select EMR input in EMR Configuration dialog
            label: gettext('account', 'oscar', 'selectEMRFormOptionLabel')
          }
        ],
        // Translators: Label displayed for the Select drop-down option of \
        // Select EMR input in EMR Configuration dialog
        placeholder: gettext('account', 'selectEMR', 'selectEMRFormOptionLabel'),
      }
    }
  }
};

export default i18n;
