import { PartialRecord, Utils } from '@sigmail/common';
import React from 'react';
import { Action, ActionLabel, RouterAction } from 'sigmail';
import { useTranslation } from '../../i18n';
import { resolveActionLabel } from '../../utils/resolve-action-label';
import { resolveActionPath } from '../../utils/resolve-action-path';
import navStyle from './nav.module.css';
import { RouterNavLink, RouterNavLinkProps } from './router-nav-link.component';

type HtmlUListElementProps = Omit<React.ComponentProps<'ul'>, 'children' | 'ref'>;

export type NavActionListClassKey = 'listItem' | 'anchor';

export interface NavAction extends ActionLabel, Omit<RouterNavLinkProps, 'to' | 'href'> {
  actionId: string;
  href?: Action['href'] | undefined;
  image?: React.ImgHTMLAttributes<HTMLImageElement> | undefined;
  render?: ((props: { action: NavAction }) => React.ReactNode) | undefined;
  renderLabel?: ((actionId: string, label: ActionLabel['label']) => React.ReactNode) | undefined;
  to?: RouterAction['to'] | undefined;
}

export interface Props extends HtmlUListElementProps {
  actionContext?: any;
  actionList?: ReadonlyArray<NavAction> | undefined;
  children?: never;
  classes?: PartialRecord<NavActionListClassKey, string> | undefined;
}

const DEFAULT_CLASSES: Record<NavActionListClassKey, string> = { anchor: navStyle.link, listItem: navStyle.item };

export const NavActionList = React.forwardRef<HTMLUListElement, Props>(
  ({ actionContext, actionList, children, classes: classesProp, ...rootProps }, ref) => {
    const { t } = useTranslation();
    const classes = React.useMemo(() => Utils.defaults({}, classesProp as unknown, DEFAULT_CLASSES), [classesProp]);

    const navItemList = React.useMemo<ReadonlyArray<React.ReactNode> | undefined>(() => {
      return actionList?.map(
        ({ actionId, children, href: hrefProp, label, render, renderLabel, to, ...action }, index) => {
          if (typeof render === 'function') {
            return render({ action: Utils.omit(actionList[index], 'render') });
          }

          const href =
            Utils.isString(hrefProp) || typeof hrefProp === 'function'
              ? t(resolveActionPath({ href: hrefProp, context: actionContext }))
              : undefined;

          const linkProps: RouterNavLinkProps = {
            ...action,
            className: classes.anchor,
            href,
            to: t(resolveActionPath({ to, context: actionContext }))
          };

          return (
            <li key={actionId} className={classes.listItem}>
              <RouterNavLink data-action-id={actionId} {...linkProps}>
                {typeof renderLabel === 'function'
                  ? renderLabel(actionId, label)
                  : t(resolveActionLabel({ label }, actionId, actionContext))}
              </RouterNavLink>
            </li>
          );
        }
      );
    }, [actionContext, actionList, classes.anchor, classes.listItem, t]);

    return Utils.isNotNil(navItemList) && navItemList.length > 0 ? (
      <ul ref={ref} {...rootProps}>
        {navItemList}
      </ul>
    ) : null;
  }
);

NavActionList.displayName = 'NavActionList';
NavActionList.defaultProps = { className: navStyle.nav };
