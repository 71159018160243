import { Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import React from 'react';
import { EMPTY_PLAIN_OBJECT } from '../constants';
import { useDispatchFetchObjects } from './use-dispatch-fetch-objects';

export const useDispatchFetchServerDateTime = () => {
  const dispatchFetchObjects = useDispatchFetchObjects();

  return React.useCallback(
    () =>
      dispatchFetchObjects(EMPTY_PLAIN_OBJECT).then(({ serverDateTime }) => {
        const dtServer = Utils.isString(serverDateTime) && new Date(serverDateTime);
        if (!Utils.isValidDate(dtServer)) throw new Api.MalformedResponseException();
        return dtServer;
      }),
    [dispatchFetchObjects]
  );
};
