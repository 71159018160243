import type { SigmailObjectTypeCode } from '@sigmail/common';
import { AppUser, AppUserGroup, Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectEncounterMetadataValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_ENCOUNTER_METADATA;

/** @public */
export class DataObjectEncounterMetadata extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      AppUser.isValidId(dv.originatorId) &&
      AppUserGroup.isValidId(dv.groupId) &&
      (Utils.isNil(dv.guestId) || AppUser.isValidId(dv.guestId)) &&
      Utils.isString(dv.title) &&
      Utils.isArray(dv.messageList) &&
      Utils.isNonArrayObjectLike(dv.timestamp)
    );
  }

  public updateValue(newValue: DV): Promise<DataObjectEncounterMetadata> {
    const Class = this.constructor as typeof DataObjectEncounterMetadata;
    return Class.updateValue(this, newValue);
  }
}
