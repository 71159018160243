import { Utils } from '@sigmail/common';
import React from 'react';

type HtmlImageElementProps = React.ComponentProps<'img'>;
export type HtmlImageElementPropsWithAlt = Omit<HtmlImageElementProps, 'alt'> & { alt: string };

/**
 * Returns a new {@link HtmlImageElementPropsWithAlt} with ALT and ROLE
 * attribute added if necessary.
 *
 * ALT attribute is added if it's value is not of data type string.
 *
 * ROLE attribute is added if it's value is not of data type string and ALT
 * attribute's value evaluates to an empty string.
 *
 * @param props
 */
export function processImgProps({ alt, role, ...props }: HtmlImageElementProps): HtmlImageElementPropsWithAlt {
  if (!Utils.isNonArrayObjectLike(props)) return props as any;

  if (!Utils.isString(alt)) alt = '';
  if (!Utils.isString(role) && alt.trim().length === 0) role = 'presentation';
  return { ...props, alt, role };
}
