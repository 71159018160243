import type { HealthPlanJurisdiction } from '../types';

export const Alberta: Extract<HealthPlanJurisdiction, 'CAN$AB'> = 'CAN$AB';
export const BritishColumbia: Extract<HealthPlanJurisdiction, 'CAN$BC'> = 'CAN$BC';
export const Manitoba: Extract<HealthPlanJurisdiction, 'CAN$MB'> = 'CAN$MB';
export const NewBrunswick: Extract<HealthPlanJurisdiction, 'CAN$NB'> = 'CAN$NB';
export const NovaScotia: Extract<HealthPlanJurisdiction, 'CAN$NS'> = 'CAN$NS';
export const NewfoundlandAndLabrador: Extract<HealthPlanJurisdiction, 'CAN$NL'> = 'CAN$NL';
export const NorthwestTerritories: Extract<HealthPlanJurisdiction, 'CAN$NT'> = 'CAN$NT';
export const Nunavut: Extract<HealthPlanJurisdiction, 'CAN$NU'> = 'CAN$NU';
export const Ontario: Extract<HealthPlanJurisdiction, 'CAN$ON'> = 'CAN$ON';
export const PrinceEdwardIsland: Extract<HealthPlanJurisdiction, 'CAN$PE'> = 'CAN$PE';
export const Quebec: Extract<HealthPlanJurisdiction, 'CAN$QC'> = 'CAN$QC';
export const Saskatchewan: Extract<HealthPlanJurisdiction, 'CAN$SK'> = 'CAN$SK';
export const Yukon: Extract<HealthPlanJurisdiction, 'CAN$YT'> = 'CAN$YT';

export const SelfPaid: Extract<HealthPlanJurisdiction, 'self-paid'> = 'self-paid';
export const Insurance: Extract<HealthPlanJurisdiction, 'insurance'> = 'insurance';
export const Foreign: Extract<HealthPlanJurisdiction, 'foreign'> = 'foreign';
