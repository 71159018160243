/* eslint-disable no-unused-vars */

import { AppException } from './app-exception';
import { E_MESSAGING_FAIL } from './constants/error';
import { isInteger } from './utils';

/**
 * Serves as the base class for SigMail messaging-related exceptions.
 *
 * @public
 */
export class MessagingException extends AppException {
  /**
   * Initializes a new instance of `MessagingException` class with a specified
   * error message.
   *
   * @param message - A message that describes the error.
   */
  public constructor(message: string);

  /**
   * Initializes a new instance of `MessagingException` class with a specified
   * error code, and optionally with a specified error message.
   *
   * @param errorCode - A coded numeric value.
   * @param message - A message that describes the error.
   */
  public constructor(errorCode?: number, message?: string);

  public constructor(...args: Array<unknown>) {
    const errorCode = isInteger(args[0]) ? (args[0] as number) : E_MESSAGING_FAIL;
    const message = args.length > 0 ? (args[args.length - 1] as string) : undefined;

    super(errorCode, message);

    this.name = 'MessagingException';
  }
}
