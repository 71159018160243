import type { EncapsulatedKey } from '@sigmail/common';
import { Algorithm } from '.';
import { E_METHOD_NOT_IMPL } from '../constants';
import * as Encoder from '../encoder';
import { RSA_OAEP } from '../encryptor/asymmetric/RSA_OAEP';
import { SigmailCryptoException } from '../SigmailCryptoException';
import type { AsymmetricEncryptor, AsymmetricKey, EncryptEncapsulatedKeyAlgorithm } from '../types';

/**
 * Define the algorithm EncryptEncapsulatedKeyAlgorithm. This is used to encrypt
 * an encapsulated key (EK) to be stored in the Keys table, using a
 * public/private key (PPK).
 *
 * - The encapsulated key is encrypted using the public key
 * - The result is base64 encoded
 * - Decryption uses the corresponding private key
 *
 * @author Kim Birchard <kbirchard@sigmahealthtech.com>
 * @public
 */
export class EncryptEncapsulatedKeyAlgorithmImpl
  extends Algorithm<AsymmetricEncryptor, EncapsulatedKey, AsymmetricKey>
  implements EncryptEncapsulatedKeyAlgorithm
{
  public constructor() {
    super('EncryptEncapsulatedKeyAlgorithm', new RSA_OAEP());
  }

  public decrypt(key: AsymmetricKey, data: string, __UNUSED_version: number): Promise<EncapsulatedKey> {
    const encryptedValue = Encoder.Base64.decode(data);
    return this.encryptor.decrypt(key, encryptedValue);
  }

  public async encrypt(key: AsymmetricKey, data: EncapsulatedKey, __UNUSED_version: number): Promise<string> {
    const encryptedValue = await this.encryptor.encrypt(key, data);
    return Encoder.Base64.encode(encryptedValue);
  }

  public generateKey(): Promise<AsymmetricKey> {
    return Promise.reject(new SigmailCryptoException(E_METHOD_NOT_IMPL, 'Method not implemented.'));
  }
}
