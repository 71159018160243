import { KeyboardTimePicker as TimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import React from 'react';

export interface TimePickerFieldProps extends KeyboardTimePickerProps {}

const TimePickerFieldComponent = React.memo<TimePickerFieldProps>((props) => <TimePicker {...props} />);

export const TimePickerField = React.forwardRef<HTMLDivElement, TimePickerFieldProps>((props, ref) => (
  <TimePickerFieldComponent {...props} innerRef={ref || props.innerRef} />
));

TimePickerField.displayName = 'TimePickerField';

TimePickerField.defaultProps = {
  ampm: true,
  autoOk: true,
  fullWidth: true,
  inputVariant: 'standard',
  margin: 'none',
  variant: 'inline'
};
