import { Utils } from '@sigmail/common';
import { DataObjectEncounterMetadataValue, DataObjectMsgMetadata } from '@sigmail/objects';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { DataObjectCache } from '../../../../app-state/data-objects-slice/cache';
import { useDataObjectByIdSelector, useDispatchFetchDataObjects } from '../../../../app-state/hooks';
import { UNRESOLVED_RESOURCE } from '../../../../constants';
import { useThrowAsyncError } from '../../../../hooks';

export type EncounterMetadataResource = ISuspenseResource<DataObjectEncounterMetadataValue>;

export const useEncounterMetadataResource = (encounterMetadataId: number): EncounterMetadataResource => {
  const dataObjectSelector = useDataObjectByIdSelector();
  const dispatchFetchDataObjects = useDispatchFetchDataObjects();
  const throwAsyncError = useThrowAsyncError();
  const [resource, setResource] = React.useState<EncounterMetadataResource>(UNRESOLVED_RESOURCE);
  const [fetchPromise, setFetchPromise] = React.useState<Promise<any>>();

  const prevMetadataId = React.useRef<number>();
  const encounterMetadata = React.useMemo(() => {
    prevMetadataId.current = encounterMetadataId;

    const encounterMetadataObject = dataObjectSelector<DataObjectEncounterMetadataValue>(encounterMetadataId);
    return DataObjectCache.getValue(encounterMetadataObject);
  }, [dataObjectSelector, encounterMetadataId]);

  const prevMetadata = React.useRef<typeof encounterMetadata>();
  React.useEffect(() => {
    if (Utils.isNil(encounterMetadata)) {
      if (Utils.isNotNil(fetchPromise) && prevMetadataId.current === encounterMetadataId) {
        return;
      }

      prevMetadata.current = undefined;
      if (DataObjectMsgMetadata.isValidId(encounterMetadataId)) {
        setFetchPromise(
          dispatchFetchDataObjects({ authState: 'undefined', ids: [encounterMetadataId] }).then(
            () => setFetchPromise(undefined),
            throwAsyncError
          )
        );
      }

      setResource(UNRESOLVED_RESOURCE);
      return;
    }

    if (prevMetadata.current !== encounterMetadata) {
      prevMetadata.current = encounterMetadata;
      setResource({ value: () => encounterMetadata });
    }
  }, [dispatchFetchDataObjects, encounterMetadata, encounterMetadataId, fetchPromise, throwAsyncError]);

  return resource;
};
