import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import sharedFormStyle from './forms.module.css';

type HtmlButtonElementProps = React.ComponentPropsWithoutRef<'button'>;

export interface Props extends HtmlButtonElementProps {
  progress?: boolean | undefined;
}

export const SubmitButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, className, progress, ...rootProps }, ref) => {
    return (
      <button
        className={clsx(className, rootProps.disabled === true && 'disabled')}
        ref={ref}
        type="submit"
        {...rootProps}
      >
        {progress === true && <CircularProgress size="" />}
        {children}
      </button>
    );
  }
);

SubmitButton.displayName = 'SubmitButton';
SubmitButton.defaultProps = { className: sharedFormStyle['btn-submit'] };
