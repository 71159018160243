import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import { Constants, MessageHeader, MessageRecipient, MessageSensitivity, MessagingException, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectDocBody,
  DataObjectDocBodyValue,
  DataObjectDocMetadata,
  DataObjectDocMetadataValue,
  DataObjectMsgBody,
  DataObjectMsgBodyValue,
  DataObjectMsgFolder,
  DataObjectMsgFolderValue,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  IDataObject,
  LinkedMessageListItem,
  MessageFolderItemCount,
  MessageFolderListItem,
  MessageTimestampRecord,
  UserMessageFolderItem
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import sanitizeHtml from 'sanitize-html';
import { AppThunk } from '../..';
import { MessageFlags } from '../../../app/messaging/utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { contactListItemToMessageRecipient } from '../../../utils/contact-list';
import { readBlobAsDataUri } from '../../../utils/read-file-as-data-uri';
import { EMPTY_ARRAY } from '../../constants';
import { DataObjectCache } from '../../data-objects-slice/cache';
import * as DataObjectSelectors from '../../selectors/data-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { ActionInitParams } from '../base-action';
import { SANITIZER_OPTIONS } from '../constants';
import { AUTH_STATE_SAVE_MESSAGE_AS_DRAFT } from '../constants/auth-state-identifier';
import { isValidMessageSensitivity } from './base-messaging-action';
import { createMessageFolderExtensionAction } from './create-message-folder-extension-action';
import { updateMessageFolderAction } from './update-msg-folder-action';

interface Payload extends Omit<MessagingActionPayload.SaveAsDraft, 'flags' | 'sensitivity'> {
  readonly flags: Required<NonNullable<MessagingActionPayload.SaveAsDraft['flags']>>;
  readonly sensitivity: MessageSensitivity;
}

interface State extends AuthenticatedActionState {
  batchUpdateAuthState: string;
  documentList: Array<Required<MessageFolderListItem>['documentList'][0]>;
  draftsFolderContents: DataObjectMsgFolderValue;
  draftsFolderObject: IDataObject<DataObjectMsgFolderValue>;
  draftsMsgFolder: UserMessageFolderItem;
  dtServer: Date;
  folderItemDraftMessage: MessageFolderListItem;
  idsClaim: string;
  idRecord: Api.GetIdsResponseData['ids'];
  msgBodyId: number;
  msgMetadataId: number;
  recipientList: MessageHeader['recipientList'];
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

class SaveAsDraftAction extends AuthenticatedAction<Payload, State> {
  public constructor({ payload, ...params }: ActionInitParams<Payload>) {
    super({
      ...params,
      payload: {
        ...payload,
        flags: {
          ...payload.flags,
          billable: payload.flags?.billable === true,
          doNotReply: payload.flags?.doNotReply === true,
          important: payload.flags?.important === true
        },
        sensitivity: isValidMessageSensitivity(payload.sensitivity) ? payload.sensitivity! : 'normal'
      }
    });

    if (Utils.isNotNil(payload.messageKind) && Utils.isNil(payload.sourceMessage)) {
      throw new MessagingException('Expected <sourceMessage> to not be nil.');
    }
  }

  protected async preExecute() {
    const result = await super.preExecute();

    const { primaryRecipientList, secondaryRecipientList, sender } = this.payload;
    if (sender.id !== this.state.currentUser.id) {
      throw new MessagingException(Constants.Error.E_MESSAGING_FAIL, 'Expected sender to be the current user.');
    }

    this.state.recipientList = primaryRecipientList
      .map(({ keyId, ...contact }) => ({
        entity: { ...contactListItemToMessageRecipient(contact, 'primary'), keyId } as MessageRecipient
      }))
      .concat(
        secondaryRecipientList.map(({ keyId, ...contact }) => ({
          entity: { ...contactListItemToMessageRecipient(contact, 'secondary'), keyId } as MessageRecipient
        }))
      );

    return result;
  }

  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchMessageFolderList();
        this.extractDraftsFolderContents();

        this.initializeRequestBodyAndSuccessPayload();
        await this.markCurrentDraftMsgObjectsAsExpired();

        await this.generateIdSequence();
        await this.generateRequestBody();

        const { batchUpdateAuthState: authState, idsClaim, requestBody } = this.state;
        await this.dispatchBatchUpdateData({ authState, claims: [idsClaim], ...requestBody.build() });

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }

    try {
      await this.dispatchBatchQueryDataSuccess(this.state.successPayload);
    } catch (error) {
      this.logger.warn('Error while manually updating app state:', error);
      /* ignore */
    }

    try {
      await this.dispatch(createMessageFolderExtensionAction({ folderKey: Constants.MessageFolderKey.Drafts }));
    } catch (error) {
      this.logger.warn('Error while creating message folder extension:', error);
      /* ignore */
    }
  }

  private async fetchMessageFolderList(): Promise<void> {
    this.logger.info('Fetching latest message folder data.');

    const {
      currentUser: { id: userId },
      roleAuthClaim: authState
    } = this.state;

    const { serverDateTime } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_FOLDER_LIST, userId }]
    });

    const msgFolderMap = UserObjectSelectors.messageFolderMapSelector(this.getRootState())(/***/);
    const msgFolderItem = msgFolderMap[Constants.MessageFolderKey.Drafts];
    if (Utils.isNil(msgFolderItem) || !DataObjectMsgFolder.isValidId(msgFolderItem.id)) {
      throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_ID);
    }

    await this.dispatchFetchObjects({ authState, dataObjects: { ids: [msgFolderItem.id] } });

    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
    this.state.draftsMsgFolder = msgFolderItem;
  }

  private extractDraftsFolderContents(): void {
    this.logger.info('Extracting drafts folder contents.');

    const dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
    const draftsFolderObject = dataObjectByIdSelector<DataObjectMsgFolderValue>(this.state.draftsMsgFolder.id);
    const draftsFolderContents = DataObjectCache.getValue(draftsFolderObject);
    if (Utils.isNil(draftsFolderObject) || Utils.isNil(draftsFolderContents)) {
      throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_DATA_INVALID);
    }

    this.state.draftsFolderObject = draftsFolderObject;
    this.state.draftsFolderContents = draftsFolderContents;
  }

  private initializeRequestBodyAndSuccessPayload(): void {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] } },
      response: { dataObjects: [], serverDateTime: '' }
    };
  }

  private async markCurrentDraftMsgObjectsAsExpired(): Promise<void> {
    const { messageKind, sourceMessage } = this.payload;
    if (messageKind !== 'draft') return;

    this.logger.info('Adding an expire operation to request body for current draft message objects.');

    const { header: draftMsgMetadataId, body: draftMsgBodyId } = sourceMessage!;
    const { ownerId, requestBody, successPayload } = this.state;

    requestBody.expire([
      { type: process.env.DATA_OBJECT_TYPE_MSG_METADATA, id: draftMsgMetadataId, version: 0, ownerId } as IDataObject<any>,
      { type: process.env.DATA_OBJECT_TYPE_MSG_BODY, id: draftMsgBodyId, version: 0, ownerId } as IDataObject<any>
    ]);

    successPayload.request.dataObjects!.ids.push(draftMsgMetadataId!, draftMsgBodyId!);
    // XXX: because we are not pushing the corresponding data objects in
    // successPayload's response, they will be removed from app state if present
  }

  private async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const { documentList } = this.payload;

    const documentIdList: Array<NonNullable<Api.GetIdsRequestData['ids']['ids']>[0]> = [];
    if (Utils.isNonEmptyArray(documentList)) {
      const { length: count } = documentList.filter((doc) => doc instanceof File);
      if (count > 0) {
        documentIdList.push(
          { type: process.env.DATA_OBJECT_TYPE_DOC_METADATA, count },
          { type: process.env.DATA_OBJECT_TYPE_DOC_BODY, count }
        );
      }
    }

    const query: Api.GetIdsRequestData = {
      authState: this.state.roleAuthClaim,
      state: AUTH_STATE_SAVE_MESSAGE_AS_DRAFT,
      ids: {
        ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_METADATA }, { type: process.env.DATA_OBJECT_TYPE_MSG_BODY }, ...documentIdList]
      }
    };

    const { authState, idsClaim, ids: idRecord } = await this.dispatchFetchIdsByUsage(query);

    this.state.batchUpdateAuthState = authState;
    this.state.idsClaim = idsClaim;
    this.state.idRecord = idRecord;
  }

  private async generateRequestBody(): Promise<void> {
    await this.addInsertOperationsForAttachedDocumentList(); // 301s, 302s
    await this.addInsertOperationForMessageMetadata(); // 201
    await this.addInsertOperationForMessageBody(); // 202

    await this.addUpdateOperationForDraftsMessageFolder(); // 251/252s
  }

  private async addInsertOperationsForAttachedDocumentList(): Promise<void> {
    const { documentList: attachedDocumentList, sender } = this.payload;
    const { auditId, dtServer, idRecord, ownerId, requestBody } = this.state;

    if (!Utils.isNonEmptyArray<NonNullable<Payload['documentList']>>(attachedDocumentList)) {
      return;
    }

    this.logger.info("Adding an insert operation to request body for each attached document's metadata and body.");

    const idSeqDocMetadata = Utils.makeSequence(idRecord[process.env.DATA_OBJECT_TYPE_DOC_METADATA]);
    const idSeqDocBody = Utils.makeSequence(idRecord[process.env.DATA_OBJECT_TYPE_DOC_BODY]);

    this.state.documentList = [];
    for (let index = 0; index < attachedDocumentList.length; index++) {
      const doc = attachedDocumentList[index];

      if (doc instanceof File) {
        const docContents = await readBlobAsDataUri(doc);
        const { value: metadataId } = idSeqDocMetadata.next();

        const metadata: DataObjectDocMetadataValue = { $$formatver: 1, name: doc.name, size: doc.size, docType: `attachment${index + 1}` };
        const metadataObject = await DataObjectDocMetadata.create(metadataId, undefined, 0, metadata, ownerId, sender.id, dtServer);

        const { value: bodyId } = idSeqDocBody.next();
        const body: DataObjectDocBodyValue = { $$formatver: 1, data: docContents };
        const bodyObject = await DataObjectDocBody.create(bodyId, undefined, 0, body, ownerId, sender.id, dtServer);

        for (const obj of [metadataObject, bodyObject]) {
          const keyList = await obj.generateKeysEncryptedFor(auditId);
          keyList.push(obj[Constants.$$CryptographicKey]);
          requestBody.insert(obj);
          requestBody.insert(keyList.filter(Utils.isNotNil));
        }

        this.state.documentList!.push({ metadata: metadataId, body: bodyId, name: doc.name, size: doc.size });
      } else if (!!!doc.deleted) {
        this.state.documentList!.push(Utils.omit(doc, 'deleted'));
      }
    }
  }

  private async addInsertOperationForMessageMetadata(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for message metadata.');

    const { flags, messageKind, sender, sensitivity, sourceMessage, subjectLine } = this.payload;
    const { auditId, documentList, dtServer, ownerId, recipientList, requestBody } = this.state;

    let sentAtUtc = dtServer.getTime();
    if (messageKind === 'draft') {
      let timestamp: number | undefined;
      if (Utils.isNonArrayObjectLike<MessageTimestampRecord>(sourceMessage!.timestamp)) {
        timestamp = sourceMessage!.timestamp.createdAt;
      } else {
        timestamp = sourceMessage!.timestamp;
      }

      if (Utils.isInteger(timestamp)) sentAtUtc = timestamp;
    }

    const [id] = this.state.idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA];
    const value: DataObjectMsgMetadataValue = {
      $$formatver: 1,
      billable: flags.billable || undefined,
      documentList,
      importance: flags.important ? 'high' : 'normal',
      messageForm: { name: Constants.MessageFormName.Default },
      recipientList: recipientList.map(({ entity: { keyId, emailAddress, languagePreference, ...entity } }) => ({
        entity
      })),
      readReceiptId: 0,
      replyTo: flags.doNotReply ? EMPTY_ARRAY : undefined,
      sender,
      sensitivity,
      sentAtUtc,
      subjectLine: subjectLine.trim()
    };

    this.logger.debug({ id, ...value });

    const msgMetadataObject = await DataObjectMsgMetadata.create(id, undefined, 0, value, ownerId, sender.id, dtServer);
    const keyList = await msgMetadataObject.generateKeysEncryptedFor(auditId);
    keyList.push(msgMetadataObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgMetadataObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.msgMetadataId = id;
  }

  private async addInsertOperationForMessageBody(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for message body.');

    const { messageBody, sender } = this.payload;
    const { auditId, dtServer, ownerId, requestBody } = this.state;

    const [id] = this.state.idRecord[process.env.DATA_OBJECT_TYPE_MSG_BODY];
    const value: DataObjectMsgBodyValue = {
      $$formatver: 1,
      ...messageBody,
      data: sanitizeHtml(messageBody.data, SANITIZER_OPTIONS)
    };
    this.logger.debug({ id, data: value });

    const msgBodyObject = await DataObjectMsgBody.create(id, undefined, 0, value, ownerId, sender.id, dtServer);
    const keyList = await msgBodyObject.generateKeysEncryptedFor(auditId);
    keyList.push(msgBodyObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgBodyObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.msgBodyId = id;
  }

  private async addUpdateOperationForDraftsMessageFolder(): Promise<void> {
    this.logger.info('Adding an update operation to request body for drafts message folder.');

    const { flags, messageKind, sensitivity, sourceMessage: sourceMsg, subjectLine: subject } = this.payload;
    const { dtServer, documentList, msgBodyId, msgMetadataId, recipientList: recipients, requestBody, successPayload } = this.state;

    const recipientList = recipients.map(({ entity: { id, type } }) => ({ id, type }));
    let linkedMessageList: MessageFolderListItem['linkedMessageList'];
    if (messageKind === 'draft') {
      const { linkedMessageList: savedLinkedMessageList } = sourceMsg!;
      if (Utils.isArray<LinkedMessageListItem>(savedLinkedMessageList) && savedLinkedMessageList.length === 1) {
        const [linkedMsg] = savedLinkedMessageList;
        if (linkedMsg.rel === 'forwardOf' || linkedMsg.rel === 'replyOf') {
          // recipient list could've been changed from the time message was
          // saved as a draft
          linkedMessageList = [{ ...linkedMsg, recipientList }];
        }
      }
    } else if (Utils.isNotNil(messageKind)) {
      const sender = { id: this.payload.sender.id, type: this.payload.sender.type };
      const sourceMessage = { body: sourceMsg!.body, header: sourceMsg!.header };

      if (messageKind === 'forward') {
        linkedMessageList = [{ recipientList, rel: 'forwardOf', sender, sourceMessage }];
      } else if (messageKind === 'reply' || messageKind === 'replyAll') {
        linkedMessageList = [{ recipientList, rel: 'replyOf', sender, sourceMessage }];
      }
    }

    this.state.folderItemDraftMessage = {
      body: msgBodyId,
      documentList,
      entity: recipients.map(({ entity: recipient }) =>
        recipient.type === 'group' ? recipient.groupName : Utils.joinPersonName(recipient)
      ),
      extraData: null,
      header: msgMetadataId,
      flags: {
        billable: flags.billable || undefined,
        importance: flags.important ? 'high' : 'normal',
        doNotReply: flags.doNotReply || undefined,
        markedAsRead: true
      },
      linkedMessageList,
      messageForm: { name: Constants.MessageFormName.Default },
      sensitivity,
      snippet: null,
      subject,
      timestamp: messageKind === 'draft' ? sourceMsg!.timestamp : { createdAt: dtServer.getTime(), firstReadAt: dtServer.getTime() }
    };

    await this.dispatch(
      updateMessageFolderAction({
        folderKey: Constants.MessageFolderKey.Drafts,
        requestBody,
        successPayload,
        applyUpdate: this.applyDraftsMessagesFolderUpdate.bind(this)
      })
    );
  }

  private applyDraftsMessagesFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    itemCount: MessageFolderItemCount,
    { folderOrExtId, folderOrExtType }: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { flags, messageKind, sourceMessage } = this.payload;
    const { draftsMsgFolder, folderItemDraftMessage } = this.state;

    const isMessageKindDraft = messageKind === 'draft';

    if (folderOrExtId === draftsMsgFolder.id) {
      ++itemCount.all.total;
      itemCount.important.total += +flags.important;
      itemCount.billable.total += +flags.billable;

      if (!isMessageKindDraft) {
        folderData.unshift(folderItemDraftMessage);
        return { updated: true, done: true };
      }
    }

    if (!isMessageKindDraft) {
      throw new MessagingException(`Expected <messageKind> to be <draft>, was <${messageKind}>.`);
    }

    const folderOrExt = `folder${folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const { header: msgMetadataId, body: msgBodyId } = sourceMessage!;
    this.logger.info(`Locating item with metadata ID <${msgMetadataId}> and body ID <${msgBodyId}> in ${folderOrExt} <${folderOrExtId}>.`);
    const index = folderData.findIndex(({ header, body }) => header === msgMetadataId && body === msgBodyId);
    if (index === -1) return;

    folderData[index] = folderItemDraftMessage;

    const { hasReminder, isBillable, isImportant, isMarkedAsRead } = MessageFlags(folderItemDraftMessage);
    --itemCount.all.total;
    itemCount.all.unread -= +!isMarkedAsRead;
    itemCount.important.total -= +isImportant;
    itemCount.important.unread -= +(isImportant && !isMarkedAsRead);
    itemCount.reminder.total -= +hasReminder;
    itemCount.reminder.unread -= +(hasReminder && !isMarkedAsRead);
    itemCount.billable.total -= +isBillable;
    itemCount.billable.unread -= +(isBillable && !isMarkedAsRead);

    return { updated: true, done: true };
  }
}

export const saveAsDraftAction = (payload: MessagingActionPayload.SaveAsDraft): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'saveAsDraftAction:');

    const action = new SaveAsDraftAction({ payload: payload as Payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
