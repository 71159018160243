import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Form.SendCaregiverContactInvitation = {
  fieldsetContact: {
    formField: {
      cellNumber: {
        // Translators: Label for the Cell Number input on Caregiver form
        label: gettext('account', 'cellNumber', 'sendCaregiverAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error displayed when Cell Number input on Caregiver \
          // form contains invalid characters
          patternMismatch: gettext('account', 'cellNumberPattern', 'sendCaregiverAccountInvitationFormError'),
          // Translators: Error displayed when Cell Number input on Caregiver \
          // form is left blank
          valueMissing: gettext('account', 'cellNumberRequired', 'sendCaregiverAccountInvitationFormError')
        }
      },
      emailAddress: {
        // Translators: Label for the Email Address input on Caregiver form
        label: gettext('account', 'emailAddress', 'sendCaregiverAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error displayed when Email Address input on \
          // Caregiver form doesn't satisfy minimum/maximum length requirement
          length: gettext('account', 'emailAddressLength', 'sendCaregiverAccountInvitationFormError'),
          // Translators: Error displayed when Email Address input on Caregiver \
          // form contains invalid characters
          patternMismatch: gettext('account', 'emailAddressPattern', 'sendCaregiverAccountInvitationFormError'),
          // Translators: Error displayed when Email Address input on Caregiver \
          // form is left blank
          valueMissing: gettext('account', 'emailAddressRequired', 'sendCaregiverAccountInvitationFormError')
        }
      }
    }
  },
  fieldsetName: {
    formField: {
      firstName: {
        // Translators: Label for the First Name input on Caregiver form
        label: gettext('account', 'firstName', 'sendCaregiverAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error displayed when First Name input on Caregiver \
          // form doesn't satisfy minimum/maximum length requirement
          length: gettext('account', 'firstNameLength', 'sendCaregiverAccountInvitationFormError'),
          // Translators: Error displayed when First Name input on Caregiver \
          // form contains invalid characters
          patternMismatch: gettext('account', 'firstNamePattern', 'sendCaregiverAccountInvitationFormError'),
          // Translators: Error displayed when First Name input on Caregiver \
          // form is left blank
          valueMissing: gettext('account', 'firstNameRequired', 'sendCaregiverAccountInvitationFormError')
        }
      },
      lastName: {
        // Translators: Label for the Last Name input on Caregiver form
        label: gettext('account', 'lastName', 'sendCaregiverAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error displayed when Last Name input on Caregiver \
          // form doesn't satisfy minimum/maximum length requirement
          length: gettext('account', 'lastNameLength', 'sendCaregiverAccountInvitationFormError'),
          // Translators: Error displayed when Last Name input on Caregiver
          // form contains invalid characters
          patternMismatch: gettext('account', 'lastNamePattern', 'sendCaregiverAccountInvitationFormError'),
          // Translators: Error displayed when Last Name input on Caregiver \
          // form is left blank
          valueMissing: gettext('account', 'lastNameRequired', 'sendCaregiverAccountInvitationFormError')
        }
      }
    }
  },
  fieldsetNotifyBy: {
    formField: {
      notifyBy: {
        // Translators: Label for the Notify By input
        label: 'Notify by',
        options: [
          {
            codedValue: 'both',
            // Translators: Label for the Both option of Notify By \
            // input
            label: gettext('account', 'both', 'sendCaregiverAccountInvitationFormNotifyByOptionLabel')
          },
          {
            codedValue: 'email',
            // Translators: Label for the Email message option of Notify By \
            // input
            label: gettext('account', 'email', 'sendCaregiverAccountInvitationFormNotifyByOptionLabel')
          },
          {
            codedValue: 'sms',
            // Translators: Label for the Text message option of Notify By \
            // input
            label: gettext('account', 'sms', 'sendCaregiverAccountInvitationFormNotifyByOptionLabel')
          }
        ]
      }
    }
  },
  fieldsetRelationship: {
    formField: {
      relationship: {
        // Translators: Label for the Relationship input on Caregiver form
        label: gettext('account', 'relationship', 'sendCaregiverAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error displayed when Relationship input on Caregiver \
          // form is left blank
          valueMissing: gettext('account', 'relationshipRequired', 'sendCaregiverAccountInvitationFormError')
        }
      }
    }
  }
};

export default i18n;
