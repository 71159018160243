import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['memberRole'] = {
  // Translators: Label for the Admin Physician member role
  admin: gettext('global', 'adminPhysician', 'memberRole'),
  // Translators: Label for the Admin Messaging User member role
  adminMessagingUser: gettext('global', 'adminMessagingUser', 'memberRole'),
  // Translators: Label for the Admin Staff member role
  adminStaff: gettext('global', 'adminStaff', 'memberRole'),
  // Translators: Label for the Basic Physician member role
  basicPhysician: gettext('global', 'basicPhysician', 'memberRole'),
  // Translators: Label for the Caregiver member role
  caregiver: gettext('global', 'caregiver', 'memberRole'),
  // Translators: Label for the Messaging User member role
  messagingUser: gettext('global', 'messagingUser', 'memberRole'),
  // Translators: Label for the Guest member role
  patient: gettext('global', 'guest', 'memberRole'),
  // Translators: Label for the Junior Physician member role
  physician: gettext('global', 'juniorPhysician', 'memberRole'),
  // Translators: Label for the Senior Physician member role
  'physician+': gettext('global', 'seniorPhysician', 'memberRole'),
  // Translators: Label for the SigMail Admin member role
  sigMailAdmin: gettext('global', 'sigMailAdmin', 'memberRole'),
  // Translators: Label for the Staff member role
  staff: gettext('global', 'staff', 'memberRole')
};

export default i18n;
