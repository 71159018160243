import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.View.CardiacIntake = {
  action: {
    cancel: {
      label: gettext('health-data', 'cancel', 'cardiacIntakeViewActionLabel')
    },
    submit: {
      label: gettext('health-data', 'submit', 'cardiacIntakeViewActionLabel')
    }
  },
  heading: gettext('health-data', 'heading', 'cardiacIntakeView', 'safe-html'),
  stepper: {
    fieldsetArrhythmia: gettext('health-data', 'stepperArrhythmia', 'cardiacIntakeView'),
    fieldsetDyspnea: gettext('health-data', 'stepperDyspnea', 'cardiacIntakeView'),
    fieldsetLegPain: gettext('health-data', 'stepperLegPain', 'cardiacIntakeView'),
    fieldsetPain: gettext('health-data', 'stepperPain', 'cardiacIntakeView'),
    fieldsetSyncope: gettext('health-data', 'stepperSyncope', 'cardiacIntakeView')
  },
  stepperLabel: gettext('health-data', 'stepperLabel', 'cardiacIntakeView')
};

export default i18n;
