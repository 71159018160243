import { createSlice } from '@reduxjs/toolkit';
import { StoreStateReminderNotification } from '@sigmail/app-state';
import { closeReminderNotificationReducer } from './close-reminder-notification';
import { dismissReminderNotificationReducer } from './dismiss-reminder-notification';
import { queueReminderNotificationReducer } from './queue-reminder-notification';
import { processReminderNotificationReducer } from './process-reminder-notification';
import { addEventToDismissedListReducer } from './add-event-to-dismissed-list';

export const INITIAL_STATE: StoreStateReminderNotification = {
  active: null,
  eventOnGoing: false,
  dismissedList: [],
  queueList: []
};

const reminderNotificationSlice = createSlice({
  name: 'reminderNotification',
  initialState: INITIAL_STATE,
  reducers: {
    addEventToDismissedList: addEventToDismissedListReducer,
    closeReminderNotification: closeReminderNotificationReducer,
    dismissReminderNotification: dismissReminderNotificationReducer,
    processReminderNotification: processReminderNotificationReducer,
    queueReminderNotification: queueReminderNotificationReducer,
    toggleEventOnGoing: (state) => ({ ...state, eventOnGoing: !state.eventOnGoing })
  }
});

export const {
  addEventToDismissedList,
  closeReminderNotification,
  dismissReminderNotification,
  processReminderNotification,
  queueReminderNotification,
  toggleEventOnGoing
} = reminderNotificationSlice.actions;
export const { reducer: reminderNotificationReducer } = reminderNotificationSlice;
