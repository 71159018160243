import React from 'react';
import { DisplayMode } from 'sigmail';
import { DEFAULT_CONTEXT_VALUE } from '../context';

const DISPLAY_MODE_LIST: ReadonlyArray<DisplayMode> = [
  'browser',
  'fullscreen',
  'minimal-ui',
  'standalone',
  'window-controls-overlay'
];

export const useDisplayModeQuery = (): DisplayMode => {
  const [displayMode, setDisplayMode] = React.useState(DEFAULT_CONTEXT_VALUE.displayMode);

  React.useEffect(() => {
    const onChange = (mode: DisplayMode, e: MediaQueryListEvent) => {
      if (e.matches) setDisplayMode(mode);
    };

    const cleanupCallbackList = DISPLAY_MODE_LIST.map((mode) => {
      const listener = onChange.bind(null, mode);
      const media = window.matchMedia(`(display-mode: ${mode})`);
      media.addEventListener('change', listener);
      return () => media.removeEventListener('change', listener);
    });

    return () => {
      cleanupCallbackList.forEach((removeListener) => removeListener());
    };
  }, []);

  return displayMode;
};
