import { ApiActionPayload } from '@sigmail/app-state';
import { PartialRecord, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedCollectionObject,
  ApiFormattedNotificationObject,
  ApiFormattedUserCredentials,
  ApiFormattedUserObject
} from '@sigmail/objects';
import type { Writeable } from 'sigmail';
import { AppThunk } from '..';
import { EMPTY_ARRAY } from '../constants';
import { batchQueryDataAction } from './batch-query-data-action';

type Payload = ApiActionPayload.FetchObjects;

export type FetchObjectsActionResult = Exclude<NonNullable<Payload['responseOnError']>, 'empty'>;

type WriteableFetchObjectsActionResult = Writeable<
  {
    [K in keyof FetchObjectsActionResult]: FetchObjectsActionResult[K] extends ReadonlyArray<infer I>
      ? Array<I>
      : FetchObjectsActionResult[K];
  }
>;

const LOGGER = getLoggerWithPrefix('Action', 'fetchObjects:');

const BY_TYPE_KEY_LIST: ReadonlyArray<Extract<keyof Payload, `${string}ByType`>> = [
  'collectionObjectsByType',
  'keysByType',
  'notificationObjectsByType',
  'userCredentialsByType',
  'userObjectsByType'
];

const IDS_KEY_LIST: ReadonlyArray<Extract<keyof Payload, `${string}Objects`>> = [
  'dataObjects',
  'notificationObjects',
  'userCredentialsObjects',
  'userObjects'
];

const arrayLength = (array?: unknown): number => Utils.arrayOrDefault(array, EMPTY_ARRAY).length;

const EMPTY_RESULT: WriteableFetchObjectsActionResult = {
  claims: EMPTY_ARRAY,
  collectionObjectList: EMPTY_ARRAY,
  credentialList: EMPTY_ARRAY,
  dataObjectList: EMPTY_ARRAY,
  keyList: EMPTY_ARRAY,
  notificationObjectList: EMPTY_ARRAY,
  serverDateTime: new Date().toISOString(),
  userObjectList: EMPTY_ARRAY
};

export const fetchObjectsAction = ({
  accessToken,
  cache,
  expectedCount,
  logger,
  responseOnError,
  ...query
}: ApiActionPayload.FetchObjects): AppThunk<Promise<FetchObjectsActionResult>> => async (dispatch): Promise<FetchObjectsActionResult> => {
  if (Utils.isNil(logger)) {
    logger = LOGGER;
    logger.info('== BEGIN ==');
  }

  try {
    if (Utils.isUndefined(expectedCount)) expectedCount = {};

    const result: WriteableFetchObjectsActionResult = { ...EMPTY_RESULT };
    const response = await dispatch(batchQueryDataAction({ accessToken, cache, query }));

    if (expectedCount !== null && logger.getLevel() <= 3 /* log.levels.WARN */) {
      expectedCount = {
        claims: expectedCount.claims === null ? null : Utils.numberOrDefault(expectedCount.claims),
        ...BY_TYPE_KEY_LIST.reduce<PartialRecord<typeof BY_TYPE_KEY_LIST[0], number | null>>((count, key) => {
          count[key] = expectedCount![key] === null ? null : arrayLength(query[key]);
          return count;
        }, {}),
        ...IDS_KEY_LIST.reduce<PartialRecord<typeof IDS_KEY_LIST[0], number | null>>((count, key) => {
          count[key] = expectedCount![key] === null ? null : arrayLength(query[key]?.ids);
          return count;
        }, {})
      };

      for (const key of Object.keys(expectedCount) as Array<keyof typeof expectedCount>) {
        const expected = expectedCount[key];
        if (expected === null) continue;

        const actual = arrayLength(response[key]);
        if (expected !== actual) {
          logger.warn(`Expected <${key}> to be of size ${expected}; was ${actual}.`);
        }
      }
    }

    let dtServer = new Date(Utils.trimOrDefault(response.serverDateTime));
    if (!Utils.isValidDate(dtServer)) {
      logger.warn('Server date-time was either missing or invalid.');
      dtServer = new Date();
    }

    const {
      claims,
      collectionObjectsByType: collectionsByType,
      dataObjects,
      keysByType,
      notificationObjects: notifications,
      notificationObjectsByType: notificationsByType,
      userCredentialsByType: credentialsByType,
      userCredentialsObjects: credentials,
      userObjects,
      userObjectsByType
    } = response;

    result.claims = Utils.arrayOrDefault<string>(claims);
    result.collectionObjectList = Utils.arrayOrDefault<ApiFormattedCollectionObject>(collectionsByType);
    result.credentialList = Utils.arrayOrDefault<ApiFormattedUserCredentials>(credentials).concat(Utils.arrayOrDefault(credentialsByType));
    result.dataObjectList = Utils.arrayOrDefault(dataObjects);
    result.keyList = Utils.arrayOrDefault(keysByType);
    result.notificationObjectList = Utils.arrayOrDefault<ApiFormattedNotificationObject>(notifications).concat(
      Utils.arrayOrDefault(notificationsByType)
    );
    result.serverDateTime = dtServer.toISOString();
    result.userObjectList = Utils.arrayOrDefault<ApiFormattedUserObject>(userObjects).concat(Utils.arrayOrDefault(userObjectsByType));

    return result;
  } catch (error) {
    if (responseOnError === 'empty') {
      return Object.freeze<FetchObjectsActionResult>(EMPTY_RESULT);
    } else if (Utils.isNonArrayObjectLike<FetchObjectsActionResult>(responseOnError)) {
      return responseOnError;
    }
    throw error;
  } finally {
    if (logger !== LOGGER) logger.info('== END ==');
  }
};
