import { MessagingActionPayload } from '@sigmail/app-state';
import { Constants, GroupMessageFolderKey, MessageSensitivity, MessagingException, UserMessageFolderKey, Utils } from '@sigmail/common';
import {
  DataObjectMsgFolder,
  FolderItem,
  INotificationObject,
  NotificationObjectIncomingMessage,
  NotificationObjectIncomingMessageValue
} from '@sigmail/objects';
import * as GroupObjectSelectors from '../../selectors/group-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';
import { GROUP_MESSAGE_FOLDER_PREFIX } from '../constants';

// Unique collection of all top-level message folder keys
const MESSAGE_FOLDER_KEY_SET: ReadonlySet<string> = new Set<UserMessageFolderKey | GroupMessageFolderKey>([
  Constants.MessageFolderKey.Inbox,
  Constants.MessageFolderKey.GroupInbox,
  Constants.MessageFolderKey.Sent,
  Constants.MessageFolderKey.Drafts
]);

export const isMessageSensitivityConfidential = (value?: any): value is Extract<MessageSensitivity, 'confidential'> =>
  value === 'confidential';
export const isMessageSensitivityNormal = (value?: any): value is Extract<MessageSensitivity, 'normal'> => value === 'normal';
export const isMessageSensitivityPersonal = (value?: any): value is Extract<MessageSensitivity, 'personal'> => value === 'personal';
export const isMessageSensitivityPrivate = (value?: any): value is Extract<MessageSensitivity, 'private'> => value === 'private';

// NOTE: <confidential> and <personal> are commented out because currently we
// only support message with <normal> and <private> sensitivity
export const isValidMessageSensitivity = (value?: any): value is MessageSensitivity =>
  // isMessageSensitivityConfidential(value) ||
  isMessageSensitivityNormal(value) ||
  // isMessageSensitivityPersonal(value) ||
  isMessageSensitivityPrivate(value);

export abstract class BaseMessagingAction<
  P,
  S extends AuthenticatedActionState = AuthenticatedActionState,
  R = void
> extends AuthenticatedAction<P, S, R> {
  protected async findMessageFolder(
    folderKey: string,
    parentFolderKey?: MessagingActionPayload.ParentMessageFolderKey
  ): Promise<FolderItem> {
    this.logger.info('Locating message folder.', `(folderKey=${folderKey}, parentFolderKey=${String(parentFolderKey)})`);

    const isGroupFolderKey =
      folderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX) ||
      (Utils.isString(parentFolderKey) && parentFolderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX));

    const msgFolderMap = isGroupFolderKey
      ? GroupObjectSelectors.messageFolderMapSelector(this.getRootState())()
      : UserObjectSelectors.messageFolderMapSelector(this.getRootState())();

    let msgFolder: FolderItem | undefined;
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      msgFolder = undefined;

      if (MESSAGE_FOLDER_KEY_SET.has(folderKey)) {
        msgFolder = msgFolderMap[folderKey];
      } else if (parentFolderKey === Constants.MessageFolderKey.Inbox || parentFolderKey === Constants.MessageFolderKey.GroupInbox) {
        const msgFolderChildMap = msgFolderMap[parentFolderKey]?.children;
        msgFolder = msgFolderChildMap?.[folderKey];
      }

      if (DataObjectMsgFolder.isValidId(msgFolder?.id)) {
        break;
      } else if (attempt === MAX_ATTEMPTS) {
        throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_ID);
      }

      await this.fetchMessageFolderList({ user: true, group: isGroupFolderKey });
    }

    return msgFolder!;
  }

  protected async fetchMessageFolderList(options?: { user?: boolean; group?: boolean }): Promise<void> {
    this.logger.info('Fetching the latest message folder list.');

    const {
      roleAuthClaim: authState,
      currentUser: { id: userId },
      activeGroupId
    } = this.state;

    const opts = Utils.defaults({}, options, { user: true, group: true });
    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        opts.group === true ? { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC } : undefined,
        opts.user === true ? { userId, type: process.env.USER_OBJECT_TYPE_FOLDER_LIST } : undefined
      ].filter(Utils.isNotNil)
    };

    if (query.userObjectsByType!.length === 0) {
      return Promise.resolve();
    }

    await this.dispatchFetchObjects(query);

    if (opts.group === true) {
      await this.dispatchFetchObjects({
        authState,
        userObjectsByType: [{ userId: activeGroupId, type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST }]
      });
    }
  }

  protected async createIncomingMessageNotificationObjects(data: {
    body: number;
    dtServer: Date;
    header: number;
    idSequence: Generator<number, number>;
    recipientIdList: ReadonlyArray<number>;
    senderId: number;
  }): Promise<Array<INotificationObject<NotificationObjectIncomingMessageValue>>> {
    const { header, body, recipientIdList, idSequence, senderId, dtServer } = data;

    const value: NotificationObjectIncomingMessageValue = { $$formatver: 1, header, body };
    const promiseList = recipientIdList.map((recipientId) => {
      const { value: id } = idSequence.next();
      return NotificationObjectIncomingMessage.create(id, undefined, 0, value, recipientId, senderId, 0, dtServer);
    });

    return Promise.all(promiseList);
  }
}
