import type {
  EventLogRecordCodeAccountSetup,
  EventLogRecordCodeChangePassword,
  EventLogRecordCodeChangeUsername,
  EventLogRecordCodeConsultationReceived,
  EventLogRecordCodeConsultationResponded,
  EventLogRecordCodeConsultationSent,
  EventLogRecordCodeMessageAssigned,
  EventLogRecordCodeMessageCategoryChanged,
  EventLogRecordCodeMessageForwarded,
  EventLogRecordCodeMessageMoved,
  EventLogRecordCodeMessageRecalled,
  EventLogRecordCodeMessageReceived,
  EventLogRecordCodeMessageResponded,
  EventLogRecordCodeMessageSent,
  EventLogRecordCodeMessageSentToEMR,
  EventLogRecordCodeMessageSentToHRM,
  EventLogRecordCodeNil,
  EventLogRecordCodeReferralAccepted,
  EventLogRecordCodeReferralDeclined,
  EventLogRecordCodeReferralReceived,
  EventLogRecordCodeReferralSent,
  EventLogRecordCodeRevokeInvitation,
  EventLogRecordCodeSendInvitation,
  EventLogRecordCodeSessionAuth,
  EventLogRecordCodeUpdateMFA,
  EventLogRecordCodeUpdatePreference,
  EventLogRecordCodeUpdateProfile
} from '../types';
import { isNil as _isNil } from '../utils';

export const isNil = (code?: unknown): code is EventLogRecordCodeNil => _isNil(code);

export const SessionAuth: EventLogRecordCodeSessionAuth = 1;

export const AccountSetup: EventLogRecordCodeAccountSetup = 51;
export const ChangePassword: EventLogRecordCodeChangePassword = 52;
export const ChangeUsername: EventLogRecordCodeChangeUsername = 53;
export const RevokeInvitation: EventLogRecordCodeRevokeInvitation = 54;
export const SendInvitation: EventLogRecordCodeSendInvitation = 55;
export const UpdateMFA: EventLogRecordCodeUpdateMFA = 56;
export const UpdatePreference: EventLogRecordCodeUpdatePreference = 57;
export const UpdateProfile: EventLogRecordCodeUpdateProfile = 58;

export const ConsultationReceived: EventLogRecordCodeConsultationReceived = 101;
export const ConsultationResponded: EventLogRecordCodeConsultationResponded = 102;
export const ConsultationSent: EventLogRecordCodeConsultationSent = 103;

/** @deprecated use {@link ConsultationReceived} */
export const EConsultReceived = ConsultationReceived;
/** @deprecated use {@link ConsultationResponded} */
export const EConsultResponded = ConsultationResponded;
/** @deprecated use {@link ConsultationSent} */
export const EConsultSent = ConsultationSent;

export const MessageAssigned: EventLogRecordCodeMessageAssigned = 202;
export const MessageCategoryChanged: EventLogRecordCodeMessageCategoryChanged = 203;
export const MessageForwarded: EventLogRecordCodeMessageForwarded = 204;
export const MessageMoved: EventLogRecordCodeMessageMoved = 205;
export const MessageRecalled: EventLogRecordCodeMessageRecalled = 206;
export const MessageReceived: EventLogRecordCodeMessageReceived = 207;
export const MessageResponded: EventLogRecordCodeMessageResponded = 208;
export const MessageSent: EventLogRecordCodeMessageSent = 209;
export const MessageSentToEMR: EventLogRecordCodeMessageSentToEMR = 210;
export const MessageSentToHRM: EventLogRecordCodeMessageSentToHRM = 211;

export const ReferralAccepted: EventLogRecordCodeReferralAccepted = 151;
export const ReferralDeclined: EventLogRecordCodeReferralDeclined = 152;
export const ReferralReceived: EventLogRecordCodeReferralReceived = 153;
export const ReferralSent: EventLogRecordCodeReferralSent = 154;
