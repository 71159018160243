import { Utils } from '@sigmail/common';

/** @public */
export interface Base64EncoderParams {
  ALPHA: string;
  PAD_CHAR: string;
}

const DEFAULT_PARAMS: Base64EncoderParams = {
  // accept either '+/' or url safe '-_' as the last two characters
  ALPHA: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/-_',

  // pad characters are ignored on decode, never added on encode
  PAD_CHAR: '='
};

/** @public */
export function decode(value: string, params?: Partial<Base64EncoderParams>): Uint8Array {
  const { ALPHA, PAD_CHAR } = Utils.defaults({}, params, DEFAULT_PARAMS);

  let { length: N } = value;
  if (N === 0) return new Uint8Array(0);

  while (N > 0 && value.charAt(N - 1) === PAD_CHAR) {
    N -= 1;
  }

  // this is the exact length of the result
  const result = new Uint8Array(Math.trunc((N * 6) / 8));
  let bits = 0;
  let nb = 0;
  let j = 0;
  for (let i = 0; i < N; ++i) {
    const ch = value.charAt(i);
    let by = ALPHA.indexOf(ch);
    if (by > 0x3f) {
      by = 0x3e + (by & 0x01); // treat all char pairs after end as same as +/
    }
    bits = ((bits & 0xff) << 6) | by;
    nb += 6;
    if (nb >= 8) {
      result[j] = (bits >> (nb - 8)) & 0xff;
      j += 1;
      nb -= 8;
    }
  }
  return result;
}

/** @public */
export function encode(value: Uint8Array, params?: Partial<Base64EncoderParams>): string {
  const { ALPHA } = Utils.defaults({}, params, DEFAULT_PARAMS);

  let result = '';
  let bits = 0;
  let nb = 0;

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let i = 0; i < value.length; ++i) {
    const byte = value[i]! & 0xff;

    bits = ((bits & 0xff) << 8) | byte;
    nb += 8;

    while (nb >= 6) {
      nb -= 6;
      result += ALPHA.charAt((bits >> nb) & 0x3f);
    }
  }

  // encode last character, if any (no padding)
  if (nb > 0) {
    result += ALPHA.charAt((bits << (6 - nb)) & 0x3f);
  }

  return result;
}
