import { Constants, IAppUserGroup, MessagingException, ReadonlyMessageBodyReferral, Utils, ValueObject } from '@sigmail/common';
import {
  CryptographicKeyPublic,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadataValue,
  IUserObject,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import * as UserObjectSelectors from '../../../selectors/user-object';
import { FetchObjectsRequestData } from '../../base-action';
import { AUTH_STATE_LOG_EVENT, AUTH_STATE_SEND_REFERRAL } from '../../constants/auth-state-identifier';
import { logEventAction } from '../../log-event-action';
import { BaseSendMessageAction } from './base';

export class SendReferralMessageAction extends BaseSendMessageAction {
  public constructor(params: ConstructorParameters<typeof BaseSendMessageAction>[0]) {
    super(params);

    const { recipientList } = params.payload;
    if (recipientList.length !== 1) {
      throw new MessagingException('Invalid payload; expected at most one recipient entry.');
    }
  }

  /** @override */
  protected validateMessageFormName(): void {
    const { messageFormName } = this.payload;
    if (!Utils.isMessageFormNameReferral(messageFormName)) {
      throw new MessagingException(`Expected message form name to be <${Constants.MessageFormName.Referral}>; was <${messageFormName}>`);
    }
  }

  /** @override */
  protected async createIdsRequestData(): Promise<Api.GetIdsRequestData> {
    const data = await super.createIdsRequestData();
    return { ...data, state: AUTH_STATE_SEND_REFERRAL };
  }

  /** @override */
  protected async generateRequestBody(): Promise<void> {
    await super.generateRequestBody();

    await this.addInsertOperationsForGuestUserObjectKeys();
  }

  /** @override */
  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    let msgMetadata = await super.createMessageMetadataValue();

    const { referrer, ccReplyToGroupInbox } = (this.payload.messageBody as ReadonlyMessageBodyReferral).messageForm.value;
    if (Utils.isNonEmptyArray<Omit<IAppUserGroup, keyof ValueObject>>(ccReplyToGroupInbox)) {
      msgMetadata = { ...msgMetadata, replyTo: [referrer, ...ccReplyToGroupInbox] };
    }

    return msgMetadata;
  }

  /** @override */
  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    const { sender, messageBody } = this.payload;
    const { roleAuthClaim: authState, dtServer } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_PREFERENCES, userId: sender.id }]
    };

    await this.dispatchFetchObjects(query);

    let expiredAtUtc: number;
    const userPreferences = UserObjectSelectors.preferencesSelector(this.getRootState());
    if (userPreferences.referralExpiry === 0) {
      expiredAtUtc = new Date(9999, 11, 31, 23, 59, 59, 999).getTime();
    } else {
      const dtExpiry = new Date(dtServer.getTime());
      dtExpiry.setDate(dtExpiry.getDate() + userPreferences.referralExpiry);
      expiredAtUtc = dtExpiry.getTime();
    }

    let body = messageBody as ReadonlyMessageBodyReferral;
    body = {
      messageForm: {
        ...body.messageForm,
        value: {
          ...body.messageForm.value,
          expiredAtUtc
        }
      }
    };

    return { $$formatver: 1, ...body };
  }

  private async addInsertOperationsForGuestUserObjectKeys(): Promise<void> {
    this.logger.info('Adding an insert operation each for guest user object keys.');

    const {
      patient: { id: guestUserId },
      referToList
    } = (this.payload.messageBody as ReadonlyMessageBodyReferral).messageForm.value;
    const { batchUpdateClaims, requestBody, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [{ id: guestUserId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }],
      userObjectsByType: [
        process.env.USER_OBJECT_TYPE_PROFILE_BASIC,
        process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
        process.env.USER_OBJECT_TYPE_CONTACT_INFO,
        process.env.USER_OBJECT_TYPE_CONTACT_LIST,
        process.env.USER_OBJECT_TYPE_SERVER_RIGHTS,
        process.env.USER_OBJECT_TYPE_SCHEDULE,
        process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE,
        process.env.USER_OBJECT_TYPE_HEALTH_DATA,
        process.env.USER_OBJECT_TYPE_EVENT_LOG,
        process.env.USER_OBJECT_TYPE_ENCOUNTER,
        process.env.USER_OBJECT_TYPE_CARE_PLANS
      ].map((typeCode) => ({ type: typeCode, userId: guestUserId })),
      expectedCount: { claims: 1 }
    };

    const { claims, keyList, userObjectList } = await this.dispatchFetchObjects(query);
    const keyAvailableClaim = this.findClaim(claims, { name: 'keyAvailable' });
    if (Utils.isNil(keyAvailableClaim)) {
      throw new Api.MalformedResponseException(
        Constants.Error.E_CLAIM_MISSING_OR_INVALID,
        '<keyAvailable> claim is either missing or invalid.'
      );
    }

    batchUpdateClaims.push(keyAvailableClaim);

    const guestUserPublicKeyIndex = this.findKeyIndex(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: guestUserId });
    if (guestUserPublicKeyIndex === -1) {
      throw new MessagingException('Guest user public key could not be found.');
    }

    const serverRightsJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS, userId: guestUserId });
    const objectList = [
      UserObjectSelectors.basicProfileObjectSelector,
      UserObjectSelectors.protectedProfileObjectSelector,
      UserObjectSelectors.contactInfoObjectSelector,
      UserObjectSelectors.contactListObjectSelector,
      () => () =>
        Utils.isNil(serverRightsJson)
          ? undefined
          : (new UserObjectServerRights(serverRightsJson) as IUserObject<UserObjectServerRightsValue>),
      UserObjectSelectors.circleOfCareObjectSelector,
      UserObjectSelectors.scheduleObjectSelector,
      UserObjectSelectors.healthDataObjectSelector,
      UserObjectSelectors.eventLogObjectSelector,
      UserObjectSelectors.encounterObjectSelector,
      UserObjectSelectors.carePlansObjectSelector
    ]
      .map((selector) => selector(this.getRootState())(guestUserId))
      .filter(Utils.isNotNil);

    if (objectList.length !== query.userObjectsByType!.length) {
      throw new MessagingException('One or more user objects could not be found.');
    }

    const guestUserKeyPublic = new CryptographicKeyPublic(keyList[guestUserPublicKeyIndex]);
    for (const referToEntity of referToList) {
      const keyList = await Promise.all(objectList.map((obj) => obj.generateKeysEncryptedFor(referToEntity.id)));
      keyList.forEach((keys) => requestBody.insert(keys.filter(Utils.isNotNil)));
      const keyEncryptedForReferee = await CryptographicKeyPublic.encryptFor(guestUserKeyPublic, referToEntity.id);
      requestBody.insert(keyEncryptedForReferee);
    }
  }

  /** @override */
  protected async addUpdateOperationForUserEventLog(): Promise<void> {
    const { value: referralValue } = (this.payload.messageBody as ReadonlyMessageBodyReferral).messageForm;
    const { roleAuthClaim: authState, currentUser, dtServer, msgMetadataId, msgBodyId, requestBody, successPayload } = this.state;

    const claims = await this.dispatch(
      logEventAction({
        dtServer,
        fetchIds: (count) => {
          return this.dispatchFetchIdsByUsage({
            authState,
            state: AUTH_STATE_LOG_EVENT,
            ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
          });
        },
        logger: this.logger,
        record: this.newEventLogRecordValue(dtServer, Constants.EventLogCode.ReferralSent, msgMetadataId, msgBodyId, referralValue),
        requestBody,
        successPayload,
        userId: currentUser.id,
        userIdType: 'user'
      })
    );

    this.state.batchUpdateClaims.push(...claims);
  }
}
