import type { Http } from '../types';
import { BaseHttpService, logHttpError, logHttpRequest } from './base-http-service';
import { ServiceException as HttpServiceException } from './service-exception';

export class HttpService extends BaseHttpService {
  public override delete(url: string, init?: Http.RequestInit): Promise<Response>;

  public override delete<T = unknown>(url: string, init: Http.RequestInitWithResponseParser<T>): Promise<T>;

  public override async delete(url: string, init?: Http.RequestInit): Promise<unknown> {
    const { responseParser, ...initParams } = { ...(init as Http.RequestInitWithResponseParser), method: 'DELETE' };
    logHttpRequest('delete:', url, initParams);

    try {
      const response = await fetch(url, initParams);
      return typeof responseParser === 'function' ? responseParser(response) : response;
    } catch (error) {
      logHttpError(error);
      throw new HttpServiceException(Response.error());
    }
  }

  public override get(url: string, init?: Http.RequestInit): Promise<Response>;

  public override get<T = unknown>(url: string, init: Http.RequestInitWithResponseParser<T>): Promise<T>;

  public override async get(url: string, init?: Http.RequestInit): Promise<unknown> {
    const { responseParser, ...initParams } = { ...(init as Http.RequestInitWithResponseParser), method: 'GET' };
    logHttpRequest('get:', url, initParams);

    try {
      const response = await fetch(url, initParams);
      return typeof responseParser === 'function' ? responseParser(response) : response;
    } catch (error) {
      logHttpError(error);
      throw new HttpServiceException(Response.error());
    }
  }

  public override post(url: string, body: string, init?: Http.RequestInit): Promise<Response>;

  public override post<T = unknown>(url: string, body: string, init: Http.RequestInitWithResponseParser<T>): Promise<T>;

  public override async post(url: string, body: string, init?: Http.RequestInit): Promise<unknown> {
    const { responseParser, ...initParams } = { ...(init as Http.RequestInitWithResponseParser), method: 'POST', body };
    logHttpRequest('post:', url, initParams);

    try {
      const response = await fetch(url, initParams);
      return typeof responseParser === 'function' ? responseParser(response) : response;
    } catch (error) {
      logHttpError(error);
      throw new HttpServiceException(Response.error());
    }
  }
}
