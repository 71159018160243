import { Utils } from '@sigmail/common';
import { SchedulingI18n } from '@sigmail/i18n';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { withTranslation } from '../../../i18n';
import { I18N_NS_GLOBAL, I18N_NS_SCHEDULING } from '../../../i18n/config/namespace-identifiers';
import globalI18n from '../../../i18n/global';
import schedulingI18n from '../../../i18n/scheduling';
import { ErrorMessageSnackbar } from '../../shared/error-message-snackbar.component';
import { Context } from './meeting-room.context';

export interface Props {
  children?: React.ReactNode;
  onError?: (error: any) => any;
}

interface ComponentProps extends Props, WithTranslation {}

interface State {
  snackbarMessage: string | null;
}

const { errorNameToMsgMap } = schedulingI18n;

class MeetingRoomErrorSnackbarComponent extends React.PureComponent<ComponentProps, State> {
  public static contextType = Context;
  context!: React.ContextType<typeof Context>;

  public constructor(props: ComponentProps) {
    super(props);

    this.state = {
      snackbarMessage: null
    };

    this.resetSnackbar = this.resetSnackbar.bind(this);
  }

  public componentDidCatch(error: any): void {
    const { t } = this.props;
    const { disconnect } = this.context;

    let snackbarMessage = t(globalI18n.errorOccurredMessageGeneric);
    if (Utils.isNonArrayObjectLike<Error>(error) && Utils.isString(error.name)) {
      const errorMessageI18n = errorNameToMsgMap[error.name as keyof SchedulingI18n.ErrorMessage];
      if (Utils.isString(errorMessageI18n)) {
        snackbarMessage = t(errorMessageI18n);
      }
    }

    this.setState((_, { onError }) => {
      if (typeof onError === 'function') {
        setTimeout(onError, undefined, error);
      }
      return { snackbarMessage };
    }, disconnect);
  }

  public render(): React.ReactNode {
    const { t, children } = this.props;
    const { snackbarMessage } = this.state;

    return (
      <React.Fragment>
        <ErrorMessageSnackbar
          closeText={t(globalI18n.ariaLabelClosePopup)}
          message={snackbarMessage!}
          onClose={this.resetSnackbar}
          open={Utils.isString(snackbarMessage)}
        />

        {!Utils.isString(snackbarMessage) && children}
      </React.Fragment>
    );
  }

  private resetSnackbar(): void {
    this.setState({ snackbarMessage: null });
  }
}

const ns = [I18N_NS_GLOBAL, I18N_NS_SCHEDULING];

export const MeetingRoomErrorSnackbar = withTranslation(ns)(MeetingRoomErrorSnackbarComponent);
MeetingRoomErrorSnackbar.displayName = 'MeetingRoomErrorSnackbar';
