import { createSlice } from '@reduxjs/toolkit';
import { NIL as INITIAL_STORE_STATE_SESSION, v4 as uuid } from 'uuid';
import { getRandomBytes } from '../../utils/get-random-bytes';
import { signOutAction } from '../actions';
import { authSuccess } from '../auth-slice';

export { INITIAL_STORE_STATE_SESSION };

const newSessionId = uuid.bind(null, { rng: () => getRandomBytes(16) });

const sessionSlice = createSlice({
  name: 'session',
  initialState: INITIAL_STORE_STATE_SESSION,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authSuccess, () => newSessionId());
    builder.addCase(signOutAction, () => INITIAL_STORE_STATE_SESSION);
  }
});

export const { reducer: sessionReducer } = sessionSlice;
