import { AppException, Utils } from '@sigmail/common';
import { E_FAIL } from './constants/error';

const DEFAULT_ERROR_MESSAGE = 'API service error.';

export class ServiceException extends AppException {
  public readonly response: Response;

  public constructor(message: string);
  public constructor(errorCode?: number, message?: string);
  public constructor(response: Response, message?: string);
  public constructor(response?: Response, errorCode?: number, message?: string);

  public constructor(...args: Array<unknown>) {
    const params: [Response, number, string] = [Response.error(), E_FAIL, DEFAULT_ERROR_MESSAGE];

    if (args.length >= 1) {
      if (args[0] instanceof Response) params[0] = args[0];

      if (args.length >= 2) {
        if (Utils.isNumber(args[1])) params[1] = args[1];

        if (args.length >= 3) {
          if (Utils.isString(args[2])) params[2] = args[2];
        } else {
          if (Utils.isNumber(args[0])) params[1] = args[0];
          if (Utils.isString(args[1])) params[2] = args[1];
        }
      } else if (Utils.isNumber(args[0])) {
        params[1] = args[0];
      } else if (Utils.isString(args[0])) {
        params[2] = args[0];
      }
    }

    params[2] = `<HTTP ${(args[0] as Response).status}> ${params[2]}`;
    super(params[1], params[2]);

    this.name = 'ApiServiceException';
    this.response = params[0];
  }
}
