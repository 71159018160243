import { PayloadAction } from '@reduxjs/toolkit';
import { StoreStateAuthorization } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';

const Logger = getLoggerWithPrefix('Reducer', 'authSuccessOneTimeContactReducer:');

export const authSuccessOneTimeContactReducer = (
  state: StoreStateAuthorization,
  action: PayloadAction<{ accessToken: string; authClaim: string }>
) => {
  try {
    Logger.info('== BEGIN ==');

    if (!Utils.isObject(action.payload)) {
      Logger.warn('Invalid payload.', action.payload);
      return state;
    }

    const { accessToken, authClaim } = action.payload;
    return { ...state, accessToken, authClaim };
  } finally {
    Logger.info('== END ==');
  }
};
