import { Utils } from '@sigmail/common';
import { ActionLabel } from 'sigmail';

export function resolveActionLabel(action: ActionLabel, actionId?: string, context?: any): string {
  const actionContext = !Utils.isUndefined(context) ? context : action.context;

  if (typeof action.label === 'function') {
    return action.label(Utils.isString(actionId) ? actionId : '', actionContext);
  } else if (Utils.isString(action.label)) {
    return action.label;
  }
  return '';
}
