import {
  AppException,
  Constants,
  EventLogRecordCodeReferralAccepted,
  EventLogRecordCodeReferralDeclined,
  EventLogRecordCodeReferralReceived,
  EventLogRecordCodeReferralSent,
  ReadonlyMessageBodyReferral,
  SigmailUserId,
  Utils
} from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectEventLog,
  DataObjectMsgBody,
  DataObjectMsgFolder,
  DataObjectMsgFolderExt,
  EventLogRecord,
  IUserObject,
  UserObjectEConsult,
  UserObjectEventLog,
  UserObjectEventLogValue,
  UserObjectFolderList
} from '@sigmail/objects';
import { AppThunk } from '../..';
import { MessageFlags } from '../../../app/messaging/utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { selectMessageFolderMap } from '../../selectors/user-object';
import { BaseAction, BaseActionState } from '../base-action';
import { AUTH_STATE_EVENT_LOG_INITIALIZATION_MIGRATION, AUTH_STATE_LOG_EVENT } from '../constants/auth-state-identifier';
import { logEventAction } from '../log-event-action';

interface Payload {
  accessToken: string;
  roleAuthClaim: string;
  roleId: string;
  userId: SigmailUserId;
}

interface State extends BaseActionState {
  consultationEventLogRecordList: Array<EventLogRecord>;
  dtServer: Date;
  eventLogMap: UserObjectEventLogValue;
  eventLogMapObject: IUserObject<UserObjectEventLogValue>;
  referralEventLogRecordList: Array<EventLogRecord>;
}

const CONSULTATION_RESPONSE_SERVICE_CODE_LIST = ['K731', 'K739'];

type EventLogRecordCodeReferral =
  | EventLogRecordCodeReferralAccepted
  | EventLogRecordCodeReferralDeclined
  | EventLogRecordCodeReferralReceived
  | EventLogRecordCodeReferralSent;

class MigrationEventLogInitialization extends BaseAction<Payload, State> {
  protected async onExecute() {
    await this.fetchEventLogMap();

    const { eConsult: consultationEventLogObjectId, referral: referralEventLogObjectId } = this.state.eventLogMap;
    const consultationEventLogObjectExists = DataObjectEventLog.isValidId(consultationEventLogObjectId);
    const referralEventLogObjectExists = DataObjectEventLog.isValidId(referralEventLogObjectId);
    if (!consultationEventLogObjectExists && !referralEventLogObjectExists) {
      await this.applyMigration();
      return;
    }

    this.logger.info('Call ignored; migration is not required.');
  }

  private async fetchEventLogMap(): Promise<void> {
    this.logger.info('Fetching the latest event log map object.');

    const { accessToken, roleAuthClaim, userId } = this.payload;

    const { serverDateTime, userObjectList } = await this.fetchObjects(accessToken, {
      authState: roleAuthClaim,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_EVENT_LOG, userId }]
    });

    const eventLogMapJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_EVENT_LOG, userId });
    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
    this.state.eventLogMapObject = new UserObjectEventLog(eventLogMapJson!);
    this.state.eventLogMap = await this.state.eventLogMapObject.decryptedValue();
  }

  private async applyMigration(): Promise<void> {
    await this.buildConsultationEventLogRecordList();
    await this.buildReferralEventLogRecordList();

    const { accessToken, roleAuthClaim: authState, userId } = this.payload;
    const { consultationEventLogRecordList, dtServer, referralEventLogRecordList } = this.state;

    const requestBody = new BatchUpdateRequestBuilder();
    const claims = await this.dispatch(
      logEventAction({
        accessToken,
        authState,
        dtServer,
        fetchIds: (count) => {
          return this.fetchIdsByUsage(accessToken, {
            authState,
            state: AUTH_STATE_LOG_EVENT,
            ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
          });
        },
        logger: this.logger,
        record: consultationEventLogRecordList.concat(referralEventLogRecordList),
        requestBody,
        userId,
        userIdType: 'user'
      })
    );

    const mutations = requestBody.build();
    if (Utils.isNonEmptyArray(mutations.dataObjects) || Utils.isNonEmptyArray(mutations.userObjects)) {
      const { authState: batchUpdateAuthState } = await this.enterState(accessToken, {
        authState,
        state: AUTH_STATE_EVENT_LOG_INITIALIZATION_MIGRATION
      });

      await this.batchUpdateData(accessToken, { authState: batchUpdateAuthState, claims, ...mutations });
    }
  }

  private async buildConsultationEventLogRecordList(): Promise<void> {
    this.logger.info('Building a list of consultation event log records.');

    const { accessToken, roleAuthClaim, roleId, userId } = this.payload;

    const eventLogRecordList: Array<EventLogRecord> = [];
    if (Utils.isPhysicianRole(roleId)) {
      const { userObjectList } = await this.fetchObjects(accessToken, {
        authState: roleAuthClaim,
        userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_E_CONSULT, userId }]
      });

      const consultationListJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_E_CONSULT, userId });
      const consultationListObject = new UserObjectEConsult(consultationListJson!);
      const { list: consultationList } = await consultationListObject.decryptedValue();

      for (const consultation of consultationList) {
        const isReceivedEConsult = consultation.consultant.id === userId;
        const isReplyToEConsult = isReceivedEConsult && CONSULTATION_RESPONSE_SERVICE_CODE_LIST.includes(consultation.serviceCode);
        const isSentEConsult = consultation.referrer.id === userId;

        const code = isReplyToEConsult
          ? Constants.EventLogCode.EConsultResponded
          : isReceivedEConsult
          ? Constants.EventLogCode.EConsultReceived
          : isSentEConsult
          ? Constants.EventLogCode.EConsultSent
          : undefined;

        if (Constants.EventLogCode.isNil(code)) {
          throw new AppException(Constants.Error.S_ERROR, "Consultation record's code couldn't be determined.");
        }

        const logRecord = this.newEventLogRecordValue(
          new Date(consultation.timestamp),
          code,
          consultation.header,
          consultation.body,
          consultation
        );

        eventLogRecordList.push({ ...logRecord, gid: undefined!, sid: undefined! });
      }
    }

    this.state.consultationEventLogRecordList = eventLogRecordList;
  }

  private async buildReferralEventLogRecordList(): Promise<void> {
    this.logger.info('Building a list of referral event log records.');

    const { accessToken, roleAuthClaim: authState, userId } = this.payload;

    const { userObjectList } = await this.fetchObjects(accessToken, {
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_FOLDER_LIST, userId }]
    });

    const folderListJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_FOLDER_LIST, userId });
    const folderListObject = new UserObjectFolderList(folderListJson!);
    const msgFolderMap = selectMessageFolderMap(await folderListObject.decryptedValue());
    const inboxFolderId = msgFolderMap[Constants.MessageFolderKey.Inbox]?.id;
    const archivedFolderId = msgFolderMap[Constants.MessageFolderKey.Inbox]?.children?.archived?.id;
    const sentFolderId = msgFolderMap[Constants.MessageFolderKey.Sent]?.id;

    const eventLogRecordList: Array<EventLogRecord> = [];
    for (const folderId of [inboxFolderId, archivedFolderId, sentFolderId]) {
      const isSentFolder = folderId === sentFolderId;
      if (!DataObjectMsgFolder.isValidId(folderId)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Message folder ID is either missing or invalid.');
      }

      let nextFolderId: typeof folderId | null = folderId;
      while (DataObjectMsgFolder.isValidId(nextFolderId)) {
        let { dataObjectList } = await this.fetchObjects(accessToken, { authState, dataObjects: { ids: [nextFolderId] } });
        const msgFolderJson = this.findDataObject(dataObjectList, { id: nextFolderId });
        const DataObjectMsgFolderClass = msgFolderJson?.type === DataObjectMsgFolder.TYPE ? DataObjectMsgFolder : DataObjectMsgFolderExt;
        const msgFolderObject = new DataObjectMsgFolderClass(msgFolderJson!);
        const msgFolder = await msgFolderObject.decryptedValue();

        nextFolderId = msgFolder.next;

        const messageList = Utils.filterMap(msgFolder.data, (message, a, b) => Utils.isReferralMessageForm(message.messageForm) && message);
        if (messageList.length === 0) continue;

        dataObjectList = (
          await this.fetchObjects(accessToken, {
            authState,
            dataObjects: { ids: messageList.map(({ body: id }) => id) }
          })
        ).dataObjectList;

        for (const message of messageList) {
          const msgBodyJson = this.findDataObject(dataObjectList, { id: message.body });
          const msgBodyObject = new DataObjectMsgBody(msgBodyJson!);
          const { value: msgBody } = ((await msgBodyObject.decryptedValue()) as ReadonlyMessageBodyReferral).messageForm;
          const { isAccepted, isDeclined } = MessageFlags(message);

          let code: EventLogRecordCodeReferral | undefined;
          if (isSentFolder) {
            if (msgBody.referrer.id === userId) {
              code = Constants.EventLogCode.ReferralSent;
            } else if (isAccepted) {
              code = Constants.EventLogCode.ReferralAccepted;
            } else if (isDeclined) {
              code = Constants.EventLogCode.ReferralDeclined;
            }
          } else if (msgBody.referToList.some(({ id }) => id === userId)) {
            code = Constants.EventLogCode.ReferralReceived;
          } else if (isAccepted || isDeclined) {
            continue;
          }

          if (Constants.EventLogCode.isNil(code)) {
            throw new AppException(Constants.Error.S_ERROR, "Referral record's code couldn't be determined.");
          }

          const createdAt = Utils.isNumber(message.timestamp) ? message.timestamp : message.timestamp.createdAt!;
          let logRecord = this.newEventLogRecordValue(new Date(createdAt), code, message.header, message.body, msgBody);
          eventLogRecordList.push({ ...logRecord, gid: undefined!, sid: undefined! });
        }
      }
    }

    this.state.referralEventLogRecordList = eventLogRecordList;
  }
}

export const migration_eventLogInitialization = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'eventLogInitialization:');

    const migration = new MigrationEventLogInitialization({ payload, dispatch, getState, apiService, logger: Logger });
    return migration.execute();
  };
};
