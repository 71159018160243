import { Card, CardHeader, Drawer, DrawerProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { homeRoutePathSelector } from '../../../app-state/selectors';
import {
  isUserLoggedInSelector,
  selectAccessRight,
  selectAccessRightRoleIdList
} from '../../../app-state/selectors/auth';
import { AppDrawer as ActionContextAppDrawer } from '../../../constants/action-context';
import * as ActionId from '../../../constants/action-ids';
import { useTranslation } from '../../../i18n';
import { I18N_NS_GLOBAL } from '../../../i18n/config/namespace-identifiers';
import globalI18n from '../../../i18n/global';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { AccountNavActionList } from '../../account/nav-action-list.component';
import { ContactNavActionList } from '../../contact-list/nav-action-list.component';
import { MessageFolderList } from '../../messaging/folder-list/folder-list.component';
import { useGroupMessageFolderMapResource, useUserMessageFolderMapResource } from '../../messaging/hooks';
import { SchedulingNavActionList } from '../../scheduling/nav-action-list.component';
import { CircularProgress } from '../../shared/circular-progress.component';
import { RouterNavLink } from '../../shared/router-nav-link.component';
import style from './app-drawer.module.css';
import { AppDrawerMenuGroup, Props as AppDrawerMenuGroupProps } from './menu-group.component';

export interface Props extends Omit<DrawerProps, 'children' | 'ref'> {
  readonly children?: never;
  readonly onClickNavLink?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

const resetExpandedMenuGroupState = (canAccessMailbox: boolean) => (canAccessMailbox ? ActionId.Mailbox : undefined);

export const AppDrawer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, onClickNavLink, onClose, open, ...rootProps }, ref) => {
    const hasAccessRight = useSelector(selectAccessRight);
    const homeRoutePath = useSelector(homeRoutePathSelector);
    const isUserLoggedIn = useSelector(isUserLoggedInSelector);
    const groupMessageFolderMapResource = useGroupMessageFolderMapResource();
    const { t } = useTranslation([I18N_NS_GLOBAL]);
    const userMessageFolderMapResource = useUserMessageFolderMapResource();

    const canAccessCircleOfCare = hasAccessRight('accessCircleOfCare');
    const canAccessClientContacts = hasAccessRight('accessClientContacts');
    const canAccessGlobalContacts = hasAccessRight('accessGlobalContacts');
    const canAccessContacts = canAccessCircleOfCare || canAccessClientContacts || canAccessGlobalContacts;

    const canAccessGroupSchedule = hasAccessRight('accessGroupSchedule');
    const canAccessOwnSchedule = hasAccessRight('accessOwnSchedule');
    const canAccessSchedule = canAccessOwnSchedule || canAccessGroupSchedule;

    const canAccessMailbox = hasAccessRight('accessGroupMailbox') || hasAccessRight('accessMailbox');
    const canInviteGuest = useSelector(selectAccessRightRoleIdList)('inviteMember').some(Utils.isGuestRole);

    const [expandedMenuGroup, setExpandedMenuGroup] = React.useState(() =>
      resetExpandedMenuGroupState(canAccessMailbox)
    );

    const toggleMenuGroup = React.useCallback<AppDrawerMenuGroupProps['onToggle']>(
      (_, group) => setExpandedMenuGroup(group === expandedMenuGroup ? undefined : group),
      [expandedMenuGroup]
    );

    const titleNode = React.useMemo<React.ReactNode>(
      () => (
        <RouterNavLink data-action-id={ActionId.Home} exact={true} styleName="style.title" to={homeRoutePath}>
          {t(globalI18n.logoText)}
        </RouterNavLink>
      ),
      [homeRoutePath, t]
    );

    const closeDrawer = React.useMemo<React.MouseEventHandler | undefined>(
      () => (typeof onClose === 'function' ? () => onClose({}, 'backdropClick') : undefined),
      [onClose]
    );

    React.useEffect(() => {
      !open && setExpandedMenuGroup(resetExpandedMenuGroupState(canAccessMailbox));
    }, [canAccessMailbox, open]);

    return (
      <Drawer onClose={onClose} open={open} ref={ref} {...rootProps}>
        <Card classes={{ root: style.card }} elevation={0}>
          <CardHeader
            action={
              <CloseIcon
                aria-hidden={false}
                aria-label={t(globalI18n.ariaLabelCloseDrawer)}
                onClick={closeDrawer}
                tabIndex={0}
              />
            }
            classes={{ action: style.action, root: style.header }}
            disableTypography={true}
            title={titleNode}
          />

          {isUserLoggedIn && (
            <AppDrawerMenuGroup
              onToggle={toggleMenuGroup}
              open={expandedMenuGroup === ActionId.UserAccountMenu}
              variant={ActionId.UserAccountMenu}
            >
              <AccountNavActionList
                classes={{ anchor: style.anchor, listItem: style.li }}
                className={style.ul}
                onNavLinkClick={onClickNavLink}
              />
            </AppDrawerMenuGroup>
          )}

          {canAccessMailbox && (
            <AppDrawerMenuGroup
              onToggle={toggleMenuGroup}
              open={expandedMenuGroup === ActionId.Mailbox}
              variant={ActionId.Mailbox}
            >
              <React.Suspense fallback={<CircularProgress color="inherit" />}>
                <MessageFolderList
                  classes={{ anchor: style.anchor, listItem: style.li }}
                  className={style.ul}
                  groupMessageFolderMapResource={groupMessageFolderMapResource}
                  onNavLinkClick={onClickNavLink}
                  userMessageFolderMapResource={userMessageFolderMapResource}
                />
              </React.Suspense>
            </AppDrawerMenuGroup>
          )}

          {canAccessSchedule && (
            <AppDrawerMenuGroup
              onToggle={toggleMenuGroup}
              open={expandedMenuGroup === ActionId.Schedule}
              variant={ActionId.Schedule}
            >
              <SchedulingNavActionList
                classes={{ anchor: style.anchor, listItem: style.li }}
                className={style.ul}
                onNavLinkClick={onClickNavLink}
              />
            </AppDrawerMenuGroup>
          )}

          {canAccessContacts && (
            <AppDrawerMenuGroup
              onToggle={toggleMenuGroup}
              open={expandedMenuGroup === ActionId.ContactList}
              variant={ActionId.ContactList}
            >
              <ContactNavActionList
                classes={{ anchor: style.anchor, listItem: style.li }}
                className={style.ul}
                onNavLinkClick={onClickNavLink}
              />
            </AppDrawerMenuGroup>
          )}

          {canInviteGuest && (
            <div styleName="style.menu-group">
              <RouterNavLink
                data-action-id={ActionId.SendGuestAccountInvitation}
                exact={true}
                onClick={onClickNavLink}
                to={homeRoutePath}
              >
                {t(
                  resolveActionLabel(
                    globalI18n.action[ActionId.SendGuestAccountInvitation],
                    ActionId.SendGuestAccountInvitation,
                    ActionContextAppDrawer
                  )
                )}
              </RouterNavLink>
            </div>
          )}
        </Card>
      </Drawer>
    );
  }
);

AppDrawer.displayName = 'AppDrawer';
AppDrawer.defaultProps = { className: style.root };
