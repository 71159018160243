import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { getRoutePath } from '../../app/routes';
import FolderIconArchivedPrimary from '../../assets/images/folder-icon-archived-primary.min.svg';
import FolderIconArchivedSecondary from '../../assets/images/folder-icon-archived-secondary.min.svg';
import FolderIconConsultationPrimary from '../../assets/images/folder-icon-consultation-primary.min.svg';
import FolderIconConsultationSecondary from '../../assets/images/folder-icon-consultation-secondary.min.svg';
import FolderIconDeletedPrimary from '../../assets/images/folder-icon-deleted-primary.min.svg';
import FolderIconDeletedSecondary from '../../assets/images/folder-icon-deleted-secondary.min.svg';
import FolderIconDraftsPrimary from '../../assets/images/folder-icon-drafts-primary.min.svg';
import FolderIconDraftsSecondary from '../../assets/images/folder-icon-drafts-secondary.min.svg';
import FolderIconImportantPrimary from '../../assets/images/folder-icon-important-primary.min.svg';
import FolderIconImportantSecondary from '../../assets/images/folder-icon-important-secondary.min.svg';
import FolderIconInboxPrimary from '../../assets/images/folder-icon-inbox-primary.min.svg';
import FolderIconInboxSecondary from '../../assets/images/folder-icon-inbox-secondary.min.svg';
import FolderIconReferralsPrimary from '../../assets/images/folder-icon-referrals-primary.min.svg';
import FolderIconReferralsSecondary from '../../assets/images/folder-icon-referrals-secondary.min.svg';
import FolderIconReminderPrimary from '../../assets/images/folder-icon-reminder-primary.min.svg';
import FolderIconReminderSecondary from '../../assets/images/folder-icon-reminder-secondary.min.svg';
import FolderIconSentPrimary from '../../assets/images/folder-icon-sent-primary.min.svg';
import FolderIconSentSecondary from '../../assets/images/folder-icon-sent-secondary.min.svg';
import IconNewMessagePrimary from '../../assets/images/icon-new-message-primary.min.svg';
import IconNewMessageSecondary from '../../assets/images/icon-new-message-secondary.min.svg';
import { Messaging } from '../../constants/action-context';
import * as ActionId from '../../constants/action-ids';
import { EnglishCanada, FrenchCanada } from '../../constants/language-codes';
import * as RouteId from '../../constants/route-identifiers';

const i18n: MessagingI18n.Root['navAction'] = {
  [ActionId.ComposeConsultationRequest]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: IconNewMessagePrimary,
        [FrenchCanada]: IconNewMessagePrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: IconNewMessageSecondary,
        [FrenchCanada]: IconNewMessageSecondary
      }
    },
    // Translators: Label for the Compose Consultation Request navigation action
    label: gettext('messaging', 'composeConsultationRequest', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_COMPOSE_CONSULTATION_REQUEST),
    context: Messaging
  },
  [ActionId.ComposeHrmMessage]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: IconNewMessagePrimary,
        [FrenchCanada]: IconNewMessagePrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: IconNewMessageSecondary,
        [FrenchCanada]: IconNewMessageSecondary
      }
    },
    // Translators: Label for the Compose HRM Message navigation action
    label: gettext('messaging', 'composeHrmMessage', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_COMPOSE_HRM_MESSAGE),
    context: Messaging
  },
  [ActionId.ComposeMessage]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: IconNewMessagePrimary,
        [FrenchCanada]: IconNewMessagePrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: IconNewMessageSecondary,
        [FrenchCanada]: IconNewMessageSecondary
      }
    },
    // Translators: Label for the Compose Message navigation action
    label: gettext('messaging', 'composeMessage', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_COMPOSE_MESSAGE),
    context: Messaging
  },
  [ActionId.ReceivedMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconInboxPrimary,
        [FrenchCanada]: FolderIconInboxPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconInboxSecondary,
        [FrenchCanada]: FolderIconInboxSecondary
      }
    },
    // Translators: Label for the Received Messages navigation action
    label: gettext('messaging', 'receivedMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_INBOX),
    context: Messaging
  },
  [ActionId.ReceivedMessagesShared]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconInboxPrimary,
        [FrenchCanada]: FolderIconInboxPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconInboxSecondary,
        [FrenchCanada]: FolderIconInboxSecondary
      }
    },
    // Translators: Label for the Shared Received Messages navigation action
    label: gettext('messaging', 'receivedMessagesShared', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_INBOX_SHARED),
    context: Messaging
  },
  [ActionId.ReminderMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconReminderPrimary,
        [FrenchCanada]: FolderIconReminderPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconReminderSecondary,
        [FrenchCanada]: FolderIconReminderSecondary
      }
    },
    // Translators: Label for the Reminder Messages navigation action
    label: gettext('messaging', 'reminderMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_REMINDER),
    context: Messaging
  },
  [ActionId.ConsultationMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconConsultationPrimary,
        [FrenchCanada]: FolderIconConsultationPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconConsultationSecondary,
        [FrenchCanada]: FolderIconConsultationSecondary
      }
    },
    // Translators: Label for the Consultation Messages navigation action
    label: gettext('messaging', 'consultationMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_CONSULTATION),
    context: Messaging
  },
  [ActionId.ImportantMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconImportantPrimary,
        [FrenchCanada]: FolderIconImportantPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconImportantSecondary,
        [FrenchCanada]: FolderIconImportantSecondary
      }
    },
    // Translators: Label for the Important Messages navigation action
    label: gettext('messaging', 'importantMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_IMPORTANT),
    context: Messaging
  },
  [ActionId.ImportantMessagesShared]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconImportantPrimary,
        [FrenchCanada]: FolderIconImportantPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconImportantSecondary,
        [FrenchCanada]: FolderIconImportantSecondary
      }
    },
    // Translators: Label for the Shared Important Messages navigation action
    label: gettext('messaging', 'importantMessagesShared', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_IMPORTANT_SHARED),
    context: Messaging
  },
  [ActionId.ReferralMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconReferralsPrimary,
        [FrenchCanada]: FolderIconReferralsPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconReferralsSecondary,
        [FrenchCanada]: FolderIconReferralsSecondary
      }
    },
    // Translators: Label for the Referral Messages navigation action
    label: gettext('messaging', 'referralMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_REFERRALS),
    context: Messaging
  },
  [ActionId.ReferralMessagesShared]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconReferralsPrimary,
        [FrenchCanada]: FolderIconReferralsPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconReferralsSecondary,
        [FrenchCanada]: FolderIconReferralsSecondary
      }
    },
    // Translators: Label for the Shared Referral Messages navigation action
    label: gettext('messaging', 'referralMessagesShared', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_REFERRALS_SHARED),
    context: Messaging
  },
  [ActionId.ArchivedMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconArchivedPrimary,
        [FrenchCanada]: FolderIconArchivedPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconArchivedSecondary,
        [FrenchCanada]: FolderIconArchivedSecondary
      }
    },
    // Translators: Label for the Archived Messages navigation action
    label: gettext('messaging', 'archivedMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_ARCHIVED),
    context: Messaging
  },
  [ActionId.ArchivedMessagesShared]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconArchivedPrimary,
        [FrenchCanada]: FolderIconArchivedPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconArchivedSecondary,
        [FrenchCanada]: FolderIconArchivedSecondary
      }
    },
    // Translators: Label for the Shared Archived Messages navigation action
    label: gettext('messaging', 'archivedMessagesShared', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_ARCHIVED_SHARED),
    context: Messaging
  },
  [ActionId.DeletedMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconDeletedPrimary,
        [FrenchCanada]: FolderIconDeletedPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconDeletedSecondary,
        [FrenchCanada]: FolderIconDeletedSecondary
      }
    },
    // Translators: Label for the Deleted Messages navigation action
    label: gettext('messaging', 'deletedMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_TRASH),
    context: Messaging
  },
  [ActionId.DeletedMessagesShared]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconDeletedPrimary,
        [FrenchCanada]: FolderIconDeletedPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconDeletedSecondary,
        [FrenchCanada]: FolderIconDeletedSecondary
      }
    },
    // Translators: Label for the Shared Deleted Messages navigation action
    label: gettext('messaging', 'deletedMessagesShared', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_TRASH_SHARED),
    context: Messaging
  },
  [ActionId.SentMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconSentPrimary,
        [FrenchCanada]: FolderIconSentPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconSentSecondary,
        [FrenchCanada]: FolderIconSentSecondary
      }
    },
    // Translators: Label for the Sent Messages navigation action
    label: gettext('messaging', 'sentMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_SENT),
    context: Messaging
  },
  [ActionId.DraftMessages]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconDraftsPrimary,
        [FrenchCanada]: FolderIconDraftsPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: FolderIconDraftsSecondary,
        [FrenchCanada]: FolderIconDraftsSecondary
      }
    },
    // Translators: Label for the Draft Messages navigation action
    label: gettext('messaging', 'draftMessages', 'navActionLabel'),
    to: getRoutePath(RouteId.ROUTE_MAIL_DRAFTS),
    context: Messaging
  }
};

export default i18n;
