import { PartialRecord, Utils } from '@sigmail/common';
import { HealthDataI18n } from '@sigmail/i18n';
import {
  CardiacIntakeFormArrhythmiaData,
  CardiacIntakeFormData,
  CardiacIntakeFormDyspneaData,
  CardiacIntakeFormLegPainData,
  CardiacIntakeFormPainData,
  CardiacIntakeFormSyncopeData,
  CardiacSymptomFrequency,
  UserObjectHealthDataValue
} from '@sigmail/objects';
import { TOptions, WithT } from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { SANITIZER_OPTIONS } from '../../../app-state/actions/constants';
import {
  DataFormCssClassPrefix,
  DataFormNameCardiacIntake,
  SPLIT_BY_CAPITAL_REGEX
} from '../../../app/health-data/constants';
import { FieldName, FIELD_SET_LIST, FormValues } from '../../../app/health-data/forms/cardiac-intake.component';
import { CardiacFormPainSeverity } from '../../../constants/form-input-constraint';
import { EnglishCanada } from '../../../constants/language-codes';
import healthDataI18n from '../../../i18n/health-data';
import { dateToUtcValues } from '../../date-to-utc-values';

const { cardiacIntake: formI18n } = healthDataI18n.form;
const { dataValueNotSet } = healthDataI18n.dataViewer.cardiacIntake;

export type CardiacIntakeGridFieldName = Exclude<FieldName, `${string}Other${string}`>;
export type CardiacIntakeGridData = Omit<FormValues, `${string}Other${string}` | `painSmokingHistory${string}`> &
  PartialRecord<'timestamp', number> &
  Record<'painSmokingHistory', CardiacIntakeFormPainData['smokingHistory']>;

type TFrequency = CardiacSymptomFrequency;

type TReliefFactor<T extends keyof CardiacIntakeFormData> = T extends 'arrhythmia'
  ? CardiacIntakeFormArrhythmiaData['reliefFactor']
  : T extends 'dyspnea'
  ? CardiacIntakeFormDyspneaData['reliefFactor']
  : T extends 'pain'
  ? CardiacIntakeFormPainData['reliefFactor']
  : never;

type TSymptoms<T extends keyof CardiacIntakeFormData> = T extends 'arrhythmia'
  ? CardiacIntakeFormArrhythmiaData['symptoms']
  : T extends 'dyspnea'
  ? CardiacIntakeFormDyspneaData['symptoms']
  : T extends 'legPain'
  ? CardiacIntakeFormLegPainData['symptoms']
  : T extends 'pain'
  ? CardiacIntakeFormPainData['symptoms']
  : never;

type TTrigger<T extends keyof CardiacIntakeFormData> = T extends 'arrhythmia'
  ? CardiacIntakeFormArrhythmiaData['trigger']
  : T extends 'dyspnea'
  ? CardiacIntakeFormDyspneaData['trigger']
  : T extends 'legPain'
  ? CardiacIntakeFormLegPainData['trigger']
  : T extends 'pain'
  ? CardiacIntakeFormPainData['trigger']
  : T extends 'syncope'
  ? CardiacIntakeFormSyncopeData['trigger']
  : never;

// const RE_RANGE_PROCESSOR = /\[(\d+)-(\d+)]/;
const RE_RANGE_PROCESSOR = /\((\S*)\).*?\[((.|\n)*)\]/;
const rangeMatches = (rangeLabel: string, rangeValue: number): boolean => {
  if (rangeLabel.indexOf('-') > -1) {
    const [from, to] = rangeLabel.split('-').map((value) => (value === 'inf' ? Infinity : parseInt(value, 10)));
    return rangeValue >= from && rangeValue <= to;
  } else {
    const match = parseInt(rangeLabel, 10);
    return match === rangeValue;
  }
};

const getSmokingHistoryValue = (data: CardiacIntakeFormPainData['smokingHistory'], index: 1 | 2 | 3) => {
  if (Utils.isNonEmptyArray(data)) {
    if (index === 1 || index === 2 || (index === 3 && data[0] === 'former')) return data[index];
  }
};

export class CardiacIntakeDataUtil {
  private readonly data: CardiacIntakeFormData | undefined;

  public constructor(data?: CardiacIntakeFormData) {
    if (Utils.isNil(data)) return;

    const Class = this.constructor as typeof CardiacIntakeDataUtil;
    if (!Class.isValid(data)) throw new TypeError('Invalid cardiac intake data.');

    this.data = data;
  }

  public static buildGridData(
    data: UserObjectHealthDataValue
  ): Readonly<[ReadonlyArray<number>, ReadonlyArray<CardiacIntakeGridData>]> {
    const timestampSet = new Set<number>();
    for (const requestId in data.$index) {
      const [indexOfRequest, responseList] = data.$index[+requestId]!;
      if (data.requestList![indexOfRequest].form !== DataFormNameCardiacIntake) continue;

      responseList.forEach((ts) => timestampSet.add(ts));
    }

    const timestampList = Array.from(timestampSet.values());
    const dataList: Array<CardiacIntakeGridData> = [];

    if (Utils.isNotNil(data.cardiacIntake) && timestampList.length > 0) {
      for (const ts of timestampList) {
        const [utcYear, utcMonth, utcDate] = dateToUtcValues(ts);
        let cardiacInTakeData = data.cardiacIntake![utcYear]![utcMonth]![utcDate]!;
        if (cardiacInTakeData) {
          dataList.push({ ...CardiacIntakeDataUtil.mergeGridData(cardiacInTakeData), timestamp: ts });
        }
      }

      timestampList.sort((a, b) => b - a);
    }

    return [timestampList, dataList];
  }

  public static createDataFromFormValues({
    arrhythmia,
    arrhythmiaFrequency,
    arrhythmiaOtherFrequency,
    arrhythmiaReliefFactor,
    arrhythmiaOtherReliefFactor,
    dyspnea,
    dyspneaFrequency,
    dyspneaOtherFrequency,
    legPain,
    legPainFrequency,
    legPainOtherFrequency,
    medications,
    notes,
    pain,
    painFrequency,
    painTrigger,
    painOtherFrequency,
    painReliefFactor,
    painOtherReliefFactor,
    painOtherTrigger,
    painSmokingHistory,
    painSmokingHistoryCurrentFrequency,
    painSmokingHistoryCurrentPeriod,
    painSmokingHistoryFormerFrequency,
    painSmokingHistoryFormerPeriod,
    painSmokingHistoryFormerPeriodQuit,
    syncope,
    syncopeFrequency,
    syncopeOtherFrequency,
    ...rest
  }: FormValues): CardiacIntakeFormData {
    const smokingHistory: CardiacIntakeFormPainData['smokingHistory'] =
      painSmokingHistory === 1
        ? ['current', +painSmokingHistoryCurrentFrequency, painSmokingHistoryCurrentPeriod]
        : painSmokingHistory === 2
        ? [
            'former',
            +painSmokingHistoryFormerFrequency,
            painSmokingHistoryFormerPeriod,
            painSmokingHistoryFormerPeriodQuit
          ]
        : painSmokingHistory;

    arrhythmiaFrequency = (arrhythmiaFrequency !== 'other'
      ? arrhythmiaFrequency
      : arrhythmiaOtherFrequency) as TFrequency;
    arrhythmiaReliefFactor = !arrhythmiaReliefFactor.includes('other')
      ? arrhythmiaReliefFactor
      : [...arrhythmiaReliefFactor.filter((value) => value !== 'other'), `other:${arrhythmiaOtherReliefFactor}`];
    dyspneaFrequency = (dyspneaFrequency !== 'other' ? dyspneaFrequency : dyspneaOtherFrequency) as TFrequency;
    legPainFrequency = (legPainFrequency !== 'other' ? legPainFrequency : legPainOtherFrequency) as TFrequency;
    painFrequency = (painFrequency !== 'other' ? painFrequency : painOtherFrequency) as TFrequency;
    painReliefFactor = !painReliefFactor.includes('other')
      ? painReliefFactor
      : [...painReliefFactor.filter((value) => value !== 'other'), `other:${painOtherReliefFactor}`];
    painTrigger = !painTrigger.includes('other')
      ? painTrigger
      : [...painTrigger.filter((value) => value !== 'other'), `other:${painOtherTrigger}`];
    syncopeFrequency = (syncopeFrequency !== 'other' ? syncopeFrequency : syncopeOtherFrequency) as TFrequency;

    return {
      arrhythmia:
        arrhythmia === 0
          ? false
          : {
              character: rest.arrhythmiaCharacter,
              development: rest.arrhythmiaDevelopment,
              frequency: arrhythmiaFrequency,
              onset: rest.arrhythmiaOnset,
              reliefFactor: arrhythmiaReliefFactor as TReliefFactor<'arrhythmia'>,
              start: rest.arrhythmiaStart,
              stop: rest.arrhythmiaStop,
              symptoms: rest.arrhythmiaSymptoms as TSymptoms<'arrhythmia'>,
              trigger: rest.arrhythmiaTrigger as TTrigger<'arrhythmia'>
            },
      dyspnea:
        dyspnea === 0
          ? false
          : {
              development: rest.dyspneaDevelopment,
              frequency: dyspneaFrequency,
              onset: rest.dyspneaOnset,
              reliefFactor: rest.dyspneaReliefFactor as TReliefFactor<'dyspnea'>,
              start: rest.dyspneaStart,
              symptoms: rest.dyspneaSymptoms as TSymptoms<'dyspnea'>,
              trigger: rest.dyspneaTrigger as TTrigger<'dyspnea'>
            },
      legPain:
        legPain === 0
          ? false
          : {
              character: rest.legPainCharacter,
              development: rest.legPainDevelopment,
              frequency: legPainFrequency,
              location: rest.legPainLocation,
              onset: rest.legPainOnset,
              symptoms: rest.legPainSymptoms as TSymptoms<'legPain'>,
              trigger: rest.legPainTrigger as TTrigger<'legPain'>
            },
      medications,
      notes,
      pain:
        pain === 0
          ? false
          : {
              character: rest.painCharacter,
              development: rest.painDevelopment,
              frequency: painFrequency,
              location: rest.painLocation,
              onset: rest.painOnset,
              radiation: rest.painRadiation as CardiacIntakeFormPainData['radiation'],
              reliefFactor: painReliefFactor as TReliefFactor<'pain'>,
              severity: rest.painSeverity,
              start: rest.painStart,
              symptoms: rest.painSymptoms as TSymptoms<'pain'>,
              trigger: painTrigger as TTrigger<'pain'>,
              smokingHistory
            },
      syncope:
        syncope === 0
          ? false
          : {
              character: rest.syncopeCharacter,
              development: rest.syncopeDevelopment,
              frequency: syncopeFrequency,
              onset: rest.syncopeOnset,
              trigger: rest.syncopeTrigger as TTrigger<'syncope'>
            }
    };
  }

  public static formatDataValue(
    data: CardiacIntakeGridData,
    fieldName: CardiacIntakeGridFieldName,
    t: WithT['t'],
    language?: string
  ): string | number | undefined {
    let tOptions: TOptions | undefined;
    if (Utils.isString(language)) tOptions = { language };

    let dataValue: any;
    const painSmokingHistory = data.painSmokingHistory;
    switch (fieldName) {
      case 'painSmokingHistory':
        if (Utils.isNumber(painSmokingHistory)) {
          dataValue = painSmokingHistory;
        } else if (Utils.isNonEmptyArray(painSmokingHistory)) {
          dataValue = painSmokingHistory[0] === 'current' ? 1 : 2;
        }
        break;
      case 'painSmokingHistoryCurrentFrequency':
      case 'painSmokingHistoryFormerFrequency':
        dataValue = getSmokingHistoryValue(painSmokingHistory, 1);
        break;
      case 'painSmokingHistoryCurrentPeriod':
      case 'painSmokingHistoryFormerPeriod':
        dataValue = getSmokingHistoryValue(painSmokingHistory, 2);
        break;
      case 'painSmokingHistoryFormerPeriodQuit':
        dataValue = getSmokingHistoryValue(painSmokingHistory, 3);
        break;
      default:
        dataValue = data[fieldName];
        break;
    }

    if (Utils.isNil(dataValue) || (!Utils.isNumber(dataValue) && dataValue.length === 0)) return undefined;

    if (fieldName === 'medications' || fieldName === 'notes') {
      return sanitizeHtml(Utils.trimOrDefault(dataValue), SANITIZER_OPTIONS);
    }

    if (fieldName === 'painSmokingHistoryCurrentFrequency' || fieldName === 'painSmokingHistoryFormerFrequency') {
      return dataValue;
    }

    if (fieldName === 'painSeverity') {
      return this.getDataRangeLabel(fieldName, dataValue as number, t);
    }

    const [fieldset] = FIELD_SET_LIST.find(([, fieldNameList]) => fieldNameList.includes(fieldName))!;
    let options = (formI18n[fieldset] as any).formField[fieldName].options as ReadonlyArray<
      HealthDataI18n.Form.CardiacIntakeFormOption<number | string>
    >;

    if (fieldName === 'painRadiation') {
      if ((dataValue as unknown) === false) {
        dataValue = 'none';
      }
    } else if (
      (fieldName.endsWith('Frequency') ||
        fieldName.endsWith('Onset') ||
        fieldName === 'painSmokingHistoryCurrentPeriod' ||
        fieldName === 'painSmokingHistoryFormerPeriod' ||
        fieldName === 'painSmokingHistoryFormerPeriodQuit') &&
      Utils.isString(dataValue)
    ) {
      const isFrequencyField = fieldName.endsWith('Frequency');

      if (isFrequencyField) {
        const fieldPrefix = this.getFieldPrefix(fieldName);
        options = (formI18n[fieldset] as any).formField[`${fieldPrefix}OtherFrequency`].options as ReadonlyArray<
          HealthDataI18n.Form.CardiacIntakeFormOption<HealthDataI18n.CardiacIntakeFormPeriod>
        >;
      }

      const periodValue = dataValue.slice(-1) as HealthDataI18n.CardiacIntakeFormPeriod;
      return `${dataValue.slice(0, -1)} ${t(
        options.find(({ codedValue }) => codedValue === periodValue)!.label,
        tOptions
      )}`;
    }

    if (Utils.isArray(dataValue)) {
      return dataValue
        .map((value) =>
          Utils.isString(value) && value.startsWith('other:')
            ? value.replace('other:', '')
            : t(options.find(({ codedValue }) => codedValue === value)!.label, tOptions)
        )
        .join(', ');
    }

    return t(options.find(({ codedValue }) => codedValue === dataValue)!.label, tOptions) as string;
  }

  public static getDataRange(fieldName: Extract<FieldName, 'painSeverity'>): Record<'max' | 'min', number> {
    // prettier-ignore
    switch (fieldName) {
      case 'painSeverity': return CardiacFormPainSeverity;
      default:
        throw new Error(`Unhandled case - ${String(fieldName)}`);
    }
  }

  public static getDataRangeLabel(
    fieldName: Extract<FieldName, 'painSeverity'>,
    fieldValue: number,
    t: WithT['t']
  ): string {
    let rangeLabel = t(formI18n.fieldsetPain.formField[fieldName].rangeLabel, {
      LBOUND: fieldValue,
      UBOUND: this.getDataRange(fieldName).max
    });

    for (const range of rangeLabel.split(';')) {
      const match = RE_RANGE_PROCESSOR.exec(range);
      if (match !== null && rangeMatches(match[1], fieldValue)) {
        rangeLabel = match[2];
        break;
      }
    }

    return rangeLabel;
  }

  public static getFieldPrefix(fieldName: FieldName) {
    return fieldName.startsWith('legPain') ? 'legPain' : (fieldName.split(SPLIT_BY_CAPITAL_REGEX)[0] as FieldName);
  }

  /**
   * IMPORTANT: This utility class merges data into a single object based on parent key.
   * Before making any changes, please refer to the "health-data" typings and understand the naming conventions \
   * used in the "cardiacIntake form".
   */
  private static mergeGridData(data: CardiacIntakeFormData) {
    for (const fieldName of Object.keys(data) as ReadonlyArray<keyof CardiacIntakeFormData>) {
      if (fieldName !== 'medications' && fieldName !== 'notes') {
        if (data[fieldName] === false) {
          data = { ...data, [fieldName]: 0 };
        } else {
          const subFields = Object.entries(data[fieldName]).reduce((result, [subFieldName, subFieldValue]) => {
            const fieldIndex = Utils.camelCase(`${fieldName} ${subFieldName}`);
            result[fieldIndex] = subFieldValue;

            return result;
          }, {} as any);
          data = { ...data, ...subFields, [fieldName]: 1 };
        }
      }
    }

    return (data as unknown) as CardiacIntakeGridData;
  }

  public static isValid(data?: null | undefined | unknown): data is CardiacIntakeFormData {
    return (
      Utils.isNonArrayObjectLike<CardiacIntakeFormData>(data) &&
      (data.arrhythmia === false || Utils.isNonArrayObjectLike<CardiacIntakeFormArrhythmiaData>(data.arrhythmia)) &&
      (data.dyspnea === false || Utils.isNonArrayObjectLike<CardiacIntakeFormDyspneaData>(data.dyspnea)) &&
      (data.legPain === false || Utils.isNonArrayObjectLike<CardiacIntakeFormLegPainData>(data.legPain)) &&
      (Utils.isNil(data.medications) || Utils.isString(data.medications)) &&
      (Utils.isNil(data.notes) || Utils.isString(data.notes)) &&
      (data.pain === false || Utils.isNonArrayObjectLike<CardiacIntakeFormPainData>(data.pain)) &&
      (data.syncope === false || Utils.isNonArrayObjectLike<CardiacIntakeFormSyncopeData>(data.syncope))
    );
  }

  // @ts-expect-error TS2394
  public toHtml(t: WithT['t']): string;
  public toHtml(data: CardiacIntakeFormData, t: WithT['t']): string;
  public toHtml(arg0: unknown, arg1: WithT['t']): string {
    let data: CardiacIntakeFormData;
    let t: typeof arg1;

    if (typeof arg0 === 'function') {
      data = this.data!;
      t = arg0 as typeof t;
    } else {
      data = arg0 as CardiacIntakeFormData;
      t = arg1;
    }

    const Class = this.constructor as typeof CardiacIntakeDataUtil;
    const mergedData = Class.mergeGridData(data);

    const excludeFieldName: Array<FieldName> = [];
    const painSmokingHistory = mergedData.painSmokingHistory;
    if (!Utils.isArray(painSmokingHistory) || painSmokingHistory[0] !== 'current') {
      excludeFieldName.push('painSmokingHistoryCurrentFrequency', 'painSmokingHistoryCurrentPeriod');
    }
    if (!Utils.isArray(painSmokingHistory) || painSmokingHistory[0] !== 'former') {
      excludeFieldName.push(
        'painSmokingHistoryFormerFrequency',
        'painSmokingHistoryFormerPeriod',
        'painSmokingHistoryFormerPeriodQuit'
      );
    }

    const tbodyList = FIELD_SET_LIST.map<string>(([fieldsetName, fieldNameList]) => {
      const i18n = formI18n[fieldsetName] as any;

      const columnList = fieldNameList
        .filter(
          (fieldName): fieldName is CardiacIntakeGridFieldName =>
            !(['arrhythmia', 'dyspnea', 'legPain', 'pain', 'syncope'] as ReadonlyArray<FieldName>).includes(
              fieldName
            ) &&
            !fieldName.endsWith('OtherFrequency') &&
            !fieldName.endsWith('OtherTrigger') &&
            !fieldName.endsWith('OtherReliefFactor') &&
            !excludeFieldName.includes(fieldName)
        )
        .map<string>((fieldName) => {
          const header = t(i18n.formField[fieldName].label, { language: EnglishCanada });

          let value = Class.formatDataValue(mergedData, fieldName, t, EnglishCanada);

          if (Utils.isString(value)) {
            if (fieldName.endsWith('ReliefFactor') || fieldName.endsWith('Symptoms') || fieldName.endsWith('Trigger')) {
              value = `<ul>${value!
                .split(', ')
                .map((option) => `<li>${option}</li>`)
                .join('')}</ul>`;
            }
          } else if (Utils.isNil(value)) value = t(dataValueNotSet) as string;

          const isFieldNameGroup =
            fieldName === 'painSmokingHistoryCurrentFrequency' ||
            fieldName === 'painSmokingHistoryCurrentPeriod' ||
            fieldName === 'painSmokingHistoryFormerFrequency' ||
            fieldName === 'painSmokingHistoryFormerPeriod' ||
            fieldName === 'painSmokingHistoryFormerPeriodQuit';

          if (isFieldNameGroup) {
            return `<th scope="row" class="indent-2">${header}</th><td>${value}</td>`;
          }

          return `<th scope="row">${header}</th><td>${value}</td>`;
        });

      const isFieldsetText = fieldsetName === 'fieldsetMedications' || fieldsetName === 'fieldsetNotes';

      let fieldsetValue;
      let isFieldsetGroup = false;
      if (!isFieldsetText) {
        const fieldName = Utils.camelCase(fieldsetName.replace('fieldset', '')) as CardiacIntakeGridFieldName;
        isFieldsetGroup =
          (fieldName === 'arrhythmia' ||
            fieldName === 'dyspnea' ||
            fieldName === 'legPain' ||
            fieldName === 'pain' ||
            fieldName === 'syncope') &&
          Boolean(mergedData[fieldName]);
        fieldsetValue = CardiacIntakeDataUtil.formatDataValue(mergedData, fieldName, t);
      }

      if (isFieldsetText) {
        const rowList = `<tr>${columnList.join('</tr><tr>')}</tr>`;
        return `<tbody>${rowList}</tbody>`;
      } else {
        const header = t(i18n.label, { language: EnglishCanada });

        if (isFieldsetGroup) {
          const rowList = `<tr class="indent-1">${columnList.join('</tr><tr class="indent-1">')}</tr>`;
          return `<tbody><tr><th scope="col">${header}</th><td>${fieldsetValue}</td></tr>${rowList}</tbody>`;
        }

        return `<tbody><tr><th scope="col">${header}</th><td>${fieldsetValue}</td></tr></tbody>`;
      }
    }).join('');

    const caption = '<caption>Questionnaire</caption>';
    const thead = '<thead><th>Question</th><th>Answer</th></thead>';

    return `<table class="${DataFormCssClassPrefix}${DataFormNameCardiacIntake}">${caption}${thead}${tbodyList}</table>`;
  }
}
