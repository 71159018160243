module.exports = ({ addUtilities, theme }) => {
  addUtilities({
    '.btn': {
      backgroundColor: 'transparent',
      display: 'inline-block',
      textAlign: 'center',
      verticalAlign: 'middle',
      userSelect: 'none'

      // '&:focus': {
      //   outline: 0
      // },

      // '&:disabled, &.disabled': {
      //   cursor: 'auto'
      // }
    },
    '.btn-sm': {
      paddingBottom: '.5rem',
      paddingTop: '.5rem'
    },
    '.btn-lg': {
      paddingBottom: '1rem',
      paddingTop: '1rem'
    },
    '.btn-rounded': {
      borderRadius: '.25rem'
    },
    '.btn-rounded-full': {
      borderRadius: '9999px'
    },
    '.btn-primary': {
      border: `solid ${theme('borderWidth.default')} ${theme('borderColor.primary')}`,
      backgroundColor: theme('colors.primary'),
      color: theme('colors.white')

      // '&:disabled, &.disabled': {
      //   backgroundColor: theme('colors.primary-dark')
      // }
    },
    '.btn-secondary': {
      backgroundColor: theme('colors.secondary'),
      color: theme('colors.white')

      // '&:disabled, &.disabled': {
      //   backgroundColor: theme('colors.secondary-dark')
      // }
    }
  });
};
