import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.DataViewer.CopdAssessment = {
  category: {
    high: {
      label: gettext('health-data', 'high', 'copdAssessmentDataViewerCategoryLabel'),
      meaning: gettext('health-data', 'high', 'copdAssessmentDataViewerCategoryMeaning'),
      score: gettext('health-data', 'high', 'copdAssessmentDataViewerCategoryScore')
    },
    low: {
      label: gettext('health-data', 'low', 'copdAssessmentDataViewerCategoryLabel'),
      meaning: gettext('health-data', 'low', 'copdAssessmentDataViewerCategoryMeaning'),
      score: gettext('health-data', 'low', 'copdAssessmentDataViewerCategoryScore')
    },
    medium: {
      label: gettext('health-data', 'medium', 'copdAssessmentDataViewerCategoryLabel'),
      meaning: gettext('health-data', 'medium', 'copdAssessmentDataViewerCategoryMeaning'),
      score: gettext('health-data', 'medium', 'copdAssessmentDataViewerCategoryScore')
    },
    veryHigh: {
      label: gettext('health-data', 'veryHigh', 'copdAssessmentDataViewerCategoryLabel'),
      meaning: gettext('health-data', 'veryHigh', 'copdAssessmentDataViewerCategoryMeaning'),
      score: gettext('health-data', 'veryHigh', 'copdAssessmentDataViewerCategoryScore')
    }
  },
  categoryLabel: gettext('health-data', 'categoryLabel', 'copdAssessmentDataViewer'),
  columnHeader: {
    question: gettext('health-data', 'question', 'copdAssessmentDataViewerColumnHeader'),
    score: gettext('health-data', 'score', 'copdAssessmentDataViewerColumnHeader')
  },
  dataNotAvailable: gettext('health-data', 'dataNotAvailable', 'copdAssessmentDataViewer')
};

export default i18n;
