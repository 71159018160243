import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import type { Api, Http } from '../types';
import { URL_BATCH_UPDATE_DATA as API_URL } from './constants';
import { MalformedResponseException } from './malformed-response-exception';
import { ServiceException as ApiServiceException } from './service-exception';

type SuccessResponse = Pick<Api.BatchUpdateResponseData, 'CredentialsOperations' | 'DataOperations' | 'KeysOperations'>;

const Logger = getLoggerWithPrefix('ApiService', 'batchUpdateData:');

export async function apiBatchUpdateData(
  this: Api.Service,
  accessToken: string,
  query: Api.BatchUpdateRequestData,
  init?: Http.RequestInit
): Promise<Api.BatchUpdateResponseData> {
  try {
    Logger.info('== BEGIN ==');
    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.BatchUpdateResponseData>(requestUrl, JSON.stringify(query), {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status === 200) {
          const json = await Utils.tryGetResponseJson<SuccessResponse>(response, undefined);
          if (isValidSuccessResponse(json)) return json;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status >= 400 && response.status < 500) {
          const responseBody = await response.clone().text();
          throw new ApiServiceException(response, responseBody);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}

function isValidSuccessResponse(json: unknown): json is SuccessResponse {
  return (
    Utils.isNonArrayObjectLike<SuccessResponse>(json) &&
    ((Utils.has(json, 'CredentialsOperations') && json.CredentialsOperations === 'success') ||
      (Utils.has(json, 'DataOperations') && json.DataOperations === 'success') ||
      (Utils.has(json, 'KeysOperations') && json.KeysOperations === 'success'))
  );
}
