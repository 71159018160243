import { MessagingActionPayload } from '@sigmail/app-state';
import {
  AppException,
  Constants,
  JsonObject,
  MessagingException,
  ReadonlyMessageBodyReferral as BaseReadonlyMessageBodyReferral,
  SigmailUserId,
  Utils
} from '@sigmail/common';
import {
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadataValue,
  GroupObjectGuestListValue,
  IUserObject,
  MessageFolderItemCount,
  MessageFolderListItem,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { CIRCLE_OF_CARE } from '../../../../constants/medical-institute-user-group-type-identifier';
import { EMPTY_ARRAY } from '../../../constants';
import { userListObjectSelector as selectClientUserList } from '../../../selectors/client-object';
import {
  contactInfoObjectSelector as selectGroupContactInfo,
  guestListObjectSelector as selectGroupGuestList,
  serverRightsObjectSelector as selectGroupServerRights
} from '../../../selectors/group-object';
import * as UserObjectSelectors from '../../../selectors/user-object';
import { UserObjectCache } from '../../../user-objects-slice/cache';
import { ActionInitParams, FetchObjectsRequestData } from '../../base-action';
import { AUTH_STATE_LOG_EVENT, AUTH_STATE_SEND_REFERRAL_RESPONSE } from '../../constants/auth-state-identifier';
import { logEventAction } from '../../log-event-action';
import { updateMessageFolderAction } from '../update-msg-folder-action';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';

const MessageFolderKeyInbox = Constants.MessageFolderKey.Inbox;
const MessageFolderKeyGroupInbox = Constants.MessageFolderKey.GroupInbox;

type ReadonlyMessageBodyReferral = Readonly<{
  messageForm: Omit<BaseReadonlyMessageBodyReferral['messageForm'], 'value'> &
    Readonly<{
      value: Required<BaseReadonlyMessageBodyReferral['messageForm']['value']>;
    }>;
}>;

export interface Payload extends Omit<BaseSendMessagePayload, 'messageBody' | 'sourceMessage'> {
  messageBody: Exclude<BaseSendMessagePayload['messageBody'], BaseReadonlyMessageBodyReferral> | ReadonlyMessageBodyReferral;
  sourceMessage: NonNullable<BaseSendMessagePayload['sourceMessage']>;
}

interface State extends BaseSendMessageState {
  guestUserId: SigmailUserId;
  inboxFolderKey: typeof MessageFolderKeyInbox | typeof MessageFolderKeyGroupInbox;
  sourceMsgBody: BaseReadonlyMessageBodyReferral;
  sourceMsgMetadata: DataObjectMsgMetadataValue;
}

export class SendReferralResponseAction extends BaseSendMessageAction<Payload, State> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params);

    const { folderKey, parentFolderKey, messageBody, messageFormName, sourceMessage } = params.payload;
    if (!Utils.isMessageFormNameReferral(messageFormName)) return;

    const { response } = (messageBody as ReadonlyMessageBodyReferral).messageForm.value;
    if (Utils.isNil(response)) {
      throw new MessagingException('Referral response data is either missing or invalid.');
    }

    if (!Utils.isReferralMessageForm(sourceMessage.messageForm)) {
      throw new MessagingException(
        `Expected source message's form name to be <${Constants.MessageFormName.Referral}>; was <${sourceMessage?.messageForm?.name}>`
      );
    }

    if (folderKey === MessageFolderKeyInbox || parentFolderKey === MessageFolderKeyInbox) {
      this.state.inboxFolderKey = MessageFolderKeyInbox;
    } else if (folderKey === MessageFolderKeyGroupInbox || parentFolderKey === MessageFolderKeyGroupInbox) {
      this.state.inboxFolderKey = MessageFolderKeyGroupInbox;
    } else {
      throw new MessagingException(
        `Operation is not supported for this folder. (folderKey=${folderKey}, parentFolderKey=${String(parentFolderKey)})`
      );
    }
  }

  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();

    const { body, header } = this.payload.sourceMessage;

    const msgBody = (await this.getDataObjectValue<DataObjectMsgBodyValue>(body)) as BaseReadonlyMessageBodyReferral | undefined;
    const msgMetadata = await this.getDataObjectValue<DataObjectMsgMetadataValue>(header);
    if (Utils.isNil(msgBody) || Utils.isNil(msgMetadata)) {
      throw new MessagingException('Message metadata and/or body is either missing or invalid.');
    }

    this.state.guestUserId = msgBody.messageForm.value.patient.id;
    this.state.sourceMsgBody = msgBody;
    this.state.sourceMsgMetadata = msgMetadata;

    return result;
  }

  /** @override */
  protected validateMessageFormName() {
    const { messageFormName } = this.payload;
    if (!Utils.isMessageFormNameReferral(messageFormName)) {
      throw new MessagingException(`Expected message form name to be <${Constants.MessageFormName.Referral}>; was <${messageFormName}>`);
    }
  }

  /** @override */
  protected async createIdsRequestData() {
    const data = await super.createIdsRequestData();
    return { ...data, state: AUTH_STATE_SEND_REFERRAL_RESPONSE };
  }

  /** @override */
  protected async generateIdSequence() {
    const { dtServer, sourceMsgBody } = this.state;

    if (dtServer.getTime() > sourceMsgBody.messageForm.value.expiredAtUtc!) {
      throw new AppException(Constants.Error.S_ERROR, 'Referral has already expired.');
    }

    await super.generateIdSequence();
  }

  /** @override */
  protected async generateRequestBody() {
    await super.generateRequestBody();

    const { response } = (this.payload.messageBody as ReadonlyMessageBodyReferral).messageForm.value;
    if (response.status === 'accepted') {
      await this.encryptGuestUserObjectsForGroupKey();
      await this.addInsertOperationForClientKeyPublic();
      await this.addInsertOperationForAuditKeyPublic();

      await this.addUpdateOperationForGroupGuestList(); // 468
      await this.addUpdateOperationForGuestContactList(); // 406
    }
  }

  /** @override */
  protected async getGuestUserForMetadataValue(
    ...args: Parameters<BaseSendMessageAction['getGuestUserForMetadataValue']>
  ): Promise<DataObjectMsgMetadataValue['guestUser']> {
    if (args.length > 0) return super.getGuestUserForMetadataValue(...args);

    const { guestUserId, roleAuthClaim: authState } = this.state;

    await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [
        { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId: guestUserId },
        { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED, userId: guestUserId }
      ]
    });

    return super.getGuestUserForMetadataValue({
      ...UserObjectCache.getValue(UserObjectSelectors.basicProfileObjectSelector(this.getRootState())(guestUserId)),
      ...UserObjectCache.getValue(UserObjectSelectors.protectedProfileObjectSelector(this.getRootState())(guestUserId))
    });
  }

  /** @override */
  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    const { messageBody } = this.payload;
    const { sourceMsgBody } = this.state;

    return {
      $$formatver: 1,
      messageForm: {
        ...sourceMsgBody.messageForm,
        value: {
          ...sourceMsgBody.messageForm.value,
          ...(messageBody as ReadonlyMessageBodyReferral).messageForm.value
        }
      }
    };
  }

  /** @override */
  protected async addUpdateOperationForCurrentMessageFolder() {
    const { inboxFolderKey, requestBody, successPayload } = this.state;

    await this.dispatch(
      updateMessageFolderAction({
        folderKey: inboxFolderKey,
        requestBody,
        successPayload,
        applyUpdate: this.applyCurrentMessageFolderUpdate.bind(this)
      })
    );
  }

  /** @override */
  protected applyCurrentMessageFolderUpdate(
    folderData: MessageFolderListItem[],
    _: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ) {
    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    const { header: msgMetadataId } = this.payload.sourceMessage;
    const { response } = (this.payload.messageBody as ReadonlyMessageBodyReferral).messageForm.value;

    this.logger.info(`Locating item with metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);
    const index = folderData.findIndex(({ header }) => header === msgMetadataId);
    if (index !== -1) {
      const message = folderData[index];

      folderData[index] = {
        ...message,
        extraData: {
          ...(message.extraData || {}),
          referralResponse: (response as unknown) as JsonObject
        }
      };

      result.updated = result.done = true;
    }

    return result;
  }

  /** @override */
  protected createSentMessage() {
    const message = super.createSentMessage();

    const { response } = (this.payload.messageBody as ReadonlyMessageBodyReferral).messageForm.value;
    const { dtServer } = this.state;

    return {
      ...message,
      extraData: {
        referralResponse: ({
          ...response,
          decidedAt: dtServer.getTime()
        } as unknown) as JsonObject
      }
    };
  }

  protected async encryptGuestUserObjectsForGroupKey(): Promise<void> {
    this.logger.info('Encrypting guest user objects for group key');

    const groupId = this.payload.circleOfCareGroupId!;
    const { guestUserId, requestBody, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [
        { id: groupId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE },
        { id: groupId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC },
        { id: guestUserId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }
      ],
      userObjectsByType: [
        process.env.USER_OBJECT_TYPE_PROFILE_BASIC,
        process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
        process.env.USER_OBJECT_TYPE_CONTACT_INFO,
        process.env.USER_OBJECT_TYPE_CONTACT_LIST,
        process.env.USER_OBJECT_TYPE_SERVER_RIGHTS,
        process.env.USER_OBJECT_TYPE_SCHEDULE,
        process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE,
        process.env.USER_OBJECT_TYPE_HEALTH_DATA,
        process.env.USER_OBJECT_TYPE_EVENT_LOG,
        process.env.USER_OBJECT_TYPE_ENCOUNTER,
        process.env.USER_OBJECT_TYPE_CARE_PLANS
      ].map((typeCode) => ({ type: typeCode, userId: guestUserId }))
    };

    const { keyList, userObjectList } = await this.dispatchFetchObjects(query);

    const guestPublicKeyJson = this.findKey(keyList, { id: guestUserId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC });
    if (Utils.isNil(guestPublicKeyJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch guest user public key.');
    } else {
      const guestKeyPublic = new CryptographicKeyPublic(guestPublicKeyJson);
      await CryptographicKeyPublic.cache(guestKeyPublic);
    }

    const groupPrivateKeyJson = this.findKey(keyList, { id: groupId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE });
    const groupPublicKeyJson = this.findKey(keyList, { id: groupId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC });
    if (Utils.isNil(groupPrivateKeyJson) || Utils.isNil(groupPublicKeyJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch group private/public key.');
    } else {
      const groupKeyPrivate = new CryptographicKeyPrivate(groupPrivateKeyJson);
      const groupKeyPublic = new CryptographicKeyPublic(groupPublicKeyJson);
      const keyList = await Promise.all([groupKeyPrivate.encryptFor(guestUserId), groupKeyPublic.encryptFor(guestUserId)]);
      requestBody.insert(keyList);
      await CryptographicKeyPublic.cache(groupKeyPublic);
    }

    const serverRightsJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS, userId: guestUserId });
    const objectList = [
      UserObjectSelectors.basicProfileObjectSelector,
      UserObjectSelectors.protectedProfileObjectSelector,
      UserObjectSelectors.contactInfoObjectSelector,
      UserObjectSelectors.contactListObjectSelector,
      () => () =>
        Utils.isNil(serverRightsJson)
          ? undefined
          : (new UserObjectServerRights(serverRightsJson) as IUserObject<UserObjectServerRightsValue>),
      UserObjectSelectors.circleOfCareObjectSelector,
      UserObjectSelectors.scheduleObjectSelector,
      UserObjectSelectors.healthDataObjectSelector,
      UserObjectSelectors.eventLogObjectSelector,
      UserObjectSelectors.encounterObjectSelector,
      UserObjectSelectors.carePlansObjectSelector
    ]
      .map((selector) => selector(this.getRootState())(guestUserId))
      .filter(Utils.isNotNil);

    if (objectList.length !== query.userObjectsByType!.length) {
      throw new MessagingException('One or more user objects could not be found.');
    }

    const generatedKeyList = await Promise.all(objectList.map((obj) => obj.generateKeysEncryptedFor(groupId)));
    generatedKeyList.forEach((keys) => requestBody.insert(keys.filter(Utils.isNotNil)));
  }

  private async addInsertOperationForClientKeyPublic(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for client public key.');

    const { clientId, guestUserId, requestBody, roleAuthClaim: authState } = this.state;

    const { keyList } = await this.dispatchFetchObjects({
      authState,
      keysByType: [{ id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }]
    });

    const clientPublicKeyJson = this.findKey(keyList, { id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC });
    if (Utils.isNil(clientPublicKeyJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch client public key.');
    } else {
      const clientKeyPublic = new CryptographicKeyPublic(clientPublicKeyJson);
      requestBody.insert(await clientKeyPublic.encryptFor(guestUserId));
    }
  }

  private async addInsertOperationForAuditKeyPublic(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for audit public key.');

    const { auditId, guestUserId, requestBody, roleAuthClaim: authState } = this.state;

    const { keyList } = await this.dispatchFetchObjects({
      authState,
      keysByType: [{ id: auditId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }]
    });

    const auditPublicKeyJson = this.findKey(keyList, { id: auditId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC });
    if (Utils.isNil(auditPublicKeyJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch audit public key.');
    } else {
      const auditKeyPublic = new CryptographicKeyPublic(auditPublicKeyJson);
      requestBody.insert(await auditKeyPublic.encryptFor(guestUserId));
    }
  }

  private async addUpdateOperationForGroupGuestList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group guest list.');

    const groupId = this.payload.circleOfCareGroupId!;
    const { header, body } = this.payload.sourceMessage;
    const { roleAuthClaim: authState, clientId, guestUserId, requestBody, successPayload } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [{ id: guestUserId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }],
      userObjectsByType: [
        { type: process.env.CLIENT_OBJECT_TYPE_USER_LIST, userId: clientId },
        { type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO, userId: groupId },
        { type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST, userId: groupId },
        { type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS, userId: groupId }
      ]
    };

    const { keyList } = await this.dispatchFetchObjects(query);

    const groupServerRightsObject = selectGroupServerRights(this.getRootState())(groupId);
    const groupServerRights = UserObjectCache.getValue(groupServerRightsObject);
    if (Utils.isNil(groupServerRights)) {
      throw new MessagingException('Group server rights is either missing or invalid.');
    }

    this.state.batchUpdateClaims.push(groupServerRights.groupClaim);

    const guestUserPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: guestUserId });
    if (Utils.isNil(guestUserPublicKeyJson)) {
      throw new MessagingException('Guest user public key could not be fetched.');
    } else {
      const guestUserKeyPublic = new CryptographicKeyPublic(guestUserPublicKeyJson);
      const keyEncryptedForGroup = await CryptographicKeyPublic.encryptFor(guestUserKeyPublic, groupId);
      requestBody.insert(keyEncryptedForGroup);
    }

    const groupGuestListObject = selectGroupGuestList(this.getRootState())(groupId);
    const groupGuestList = UserObjectCache.getValue(groupGuestListObject);
    if (Utils.isNil(groupGuestListObject) || Utils.isNil(groupGuestList)) {
      throw new MessagingException('Group guest list is either missing or invalid.');
    }

    const guestProtectedProfileObject = UserObjectSelectors.protectedProfileObjectSelector(this.getRootState())(guestUserId);
    const guestProtectedProfile = UserObjectCache.getValue(guestProtectedProfileObject);
    const guestContactInfoObject = UserObjectSelectors.contactInfoObjectSelector(this.getRootState())(guestUserId);
    const guestContactInfo = UserObjectCache.getValue(guestContactInfoObject);
    if (Utils.isNil(guestProtectedProfileObject) || Utils.isNil(guestProtectedProfile)) {
      throw new MessagingException('Guest protected profile is either missing or invalid.');
    } else if (Utils.isNil(guestContactInfoObject) || Utils.isNil(guestContactInfo)) {
      throw new MessagingException('Guest contact info is either missing or invalid.');
    }

    const guestContact: Extract<typeof groupGuestList.list[0], { type: 'user' }> = {
      id: guestUserId,
      type: 'user',
      userData: {
        ...Utils.omit(guestContactInfo, '$$formatver'),
        birthDate: guestProtectedProfile.birthDate // use non-masked birth date
      }
    };

    let updatedList: Array<typeof groupGuestList.list[0]>;
    const index = groupGuestList.list.findIndex(({ type, id }) => type === 'user' && id === guestUserId);
    if (index === -1) {
      const clientUserListObject = selectClientUserList(this.getRootState())(clientId);
      const clientUserList = UserObjectCache.getValue(clientUserListObject);
      if (Utils.isNil(clientUserList)) {
        throw new MessagingException('Client user list is either missing or invalid.');
      }

      const isClientUser = ([clientUserList.active, clientUserList.inactive, clientUserList.pending] as const).some((list) =>
        list.some(({ id }) => id === guestUserId)
      );

      updatedList = groupGuestList.list.concat({
        ...guestContact,
        userData: {
          ...guestContact.userData,
          memberType: isClientUser ? 'otherGroup' : 'nonClient',
          referralMsgList: [{ header, body }]
        }
      });
    } else {
      const { userData: prevData } = groupGuestList.list[index] as typeof guestContact;

      type ReferralMsgListItem = NonNullable<typeof guestContact.userData.referralMsgList>[0];
      let referralMsgList: Array<ReferralMsgListItem> | undefined;
      if (Utils.isNotNil(prevData.memberType)) {
        referralMsgList = Utils.arrayOrDefault<ReferralMsgListItem>(prevData.referralMsgList, EMPTY_ARRAY).slice();
        if (!referralMsgList.some((entry) => entry.header === header && entry.body === body)) {
          referralMsgList.push({ body, header });
        }
      }

      updatedList = groupGuestList.list.slice();
      updatedList[index] = {
        ...guestContact,
        userData: {
          ...guestContact.userData,
          memberType: prevData.memberType,
          referralMsgList
        }
      };
    }

    const updatedValue: GroupObjectGuestListValue = { ...groupGuestList, list: updatedList };
    const updatedObject = await groupGuestListObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    successPayload.request.userObjects!.ids.push(updatedObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  protected async addUpdateOperationForGuestContactList(): Promise<void> {
    this.logger.info("Adding an update operation to request body for guest's contact list.");

    const groupId = this.payload.circleOfCareGroupId!;
    const { batchUpdateClaims, guestUserId, requestBody, roleAuthClaim: authState, successPayload } = this.state;

    const { claims } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [
        { type: process.env.USER_OBJECT_TYPE_CONTACT_LIST, userId: guestUserId },
        { type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO, userId: groupId }
      ]
    });

    if (Utils.isNil(this.findClaim(claims, { name: 'keyAvailable' }))) {
      throw new Api.MalformedResponseException(
        Constants.Error.E_CLAIM_MISSING_OR_INVALID,
        '<keyAvailable> claim is either missing or invalid.'
      );
    }

    batchUpdateClaims.push(...claims);

    const guestContactListObject = await this.getUserObject(UserObjectSelectors.contactListObjectSelector, { userId: guestUserId });
    const guestContactList = UserObjectCache.getValue(guestContactListObject);
    if (Utils.isNil(guestContactListObject) || Utils.isNil(guestContactList)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch guest contact list object.');
    }

    const groupContactInfo = await this.getUserObjectValue(selectGroupContactInfo, { userId: groupId });
    if (Utils.isNil(groupContactInfo)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, "Failed to fetch active group's contact info object.");
    }

    const entry: typeof updatedList[0] = {
      groupData: {
        groupName: groupContactInfo.groupName,
        institute: groupContactInfo.institute
      },
      groupType: CIRCLE_OF_CARE,
      id: groupId,
      type: 'group'
    };

    let updatedList: Array<typeof guestContactList.contacts[0]>;
    const index = guestContactList.contacts.findIndex(({ id, type }) => type === 'group' && id === groupId);
    if (index === -1) {
      updatedList = guestContactList.contacts.concat(entry);
    } else {
      updatedList = guestContactList.contacts.slice();
      updatedList[index] = entry;
    }

    const updatedObject = await guestContactListObject.updateValue({ ...guestContactList, contacts: updatedList });
    requestBody.update(updatedObject);

    successPayload.request.userObjects!.ids.push(updatedObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  /** @override */
  protected async addUpdateOperationForUserEventLog(): Promise<void> {
    const {
      currentUser,
      dtServer,
      msgBodyId,
      msgMetadataId,
      requestBody,
      roleAuthClaim: authState,
      sourceMsgBody,
      successPayload
    } = this.state;

    const { status } = (this.payload.messageBody as ReadonlyMessageBodyReferral).messageForm.value.response;
    const code = status === 'accepted' ? Constants.EventLogCode.ReferralAccepted : Constants.EventLogCode.ReferralDeclined;

    const claims = await this.dispatch(
      logEventAction({
        dtServer,
        fetchIds: (count) => {
          return this.dispatchFetchIdsByUsage({
            authState,
            state: AUTH_STATE_LOG_EVENT,
            ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
          });
        },
        logger: this.logger,
        record: this.newEventLogRecordValue(dtServer, code, msgMetadataId, msgBodyId, sourceMsgBody.messageForm.value),
        requestBody,
        successPayload,
        userId: currentUser.id,
        userIdType: 'user'
      })
    );

    this.state.batchUpdateClaims.push(...claims);
  }
}
