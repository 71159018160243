import { MultiFactorAuthI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MultiFactorAuthI18n.Form.SelectMethod = {
  fieldsetDefault: {
    // Translators: Label for the Select MFA Method fieldset
    label: gettext('mfa', 'selectMethodForm', 'fieldsetLabelMfaMethod'),
    formField: {
      mfaMethod: {
        label: gettext('mfa', 'selectMethodForm', 'optionGroupLabelMfaMethod'),
        optionList: [
          {
            method: 'sms',
            // Translators: Label for the Text Message option
            label: gettext('mfa', 'selectMethodForm', 'optionLabelMfaMethodSMS')
          },
          {
            method: 'email',
            // Translators: Label for the Email option
            label: gettext('mfa', 'selectMethodForm', 'optionLabelMfaMethodEmail')
          }
        ],
        error: {
          // Translators: Error message displayed when MFA Method is not \
          // selected
          valueMissing: gettext('mfa', 'selectMethodForm', 'errorMessageValueMissingMfaMethod')
        }
      }
    }
  }
};

export default i18n;
