import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ContactActionToolbar, ManageMembers } from '../../constants/action-context';
import accountI18n from '../account';
import groupsI18n from '../groups';
import messagingI18n from '../messaging';

const {
  activateAccount,
  deactivateAccount,
  sendGuestAccountInvitation,
  sendNonGuestAccountInvitation,
  removeAccountInvitation,
  resendAccountInvitation,
  revokeAccountInvitation
} = accountI18n.accountToolbarAction;

const { composeConsultationRequest, composeHrmMessage, composeMessage, composeReferral } = messagingI18n.messageToolbarAction;

const { assignUsersToGroup, inviteUsersToGroup, removeUsersFromGroup, transferUsersToGroup } = groupsI18n.groupToolbarAction;

const i18n: ContactListI18n.ContactToolbarActionI18n = {
  activateAccount,
  assignUsersToGroup,
  composeConsultationRequest,
  composeHrmMessage,
  composeMessage,
  composeReferral,
  createGuestContactGroup: {
    // Translators: Label for the Create Guest Contact Group action
    label: gettext('contact-list', 'createGuestContactGroup', 'contactToolbarActionLabel'),
    context: ContactActionToolbar
  },
  deactivateAccount,
  editGuestProfile: {
    // Translators: Label for the Edit Guest Profile action
    label: gettext('contact-list', 'editGuestProfile', 'contactToolbarActionLabel'),
    context: ContactActionToolbar
  },
  inviteUsersToGroup,
  filter: {
    // Translators: Label for the Filter action
    label: gettext('contact-list', 'filter', 'contactToolbarActionLabel'),
    context: ContactActionToolbar
  },
  removeUsersFromGroup,
  sendGuestAccountInvitation,
  sendNonGuestAccountInvitation,
  removeAccountInvitation,
  requestHealthData: {
    // Translators: Label for the Request Health Data action
    label: gettext('contact-list', 'requestHealthData', 'contactToolbarActionLabel'),
    context: ContactActionToolbar
  },
  resendAccountInvitation,
  resetFilter: {
    // Translators: Label for the Reset Filter action
    label: gettext('contact-list', 'resetFilter', 'contactToolbarActionLabel'),
    context: ContactActionToolbar
  },
  revokeAccountInvitation,
  sendCaregiverAccountInvitation: {
    // Translators: Label for the Send Caregiver Request action
    label: gettext('contact-list', 'sendCaregiverAccountInvitation', 'contactToolbarActionLabel'),
    context: ContactActionToolbar
  },
  transferUsersToGroup,
  viewCarePlans: {
    // Translators: Label for the View Care Plan action
    label: gettext('contact-list', 'viewCarePlans', 'contactToolbarActionLabel'),
    context: ContactActionToolbar
  },
  viewContactDetails: {
    label: (_, context) => {
      if (context === ManageMembers) {
        // Translators: Label for the View Member Details action
        return gettext('contact-list', 'viewMemberDetails', 'contactToolbarActionLabel');
      } else {
        // Translators: Label for the View Contact Details action
        return gettext('contact-list', 'viewContactDetails', 'contactToolbarActionLabel');
      }
    }
  },
  viewHealthData: {
    // Translators: Label for the View Health Data action
    label: gettext('contact-list', 'viewHealthData', 'contactToolbarActionLabel'),
    context: ContactActionToolbar
  }
};

export default i18n;
