import { Utils } from '@sigmail/common';
import { getLogger } from '@sigmail/logging';
import React from 'react';
import { Track } from 'twilio-video';
import { AudioTrack } from './audio-track.component';
import { IAudioTrack, ITrackPublication, IVideoTrack } from './types';
import { Props as VideoTrackProps, VideoTrack } from './video-track.component';

const Logger = getLogger('MeetingRoomTrackPublication');

export interface Props {
  children?: never;
  publication: ITrackPublication;
  isLocalParticipant?: boolean | null | undefined;
  videoOnly?: boolean | null | undefined;
  videoPriority?: Track.Priority | null | undefined;
}

interface State {
  track: ITrackPublication['track'];
  mediaStreamTrack: MediaStreamTrack | null;
  videoTrackDimensions: Readonly<{ width: number; height: number }>;
}

interface AddRemoveEventListener {
  (eventName: 'trackStarted', track: ITrackPublication['track']): void;
  (eventName: 'trackDimensionsChanged', track: ITrackPublication['track']): void;
  (eventName: 'publicationSubscribed', publication: ITrackPublication): void;
  (eventName: 'publicationUnsubscribed', publication: ITrackPublication): void;
}

const EVENT_NAME_TRACK_STARTED = 'started';
const EVENT_NAME_TRACK_DIMENSIONS_CHANGED = 'dimensionsChanged';
const EVENT_NAME_PUBLICATION_SUBSCRIBED = 'subscribed';
const EVENT_NAME_PUBLICATION_UNSUBSCRIBED = 'unsubscribed';

const NULL_VIDEO_TRACK_DIMENSIONS: State['videoTrackDimensions'] = { width: 0, height: 0 };
function getVideoTrackDimensions(track?: IVideoTrack | null | undefined): State['videoTrackDimensions'] {
  return track?.kind !== 'video'
    ? NULL_VIDEO_TRACK_DIMENSIONS
    : {
        width: +track!.dimensions.width! || 0,
        height: +track!.dimensions.height! || 0
      };
}

class TrackPublicationComponent extends React.PureComponent<Props, State> {
  public static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: State): Partial<State> | null {
    const { track } = nextProps.publication;
    const { track: prevTrack } = prevState;

    if (track !== prevTrack) {
      return {
        track,
        mediaStreamTrack: (track as IAudioTrack | IVideoTrack).mediaStreamTrack,
        videoTrackDimensions: getVideoTrackDimensions(track as IVideoTrack)
      };
    }

    return null;
  }

  protected readonly addEventListener: AddRemoveEventListener;
  protected readonly removeEventListener: AddRemoveEventListener;

  public constructor(props: Props) {
    super(props);

    const { track } = props.publication;
    this.state = {
      track,
      mediaStreamTrack: track === null ? null : (track as IAudioTrack | IVideoTrack).mediaStreamTrack,
      videoTrackDimensions: getVideoTrackDimensions(track as IVideoTrack)
    };

    // suppress React's warning of using getSnapshotBeforeUpdate without
    // componentDidUpdate
    this.componentDidUpdate = Utils.noop;

    this.onTrackStarted = this.onTrackStarted.bind(this);
    this.onTrackDimensionsChanged = this.onTrackDimensionsChanged.bind(this);
    this.onPublicationSubscribed = this.onPublicationSubscribed.bind(this);
    this.onPublicationUnsubscribed = this.onPublicationUnsubscribed.bind(this);

    this.addEventListener = (eventName, ...args: any[]) => {
      if (args.length === 0 || Utils.isNil(args[0])) return;

      if (
        eventName === 'trackDimensionsChanged' &&
        (args[0] as NonNullable<ITrackPublication['track']>).kind !== 'video'
      ) {
        return;
      }

      Logger.info('addEventListener:', `eventName=${eventName},`, args);
      switch (eventName) {
        case 'trackStarted': {
          const track = args[0] as NonNullable<ITrackPublication['track']>;
          track.on(EVENT_NAME_TRACK_STARTED, this.onTrackStarted);
          break;
        }
        case 'trackDimensionsChanged': {
          const track = args[0] as NonNullable<ITrackPublication['track']>;
          track.on(EVENT_NAME_TRACK_DIMENSIONS_CHANGED, this.onTrackDimensionsChanged);
          break;
        }
        case 'publicationSubscribed': {
          const publication = args[0] as ITrackPublication;
          publication.on(EVENT_NAME_PUBLICATION_SUBSCRIBED, this.onPublicationSubscribed);
          break;
        }
        case 'publicationUnsubscribed': {
          const publication = args[0] as ITrackPublication;
          publication.on(EVENT_NAME_PUBLICATION_UNSUBSCRIBED, this.onPublicationUnsubscribed);
          break;
        }
        default: {
          break;
        }
      }
    };

    this.removeEventListener = (eventName, ...args: any[]) => {
      if (args.length === 0 || Utils.isNil(args[0])) return;

      if (
        eventName === 'trackDimensionsChanged' &&
        (args[0] as NonNullable<ITrackPublication['track']>).kind !== 'video'
      ) {
        return;
      }

      Logger.info('removeEventListener:', `eventName=${eventName},`, args);
      switch (eventName) {
        case 'trackStarted': {
          const track = args[0] as NonNullable<ITrackPublication['track']>;
          track.off(EVENT_NAME_TRACK_STARTED, this.onTrackStarted);
          break;
        }
        case 'trackDimensionsChanged': {
          const track = args[0] as NonNullable<ITrackPublication['track']>;
          track.off(EVENT_NAME_TRACK_DIMENSIONS_CHANGED, this.onTrackDimensionsChanged);
          break;
        }
        case 'publicationSubscribed': {
          const publication = args[0] as ITrackPublication;
          publication.off(EVENT_NAME_PUBLICATION_SUBSCRIBED, this.onPublicationSubscribed);
          break;
        }
        case 'publicationUnsubscribed': {
          const publication = args[0] as ITrackPublication;
          publication.off(EVENT_NAME_PUBLICATION_UNSUBSCRIBED, this.onPublicationUnsubscribed);
          break;
        }
        default: {
          break;
        }
      }
    };

    this.addEventListener('trackStarted', track);
    this.addEventListener('trackDimensionsChanged', track);
    this.addEventListener('publicationSubscribed', props.publication);
    this.addEventListener('publicationUnsubscribed', props.publication);
  }

  public getSnapshotBeforeUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): any {
    const { publication } = this.props;
    const { publication: prevPublication } = prevProps;
    if (prevPublication !== publication) {
      Logger.info(
        `Publication changed:`,
        `current={kind:${publication.kind}, trackName:${publication?.trackName}},`,
        `previous={kind:${prevPublication.kind}, trackName:${prevPublication?.trackName}}`
      );
      this.removeEventListener('publicationSubscribed', prevPublication);
      this.removeEventListener('publicationUnsubscribed', prevPublication);
      this.addEventListener('publicationSubscribed', publication);
      this.addEventListener('publicationUnsubscribed', publication);
    }

    const { track } = this.state;
    const { track: prevTrack } = prevState;
    if (track !== prevTrack) {
      Logger.info(
        `Track changed:`,
        `current={kind:${track?.kind}, name:${track?.name}},`,
        `previous={kind:${prevTrack?.kind}, name:${prevTrack?.name}}`
      );
      this.removeEventListener('trackStarted', prevTrack);
      this.removeEventListener('trackDimensionsChanged', prevTrack);
      this.addEventListener('trackStarted', track);
      this.addEventListener('trackDimensionsChanged', track);
    }

    if (this.state.mediaStreamTrack !== prevState.mediaStreamTrack) {
      Logger.info(
        `Media stream track changed:`,
        `currentFacingMode=${this.state.mediaStreamTrack?.getSettings().facingMode},`,
        `previousFacingMode=${prevState.mediaStreamTrack?.getSettings().facingMode}`
      );
    }

    if (this.state.videoTrackDimensions !== prevState.videoTrackDimensions) {
      Logger.info(
        `Video track dimensions changed:`,
        `current={width:${this.state.videoTrackDimensions.width}, height:${this.state.videoTrackDimensions.height}},`,
        `previous={width:${prevState.videoTrackDimensions.width}, height:${prevState.videoTrackDimensions.height}}`
      );
    }

    return null;
  }

  public componentWillUnmount(): void {
    const { publication } = this.props;
    const { track } = this.state;

    this.removeEventListener('trackStarted', track);
    this.removeEventListener('trackDimensionsChanged', track);
    this.removeEventListener('publicationSubscribed', publication);
    this.removeEventListener('publicationUnsubscribed', publication);
  }

  public render(): React.ReactNode {
    const {
      publication: { track },
      isLocalParticipant,
      videoOnly,
      videoPriority
    } = this.props;

    const { mediaStreamTrack, videoTrackDimensions: dimensions } = this.state;

    switch (track?.kind) {
      case 'audio': {
        return videoOnly === true ? null : <AudioTrack track={track as IAudioTrack} />;
      }
      case 'video': {
        let style: NonNullable<VideoTrackProps['style']> = {};

        // local video track is mirrored if it is not facing the environment
        const isLocal = isLocalParticipant === true && !track.name.includes('screen');
        const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== 'environment';
        if (isLocal && isFrontFacing) {
          style.transform = 'rotateY(180deg)';
        }

        const isPortrait = dimensions.height > dimensions.width;
        if (isPortrait || track.name.includes('screen')) {
          style.objectFit = 'contain';
        }

        return <VideoTrack track={track as IVideoTrack} priority={videoPriority} style={style} />;
      }
      default: {
        return null;
      }
    }
  }

  protected onTrackStarted(): void {
    this.setState(({ track }) => ({
      mediaStreamTrack: (track as IAudioTrack | IVideoTrack).mediaStreamTrack,
      videoTrackDimensions: getVideoTrackDimensions(track as IVideoTrack)
    }));
  }

  protected onTrackDimensionsChanged(track: IVideoTrack): void {
    this.setState({ videoTrackDimensions: getVideoTrackDimensions(track) });
  }

  protected onPublicationSubscribed(track: ITrackPublication['track']) {
    this.setState({ track });
  }

  protected onPublicationUnsubscribed() {
    this.setState({ track: null, mediaStreamTrack: null });
  }
}

export const TrackPublication = TrackPublicationComponent;
(TrackPublication as any).displayName = 'TrackPublication';
