import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { getRoutePath } from '../../app/routes';
import * as ActionId from '../../constants/action-ids';
import * as RouteId from '../../constants/route-identifiers';

const i18n: GlobalI18n['action'] = {
  [ActionId.SkipToNavMenu]: {
    // Translators: Label for the Skip To Navigation Menu action (available to screen readers only)
    label: gettext('global', 'global', 'actionLabelSkipToNavMenu'),
    href: '#sr-nav'
  },
  [ActionId.SkipToMainContent]: {
    // Translators: Label for the Skip To Main Content action (available to screen readers only)
    label: gettext('global', 'global', 'actionLabelSkipToMainContent'),
    href: '#sr-content'
  },
  [ActionId.LanguageToggle]: {
    // Translators: Label for the Language Toggle action
    label: gettext('global', 'global', 'actionLabelLanguageToggle'),
    href: () => window.location.href
  },
  [ActionId.AccountManageSelf]: {
    // Translators: Label for the My Account action
    label: gettext('global', 'global', 'actionLabelMyAccount'),
    to: getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_SELF)
  },
  [ActionId.AccountManageCaregivers]: {
    // Translators: Label for the Manage Caregivers action
    label: gettext('global', 'global', 'actionLabelManageCaregiver'),
    to: getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_CAREGIVERS)
  },
  [ActionId.AccountManageCarePlans]: {
    // Translators: Label for the Manage Care Plans action
    label: gettext('global', 'global', 'actionLabelManageCarePlans'),
    to: getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_CARE_PLANS)
  },
  [ActionId.AccountManageMembers]: {
    // Translators: Label for the Manage Members action
    label: gettext('global', 'global', 'actionLabelManageMembers'),
    to: getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_MEMBERS)
  },
  [ActionId.AccountManageGroups]: {
    // Translators: Label for the Manage Groups action
    label: gettext('global', 'global', 'actionLabelManageGroups'),
    to: getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_GROUPS)
  },
  [ActionId.BillingFile]: {
    // Translators: Label for the Billing action
    label: gettext('global', 'global', 'actionLabelBilling'),
    to: ''
  },
  [ActionId.SendGuestAccountInvitation]: {
    // Translators: Label for the Send Guest Account Invitation action
    label: gettext('global', 'global', 'actionLabelSendGuestAccountInvitation'),
    to: ''
  },
  [ActionId.Mailbox]: {
    // Translators: Label for the Mailbox action
    label: gettext('global', 'global', 'actionLabelMailbox'),
    to: getRoutePath(RouteId.ROUTE_MAIL)
  },
  [ActionId.Schedule]: {
    // Translators: Label for the Schedule action
    label: gettext('global', 'global', 'actionLabelSchedule'),
    to: getRoutePath(RouteId.ROUTE_SCHEDULE)
  },
  [ActionId.ContactList]: {
    // Translators: Label for the Contact List action
    label: gettext('global', 'global', 'actionLabelContactList'),
    to: getRoutePath(RouteId.ROUTE_CONTACT_LIST)
  },
  [ActionId.DocumentList]: {
    // Translators: Label for the Document List action
    label: gettext('global', 'global', 'actionLabelDocumentList'),
    to: getRoutePath(RouteId.ROUTE_DOCUMENT_LIST)
  },
  [ActionId.FAQ]: {
    // Translators: Label for the FAQ action
    label: gettext('global', 'global', 'actionLabelFAQ'),
    to: getRoutePath(RouteId.ROUTE_FAQ),
    href: 'https://www.sigmahealthtech.com/#faq'
  },
  [ActionId.TermsAndConditions]: {
    // Translators: Label for the Terms and Conditions action
    label: gettext('global', 'global', 'actionLabelTermsAndConditions'),
    to: getRoutePath(RouteId.ROUTE_TERMS_AND_CONDITIONS),
    href: 'https://www.sigmahealthtech.com/terms-of-use'
  },
  [ActionId.PrivacyPolicy]: {
    // Translators: Label for the Privacy Policy action
    label: gettext('global', 'global', 'actionLabelPrivacyPolicy'),
    to: getRoutePath(RouteId.ROUTE_PRIVACY_POLICY),
    href: 'https://www.sigmahealthtech.com/privacy-policy'
  },
  [ActionId.ContactUs]: {
    // Translators: Label for the Contact Us action
    label: gettext('global', 'global', 'actionLabelContactUs'),
    to: getRoutePath(RouteId.ROUTE_CONTACT_US)
  },
  [ActionId.SignOut]: {
    // Translators: Label for the Sign Out action
    label: gettext('global', 'global', 'actionLabelSignOut'),
    to: ''
  }
};

export default i18n;
