import type { AccountActionPayload } from '@sigmail/app-state';
import { getLoggerWithPrefix } from '@sigmail/logging';
import type { AppThunk } from '../../..';
import { EMPTY_PLAIN_OBJECT } from '../../../constants';
import { AuthenticatedAction } from '../../authenticated-action';
import type { ActionInitParams } from '../../base-action';
import type { BaseProcessNotificationsPayload } from './base';
import { ProcessCaregiverUpdateNotificationsAction } from './caregiver-update';
import { ProcessGuestProfileUpdateNotificationsAction } from './guest-profile-update';

const Logger = getLoggerWithPrefix('Action', 'processNotificationsAction:');

type Payload = AccountActionPayload.ProcessNotifications;

export const processNotificationsAction = (payload: Payload): AppThunk<Promise<void>> => {
  return async (dispatch, getState, { apiService }) => {
    let actionPayload = EMPTY_PLAIN_OBJECT as BaseProcessNotificationsPayload;

    try {
      const params: ActionInitParams<typeof actionPayload> = {
        apiService,
        dispatch,
        getState,
        logger: Logger,
        payload: actionPayload
      };

      let action: AuthenticatedAction<typeof params.payload>;
      switch (payload.type) {
        case 'profileUpdate': {
          action = new ProcessGuestProfileUpdateNotificationsAction(params);
          break;
        }
        case 'caregiverUpdate':
          action = new ProcessCaregiverUpdateNotificationsAction(params);
          break;
        default: {
          throw new Error(`Unhandled case - ${payload.type}`);
        }
      }

      return action.execute();
    } catch (error) {
      Logger.warn(`Error while processing notifications of type <${payload.type}>:`, error);
      /* ignore */
    }
  };
};
