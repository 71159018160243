import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogEMRTokenExpired } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.EMR.OAuthParamsMissing = {
  // Translators: (HTML) Title for the EMR Token Expired dialog
  title: gettext('messaging', 'title', 'dialogEMRTokenExpired', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of EMR Token \
  // Expired dialog
  body: gettext('messaging', 'body', 'dialogEMRTokenExpired', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of EMR Token Expired dialog
      label: gettext('messaging', 'accept', 'dialogEMRTokenExpiredActionLabel'),
      context: DialogEMRTokenExpired
    }
  }
};

export default i18n;
