import React from 'react';
import { moveToArchivedMessagesAction } from '../../../../app-state/actions/messaging/move-to-archived-messages-action';
import { useAppState } from '../../../../app-state/hooks';
import { ActionData } from '../../types';
import { BaseContextValue } from '../context';

export interface ActionDataArchiveMessage extends ActionData {
  currentFolder?: BaseContextValue['currentFolder'];
  messageList: ReturnType<BaseContextValue['getSelectedMessageList']>;
}

export const useArchiveMessageActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({ currentFolder, failCallback, messageList, successCallback }: ActionDataArchiveMessage) => {
      let value: any;

      try {
        value = await appDispatch(
          moveToArchivedMessagesAction({
            folderKey: currentFolder?.folderKey!,
            parentFolderKey: currentFolder?.parentFolderKey,
            msgMetadataId: messageList.map(({ header }) => header)
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
