import { Constants, GenderIdentity, Nullable, Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../../../app-state/actions/constants';
import { activeGroupIdSelector } from '../../../app-state/selectors';
import { SendGuestAccountInvitation } from '../../../constants/action-ids';
import { EnglishCanada } from '../../../constants/language-codes';
import { FormApi } from '../../shared/form';
import { FieldName, FormValues, Props as FormProps } from '../form/send-guest-account-invitation.component';
import { OnClickActionHandler } from '../types';
import { ActionDataSendGuestAccountInvitation } from './use-send-guest-account-invitation-action-handler';

export interface Params {
  actionClickHandler?: Nullable<OnClickActionHandler>;
  locale: string;
}

export interface Result {
  formApi: FormApi<FieldName, FormValues> | undefined;
  formId: string | undefined;
  formSubmitting: boolean;
  props: FormProps;
  setFormRef: React.RefCallback<HTMLFormElement | null> | undefined;
}

export const useSendGuestAccountInvitationFormState = (params?: Nullable<Params> | false): Result => {
  const noop = !Utils.isNonArrayObjectLike<Exclude<typeof params, false>>(params);

  let actionClickHandler: Params['actionClickHandler'];
  let locale: Params['locale'] = EnglishCanada;
  if (!noop) ({ actionClickHandler, locale } = params);

  const groupId = useSelector(activeGroupIdSelector);
  const [formApi, setFormApi] = React.useState<FormApi<FieldName, FormValues>>();
  const [formId, setFormId] = React.useState<string>();
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  const onFormReady = React.useMemo<FormProps['onReady']>(() => {
    return noop
      ? undefined
      : ({ api }) => {
          if (process.env.REACT_APP_ENV === 'local') {
            const { faker } = require('@faker-js/faker');
            const gender = faker.helpers.arrayElement([Constants.Gender.Male, Constants.Gender.Female]);

            api.initialize({
              firstName: faker.name.firstName(gender),
              lastName: faker.name.lastName(gender),
              birthDate: faker.date.between('1950-01-01', '2000-01-01'),
              gender: gender,
              homeNumber: `(${faker.random.numeric(3)}) ${faker.random.numeric(3)}-${faker.random.numeric(4)}`,
              // cellNumber: `(${faker.random.numeric(3)}) ${faker.random.numeric(3)}-${faker.random.numeric(4)}`,
              cellNumber: '(431) 778-5436',
              emailAddress: 'hniyitegeka@sigmahealthtech.com',
              jurisdiction: DEFAULT_HEALTH_PLAN_JURISDICTION,
              notifyBy: 'email',
              planNumber: faker.random
                .numeric(4)
                .concat('-567-890-')
                .concat(faker.random.alpha({ bannedChars: ['i', 'o', 'x'], count: 2, upcase: true }))
            });
          }
          setFormApi(api);
        };
  }, [noop]);

  const onFormStateChange = React.useMemo<FormProps['onFormStateChange']>(() => {
    return noop ? undefined : ({ formState: { submitting } }) => setFormSubmitting(submitting === true);
  }, [noop]);

  const onSubmit = React.useMemo<FormProps['onSubmit']>(() => {
    return noop
      ? undefined
      : ({ gender, jurisdiction: healthPlanJurisdiction, planNumber: hpn, notifyBy, ...values }) => {
          if (typeof actionClickHandler !== 'function') return;

          const birthDate = values.birthDate.toISOString();
          const maskedBirthDate = Utils.maskBirthDate(birthDate, locale);

          const actionData: ActionDataSendGuestAccountInvitation = {
            ...values,
            birthDate,
            groupList: [{ id: groupId, name: undefined! }],
            gender: gender as GenderIdentity,
            healthCardNumber: hpn.toUpperCase(),
            healthPlanJurisdiction,
            maskedBirthDate,
            notifyBy: notifyBy as ActionDataSendGuestAccountInvitation['notifyBy']
          };

          return Promise.resolve(actionClickHandler(SendGuestAccountInvitation, actionData));
        };
  }, [actionClickHandler, groupId, locale, noop]);

  const setFormRef = React.useMemo<React.RefCallback<HTMLFormElement> | undefined>(() => {
    return noop ? undefined : (form) => setFormId(form?.id);
  }, [noop]);

  return React.useMemo<Result>(
    () => ({
      formApi,
      formId,
      formSubmitting,
      props: {
        disabled: formSubmitting,
        onFormStateChange,
        onReady: onFormReady,
        onSubmit
      },
      setFormRef
    }),
    [formApi, formId, formSubmitting, onFormReady, onFormStateChange, onSubmit, setFormRef]
  );
};
