import { MessagingActionPayload } from '@sigmail/app-state';
import { Constants, SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { DataObjectMsgMetadata, IDataObject, MessageFolderItemCount, MessageFolderListItem } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { Set as ImmutableSet } from 'immutable';
import { AppThunk } from '../..';
import { MessageFlags } from '../../../app/messaging/utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { ActionInitParams } from '../base-action';
import { AUTH_STATE_DELETE_MESSAGE } from '../constants/auth-state-identifier';
import { updateMessageFolderAction } from './update-msg-folder-action';

interface Payload extends MessagingActionPayload.DeleteMessage {}

interface State extends AuthenticatedActionState {
  dataObjectIdListToExpire: Array<[msgMetadataId: SigmailObjectId, msgBodyId: SigmailObjectId]>;
  isDraftsFolder: boolean;
  msgMetadataIdSet: ImmutableSet<SigmailObjectId>;
}

const isDraftsFolder = (folderKey: Payload['folderKey'], parentFolderKey?: Payload['parentFolderKey']) =>
  folderKey === Constants.MessageFolderKey.Drafts && Utils.isNil(parentFolderKey);

class DeleteMessageAction extends AuthenticatedAction<Payload, State> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params);

    const { folderKey, parentFolderKey, msgMetadataId } = params.payload;

    this.state.isDraftsFolder = isDraftsFolder(folderKey, parentFolderKey);
    this.state.msgMetadataIdSet = ImmutableSet(
      Utils.filterMap(Utils.flatten([msgMetadataId]), (id) => DataObjectMsgMetadata.isValidId(id) && id)
    );
  }

  protected get accessToken() {
    return Utils.isString(this.payload.accessToken) ? this.payload.accessToken : super.accessToken;
  }

  protected get authState() {
    return Utils.isString(this.payload.authState) ? this.payload.authState : super.authState;
  }

  /** @override */
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      this.state.dataObjectIdListToExpire = [];

      let { requestBody, successPayload } = this.payload as Required<Payload>;

      if (Utils.isNil(requestBody)) {
        requestBody = new BatchUpdateRequestBuilder();
      }

      if (Utils.isNil(successPayload)) {
        successPayload = {
          request: { dataObjects: { ids: [] } },
          response: { dataObjects: [], serverDateTime: '' }
        };
      }

      try {
        const { folderKey, parentFolderKey } = this.payload;
        await this.dispatch(
          updateMessageFolderAction({
            folderKey,
            parentFolderKey,
            requestBody,
            successPayload,
            applyUpdate: this.applyMessageFolderUpdate.bind(this)
          })
        );

        if (this.state.dataObjectIdListToExpire.length > 0) {
          const { ownerId } = this.state;
          requestBody.expire(
            Utils.flatten(
              this.state.dataObjectIdListToExpire.map(([msgMetadataId, msgBodyId]) => [
                { type: process.env.DATA_OBJECT_TYPE_MSG_METADATA, id: msgMetadataId, version: 0, ownerId } as IDataObject<any>,
                { type: process.env.DATA_OBJECT_TYPE_MSG_BODY, id: msgBodyId, version: 0, ownerId } as IDataObject<any>
              ])
            )
          );
        }

        if (Utils.isNil(this.payload.requestBody)) {
          const mutations = requestBody.build();
          if (Utils.isNonEmptyArray<NonNullable<typeof mutations.dataObjects>[0]>(mutations.dataObjects)) {
            const query: Api.EnterStateRequestData = {
              authState: this.state.roleAuthClaim,
              state: AUTH_STATE_DELETE_MESSAGE
            };

            const { authState: batchUpdateAuthState } = await this.dispatchEnterState(query);
            await this.dispatchBatchUpdateData({ authState: batchUpdateAuthState, ...mutations });

            try {
              await this.dispatchBatchQueryDataSuccess(successPayload);
            } catch (error) {
              this.logger.warn('Error manually updating app state:', error);
              /* ignore */
            }
          }
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; batch update operation will be retried.');
      }
    }
  }

  private applyMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    itemCount: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { msgMetadataIdSet, isDraftsFolder } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    const idsFound: Array<SigmailObjectId> = [];
    for (const msgMetadataId of msgMetadataIdSet) {
      this.logger.info(`Locating item with message metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);

      const index = folderData.findIndex(({ header }) => header === msgMetadataId);
      if (index === -1) continue;
      idsFound.push(msgMetadataId);

      const message = folderData[index];
      if (isDraftsFolder) {
        this.state.dataObjectIdListToExpire.push([message.header, message.body]);
      }

      const { isMarkedAsRead, isImportant, hasReminder, isBillable, isMessageFormReferral } = MessageFlags(message);
      const { all, important, reminder, billable, referral } = itemCount!;
      --all.total;
      all.unread -= +!isMarkedAsRead;
      important.total -= +isImportant;
      important.unread -= +(isImportant && !isMarkedAsRead);
      reminder.total -= +hasReminder;
      reminder.unread -= +(hasReminder && !isMarkedAsRead);
      billable.total -= +isBillable;
      billable.unread -= +(isBillable && !isMarkedAsRead);
      referral.total -= +isMessageFormReferral;
      referral.unread -= +(isMessageFormReferral && !isMarkedAsRead);

      result.updated = true;

      folderData.splice(index, 1);
    }

    this.state.msgMetadataIdSet = msgMetadataIdSet.subtract(idsFound);

    result.done = msgMetadataIdSet.isEmpty();
    return result;
  }
}

export const deleteMessageAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'deleteMessageAction:');

    const action = new DeleteMessageAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
