import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ManageAccountIntegrations } from '../../constants/action-context';

const i18n: AccountI18n.View.Integrations = {
  // Translators: Label for the Integrations View under My Account
  label: gettext('account', 'integrations', 'accountViewLabel'),
  action: {
    editAccuroEMRProviderId: {
      // Translators: Label for the Edit Accuro EMR Provider ID action
      label: gettext('account', 'editAccuroEMRProviderId', 'accountIntegrationsActionLabel'),
      context: ManageAccountIntegrations
    },
    editEMRConfig: {
      // Translators: Label for the Edit EMR Configuration action
      label: gettext('account', 'editEMRConfig', 'accountIntegrationsActionLabel'),
      context: ManageAccountIntegrations
    }
  },
  hrmSubscriptionStatus: {
    // Translators: Label for the Active HRM Subscription status
    active: gettext('account', 'active', 'accountIntegrationsHrmStatus'),
    // Translators: Label for the Inactive HRM Subscription status
    inactive: gettext('account', 'inactive', 'accountIntegrationsHrmStatus')
  },
  // Translators: (HTML) Lead text displayed for the Integrations option group \
  // on My Account page
  leadText: gettext('account', 'leadText', 'accountIntegrations', 'safe-html'),
  option: {
    accuroEMRProviderId: {
      // Translators: Label for the Accuro EMR Provider ID Integration option
      label: gettext('account', 'accuroEMRProviderId', 'accountIntegrationsOptionLabel'),
      // Translators: Description for the Accuro EMR Provider ID option
      description: gettext('account', 'accuroEMRProviderId', 'accountIntegrationsOptionDescription')
    },
    EMR: {
      // Translators: Label for the EMR Integration option
      label: gettext('account', 'EMR', 'accountIntegrationsOptionLabel'),
      // Translators: Description for the EMR option
      description: gettext('account', 'EMR', 'accountIntegrationsOptionDescription')
    },
    HRM: {
      // Translators: Label for the Health Report Manager (HRM) option
      label: gettext('account', 'HRM', 'accountIntegrationsOptionLabel'),
      // Translators: Description for the Health Report Manager (HRM) option
      description: gettext('account', 'HRM', 'accountIntegrationsOptionDescription')
    },
    hrmReceiver: {
      // Translators: Label for the HRM Receiving Facility option
      label: gettext('account', 'hrmReceiver', 'accountIntegrationsOptionLabel'),
      // Translators: Description for the HRM Receiving Facility option
      description: gettext('account', 'hrmReceiver', 'accountIntegrationsOptionDescription')
    },
    hrmSender: {
      // Translators: Label for the HRM Sending Facility option
      label: gettext('account', 'hrmSender', 'accountIntegrationsOptionLabel'),
      // Translators: Description for the HRM Sending Facility option
      description: gettext('account', 'hrmSender', 'accountIntegrationsOptionDescription')
    }
  }
};

export default i18n;
