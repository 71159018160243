import { Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n } from '@sigmail/i18n';
import { FieldState, FieldValidator, FORM_ERROR } from 'final-form';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { withTranslation } from '../../i18n';
import { I18N_NS_MFA } from '../../i18n/config/namespace-identifiers';
import mfaI18n from '../../i18n/MFA';
import { ValidationErrorResult } from './form/field-validator';
import { FieldProps, FormComponent, FormComponentProps, FormComponentState } from './form/form.component';
import style from './forms.module.css';

const { verifyCode: i18n } = mfaI18n.form;

export type FieldsetKeyDefault = keyof typeof i18n;
export type FieldsetKey = FieldsetKeyDefault;
export type FieldName = keyof typeof i18n.fieldsetDefault.formField;
export type FormValues = Record<FieldName, string>;

export const ERROR_RESULT_OTP_CODE_MISMATCH: ValidationErrorResult<'otpCodeMismatch'> = { key: 'otpCodeMismatch' };

const FIELD_SET_LIST: ReadonlyArray<Readonly<[FieldsetKey, ReadonlyArray<FieldName>]>> = [
  ['fieldsetDefault', ['code']]
];

const FIELD_PROPS: Record<FieldName, FieldProps> = {
  code: {
    label: i18n.fieldsetDefault.formField.code.label,
    placeholder: i18n.fieldsetDefault.formField.code.placeholder,
    config: {
      required: true
    }
  }
};

export interface Props extends FormComponentProps<FieldName, FormValues> {
  codeValidator?: FieldValidator<string> | null | undefined;
}

interface ComponentProps extends Props, WithTranslation {}
interface State extends FormComponentState<FieldName, FormValues> {}

class VerifyMfaCodeFormComponent extends FormComponent<
  FieldsetKey,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push('t', 'tReady', 'i18n', 'codeValidator');

    this.createForm({
      initialValues: { code: '' }
    });
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-verify-mfa-${this.formIdSuffix}`;
  }

  /** @override */
  protected getFormSubscription(...args: any[]) {
    const baseSubscription = super.getFormSubscription(...args);
    return { ...baseSubscription, submitError: true };
  }

  /** @override */
  protected getFieldSubscription(fieldName: FieldName, ...args: any[]) {
    const baseSubscription = super.getFieldSubscription(fieldName, ...args);
    return { ...baseSubscription, dirtySinceLastSubmit: true };
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetKey: FieldsetKey) {
    const { label } = i18n[fieldsetKey];
    return Utils.isString(label) ? this.props.t(label) : null;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { t } = this.props;
    const { code: fieldStateCode, formState } = this.state;

    let copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    if (fieldName === 'code' && fieldStateCode.dirtySinceLastSubmit !== true) {
      if (formState.submitError === ERROR_RESULT_OTP_CODE_MISMATCH) {
        helperText = this.props.t(i18n.fieldsetDefault.formField.code.error!.codeMismatch!);
        copyOfDefaultProps.error = true;
      }
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined = undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const errorI18n = i18n[fieldsetName!].formField[fieldName].error;
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    return Utils.isString(errorMessageI18n) ? t(errorMessageI18n) : error;
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]) {
    return (
      <div key={fieldName} className={style['form-input-group']}>
        {super.renderField(fieldName, ...args)}
      </div>
    );
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<string>) {
    let { error } = fieldState;

    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    let { formState } = this.state;
    if (fieldName === 'code' && formState?.submitError === ERROR_RESULT_OTP_CODE_MISMATCH) {
      super.setFormState({ ...formState, submitError: undefined });
    }

    return super.setFieldState(fieldName, { ...fieldState, error });
  }

  /** @override */
  protected async formSubmitHandler(values: FormValues) {
    let { codeValidator, onSubmit } = this.props;

    if (typeof codeValidator !== 'function') {
      codeValidator = () => null;
    }

    const validationResult = await Promise.resolve(codeValidator(values.code, values));
    if (Utils.isNotNil(validationResult)) {
      this.setState({ areValidationErrorsVisible: true });
      return { [FORM_ERROR]: validationResult };
    }

    if (typeof onSubmit === 'function') return onSubmit(values);
  }
}

const ns = [I18N_NS_MFA];

export const VerifyMfaCodeForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => (
    <VerifyMfaCodeFormComponent {...props} innerRef={ref || props.innerRef} />
  ))
);

VerifyMfaCodeForm.displayName = 'VerifyMfaCodeForm';

VerifyMfaCodeForm.defaultProps = {
  className: style['form-base']
};
