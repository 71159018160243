export const AccountActionToolbar = 'accountActionToolbar';
export const AccountSetupSuccess = 'accountSetupSuccess';
export const AccountSignIn = 'accountSignIn';
export const AppDrawer = 'appDrawer';
export const AppHeader = 'appHeader';

export const BulkInviteGuests = 'bulkInviteGuests';

export const CardiomyopathyQuestionnaireView = 'cardiomyopathyQuestionnaireView';
export const CopdAssessmentView = 'copdAssessmentView';
export const ComposeConsultationRequest = 'composeConsultationRequest';
export const ComposeHrmMessageView = 'composeHrmMessageView';
export const ComposeMessageView = 'composeMessageView';
export const ComposeReferralView = 'composeReferralView';
export const VitalsQuestionnaireView = 'vitalsQuestionnaireView';

export const ContactActionToolbar = 'contactActionToolbar';
export const ContactList = 'contactList';

export const DialogAcceptReferral = 'dialogAcceptReferral';
export const DialogAssignMessage = 'dialogAssignMessage';
export const DialogBillingFile = 'dialogBillingFile';
export const DialogBulkInviteGuestsConfirmSubmit = 'dialogBulkInviteGuestsConfirmSubmit';
export const DialogBulkInviteGuestsProgress = 'dialogBulkInviteGuestsProgress';
export const DialogChangePassword = 'dialogChangePassword';
export const DialogChangePasswordFormSignIn = 'dialogChangePasswordFormSignIn';
export const DialogChangePasswordFormVerifyMfa = 'dialogChangePasswordFormVerifyMfa';
export const DialogChangePasswordFormNewPassword = 'dialogChangePasswordFormNewPassword';
export const DialogConfirmCancelHrmMessage = 'dialogConfirmCancelHrmMessage';
export const DialogConfirmCloseUnsentMessage = 'dialogConfirmCloseUnsentMessage';
export const DialogConfirmDeactivateAccount = 'deactivateAccount';
export const DialogConfirmDeleteMessage = 'dialogConfirmDeleteMessage';
export const DialogConfirmDiscardDraft = 'dialogConfirmDiscardDraft';
export const DialogConfirmOperation = 'dialogConfirmOperation';
export const DialogConfirmRecallEvent = 'dialogConfirmRecallEvent';
export const DialogConfirmRecallMessage = 'dialogConfirmRecallMessage';
export const DialogConfirmRemoveAccountInvitation = 'dialogConfirmRemoveAccountInvitation';
export const DialogConfirmResendAccountInvitation = 'dialogConfirmResendAccountInvitation';
export const DialogConfirmRevokeAccountInvitation = 'dialogConfirmRevokeAccountInvitation';
export const DialogConfirmSendToEMR = 'dialogConfirmSendToEMR';
export const DialogConfirmSendToHrm = 'dialogConfirmSendToHrm';
export const DialogConsultationRequestMissingBillingNumber = 'dialogConsultationRequestMissingBillingNumber';
export const DialogConsultationRequestSelectPatientRecord = 'dialogConsultationRequestSelectPatientRecord';
export const DialogContactListGridFilter = 'dialogContactListGridFilter';
export const DialogDeclineReferral = 'dialogDeclineReferral';
export const DialogDisableMfa = 'dialogDisableMfa';
export const DialogDisableMfaFormSignIn = 'dialogDisableMfaFormSignIn';
export const DialogDisableMfaFormVerifyMfaCode = 'dialogDisableMfaFormVerifyMfa';
export const DialogDocumentPreview = 'dialogDocumentPreview';
export const DialogEditAccuroEMRProviderId = 'dialogEditAccuroEMRProviderId';
export const DialogEditMessageSignature = 'dialogEditMessageSignature';
export const DialogEditProfile = 'dialogEditProfile';
export const DialogEditProfileSignIn = 'dialogEditProfileSignIn';
export const DialogEnableMfa = 'dialogEnableMfa';
export const DialogEnableMfaFormSelectMfaMethod = 'dialogEnableMfaFormSelectMfa';
export const DialogEnableMfaFormSignIn = 'dialogEnableMfaFormSignIn';
export const DialogEnableMfaFormVerifyMfaCode = 'dialogEnableMfaFormVerifyMfa';
export const DialogEmrConfiguration = 'dialogEmrConfiguration';
export const DialogEmrConfigurationAccuro = 'dialogEmrConfigurationAccuro';

export const DialogEMRNoMatchingPatientRecord = 'dialogEMRNoMatchingPatientRecord';
export const DialogEMROAuthParamsMissing = 'dialogEMROAuthParamsMissing';
export const DialogEMRProviderIdMissing = 'dialogEMRProviderIdMissing';
export const DialogEMRTokenExpired = 'dialogEMRTokenExpired';
export const DialogEMRUploadProgress = 'dialogEMRUploadProgress';

export const DialogEmrConfigurationOscar = 'dialogEmrConfigurationOscar';
export const DialogHealthDataRequest = 'dialogHealthDataRequest';
export const DialogHrmInactiveLicense = 'dialogHrmInactiveLicense';
export const DialogHrmMissingCellNumber = 'dialogHrmMissingCellNumber';
export const DialogHrmMissingLicenseNumber = 'dialogHrmMissingLicenseNumber';
export const DialogHrmNonOntarioHpn = 'dialogHrmNonOntarioHpn';
export const DialogHrmUnauthorizedSender = 'dialogHrmUnauthorizedSender';
export const DialogHrmUnsupportedDocRemoved = 'dialogHrmUnsupportedDocRemoved';
export const DialogMeetingRoom = 'dialogMeetingRoom';
export const DialogMemberFilterSelection = 'dialogMemberFilterSelection';
export const DialogMessageAlreadyReplyTo = 'dialogMessageAlreadyRepliedTo';
export const DialogOneTimeMessageAccessCode = 'dialogOneTimeMessageAccessCode';
export const DialogNewEvent = 'dialogNewEvent';
export const DialogNewVersionAvailable = 'dialogNewVersionAvailable';
export const DialogRecipientsRemoved = 'dialogRecipientsRemoved';
export const DialogReplyDisabledBySelf = 'dialogReplyDisabledBySelf';
export const DialogReplyDisabledBySender = 'dialogReplyDisabledBySender';
export const DialogReplyWindowClosed = 'dialogReplyWindowClosed';
export const DialogSelectEMRPatientRecord = 'dialogSelectEMRPatientRecord';
export const DialogSelectEMRProviderFolder = 'dialogSelectEMRProviderFolder';
export const DialogSendGuestAccountInvitation = 'dialogSendGuestAccountInvitation';
export const DialogSendNonGuestAccountInvitation = 'dialogSendNonGuestAccountInvitation';
export const DialogVersionConflict = 'dialogVersionConflict';
export const DialogViewEvent = 'dialogViewEvent';

export const FormAuthCredentialsSetup = 'formAuthCredentialsSetup';
export const FormBillingFile = 'formBillingFile';
export const FormComposeMessage = 'formComposeMessage';
export const FormComposeReferral = 'formComposeReferral';
export const FormConfirmSecuritySetup = 'formConfirmSecuritySetup';
export const FormContactUs = 'formContactUs';
export const FormPasswordResetSetup = 'formPasswordResetSetup';
export const FormSelectMfaMethod = 'formSelectMfaMethod';
export const FormSendPasswordResetLink = 'formSendPasswordResetLink';
export const FormSignIn = 'formSignIn';
export const FormSubmitDocuments = 'formSubmitDocuments';
export const FormVerifyMfaCode = 'formVerifyMfaCode';

export const GroupActionToolbar = 'groupActionToolbar';
export const GroupSchedule = 'groupSchedule';

export const HealthDataRequestView = 'healthDataRequestView';

export const ManageMembers = 'manageMembers';
export const ManageCaregivers = 'manageCaregivers';
export const ManageAccountProfile = 'manageAccountProfile';
export const ManageAccountSecurity = 'manageAccountSecurity';
export const ManageAccountPreferences = 'manageAccountPreferences';
export const ManageAccountSchedule = 'manageAccountSchedule';
export const ManageAccountIntegrations = 'manageAccountIntegrations';
export const MessageActionToolbar = 'messageActionToolbar';
export const MessageViewEvent = 'messageViewEvent';
export const Messaging = 'messaging';
export const OwnSchedule = 'ownSchedule';
export const PasswordResetSuccess = 'passwordResetSuccess';
export const SendPasswordResetLinkSuccess = 'sendPasswordResetLinkSuccess';

export const WizardForgotPassword = 'wizardForgotPassword';
