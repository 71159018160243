import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.View.HealthDataRequestMessage = {
  dataGroup: {
    request: {
      item: {
        // Translators: Label for the Data Form data group item in Health Data \
        // Request Message view
        dataForm: gettext('messaging', 'dataForm', 'healthDataRequestMessageViewDataGroupItemLabel'),
        // Translators: Label for the End Date data group item in Health Data \
        // Request Message view
        endDate: gettext('messaging', 'endDate', 'healthDataRequestMessageViewDataGroupItemLabel'),
        // Translators: Label for the Recurrence data group item in Health \
        // Data Request Message view
        recurrence: gettext('messaging', 'recurrence', 'healthDataRequestMessageViewDataGroupItemLabel'),
        // Translators: Label for the Start Date data group item in Health \
        // Data Request Message view
        startDate: gettext('messaging', 'startDate', 'healthDataRequestMessageViewDataGroupItemLabel')
      },
      // Translators: Label for the Request data group in Health Data Request \
      // Message view
      label: '',
      // Translators: Value displayed for End Date data group item in Health \
      // Data Request Message view when no end date is set for the recurrence
      noEndDate: gettext('messaging', 'noEndDate', 'healthDataRequestMessageViewDataGroupItemValue'),
      // Translators: Value displayed for Recurrence data group item in Health \
      // Data Request Message view when it's a one-time request
      noRepeat: gettext('messaging', 'noRepeat', 'healthDataRequestMessageViewDataGroupItemValue')
    }
  }
};

export default i18n;
