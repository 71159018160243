import { isString } from '.';

const RE_VALID_BEARER_TOKEN = /^[A-Za-z0-9\-._~+/]+=*/;
const RE_VALID_ID_TOKEN = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

export function isValidJwtToken(value: unknown, tokenType: 'bearer' | 'id' = 'bearer'): value is string {
  if (isString(value)) {
    const jwtTokenType = arguments.length === 1 ? 'bearer' : tokenType;
    switch (jwtTokenType) {
      case 'bearer':
        return RE_VALID_BEARER_TOKEN.test(value);
      case 'id':
        return RE_VALID_ID_TOKEN.test(value);
      default:
        break;
    }
  }
  return false;
}
