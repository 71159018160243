import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['memberLicense'] = {
  // Translators: Label for the Pro license type
  pro: gettext('global', 'pro', 'memberLicense'),
  // Translators: Label for the Standard license type
  standard: gettext('global', 'standard', 'memberLicense'),
  // Translators: Label for the Guest license type
  guest: gettext('global', 'guest', 'memberLicense')
};

export default i18n;
