import { E_FAIL, E_FAIL_DIGEST } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';
import type { HashFunction } from '../types';

async function hash(algorithm: 'SHA-256' | 'SHA-384' | 'SHA-512', data: Uint8Array): Promise<Uint8Array> {
  if (typeof crypto?.subtle?.digest !== 'function') {
    throw new SigmailCryptoException(E_FAIL, 'Not supported.');
  }

  try {
    return new Uint8Array(await crypto.subtle.digest(algorithm, data));
  } catch {
    throw new SigmailCryptoException(E_FAIL_DIGEST);
  }
}

/** @public */
export const SHA256: HashFunction = Object.assign(hash.bind(null, 'SHA-256'), {
  NAME: 'SHA-256',
  hashLength: 256
});

/** @public */
export const SHA384: HashFunction = Object.assign(hash.bind(null, 'SHA-384'), {
  NAME: 'SHA-384',
  hashLength: 384
});

/** @public */
export const SHA512: HashFunction = Object.assign(hash.bind(null, 'SHA-512'), {
  NAME: 'SHA-512',
  hashLength: 512
});
