import type { EncapsulatedKey } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { Encryptor } from '..';
import {
  E_FAIL_DECRYPT,
  E_FAIL_DERIVE_KEY,
  E_FAIL_ENCRYPT,
  E_FAIL_GENERATE_KEY,
  E_METHOD_NOT_IMPL
} from '../../constants';
import * as RNG from '../../rng';
import { SigmailCryptoException } from '../../SigmailCryptoException';
import type { KeyDerivationFunction, SymmetricEncryptor, SymmetricKey } from '../../types';

/** @internal */
export interface AesGcmEncryptorParams {
  /** Function to derive additional data value. */
  adDf: KeyDerivationFunction;

  /** Length, in bits, of the encapsulated key. */
  encapsulatedKeyLength: number;

  /** Function to derive initialization vector value. */
  ivDf: KeyDerivationFunction;

  /** Function to derive key value. */
  keyDf: KeyDerivationFunction;

  /** Size, in bits, of the authentication tag generated in the encryption operation. */
  tagLength: 96 | 104 | 112 | 120 | 128;
}

const DefaultKdf: KeyDerivationFunction = {
  NAME: 'default',
  derive: () => Promise.reject(new SigmailCryptoException(E_METHOD_NOT_IMPL, 'Method not implemented.'))
};

const DEFAULT_PARAMS: AesGcmEncryptorParams = {
  adDf: DefaultKdf,
  ivDf: DefaultKdf,
  encapsulatedKeyLength: 256,
  keyDf: DefaultKdf,
  tagLength: 128
};

/**
 * Define the primitive AES_GCM to perform crypto operations related to AES_GCM.
 *
 * - Operations are generateKey, deriveKey, encrypt, decrypt
 * - A new key should be derived for each use of encrypt; the same
 *   encapsulatedKey should be used for the same object always
 * - The version parameter of deriveKey should be incremented for each
 *   encryption to generate distinct values
 *
 * @author Kim Birchard <kbirchard@sigmahealthtech.com>
 * @internal
 */
export class AES_GCM extends Encryptor implements SymmetricEncryptor {
  private readonly params: AesGcmEncryptorParams;

  public constructor(params?: Partial<AesGcmEncryptorParams>) {
    super('AES-GCM');

    this.params = Utils.defaults({}, params, DEFAULT_PARAMS);
  }

  public async decrypt(key: SymmetricKey, data: Uint8Array): Promise<Uint8Array> {
    const { iv, aad: additionalData, key: privateKey } = key;
    try {
      const algo: AesGcmParams = { name: this.NAME, iv, additionalData, tagLength: this.params.tagLength };
      const decrypted = await crypto.subtle.decrypt(algo, privateKey, data);
      return new Uint8Array(decrypted);
    } catch {
      throw new SigmailCryptoException(E_FAIL_DECRYPT);
    }
  }

  public async deriveKey(encapsulatedKey: EncapsulatedKey, version: number): Promise<SymmetricKey> {
    try {
      const exportedKey = await this.params.keyDf.derive(encapsulatedKey, version);
      const iv = await this.params.ivDf.derive(encapsulatedKey, version);
      const aad = await this.params.adDf.derive(encapsulatedKey, version);
      const key = await crypto.subtle.importKey('raw', exportedKey, this.NAME, /* extractable := */ false, [
        'decrypt',
        'encrypt'
      ]);
      return { key, exportedKey, iv, aad };
    } catch (error) {
      const exception = error instanceof SigmailCryptoException ? error : new SigmailCryptoException(E_FAIL_DERIVE_KEY);
      throw exception;
    }
  }

  public async encrypt(key: SymmetricKey, data: Uint8Array): Promise<Uint8Array> {
    const { iv, aad: additionalData, key: publicKey } = key;
    try {
      const algo: AesGcmParams = { name: this.NAME, iv, additionalData, tagLength: this.params.tagLength };
      const encrypted = await crypto.subtle.encrypt(algo, publicKey, data);
      return new Uint8Array(encrypted);
    } catch {
      throw new SigmailCryptoException(E_FAIL_ENCRYPT);
    }
  }

  public generateKey(): Promise<EncapsulatedKey> {
    return new Promise<EncapsulatedKey>((resolve, reject) => {
      try {
        resolve(RNG.Uint8(this.params.encapsulatedKeyLength / 8));
      } catch (error) {
        const exception =
          error instanceof SigmailCryptoException ? error : new SigmailCryptoException(E_FAIL_GENERATE_KEY);
        reject(exception);
      }
    });
  }
}
