import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ComposeConsultationRequest } from '../../constants/action-context';
import dialogMissingBillingNumber from './dialog-consultation-request-missing-billing-number';

const i18n: MessagingI18n.View.ComposeConsultationRequest = {
  // Translators: Title for the Compose Consultation Request view
  title: gettext('messaging', 'title', 'composeConsultationRequestView'),
  action: {
    submit: {
      // Translators: Label for the Submit action in Compose Consultation \
      // Request view
      label: gettext('messaging', 'submit', 'composeConsultationRequestViewActionLabel'),
      context: ComposeConsultationRequest
    },
    close: {
      // Translators: Label for the Close action in Compose Consultation \
      // Request view
      label: gettext('messaging', 'close', 'composeConsultationRequestViewActionLabel'),
      context: ComposeConsultationRequest
    },
    attach: {
      // Translators: Label for the Attach Documents action in Compose \
      // Consultation Request view
      label: gettext('messaging', 'attach', 'composeConsultationRequestViewActionLabel'),
      context: ComposeConsultationRequest
    }
  },
  dialog: {
    missingBillingNumber: dialogMissingBillingNumber
  }
};

export default i18n;
