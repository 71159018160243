import { EnglishCanada } from '../../constants/language-codes';
import { fromCarePlanDate } from './from-care-plan-date';

const CarePlanDateFormatter = new Intl.DateTimeFormat(EnglishCanada, {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
});

export function formatCarePlanDate(value: unknown): string | null {
  const dt = fromCarePlanDate(value);
  return dt === null ? null : CarePlanDateFormatter.format(dt);
}
