import { Nullable, ReadonlyPartialRecord, Utils } from '@sigmail/common';
import { HealthDataI18n } from '@sigmail/i18n';
import {
  BloodPressureCategory,
  BPReadingArmUsed,
  BPReadingFormData as BaseBPReadingFormData,
  UserObjectHealthDataValue
} from '@sigmail/objects';
import { endOfDay } from 'date-fns';
import { TOptions, WithT } from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { SANITIZER_OPTIONS } from '../../../app-state/actions/constants';
import { DataFormCssClassPrefix, DataFormNameBPReading } from '../../../app/health-data/constants';
import {
  FieldName,
  FieldsetName,
  FIELD_SET_LIST,
  FormValues
} from '../../../app/health-data/forms/bp-reading.component';
import { MAX_SAFE_TIMESTAMP, MIN_SAFE_TIMESTAMP } from '../../../constants';
import { EnglishCanada } from '../../../constants/language-codes';
import healthDataI18n from '../../../i18n/health-data';
import { dateToUtcValues } from '../../date-to-utc-values';

type BPReadingFormData = BaseBPReadingFormData & { requestTimestamp: number };

const { bpReading: formI18n } = healthDataI18n.form;
const { dataValueNotSet } = healthDataI18n.dataViewer.bpReading;

export class BPReadingDataUtil {
  private readonly data: BPReadingFormData | undefined;

  public constructor(data?: Nullable<BPReadingFormData>) {
    if (Utils.isNil(data)) return;

    const Class = this.constructor as typeof BPReadingDataUtil;
    if (!Class.isValid(data)) throw new TypeError('Invalid BP Reading data.');

    this.data = data;
  }

  public static buildGridData(
    data: UserObjectHealthDataValue,
    params?: ReadonlyPartialRecord<'from' | 'to', Date>
  ): ReadonlyArray<BPReadingFormData> {
    let from = params?.from?.getTime() as number;
    from = Math.min(
      Math.max(MIN_SAFE_TIMESTAMP, Utils.numberOrDefault(Utils.isInteger(from) && from, MIN_SAFE_TIMESTAMP)),
      MAX_SAFE_TIMESTAMP
    );

    let to = params?.to?.getTime() as number;
    to = endOfDay(
      Math.min(Math.max(from, Utils.numberOrDefault(Utils.isInteger(to) && to, MAX_SAFE_TIMESTAMP)), MAX_SAFE_TIMESTAMP)
    ).getTime();

    const timestampSet = new Set<number>();
    for (const requestId in data.$index) {
      const [indexOfRequest, responseList] = data.$index[+requestId]!;
      if (data.requestList![indexOfRequest].form !== DataFormNameBPReading) continue;

      responseList.filter((ts) => ts >= from && ts <= to).forEach((ts) => timestampSet.add(ts));
    }

    const timestampList = Array.from(timestampSet.values());
    const dataList: Array<BPReadingFormData> = [];

    if (Utils.isNotNil(data.bpReading) && timestampList.length > 0) {
      timestampList.sort((a, b) => b - a);

      for (const ts of timestampList) {
        const [utcYear, utcMonth, utcDate] = dateToUtcValues(ts);
        const bpReadingData = data.bpReading![utcYear]![utcMonth]![utcDate]!;

        if (bpReadingData) {
          dataList.push({ ...bpReadingData, requestTimestamp: ts });
        }
      }
    }

    return dataList;
  }

  public static createDataFromFormValues({
    additionalReading,
    armUsed1,
    armUsed2,
    comment1,
    comment2,
    diastolic1,
    diastolic2,
    heartRate1,
    heartRate2,
    systolic1,
    systolic2,
    timestampDate1,
    timestampDate2,
    ...rest
  }: FormValues) {
    timestampDate1.setHours(rest.timestampTime1.getHours(), rest.timestampTime1.getMinutes());

    const armUsed = [armUsed1];
    const bloodPressure = [{ diastolic: +diastolic1, systolic: +systolic1 }];
    let comment = [comment1];
    const heartRate = [+heartRate1];
    const timestamp = [timestampDate1.getTime()];

    if (additionalReading) {
      armUsed.push(armUsed2);
      bloodPressure.push({ diastolic: +diastolic2, systolic: +systolic2 });
      comment.push(comment2);
      heartRate.push(+heartRate2);

      timestampDate2.setHours(rest.timestampTime2.getHours(), rest.timestampTime2.getMinutes());
      timestamp.push(timestampDate2.getTime());
    }

    if (comment.filter((value) => Utils.isString(value)).length === 0) comment = undefined!;

    const formData = {
      armUsed,
      bloodPressure,
      comment,
      heartRate,
      timestamp
    };

    return (formData as unknown) as BPReadingFormData;
  }

  public static formatDataValue(data: BPReadingFormData, fieldName: FieldName, t: WithT['t'], language?: string) {
    let tOptions: TOptions | undefined;
    if (Utils.isString(language)) tOptions = { language };

    const dataIndex = +fieldName.substr(-1) - 1;

    switch (fieldName) {
      case 'armUsed1':
      case 'armUsed2': {
        const [fieldset] = FIELD_SET_LIST.find(([, fieldNameList]) => fieldNameList.includes(fieldName))!;

        const dataValue = data.armUsed[dataIndex];
        if (Utils.isNil(dataValue)) return undefined;

        const options = (formI18n[fieldset] as any).formField[fieldName].options as Array<
          HealthDataI18n.Form.BPReadingFormInputOption<BPReadingArmUsed>
        >;

        return t(options.find(({ codedValue }) => codedValue === dataValue)!.label, tOptions);
      }
      case 'comment1':
      case 'comment2': {
        const dataValue = Utils.isNil(data.comment) ? undefined : data.comment[dataIndex];
        return Utils.isNil(dataValue) ? undefined : sanitizeHtml(Utils.trimOrDefault(dataValue), SANITIZER_OPTIONS);
      }
      case 'diastolic1':
      case 'diastolic2': {
        const bpData = data.bloodPressure[dataIndex];
        return Utils.isNil(bpData) ? undefined : bpData.diastolic;
      }
      case 'heartRate1':
      case 'heartRate2': {
        return data.heartRate[dataIndex];
      }
      case 'systolic1':
      case 'systolic2': {
        const bpData = data.bloodPressure[dataIndex];
        return Utils.isNil(bpData) ? undefined : bpData.systolic;
      }
      case 'timestampDate1':
      case 'timestampDate2': {
        return data.timestamp[dataIndex];
      }
      default:
        throw new Error(`Unhandled case - ${fieldName}`);
    }
  }

  public static getBPCategory(systolic: number, diastolic: number): Readonly<BloodPressureCategory | undefined> {
    if (systolic >= 180 || diastolic >= 110) {
      return 'hypertension3';
    } else if ((systolic >= 160 && systolic <= 179) || (diastolic >= 100 && diastolic <= 109)) {
      return 'hypertension2';
    } else if ((systolic >= 140 && systolic <= 159) || (diastolic >= 90 && diastolic <= 99)) {
      return 'hypertension1';
    } else if ((systolic >= 130 && systolic <= 139) || (diastolic >= 85 && diastolic <= 89)) {
      return 'highNormal';
    } else if ((systolic >= 120 && systolic <= 129) || (diastolic >= 80 && diastolic <= 84)) {
      return 'normal';
    } else if (systolic < 120 && diastolic < 80) {
      return 'lowNormal';
    }
  }

  public static isValid(data?: null | undefined | unknown): data is BPReadingFormData {
    return (
      Utils.isNonArrayObjectLike<BPReadingFormData>(data) &&
      Utils.isArray(data.armUsed) &&
      Utils.isArray(data.bloodPressure) &&
      (Utils.isNil(data.comment) || Utils.isArray(data.comment)) &&
      Utils.isArray(data.heartRate) &&
      Utils.isArray(data.timestamp)
    );
  }

  // @ts-expect-error TS2394
  public toHtml(t: WithT['t']): string;
  public toHtml(data: BPReadingFormData, t: WithT['t']): string;
  public toHtml(arg0: unknown, arg1: WithT['t']): string {
    let data: BPReadingFormData;
    let t: typeof arg1;

    if (typeof arg0 === 'function') {
      data = this.data!;
      t = arg0 as typeof t;
    } else {
      data = arg0 as BPReadingFormData;
      t = arg1;
    }

    const Class = this.constructor as typeof BPReadingDataUtil;

    const fieldsetList = Utils.isNil(data.armUsed[1])
      ? FIELD_SET_LIST.filter(
          ([fieldsetName]) =>
            !([
              'fieldsetAdditionalReading',
              'fieldsetArmUsed2',
              'fieldsetBloodPressure2',
              'fieldsetComment2',
              'fieldsetHeartRate2',
              'fieldsetTimestampDate2',
              'fieldsetTimestampTime2'
            ] as ReadonlyArray<FieldsetName>).includes(fieldsetName)
        )
      : FIELD_SET_LIST;

    const tbodyList = fieldsetList
      .map<string>(([fieldsetName, fieldNameList]) => {
        const i18n = formI18n[fieldsetName] as any;

        const columnList = fieldNameList
          .filter((fieldName) => fieldName !== 'additionalReading' && !fieldName.startsWith('timestampTime'))
          .map((fieldName) => {
            const header = t(i18n.formField[fieldName].label, { language: EnglishCanada });

            let value = Class.formatDataValue(data, fieldName, t, EnglishCanada);
            if (Utils.isNotNil(value)) {
              if (fieldName === 'timestampDate1' || fieldName === 'timestampDate2') {
                value = Utils.DATE_FORMAT_FULL(EnglishCanada).format(value as number);
              }
            } else {
              value = t(dataValueNotSet) as string;
            }

            if (Utils.isNumber(value)) {
              let bpCategory;

              if (fieldName === 'diastolic1' || fieldName === 'systolic1') {
                const diastolic1 = Class.formatDataValue(data, 'diastolic1', t, EnglishCanada) as number;
                const systolic1 = Class.formatDataValue(data, 'systolic1', t, EnglishCanada) as number;
                bpCategory = Class.getBPCategory(systolic1, diastolic1);
              } else if (fieldName === 'diastolic2' || fieldName === 'systolic2') {
                const diastolic2 = Class.formatDataValue(data, 'diastolic2', t, EnglishCanada)!;
                const systolic2 = Class.formatDataValue(data, 'systolic2', t, EnglishCanada)!;
                bpCategory = Class.getBPCategory(+systolic2, +diastolic2);
              }

              if (Utils.isNotNil(bpCategory)) {
                return `<th scope="row">${t(header)}</th><td class="${bpCategory}">${value}</td>`;
              }
            }

            return `<th scope="row">${t(header)}</th><td>${value}</td>`;
          });

        let rowList = `<tr>${columnList.join('</tr><tr>')}</tr>`;

        if (
          !([
            'fieldsetArmUsed1',
            'fieldsetAdditionalReading',
            'fieldsetBloodPressure1',
            'fieldsetBloodPressure2'
          ] as ReadonlyArray<FieldsetName>).includes(fieldsetName)
        ) {
          return `<tbody>${rowList}</tbody>`;
        }

        if (fieldsetName === 'fieldsetBloodPressure1' || fieldsetName === 'fieldsetBloodPressure2') {
          rowList = `<tr class="indent-1">${columnList.join('</tr><tr class="indent-1">')}</tr>`;
        }

        const fieldsetHeader = t(i18n!.label, { language: EnglishCanada });
        return `<tbody><tr><th>${fieldsetHeader}</th><td/></tr></tbody><tbody>${rowList}</tbody>`;
      })
      .join('');

    const caption = '<caption>Questionnaire</caption>';
    const thead = '<thead><th>Question</th><th>Answer</th></thead>';

    return `<table class="${DataFormCssClassPrefix}${DataFormNameBPReading}">${caption}${thead}${tbodyList}</table>`;
  }
}
