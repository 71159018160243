import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectProfileProtectedValue as DV, UserObjectProfileProtectedValue } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED;

const OPTIONAL_STRING_PROP_LIST: ReadonlyArray<
  keyof Pick<
    DV,
    | 'birthDate'
    | 'gender'
    | 'groupName'
    | 'healthCardNumber'
    | 'healthPlanJurisdiction'
    | 'licenseNumber'
    | 'ohipBillingNumber'
  >
> = [
  'birthDate',
  'gender',
  'groupName',
  'healthCardNumber',
  'healthPlanJurisdiction',
  'licenseNumber',
  'ohipBillingNumber'
];

/** @public */
export class UserObjectProfileProtected extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    if (!OPTIONAL_STRING_PROP_LIST.every((prop) => Utils.isUndefined(dv[prop]) || Utils.isString(dv[prop]))) {
      return false;
    }

    const dv1 = value as UserObjectProfileProtectedValue<1>;
    if (Utils.isUndefined(dv1.$$formatver) || dv1.$$formatver === 1) {
      return Utils.isString(dv1.cellNumber) && Utils.isString(dv1.officeNumber);
    }

    return dv.$$formatver >= 2;
  }

  public override updateValue(newValue: DV): Promise<UserObjectProfileProtected> {
    const Class = this.constructor as typeof UserObjectProfileProtected;
    return Class.updateValue(this, newValue);
  }
}
