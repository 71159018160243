import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.Form.AssignMessage = {
  fieldsetAssignee: {
    formField: {
      assigneeList: {
        error: {
          // Translators: Error message displayed when no assignee is selected \
          // in Assign Message form
          valueMissing: gettext('messaging', 'assigneeListRequired', 'assignMessageFormError')
        },
        // Translators: Label for the Assignee List input of Assign Message form
        label: gettext('messaging', 'assigneeList', 'assignMessageFormInputLabel'),
        localeText: {
          // Translators: Label for the Close Popup icon of Assignee input
          closePopup: gettext('messaging', 'closeAssigneePopup', 'assignMessageFormLocaleText'),
          // Translators: Text to display when there are no contacts to show \
          // in Assignee input
          noOptions: gettext('messaging', 'noAssignee', 'assignMessageFormLocaleText'),
          // Translators: Label for the Open Popup icon of Assignee input
          openPopup: gettext('messaging', 'openAssigneePopup', 'assignMessageFormLocaleText')
        },
        // Translators: Label for the Select option of Assignee input in \
        // Assign Message form
        placeholder: gettext('messaging', 'selectAssignee', 'assignMessageFormOptionLabel')
      }
    }
  }
};

export default i18n;
