import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';
import {
  DataFormNameBPReading as HealthDataFormNameBPReading,
  DataFormNameCardiacIntake as HealthDataFormNameCardiacIntake,
  DataFormNameCopdAssessment as HealthDataFormNameCopdAssessment,
  DataFormNameKCCQ as HealthDataFormNameKCCQ,
  DataFormNameVitals as VitalsDataFormNameKCCQ
} from '../../app/health-data/constants';

const i18n: HealthDataI18n.Form.SelectHealthDataType = {
  fieldsetDataForm: {
    formField: {
      dataForm: {
        // Translators: Label for the Data Type input in Select Health Data \
        // Type form
        label: gettext('health-data', 'dataForm', 'selectHealthDataTypeFormInputLabel'),
        // Translators: Label for the Data Type dropdown option in Select \
        // Health Data Type form
        placeholder: gettext('health-data', 'selectDataForm', 'selectHealthDataTypeFormOptionLabel'),
        options: [
          {
            code: HealthDataFormNameBPReading,
            // Translators: Label for the BP Reading option of Request Type \
            // input in Select Health Data Type form
            label: gettext('health-data', 'dataFormBPReading', 'selectHealthDataTypeFormOptionLabel')
          },
          {
            code: HealthDataFormNameCardiacIntake,
            // Translators: Label for the Cardiac Intake Questionnaire option \
            // of Request Type input in Select Health Data Type form
            label: gettext('health-data', 'dataFormCardiacIntake', 'selectHealthDataTypeFormOptionLabel')
          },
          {
            code: HealthDataFormNameCopdAssessment,
            // Translators: Label for the COPD Assessment option of Request \
            // Type input in Select Health Data Type form
            label: gettext('health-data', 'dataFormCopdAssessment', 'selectHealthDataTypeFormOptionLabel')
          },
          {
            code: HealthDataFormNameKCCQ,
            // Translators: Label for the Cardiomyopathy Questionnaire option \
            // of Request Type input in Select Health Data Type form
            label: gettext('health-data', 'dataFormKCCQ', 'selectHealthDataTypeFormOptionLabel')
          },
          {
            code: VitalsDataFormNameKCCQ,
            // Translators: Label for the Cardiomyopathy Questionnaire option \
            // of Request Type input in Select Health Data Type form
            label: gettext('health-data', 'dataFormVitals', 'selectHealthDataTypeFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error displayed when no option is selected for Data \
          // Form input in Select Health Data Type form
          valueMissing: gettext('health-data', 'dataFormRequired', 'selectHealthDataTypeFormError'),
          // Translators: Error displayed when an invalid value is selected \
          // for Request Type input in Select Health Data Type form
          patternMismatch: gettext('health-data', 'dataFormPattern', 'selectHealthDataTypeFormError')
        }
      }
    }
  }
};

export default i18n;
