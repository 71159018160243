import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ManageAccountPreferences } from '../../constants/action-context';

const i18n: AccountI18n.View.Preferences = {
  action: {
    editNotification: {
      // Translators: Label for the Edit Notification action
      label: gettext('account', 'editNotification', 'accountPreferencesActionLabel'),
      context: ManageAccountPreferences,
      options: [
        {
          code: '',
          // Translators: Label for the Never option of Edit Notification \
          // action
          label: gettext('account', 'never', 'accountPreferencesEditNotificationActionOptionLabel')
        },
        {
          code: 'email',
          // Translators: Label for the Email option of Edit Notification \
          // action
          label: gettext('account', 'email', 'accountPreferencesEditNotificationActionOptionLabel')
        },
        {
          code: 'sms',
          // Translators: Label for the SMS option of Edit Notification \
          // action
          label: gettext('account', 'sms', 'accountPreferencesEditNotificationActionOptionLabel')
        },
        {
          code: 'webPush',
          // Translators: Label for the Web Push option of Edit Notification \
          // action
          label: gettext('account', 'webPush', 'accountPreferencesEditNotificationActionOptionLabel')
        },
        {
          code: 'all',
          // Translators: Label for the All/Both option of Edit Notification \
          // action
          label: gettext('account', 'all', 'accountPreferencesEditNotificationActionOptionLabel')
        }
      ]
    },
    editMessageSignature: {
      // Translators: Label for the Edit Message Signature action
      label: gettext('account', 'editMessageSignature', 'accountPreferencesActionLabel'),
      context: ManageAccountPreferences
    }
  },
  // Translators: Label for the Preferences View under My Account
  label: gettext('account', 'preferences', 'accountViewLabel'),
  option: {
    groupInboxNotification: {
      // Translators: Label for the Group Inbox Notification option
      label: gettext('account', 'groupInboxNotification', 'accountPreferencesOptionLabel'),
      // Translators: Description for the Group Inbox Notification option
      description: gettext('account', 'groupInboxNotification', 'accountPreferencesOptionDescription')
    },
    inboxNotification: {
      // Translators: Label for the Inbox Notification option
      label: gettext('account', 'inboxNotification', 'accountPreferencesOptionLabel'),
      // Translators: Description for the Inbox Notification option
      description: gettext('account', 'inboxNotification', 'accountPreferencesOptionDescription')
    },
    messageSignature: {
      // Translators: Label for the Message Signature option
      label: gettext('account', 'messageSignature', 'accountPreferencesOptionLabel'),
      // Translators: Description for the Message Signature option
      description: gettext('account', 'messageSignature', 'accountPreferencesOptionDescription')
    }
  }
};

export default i18n;
