import { HRMI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HRMI18n.DropDownList = {
  contactType: [
    {
      key: 'home',
      value: gettext('hrm', 'home', 'hrmContactType')
    },
    {
      key: 'work',
      value: gettext('hrm', 'work', 'hrmContactType')
    },
    {
      key: 'temp',
      value: gettext('hrm', 'temp', 'hrmContactType')
    },
    {
      key: 'old',
      value: gettext('hrm', 'old', 'hrmContactType')
    },
    {
      key: 'mobile',
      value: gettext('hrm', 'mobile', 'hrmContactType')
    }
  ],
  addressType: [
    {
      key: 'home',
      value: gettext('hrm', 'home', 'hrmAddressType')
    },
    {
      key: 'work',
      value: gettext('hrm', 'work', 'hrmAddressType')
    },
    {
      key: 'temp',
      value: gettext('hrm', 'temp', 'hrmAddressType')
    },
    {
      key: 'old',
      value: gettext('hrm', 'old', 'hrmAddressType')
    }
  ],
  encounterStatus: [
    {
      key: 'planned',
      value: gettext('hrm', 'planned', 'hrmEncounterStatus')
    },
    {
      key: 'arrived',
      value: gettext('hrm', 'arrived', 'hrmEncounterStatus')
    },
    {
      key: 'in-progress',
      value: gettext('hrm', 'inProgress', 'hrmEncounterStatus')
    },
    {
      key: 'onleave',
      value: gettext('hrm', 'onLeave', 'hrmEncounterStatus')
    },
    {
      key: 'finished',
      value: gettext('hrm', 'finished', 'hrmEncounterStatus')
    },
    {
      key: 'cancelled',
      value: gettext('hrm', 'cancelled', 'hrmEncounterStatus')
    }
  ],
  encounterClass: [
    {
      key: 'inpatient',
      value: gettext('hrm', 'inpatient', 'hrmEncounterClass')
    },
    {
      key: 'outpatient',
      value: gettext('hrm', 'outpatient', 'hrmEncounterClass')
    },
    {
      key: 'ambulatory',
      value: gettext('hrm', 'ambulatory', 'hrmEncounterClass')
    },
    {
      key: 'emergency',
      value: gettext('hrm', 'emergency', 'hrmEncounterClass')
    },
    {
      key: 'home',
      value: gettext('hrm', 'home', 'hrmEncounterClass')
    },
    {
      key: 'field',
      value: gettext('hrm', 'field', 'hrmEncounterClass')
    },
    {
      key: 'daytime',
      value: gettext('hrm', 'daytime', 'hrmEncounterClass')
    },
    {
      key: 'virtual',
      value: gettext('hrm', 'virtual', 'hrmEncounterClass')
    },
    {
      key: 'other',
      value: gettext('hrm', 'other', 'hrmEncounterClass')
    }
  ],
  diagnosisUrgency: {
    no: gettext('hrm', 'no', 'hrmDiagnosisUrgency'),
    yes: gettext('hrm', 'yes', 'hrmDiagnosisUrgency')
  },
  diagnosisCategory: [
    {
      code: 'AU',
      name: gettext('hrm', 'AU', 'hrmDiagnosisCategory')
    },
    {
      code: 'BG',
      name: gettext('hrm', 'BG', 'hrmDiagnosisCategory')
    },
    {
      code: 'BLB',
      name: gettext('hrm', 'BLB', 'hrmDiagnosisCategory')
    },
    {
      code: 'CG',
      name: gettext('hrm', 'CG', 'hrmDiagnosisCategory')
    },
    {
      code: 'CH',
      name: gettext('hrm', 'CH', 'hrmDiagnosisCategory')
    },
    {
      code: 'CP',
      name: gettext('hrm', 'CP', 'hrmDiagnosisCategory')
    },
    {
      code: 'CT',
      name: gettext('hrm', 'CT', 'hrmDiagnosisCategory')
    },
    {
      code: 'CTH',
      name: gettext('hrm', 'CTH', 'hrmDiagnosisCategory')
    },
    {
      code: 'CUS',
      name: gettext('hrm', 'CUS', 'hrmDiagnosisCategory')
    },
    {
      code: 'EC',
      name: gettext('hrm', 'EC', 'hrmDiagnosisCategory')
    },
    {
      code: 'EN',
      name: gettext('hrm', 'EN', 'hrmDiagnosisCategory')
    },
    {
      code: 'GE',
      name: gettext('hrm', 'GE', 'hrmDiagnosisCategory')
    },
    {
      code: 'HM',
      name: gettext('hrm', 'HM', 'hrmDiagnosisCategory')
    },
    {
      code: 'ICU',
      name: gettext('hrm', 'ICU', 'hrmDiagnosisCategory')
    },
    {
      code: 'IMM',
      name: gettext('hrm', 'IMM', 'hrmDiagnosisCategory')
    },
    {
      code: 'LAB',
      name: gettext('hrm', 'LAB', 'hrmDiagnosisCategory')
    },
    {
      code: 'MB',
      name: gettext('hrm', 'MB', 'hrmDiagnosisCategory')
    },
    {
      code: 'MCB',
      name: gettext('hrm', 'MCB', 'hrmDiagnosisCategory')
    },
    {
      code: 'MYC',
      name: gettext('hrm', 'MYC', 'hrmDiagnosisCategory')
    },
    {
      code: 'NMR',
      name: gettext('hrm', 'NMR', 'hrmDiagnosisCategory')
    },
    {
      code: 'NMS',
      name: gettext('hrm', 'NMS', 'hrmDiagnosisCategory')
    },
    {
      code: 'NRS',
      name: gettext('hrm', 'NRS', 'hrmDiagnosisCategory')
    },
    {
      code: 'OSL',
      name: gettext('hrm', 'OSL', 'hrmDiagnosisCategory')
    },
    {
      code: 'OT',
      name: gettext('hrm', 'OT', 'hrmDiagnosisCategory')
    },
    {
      code: 'OTH',
      name: gettext('hrm', 'OTH', 'hrmDiagnosisCategory')
    },
    {
      code: 'OUS',
      name: gettext('hrm', 'OUS', 'hrmDiagnosisCategory')
    },
    {
      code: 'PF',
      name: gettext('hrm', 'PF', 'hrmDiagnosisCategory')
    },
    {
      code: 'PHR',
      name: gettext('hrm', 'PHR', 'hrmDiagnosisCategory')
    },
    {
      code: 'PHY',
      name: gettext('hrm', 'PHY', 'hrmDiagnosisCategory')
    },
    {
      code: 'PT',
      name: gettext('hrm', 'PT', 'hrmDiagnosisCategory')
    },
    {
      code: 'RAD',
      name: gettext('hrm', 'RAD', 'hrmDiagnosisCategory')
    },
    {
      code: 'RC',
      name: gettext('hrm', 'RC', 'hrmDiagnosisCategory')
    },
    {
      code: 'RT',
      name: gettext('hrm', 'RT', 'hrmDiagnosisCategory')
    },
    {
      code: 'RUS',
      name: gettext('hrm', 'RUS', 'hrmDiagnosisCategory')
    },
    {
      code: 'RX',
      name: gettext('hrm', 'RX', 'hrmDiagnosisCategory')
    },
    {
      code: 'SP',
      name: gettext('hrm', 'SP', 'hrmDiagnosisCategory')
    },
    {
      code: 'SR',
      name: gettext('hrm', 'SR', 'hrmDiagnosisCategory')
    },
    {
      code: 'TX',
      name: gettext('hrm', 'TX', 'hrmDiagnosisCategory')
    },
    {
      code: 'VR',
      name: gettext('hrm', 'VR', 'hrmDiagnosisCategory')
    },
    {
      code: 'VUS',
      name: gettext('hrm', 'VUS', 'hrmDiagnosisCategory')
    },
    {
      code: 'XRC',
      name: gettext('hrm', 'XRC', 'hrmDiagnosisCategory')
    }
  ],
  diagnosisStatus: [
    {
      key: 'final',
      value: gettext('hrm', 'final', 'hrmDiagnosisStatus')
    },
    {
      key: 'corrected',
      value: gettext('hrm', 'corrected', 'hrmDiagnosisStatus')
    },
    {
      key: 'cancelled',
      value: gettext('hrm', 'cancelled', 'hrmDiagnosisStatus')
    }
  ],
  diagnosisLoincType: [
    {
      key: 'historyPhysical',
      value: gettext('hrm', 'historyPhysical', 'hrmDiagnosisLoincType')
    },
    {
      key: 'note',
      value: gettext('hrm', 'note', 'hrmDiagnosisLoincType')
    },
    {
      key: 'preoperativeEvaluationManagement',
      value: gettext('hrm', 'preoperativeEvaluationManagement', 'hrmDiagnosisLoincType')
    },
    {
      key: 'progress',
      value: gettext('hrm', 'progress', 'hrmDiagnosisLoincType')
    },
    {
      key: 'transferSummary',
      value: gettext('hrm', 'transferSummary', 'hrmDiagnosisLoincType')
    },
    {
      key: 'letter',
      value: gettext('hrm', 'letter', 'hrmDiagnosisLoincType')
    },
    {
      key: 'diagnosticStudy',
      value: gettext('hrm', 'diagnosticStudy', 'hrmDiagnosisLoincType')
    },
    {
      key: 'dischargeSummary',
      value: gettext('hrm', 'dischargeSummary', 'hrmDiagnosisLoincType')
    },
    {
      key: 'diagnosticImagingReport',
      value: gettext('hrm', 'diagnosticImagingReport', 'hrmDiagnosisLoincType')
    },
    {
      key: 'eKGImpression',
      value: gettext('hrm', 'eKGImpression', 'hrmDiagnosisLoincType')
    },
    {
      key: 'referral',
      value: gettext('hrm', 'referral', 'hrmDiagnosisLoincType')
    },
    {
      key: 'consultation',
      value: gettext('hrm', 'consultation', 'hrmDiagnosisLoincType')
    }
  ]
};

export default i18n;
