import { SchedulingActionPayload } from '@sigmail/app-state';
import React from 'react';
import { respondToCalendarEventAction } from '../../../../app-state/actions/scheduling/respond-to-calendar-event-action';
import { useAppState } from '../../../../app-state/hooks';
import { ActionData } from '../../types';
import { BaseContextValue, OpenedMessageViewState } from '../context';

export interface ActionDataRespondToCalendarEvent
  extends ActionData,
    Pick<SchedulingActionPayload.RespondToCalendarEvent, 'response' | 'videoConsent'> {
  currentFolder?: BaseContextValue['currentFolder'] | undefined;
  sourceMessage?: Pick<OpenedMessageViewState['openedMessage'], 'body' | 'header'> | undefined;
}

export const useRespondToCalendarEventActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({
      currentFolder,
      failCallback,
      response,
      sourceMessage,
      successCallback,
      videoConsent
    }: ActionDataRespondToCalendarEvent) => {
      let value: any;

      try {
        value = await appDispatch(
          respondToCalendarEventAction({
            folderKey: currentFolder?.folderKey,
            parentFolderKey: currentFolder?.parentFolderKey,
            msgMetadataId: sourceMessage?.header!,
            msgBodyId: sourceMessage?.body!,
            response,
            videoConsent
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
