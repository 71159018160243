/* eslint-disable import/export */

import {
    ApiService,
    Constants as ApiConstants,
    MalformedResponseException,
    ServiceException as ApiServiceException,
    VersionConflictException
} from './api';
import {
    BaseHttpService,
    Constants as HttpConstants,
    HttpService,
    logHttpError,
    logHttpRequest,
    ServiceException as HttpServiceException
} from './http';

export * from './types';

/** @public */
export const Api = {
  ApiService,
  Constants: ApiConstants,
  MalformedResponseException,
  ServiceException: ApiServiceException,
  VersionConflictException
} as const;

/** @public */
export const Http = {
  BaseHttpService,
  Constants: HttpConstants,
  HttpService,
  ServiceException: HttpServiceException,
  logHttpError,
  logHttpRequest
} as const;
