import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogEditAccuroEMRProviderId } from '../../constants/action-context';

const i18n: AccountI18n.Dialog.EditAccuroEMRProviderId = {
  action: {
    accept: {
      context: DialogEditAccuroEMRProviderId,
      // Translators: Label for the Submit action of Select Accuro EMR \
      // Provider dialog
      label: gettext('account', 'accept', 'selectAccuroEMRProviderDialogActionLabel')
    },
    reject: {
      context: DialogEditAccuroEMRProviderId,
      // Translators: Label for the Cancel action of Select Accuro EMR \
      // Provider dialog
      label: gettext('account', 'reject', 'selectAccuroEMRProviderDialogActionLabel')
    }
  },
  error: {
    // Translators: Error message displayed in Select Accuro EMR Provider \
    // dialog when Select action is clicked without selecting a record from \
    // the list
    selectRecord: gettext('account', 'selectRecord', 'selectAccuroEMRProviderDialogError'),
    // Translators: Error message displayed in Select Accuro EMR Provider \
    // dialog when access token required to connect to EMR is invalid or has \
    // expired already
    tokenExpired: gettext('account', 'tokenExpired', 'selectAccuroEMRProviderDialogError')
  },
  grid: {
    action: {
      showAllRecords: {
        context: DialogEditAccuroEMRProviderId,
        // Translators: Label for the Show All Records action of Select Accuro \
        // EMR Provider Record grid
        label: gettext('account', 'showAll', 'selectAccuroEMRProviderGridActionLabel')
      }
    },
    columnHeader: {
      // Translators: Label for the Address column header of Select Accuro EMR \
      // Provider grid
      address: gettext('account', 'address', 'selectAccuroEMRProviderGridColumnHeader'),
      // Translators: Label for the Cell Number column header of Select Accuro \
      // EMR Provider grid
      cellNumber: gettext('account', 'cellNumber', 'selectAccuroEMRProviderGridColumnHeader'),
      // Translators: Label for the Email Address column header of Select \
      // Accuro EMR Provider grid
      emailAddress: gettext('account', 'emailAddress', 'selectAccuroEMRProviderGridColumnHeader'),
      // Translators: Label for the License Number column header of Select \
      // Accuro EMR Provider grid
      licenseNumber: gettext('account', 'licenseNumber', 'selectAccuroEMRProviderGridColumnHeader'),
      // Translators: Label for the Name column header of Select Accuro EMR \
      // Provider grid
      name: gettext('account', 'name', 'selectAccuroEMRProviderGridColumnHeader'),
      // Translators: Label for the Office Number column header of Select \
      // Accuro EMR Provider grid
      officeNumber: gettext('account', 'officeNumber', 'selectAccuroEMRProviderGridColumnHeader')
    },
    legend: {
      // Translators: Label for the Previously Selected Record legend of \
      // Select Accuro EMR Provider grid
      prevSelection: gettext('account', 'prevSelection', 'selectAccuroEMRProviderGridLegend')
    },
    localeText: {
      // Translators: Message, for screen readers, explaining which key can be \
      // pressed to deselect a row in the Select Accuro EMR Provider grid
      ariaRowDeselect: gettext('account', 'ariaRowDeselect', 'selectAccuroEMRProviderGridLocaleText'),
      // Translators: Message, for screen readers, explaining which key can be \
      // pressed to select a row in the Select Accuro EMR Provider grid
      ariaRowSelect: gettext('account', 'ariaRowSelect', 'selectAccuroEMRProviderGridLocaleText'),
      // Translators: Message, for screen readers, explaining which key can be \
      // pressed to toggle all rows selection in the Select Accuro EMR Provider grid
      ariaRowSelectAll: gettext('account', 'ariaRowSelectAll', 'selectAccuroEMRProviderGridLocaleText'),
      // Translators: Message, for screen readers, explaining which key can be \
      // pressed to toggle a single row selection in the Select Accuro EMR Provider grid
      ariaRowToggleSelection: gettext('account', 'ariaRowToggleSelection', 'selectAccuroEMRProviderGridLocaleText'),
      // Translators: Message displayed in the grid when there are no rows \
      // available to show in the Select Accuro EMR Provider grid
      noRowsToShow: gettext('account', 'noRowsToShow', 'selectAccuroEMRProviderGridLocaleText')
    }
  },
  // Translators: (HTML) Title of the Select Accuro EMR Provider dialog
  title: gettext('account', 'title', 'selectAccuroEMRProviderDialog', 'safe-html')
};

export default i18n;
