import { Avatar, AvatarProps } from '@material-ui/core';
import { Utils } from '@sigmail/common';
import React from 'react';

export interface Props {
  /** The background color to use. */
  backgroundColor?: string;
  children?: never;
  className?: string;
  /** Value to be used as the first name. */
  firstName?: string | undefined;
  /** Value to be used as the last name. */
  lastName?: string | undefined;
  /** The shape of the avatar. Default is circular. */
  variant?: AvatarProps['variant'];
}

const PersonNameAvatarComponent: React.FC<Props> = ({
  backgroundColor,
  children,
  className,
  firstName,
  lastName,
  variant
}) => {
  const letters = ['', ''];

  let trimmedFirstName = Utils.trimOrDefault(firstName);
  let trimmedLastName = Utils.trimOrDefault(lastName);

  if (trimmedFirstName.length > 0) {
    const match = trimmedFirstName.match(/[a-z]+/gi);
    if (match) {
      letters[0] = match[0][0].toUpperCase();
    }
  }

  if (trimmedLastName.length > 0) {
    const match = trimmedLastName.match(/[a-z]+$/gi);
    if (match) {
      letters[1] = match[0][0].toUpperCase();
    }
  }

  let color: React.CSSProperties['color'];
  if (!Utils.isString(backgroundColor)) {
    backgroundColor = Utils.stringToHslColor(Utils.joinPersonName({ firstName, lastName }));
    color = '#fff';
  }

  return (
    <Avatar
      alt=""
      aria-hidden={true}
      className={className}
      role="presentation"
      style={{ backgroundColor, color }}
      variant={variant}
    >
      {letters.filter(Boolean).join('') || null}
    </Avatar>
  );
};

PersonNameAvatarComponent.defaultProps = {
  variant: 'circular'
};

export const PersonNameAvatar = React.memo(PersonNameAvatarComponent);
PersonNameAvatar.displayName = 'PersonNameAvatar';
