import {
  Constants,
  GroupMessageFolderKey,
  GroupMessageSubFolderKey,
  UserMessageFolderKey,
  UserMessageSubFolderKey,
  Utils
} from '@sigmail/common';
import { DataObjectMsgFolder } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  GROUP_MESSAGE_FOLDER_PREFIX,
  USER_DEFINED_MESSAGE_FOLDER_PREFIX
} from '../../../../app-state/actions/constants';
import { useDataObjectByIdSelector } from '../../../../app-state/hooks';
import { selectAccessRight } from '../../../../app-state/selectors/auth';
import * as ActionId from '../../../../constants/action-ids';
import { GroupMessageFolderMapResource, UserMessageFolderMapResource } from '../../hooks';
import { getFolderItemCount } from '../../utils';

export interface MessageFolderChildItem {
  actionId: string;
  isUserDefinedFolder?: boolean; // `false` when omitted
  itemCount: number;
  folderId: number;
  parentFolderId: number;
}

export interface MessageFolderItem extends Omit<MessageFolderChildItem, 'parentFolderId'> {
  children?: Array<MessageFolderChildItem>;
}

const ORDERED_ACTION_ID_LIST: ReadonlyArray<
  Readonly<
    [
      actionId: string,
      folderKey: GroupMessageFolderKey | UserMessageFolderKey,
      childrenIfAny:
        | ReadonlyArray<Readonly<[actionId: string, subFolderKey: GroupMessageSubFolderKey | UserMessageSubFolderKey]>>
        | undefined
    ]
  >
> = [
  [
    ActionId.ReceivedMessagesShared,
    Constants.MessageFolderKey.GroupInbox,
    [
      [ActionId.ArchivedMessagesShared, 'archived'],
      [ActionId.DeletedMessagesShared, 'deleted']
    ]
  ],
  [
    ActionId.ReceivedMessages,
    Constants.MessageFolderKey.Inbox,
    [
      [ActionId.ArchivedMessages, 'archived'],
      [ActionId.DeletedMessages, 'deleted']
    ]
  ],
  [ActionId.SentMessages, Constants.MessageFolderKey.Sent, undefined],
  [ActionId.DraftMessages, Constants.MessageFolderKey.Drafts, undefined]
];

export const useMessageFolderItemList = (
  groupMessageFolderMapResource: GroupMessageFolderMapResource,
  userMessageFolderMapResource: UserMessageFolderMapResource
) => {
  const dataObjectByIdSelector = useDataObjectByIdSelector();

  const hasAccessRight = useSelector(selectAccessRight);
  const canAccessGroupMailbox = hasAccessRight('accessGroupMailbox');
  const canAccessMailbox = hasAccessRight('accessMailbox');
  const canArchiveMessage = hasAccessRight('archiveMessage');
  const canComposeConsults = hasAccessRight('composeEConsult');
  const canComposeReferral = hasAccessRight('composeReferral');
  const canDeleteMessage = hasAccessRight('deleteMessage');
  const canSaveDraftMessage = hasAccessRight('saveMessageDraft');
  const canSendMessage = hasAccessRight('sendMessage');

  return React.useMemo<ReadonlyArray<MessageFolderItem>>(() => {
    // if (!canAccessGroupMailbox && !canAccessMailbox) return EMPTY_ARRAY;

    const groupMessageFolderMap = groupMessageFolderMapResource.value();
    const userMessageFolderMap = userMessageFolderMapResource.value();

    return Utils.transform(
      ORDERED_ACTION_ID_LIST,
      (folderItemList, [actionId, folderKey, children]) => {
        switch (actionId) {
          case ActionId.DraftMessages:
            if (!canSaveDraftMessage) return;
            break;
          case ActionId.ReceivedMessages:
            if (!canAccessMailbox) return;
            break;
          case ActionId.ReceivedMessagesShared:
            if (!canAccessGroupMailbox) return;
            break;
          case ActionId.SentMessages:
            if (!canSendMessage) return;
            break;
          default:
            break;
        }

        const folderItem = folderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX)
          ? groupMessageFolderMap[folderKey]
          : userMessageFolderMap[folderKey];
        const folderId = folderItem?.id;
        if (!DataObjectMsgFolder.isValidId(folderId)) return;

        let parentFolderId = folderId;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { all, reminder, important, referral } = getFolderItemCount(folderId, dataObjectByIdSelector);
        const itemCount = actionId === ActionId.DraftMessages ? all.total : all.unread;

        const index = folderItemList.push({ actionId, itemCount, folderId }) - 1;
        if (actionId === ActionId.ReceivedMessages) {
          // INBOX has five virtual folders: REMINDER, IMPORTANT, CONSULTATIONS,
          // VIRTUAL VISITS, and REFERRALS; the following code block handles that
          // special case
          folderItemList[index].children = [
            // { actionId: ActionId.ReminderMessages, itemCount: reminder.unread, folderId, parentFolderId: folderId },
            { actionId: ActionId.ImportantMessages, itemCount: important.unread, folderId, parentFolderId: folderId }
          ];

          // CONSULTATIONS is available to users who themselves can compose consultation requests
          if (canComposeConsults) {
            folderItemList[index].children!.push({
              actionId: ActionId.ConsultationMessages,
              itemCount: 0,
              folderId,
              parentFolderId
            });
          }

          // REFERRALS is available to users who themselves can compose referrals
          if (canComposeReferral) {
            folderItemList[index].children!.push({
              actionId: ActionId.ReferralMessages,
              itemCount: referral.unread,
              folderId,
              parentFolderId
            });
          }
        } else if (actionId === ActionId.ReceivedMessagesShared) {
          // GROUP INBOX has two virtual folders - IMPORTANT and REFERRALS
          folderItemList[index].children = [
            { actionId: ActionId.ImportantMessagesShared, itemCount: important.unread, folderId, parentFolderId }
          ];

          folderItemList[index].children!.push({
            actionId: ActionId.ReferralMessagesShared,
            itemCount: referral.unread,
            folderId,
            parentFolderId: folderId
          });
        }

        const folderChildMap = folderItem?.children;
        if (Utils.isNil(folderChildMap)) return;

        // any user-defined folders are placed above the known sub-folders,
        // and are arranged in an ascending order by name
        const userDefinedFolderList: Required<MessageFolderItem>['children'] = [];
        Object.keys(folderChildMap)
          .filter((folderKey) => folderKey.startsWith(USER_DEFINED_MESSAGE_FOLDER_PREFIX))
          .forEach((folderKey) => {
            const { id: folderId, name: folderName } = folderChildMap[folderKey] || {};
            if (
              !DataObjectMsgFolder.isValidId(folderId) ||
              !Utils.isString(folderName) ||
              folderName.trim().length === 0
            ) {
              return;
            }
            const itemCount = getFolderItemCount(folderId, dataObjectByIdSelector).all.unread;
            userDefinedFolderList.push({
              actionId: folderName,
              itemCount,
              isUserDefinedFolder: true,
              folderId,
              parentFolderId
            });
          });

        userDefinedFolderList.sort(({ actionId: name1 }, { actionId: name2 }) => name1.localeCompare(name2));
        (folderItemList[index].children || (folderItemList[index].children = [])).push(...userDefinedFolderList);

        if (Utils.isArray(children)) {
          children.forEach(([actionId, folderKey]) => {
            // prettier-ignore
            if ((actionId === ActionId.ArchivedMessages || actionId === ActionId.ArchivedMessagesShared) && !canArchiveMessage) return;
            // prettier-ignore
            if ((actionId === ActionId.DeletedMessages || actionId === ActionId.DeletedMessagesShared) && !canDeleteMessage) return;

            const folderId = folderChildMap[folderKey]?.id;
            if (!DataObjectMsgFolder.isValidId(folderId)) return;

            const itemCount = getFolderItemCount(folderId, dataObjectByIdSelector).all.unread;
            (folderItemList[index].children || (folderItemList[index].children = [])).push({
              actionId,
              itemCount,
              folderId,
              parentFolderId
            });
          });
        }
      },
      [] as Array<MessageFolderItem>
    );
  }, [
    canAccessGroupMailbox,
    canAccessMailbox,
    canArchiveMessage,
    canComposeConsults,
    canComposeReferral,
    canDeleteMessage,
    canSaveDraftMessage,
    canSendMessage,
    dataObjectByIdSelector,
    groupMessageFolderMapResource,
    userMessageFolderMapResource
  ]);
};
