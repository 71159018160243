import { Nullable, Utils } from '@sigmail/common';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';
import { isDateCtorParam } from './is-date-ctor-param';

export type CreateDateRangeOptions = NonNullable<Parameters<typeof startOfWeek>[1]>;
export type DateRangeVariant = `${'last' | 'this'}${'Month' | 'Week' | 'Year'}` | 'today' | 'yesterday';

/**
 * @param range
 * Default value `"thisMonth"` will be used when `false`, `null`, or `undefined`.
 *
 * When value is a tuple, START date of current month (relative to `dtToday`) will
 * be used for `min` when it's set to `false`, `null`, or `undefined`. Similarly,
 * END date of the month (relative to `min`) will be used for `max` when it's set
 * to `false`, `null`, or `undefined`.
 *
 * @param dtToday
 * Today's date. If not provided, local machine's date-time will be used.
 *
 * @param options
 * Options used for start/endOfWeek calculations.
 * (see [StartOfWeekOptions](https://date-fns.org/v3.6.0/docs/startOfWeek#types/StartOfWeekOptions/1362))
 *
 * @returns
 * A tuple of both valid dates, or a tuple of both elements set to `undefined`.
 */
export function createDateRange(
  range: Nullable<
    | false
    | Date
    | number
    | (DateRangeVariant | (string & {}))
    | readonly [min?: Nullable<false | Date | number | string>, max?: Nullable<false | Date | number | string>]
  >,
  dtToday?: Nullable<false | Date | number | string>,
  options?: Nullable<false | CreateDateRangeOptions>
): [start: Date, end: Date] | [undefined, undefined] {
  let dtEnd: Date | undefined;
  let dtStart: Date | undefined;

  try {
    dtToday = startOfDay(isDateCtorParam(dtToday) ? new Date(dtToday) : new Date());

    if (Utils.isDate(range) || Utils.isInteger(range)) {
      dtStart = startOfDay(new Date(range));
      dtEnd = endOfDay(dtStart);
    } else if (Utils.isArray<Date | number | string | undefined>(range)) {
      const [min, max] = range;
      dtStart = isDateCtorParam(min) ? new Date(min) : startOfMonth(dtToday);
      dtEnd = isDateCtorParam(max) ? new Date(max) : endOfMonth(dtStart);
    } else {
      range = Utils.stringOrDefault<DateRangeVariant>(range, 'thisMonth');
      if (range === 'lastMonth' || range === 'thisMonth') {
        dtStart = startOfMonth(dtToday);
        if (range === 'lastMonth') {
          dtStart = startOfMonth(dtStart.getTime() - 1);
        }
        dtEnd = endOfMonth(dtStart);
      } else if (range === 'lastWeek' || range === 'thisWeek') {
        const opts = Utils.isNonArrayObjectLike<typeof options>(options) ? options : undefined;
        dtStart = startOfWeek(dtToday, opts);
        if (range === 'lastWeek') {
          dtStart = startOfWeek(dtStart.getTime() - 1, opts);
        }
        dtEnd = endOfWeek(dtStart, opts);
      } else if (range === 'lastYear' || range === 'thisYear') {
        dtStart = startOfYear(dtToday);
        if (range === 'lastYear') {
          dtStart = startOfYear(dtStart.getTime() - 1);
        }
        dtEnd = endOfYear(dtStart);
      } else if (range === 'today' || range === 'yesterday') {
        dtStart = startOfDay(dtToday.getTime() - Number(range === 'yesterday'));
        dtEnd = endOfDay(dtStart);
      }

      if (Utils.isUndefined(dtStart) && Utils.isUndefined(dtEnd)) {
        dtStart = startOfDay(new Date(range));
        dtEnd = endOfDay(dtStart);
      }
    }
  } catch {
    dtStart = dtEnd = undefined;
  }

  if (!Utils.isValidDate(dtStart) || !Utils.isValidDate(dtEnd)) {
    dtStart = dtEnd = undefined;
  }

  return [dtStart!, dtEnd!];
}
