import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import { AppException, AppUser, Constants, MemberRole, PersonName, ReadonlyMessageBodyHrm, SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectDocBody,
  DataObjectDocMetadata,
  DataObjectDocMetadataValue,
  DataObjectMsgBody,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  MessageFolderItemCount,
  MessageFolderListItem,
  MessageTimestampRecord,
  UserObjectContactInfoValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import * as UserObjectSelectors from '../../selectors/user-object';
import { UserObjectCache } from '../../user-objects-slice/cache';
import { AuthenticatedActionState } from '../authenticated-action';
import { AUTH_STATE_LOG_EVENT, AUTH_STATE_SEND_HRM_MESSAGE } from '../constants/auth-state-identifier';
import { logEventAction } from '../log-event-action';
import { BaseMessagingAction } from './base-messaging-action';
import { createMessageFolderExtensionAction } from './create-message-folder-extension-action';
import { updateMessageFolderAction } from './update-msg-folder-action';

type Payload = MessagingActionPayload.SendHrmMessage;

interface State extends AuthenticatedActionState {
  batchUpdateAuthState: string;
  batchUpdateClaims: Array<string>;
  documentList: Array<Required<MessageFolderListItem>['documentList'][0]>;
  dtServer: Date;
  hrmMsgBodyValue: ReadonlyMessageBodyHrm['messageForm']['value'];
  hrmRequestId: number;
  idRecord: Api.GetIdsResponseData['ids'];
  requestBody: BatchUpdateRequestBuilder;
  senderId: number;
  sentAtUtc: number;
  sentFolderUpdateIdList: Array<
    [metadataId: SigmailObjectId, bodyId: SigmailObjectId, key: Extract<keyof MessageTimestampRecord, `${'corrected' | 'sentToHRM'}At`>]
  >;
  sentMessage: MessageFolderListItem;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

class SendHrmMessageAction extends BaseMessagingAction<Payload, State> {
  protected async preExecute() {
    const result = await super.preExecute();
    this.state.senderId = this.state.currentUser.id;
    this.state.dtServer = await this.dispatchFetchServerDateAndTime();
    return result;
  }

  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        this.state.sentFolderUpdateIdList = [];

        await this.generateIdSequence();
        await this.fetchSourceHrmMessage();

        this.initializeRequestBodyAndSuccessPayload();
        await this.generateRequestBody();

        await this.sendMessageToHRM();
        await this.addInsertOperationForUserEventLog(); // 425

        const { batchUpdateAuthState: authState, batchUpdateClaims: claims, requestBody } = this.state;
        await this.dispatchBatchUpdateData({ authState, claims, ...requestBody.build() });

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }

    try {
      await this.dispatchBatchQueryDataSuccess(this.state.successPayload);
    } catch (error) {
      this.logger.warn('Error manually updating app state:', error);
      /* ignore */
    }

    try {
      await this.dispatch(createMessageFolderExtensionAction({ folderKey: Constants.MessageFolderKey.Sent }));
    } catch (error) {
      this.logger.warn('Error creating message folder extension:', error);
      /* ignore */
    }
  }

  private async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const { docs: documentList } = this.payload.messageBody.document;
    const { roleAuthClaim } = this.state;

    const documentIds: Array<Required<Api.GetIdsRequestData['ids']>['ids'][0]> = [];
    if (Utils.isNonEmptyArray(documentList)) {
      const { length: count } = documentList;
      documentIds.push({ type: process.env.DATA_OBJECT_TYPE_DOC_METADATA, count });
      documentIds.push({ type: process.env.DATA_OBJECT_TYPE_DOC_BODY, count });
    }

    const query: Api.GetIdsRequestData = {
      authState: roleAuthClaim,
      state: AUTH_STATE_SEND_HRM_MESSAGE,
      ids: {
        ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_METADATA }, { type: process.env.DATA_OBJECT_TYPE_MSG_BODY }, ...documentIds]
      }
    };

    const { ids: idRecord, authState, idsClaim } = await this.dispatchFetchIdsByUsage(query);
    this.state.idRecord = idRecord;
    this.state.batchUpdateAuthState = authState;
    this.state.batchUpdateClaims = [idsClaim];
  }

  private async fetchSourceHrmMessage(): Promise<void> {
    const { idRecord, dtServer } = this.state;
    [this.state.hrmRequestId] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_BODY];
    this.state.sentAtUtc = dtServer.getTime();

    const { sourceHrmMessage } = this.payload;
    if (Utils.isNil(sourceHrmMessage)) return;

    this.logger.info("Fetching source HRM message's metadata and body.");
    const msgMetadata = await this.getDataObjectValue<DataObjectMsgMetadataValue>(sourceHrmMessage.header);
    const msgBody = await this.getDataObjectValue<DataObjectMsgBodyValue>(sourceHrmMessage.body);
    if (Utils.isNil(msgMetadata) || Utils.isNil(msgBody)) {
      throw new AppException(
        Constants.Error.E_DATA_MISSING_OR_INVALID,
        "Existing HRM message's metadata and/or body object could not be fetched."
      );
    }

    this.state.sentAtUtc = msgMetadata.sentAtUtc;
    this.state.sentFolderUpdateIdList.push([sourceHrmMessage.header, sourceHrmMessage.body, 'correctedAt']);
    this.state.hrmRequestId = (msgBody as ReadonlyMessageBodyHrm).messageForm.value.id;
  }

  private initializeRequestBodyAndSuccessPayload(): void {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };
  }

  private async generateRequestBody(): Promise<void> {
    await this.addInsertOperationsForDocumentList(); // 301s and 302s
    await this.addInsertOperationForMessageMetadata(); // 201
    await this.addInsertOperationForMessageBody(); // 202

    await this.addUpdateOperationForCurrentMessagesFolder(); // 251/252
    await this.addUpdateOperationForSentMessagesFolder(); // 251/252
  }

  private async addInsertOperationsForDocumentList(): Promise<void> {
    const { docs: documentList } = this.payload.messageBody.document;
    if (!Utils.isNonEmptyArray(documentList)) return;

    this.logger.info("Adding an insert operation each to request body for every document's metadata, body, and associated keys.");

    const { idRecord, ownerId, senderId, dtServer, auditId, requestBody } = this.state;

    this.state.documentList = [];
    for (let index = 0; index < documentList.length; index++) {
      const doc = documentList[index];

      const { length: docSize } = window.atob(doc.data);
      const docMetadata: DataObjectDocMetadataValue = { $$formatver: 1, name: doc.title, size: docSize, docType: `document${index + 1}` };
      const docMetadataId = idRecord[process.env.DATA_OBJECT_TYPE_DOC_METADATA][index];
      const docMetadataObject = await DataObjectDocMetadata.create(docMetadataId, undefined, 0, docMetadata, ownerId, senderId, dtServer);

      const docBody: DataObjectMsgBodyValue = { $$formatver: 1, data: `data:${doc.contentType};base64,${doc.data}` };
      const docBodyId = idRecord[process.env.DATA_OBJECT_TYPE_DOC_BODY][index];
      const docBodyObject = await DataObjectDocBody.create(docBodyId, undefined, 0, docBody, ownerId, senderId, dtServer);

      const objectList = [docMetadataObject, docBodyObject];
      const docMetadataKeyList = await docMetadataObject.generateKeysEncryptedFor(auditId);
      docMetadataKeyList.push(docMetadataObject[Constants.$$CryptographicKey]);
      const docBodyKeyList = await docBodyObject.generateKeysEncryptedFor(auditId);
      docBodyKeyList.push(docBodyObject[Constants.$$CryptographicKey]);
      requestBody.insert(objectList);
      requestBody.insert(docMetadataKeyList.filter(Utils.isNotNil));
      requestBody.insert(docBodyKeyList.filter(Utils.isNotNil));

      this.state.documentList!.push({ metadata: docMetadataId, body: docBodyId, name: docMetadata.name, size: docMetadata.size });
    }
  }

  private async getGuestUserForMetadataValue(): Promise<DataObjectMsgMetadataValue['guestUser']> {
    const { messageBody, sourceHrmMessage } = this.payload;

    if (Utils.isNotNil(sourceHrmMessage?.guestUser)) {
      return sourceHrmMessage!.guestUser;
    }

    let guestContactInfo: UserObjectContactInfoValue | undefined;
    let guestUserId = Number.parseInt(messageBody.patient.id, 10);
    if (AppUser.isValidId(guestUserId)) {
      try {
        guestContactInfo = await this.getUserObjectValue(UserObjectSelectors.contactInfoObjectSelector, {
          type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
          userId: guestUserId
        });
      } catch {
        /* ignore */
      }
    }

    if (Utils.isNotNil(guestContactInfo)) {
      const contactData = {
        ...UserObjectSelectors.selectContactNumber(guestContactInfo),
        ...UserObjectSelectors.selectPersonName(guestContactInfo),
        ...UserObjectSelectors.selectPostalAddress(guestContactInfo),
        ...UserObjectSelectors.selectRoleBasedData(guestContactInfo, guestContactInfo)
      } as const;

      if (contactData.role === (Constants.ROLE_ID_GUEST as Extract<MemberRole, 'patient'>) && contactData.healthCardNumber.length > 0) {
        let address: NonNullable<DataObjectMsgMetadataValue['guestUser']>['address'];
        const postalAddress = Utils.pick(contactData, Constants.POSTAL_ADDRESS_KEY_LIST);
        if (Object.values(postalAddress).some((value) => value.length > 0)) {
          address = Object.fromEntries(Object.entries(postalAddress).filter(([, value]) => value.length > 0));
        }

        let birthDate: NonNullable<DataObjectMsgMetadataValue['guestUser']>['birthDate'];
        if (contactData.birthDate.length > 0) birthDate = contactData.birthDate;

        let contact: NonNullable<DataObjectMsgMetadataValue['guestUser']>['contact'];
        if (contactData.cellNumber.length > 0) {
          contact = { type: 'mobile', value: contactData.cellNumber };
        } else if (contactData.homeNumber.length > 0) {
          contact = { type: 'home', value: contactData.homeNumber };
        }

        const contactName: Pick<NonNullable<DataObjectMsgMetadataValue['guestUser']>, keyof PersonName> = {
          firstName: '',
          lastName: '',
          ...Object.fromEntries(
            Object.entries(Utils.pick(contactData, Constants.PERSON_NAME_KEY_LIST)).filter(([, value]) => value.length > 0)
          )
        };

        return {
          address,
          birthDate,
          contact,
          ...contactName,
          gender: contactData.gender,
          healthPlan: {
            jurisdiction: contactData.healthPlanJurisdiction,
            number: contactData.healthCardNumber
          },
          id: guestUserId
        };
      }
    }

    return undefined;
  }

  private async addInsertOperationForMessageMetadata(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for message metadata and associated keys.');

    const { messageBody } = this.payload;
    const { senderId, idRecord, documentList, sentAtUtc, ownerId, dtServer, auditId, requestBody } = this.state;

    const senderProfileObjectBasic = UserObjectSelectors.basicProfileObjectSelector(this.getRootState())(senderId);
    const senderProfileBasic = UserObjectCache.getValue(senderProfileObjectBasic);
    if (Utils.isNil(senderProfileBasic)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, "Sender's basic profile object could not be fetched.");
    }

    const [id] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA];
    const recipientNameList: Array<string> = [];
    const guestUser = await this.getGuestUserForMetadataValue();
    const value: DataObjectMsgMetadataValue = {
      $$formatver: 1,
      documentList: documentList!,
      guestUser,
      importance: 'normal',
      messageForm: { name: Constants.MessageFormName.HRM },
      readReceiptId: 0,
      recipientList: messageBody.practitioners.map<DataObjectMsgMetadataValue['recipientList'][0]>((hrmUser) => {
        recipientNameList.push(Utils.joinPersonName(hrmUser));

        return {
          entity: {
            id: Number.parseInt(hrmUser.practitionerId, 10),
            type: 'user',
            entityType: 'primary',
            firstName: hrmUser.firstName,
            lastName: hrmUser.lastName
          }
        };
      }),
      sender: { type: 'user', id: senderId, ...Utils.pick(senderProfileBasic, Constants.PERSON_NAME_KEY_LIST) },
      // IMPORTANT: subject line prefix is intentionally non-translated
      subjectLine: `HRM message for ${Utils.joinPersonName(messageBody.patient.name)}`,
      sentAtUtc
    };
    this.logger.debug({ id, ...value });

    const msgMetadataObject = await DataObjectMsgMetadata.create(id, undefined, 0, value, ownerId, senderId, dtServer);
    const keyList = await msgMetadataObject.generateKeysEncryptedFor(auditId);
    keyList.push(msgMetadataObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgMetadataObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.sentMessage = {
      body: idRecord[process.env.DATA_OBJECT_TYPE_MSG_BODY][0],
      documentList: value.documentList,
      entity: recipientNameList,
      extraData: null,
      flags: { markedAsRead: true },
      guestUser,
      header: id,
      linkedMessageList: undefined,
      messageForm: { name: value.messageForm!.name },
      snippet: null,
      subject: value.subjectLine,
      timestamp: {
        createdAt: dtServer.getTime(),
        firstReadAt: dtServer.getTime()
      }
    };
  }

  private async addInsertOperationForMessageBody(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for message body and associated keys.');

    const { messageBody } = this.payload;
    const { idRecord, hrmRequestId, documentList, ownerId, senderId, dtServer, auditId, requestBody } = this.state;

    const patientAddress = messageBody.patient.address.map((address) => ({
      ...address,
      line: address.line[0].length === 0 ? ['NA'] : address.line,
      city: address.city.length === 0 ? 'NA' : address.city,
      postalCode: address.postalCode.length === 0 ? 'M1M1M1' : address.postalCode
    }));

    const [id] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_BODY];
    const value: DataObjectMsgBodyValue = {
      $$formatver: 1,
      messageForm: {
        name: Constants.MessageFormName.HRM,
        value: {
          ...messageBody,
          id: hrmRequestId,
          patient: {
            ...messageBody.patient,
            address: patientAddress
          },
          document: {
            ...messageBody.document,
            docs: messageBody.document.docs.map(({ data, ...doc }, index) => ({
              ...doc,
              metadataId: documentList[index].metadata,
              bodyId: documentList[index].body,
              createdDate: dtServer.toISOString()
            }))
          }
        }
      }
    };
    this.logger.debug({ id, ...value });

    const msgBodyObject = await DataObjectMsgBody.create(id, undefined, 0, value, ownerId, senderId, dtServer);
    const keyList = await msgBodyObject.generateKeysEncryptedFor(auditId);
    keyList.push(msgBodyObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgBodyObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.hrmMsgBodyValue = value.messageForm.value;
  }

  private async addUpdateOperationForCurrentMessagesFolder(): Promise<void> {
    const { folderKey, sourceMessage, parentFolderKey } = this.payload;
    const { requestBody, successPayload } = this.state;

    if (Utils.isNil(sourceMessage) || (Utils.isNil(folderKey) && Utils.isNil(parentFolderKey))) {
      return;
    }

    if (folderKey === Constants.MessageFolderKey.Sent) {
      this.state.sentFolderUpdateIdList.push([sourceMessage.header, sourceMessage.body, 'sentToHRMAt']);
      return;
    }

    this.logger.info('Adding an update operation to request body for current message folder.');
    await this.dispatch(
      updateMessageFolderAction({
        applyUpdate: this.applyCurrentMessagesFolderUpdate.bind(this),
        folderKey: folderKey!,
        parentFolderKey,
        requestBody,
        successPayload
      })
    );
  }

  private async addUpdateOperationForSentMessagesFolder(): Promise<void> {
    this.logger.info('Adding an update operation to request body for sent message folder.');

    const { requestBody, successPayload } = this.state;

    await this.dispatch(
      updateMessageFolderAction({
        applyUpdate: this.applySentMessagesFolderUpdate.bind(this),
        folderKey: Constants.MessageFolderKey.Sent,
        requestBody,
        successPayload
      })
    );
  }

  private applyCurrentMessagesFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    __UNUSED_itemCount: MessageFolderItemCount,
    { folderOrExtId, folderOrExtType }: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { sourceMessage } = this.payload;
    const { dtServer } = this.state;

    const folderOrExt = `folder${folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const { header: msgMetadataId, body: msgBodyId } = sourceMessage!;
    this.logger.info(`Locating item with metadata ID <${msgMetadataId}> and body ID <${msgBodyId}> in ${folderOrExt} <${folderOrExtId}>.`);
    const index = folderData.findIndex(({ header, body }) => header === msgMetadataId && body === msgBodyId);
    if (index === -1) return;

    const message = folderData[index];
    let newTimestamp: MessageTimestampRecord;
    if (Utils.isInteger(message.timestamp)) {
      newTimestamp = { createdAt: message.timestamp, sentToHRMAt: dtServer.getTime() };
    } else {
      newTimestamp = { ...message.timestamp, sentToHRMAt: dtServer.getTime() };
    }

    folderData[index] = { ...message, timestamp: newTimestamp };
    return { updated: true, done: true };
  }

  private applySentMessagesFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    itemCount: MessageFolderItemCount,
    { folderOrExtId, folderOrExtType }: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { sourceHrmMessage } = this.payload;
    const { sentFolderUpdateIdList, sentMessage, dtServer } = this.state;

    const folderOrExt = `folder${folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    if (folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER && Utils.isNil(sourceHrmMessage)) {
      folderData.unshift(sentMessage);

      ++itemCount.all.total;
      result.updated = true;
    }

    for (let i = sentFolderUpdateIdList.length - 1; i >= 0; i--) {
      const [msgMetadataId, msgBodyId, key] = sentFolderUpdateIdList[i];

      this.logger.info(
        `Locating item with metadata ID <${msgMetadataId}> and body ID <${msgBodyId}> in ${folderOrExt} <${folderOrExtId}>.`
      );
      const index = folderData.findIndex(({ header, body }) => header === msgMetadataId && body === msgBodyId);
      if (index === -1) continue;

      const message = folderData[index];
      let newTimestamp: MessageTimestampRecord;
      if (Utils.isInteger(message.timestamp)) {
        newTimestamp = { createdAt: message.timestamp, [key]: dtServer.getTime() };
      } else {
        newTimestamp = { ...message.timestamp, [key]: dtServer.getTime() };
      }

      if (key === 'sentToHRMAt') {
        folderData[index] = { ...message, timestamp: newTimestamp };
      } else {
        folderData[index] = {
          ...sentMessage,
          timestamp: newTimestamp,
          flags: {
            ...message.flags,
            ...sentMessage.flags
          }
        };
      }

      sentFolderUpdateIdList.splice(index, 1);

      result.updated = true;
    }

    result.done = sentFolderUpdateIdList.length === 0;
    return result;
  }

  private sendMessageToHRM(): Promise<Api.HrmSendReportResponseData> {
    const { accessToken, clientId, currentUser } = this.state;
    const { notes, ...messageBody } = this.payload.messageBody;
    const { id: requestId, patient } = this.state.hrmMsgBodyValue;

    const requestData: Api.HrmSendReportRequestData = {
      ...messageBody,
      clientId,
      patient: patient as Api.HrmSendReportRequestData['patient'],
      requestId,
      userId: currentUser.id
    };

    return this.apiService.hrmSendReport(accessToken, requestData);
  }

  private async addInsertOperationForUserEventLog(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for user event log.');

    const { messageBody, sourceHrmMessage } = this.payload;
    const { currentUser, dtServer, hrmRequestId, requestBody, roleAuthClaim: authState, successPayload } = this.state;

    const claims = await this.dispatch(
      logEventAction({
        dtServer,
        fetchIds: (count) => {
          return this.dispatchFetchIdsByUsage({
            authState,
            state: AUTH_STATE_LOG_EVENT,
            ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
          });
        },
        logger: this.logger,
        record: this.newEventLogRecordValue(
          dtServer,
          Constants.EventLogCode.MessageSentToHRM,
          hrmRequestId,
          messageBody.patient,
          messageBody.practitioners,
          sourceHrmMessage
        ),
        requestBody,
        successPayload,
        userId: currentUser.id,
        userIdType: 'user'
      })
    );

    this.state.batchUpdateClaims.push(...claims);
  }
}

export const sendHrmMessageAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'sendHrmMessageAction:');

    const action = new SendHrmMessageAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
