import type { CanadianProvinceCode } from '../types';

export const Alberta: Extract<CanadianProvinceCode, 'AB'> = 'AB';
export const BritishColumbia: Extract<CanadianProvinceCode, 'BC'> = 'BC';
export const Manitoba: Extract<CanadianProvinceCode, 'MB'> = 'MB';
export const NewBrunswick: Extract<CanadianProvinceCode, 'NB'> = 'NB';
export const NovaScotia: Extract<CanadianProvinceCode, 'NS'> = 'NS';
export const NewfoundlandAndLabrador: Extract<CanadianProvinceCode, 'NL'> = 'NL';
export const NorthwestTerritories: Extract<CanadianProvinceCode, 'NT'> = 'NT';
export const Nunavut: Extract<CanadianProvinceCode, 'NU'> = 'NU';
export const Ontario: Extract<CanadianProvinceCode, 'ON'> = 'ON';
export const PrinceEdwardIsland: Extract<CanadianProvinceCode, 'PE'> = 'PE';
export const Quebec: Extract<CanadianProvinceCode, 'QC'> = 'QC';
export const Saskatchewan: Extract<CanadianProvinceCode, 'SK'> = 'SK';
export const Yukon: Extract<CanadianProvinceCode, 'YT'> = 'YT';
