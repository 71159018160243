import { ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from '../app-state';
import { DialogAccept, DialogReject } from '../constants/action-ids';
import { useServiceWorker } from '../hooks';
import { useTranslation } from '../i18n';
import { I18N_NS_GLOBAL } from '../i18n/config/namespace-identifiers';
import globalI18n from '../i18n/global';
import { UITheme } from '../ui-theme';
import { resolveActionLabel } from '../utils/resolve-action-label';
import { AppRouterOutlet } from './app-router-outlet.component';
import style from './app.module.css';
import { AppLayout } from './layout/app-layout.component';
import { DialogActionsAcceptReject } from './shared/dialog/actions/accept-reject.component';
import { DialogBox } from './shared/dialog/dialog-box.component';

export interface Props {
  children?: never;
}

const { newVersionAvailable: dialogI18n } = globalI18n.dialog;

const AppComponent: React.FC<Props> = () => {
  const { t } = useTranslation([I18N_NS_GLOBAL]);
  const worker = useServiceWorker();

  type TConfirmUpdateSigMail = (proceed: boolean) => void;
  const [confirmUpdateSigMail, setConfirmUpdateSigMail] = React.useState<TConfirmUpdateSigMail | null>(null);

  React.useEffect(() => {
    if (worker === null) return;

    worker.addEventListener('waiting', () => {
      worker.addEventListener('controlling', () => {
        window.location.reload();
      });

      setConfirmUpdateSigMail((state: TConfirmUpdateSigMail | null) => {
        if (state !== null) return state;

        return (proceed: boolean): void => {
          if (proceed) worker.messageSkipWaiting();
          setConfirmUpdateSigMail(null);
        };
      });
    });
  }, [worker]);

  const actionLabelAccept = t(resolveActionLabel(dialogI18n.action.accept, DialogAccept));
  const actionLabelReject = t(resolveActionLabel(dialogI18n.action.reject, DialogReject));
  const body = t(dialogI18n.body, { CLASS_NAME_NOTE_1: style.note1, CLASS_NAME_NOTE_2: style.note2 });
  const title = t(dialogI18n.title, { CLASS_NAME_LOGO: style['app-logo'] });

  return (
    <ThemeProvider theme={UITheme}>
      <Provider store={store}>
        <Router>
          <AppLayout>
            <AppRouterOutlet />

            <DialogBox
              actions={
                <DialogActionsAcceptReject
                  AcceptActionProps={{
                    children: actionLabelAccept,
                    onClick: () => confirmUpdateSigMail!(true),
                    type: 'button'
                  }}
                  RejectActionProps={{
                    children: actionLabelReject,
                    onClick: () => confirmUpdateSigMail!(false)
                  }}
                />
              }
              ContentProps={{
                classes: { root: style.body },
                dangerouslySetInnerHTML: { __html: body }
              }}
              styleName="style.dialog style.new-version-available"
              disableBackdropClick={true}
              disableEscapeKeyDown={true}
              onClose={() => setConfirmUpdateSigMail(null)}
              open={confirmUpdateSigMail !== null}
              TitleProps={{ dangerouslySetInnerHTML: { __html: title } }}
            />
          </AppLayout>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export const App = AppComponent;
App.displayName = 'App';
