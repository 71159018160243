import type { SigmailObjectTypeCode } from '@sigmail/common';
import type { UserObjectAccessRightsValue as DV } from '../types';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS;

/** @public */
export class UserObjectAccessRights extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    return super.isValidDecryptedValue(value);
  }

  public override updateValue(newValue: DV): Promise<UserObjectAccessRights> {
    const Class = this.constructor as typeof UserObjectAccessRights;
    return Class.updateValue(this, newValue);
  }
}
