import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogSelectEMRPatientRecord } from '../../constants/action-context';

const i18n: GlobalI18n['dialog']['selectPatientRecord'] = {
  action: {
    accept: {
      // Translators: Label for the Cancel action of Select EMR Patient Record \
      // dialog
      label: gettext('global', 'accept', 'selectEMRPatientRecordDialogActionLabel'),
      context: DialogSelectEMRPatientRecord
    },
    reject: {
      // Translators: Label for the Select action of Select EMR Patient Record \
      // dialog
      label: gettext('global', 'reject', 'selectEMRPatientRecordDialogActionLabel'),
      context: DialogSelectEMRPatientRecord
    }
  },
  // Translators: (HTML) Title of the Select EMR Patient Record dialog
  title: gettext('global', 'title', 'selectEMRPatientRecordDialog', 'safe-html')
};

export default i18n;
