const { BreakpointKeys, BreakpointValues } = require('../constants');

const BASE_FONT_FAMILY_SANS = [
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  '"Noto Sans"',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
  'sans-serif'
];

const FONT_FAMILY_MONO = ['Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'];

const { GRID_GUTTER_WIDTH, default: gridUtilities } = require('./plugins/grid');

const plugins = [
  require('./plugins/button'),
  gridUtilities,
  require('./plugins/embed'),
  // require('./plugins/modal'),
  ({ addUtilities, theme }) =>
    addUtilities({
      '.h1': {
        color: theme('colors.mineShaft'),
        fontSize: '2.625rem',
        fontWeight: 300,
        lineHeight: 1.3
      },
      '.lead': {
        color: theme('colors.secondary')
      }
    })
];

const themeColorPalette = {
  transparent: 'transparent',

  black: '#000',
  white: '#fff',

  havelockBlue: '#52a3d8',
  allports: '#0074a6',
  blueLagoon: '#00838f',
  regalBlue: '#004977',

  doveGray: '#666666',
  tundora: '#444444',
  codGray: '#191919',
  alto: '#dddddd',
  geyser: '#d8e0e7',
  athensGray: '#f5f7f9',
  dustyGray: '#999999',

  mineShaft: '#323232',
  silver: '#c0c0c0',
  forestGreen: '#28771f',
  crimson: '#dc143c',
  mercury: '#e5e5e5',
  mystic: '#ecf0f3',

  milkPunch: '#FFF6D6',
  chelseaGem: '#946805',

  highlight: '#fff4e5'
};

const colors = {
  ...themeColorPalette,

  primaryLight: themeColorPalette.havelockBlue,
  primary: themeColorPalette.allports,
  primaryDark: themeColorPalette.regalBlue,

  secondaryLight: themeColorPalette.doveGray,
  secondary: themeColorPalette.tundora,
  secondaryDark: themeColorPalette.codGray,

  textPrimary: themeColorPalette.mineShaft,
  textSecondary: '#9e9e9e', //the equivalent of (0,0,0,0.38)
  textDisabled: 'rgba(0,0,0,0.26)',

  success: themeColorPalette.forestGreen,
  danger: themeColorPalette.crimson
};

module.exports = {
  purge: false,
  theme: {
    screens: BreakpointKeys.reduce((breakpoint, key, index) => {
      breakpoint[key] = `${BreakpointValues[index]}px`;
      return breakpoint;
    }, {}),
    colors,
    fontFamily: {
      mono: FONT_FAMILY_MONO,
      base: BASE_FONT_FAMILY_SANS,
      sans: BASE_FONT_FAMILY_SANS,
      serif: BASE_FONT_FAMILY_SANS
    },
    extend: {
      height: {
        meetingRoomMobileSidebar: '5rem'
      },
      maxHeight: {
        meetingRoomVideo: '60vh'
      },
      padding: {
        meetingRoomMobileSidebar: '1rem'
      },
      spacing: {
        gutter: `${GRID_GUTTER_WIDTH}px`,
        'gutter-1/2': `${GRID_GUTTER_WIDTH / 2}px`,
        'gutter-2x': `${GRID_GUTTER_WIDTH * 2}px`
      },
      width: {
        sidebar: '20rem',
        meetingRoomSidebar: '10rem',
        meetingRoomParticipantBorder: '2px'
      },
      zIndex: {
        dropdown: 1000,
        sticky: 1020,
        fixed: 1030,
        'modal-backdrop': 1040,
        modal: 1050,
        popover: 1060,
        tooltip: 1070
      }
    }
  },
  variants: {},
  plugins
};
