import { AppException, AppUser, CancelablePromise, Constants, Utils } from '@sigmail/common';
import { getLogger } from '@sigmail/logging';
import { ApiFormattedDataObject, DataObjectCalendarEvent, UserObjectProfileBasicValue } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { isBefore, subMilliseconds } from 'date-fns';
import React from 'react';
import { connect, ConnectedProps as ReduxConnectedProps } from 'react-redux';
import Video, { LocalAudioTrack, LocalTrackPublication, LocalVideoTrack, RemoteParticipant } from 'twilio-video';
import { AppDispatch } from '../../../app-state';
import { batchQueryDataAction } from '../../../app-state/actions/batch-query-data-action';
import { getVideoTokenAction } from '../../../app-state/actions/get-video-token-action';
import { addEventToDismissedList, toggleEventOnGoing } from '../../../app-state/reminder-notification-slice';
import { RootState } from '../../../app-state/root-reducer';
import * as AuthSelectors from '../../../app-state/selectors/auth';
import * as UserObjectSelectors from '../../../app-state/selectors/user-object';
import { UserObjectCache } from '../../../app-state/user-objects-slice/cache';
import { REMINDER_NOTIFICATION_REMIND_INTERVAL } from '../../../constants';
import { EventFlags } from '../../../utils';
import { calendarEventEndTime } from '../../../utils/calendar-event-end-time';
import { calendarEventStartTime } from '../../../utils/calendar-event-start-time';
import { CONNECT_OPTIONS, DEFAULT_VIDEO_CONSTRAINTS } from './constants';
import { ConnectionState, Context, ContextValue } from './meeting-room.context';
import {
  AlreadyConnectedError,
  AudioInputDeviceMissingError,
  CameraPermissionDeniedError,
  EventObjectFetchFailureError,
  ExpiredMeetingError,
  MediaPermissionDeniedError,
  MeetingAccessTokenError,
  MeetingNotStartedError,
  MicrophonePermissionDeniedError,
  PermissionName
} from './types';

const Logger = getLogger('MeetingRoomContextProvider');

export interface Props {
  children: React.ReactNode;
  eventObjectId: number;
  onConnect?: ((...args: any[]) => any) | null | undefined;
  onDisconnect?: ((...args: any[]) => any) | null | undefined;
}

const mapStateToProps = (state: RootState) => {
  let currentUserId = AuthSelectors.currentUserIdSelector(state);
  const authState = AuthSelectors.authClaimSelector(state);
  let basicProfile: UserObjectProfileBasicValue | undefined = undefined;

  if (AppUser.isValidId(currentUserId)) {
    const basicProfileObject = UserObjectSelectors.basicProfileObjectSelector(state)(/***/);
    basicProfile = UserObjectCache.getValue(basicProfileObject);
  }

  return { currentUserId, authState, basicProfile };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({ dispatch });

const mergeProps = (
  { authState, ...stateProps }: ReturnType<typeof mapStateToProps>,
  { dispatch, ...dispatchProps }: ReturnType<typeof mapDispatchToProps>,
  ownProps: Props
) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,

  dispatchBatchQueryData(query: Omit<Api.BatchQueryRequestData, 'authState'>) {
    return dispatch(batchQueryDataAction({ query: { ...query, authState } }));
  },

  dispatchGetVideoToken(roomName: string) {
    const { currentUserId } = stateProps;

    if (!AppUser.isValidId(currentUserId)) {
      throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID);
    }

    return dispatch(
      getVideoTokenAction({
        data: { identity: currentUserId.toString(10), room: roomName }
      })
    );
  },

  dispatchAddEventToDismissedList() {
    const { eventObjectId } = ownProps;
    return dispatch(addEventToDismissedList([eventObjectId]));
  },

  dispatchToggleEventOnGoing() {
    return dispatch(toggleEventOnGoing());
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps, { forwardRef: true });
type ConnectedProps = Omit<ReduxConnectedProps<typeof withConnect>, keyof Props>;

interface ComponentProps extends Props, ConnectedProps {}

type ReturnTypeInitiateConnection = Omit<ConnectionState, 'isConnecting'>;

interface State extends ContextValue {
  batchQueryPromise: CancelablePromise<Api.BatchQueryResponseData> | null;
  connectPromise: CancelablePromise<ReturnTypeInitiateConnection> | null;
  publishVideoTrackPromise: CancelablePromise<
    [track: Video.LocalVideoTrack | null, videoInputDevices: Array<MediaDeviceInfo>]
  > | null;
}

const STATE_PROPS_TO_OMIT: ReadonlyArray<Exclude<keyof State, keyof ContextValue>> = [
  'batchQueryPromise',
  'connectPromise',
  'publishVideoTrackPromise'
];

async function getMediaDevices() {
  Logger.info('Enumerating media devices');
  const devices = await navigator.mediaDevices.enumerateDevices();

  const audioInputDevices = devices.filter(({ kind }) => kind === 'audioinput');
  const videoInputDevices = devices.filter(({ kind }) => kind === 'videoinput');
  const audioOutputDevices = devices.filter(({ kind }) => kind === 'audiooutput');

  Logger.info('BEGIN AUDIO INPUT DEVICES ====');
  audioInputDevices.forEach((device) => Logger.info(device.toJSON()));
  Logger.info('END AUDIO INPUT DEVICES ====');

  Logger.info('BEGIN VIDEO INPUT DEVICES ====');
  videoInputDevices.forEach((device) => Logger.info(device.toJSON()));
  Logger.info('END VIDEO INPUT DEVICES ====');

  Logger.info('BEGIN AUDIO OUTPUT DEVICES ====');
  audioOutputDevices.forEach((device) => Logger.info(device.toJSON()));
  Logger.info('END AUDIO OUTPUT DEVICES ====');

  return { audioInputDevices, videoInputDevices, audioOutputDevices };
}

function queryPermissionStatus(name: PermissionName): Promise<PermissionStatus> {
  const permissionStatusGranted = Promise.resolve({ state: 'granted' } as PermissionStatus);

  return navigator.permissions
    ? navigator.permissions.query({ name: name as any }).catch(() => permissionStatusGranted)
    : permissionStatusGranted;
}

// function isPermissionDenied(name: Extract<PermissionName, 'camera' | 'microphone'>): Promise<boolean> {
//   return queryPermissionStatus(name).then(({ state }) => state === 'denied');
// }

function onAudioDeviceChange(localTracks: State['localTracks']): void {
  const audioTrack = localTracks?.find(({ kind }) => kind === 'audio') as LocalAudioTrack | undefined;
  if (audioTrack?.mediaStreamTrack.readyState === 'ended') {
    Logger.info('Audio device changed; restarting audio track');
    audioTrack?.restart({});
  }
}

class MeetingRoomContextProviderComponent extends React.PureComponent<ComponentProps, State> {
  protected onAudioDeviceChange = Utils.noop;

  public constructor(props: ComponentProps) {
    super(props);

    this.state = {
      batchQueryPromise: Utils.makeCancelablePromise(Promise.resolve({ serverDateTime: '' })),
      connectPromise: null,
      publishVideoTrackPromise: null,

      isConnecting: false,
      calendarEvent: null,
      room: null,
      localTracks: null,
      localParticipant: null,
      remoteParticipantList: null,
      dominantSpeaker: null,
      mainParticipant: null,
      isLocalAudioEnabled: false,
      isLocalVideoEnabled: false,

      connect: this.connect.bind(this),
      disconnect: this.disconnect.bind(this),
      toggleAudioEnabled: this.toggleAudioEnabled.bind(this),
      toggleVideoEnabled: this.toggleVideoEnabled.bind(this),

      findLocalAudioTrack: this.findLocalAudioTrack.bind(this),
      findLocalVideoTrack: this.findLocalVideoTrack.bind(this)
    };

    this.onLocalTrackPublished = this.onLocalTrackPublished.bind(this);
    this.onLocalTrackUnpublished = this.onLocalTrackUnpublished.bind(this);
    this.onDominantSpeakerChanged = this.onDominantSpeakerChanged.bind(this);
    this.onParticipantConnected = this.onParticipantConnected.bind(this);
    this.onParticipantDisconnected = this.onParticipantDisconnected.bind(this);
    this.onRoomDisconnected = this.onRoomDisconnected.bind(this);
  }

  public componentDidMount(): void {
    const { currentUserId: userId, basicProfile } = this.props;

    if (!AppUser.isValidId(userId)) return;

    if (Utils.isNil(basicProfile)) {
      this.dispatchBatchQueryData({
        userObjectsByType: [{ userId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC }]
      });
    } else {
      this.setState({ batchQueryPromise: null });
    }
  }

  public getSnapshotBeforeUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>): any {
    const { room, localTracks, localParticipant } = this.state;
    const { room: prevRoom, localTracks: prevLocalTracks, localParticipant: prevLocalParticipant } = prevState;

    if (room !== prevRoom) {
      Logger.info(`Room changed: current=${room?.name}, previous=${prevRoom?.name}`);

      if (prevRoom !== null) {
        Logger.info(
          '[previousRoom] Removing event listeners -',
          'dominantSpeakerChanged, participantConnected, participantDisconnected'
        );
        prevRoom.off('dominantSpeakerChanged', this.onDominantSpeakerChanged);
        prevRoom.off('participantConnected', this.onParticipantConnected);
        prevRoom.off('participantDisconnected', this.onParticipantDisconnected);

        Logger.info('Removing window event listener - beforeunload');
        window.removeEventListener('beforeunload', this.disconnect);
      }

      if (room !== null) {
        Logger.info('Adding window event listener - beforeunload');
        window.addEventListener('beforeunload', this.disconnect);

        Logger.info(
          '[currentRoom] Adding event listeners -',
          'dominantSpeakerChanged, participantConnected, participantDisconnected'
        );
        room.on('dominantSpeakerChanged', this.onDominantSpeakerChanged);
        room.on('participantConnected', this.onParticipantConnected);
        room.on('participantDisconnected', this.onParticipantDisconnected);

        Logger.info('Adding a `once` event listener for current room - disconnected');
        room.once('disconnected', () => {
          Logger.info(`Room disconnected: name=${room.name}`);

          // ==
          // reset the room only after other 'disconnected' listeners have been
          // called
          setTimeout(this.onRoomDisconnected);
          // ==

          Logger.info('Removing window event listener - beforeunload');
          window.removeEventListener('beforeunload', this.disconnect);
        });
      }

      const connected = room !== null && prevRoom === null;
      const disconnected = room === null && prevRoom !== null;
      if (connected) {
        const { onConnect } = this.props;
        if (typeof onConnect === 'function') {
          onConnect();
        }

        this.props.dispatchAddEventToDismissedList();
        this.props.dispatchToggleEventOnGoing();
      } else if (disconnected) {
        const { onDisconnect } = this.props;
        if (typeof onDisconnect === 'function') {
          onDisconnect();
        }

        this.props.dispatchToggleEventOnGoing();
      }
    }

    if (localTracks !== prevLocalTracks) {
      Logger.info(`Local tracks list changed`);

      if (prevLocalTracks !== null) {
        Logger.info('Removing previous devicechange event listener');
        navigator.mediaDevices.removeEventListener('devicechange', this.onAudioDeviceChange);

        const prevAudioTrack = prevLocalTracks.find(({ kind }) => kind === 'audio') as LocalAudioTrack | undefined;
        if (Utils.isNotNil(prevAudioTrack) && prevAudioTrack !== this.findLocalAudioTrack()) {
          Logger.info('Stopping audio track');
          prevAudioTrack.stop();
        }

        const prevVideoTrack = prevLocalTracks.find(({ kind }) => kind === 'video') as LocalVideoTrack | undefined;
        if (Utils.isNotNil(prevVideoTrack) && prevVideoTrack !== this.findLocalVideoTrack()) {
          Logger.info('Stopping video track');
          prevVideoTrack.stop();
        }
      }

      if (localTracks !== null) {
        Logger.info('BEGIN TRACK LIST ====');
        localTracks.forEach((track) => Logger.info(`kind=${track.kind}, name=${track.name}`));
        Logger.info('END TRACK LIST ====');

        Logger.info('Adding new devicechange event listener');
        this.onAudioDeviceChange = onAudioDeviceChange.bind(null, localTracks);
        navigator.mediaDevices.addEventListener('devicechange', this.onAudioDeviceChange);
      }
    }

    if (localParticipant !== prevLocalParticipant) {
      Logger.info(
        'Local participant changed:',
        `current=${localParticipant?.identity},`,
        `previous=${prevLocalParticipant?.identity}`
      );

      if (prevLocalParticipant !== null) {
        Logger.info('[previousLocalParticipant] Removing event listeners - trackPublished, trackUnpublished');
        prevLocalParticipant.off('trackPublished', this.onLocalTrackPublished);
        prevLocalParticipant.off('trackUnpublished', this.onLocalTrackUnpublished);
      }

      if (localParticipant !== null) {
        Logger.info('[currentParticipant] Adding event listeners - trackPublished, trackUnpublished');
        localParticipant.on('trackPublished', this.onLocalTrackPublished);
        localParticipant.on('trackUnpublished', this.onLocalTrackUnpublished);
      }
    }

    return null;
  }

  public componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>): any {
    const { remoteParticipantList } = this.state;
    const { remoteParticipantList: prevRemoteParticipantList } = prevState;

    if (remoteParticipantList !== prevRemoteParticipantList) {
      Logger.info('BEGIN REMOTE PARTICIPANT LIST ====');
      remoteParticipantList?.forEach((participant) =>
        Logger.info(`identity=${participant.identity}, sid=${participant.sid}`)
      );
      Logger.info('END REMOTE PARTICIPANT LIST ====');
    }
  }

  public componentWillUnmount(): void {
    const { batchQueryPromise, connectPromise, publishVideoTrackPromise } = this.state;

    batchQueryPromise?.cancel();
    connectPromise?.cancel();
    publishVideoTrackPromise?.cancel();
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    const { batchQueryPromise } = this.state;

    if (batchQueryPromise !== null) return null;

    const contextValue = Utils.omit(this.state, STATE_PROPS_TO_OMIT);
    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  }

  protected connect(): Promise<void> {
    Logger.info('`connect` was called');

    return new Promise<void>((resolve, reject) => {
      this.setState(({ connectPromise }, { eventObjectId }) => {
        if (connectPromise !== null) {
          Logger.warn('Call to `connect` was ignored; already connecting');
          resolve();
          return null;
        }

        connectPromise = Utils.makeCancelablePromise(this.initiateConnection(eventObjectId));
        connectPromise.promise
          .then((nextState) => {
            Logger.info('Connection succeeded');
            this.setState({ ...nextState, isConnecting: false, connectPromise: null }, resolve);
          })
          .catch((error) => {
            if (Utils.isNonArrayObjectLike<{ isCanceled: boolean }>(error) && error.isCanceled === true) return;

            Logger.info('Connection failed');
            this.setState({ isConnecting: false, connectPromise: null }, () => reject(error));
          });

        return { isConnecting: true, connectPromise };
      });
    });
  }

  protected async initiateConnection(eventObjectId: number): Promise<ReturnTypeInitiateConnection> {
    const { dispatchBatchQueryData, currentUserId, dispatchGetVideoToken } = this.props;
    const { room, localTracks } = this.state;

    let newRoom: Video.Room | null = null;
    try {
      if (
        room !== null ||
        localTracks !== null ||
        this.state.localParticipant !== null ||
        this.state.remoteParticipantList !== null
      ) {
        throw AlreadyConnectedError;
      }

      Logger.info(`Fetching calendar event: id=${eventObjectId}`);
      if (!DataObjectCalendarEvent.isValidId(eventObjectId)) {
        throw EventObjectFetchFailureError;
      }

      const { dataObjects, serverDateTime } = await dispatchBatchQueryData({
        dataObjects: { ids: [eventObjectId] }
      }).catch(() => ({
        dataObjects: [] as Array<ApiFormattedDataObject>,
        serverDateTime: new Date().toISOString()
      }));

      const calendarEventJson = dataObjects?.find(({ id }) => id === eventObjectId);
      if (Utils.isNil(calendarEventJson)) {
        throw EventObjectFetchFailureError;
      }

      const calendarEventObject = new DataObjectCalendarEvent(calendarEventJson);
      const calendarEvent = await calendarEventObject.decryptedValue();
      let dtServer: Date | undefined = undefined;
      if (Utils.isString(serverDateTime)) dtServer = new Date(serverDateTime);
      if (!Utils.isValidDate(dtServer)) dtServer = new Date();

      Logger.info('Checking if meeting can be started');
      const dtEventStart = calendarEventStartTime(calendarEvent);
      if (isBefore(dtServer.getTime(), subMilliseconds(dtEventStart, REMINDER_NOTIFICATION_REMIND_INTERVAL))) {
        throw MeetingNotStartedError;
      }

      Logger.info('Making sure meeting has not expired already');
      const dtEventEnd = calendarEventEndTime(calendarEvent);
      if (dtEventEnd < dtServer.getTime()) {
        throw ExpiredMeetingError;
      }

      Logger.info('Checking if current user can join this meeting room');
      const currentAttendee = calendarEvent.extendedProps.attendeeList.find(({ id }) => id === currentUserId);
      if (Utils.isNil(currentAttendee)) {
        Logger.info('Current user could not be found in attendee list');
        throw MeetingAccessTokenError;
      }

      Logger.info(`Getting access token: roomName=${calendarEvent.id}`);
      const token = await dispatchGetVideoToken(calendarEvent.id).catch(() => {
        throw MeetingAccessTokenError;
      });

      const { audioInputDevices, videoInputDevices } = await getMediaDevices();
      const audioTrack = await this.createLocalAudioTrack(audioInputDevices);

      const { isVideoMeeting, hasVideoConsent } = EventFlags(calendarEvent);
      let videoTrack: LocalVideoTrack | null = null;
      if (isVideoMeeting && hasVideoConsent(currentAttendee)) {
        videoTrack = await this.createLocalVideoTrack(videoInputDevices);
      }

      const tracks = [audioTrack, videoTrack].filter(Utils.isNotNil);
      if (tracks.length === 0) {
        throw MediaPermissionDeniedError;
      }

      const connectOptions: Video.ConnectOptions = { ...CONNECT_OPTIONS, tracks };
      Logger.info(
        `Initiating connection: options=${JSON.stringify(Utils.omit(connectOptions, 'tracks'), undefined, 2)}`
      );
      newRoom = await Video.connect(token, connectOptions);

      // newRoom.setMaxListeners(MAX_EVENT_ATTENDEE_LIST_COUNT + 2);

      const { localParticipant } = newRoom;
      // all video tracks are published with 'low' priority because the video
      // track that is displayed in the 'MainParticipant' component will have
      // it's priority set to 'high' via track.setPriority()
      Logger.info('Setting priority of all video tracks to `low`');
      localParticipant.videoTracks.forEach((publication) => publication.setPriority('low'));

      Logger.info('Getting remote participant list');
      const remoteParticipantList = Array.from(newRoom.participants.values());

      return {
        calendarEvent,
        room: newRoom,
        localTracks: tracks,
        mainParticipant: localParticipant,
        localParticipant,
        dominantSpeaker: null,
        remoteParticipantList,
        isLocalAudioEnabled: audioInputDevices.length === 0 ? null : audioTrack?.isEnabled === true,
        isLocalVideoEnabled: videoInputDevices.length === 0 ? null : Boolean(videoTrack)
      };
    } catch (error) {
      if (newRoom !== null) {
        Logger.warn('Call to `initiateConnection` failed with an error; disconnecting from room');
        newRoom.disconnect();
      }
      throw error;
    }
  }

  protected async createLocalAudioTrack(
    audioInputDevices?: ReadonlyArray<MediaDeviceInfo> | null | undefined
  ): Promise<Video.LocalAudioTrack | null> {
    let devices = audioInputDevices;
    if (!Utils.isArray(devices)) {
      devices = (await getMediaDevices()).audioInputDevices;
    }

    if (devices.length === 0) {
      Logger.warn('Call to `createLocalAudioTrack` failed; no audio input devices found');
      throw AudioInputDeviceMissingError;
    }

    const { state: permissionState } = await queryPermissionStatus('microphone');
    // prettier-ignore
    const promise = permissionState === 'denied'
      ? Promise.reject(MicrophonePermissionDeniedError)
      : Video.createLocalAudioTrack();

    return promise.catch((error) => {
      if (Utils.isNonArrayObjectLike<Error>(error)) {
        if (error === MicrophonePermissionDeniedError || error.name === 'NotAllowedError') {
          Logger.warn('Permission denied for microphone access');
          throw MicrophonePermissionDeniedError;
        } else if (error.name === 'NotFoundError') {
          throw AudioInputDeviceMissingError;
        }
      }

      throw error;
    });
  }

  protected async createLocalVideoTrack(
    videoInputDevices?: ReadonlyArray<MediaDeviceInfo> | null | undefined
  ): Promise<Video.LocalVideoTrack | null> {
    let devices = videoInputDevices;
    if (!Utils.isArray(devices)) {
      devices = (await getMediaDevices()).videoInputDevices;
    }

    if (devices.length === 0) {
      Logger.warn('Call to `createLocalVideoTrack` ignored; no video input devices found');
      // do NOT fail if no video input device is available
      return null;
    }

    const { state: permissionState } = await queryPermissionStatus('camera');
    // prettier-ignore
    const promise = permissionState === 'denied'
      ? Promise.reject(CameraPermissionDeniedError)
      : Video.createLocalVideoTrack(DEFAULT_VIDEO_CONSTRAINTS);

    return promise.catch((error) => {
      if (Utils.isNonArrayObjectLike<Error>(error)) {
        const isNotAllowedError = error === CameraPermissionDeniedError || error.name === 'NotAllowedError';
        const isNotFoundError = !isNotAllowedError && error.name === 'NotFoundError';

        if (isNotAllowedError || isNotFoundError) {
          if (isNotAllowedError) Logger.warn('Permission denied for camera access');
          // do NOT fail if either:
          // - the permission to access camera is denied
          // - or, no video input device is available
          return null;
        }
      }

      throw error;
    });
  }

  protected disconnect(): void {
    Logger.info('`disconnect` was called');

    this.setState(({ connectPromise, room }) => {
      if (connectPromise !== null) {
        Logger.info('Connection is in progress; aborting connection');
        connectPromise.cancel();
        return { isConnecting: false, connectPromise: null };
      }

      Logger.info('Calling disconnect on current room');
      room?.disconnect();
      return null;
    });
  }

  protected onRoomDisconnected(): void {
    Logger.info('`onRoomDisconnected` was called');

    this.setState({
      calendarEvent: null,
      room: null,
      localTracks: null,
      mainParticipant: null,
      localParticipant: null,
      remoteParticipantList: null,
      dominantSpeaker: null,
      isLocalAudioEnabled: false,
      isLocalVideoEnabled: false
    });
  }

  protected toggleAudioEnabled(): void {
    Logger.info('`toggleAudioEnabled` was called');

    const audioTrack = this.findLocalAudioTrack();
    if (!Utils.isUndefined(audioTrack)) {
      const { isEnabled } = audioTrack;

      Logger.info(`Local audio track was found: current isEnabled=${isEnabled}`);
      if (isEnabled) audioTrack.disable();
      else audioTrack.enable();

      this.setState({ isLocalAudioEnabled: !isEnabled });
    } else {
      Logger.warn('No local audio track was found');
      this.setState({ isLocalAudioEnabled: false });
    }
  }

  protected toggleVideoEnabled(): void {
    Logger.info('`toggleVideoEnabled` was called');

    this.setState(({ room, publishVideoTrackPromise }) => {
      if (room === null || publishVideoTrackPromise !== null) {
        if (room === null) Logger.warn('Call ignored; room === null');
        else Logger.warn('Call ignored; video track publish is already in progress');
        return null;
      }

      const { localParticipant } = room;
      const videoTrack = this.findLocalVideoTrack();

      if (Utils.isUndefined(videoTrack)) {
        Logger.info('No existing video track was found; creating one');

        publishVideoTrackPromise = Utils.makeCancelablePromise(
          getMediaDevices().then(({ videoInputDevices }) =>
            this.createLocalVideoTrack(videoInputDevices).then((track) => [track, videoInputDevices])
          )
        );

        publishVideoTrackPromise.promise
          .then(([track, videoInputDevices]) => {
            let isLocalVideoEnabled = videoInputDevices.length === 0 ? null : false;
            if (Utils.isNotNil(track)) {
              isLocalVideoEnabled = true;
              localParticipant.publishTrack(track, { priority: 'low' });
            }

            this.setState({ publishVideoTrackPromise: null, isLocalVideoEnabled });
          })
          .catch((error) => {
            if (publishVideoTrackPromise!.hasCanceled) return;

            Logger.warn('Failed to publish local video track');
            this.setState({ publishVideoTrackPromise: null, isLocalVideoEnabled: false });
            throw error;
          });
      } else {
        Logger.info('Existing video track was found; stopping and unpublishing it');

        const publication = localParticipant.unpublishTrack(videoTrack);
        // TODO: remove when Twilio Video SDK implements this event
        // NOTE: statement is wrapped inside setTimeout so that we don't end up
        // calling setState from within this setState call
        setTimeout(() => localParticipant.emit('trackUnpublished', publication));
        // videoTrack.stop();
      }

      return { publishVideoTrackPromise, isLocalVideoEnabled: false };
    });
  }

  protected findLocalAudioTrack(): LocalAudioTrack | undefined {
    const { localTracks } = this.state;
    return localTracks?.find(({ kind }) => kind === 'audio') as LocalAudioTrack | undefined;
  }

  protected findLocalVideoTrack(): LocalVideoTrack | undefined {
    const { localTracks } = this.state;
    return localTracks?.find(({ kind, name }) => kind === 'video' && !name.includes('screen')) as
      | LocalVideoTrack
      | undefined;
  }

  protected onLocalTrackPublished({ track: publishedTrack }: LocalTrackPublication): void {
    Logger.info(`Local track published: kind=${publishedTrack.kind}, name=${publishedTrack.name}`);

    this.setState(({ localTracks }) => {
      return Utils.isArray(localTracks)
        ? { localTracks: localTracks.concat(publishedTrack as NonNullable<typeof localTracks[0]>) }
        : null;
    });
  }

  protected onLocalTrackUnpublished({ track: unpublishedTrack }: LocalTrackPublication): void {
    Logger.info(`Local track unpublished: kind=${unpublishedTrack.kind}, name=${unpublishedTrack.name}`);

    this.setState(({ localTracks }) => {
      return Utils.isArray(localTracks)
        ? { localTracks: localTracks.filter((track) => track !== unpublishedTrack) }
        : null;
    });
  }

  protected onDominantSpeakerChanged(dominantSpeaker?: RemoteParticipant | null | undefined): void {
    Logger.info(`Dominant speaker changed: identity=${dominantSpeaker?.identity}, sid=${dominantSpeaker?.sid}`);

    // sometimes, the 'dominantSpeakerChanged' event can emit 'null', which
    // means that there is no dominant speaker. If we change the main
    // participant when 'null' is emitted, the effect can be jarring to the
    // user. Here we ignore any 'null' values and continue to display the
    // previous dominant speaker as the main participant.
    if (Utils.isNil(dominantSpeaker)) return;

    this.setState(({ remoteParticipantList: prevRemoteParticipantList }) => {
      let remoteParticipantList = prevRemoteParticipantList;
      if (Utils.isArray<RemoteParticipant>(prevRemoteParticipantList)) {
        remoteParticipantList = [
          dominantSpeaker,
          ...prevRemoteParticipantList.filter((remoteParticipant) => remoteParticipant !== dominantSpeaker)
        ];
      }

      return { dominantSpeaker, mainParticipant: dominantSpeaker, remoteParticipantList };
    });
  }

  protected onParticipantConnected(participant?: RemoteParticipant | null | undefined): void {
    Logger.info(`Remote participant connected: identity=${participant?.identity}, sid=${participant?.sid}`);
    if (Utils.isNil(participant)) return;

    this.setState(
      ({
        remoteParticipantList: prevRemoteParticipantList,
        mainParticipant: prevMainParticipant,
        localParticipant,
        dominantSpeaker
      }) => {
        const remoteParticipantList = (prevRemoteParticipantList || []).concat(participant);

        let mainParticipant: State['mainParticipant'] = localParticipant;
        for (const newMainParticipant of [prevMainParticipant, dominantSpeaker, remoteParticipantList[0]]) {
          if (Utils.isNotNil(newMainParticipant)) {
            mainParticipant = newMainParticipant;
            break;
          }
        }

        return { mainParticipant, remoteParticipantList };
      }
    );
  }

  protected onParticipantDisconnected(participant?: RemoteParticipant | null | undefined): void {
    Logger.info(`Remote participant disconnected: identity=${participant?.identity}, sid=${participant?.sid}`);
    if (Utils.isNil(participant)) return;

    this.setState(
      ({
        remoteParticipantList: prevRemoteParticipantList,
        dominantSpeaker: prevDominantSpeaker,
        mainParticipant: prevMainParticipant,
        localParticipant
      }) => {
        let remoteParticipantList = prevRemoteParticipantList as Array<RemoteParticipant> | null;
        if (Utils.isNonEmptyArray<RemoteParticipant>(remoteParticipantList)) {
          const index = remoteParticipantList.indexOf(participant);
          if (index !== -1) {
            remoteParticipantList = remoteParticipantList.slice();
            remoteParticipantList.splice(index, 1);
          }
        }

        const dominantSpeaker = prevDominantSpeaker === participant ? null : prevDominantSpeaker;

        let mainParticipant: State['mainParticipant'] = localParticipant;
        for (const newMainParticipant of [prevMainParticipant, dominantSpeaker, remoteParticipantList?.[0]]) {
          if (Utils.isNotNil(newMainParticipant) && newMainParticipant !== participant) {
            mainParticipant = newMainParticipant;
            break;
          }
        }

        // prettier-ignore
        Logger.info(`Previous dominant speaker: identity=${prevDominantSpeaker?.identity}, sid=${prevDominantSpeaker?.sid}`);
        Logger.info(`Current dominant speaker: identity=${dominantSpeaker?.identity}, sid=${dominantSpeaker?.sid}`);

        // prettier-ignore
        Logger.info(`Previous main participant: identity=${prevMainParticipant?.identity}, sid=${prevMainParticipant?.sid}`);
        Logger.info(`Current main participant: identity=${mainParticipant?.identity}, sid=${mainParticipant?.sid}`);

        Logger.info(`Local participant: identity=${localParticipant?.identity}`);
        return { remoteParticipantList, dominantSpeaker, mainParticipant };
      }
    );
  }

  protected dispatchBatchQueryData(query: Parameters<ConnectedProps['dispatchBatchQueryData']>[0]): void {
    this.setState(({ batchQueryPromise }, { dispatchBatchQueryData }) => {
      batchQueryPromise?.cancel();

      batchQueryPromise = Utils.makeCancelablePromise(dispatchBatchQueryData(query));
      batchQueryPromise.promise.then(() => this.setState({ batchQueryPromise: null })).catch(Utils.noop /* ignore */);

      return { batchQueryPromise };
    });
  }
}

export const MeetingRoomContextProvider = withConnect<React.ComponentType<ComponentProps>>(
  MeetingRoomContextProviderComponent
);

MeetingRoomContextProvider.displayName = 'MeetingRoomContextProvider';
