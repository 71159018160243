import { getLoggerWithPrefix } from '@sigmail/logging';
import { CaseReducerSetDataObjectsState, StoreStateDataObjects } from '@sigmail/app-state';

const Logger = getLoggerWithPrefix('Reducer', 'setDataObjectsStateReducer:');

export const setStateReducer: CaseReducerSetDataObjectsState = (
  state,
  { payload: nextState }
): StoreStateDataObjects => {
  try {
    Logger.info('== BEGIN ==');
    // @ts-ignore TS2322
    return state.equals(nextState) ? state : nextState;
  } finally {
    Logger.info('== END ==');
  }
};
