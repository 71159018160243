import { MessagingI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import dialogConfirmEventRecall from '../scheduling/dialog-confirm-event-recall';
import dialogAcceptReferral from './dialog-accept-referral';
import dialogAssignMessage from './dialog-assign-message';
import dialogConfirmCancelHrmMessage from './dialog-confirm-cancel-hrm-message';
import dialogConfirmCloseUnsentMessage from './dialog-confirm-close-unsent-message';
import dialogConfirmDeleteMessage from './dialog-confirm-delete-message';
import dialogConfirmDiscardDraft from './dialog-confirm-discard-draft';
import dialogConfirmRecallMessage from './dialog-confirm-recall-message';
import dialogConfirmSendToEMR from './dialog-confirm-send-message-to-emr';
import dialogConsultationRequestSelectPatientRecord from './dialog-consultation-request-select-patient-record';
import dialogDeclineReferral from './dialog-decline-referral';
import dialogDocumentPreview from './dialog-document-preview';
import dialogEMRNoMatchingPatientRecord from './dialog-emr-no-matching-patient-record';
import dialogEMROAuthParamsMissing from './dialog-emr-oauth-params-missing';
import dialogEMRProviderIdMissing from './dialog-emr-provider-id-missing';
import dialogEMRTokenExpired from './dialog-emr-token-expired';
import dialogEMRUploadProgress from './dialog-emr-upload-progress';
import oneTimeMessageAccessCodeDialog from './dialog-one-time-message-access-code';
import formAssignMessage from './form-assign-message';
import composeConsultationRequestForm from './form-compose-consultation-request';
import composeMessageForm from './form-compose-message';
import composeReferralForm from './form-compose-referral';
import gridFilterForm from './form-grid-filter';
import oneTimeMessageAccessCodeForm from './form-one-time-message-access-code';
import oneTimeMessageVerifyPhoneNumberForm from './form-one-time-message-verify-phone-number';
import htmlEditorToolbarAction from './html-editor-toolbar-action';
import messageFolderGridLocaleText from './message-folder-grid-locale-text';
import messageToolbarAction from './message-toolbar-action';
import navAction from './nav-action';
import composeConsultationRequestView from './view-compose-consultation-request';
import composeHrmMessageView from './view-compose-hrm-message';
import composeMessageView from './view-compose-message';
import composeReferralView from './view-compose-referral';
import healthDataRequestMessageView from './view-health-data-request-message';
import joinGroupInvitationMessageView from './view-join-group-invitation-message';
import messageMetadataView from './view-message-metadata';
import oneTimeMessageVerifyAccessCodeView from './view-one-time-message-verify-access-code';
import oneTimeMessageVerifyPhoneNumberView from './view-one-time-message-verify-phone-number';
import referralMessageView from './view-referral-message';

const i18n: MessagingI18n.Root = {
  navAction,
  messageToolbarAction,
  messageFolderGridLocaleText,
  htmlEditorToolbarAction,
  view: {
    composeConsultationRequest: composeConsultationRequestView,
    composeHrmMessage: composeHrmMessageView,
    composeMessage: composeMessageView,
    composeReferral: composeReferralView,
    healthDataRequestMessage: healthDataRequestMessageView,
    hrmMessage: {
      action: {
        cancel: {
          // Translators: Label for the Cancel action in HRM message view
          label: gettext('messaging', 'cancel', 'hrmMessageViewActionLabel')
        },
        correct: {
          // Translators: Label for the Correct action in HRM message view
          label: gettext('messaging', 'correct', 'hrmMessageViewActionLabel')
        }
      }
    },
    joinGroupInvitation: joinGroupInvitationMessageView,
    messageMetadata: messageMetadataView,
    oneTimeMessage: {
      expireLabel: {
        D: ngettext('messaging', 'day', 'oneTimeMessageViewExpireLabel'),
        M: ngettext('messaging', 'month', 'oneTimeMessageViewExpireLabel'),
        W: ngettext('messaging', 'week', 'oneTimeMessageViewExpireLabel'),
        Y: ngettext('messaging', 'year', 'oneTimeMessageViewExpireLabel')
      },
      headerInfo: gettext('messaging', 'headerInfo', 'oneTimeMessageView'),
      messageViewError: {
        body: gettext('messaging', 'body', 'oneTimeMessageViewError'),
        title: gettext('messaging', 'title', 'oneTimeMessageViewError')
      },
      verifyAccessCode: oneTimeMessageVerifyAccessCodeView,
      verifyPhoneNumber: oneTimeMessageVerifyPhoneNumberView
    },
    referralMessage: referralMessageView
  },
  dialog: {
    assignMessage: dialogAssignMessage,
    confirmation: {
      closeUnsentMessage: dialogConfirmCloseUnsentMessage,
      deleteMessage: dialogConfirmDeleteMessage,
      discardDraft: dialogConfirmDiscardDraft,
      hrmCancelMessage: dialogConfirmCancelHrmMessage,
      recallEvent: {
        title: dialogConfirmEventRecall.title,
        body: dialogConfirmEventRecall.body,
        note: dialogConfirmEventRecall.noteEventMayNotRecall,
        action: dialogConfirmEventRecall.action
      },
      recallMessage: dialogConfirmRecallMessage,
      sendMessageToEMR: dialogConfirmSendToEMR
    },
    EMR: {
      noMatchingPatientRecord: dialogEMRNoMatchingPatientRecord,
      OAuthParamsMissing: dialogEMROAuthParamsMissing,
      providerIdMissing: dialogEMRProviderIdMissing,
      tokenExpired: dialogEMRTokenExpired,
      uploadProgress: dialogEMRUploadProgress
    },
    consultationRequest: {
      selectPatientRecord: dialogConsultationRequestSelectPatientRecord
    },
    oneTimeMessage: {
      accessCode: oneTimeMessageAccessCodeDialog
    },
    referral: {
      accept: dialogAcceptReferral,
      decline: dialogDeclineReferral
    },
    documentPreview: dialogDocumentPreview
  },
  form: {
    assignMessage: formAssignMessage,
    composeConsultationRequest: composeConsultationRequestForm,
    composeMessage: composeMessageForm,
    composeReferral: composeReferralForm,
    gridFilter: gridFilterForm,
    oneTimeMessage: {
      accessCode: oneTimeMessageAccessCodeForm,
      verifyPhoneNumber: oneTimeMessageVerifyPhoneNumberForm
    }
  },
  messageBody: {
    // Translators: (HTML) Message body displayed for an E-Consult message
    // Translators: Token(s) available: \
    // {{REFERRER_INSTITUTE_NAME}}, \
    // {{REFERRER_NAME}}, \
    // {{REFERRER_ADDRESS}}, \
    // {{REFERRER_BILLING_CODE}}, \
    // {{CONSULTANT_NAME}}, \
    // {{CONSULTANT_ADDRESS}}, \
    // {{PATIENT_NAME_FIRST}}, \
    // {{PATIENT_NAME_LAST}}, \
    // {{PATIENT_BIRTH_DATE}}, \
    // {{PATIENT_GENDER}}, \
    // {{PATIENT_HEALTH_PLAN_NUMBER}}, \
    // {{PATIENT_HEALTH_PLAN_JURISDICTION}}, \
    // {{CONSULTATION_REASON}}, \
    // {{MESSAGE}}
    consultationRequest: gettext('messaging', 'consultationRequest', 'messageBody', 'safe-html'),
    eventAttendance: {
      attendeeType: {
        // Translators: Label for the Event Attendee Type - Caregiver
        caregiver: gettext('messaging', 'caregiver', 'eventAttendeeType'),
        // Translators: Label for the Event Attendee Type - Organizer
        organizer: gettext('messaging', 'organizer', 'eventAttendeeType'),
        // Translators: Label for the Event Attendee Type - Patient
        patient: gettext('messaging', 'patient', 'eventAttendeeType'),
        // Translators: Label for the Event Attendee Type - Physician
        physician: gettext('messaging', 'physician', 'eventAttendeeType'),
        // Translators: Label for the Event Attendee Type - Staff
        staff: gettext('messaging', 'staff', 'eventAttendeeType')
      },
      eventType: {
        // Translators: Label for the Calendar Event Type - Clinical Direct
        clinicalDirect: gettext('messaging', 'clinicalDirect', 'calendarEventType'),
        // Translators: Label for the Calendar Event Type - Clinical Indirect
        clinicalIndirect: gettext('messaging', 'clinicalIndirect', 'calendarEventType')
      },
      // Translators: (HTML) Message body displayed for an Event Attendance message
      // Translators: Token(s) available: \
      // {{EVENT_TYPE}} \
      // {{MODALITY}} \
      // {{START_DATE_TIME}} \
      // {{END_DATE_TIME}} \
      // {{DURATION}} \
      // {{ATTENDEE_LIST}} \
      // {{GEO_LOCATION_LIST}}
      html: gettext('messaging', 'eventAttendance', 'messageBody', 'safe-html'),
      location: {
        // Translators: Label for the Geo Location Data - City
        city: gettext('messaging', 'city', 'geoLocationDataLabel'),
        // Translators: Label for the Geo Location Data - Country
        country: gettext('messaging', 'country', 'geoLocationDataLabel'),
        // Translators: Label for the Geo Location Data - IP Address
        ip: gettext('messaging', 'ip', 'geoLocationDataLabel'),
        // Translators: Label for the Geo Location Data - Postal Code
        postal: gettext('messaging', 'postal', 'geoLocationDataLabel'),
        // Translators: Label for the Geo Location Data - Region/Province
        region: gettext('messaging', 'region', 'geoLocationDataLabel')
      },
      modality: {
        // Translators: Label for the Event Modality - Audio
        audio: gettext('messaging', 'audio', 'eventModality'),
        // Translators: Label for the Event Modality - Video
        video: gettext('messaging', 'video', 'eventModality')
      }
    },
    // Translators: (HTML) Message body displayed to the receiver of a Join \
    // Group Invitation message
    // Translators: Token(s) available: {{FULL_NAME}}, {{EXPIRY_NOTE}}
    joinGroupInvitation: gettext('messaging', 'joinGroupInvitation', 'messageBody', 'safe-html'),
    // Translators: (HTML) Note embedded in the receiver's Join Group \
    // Invitation message body if the invitation has an expiry date set
    // Translators: Token(s) available: {{DATE_TIME}}
    joinGroupInvitationExpiryNote: gettext('messaging', 'joinGroupInvitationExpiryNote', 'messageBody', 'safe-html'),
    // Translators: (HTML) Note embedded in the receiver's Join Group \
    // Invitation message body if the invitation has already expired
    // Translators: Token(s) available: {{DATE_TIME}}
    joinGroupInvitationAlreadyExpiredNote: gettext('messaging', 'joinGroupInvitationAlreadyExpiredNote', 'messageBody', 'safe-html'),
    // Translators: (HTML) Text prepended to the message body when the reply \
    // being composed is a phone consultation response
    phoneConsultationResponse: gettext('messaging', 'phoneConsultationResponse', 'messageBody', 'safe-html'),
    // Translators: (HTML) Message body displayed to the sender of a Join \
    // Group Invitation message
    // Translators: Token(s) available: {{FULL_NAME}}, {{EXPIRY_NOTE}}
    sentJoinGroupInvitation: gettext('messaging', 'sentJoinGroupInvitation', 'messageBody', 'safe-html'),
    // Translators: (HTML) Note embedded in the sender's Join Group Invitation \
    // message body if the invitation has an expiry date set
    // Translators: Token(s) available: {{DATE_TIME}}
    sentJoinGroupInvitationExpiryNote: gettext('messaging', 'sentJoinGroupInvitationExpiryNote', 'messageBody', 'safe-html')
  },
  notification: {
    body: {
      newMessage: gettext('messaging', 'body', 'notificationNewMessage')
    },
    title: {
      newMessage: gettext('messaging', 'title', 'notificationNewMessage')
    }
  },
  errorMessage: {
    // Translators: Error message displayed when recall message window is \
    // already closed
    // Translators: Token(s) available: {{count}}
    recallWindowClosed: ngettext('messaging', 'recallWindowClosed', 'errorMessage'),
    // Translators: Error message displayed when user tries to attach more \
    // than the maximum allowed number of documents
    // Translators: Token(s) available: {{count}}
    maxAttachmentLimitExceeded: ngettext('messaging', 'maxAttachmentLimitExceeded', 'errorMessage'),
    // Translators: Error message displayed when size of the document being \
    // attached is more than the allowed per-file size limit
    // Translators: {{MAX}}
    attachmentSizeLimitExceeded: gettext('messaging', 'attachmentSizeLimitExceeded', 'errorMessage'),
    // Translators: Error message displayed when combined size of all attached \
    // documents is more than the allowed limit
    // Translators: {{MAX}}
    attachmentTotalSizeLimitExceeded: gettext('messaging', 'attachmentTotalSizeLimitExceeded', 'errorMessage'),
    // Translators: Error message displayed when user tries to attach an \
    // unsupported file type
    unsupportedFileType: gettext('messaging', 'unsupportedFileType', 'errorMessage')
  },
  successMessage: {
    // Translators: Success message displayed when HRM form submission was \
    // successful
    hrmSubmit: gettext('messaging', 'hrmSubmit', 'successMessage'),
    // Translators: Success message displayed when HRM message correction was \
    // successful
    hrmCorrect: gettext('messaging', 'hrmCorrect', 'successMessage'),
    // Translators: Success message displayed when HRM message was cancelled \
    // successfully
    hrmCancel: gettext('messaging', 'hrmCancel', 'successMessage')
  },
  // Translators: Label for the Date Range of Message Count widget
  messageCountWidgetRangeLabel: gettext('messaging', 'messageCountWidget', 'rangeLabel')
};

export default i18n;
