import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GlobalI18n['form']['searchPatientList'] = {
  fieldsetSearch: {
    formField: {
      search: {
        error: {
          // Translators: Message displayed in Search Patient List form when \
          // no search results are found.
          noResults: gettext('global', 'noResults', 'searchPatientListFormError')
        },
        iconLabel: {
          // Translators: Label for the Clear Search icon on Search Patient \
          // List form.
          clear: gettext('global', 'clearSearch', 'searchPatientListFormInputIconLabel'),
          // Translators: Label for the Start Search icon on Search Patient \
          // List form.
          search: gettext('global', 'startSearch', 'searchPatientListFormInputIconLabel')
        },
        // Translators: Label for the Search Health Plan Number input on \
        // Search Patient List form.
        label: gettext('global', 'search', 'searchPatientListFormInputLabel'),
        // Translators: Hint for the Search Health Plan Number input on \
        // Search Patient List form.
        placeholder: gettext('global', 'search', 'searchPatientListFormInputHint')
      }
    }
  }
};

export default i18n;
