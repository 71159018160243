import { Nullable, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';

type AccuroEMRHealthCardRecord = Pick<NonNullable<Api.AccuroEMRPatientRecord['demographics']>, 'healthCard'>;

export const selectHealthPlanNumber = (record: Nullable<AccuroEMRHealthCardRecord>): string | null =>
  Utils.isNonArrayObjectLike<AccuroEMRHealthCardRecord>(record) &&
  Utils.isNonArrayObjectLike<Pick<NonNullable<AccuroEMRHealthCardRecord['healthCard']>, 'phn'>>(record.healthCard) &&
  Utils.isString(record.healthCard.phn)
    ? record.healthCard?.phn
    : null;
