export const ROUTE_APP: string = 'routeApp';
export const ROUTE_APP_ROOT: string = 'routeApp/root';
export const ROUTE_SIGN_IN: string = 'routeApp/signIn';
export const ROUTE_CHANGE_USERNAME = 'routeApp/changeUsername';
export const ROUTE_RESET_PASSWORD = 'routeApp/resetPassword';
export const ROUTE_CONTACT_US: string = 'routeApp/contactUs';
export const ROUTE_FAQ: string = 'routeApp/faq';
export const ROUTE_PRIVACY_POLICY: string = 'routeApp/privacyPolicy';
export const ROUTE_TERMS_AND_CONDITIONS: string = 'routeApp/termsAndConditions';

export const ROUTE_ACCOUNT: string = 'routeAccount';
export const ROUTE_ACCOUNT_ROOT: string = 'routeAccount/root';
export const ROUTE_ACCOUNT_BULK_INVITE_GUESTS: string = 'routeAccount/bulkInviteGuests';
export const ROUTE_ACCOUNT_MANAGE_CAREGIVERS: string = 'routeAccount/caregivers';
export const ROUTE_ACCOUNT_MANAGE_CARE_PLANS: string = 'routeAccount/careplans';
export const ROUTE_ACCOUNT_MANAGE_GROUPS: string = 'routeAccount/groups';
export const ROUTE_ACCOUNT_MANAGE_MEMBERS: string = 'routeAccount/members';
export const ROUTE_ACCOUNT_MANAGE_SELF: string = 'routeAccount/myAccount';
export const ROUTE_ACCOUNT_CAREGIVER_SETUP: string = 'routeAccount/caregiverSetup';
export const ROUTE_ACCOUNT_SETUP: string = 'routeAccount/setup';

export const ROUTE_MAIL: string = 'routeMail';
export const ROUTE_MAIL_ROOT: string = 'routeMail/root';
export const ROUTE_MAIL_ARCHIVED: string = 'routeMail/archived';
export const ROUTE_MAIL_ARCHIVED_SHARED: string = 'routeMail/sharedArchived';
export const ROUTE_MAIL_COMPOSE_CONSULTATION_REQUEST: string = 'routeMail/composeConsultationRequest';
export const ROUTE_MAIL_COMPOSE_HRM_MESSAGE: string = 'routeMail/composeHrmMessage';
export const ROUTE_MAIL_COMPOSE_MESSAGE: string = 'routeMail/composeMessage';
export const ROUTE_MAIL_COMPOSE_REFERRAL: string = 'routeMail/composeReferral';
export const ROUTE_MAIL_CONSULTATION: string = 'routeMail/consultation';
export const ROUTE_MAIL_DRAFTS: string = 'routeMail/drafts';
export const ROUTE_MAIL_IMPORTANT: string = 'routeMail/important';
export const ROUTE_MAIL_IMPORTANT_SHARED: string = 'routeMail/sharedImportant';
export const ROUTE_MAIL_INBOX: string = 'routeMail/inbox';
export const ROUTE_MAIL_INBOX_SHARED: string = 'routeMail/sharedInbox';
export const ROUTE_MAIL_REMINDER: string = 'routeMail/reminder';
export const ROUTE_MAIL_REFERRALS: string = 'routeMail/referrals';
export const ROUTE_MAIL_REFERRALS_SHARED: string = 'routeMail/sharedReferrals';
export const ROUTE_MAIL_SECURE: string = 'routeMail/secure';
export const ROUTE_MAIL_SENT: string = 'routeMail/sent';
export const ROUTE_MAIL_TRASH: string = 'routeMail/trash';
export const ROUTE_MAIL_TRASH_SHARED: string = 'routeMail/sharedTrash';
export const ROUTE_MAIL_USER_DEFINED_FOLDER: string = 'routeMail/userDefinedFolder';

export const ROUTE_SCHEDULE: string = 'routeSchedule';
export const ROUTE_SCHEDULE_ROOT: string = 'routeSchedule/root';
export const ROUTE_SCHEDULE_GROUP: string = 'routeSchedule/group';
export const ROUTE_SCHEDULE_OWN: string = 'routeSchedule/own';

export const ROUTE_CONTACT_LIST: string = 'routeContactList';
export const ROUTE_CONTACT_LIST_ROOT: string = 'routeContactList/root';
export const ROUTE_CONTACT_LIST_CLIENT: string = 'routeContactList/client';
export const ROUTE_CONTACT_LIST_GLOBAL_GROUPS = 'routeContactList/globalGroups';
export const ROUTE_CONTACT_LIST_GLOBAL_USERS = 'routeContactList/globalUsers';
export const ROUTE_CONTACT_LIST_GUEST: string = 'routeContactList/guest';

export const ROUTE_DOCUMENT_LIST = 'routeDocumentList';
