import { AppException } from './app-exception';
import { E_NOT_IMPLEMENTED } from './constants/error';

/**
 * The exception that is thrown when a requested method or operation is not
 * implemented.
 *
 * @public
 */
export class NotImplementedException extends AppException {
  public constructor() {
    super(E_NOT_IMPLEMENTED);

    this.name = 'NotImplementedException';
  }
}
