import {
  Constants,
  EventMessageStatus,
  MessageFormName,
  MessageSensitivity,
  PartialRecord,
  ReferralMessageStatus,
  Utils,
  Writeable
} from '@sigmail/common';
import { MessagingI18n } from '@sigmail/i18n';
import { DataObjectMsgFolder, DataObjectMsgFolderValue, MessageFolderListItem } from '@sigmail/objects';
import { USER_DEFINED_MESSAGE_FOLDER_PREFIX } from '../../../app-state/actions/constants';
import { DataObjectCache } from '../../../app-state/data-objects-slice/cache';
import { useDataObjectByIdSelector } from '../../../app-state/hooks';
import { selectAccessRight } from '../../../app-state/selectors/auth';
import * as ActionId from '../../../constants/action-ids';

const EVENT_STATUS_ACCEPTED: Extract<EventMessageStatus, 'accepted'> = 'accepted';
const EVENT_STATUS_DECLINED: Extract<EventMessageStatus, 'declined'> = 'declined';

const REFERRAL_STATUS_ACCEPTED: Extract<ReferralMessageStatus, 'accepted'> = 'accepted';
const REFERRAL_STATUS_DECLINED: Extract<ReferralMessageStatus, 'declined'> = 'declined';

export type MessageFlagsKey =
  | `${'assigned' | 'forwarded' | 'repliedTo' | `sentTo${'Emr' | 'Hrm'}`}At`
  | `has${'Attachments' | 'Reminder'}`
  | `is${
      | 'Accepted'
      | 'Assigned'
      | 'Billable'
      | 'Billed'
      | 'Canceled'
      | 'Corrected'
      | 'Declined'
      | 'Forwarded'
      | 'Important'
      | 'MarkedAsRead'
      | `Message${`Form${Capitalize<Exclude<MessageFormName, 'hrm'>>}` | 'Sensitive'}`
      | 'Recalled'
      | 'RepliedTo'
      | `Sensitivity${Capitalize<MessageSensitivity>}`
      | `SentTo${'EMR' | 'HRM'}`}`
  | 'isMessageFormHRM';

export type MessageFlagsResult = Readonly<
  Record<Extract<MessageFlagsKey, `${string}At`>, number> & Record<Exclude<MessageFlagsKey, `${string}At`>, boolean>
>;

export function MessageFlags(msg: any): MessageFlagsResult {
  const result: Writeable<MessageFlagsResult> = {
    assignedAt: undefined!,
    forwardedAt: undefined!,
    hasAttachments: false,
    hasReminder: false,
    isAccepted: false,
    isAssigned: false,
    isBillable: false,
    isBilled: false,
    isCanceled: false,
    isCorrected: false,
    isDeclined: false,
    isForwarded: false,
    isImportant: false,
    isMarkedAsRead: false,
    isRecalled: false,
    isRepliedTo: false,
    isMessageFormDefault: false,
    isMessageFormEConsult: false,
    isMessageFormEncounter: false,
    isMessageFormEvent: false,
    isMessageFormEventAttendance: false,
    isMessageFormHealthDataRequest: false,
    isMessageFormHRM: false,
    isMessageFormJoinGroupInvitation: false,
    isMessageFormReferral: false,
    isMessageSensitive: false,
    isSensitivityConfidential: false,
    isSensitivityNormal: false,
    isSensitivityPersonal: false,
    isSensitivityPrivate: false,
    isSentToEMR: false,
    isSentToHRM: false,
    repliedToAt: undefined!,
    sentToEmrAt: undefined!,
    sentToHrmAt: undefined!
  };

  if (Utils.isNonArrayObjectLike<MessageFolderListItem>(msg)) {
    let { messageForm, documentList, flags: msgFlags, sensitivity, timestamp, extraData } = msg;

    if (!Utils.isNonArrayObjectLike<typeof msgFlags>(msgFlags)) {
      msgFlags = {};
    }

    if (Utils.isDefaultMessageForm(messageForm)) {
      result.isMessageFormDefault = true;
    } else {
      const { name: msgFormName } = messageForm;
      if (Utils.isMessageFormNameEConsult(msgFormName)) {
        result.isMessageFormEConsult = true;
      } else if (Utils.isMessageFormNameEncounter(msgFormName)) {
        result.isMessageFormEncounter = true;
      } else if (Utils.isMessageFormNameEvent(msgFormName)) {
        result.isMessageFormEvent = true;
      } else if (Utils.isMessageFormNameEventAttendance(msgFormName)) {
        result.isMessageFormEventAttendance = true;
      } else if (Utils.isMessageFormNameHealthDataRequest(msgFormName)) {
        result.isMessageFormHealthDataRequest = true;
      } else if (Utils.isMessageFormNameHRM(msgFormName)) {
        result.isMessageFormHRM = true;
      } else if (Utils.isMessageFormNameJoinGroupInvitation(msgFormName)) {
        result.isMessageFormJoinGroupInvitation = true;
      } else if (Utils.isMessageFormNameReferral(msgFormName)) {
        result.isMessageFormReferral = true;
      }
    }

    if (Utils.isNonArrayObjectLike<Exclude<typeof timestamp, number>>(timestamp)) {
      result.isAssigned = Utils.isInteger(timestamp.assignedAt);
      result.isForwarded = Utils.isInteger(timestamp.forwardedAt);
      result.isRecalled = Utils.isInteger(timestamp.recalledAt);
      result.isRepliedTo = Utils.isInteger(timestamp.repliedToAt);
      result.isSentToEMR = Utils.isInteger(timestamp.sentToEMRAt);
      result.isSentToHRM = Utils.isInteger(timestamp.sentToHRMAt);

      if (result.isAssigned) result.assignedAt = timestamp.assignedAt!;
      if (result.isForwarded) result.forwardedAt = timestamp.forwardedAt!;
      if (result.isRepliedTo) result.repliedToAt = timestamp.repliedToAt!;
      if (result.isSentToEMR) result.sentToEmrAt = timestamp.sentToEMRAt!;
      if (result.isSentToHRM) result.sentToHrmAt = timestamp.sentToHRMAt!;

      if (result.isMessageFormHRM) {
        result.isCanceled = Utils.isInteger(timestamp.canceledAt);
        result.isCorrected = Utils.isInteger(timestamp.correctedAt);
      } else if (result.isMessageFormEvent || result.isMessageFormJoinGroupInvitation) {
        let eventStatus: EventMessageStatus | undefined;
        if (
          Utils.isNonArrayObjectLike<typeof extraData>(extraData) &&
          Utils.isNonArrayObjectLike<{ status: EventMessageStatus }>(extraData.eventResponse)
        ) {
          eventStatus = extraData.eventResponse.status;
        }

        if (Utils.isInteger(timestamp.acceptedAt) || eventStatus === EVENT_STATUS_ACCEPTED) {
          result.isAccepted = true;
        } else if (Utils.isInteger(timestamp.declinedAt) || eventStatus === EVENT_STATUS_DECLINED) {
          result.isDeclined = true;
        }
      }
    } else {
      result.isRecalled = Utils.isInteger(msgFlags.recalled);
    }

    if (
      result.isMessageFormReferral &&
      Utils.isNonArrayObjectLike<typeof extraData>(extraData) &&
      Utils.isNonArrayObjectLike<{ status: string }>(extraData.referralResponse)
    ) {
      const { status: referralStatus } = extraData.referralResponse;
      if (referralStatus === REFERRAL_STATUS_ACCEPTED) {
        result.isAccepted = true;
      } else if (referralStatus === REFERRAL_STATUS_DECLINED) {
        result.isDeclined = true;
      }
    }

    result.hasAttachments = Utils.isNonEmptyArray(documentList);
    // result.hasReminder = Utils.isInteger(msgFlags.reminder);
    result.isBillable = msgFlags.billable === true;
    result.isBilled = Utils.isNonEmptyArray(msg.billedByMsg);
    result.isImportant = msgFlags.importance === 'high';
    result.isMarkedAsRead = msgFlags.markedAsRead === true;
    result.isSensitivityConfidential = sensitivity === 'confidential';
    result.isSensitivityPersonal = sensitivity === 'personal';
    result.isSensitivityPrivate = sensitivity === 'private';
    result.isSensitivityNormal =
      !result.isSensitivityConfidential && !result.isSensitivityPersonal && !result.isSensitivityPrivate;
    result.isMessageSensitive = !result.isSensitivityNormal;
  }

  return result;
}

export function getFolderItemCount(
  folderId: any,
  dataObjectByIdSelector: ReturnType<typeof useDataObjectByIdSelector>
): DataObjectMsgFolderValue['itemCount'] {
  const defaultItemCount: DataObjectMsgFolderValue['itemCount'] = {
    all: { total: 0, unread: 0 },
    reminder: { total: 0, unread: 0 },
    important: { total: 0, unread: 0 },
    billable: { total: 0, unread: 0 },
    referral: { total: 0, unread: 0 }
  };

  if (DataObjectMsgFolder.isValidId(folderId)) {
    const folderObject = dataObjectByIdSelector<DataObjectMsgFolderValue>(folderId);
    const folderContents = DataObjectCache.getValue(folderObject);
    if (
      Utils.isNotNil(folderContents) &&
      Utils.isNotNil(folderContents.$$formatver) &&
      folderContents.$$formatver >= 2
    ) {
      // @ts-expect-error - false warning: condition will always return false
      if (folderContents.$$formatver === 2) {
        return { ...folderContents.itemCount, referral: defaultItemCount.referral };
      }
      return folderContents.itemCount;
    }
  }

  return defaultItemCount;
}

export const ORDERED_MESSAGE_TOOLBAR_ACTION_ID_LIST: ReadonlyArray<MessagingI18n.MessageToolbarActionId> = [
  // ===========================================================================
  // IMPORTANT: reply followed by replyAll, followed by forward should always
  // be the first three actions
  //
  // DO NOT CHANGE THIS ORDER UNLESS YOU ABSOLUTELY KNOW WHAT YOU'RE DOING
  ActionId.Reply,
  ActionId.ReplyAll,
  ActionId.Forward,
  ActionId.AssignMessageTo,
  // ===========================================================================

  ActionId.SendMessageToEMR,

  // ===========================================================================
  // IMPORTANT: if required, move these two actions together as one group; and,
  // please make sure markRead is followed by markUnread
  // ===========================================================================
  ActionId.MarkAsRead,
  ActionId.MarkAsUnread,
  // ===========================================================================

  ActionId.Recall,
  ActionId.MoveToArchive,
  ActionId.AssignCategory,
  ActionId.ComposeReferral,
  ActionId.ComposeHrmMessage,
  ActionId.ComposeConsultationRequest,

  // ===========================================================================
  // IMPORTANT: typically, these two actions should come after every other
  // action in the list, with print being the last one
  // ===========================================================================
  ActionId.DeleteMessage,
  ActionId.DiscardDraft,
  ActionId.Print,
  // ===========================================================================

  ActionId.CloseMessage
];

interface TypedGetMessageToolbarActionIdListState {
  excludedActionIdList: ReadonlyArray<MessagingI18n.MessageToolbarActionId>;
  folderKey: string;
  hasAccessRight: ReturnType<typeof selectAccessRight>;
  messageFormName: MessageFormName;
  parentFolderKey: string;
}

export interface MessageToolbarActionIdFilterPredicateState
  extends Omit<TypedGetMessageToolbarActionIdListState, 'messageFormName'> {
  msgFlags: MessageFlagsResult;
}

interface MessageToolbarActionIdFilterPredicate {
  (state: MessageToolbarActionIdFilterPredicateState, actionId: MessagingI18n.MessageToolbarActionId): boolean;
}

const isInbox = (folderKey: string) => folderKey === Constants.MessageFolderKey.Inbox;
const isGroupInbox = (folderKey: string) => folderKey === Constants.MessageFolderKey.GroupInbox;

const MESSAGE_TOOLBAR_ACTION_ID_FILTER_PREDICATE: Record<
  MessagingI18n.MessageToolbarActionId,
  boolean | MessageToolbarActionIdFilterPredicate | ReadonlyArray<MessageToolbarActionIdFilterPredicate>
> = {
  archive: [
    ({ hasAccessRight }) => hasAccessRight('archiveMessage'),
    ({ folderKey, parentFolderKey }) => {
      return (
        isInbox(folderKey) ||
        isGroupInbox(folderKey) ||
        (folderKey.startsWith(USER_DEFINED_MESSAGE_FOLDER_PREFIX) &&
          (isInbox(parentFolderKey) || isGroupInbox(parentFolderKey)))
      );
    }
  ],

  assignCategory: ({ folderKey, hasAccessRight, parentFolderKey }) => {
    return (
      ((isInbox(folderKey) || isInbox(parentFolderKey)) && hasAccessRight('assignMessageCategory')) ||
      ((isGroupInbox(folderKey) || isGroupInbox(parentFolderKey)) && hasAccessRight('assignGroupMessageCategory'))
    );
  },

  assignMessageTo: [
    ({ hasAccessRight }) => hasAccessRight('assignMessage'),
    ({ folderKey, parentFolderKey }) => isGroupInbox(folderKey) || isGroupInbox(parentFolderKey),
    ({ msgFlags: { isMessageFormDefault } }) => isMessageFormDefault
  ],

  closeMessage: false,

  composeConsultationRequest: [
    ({ hasAccessRight }) => hasAccessRight('composeEConsult'),
    ({
      msgFlags: {
        isMessageFormDefault,
        isMessageFormEConsult,
        isMessageFormEvent,
        isMessageFormHRM,
        isMessageFormHealthDataRequest,
        isMessageFormReferral
      }
    }) =>
      isMessageFormDefault ||
      isMessageFormEConsult ||
      isMessageFormEvent ||
      isMessageFormHRM ||
      isMessageFormHealthDataRequest ||
      isMessageFormReferral
  ],

  composeHrmMessage: [
    ({ hasAccessRight }) => hasAccessRight('composeHrmMessage'),
    ({
      msgFlags: {
        isMessageFormDefault,
        isMessageFormEConsult,
        isMessageFormEvent,
        isMessageFormHealthDataRequest,
        isMessageFormReferral
      }
    }) =>
      isMessageFormDefault ||
      isMessageFormEConsult ||
      isMessageFormEvent ||
      isMessageFormHealthDataRequest ||
      isMessageFormReferral
  ],

  composeReferral: [
    ({ hasAccessRight }) => hasAccessRight('composeReferral'),
    ({ msgFlags: { isMessageFormDefault, isMessageFormEncounter } }) => isMessageFormDefault || isMessageFormEncounter
  ],

  deleteMessage: [
    ({ hasAccessRight }) => hasAccessRight('deleteMessage'),
    ({ folderKey }) => folderKey !== Constants.MessageFolderKey.Drafts
  ],

  discardDraft: ({ folderKey }) => folderKey === Constants.MessageFolderKey.Drafts,

  forward: [
    ({ folderKey, hasAccessRight }) =>
      hasAccessRight(folderKey === Constants.MessageFolderKey.Sent ? 'forwardSentMessage' : 'forwardMessage'),
    ({ msgFlags: { isMessageFormDefault, isMessageFormEConsult, isMessageFormEncounter } }) =>
      isMessageFormDefault || isMessageFormEConsult || isMessageFormEncounter
  ],

  markRead: ({ hasAccessRight }) => hasAccessRight('accessMailbox'),
  markUnread: ({ hasAccessRight }) => hasAccessRight('accessMailbox'),

  print: true,

  recall: [
    ({ hasAccessRight }) => hasAccessRight('recallMessage'),
    ({ folderKey }) => folderKey === Constants.MessageFolderKey.Sent,
    ({ msgFlags: { isMessageFormDefault, isMessageFormEvent, isMessageFormEncounter } }) =>
      isMessageFormDefault || isMessageFormEvent || isMessageFormEncounter
  ],

  reply: [
    ({ folderKey, hasAccessRight }) =>
      hasAccessRight(folderKey === Constants.MessageFolderKey.Sent ? 'replyToSentMessage' : 'replyToMessage'),
    ({ msgFlags: { isMessageFormEventAttendance, isMessageFormHRM } }) =>
      !isMessageFormEventAttendance && !isMessageFormHRM
  ],

  replyAll: [
    ({ folderKey, hasAccessRight }) =>
      hasAccessRight(folderKey === Constants.MessageFolderKey.Sent ? 'replyToSentMessage' : 'replyToMessage'),
    ({ msgFlags: { isMessageFormEventAttendance, isMessageFormHRM } }) =>
      !isMessageFormEventAttendance && !isMessageFormHRM
  ],

  sendMessageToEMR: [
    ({ hasAccessRight }) => hasAccessRight('sendMessageToEMR'),
    ({
      msgFlags: {
        isMessageFormEvent,
        isMessageFormEventAttendance,
        isMessageFormJoinGroupInvitation,
        isMessageFormReferral
      }
    }) =>
      !isMessageFormEvent &&
      !isMessageFormEventAttendance &&
      !isMessageFormJoinGroupInvitation &&
      !isMessageFormReferral
  ]
};

export const DEFAULTS_GET_MESSAGE_TOOLBAR_ACTION_ID_LIST: MessageToolbarActionIdFilterPredicateState = {
  excludedActionIdList: [ActionId.AssignCategory],
  folderKey: '',
  hasAccessRight: () => false,
  parentFolderKey: '',
  msgFlags: undefined!
};

type GetMessageToolbarActionIdListState = PartialRecord<keyof TypedGetMessageToolbarActionIdListState, any>;

export function getMessageToolbarActionIdList(state?: GetMessageToolbarActionIdListState | null | undefined) {
  const data = Utils.defaults({}, state as unknown, {
    ...DEFAULTS_GET_MESSAGE_TOOLBAR_ACTION_ID_LIST,
    msgFlags: MessageFlags({ messageForm: { name: state?.messageFormName } })
  });

  return ORDERED_MESSAGE_TOOLBAR_ACTION_ID_LIST.filter((actionId) => {
    const predicate = MESSAGE_TOOLBAR_ACTION_ID_FILTER_PREDICATE[actionId];

    let result;
    if (typeof predicate === 'boolean') {
      result = predicate;
    } else {
      const predicateList = typeof predicate === 'function' ? [predicate] : predicate;
      result = predicateList.every((predicateFn) => predicateFn(data, actionId));
    }

    if (result === true && actionId !== ActionId.DiscardDraft) {
      result = data.folderKey !== Constants.MessageFolderKey.Drafts;
    }

    return result === true && !data.excludedActionIdList.includes(actionId);
  });
}

type GetMessageToolbarActionIdListForGridState = Omit<GetMessageToolbarActionIdListState, 'messageFormName'> &
  PartialRecord<'selectedMessageFormNames', any>;

export function getMessageToolbarActionIdListForGrid(
  state?: GetMessageToolbarActionIdListForGridState | null | undefined
) {
  const data = Utils.defaults(
    {},
    state as unknown,
    ({
      excludedActionIdList: [
        ActionId.AssignMessageTo,
        ActionId.CancelHrmMessage,
        ActionId.CloseMessage,
        ActionId.ComposeConsultationRequest,
        ActionId.ComposeHrmMessage,
        ActionId.ComposeReferral,
        ActionId.CorrectHrmMessage,
        ActionId.Print,
        ActionId.SendMessageToEMR
      ]
    } as unknown) as MessageToolbarActionIdFilterPredicateState
  );

  const msgFlags = MessageFlags(null);
  if (Utils.isNonEmptyArray(state?.selectedMessageFormNames)) {
    const PROPS: ReadonlyArray<Extract<keyof MessageFlagsResult, `isMessageForm${string}`>> = [
      'isMessageFormDefault',
      'isMessageFormEConsult',
      'isMessageFormEncounter',
      'isMessageFormEvent',
      'isMessageFormEventAttendance',
      'isMessageFormHRM',
      'isMessageFormJoinGroupInvitation',
      'isMessageFormReferral'
    ];

    Utils.transform(
      state!.selectedMessageFormNames!,
      (flags, formName) => {
        if (PROPS.every((prop) => flags[prop] === true)) return false;

        const msg = MessageFlags({ messageForm: { name: formName } });
        for (const prop of PROPS) {
          if (msg[prop] === true && flags[prop] !== true) {
            flags[prop] = true;
          }
        }
      },
      msgFlags as Writeable<MessageFlagsResult>
    );
  }

  if (
    msgFlags.isMessageFormEvent ||
    msgFlags.isMessageFormEventAttendance ||
    msgFlags.isMessageFormHRM ||
    msgFlags.isMessageFormJoinGroupInvitation ||
    msgFlags.isMessageFormReferral
  ) {
    data.excludedActionIdList = data.excludedActionIdList.concat(ActionId.Forward);

    if (!msgFlags.isMessageFormEvent) {
      data.excludedActionIdList = data.excludedActionIdList.concat(ActionId.Recall);
      if (msgFlags.isMessageFormEventAttendance || msgFlags.isMessageFormHRM) {
        data.excludedActionIdList = data.excludedActionIdList.concat(ActionId.Reply, ActionId.ReplyAll);
      }
    }
  }

  data.msgFlags = msgFlags;
  return getMessageToolbarActionIdList(data);
}
