import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogDocumentPreview } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.DocumentPreview = {
  action: {
    download: {
      // Translators: Label for the Download action in Document Preview dialog
      label: gettext('messaging', 'download', 'dialogDocumentPreviewActionLabel'),
      context: DialogDocumentPreview
    },
    selectDocument: {
      // Translators: Label for the Select Document action in Document Preview \
      // dialog
      label: gettext('messaging', 'selectDocument', 'dialogDocumentPreviewActionLabel'),
      context: DialogDocumentPreview
    }
  },
  message: {
    // Translators: Message displayed when preview is not available for the \
    // selected file in Document Preview dialog
    previewUnavailable: gettext('messaging', 'previewUnavailable', 'dialogDocumentPreviewMessage')
  }
};

export default i18n;
