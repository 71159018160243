import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import {
  DialogChangePasswordFormNewPassword,
  DialogChangePasswordFormSignIn,
  DialogChangePasswordFormVerifyMfa
} from '../../constants/action-context';

const i18n: AccountI18n.Dialog.ChangePassword = {
  action: {
    newPassword: {
      accept: {
        // Translators: Label for the Submit action of Change Password \
        // dialog's New Password step
        label: gettext('account', 'acceptNewPassword', 'changePasswordDialogActionLabel'),
        context: DialogChangePasswordFormNewPassword
      },
      reject: {
        // Translators: Label for the Cancel action of Change Password \
        // dialog's New Password step
        label: gettext('account', 'rejectNewPassword', 'changePasswordDialogActionLabel'),
        context: DialogChangePasswordFormNewPassword
      }
    },
    signIn: {
      accept: {
        // Translators: Label for the Submit action of Change Password \
        // dialog's Verify ID step
        label: gettext('account', 'acceptSignIn', 'changePasswordDialogActionLabel'),
        context: DialogChangePasswordFormSignIn
      },
      reject: {
        // Translators: Label for the Cancel action of Change Password \
        // dialog's Verify ID step
        label: gettext('account', 'rejectSignIn', 'changePasswordDialogActionLabel'),
        context: DialogChangePasswordFormSignIn
      }
    },
    verifyMfa: {
      accept: {
        // Translators: Label for the Submit action of Change Password \
        // dialog's Verify Code step
        label: gettext('account', 'acceptVerifyMfa', 'changePasswordDialogActionLabel'),
        context: DialogChangePasswordFormVerifyMfa
      },
      reject: {
        // Translators: Label for the Cancel action of Change Password \
        // dialog's Verify Code step
        label: gettext('account', 'rejectVerifyMfa', 'changePasswordDialogActionLabel'),
        context: DialogChangePasswordFormVerifyMfa
      }
    }
  },
  body: {
    // Translators: (HTML) Body for Change Password dialog's Verify ID step
    signIn: gettext('account', 'signIn', 'changePasswordDialogBody', 'safe-html')
  },
  // Translators: Error message displayed when an error occurs while changing \
  // the password
  submitErrorMessage: gettext('account', 'submit', 'changePasswordDialogError'),
  title: {
    // Translators: (HTML) Title for Change Password dialog's Verify ID step
    signIn: gettext('account', 'signIn', 'changePasswordDialogTitle', 'safe-html'),
    // Translators: (HTML) Title for Change Password dialog's Verify Code step
    verifyMfa: gettext('account', 'verifyMfa', 'changePasswordDialogTitle', 'safe-html'),
    // Translators: (HTML) Title for Change Password dialog's New Password step
    newPassword: gettext('account', 'newPassword', 'changePasswordDialogTitle', 'safe-html')
  }
};

export default i18n;
