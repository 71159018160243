import { AppException, Constants, MessagingException, ReadonlyMessageBodyEventAttendance, Utils } from '@sigmail/common';
import {
  CryptographicKey,
  CryptographicKeyPublic,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadataValue,
  DataObjectSigmailGlobalContactListValue
} from '@sigmail/objects';
import { SIGMAIL_REPORT_GROUP } from '../../../../constants/medical-institute-user-group-type-identifier';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';

interface State extends BaseSendMessageState {
  group: Extract<DataObjectSigmailGlobalContactListValue['list'][0], { type: 'group' }>;
}

export class SendEventAttendanceMessageAction extends BaseSendMessageAction<BaseSendMessagePayload, State> {
  /** @override */
  protected validateMessageFormName(): void {
    const { messageFormName } = this.payload;
    if (!Utils.isMessageFormNameEventAttendance(messageFormName)) {
      throw new MessagingException(
        `Expected message form name to be <${Constants.MessageFormName.EventAttendance}>; was <${messageFormName}>`
      );
    }
  }

  /** @override */
  protected async generateIdSequence() {
    await super.generateIdSequence();
    this.state.msgReadReceiptId = 0;

    await this.fetchSigmailReportGroup();
    await this.fetchSigmailReportGroupPublicKey();
  }

  /** @override */
  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    const { groupData, ...group } = this.state.group;

    const metadata = await super.createMessageMetadataValue();
    return { ...metadata, sender: { ...group, groupName: groupData.groupName } };
  }

  /** @override */
  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    const { messageForm } = this.payload.messageBody as ReadonlyMessageBodyEventAttendance;
    return { $$formatver: 1, messageForm };
  }

  /** @override */
  protected addInsertOperationForMessageReadReceipt(): Promise<void> {
    return Promise.resolve();
  }

  /** @override */
  protected addUpdateOperationForSentMessagesFolder(): Promise<void> {
    return Promise.resolve();
  }

  private async fetchSigmailReportGroup(): Promise<void> {
    const { globalContactListId } = this.state;

    const globalContactList = await this.getDataObjectValue<DataObjectSigmailGlobalContactListValue>(globalContactListId, { fetch: true });
    if (Utils.isNil(globalContactList)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Global contact list is either missing or invalid.');
    }

    const sigMailReportGroup = globalContactList.list.find(
      (contact) => contact.type === 'group' && contact.groupType === SIGMAIL_REPORT_GROUP
    ) as State['group'] | undefined;
    if (Utils.isNil(sigMailReportGroup)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to find SigMail Report Group in global contact list.');
    }

    this.state.group = sigMailReportGroup;
    this.state.recipientKeyIdList.concat(sigMailReportGroup.id);
  }

  private async fetchSigmailReportGroupPublicKey(): Promise<void> {
    const { group, roleAuthClaim: authState } = this.state;

    const { keyList } = await this.dispatchFetchObjects({
      authState,
      keysByType: [{ id: group.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }]
    });

    const groupPublicKeyJson = this.findKey(keyList, { id: group.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC });
    if (Utils.isNil(groupPublicKeyJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch public key of SigMail Report Group.');
    } else {
      const groupKeyPublic = new CryptographicKeyPublic(groupPublicKeyJson);
      await CryptographicKey.cache(groupKeyPublic);
    }
  }
}
