import { CarePlanI18n } from '@sigmail/i18n';
import { gettext } from '..';
import editFormI18n from './form-edit-care-plan';

const { fieldsetGroup: formI18n } = editFormI18n;

const { formField: allergyFieldI18n } = formI18n.allergyList.fieldsetAllergy;
const { formField: memberFieldI18n } = formI18n.careTeam.fieldsetMember;
const { formField: goalFieldI18n } = formI18n.goalList.fieldsetGoal;
const { formField: assessmentFieldI18n } = formI18n.healthAssessmentList.fieldsetHealthAssessment;
const { formField: issueFieldI18n } = formI18n.healthIssueList.fieldsetHealthIssue;
const { formField: medFieldI18n } = formI18n.medicationCoordination.fieldsetMedication;
const { formField: drugFieldI18n } = formI18n.medicationList.fieldsetDrug;
const { formField: aboutFieldI18n } = formI18n.personalInfo.fieldsetPersonalInfo;
const { formField: visitFieldI18n } = formI18n.recentHospitalVisit.fieldsetVisit;
const { formField: supportingInfoFieldI18n } = formI18n.supportingInfo.fieldsetPriorityAndConcern;

// Translators: Label for the Show Differences checkbox of Care Plan Viewer
const diffCheckboxLabel = gettext('care-plan', 'diffCheckbox', 'planViewerInputLabel');

const sectionActionI18n: CarePlanI18n.PlanViewer['section']['allergyList']['action'] = {
  hideHistory: {
    // Translators: Label for the Hide History action of a section in Care Plan Viewer
    label: gettext('care-plan', 'hideHistory', 'planViewerSectionActionLabel')
  },
  showHistory: {
    // Translators: Label for the Show History action of a section in Care Plan Viewer
    label: gettext('care-plan', 'showHistory', 'planViewerSectionActionLabel')
  }
};

const sectionHistoryI18n: CarePlanI18n.SectionHistory = {
  // Translators: Text displayed for the Section Added list item of Care Plan Section History panel
  // Translators: Token(s) available: {{NAME}}
  addedSection: gettext('care-plan', 'addedSection', 'planViewerSectionHistory'),

  // Translators: Heading of Care Plan Section History panel
  heading1: gettext('care-plan', 'heading1', 'planViewerSectionHistory'),

  // Translators: Text displayed for the Section Removed list item of Care Plan Section History panel
  // Translators: Token(s) available: {{NAME}}
  removedSection: gettext('care-plan', 'removedSection', 'planViewerSectionHistory'),

  // Translators: Text displayed for the Section Updated list item of Care Plan Section History panel
  // Translators: Token(s) available: {{NAME}}
  updatedSection: gettext('care-plan', 'updatedSection', 'planViewerSectionHistory')
};

const i18n: CarePlanI18n.PlanViewer = {
  action: {
    downloadPlan: {
      // Translators: Label for the Download Plan action of Care Plan Viewer
      label: gettext('care-plan', 'downloadPlan', 'planViewerActionLabel')
    },
    editPlan: {
      // Translators: Label for the Edit Plan action of Care Plan Viewer
      label: gettext('care-plan', 'editPlan', 'planViewerActionLabel')
    },
    maximize: {
      // Translators: Label for the Maximize action of Care Plan Viewer
      label: gettext('care-plan', 'maximize', 'planViewerActionLabel')
    },
    printPlan: {
      // Translators: Label for the Print Plan action of Care Plan Viewer
      label: gettext('care-plan', 'printPlan', 'planViewerActionLabel')
    },
    restoreSize: {
      // Translators: Label for the Restore Size action of Care Plan Viewer
      label: gettext('care-plan', 'restoreSize', 'planViewerActionLabel')
    },
    sendPlanToEMR: {
      // Translators: Label for the Send Plan To EMR action of Care Plan Viewer
      label: gettext('care-plan', 'sendPlanToEMR', 'planViewerActionLabel')
    },
    sendPlanToHRM: {
      // Translators: Label for the Send Plan To HRM action of Care Plan Viewer
      label: gettext('care-plan', 'sendPlanToHRM', 'planViewerActionLabel')
    }
  },
  diffCheckboxLabel,
  // Translators: Label for the Last Updated By data of Care Plan Viewer
  lastUpdatedBy: gettext('care-plan', 'lastUpdatedBy', 'planViewerDataLabel'),
  // Translators: Label for the Last Updated Date data of Care Plan Viewer
  // Translators: Token(s) available: {{TIMESTAMP}}
  lastUpdatedDate: gettext('care-plan', 'lastUpdatedDate', 'planViewerDataLabel'),
  legend: {
    // Translators: Label for the legend option UPDATED IN PAST 14 DAYS of Care Plan Viewer
    past14Days: gettext('care-plan', 'past14Days', 'planViewerLegendText'),
    // Translators: Label for the legend option UPDATED IN PAST 7 DAYS of Care Plan Viewer
    past7Days: gettext('care-plan', 'past7Days', 'planViewerLegendText')
  },
  // Translators: Message displayed in Care Plan Viewer when no plan has been selected from the list to show
  noPlanSelected: gettext('care-plan', 'noPlanSelected', 'planViewerAlertMessage'),
  // Translators: Message displayed in Care Plan Viewer when no plan has been selected from the list to show
  // Translators: Token(s) available: {{ACTION_BEGIN}}, {{ACTION_END}}
  noPlanSelectedCreateOne: gettext('care-plan', 'noPlanSelectedCreateOne', 'planViewerAlertMessage'),
  noPlansAvailable: (context) => {
    if (context === 'other') {
      // Translators: Message displayed in Care Plan Viewer when there are no plans available to show
      return gettext('care-plan', 'noPlansAvailableForPatient', 'planViewerAlertMessage');
    } else if (context === 'self') {
      // Translators: Message displayed in Care Plan Viewer when there are no plans available to show
      return gettext('care-plan', 'noPlansAvailable', 'planViewerAlertMessage');
    }
    return '';
  },
  noPlansAvailableCreateOne: (context) => {
    if (context === 'other') {
      // Translators: Message displayed in Care Plan Viewer when there are no plans available to show
      // Translators: Token(s) available: {{ACTION_BEGIN}}, {{ACTION_END}}
      return gettext('care-plan', 'noPlansAvailableForPatientCreateOne', 'planViewerAlertMessage');
    } else if (context === 'self') {
      // Translators: Message displayed in Care Plan Viewer when there are no plans available to show
      // Translators: Token(s) available: {{ACTION_BEGIN}}, {{ACTION_END}}
      return gettext('care-plan', 'noPlansAvailableCreateOne', 'planViewerAlertMessage');
    }
    return '';
  },
  // Translators: Label for the Plan Description data of Care Plan Viewer
  planDescription: gettext('care-plan', 'planDescription', 'planViewerDataLabel'),
  section: {
    allergyList: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      // Translators: Text to display for a removed entry in the Allergy List section
      entryRemoved: gettext('care-plan', 'entryRemoved', 'planViewerAllergyListSection'),
      fieldLabel: [
        ['identifier', allergyFieldI18n.allergyIdentifier.label],
        ['symptoms', allergyFieldI18n.allergySymptoms.label]
      ],
      heading: formI18n.allergyList.fullLabel,
      history: {
        ...sectionHistoryI18n,

        // Translators: List item text to display in History panel when one or more allergies were added and removed
        // from Allergy List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedRows: gettext('care-plan', 'addedRemovedAllergies', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more allergies were added, removed, and
        // updated in the Allergy List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedUpdatedRows: gettext('care-plan', 'addedRemovedUpdatedAllergies', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more allergies were added in the
        // Allergy List section
        // Translators: Token(s) available: {{NAME}}
        addedRows: gettext('care-plan', 'addedAllergies', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more allergies were added and updated
        // in the Allergy List section
        // Translators: Token(s) available: {{NAME}}
        addedUpdatedRows: gettext('care-plan', 'addedUpdatedAllergies', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more allergies were removed from
        // Allergy List section
        // Translators: Token(s) available: {{NAME}}
        removedRows: gettext('care-plan', 'removedAllergies', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more allergies were removed and updated
        // in the Allergy List section
        // Translators: Token(s) available: {{NAME}}
        removedUpdatedRows: gettext('care-plan', 'removedUpdatedAllergies', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an added row in the Allergy List section
        rowAdded: gettext('care-plan', 'addedAllergy', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel a removed row in the Allergy List section
        rowRemoved: gettext('care-plan', 'removedAllergy', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an updated row in the Allergy List section
        rowUpdated: gettext('care-plan', 'updatedAllergy', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more allergies were updated in the
        // Allergy List section
        // Translators: Token(s) available: {{NAME}}
        updatedRows: gettext('care-plan', 'updatedAllergies', 'planViewerSectionHistory')
      }
    },
    careTeam: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      // Translators: Text to display for a removed entry in the Care Team section
      entryRemoved: gettext('care-plan', 'entryRemoved', 'planViewerCareTeamSection'),
      fieldLabel: [
        ['firstName', memberFieldI18n.memberFirstName.label],
        ['lastName', memberFieldI18n.memberLastName.label],
        ['role', memberFieldI18n.memberRole.label],
        ['organization', memberFieldI18n.memberOrganization.label],
        ['contact', memberFieldI18n.memberContact.label]
      ],
      heading: formI18n.careTeam.fullLabel,
      history: {
        ...sectionHistoryI18n,

        // Translators: List item text to display in History panel when one or more members were added and removed
        // from Care Team section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedRows: gettext('care-plan', 'addedRemovedMembers', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more members were added, removed, and
        // updated in the Care Team section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedUpdatedRows: gettext('care-plan', 'addedRemovedUpdatedMembers', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more members were added in the
        // Care Team section
        // Translators: Token(s) available: {{NAME}}
        addedRows: gettext('care-plan', 'addedMembers', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more members were added and updated
        // in the Care Team section
        // Translators: Token(s) available: {{NAME}}
        addedUpdatedRows: gettext('care-plan', 'addedUpdatedMembers', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more members were removed from
        // Care Team section
        // Translators: Token(s) available: {{NAME}}
        removedRows: gettext('care-plan', 'removedMembers', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more members were removed and updated
        // in the Care Team section
        // Translators: Token(s) available: {{NAME}}
        removedUpdatedRows: gettext('care-plan', 'removedUpdatedMembers', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an added row in the Care Team section
        rowAdded: gettext('care-plan', 'addedMember', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel a removed row in the Care Team section
        rowRemoved: gettext('care-plan', 'removedMember', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an updated row in the Care Team section
        rowUpdated: gettext('care-plan', 'updatedMember', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more members were updated in the
        // Care Team section
        // Translators: Token(s) available: {{NAME}}
        updatedRows: gettext('care-plan', 'updatedMembers', 'planViewerSectionHistory')
      }
    },
    goalList: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      // Translators: Text to display for a removed entry in the Goal List section
      entryRemoved: gettext('care-plan', 'entryRemoved', 'planViewerGoalListSection'),
      fieldLabel: [
        ['identifier', goalFieldI18n.goalIdentifier.label],
        ['actionPlan', goalFieldI18n.goalActionPlan.label],
        ['details', goalFieldI18n.goalDetails.label],
        ['coordinator', goalFieldI18n.goalCoordinator.label],
        ['date', goalFieldI18n.goalDate.label]
      ],
      heading: formI18n.goalList.fullLabel,
      history: {
        ...sectionHistoryI18n,

        // Translators: List item text to display in History panel when one or more goals were added and removed
        // from Goal List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedRows: gettext('care-plan', 'addedRemovedGoals', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more goals were added, removed, and
        // updated in the Goal List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedUpdatedRows: gettext('care-plan', 'addedRemovedUpdatedGoals', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more goals were added in the
        // Goal List section
        // Translators: Token(s) available: {{NAME}}
        addedRows: gettext('care-plan', 'addedGoals', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more goals were added and updated
        // in the Goal List section
        // Translators: Token(s) available: {{NAME}}
        addedUpdatedRows: gettext('care-plan', 'addedUpdatedGoals', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more goals were removed from
        // Goal List section
        // Translators: Token(s) available: {{NAME}}
        removedRows: gettext('care-plan', 'removedGoals', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more goals were removed and updated
        // in the Goal List section
        // Translators: Token(s) available: {{NAME}}
        removedUpdatedRows: gettext('care-plan', 'removedUpdatedGoals', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an added row in the Goal List section
        rowAdded: gettext('care-plan', 'addedGoal', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel a removed row in the Goal List section
        rowRemoved: gettext('care-plan', 'removedGoal', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an updated row in the Goal List section
        rowUpdated: gettext('care-plan', 'updatedGoal', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more goals were updated in the
        // Goal List section
        // Translators: Token(s) available: {{NAME}}
        updatedRows: gettext('care-plan', 'updatedGoals', 'planViewerSectionHistory')
      }
    },
    healthAssessmentList: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      // Translators: Text to display for a removed entry in the Health Assessment List section
      entryRemoved: gettext('care-plan', 'entryRemoved', 'planViewerHealthAssessmentListSection'),
      fieldLabel: [
        ['identifier', assessmentFieldI18n.healthAssessmentIdentifier.label],
        ['date', assessmentFieldI18n.healthAssessmentDate.label],
        ['notes', assessmentFieldI18n.healthAssessmentNotes.label]
      ],
      heading: formI18n.healthAssessmentList.fullLabel,
      history: {
        ...sectionHistoryI18n,

        // Translators: List item text to display in History panel when one or more assessments were added and removed
        // from Health Assessment List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedRows: gettext('care-plan', 'addedRemovedAssessments', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more assessments were added, removed, and
        // updated in the Health Assessment List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedUpdatedRows: gettext('care-plan', 'addedRemovedUpdatedAssessments', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more assessments were added in the
        // Health Assessment List section
        // Translators: Token(s) available: {{NAME}}
        addedRows: gettext('care-plan', 'addedAssessments', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more assessments were added and updated
        // in the Health Assessment List section
        // Translators: Token(s) available: {{NAME}}
        addedUpdatedRows: gettext('care-plan', 'addedUpdatedAssessments', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more assessments were removed from
        // Health Assessment List section
        // Translators: Token(s) available: {{NAME}}
        removedRows: gettext('care-plan', 'removedAssessments', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more assessments were removed and updated
        // in the Health Assessment List section
        // Translators: Token(s) available: {{NAME}}
        removedUpdatedRows: gettext('care-plan', 'removedUpdatedAssessments', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an added row in the Health Assessment List section
        rowAdded: gettext('care-plan', 'addedAssessment', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel a removed row in the Health Assessment List section
        rowRemoved: gettext('care-plan', 'removedAssessment', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an updated row in the Health Assessment List section
        rowUpdated: gettext('care-plan', 'updatedAssessment', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more assessments were updated in the
        // Health Assessment List section
        // Translators: Token(s) available: {{NAME}}
        updatedRows: gettext('care-plan', 'updatedAssessments', 'planViewerSectionHistory')
      }
    },
    healthIssueList: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      // Translators: Text to display for a removed entry in the Health Issue List section
      entryRemoved: gettext('care-plan', 'entryRemoved', 'planViewerHealthIssueListSection'),
      fieldLabel: [
        ['identifier', issueFieldI18n.healthIssueIdentifier.label],
        ['details', issueFieldI18n.healthIssueDetails.label]
      ],
      heading: formI18n.healthIssueList.fullLabel,
      history: {
        ...sectionHistoryI18n,

        // Translators: List item text to display in History panel when one or more issues were added and removed
        // from Health Issue List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedRows: gettext('care-plan', 'addedRemovedIssues', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more issues were added, removed, and
        // updated in the Health Issue List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedUpdatedRows: gettext('care-plan', 'addedRemovedUpdatedIssues', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more issues were added in the
        // Health Issue List section
        // Translators: Token(s) available: {{NAME}}
        addedRows: gettext('care-plan', 'addedIssues', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more issues were added and updated
        // in the Health Issue List section
        // Translators: Token(s) available: {{NAME}}
        addedUpdatedRows: gettext('care-plan', 'addedUpdatedIssues', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more issues were removed from
        // Health Issue List section
        // Translators: Token(s) available: {{NAME}}
        removedRows: gettext('care-plan', 'removedIssues', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more issues were removed and updated
        // in the Health Issue List section
        // Translators: Token(s) available: {{NAME}}
        removedUpdatedRows: gettext('care-plan', 'removedUpdatedIssues', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an added row in the Health Issue List section
        rowAdded: gettext('care-plan', 'addedIssue', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel a removed row in the Health Issue List section
        rowRemoved: gettext('care-plan', 'removedIssue', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an updated row in the Health Issue List section
        rowUpdated: gettext('care-plan', 'updatedIssue', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more issues were updated in the
        // Health Issue List section
        // Translators: Token(s) available: {{NAME}}
        updatedRows: gettext('care-plan', 'updatedIssues', 'planViewerSectionHistory')
      }
    },
    medicationCoordination: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      fieldLabel: [
        ['source', medFieldI18n.medSource.label],
        ['aids', medFieldI18n.medAids.label],
        ['helper', medFieldI18n.medHelper.label],
        ['challenges', medFieldI18n.medChallenges.label]
      ],
      heading: formI18n.medicationCoordination.fullLabel,
      history: sectionHistoryI18n
    },
    medicationList: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      // Translators: Text to display for a removed entry in the Medication List section
      entryRemoved: gettext('care-plan', 'entryRemoved', 'planViewerMedicationListSection'),
      fieldLabel: [
        ['name', drugFieldI18n.drugName.label],
        ['dose', drugFieldI18n.drugDose.label],
        ['frequency', drugFieldI18n.drugFrequency.label],
        ['reason', drugFieldI18n.drugReason.label],
        ['prescriber', drugFieldI18n.drugPrescriber.label],
        ['start', drugFieldI18n.drugStart.label],
        ['notes', drugFieldI18n.drugNotes.label]
      ],
      heading: formI18n.medicationList.fullLabel,
      history: {
        ...sectionHistoryI18n,

        // Translators: List item text to display in History panel when one or more drugs were added and removed
        // from Medication List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedRows: gettext('care-plan', 'addedRemovedDrugs', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more drugs were added, removed, and
        // updated in the Medication List section
        // Translators: Token(s) available: {{NAME}}
        addedRemovedUpdatedRows: gettext('care-plan', 'addedRemovedUpdatedDrugs', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more drugs were added in the
        // Medication List section
        // Translators: Token(s) available: {{NAME}}
        addedRows: gettext('care-plan', 'addedDrugs', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more drugs were added and updated
        // in the Medication List section
        // Translators: Token(s) available: {{NAME}}
        addedUpdatedRows: gettext('care-plan', 'addedUpdatedDrugs', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more v were removed from
        // Medication List section
        // Translators: Token(s) available: {{NAME}}
        removedRows: gettext('care-plan', 'removedDrugs', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more drugs were removed and updated
        // in the Medication List section
        // Translators: Token(s) available: {{NAME}}
        removedUpdatedRows: gettext('care-plan', 'removedUpdatedDrugs', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an added row in the Medication List section
        rowAdded: gettext('care-plan', 'addedDrug', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel a removed row in the Medication List section
        rowRemoved: gettext('care-plan', 'removedDrug', 'planViewerSectionHistory'),

        // Translators: Caption to display in History panel an updated row in the Medication List section
        rowUpdated: gettext('care-plan', 'updatedDrug', 'planViewerSectionHistory'),

        // Translators: List item text to display in History panel when one or more drugs were updated in the
        // Medication List section
        // Translators: Token(s) available: {{NAME}}
        updatedRows: gettext('care-plan', 'updatedDrugs', 'planViewerSectionHistory')
      }
    },
    personalInfo: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      fieldLabel: [
        ['income', aboutFieldI18n.aboutIncome.label],
        ['employment', aboutFieldI18n.aboutEmployment.label],
        ['housing', aboutFieldI18n.aboutHousing.label],
        ['transportation', aboutFieldI18n.aboutTransportation.label],
        ['foodSecurity', aboutFieldI18n.aboutFoodSecurity.label],
        ['socialNetwork', aboutFieldI18n.aboutSocialNetwork.label],
        ['healthKnowledge', aboutFieldI18n.aboutHealthKnowledge.label],
        ['newInCountry', aboutFieldI18n.aboutNewInCountry.label],
        ['legalConcerns', aboutFieldI18n.aboutLegalConcerns.label],
        ['spiritualAffiliation', aboutFieldI18n.aboutSpiritualAffiliation.label],
        ['caregiverIssues', aboutFieldI18n.aboutCaregiverIssues.label],
        ['other', aboutFieldI18n.aboutOther.label]
      ],
      heading: formI18n.personalInfo.fullLabel,
      history: sectionHistoryI18n
    },
    recentHospitalVisit: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      fieldLabel: [
        ['hospitalName', visitFieldI18n.visitHospitalName.label],
        ['reason', visitFieldI18n.visitReason.label],
        ['description', visitFieldI18n.visitDescription.label],
        ['date', visitFieldI18n.visitDate.label],
        ['discharge', visitFieldI18n.visitDischarge.label],
        ['comments', visitFieldI18n.visitComments.label]
      ],
      heading: formI18n.recentHospitalVisit.fullLabel,
      history: sectionHistoryI18n
    },
    subject: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      fieldLabel: [
        ['firstName', formI18n.subject.fieldsetNameBirthDate.formField.firstName.label],
        ['lastName', formI18n.subject.fieldsetNameBirthDate.formField.lastName.label],
        ['preferredName', formI18n.subject.fieldsetNameBirthDate.formField.preferredName.label],
        ['birthDate', formI18n.subject.fieldsetNameBirthDate.formField.birthDate.label],
        ['gender', formI18n.subject.fieldsetGender.formField.gender.label],
        ['preferredPronoun', formI18n.subject.fieldsetGender.formField.preferredPronoun.label],
        // Translators: Header for the Address column of Identifiers section in Care Plan viewer
        ['address', gettext('care-plan', 'address', 'planViewerSubjectSectionCellHeader')],
        ['addressLine1', formI18n.subject.fieldsetAddress.formField.addressLine1.label],
        ['addressLine2', formI18n.subject.fieldsetAddress.formField.addressLine2.label],
        ['addressLevel2', formI18n.subject.fieldsetAddress.formField.addressLevel2.label],
        ['addressLevel1', formI18n.subject.fieldsetAddress.formField.addressLevel1.label],
        ['postalCode', formI18n.subject.fieldsetAddress.formField.postalCode.label],
        // Translators: Header for the Health Plan column of Identifiers section in Care Plan viewer
        ['healthPlan', gettext('care-plan', 'healthPlan', 'planViewerSubjectSectionCellHeader')],
        ['healthPlanNumber', formI18n.subject.fieldsetHealthPlan.formField.healthPlanNumber.label],
        ['healthPlanJurisdiction', formI18n.subject.fieldsetHealthPlan.formField.healthPlanJurisdiction.label],
        ['ancestry', formI18n.subject.fieldsetAncestry.formField.ancestry.label],
        ['indigenous', formI18n.subject.fieldsetAncestry.formField.indigenous.label],
        ['language', formI18n.subject.fieldsetCommunication.formField.language.label],
        ['communication', formI18n.subject.fieldsetCommunication.formField.communication.label]
      ],
      heading: formI18n.subject.fullLabel,
      history: sectionHistoryI18n
    },
    supportingInfo: {
      action: sectionActionI18n,
      diffCheckboxLabel,
      fieldLabel: [
        ['priority', supportingInfoFieldI18n.priority.label],
        ['healthConcern', supportingInfoFieldI18n.healthConcern.label]
      ],
      heading: formI18n.supportingInfo.fullLabel,
      history: sectionHistoryI18n
    }
  },
  // Translators: Text displayed for an Added Section in Care Plan Viewer
  // Translators: Tokens available: {{NAME}}, {{TIMESTAMP}}
  sectionAdded: gettext('care-plan', 'sectionAdded', 'planViewerHistory'),
  // Translators: Text displayed for a Removed Section in Care Plan Viewer
  // Translators: Tokens available: {{NAME}}, {{TIMESTAMP}}
  sectionRemoved: gettext('care-plan', 'sectionRemoved', 'planViewerHistory'),
  // Translators: Text displayed for an Updated Section in Care Plan Viewer
  // Translators: Tokens available: {{NAME}}, {{TIMESTAMP}}
  sectionUpdated: gettext('care-plan', 'sectionUpdated', 'planViewerHistory')
};

export default i18n;
