import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '..';
import { RootState } from '../root-reducer';

export const useAppState = (): [RootState, AppDispatch] => {
  const rootState = useSelector((state: RootState) => state);
  const dispatch = useDispatch<AppDispatch>();

  return React.useMemo(() => [rootState, dispatch], [rootState, dispatch]);
};
