import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogDeclineReferral } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.Referral.Decline = {
  action: {
    accept: {
      context: DialogDeclineReferral,
      // Translators: Label for the Proceed action of Decline Referral dialog
      label: gettext('messaging', 'accept', 'dialogDeclineReferralActionLabel')
    },
    reject: {
      context: DialogDeclineReferral,
      // Translators: Label for the Cancel action of Decline Referral dialog
      label: gettext('messaging', 'reject', 'dialogDeclineReferralActionLabel')
    }
  },
  // Translators: (HTML) Body of the Decline Referral dialog
  body: gettext('messaging', 'body', 'dialogDeclineReferral', 'safe-html'),
  form: {
    fieldsetReason: {
      formField: {
        reason: {
          error: {
            // Translators: Error message displayed when Reason input in \
            // Decline Referral dialog doesn't satisfy the maximum length \
            // constraint
            // Translators: Token(s) available: {{MAX_LENGTH}}
            tooLong: gettext('messaging', 'reasonTooLong', 'formDeclineReferralError'),
            // Translators: Error message displayed when Reason input is left \
            // blank in Decline Referral dialog
            valueMissing: gettext('messaging', 'reasonRequired', 'formDeclineReferralError')
          },
          // Translators: Label for the Reason input in Decline Referral dialog
          label: gettext('messaging', 'reason', 'formDeclineReferralInputLabel')
        }
      }
    }
  },
  // Translators: (HTML) Title for the Decline Referral dialog
  title: gettext('messaging', 'title', 'dialogDeclineReferral', 'safe-html')
};

export default i18n;
