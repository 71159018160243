import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';
import {
  DialogConfirmDeactivateAccount,
  DialogConfirmRemoveAccountInvitation,
  DialogConfirmResendAccountInvitation,
  DialogConfirmRevokeAccountInvitation
} from '../../constants/action-context';

const removeAccountInvitationI18n = {
  // Translators: (HTML) Title for the Confirm Remove Account Invitation
  // dialog
  title: gettext('contact-list', 'title', 'confirmRemoveAccountInvitationDialog', 'safe-html'),
  // Translators: (HTML) Body for the Confirm Remove Account Invitation
  // dialog
  body: gettext('contact-list', 'body', 'confirmRemoveAccountInvitationDialog', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Confirm Remove Account
      // Invitation dialog
      label: gettext('contact-list', 'reject', 'confirmRemoveAccountInvitationDialogActionLabel'),
      context: DialogConfirmRemoveAccountInvitation
    },
    accept: {
      // Translators: Label for the Proceed action of Confirm Remove Account
      // Invitation dialog
      label: gettext('contact-list', 'accept', 'confirmRemoveAccountInvitationDialogActionLabel'),
      context: DialogConfirmRemoveAccountInvitation
    }
  }
};

const i18n: ContactListI18n.View.ContactListView['dialog']['confirmOperation'] = {
  deactivateAccount: {
    // Translators: (HTML) Title for the Deactivate Account dialog
    title: gettext('contact-list', 'title', 'deactivateAccountDialog', 'safe-html'),
    // Translators: (HTML) Body for the Deactivate Account dialog
    body: gettext('contact-list', 'body', 'deactivateAccountDialog', 'safe-html'),
    // Translators: (HTML) Body for the Deactivate Account of caregiver dialog
    bodyCaregiver: gettext('contact-list', 'bodyCaregiver', 'deactivateAccountDialog', 'safe-html'),
    action: {
      reject: {
        // Translators: Label for the Cancel action of Deactivate Account
        // dialog
        label: gettext('contact-list', 'reject', 'deactivateAccountDialogActionLabel'),
        context: DialogConfirmDeactivateAccount
      },
      accept: {
        // Translators: Label for the Proceed action of Deactivate Account
        // dialog
        label: gettext('contact-list', 'accept', 'deactivateAccountDialogActionLabel'),
        context: DialogConfirmDeactivateAccount
      }
    }
  },
  removeAccountInvitation: removeAccountInvitationI18n,
  resendAccountInvitation: {
    // Translators: (HTML) Title for the Confirm Resend Account Invitation
    // dialog
    title: gettext('contact-list', 'title', 'confirmResendAccountInvitationDialog', 'safe-html'),
    // Translators: (HTML) Body for the Confirm Resend Account Invitation
    // dialog
    body: gettext('contact-list', 'body', 'confirmResendAccountInvitationDialog', 'safe-html'),
    action: {
      reject: {
        // Translators: Label for the Cancel action of Confirm Resend Account
        // Invitation dialog
        label: gettext('contact-list', 'reject', 'confirmResendAccountInvitationDialogActionLabel'),
        context: DialogConfirmResendAccountInvitation
      },
      accept: {
        // Translators: Label for the Proceed action of Confirm Resend Account
        // Invitation dialog
        label: gettext('contact-list', 'accept', 'confirmResendAccountInvitationDialogActionLabel'),
        context: DialogConfirmResendAccountInvitation
      }
    }
  },
  revokeAccountInvitation: {
    // Translators: (HTML) Title for the Confirm Revoke Account Invitation
    // dialog
    title: gettext('contact-list', 'title', 'confirmRevokeAccountInvitationDialog', 'safe-html'),
    // Translators: (HTML) Body for the Confirm Revoke Account Invitation
    // dialog
    body: gettext('contact-list', 'body', 'confirmRevokeAccountInvitationDialog', 'safe-html'),
    action: {
      reject: {
        // Translators: Label for the Cancel action of Confirm Revoke Account
        // Invitation dialog
        label: gettext('contact-list', 'reject', 'confirmRevokeAccountInvitationDialogActionLabel'),
        context: DialogConfirmRevokeAccountInvitation
      },
      accept: {
        // Translators: Label for the Proceed action of Confirm Revoke Account
        // Invitation dialog
        label: gettext('contact-list', 'accept', 'confirmRevokeAccountInvitationDialogActionLabel'),
        context: DialogConfirmRevokeAccountInvitation
      }
    }
  }
};

export default i18n;
