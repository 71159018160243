import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectSigmailGlobalContactListValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_SIGMAIL_GLOBAL_CONTACT_LIST;

/** @public */
export class DataObjectSigmailGlobalContactList extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.list);
  }

  public updateValue(newValue: DV): Promise<DataObjectSigmailGlobalContactList> {
    const Class = this.constructor as typeof DataObjectSigmailGlobalContactList;
    return Class.updateValue(this, newValue);
  }
}
