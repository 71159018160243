import { MessagingActionPayload } from '@sigmail/app-state';
import { Constants, MessagingException, SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectMsgFolderExt,
  DataObjectMsgFolderExtValue,
  DataObjectMsgFolderValue,
  FolderItem,
  IDataObject,
  MessageFolderItemCount
} from '@sigmail/objects';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { DataObjectCache } from '../../data-objects-slice/cache';
import * as DataObjectSelectors from '../../selectors/data-object';
import { AuthenticatedActionState } from '../authenticated-action';
import { BaseMessagingAction } from './base-messaging-action';

type Payload = MessagingActionPayload.UpdateMessageFolder;

interface State extends AuthenticatedActionState {
  msgFolder: FolderItem;
}

class UpdateMessageFolderAction extends BaseMessagingAction<Payload, State> {
  protected preExecute() {
    const { roleAuthClaim, folderKey, parentFolderKey } = this.payload;

    return super
      .preExecute()
      .then(() => {
        if (Utils.isString(roleAuthClaim)) {
          this.state.roleAuthClaim = roleAuthClaim;
        }
        return this.findMessageFolder(folderKey, parentFolderKey);
      })
      .then((msgFolder) => (this.state.msgFolder = msgFolder));
  }

  protected async onExecute() {
    await this.addUpdateOperationForMsgFolder();
  }

  private async addUpdateOperationForMsgFolder(): Promise<void> {
    const { folderKey, parentFolderKey, applyUpdate, requestBody, successPayload } = this.payload;
    const { roleAuthClaim: authState } = this.state;

    this.logger.info(
      'Adding an update operation to request body for message folder.',
      `(folderKey=${folderKey}, parentFolderKey=${String(parentFolderKey)})`
    );

    let msgFolderItem: FolderItem | undefined = this.state.msgFolder;

    this.logger.info(`Fetching data for message folder. (ID=${msgFolderItem.id})`);
    await this.dispatchFetchObjects({ authState, dataObjects: { ids: [msgFolderItem.id] } });
    let dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
    const baseFolderObject = dataObjectByIdSelector<DataObjectMsgFolderValue>(msgFolderItem.id);
    const baseFolder = DataObjectCache.getValue(baseFolderObject);
    if (Utils.isNil(baseFolderObject) || Utils.isNil(baseFolder)) {
      throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_DATA_INVALID);
    }

    const updatedBaseFolder: DataObjectMsgFolderValue = { ...baseFolder, data: [...baseFolder.data] };
    const updatedItemCount = Utils.cloneDeep<MessageFolderItemCount>(updatedBaseFolder.itemCount);
    let addUpdatedBaseFolderToRequestBody = false;

    while (Utils.isNotNil(msgFolderItem)) {
      const folderOrExtId = msgFolderItem.id as SigmailObjectId;
      const folderOrExtType = msgFolderItem.type as MessagingActionPayload.ApplyMessageFolderUpdateMeta['folderOrExtType'];

      let folderExtObject: IDataObject<DataObjectMsgFolderExtValue> | undefined = undefined;
      let folderExt: DataObjectMsgFolderExtValue | undefined = undefined;
      let folderData: Array<DataObjectMsgFolderExtValue['data'][0]> = [];
      let nextFolderExtId: DataObjectMsgFolderExtValue['next'] = null;

      if (folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER) {
        folderData = updatedBaseFolder.data as typeof folderData;
        nextFolderExtId = updatedBaseFolder.next;
      } else if (folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT) {
        this.logger.info(`Fetching data for message folder extension. (ID=${folderOrExtId})`);
        await this.dispatchFetchObjects({ authState, dataObjects: { ids: [folderOrExtId] } });

        dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
        folderExtObject = dataObjectByIdSelector<DataObjectMsgFolderExtValue>(folderOrExtId);
        folderExt = DataObjectCache.getValue(folderExtObject);
        if (Utils.isNil(folderExtObject) || Utils.isNil(folderExt)) {
          throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_DATA_INVALID);
        }

        folderData = [...folderExt.data];
        nextFolderExtId = folderExt.next;
      }

      const result = await Promise.resolve(applyUpdate(folderData, updatedItemCount, { folderOrExtId, folderOrExtType, nextFolderExtId }));
      if (Utils.isNonArrayObjectLike<MessagingActionPayload.NonVoidApplyMessageFolderUpdateResult>(result!)) {
        const { updated, done } = result;

        if (updated === true) {
          if (folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER) {
            addUpdatedBaseFolderToRequestBody = true;
          } else if (requestBody instanceof BatchUpdateRequestBuilder) {
            const updatedObject = await folderExtObject!.updateValue({ ...folderExt!, data: folderData });
            requestBody.update(updatedObject);

            if (Utils.isArray(successPayload?.request.dataObjects?.ids) && Utils.isArray(successPayload?.response.dataObjects)) {
              successPayload!.request.dataObjects!.ids.push(updatedObject.id);
              successPayload!.response.dataObjects!.push(updatedObject.toApiFormatted());
            }
          }
        }

        if (done === true) break;
      }

      if (DataObjectMsgFolderExt.isValidId(nextFolderExtId)) {
        msgFolderItem = { id: nextFolderExtId, type: process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT };
      } else {
        msgFolderItem = undefined;
      }
    }

    if (addUpdatedBaseFolderToRequestBody && requestBody instanceof BatchUpdateRequestBuilder) {
      const updatedObject = await baseFolderObject.updateValue({ ...updatedBaseFolder, itemCount: updatedItemCount });
      requestBody.update(updatedObject);

      if (Utils.isArray(successPayload?.request.dataObjects?.ids) && Utils.isArray(successPayload?.response.dataObjects)) {
        successPayload!.request.dataObjects!.ids.push(updatedObject.id);
        successPayload!.response.dataObjects!.push(updatedObject.toApiFormatted());
      }
    }
  }
}

export const updateMessageFolderAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'updateMessageFolderAction:');

    const action = new UpdateMessageFolderAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
