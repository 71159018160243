import type {
  GroupMessageFolderKeyInbox,
  GroupMessageFolderKeyPrefix,
  MessageFolderKeyDrafts,
  MessageFolderKeyInbox,
  MessageFolderKeySent
} from '../types';

export const GroupKeyPrefix: GroupMessageFolderKeyPrefix = '$group_';

export const GroupInbox: GroupMessageFolderKeyInbox = '$group_inbox';

export const Drafts: MessageFolderKeyDrafts = 'drafts';
export const Inbox: MessageFolderKeyInbox = 'inbox';
export const Sent: MessageFolderKeySent = 'sent';
