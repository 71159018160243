import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogHrmMissingCellNumber } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeHrmMessage['dialog']['missingCellNumber'] = {
  // Translators: (HTML) Title for the Missing Cell Number dialog in Compose \
  // HRM Message view
  title: gettext('messaging', 'title', 'dialogHrmMissingCellNumber', 'safe-html'),
  // Translators: (HTML) Message displayed in the body of Missing Cell Number \
  // dialog in Compose HRM Message view
  body: gettext('messaging', 'body', 'dialogHrmMissingCellNumber', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Missing Cell Number dialog \
      // in Compose HRM Message view
      label: gettext('messaging', 'accept', 'dialogHrmMissingCellNumberActionLabel'),
      context: DialogHrmMissingCellNumber
    }
  }
};

export default i18n;
