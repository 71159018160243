import { endOfDay } from 'date-fns';
import { MAX_EVENT_ATTENDEE_LIST_COUNT } from '.';

const MIN_VALID_DATE = new Date(1970, 0, 1);
const MAX_VALID_DATE = new Date(2099, 11, 31);
const MIN_VALID_TIME = new Date(0, 0, 1, 0, 0, 0, 0);
const MAX_VALID_TIME = new Date(0, 0, 1, 23, 59, 0, 0);

export const InstituteName = { minLength: 4, maxLength: 40 };

export const FirstName = { minLength: 2, maxLength: 40 };
export const LastName = { minLength: 2, maxLength: 40 };
export const EmailAddress = { minLength: 5, maxLength: 60 };

export const Username = { minLength: 8, maxLength: 64 };

// ============
// IMPORTANT:
// any change in min and max length here must be kept in sync with the
// regular expression defined in `./form-input-pattern.ts`.
// ============
export const Password = { minLength: 12, maxLength: 64 };
// ============

export const SecurityAnswer = { minLength: 2, maxLength: 64 };

export const GroupName = { minLength: 2, maxLength: 64 };
export const GuestContactGroupName = { minLength: 2, maxLength: 128 };

export const ReferralDeclineReason = { minLength: 1, maxLength: 500 };

export const BillingFileStartDate = { min: new Date(2022, 0, 1), max: MAX_VALID_DATE };
export const BillingFileEndDate = { min: new Date(2022, 0, 1), max: MAX_VALID_DATE };

export const HrmStartDate = { min: MIN_VALID_DATE, max: MAX_VALID_DATE };
export const HrmEndDate = { min: MIN_VALID_DATE, max: MAX_VALID_DATE };
export const HrmIssuedDate = { min: MIN_VALID_DATE, max: MAX_VALID_DATE };
export const HrmEffectiveDate = { min: MIN_VALID_DATE, max: MAX_VALID_DATE };
export const HrmNotes = { minLength: 0, maxLength: 5000 };
export const HrmRecipientList = { minLength: 1, maxLength: Number.MAX_SAFE_INTEGER };

export const EventTitle = { minLength: 1, maxLength: 500 };
export const EventDate = { min: MIN_VALID_DATE, max: MAX_VALID_DATE };
export const EventTime = { min: MIN_VALID_TIME, max: MAX_VALID_TIME };
export const EventAttendeeList = { minLength: 1, maxLength: MAX_EVENT_ATTENDEE_LIST_COUNT };
export const EventDescription = { minLength: 0, maxLength: 5000 };

export const Age = { min: 0, max: 130 };
export const RegistrationDate = { min: new Date(2020, 0, 1), max: MAX_VALID_DATE };

export const BPReadingFormDate = { min: MIN_VALID_DATE, max: endOfDay(Date.now()) };
export const BPReadingFormTime = { min: MIN_VALID_TIME, max: MAX_VALID_TIME };
export const BPReadingFormDiastolic = { min: 40, max: 140 };
export const BPReadingFormHeartRate = { min: 30, max: 250 };
export const BPReadingFormSystolic = { min: 60, max: 200 };

export const HealthDataRequestPhysicianNote = { minLength: 0, maxLength: 1000 };

export const VitalsFormBPDiastolic = { min: 30, max: 100 };
export const VitalsFormBPSystolic = { min: 60, max: 180 };
export const VitalsFormHeartRate = { min: 30, max: 110 };
export const VitalsFormPulseOximeter = { min: 60, max: 90 };

export const CardiacFormPainSeverity = { min: 0, max: 10 };
