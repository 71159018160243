import { Utils } from '@sigmail/common';
import { ActionIcon, LocalizedImageProps } from 'sigmail';

function isLocalizedImagePropsLike(value?: any): value is LocalizedImageProps {
  return (
    Utils.isObject(value) === true &&
    !Utils.isArray(value) &&
    'alt' in value &&
    Utils.isString(value.alt) &&
    'src' in value &&
    Utils.isPlainObject(value.src)
  );
}

export function resolveActionIcon(action: ActionIcon, actionId?: string, context?: any): LocalizedImageProps {
  if (typeof action.icon === 'function') {
    return action.icon(Utils.isString(actionId) ? actionId : '', context);
  } else if (isLocalizedImagePropsLike(action.icon)) {
    return action.icon;
  }
  return { alt: '', src: {} };
}
