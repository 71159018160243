import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { Utils } from '@sigmail/common';
import { WithT } from 'i18next';
import React from 'react';
import {
  ActionDataArchiveMessage,
  ActionDataAssignMessageCategory,
  ActionDataCancelHrmMessage,
  ActionDataDeleteMessage,
  ActionDataDiscardDraft,
  ActionDataMarkMessageReadUnread,
  ActionDataRecallMessage,
  ActionDataReplyToMessage,
  ActionDataRespondToCalendarEvent,
  ActionDataRespondToJoinGroupInvitation,
  ActionDataSaveAsDraft,
  ActionDataSendHrmMessage,
  ActionDataSendMessage,
  ActionDataSendReferralResponse,
  useArchiveMessageActionHandler,
  useAssignMessageCategoryActionHandler,
  useCancelHrmMessageActionHandler,
  useDeleteMessageActionHandler,
  useDiscardDraftActionHandler,
  useMarkMessageReadUnreadActionHandler,
  useRecallMessageActionHandler,
  useReplyToMessageActionHandler,
  useRespondToCalendarEventActionHandler,
  useRespondToJoinGroupInvitationActionHandler,
  useSaveAsDraftActionHandler,
  useSendConsultationRequestActionHandler,
  useSendHrmMessageActionHandler,
  useSendMessageActionHandler,
  useSendReferralActionHandler,
  useSendReferralResponseActionHandler
} from '.';
import { BillingNumberMissingError } from '../../../../app-state/actions/messaging/send-message-action';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../../../../app-state/constants';
import { useCurrentUser } from '../../../../app-state/hooks';
import * as ActionId from '../../../../constants/action-ids';
import globalI18n from '../../../../i18n/global';
import { useSubmitHealthDataActionHandler } from '../../../health-data/hooks';
import { ActionDataSubmitHealthData } from '../../../health-data/types';
import { ActionData, OnClickActionHandler } from '../../types';
import {
  BaseContextValue,
  ComposeMessageViewState,
  MessageFolderViewContextValue,
  OpenedMessageViewState
} from '../context';
import {
  closeDialogAction,
  closeMessageAction,
  composeConsultationRequestAction,
  composeHrmMessageAction,
  composeMessageAction,
  composeReferralAction,
  forwardMessageAction,
  openDialogAction
} from '../context/actions';
import { MessageFolderGridItem } from '../folder-item-grid/types';

export interface UseActionClickHandlerParams extends WithT {
  context?: MessageFolderViewContextValue | undefined;
  dispatch: React.Dispatch<AnyAction>;
  setEventObjectId?: React.Dispatch<React.SetStateAction<number | undefined>> | undefined;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export type UseActionClickHandlerResult = [
  action: PayloadAction<ActionData> | undefined,
  actionClickHandler: OnClickActionHandler
];

export const useActionClickHandler = ({
  context,
  dispatch,
  setEventObjectId,
  setSnackbarMessage,
  t
}: UseActionClickHandlerParams): UseActionClickHandlerResult => {
  const [action, setAction] = React.useState<PayloadAction<ActionData>>();
  const prevAction = React.useRef<PayloadAction<ActionData>>();
  const currentUser = useCurrentUser();
  const currentUserId = currentUser?.id;
  const isUserRoleGuest = Utils.isGuestRole(currentUser?.role);

  const setActionData = React.useCallback<React.Dispatch<ActionData>>(
    (payload) => setAction({ type: prevAction.current!.type, payload }),
    []
  );

  const { currentFolder, currentView, getSelectedMessageList, openedDialog } = Utils.isNotNil(context)
    ? context
    : (EMPTY_PLAIN_OBJECT as NonNullable<typeof context>);
  const messageKind = (context as (BaseContextValue & ComposeMessageViewState) | undefined)?.messageKind;
  const isMessageKindDraft = messageKind === 'draft';

  type TSelectedMessageList = ReturnType<typeof getSelectedMessageList>;
  const [sourceMessage, messageList] = React.useMemo<
    [TSelectedMessageList[0] | undefined, TSelectedMessageList]
  >(() => {
    let msg: MessageFolderGridItem | undefined;
    let list: TSelectedMessageList = EMPTY_ARRAY;

    switch (currentView) {
      case 'grid':
        list = getSelectedMessageList();
        break;
      case 'openedMessage': {
        msg = (context as BaseContextValue & OpenedMessageViewState).openedMessage;
        list = [msg!];
        break;
      }
      case 'composeHrmMessage':
      case 'composeMessage': {
        msg = (context as BaseContextValue & ComposeMessageViewState).sourceMessage;
        if (isMessageKindDraft && Utils.isNotNil(msg)) list = [msg];
        break;
      }
      default:
        break;
    }
    return [msg, list];
  }, [context, currentView, getSelectedMessageList, isMessageKindDraft]);

  const actionHandlerArchiveMessage = useArchiveMessageActionHandler();
  const actionHandlerCancelHrmMessage = useCancelHrmMessageActionHandler();
  const actionHandlerDeleteMessage = useDeleteMessageActionHandler();
  const actionHandlerDiscardDraft = useDiscardDraftActionHandler();
  const actionHandlerMarkReadUnread = useMarkMessageReadUnreadActionHandler();
  const actionHandlerAssignMessageCategory = useAssignMessageCategoryActionHandler();
  const actionHandlerRecallMessage = useRecallMessageActionHandler();
  const actionHandlerReplyToMessage = useReplyToMessageActionHandler();
  const actionHandlerRespondToCalendarEvent = useRespondToCalendarEventActionHandler();
  const actionHandlerRespondToJoinGroupInvitation = useRespondToJoinGroupInvitationActionHandler();
  const actionHandlerSaveAsDraft = useSaveAsDraftActionHandler(currentUser);
  const actionHandlerSendConsultationRequest = useSendConsultationRequestActionHandler(currentUser);
  const actionHandlerSendHrmMessage = useSendHrmMessageActionHandler();
  const actionHandlerSendMessage = useSendMessageActionHandler(currentUser);
  const actionHandlerSendReferral = useSendReferralActionHandler(currentUserId!);
  const actionHandlerSendReferralResponse = useSendReferralResponseActionHandler(currentUserId!);
  const actionHandlerSubmitHealthData = useSubmitHealthDataActionHandler();

  //
  //#region actionClickHandler
  const actionClickHandler = React.useCallback<OnClickActionHandler>(
    (actionId, baseActionData) => {
      if (!Utils.isString(actionId)) {
        if (process.env.REACT_APP_ENV === 'local') {
          throw new Error('typeof actionId !== "string"');
        }
        return;
      }

      if (actionId === ActionId.CloseMessage) {
        dispatch(closeMessageAction());
        return;
      }

      let actionData: ActionData = { ...baseActionData };
      if (Utils.isNotNil(openedDialog) && (actionId === ActionId.DialogAccept || actionId === ActionId.DialogReject)) {
        dispatch(closeDialogAction());

        if (actionId === ActionId.DialogReject) {
          if (openedDialog === 'emrSelectPatientRecord') {
            actionData.selectedPatientRecord = null;
          } else if (openedDialog === 'emrSelectProviderFolder') {
            actionData.selectedProviderFolder = null;
          } else {
            return;
          }
        }

        if (openedDialog === 'confirmDeleteMessage') {
          actionId = ActionId.DeleteMessage;
          actionData.confirmation = true;
        } else if (openedDialog === 'confirmDiscardDraft') {
          actionId = ActionId.DiscardDraft;
          actionData.confirmation = true;
        } else if (openedDialog === 'confirmRecallMessage' || openedDialog === 'confirmRecallEvent') {
          actionId = ActionId.Recall;
          actionData.confirmation = true;
        } else if (openedDialog === 'videoMeetingConsent') {
          actionId = ActionId.AcceptEvent;
          actionData.confirmation = true;
        } else if (openedDialog === 'confirmSendMessageToEMR') {
          actionId = ActionId.SendMessageToEMR;
          actionData.confirmation = true;
        } else if (openedDialog === 'confirmHrmCancelMessage') {
          actionId = ActionId.CancelHrmMessage;
          actionData.confirmation = true;
        } else if (openedDialog === 'referralDecline') {
          actionId = ActionId.DeclineReferral;
        } else if (openedDialog === 'referralAccept') {
          actionId = ActionId.AcceptReferral;
        } else if (
          openedDialog === 'emrOAuthParamsMissing' ||
          openedDialog === 'emrNoMatchingPatientRecord' ||
          openedDialog === 'emrTokenExpired' ||
          openedDialog === 'emrUploadProgress'
        ) {
          return setAction(undefined);
        } else if (openedDialog === 'emrSelectPatientRecord') {
          actionId = ActionId.SendMessageToEMR;
          actionData.patientRecordSelection = true;
        } else if (openedDialog === 'emrSelectProviderFolder') {
          actionId = ActionId.SendMessageToEMR;
          actionData.providerFolderSelection = true;
        } else {
          const error = new Error(`Unhandled case - ${openedDialog}`);
          if (process.env.REACT_APP_ENV === 'local') throw error;

          return;
        }
      }

      switch (actionId) {
        case ActionId.AcceptEvent: {
          if (actionData.confirmation !== true && actionData.isVideoMeeting === true && isUserRoleGuest) {
            dispatch(openDialogAction('videoMeetingConsent'));
            return;
          }

          const handlerActionData: ActionDataRespondToCalendarEvent = {
            ...actionData,
            currentFolder,
            response: 'accepted',
            sourceMessage
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.AssignMessageTo:
        case ActionId.SendMessage: {
          const { isFormSubmit, ...sendMessageActionData } = actionData as ActionDataSendMessage;

          const isAssignMessageAction = actionId === ActionId.AssignMessageTo;
          if (isAssignMessageAction && isFormSubmit !== true) {
            dispatch(openDialogAction('assignMessage'));
            return;
          }

          const handlerActionData: ActionDataSendMessage = {
            ...sendMessageActionData,
            currentFolder,
            messageKind: isAssignMessageAction ? 'assign' : messageKind,
            sourceMessage
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.AcceptJoinGroupInvitation: {
          const handlerActionData: ActionDataRespondToJoinGroupInvitation = {
            ...actionData,
            currentFolder,
            response: 'accept',
            sourceMessage
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.AcceptReferral: {
          if (actionData.consultDate !== null && !(actionData.consultDate instanceof Date)) {
            dispatch(openDialogAction('referralAccept'));
            return;
          }

          const handlerActionData: ActionDataSendReferralResponse = {
            ...(actionData as ActionDataSendReferralResponse),
            currentFolder,
            sourceMessage,
            status: 'accepted'
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.AssignCategory: {
          const handlerActionData = {
            ...actionData,
            currentFolder,
            messageList
          } as ActionDataAssignMessageCategory;

          actionData = handlerActionData;
          break;
        }
        case ActionId.CancelHrmMessage: {
          if (actionData.confirmation !== true) {
            dispatch(openDialogAction('confirmHrmCancelMessage'));
            return;
          }

          const handlerActionData: ActionDataCancelHrmMessage = { ...actionData, currentFolder, sourceMessage };

          actionData = handlerActionData;
          break;
        }
        case ActionId.ComposeConsultationRequest: {
          const { guestUserId, nonGuestUserId } = actionData;
          dispatch(composeConsultationRequestAction({ guestUserId, nonGuestUserId }));
          return;
        }
        case ActionId.ComposeHrmMessage:
        case ActionId.CorrectHrmMessage: {
          dispatch(composeHrmMessageAction(EMPTY_PLAIN_OBJECT));
          return;
        }
        case ActionId.ComposeReferral: {
          const { guestUserId } = actionData;
          dispatch(composeReferralAction({ guestUserId }));
          return;
        }
        case ActionId.ComposeMessage: {
          dispatch(composeMessageAction(EMPTY_PLAIN_OBJECT));
          return;
        }
        case ActionId.DeclineEvent: {
          const handlerActionData: ActionDataRespondToCalendarEvent = {
            ...actionData,
            currentFolder,
            response: 'declined',
            sourceMessage
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.DeclineJoinGroupInvitation: {
          const handlerActionData: ActionDataRespondToJoinGroupInvitation = {
            ...actionData,
            currentFolder,
            response: 'decline',
            sourceMessage
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.DeclineReferral: {
          if (!Utils.isString(actionData.reason)) {
            dispatch(openDialogAction('referralDecline'));
            return;
          }

          const handlerActionData: ActionDataSendReferralResponse = {
            ...(actionData as ActionDataSendReferralResponse),
            currentFolder,
            sourceMessage,
            status: 'declined'
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.DeleteMessage: {
          if (actionData.confirmation !== true) {
            dispatch(openDialogAction('confirmDeleteMessage'));
            return;
          }

          const handlerActionData: ActionDataDeleteMessage = { currentFolder, messageList };
          actionData = handlerActionData;
          break;
        }
        case ActionId.DiscardDraft: {
          if (actionData.confirmation !== true) {
            dispatch(openDialogAction('confirmDiscardDraft'));
            return;
          }

          const handlerActionData: ActionDataDiscardDraft = { messageList };
          actionData = handlerActionData;
          break;
        }
        case ActionId.Forward: {
          dispatch(forwardMessageAction());
          return;
        }
        case ActionId.JoinEvent: {
          if (typeof setEventObjectId === 'function') {
            setEventObjectId(actionData.eventObjectId);
            dispatch(openDialogAction('meetingRoom'));
          }
          return;
        }
        case ActionId.MarkAsRead:
        case ActionId.MarkAsUnread: {
          const markUnread = actionId === ActionId.MarkAsUnread;
          const handlerActionData: ActionDataMarkMessageReadUnread = {
            ...actionData,
            currentFolder,
            markUnread,
            messageList
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.MoveToArchive: {
          const handlerActionData: ActionDataArchiveMessage = { ...actionData, currentFolder, messageList };

          actionData = handlerActionData;
          break;
        }
        case ActionId.Print: {
          window.print();
          return;
        }
        case ActionId.Recall:
        case ActionId.RecallEvent: {
          if (actionData.confirmation !== true) {
            const areAllEventMessages =
              actionId === ActionId.RecallEvent || messageList.every(({ msgFlags }) => msgFlags.isMessageFormEvent);
            dispatch(openDialogAction(areAllEventMessages ? 'confirmRecallEvent' : 'confirmRecallMessage'));
            return;
          }

          const handlerActionData: ActionDataRecallMessage = { ...actionData, currentFolder, messageList };

          actionData = handlerActionData;
          break;
        }
        case ActionId.Reply:
        case ActionId.ReplyAll: {
          const handlerActionData: ActionDataReplyToMessage = {
            dispatch,
            messageKind: actionId,
            setActionData,
            sourceMessage: currentView === 'grid' ? messageList[0] : sourceMessage
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.SaveAsDraft: {
          const handlerActionData: ActionDataSaveAsDraft = {
            ...(actionData as ActionDataSaveAsDraft),
            messageKind,
            sourceMessage
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.SendConsultationRequest:
        case ActionId.SendReferral: {
          break;
        }
        case ActionId.SendHrmMessage: {
          const handlerActionData: ActionDataSendHrmMessage = {
            ...(actionData as ActionDataSendHrmMessage),
            folderKey: currentFolder.folderKey,
            parentFolderKey: currentFolder.parentFolderKey,
            sourceMessage
          };

          actionData = handlerActionData;
          break;
        }
        case ActionId.SendMessageToDoctorCare: {
          break;
        }
        case ActionId.SendMessageToEMR: {
          return dispatch(
            openDialogAction(actionData.confirmation === true ? 'emrUploadProgress' : 'confirmSendMessageToEMR')
          );
        }
        case ActionId.SubmitHealthData: {
          const handlerActionData: ActionDataSubmitHealthData = {
            bpReading: undefined!,
            cardiacIntake: undefined!,
            kccq: undefined!,
            vitals: undefined!,
            ...actionData,
            currentFolder,
            recipientId: actionData.recipientId,
            sourceMessage: messageList[0],
            t
          };

          actionData = handlerActionData;
          break;
        }
        default: {
          if (process.env.REACT_APP_ENV === 'local') {
            throw new Error(`Unhandled case - ${actionId}`);
          }
          return;
        }
      }

      return new Promise<PromiseSettledResult<any>>((resolve) => {
        const handlerActionData = Object.assign<ActionData, ActionData, ActionData>({}, actionData, {
          successCallback: (value?: any): void => {
            if (
              actionId !== ActionId.Reply &&
              actionId !== ActionId.ReplyAll &&
              actionId !== ActionId.MarkAsRead &&
              actionId !== ActionId.SendMessageToEMR
            ) {
              if (actionId === ActionId.SendMessageToDoctorCare || actionId === ActionId.SubmitHealthData) {
                dispatch(closeDialogAction());
              } else if (actionId !== ActionId.AssignMessageTo) {
                dispatch(closeMessageAction());
              } else {
                dispatch(closeDialogAction());
                dispatch(closeMessageAction());
              }
            }
            setAction(undefined);
            resolve({ status: 'fulfilled', value });
          },
          failCallback: (...args: any[]): void => {
            if (process.env.REACT_APP_ENV !== 'production') {
              /* eslint-disable no-console */
              console.group(actionId);
              console.error(...args);
              console.error({ actionData });
              console.groupEnd();
              /* eslint-enable no-console */
            }

            let showGenericErrorMessage = args.length === 0;
            if (args.length > 0) {
              switch (args[0]) {
                case BillingNumberMissingError:
                  showGenericErrorMessage = false;
                  break;
                default:
                  showGenericErrorMessage = true;
                  break;
              }
            }

            if (showGenericErrorMessage) {
              setSnackbarMessage(t(globalI18n.errorOccurredTryAgainMessageGeneric));
            }

            setAction(undefined);
            resolve({ status: 'rejected', reason: args[0] });
          }
        });

        setAction({ type: actionId, payload: handlerActionData });
      });
    },
    [
      currentFolder,
      currentView,
      dispatch,
      isUserRoleGuest,
      messageKind,
      messageList,
      openedDialog,
      setActionData,
      setEventObjectId,
      setSnackbarMessage,
      sourceMessage,
      t
    ]
  );
  //#endregion
  //

  React.useEffect(() => {
    const { type: actionId, payload: actionData } = (action || {}) as PayloadAction<any>;
    const ignoreAction =
      !Utils.isString(actionId) ||
      (Utils.isNotNil(prevAction.current) &&
        actionId === prevAction.current.type &&
        actionData === prevAction.current.payload);
    prevAction.current = action;
    if (ignoreAction) return;

    switch (actionId) {
      case ActionId.AcceptJoinGroupInvitation:
      case ActionId.DeclineJoinGroupInvitation: {
        actionHandlerRespondToJoinGroupInvitation(actionData);
        return;
      }
      case ActionId.AcceptEvent:
      case ActionId.DeclineEvent: {
        actionHandlerRespondToCalendarEvent(actionData);
        return;
      }
      case ActionId.AcceptReferral:
      case ActionId.DeclineReferral: {
        actionHandlerSendReferralResponse(actionData);
        return;
      }
      case ActionId.AssignCategory: {
        actionHandlerAssignMessageCategory(actionData);
        return;
      }
      case ActionId.AssignMessageTo:
      case ActionId.SendMessage: {
        actionHandlerSendMessage(actionData);
        return;
      }
      case ActionId.CancelHrmMessage: {
        actionHandlerCancelHrmMessage(actionData);
        break;
      }
      case ActionId.DeleteMessage: {
        if (process.env.REACT_APP_ENV === 'local') {
          actionHandlerDeleteMessage(actionData);
        } else {
          actionData.successCallback!();
        }
        return;
      }
      case ActionId.DiscardDraft: {
        actionHandlerDiscardDraft(actionData);
        return;
      }
      case ActionId.MarkAsRead:
      case ActionId.MarkAsUnread: {
        actionHandlerMarkReadUnread(actionData);
        return;
      }
      case ActionId.MoveToArchive: {
        actionHandlerArchiveMessage(actionData);
        return;
      }
      case ActionId.Recall:
      case ActionId.RecallEvent: {
        actionHandlerRecallMessage(actionData);
        return;
      }
      case ActionId.Reply:
      case ActionId.ReplyAll: {
        actionHandlerReplyToMessage(actionData);
        return;
      }
      case ActionId.SaveAsDraft: {
        actionHandlerSaveAsDraft(actionData);
        return;
      }
      case ActionId.SendConsultationRequest: {
        actionHandlerSendConsultationRequest(actionData);
        return;
      }
      case ActionId.SendHrmMessage: {
        actionHandlerSendHrmMessage(actionData);
        return;
      }
      case ActionId.SendMessageToDoctorCare: {
        actionHandlerSendMessage(actionData);
        return;
      }
      case ActionId.SendReferral: {
        actionHandlerSendReferral(actionData);
        return;
      }
      case ActionId.SubmitHealthData: {
        actionHandlerSubmitHealthData(actionData);
        return;
      }
      default: {
        break;
      }
    }
  }, [
    action,
    actionHandlerArchiveMessage,
    actionHandlerAssignMessageCategory,
    actionHandlerCancelHrmMessage,
    actionHandlerDeleteMessage,
    actionHandlerDiscardDraft,
    actionHandlerMarkReadUnread,
    actionHandlerRecallMessage,
    actionHandlerReplyToMessage,
    actionHandlerRespondToCalendarEvent,
    actionHandlerRespondToJoinGroupInvitation,
    actionHandlerSaveAsDraft,
    actionHandlerSendConsultationRequest,
    actionHandlerSendHrmMessage,
    actionHandlerSendMessage,
    actionHandlerSendReferral,
    actionHandlerSendReferralResponse,
    actionHandlerSubmitHealthData,
    dispatch
  ]);

  return React.useMemo(() => [action, actionClickHandler], [action, actionClickHandler]);
};
