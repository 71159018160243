import { Utils } from '@sigmail/common';
import { GlobalI18n, HealthPlanJurisdictionListItem } from '@sigmail/i18n';
import { gettext } from '..';
import addressLevel1I18n from './address-level1';

const i18n: GlobalI18n['healthPlanJurisdictionList'] = [
  ...Utils.flatten(
    (Object.keys(addressLevel1I18n) as Array<keyof typeof addressLevel1I18n>).map((key) =>
      addressLevel1I18n[key].map<HealthPlanJurisdictionListItem>(({ code, name: label, abbr }) => ({
        code: `${key}$${code}`,
        label,
        abbr
      }))
    )
  ),
  {
    code: 'self-paid',
    // Translators: Label for the Health Plan Jurisdiction option (Self Paid)
    label: gettext('global', 'selfPaid', 'healthPlanJurisdiction')
  },
  {
    code: 'insurance',
    // Translators: Label for the Health Plan Jurisdiction option (Other Insurance)
    label: gettext('global', 'other', 'healthPlanJurisdiction')
  },
  {
    code: 'foreign',
    // Translators: Label for the Health Plan Jurisdiction option (Foreign)
    label: gettext('global', 'foreign', 'healthPlanJurisdiction')
  }
];

export default i18n;
