// import { Utils } from '@sigmail/common';
import { Workbox } from 'workbox-window';

export interface RegisterServiceWorkerParams {
  readonly queryPeriodicSyncPermission?: boolean;
}

export async function register(params?: RegisterServiceWorkerParams): Promise<Workbox | null> {
  if (!('serviceWorker' in navigator)) return null;

  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) return null;

  const wb = new Workbox(`${process.env.PUBLIC_URL}/sw.js`);
  await wb.register();
  // if (params?.queryPeriodicSyncPermission === true && Utils.isNotNil(sw) && Utils.has(sw, 'periodicSync')) {
  //   void (await navigator.permissions.query({
  //     // @ts-expect-error TS2322: Type '"periodic-background-sync"' is not assignable to type 'PermissionName'
  //     name: 'periodic-background-sync'
  //   }));
  // }
  return wb;
}

export async function unregister(): Promise<boolean> {
  try {
    if ('serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready;
      return registration.unregister();
    }
  } catch {
    /* ignore */
  }
  return true;
}
