import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '../../..';
import { accessTokenSelector } from '../../../selectors/auth';

export const accuroEMRAddDocument = (
  payload: ApiActionPayload.AccuroEMRAddDocument
): AppThunk<Promise<Api.AccuroEMRAddDocumentResponse>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'accuroEMRAddDocument:');

    Logger.info('== BEGIN ==');
    try {
      let { accessToken, ...query } = payload;
      accessToken = Utils.stringOrDefault(accessToken, accessTokenSelector(getState()));
      return await apiService.accuroEMRAddDocument(accessToken, query);
    } finally {
      Logger.info('== END ==');
    }
  };
};
