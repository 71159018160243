/* eslint-disable no-unused-vars */

import type { AlgorithmCode, Nullable, SigmailGroupId, SigmailObjectId, SigmailUserId } from '@sigmail/common';
import { AppException, AppUserOrUserGroup, Constants, Utils } from '@sigmail/common';
import { Algorithm } from '@sigmail/crypto';
import { CryptographicKeyEncapsulated } from '../cryptographic-key/encapsulated-key';
import type {
  ApiFormattedCryptographicKey,
  ApiFormattedNotificationObject,
  ApiFormattedVersionedSigmailObject,
  ICryptographicKeyEncapsulated,
  INotificationObject,
  IVersionedSigmailObject,
  ValueFormatVersion
} from '../types';
import { VersionedSigmailObject } from '../versioned-sigmail-object';

/** @public */
export type NotificationObjectValueType<T extends NotificationObject<ValueFormatVersion>> =
  T extends NotificationObject<infer V> ? V : never;

const PROPS: ReadonlyArray<
  keyof Omit<
    INotificationObject<ValueFormatVersion>,
    keyof IVersionedSigmailObject<ValueFormatVersion> | 'generateKeysEncryptedFor' | 'decryptedValue'
  >
> = ['value', 'userId', 'sendingUserId', 'createdAtUtc', 'expiredAtUtc'];

const API_FORMATTED_PROPS: ReadonlyArray<
  keyof Omit<ApiFormattedNotificationObject, keyof ApiFormattedVersionedSigmailObject>
> = ['userId', 'sendingUserId', 'key', 'value', 'createdAtUtc', 'expiredAtUtc'];

/**
 * @TODO document
 *
 * @typeParam DV - Type of decrypted value.
 * @public
 */
export abstract class NotificationObject<DV extends ValueFormatVersion>
  extends VersionedSigmailObject<DV>
  implements INotificationObject<DV>
{
  protected static readonly DecryptedValueCache = new WeakMap<NotificationObject<any>, unknown>();

  protected static override get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT;
  }

  public static override isValidAlgorithmCode(value: unknown): value is AlgorithmCode {
    return Algorithm.isValidNonEncryptedObjectCode(value);
  }

  public static override isValidVersion(value: unknown): value is number {
    return value === 0;
  }

  /** Determines if the given value is a valid encrypted value. */
  public static isValidEncryptedValue(value: unknown): value is string {
    return Utils.isString(value);
  }

  /**
   * Override this method in derived classes to provide a correct/more specific
   * implementation of validation checks for the decrypted value of an object.
   */
  protected static isValidDecryptedValue(value: unknown): boolean {
    if (!Utils.isNonArrayObjectLike<ValueFormatVersion>(value)) return false;

    const { $$formatver } = value;
    return Utils.isUndefined($$formatver) || (Utils.isInteger($$formatver) && $$formatver > 0);
  }

  /** Determines if the given value is a valid creation date. */
  public static isValidCreationDate(value: unknown): value is Date {
    return Utils.isValidDate(value);
  }

  /**
   * Determines if the given value is a valid expiry date. `null` is considered
   * a valid value.
   */
  public static isValidExpiryDate(value: unknown): value is Date | null {
    return value === null || Utils.isValidDate(value);
  }

  protected static isKeyAssignableFrom(key: unknown): key is ICryptographicKeyEncapsulated {
    return CryptographicKeyEncapsulated.isAssignableFrom(key);
  }

  protected static isKeyApiFormatted(key: unknown): key is ApiFormattedCryptographicKey {
    return CryptographicKeyEncapsulated.isApiFormatted(key);
  }

  protected static keyFromApiFormatted(key: unknown): ICryptographicKeyEncapsulated {
    return new CryptographicKeyEncapsulated(key as ApiFormattedCryptographicKey);
  }

  public static override isAssignableFrom(value: unknown): value is INotificationObject<ValueFormatVersion> {
    if (!(super.isAssignableFrom(value) && Utils.every(PROPS, Utils.partial(Utils.has, value)))) return false;

    const obj = value as INotificationObject<ValueFormatVersion>;
    return (
      this.isValidEncryptedValue(obj.value) &&
      AppUserOrUserGroup.isValidId(obj.userId) &&
      AppUserOrUserGroup.isValidId(obj.sendingUserId) &&
      this.isValidCreationDate(obj.createdAtUtc) &&
      this.isValidExpiryDate(obj.expiredAtUtc) &&
      (obj[Constants.$$CryptographicKey] === null || this.isKeyAssignableFrom(obj[Constants.$$CryptographicKey])) &&
      typeof obj.generateKeysEncryptedFor === 'function' &&
      typeof obj.decryptedValue === 'function'
    );
  }

  public static override isApiFormatted(value: unknown): value is ApiFormattedNotificationObject {
    if (!(super.isApiFormatted(value) && Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, value)))) {
      return false;
    }

    const obj = value as ApiFormattedNotificationObject;
    return (
      Utils.isString(obj.value) &&
      Utils.isNumber(obj.userId) &&
      Utils.isNumber(obj.sendingUserId) &&
      Utils.isString(obj.createdAtUtc) &&
      (obj.expiredAtUtc === null || Utils.isString(obj.expiredAtUtc)) &&
      (obj.key === null || this.isKeyApiFormatted(obj.key))
    );
  }

  public static async create<T extends NotificationObject<ValueFormatVersion>>(
    this: new (...args: Array<any>) => T,
    id: number,
    code: AlgorithmCode | undefined,
    version: number,
    value: NotificationObjectValueType<T>,
    userId: number,
    sendingUserId: number,
    encryptedFor: number,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  ): Promise<T> {
    const Class = this as unknown as typeof NotificationObject;
    if (Class === NotificationObject) throw new TypeError('Illegal method invocation.');

    const objectCode = Utils.isUndefined(code) ? Class.DEFAULT_CODE : code;
    const key = await CryptographicKeyEncapsulated.createForObject(
      { type: Class.TYPE, id, code: objectCode, version } as NotificationObject<ValueFormatVersion>,
      encryptedFor,
      createdAtUtc
    );
    const encryptedValue = await Class.encryptObjectValue(value, { key, objectCode, objectVersion: version });

    const args = [id, objectCode, version, encryptedValue, userId, sendingUserId, key, createdAtUtc, expiredAtUtc];
    return Reflect.construct(Class, args) as T;
  }

  public readonly [Constants.$$CryptographicKey]!: ICryptographicKeyEncapsulated | null;
  public readonly createdAtUtc: Date;
  public readonly expiredAtUtc: Date | null;
  public readonly sendingUserId: number;
  public readonly userId: number;
  public readonly value: string;

  public constructor(
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number,
    value: string,
    userId: SigmailGroupId | SigmailUserId,
    sendingUserId: SigmailUserId,
    key: ICryptographicKeyEncapsulated | null,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  );

  public constructor(obj: ApiFormattedNotificationObject);

  public constructor(...args: Array<unknown>) {
    super(...(args.length === 1 ? args : [undefined, ...args]));

    const Class = this.constructor as typeof NotificationObject;
    if (Class === NotificationObject) throw new TypeError('Initialization error.');

    const obj = args[0] as ApiFormattedNotificationObject;
    const value = args.length === 1 ? obj.value : args[3];
    const userId = args.length === 1 ? obj.userId : args[4];
    const sendingUserId = args.length === 1 ? obj.sendingUserId : args[5];
    const apiFormattedKey = args.length === 1 ? obj.key : args[6];
    const createdAtUtc = args.length === 1 ? new Date(obj.createdAtUtc) : args[7];
    const expiredAtUtc = args.length === 1 ? obj.expiredAtUtc : (args[8] as Nullable<Date>);
    const expiryDate = Utils.isNil(expiredAtUtc) ? null : new Date(expiredAtUtc);

    const key =
      // eslint-disable-next-line no-nested-ternary
      apiFormattedKey === null
        ? null
        : Class.isKeyAssignableFrom(apiFormattedKey)
        ? apiFormattedKey
        : Class.keyFromApiFormatted(apiFormattedKey);

    if (!Class.isValidEncryptedValue(value)) throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE);
    if (!AppUserOrUserGroup.isValidId(userId)) throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID);
    if (!AppUserOrUserGroup.isValidId(sendingUserId))
      throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID);
    if (!Class.isValidCreationDate(createdAtUtc)) throw new AppException(Constants.Error.E_INVALID_CREATION_DATE);
    if (!Class.isValidExpiryDate(expiryDate)) throw new AppException(Constants.Error.E_INVALID_EXPIRY_DATE);

    this[Constants.$$CryptographicKey] = key;
    this.createdAtUtc = createdAtUtc;
    this.expiredAtUtc = expiryDate;
    this.sendingUserId = sendingUserId;
    this.userId = userId;
    this.value = value;
  }

  public override equals(other: unknown): other is INotificationObject<DV> {
    if (!super.equals(other)) return false;

    const obj = other as INotificationObject<DV>;
    return this.userId === obj.userId && this.sendingUserId === obj.sendingUserId;
  }

  public override hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.userId)) | 0;
    hashed = (31 * hashed + Utils.hashNumber(this.sendingUserId)) | 0;
    return hashed;
  }

  public async generateKeysEncryptedFor(
    ...ids: [number, ...Array<number>]
  ): Promise<Array<ICryptographicKeyEncapsulated | null>>;

  public async generateKeysEncryptedFor(
    createdAtUtc: Date,
    ...ids: [number, ...Array<number>]
  ): Promise<Array<ICryptographicKeyEncapsulated | null>>;

  public async generateKeysEncryptedFor(
    ...ids: [Date | number, ...Array<number>]
  ): Promise<Array<ICryptographicKeyEncapsulated | null>> {
    const createdAtUtc = Utils.isDate(ids[0]) ? (ids.shift() as Date) : undefined;
    const keys = new Array<ICryptographicKeyEncapsulated | null>(ids.length);

    if (ids.length > 0) {
      keys.fill(null);

      if (!Algorithm.isValidNonEncryptedObjectCode(this.code)) {
        for (let index = 0; index < ids.length; index++) {
          const key = await this[Constants.$$CryptographicKey]!.encryptFor(
            (ids as Array<number>)[index]!,
            createdAtUtc
          );
          keys[index] = key;
        }
      }
    }

    return keys;
  }

  protected freezeValue(value: DV): DV {
    return Utils.deepFreeze(value);
  }

  public async decryptedValue(): Promise<DV> {
    const Class = this.constructor as typeof NotificationObject;

    let value = Class.DecryptedValueCache.get(this) as DV | undefined;
    if (Utils.isUndefined(value)) {
      value = await Class.decryptObjectValue<DV>(this.value, {
        key: this[Constants.$$CryptographicKey],
        objectCode: this.code,
        objectVersion: this.version
      });

      if (!Class.isValidDecryptedValue(value)) {
        const errorMessage = [
          'Decrypted value failed the validation check.',
          `type=${this.type}`,
          `id=${this.id}`,
          `code=${this.code}`,
          `version=${this.version}`,
          `key.type=${this[Constants.$$CryptographicKey]?.type}`,
          `key.code=${this[Constants.$$CryptographicKey]?.code}`
        ];

        // eslint-disable-next-line no-console
        console.warn(errorMessage.concat('value=').join('\n'), value);

        throw new AppException(
          Constants.Error.E_INVALID_OBJECT_VALUE,
          `${errorMessage[0]} (${errorMessage.slice(1).join(', ')})`
        );
      }

      value = this.freezeValue(value);
      Class.DecryptedValueCache.set(this, value);
    }

    return value;
  }

  public override toApiFormatted(): ApiFormattedNotificationObject {
    const { code, id, type, version } = super.toApiFormatted();

    const key = this[Constants.$$CryptographicKey];
    let apiFormattedKey: ApiFormattedCryptographicKey | null = null;
    if (key !== null) {
      apiFormattedKey = key.toApiFormatted();
    }

    return {
      code,
      createdAtUtc: this.createdAtUtc.toISOString(),
      expiredAtUtc: Utils.stringOrDefault(this.expiredAtUtc?.toISOString(), null!),
      id,
      key: apiFormattedKey,
      sendingUserId: this.sendingUserId,
      type,
      userId: this.userId,
      value: this.value,
      version
    };
  }
}
