import { AppUser, AppUserGroup, Nullable, SigmailClientId, SigmailGroupId, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { useDispatchFetchUserObjects } from '.';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { accuroEMRCheckAuthStatus } from '../actions/EMR/accuro/check-auth-status';
import { accuroEMRProviderFolderList } from '../actions/EMR/accuro/provider-folder-list';
import { EMPTY_ARRAY } from '../constants';
import { activeGroupIdSelector } from '../selectors';
import { preferencesObjectSelector } from '../selectors/group-object';
import { clientIdSelector } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';
import { useAppState } from './use-app-state';

export type ResourceValue = Readonly<
  [authStatus: boolean, folderList: Api.AccuroEMRProviderFolderListResponse['folders']]
>;

export type Resource = ISuspenseResource<ResourceValue>;

export interface Params {
  providerId?: Nullable<number> | false;
}

const isClientIdValid = (id?: unknown): id is SigmailClientId => AppUser.isValidId(id);
const isProviderIdValid = (id?: unknown): id is number => Utils.isInteger(id);

export const useAccuroEMRProviderFolderListResource = (params?: Nullable<Params> | false): Resource => {
  const providerId = Utils.isNonArrayObjectLike<Params>(params) ? params.providerId : null;
  const [resource, setResource] = React.useState<Resource>(UNRESOLVED_RESOURCE);

  const [, appDispatch] = useAppState();
  const fetchUserObjects = useDispatchFetchUserObjects();
  const clientId = useSelector(clientIdSelector);
  const groupId = useSelector(activeGroupIdSelector);
  const groupPrefs = UserObjectCache.getValue(useSelector(preferencesObjectSelector)(groupId));
  const oauthParams = groupPrefs?.integrations?.accuroEMR?.oauthParams;
  const isAccuroEMRAvailable = Utils.isNotNil(oauthParams);

  const prevGroupId = React.useRef<SigmailGroupId>();
  if (prevGroupId.current !== groupId) {
    if (resource !== UNRESOLVED_RESOURCE) {
      setResource(UNRESOLVED_RESOURCE);
    }

    if (isClientIdValid(clientId) && isProviderIdValid(providerId)) {
      prevGroupId.current = groupId;

      if (Utils.isNil(groupPrefs) && AppUserGroup.isValidId(groupId)) {
        fetchUserObjects({ userObjects: [{ type: process.env.GROUP_OBJECT_TYPE_PREFERENCES, userId: groupId }] }).catch(
          Utils.noop
        );
      }
    } else {
      prevGroupId.current = undefined;
    }
  }

  React.useEffect(() => {
    setResource(UNRESOLVED_RESOURCE);
    if (!isAccuroEMRAvailable || !isClientIdValid(clientId) || !isProviderIdValid(providerId)) return;

    const getFolderListResourceValue = async (): Promise<ResourceValue> => {
      let authStatus = false;
      let folderList: ReadonlyArray<Api.AccuroEMRProviderFolderListItem> = EMPTY_ARRAY;

      try {
        authStatus = await appDispatch(accuroEMRCheckAuthStatus({ clientId }));
        if (authStatus) {
          const response = await appDispatch(accuroEMRProviderFolderList({ clientId, providerId }));
          folderList = response.folders;
        }
      } catch {
        /* ignore */
      }

      return [authStatus, folderList];
    };

    const fetchPromise = Utils.makeCancelablePromise(getFolderListResourceValue());
    fetchPromise.promise.then((value) => setResource({ value: () => value }), Utils.noop);
    return () => fetchPromise.cancel();
  }, [appDispatch, clientId, isAccuroEMRAvailable, providerId]);

  return resource;
};
