import { Utils } from '@sigmail/common';
import { CarePlanDateValue } from '@sigmail/objects';

export function toCarePlanDate(date: Date | null): CarePlanDateValue | undefined {
  return !Utils.isValidDate(date)
    ? undefined
    : {
        D: date.getDate(),
        M: date.getMonth() + 1,
        Y: date.getFullYear()
      };
}
