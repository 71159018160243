import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import { Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { MessageFolderItemCount, MessageFolderListItem, MessageTimestampRecord } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { AuthenticatedActionState } from '../authenticated-action';
import { AUTH_STATE_LOG_EVENT, AUTH_STATE_SET_LAST_SENT_TO_EMR_FLAG } from '../constants/auth-state-identifier';
import { logEventAction } from '../log-event-action';
import { BaseMessagingAction } from './base-messaging-action';
import { updateMessageFolderAction } from './update-msg-folder-action';

type Payload = MessagingActionPayload.SetLastSentToEmrFlag;

interface State extends AuthenticatedActionState {
  batchUpdateClaims: Array<string>;
  dtServer: Date;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

class SetLastSentToEMRFlagAction extends BaseMessagingAction<Payload, State> {
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        this.state.dtServer = await this.dispatchFetchServerDateAndTime();

        await this.generateRequestBody();

        const { batchUpdateClaims: claims, requestBody, successPayload } = this.state;
        const mutations = requestBody.build();
        if (Utils.isArray(mutations.dataObjects)) {
          const query: Api.EnterStateRequestData = {
            authState: this.state.roleAuthClaim,
            state: AUTH_STATE_SET_LAST_SENT_TO_EMR_FLAG
          };

          const { authState: batchUpdateAuthState } = await this.dispatchEnterState(query);
          await this.dispatchBatchUpdateData({ authState: batchUpdateAuthState, claims, ...mutations });

          try {
            await this.dispatchBatchQueryDataSuccess(successPayload);
          } catch (error) {
            this.logger.warn('Error manually updating app state:', error);
            /* ignore */
          }
        } else {
          this.logger.warn('Call ignored; nothing to update.');
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; batch update operation will be retried.');
      }
    }
  }

  private async generateRequestBody(): Promise<void> {
    const { folderKey, logRecord, parentFolderKey } = this.payload;

    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };

    const { currentUser, dtServer, requestBody, roleAuthClaim: authState, successPayload } = this.state;

    const { id: folderId } = await this.findMessageFolder(folderKey, parentFolderKey);
    const record: typeof logRecord = {
      ...logRecord,
      src: [logRecord.src[0], logRecord.src[1], folderId]
    };

    this.state.batchUpdateClaims = await this.dispatch(
      logEventAction({
        dtServer,
        fetchIds: (count) => {
          return this.dispatchFetchIdsByUsage({
            authState,
            state: AUTH_STATE_LOG_EVENT,
            ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
          });
        },
        logger: this.logger,
        record: this.newEventLogRecordValue(dtServer, Constants.EventLogCode.MessageSentToEMR, record),
        requestBody,
        successPayload,
        userId: currentUser.id,
        userIdType: 'user'
      })
    );

    await this.dispatch(
      updateMessageFolderAction({
        folderKey,
        parentFolderKey,
        requestBody,
        successPayload,
        applyUpdate: this.applyMessageFolderUpdate.bind(this)
      })
    );
  }

  private applyMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    _: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { msgMetadataId } = this.payload;
    const { dtServer } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    this.logger.info(`Locating item with message metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);
    const index = folderData.findIndex(({ header }) => header === msgMetadataId);
    if (index === -1) return result;
    const message = folderData[index];

    let timestamp: MessageTimestampRecord;
    if (Utils.isInteger(message.timestamp)) {
      timestamp = { createdAt: message.timestamp, sentToEMRAt: dtServer.getTime() };
    } else {
      timestamp = { ...message.timestamp, sentToEMRAt: dtServer.getTime() };
    }

    const { lastSentToOscar, ...flags } = { ...message.flags };
    folderData[index] = { ...message, timestamp, flags };

    result.updated = true;
    result.done = true;

    return result;
  }
}

export const setLastSentToEMRFlagAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'setLastSentToEMRFlagAction:');

    const action = new SetLastSentToEMRFlagAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
