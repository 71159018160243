import { IUserObjectCache } from '@sigmail/app-state';
import { Nullable, SigmailUserId, Utils } from '@sigmail/common';
import React from 'react';
import { useDispatchFetchUserObjects, UseDispatchFetchUserObjectsParams } from '.';
import { UseDispatchFetchObjectsParams } from './use-dispatch-fetch-objects';

export interface UseDispatchFetchUserProfilesParams
  extends Pick<UseDispatchFetchObjectsParams, 'authState' | 'claims'> {
  basicProfile?: false;
  privateProfile?: false;
  protectedProfile?: false;
  userId: SigmailUserId;
}

export const useDispatchFetchUserProfiles = () => {
  const dispatchFetchUserObjects = useDispatchFetchUserObjects();

  return React.useCallback(
    (
      { basicProfile, privateProfile, protectedProfile, userId, ...query }: UseDispatchFetchUserProfilesParams,
      cache?: Nullable<IUserObjectCache>
    ) => {
      const userObjects: NonNullable<UseDispatchFetchUserObjectsParams['userObjects']> = [
        basicProfile !== false && { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId },
        protectedProfile !== false && { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED, userId },
        privateProfile !== false && { type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId }
      ].filter((value): value is Exclude<typeof value, false> => Utils.isNonArrayObjectLike(value));

      return dispatchFetchUserObjects({ ...query, userObjects }, cache);
    },
    [dispatchFetchUserObjects]
  );
};
