import type { MessageFormName, ReadonlyMessageBodyDefault, SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectMsgBodyValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_MSG_BODY;

/** @public */
export class DataObjectMsgBody extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as ReadonlyMessageBodyDefault & {
      messageForm?: { name: MessageFormName };
    };

    let messageForm: MessageFormName | null = null;
    if (Utils.isString(dv.data)) {
      if (Utils.isUndefined(dv.messageForm)) {
        messageForm = 'default';
      }
    } else if (Utils.isUndefined(dv.data)) {
      if (Utils.isNonArrayObjectLike(dv.messageForm)) {
        messageForm = dv.messageForm.name;
      }
    }

    return Utils.isMessageFormNameValid(messageForm);
  }

  public static override isValidVersion(value: unknown): value is number {
    return value === 0;
  }

  public updateValue(newValue: DV): Promise<DataObjectMsgBody> {
    const Class = this.constructor as typeof DataObjectMsgBody;
    return Class.updateValue(this, newValue);
  }
}
