import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserCredentials } from '..';
import type {
  EmailTokenResponseType,
  ServerParamsEmailToken as DVServer,
  SharedParamsEmailTokenClientInvitation as DVShared
} from '../../types';

const RESPONSE_TYPE: Extract<EmailTokenResponseType, 'clientInvitation'> = 'clientInvitation';
const SERVER_PARAMS_PROPS: ReadonlyArray<keyof DVServer> = ['verifier'];
const SHARED_PARAMS_PROPS: ReadonlyArray<keyof DVShared> = ['response', 'salt', 'nvac'];
const TYPE = process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN;

/** @public */
export class ClientInvitation extends UserCredentials<DVShared, DVServer> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  public static override isValidServerParameters(value: unknown): value is DVServer {
    if (!super.isValidServerParameters(value)) return false;

    const dv = value as DVServer;
    return Utils.every(SERVER_PARAMS_PROPS, Utils.partial(Utils.has, dv)) && Utils.isString(dv.verifier);
  }

  public static override isValidSharedParameters(value: unknown): value is DVShared {
    if (!super.isValidSharedParameters(value)) return false;

    const dv = value as DVShared;
    return (
      Utils.every(SHARED_PARAMS_PROPS, Utils.partial(Utils.has, value)) &&
      dv.response === RESPONSE_TYPE &&
      Utils.isString(dv.salt) &&
      typeof dv.nvac === 'boolean'
    );
  }
}
