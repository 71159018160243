import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const armUsedInputI18n: HealthDataI18n.Form.BPReading['fieldsetArmUsed1']['formField']['armUsed1'] = {
  error: {
    // Translators: Error displayed when no option is selected for Arm \
    // Used input on BP Reading form
    badInput: gettext('health-data', 'armUsedRequired', 'bpReadingFormError')
  },
  // Translators: Label for the Arm Used input on BP Reading form
  label: gettext('health-data', 'armUsed', 'bpReadingFormInputLabel'),
  options: [
    {
      codedValue: 'L',
      // Translators: Label for the Left Arm option of Arm Used input on \
      // BP Reading form
      label: gettext('health-data', 'armLeft', 'bpReadingFormArmUsedOptionLabel')
    },
    {
      codedValue: 'R',
      // Translators: Label for the Right Arm option of Arm Used input on \
      // BP Reading form
      label: gettext('health-data', 'armRight', 'bpReadingFormArmUsedOptionLabel')
    }
  ]
};

const diastolicInputI18n = {
  error: {
    // Translators: Error displayed when value entered for Diastolic input \
    // doesn't satisfy the minimum/maximum length requirement of BP Reading form
    range: gettext('health-data', 'diastolicRange', 'bpReadingFormError'),
    // Translators: Error displayed when no value is entered for Diastolic \
    // input on BP Reading form
    valueMissing: gettext('health-data', 'diastolicRequired', 'bpReadingFormError')
  },
  // Translators: Label displayed for the Diastolic input on BP Reading form
  label: gettext('health-data', 'diastolic', 'bpReadingFormInputLabel')
};

const systolicInputI18n = {
  error: {
    // Translators: Error displayed when value entered for Systolic input \
    // doesn't satisfy the minimum/maximum length requirement of BP Reading form
    range: gettext('health-data', 'systolicRange', 'bpReadingFormError'),
    // Translators: Error displayed when no value is entered for Systolic \
    // input on BP Reading form
    valueMissing: gettext('health-data', 'systolicRequired', 'bpReadingFormError')
  },
  // Translators: Label displayed for the Systolic input on BP Reading form
  label: gettext('health-data', 'systolic', 'bpReadingFormInputLabel')
};

const commentInputI18n = {
  // Translators: Label displayed for the Comments input on BP Reading form
  label: gettext('health-data', 'comment', 'bpReadingFormInputLabel'),
  // Translators: Hint displayed for the Comments input on BP Reading form
  placeholder: gettext('health-data', 'comment', 'bpReadingFormInputHint')
};

const heartRateInputI18n = {
  error: {
    // Translators: Error displayed when value entered for Heart Rate input \
    // doesn't satisfy the minimum/maximum length requirement of BP Reading form
    range: gettext('health-data', 'heartRateRange', 'bpReadingFormError'),
    // Translators: Error displayed when no value is entered for Heart Rate \
    // input on BP Reading form
    valueMissing: gettext('health-data', 'heartRateRequired', 'bpReadingFormError')
  },
  // Translators: Label displayed for the Heart Rate input on BP Reading form
  label: gettext('health-data', 'heartRate', 'bpReadingFormInputLabel'),
  // Translators: Hint displayed for the Heart Rate input on BP Reading form
  placeholder: gettext('health-data', 'heartRate', 'bpReadingFormInputHint')
};

const dateInputI18n = {
  // Translators: Label displayed for the Date input on BP Reading form
  label: gettext('health-data', 'timestampDate', 'bpReadingFormInputLabel'),
  // Translators: Hint displayed for the Date input on BP Reading form
  placeholder: gettext('health-data', 'timestampDate', 'bpReadingFormInputHint')
};

const timeInputI18n = {
  error: {
    // Translators: Error displayed when value entered is not a valid date \
    // on BP Reading form
    badInput: gettext('health-data', 'timestampTimeBadInput', 'bpReadingFormError'),
    // Translators: Error displayed when time is out of acceptable range on \
    //  BP Reading form
    range: gettext('health-data', 'timestampTimeRange', 'bpReadingFormError'),
    // Translators: Error displayed when no value is selected for the Time \
    // input on BP Reading form
    valueMissing: gettext('health-data', 'timestampTimeRequired', 'bpReadingFormError')
  },
  label: '',
  // Translators: Hint displayed for the Time input on BP Reading form
  placeholder: gettext('health-data', 'timestampTime', 'bpReadingFormInputHint')
};

const i18n: HealthDataI18n.Form.BPReading = {
  fieldsetAdditionalReading: {
    formField: {
      additionalReading: {
        // Translators: Label for the Additional Reading input on BP Reading \
        // form
        label: gettext('health-data', 'additionalReading', 'bpReadingFormInputLabel'),
        options: [
          {
            codedValue: 0,
            // Translators: Label for the NO option of Additional Reading \
            // input on BP Reading form
            label: gettext('health-data', 'no', 'bpReadingFormAdditionalReadingOptionLabel')
          },
          {
            codedValue: 1,
            // Translators: Label for the YES option of Additional Reading \
            // input on BP Reading form
            label: gettext('health-data', 'yes', 'bpReadingFormAdditionalReadingOptionLabel')
          }
        ]
      }
    },
    // Translators: Label for the Additional Reading fieldset on BP Reading form
    label: gettext('health-data', 'additionalReading', 'bpReadingFormFieldsetLabel')
  },
  fieldsetArmUsed1: {
    formField: {
      armUsed1: armUsedInputI18n
    },
    // Translators: Label for the Arm Used fieldset on BP Reading form
    label: gettext('health-data', 'armUsed1', 'bpReadingFormFieldsetLabel')
  },
  fieldsetArmUsed2: {
    formField: {
      armUsed2: armUsedInputI18n
    }
  },
  fieldsetBloodPressure1: {
    formField: {
      diastolic1: diastolicInputI18n,
      systolic1: systolicInputI18n
    },
    // Translators: Label for the Blood Pressure fieldset on BP Reading form
    label: gettext('health-data', 'bloodPressure', 'bpReadingFormFieldsetLabel')
  },
  fieldsetBloodPressure2: {
    formField: {
      diastolic2: diastolicInputI18n,
      systolic2: systolicInputI18n
    },
    // Translators: Label for the Blood Pressure fieldset on BP Reading form
    label: gettext('health-data', 'bloodPressure', 'bpReadingFormFieldsetLabel')
  },
  fieldsetComment1: {
    formField: {
      comment1: commentInputI18n
    },
    // Translators: Label for the Comments fieldset on BP Reading form
    label: gettext('health-data', 'comment', 'bpReadingFormFieldsetLabel')
  },
  fieldsetComment2: {
    formField: {
      comment2: commentInputI18n
    },
    // Translators: Label for the Comments fieldset on BP Reading form
    label: gettext('health-data', 'comment', 'bpReadingFormFieldsetLabel')
  },
  fieldsetHeartRate1: {
    formField: {
      heartRate1: heartRateInputI18n
    },
    // Translators: Label for the Heart Rate fieldset on BP Reading form
    label: gettext('health-data', 'heartRate', 'bpReadingFormFieldsetLabel')
  },
  fieldsetHeartRate2: {
    formField: {
      heartRate2: heartRateInputI18n
    },
    // Translators: Label for the Heart Rate fieldset on BP Reading form
    label: gettext('health-data', 'heartRate', 'bpReadingFormFieldsetLabel')
  },
  fieldsetTimestampDate1: {
    formField: {
      timestampDate1: {
        ...dateInputI18n,
        error: {
          // Translators: Error displayed when date is out of acceptable range on \
          //  BP Reading form
          badInput: gettext('health-data', 'timestampDateBadInput', 'bpReadingFormError'),
          // Translators: Error displayed when date is out of acceptable range on \
          //  BP Reading form
          range: gettext('health-data', 'timestampDateRange', 'bpReadingFormError'),
          // Translators: Error displayed when no value is selected for Date \
          // input on BP Reading form
          valueMissing: gettext('health-data', 'timestampDateRequired', 'bpReadingFormError')
        }
      }
    },
    // Translators: Label for the Date and Time fieldset on BP Reading form
    label: gettext('health-data', 'timestampDate', 'bpReadingFormFieldsetLabel')
  },
  fieldsetTimestampTime1: {
    formField: {
      timestampTime1: timeInputI18n
    },
    // Translators: Label for the Date and Time fieldset on BP Reading form
    label: gettext('health-data', 'timestampTime', 'bpReadingFormFieldsetLabel')
  },
  fieldsetTimestampDate2: {
    formField: {
      timestampDate2: dateInputI18n
    },
    // Translators: Label for the Date and Time fieldset on BP Reading form
    label: gettext('health-data', 'timestampDate', 'bpReadingFormFieldsetLabel')
  },
  fieldsetTimestampTime2: {
    formField: {
      timestampTime2: timeInputI18n
    },
    // Translators: Label for the Date and Time fieldset on BP Reading form
    label: gettext('health-data', 'timestampTime', 'bpReadingFormFieldsetLabel')
  }
};

export default i18n;
