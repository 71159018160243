import { MemberRole, Utils } from '@sigmail/common';
import { SpecialtyListItem } from '@sigmail/i18n';
import globalI18n from '../i18n/global';

export function getSpecialtyListByRole(roleId: MemberRole | (string & {})): Array<SpecialtyListItem> {
  let specialtyList: Array<SpecialtyListItem> = [];
  if (Utils.isPhysicianRole(roleId)) {
    specialtyList = globalI18n.physicianSpecialtyList;
  } else if (Utils.isStaffRole(roleId)) {
    specialtyList = globalI18n.staffSpecialtyList;
  }
  return specialtyList;
}
