import { EventLogRecordSessionAuth } from '@sigmail/objects';

type EventLogRecordValueSessionAuth = EventLogRecordSessionAuth['value'];

export type Params = Readonly<[value: Omit<EventLogRecordValueSessionAuth, 'ua'>]>;

export const newEventLogRecordValueSessionAuth = (...params: Params): EventLogRecordValueSessionAuth => ({
  ...params[0],
  ua: navigator.userAgent
});
