import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectDocBodyValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_DOC_BODY;

/** @public */
export class DataObjectDocBody extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isString(dv.data);
  }

  public static override isValidVersion(value: unknown): value is number {
    return value === 0;
  }

  public updateValue(newValue: DV): Promise<DataObjectDocBody> {
    const Class = this.constructor as typeof DataObjectDocBody;
    return Class.updateValue(this, newValue);
  }
}
