import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';
import bpReadingDataViewer from './data-viewer-bp-reading';
import cardiacIntakeDataViewer from './data-viewer-cardiac-intake';
import copdAssessmentDataViewer from './data-viewer-copd-assessment';
import vitalsQuestionnaireDataViewer from './data-viewer-vitals-questionnaire';
import bpReadingForm from './form-bp-reading';
import bpReadingHealthDataParametersForm from './form-bp-reading-health-data-parameters';
import cardiacIntakeForm from './form-cardiac-intake';
import cardiomyopathyQuestionnaireForm from './form-cardiomyopathy-questionnaire';
import copdAssessmentForm from './form-copd-assessment';
import healthDataRequestForm from './form-health-data-request';
import kccqHealthDataParametersForm from './form-kccq-health-data-parameters';
import selectHealthDataTypeForm from './form-select-health-data-type';
import vitalsHealthDataParametersForm from './form-vitals-health-data-parameters';
import vitalsQuestionnaireForm from './form-vitals-questionnaire';
import bpReadingView from './view-bp-reading';
import cardiacIntakeView from './view-cardiac-intake';
import cardiomyopathyQuestionnaireView from './view-cardiomyopathy-questionnaire';
import copdAssessmentView from './view-copd-assessment';
import healthDataRequestView from './view-health-data-request';
import vitalsQuestionnaireView from './view-vitals-questionnaire';

const i18n: HealthDataI18n.Root = {
  dataViewer: {
    bpReading: bpReadingDataViewer,
    cardiacIntake: cardiacIntakeDataViewer,
    copdAssessment: copdAssessmentDataViewer,
    vitalsQuestionnaire: vitalsQuestionnaireDataViewer
  },
  form: {
    bpReading: bpReadingForm,
    bpReadingHealthDataParameters: bpReadingHealthDataParametersForm,
    cardiacIntake: cardiacIntakeForm,
    cardiomyopathyQuestionnaire: cardiomyopathyQuestionnaireForm,
    copdAssessment: copdAssessmentForm,
    healthDataRequest: healthDataRequestForm,
    kccqHealthDataParameters: kccqHealthDataParametersForm,
    selectHealthDataType: selectHealthDataTypeForm,
    vitalsHealthDataParameters: vitalsHealthDataParametersForm,
    vitalsQuestionnaire: vitalsQuestionnaireForm
  },
  view: {
    bpReading: bpReadingView,
    cardiacIntake: cardiacIntakeView,
    cardiomyopathyQuestionnaire: cardiomyopathyQuestionnaireView,
    copdAssessment: copdAssessmentView,
    healthDataRequest: healthDataRequestView,
    vitalsQuestionnaire: vitalsQuestionnaireView,
    dialog: {
      accordionHeader: {
        // Translators: (HTML) Page accordion Note header of Heath Data \
        // Request view
        note: gettext('health-data', 'note', 'healthDataFormDialogAccordionLabel'),
        // Translators: (HTML) Page accordion Questionnaire header of Heath \
        // Data Request view
        questionnaire: gettext('health-data', 'questionnaire', 'healthDataFormDialogAccordionLabel')
      }
    }
  }
};

export default i18n;
