import { ApiActionPayload, GroupActionPayload } from '@sigmail/app-state';
import { AppException, Constants, IAppUserGroup, SigmailObjectId, Utils, ValueObject } from '@sigmail/common';
import { getAlgorithm } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  CryptographicKey,
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  DataObjectSigmailGlobalContactList,
  DataObjectSigmailGlobalContactListValue,
  GroupObjectProfileBasic,
  GroupObjectProfileBasicValue,
  IDataObject,
  UserObjectProfilePrivate
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { SIGMAIL_GROUP_PREFIX } from '../../../constants/medical-institute-user-group-type-identifier';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { EMPTY_ARRAY } from '../../constants';
import { BaseAction, BaseActionState, FetchObjectsRequestData } from '../base-action';
import { batchQuerySuccessAction } from '../batch-query-success-action';
import { AUTH_STATE_CREATE_SIGMAIL_GROUP } from '../constants/auth-state-identifier';

type Payload = GroupActionPayload.CreateSigMailGroup;

interface State extends BaseActionState {
  batchUpdateAuthState: string;
  dtServer: Date;
  group: Omit<IAppUserGroup, keyof ValueObject>;
  groupClaim: string;
  idRecord: Api.GetIdsResponseData['ids'];
  idsClaim: string;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

class CreateSigMailGroupAction extends BaseAction<Payload, State, void> {
  private readonly asymmetricKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);

  protected async onExecute(): Promise<void> {
    await this.fetchUserProfilePrivate();

    await this.generateIdSequence();
    await this.generateRequestBody();

    const { batchUpdateAuthState: authState, idsClaim, requestBody, successPayload, group } = this.state;
    await this.batchUpdateData(this.payload.apiAccessToken, { authState: authState, claims: [idsClaim], ...requestBody.build() });

    if (this.isUserLoggedIn) {
      try {
        await this.dispatch(batchQuerySuccessAction(successPayload));
      } catch (error) {
        this.logger.warn('Error manually updating app state:', error);
        /* ignore */
      }
    } else {
      CryptographicKey.clearPrivateKey(group.id);
      CryptographicKey.clearPublicKey(group.id);
    }
  }

  private async fetchUserProfilePrivate(): Promise<void> {
    this.logger.info('Fetching the latest private profile object.');

    const { apiAccessToken, currentUserId: userId, roleAuthClaim: authState } = this.payload;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId }]
    };

    const { serverDateTime, userObjectList } = await this.fetchObjects(apiAccessToken, query);

    const privateProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId });
    if (Utils.isNil(privateProfileJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Private profile could not be fetched.');
    }

    const privateProfileObject = new UserObjectProfilePrivate(privateProfileJson);
    const { auditId, clientId, globalContactListId, ownerId } = await privateProfileObject.decryptedValue();

    this.state.auditId = auditId;
    this.state.clientId = clientId;
    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
    this.state.globalContactListId = globalContactListId;
    this.state.ownerId = ownerId;
  }

  private async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const { apiAccessToken, groupType, roleAuthClaim } = this.payload;

    const { updateIds } = Utils.decodeIdToken(roleAuthClaim);
    if (!Utils.isArray<SigmailObjectId>(updateIds)) {
      throw new AppException(Constants.Error.S_ERROR, '<updateIds> is either missing or invalid in decoded authState.');
    }

    const query: Api.GetIdsRequestData = {
      authState: roleAuthClaim,
      ids: {
        ids: [{ type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC }, { type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS }],
        usages: [{ usage: 'groupId' }]
      },
      state: AUTH_STATE_CREATE_SIGMAIL_GROUP,
      updateIds: updateIds as Array<SigmailObjectId>
    };

    const { authState, claims, ids: idRecord, idsClaim } = await this.fetchIdsByUsage(apiAccessToken, query);
    const [groupId] = idRecord['groupId'];

    const groupClaim = this.findClaim(claims, { name: 'group' });
    if (!Utils.isValidJwtToken(groupClaim, 'id')) {
      throw new Api.MalformedResponseException('Group claim is either missing or invalid.');
    }

    this.state.batchUpdateAuthState = authState;
    this.state.group = { groupType, id: groupId, type: 'group' };
    this.state.groupClaim = groupClaim;
    this.state.idRecord = idRecord;
    this.state.idsClaim = idsClaim;

    this.logger.debug({ groupId });
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], serverDateTime: '', userObjects: [] }
    };

    await this.addInsertOperationForKeyPair(); // 111, 101
    await this.addInsertOperationForProfileBasic(); // 431
    await this.addUpdateOperationForGlobalContactList(); // 360
  }

  private async addInsertOperationForKeyPair(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for a group key pair.');

    const { clientId, dtServer, globalContactListId, group, requestBody } = this.state;

    const { exportedPrivateKey, exportedPublicKey, privateKey, publicKey } = await this.asymmetricKeyAlgo.generateKey();
    this.logger.debug('Group private JWK', JSON.stringify(exportedPrivateKey));
    this.logger.debug('Group public JWK', JSON.stringify(exportedPublicKey));
    CryptographicKey.setPrivateKey(group.id, privateKey!);
    CryptographicKey.setPublicKey(group.id, publicKey!);

    const groupPrivateKey = await CryptographicKeyPrivate.create(group.id, undefined, 0, exportedPrivateKey!, clientId, dtServer);
    const groupPublicKey = await CryptographicKeyPublic.create(group.id, undefined, 0, exportedPublicKey!, group.id, dtServer);
    const groupPublicKeyForClient = await CryptographicKeyPublic.encryptFor(groupPublicKey, clientId);
    const groupPublicKeyForGlobalContactList = await CryptographicKeyPublic.encryptFor(groupPublicKey, globalContactListId);
    requestBody.insert([groupPrivateKey, groupPublicKey, groupPublicKeyForClient, groupPublicKeyForGlobalContactList]);
  }

  private async addInsertOperationForProfileBasic(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group profile.');

    const { groupName } = this.payload;
    const { auditId, clientId, dtServer, group, idRecord, requestBody, successPayload } = this.state;

    const [id] = idRecord[process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC];
    // @ts-expect-error
    const value: GroupObjectProfileBasicValue = { $$formatver: 4, name: groupName, memberList: EMPTY_ARRAY };
    this.logger.debug({ id, groupId: group.id, ...value });

    const profileObject = await GroupObjectProfileBasic.create(id, undefined, 1, value, group.id, group.id, dtServer);
    const keyList = await profileObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(profileObject[Constants.$$CryptographicKey]);
    requestBody.insert(profileObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.userObjects!.ids.push(profileObject.id);
    successPayload.response.userObjects!.push(profileObject.toApiFormatted());
  }

  private async addUpdateOperationForGlobalContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for global contact list.');

    const { apiAccessToken, groupName, roleAuthClaim: authState } = this.payload;
    const { globalContactListId, group, requestBody, successPayload } = this.state;

    const query: FetchObjectsRequestData = { authState, dataObjects: { ids: [globalContactListId] } };
    const { dataObjectList } = await this.fetchObjects(apiAccessToken, query);

    const contactListJson = this.findDataObject(dataObjectList, { id: globalContactListId });
    if (Utils.isNil(contactListJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Global contact list could not be fetched.');
    }

    const contactListObject = new DataObjectSigmailGlobalContactList(contactListJson);
    const applyUpdate = async (contactListObject: IDataObject<DataObjectSigmailGlobalContactListValue>) => {
      const contactList = await contactListObject.decryptedValue();

      const updatedValue: DataObjectSigmailGlobalContactListValue = {
        ...contactList,
        list: contactList.list.concat({ ...group, groupData: { groupName } })
      };

      return contactListObject.updateValue(updatedValue);
    };

    const contactListObjectKey = contactListObject[Constants.$$CryptographicKey];
    const dataUpdater: Api.DataUpdater<IDataObject<any>> = async (contactListJson, { dataObjects }) => {
      let index = dataObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === contactListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; global contact list could not be found in request body.');
      }

      const key = contactListObjectKey === null ? null : contactListObjectKey.toApiFormatted();
      const contactListObject = new DataObjectSigmailGlobalContactList({ ...contactListJson, key });

      const updatedObject = await applyUpdate(contactListObject);
      dataObjects![index].data = updatedObject;

      index = successPayload.response.dataObjects!.findIndex(({ id }) => id === contactListJson.id);
      if (index !== -1) successPayload.response.dataObjects![index] = updatedObject.toApiFormatted();
    };

    const updatedObject = await applyUpdate(contactListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.dataObjects!.ids.push(updatedObject.id);
    successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
  }
}

export const createSigMailGroupAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'createSigmailGroupAction:');

    if (!Utils.startsWith(payload.groupType, SIGMAIL_GROUP_PREFIX)) {
      throw new AppException(
        Constants.Error.S_ERROR,
        `Invalid payload; expected <groupType> to be a string starting with "${SIGMAIL_GROUP_PREFIX}"; was ${payload.groupType}`
      );
    }

    const action = new CreateSigMailGroupAction({ apiService, dispatch, getState, logger: Logger, payload });
    return action.execute();
  };
};
