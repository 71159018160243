import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectRegistrationDetailsValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_REGISTRATION_DETAILS;

/**
 * @public
 * @deprecated - not in use
 */
export class UserObjectRegistrationDetails extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isString(dv.name) && Utils.isNonArrayObjectLike(dv.adminContact) && Utils.isArray(dv.documentList);
  }

  public override updateValue(newValue: DV): Promise<UserObjectRegistrationDetails> {
    const Class = this.constructor as typeof UserObjectRegistrationDetails;
    return Class.updateValue(this, newValue);
  }
}
