import { HealthDataFormName, SigmailGroupId, Utils } from '@sigmail/common';
import { UserContactListItem } from '@sigmail/objects';
import React from 'react';
import { EMPTY_PLAIN_OBJECT } from '../../../app-state/constants';
import { SubmitHealthData } from '../../../constants/action-ids';
import { BPReadingDataUtil, CopdAssessmentDataUtil, KCCQDataUtil, VitalsDataUtil } from '../../../utils/health-data';
import { CardiacIntakeDataUtil } from '../../../utils/health-data/cardiac-intake';
import { FormComponentProps } from '../../shared/form/form.component';
import {
  DataFormNameBPReading,
  DataFormNameCardiacIntake,
  DataFormNameCopdAssessment,
  DataFormNameKCCQ,
  DataFormNameVitals
} from '../constants';
import { ActionDataSubmitHealthData, OnClickActionHandler } from '../types';

interface Params {
  actionClickHandler?: OnClickActionHandler | null | undefined;
  dataForm: HealthDataFormName;
  guestContact?: UserContactListItem;
  replyToGroupId: SigmailGroupId;
}

interface Result {
  formHasValidationErrors: boolean;
  formId: string | undefined;
  formSubmitting: boolean;
  props: object;
  setFormRef: React.RefCallback<HTMLFormElement | null> | undefined;
}

export type { Params as UseHealthDataFormStateParams, Result as UseHealthDataFormStateResult };

type FormStateChangeHandler = FormComponentProps<string, Record<string, unknown>>['onFormStateChange'];
type FormSubmitHandler = FormComponentProps<string, Record<string, unknown>>['onSubmit'];

export const useHealthDataFormState = (params?: Params | false | undefined): Result => {
  const noop = !Utils.isNonArrayObjectLike<Params>(params);
  const { actionClickHandler, dataForm, guestContact, replyToGroupId } = noop ? (EMPTY_PLAIN_OBJECT as Params) : params;
  const [formId, setFormId] = React.useState<string>();
  const [formHasValidationErrors, setFormHasValidationErrors] = React.useState(false);
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  const onFormStateChange = React.useMemo<FormStateChangeHandler>(() => {
    if (!noop) {
      return ({ formState: { hasValidationErrors, submitting } }) => {
        setFormHasValidationErrors(hasValidationErrors === true);
        setFormSubmitting(submitting === true);
      };
    }
  }, [noop]);

  const onSubmit = React.useMemo<FormSubmitHandler>(() => {
    if (!noop && typeof actionClickHandler === 'function') {
      return (values) => {
        //
        // <currentFolder>, <failCallback>, <sourceMessage>, <successCallback>,
        // <t> will be set later by the action click handler
        //

        const actionData = { dataForm, guestContact, recipientId: replyToGroupId } as ActionDataSubmitHealthData;
        if (dataForm === DataFormNameBPReading) {
          actionData.bpReading = BPReadingDataUtil.createDataFromFormValues(values as any);
        } else if (dataForm === DataFormNameCardiacIntake) {
          actionData.cardiacIntake = CardiacIntakeDataUtil.createDataFromFormValues(values as any);
        } else if (dataForm === DataFormNameCopdAssessment) {
          actionData.copdAssessment = CopdAssessmentDataUtil.createDataFromFormValues(values as any);
        } else if (dataForm === DataFormNameKCCQ) {
          actionData.kccq = KCCQDataUtil.createDataFromFormValues(values as any);
        } else if (dataForm === DataFormNameVitals) {
          actionData.vitals = VitalsDataUtil.createDataFromFormValues(values as any);
        }

        return Promise.resolve(actionClickHandler(SubmitHealthData, actionData));
      };
    }
  }, [actionClickHandler, dataForm, guestContact, noop, replyToGroupId]);

  const setFormRef = React.useMemo<React.RefCallback<HTMLFormElement> | undefined>(() => {
    if (!noop) {
      return (form) => setFormId(form?.id);
    }
  }, [noop]);

  return React.useMemo<Result>(
    () => ({
      formHasValidationErrors,
      formId,
      formSubmitting: !noop && formSubmitting,
      props: {
        disabled: noop || formSubmitting,
        onFormStateChange,
        onSubmit
      },
      setFormRef
    }),
    [formHasValidationErrors, formId, formSubmitting, noop, onFormStateChange, onSubmit, setFormRef]
  );
};
