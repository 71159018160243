import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogSendNonGuestAccountInvitation } from '../../constants/action-context';

const i18n: AccountI18n.Dialog.SendNonGuestAccountInvitation = {
  // Translators: (HTML) Title for Send Non-Guest Account Invitation dialog
  title: gettext('account', 'title', 'sendNonGuestAccountInvitationDialog', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Send Non-Guest Account \
      // Invitation dialog
      label: gettext('account', 'cancel', 'sendNonGuestAccountInvitationDialogActionLabel'),
      context: DialogSendNonGuestAccountInvitation
    },
    accept: {
      // Translators: Label for the Submit action of Send Non-Guest Account \
      // Invitation dialog
      label: gettext('account', 'submit', 'sendNonGuestAccountInvitationDialogActionLabel'),
      context: DialogSendNonGuestAccountInvitation
    }
  }
};

export default i18n;
