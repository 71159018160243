import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Form.WorkSchedule = {
  fieldsetTime: {
    formField: {
      timeEnd: {
        error: {
          // Translators: Error message displayed when an invalid value is entered for End Time input of Work \
          // Schedule form
          badInput: gettext('account', 'endTimeInvalid', 'workScheduleFormError'),
          // Translators: Error message displayed when value entered in End Time input of Work Schedule form is \
          // out of acceptable range
          tooShort: gettext('account', 'endTimeTooShort', 'workScheduleFormError'),
          // Translators: Error message displayed when End Time input of Work Schedule form is left blank
          valueMissing: gettext('account', 'endTimeRequired', 'workScheduleFormError')
        },
        // Translators: Label for the End Time input of Work Schedule form
        label: gettext('account', 'endTime', 'workScheduleFormInputLabel')
      },
      timeStart: {
        error: {
          // Translators: Error message displayed when an invalid value is entered for Start Time input of Work \
          // Schedule form
          badInput: gettext('account', 'endTimeInvalid', 'workScheduleFormError'),
          // Translators: Error message displayed when Start Time input of Work Schedule form is left blank
          valueMissing: gettext('account', 'startTimeRequired', 'workScheduleFormError')
        },
        // Translators: Label for the Start Time input of Work Schedule form
        label: gettext('account', 'startTime', 'workScheduleFormInputLabel')
      }
    }
  },
  fieldsetWeekday: {
    formField: {
      weekday: {
        error: {
          // Translators: Error message displayed when no option is selected for Weekday input of Work Schedule form
          valueMissing: gettext('account', 'weekdayRequired', 'workScheduleFormError')
        },
        // Translators: Label for the Weekday input of Work Schedule form
        label: gettext('account', 'weekday', 'workScheduleFormInputLabel')
      }
    }
  }
};

export default i18n;
