import { SchedulingActionPayload } from '@sigmail/app-state';
import {
  CalendarEventExtendedProps,
  Constants,
  MessageFormName,
  MessageImportance,
  MessagingException,
  SigmailObjectId,
  SigmailUserId,
  Utils
} from '@sigmail/common';
import {
  DataObjectMsgBodyValue,
  DataObjectMsgMetadataValue,
  DataObjectMsgReadReceiptValue,
  GroupObjectContactInfoValue,
  MessageFolderListItem,
  MessageTimestampRecord,
  NotificationObjectIncomingMessage,
  NotificationObjectIncomingMessageValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { MAX_EVENT_ATTENDEE_LIST_COUNT } from '../../../../constants';
import * as EmailTemplateParams from '../../../../constants/email-template-params';
import { EnglishCanada } from '../../../../constants/language-codes';
import { calendarEventEndTime } from '../../../../utils/calendar-event-end-time';
import { calendarEventStartTime } from '../../../../utils/calendar-event-start-time';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../../../constants';
import { queueReminderNotification } from '../../../reminder-notification-slice';
import { contactInfoObjectSelector as selectGroupContactInfo } from '../../../selectors/group-object';
import { basicProfileObjectSelector as selectUserProfileBasic, selectPersonName } from '../../../selectors/user-object';
import { UserObjectCache } from '../../../user-objects-slice/cache';
import { ActionInitParams } from '../../base-action';
import { AUTH_STATE_SEND_EVENT_MESSAGE } from '../../constants/auth-state-identifier';
import { sendTemplatedEmailMessageAction } from '../../email/send-templated-email-message-action';
import { newNotificationAction } from '../../notifications/new-notification-action';
import { createCalendarEventAction } from '../../scheduling/create-calendar-event-action';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';

export type ReadonlyMessageBodyEvent = Readonly<{
  messageForm: Readonly<{
    name: Extract<MessageFormName, 'event'>;
    value: SchedulingActionPayload.CreateCalendarEvent['data'];
  }>;
}>;

type ReminderListItem = NonNullable<CalendarEventExtendedProps['attendeeList'][0]['reminderList']>[0] &
  Partial<Pick<Api.NewNotificationRequestData['notificationList'][0], 'emailAddress' | 'phoneNumber'>>;

export interface State extends BaseSendMessageState {
  eventObjectId: SigmailObjectId;
  isSenderInAttendeeList: boolean;
  reminderListMap: Map<SigmailUserId, ReadonlyArray<ReminderListItem>>;
}

const DATE_FORMATTER = new Intl.DateTimeFormat(EnglishCanada, {
  day: '2-digit',
  month: 'short',
  weekday: 'short',
  year: 'numeric'
});

const TIME_FORMATTER = new Intl.DateTimeFormat(EnglishCanada, {
  hour: 'numeric',
  hour12: true,
  minute: 'numeric',
  timeZoneName: 'short'
});

export interface Payload extends Omit<BaseSendMessagePayload, 'messageBody'> {
  readonly messageBody: ReadonlyMessageBodyEvent;
}

export class SendEventAction extends BaseSendMessageAction<Payload, State> {
  public constructor(params: ActionInitParams<Payload>) {
    const { flags, messageBody, recipientList, sender } = params.payload;
    const { messageForm } = messageBody;
    const { value } = messageForm;

    super({
      ...params,
      payload: {
        ...params.payload,
        messageBody: {
          ...messageBody,
          messageForm: {
            ...messageForm,
            value: {
              ...value,
              extendedProps: {
                ...value.extendedProps,
                billable: flags.billable || undefined,
                importance: Utils.stringOrDefault<MessageImportance>(flags.important && 'high', undefined)
              }
            }
          }
        }
      }
    });

    if (sender.id !== this.currentUser.id) {
      throw new MessagingException('Invalid payload; expected sender to be the current user.');
    }

    if (recipientList.length > MAX_EVENT_ATTENDEE_LIST_COUNT) {
      throw new MessagingException(`Maximum number of recipients may not be more than ${MAX_EVENT_ATTENDEE_LIST_COUNT}`);
    }

    const { attendeeList } = messageBody.messageForm.value.extendedProps;
    this.state.isSenderInAttendeeList = attendeeList.some(({ id: userId }) => userId === sender.id);
  }

  /** @override */
  protected validateMessageFormName(): void {
    const { messageFormName } = this.payload;
    if (!Utils.isMessageFormNameEvent(messageFormName)) {
      throw new MessagingException(`Expected message form name to be <${Constants.MessageFormName.Event}>; was <${messageFormName}>`);
    }
  }

  /** @override */
  protected async createIdsRequestData(): Promise<Api.GetIdsRequestData> {
    const data = await super.createIdsRequestData();
    const idList = data.ids.ids!.filter(({ type }) => type !== process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE);

    const { recipientList } = this.payload;
    const { value: calendarEvent } = this.payload.messageBody.messageForm;
    const { isSenderInAttendeeList, dtServer } = this.state;

    const eventStartTime = calendarEventStartTime(calendarEvent);
    const { attendeeList } = calendarEvent.extendedProps;

    const reminderListMap = (this.state.reminderListMap = new Map(
      attendeeList.map<[SigmailUserId, ReadonlyArray<ReminderListItem>]>(({ id: attendeeId, reminderList, userData }) => {
        let phoneNumber: string = Utils.trimOrDefault(userData.cellNumber);
        let emailAddress: string = Utils.trimOrDefault(userData.emailAddress);

        //
        // if reminder list has already been provided for the attendee, use it.
        // Otherwise, ...
        //
        if (Utils.isNonEmptyArray(reminderList)) {
          return [
            attendeeId,
            reminderList.map((entry) => {
              const isMethodBoth = entry.method === 'both';
              return {
                ...entry,
                emailAddress: isMethodBoth || entry.method === 'email' ? emailAddress : undefined,
                phoneNumber: isMethodBoth || entry.method === 'sms' ? phoneNumber : undefined
              };
            })
          ];
        }

        //
        // ... try and create a reminder for the attendee with sane defaults
        //
        let method: ReminderListItem['method'] | undefined;
        if (phoneNumber.length > 0) {
          if (emailAddress.length > 0) {
            method = 'both';
          } else {
            method = 'sms';
            emailAddress = undefined!;
          }
        } else if (emailAddress.length > 0) {
          method = 'email';
          phoneNumber = undefined!;
        }

        if (Utils.isNotNil(method)) {
          let timestamp = eventStartTime - 60 * 60 * 1000;
          if (dtServer.getTime() >= timestamp) timestamp = eventStartTime - 30 * 60 * 1000;
          if (dtServer.getTime() >= timestamp) timestamp = eventStartTime - 15 * 60 * 1000;
          if (dtServer.getTime() >= timestamp) timestamp = eventStartTime - 5 * 60 * 1000;
          if (dtServer.getTime() >= timestamp) timestamp = eventStartTime;

          return [attendeeId, [{ id: 0, method, timestamp, emailAddress, phoneNumber }]];
        }

        return [attendeeId, EMPTY_ARRAY];
      })
    ));

    const { length: reminderListCount } = Utils.flatten(Array.from(reminderListMap.values()));
    idList.push({ type: process.env.DATA_OBJECT_TYPE_CALENDAR_EVENT, count: reminderListCount + 1 });

    let { length: recipientListCount } = recipientList;
    recipientListCount -= Number(isSenderInAttendeeList);
    if (recipientListCount > 0) {
      idList.push({
        type: process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE,
        count: recipientListCount
      });
    }

    return { ...data, state: AUTH_STATE_SEND_EVENT_MESSAGE, ids: { ids: idList } };
  }

  /** @override */
  protected async generateIdSequence(): Promise<void> {
    await super.generateIdSequence();

    const idSequence = Utils.makeSequence(this.state.idRecord[process.env.DATA_OBJECT_TYPE_CALENDAR_EVENT]);
    this.state.eventObjectId = idSequence.next().value;

    this.state.reminderListMap.forEach((reminderList) =>
      reminderList.forEach((reminder) => {
        reminder.id = idSequence.next().value;
      })
    );
  }

  /** @override */
  protected async generateRequestBody(): Promise<void> {
    const { messageBody, sender } = this.payload;
    const { dtServer, eventObjectId, msgMetadataId, msgBodyId } = this.state;

    const dtCreated = dtServer.getTime();
    const isVideoMeeting = messageBody.messageForm.value.extendedProps.meetingType === 'video';
    let attendeeList = messageBody.messageForm.value.extendedProps.attendeeList.slice();

    for (let index = 0; index < attendeeList.length; index++) {
      const attendee = attendeeList[index];
      const isAttendeeTheSender = attendee.id === sender.id;
      const isAttendeeRoleNonGuest = Utils.isNonGuestRole(attendee.userData.role);

      let timestamp = attendee.timestamp!;
      if (!Utils.isNonArrayObjectLike<typeof timestamp>(timestamp)) {
        timestamp = {};
      }

      // is this attendee is also the sender of the event, automatically mark
      // their RSVP as accepted
      if (isAttendeeTheSender) {
        timestamp.acceptedAt = dtCreated;
      }

      // attendees of `patient` role have to provide or reject the video consent
      // when they choose to accept the event; for any other role, we
      // automatically set the videoConsentAt timestamp
      if (isVideoMeeting && isAttendeeRoleNonGuest) {
        timestamp.videoConsentAt = dtCreated;
      }

      // loop through all timestamps and set them to server's current date-time
      timestamp = Utils.mapValues(timestamp, () => dtCreated);

      let reminderList = this.state.reminderListMap.get(attendee.id);
      if (Utils.isNonEmptyArray<typeof reminderList>(reminderList)) {
        reminderList = reminderList.map(({ emailAddress, phoneNumber, ...reminder }) => reminder);
      }

      attendeeList[index] = { ...attendee, timestamp, reminderList };
    }

    let { value: calendarEvent } = messageBody.messageForm;
    calendarEvent = {
      ...calendarEvent,
      id: `SM-E-${eventObjectId}`,
      extendedProps: {
        ...calendarEvent.extendedProps,
        attendeeList,
        dtCreated,
        msgMetadataId,
        msgBodyId
      }
    };

    await this.dispatch(
      createCalendarEventAction({
        id: eventObjectId,
        data: calendarEvent,
        claims: this.state.batchUpdateClaims,
        requestBody: this.state.requestBody,
        successPayload: this.state.successPayload
      })
    );

    return super.generateRequestBody();
  }

  /** @override */
  protected async createMessageReadReceiptValue(): Promise<DataObjectMsgReadReceiptValue> {
    const data = await super.createMessageReadReceiptValue();

    const { id: senderId } = this.payload.sender;
    const { dtServer } = this.state;

    return {
      ...data,
      data: Utils.filterMap(data.data, (entry) => {
        return entry.recipientId !== senderId ? entry : { ...entry, readAtUtc: dtServer.getTime() };
      })
    };
  }

  /** @override */
  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    const { activeGroupId } = this.state;

    const value = await super.createMessageMetadataValue();
    return { ...value, replyTo: [{ id: activeGroupId, type: 'group' }] };
  }

  /** @override */
  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    const { eventObjectId } = this.state;

    return {
      $$formatver: 1,
      messageForm: {
        name: Constants.MessageFormName.Event,
        value: { eventObjectId }
      }
    };
  }

  /** @override */
  protected async addInsertOperationsForRecipientNotification(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for recipient notification.');

    const { recipientList, sender } = this.payload;
    const { idRecord, msgMetadataId: header, msgBodyId: body, requestBody, dtServer } = this.state;

    const idSequence = Utils.makeSequence(idRecord[process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE]);

    const data: NotificationObjectIncomingMessageValue = { $$formatver: 1, header, body };
    for (const { entity } of recipientList) {
      if (entity.id === sender.id) continue;

      const { value: id } = idSequence.next();
      const notificationObject = await NotificationObjectIncomingMessage.create(id, undefined, 0, data, entity.id, sender.id, 0, dtServer);
      requestBody.insert(notificationObject);
    }
  }

  /** @override */
  protected createSentMessage(): MessageFolderListItem {
    const message = super.createSentMessage();

    const { isSenderInAttendeeList, dtServer } = this.state;
    return !isSenderInAttendeeList
      ? message
      : {
          ...message,
          timestamp: {
            ...(message.timestamp as MessageTimestampRecord),
            acceptedAt: dtServer.getTime()
          }
        };
  }

  /** @override */
  protected async sendNewMessageEmailNotifications(): Promise<ReadonlyArray<Promise<void>>> {
    this.logger.info('Sending email notifications (Inbox) to all recipients who do not have it disabled.');

    let institute: GroupObjectContactInfoValue['institute'];
    try {
      const groupContactInfo = await this.getUserObjectValue(selectGroupContactInfo, {
        type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO,
        userId: this.state.activeGroupId
      });
      institute = groupContactInfo?.institute;
    } catch {
      /* ignore */
    }

    const { accessToken, currentUser } = this.state;
    const { value: calendarEvent } = this.payload.messageBody.messageForm;

    const eventEndTime = calendarEventEndTime(calendarEvent);
    const eventStartTime = calendarEventStartTime(calendarEvent);
    const basicProfileObject = selectUserProfileBasic(this.getRootState())(/***/);
    const organizerName = selectPersonName(UserObjectCache.getValue(basicProfileObject)).fullName;

    let icsUrl = Utils.trimOrDefault(
      (
        await this.apiService
          .generateIcsUrl(accessToken, {
            calendar: {
              dateEnd: new Date(eventEndTime).toISOString(),
              dateStart: new Date(eventStartTime).toISOString(),
              location: process.env.HOST_URL,
              organizer: {
                email: 'noreply@sigmail.ca',
                name: organizerName
              },
              summary: calendarEvent.title
            },
            userId: currentUser.id
          })
          .catch((error) => {
            this.logger.warn('Error generating ICS download link:', error);
            return EMPTY_PLAIN_OBJECT as Api.GenerateIcsUrlResponseData;
          })
      ).endpoint
    );

    if (icsUrl.length === 0) {
      icsUrl = `${process.env.API_BASE_URL}api/calendar/`;
    }

    const promiseList: Array<Promise<void>> = [];
    for (const recipient of this.state.recipientList) {
      const requestBody = await this.createTemplatedEmailRequestBody(recipient, {
        [EmailTemplateParams.CalendarLink]: icsUrl,
        [EmailTemplateParams.ClinicName]: Utils.trimOrDefault(institute?.name)
      });

      if (!Utils.isNonArrayObjectLike<Api.TemplatedEmailMessage>(requestBody)) {
        continue;
      }

      promiseList.push(
        this.dispatch(
          sendTemplatedEmailMessageAction({
            ...requestBody,
            // cspell:disable-next-line
            template_id: 'd-suaeqfcgxni4iomgtjk4g10hvebpab7i'
          })
        ).catch((error) => {
          this.logger.warn('Error sending email notification:', error);
          return Promise.resolve();
        })
      );
    }

    return promiseList;
  }

  /** @override */
  protected async onBatchUpdateDataSuccess<T = void>(): Promise<T> {
    const { messageBody } = this.payload;
    const { eventObjectId, reminderListMap, isSenderInAttendeeList } = this.state;

    const dtEventStart = new Date(calendarEventStartTime(messageBody.messageForm.value));
    const flattenedReminderList = Utils.flatten(Array.from(reminderListMap.values()));
    const notificationList = flattenedReminderList.map<Api.NewNotificationRequestData['notificationList'][0]>(
      ({ id, timestamp, phoneNumber, emailAddress }) => ({
        id,
        notifyAt: new Date(timestamp).toISOString(),
        countryCode: null,
        subjectLine: 'SigMail meeting reminder',
        message: `SigMail: This is an automated reminder that you have a meeting on ${DATE_FORMATTER.format(
          dtEventStart
        )} at ${TIME_FORMATTER.format(dtEventStart)}.`,
        phoneNumber: phoneNumber!,
        emailAddress: emailAddress!
      })
    );

    await this.dispatch(newNotificationAction({ notificationList }));

    const { value: eventValue } = messageBody.messageForm;
    if (!eventValue.allDay && isSenderInAttendeeList) {
      this.dispatch(
        queueReminderNotification([
          {
            eventObjectId,
            start: eventValue.start,
            title: eventValue.title
          }
        ])
      );
    }

    return super.onBatchUpdateDataSuccess();
  }
}
