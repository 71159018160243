import { GlobalI18n } from '@sigmail/i18n';
import { WithT } from 'i18next';
import React from 'react';
import { DialogAccept, DialogReject } from '../../../constants/action-ids';
import globalI18n from '../../../i18n/global';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { DialogActionsAcceptReject } from '../../shared/dialog/actions/accept-reject.component';
import { DialogBoxProps } from '../../shared/dialog/dialog-box.component';
import style from '../app-layout.module.css';

export const DIALOG_APP_OFFLINE = 'appOffline';
export const DIALOG_BILLING_FILE: Extract<keyof GlobalI18n['form'], 'billingFile'> = 'billingFile';

export interface UseDialogPropsParams extends WithT {
  body?: DialogBoxProps['body'] | undefined;
  formId?: string | undefined;
  formLoading?: boolean | undefined;
  formSubmitting?: boolean | undefined;
  onClose?: DialogBoxProps['onClose'] | undefined;
  onDialogActionClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  open: boolean;
  openedDialog?: string | null | undefined;
}

export const useDialogProps = (params: UseDialogPropsParams) => {
  const { body, formId, formSubmitting, onClose, onDialogActionClick: onClick, open, openedDialog, t } = params;
  const isFormSubmitting = formSubmitting === true;

  return React.useMemo(() => {
    const result: DialogBoxProps = { open, onClose };
    if (open !== true) return result;

    switch (openedDialog) {
      case DIALOG_APP_OFFLINE: {
        result.BackdropProps = { style: { backgroundColor: 'rgba(255,255,255,0.75)' } };
        result.body = body;
        result.ContentProps = { className: style.content };
        result.className = style['dialog-app-offline'];
        result.disableBackdropClick = true;
        result.disableEscapeKeyDown = true;

        break;
      }
      case DIALOG_BILLING_FILE: {
        const { billingFile: i18n } = globalI18n.dialog;

        const actionLabelAccept = t(resolveActionLabel(i18n.action.accept), DialogAccept);
        const actionLabelReject = t(resolveActionLabel(i18n.action.reject), DialogReject);

        result.disableBackdropClick = true;
        result.classes = { root: style['dialog-billing-file'] };
        result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };
        result.body = (
          <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: t(i18n.body) }} />
            {body}
          </React.Fragment>
        );
        result.actions = (
          <DialogActionsAcceptReject
            AcceptActionProps={{
              children: actionLabelAccept,
              form: formId,
              disabled: isFormSubmitting,
              progress: isFormSubmitting
            }}
            classes={{ actionAccept: style['action-accept'] }}
            RejectActionProps={{ children: actionLabelReject, disabled: isFormSubmitting, onClick }}
          />
        );

        break;
      }
      default: {
        break;
      }
    }

    return result;
  }, [body, formId, isFormSubmitting, onClick, onClose, open, openedDialog, t]);
};
