import { UserObjectHealthDataValue } from '@sigmail/objects';
import { MAX_SAFE_TIMESTAMP } from '../../../../../constants';
import { Utils } from '@sigmail/common';
import { endOfDay } from 'date-fns';
import { recurringDates } from '../../../../../utils/recurring-dates';
import { LIST as WEEKDAY_FLAG_LIST } from '../../../../../constants/weekday-flag';
import { HealthDataRequestConflictException } from '../health-data-request';

interface Params {
  currentRequest: any;
  healthData: UserObjectHealthDataValue;
  onConflict?: () => void;
}

export const healthDataRequestConflictException = ({ currentRequest, healthData, onConflict }: Params) => {
  const dateList: Array<number> = [];
  let dateListInit = false;

  for (const requestId in healthData.$index) {
    const [indexOfRequest] = healthData.$index[requestId]!;

    const existingRequest = {
      ...healthData.requestList![indexOfRequest],
      until: Utils.numberOrDefault(
        healthData.requestList![indexOfRequest].until,
        Utils.isNil(healthData.requestList![indexOfRequest].frequency)
          ? endOfDay(healthData.requestList![indexOfRequest].start).getTime()
          : MAX_SAFE_TIMESTAMP
      )
    };

    // [1] this request starts after the existing request expires
    // [2] this request expires before the existing request starts
    // [3] this request is for a different form than the existing request's
    if (
      /* [1] */ currentRequest.start > existingRequest.until ||
      /* [2] */ currentRequest.until < existingRequest.start ||
      /* [3] */ currentRequest.form !== existingRequest.form
    ) {
      continue;
    }

    if (!dateListInit) {
      dateListInit = true;
      dateList.push(
        ...recurringDates(new Date(currentRequest.start), {
          frequency: currentRequest.frequency,
          max: new Date(currentRequest.until),
          weekdays: Utils.filterMap(WEEKDAY_FLAG_LIST, (flag, index) => (currentRequest.weekdays! & flag) === flag && index)
        }).map((date) => date.getTime())
      );
    }

    recurringDates(new Date(existingRequest.start), {
      frequency: existingRequest.frequency,
      max: new Date(existingRequest.until),
      min: new Date(currentRequest.start),
      weekdays: Utils.filterMap(WEEKDAY_FLAG_LIST, (flag, index) => (existingRequest.weekdays & flag) === flag && index),
      callback: (date) => {
        if (dateList.includes(date.getTime())) {
          if (Utils.isNotNil(onConflict)) onConflict();
          throw new HealthDataRequestConflictException(existingRequest);
        }
      }
    });
  }
};
