import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { CardiomyopathyQuestionnaireView } from '../../constants/action-context';

const i18n: HealthDataI18n.View.CardiomyopathyQuestionnaire = {
  action: {
    cancel: {
      // Translators: Label for the Cancel action of Cardiomyopathy \
      // Questionnaire view
      label: gettext('health-data', 'cancel', 'kccqViewActionLabel'),
      context: CardiomyopathyQuestionnaireView
    },
    submit: {
      // Translators: Label for the Submit action of Cardiomyopathy \
      // Questionnaire view
      label: gettext('health-data', 'submit', 'kccqViewActionLabel'),
      context: CardiomyopathyQuestionnaireView
    }
  },
  // Translators: (HTML) Page heading of Cardiomyopathy Questionnaire view
  heading: gettext('health-data', 'h1', 'kccqView', 'safe-html'),
  // Translators: (HTML) Lead text of Cardiomyopathy Questionnaire view
  leadText: gettext('health-data', 'leadText', 'kccqView', 'safe-html')
};

export default i18n;
