import { ApiActionPayload, StoreStateAuthorization } from '@sigmail/app-state';
import {
  AppException,
  Constants,
  EventLogRecordCodeAccountSetup,
  EventLogRecordCodeChangePassword,
  EventLogRecordCodeChangeUsername,
  EventLogRecordCodeMessageAssigned,
  EventLogRecordCodeMessageCategoryChanged,
  EventLogRecordCodeMessageForwarded,
  EventLogRecordCodeMessageMoved,
  EventLogRecordCodeMessageRecalled,
  EventLogRecordCodeMessageReceived,
  EventLogRecordCodeMessageResponded,
  EventLogRecordCodeMessageSent,
  EventLogRecordCodeMessageSentToEMR,
  EventLogRecordCodeMessageSentToHRM,
  EventLogRecordCodeNil,
  EventLogRecordCodeRevokeInvitation,
  EventLogRecordCodeSendInvitation,
  EventLogRecordCodeSessionAuth,
  EventLogRecordCodeUpdateMFA,
  EventLogRecordCodeUpdatePreference,
  EventLogRecordCodeUpdateProfile,
  PartialRecord,
  ReadonlyRecord,
  SigmailAuditId,
  SigmailClientId,
  SigmailGroupId,
  SigmailObjectId,
  SigmailOwnerId,
  SigmailSessionId,
  Utils,
  Writeable
} from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedCryptographicKey,
  ApiFormattedDataObject,
  ApiFormattedNotificationObject,
  ApiFormattedUserCredentials,
  ApiFormattedUserObject,
  EventLogRecord,
  EventLogRecordAccount,
  EventLogRecordConsultation,
  EventLogRecordMessage,
  EventLogRecordReferral,
  EventLogRecordValueMessageSentToEMR,
  IUserObject,
  SharedParamsEmailTokenUserRegistration,
  UserObject
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { NIL as NIL_UUID } from 'uuid';
import { AppDispatch } from '..';
import { HealthPlanNumber as HealthPlanNumberMask } from '../../constants/form-input-mask';
import {
  newEventLogRecordValueAccountSetup,
  NewEventLogRecordValueAccountSetupParams,
  newEventLogRecordValueCategoryChanged,
  NewEventLogRecordValueCategoryChangedParams,
  newEventLogRecordValueChangePassword,
  NewEventLogRecordValueChangePasswordParams,
  newEventLogRecordValueChangeUsername,
  NewEventLogRecordValueChangeUsernameParams,
  newEventLogRecordValueConsultation,
  NewEventLogRecordValueConsultationParams,
  newEventLogRecordValueMoveMessage,
  NewEventLogRecordValueMoveMessageParams,
  newEventLogRecordValueNil,
  NewEventLogRecordValueNilParams,
  newEventLogRecordValueRecallMessage,
  NewEventLogRecordValueRecallMessageParams,
  NewEventLogRecordValueReceivedMessageParams,
  newEventLogRecordValueReceiveMessage,
  newEventLogRecordValueReferral,
  NewEventLogRecordValueReferralParams,
  newEventLogRecordValueRevokeInvitation,
  NewEventLogRecordValueRevokeInvitationParams,
  NewEventLogRecordValueSendCaregiverInvitationParams,
  NewEventLogRecordValueSendGroupInvitationParams,
  NewEventLogRecordValueSendInvitationParams,
  newEventLogRecordValueSendMessage,
  NewEventLogRecordValueSendMessageParams,
  newEventLogRecordValueSendToHRM,
  newEventLogRecordValueSendToHRMParams,
  newEventLogRecordValueSessionAuth,
  NewEventLogRecordValueSessionAuthParams,
  newEventLogRecordValueUpdateMFA,
  NewEventLogRecordValueUpdateMfaParams,
  newEventLogRecordValueUpdatePreference,
  NewEventLogRecordValueUpdatePreferenceParams,
  newEventLogRecordValueUpdateProfile,
  NewEventLogRecordValueUpdateProfileParams
} from '../../utils/event-log';
import { generateRandomAccessCode } from '../../utils/generate-access-code';
import { generateEmailToken } from '../../utils/generate-email-token';
import { isValidSessionId } from '../../utils/is-valid-session-id';
import { RootState } from '../root-reducer';
import { activeGroupIdSelector, sessionIdSelector } from '../selectors';
import { accessTokenSelector, authClaimSelector, currentUserSelector, isUserLoggedInSelector } from '../selectors/auth';
import { globalContactListIdSelector } from '../selectors/client-object';
import { auditIdSelector, clientIdSelector, ownerIdSelector } from '../selectors/user-object';
import { batchUpdateDataAction } from './batch-update-data-action';
import { DEFAULT_ACCESS_CODE_MEMBER_REGISTRATION } from './constants';
import { fetchObjectsAction } from './fetch-objects-action';
import { getIdGeneratorAction } from './get-id-generator-action';

type EventLogRecordCodeAccount = EventLogRecordAccount['code'];
type EventLogRecordCodeConsultation = EventLogRecordConsultation['code'];

type EventLogRecordCodeSendMessage =
  | EventLogRecordCodeMessageAssigned
  | EventLogRecordCodeMessageForwarded
  | EventLogRecordCodeMessageResponded
  | EventLogRecordCodeMessageSent;

type EventLogRecordCodeMessage = EventLogRecordMessage['code'];
type EventLogRecordCodeReferral = EventLogRecordReferral['code'];
type EventLogRecordCodeSession = EventLogRecordCodeSessionAuth;

type EventLogRecordCode =
  | EventLogRecordCodeAccount
  | EventLogRecordCodeConsultation
  | EventLogRecordCodeMessage
  | EventLogRecordCodeNil
  | EventLogRecordCodeReferral
  | EventLogRecordCodeSession;

export interface FetchObjectsRequestData extends Api.BatchQueryRequestData {
  expectedCount?: PartialRecord<Exclude<keyof Api.BatchQueryRequestData, 'authState' | 'encryptedFor'>, number | null> | undefined;
}

export type FetchObjectsResponseData = Writeable<
  {
    [K in keyof Omit<Api.BatchQueryResponseData, `${string}Objects`> as K extends `${infer S}sByType`
      ? K extends 'userCredentialsByType'
        ? 'credentialList'
        : `${S}List`
      : K]-?: NonNullable<Api.BatchQueryResponseData[K]> extends ReadonlyArray<infer I> ? Array<I> : Api.BatchQueryResponseData[K];
  }
> & { dataObjectList: Array<ApiFormattedDataObject> };

export interface BaseActionState {
  accessToken: string;
  activeGroupId: SigmailGroupId;
  auditId: SigmailAuditId;
  clientId: SigmailClientId;
  currentUser: NonNullable<StoreStateAuthorization['user']>;
  globalContactListId: SigmailObjectId;
  ownerId: SigmailOwnerId;
  roleAuthClaim: string;
  sessionId: SigmailSessionId;
}

export interface ActionInitParams<P> {
  apiService?: Api.Service;
  dispatch?: AppDispatch;
  getState: () => RootState;
  logger: ReturnType<typeof getLoggerWithPrefix>;
  payload: P;
}

type UserObjectClass<T> = T extends new (arg: ApiFormattedUserObject) => UserObject<infer DV> ? IUserObject<DV> : never;

// const EVENT_LOG_TIMESTAMP_FORMATTER = new Intl.DateTimeFormat(EnglishCanada, {
//   year: 'numeric',
//   month: '2-digit',
//   day: '2-digit',
//   hour12: false,
//   hour: '2-digit',
//   minute: '2-digit',
//   second: '2-digit',
//   // @ts-expect-error
//   timeZoneName: 'longOffset'
// });

export abstract class BaseAction<P, S extends BaseActionState = BaseActionState, R = void> {
  protected readonly apiService: Api.Service;
  protected readonly dispatch: AppDispatch;
  protected readonly getRootState: () => RootState;
  protected readonly logger: ReturnType<typeof getLoggerWithPrefix>;
  protected readonly payload: P;
  protected readonly state: S;

  public constructor({ apiService, dispatch, getState, logger, payload }: ActionInitParams<P>) {
    this.apiService = apiService!;
    this.dispatch = dispatch!;
    this.getRootState = getState;
    this.logger = logger;
    this.payload = payload;

    this.state = {
      accessToken: this.accessToken,
      activeGroupId: this.activeGroupId,
      auditId: this.auditId,
      clientId: this.clientId,
      currentUser: this.currentUser,
      globalContactListId: this.globalContactListId,
      ownerId: this.ownerId,
      roleAuthClaim: this.authState,
      sessionId: this.sessionId
    } as S;

    this.onExecute = this.onExecute.bind(this);
    this.postExecute = this.postExecute.bind(this);
  }

  protected get accessToken(): string {
    return accessTokenSelector(this.getRootState());
  }

  protected get activeGroupId(): SigmailGroupId {
    return activeGroupIdSelector(this.getRootState());
  }

  protected get auditId(): SigmailAuditId {
    return auditIdSelector(this.getRootState())!;
  }

  protected get authState(): string {
    return authClaimSelector(this.getRootState());
  }

  protected get clientId(): SigmailClientId {
    return clientIdSelector(this.getRootState())!;
  }

  protected get currentUser(): NonNullable<StoreStateAuthorization['user']> {
    return currentUserSelector(this.getRootState())!;
  }

  protected get globalContactListId(): SigmailObjectId {
    return globalContactListIdSelector(this.getRootState())!;
  }

  protected get ownerId(): SigmailOwnerId {
    return ownerIdSelector(this.getRootState())!;
  }

  protected get sessionId(): SigmailSessionId {
    const sessionId = sessionIdSelector(this.getRootState());
    if (!isValidSessionId(sessionId) && sessionId !== NIL_UUID) {
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Session ID is invalid.');
    }
    return sessionId;
  }

  protected preExecute(..._args: any[]): Promise<any> {
    this.logger.info('== BEGIN ==');
    return Promise.resolve();
  }

  protected abstract onExecute(...args: any[]): Promise<R>;

  protected postExecute(..._args: any[]): Promise<void> {
    this.logger.info('== END ==');
    return Promise.resolve();
  }

  public execute(...args: any[]): Promise<R> {
    return this.preExecute(...args)
      .then(() => this.onExecute(...args))
      .catch((error) => {
        this.logger.warn('Error while executing action:', error);
        throw error;
      })
      .finally(() => this.postExecute(...args));
  }

  protected get isUserLoggedIn(): boolean {
    return isUserLoggedInSelector(this.getRootState());
  }

  // protected batchQueryData(
  //   accessToken: string,
  //   payload: Omit<ApiActionPayload.BatchQueryData, 'accessToken'>
  // ): Promise<Api.BatchQueryResponseData> {
  //   const cache = Utils.has(payload, 'cache') ? payload.cache : null;
  //   return this.dispatch(batchQueryDataAction({ ...payload, accessToken, cache }));
  // }

  protected async fetchObjects(accessToken: string, request: FetchObjectsRequestData): Promise<FetchObjectsResponseData> {
    const cache = arguments.length >= 3 ? arguments[2] : null;
    const response = await this.dispatch(
      fetchObjectsAction({
        accessToken,
        cache,
        logger: this.logger,
        ...request
      })
    );
    return response as FetchObjectsResponseData;
  }

  protected findDataObjectIndex(
    list: ReadonlyArray<ApiFormattedDataObject> | undefined,
    criteria: Partial<Pick<ApiFormattedDataObject, 'type' | 'id' | 'ownerId'>>
  ): number {
    if (!Utils.isNonEmptyArray<ApiFormattedDataObject>(list)) return -1;
    if (!Object.values(criteria).some(Utils.isInteger)) return -1;

    return list.findIndex(
      (obj) =>
        Utils.isNotNil(obj) &&
        (Utils.isUndefined(criteria.type) || obj.type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || obj.id === criteria.id) &&
        (Utils.isUndefined(criteria.ownerId) || obj.ownerId === criteria.ownerId)
    );
  }

  protected findDataObject(
    list: ReadonlyArray<ApiFormattedDataObject> | undefined,
    criteria: Partial<Pick<ApiFormattedDataObject, 'type' | 'id' | 'ownerId'>>
  ): ApiFormattedDataObject | undefined {
    const index = this.findDataObjectIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findKeyIndex(
    list: ReadonlyArray<ApiFormattedCryptographicKey> | undefined,
    criteria: Partial<Pick<ApiFormattedCryptographicKey, 'type' | 'id' | 'encryptedForId'>>
  ): number {
    if (!Utils.isNonEmptyArray<ApiFormattedCryptographicKey>(list)) return -1;
    if (!Object.values(criteria).some(Utils.isInteger)) return -1;

    return list.findIndex(
      (key) =>
        Utils.isNotNil(key) &&
        (Utils.isUndefined(criteria.type) || key.type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || key.id === criteria.id) &&
        (Utils.isUndefined(criteria.encryptedForId) || key.encryptedForId === criteria.encryptedForId)
    );
  }

  protected findKey(
    list: ReadonlyArray<ApiFormattedCryptographicKey> | undefined,
    criteria: Partial<Pick<ApiFormattedCryptographicKey, 'type' | 'id' | 'encryptedForId'>>
  ): ApiFormattedCryptographicKey | undefined {
    const index = this.findKeyIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findNotificationObjectIndex(
    list: ReadonlyArray<ApiFormattedNotificationObject> | undefined,
    criteria: Partial<Pick<ApiFormattedNotificationObject, 'type' | 'id' | 'userId'>>
  ): number {
    if (!Utils.isNonEmptyArray<ApiFormattedNotificationObject>(list)) return -1;
    if (!Object.values(criteria).some(Utils.isInteger)) return -1;

    return list.findIndex(
      (obj) =>
        Utils.isNotNil(obj) &&
        (Utils.isUndefined(criteria.type) || obj.type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || obj.id === criteria.id) &&
        (Utils.isUndefined(criteria.userId) || obj.userId === criteria.userId)
    );
  }

  protected findNotificationObject(
    list: ReadonlyArray<ApiFormattedNotificationObject> | undefined,
    criteria: Partial<Pick<ApiFormattedNotificationObject, 'type' | 'id' | 'userId'>>
  ): ApiFormattedNotificationObject | undefined {
    const index = this.findNotificationObjectIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findClaimIndex(list: ReadonlyArray<string> | undefined, criteria: ReadonlyRecord<string, unknown>): number {
    if (Utils.isNonEmptyArray<string>(list)) {
      for (let index = 0; index < list.length; index++) {
        const claim = Utils.decodeIdToken(list[index]!);
        if (Object.keys(criteria).every((key) => key in claim && claim[key] === criteria[key])) {
          return index;
        }
      }
    }
    return -1;
  }

  protected findClaim(list: ReadonlyArray<string> | undefined, criteria: ReadonlyRecord<string, unknown>): string | undefined {
    const index = this.findClaimIndex(list, criteria);
    return index > -1 ? list![index] : undefined;
  }

  protected findCredentialsIndex(
    list: ReadonlyArray<ApiFormattedUserCredentials> | undefined,
    criteria: Partial<Pick<ApiFormattedUserCredentials, 'type' | 'id' | 'userId'>>
  ): number {
    if (!Utils.isNonEmptyArray<ApiFormattedUserCredentials>(list)) return -1;
    if (!Object.values(criteria).some(Utils.isInteger)) return -1;

    return list.findIndex(
      (obj) =>
        Utils.isNotNil(obj) &&
        (Utils.isUndefined(criteria.type) || obj.type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || obj.id === criteria.id) &&
        (Utils.isUndefined(criteria.userId) || obj.userId === criteria.userId)
    );
  }

  protected findCredentials(
    list: ReadonlyArray<ApiFormattedUserCredentials> | undefined,
    criteria: Partial<Pick<ApiFormattedUserCredentials, 'type' | 'id' | 'userId'>>
  ): ApiFormattedUserCredentials | undefined {
    const index = this.findCredentialsIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findUserObjectIndex(
    list: ReadonlyArray<ApiFormattedUserObject> | undefined,
    criteria: Partial<Pick<ApiFormattedUserObject, 'type' | 'id' | 'userId'>>
  ): number {
    if (!Utils.isNonEmptyArray<ApiFormattedUserObject>(list)) return -1;
    if (!Object.values(criteria).some(Utils.isInteger)) return -1;

    return list.findIndex(
      (obj) =>
        Utils.isNotNil(obj) &&
        (Utils.isUndefined(criteria.type) || obj.type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || obj.id === criteria.id) &&
        (Utils.isUndefined(criteria.userId) || obj.userId === criteria.userId)
    );
  }

  protected findUserObject(
    list: ReadonlyArray<ApiFormattedUserObject> | undefined,
    criteria: Partial<Pick<ApiFormattedUserObject, 'type' | 'id' | 'userId'>>
  ): ApiFormattedUserObject | undefined {
    const index = this.findUserObjectIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findAndCreateUserObject<T extends { new (arg: ApiFormattedUserObject): any; readonly TYPE: number }>(
    list: ReadonlyArray<ApiFormattedUserObject> | undefined,
    Class: T,
    criteria: Omit<Parameters<typeof this.findUserObjectIndex>[1], 'type'>
  ): UserObjectClass<T> | undefined {
    const index = this.findUserObjectIndex(list, { ...criteria, type: Class.TYPE });
    return index > -1 ? new Class(list![index]) : undefined;
  }

  protected batchUpdateData(
    accessToken: Required<ApiActionPayload.BatchUpdateData>['accessToken'],
    mutations: ApiActionPayload.BatchUpdateData['mutations']
  ): Promise<Api.BatchUpdateResponseData> {
    return this.dispatch(batchUpdateDataAction({ accessToken, mutations }));
  }

  protected async fetchServerDateAndTime(accessToken: string, authState: string): Promise<Date> {
    this.logger.info("Fetching API server's current date and time.");

    // const { serverDateTime } = await this.batchQueryData(accessToken, { query: { authState }, cache: null });
    const { serverDateTime } = await this.fetchObjects(accessToken, { authState });
    return this.deserializeServerDateTime(serverDateTime);
  }

  protected deserializeServerDateTime(serverDateTime: any): Date {
    if (Utils.isString(serverDateTime)) {
      const dtServer = new Date(serverDateTime);
      if (Utils.isValidDate(dtServer)) {
        return dtServer;
      }
    }
    this.logger.warn('Server date-time was either missing or invalid.');
    return new Date();
  }

  protected fetchIds(accessToken: string, count: number): Promise<Generator<number, number>> {
    this.logger.info(`Fetching a list of IDs. (count = ${count})`);

    return this.dispatch(getIdGeneratorAction({ accessToken, count }));
  }

  protected fetchIdsByUsage(accessToken: string, request: Api.GetIdsRequestData): Promise<Api.GetIdsResponseData> {
    const count =
      Utils.isNonArrayObjectLike(request) &&
      Utils.isNonArrayObjectLike(request.ids) &&
      (Utils.isArray(request.ids.ids) ? request.ids.ids : []).reduce(
        (count, id) => count + (Utils.isNonArrayObjectLike(id) && Utils.isInteger(id.count) && id.count > 0 ? id.count : 1),
        Utils.isArray(request.ids.usages) ? request.ids.usages.length : 0
      );

    this.logger.info(`Fetching a list of IDs. (Count = ${count})`);
    return this.apiService.getIdsByUsage(accessToken, request);
  }

  protected enterState(accessToken: string, request: Api.EnterStateRequestData): Promise<Api.EnterStateResponseData> {
    return this.apiService.enterState(accessToken, request);
  }

  protected generateUserRegistrationSharedParameters(roleId: string): SharedParamsEmailTokenUserRegistration {
    const isUserRoleCaregiver = Utils.isCaregiverRole(roleId);
    const isUserRoleGuest = !isUserRoleCaregiver && Utils.isGuestRole(roleId);
    const isUserRoleNonGuest = !isUserRoleCaregiver && !isUserRoleGuest && Utils.isNonGuestRole(roleId);

    return {
      response: 'userRegistration',
      caregiver: isUserRoleCaregiver === true ? true : undefined,
      salt: Utils.generateSalt('hex'),
      nvac: isUserRoleNonGuest, // no verification for access code
      nphi: isUserRoleNonGuest // no patient health information consent
    };
  }

  protected generateUserRegistrationEmailToken(sharedParameters: SharedParamsEmailTokenUserRegistration): string {
    return generateEmailToken(sharedParameters);
  }

  protected generateUserRegistrationAccessCode(
    roleId: string,
    healthPlanJurisdiction?: string,
    healthPlanNumber?: string
  ): [accessCode: string, isRandom: boolean] {
    let accessCode = DEFAULT_ACCESS_CODE_MEMBER_REGISTRATION;
    let isRandom = false;

    if (Utils.isGuestRole(roleId) || Utils.isCaregiverRole(roleId)) {
      accessCode = generateRandomAccessCode().join('');
      isRandom = true;

      if (Utils.isString(healthPlanJurisdiction) && /^[A-Z][A-Z][A-Z]\$[A-Z]+$/.test(healthPlanJurisdiction)) {
        const [countryCode, stateCode] = healthPlanJurisdiction.split('$', 2);

        if (Utils.isString(healthPlanNumber) && Utils.isArray(HealthPlanNumberMask[countryCode]?.[stateCode])) {
          const planNumberDigits = healthPlanNumber.replaceAll(/\D/gu, '');
          if (planNumberDigits.length >= 6) {
            accessCode = planNumberDigits.slice(-6);
            isRandom = false;
          }
        }
      }
    }

    return [accessCode, isRandom];
  }

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeConsultation,
    ...args: NewEventLogRecordValueConsultationParams
  ): EventLogRecord;

  protected newEventLogRecordValue(timestamp: Date, code: EventLogRecordCodeNil, ...args: NewEventLogRecordValueNilParams): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeAccountSetup,
    ...args: NewEventLogRecordValueAccountSetupParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeChangePassword,
    ...args: NewEventLogRecordValueChangePasswordParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeChangeUsername,
    ...args: NewEventLogRecordValueChangeUsernameParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeMessageCategoryChanged,
    ...args: NewEventLogRecordValueCategoryChangedParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeMessageMoved,
    ...args: NewEventLogRecordValueMoveMessageParams
  ): EventLogRecord;

  protected newEventLogRecordValue(timestamp: Date, code: EventLogRecordCodeNil, ...args: NewEventLogRecordValueNilParams): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeMessageRecalled,
    ...args: NewEventLogRecordValueRecallMessageParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeMessageReceived,
    ...args: NewEventLogRecordValueReceivedMessageParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeRevokeInvitation,
    ...args: NewEventLogRecordValueRevokeInvitationParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeSendInvitation,
    ...args: NewEventLogRecordValueSendCaregiverInvitationParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeSendInvitation,
    ...args: NewEventLogRecordValueSendGroupInvitationParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeSendInvitation,
    ...args: NewEventLogRecordValueSendInvitationParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeSendMessage,
    ...args: NewEventLogRecordValueSendMessageParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeReferral,
    ...args: NewEventLogRecordValueReferralParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeMessageSentToEMR,
    ...args: [record: EventLogRecordValueMessageSentToEMR]
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeMessageSentToHRM,
    ...args: newEventLogRecordValueSendToHRMParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeSession,
    ...args: [value: Omit<NewEventLogRecordValueSessionAuthParams[0], 'aid' | 'cid' | 'oid' | 'ua' | 'uid'>]
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeUpdateMFA,
    ...args: NewEventLogRecordValueUpdateMfaParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeUpdatePreference,
    ...args: NewEventLogRecordValueUpdatePreferenceParams
  ): EventLogRecord;

  protected newEventLogRecordValue(
    timestamp: Date,
    code: EventLogRecordCodeUpdateProfile,
    ...args: NewEventLogRecordValueUpdateProfileParams
  ): EventLogRecord;

  protected newEventLogRecordValue(timestamp: Date, code: EventLogRecordCode, ...args: Array<unknown>): EventLogRecord {
    const { activeGroupId: gid, auditId: aid, clientId: cid, ownerId: oid, sessionId: sid } = this.state;
    const uid = this.state.currentUser?.id;

    const logRecord = { code, gid, sid, ts: timestamp.toISOString(), uid: String(uid) } as Writeable<EventLogRecord>;

    if (Constants.EventLogCode.isNil(code)) {
      const params = (args as unknown) as NewEventLogRecordValueNilParams;
      logRecord.value = /*#__NOINLINE__*/ newEventLogRecordValueNil(...params);
      return logRecord;
    }

    switch (code) {
      case Constants.EventLogCode.AccountSetup: {
        const params = (args as unknown) as NewEventLogRecordValueAccountSetupParams;
        logRecord.value = newEventLogRecordValueAccountSetup(...params);
        break;
      }
      case Constants.EventLogCode.ChangePassword: {
        const params = (args as unknown) as NewEventLogRecordValueChangePasswordParams;
        logRecord.value = newEventLogRecordValueChangePassword(...params);
        break;
      }
      case Constants.EventLogCode.ChangeUsername: {
        const params = (args as unknown) as NewEventLogRecordValueChangeUsernameParams;
        logRecord.value = newEventLogRecordValueChangeUsername(...params);
        break;
      }
      case Constants.EventLogCode.ConsultationReceived:
      case Constants.EventLogCode.ConsultationResponded:
      case Constants.EventLogCode.ConsultationSent: {
        const params = (args as unknown) as NewEventLogRecordValueConsultationParams;
        logRecord.value = newEventLogRecordValueConsultation(...params);
        break;
      }
      case Constants.EventLogCode.MessageAssigned:
      case Constants.EventLogCode.MessageForwarded:
      case Constants.EventLogCode.MessageResponded:
      case Constants.EventLogCode.MessageSent: {
        const params = (args as unknown) as NewEventLogRecordValueSendMessageParams;
        logRecord.value = newEventLogRecordValueSendMessage(code, ...params);
        break;
      }
      case Constants.EventLogCode.MessageCategoryChanged: {
        const params = (args as unknown) as NewEventLogRecordValueCategoryChangedParams;
        logRecord.value = newEventLogRecordValueCategoryChanged(...params);
        break;
      }
      case Constants.EventLogCode.MessageMoved: {
        const params = (args as unknown) as NewEventLogRecordValueMoveMessageParams;
        logRecord.value = newEventLogRecordValueMoveMessage(...params);
        break;
      }
      case Constants.EventLogCode.MessageRecalled: {
        const params = (args as unknown) as NewEventLogRecordValueRecallMessageParams;
        logRecord.value = newEventLogRecordValueRecallMessage(...params);
        break;
      }
      case Constants.EventLogCode.MessageReceived: {
        const params = (args as unknown) as NewEventLogRecordValueReceivedMessageParams;
        logRecord.value = newEventLogRecordValueReceiveMessage(...params);
        break;
      }
      case Constants.EventLogCode.MessageSentToEMR: {
        logRecord.value = args[0] as EventLogRecordValueMessageSentToEMR;
        break;
      }
      case Constants.EventLogCode.MessageSentToHRM: {
        const params = (args as unknown) as newEventLogRecordValueSendToHRMParams;
        logRecord.value = newEventLogRecordValueSendToHRM(...params);
        break;
      }
      case Constants.EventLogCode.ReferralAccepted:
      case Constants.EventLogCode.ReferralDeclined:
      case Constants.EventLogCode.ReferralReceived:
      case Constants.EventLogCode.ReferralSent: {
        const params = (args as unknown) as NewEventLogRecordValueReferralParams;
        logRecord.value = newEventLogRecordValueReferral(...params);
        break;
      }
      case Constants.EventLogCode.SessionAuth: {
        const params = (args as unknown) as NewEventLogRecordValueSessionAuthParams;
        logRecord.value = { ...newEventLogRecordValueSessionAuth(...params), aid, cid, oid, uid };
        break;
      }
      case Constants.EventLogCode.RevokeInvitation: {
        const params = (args as unknown) as NewEventLogRecordValueRevokeInvitationParams;
        logRecord.value = newEventLogRecordValueRevokeInvitation(...params);
        break;
      }
      case Constants.EventLogCode.UpdateMFA: {
        const params = (args as unknown) as NewEventLogRecordValueUpdateMfaParams;
        logRecord.value = newEventLogRecordValueUpdateMFA(...params);
        break;
      }
      case Constants.EventLogCode.UpdatePreference: {
        const params = (args as unknown) as NewEventLogRecordValueUpdatePreferenceParams;
        logRecord.value = newEventLogRecordValueUpdatePreference(...params);
        break;
      }
      case Constants.EventLogCode.UpdateProfile: {
        const params = (args as unknown) as NewEventLogRecordValueUpdateProfileParams;
        logRecord.value = newEventLogRecordValueUpdateProfile(...params);
        break;
      }
      default: {
        throw new Error(`Unhandled case - ${code}.`);
      }
    }

    return logRecord;
  }
}
