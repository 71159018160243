import { SchedulingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogViewEvent } from '../../constants/action-context';

const i18n: SchedulingI18n.Dialog.ViewEvent = {
  // Translators: (HTML) Title for the View Event dialog
  title: gettext('scheduling', 'viewEventDialog', 'title', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel Dialog action
      label: gettext('scheduling', 'viewEventDialog', 'actionLabelReject'),
      context: DialogViewEvent
    },
    dismiss: {
      // Translators: Label for the Close Dialog action
      label: gettext('scheduling', 'viewEventDialog', 'actionLabelDismiss'),
      context: DialogViewEvent
    },
    accept: {
      // Translators: Label for the Submit dialog action
      label: gettext('scheduling', 'viewEventDialog', 'actionLabelAccept'),
      context: DialogViewEvent
    },
    acceptEvent: {
      // Translators: Label for the Accept Event action
      label: gettext('scheduling', 'viewEventDialog', 'actionLabelAcceptEvent'),
      context: DialogViewEvent
    },
    declineEvent: {
      // Translators: Label for the Decline Event action
      label: gettext('scheduling', 'viewEventDialog', 'actionLabelDeclineEvent'),
      context: DialogViewEvent
    },
    joinEvent: {
      // Translators: Label for the Join Event action
      label: gettext('scheduling', 'viewEventDialog', 'actionLabelJoinEvent'),
      context: DialogViewEvent
    },
    recallEvent: {
      // Translators: Label for the Recall Event dialog action
      label: gettext('scheduling', 'viewEventDialog', 'actionLabelRecallEvent'),
      context: DialogViewEvent
    },
    removeEvent: {
      // Translators: Label for the Remove From Calendar dialog action
      label: gettext('scheduling', 'viewEventDialog', 'actionLabelRemoveEvent'),
      context: DialogViewEvent
    }
  }
};

export default i18n;
