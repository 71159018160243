import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogEditProfile, DialogEditProfileSignIn, DialogVersionConflict } from '../../constants/action-context';

const i18n: ContactListI18n.View.ContactListView['dialog']['editGuestProfile'] = {
  action: {
    editProfile: {
      accept: {
        context: DialogEditProfile,
        label: gettext('contact-list', 'accept', 'editGuestProfileDialogActionLabel')
      },
      reject: {
        context: DialogEditProfile,
        label: gettext('contact-list', 'reject', 'editGuestProfileDialogActionLabel')
      }
    },
    signIn: {
      accept: {
        context: DialogEditProfileSignIn,
        label: gettext('contact-list', 'acceptSignIn', 'editGuestProfileDialogActionLabel')
      },
      reject: {
        context: DialogEditProfileSignIn,
        label: gettext('contact-list', 'rejectSignIn', 'editGuestProfileDialogActionLabel')
      }
    },
    versionConflict: {
      accept: {
        context: DialogVersionConflict,
        label: gettext('contact-list', 'acceptVersionConflict', 'editGuestProfileDialogActionLabel')
      }
    }
  },
  body: {
    signIn: gettext('contact-list', 'signIn', 'editGuestProfileDialogBody', 'safe-html'),
    versionConflict: gettext('contact-list', 'versionConflict', 'editGuestProfileDialogBody', 'safe-html')
  },
  title: {
    editProfile: gettext('contact-list', 'profile', 'editGuestProfileDialogTitle', 'safe-html'),
    signIn: gettext('contact-list', 'signIn', 'editGuestProfileDialogTitle', 'safe-html'),
    versionConflict: gettext('contact-list', 'versionConflict', 'editGuestProfileDialogTitle', 'safe-html')
  }
};

export default i18n;
