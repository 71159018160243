import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogConfirmCloseUnsentMessage } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.Confirmation.CloseUnsentMessage = {
  // Translators: (HTML) Title for the Confirm Close Unsent Message dialog
  title: gettext('messaging', 'title', 'dialogConfirmCloseUnsentMessage', 'safe-html'),
  // Translators: (HTML) Confirmation message to be displayed in the body of \
  // Confirm Close Unsent Message dialog
  body: gettext('messaging', 'body', 'dialogConfirmCloseUnsentMessage', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Close Message action of Confirm Close \
      // Unsent Message dialog
      label: gettext('messaging', 'reject', 'dialogConfirmCloseUnsentMessageActionLabel'),
      context: DialogConfirmCloseUnsentMessage
    },
    accept: {
      // Translators: Label for the Keep Editing action of Confirm Close \
      // Unsent Message dialog
      label: gettext('messaging', 'accept', 'dialogConfirmCloseUnsentMessageActionLabel'),
      context: DialogConfirmCloseUnsentMessage
    },
    saveAsDraft: {
      // Translators: Label for the Save As Draft action of Confirm Close \
      // Unsent Message dialog
      label: gettext('messaging', 'saveAsDraft', 'dialogConfirmCloseUnsentMessageActionLabel'),
      context: DialogConfirmCloseUnsentMessage
    }
  }
};

export default i18n;
