import { Nullable, Utils } from '@sigmail/common';

interface AccuroEMRBirthdayRecord {
  readonly birthday?: Nullable<string>;
}

export const selectBirthDate = (record: Nullable<AccuroEMRBirthdayRecord>): Date | null => {
  const birthday = Utils.trimOrDefault(Utils.isNonArrayObjectLike<AccuroEMRBirthdayRecord>(record) && record.birthday);
  const dtBirth: Date | null = Utils.dateOrDefault(birthday.length > 0 && new Date(birthday), null!);
  return Utils.isValidDate(dtBirth) ? dtBirth : null;
};
