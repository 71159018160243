import { Utils } from '@sigmail/common';

export async function getPushSubscription(): Promise<PushSubscription | null> {
  let subscription: PushSubscription | null = null;
  try {
    if ('serviceWorker' in window.navigator) {
      const swReg = await window.navigator.serviceWorker.getRegistration();
      if (Utils.isNotNil(swReg) && 'pushManager' in swReg) {
        subscription = await swReg.pushManager.getSubscription();
      }
    }
  } catch {
    /* ignore */
  }
  return subscription;
}
