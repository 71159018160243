import { Nullable, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';

type AccuroEMRHealthCardRecord = Pick<NonNullable<Api.AccuroEMRPatientRecord['demographics']>, 'healthCard'>;

export const selectHealthPlanJurisdiction = (record: Nullable<AccuroEMRHealthCardRecord>): string | null =>
  Utils.isNonArrayObjectLike<AccuroEMRHealthCardRecord>(record) &&
  Utils.isNonArrayObjectLike<Pick<NonNullable<AccuroEMRHealthCardRecord['healthCard']>, 'location'>>(
    record.healthCard
  ) &&
  Utils.isString(record.healthCard.location)
    ? record.healthCard?.location
    : null;
