import { GroupsI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';

const i18n: GroupsI18n.Dialog.InviteUsersToGroup = {
  // Translators: (HTML) Title for the Invite Users To Group dialog
  title: gettext('groups', 'title', 'inviteUsersToGroupDialog', 'safe-html'),
  // Translators: (HTML) Body for the Invite Users To Group dialog
  body: gettext('groups', 'body', 'inviteUsersToGroupDialog', 'safe-html'),
  note: {
    // Translators: (HTML) A general note in Invite Users To Group dialog \
    // briefly explaining the flow of invitation to join a group
    general: ngettext('groups', 'noteGeneral', 'inviteUsersToGroupDialog', 'safe-html'),
    // Translators: (HTML) Note in Invite Users To Group dialog providing \
    // details on how long the invitation will be valid for
    // Translators: Token(s) available: {{count}}
    expiry: ngettext('groups', 'noteExpiry', 'inviteUsersToGroupDialog', 'safe-html')
  },
  action: {
    reject: {
      // Translators: Label for the Cancel action of Invite Users To Group \
      // dialog
      label: gettext('groups', 'reject', 'inviteUsersToGroupDialogActionLabel')
    },
    accept: {
      // Translators: Label for the Submit action of Invite Users To Group \
      // dialog
      label: gettext('groups', 'accept', 'inviteUsersToGroupDialogActionLabel')
    },
    dismiss: {
      // Translators: Label for the Close action of Invite Users To Group \
      // dialog
      label: gettext('groups', 'dismiss', 'inviteUsersToGroupDialogActionLabel')
    }
  },
  // Translators: Error message displayed when the operation fails for one or \
  // more contacts
  submitError: ngettext('groups', 'submitError', 'inviteUsersToGroupDialog')
};

export default i18n;
