import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '../../..';
import { accessTokenSelector } from '../../../selectors/auth';

export const accuroEMRProviderFolderList = (
  payload: ApiActionPayload.AccuroEMRProviderFolderList
): AppThunk<Promise<Api.AccuroEMRProviderFolderListResponse>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'accuroEMRProviderFolderList:');

    Logger.info('== BEGIN ==');
    try {
      const apiAccessToken = Utils.stringOrDefault(payload.accessToken, accessTokenSelector(getState()));
      return await apiService.accuroEMRProviderFolderList(apiAccessToken, payload);
    } finally {
      Logger.info('== END ==');
    }
  };
};
