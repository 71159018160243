import { AppUser, Nullable, Utils } from '@sigmail/common';
import { ContactListItemExt, GroupContactListItem } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { CIRCLE_OF_CARE } from '../../constants/medical-institute-user-group-type-identifier';
import {
  BatchQueryRequest,
  compareContactListItem,
  ExcludeListItem,
  filterContactList,
  FilterFunction
} from '../../utils/contact-list';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { currentUserIdSelector } from '../selectors/auth';
import { basicProfileObjectSelector, membershipByGroupTypeSelector } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';
import { useClientContactList } from './use-client-contact-list';
import { useGlobalContactList } from './use-global-contact-list';

export interface UseMembershipGroupContactListParams {
  exclude?: Nullable<ReadonlyArray<Nullable<ExcludeListItem>>>;
  filterFn?: Nullable<FilterFunction<ContactListItemExt>>;
  forceFetch?: Nullable<boolean>;
  groupType?: Nullable<string>;
  unsorted?: Nullable<boolean>;
}

export type UseMembershipGroupContactListResult = [list: ReadonlyArray<GroupContactListItem>, query: BatchQueryRequest];

export const useMembershipGroupContactList = (params?: Nullable<UseMembershipGroupContactListParams>) => {
  const currentUserId = useSelector(currentUserIdSelector);
  const basicProfileObject = useSelector(basicProfileObjectSelector)(/***/);
  const basicProfile = UserObjectCache.getValue(basicProfileObject);
  const isUserRolePhysician = Utils.isPhysicianRole(basicProfile?.role);

  const isGroupTypeCircleOfCare = params?.groupType === CIRCLE_OF_CARE;
  const includeGlobalContactList = isGroupTypeCircleOfCare && isUserRolePhysician;
  const includeClientContactList = isGroupTypeCircleOfCare && !isUserRolePhysician;
  const exclude = params?.exclude;
  const filterFn = typeof params?.filterFn === 'function' ? params!.filterFn! : filterContactList;
  const forceFetch = params?.forceFetch === true;
  const unsorted = params?.unsorted === true;

  const globalContactList = useGlobalContactList({ forceFetch, groups: includeGlobalContactList });
  const clientContactList = useClientContactList({ forceFetch, groups: includeClientContactList });
  const membershipGroupList = useSelector(membershipByGroupTypeSelector);

  return React.useMemo((): UseMembershipGroupContactListResult => {
    let contactList: ReadonlyArray<GroupContactListItem> = EMPTY_ARRAY;
    const dataObjects: Required<BatchQueryRequest>['dataObjects'] = { ids: [] };
    const userObjects: Required<BatchQueryRequest>['userObjectsByType'] = [];

    do {
      if (!includeGlobalContactList && !includeClientContactList) break;
      if (!AppUser.isValidId(currentUserId)) break;
      if (dataObjects.ids.length > 0 || userObjects.length > 0) break;

      let groupContactList: UseMembershipGroupContactListResult[0] = EMPTY_ARRAY;
      if (includeGlobalContactList) {
        const [contactListGlobal, query] = globalContactList as UseMembershipGroupContactListResult;
        groupContactList = contactListGlobal;
        if (Utils.isNonEmptyArray(query.dataObjects?.ids)) dataObjects.ids = query.dataObjects!.ids;
        if (Utils.isNonEmptyArray(query.userObjectsByType)) userObjects.push(...query.userObjectsByType!);
      } else if (includeClientContactList) {
        const [contactListClient, query] = clientContactList as UseMembershipGroupContactListResult;
        groupContactList = contactListClient;
        if (Utils.isNonEmptyArray(query.dataObjects?.ids)) dataObjects.ids = query.dataObjects!.ids;
        if (Utils.isNonEmptyArray(query.userObjectsByType)) userObjects.push(...query.userObjectsByType!);
      }

      if (Utils.isNil(basicProfileObject)) {
        userObjects.push({ type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId: currentUserId });
      }

      if (dataObjects.ids.length > 0 || userObjects.length > 0) break;

      const memberOf = membershipGroupList(Utils.stringOrDefault(isGroupTypeCircleOfCare && CIRCLE_OF_CARE));
      contactList = memberOf.map<GroupContactListItem>(({ groupType, id }) => {
        const contact = groupContactList.find((group) => group.groupType === groupType && group.id === id);
        if (Utils.isNil(contact)) {
          if (process.env.REACT_APP_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.warn(`Group <ID=${id}> could not be found in group contact list.`);
          }
          return { type: 'group', id, groupType, groupData: { groupName: '' } };
        }
        return contact;
      });

      contactList = filterFn(contactList, { exclude }) as any;

      if (!unsorted && contactList.length > 1) {
        contactList = contactList.slice().sort(compareContactListItem);
      }
    } while (false);

    let query: UseMembershipGroupContactListResult[1] = EMPTY_PLAIN_OBJECT;
    if (dataObjects.ids.length > 0 || userObjects.length > 0) {
      query = { dataObjects, userObjectsByType: userObjects };
    }

    return [contactList, query];
  }, [
    basicProfileObject,
    clientContactList,
    currentUserId,
    exclude,
    filterFn,
    globalContactList,
    includeClientContactList,
    includeGlobalContactList,
    isGroupTypeCircleOfCare,
    membershipGroupList,
    unsorted
  ]);
};
