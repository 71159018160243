import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectServerRightsValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_SERVER_RIGHTS;

/** @public */
export class UserObjectServerRights extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isValidJwtToken(dv.userClaim, 'id');
  }

  public override updateValue(newValue: DV): Promise<UserObjectServerRights> {
    const Class = this.constructor as typeof UserObjectServerRights;
    return Class.updateValue(this, newValue);
  }
}
