import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Dialog.VerifyIdentity = {
  action: {
    accept: {
      // Translators: Label for the Accept action of Verify Identity dialog
      label: gettext('account', 'accept', 'verifyIdentityDialogActionLabel')
    },
    reject: {
      // Translators: Label for the Reject action of Verify Identity dialog
      label: gettext('account', 'reject', 'verifyIdentityDialogActionLabel')
    }
  },
  // Translators: (HTML) Title for the Verify Identity dialog
  title: gettext('account', 'title', 'verifyIdentityDialog', 'safe-html')
};

export default i18n;
