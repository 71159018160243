import { Utils } from '@sigmail/common';
import { ContactListItem } from '@sigmail/objects';
import { ExcludeListItem } from './types';

const DEFAULT_EXCLUDE_LIST: ReadonlyArray<ExcludeListItem> = [];

export interface FilterContactListParams {
  exclude?: ReadonlyArray<ExcludeListItem | null | undefined> | null | undefined;
}

export const filterContactList = <T extends ContactListItem = ContactListItem>(
  contactList: ReadonlyArray<T>,
  params?: FilterContactListParams | null | undefined
) => {
  const exclude = Utils.isNonEmptyArray<ExcludeListItem>(params?.exclude) ? params!.exclude! : DEFAULT_EXCLUDE_LIST;

  if (contactList.length > 0 && exclude.length > 0) {
    const excludeList: Array<number> = [];
    for (const item of exclude) {
      if (Utils.isNonArrayObjectLike<typeof item>(item)) {
        const index = contactList.findIndex(
          ({ type, id }) => (Utils.isNil(item.type) || type === item.type) && (Utils.isNil(item.id) || id === item.id)
        );
        if (index > -1) excludeList.push(index);
      }
    }

    if (excludeList.length > 0) {
      return contactList.filter((_, index) => excludeList.indexOf(index) === -1);
    }
  }

  return contactList;
};
