import { AllDayCalendarEvent, CalendarEvent, ReadonlyCalendarEventWithProps } from '@sigmail/common';
import React from 'react';
import { LocalAudioTrack, LocalParticipant, LocalVideoTrack, Participant, RemoteParticipant, Room } from 'twilio-video';

export interface ConnectionState {
  readonly isConnecting: boolean;
  readonly calendarEvent:
    | ReadonlyCalendarEventWithProps<CalendarEvent>
    | ReadonlyCalendarEventWithProps<AllDayCalendarEvent>
    | null;
  readonly room: Room | null;
  readonly localTracks: ReadonlyArray<LocalAudioTrack | LocalVideoTrack> | null;
  readonly mainParticipant: Participant | null;
  readonly localParticipant: LocalParticipant | null;
  readonly remoteParticipantList: ReadonlyArray<RemoteParticipant> | null;
  readonly dominantSpeaker: RemoteParticipant | null;
  readonly isLocalAudioEnabled: boolean | null; // null indicates that no audio input device is available
  readonly isLocalVideoEnabled: boolean | null; // null indicates that no video input device is available
}

export interface ContextValue extends ConnectionState {
  readonly connect: () => Promise<void>;
  readonly disconnect: () => void;
  readonly toggleAudioEnabled: () => void;
  readonly toggleVideoEnabled: () => void;

  readonly findLocalAudioTrack: () => LocalAudioTrack | undefined;
  readonly findLocalVideoTrack: () => LocalVideoTrack | undefined;
}

export const Context = React.createContext<ContextValue>(null!);
Context.displayName = 'MeetingRoomContext';
