import { Utils } from '@sigmail/common';
import { selectBirthDate } from '.';

export const selectFormattedBirthDate = (...args: Parameters<typeof selectBirthDate>): string => {
  const value = selectBirthDate(...args);
  if (!Utils.isValidDate(value)) return '';

  const yyyy = value.getFullYear();
  const mm = (value.getMonth() + 1).toString(10).padStart(2, '0');
  const dd = value.getDate().toString(10).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};
