import { AppException } from './app-exception';
import { AppUserOrUserGroup } from './app-user-or-group';
import { E_INVALID_GROUP_TYPE } from './constants/error';
import type { IAppUserGroup, IAppUserOrUserGroup, SigmailGroupId } from './types';
import { every, has, hashString, partial, trimOrDefault } from './utils';

const OWN_PROPS: ReadonlyArray<keyof Omit<IAppUserGroup, keyof IAppUserOrUserGroup>> = ['groupType'];

/** @public */
export class AppUserGroup extends AppUserOrUserGroup<'group'> implements IAppUserGroup {
  public static override isValidType(value: unknown): value is 'group' {
    return value === 'group';
  }

  public static isValidGroupType(value: unknown): value is string {
    return trimOrDefault(value).length > 0;
  }

  public static override isAssignableFrom(obj: unknown): obj is IAppUserGroup {
    return (
      super.isAssignableFrom(obj) &&
      every(OWN_PROPS, partial(has, obj)) &&
      this.isValidGroupType((obj as IAppUserGroup).groupType)
    );
  }

  public readonly groupType: string;

  public constructor(id: SigmailGroupId, groupType: string);
  public constructor(...args: Array<unknown>);

  public constructor(...args: Array<unknown>) {
    super(...(args.length === 1 ? args : ['group', ...args]));

    const Class = this.constructor as typeof AppUserGroup;

    const groupType = args.length === 1 ? (args[0] as IAppUserGroup).groupType : args[1];
    if (!Class.isValidGroupType(groupType)) throw new AppException(E_INVALID_GROUP_TYPE);

    this.groupType = groupType.trim();
    Object.freeze(this);
  }

  /** @override */
  public override equals(other: unknown): other is IAppUserGroup {
    return super.equals(other) && this.groupType === (other as IAppUserGroup).groupType;
  }

  /** @override */
  public override hashCode(): number {
    const hashCode = super.hashCode();
    return (31 * hashCode + hashString(this.groupType)) | 0;
  }
}
