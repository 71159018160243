import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.Form.ComposeMessage = {
  fieldsetRecipients: {
    formField: {
      primary: {
        // Translators: Label for the Primary Recipients input
        label: gettext('messaging', 'primaryRecipients', 'composeMessageFormInputLabel'),
        // Translators: Placeholder for the Primary Recipients input
        placeholder: gettext('messaging', 'primaryRecipients', 'composeMessageFormInputHint'),
        // Translators: Alternate placeholder for the Primary Recipients input for users who can send one-time message
        placeholderAlt: gettext('messaging', 'toNameGroupOrEmail', 'composeMessageFormInputHint'),
        error: {
          // Translators: Error message to inform user that a message marked \
          // as billable may have no more than one recipient in TO list and \
          // that too a guest contact
          billableToGuestContactOnly: gettext('messaging', 'billableToGuestContactOnly', 'composeMessageFormError'),
          // Translators: Error message to inform user that no other contacts \
          // may be present in the recipient list when one of them is a guest \
          // contact group
          guestContactGroup: gettext('messaging', 'guestContactGroup', 'composeMessageFormError'),
          // Translators: Error message displayed when more than one guest \
          // contact entry is present in TO and CC list combined
          maxOneGuestContact: gettext('messaging', 'maxOneGuestContact', 'composeMessageFormError'),
          // Translators: Error message to inform user that a message marked \
          // as private may have no more than one recipient in TO and CC list \
          // combined and that too a guest contact
          privateMsgToGuestOnly: gettext('messaging', 'privateMsgToGuestOnly', 'composeMessageFormError'),
          // Translators: Error message displayed when recipient list is empty
          tooShort: gettext('messaging', 'recipientListTooShort', 'composeMessageFormError')
        },
        localeText: {
          // Translators: Label for the Close Popup icon of Primary \
          // Recipients input
          closePopup: gettext('messaging', 'closePrimaryRecipientsPopup', 'composeMessageFormLocaleText'),
          // Translators: Text to display when there are no contacts to show \
          // in Primary Recipients input
          noOptions: gettext('messaging', 'noPrimaryRecipients', 'composeMessageFormLocaleText'),
          // Translators: Label for the Open Popup icon of Primary Recipients \
          // input
          openPopup: gettext('messaging', 'openPrimaryRecipientsPopup', 'composeMessageFormLocaleText')
        }
      },
      secondary: {
        // Translators: Label for the Secondary Recipients input
        label: gettext('messaging', 'secondaryRecipients', 'composeMessageFormInputLabel'),
        // Translators: Placeholder for the Secondary Recipients input
        placeholder: gettext('messaging', 'secondaryRecipients', 'composeMessageFormInputHint'),
        // Translators: Alternate placeholder for the Secondary Recipients input for users who can send one-time message
        placeholderAlt: gettext('messaging', 'ccNameGroupOrEmail', 'composeMessageFormInputHint'),
        error: {
          // Translators: Error message to inform user that no other contacts \
          // may be present in the recipient list when one of them is a guest \
          // contact group
          guestContactGroup: gettext('messaging', 'guestContactGroup', 'composeMessageFormError'),
          // Translators: Error message displayed when more than one guest \
          // contact entry is present in TO and CC list combined
          maxOneGuestContact: gettext('messaging', 'maxOneGuestContact', 'composeMessageFormError'),
          // Translators: Error message to inform user that a message marked \
          // as private may have no more than one recipient in TO and CC list \
          // combined and that too a guest contact
          privateMsgToGuestOnly: gettext('messaging', 'privateMsgToGuestOnly', 'composeMessageFormError')
        },
        localeText: {
          // Translators: Label for the Close Popup icon of Secondary \
          // Recipients input
          closePopup: gettext('messaging', 'closeSecondaryRecipientsPopup', 'composeMessageFormLocaleText'),
          // Translators: Text to display when there are no contacts to show \
          // in Secondary Recipients input
          noOptions: gettext('messaging', 'noSecondaryRecipients', 'composeMessageFormLocaleText'),
          // Translators: Label for the Open Popup icon of Secondary Recipients input
          openPopup: gettext('messaging', 'openSecondaryRecipientsPopup', 'composeMessageFormLocaleText')
        }
      }
    }
  },
  fieldsetSubject: {
    formField: {
      subjectLine: {
        // Translators: Label for the Subject Line input
        label: gettext('messaging', 'subjectLine', 'composeMessageFormInputLabel'),
        // Translators: Placeholder for the Subject Line input
        placeholder: gettext('messaging', 'subjectLine', 'composeMessageFormInputHint'),
        error: {
          // Translators: Error message displayed when Subject Line contains \
          // invalid characters
          patternMismatch: gettext('messaging', 'subjectLinePattern', 'composeMessageFormError')
        }
      }
    }
  },
  fieldsetMessage: {
    formField: {
      messageBody: {
        // Translators: Label for the Message Body input
        label: gettext('messaging', 'messageBody', 'composeMessageFormInputLabel'),
        // Translators: Placeholder for the Message Body input
        placeholder: gettext('messaging', 'messageBody', 'composeMessageFormInputHint')
      }
    }
  },
  fieldsetOptions: {
    formField: {
      billable: {
        // Translators: Label for the Billable checkbox input
        label: gettext('messaging', 'billable', 'composeMessageFormInputLabel')
      },
      doNotReply: {
        // Translators: Label for the Do Not Reply checkbox input
        label: gettext('messaging', 'doNotReply', 'composeMessageFormInputLabel')
      },
      important: {
        // Translators: Label for the Important checkbox input
        label: gettext('messaging', 'important', 'composeMessageFormInputLabel')
      },
      sensitivity: {
        // Translators: Label for the Message Sensitivity input
        label: gettext('messaging', 'sensitivity', 'composeMessageFormInputLabel'),
        options: [
          {
            code: 'confidential',
            // Translators: Label for the Confidential Message Sensitivity \
            // dropdown option
            label: gettext('messaging', 'confidential', 'composeMessageFormSensitivityOptionLabel')
          },
          {
            code: 'normal',
            // Translators: Label for the Normal Message Sensitivity dropdown \
            // option
            label: gettext('messaging', 'normal', 'composeMessageFormSensitivityOptionLabel')
          },
          {
            code: 'personal',
            // Translators: Label for the Personal Message Sensitivity \
            // dropdown option
            label: gettext('messaging', 'personal', 'composeMessageFormSensitivityOptionLabel')
          },
          {
            code: 'private',
            // Translators: Label for the Private Message Sensitivity dropdown \
            // option
            label: gettext('messaging', 'private', 'composeMessageFormSensitivityOptionLabel')
          }
        ]
      }
    }
  },
  fieldsetSendTo: {
    formField: {
      sendToEMR: {
        // Translators: Label for the Send To EMR checkbox input
        label: gettext('messaging', 'sendToEMR', 'composeMessageFormInputLabel')
      },
      sendToHRM: {
        // Translators: Label for the Send To HRM checkbox input
        label: gettext('messaging', 'sendToHRM', 'composeMessageFormInputLabel')
      }
    }
  }
};

export default i18n;
