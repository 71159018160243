import { MessagingException, ReadonlyMessageBodyJoinGroupInvitation, Utils } from '@sigmail/common';
import { DataObjectMsgBodyValue } from '@sigmail/objects';
import { BaseSendMessageAction } from './base';

export class SendGroupInviteMessageAction extends BaseSendMessageAction {
  /** @override */
  protected validateMessageFormName(): void {
    const { messageFormName } = this.payload;
    if (!Utils.isMessageFormNameJoinGroupInvitation(messageFormName)) {
      throw new MessagingException(`Expected message form name to be <joinGroupInvitation>; was <${messageFormName}>`);
    }
  }

  /** @override */
  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    const { messageForm } = this.payload.messageBody as ReadonlyMessageBodyJoinGroupInvitation;
    return { $$formatver: 1, messageForm };
  }
}
