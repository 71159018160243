import type { AccountActionPayload, ApiActionPayload } from '@sigmail/app-state';
import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import type { INotificationObject, NotificationObjectGuestProfileUpdateValue } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { BatchUpdateRequestBuilder } from '../../../../utils/batch-update-request-builder';
import type { AuthenticatedActionState } from '../../authenticated-action';
import { AuthenticatedAction } from '../../authenticated-action';
import type { ActionInitParams, FetchObjectsRequestData } from '../../base-action';

type NotificationObjectValue = NotificationObjectGuestProfileUpdateValue;

interface NotificationObjectConstructor<T extends NotificationObjectValue> {
  new (...args: Array<any>): INotificationObject<T>;
  readonly TYPE: SigmailObjectTypeCode;
}

export type BaseProcessNotificationsPayload = Omit<AccountActionPayload.ProcessNotifications, 'type'>;

export interface BaseProcessNotificationsState<T extends NotificationObjectValue = NotificationObjectValue>
  extends AuthenticatedActionState {
  batchUpdateClaims: NonNullable<Api.BatchUpdateRequestData['claims']>;
  dtServer: Date;
  notificationObjectList: ReadonlyArray<Readonly<[INotificationObject<T>, T]>>;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

export abstract class BaseProcessNotificationsAction<
  T extends NotificationObjectValue,
  P extends BaseProcessNotificationsPayload = BaseProcessNotificationsPayload,
  S extends BaseProcessNotificationsState<T> = BaseProcessNotificationsState<T>
> extends AuthenticatedAction<P, S> {
  protected readonly NotificationObjectClass: NotificationObjectConstructor<T>;

  public constructor(params: ActionInitParams<P>, NotificationObjectClass: NotificationObjectConstructor<T>) {
    super(params);

    this.NotificationObjectClass = NotificationObjectClass;
  }

  protected abstract getBatchUpdateAuthState(): Promise<Api.BatchUpdateRequestData['authState']>;
  protected abstract processNotificationList(): Promise<void>;

  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();

    const batchUpdateAuthState = await this.getBatchUpdateAuthState();
    this.state.roleAuthClaim = batchUpdateAuthState;

    return result;
  }

  /** @override */
  protected async onExecute(..._args: any[]) {
    const { roleAuthClaim: authState } = this.state;

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      this.logger.info('Fetching latest group guest list.');
      this.state.dtServer = await this.dispatchFetchServerDateAndTime();

      this.state.batchUpdateClaims = [];
      this.state.notificationObjectList = [];
      this.state.requestBody = new BatchUpdateRequestBuilder();
      this.state.successPayload = {
        request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
        response: { dataObjects: [], userObjects: [], serverDateTime: '' }
      };

      await this.fetchNotificationObjectList();
      if (this.state.notificationObjectList.length === 0) {
        this.logger.info(`No new notifications of type <${this.NotificationObjectClass.TYPE}> are available; call ignored.`);
        return;
      }

      await this.processNotificationList();

      try {
        const mutations = this.state.requestBody.build();
        const skipBatchUpdate = Utils.every(mutations, (list) => !Utils.isNonEmptyArray(list));

        if (!skipBatchUpdate) {
          const { batchUpdateClaims: claims, successPayload } = this.state;
          await this.dispatchBatchUpdateData({ authState, claims, ...mutations });

          try {
            await this.dispatchBatchQueryDataSuccess(successPayload);
          } catch (error) {
            this.logger.warn('Error manually updating app state:', error);
            /* ignore */
          }
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }
  }

  protected async fetchNotificationObjectList(): Promise<void> {
    const { TYPE } = this.NotificationObjectClass;
    this.logger.info(`Fetching notifications of type <${TYPE}> (if any).`);

    const { accessToken, activeGroupId, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      notificationObjectsByType: [{ type: TYPE, userId: activeGroupId }],
      expectedCount: { notificationObjectsByType: null }
    };

    const { notificationObjectList } = await this.fetchObjects(accessToken, query);
    const instanceList = notificationObjectList.map((json) => new this.NotificationObjectClass(json));
    if (instanceList.length === 0) return;

    const resultList = await Promise.allSettled(instanceList.map((instance) => instance.decryptedValue()));
    this.state.notificationObjectList = Utils.filterMap(resultList, (result, index) => {
      if (result.status !== 'fulfilled') return false;
      return [instanceList[index], result.value];
    });
  }
}
