import { SchedulingI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';

const i18n: SchedulingI18n.ReminderNotification = {
  action: {
    dismiss: {
      // Translators: Label for the Dismiss action
      label: gettext('scheduling', 'dismiss', 'reminderNotificationActionLabel')
    },
    join: {
      // Translators: Label for the Join action
      label: gettext('scheduling', 'join', 'reminderNotificationActionLabel')
    }
  },
  // Translators: (HTML) Message Body for the reminder notification >= 1 min
  message: ngettext('scheduling', 'message', 'reminderNotification', 'safe-html'),
  // Translators: (HTML) Message Body for the reminder notification < 0 min
  messageBelow0Min: ngettext('scheduling', 'messageBelow0Min', 'reminderNotification', 'safe-html'),
  // Translators: (HTML) Message Body for the reminder notification < 1 min
  messageBelow1Min: ngettext('scheduling', 'messageBelow1Min', 'reminderNotification', 'safe-html')
};

export default i18n;
