import { AccountI18n } from '@sigmail/i18n';

export const DIALOG_SEND_CAREGIVER_ACCOUNT_INVITATION: Extract<
  keyof AccountI18n.Root['dialog'],
  'sendCaregiverAccountInvitation'
> = 'sendCaregiverAccountInvitation';

export const DIALOG_SEND_GUEST_ACCOUNT_INVITATION: Extract<
  keyof AccountI18n.Root['dialog'],
  'sendGuestAccountInvitation'
> = 'sendGuestAccountInvitation';

export const DIALOG_SEND_NON_GUEST_ACCOUNT_INVITATION: Extract<
  keyof AccountI18n.Root['dialog'],
  'sendNonGuestAccountInvitation'
> = 'sendNonGuestAccountInvitation';
