import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogHrmUnauthorizedSender } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeHrmMessage['dialog']['unauthorizedHrmSender'] = {
  // Translators: (HTML) Title for the Unauthorized HRM Sender dialog in \
  // Compose HRM Message view
  title: gettext('messaging', 'title', 'dialogHrmUnauthorizedSender', 'safe-html'),
  // Translators: (HTML) Message displayed in the body of Unauthorized HRM \
  // Sender dialog in Compose HRM Message view
  body: gettext('messaging', 'body', 'dialogHrmUnauthorizedSender', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Unauthorized HRM Sender \
      // dialog in Compose HRM Message view
      label: gettext('messaging', 'accept', 'dialogHrmUnauthorizedSenderActionLabel'),
      context: DialogHrmUnauthorizedSender
    }
  }
};

export default i18n;
