import { AppUser, AppUserGroup, CancelablePromise, IAppUserOrUserGroup, Utils, ValueObject } from '@sigmail/common';
import { MessageCategory } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { EMPTY_ARRAY } from '../../../../app-state/constants';
import { useDispatchFetchUserObjects } from '../../../../app-state/hooks';
import { preferencesObjectSelector as groupPreferencesSelector } from '../../../../app-state/selectors/group-object';
import { preferencesObjectSelector as userPreferencesSelector } from '../../../../app-state/selectors/user-object';
import { UserObjectCache } from '../../../../app-state/user-objects-slice/cache';
import { DEFAULT_MESSAGE_CATEGORY_LIST, UNRESOLVED_RESOURCE } from '../../../../constants';

export type ResourceValue = ReadonlyArray<MessageCategory>;
export type Resource = ISuspenseResource<ResourceValue>;
export type Params = Omit<IAppUserOrUserGroup, keyof ValueObject>;

export const useMessageCategoryListResource = ({ id, type }: Params): Resource => {
  const [resource, setResource] = React.useState<Resource>(UNRESOLVED_RESOURCE);
  const groupPrefsObject = useSelector(groupPreferencesSelector)(Utils.numberOrDefault(type === 'group' && id));
  const groupPrefs = UserObjectCache.getValue(groupPrefsObject);
  const userPrefsObject = useSelector(userPreferencesSelector)(Utils.numberOrDefault(type === 'user' && id));
  const userPrefs = UserObjectCache.getValue(userPrefsObject);
  const fetchUserObjects = useDispatchFetchUserObjects();
  const prevParams = React.useRef({} as Params);
  const { id: prevId, type: prevType } = prevParams.current;
  const fetchPromise = React.useRef<CancelablePromise<unknown> | null>(null);

  if (prevId !== id || prevType !== type) {
    prevParams.current = { id, type };

    let promise: CancelablePromise<unknown> | null = null;
    if (type === 'group' && AppUserGroup.isValidId(id) && Utils.isNil(groupPrefsObject)) {
      if (fetchPromise.current !== null) fetchPromise.current.cancel();

      promise = Utils.makeCancelablePromise(
        fetchUserObjects({ userObjects: [{ type: process.env.GROUP_OBJECT_TYPE_PREFERENCES, userId: id }] })
      );
    } else if (type === 'user' && AppUser.isValidId(id) && Utils.isNil(userPrefsObject)) {
      if (fetchPromise.current !== null) fetchPromise.current.cancel();

      promise = Utils.makeCancelablePromise(
        fetchUserObjects({ userObjects: [{ type: process.env.USER_OBJECT_TYPE_PREFERENCES, userId: id }] })
      );
    }

    if (promise !== null) {
      promise.promise.then(
        () => {
          fetchPromise.current = null;
        },
        () => {
          if (promise!.hasCanceled) return;
          fetchPromise.current = null;
        }
      );

      fetchPromise.current = promise;
      setResource(UNRESOLVED_RESOURCE);
    }
  }

  React.useEffect(() => {
    if (fetchPromise.current !== null) return;

    let value: ResourceValue = EMPTY_ARRAY;
    const DEFAULT_CATEGORY_LIST = DEFAULT_MESSAGE_CATEGORY_LIST as Array<MessageCategory>;
    if (type === 'group' && AppUserGroup.isValidId(id)) {
      value = Utils.arrayOrDefault(Utils.isNotNil(groupPrefs) && groupPrefs.messageCategoryList, DEFAULT_CATEGORY_LIST);
    } else if (type === 'user' && AppUser.isValidId(id)) {
      value = Utils.arrayOrDefault(Utils.isNotNil(userPrefs) && userPrefs.messageCategoryList, DEFAULT_CATEGORY_LIST);
    }

    setResource({ value: () => value });
  }, [groupPrefs, id, type, userPrefs]);

  return resource;
};
