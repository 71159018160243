import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { FormBillingFile } from '../../constants/action-context';

const i18n: GlobalI18n['form']['billingFile'] = {
  fieldsetDateRange: {
    formField: {
      endDate: {
        // Translators: Label for the End Date input of Billing File form
        label: gettext('global', 'endDate', 'billingFileFormInputLabel'),
        error: {
          // Translators: Error displayed when End Date input of Billing \
          // File form is left blank
          valueMissing: gettext('global', 'endDateRequired', 'billingFileFormError'),
          // Translators: Error displayed when End Date input of Billing \
          // File form is not within the valid date range
          // Translators: Token(s) available: {{MIN_DATE}}, {{MAX_DATE}}
          range: gettext('global', 'endDateRange', 'billingFileFormError'),
          // Translators: Error displayed when End Date input of Billing \
          // File form is not valid, for e.x., 2022-02-30
          badInput: gettext('global', 'endDateBadInput', 'billingFileFormError')
        }
      },
      startDate: {
        // Translators: Label for the Start Date input of Billing File form
        label: gettext('global', 'startDate', 'billingFileFormInputLabel'),
        error: {
          // Translators: Error displayed when Start Date input of Billing \
          // File form is left blank
          valueMissing: gettext('global', 'startDateRequired', 'billingFileFormError'),
          // Translators: Error displayed when Start Date input of Billing \
          // File form is not within the valid date range
          // Translators: Token(s) available: {{MIN_DATE}}, {{MAX_DATE}}
          range: gettext('global', 'startDateRange', 'billingFileFormError'),
          // Translators: Error displayed when Start Date input of Billing \
          // File form is not valid, for e.x., 2022-02-30
          badInput: gettext('global', 'startDateBadInput', 'billingFileFormError')
        }
      }
    }
  },
  fieldsetFileType: {
    formField: {
      fileType: {
        // Translators: Label for the File Type input of Billing File form
        label: gettext('global', 'fileType', 'billingFileFormInputLabel'),
        // Translators: Label for the Select File Type option of File Type \
        // input on Billing File form
        placeholder: gettext('global', 'selectFileType', 'billingFileFormOptionLabel'),
        options: [
          {
            code: 'e-consult',
            // Translators: Label for the Consultation option of File Type \
            // input on Billing File form
            label: gettext('global', 'consultation', 'billingFileFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error displayed when no option is selected for \
          // File Type input of Billing File form
          valueMissing: gettext('global', 'fileTypeRequired', 'billingFileFormError'),
          // Translators: Error displayed when referrer does not have Billing \
          // Number set in their account profile
          billingNumberMissing: gettext('global', 'billingNumberRequired', 'billingFileFormError'),
          // Translators: Error displayed when there are no billing records \
          // available to submit
          noBillingRecordFound: gettext('global', 'noBillingRecordFound', 'billingFileFormError')
        }
      }
    }
  },
  action: {
    generate: {
      // Translators: Label for the Generate action of Billing File form
      label: gettext('global', 'generate', 'billingFileFormActionLabel'),
      context: FormBillingFile
    }
  }
};

export default i18n;
