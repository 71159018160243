import { PostalAddress, ReadonlyMessageBodyConsultation, Utils } from '@sigmail/common';
import { TOptions, WithT } from 'i18next';
import sanitizeHtml from 'sanitize-html';
import { SANITIZER_OPTIONS } from '../../app-state/actions/constants';
import globalI18n from '../../i18n/global';
import messagingI18n from '../../i18n/messaging';

const { consultationRequest: bodyI18n } = messagingI18n.messageBody;
const { genderList, healthPlanJurisdictionList } = globalI18n;

export interface GenerateConsultationRequestMessageBodyParams extends WithT {
  tOptions?: TOptions;
  value: ReadonlyMessageBodyConsultation['messageForm']['value'];
}

const postalAddressToHtml = ({
  addressLine1,
  addressLine2,
  addressLevel1,
  addressLevel2,
  postalCode
}: PostalAddress) => {
  let html = Utils.joinPostalAddress({ addressLine1, addressLine2 });
  if (html.length > 0) html = `<p class="ql-indent-1">${html}</p>`;

  const cityProvincePostalCode = Utils.joinPostalAddress({ addressLevel1, addressLevel2, postalCode });
  if (cityProvincePostalCode.length > 0) {
    html = `${html}<p class="ql-indent-1">${cityProvincePostalCode}</p>`;
  }

  return html;
};

export function generateConsultationRequestMessageBody({
  t,
  tOptions,
  value
}: GenerateConsultationRequestMessageBodyParams): string {
  let instituteName = value.referrer.instituteName.trim();
  if (instituteName.length > 0) {
    instituteName = `<p><strong>${instituteName}</strong></p><p><br></p><p><br></p>`;
  }

  let { gender, healthPlanJurisdiction } = value.patient;
  let index = genderList.findIndex(({ code }) => code === gender);
  if (index > -1) gender = t(genderList[index].label);
  index = healthPlanJurisdictionList.findIndex(({ code }) => code === healthPlanJurisdiction);
  if (index > -1) healthPlanJurisdiction = t(healthPlanJurisdictionList[index].label);

  const html = t(bodyI18n, {
    REFERRER_INSTITUTE_NAME: instituteName,
    REFERRER_NAME: Utils.joinPersonName(value.referrer),
    REFERRER_ADDRESS: postalAddressToHtml(value.referrer),
    REFERRER_BILLING_CODE: value.referrer.ohipBillingNumber,
    CONSULTANT_NAME: Utils.joinPersonName(value.consultant),
    CONSULTANT_ADDRESS: postalAddressToHtml(value.consultant),
    PATIENT_NAME_FIRST: value.patient.firstName,
    PATIENT_NAME_LAST: value.patient.lastName,
    PATIENT_BIRTH_DATE: value.patient.birthDate,
    PATIENT_GENDER: gender,
    PATIENT_HEALTH_PLAN_NUMBER: value.patient.healthPlanNumber,
    PATIENT_HEALTH_PLAN_JURISDICTION: healthPlanJurisdiction,
    CONSULTATION_REASON: value.consultationReason,
    MESSAGE: value.message,
    ...tOptions
  });

  return sanitizeHtml(html, SANITIZER_OPTIONS);
}
