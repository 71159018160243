import { AppException, Constants, MessagingException, SigmailObjectId, Utils } from '@sigmail/common';
import { DataObjectMsgMetadata, DataObjectMsgMetadataValue, MessageFolderListItem } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { contactInfoObjectSelector } from '../../../selectors/user-object';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';

interface State extends BaseSendMessageState {
  msgMetadataIdForSender: SigmailObjectId;
  msgMetadataForSender: DataObjectMsgMetadataValue;
}

export const BillingNumberMissingError = new AppException(
  Utils.MAKE_ERROR_CODE(Constants.Error.SEVERITY_ERROR, Constants.Error.FACILITY_CLIENT, 1)
);

export class SendMessageToBillingProviderAction extends BaseSendMessageAction<BaseSendMessagePayload, State> {
  public constructor(params: ConstructorParameters<typeof BaseSendMessageAction>[0]) {
    super(params);

    const { documentList } = params.payload;
    if (!Utils.isArray<NonNullable<typeof documentList>[0]>(documentList) || documentList.length !== 1) {
      throw new MessagingException('Expected <documentList> to be an array of size 1.');
    }

    throw new MessagingException(`This action is not in use anymore.`);
  }

  /** @override */
  protected async preExecute(): Promise<any> {
    const result = await super.preExecute();

    const { id: senderId } = this.payload.sender;
    const contactInfo = await this.getUserObjectValue(contactInfoObjectSelector, {
      type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
      userId: senderId
    });

    const billingNumber = contactInfo?.ohipBillingNumber?.trim() || '';
    if (billingNumber.length === 0) throw BillingNumberMissingError;

    return result;
  }

  /** @override */
  protected async createIdsRequestData(): Promise<Api.GetIdsRequestData> {
    const requestData = await super.createIdsRequestData();

    const ids = requestData.ids.ids!.slice();
    const index = ids.findIndex(({ type }) => type === process.env.DATA_OBJECT_TYPE_MSG_METADATA);
    const count = (Utils.isInteger(ids[index].count) ? ids[index].count! : 1) + 1;
    ids[index] = { ...ids[index], count };

    return { ...requestData, ids: { ids } };
  }

  /** @override */
  protected async generateIdSequence(): Promise<void> {
    await super.generateIdSequence();

    const metadataIdList = this.state.idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA];
    this.state.msgMetadataIdForSender = metadataIdList[metadataIdList.length - 1];
  }

  /** @override */
  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    const value = await super.createMessageMetadataValue();
    this.state.msgMetadataForSender = Utils.omit(value, 'documentList');
    return value;
  }

  /** @override */
  protected async addInsertOperationForMessageMetadata(): Promise<void> {
    await super.addInsertOperationForMessageMetadata();

    this.logger.info("Adding an insert operation each to request body for sender's message metadata and associated keys.");

    const { sender } = this.payload;
    const { msgMetadataIdForSender: id, msgMetadataForSender: data, dtServer, ownerId, auditId, requestBody } = this.state;

    this.logger.debug({ id, ...data });

    const msgMetadataObject = await DataObjectMsgMetadata.create(id, undefined, 0, data, ownerId, sender.id, dtServer);
    const keyList = await msgMetadataObject.generateKeysEncryptedFor(auditId);
    keyList.push(msgMetadataObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgMetadataObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  /** @override */
  protected createSentMessage(): MessageFolderListItem {
    const { documentList, ...sentMessage } = super.createSentMessage();
    return { ...sentMessage, header: this.state.msgMetadataIdForSender };
  }
}
