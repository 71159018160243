export function isDateCtorParam(arg: unknown): arg is Date | Number | String | number | string {
  const argStr = Object.prototype.toString.call(arg);
  return (
    arg instanceof Date ||
    (typeof arg === 'object' && argStr === '[object Date]') ||
    typeof arg === 'number' ||
    argStr === '[object Number]' ||
    typeof arg === 'string' ||
    argStr === '[object String]'
  );
}
