import { HealthDataI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import * as HealthDataConstants from '../../app/health-data/constants';
import { DialogHealthDataRequest } from '../../constants/action-context';

const i18n: HealthDataI18n.Form.HealthDataRequest = {
  fieldsetDataForm: {
    formField: {
      dataForm: {
        error: {
          // Translators: Error displayed when on-behalf request being made \
          // conflicts with an open request of the same data type on Health \
          // Data Request form
          onBehalfConflict: gettext('health-data', 'onBehalfConflict', 'healthDataRequestFormError'),
          // Translators: Error displayed when an invalid value is selected \
          // for Select Data Type input on Health Data Request form
          patternMismatch: gettext('health-data', 'dataFormPattern', 'healthDataRequestFormError'),
          // Translators: Error displayed when the request being scheduled \
          // conflicts with an open request of the same data type on Health \
          // Data Request form
          requestConflict: gettext('health-data', 'requestConflict', 'healthDataRequestFormError'),
          // Translators: Error displayed when no option is selected for \
          // Select Data Type input on Health Data Request form
          valueMissing: gettext('health-data', 'dataFormRequired', 'healthDataRequestFormError')
        },
        // Translators: Label for the Select Data Type input on Health Data \
        // Request form
        label: gettext('health-data', 'dataForm', 'healthDataRequestFormInputLabel'),
        options: [
          {
            code: HealthDataConstants.DataFormNameBPReading,
            // Translators: Label for the BP Reading dropdown option of Select \
            // Data Type input on Heath Data Request form
            label: gettext('health-data', 'dataFormBPReading', 'healthDataRequestFormOptionLabel')
          },
          {
            code: HealthDataConstants.DataFormNameCardiacIntake,
            // Translators: Label for the Cardiac Intake dropdown option of \
            // Select Data Type input on Heath Data Request form
            label: gettext('health-data', 'dataFormCardiacIntake', 'healthDataRequestFormOptionLabel')
          },
          {
            code: HealthDataConstants.DataFormNameCopdAssessment,
            // Translators: Label for the COPD Assessment option of Select \
            // Data Type input on Health Data Request form
            label: gettext('health-data', 'dataFormCopdAssessment', 'healthDataRequestFormOptionLabel')
          },
          {
            code: HealthDataConstants.DataFormNameKCCQ,
            // Translators: Label for the KCCQ-12 option of Select Data Type \
            // input on Health Data Request form
            label: gettext('health-data', 'dataFormKCCQ', 'healthDataRequestFormOptionLabel')
          },
          {
            code: HealthDataConstants.DataFormNameVitals,
            // Translators: Label for the Vitals Questionnaire option \
            // of Select Data Type input on Health Data Request form
            label: gettext('health-data', 'dataFormVitals', 'healthDataRequestFormOptionLabel')
          }
        ],
        // Translators: Label for the SELECT dropdown option of Select Data \
        // Type input on Health Data Request form
        placeholder: gettext('health-data', 'selectDataForm', 'healthDataRequestFormOptionLabel')
      }
    }
  },
  fieldsetNote: {
    formField: {
      note: {
        error: {
          // Translators: Error message displayed when Note input doesn't \
          // satisfy the maximum length requirement on Health Data Request form
          length: gettext('health-data', 'noteLength', 'healthDataRequestFormError')
        },
        // Translators: Label for the Note input on Health Data Request form
        label: gettext('health-data', 'note', 'healthDataRequestFormInputLabel'),
        // Translators: Helper Text for the Note input on Health Data Request \
        // form
        helperText: gettext('health-data', 'note', 'healthDataRequestFormInputHelperText'),
        // Translators: Hint for the Note input on Health Data Request form
        placeholder: gettext('health-data', 'note', 'healthDataRequestFormInputHint')
      }
    }
  },
  fieldsetRecurrence: {
    formField: {
      frequency: {
        error: {
          // Translators: Error displayed when an invalid value is selected \
          // for Recurrence Frequency input on Health Data Request form
          patternMismatch: gettext('health-data', 'frequencyPattern', 'healthDataRequestFormError'),
          // Translators: Error displayed when no option is selected for \
          // Recurrence Frequency input on Health Data Request form
          valueMissing: gettext('health-data', 'frequencyRequired', 'healthDataRequestFormError')
        },
        // Translators: Label for the Recurrence Frequency input on Health \
        // Data Request form
        label: gettext('health-data', 'frequency', 'healthDataRequestFormInputLabel'),
        options: [
          {
            code: '',
            // Translators: Label for the Never Repeat option of Recurrence \
            // Frequency input on Health Data Request form
            label: gettext('health-data', 'frequencyNever', 'healthDataRequestFormOptionLabel')
          },
          {
            code: 'DAILY',
            // Translators: Label for the Daily option of Recurrence Frequency \
            // input on Health Data Request form
            label: gettext('health-data', 'frequencyDaily', 'healthDataRequestFormOptionLabel')
          },
          {
            code: 'WEEKLY',
            // Translators: Label for the Weekly option of Recurrence \
            // Frequency input on Health Data Request form
            label: gettext('health-data', 'frequencyWeekly', 'healthDataRequestFormOptionLabel')
          },
          {
            code: 'MONTHLY',
            // Translators: Label for the Monthly option of Recurrence \
            // Frequency input on Health Data Request form
            label: gettext('health-data', 'frequencyMonthly', 'healthDataRequestFormOptionLabel')
          },
          {
            code: 'YEARLY',
            // Translators: Label for the Yearly option of Recurrence \
            // Frequency input on Health Data Request form
            label: gettext('health-data', 'frequencyYearly', 'healthDataRequestFormOptionLabel')
          }
        ]
      },
      startDate: {
        error: {
          // Translators: Error displayed when an invalid value is entered \
          // for Recurrence Start Date input on Health Data Request form
          badInput: gettext('health-data', 'startDateInvalid', 'healthDataRequestFormError'),
          // Translators: Error displayed when Recurrence Start Date input is \
          // left blank on Health Data Request form
          valueMissing: gettext('health-data', 'startDateRequired', 'healthDataRequestFormError')
        },
        // Translators: Label for the Recurrence Start Date input on Health \
        // Data Request form
        label: gettext('health-data', 'startDate', 'healthDataRequestFormInputLabel')
      },
      weekdays: {
        error: {
          // Translators: Error displayed when value of Select Weekdays input \
          // is out of range on Health Data Request form
          range: gettext('health-data', 'weekdaysOutOfRange', 'healthDataRequestFormError')
        },
        // Translators: Label for the Select Weekdays input on Health Data \
        // Request form
        label: gettext('health-data', 'weekdays', 'healthDataRequestFormInputLabel')
      }
    }
  },
  fieldsetRecurrenceRule: {
    foreverBy: {
      day: ngettext('health-data', 'foreverByDay', 'healthDataRequestFormMessage'),
      month: ngettext('health-data', 'foreverByMonth', 'healthDataRequestFormMessage'),
      week: ngettext('health-data', 'foreverByWeek', 'healthDataRequestFormMessage'),
      weekday: ngettext('health-data', 'foreverByWeekDay', 'healthDataRequestFormMessage'),
      year: ngettext('health-data', 'foreverByYear', 'healthDataRequestFormMessage')
    },
    formField: {
      endDate: {
        error: {
          // Translators: Error displayed when an invalid value is entered \
          // for Recurrence End Date input on Health Data Request form
          badInput: gettext('health-data', 'endDateInvalid', 'healthDataRequestFormError'),
          // Translators: Error displayed when Recurrence End Date input is \
          // left blank on Health Data Request form
          valueMissing: gettext('health-data', 'endDateRequired', 'healthDataRequestFormError')
        },
        // Translators: Label for the Recurrence End Date input on Health \
        // Data Request form
        label: gettext('health-data', 'endDate', 'healthDataRequestFormInputLabel')
      }
    },
    occurrenceBy: {
      day: ngettext('health-data', 'occurrenceByDay', 'healthDataRequestFormMessage'),
      month: ngettext('health-data', 'occurrenceByMonth', 'healthDataRequestFormMessage'),
      week: ngettext('health-data', 'occurrenceByWeek', 'healthDataRequestFormMessage'),
      weekday: ngettext('health-data', 'occurrenceByWeekDay', 'healthDataRequestFormMessage'),
      year: ngettext('health-data', 'occurrenceByYear', 'healthDataRequestFormMessage')
    }
  },
  fillOnBehalfOf: {
    action: {
      context: DialogHealthDataRequest,
      // Translators: Label for the Fill on Behalf action of Heath Data \
      // Request view
      label: gettext('health-data', 'fillOnBehalfOfActionLabel', 'healthDataRequestFormMessage')
    },
    // Translators: (HTML) Page Text of Heath Data Request view
    text: gettext('health-data', 'fillOnBehalfOfText', 'healthDataRequestFormMessage')
  }
};

export default i18n;
