import { Utils } from '@sigmail/common';
import { ActionHref, RouterAction } from 'sigmail';

interface ActionPath extends Partial<ActionHref>, Partial<Pick<RouterAction, 'to'>> {}

const ORDERED_KEY_LIST: ReadonlyArray<keyof ActionPath> = ['href', 'to'];

export function resolveActionPath(action: ActionPath, actionId?: string, context?: any): string {
  for (const key of ORDERED_KEY_LIST) {
    const path = action[key];
    if (typeof path === 'function') {
      return path(Utils.isString(actionId) ? actionId : '', context);
    } else if (Utils.isString(path)) {
      return path;
    }
  }
  return '';
}
