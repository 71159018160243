import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.Form.ComposeReferral = {
  fieldsetDefault: {
    formField: {
      urgent: {
        // Translators: Label for the Urgent Referral input
        label: gettext('messaging', 'urgent', 'composeReferralFormInputLabel'),
        options: [
          {
            code: 'true',
            // Translators: Label for the YES option of Urgent Referral input
            label: gettext('messaging', 'urgentYes', 'composeReferralFormOptionLabel')
          },
          {
            code: 'false',
            // Translators: Label for the NO option of Urgent Referral input
            label: gettext('messaging', 'urgentNo', 'composeReferralFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Urgent input field
          valueMissing: gettext('messaging', 'urgentRequired', 'composeReferralFormError')
        }
      },
      guest: {
        // Translators: Label for the Patient input
        label: gettext('messaging', 'guest', 'composeReferralFormInputLabel'),
        error: {
          // Translators: Error message displayed when user tries to submit \
          // the referral without selecting a patient
          valueMissing: gettext('messaging', 'guestRequired', 'composeReferralFormError')
        },
        localeText: {
          // Translators: Label for the Close Popup icon of Patient input
          closePopup: gettext('messaging', 'closePatientPopup', 'composeReferralFormLocaleText'),
          // Translators: Text to display when there are no contacts to show in \
          // Patient input
          noOptions: gettext('messaging', 'noPatients', 'composeReferralFormLocaleText'),
          // Translators: Label for the Open Popup icon of Patient input
          openPopup: gettext('messaging', 'openPatientPopup', 'composeReferralFormLocaleText')
        }
      },
      recipient: {
        // Translators: Label for the Recipient input
        label: gettext('messaging', 'recipient', 'composeReferralFormInputLabel'),
        error: {
          // Translators: Error message displayed when user tries to submit \
          // the referral without selecting a recipient
          valueMissing: gettext('messaging', 'recipientRequired', 'composeReferralFormError')
        },
        localeText: {
          // Translators: Label for the Close Popup icon of Recipient input
          closePopup: gettext('messaging', 'closeRecipientPopup', 'composeReferralFormLocaleText'),
          // Translators: Text to display when there are no contacts to show in \
          // Recipient input
          noOptions: gettext('messaging', 'noRecipients', 'composeReferralFormLocaleText'),
          // Translators: Label for the Open Popup icon of Recipient input
          openPopup: gettext('messaging', 'openRecipientPopup', 'composeReferralFormLocaleText')
        }
      },
      department: {
        // Translators: Label for the Department Or Specialty Area input
        label: gettext('messaging', 'department', 'composeReferralFormInputLabel')
      },
      reason: {
        // Translators: Label for the Reason For Referral input
        label: gettext('messaging', 'reason', 'composeReferralFormInputLabel'),
        error: {
          // Translators: Error message displayed when Reason For Referral \
          // input is left blank
          valueMissing: gettext('messaging', 'reasonRequired', 'composeReferralFormError')
        }
      },
      notes: {
        // Translators: Label for the Medical History input
        label: gettext('messaging', 'notes', 'composeReferralFormInputLabel'),
        error: {
          // Translators: Error message displayed when Medical History \
          // input is left blank
          valueMissing: gettext('messaging', 'notesRequired', 'composeReferralFormError')
        }
      },
      icdCode: {
        // Translators: Label for the ICD 9/10 Code input
        label: gettext('messaging', 'icdCode', 'composeReferralFormInputLabel'),
        error: {
          // Translators: Error message displayed when ICD 9/10 Code input \
          // doesn't satisfy the minimum length constraint
          // Translators: Token(s) available: {{MIN_LENGTH}}
          tooShort: gettext('messaging', 'icdCodeTooShort', 'composeReferralFormError')
        }
      },
      ccReplyToSharedInbox: {
        // Translators: Label for the CC Reply To Shared Inbox checkbox
        label: gettext('messaging', 'ccReplyToSharedInbox', 'composeReferralFormInputLabel')
      }
    }
  }
};

export default i18n;
