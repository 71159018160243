import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogConsultationRequestSelectPatientRecord } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.ConsultationRequest.SelectPatientRecord = {
  title: {
    // Translators: (HTML) Title for the Select Consultation Request Patient \
    // Record dialog after search is complete and more than one match was found
    found: gettext('messaging', 'titleFound', 'dialogConsultationRequestSelectPatientRecord', 'safe-html'),
    // Translators: (HTML) Title for the Select Consultation Request Patient \
    // Record dialog after search is complete and no matching record was found
    notFound: gettext('messaging', 'titleNotFound', 'dialogConsultationRequestSelectPatientRecord', 'safe-html'),
    // Translators: (HTML) Title for the Select Consultation Request Patient \
    // Record dialog while a search is in progress
    searching: gettext('messaging', 'titleSearching', 'dialogConsultationRequestSelectPatientRecord', 'safe-html')
  },
  body: {
    // Translators: (HTML) Message to be displayed in the body of Select \
    // Consultation Request Patient Record dialog when more than one match \
    // was found
    found: gettext('messaging', 'bodyFound', 'dialogConsultationRequestSelectPatientRecord', 'safe-html'),
    // Translators: (HTML) Message to be displayed in the body of Select \
    // Consultation Request Patient Record dialog when no matching record \
    // was found
    notFound: gettext('messaging', 'bodyNotFound', 'dialogConsultationRequestSelectPatientRecord', 'safe-html')
  },
  grid: {
    columnHeader: {
      // Translators: Label for the Birth Date column header of Select \
      // Consultation Request Patient Record dialog
      birthDate: gettext('messaging', 'birthDate', 'gridColumnHeaderConsultationRequestPatientRecord'),
      // Translators: Label for the First Name column header of Select \
      // Consultation Request Patient Record dialog
      firstName: gettext('messaging', 'firstName', 'gridColumnHeaderConsultationRequestPatientRecord'),
      // Translators: Label for the Gender column header of Select \
      // Consultation Request Patient Record dialog
      gender: gettext('messaging', 'gender', 'gridColumnHeaderConsultationRequestPatientRecord'),
      // Translators: Label for the Health Plan Number column header of Select \
      // Consultation Request Patient Record dialog
      healthPlanNumber: gettext('messaging', 'healthPlanNumber', 'gridColumnHeaderConsultationRequestPatientRecord'),
      // Translators: Label for the Last Name column header of Select \
      // Consultation Request Patient Record dialog
      lastName: gettext('messaging', 'lastName', 'gridColumnHeaderConsultationRequestPatientRecord'),
      // Translators: Label for the Middle Name column header of Select \
      // Consultation Request Patient Record dialog
      middleName: gettext('messaging', 'middleName', 'gridColumnHeaderConsultationRequestPatientRecord')
    }
  },
  action: {
    reject: {
      // Translators: Label for the Cancel action of Select Consultation \
      // Request Patient Record dialog
      label: gettext('messaging', 'reject', 'dialogConsultationRequestSelectPatientRecordActionLabel'),
      context: DialogConsultationRequestSelectPatientRecord
    },
    accept: {
      // Translators: Label for the Continue action of Select Consultation \
      // Request Patient Record dialog
      label: gettext('messaging', 'accept', 'dialogConsultationRequestSelectPatientRecordActionLabel'),
      context: DialogConsultationRequestSelectPatientRecord
    }
  }
};

export default i18n;
