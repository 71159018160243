import { AppException, Constants, SigmailUserId, Utils } from '@sigmail/common';
import {
  CryptographicKey,
  CryptographicKeyPrivate,
  IUserObject,
  LinkedCareRecipientContact as BaseLinkedCareRecipientContact,
  NotificationObjectGuestProfileUpdate,
  NotificationObjectGuestProfileUpdateValue,
  NotificationObjectMultiPurposeValue,
  UserObjectContactInfo,
  UserObjectProfileProtectedValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { protectedProfileObjectSelector as userProtectedProfileObjectSelector } from '../../../selectors/user-object';
import { ActionInitParams, FetchObjectsRequestData } from '../../base-action';
import { AUTH_STATE_PROCESS_CAREGIVER_UPDATES } from '../../constants/auth-state-identifier';
import { BaseProcessNotificationsAction, BaseProcessNotificationsPayload, BaseProcessNotificationsState } from './base';

type LinkedCareRecipientContact = BaseLinkedCareRecipientContact & { id: SigmailUserId };
type ProcessedRecordSet = { id: SigmailUserId; type: NotificationObjectMultiPurposeValue['type'] };

export type Payload = BaseProcessNotificationsPayload;

interface State extends BaseProcessNotificationsState {
  protectedProfileObject: IUserObject<UserObjectProfileProtectedValue>;
  removeIdList: Set<SigmailUserId>;
  updateLinkedIdList: Set<SigmailUserId>;
}

type TLinkedContact = NonNullable<UserObjectProfileProtectedValue['linkedContactList']>[0];

export class ProcessCaregiverUpdateNotificationsAction extends BaseProcessNotificationsAction<
  NotificationObjectGuestProfileUpdateValue,
  Payload,
  State
> {
  /** @override */
  protected get activeGroupId() {
    return null!;
  }

  public constructor(params: ActionInitParams<Payload>) {
    super(params, NotificationObjectGuestProfileUpdate);

    this.state.successPayload = {
      request: { userObjects: { ids: [] } },
      response: { userObjects: [], serverDateTime: '' }
    };

    this.state.removeIdList = new Set();
    this.state.updateLinkedIdList = new Set();
  }

  /** @override */
  protected async getBatchUpdateAuthState() {
    const { accessToken, roleAuthClaim: authState } = this.state;

    const { authState: batchUpdateAuthState } = await this.enterState(accessToken, {
      authState,
      state: AUTH_STATE_PROCESS_CAREGIVER_UPDATES
    });

    return batchUpdateAuthState;
  }

  /** @override */
  protected async fetchNotificationObjectList(): Promise<void> {
    const { TYPE } = this.NotificationObjectClass;
    this.logger.info(`Fetching notifications of type <${TYPE}> (if any).`);

    const { accessToken, currentUser, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      notificationObjectsByType: [{ type: TYPE, userId: currentUser.id }]
    };

    const { notificationObjectList } = await this.fetchObjects(accessToken, query);
    const instanceList = notificationObjectList.map((json) => new this.NotificationObjectClass(json));

    if (instanceList.length === 0) return;

    const resultList = await Promise.allSettled(instanceList.map((instance) => instance.decryptedValue()));
    this.state.notificationObjectList = Utils.filterMap(resultList, (result, index) => {
      if (result.status !== 'fulfilled') return false;
      return [instanceList[index], result.value];
    });
  }

  /** @override */
  protected async processNotificationList() {
    this.logger.info('Processing caregiver update notification objects.');

    const { dtServer, notificationObjectList, removeIdList, requestBody, updateLinkedIdList } = this.state;

    const processedRecordSet = new Set<ProcessedRecordSet>();
    for (let index = 0; index < notificationObjectList.length; index++) {
      const [notificationObject] = notificationObjectList[index];
      const { sendingUserId: userId, value } = notificationObject;

      const notificationValue: NotificationObjectMultiPurposeValue = JSON.parse(value);
      const processRecord = { id: userId, type: notificationValue.type };

      if (processedRecordSet.has(processRecord)) {
        requestBody.expire(notificationObject);
        continue;
      }

      if (notificationValue.type === 1) removeIdList.add(notificationValue.cid);
      else if (notificationValue.type === 2) removeIdList.add(notificationValue.pid);
      else updateLinkedIdList.add(userId);

      processedRecordSet.add(processRecord);
      requestBody.expire(notificationObject, dtServer);
    }

    const protectedProfileObject = await this.getUserObject(userProtectedProfileObjectSelector);
    if (Utils.isNil(protectedProfileObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'User protected profile object is either missing or invalid');
    }

    this.state.protectedProfileObject = protectedProfileObject;

    if (removeIdList.size > 0) await this.addUpdateOperationForProtectedProfile();
    if (updateLinkedIdList.size > 0) await this.addUpdateOperationForLinkedContact();
  }

  private async addUpdateOperationForProtectedProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for removing linked contact.');

    const { protectedProfileObject, removeIdList, requestBody, successPayload } = this.state;

    const protectedProfile = await protectedProfileObject.decryptedValue();
    const linkedContactList = Utils.arrayOrDefault<TLinkedContact>(protectedProfile.linkedContactList).filter(
      ({ id }) => !removeIdList.has(id)
    );

    const updatedValue: UserObjectProfileProtectedValue = { ...protectedProfile, linkedContactList };
    const updatedObject = await protectedProfileObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    successPayload.request.userObjects!.ids.push(protectedProfileObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForLinkedContact(): Promise<void> {
    this.logger.info('Adding an update operation to request body for updating linked contact.');

    const { roleAuthClaim: authState, currentUser, protectedProfileObject, requestBody, successPayload, updateLinkedIdList } = this.state;

    const protectedProfile = await protectedProfileObject.decryptedValue();
    const linkedContactList = [...Utils.arrayOrDefault<LinkedCareRecipientContact>(protectedProfile.linkedContactList)];

    const isAnyContactAvailable = linkedContactList.some(({ id }) => updateLinkedIdList.has(id));
    if (!isAnyContactAvailable) return;

    const { keyList } = await this.dispatchFetchObjects({
      authState,
      keysByType: [...updateLinkedIdList].map((guestUserId) => ({
        encryptedForId: currentUser.id,
        id: guestUserId,
        type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE
      }))
    });

    for (const guestUserId of updateLinkedIdList) {
      const guestPrivateKeyJSON = this.findKey(keyList, {
        encryptedForId: currentUser.id,
        id: guestUserId,
        type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE
      });

      if (Utils.isNil(guestPrivateKeyJSON)) {
        throw new Api.MalformedResponseException('Guest private key could not be fetched.');
      } else {
        const guestPrivateKey = new CryptographicKeyPrivate(guestPrivateKeyJSON);
        await CryptographicKey.cache(guestPrivateKey);
      }
    }

    const { userObjectList } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [...updateLinkedIdList].map((guestUserId) => ({
        type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
        userId: guestUserId
      }))
    });

    await Promise.all(
      [...updateLinkedIdList].map(async (guestUserId) => {
        const contactInfoJson = this.findUserObject(userObjectList, {
          type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
          userId: guestUserId
        });
        if (Utils.isNil(contactInfoJson)) {
          throw new Api.MalformedResponseException('Guest contact info could not be fetched.');
        }

        const contactInfoObject = new UserObjectContactInfo(contactInfoJson);
        const contactInfo = await contactInfoObject.decryptedValue();

        const index = linkedContactList.findIndex(({ id }) => id === guestUserId);
        if (index === -1) return;

        linkedContactList[index] = { ...linkedContactList[index], ...Utils.pick(contactInfo, Constants.PERSON_NAME_KEY_LIST) };
      })
    );

    const updatedValue: UserObjectProfileProtectedValue = { ...protectedProfile, linkedContactList };
    const updatedObject = await protectedProfileObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    successPayload.request.userObjects!.ids.push(protectedProfileObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }
}
