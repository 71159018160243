import { createSlice } from '@reduxjs/toolkit';
import { StoreStateAuthorization } from '@sigmail/app-state';
import { CryptographicKey } from '@sigmail/objects';
import { signOutAction } from '../actions';
import { CollectionObjectCache } from '../collection-objects-slice/cache';
import { DataObjectCache } from '../data-objects-slice/cache';
import { UserObjectCache } from '../user-objects-slice/cache';
import { authFailureReducer } from './auth-failure-reducer';
import { authSuccessActOnBehalfForReducer } from './auth-success-act-on-behalf-for-reducer';
import { authSuccessReducer } from './auth-success-reducer';
import { mfaEnableSuccessReducer } from './mfa-enable-success-reducer';
import { authSuccessOneTimeContactReducer } from './auth-success-one-time-contact-reducer';

export const INITIAL_STATE: StoreStateAuthorization = {
  scope: '',
  tokenType: '',
  accessToken: '',
  expiresIn: 0,
  refreshToken: '',
  idToken: '',
  authClaim: ''
};

const authorizationSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    authSuccess: authSuccessReducer,
    authFailure: authFailureReducer,
    mfaEnableSuccess: mfaEnableSuccessReducer,
    mfaDisableSuccess: ({ otpClaim, ...nextState }) => nextState,
    authSuccessActOnBehalfFor: authSuccessActOnBehalfForReducer,
    authSuccessOneTimeContact: authSuccessOneTimeContactReducer
  },
  extraReducers: {
    [signOutAction.type]: () => {
      CollectionObjectCache.clear();
      DataObjectCache.clear();
      UserObjectCache.clear();

      CryptographicKey.clearAllPrivateKeys();
      CryptographicKey.clearAllPublicKeys();

      return INITIAL_STATE;
    }
  }
});

export const {
  authSuccess,
  authFailure,
  mfaEnableSuccess,
  mfaDisableSuccess,
  authSuccessActOnBehalfFor,
  authSuccessOneTimeContact
} = authorizationSlice.actions;
export const { reducer: authStateReducer } = authorizationSlice;
