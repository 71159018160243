import { Nullable, Utils } from '@sigmail/common';
import { MessagingI18n } from '@sigmail/i18n';
import { WithT } from 'i18next';
import React from 'react';
import { MAX_VALUE_RECALL_MESSAGE_WINDOW } from '../../../../app-state/selectors/auth';
import { DialogAccept, DialogReject, SaveAsDraft } from '../../../../constants/action-ids';
import messagingI18n from '../../../../i18n/messaging';
import { resolveActionLabel } from '../../../../utils/resolve-action-label';
import { DialogActionsAcceptReject } from '../../../shared/dialog/actions/accept-reject.component';
import { DialogBoxProps } from '../../../shared/dialog/dialog-box.component';
import style from '../folder-view.module.css';

const { confirmation: dialogI18n } = messagingI18n.dialog;

export interface UseConfirmationDialogPropsParams extends WithT {
  actionLabelSaveAsDraft?: Nullable<string>;
  onClose?: DialogBoxProps['onClose'];
  onDialogActionClick?: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  openedDialog?: Nullable<`confirm${Capitalize<keyof typeof dialogI18n>}`>;
  recallMessageWindow?: Nullable<number>;
  renderAction?: Nullable<(actionId: string, actionLabel: string, className: string) => React.ReactElement | null>;
  selectedMessageCount?: number;
}

export const useConfirmationDialogProps = ({
  actionLabelSaveAsDraft,
  onDialogActionClick: onClick,
  onClose,
  open,
  openedDialog,
  recallMessageWindow,
  renderAction,
  selectedMessageCount: count,
  t
}: UseConfirmationDialogPropsParams) => {
  return React.useMemo(() => {
    const result: DialogBoxProps = { disableBackdropClick: true, disableEscapeKeyDown: true, open, onClose };
    if (open !== true) return result;

    switch (openedDialog) {
      case 'confirmCloseUnsentMessage': {
        const { closeUnsentMessage: i18n } = dialogI18n;
        const actionLabel = Utils.stringOrDefault(actionLabelSaveAsDraft);

        result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };
        result.ContentProps = { dangerouslySetInnerHTML: { __html: t(i18n.body) } };
        result.actions = (
          <React.Fragment>
            {typeof renderAction === 'function' && renderAction(SaveAsDraft, actionLabel, style['action-save-draft'])}
            <DialogActionsAcceptReject
              AcceptActionProps={{ children: t(resolveActionLabel(i18n.action.accept, DialogAccept)), onClick }}
              RejectActionProps={{ children: t(resolveActionLabel(i18n.action.reject, DialogReject)), onClick }}
            />
          </React.Fragment>
        );
        break;
      }
      case 'confirmDeleteMessage':
      case 'confirmDiscardDraft':
      case 'confirmSendMessageToEMR':
      case 'confirmHrmCancelMessage': {
        let i18n: Pick<MessagingI18n.Dialog.Confirmation.DiscardDraft, 'title' | 'body' | 'action'>;
        if (openedDialog === 'confirmDeleteMessage') {
          i18n = dialogI18n.deleteMessage;
        } else if (openedDialog === 'confirmDiscardDraft') {
          i18n = dialogI18n.discardDraft;
        } else if (openedDialog === 'confirmSendMessageToEMR') {
          i18n = dialogI18n.sendMessageToEMR;
        } else {
          i18n = dialogI18n.hrmCancelMessage;
        }

        result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };
        result.ContentProps = { dangerouslySetInnerHTML: { __html: t(i18n.body, { count }) } };
        result.actions = (
          <DialogActionsAcceptReject
            AcceptActionProps={{ children: t(resolveActionLabel(i18n.action.accept, DialogAccept)), onClick }}
            RejectActionProps={{ children: t(resolveActionLabel(i18n.action.reject, DialogReject)), onClick }}
          />
        );
        break;
      }
      case 'confirmRecallEvent':
      case 'confirmRecallMessage': {
        const i18n = openedDialog === 'confirmRecallEvent' ? dialogI18n.recallEvent : dialogI18n.recallMessage;

        let note = '';
        if (
          Utils.isInteger(recallMessageWindow) &&
          recallMessageWindow > 0 &&
          recallMessageWindow < MAX_VALUE_RECALL_MESSAGE_WINDOW
        ) {
          note = t(i18n.note, { count: recallMessageWindow });
        }

        const body = t(i18n.body, { count }).concat(note);
        result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };
        result.ContentProps = { dangerouslySetInnerHTML: { __html: body } };
        result.actions = (
          <DialogActionsAcceptReject
            AcceptActionProps={{ children: t(resolveActionLabel(i18n.action.accept, DialogAccept)), onClick }}
            RejectActionProps={{ children: t(resolveActionLabel(i18n.action.reject, DialogReject)), onClick }}
          />
        );
        break;
      }
      default: {
        break;
      }
    }

    return result;
  }, [actionLabelSaveAsDraft, onClick, onClose, open, openedDialog, recallMessageWindow, renderAction, count, t]);
};
