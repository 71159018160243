import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Form.EditProfile = {
  fieldsetGroup: {
    personalInfo: {
      // Translators: Label for the Personal Info fieldset group
      label: gettext('account', 'personalInfo', 'editProfileFormFieldsetGroupLabel'),
      fieldsetBirthDateGender: {
        formField: {
          birthDate: {
            // Translators: Label for the Birth Date input
            label: gettext('account', 'birthDate', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Birth Date is left \
              // blank
              valueMissing: gettext('account', 'birthDateRequired', 'editProfileFormError'),
              // Translators: Error message displayed when an invalid Birth \
              // Date is entered e.x. 2021-02-31
              badInput: gettext('account', 'birthDateBadInput', 'editProfileFormError')
            }
          },
          gender: {
            // Translators: Label for the Gender input
            label: gettext('account', 'gender', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Gender is not \
              // selected
              valueMissing: gettext('account', 'genderRequired', 'editProfileFormError')
            }
          }
        }
      },
      fieldsetDegreeSpecialty: {
        formField: {
          academicDegrees: {
            // Translators: Label for the Academic Degrees input
            label: gettext('account', 'academicDegrees', 'editProfileFormInputLabel')
          },
          specialty: {
            // Translators: Label for the Specialty input
            label: gettext('account', 'specialty', 'editProfileFormInputLabel'),
            // Translators: Label for that option which indicates that no \
            // specialty is selected
            placeholder: gettext('account', 'specialtyUnselected', 'editProfileFormOptionLabel'),
            error: {
              // Translators: Error message displayed when Specialty is not \
              // selected
              valueMissing: gettext('account', 'specialtyRequired', 'editProfileFormError')
            }
          }
        }
      },
      fieldsetHealthInsurance: {
        // Translators: Label for the Health Insurance fieldset
        label: gettext('account', 'healthInsurance', 'editProfileFormFieldsetLabel'),
        formField: {
          jurisdiction: {
            // Translators: Label for the Health Insurance Jurisdiction input
            label: gettext('account', 'healthInsuranceJurisdiction', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Health Insurance \
              // Jurisdiction is not selected
              valueMissing: gettext('account', 'healthInsuranceJurisdictionRequired', 'editProfileFormError')
            }
          },
          planNumber: {
            // Translators: Label for the Health Insurance Plan Number input
            label: gettext('account', 'healthInsurancePlanNumber', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Health Insurance \
              // Plan Number is left blank
              valueMissing: gettext('account', 'healthInsurancePlanNumberRequired', 'editProfileFormError'),
              // Translators: Error message displayed when Health Insurance \
              // Plan Number doesn't match the expected format
              patternMismatch: gettext('account', 'healthInsurancePlanNumberPattern', 'editProfileFormError')
            }
          }
        }
      },
      fieldsetLicenseBillingNumber: {
        formField: {
          licenseNumber: {
            // Translators: Label for the License Number input
            label: gettext('account', 'licenseNumber', 'editProfileFormInputLabel')
          },
          ohipBillingNumber: {
            // Translators: Label for the OHIP Billing Number input
            label: gettext('account', 'OHIPBillingNumber', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when OHIP Billing Number \
              // doesn't match the expected format
              patternMismatch: gettext('account', 'OHIPBillingNumberPattern', 'editProfileFormError')
            }
          }
        }
      },
      fieldsetName: {
        formField: {
          firstName: {
            // Translators: Label for the First Name input
            label: gettext('account', 'firstName', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when First Name is left \
              // blank
              valueMissing: gettext('account', 'firstNameRequired', 'editProfileFormError'),
              // Translators: Error message displayed when First Name doesn't \
              // satisfy minimum/maximum length requirement
              // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
              length: gettext('account', 'firstNameLength', 'editProfileFormError'),
              // Translators: Error message displayed when First Name contains \
              // nothing but white-space characters
              patternMismatch: gettext('account', 'firstNamePattern', 'editProfileFormError')
            }
          },
          lastName: {
            // Translators: Label for the Last Name input
            label: gettext('account', 'lastName', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Last Name is left \
              // blank
              valueMissing: gettext('account', 'lastNameRequired', 'editProfileFormError'),
              // Translators: Error message displayed when Last Name doesn't \
              // satisfy minimum/maximum length requirement
              // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
              length: gettext('account', 'lastNameLength', 'editProfileFormError'),
              // Translators: Error message displayed when Last Name contains \
              // nothing but white-space characters
              patternMismatch: gettext('account', 'lastNamePattern', 'editProfileFormError')
            }
          }
        }
      },
      fieldsetOtherSpecialties: {
        formField: {
          otherSpecialties: {
            // Translators: Label for the Other Specialties input
            label: gettext('account', 'otherSpecialties', 'editProfileFormInputLabel')
          }
        }
      },
    },
    contactInfo: {
      // Translators: Label for the Contact Info fieldset group
      label: gettext('account', 'contactInfo', 'editProfileFormFieldsetGroupLabel'),
      fieldsetAddress: {
        formField: {
          addressLine1: {
            // Translators: Label for the Address Line 1 input
            label: gettext('account', 'addressLine1', 'editProfileFormInputLabel')
          },
          addressLine2: {
            // Translators: Label for the Address Line 2 input
            label: gettext('account', 'addressLine2', 'editProfileFormInputLabel')
          },
          addressLevel1: {
            // Translators: Label for the First Administrative Level input \
            // (typically the province in which the address is located)
            label: gettext('account', 'addressLevel1', 'editProfileFormInputLabel'),
            // Translators: Label for that option which indicates that no \
            // province is selected
            placeholder: gettext('account', 'addressLevel1Unselected', 'editProfileFormOptionLabel')
          },
          addressLevel2: {
            // Translators: Label for the Second Administrative Level input \
            // (typically the city in which the address is located)
            label: gettext('account', 'addressLevel2', 'editProfileFormInputLabel')
          },
          postalCode: {
            // Translators: Label for the Postal Code input
            label: gettext('account', 'postalCode', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Postal Code doesn't \
              // match the expected format
              patternMismatch: gettext('account', 'postalCodePattern', 'editProfileFormError')
            }
          }
        }
      },
      fieldsetContact: {
        formField: {
          officeNumber: {
            // Translators: Label for the Office Number input
            label: gettext('account', 'officeNumber', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Office Number is \
              // left blank
              valueMissing: gettext('account', 'officeNumberRequired', 'editProfileFormError'),
              // Translators: Error message displayed when Office Number \
              // doesn't match the expected format
              patternMismatch: gettext('account', 'officeNumberPattern', 'editProfileFormError')
            }
          },
          officeNumberExt: {
            // Translators: Label for the Office Number Extension input
            label: gettext('account', 'officeNumberExt', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Office Number \
              // Extension doesn't match the expected format
              patternMismatch: gettext('account', 'officeNumberExtPattern', 'editProfileFormError')
            }
          },
          cellNumber: {
            // Translators: Label for the Cell Number input
            label: gettext('account', 'cellNumber', 'editProfileFormInputLabel'),
            helpBadgePopover: {
              title: '',
              // Translators: Message displayed to inform user that changing \
              // the cell number will disable their MFA.
              body: gettext('account', 'cellNumber', 'editProfileFormPopoverBody')
            },
            error: {
              // Translators: Error message displayed when Cell Number is left \
              // blank
              valueMissing: gettext('account', 'cellNumberRequired', 'editProfileFormError'),
              // Translators: Error message displayed when Cell Number doesn't \
              // match the expected format
              patternMismatch: gettext('account', 'cellNumberPattern', 'editProfileFormError')
            }
          },
          homeNumber: {
            // Translators: Label for the Home Number input
            label: gettext('account', 'homeNumber', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Cell Number doesn't \
              // match the expected format
              patternMismatch: gettext('account', 'homeNumberPattern', 'editProfileFormError')
            }
          },
          faxNumber: {
            // Translators: Label for the Fax Number input
            label: gettext('account', 'faxNumber', 'editProfileFormInputLabel'),
            error: {
              // Translators: Error message displayed when Fax Number doesn't \
              // match the expected format
              patternMismatch: gettext('account', 'faxNumberPattern', 'editProfileFormError')
            }
          },
          emailAddress: {
            // Translators: Label for the Email Address input
            label: gettext('account', 'emailAddress', 'editProfileFormInputLabel'),
            helpBadgePopover: {
              title: '',
              // Translators: Message displayed to inform user that changing \
              // the email address will disable their MFA.
              body: gettext('account', 'emailAddress', 'editProfileFormPopoverBody')
            },
            error: {
              // Translators: Error message displayed when Email Address is \
              // left blank
              valueMissing: gettext('account', 'emailAddressRequired', 'editProfileFormError'),
              // Translators: Error message displayed when Email Address \
              // doesn't satisfy minimum/maximum length requirement
              // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
              length: gettext('account', 'emailAddressLength', 'editProfileFormError'),
              // Translators: Error message displayed when Email Address \
              // contains invalid characters
              patternMismatch: gettext('account', 'emailAddressPattern', 'editProfileFormError')
            }
          }
        }
      }
    }
  }
};

export default i18n;
