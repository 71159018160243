import { Utils } from '@sigmail/common';
import { BackendOptions } from 'i18next-xhr-backend';

const JSON_FILE_EXT = 'json';
const MARKDOWN_FILE_EXT = 'md';
const LOAD_PATH_JSON = `${process.env.PUBLIC_URL}/locale/{{lng}}/{{ns}}.${JSON_FILE_EXT}`;
const LOAD_PATH_MARKDOWN = `${process.env.PUBLIC_URL}/locale/{{lng}}/{{ns}}.${MARKDOWN_FILE_EXT}`;
const MARKDOWN_CONTENT_NAMESPACES: string[] = [];

const backend: BackendOptions = {
  withCredentials: true,

  loadPath(languages: string[], namespaces: string[]) {
    if (languages.length > 1 || namespaces.length > 1) {
      throw new Error('Multi-loading is not supported.');
    }

    let loadPath = LOAD_PATH_JSON;
    if (MARKDOWN_CONTENT_NAMESPACES.indexOf(namespaces[0]) !== -1) {
      loadPath = LOAD_PATH_MARKDOWN;
    }

    const hash = process.env.I18N_NS_HASH;
    if (Utils.isString(hash) && hash.length > 0) {
      loadPath = loadPath.replace('{{ns}}', `{{ns}}.${hash}`);
    }

    return loadPath;
  }
};

export default backend;
