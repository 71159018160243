import { AppUser, AppUserGroup, MembershipStatus, Nullable, Utils } from '@sigmail/common';
import { ContactListItemExt } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFlattenedClientUserList } from '.';
import membershipStatusI18n from '../../i18n/global/membership-status';
import {
  BatchQueryRequest,
  compareContactListItem,
  ExcludeListItem,
  filterContactList,
  FilterFunction
} from '../../utils/contact-list';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { activeGroupIdSelector } from '../selectors';
import { currentUserIdSelector, selectAccessRight } from '../selectors/auth';
import { guestListObjectSelector } from '../selectors/group-object';
import { UserObjectCache } from '../user-objects-slice/cache';

export interface UseGroupGuestContactListParams {
  exclude?: Nullable<ReadonlyArray<Nullable<ExcludeListItem>>>;
  filterFn?: Nullable<FilterFunction<ContactListItemExt>>;
  forceFetch?: Nullable<boolean>;
  groupId?: Nullable<number>;
  groups?: Nullable<boolean>;
  unsorted?: Nullable<boolean>;
  users?: Nullable<boolean | MembershipStatus>;
}

export type UseGroupGuestContactListResult = [list: ReadonlyArray<ContactListItemExt>, query: BatchQueryRequest];

const isValidMembershipStatusKey = (value?: any): value is MembershipStatus =>
  Object.keys(membershipStatusI18n).includes(value);

export const useGroupGuestContactList = (params?: Nullable<UseGroupGuestContactListParams>) => {
  let groupId = useSelector(activeGroupIdSelector);
  if (Utils.isNotNil(params?.groupId)) groupId = params!.groupId!;

  const canAccessContactList = useSelector(selectAccessRight)('accessCircleOfCare');
  const currentUserId = useSelector(currentUserIdSelector);
  const groupGuestListSelector = useSelector(guestListObjectSelector);
  const groupGuestListObject = groupGuestListSelector(groupId);
  const groupGuestList = params?.forceFetch === true ? undefined : UserObjectCache.getValue(groupGuestListObject);

  const includeGroupList = params?.groups === true;
  const includeUserList = params?.users === true || isValidMembershipStatusKey(params?.users) ? params!.users! : false;
  const exclude = params?.exclude;
  const filterFn = typeof params?.filterFn === 'function' ? params!.filterFn! : filterContactList;
  const unsorted = params?.unsorted === true;

  const [flattenedUserList, userListQuery] = useFlattenedClientUserList({
    disabledMembershipList: true,
    users: includeUserList
  });

  return React.useMemo((): UseGroupGuestContactListResult => {
    let contactList: ReadonlyArray<ContactListItemExt> = EMPTY_ARRAY;

    const userObjects: Required<BatchQueryRequest>['userObjectsByType'] = [];
    if (Utils.isArray(userListQuery.userObjectsByType)) {
      userObjects.push(...userListQuery.userObjectsByType);
    }

    do {
      if (!canAccessContactList) break;
      if (!includeUserList && !includeGroupList) break;
      if (!AppUser.isValidId(currentUserId)) break;
      if (!AppUserGroup.isValidId(groupId)) break;
      if (userObjects.length > 0) break;

      if (Utils.isNil(groupGuestList)) {
        userObjects.push({ type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST, userId: groupId });
        break;
      }

      const { list } = groupGuestList;
      contactList = list.reduce((result, contact) => {
        if (contact.type === 'group') {
          if (includeGroupList) {
            result.push(contact);
          }
        } else if (Utils.isNil(contact.userData.memberType)) {
          const index = flattenedUserList!.findIndex(({ id }) => id === contact.id);
          if (index > -1) {
            const { timestamp: accountTimestamp, ...extUserData } = flattenedUserList![index];
            if (includeUserList === true || extUserData.accountStatus === includeUserList) {
              result.push({
                ...contact,
                userData: {
                  ...contact.userData,
                  ...extUserData,
                  accountTimestamp
                }
              });
            }
          }
        } else {
          result.push({
            ...contact,
            userData: {
              ...contact.userData,
              accountStatus: 'active'
            }
          });
        }
        return result;
      }, [] as Array<typeof contactList[0]>);

      contactList = filterFn(contactList, { exclude });

      if (!unsorted && contactList.length > 1) {
        contactList = contactList.slice().sort(compareContactListItem);
      }
    } while (false);

    let query: UseGroupGuestContactListResult[1] = EMPTY_PLAIN_OBJECT;
    if (userObjects.length > 0) query = { userObjectsByType: userObjects };

    return [contactList, query];
  }, [
    canAccessContactList,
    groupGuestList,
    currentUserId,
    exclude,
    filterFn,
    flattenedUserList,
    groupId,
    includeGroupList,
    includeUserList,
    unsorted,
    userListQuery.userObjectsByType
  ]);
};
