import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.View.MessageMetadata['subjectLine'] = {
  // Translators: Subject line for a Phone Consultation request
  consultationRequestK730: gettext('messaging', 'consultationRequestK730', 'subjectLine'),
  // Translators: Subject line for a E-Consultation request
  consultationRequestK738: gettext('messaging', 'consultationRequestK738', 'subjectLine'),
  // Translators: Text displayed as subject when message has an empty subject \
  // line
  empty: gettext('messaging', 'empty', 'subjectLine'),
  // Translators: Subject line for an event attendance message
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  eventAttendanceMessage: gettext('messaging', 'eventAttendanceMessage', 'subjectLine'),
  // Translators: Subject line for a recalled message
  recalledMessage: gettext('messaging', 'recalledMessage', 'subjectLine'),
  // Translators: Subject line for an accepted referral response
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  acceptedReferral: gettext('messaging', 'acceptedReferral', 'subjectLine'),
  // Translators: Subject line for an declined referral response
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  declinedReferral: gettext('messaging', 'declinedReferral', 'subjectLine'),
  // Translators: Subject line for an accepted event response
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  acceptedEvent: gettext('messaging', 'acceptedEvent', 'subjectLine'),
  // Translators: Subject line for an declined event response
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  declinedEvent: gettext('messaging', 'declinedEvent', 'subjectLine'),
  // Translators: Subject line for an accepted group invitation response
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  acceptedGroupInvite: gettext('messaging', 'acceptedGroupInvite', 'subjectLine'),
  // Translators: Subject line for an declined group invitation response
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  declinedGroupInvite: gettext('messaging', 'declinedGroupInvite', 'subjectLine'),
  // Translators: Subject line for an HRM response
  // Translators: Token(s) available: {{PERSON_NAME}}
  hrmMessage: gettext('messaging', 'hrmMessage', 'subjectLine'),
  // Translators: Subject line for a cancelled HRM message
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  canceledHrmMessage: gettext('messaging', 'canceledHrmMessage', 'subjectLine'),
  // Translators: Subject line for a message marked as Confidential
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  confidentialSensitivity: gettext('messaging', 'confidentialSensitivity', 'subjectLine'),
  // Translators: Subject line for a message marked as Personal
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  personalSensitivity: gettext('messaging', 'personalSensitivity', 'subjectLine'),
  // Translators: Subject line for a message marked as Private
  // Translators: Token(s) available: {{SUBJECT_LINE}}
  privateSensitivity: gettext('messaging', 'privateSensitivity', 'subjectLine')
};

export default i18n;
