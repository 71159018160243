import { IconButton, useMediaQuery } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Alert } from '@material-ui/lab';
import { AnyAction } from '@reduxjs/toolkit';
import { Constants, Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RouterAction } from 'sigmail';
import { signOutAction } from '../../app-state/actions';
import { processNotificationsAction } from '../../app-state/actions/account/process-notifications-action';
import { authSuccessActOnBehalfFor } from '../../app-state/auth-slice';
import { setCaregiverMode } from '../../app-state/caregiver-slice';
import { useAppState, useCurrentUser, useInterval } from '../../app-state/hooks';
import {
  caregiverModeSelector,
  isUserLoggedInSelector,
  selectAccessRight,
  selectCanActivateMemberOfRole
} from '../../app-state/selectors/auth';
import * as ActionId from '../../constants/action-ids';
import { CIRCLE_OF_CARE } from '../../constants/medical-institute-user-group-type-identifier';
import { useContactListResource, useNextId } from '../../hooks';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { UITheme } from '../../ui-theme';
import { DIALOG_SEND_GUEST_ACCOUNT_INVITATION } from '../account/constants';
import { closeDialogAction as closeAccountDialogAction, openDialogAction } from '../account/constants/actions';
import { DialogSendGuestAccountInvitation } from '../account/dialog/send-guest-account-invitation.component';
import { useActionClickHandler } from '../account/hooks';
import { closeDialogAction as closeMessagingDialogAction } from '../messaging/folder-view/context/actions';
import { useActionClickHandler as useMessagingActionHandler } from '../messaging/folder-view/hooks';
import { OwnScheduleReminderNotification } from '../scheduling/own-schedule/reminder-notification.component';
import { Backdrop } from '../shared/backdrop.component';
import { CircularProgress } from '../shared/circular-progress.component';
import { DialogBox } from '../shared/dialog/dialog-box.component';
import { ErrorMessageSnackbar } from '../shared/error-message-snackbar.component';
import { ActiveGroupSelectionBar } from './active-group-selection-bar/active-group-selection-bar.component';
import { AppDrawer } from './app-drawer/app-drawer.component';
import { AppFooter } from './app-footer.component';
import { AppHeader } from './app-header.component';
import style from './app-layout.module.css';
import { ContextValue, DEFAULT_CONTEXT_VALUE, LayoutContextProvider } from './context';
import { BillingFileForm } from './form-billing-file.component';
import {
  DIALOG_APP_OFFLINE,
  DIALOG_BILLING_FILE,
  useAppOfflineQuery,
  useBillingFileFormState,
  useDialogProps,
  useDisplayModeQuery
} from './hooks';

export interface Props extends React.ComponentPropsWithoutRef<'div'> {}

export const AppLayout = React.forwardRef<HTMLDivElement, Props>(({ children, ...rootProps }, ref) => {
  const { i18n, t } = useTranslation([I18N_NS_GLOBAL]);
  const router = useHistory();
  const location = useLocation();
  const [appState, appDispatch] = useAppState();
  const hasAccessRight = useSelector(selectAccessRight);
  const roleId = useCurrentUser()?.role;
  const isUserCaregiverRole = Utils.isCaregiverRole(roleId);
  const isCareGiverMode = useSelector(caregiverModeSelector);
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  const isAppOffline = useAppOfflineQuery();
  const displayMode = useDisplayModeQuery();
  const isDisplayModeStandalone = displayMode === 'standalone';
  const isDisplayModeWindowControlsOverlay = !isDisplayModeStandalone && displayMode === 'window-controls-overlay';
  const isScreenSmallAndUp = useMediaQuery((theme: typeof UITheme) => theme.breakpoints.up('sm'), { noSsr: true });
  const isScreenMediumAndUp = useMediaQuery((theme: typeof UITheme) => theme.breakpoints.up('md'), { noSsr: true });

  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const toggleDrawer = React.useCallback(() => setDrawerOpen((open) => !open), []);
  const [openedDialog, setOpenedDialog] = React.useState<string>();
  const closeDialog = React.useCallback(() => setOpenedDialog(undefined), []);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>();
  const closeSnackbar = React.useCallback(() => setSnackbarMessage(undefined), []);

  const isHomePath = React.useMemo(() => location.pathname === '/', [location]);

  const dispatch = React.useCallback<React.Dispatch<AnyAction>>(
    (action) => {
      if (openDialogAction.match(action)) {
        setOpenedDialog(action.payload);
      } else if (closeAccountDialogAction.match(action) || closeMessagingDialogAction.match(action)) {
        closeDialog();
      }
    },
    [closeDialog]
  );

  const canAccessMailbox = hasAccessRight('accessMailbox') || hasAccessRight('accessGroupMailbox');
  const canAccessCircleOfCare = hasAccessRight('accessCircleOfCare');
  const canAccessClientContacts = hasAccessRight('accessClientContacts');
  const canAccessGlobalContacts = hasAccessRight('accessGlobalContacts');
  const canAccessContacts = canAccessCircleOfCare || canAccessClientContacts || canAccessGlobalContacts;
  const canAccessOwnSchedule = hasAccessRight('accessOwnSchedule');
  const canAccessGroupSchedule = hasAccessRight('accessGroupSchedule');
  const canAccessSchedule = canAccessOwnSchedule || canAccessGroupSchedule;
  const canInviteGuest = useSelector(selectCanActivateMemberOfRole)(Constants.ROLE_ID_GUEST);

  const onClickNavLink = React.useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      const actionId = event.currentTarget.getAttribute('data-action-id');
      switch (actionId) {
        case ActionId.BillingFile: {
          event.preventDefault();
          event.stopPropagation();

          dispatch(openDialogAction(DIALOG_BILLING_FILE));
          break;
        }
        case ActionId.FAQ:
        case ActionId.PrivacyPolicy:
        case ActionId.TermsAndConditions:
        case ActionId.ContactUs: {
          event.preventDefault();
          event.stopPropagation();

          const { to: pathname } = globalI18n.action[actionId] as RouterAction;
          if (Utils.isString(pathname)) setTimeout(() => router.push(pathname));

          break;
        }
        case ActionId.LanguageToggle: {
          event.preventDefault();
          event.stopPropagation();

          const language = event.currentTarget.getAttribute('data-lang');
          if (Utils.isString(language) && globalI18n.languageName.findIndex(({ code }) => code === language) > -1) {
            void i18n.changeLanguage(language);
          }

          break;
        }
        case ActionId.SendGuestAccountInvitation: {
          event.preventDefault();
          event.stopPropagation();

          dispatch(openDialogAction(DIALOG_SEND_GUEST_ACCOUNT_INVITATION));
          break;
        }
        case ActionId.SignOut: {
          event.preventDefault();
          event.stopPropagation();

          setTimeout(() => appDispatch(signOutAction()));
          break;
        }
        default: {
          break;
        }
      }

      setDrawerOpen(false);
    },
    [appDispatch, dispatch, i18n, router]
  );

  const headerNavActionIdList = React.useMemo<ReadonlyArray<string>>(() => {
    if (!isUserLoggedIn || !isScreenMediumAndUp) return [ActionId.LanguageToggle];

    return [
      canAccessMailbox && ActionId.Mailbox,
      canAccessSchedule && ActionId.Schedule,
      canAccessContacts && ActionId.ContactList,
      canInviteGuest && ActionId.SendGuestAccountInvitation,
      ActionId.UserAccountMenu,
      ActionId.LanguageToggle
    ].filter(Utils.isString);
  }, [canAccessContacts, canAccessMailbox, canAccessSchedule, canInviteGuest, isScreenMediumAndUp, isUserLoggedIn]);

  //
  //#region App Drawer
  const appDrawerId = useNextId('app-drawer-');
  const appDrawerButton = React.useMemo<React.ReactNode>(() => {
    return (
      isUserLoggedIn &&
      !isScreenMediumAndUp && (
        <React.Fragment>
          <IconButton
            aria-haspopup={true}
            aria-controls={appDrawerId}
            aria-label={t(isDrawerOpen ? globalI18n.ariaLabelCloseDrawer : globalI18n.ariaLabelOpenDrawer)}
            edge="start"
            color="inherit"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>

          <AppDrawer id={appDrawerId} onClose={toggleDrawer} onClickNavLink={onClickNavLink} open={isDrawerOpen} />
        </React.Fragment>
      )
    );
  }, [appDrawerId, isDrawerOpen, isScreenMediumAndUp, isUserLoggedIn, onClickNavLink, t, toggleDrawer]);
  //
  //#endregion

  const contextValue = React.useMemo<ContextValue>(
    () => ({ ...DEFAULT_CONTEXT_VALUE, displayMode, isAppOffline, isScreenMediumAndUp, isScreenSmallAndUp }),
    [displayMode, isAppOffline, isScreenMediumAndUp, isScreenSmallAndUp]
  );

  //
  //#region Action handler and Dialog box properties/callbacks
  const [action, actionHandler] = useActionClickHandler({ dispatch, openedDialog, setSnackbarMessage, t });
  const [messagingAction, messagingActionHandler] = useMessagingActionHandler({ dispatch, setSnackbarMessage, t });
  const isActionExecuting = Utils.isNotNil(action) || Utils.isNotNil(messagingAction);

  const onDialogActionClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const actionId = event.currentTarget.getAttribute('data-action-id');
      if (!Utils.isString(actionId)) return;

      actionHandler(actionId);
    },
    [actionHandler]
  );

  const isDialogOpen = Utils.isString(openedDialog);
  const isAppOfflineDialogOpen = isDialogOpen && openedDialog === DIALOG_APP_OFFLINE;
  const isInviteGuestDialogOpen =
    isDialogOpen && !isAppOfflineDialogOpen && openedDialog === DIALOG_SEND_GUEST_ACCOUNT_INVITATION;
  const isBillingFileDialogOpen =
    isDialogOpen && !isAppOfflineDialogOpen && !isInviteGuestDialogOpen && openedDialog === DIALOG_BILLING_FILE;
  const {
    props: billingFileFormProps,
    formId: billingFileFormId,
    formSubmitting: billingFileFormSubmitting,
    setFormRef: billingFileFormRef
  } = useBillingFileFormState(isBillingFileDialogOpen ? { actionClickHandler: messagingActionHandler, t } : undefined);

  let dialogBody: React.ReactNode;
  if (isAppOfflineDialogOpen) {
    dialogBody = (
      <Alert classes={{ root: style.alert, icon: style['alert-icon'] }} severity="warning" variant="outlined">
        <div dangerouslySetInnerHTML={{ __html: t(globalI18n.dialog.appOffline.body) }} />
      </Alert>
    );
  } else if (isBillingFileDialogOpen) {
    dialogBody = <BillingFileForm {...billingFileFormProps} ref={billingFileFormRef} />;
  }

  const dialogProps = useDialogProps({
    body: dialogBody,
    formId: Utils.stringOrDefault(isBillingFileDialogOpen && billingFileFormId, undefined!),
    formSubmitting: billingFileFormSubmitting,
    onClose: closeDialog,
    onDialogActionClick: isBillingFileDialogOpen ? closeDialog : onDialogActionClick,
    open: isDialogOpen && !isInviteGuestDialogOpen,
    openedDialog,
    t
  });
  //#endregion
  //

  React.useEffect(() => (isAppOffline ? setOpenedDialog(DIALOG_APP_OFFLINE) : closeDialog()), [
    closeDialog,
    isAppOffline
  ]);

  useInterval(
    React.useCallback(() => {
      appDispatch(processNotificationsAction({ type: 'profileUpdate' }));
    }, [appDispatch]),
    canAccessCircleOfCare && !isAppOffline ? (process.env.REACT_APP_ENV === 'production' ? 300000 : 15000) : null
  );

  const exitCaregiverMode = React.useCallback(() => {
    appDispatch(authSuccessActOnBehalfFor(appState.caregiver.authClaim));
    appDispatch(setCaregiverMode());

    router.push('/');
  }, [appDispatch, appState.caregiver.authClaim, router]);

  const contactListResource = useContactListResource({
    include: !isUserLoggedIn
      ? undefined
      : {
          membershipGroupContactList: { groupType: CIRCLE_OF_CARE },
          linkedContactList: { users: true }
        }
  });

  return (
    <React.Fragment>
      <Backdrop open={isActionExecuting} style={{ backgroundColor: 'rgba(255,255,255,0.33)' }}>
        <CircularProgress />
      </Backdrop>

      <ErrorMessageSnackbar
        closeText={t(globalI18n.ariaLabelClosePopup)}
        message={snackbarMessage!}
        onClose={closeSnackbar}
        open={Utils.isString(snackbarMessage)}
        severity="error"
      />

      <div className={isUserLoggedIn ? 'root' : style.root} ref={ref} {...rootProps}>
        {isDisplayModeWindowControlsOverlay ? (
          <>
            <AppHeader variant="titlebar" />
            {isUserLoggedIn && (
              <AppHeader
                actionIdList={isScreenMediumAndUp ? headerNavActionIdList : undefined}
                appDrawerButton={appDrawerButton}
                onClickNavLink={onClickNavLink}
                variant="default"
              />
            )}
          </>
        ) : (
          <AppHeader
            actionIdList={headerNavActionIdList}
            appDrawerButton={appDrawerButton}
            onClickNavLink={onClickNavLink}
            variant="default"
          />
        )}

        {isUserLoggedIn && isCareGiverMode && (
          <Alert severity="error">
            Caregiver mode is active, to switch back{' '}
            <button onClick={exitCaregiverMode} className={style['exit-button']}>
              click here
            </button>
          </Alert>
        )}

        {isUserLoggedIn && <ActiveGroupSelectionBar contactListResource={contactListResource} />}

        <LayoutContextProvider value={contextValue}>{children}</LayoutContextProvider>

        {isHomePath && isUserLoggedIn && isUserCaregiverRole && (
          <div className={style['empty-screen']}>
            <p>
              {Utils.isNonEmptyArray(contactListResource.value())
                ? t(globalI18n.appLayout.noCareRecipientSelected)
                : t(globalI18n.appLayout.emptyCareRecipientList)}
            </p>
          </div>
        )}

        <AppFooter />
      </div>

      {isUserLoggedIn && <OwnScheduleReminderNotification />}

      <DialogBox {...dialogProps} />

      <DialogSendGuestAccountInvitation
        onClickAction={actionHandler}
        onClose={dialogProps.onClose}
        open={isInviteGuestDialogOpen}
      />
    </React.Fragment>
  );
});

AppLayout.displayName = 'AppLayout';
