import { Constants, MessagingException, Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { submitHealthDataAction } from '../../../app-state/actions/messaging/submit-health-data-action';
import { useAppState } from '../../../app-state/hooks';
import { userRoleSelector } from '../../../app-state/selectors/user-object';
import { useContactListResource } from '../../../hooks';
import { ContactListItemUtil } from '../../../utils/contact-list';
import { ActionDataSubmitHealthData } from '../types';

export const useSubmitHealthDataActionHandler = () => {
  const role = useSelector(userRoleSelector)(/***/);

  const contactListResource = useContactListResource({
    include: {
      clientContactList: { groups: Utils.isNonGuestRole(role) },
      guestContactList: { groups: Utils.isGuestRole(role) }
    }
  });

  const [, appDispatch] = useAppState();
  return React.useCallback(
    async ({
      currentFolder,
      dataForm,
      failCallback,
      guestContact,
      recipientId,
      sourceMessage,
      t,
      successCallback,
      ...values
    }: ActionDataSubmitHealthData) => {
      let value: any;
      try {
        if (Utils.isNotNil(sourceMessage) && !Utils.isHealthDataRequestMessageForm(sourceMessage.messageForm)) {
          throw new MessagingException(
            `Expected <sourceMessage> to be of form <${Constants.MessageFormName.HealthDataRequest}>.`
          );
        }

        let contactList: ReturnType<typeof contactListResource.value>;
        for (;;) {
          try {
            contactList = contactListResource.value();
            break;
          } catch (error) {
            if (!(error instanceof Promise)) {
              throw error;
            }
          }
        }

        const recipient = contactList.find(({ type, id }) => type === 'group' && id === recipientId);
        if (Utils.isNil(recipient)) {
          throw new MessagingException(
            `No recipient with type <group> and id <${recipientId}> could be found in the contact list.`
          );
        }

        value = await appDispatch(
          submitHealthDataAction({
            ...values,
            dataForm,
            folderKey: Constants.MessageFolderKey.Inbox,
            guestContact,
            recipientList: [{ entity: ContactListItemUtil.toMessageRecipient(recipient, 'primary') }],
            sourceMessage,
            t
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch, contactListResource]
  );
};
