import { Constants, NonNullableProps, Nullable, SigmailGroupId, SigmailUserId, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { useAppState, useDispatchFetchUserObjects } from '.';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { oscarEMRCheckAuthStatus } from '../actions/EMR/oscar/check-auth-status';
import { oscarEMRGetOAuthUrl } from '../actions/EMR/oscar/get-oauth-url';
import { oscarEMRSearchPatient } from '../actions/EMR/oscar/search-patient';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { activeGroupIdSelector } from '../selectors';
import { currentUserIdSelector } from '../selectors/auth';
import { preferencesObjectSelector as groupPreferencesSelector } from '../selectors/group-object';
import { UserObjectCache } from '../user-objects-slice/cache';

export interface Params {
  readonly birthDate?: Nullable<string>;
  readonly firstName?: Nullable<string>;
  readonly healthPlanNumber?: Nullable<string>;
  readonly lastName?: Nullable<string>;
}

const HEALTH_PLAN_JURISDICTION_LIST = Object.values(Constants.HealthPlanJurisdiction);
const cancelable = Utils.makeCancelablePromise;

export type Resource = ISuspenseResource<ResourceValue>;

export interface ResourceValue {
  readonly auth: Readonly<{
    requestKey: number;
    config: Nullable<Api.OscarEMROAuthParams>;
    recheckAuth: () => void;
    status: boolean;
    url: string;
  }>;
  readonly list: Api.OscarEMRSearchPatientResponse;
}

interface State {
  readonly authRequestKey: ResourceValue['auth']['requestKey'];
  readonly authStatus: ResourceValue['auth']['status'];
  readonly authUrl: ResourceValue['auth']['url'];
  readonly resourceValue?: ResourceValue['list'];
}

interface Refs extends Readonly<Partial<NonNullableProps<Params>>> {
  readonly authCheckCompleteForUserId?: SigmailUserId;
  readonly authRequestKey?: number;
  readonly groupId?: SigmailGroupId;
}

const DEFAULT_RESOURCE_VALUE: NonNullable<State['resourceValue']> = EMPTY_ARRAY;
const INITIAL_STATE: State = { authRequestKey: 1, authStatus: false, authUrl: '' };

export const useSearchOscarEMRPatientListResource = (params?: Nullable<Params> | false): Resource => {
  const noop = !Utils.isNonArrayObjectLike<Params>(params);
  const birthDate = Utils.trimOrDefault(!noop && params.birthDate, undefined!);
  const firstName = Utils.trimOrDefault(!noop && params.firstName, undefined!);
  const healthPlanNumber = Utils.stringOrDefault(!noop && params.healthPlanNumber).replaceAll(/\D/g, '');
  const lastName = Utils.trimOrDefault(!noop && params.lastName, undefined!);

  const groupId = useSelector(activeGroupIdSelector);
  const groupPrefsObject = useSelector(groupPreferencesSelector)(Utils.numberOrDefault(!noop && groupId));
  const groupPrefs = UserObjectCache.getValue(groupPrefsObject);
  const isGroupPrefsAvailable = Utils.isNotNil(groupPrefsObject) && Utils.isNotNil(groupPrefs);
  const emrConfig = groupPrefs?.integrations?.oscar!;
  const oauthParams = React.useMemo<Api.OscarEMROAuthParams | null>(() => {
    if (Utils.isNonArrayObjectLike(emrConfig) && Utils.isNonArrayObjectLike(emrConfig.oauthParams)) {
      const { clientName, uriAuthorize, uriGetToken, uriInitRequest, ...authParams } = emrConfig.oauthParams!;
      return {
        authorizationUri: uriAuthorize,
        name: clientName,
        temporaryCredentialUri: uriInitRequest,
        tokenUri: uriGetToken,
        ...authParams
      };
    }
    return null;
  }, [emrConfig]);

  const userId = useSelector(currentUserIdSelector)!;
  const [, appDispatch] = useAppState();
  const fetchObjects = useDispatchFetchUserObjects();

  const [{ authRequestKey, authStatus, authUrl, resourceValue }, setState] = React.useState(INITIAL_STATE);
  const refs = React.useRef<Refs>(EMPTY_PLAIN_OBJECT);

  React.useEffect(() => {
    if (noop) {
      refs.current = EMPTY_PLAIN_OBJECT;
      return setState(INITIAL_STATE);
    }

    if (refs.current.groupId !== groupId) {
      refs.current = { groupId };

      if (!isGroupPrefsAvailable) {
        const promise = cancelable(
          fetchObjects({
            userObjects: [{ type: process.env.GROUP_OBJECT_TYPE_PREFERENCES, userId: groupId }]
          })
        );
        promise.promise.catch(Utils.noop);
        return () => promise.cancel();
      }
    }

    const isEMRConfigAvailable = Utils.isNotNil(oauthParams);
    if (!isEMRConfigAvailable) {
      return setState({ ...INITIAL_STATE, resourceValue: DEFAULT_RESOURCE_VALUE });
    }

    const userIdChanged = refs.current.authCheckCompleteForUserId !== userId;
    if (userIdChanged || refs.current.authRequestKey !== authRequestKey) {
      let requestKey = userIdChanged ? 1 : authRequestKey;
      refs.current = { ...refs.current, authCheckCompleteForUserId: userId, authRequestKey: requestKey };
      setState({ ...INITIAL_STATE, authRequestKey: requestKey });

      const checkAuthStatus = async (): Promise<void> => {
        let isAuthenticated = false;
        let url = '';

        try {
          isAuthenticated = await appDispatch(oscarEMRCheckAuthStatus({ oauthParams, userId }));
          if (!isAuthenticated) {
            url = await appDispatch(oscarEMRGetOAuthUrl({ oauthParams, userId }));
          }
        } catch {
          /* ignore */
        }

        let resourceValue: State['resourceValue'];
        if (!isAuthenticated) {
          resourceValue = DEFAULT_RESOURCE_VALUE;
        }

        setState((state) => ({ ...state, authStatus: isAuthenticated, authUrl: url, resourceValue }));
      };

      const promise = cancelable(checkAuthStatus());
      promise.promise.catch(Utils.noop);
      return () => promise.cancel();
    }

    if (
      authStatus &&
      (refs.current.birthDate !== birthDate ||
        refs.current.firstName !== firstName ||
        refs.current.healthPlanNumber !== healthPlanNumber ||
        refs.current.lastName !== lastName)
    ) {
      refs.current = { ...refs.current, birthDate, firstName, healthPlanNumber, lastName };

      if (healthPlanNumber.length === 0) {
        return setState((state) => ({ ...state, resourceValue: DEFAULT_RESOURCE_VALUE }));
      }

      const searchPatientList = (patientList: Api.OscarEMRSearchPatientResponse): void => {
        try {
          const list: Array<typeof patientList[0]> = [];
          for (const record of patientList) {
            try {
              if (!record.hin?.startsWith(healthPlanNumber)) continue;
              if (!Utils.isInteger(record.demographicNo)) continue;

              let jurisdiction: Nullable<string> = Utils.trimOrDefault(record.hcType);
              if (jurisdiction.length === 0) {
                jurisdiction = null;
              } else {
                const match = HEALTH_PLAN_JURISDICTION_LIST.find((item) => {
                  const [, code] = item.split('$').map((value) => value.trim());
                  return jurisdiction === Utils.stringOrDefault(Utils.isString(code) && code.length > 0 && code, item);
                });
                if (Utils.isString(match)) jurisdiction = match;
              }

              list.push({ ...record, hcType: jurisdiction });
            } catch {
              /* ignore */
            }
          }
          setState((state) => ({ ...state, resourceValue: list }));
        } catch {
          setState((state) => ({ ...state, resourceValue: DEFAULT_RESOURCE_VALUE }));
        }
      };

      const promise = cancelable(
        appDispatch(
          oscarEMRSearchPatient({
            dob: birthDate,
            firstName,
            hcn: healthPlanNumber,
            lastName,
            oauthParams,
            userId
          })
        ).catch(() => DEFAULT_RESOURCE_VALUE)
      );

      promise.promise.then(searchPatientList, Utils.noop);
      return () => promise.cancel();
    }
  }, [
    appDispatch,
    authRequestKey,
    authStatus,
    birthDate,
    fetchObjects,
    firstName,
    groupId,
    healthPlanNumber,
    isGroupPrefsAvailable,
    lastName,
    noop,
    oauthParams,
    userId
  ]);

  return React.useMemo<Resource>(() => {
    if (Utils.isNil(resourceValue)) return UNRESOLVED_RESOURCE;

    let recheckAuth: ResourceValue['auth']['recheckAuth'] = Utils.noop;
    if (!authStatus) {
      recheckAuth = () => {
        setState(({ authRequestKey, resourceValue, ...state }) => ({
          ...state,
          authRequestKey: authRequestKey + 1
        }));
      };
    }

    return {
      value: (): ResourceValue => ({
        auth: {
          config: oauthParams,
          requestKey: authRequestKey,
          recheckAuth,
          status: authStatus,
          url: authUrl!
        },
        list: resourceValue
      })
    };
  }, [authRequestKey, authStatus, authUrl, oauthParams, resourceValue]);
};
