import { AccountI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';

const i18n: AccountI18n.Form.SendNonGuestContactInvitation = {
  fieldsetName: {
    formField: {
      firstName: {
        // Translators: Label for the First Name input
        label: gettext('account', 'firstName', 'sendNonGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when First Name is left \
          // blank
          valueMissing: gettext('account', 'firstNameRequired', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when First Name doesn't \
          // satisfy minimum/maximum length requirement
          // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
          length: gettext('account', 'firstNameLength', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when First Name contains \
          // invalid characters
          patternMismatch: gettext('account', 'firstNamePattern', 'sendNonGuestAccountInvitationFormError')
        }
      },
      lastName: {
        // Translators: Label for the Last Name input
        label: gettext('account', 'lastName', 'sendNonGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Last Name is left \
          // blank
          valueMissing: gettext('account', 'lastNameRequired', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Last Name doesn't \
          // satisfy minimum/maximum length requirement
          // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
          length: gettext('account', 'lastNameLength', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Last Name contains \
          // invalid characters
          patternMismatch: gettext('account', 'lastNamePattern', 'sendNonGuestAccountInvitationFormError')
        }
      }
    }
  },
  fieldsetContact: {
    formField: {
      officeNumber: {
        // Translators: Label for the Office Number input
        label: gettext('account', 'officeNumber', 'sendNonGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Office Number is \
          // left blank
          valueMissing: gettext('account', 'officeNumberRequired', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Office Number \
          // doesn't match the expected format
          patternMismatch: gettext('account', 'officeNumberPattern', 'sendNonGuestAccountInvitationFormError')
        }
      },
      officeNumberExt: {
        // Translators: Label for the Office Number Extension input
        label: gettext('account', 'officeNumberExt', 'sendNonGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Office Number \
          // Extension doesn't match the expected format
          patternMismatch: gettext('account', 'officeNumberExtPattern', 'sendNonGuestAccountInvitationFormError')
        }
      },
      faxNumber: {
        // Translators: Label for the Fax Number input
        label: gettext('account', 'faxNumber', 'sendNonGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Fax Number doesn't \
          // match the expected format
          patternMismatch: gettext('account', 'faxNumberPattern', 'sendNonGuestAccountInvitationFormError')
        }
      },
      cellNumber: {
        // Translators: Label for the Cell Number input
        label: gettext('account', 'cellNumber', 'sendNonGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Cell Number doesn't \
          // match the expected format
          patternMismatch: gettext('account', 'cellNumberPattern', 'sendNonGuestAccountInvitationFormError')
        }
      },
      emailAddress: {
        // Translators: Label for the Email Address input
        label: gettext('account', 'emailAddress', 'sendNonGuestAccountInvitationFormInputLabel'),
        error: {
          // Translators: Error message displayed when Email Address is \
          // left blank
          valueMissing: gettext('account', 'emailAddressRequired', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Email Address \
          // doesn't satisfy minimum/maximum length requirement
          // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
          length: gettext('account', 'emailAddressLength', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when Email Address \
          // contains invalid characters
          patternMismatch: gettext('account', 'emailAddressPattern', 'sendNonGuestAccountInvitationFormError')
        }
      }
    }
  },
  fieldsetOther: {
    formField: {
      groups: {
        // Translators: Label for the Groups input
        label: gettext('account', 'groups', 'sendNonGuestAccountInvitationFormInputLabel'),
        // Translators: Label displayed for the drop-down option when a \
        // non-existing group's name is entered by the user. For example: \
        // Add new group: "{{GROUP_NAME}}"
        // Translators: Available tokens: {{GROUP_NAME}}
        optionLabelAddNewGroup: gettext('account', 'addNewGroup', 'sendNonGuestAccountInvitationFormOptionLabel'),
        error: {
          // Translators: Error message displayed when at least one of the \
          // selected Group's name doesn't satisfy minimum/maximum length \
          // requirement
          // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
          length: gettext('account', 'groupNameLength', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when at least one of the \
          // selected Group's name contains invalid characters
          patternMismatch: gettext('account', 'groupNamePattern', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when less than the required \
          // number of Groups are selected
          // Translators: Available tokens: {{count}}
          groupListTooShort: ngettext('account', 'groupListTooShort', 'sendNonGuestAccountInvitationFormError'),
          // Translators: Error message displayed when user tries to add a \
          // new group using a name which is already in use
          // Translators: Available tokens: {{GROUP_NAME}}
          groupNameUnavailable: gettext('account', 'groupNameUnavailable', 'sendNonGuestAccountInvitationFormError')
        }
      },
      role: {
        // Translators: Label for the Role input
        label: gettext('account', 'role', 'sendNonGuestAccountInvitationFormInputLabel'),
        // Translators: Label for the Select Role drop-down option
        placeholder: gettext('account', 'selectRole', 'sendNonGuestAccountInvitationFormOptionLabel'),
        error: {
          // Translators: Error message displayed when Role is not selected
          valueMissing: gettext('account', 'roleRequired', 'sendNonGuestAccountInvitationFormError')
        }
      },
      specialty: {
        // Translators: Label for the Specialty input
        label: gettext('account', 'specialty', 'sendNonGuestAccountInvitationFormInputLabel'),
        // Translators: Label for Select Specialty drop-down option
        placeholder: gettext('account', 'selectSpecialty', 'sendNonGuestAccountInvitationFormOptionLabel'),
        error: {
          // Translators: Error message displayed when Specialty is not selected
          valueMissing: gettext('account', 'specialtyRequired', 'sendNonGuestAccountInvitationFormError')
        }
      }
    }
  }
};

export default i18n;
