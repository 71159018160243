import { Utils } from '@sigmail/common';
import {
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  DataObjectMsgReadReceipt,
  DataObjectMsgReadReceiptValue
} from '@sigmail/objects';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { DataObjectCache } from '../../../../app-state/data-objects-slice/cache';
import { useDataObjectByIdSelector, useDispatchFetchDataObjects } from '../../../../app-state/hooks';
import { UNRESOLVED_RESOURCE } from '../../../../constants';
import { useThrowAsyncError } from '../../../../hooks';

export type MessageMetadataResource = Readonly<
  [metadata: ISuspenseResource<DataObjectMsgMetadataValue>, readReceiptData: DataObjectMsgReadReceiptValue['data']]
>;

const DEFAULT_READ_RECEIPT_DATA: DataObjectMsgReadReceiptValue['data'] = [];

export const useMessageMetadataResource = (msgMetadataId: number): MessageMetadataResource => {
  const dataObjectSelector = useDataObjectByIdSelector();
  const dispatchFetchDataObjects = useDispatchFetchDataObjects();
  const throwAsyncError = useThrowAsyncError();
  const [metadataResource, setMetadataResource] = React.useState<MessageMetadataResource[0]>(UNRESOLVED_RESOURCE);
  const [readReceiptData, setReadReceiptData] = React.useState<MessageMetadataResource[1]>(DEFAULT_READ_RECEIPT_DATA);
  const [fetchMetadataPromise, setFetchMetadataPromise] = React.useState<Promise<any>>();
  const [fetchReadReceiptPromise, setFetchReadReceiptPromise] = React.useState<Promise<any>>();

  const prevMsgMetadataId = React.useRef<number>();
  const msgMetadata = React.useMemo(() => {
    prevMsgMetadataId.current = msgMetadataId;

    const msgMetadataObject = dataObjectSelector<DataObjectMsgMetadataValue>(msgMetadataId);
    return DataObjectCache.getValue(msgMetadataObject);
  }, [msgMetadataId, dataObjectSelector]);

  const prevMsgMetadata = React.useRef<typeof msgMetadata>();
  React.useEffect(() => {
    if (Utils.isNil(msgMetadata)) {
      if (Utils.isNotNil(fetchMetadataPromise) && prevMsgMetadataId.current === msgMetadataId) {
        return;
      }

      prevMsgMetadata.current = undefined;
      if (DataObjectMsgMetadata.isValidId(msgMetadataId)) {
        setFetchMetadataPromise(
          dispatchFetchDataObjects({ ids: [msgMetadataId] }).then(
            () => setFetchMetadataPromise(undefined),
            throwAsyncError
          )
        );
      }

      setMetadataResource(UNRESOLVED_RESOURCE);
      setReadReceiptData(DEFAULT_READ_RECEIPT_DATA);
      return;
    }

    if (prevMsgMetadata.current !== msgMetadata) {
      prevMsgMetadata.current = msgMetadata;
      setMetadataResource({ value: () => msgMetadata });
    }
  }, [dispatchFetchDataObjects, fetchMetadataPromise, msgMetadata, msgMetadataId, throwAsyncError]);

  const prevReadReceiptId = React.useRef<number>();
  const [msgReadReceiptId, msgReadReceipt] = React.useMemo(() => {
    const readReceiptId = msgMetadata?.readReceiptId!;
    prevReadReceiptId.current = readReceiptId;

    const msgReadReceiptObject = dataObjectSelector<DataObjectMsgReadReceiptValue>(readReceiptId);
    return [readReceiptId, DataObjectCache.getValue(msgReadReceiptObject)];
  }, [msgMetadata, dataObjectSelector]);

  const prevReadReceipt = React.useRef<typeof msgReadReceipt>();
  React.useEffect(() => {
    if (Utils.isNil(msgReadReceipt)) {
      if (Utils.isNotNil(fetchReadReceiptPromise) && prevReadReceiptId.current === msgReadReceiptId) {
        return;
      }

      if (DataObjectMsgReadReceipt.isValidId(msgReadReceiptId)) {
        prevReadReceipt.current = undefined;
        setFetchReadReceiptPromise(
          dispatchFetchDataObjects({ ids: [msgReadReceiptId] }).then(
            () => setFetchReadReceiptPromise(undefined),
            throwAsyncError
          )
        );
      }

      setReadReceiptData(DEFAULT_READ_RECEIPT_DATA);
      return;
    }

    if (prevReadReceipt.current !== msgReadReceipt) {
      prevReadReceipt.current = msgReadReceipt;
      setReadReceiptData(msgReadReceipt.data);
    }
  }, [dispatchFetchDataObjects, fetchReadReceiptPromise, msgReadReceipt, msgReadReceiptId, throwAsyncError]);

  return React.useMemo(() => [metadataResource, readReceiptData], [metadataResource, readReceiptData]);
};
