import type { HealthDataFormName, SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectHealthDataValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_HEALTH_DATA;

const DATA_FORM_NAME_LIST: ReadonlyArray<HealthDataFormName> = [
  'bpReading',
  'cardiacIntake',
  'jointSurgery',
  'kccq',
  'vitals'
];

/** @public */
export class UserObjectHealthData extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      Utils.isNonArrayObjectLike(dv.$index) &&
      (Utils.isUndefined(dv.requestList) || Utils.isArray(dv.requestList)) &&
      DATA_FORM_NAME_LIST.every((form) => Utils.isUndefined(dv[form]) || Utils.isNonArrayObjectLike(dv[form]))
    );
  }

  public override updateValue(newValue: DV): Promise<UserObjectHealthData> {
    const Class = this.constructor as typeof UserObjectHealthData;
    return Class.updateValue(this, newValue);
  }
}
