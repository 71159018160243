import { AccountI18n } from '@sigmail/i18n';

const i18n: AccountI18n.Dialog.WorkSchedule = {
  action: {
    accept: { label: 'Save' },
    reject: { label: 'Cancel' }
  },
  title: 'Work schedule'
};

export default i18n;
