import { PayloadAction } from '@reduxjs/toolkit';
import { StoreStateAuthorization } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';

const Logger = getLoggerWithPrefix('Reducer', 'authSuccessActOnBehalfForReducer:');

export const authSuccessActOnBehalfForReducer = (state: StoreStateAuthorization, action: PayloadAction<string>) => {
  try {
    Logger.info('== BEGIN ==');

    if (!Utils.isString(action.payload)) {
      Logger.warn('Invalid payload.', action.payload);
      return state;
    }

    return { ...state, authClaim: action.payload };
  } finally {
    Logger.info('== END ==');
  }
};
