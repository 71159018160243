import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.Form.GridFilter = {
  fieldsetSelection: {
    formField: {
      selectionToggle: {
        // Translators: Label for the Grid Selection Toggle input
        label: gettext('messaging', 'toggleSelection', 'gridFilterFormInputLabel')
      }
    }
  },
  fieldsetFilter: {
    formField: {
      search: {
        // Translators: Label for the Search input
        label: gettext('messaging', 'search', 'gridFilterFormInputLabel'),
        // Translators: Placeholder for the Search input
        placeholder: gettext('messaging', 'search', 'gridFilterFormInputHint')
      },
      messageType: {
        // Translators: Label for the Type of Messages input
        label: gettext('messaging', 'typeOfMessages', 'gridFilterFormInputLabel'),
        options: [
          {
            code: 'all',
            // Translators: Label for the All Messages option
            label: gettext('messaging', 'allMessages', 'gridFilterFormOptionLabel')
          },
          {
            code: 'unread',
            // Translators: Label for the Unread Messages option
            label: gettext('messaging', 'unreadMessages', 'gridFilterFormOptionLabel')
          },
          {
            code: 'read',
            // Translators: Label for the Read Messages option
            label: gettext('messaging', 'readMessages', 'gridFilterFormOptionLabel')
          },
          {
            code: 'important',
            // Translators: Label for the Important Messages option
            label: gettext('messaging', 'importantMessages', 'gridFilterFormOptionLabel')
          },
          {
            code: 'billable',
            // Translators: Label for the Billable Messages option
            label: gettext('messaging', 'billableMessages', 'gridFilterFormOptionLabel')
          },
          {
            code: 'events',
            // Translators: Label for the Event Messages option
            label: gettext('messaging', 'eventMessages', 'gridFilterFormOptionLabel')
          },
          {
            code: 'consultation',
            // Translators: Label for the Consultation Messages option
            label: gettext('messaging', 'consultationMessages', 'gridFilterFormOptionLabel')
          },
          {
            code: 'referrals',
            // Translators: Label for the Referral Messages option
            label: gettext('messaging', 'referralMessages', 'gridFilterFormOptionLabel')
          },
          {
            code: 'attachments',
            // Translators: Label for the Messages With Attachments option
            label: gettext('messaging', 'messagesWithAttachments', 'gridFilterFormOptionLabel')
          }
        ]
      }
    }
  }
};

export default i18n;
