import { defaults, isString, trimOrDefault } from '.';
import type { Nullable, NullableProps, PostalAddress } from '../types';
import { joinPersonName } from './join-person-name';
import { joinPostalAddress } from './join-postal-address';

export function createMessageSignature(
  name?: unknown,
  address?: unknown,
  phoneNumber?: Nullable<string>,
  phoneNumberExt?: Nullable<string>
): string {
  const result = [joinPersonName(name)];

  if (result[0]!.length > 0) {
    const { addressLine1, addressLine2, addressLevel2, addressLevel1, postalCode } = defaults(
      {} as NullableProps<PostalAddress>,
      address
    );

    result.push(
      [
        joinPostalAddress({ addressLine1, addressLine2 }),
        joinPostalAddress({ addressLevel2, addressLevel1, postalCode })
      ]
        .filter(isString)
        .join('<br/>')
    );

    const trimmedPhoneNumber = trimOrDefault(phoneNumber);
    if (trimmedPhoneNumber.length > 0) {
      result.push(trimmedPhoneNumber);

      const trimmedExt = trimOrDefault(phoneNumberExt);
      if (trimmedExt.length > 0) {
        result[result.length - 1] += `\u00a0ext.\u00a0${trimmedExt}`;
      }
    }
  }

  return `<p>${result.filter(isString).join('</p><p>')}</p>`;
}
