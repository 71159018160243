import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import * as RouteId from '../../constants/route-identifiers';

const i18n: GlobalI18n['documentTitle'] = {
  // Translators: Text to be prefixed (if any) to the document title
  prefix: gettext('global', 'prefix', 'documentTitle'),

  // Translators: Text to be suffixed (if any) to the document title
  suffix: gettext('global', 'suffix', 'documentTitle'),

  // Translators: Browser window title for Sign In page
  [RouteId.ROUTE_SIGN_IN]: gettext('global', 'signInPage', 'documentTitle'),
  // Translators: Browser window title for Reset Password page
  [RouteId.ROUTE_RESET_PASSWORD]: gettext('global', 'resetPasswordPage', 'documentTitle'),
  // Translators: Browser window title for Contact Us page
  [RouteId.ROUTE_CONTACT_US]: gettext('global', 'contactUsPage', 'documentTitle'),
  // Translators: Browser window title for FAQ page
  [RouteId.ROUTE_FAQ]: gettext('global', 'faqPage', 'documentTitle'),
  // Translators: Browser window title for Privacy Policy page
  [RouteId.ROUTE_PRIVACY_POLICY]: gettext('global', 'privacyPolicyPage', 'documentTitle'),
  // Translators: Browser window title for Terms And Conditions page
  [RouteId.ROUTE_TERMS_AND_CONDITIONS]: gettext('global', 'termsAndConditionsPage', 'documentTitle'),

  // Translators: Browser window title for Account page
  [RouteId.ROUTE_ACCOUNT_ROOT]: gettext('global', 'accountsPage', 'documentTitle'),
  // Translators: Browser window title for My Account page
  [RouteId.ROUTE_ACCOUNT_MANAGE_SELF]: gettext('global', 'myAccountPage', 'documentTitle'),
  // Translators: Browser window title for Manage Caregivers page
  [RouteId.ROUTE_ACCOUNT_MANAGE_CAREGIVERS]: gettext('global', 'manageCaregiversPage', 'documentTitle'),
  // Translators: Browser window title for Manage Members page
  [RouteId.ROUTE_ACCOUNT_MANAGE_MEMBERS]: gettext('global', 'manageMembersPage', 'documentTitle'),
  // Translators: Browser window title for Manage Groups page
  [RouteId.ROUTE_ACCOUNT_MANAGE_GROUPS]: gettext('global', 'manageGroupsPage', 'documentTitle'),
  // Translators: Browser window title for Bulk Invite Patients page
  [RouteId.ROUTE_ACCOUNT_BULK_INVITE_GUESTS]: gettext('global', 'bulkInviteGuestsPage', 'documentTitle'),

  // Translators: Browser window title for Account Caregiver Setup page
  [RouteId.ROUTE_ACCOUNT_CAREGIVER_SETUP]: gettext('global', 'accountCaregiverSetupPage', 'documentTitle'),
  // Translators: Browser window title for Account Setup page
  [RouteId.ROUTE_ACCOUNT_SETUP]: gettext('global', 'accountSetupPage', 'documentTitle'),

  // Translators: Browser window title for Mailbox page
  [RouteId.ROUTE_MAIL_ROOT]: gettext('global', 'mailboxPage', 'documentTitle'),
  // Translators: Browser window title for Archived Messages page
  [RouteId.ROUTE_MAIL_ARCHIVED]: gettext('global', 'archivedMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Shared Archived Messages page
  [RouteId.ROUTE_MAIL_ARCHIVED_SHARED]: gettext('global', 'sharedArchivedMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Compose Consultation page
  [RouteId.ROUTE_MAIL_COMPOSE_CONSULTATION_REQUEST]: gettext('global', 'composeConsultationPage', 'documentTitle'),
  // Translators: Browser window title for Compose HRM Message page
  [RouteId.ROUTE_MAIL_COMPOSE_HRM_MESSAGE]: gettext('global', 'composeHrmMessagePage', 'documentTitle'),
  // Translators: Browser window title for Compose Messages page
  [RouteId.ROUTE_MAIL_COMPOSE_MESSAGE]: gettext('global', 'composeMessagePage', 'documentTitle'),
  // Translators: Browser window title for Compose Referral page
  [RouteId.ROUTE_MAIL_COMPOSE_REFERRAL]: gettext('global', 'composeReferralPage', 'documentTitle'),
  // Translators: Browser window title for Consultation Messages page
  [RouteId.ROUTE_MAIL_CONSULTATION]: gettext('global', 'consultationMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Draft Messages page
  [RouteId.ROUTE_MAIL_DRAFTS]: gettext('global', 'draftMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Important Messages page
  [RouteId.ROUTE_MAIL_IMPORTANT]: gettext('global', 'importantMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Shared Important Messages page
  [RouteId.ROUTE_MAIL_IMPORTANT_SHARED]: gettext('global', 'sharedImportantMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Received Messages page
  [RouteId.ROUTE_MAIL_INBOX]: gettext('global', 'receivedMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Shared Received Messages page
  [RouteId.ROUTE_MAIL_INBOX_SHARED]: gettext('global', 'sharedReceivedMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Reminder Messages page
  [RouteId.ROUTE_MAIL_REMINDER]: gettext('global', 'reminderMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Referral Messages page
  [RouteId.ROUTE_MAIL_REFERRALS]: gettext('global', 'referralMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Shared Referral Messages page
  [RouteId.ROUTE_MAIL_REFERRALS_SHARED]: gettext('global', 'sharedReferralMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Sent Messages page
  [RouteId.ROUTE_MAIL_SENT]: gettext('global', 'sentMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Sent Messages page
  [RouteId.ROUTE_MAIL_SECURE]: gettext('global', 'oneTimeMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Deleted Messages page
  [RouteId.ROUTE_MAIL_TRASH]: gettext('global', 'deletedMessagesPage', 'documentTitle'),
  // Translators: Browser window title for Shared Deleted Messages page
  [RouteId.ROUTE_MAIL_TRASH_SHARED]: gettext('global', 'sharedDeletedMessagesPage', 'documentTitle'),

  // Translators: Browser window title for Schedule page
  [RouteId.ROUTE_SCHEDULE_ROOT]: gettext('global', 'schedulePage', 'documentTitle'),
  // Translators: Browser window title for My Schedule page
  [RouteId.ROUTE_SCHEDULE_OWN]: gettext('global', 'ownSchedulePage', 'documentTitle'),
  // Translators: Browser window title for Group Schedule page
  [RouteId.ROUTE_SCHEDULE_GROUP]: gettext('global', 'groupSchedulePage', 'documentTitle'),

  // Translators: Browser window title for Contact List page
  [RouteId.ROUTE_CONTACT_LIST_ROOT]: gettext('global', 'contactListPage', 'documentTitle'),
  // Translators: Browser window title for Client Contact List page
  [RouteId.ROUTE_CONTACT_LIST_CLIENT]: gettext('global', 'clientContactListPage', 'documentTitle'),
  // Translators: Browser window title for Global Group Contact List page
  [RouteId.ROUTE_CONTACT_LIST_GLOBAL_GROUPS]: gettext('global', 'globalGroupContactListPage', 'documentTitle'),
  // Translators: Browser window title for Global User Contact List page
  [RouteId.ROUTE_CONTACT_LIST_GLOBAL_USERS]: gettext('global', 'globalUserContactListPage', 'documentTitle'),
  // Translators: Browser window title for Guest Contact List page
  [RouteId.ROUTE_CONTACT_LIST_GUEST]: gettext('global', 'guestContactListPage', 'documentTitle'),

  // Translators: Browser window title for Document List page
  [RouteId.ROUTE_DOCUMENT_LIST]: gettext('global', 'documentListPage', 'documentTitle')
};

export default i18n;
