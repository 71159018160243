import { isSameWeek, isSameYear, isToday } from 'date-fns';
import { memoize } from 'lodash-es';

// eslint-disable-next-line no-unused-vars
type MemoizedDateFormatFn = (locale: string) => Intl.DateTimeFormat;

export const DATE_FORMAT_TODAY = memoize(
  (locale: string) =>
    new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric'
    })
) as MemoizedDateFormatFn;

export const DATE_FORMAT_SAME_WEEK = memoize(
  (locale: string) =>
    new Intl.DateTimeFormat(locale, {
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    })
) as MemoizedDateFormatFn;

export const DATE_FORMAT_SAME_YEAR = memoize(
  (locale: string) =>
    new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    })
) as MemoizedDateFormatFn;

export const DATE_FORMAT_SAME_YEAR_NO_TIME = memoize(
  (locale: string) =>
    new Intl.DateTimeFormat(locale, {
      month: 'short',
      day: 'numeric'
    })
) as MemoizedDateFormatFn;

export const DATE_FORMAT_FULL = memoize(
  (locale: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    })
) as MemoizedDateFormatFn;

export const DATE_FORMAT_FULL_NO_TIME = memoize(
  (locale: string) =>
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })
) as MemoizedDateFormatFn;

export const formatTimestamp = memoize(
  (date: Date, locale: string): string => {
    const dtNow = new Date();

    try {
      if (isToday(date)) return DATE_FORMAT_TODAY(locale).format(date);
      if (isSameWeek(dtNow, date)) return DATE_FORMAT_SAME_WEEK(locale).format(date);
      if (isSameYear(dtNow, date)) return DATE_FORMAT_SAME_YEAR(locale).format(date);

      return DATE_FORMAT_FULL(locale).format(date);
    } catch {
      return '';
    }
  },
  (date: Date, locale: string) => date.getTime().toString(10).concat(locale)
  // eslint-disable-next-line no-unused-vars
) as (date: Date, locale: string) => string;
