import { CancelablePromise, Utils } from '@sigmail/common';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { useThrowAsyncError } from '../../hooks';
import { useDispatchFetchServerDateTime } from './use-dispatch-fetch-server-date-time';
import { useInterval } from './use-interval';

export interface ServerDateTimeResource extends ISuspenseResource<Date> {}

export interface UseServerDateTimeResourceParams {
  /**
   * Interval, in milliseconds, before resource value is refreshed.
   * Default value is `300000` i.e. 5 minutes.
   */
  refreshInterval?: unknown | null | undefined;
}

export const DEFAULT_VALUE_REFRESH_INTERVAL_MS = 300000; // 5 minutes
const MAX_VALUE_REFRESH_INTERVAL_MS = 86400000;

export const useServerDateTimeResource = (params: UseServerDateTimeResourceParams) => {
  const throwAsyncError = useThrowAsyncError() as (error: any) => never;
  const dispatchFetchServerDateTime = useDispatchFetchServerDateTime();
  const [resource, setResource] = React.useState<ServerDateTimeResource>(UNRESOLVED_RESOURCE);
  const [firstRunComplete, setFirstRunComplete] = React.useState(false);

  const fetchPromise = React.useRef<CancelablePromise<Date>>();
  const fetchServerDateTime = React.useCallback(() => {
    fetchPromise.current?.cancel();
    setFirstRunComplete(true);

    const wrappedPromise = Utils.makeCancelablePromise(dispatchFetchServerDateTime().catch(throwAsyncError));
    fetchPromise.current = wrappedPromise;

    wrappedPromise.promise.then(
      (dtServer) => {
        fetchPromise.current = undefined;
        setResource({ value: () => dtServer });
      },
      (error) => {
        if (Utils.isNonArrayObjectLike<{ isCanceled: boolean }>(error) && error.isCanceled === true) return;
        fetchPromise.current = undefined;
      }
    );
  }, [dispatchFetchServerDateTime, throwAsyncError]);

  let delay: number | null = null;
  let refreshInterval = params.refreshInterval as number | null;
  if (refreshInterval !== null) {
    refreshInterval = Utils.isInteger(refreshInterval) ? refreshInterval : DEFAULT_VALUE_REFRESH_INTERVAL_MS;
    delay = Math.max(0, Math.min(refreshInterval, MAX_VALUE_REFRESH_INTERVAL_MS));
  }

  useInterval(fetchServerDateTime, firstRunComplete ? delay : null);
  if (!firstRunComplete && refreshInterval !== null) fetchServerDateTime();

  return resource;
};
