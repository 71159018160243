import { isString } from '.';

const PATTERN_HEX_CHAR = '[a-fA-F0-9]';
const RE_HEX_VALUE = RegExp(`^${PATTERN_HEX_CHAR}+$`);

export function uint8ArrayFromHex(value: string): Uint8Array {
  if (!isString(value)) {
    throw new TypeError('Argument <value> must be of type string.');
  }

  const trimmedValue = value.trim();
  if (trimmedValue.length % 2 !== 0) {
    throw new Error("Value has an invalid length i.e. it's not divisible by 2");
  }

  if (!RE_HEX_VALUE.test(value)) {
    throw new Error('Value must be encoded in hexadecimal format.');
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const hexCharCodeList: Array<string> = value.match(RegExp(`${PATTERN_HEX_CHAR}{2}`, 'g')) || [];
  return new Uint8Array(hexCharCodeList.map((hexCharCode) => parseInt(hexCharCode, 16)));
}
