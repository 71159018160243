import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AppThunk } from '../..';
import { accessTokenSelector } from '../../selectors/auth';

export const newNotificationAction = ({ accessToken, notificationList }: ApiActionPayload.NewNotification): AppThunk<Promise<void>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'newNotificationAction:');

    Logger.info('== BEGIN ==');
    try {
      const apiAccessToken = Utils.stringOrDefault(accessToken, accessTokenSelector(getState()));
      await apiService.newNotification(apiAccessToken, { notificationList });
    } finally {
      Logger.info('== END ==');
    }
  };
};
