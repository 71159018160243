import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Utils } from '@sigmail/common';
import React from 'react';
import { DisplayMode } from 'sigmail';

export interface ContextValue {
  readonly breakpoint?: Breakpoint;
  readonly displayMode: DisplayMode;
  readonly isAppOffline: boolean;
  readonly isScreenSmallAndUp: boolean;
  readonly isScreenMediumAndUp: boolean;
}

const DISPLAY_MODE_LIST: ReadonlyArray<DisplayMode> = [
  'fullscreen',
  'minimal-ui',
  'standalone',
  'window-controls-overlay'
];

export function getDisplayMode(): DisplayMode {
  const displayMode = DISPLAY_MODE_LIST.find((mode) => window.matchMedia(`(display-mode: ${mode})`).matches);
  return Utils.stringOrDefault<ContextValue['displayMode']>(displayMode, 'browser');
}

export const DEFAULT_CONTEXT_VALUE: ContextValue = {
  displayMode: getDisplayMode(),
  isAppOffline: false,
  isScreenSmallAndUp: false,
  isScreenMediumAndUp: false
};

const Context = React.createContext<ContextValue>(DEFAULT_CONTEXT_VALUE);
export const LayoutContextProvider = Context.Provider;

export const useLayoutContext = (): ContextValue | undefined => React.useContext(Context);

export const useLayoutContextStrict = (): ContextValue => {
  const context = useLayoutContext();
  if (Utils.isNil(context)) {
    throw new Error('`useLayoutContext` hook must be used within a `LayoutContext.Provider`.');
  }
  return context;
};
