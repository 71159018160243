import { SchedulingActionPayload } from '@sigmail/app-state';
import { AppException, Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  CalendarEventMetadata,
  CalendarEventRecord,
  DataObjectCalendarEvent,
  DataObjectCalendarEventValue,
  UserObjectScheduleValue
} from '@sigmail/objects';
import { AppThunk } from '../..';
import { calendarEventStartTime } from '../../../utils/calendar-event-start-time';
import { calendarEventToMetadata } from '../../../utils/calendar-event-to-metadata';
import { dateToUtcValues } from '../../../utils/date-to-utc-values';
import { generateContactList, GenerateContactListResult } from '../../../utils/generate-contact-list';
import { basicProfileObjectSelector as userBasicProfileObjectSelector, scheduleObjectSelector } from '../../selectors/user-object';
import { UserObjectCache } from '../../user-objects-slice/cache';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';

type Payload = SchedulingActionPayload.CreateCalendarEvent;

interface State extends AuthenticatedActionState {
  groupContactList: GenerateContactListResult['contactList'];
  isUserRoleNonGuest: boolean;
}

class CreateCalendarEventAction extends AuthenticatedAction<Payload, State> {
  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();

    const basicProfile = await this.getUserObjectValue(userBasicProfileObjectSelector, {
      type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC
    });
    if (Utils.isNil(basicProfile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Basic profile is either missing or invalid.');
    }

    this.state.isUserRoleNonGuest = Utils.isNonGuestRole(basicProfile.role);
    return result;
  }

  /** @override */
  protected async onExecute() {
    await this.fetchGroupMemberList();
    await this.addOperationsToRequestBody();
  }

  private async fetchGroupMemberList(): Promise<void> {
    this.logger.info("Fetching the latest member list of current user's circle of care group.");

    let groupContactList: GenerateContactListResult['contactList'] = [];
    for (let MAX_ITERATION_COUNT = 5, iteration = 1; iteration <= MAX_ITERATION_COUNT; iteration++) {
      const result = generateContactList(this.getRootState(), {
        include: {
          groupContactList: 'active',
          groupGuestContactList: { user: 'active' }
        }
      });

      if (result.query.userObjectsByType.length > 0) {
        await this.dispatchFetchObjects(result.query);
      } else {
        groupContactList = result.contactList;
        break;
      }
    }

    this.state.groupContactList = groupContactList;
  }

  private async addOperationsToRequestBody(): Promise<void> {
    await this.addInsertOperationForCalendarEvent(); // 310
    await this.addUpdateOperationForScheduleObjects(); // 412s
  }

  private async addInsertOperationForCalendarEvent(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for calendar event object.');

    const { data, id, requestBody, successPayload } = this.payload;
    const { activeGroupId, auditId, currentUser, isUserRoleNonGuest, ownerId } = this.state;

    const value: DataObjectCalendarEventValue = { $$formatver: 1, ...data };
    const calendarEventObject = await DataObjectCalendarEvent.create(
      id,
      undefined,
      1,
      value,
      ownerId,
      currentUser.id,
      new Date(data.extendedProps.dtCreated)
    );

    const idList: [number, ...Array<number>] = [auditId, ...data.extendedProps.attendeeList.map(({ id }) => id)];
    if (isUserRoleNonGuest) idList.push(activeGroupId);
    const keyList = await calendarEventObject.generateKeysEncryptedFor(...idList);
    requestBody.insert(calendarEventObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.dataObjects!.ids.push(id);
    successPayload.response.dataObjects!.push(calendarEventObject.toApiFormatted());
  }

  private async addUpdateOperationForScheduleObjects(): Promise<void> {
    this.logger.info(
      'Adding an update operation to request body for schedule object of each' +
        " attendee who is a member of current user's circle of care group."
    );

    const { data: calendarEvent, id: eventObjectId, requestBody, successPayload } = this.payload;
    const { groupContactList, isUserRoleNonGuest, currentUser, roleAuthClaim: authState } = this.state;

    for (const attendee of calendarEvent.extendedProps.attendeeList) {
      const { id: attendeeId } = attendee;
      if (isUserRoleNonGuest) {
        if (!groupContactList.some(({ id: userId }) => userId === attendeeId)) {
          continue;
        }
      } else if (attendeeId !== currentUser.id) {
        continue;
      }

      const query: FetchObjectsRequestData = {
        authState,
        userObjectsByType: [{ userId: attendeeId, type: process.env.USER_OBJECT_TYPE_SCHEDULE }],
        expectedCount: { claims: 1 }
      };

      const { claims } = await this.dispatchFetchObjects(query);
      if (claims.length === 0) {
        throw new AppException(Constants.Error.S_ERROR, 'Expected <claims> to be a non-empty array.');
      }

      this.payload.claims.push(...claims);

      const scheduleObject = scheduleObjectSelector(this.getRootState())(attendeeId);
      const scheduleValue = UserObjectCache.getValue(scheduleObject);
      if (Utils.isNil(scheduleObject) || Utils.isNil(scheduleValue)) {
        throw new AppException(
          Constants.Error.E_DATA_MISSING_OR_INVALID,
          `Attendee's schedule object is either missing or invalid. <attendeeId=${attendeeId}>`
        );
      }

      const updatedEventRecord = { ...(scheduleValue.events as CalendarEventRecord) };

      const dtEventStart = calendarEventStartTime(calendarEvent);
      const [utcYear, utcMonth, utcDate] = dateToUtcValues(dtEventStart);

      if (!updatedEventRecord[utcYear]?.[utcMonth]) {
        updatedEventRecord[utcYear] = { ...updatedEventRecord[utcYear], [utcMonth]: {} };
      } else {
        updatedEventRecord[utcYear] = {
          ...updatedEventRecord[utcYear],
          [utcMonth]: { ...updatedEventRecord[utcYear]![utcMonth] }
        };
      }

      let dayEventList = updatedEventRecord[utcYear]![utcMonth]![utcDate]?.slice();
      if (!Utils.isArray(dayEventList)) dayEventList = [];
      updatedEventRecord[utcYear]![utcMonth]![utcDate] = dayEventList;

      let accepted: boolean | undefined;
      if (Utils.isInteger(attendee.timestamp?.acceptedAt)) {
        accepted = true;
      } else if (Utils.isInteger(attendee.timestamp?.declinedAt)) {
        accepted = false;
      }

      const metadata: CalendarEventMetadata = {
        ...calendarEventToMetadata(calendarEvent),
        eventObjectId,
        accepted
      };

      dayEventList.push(metadata);

      const updatedValue: UserObjectScheduleValue = {
        ...scheduleValue,
        events: updatedEventRecord
      };

      const updatedObject = await scheduleObject.updateValue(updatedValue);
      requestBody.update(updatedObject);

      successPayload.request.userObjects!.ids.push(updatedObject.id);
      successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
    }
  }
}

export const createCalendarEventAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'createCalendarEventAction:');

    const action = new CreateCalendarEventAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
