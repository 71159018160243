import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserCredentials } from '.';
import type { ServerParamsMfaLogin as DVServer, SharedParamsMfaLogin as DVShared } from '../types';

const SERVER_PARAMS_PROPS: ReadonlyArray<keyof DVServer> = ['verifier'];
const SHARED_PARAMS_PROPS: ReadonlyArray<keyof DVShared> = ['salt', 'otpClaim'];
const TYPE = process.env.USER_CREDENTIALS_TYPE_MFA_LOGIN;

/** @public */
export class UserCredentialsMfaLogin extends UserCredentials<DVShared, DVServer> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  public static override isValidServerParameters(value: unknown): value is DVServer {
    if (!super.isValidServerParameters(value)) return false;

    const dv = value as DVServer;
    return Utils.every(SERVER_PARAMS_PROPS, Utils.partial(Utils.has, dv)) && Utils.isString(dv.verifier);
  }

  public static override isValidSharedParameters(value: unknown): value is DVShared {
    if (!super.isValidSharedParameters(value)) return false;

    const dv = value as DVShared;
    return (
      Utils.every(SHARED_PARAMS_PROPS, Utils.partial(Utils.has, dv)) &&
      Utils.isString(dv.salt) &&
      Utils.isValidJwtToken(dv.otpClaim, 'id')
    );
  }
}
