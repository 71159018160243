import { AppUser, Nullable, SigmailObjectId, SigmailUserId, Utils } from '@sigmail/common';
import { IUserObject, UserObjectCarePlansValue } from '@sigmail/objects';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { fetchUserObjectsAction as fetchUserObjects } from '../actions/fetch-user-objects-action';
import { EMPTY_PLAIN_OBJECT } from '../constants';
import { UserObjectCache } from '../user-objects-slice/cache';
import { useAppState } from './use-app-state';

export type CarePlanListResource = ISuspenseResource<RV>;

export type CarePlanListResourceValue =
  | [undefined]
  | [carePlanList: UserObjectCarePlansValue['list'], refresh: () => void];

type P = UseCarePlanListResourceParams;
type R = CarePlanListResource;
type RV = CarePlanListResourceValue;

export interface UseCarePlanListResourceParams {
  readonly userId?: Nullable<false | SigmailUserId>;
}

const TYPE_CARE_PLANS = process.env.USER_OBJECT_TYPE_CARE_PLANS;

export const useCarePlanListResource = (params?: Nullable<false | P>): R => {
  const { userId: userIdParam } = (Utils.isNonArrayObjectLike(params) ? params : EMPTY_PLAIN_OBJECT) as P;
  const prevUserIdParam = React.useRef<typeof userIdParam>();
  const [refreshKey, refresh] = React.useReducer((key: number) => ++key, 1);
  const prevRefreshKey = React.useRef(refreshKey);

  const [, appDispatch] = useAppState();
  const [objectId, setObjectId] = React.useState<SigmailObjectId>();
  const carePlanList = UserObjectCache.getValue<IUserObject<UserObjectCarePlansValue>>(objectId)?.list;

  React.useEffect(() => {
    let cleanup: ReturnType<React.EffectCallback>;
    const userIdChanged = prevUserIdParam.current !== userIdParam;

    let isRefreshing = false;
    if (prevRefreshKey.current !== refreshKey) {
      prevRefreshKey.current = refreshKey;
      isRefreshing = !userIdChanged;
      if (isRefreshing && AppUser.isValidId(userIdParam)) {
        void appDispatch(
          fetchUserObjects({ fetch: true, objectByType: { type: TYPE_CARE_PLANS, userId: userIdParam } })
        ).catch(Utils.noop);
      }
    }

    if (userIdChanged) {
      prevUserIdParam.current = userIdParam;

      let ignore = false;
      if (AppUser.isValidId(userIdParam)) {
        cleanup = () => void (ignore = true);

        const userId = userIdParam;
        void appDispatch(fetchUserObjects({ fetch: true, objectByType: { type: TYPE_CARE_PLANS, userId } })).then(
          ({ userObjectList }) =>
            void (
              !ignore &&
              setObjectId(userObjectList.find((obj) => obj.type === TYPE_CARE_PLANS && obj.userId === userId)?.id)
            ),
          () => void (!ignore && setObjectId(0))
        );
      }

      setObjectId(undefined);
    }

    return cleanup;
  }, [appDispatch, refreshKey, userIdParam]);

  return React.useMemo(
    (): R => (Utils.isInteger(objectId) ? { value: (): RV => [carePlanList, refresh] } : UNRESOLVED_RESOURCE),
    [carePlanList, objectId]
  );
};
