import { ConnectOptions } from "twilio-video";

export const CONNECT_OPTIONS: ConnectOptions = {
  bandwidthProfile: {
    video: {
      contentPreferencesMode: 'auto',
      dominantSpeakerPriority: 'standard',
      mode: 'collaboration',
      clientTrackSwitchOffControl: 'auto'
    }
  },
  dominantSpeaker: true,
  maxAudioBitrate: 16000,
  networkQuality: { local: 1, remote: 1 },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }]
};

export const DEFAULT_VIDEO_CONSTRAINTS: MediaTrackConstraints = {
  width: 1280,
  height: 720,
  frameRate: 24
};
