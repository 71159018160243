import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import * as AuthSelectors from '../../selectors/auth';

export const sendTemplatedEmailMessageAction = ({ apiAccessToken, ...payload }: Api.TemplatedEmailMessage): AppThunk<Promise<void>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'sendTemplatedEmailMessageAction:');

    Logger.info('== BEGIN ==');
    try {
      const accessToken = Utils.isString(apiAccessToken) ? apiAccessToken : AuthSelectors.accessTokenSelector(getState());
      await apiService.sendTemplatedEmailMessage(accessToken, payload);
    } catch (error) {
      Logger.error(error);
      throw error;
    } finally {
      Logger.info('== END ==');
    }
  };
};
