import { Nullable, SigmailObjectId, Utils } from '@sigmail/common';
import { DataObject, ValueFormatVersion } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { useThrowAsyncError } from '../../hooks';
import { fetchDataObjectsAction as fetchDataObjects } from '../actions/fetch-data-objects-action';
import { EMPTY_PLAIN_OBJECT } from '../constants';
import { DataObjectCache } from '../data-objects-slice/cache';
import { dataObjectByIdSelector } from '../selectors/data-object';
import { useAppState } from './use-app-state';

export type DataObjectResource<DV extends ValueFormatVersion> = ISuspenseResource<DV>;

type Params = UseDataObjectResourceParams;
type Resource<DV extends ValueFormatVersion> = DataObjectResource<DV>;

export interface UseDataObjectResourceParams {
  readonly objectId?: Nullable<false | SigmailObjectId>;
  readonly onError?: Nullable<false | ((error: unknown) => unknown)>;
}

export function useDataObjectResource<DV extends ValueFormatVersion>(params?: Nullable<false | Params>): Resource<DV> {
  const [, appDispatch] = useAppState();
  const selectDataObject = useSelector(dataObjectByIdSelector);
  const throwAsyncError = React.useRef(useThrowAsyncError());

  const { objectId, onError } = (Utils.isNonArrayObjectLike(params) ? params : EMPTY_PLAIN_OBJECT) as Params;
  const errorHandlerRef = React.useRef<typeof onError>();
  if (errorHandlerRef.current !== onError) {
    errorHandlerRef.current = onError;
  }
  const [dataObjectId, setDataObjectId] = React.useState<SigmailObjectId>();
  const objectValue = DataObjectCache.getValue(selectDataObject<DV>(dataObjectId!));

  React.useEffect((): ReturnType<React.EffectCallback> => {
    let ignore = false;

    if (DataObject.isValidId(objectId)) {
      void appDispatch(fetchDataObjects({ fetch: true, objectId })).then(
        ({ dataObjectList }) => {
          const id = dataObjectList.find(({ id }) => id === objectId)?.id;
          return void (!ignore && setDataObjectId(id));
        },
        (error) => {
          const errorHandler =
            typeof errorHandlerRef.current === 'function' ? errorHandlerRef.current : throwAsyncError.current;
          return ignore ? undefined : errorHandler(error);
        }
      );
    }

    setDataObjectId(undefined);
    return () => void (ignore = true);
  }, [appDispatch, objectId]);

  return React.useMemo(
    (): Resource<DV> =>
      Utils.isNil(objectValue) ? (UNRESOLVED_RESOURCE as Resource<DV>) : { value: (): DV => objectValue },
    [objectValue]
  );
}
