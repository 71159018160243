import React from 'react';
import { Participant as IParticipant, Track } from 'twilio-video';
import { ParticipantInfo } from './participant-info.component';
import { TrackPublicationList } from './track-publication-list.component';

export interface Props {
  children?: never;
  participant: IParticipant;
  isMainParticipant?: boolean | null | undefined;
  isLocalParticipant?: boolean | null | undefined;
  videoOnly?: boolean | null | undefined;
  videoPriority?: Track.Priority | null | undefined;
  onClickAction?: (actionId: string) => any;
}

class ParticipantComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { participant, isMainParticipant, onClickAction, isLocalParticipant, videoOnly, videoPriority } = this.props;

    return (
      <ParticipantInfo participant={participant} isMainParticipant={isMainParticipant} onClickAction={onClickAction}>
        <TrackPublicationList
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
        />
      </ParticipantInfo>
    );
  }
}

export const Participant = ParticipantComponent;
(Participant as any).displayName = 'Participant';
