/* eslint-disable no-redeclare */

import { NewMsgPushNotificationBuilder } from './new-msg-notify';
import type { NewMsgNotify, PushNotificationType } from './types';

export function PushNotificationBuilder(type: NewMsgNotify): NewMsgPushNotificationBuilder;

export function PushNotificationBuilder(type: PushNotificationType): unknown {
  switch (type) {
    case 'NEW_MSG_NOTIFY':
      return new NewMsgPushNotificationBuilder();
    default:
      throw new Error(`Unhandled case - ${type}`);
  }
}

export * from './get-push-subscription';
export * from './subscribe-to-push-service';
export * from './types';
export * from './unsubscribe-from-push-service';
