import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { FormSignIn, FormVerifyMfaCode } from '../../constants/action-context';
import mfaI18n from '../MFA';

const i18n: GlobalI18n['signInPage'] = {
  signInForm: {
    // Translators: (HTML) Page heading displayed above the Sign In form
    heading1: gettext('global', 'signInPage', 'signInFormHeading1', 'safe-html'),
    // Translators: (HTML) Lead text displayed above the Sign In form
    leadText: gettext('global', 'signInPage', 'signInFormLeadText', 'safe-html'),
    action: {
      submit: {
        // Translators: Label for the Submit action of Sign In form
        label: gettext('global', 'signInPage', 'actionLabelSignInFormSubmit'),
        context: FormSignIn
      },
      resetPassword: {
        // Translators: Label for the Reset Password action of Sign In form
        label: gettext('global', 'signInPage', 'actionLabelSignInFormResetPassword'),
        context: FormSignIn
      }
    },
    // Translators: Error message displayed when authentication fails
    errorMessageAuthFail: gettext('global', 'signInPage', 'errorMessageSignInFormAuthFail')
  },
  verifyMfaForm: {
    // Translators: (HTML) Page heading displayed above the Verify MFA Code form
    heading1: gettext('global', 'signInPage', 'verifyMfaFormHeading1', 'safe-html'),
    leadText: mfaI18n.verifyMfaCommons.leadText,
    resendCode: mfaI18n.verifyMfaCommons.resendCode,
    action: {
      cancel: {
        // Translators: Label for the Cancel action of Verify MFA Code form
        label: gettext('global', 'signInPage', 'actionLabelVerifyMfaFormCancel'),
        context: FormVerifyMfaCode
      },
      submit: {
        // Translators: Label for the Submit action of Verify MFA Code form
        label: gettext('global', 'signInPage', 'actionLabelVerifyMfaFormSubmit'),
        context: FormVerifyMfaCode
      },
      resendCode: {
        label: mfaI18n.verifyMfaCommons.action.resendCode.label,
        context: FormVerifyMfaCode
      }
    }
  }
};

export default i18n;
