import { InputAdornment } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { PartialRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n } from '@sigmail/i18n';
import { FieldState } from 'final-form';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { withTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { ValidationErrorResult } from './form/field-validator';
import { FieldProps, FormComponent, FormComponentProps, FormComponentState } from './form/form.component';
import style from './forms.module.css';
import { IconButtonTogglePasswordVisibility } from './icon-button-toggle-password-visibility.component';

const { signInCredentials: i18n } = globalI18n.form;

export type FieldsetKeyDefault = keyof typeof i18n;
export type FieldsetKey = FieldsetKeyDefault;
export type FieldName = keyof typeof i18n.fieldsetCredentials.formField;
export type FormValues = Record<FieldName, string>;

const FIELD_SET_LIST: ReadonlyArray<Readonly<[FieldsetKey, ReadonlyArray<FieldName>]>> = [
  ['fieldsetCredentials', ['username', 'password']]
];

const FIELD_PROPS: Record<FieldName, FieldProps> = {
  username: {
    autoComplete: 'username',
    label: i18n.fieldsetCredentials.formField.username.label,
    placeholder: i18n.fieldsetCredentials.formField.username.placeholder,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <AccountCircleOutlinedIcon color="disabled" />
        </InputAdornment>
      )
    },
    config: {
      required: true
    }
  },
  password: {
    autoComplete: 'current-password',
    label: i18n.fieldsetCredentials.formField.password.label,
    placeholder: i18n.fieldsetCredentials.formField.password.placeholder,
    type: 'password',
    config: {
      required: true
    }
  }
};

export type SignInCredentialsFormClassKey = 'inputRoot';

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<SignInCredentialsFormClassKey, string> | undefined;
  excludedFieldList?: ReadonlyArray<FieldName> | null | undefined;
}

interface ComponentProps extends Props, WithTranslation {}

interface State extends FormComponentState<FieldName, FormValues> {
  passwordInputType: 'text' | 'password';
}

class SignInCredentialsFormComponent extends FormComponent<
  FieldsetKey,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push('t', 'tReady', 'i18n', 'classes', 'excludedFieldList');

    this.state = { ...this.state, passwordInputType: 'password' };

    this.createForm({
      initialValues: {
        username: '',
        password: ''
      },
      onSubmit: props.onSubmit
    });

    this.onShowPasswordClick = this.onShowPasswordClick.bind(this);
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-sign-in-${this.formIdSuffix}`;
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetKey: FieldsetKey) {
    const { label } = i18n[fieldsetKey];
    return Utils.isString(label) ? this.props.t(label) : null;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    if (!this.isFieldExcluded(fieldName)) {
      return FIELD_PROPS[fieldName].config;
    }
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { t } = this.props;
    const { passwordInputType } = this.state;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    if (fieldName === 'password') {
      fieldProps = {
        ...fieldProps,
        InputProps: {
          ...fieldProps.InputProps,
          endAdornment: this.renderPasswordVisibilityToggleButton()
        },
        type: passwordInputType
      };
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined = undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const errorI18n = i18n[fieldsetName!].formField[fieldName].error;
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    return Utils.isString(errorMessageI18n) ? t(errorMessageI18n) : error;
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]) {
    const { excludedFieldList, classes } = this.props;

    if (Utils.isNonEmptyArray<NonNullable<Props['excludedFieldList']>[0]>(excludedFieldList)) {
      if (excludedFieldList.indexOf(fieldName) !== -1) {
        return null;
      }
    }

    const fieldNode = super.renderField(fieldName, ...args);
    if (Utils.isNil(fieldNode) || fieldNode === false) return null;

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  private renderPasswordVisibilityToggleButton(): React.ReactNode {
    const { t } = this.props;
    const { passwordInputType } = this.state;

    const { ariaLabelHidePassword, ariaLabelShowPassword } = i18n.fieldsetCredentials.formField.password;
    const isInputTypePassword = passwordInputType === 'password';

    return (
      <InputAdornment position="end">
        <IconButtonTogglePasswordVisibility
          ariaLabel={t(isInputTypePassword ? ariaLabelHidePassword : ariaLabelShowPassword)}
          visibility={!isInputTypePassword}
          onClick={this.onShowPasswordClick}
        />
      </InputAdornment>
    );
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<string>) {
    if (this.isFieldExcluded(fieldName)) return;

    let { error } = fieldState;
    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    return super.setFieldState(fieldName, { ...fieldState, error });
  }

  private onShowPasswordClick(_: React.MouseEvent<HTMLButtonElement>): void {
    this.setState(({ passwordInputType: prevPasswordInputType }) => ({
      passwordInputType: prevPasswordInputType === 'password' ? 'text' : 'password'
    }));
  }

  private isFieldExcluded(fieldName: FieldName): boolean {
    const { excludedFieldList } = this.props;

    if (Utils.isNonEmptyArray<NonNullable<Props['excludedFieldList']>[0]>(excludedFieldList)) {
      return excludedFieldList.indexOf(fieldName) !== -1;
    }

    return false;
  }
}

const ns = [I18N_NS_GLOBAL];

export const SignInCredentialsForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['form-input-group']
    } as ComponentProps['classes']);

    return <SignInCredentialsFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

SignInCredentialsForm.displayName = 'SignInCredentialsForm';

SignInCredentialsForm.defaultProps = {
  className: style['form-base']
};
