import { SchedulingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { MessageViewEvent } from '../../constants/action-context';

const i18n: SchedulingI18n.MessageViewEvent = {
  gridEventDetails: {
    // Translators: Caption for the Event Details grid
    caption: gettext('scheduling', 'messageViewEvent', 'gridCaption'),
    rowHeader: {
      // Translators: Header for the Event Start Date And Time row
      startDateTime: gettext('scheduling', 'messageViewEvent', 'gridRowHeaderStartDateTime'),
      // Translators: Header for the Event End Date And Time row
      endDateTime: gettext('scheduling', 'messageViewEvent', 'gridRowHeaderEndDateTime'),
      // Translators: Header for the Event Duration row
      duration: gettext('scheduling', 'messageViewEvent', 'gridRowHeaderDuration'),
      // Translators: Header for the Event Description row
      description: gettext('scheduling', 'messageViewEvent', 'gridRowHeaderDescription')
    }
  },
  action: {
    acceptEvent: {
      // Translators: Label for the Accept Event action
      label: gettext('scheduling', 'messageViewEvent', 'actionLabelAcceptEvent'),
      context: MessageViewEvent
    },
    declineEvent: {
      // Translators: Label for the Decline Event action
      label: gettext('scheduling', 'messageViewEvent', 'actionLabelDeclineEvent'),
      context: MessageViewEvent
    },
    joinEvent: {
      // Translators: Label for the Join Event action
      label: gettext('scheduling', 'messageViewEvent', 'actionLabelJoinEvent'),
      context: MessageViewEvent
    }
  },
  response: {
    by: {
      // Translators: Header - Accepted for the Event Response grid
      accepted: gettext('scheduling', 'acceptedBy', 'messageViewEvent'),
      // Translators: Header - Declined for the Event Response grid
      declined: gettext('scheduling', 'declinedBy', 'messageViewEvent')
    },
    // Translators: Header - Status for the Event Response grid
    status: gettext('scheduling', 'status', 'messageViewEvent'),
    statusValue: {
      // Translators: Value - Accepted for the Event Response grid
      accepted: gettext('scheduling', 'accepted', 'messageViewEventStatus'),
      // Translators: Value - DEclined for the Event Response grid
      declined: gettext('scheduling', 'declined', 'messageViewEventStatus')
    },
    // Translators: Header - Title for the Event Response grid
    title: gettext('scheduling', 'title', 'messageViewEvent')
  }
};

export default i18n;
