import { createSelector } from '@reduxjs/toolkit';
import {
  AppUserGroup,
  Constants,
  GenderIdentity,
  IAppUserGroup,
  MemberRole,
  NonNullableProps,
  PersonName,
  PostalAddress,
  ReadonlyPartialRecord,
  SigmailAuditId,
  SigmailClientId,
  SigmailOwnerId,
  SigmailUserId,
  Utils,
  Writeable
} from '@sigmail/common';
import {
  DataObjectMsgFolder,
  NewMessageNotificationMethod,
  UserMessageFolderItem,
  UserObjectCarePlansValue,
  UserObjectCircleOfCareValue,
  UserObjectConsultationValue,
  UserObjectContactInfoValue,
  UserObjectContactListValue,
  UserObjectEncounterValue,
  UserObjectEventLogValue,
  UserObjectFolderListValue,
  UserObjectFolderListValue_v2,
  UserObjectHealthDataValue,
  UserObjectPreferencesValue,
  UserObjectProfileBasicValue,
  UserObjectProfilePrivateValue,
  UserObjectProfileProtectedValue,
  UserObjectScheduleValue,
  UserObjectServerRightsValue,
  ValueFormatVersion
} from '@sigmail/objects';
import {
  DEFAULT_MESSAGE_SIGNATURE,
  DEFAULT_PREFERRED_LANGUAGE_CODE,
  DEFAULT_REFERRAL_EXPIRY_IN_DAYS
} from '../../constants';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../actions/constants';
import { EMPTY_PLAIN_OBJECT } from '../constants';
import { UserObjectCache } from '../user-objects-slice/cache';
import { authClaimSelector } from './auth';
import { createUserObjectSelector } from './utils';

/**
 * Selector to extract the basic profile object of the currently logged-in
 * user.
 */
export const basicProfileObjectSelector = createUserObjectSelector<UserObjectProfileBasicValue>(
  process.env.USER_OBJECT_TYPE_PROFILE_BASIC
);

/**
 * Selector to extract the protected profile object of the currently logged-in
 * user.
 */
export const protectedProfileObjectSelector = createUserObjectSelector<UserObjectProfileProtectedValue>(
  process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED
);

/**
 * Selector to extract the private profile object of the currently logged-in
 * user.
 */
export const privateProfileObjectSelector = createUserObjectSelector<UserObjectProfilePrivateValue>(
  process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE
);

/**
 * Selector to extract the folder list object of the currently logged-in
 * user.
 */
export const folderListObjectSelector = createUserObjectSelector<UserObjectFolderListValue>(
  process.env.USER_OBJECT_TYPE_FOLDER_LIST
);

/**
 * Selector to extract the contact info object of the currently logged-in
 * user.
 */
export const contactInfoObjectSelector = createUserObjectSelector<UserObjectContactInfoValue>(
  process.env.USER_OBJECT_TYPE_CONTACT_INFO
);

/**
 * Selector to extract the contact list object of the currently logged-in
 * user.
 */
export const contactListObjectSelector = createUserObjectSelector<UserObjectContactListValue>(
  process.env.USER_OBJECT_TYPE_CONTACT_LIST
);

/**
 * Selector to extract the guest data object of the currently logged-in
 * user.
 */
// export const guestDataObjectSelector = createUserObjectSelector<ValueFormatVersion>(
//   process.env.USER_OBJECT_TYPE_GUEST_DATA
// );

/**
 * Selector to extract the preferences object of the currently logged-in
 * user.
 */
export const preferencesObjectSelector = createUserObjectSelector<UserObjectPreferencesValue>(
  process.env.USER_OBJECT_TYPE_PREFERENCES
);

/**
 * Selector to extract the server rights object of the currently logged-in
 * user.
 */
export const serverRightsObjectSelector = createUserObjectSelector<UserObjectServerRightsValue>(
  process.env.USER_OBJECT_TYPE_SERVER_RIGHTS
);

/** Selector to extract the schedule object of the currently logged-in user. */
export const scheduleObjectSelector = createUserObjectSelector<UserObjectScheduleValue>(
  process.env.USER_OBJECT_TYPE_SCHEDULE
);

/** Selector to extract the care plans object of the currently logged-in user. */
export const carePlansObjectSelector = createUserObjectSelector<UserObjectCarePlansValue>(
  process.env.USER_OBJECT_TYPE_CARE_PLANS
);

/**
 * Selector to extract the circle of care object of the currently logged-in
 * user.
 */
export const circleOfCareObjectSelector = createUserObjectSelector<UserObjectCircleOfCareValue>(
  process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE
);

export const registrationDetailsObjectSelector = createUserObjectSelector<ValueFormatVersion>(
  process.env.USER_OBJECT_TYPE_REGISTRATION_DETAILS
);

export const encounterObjectSelector = createUserObjectSelector<UserObjectEncounterValue>(
  process.env.USER_OBJECT_TYPE_ENCOUNTER
);

export const consultationObjectSelector = createUserObjectSelector<UserObjectConsultationValue>(
  process.env.USER_OBJECT_TYPE_CONSULTATION
);

/** @deprecated use {@link consultationObjectSelector} */
export const eConsultObjectSelector = consultationObjectSelector;

export const eventLogObjectSelector = createUserObjectSelector<UserObjectEventLogValue>(
  process.env.USER_OBJECT_TYPE_EVENT_LOG
);

export const healthDataObjectSelector = createUserObjectSelector<UserObjectHealthDataValue>(
  process.env.USER_OBJECT_TYPE_HEALTH_DATA
);

export const selectUserRole = (basicProfile?: UserObjectProfileBasicValue | undefined) => basicProfile?.role;

/**
 * Selector to extract the role attribute from the profile info of the
 * specified user. If `userId` argument is not specified, current user's ID will
 * be used.
 */
export const userRoleSelector = createSelector(basicProfileObjectSelector, (basicProfileSelector) => {
  return Utils.memoize((userId?: SigmailUserId | undefined) =>
    selectUserRole(UserObjectCache.getValue(basicProfileSelector(userId)))
  );
});

export interface PersonNameAttributes extends Readonly<NonNullableProps<PersonName>> {
  readonly fullName: string;
}

export const selectPersonName = (
  basicProfile?: ReadonlyPartialRecord<keyof PersonName, unknown> | undefined
): PersonNameAttributes => {
  const nameProps = Utils.mapValues(
    Constants.PERSON_NAME_KEY_LIST.reduce((name, key) => {
      name[key] = Utils.stringOrDefault(basicProfile?.[key]);
      return name;
    }, {} as NonNullableProps<PersonName>),
    (value) => Utils.trimOrDefault(value)
  );
  return { ...nameProps, fullName: Utils.joinPersonName(nameProps) };
};

/**
 * Selector to extract all of the name attributes from the profile info of the
 * specified user. If `userId` argument is not specified, current user's ID will
 * be used.
 */
export const personNameSelector = createSelector(basicProfileObjectSelector, (basicProfileSelector) => {
  return Utils.memoize((userId?: SigmailUserId | undefined) =>
    selectPersonName(UserObjectCache.getValue(basicProfileSelector(userId)))
  );
});

export interface PostalAddressAttributes extends Readonly<NonNullableProps<PostalAddress>> {
  readonly fullAddress: string;
}

export const selectPostalAddress = (
  basicProfile?: ReadonlyPartialRecord<keyof PostalAddress, unknown> | undefined
): PostalAddressAttributes => {
  const addressProps = Utils.mapValues(
    Constants.POSTAL_ADDRESS_KEY_LIST.reduce((name, key) => {
      name[key] = Utils.stringOrDefault(basicProfile?.[key]);
      return name;
    }, {} as NonNullableProps<PostalAddress>),
    (value) => Utils.trimOrDefault(value)
  );
  return { ...addressProps, fullAddress: Utils.joinPostalAddress(addressProps) };
};

/**
 * Selector to extract all of the postal address attributes from the profile
 * info of the specified user. If `userId` argument is not specified, current
 * user's ID will be used.
 */
export const postalAddressSelector = createSelector(basicProfileObjectSelector, (basicProfileSelector) => {
  return Utils.memoize((userId?: SigmailUserId | undefined) =>
    selectPostalAddress(UserObjectCache.getValue(basicProfileSelector(userId)))
  );
});

export interface ContactNumberAttributes
  extends Readonly<
    NonNullableProps<
      Pick<
        UserObjectProfileBasicValue,
        `${'cell' | 'fax' | 'home' | 'office'}Number` | 'emailAddress' | 'officeNumberExt'
      >
    >
  > {}

const CONTACT_NUMBER_KEY_LIST: ReadonlyArray<keyof ContactNumberAttributes> = [
  'cellNumber',
  'emailAddress',
  'faxNumber',
  'homeNumber',
  'officeNumber',
  'officeNumberExt'
];

export const selectContactNumber = (
  basicProfile?: ReadonlyPartialRecord<keyof ContactNumberAttributes, unknown> | undefined
): ContactNumberAttributes => {
  return Utils.mapValues(
    CONTACT_NUMBER_KEY_LIST.reduce((name, key) => {
      name[key] = Utils.stringOrDefault(basicProfile?.[key]);
      return name;
    }, {} as Writeable<ContactNumberAttributes>),
    (value) => Utils.trimOrDefault(value)
  );
};

/**
 * Selector to extract all of the contact number attributes from the profile
 * info of the specified user. If `userId` argument is not specified, current
 * user's ID will be used.
 */
export const contactNumberSelector = createSelector(basicProfileObjectSelector, (basicProfileSelector) => {
  return Utils.memoize((userId?: SigmailUserId | undefined) =>
    selectContactNumber(UserObjectCache.getValue(basicProfileSelector(userId)))
  );
});

export interface NonGuestRoleDataAttributes
  extends Readonly<
    NonNullableProps<
      Pick<UserObjectProfileBasicValue, 'specialty' | 'otherSpecialties' | 'academicDegrees'> &
        Pick<UserObjectProfileProtectedValue, `${'license' | 'ohipBilling'}Number`>
    >
  > {}

export interface GuestRoleDataAttributes
  extends Readonly<
    NonNullableProps<
      Pick<UserObjectProfileProtectedValue, 'birthDate' | 'gender' | 'healthCardNumber' | 'healthPlanJurisdiction'>
    >
  > {}

export type RoleBasedDataAttributes =
  | ({ role: Exclude<MemberRole, 'patient'> } & NonGuestRoleDataAttributes)
  | ({ role: Extract<MemberRole, 'patient'> } & GuestRoleDataAttributes);

export const selectRoleBasedData = (
  basicProfile?:
    | ReadonlyPartialRecord<Exclude<keyof NonGuestRoleDataAttributes | 'role', `${string}Number`>, unknown>
    | undefined,
  protectedProfile?:
    | ReadonlyPartialRecord<
        keyof GuestRoleDataAttributes | Extract<keyof NonGuestRoleDataAttributes, `${string}Number`>,
        unknown
      >
    | undefined
) => {
  const role = basicProfile?.role as MemberRole | undefined;
  const isUserRoleGuest = Utils.isGuestRole(role);
  const isUserRoleNonGuest = !isUserRoleGuest && Utils.isNonGuestRole(role);

  let data: RoleBasedDataAttributes;
  if (isUserRoleNonGuest) {
    data = {
      role: role as Exclude<MemberRole, 'patient'>,
      ...Utils.mapValues(
        {
          academicDegrees: basicProfile?.academicDegrees,
          licenseNumber: protectedProfile?.licenseNumber,
          ohipBillingNumber: protectedProfile?.ohipBillingNumber,
          otherSpecialties: basicProfile?.otherSpecialties,
          specialty: basicProfile?.specialty
        },
        (value) => Utils.trimOrDefault(value)
      )
    };
  } else {
    const profile = protectedProfile;

    data = {
      role: role as Extract<MemberRole, 'patient'>,
      birthDate: Utils.trimOrDefault(isUserRoleGuest && profile?.birthDate),
      gender: Utils.trimOrDefault(isUserRoleGuest && profile?.gender, Constants.Gender.Unknown) as GenderIdentity,
      healthCardNumber: Utils.trimOrDefault(isUserRoleGuest && profile?.healthCardNumber),
      healthPlanJurisdiction: Utils.trimOrDefault(
        isUserRoleGuest && profile?.healthPlanJurisdiction,
        DEFAULT_HEALTH_PLAN_JURISDICTION
      )
    };
  }

  return data;
};

/**
 * Selector to extract all of the contact number attributes from the profile
 * info of the specified user. If `userId` argument is not specified, current
 * user's ID will be used.
 */
export const roleBasedDataSelector = createSelector(
  basicProfileObjectSelector,
  protectedProfileObjectSelector,
  (basicProfileSelector, protectedProfileSelector) => {
    return Utils.memoize((userId?: SigmailUserId | undefined) =>
      selectRoleBasedData(
        UserObjectCache.getValue(basicProfileSelector(userId)),
        UserObjectCache.getValue(protectedProfileSelector(userId))
      )
    );
  }
);

export const selectAuditId = (roleAuthClaim: string): SigmailAuditId | undefined => {
  try {
    const decodedToken = Utils.decodeIdToken(roleAuthClaim);
    return decodedToken.auditId as SigmailAuditId;
  } catch {
    /* ignore */
  }
  return undefined;
};

export const selectClientId = (roleAuthClaim: string): SigmailClientId | undefined => {
  try {
    const decodedToken = Utils.decodeIdToken(roleAuthClaim);
    return decodedToken.clientId as SigmailClientId;
  } catch {
    /* ignore */
  }
  return undefined;
};

export const selectOwnerId = (roleAuthClaim: string): SigmailOwnerId | undefined => {
  try {
    const decodedToken = Utils.decodeIdToken(roleAuthClaim);
    return decodedToken.ownerId as SigmailOwnerId;
  } catch {
    /* ignore */
  }
  return undefined;
};

/**
 * Selector to extract the audit ID associated with the currently logged-in
 * user.
 */
export const auditIdSelector = createSelector(authClaimSelector, selectAuditId);

/**
 * Selector to extract the client ID associated with the currently logged-in
 * user.
 */
export const clientIdSelector = createSelector(authClaimSelector, selectClientId);

/**
 * Selector to extract the owner ID associated with the currently logged-in
 * user.
 */
export const ownerIdSelector = createSelector(authClaimSelector, selectOwnerId);

export const selectMembershipByGroupType = (basicProfile?: UserObjectProfileBasicValue | undefined) => {
  const list = Utils.arrayOrDefault<NonNullable<UserObjectProfileBasicValue['memberOf']>[0]>(basicProfile?.memberOf);
  const selectorFn = (groupType: string): ReadonlyArray<IAppUserGroup> => {
    return Utils.filterMap(list, (group) => group.groupType === groupType && new AppUserGroup(group));
  };
  return Utils.memoize(selectorFn) as typeof selectorFn;
};

/**
 * Selector to extract a list of all groups of a specific type the current user
 * is a member of.
 */
export const membershipByGroupTypeSelector = createSelector(basicProfileObjectSelector, (basicProfileSelector) => {
  return selectMembershipByGroupType(UserObjectCache.getValue(basicProfileSelector(/***/)));
});

export const selectMessageFolderMap = (
  folderList?: UserObjectFolderListValue | undefined
): ReadonlyPartialRecord<string, UserMessageFolderItem> => {
  return Utils.isNil(folderList)
    ? EMPTY_PLAIN_OBJECT
    : Utils.transform(
        (folderList as UserObjectFolderListValue_v2).msg,
        (folderItemMap, folder, folderKey) => {
          if (
            Utils.isNonArrayObjectLike<UserMessageFolderItem>(folder) &&
            DataObjectMsgFolder.isValidId(folder.id) &&
            folder.type === process.env.DATA_OBJECT_TYPE_MSG_FOLDER
          ) {
            folderItemMap[folderKey] = folder;
          }
        },
        {} as Record<string, UserMessageFolderItem>
      );
};

/**
 * Selector to extract a map of all message folders associated with the
 * currently logged-in user's account.
 */
export const messageFolderMapSelector = createSelector(folderListObjectSelector, (folderListSelector) => {
  return Utils.memoize(() => selectMessageFolderMap(UserObjectCache.getValue(folderListSelector(/***/))));
});

export const selectPreferences = (preferences?: UserObjectPreferencesValue | undefined) => {
  const { noNotifyOnNewMessage, ...prefs } = { ...preferences };

  let notifyOnNewMessage: Array<NewMessageNotificationMethod> = [];
  if (Utils.isNil(noNotifyOnNewMessage) || typeof noNotifyOnNewMessage === 'boolean') {
    if (!noNotifyOnNewMessage) {
      notifyOnNewMessage = ['email', 'sms'];
    }
  } else if (Utils.isString(noNotifyOnNewMessage)) {
    if (noNotifyOnNewMessage === 'email') {
      notifyOnNewMessage.push('sms');
    } else if (noNotifyOnNewMessage === 'sms') {
      notifyOnNewMessage.push('email');
    }
  } else if (Utils.isArray(noNotifyOnNewMessage)) {
    if (!noNotifyOnNewMessage.includes('email')) notifyOnNewMessage.push('email');
    if (!noNotifyOnNewMessage.includes('sms')) notifyOnNewMessage.push('sms');
    if (!noNotifyOnNewMessage.includes('webPush')) notifyOnNewMessage.push('webPush');
  }

  const lastActiveGroupId = Utils.isNil(prefs.lastActiveGroupId) ? null : prefs.lastActiveGroupId!;
  const messageSignature = Utils.stringOrDefault<string>(prefs.messageSignature, DEFAULT_MESSAGE_SIGNATURE);
  const messageCategoryList = Utils.isNil(prefs.messageCategoryList) ? null : prefs.messageCategoryList!;
  const languagePreference = Utils.stringOrDefault<string>(prefs.languagePreference, DEFAULT_PREFERRED_LANGUAGE_CODE);

  let referralExpiry = Utils.numberOrDefault<number>(prefs.referralExpiry, DEFAULT_REFERRAL_EXPIRY_IN_DAYS);
  referralExpiry = Math.max(0, Math.min(referralExpiry!, 366));

  return {
    languagePreference,
    lastActiveGroupId,
    messageCategoryList,
    messageSignature,
    notifyOnNewMessage: notifyOnNewMessage as ReadonlyArray<typeof notifyOnNewMessage[0]>,
    referralExpiry
  } as const;
};

/** Selector to extract a map of currently logged-in user's preferences. */
export const preferencesSelector = createSelector(preferencesObjectSelector, (userPreferencesSelector) => {
  return selectPreferences(UserObjectCache.getValue(userPreferencesSelector(/***/)));
});
