import { KeyboardDateTimePicker as DateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers';
import React from 'react';

export interface DateTimePickerFieldProps extends KeyboardDateTimePickerProps {}

const DatePickerFieldComponent = React.memo<DateTimePickerFieldProps>((props) => <DateTimePicker {...props} />);

export const DateTimePickerField = React.forwardRef<HTMLDivElement, DateTimePickerFieldProps>((props, ref) => (
  <DatePickerFieldComponent {...props} innerRef={ref || props.innerRef} />
));

DateTimePickerField.displayName = 'DateTimePickerField';

DateTimePickerField.defaultProps = {
  ampm: true,
  autoOk: true,
  disableFuture: true,
  format: 'yyyy-MM-dd HH:mm',
  fullWidth: true,
  inputVariant: 'outlined',
  margin: 'none',
  openTo: 'year',
  strictCompareDates: true,
  variant: 'inline'
};
