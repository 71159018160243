import type { SigmailObjectTypeCode } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectCalendarEventValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_CALENDAR_EVENT;

/** @public */
export class DataObjectCalendarEvent extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    //
    // TODO validate
    //

    return true;
  }

  public updateValue(newValue: DV): Promise<DataObjectCalendarEvent> {
    const Class = this.constructor as typeof DataObjectCalendarEvent;
    return Class.updateValue(this, newValue);
  }
}
