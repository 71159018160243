import { ApiActionPayload } from '@sigmail/app-state';
import { AppException, Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '..';

export type ErrorResponseJson = Omit<Api.BatchUpdateResponseData, 'KeysOperations' | 'DataOperations' | 'CredentialsOperations'>;

export function isValidErrorResponseJson(responseJson: any): responseJson is ErrorResponseJson {
  return (
    !Utils.isNil(responseJson) &&
    Utils.isPlainObject(responseJson) &&
    (Utils.isArray(responseJson.Keys) ||
      Utils.isArray(responseJson.DataObjects) ||
      Utils.isArray(responseJson.UserObjects) ||
      Utils.isArray(responseJson.UserCredentials))
  );
}

export const batchUpdateDataAction = (payload: ApiActionPayload.BatchUpdateData): AppThunk<Promise<Api.BatchUpdateResponseData>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'batchUpdateDataAction:');
    const apiAccessToken = Utils.isString(payload.accessToken) ? payload.accessToken : getState().auth.accessToken;
    let retry;

    do {
      retry = false;
      Logger.info('== BEGIN ==');
      try {
        return await apiService.batchUpdateData(apiAccessToken, payload.mutations);
      } catch (error) {
        if (!(error instanceof Api.ServiceException && error.response.status === 400)) {
          throw error;
        }

        const responseJson = await Utils.tryGetResponseJson<{ [key: string]: any[] }>(error.response, undefined);
        if (!isValidErrorResponseJson(responseJson)) {
          throw error;
        }

        let errorToThrow = error;
        // try and find the first version conflict error (if any)
        let operationGroup: string | undefined = undefined;
        let op: Api.BatchUpdateOperationError<{ id: number; [key: string]: any }> | undefined = undefined;
        for (const prop in responseJson) {
          if (!Utils.isArray(responseJson[prop])) {
            continue;
          }

          op = responseJson[prop].find(
            (op) => op.operation === 'update' && op.err === Api.Constants.BATCH_UPDATE_ERROR_CODE_VERSION_CONFLICT
          );
          if (!Utils.isNil(op)) {
            operationGroup = prop;
            break;
          }
        }

        if (!Utils.isNil(op) && Utils.isString(operationGroup)) {
          const objectId = op.data.id;
          Logger.warn('Version conflict detected.', `Key = ${operationGroup},`, `Object ID = ${objectId}`);
          errorToThrow = new Api.VersionConflictException(error.response);
          errorToThrow.stack = error.stack;

          const hasDataUpdater = (entry: any) =>
            entry.operation === 'update' && entry.data.id === objectId && typeof entry.dataUpdater === 'function';

          let index = -1;
          let mutationKey:
            | keyof Pick<Api.BatchUpdateRequestData, 'dataObjects' | 'keys' | 'userCredentialsObjects' | 'userObjects'>
            | undefined = undefined;
          if (operationGroup === 'Keys') {
            index = Utils.isArray(payload.mutations.keys) ? payload.mutations.keys.findIndex(hasDataUpdater) : -1;
            mutationKey = 'keys';
          } else if (operationGroup === 'DataObjects') {
            index = Utils.isArray(payload.mutations.dataObjects) ? payload.mutations.dataObjects.findIndex(hasDataUpdater) : -1;
            mutationKey = 'dataObjects';
          } else if (operationGroup === 'UserObjects') {
            index = Utils.isArray(payload.mutations.userObjects) ? payload.mutations.userObjects.findIndex(hasDataUpdater) : -1;
            mutationKey = 'userObjects';
          } else if (operationGroup === 'UserCredentialsObjects') {
            index = Utils.isArray(payload.mutations.userCredentialsObjects)
              ? payload.mutations.userCredentialsObjects.findIndex(hasDataUpdater)
              : -1;
            mutationKey = 'userCredentialsObjects';
          }

          if (index !== -1 && Utils.isString(mutationKey)) {
            const { dataUpdater } = payload.mutations[mutationKey]![index];
            if (typeof dataUpdater === 'function') {
              try {
                await Promise.resolve(dataUpdater(op.data.value, payload.mutations));
                retry = true;
                continue;
              } catch (dataUpdaterError) {
                Logger.warn('dataUpdater threw exception:', dataUpdaterError);
                throw dataUpdaterError;
              }
            }
          }
        }

        throw errorToThrow;
      } finally {
        Logger.info('== END ==');
      }
    } while (retry);

    // XXX control should never reach here!!!
    throw new AppException(Constants.Error.S_ERROR, 'Unexpected error.');
  };
};
