import { Nullable, Utils, ValueObject } from '@sigmail/common';
import { ContactListItemExt, ContactListValueObject } from '@sigmail/objects';
import { OrderedSet as ImmutableOrderedSet, Set as ImmutableSet, hash } from 'immutable';
import React from 'react';
import { UseGuestContactListParams, UseMembershipGroupContactListParams, useGuestContactList } from '.';
import {
  BatchQueryRequest,
  ExcludeListItem,
  FilterFunction,
  compareContactListItem,
  filterContactList
} from '../../utils/contact-list';
import { EMPTY_ARRAY } from '../constants';
import { UseLinkedContactListParams, useLinkedContactList } from './use-linked-contact-list';
import { UseClientContactListParams, useClientContactList } from './use-client-contact-list';
import { UseGlobalContactListParams, useGlobalContactList } from './use-global-contact-list';
import { UseGroupGuestContactListParams, useGroupGuestContactList } from './use-group-guest-contact-list';
import { UseGroupMemberContactListParams, useGroupMemberContactList } from './use-group-member-contact-list';
import { useMembershipGroupContactList } from './use-membership-group-contact-list';

interface ContactListInclude {
  linkedContactList: UseLinkedContactListParams;
  clientContactList: UseClientContactListParams;
  globalContactList: UseGlobalContactListParams;
  groupMemberContactList: UseGroupMemberContactListParams;
  groupGuestContactList: UseGroupGuestContactListParams;
  guestContactList: UseGuestContactListParams;
  membershipGroupContactList: UseMembershipGroupContactListParams;
}

export interface UseContactListParams {
  exclude?: Nullable<ReadonlyArray<Nullable<ExcludeListItem>>>;
  filterFn?: Nullable<FilterFunction<ContactListItemExt>>;
  forceFetch?: Nullable<boolean>;
  forceInclude?: Nullable<ReadonlyArray<ContactListItemExt>>;
  include?: Nullable<
    {
      [K in keyof ContactListInclude]?: Nullable<
        | Omit<ContactListInclude[K], 'exclude' | 'filterFn' | 'forceFetch' | 'unsorted'>
        | (K extends 'membershipGroupContactList' ? string : boolean)
      >;
    }
  >;
  unsorted?: Nullable<boolean>;
}

export type UseContactListResult = [list: ReadonlyArray<ContactListValueObject>, query: BatchQueryRequest];

const mergeBatchQueryRequests = (...args: BatchQueryRequest[]): Required<BatchQueryRequest> => {
  let dataObjects: NonNullable<BatchQueryRequest['dataObjects']>['ids'] = [];
  let userObjects: Array<NonNullable<BatchQueryRequest['userObjectsByType']>[0] & ValueObject> = [];

  for (const request of args) {
    if (Utils.isNonEmptyArray(request.dataObjects?.ids)) {
      dataObjects.push(...request.dataObjects!.ids);
    }

    if (Utils.isNonEmptyArray(request.userObjectsByType)) {
      userObjects.push(
        ...request.userObjectsByType.map<typeof userObjects[0]>((entry) => ({
          ...entry,
          hashCode: () => entry.type * 31 + entry.userId,
          equals: (other) =>
            Utils.isNonArrayObjectLike<typeof entry>(other) &&
            other.type === entry.type &&
            other.userId === entry.userId
        }))
      );
    }
  }

  return {
    dataObjects: { ids: ImmutableSet(dataObjects).toArray() },
    userObjectsByType: ImmutableSet(userObjects).toArray()
  };
};

export const toContactListValueObject = (contact: ContactListItemExt): ContactListValueObject => ({
  ...contact,
  equals: (other) =>
    Utils.isNonArrayObjectLike<typeof contact>(other) && other.type === contact.type && other.id === contact.id,
  hashCode: () => hash(contact.type) * 31 + hash(contact.id)
});

export const useContactList = (params?: UseContactListParams | null | undefined): UseContactListResult => {
  const exclude = params?.exclude;
  const filterFn = typeof params?.filterFn === 'function' ? params!.filterFn! : filterContactList;
  const forceFetch = params?.forceFetch === true;
  const unsorted = params?.unsorted === true;

  let {
    clientContactList: includeClientContactList,
    globalContactList: includeGlobalContactList,
    groupMemberContactList: includeGroupMemberContactList,
    groupGuestContactList: includeGroupGuestContactList,
    guestContactList: includeGuestContactList,
    linkedContactList: includeLinkedContactList,
    membershipGroupContactList: includeMembershipGroupContactList
  } = Object.assign({}, params?.include as ContactListInclude);

  includeClientContactList = Utils.isNonArrayObjectLike(includeClientContactList)
    ? { ...includeClientContactList, forceFetch }
    : { groups: includeClientContactList === true, unsorted: true, users: includeClientContactList === true };

  includeGlobalContactList = Utils.isNonArrayObjectLike(includeGlobalContactList)
    ? { ...includeGlobalContactList, forceFetch }
    : { groups: includeGlobalContactList === true, unsorted: true, users: includeGlobalContactList === true };

  includeGroupMemberContactList = Utils.isNonArrayObjectLike(includeGroupMemberContactList)
    ? { ...includeGroupMemberContactList, forceFetch }
    : { unsorted: true, users: includeGroupMemberContactList === true };

  includeGroupGuestContactList = Utils.isNonArrayObjectLike(includeGroupGuestContactList)
    ? { ...includeGroupGuestContactList, forceFetch }
    : { groups: includeGroupGuestContactList === true, unsorted: true, users: includeGroupGuestContactList === true };

  includeGuestContactList = Utils.isNonArrayObjectLike(includeGuestContactList)
    ? { ...includeGuestContactList, forceFetch }
    : { groups: includeGuestContactList === true, unsorted: true, users: includeGuestContactList === true };

  includeLinkedContactList = Utils.isNonArrayObjectLike(includeLinkedContactList)
    ? { ...includeLinkedContactList, forceFetch }
    : { unsorted: true, users: includeLinkedContactList === true };

  includeMembershipGroupContactList = Utils.isString(includeMembershipGroupContactList)
    ? { groupType: includeMembershipGroupContactList, unsorted: true }
    : includeMembershipGroupContactList;

  const otherContactList = Utils.arrayOrDefault<NonNullable<UseContactListParams['forceInclude']>[0]>(
    params?.forceInclude,
    EMPTY_ARRAY
  );

  const [clientContactList, clientContactListQuery] = useClientContactList(includeClientContactList);
  const [globalContactList, globalContactListQuery] = useGlobalContactList(includeGlobalContactList);
  const [groupMemberContactList, groupMemberContactListQuery] = useGroupMemberContactList(
    includeGroupMemberContactList
  );

  const [groupGuestContactList, groupGuestContactListQuery] = useGroupGuestContactList(includeGroupGuestContactList);
  const [guestContactList, guestContactListQuery] = useGuestContactList(includeGuestContactList);
  const [linkedContactList, linkedContactListQuery] = useLinkedContactList(includeLinkedContactList);
  const [membershipGroupContactList, membershipGroupContactListQuery] = useMembershipGroupContactList(
    includeMembershipGroupContactList
  );

  return React.useMemo<UseContactListResult>(() => {
    let contactList: Array<ContactListValueObject> = EMPTY_ARRAY;

    const query = mergeBatchQueryRequests(
      clientContactListQuery,
      groupGuestContactListQuery,
      globalContactListQuery,
      groupMemberContactListQuery,
      guestContactListQuery,
      linkedContactListQuery,
      membershipGroupContactListQuery
    );

    if (query.dataObjects.ids.length === 0 && query.userObjectsByType.length === 0) {
      const allContactLists = [
        otherContactList, // <=== IMPORTANT: otherContactList MUST be the first one
        clientContactList, // <=== IMPORTANT: clientContactList MUST be the second one
        groupGuestContactList, // <=== IMPORTANT: groupGuestContactList MUST be the third one

        globalContactList,
        groupMemberContactList,
        membershipGroupContactList,

        guestContactList,
        linkedContactList
      ];

      if (allContactLists.some(Utils.isNil)) {
        return [undefined!, query];
      }

      contactList = ImmutableOrderedSet(
        filterFn(Utils.flatten(allContactLists), { exclude }).map(toContactListValueObject)
      ).toArray();

      if (contactList.length > 1 && !unsorted) {
        contactList.sort(compareContactListItem);
      }
    }

    return [contactList, query];
  }, [
    clientContactList,
    clientContactListQuery,
    exclude,
    filterFn,
    globalContactList,
    globalContactListQuery,
    groupGuestContactList,
    groupGuestContactListQuery,
    groupMemberContactList,
    groupMemberContactListQuery,
    guestContactList,
    guestContactListQuery,
    linkedContactList,
    linkedContactListQuery,
    membershipGroupContactList,
    membershipGroupContactListQuery,
    otherContactList,
    unsorted
  ]);
};
