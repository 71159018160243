import type {
  GenderIdentity,
  MessageRecipient,
  MessageSenderOrReceiver,
  Nullable,
  PersonName,
  SigmailUserId
} from '@sigmail/common';
import { Constants, Utils } from '@sigmail/common';
import type {
  ContactListItemLike,
  GroupContactListItemLike,
  UserContactListItemLike,
  UserObjectPreferencesValue
} from '@sigmail/objects';
import { differenceInYears } from 'date-fns';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../../app-state/actions/constants';
import { selectPreferences } from '../../app-state/selectors/user-object';
import { getSpecialtyListByRole } from '../get-specialty-list-by-role';

export { filterContactList } from './filter-contact-list';
export type { BatchQueryRequest, ExcludeListItem, FilterFunction } from './types';

const RE_BIRTH_DATE = /^[1-2]\d{3}-[0-1]\d-[0-3]\d/;

const fromGroupRecipient = (recipient: MessageSenderOrReceiver): ContactListItemLike => {
  if (recipient.type !== 'group') throw new Error('Invalid argument.');

  const { type, id, groupType, groupName } = recipient;
  return { type, id, groupType, groupData: { groupName } };
};

const fromUserRecipient = (recipient: MessageSenderOrReceiver): ContactListItemLike => {
  if (recipient.type !== 'user') throw new Error('Invalid argument.');

  const { type, id, prefix, firstName, middleName, lastName, suffix } = recipient;
  return { type, id, userData: { prefix, firstName, middleName, lastName, suffix } };
};

const isValidEntityType = (value?: unknown): value is MessageRecipient['entityType'] =>
  value === 'primary' || value === 'secondary';

const toGroupMessageRecipient = (
  listItem: GroupContactListItemLike,
  entityType?: Nullable<MessageRecipient['entityType']>
): MessageRecipient => ({
  entityType: isValidEntityType(entityType) ? entityType : 'primary',
  id: listItem.id,
  type: listItem.type,
  groupType: listItem.groupType,
  groupName: listItem.groupData.groupName
});

const toUserMessageRecipient = (
  { id, type, userData: { noNotifyOnNewMessage, ...userData } }: UserContactListItemLike,
  entityType?: Nullable<MessageRecipient['entityType']>
): MessageRecipient => {
  const { notifyOnNewMessage } = selectPreferences({ noNotifyOnNewMessage } as UserObjectPreferencesValue);
  let cellNumber: string | undefined;
  let emailAddress: string | undefined;
  let webPushSubscriberId: SigmailUserId | undefined;

  if (notifyOnNewMessage.includes('email')) {
    const email = Utils.trimOrDefault(userData.emailAddress);
    if (email.length > 0) emailAddress = email;
  }

  if (notifyOnNewMessage.includes('sms')) {
    const phoneNumber = Utils.trimOrDefault(userData.cellNumber).replaceAll(/\D/g, '');
    if (phoneNumber.length > 0) cellNumber = phoneNumber;
  }

  if (notifyOnNewMessage.includes('webPush')) {
    webPushSubscriberId = id;
  }

  return {
    ...Utils.pick(userData, Constants.PERSON_NAME_KEY_LIST),
    cellNumber,
    emailAddress,
    entityType: isValidEntityType(entityType) ? entityType : 'primary',
    id,
    // @ts-expect-error
    isOneTimeContact: userData.isOneTimeContact,
    languagePreference: userData.languagePreference,
    role: userData.role,
    type,
    webPushSubscriberId
  };
};

export class ContactListItemUtil {
  private readonly contact: Nullable<ContactListItemLike>;

  public constructor(contact?: Nullable<ContactListItemLike>) {
    this.contact = contact;
  }

  public static compareFullName(
    contact1?: Nullable<ContactListItemLike>,
    contact2?: Nullable<ContactListItemLike>
  ): number {
    const name1 = this.getFullName(contact1);
    const name2 = this.getFullName(contact2);
    return name1.localeCompare(name2, 'en');
  }

  public static fromMessageRecipient(recipient: MessageSenderOrReceiver): ContactListItemLike {
    return recipient.type === 'group' ? fromGroupRecipient(recipient) : fromUserRecipient(recipient);
  }

  public static getAge(contact?: Nullable<ContactListItemLike>, dtToday?: Nullable<Date>): number | null {
    const dtBirth = ContactListItemUtil.getBirthDate(contact);
    const dtNow = Utils.isValidDate(dtToday) ? dtToday : new Date();
    return Utils.isValidDate(dtBirth) ? differenceInYears(dtNow, dtBirth) : null;
  }

  public static getBirthDate(contact?: Nullable<ContactListItemLike>): Date | null {
    if (!this.isUserContactLike(contact)) return null;

    const { birthDate: value } = contact.userData;
    let dtBirth: Date = undefined!;
    if (Utils.isString(value) && RE_BIRTH_DATE.test(value)) {
      dtBirth = new Date(value);
    }

    return Utils.isValidDate(dtBirth) ? dtBirth : null;
  }

  public static getFullName(contact?: Nullable<ContactListItemLike>): string {
    let nameProps: PersonName = { prefix: '', firstName: '', middleName: '', lastName: '', suffix: '' };
    if (this.is(contact)) {
      if (contact.type === 'group' && Utils.isNonArrayObjectLike<typeof contact.groupData>(contact.groupData)) {
        nameProps.firstName = contact.groupData.groupName;
      } else if (contact.type === 'user' && Utils.isNonArrayObjectLike(contact.userData)) {
        nameProps = Utils.mapValues(nameProps, (_, key) => contact.userData[key]);
      }
    }
    return Utils.joinPersonName(nameProps);
  }

  public static getGender(contact?: Nullable<ContactListItemLike>): GenderIdentity {
    return Utils.stringOrDefault(
      this.isUserContactLike(contact) && contact.userData.gender,
      Constants.Gender.Unknown
    ).trim() as GenderIdentity;
  }

  public static getHealthPlanNumber(contact?: Nullable<ContactListItemLike>): string {
    return Utils.trimOrDefault(this.isUserContactLike(contact) && contact.userData.healthCardNumber);
  }

  public static getHealthPlanJurisdiction(contact?: Nullable<ContactListItemLike>): string {
    return this.isUserContactLike(contact)
      ? Utils.trimOrDefault(contact.userData.healthPlanJurisdiction, DEFAULT_HEALTH_PLAN_JURISDICTION)
      : '';
  }

  public static getRole(contact?: Nullable<ContactListItemLike>): string {
    return Utils.stringOrDefault(this.isUserContactLike(contact) && contact.userData.role);
  }

  public static getSpecialty(contact?: Nullable<ContactListItemLike>): string {
    if (this.isUserContactLike(contact)) {
      const { role, specialty } = contact.userData;
      if (Utils.isString(specialty) && specialty.length > 0) {
        const specialtyListI18n = getSpecialtyListByRole(role);
        const index = specialtyListI18n.findIndex(({ code }) => code === specialty);
        if (index > -1) return specialtyListI18n[index].label;
      }
    }
    return '';
  }

  public static is(contact?: unknown): contact is ContactListItemLike {
    return this.isGroupContactLike(contact) || this.isUserContactLike(contact);
  }

  public static isGroupContactLike(contact?: any): contact is GroupContactListItemLike {
    return (
      Utils.isNonArrayObjectLike<GroupContactListItemLike>(contact) &&
      contact.type === 'group' &&
      Utils.isNonArrayObjectLike(contact.groupData)
    );
  }

  public static isUserContactLike(contact?: unknown): contact is UserContactListItemLike {
    return (
      Utils.isNonArrayObjectLike<UserContactListItemLike>(contact) &&
      contact.type === 'user' &&
      Utils.isNonArrayObjectLike(contact.userData)
    );
  }

  public static toMessageRecipient(
    contact: ContactListItemLike,
    entityType?: Nullable<MessageRecipient['entityType']>
  ): MessageRecipient {
    if (!this.is(contact)) throw new Error('Invalid argument.');

    return contact.type === 'group'
      ? toGroupMessageRecipient(contact as GroupContactListItemLike, entityType)
      : toUserMessageRecipient(contact as UserContactListItemLike, entityType);
  }

  public compareFullName(contact?: Nullable<ContactListItemLike>): number {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.compareFullName(this.contact, contact);
  }

  public getAge(): number | null {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.getAge(this.contact);
  }

  public getBirthDate(): Date | null {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.getBirthDate(this.contact);
  }

  public getFullName(): string {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.getFullName(this.contact);
  }

  public getGender(): GenderIdentity {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.getGender(this.contact);
  }

  public getHealthPlanNumber(): string {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.getHealthPlanNumber(this.contact);
  }

  public getHealthPlanJurisdiction(): string {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.getHealthPlanJurisdiction(this.contact);
  }

  public getRole(): string {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.getRole(this.contact);
  }

  public getSpecialty(): string {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.getSpecialty(this.contact);
  }

  public is(): boolean {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.is(this.contact);
  }

  public isGroupContactLike(): boolean {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.isGroupContactLike(this.contact);
  }

  public isUserContactLike(): boolean {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.isUserContactLike(this.contact);
  }

  public toMessageRecipient(): MessageRecipient {
    const Class = this.constructor as typeof ContactListItemUtil;
    return Class.toMessageRecipient(this.contact!);
  }
}

/** @deprecated use {@link ContactListItemUtil.compareFullName} */
export function compareContactListItem(contact1: ContactListItemLike, contact2: ContactListItemLike): number {
  return ContactListItemUtil.compareFullName(contact1, contact2);
}

/** @deprecated use {@link ContactListItemUtil.toMessageRecipient} */
export function contactListItemToMessageRecipient(
  contact: ContactListItemLike,
  entityType?: MessageRecipient['entityType']
): MessageRecipient {
  return ContactListItemUtil.toMessageRecipient(contact, entityType);
}

/** @deprecated use {@link ContactListItemUtil.getFullName} */
export function getFullNameFromContactListItem(contact?: Nullable<ContactListItemLike>) {
  return ContactListItemUtil.getFullName(contact);
}

/** @deprecated use {@link ContactListItemUtil.getSpecialty} */
export const getSpecialtyFromContactListItem = (contact?: Nullable<ContactListItemLike>): string => {
  return ContactListItemUtil.getSpecialty(contact);
};

/** @deprecated use {@link ContactListItemUtil.is} */
export function isContactListItemLike(contact?: unknown): contact is ContactListItemLike {
  return ContactListItemUtil.is(contact);
}

/** @deprecated use {@link ContactListItemUtil.isGroupContactLike} */
export function isGroupContactListItemLike(contact?: unknown): contact is GroupContactListItemLike {
  return ContactListItemUtil.isGroupContactLike(contact);
}

/** @deprecated use {@link ContactListItemUtil.isUserContactLike} */
export function isUserContactListItemLike(contact?: unknown): contact is UserContactListItemLike {
  return ContactListItemUtil.isUserContactLike(contact);
}
