import { StoreStateReminderNotification } from '@sigmail/app-state';
import { createSelector } from 'reselect';
import { RootState } from '../root-reducer';

/** Selector to get the reminder notification state from the store. */
const reminderNotificationStateSelector: Reselect.Selector<RootState, StoreStateReminderNotification> = (state) =>
  state.reminderNotification;

/** Selector to extract the active from reminder notification state. */
export const activeReminderNotificationSelector = createSelector(
  reminderNotificationStateSelector,
  (state) => state.active
);

/** Selector to extract the event ongoing from reminder notification state. */
export const eventOnGoingReminderNotificationSelector = createSelector(
  reminderNotificationStateSelector,
  (state) => state.eventOnGoing
);
