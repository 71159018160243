import { SignInSuccessAuthOnly } from '@sigmail/app-state';
import { AppException, AuthException, Constants, PersonName, SigmailObjectId, Utils } from '@sigmail/common';
import { EncryptWithParametersAlgorithmParams, getAlgorithm } from '@sigmail/crypto';
import {
  CryptographicKey,
  CryptographicKeyMaster,
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  IUserObject,
  SharedParamsEmailTokenUserRegistration,
  UserCredentialsEmailToken,
  UserObjectContactInfoValue,
  UserObjectProfileBasicValue,
  UserObjectProfileProtectedValue,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import * as EmailTemplateParams from '../../../../constants/email-template-params';
import { generateCredentialHash } from '../../../../utils/generate-credential-hash';
import { signInAction } from '../../../auth-slice/sign-in-action';
import { EMPTY_ARRAY } from '../../../constants';
import {
  basicProfileObjectSelector as selectUserProfileBasic,
  protectedProfileObjectSelector as selectUserProfileProtected
} from '../../../selectors/user-object';
import { UserObjectCache } from '../../../user-objects-slice/cache';
import { FetchObjectsRequestData } from '../../base-action';
import { DEFAULT_ACCESS_CODE_DECLINE_INVITATION } from '../../constants';
import { BaseSendAccountInvitationAction, BaseSendAccountInvitationPayload, BaseSendAccountInvitationState } from './base';

export interface ContactInfoBasic extends Pick<UserObjectContactInfoValue, keyof PersonName | 'cellNumber' | 'emailAddress' | 'role'> {
  readonly relationship: string;
}

export type ContactInfoProtected = {};

type LinkedCaregiverContact = Extract<NonNullable<UserObjectProfileProtectedValue['linkedContactList']>[0], { type: 'caregiver' }>;
type LinkedCareRecipientContact = Extract<NonNullable<UserObjectProfileProtectedValue['linkedContactList']>[0], { type: 'careRecipient' }>;

export interface Payload extends BaseSendAccountInvitationPayload {
  readonly contactInfo: ContactInfoBasic;
  readonly password: string;
  readonly username: string;
}

interface State extends BaseSendAccountInvitationState {
  declineToken: string;
  careRecipient: LinkedCareRecipientContact;
  caregiver: LinkedCaregiverContact;
  declineCredentialId: SigmailObjectId;
  serverRightsObject: IUserObject<UserObjectServerRightsValue>;
  declineSharedParameters: Pick<SharedParamsEmailTokenUserRegistration, 'response' | 'salt'>;
}

export class SendCaregiverAccountInvitationAction extends BaseSendAccountInvitationAction<Payload, State> {
  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();

    this.state.declineSharedParameters = {
      response: 'declineInvitation',
      salt: Utils.generateSalt('hex')
    };

    return result;
  }

  /** @override */
  protected generateEmailToken() {
    super.generateEmailToken();

    const { token } = this.state;
    this.state.declineToken = `${token}#decline`;
  }

  /** @override */
  protected createBasicProfileValue(): UserObjectProfileBasicValue {
    const { relationship, ...profileDataBasic } = this.payload.contactInfo;
    return { $$formatver: 5, ...profileDataBasic };
  }

  /** @override */
  protected createProtectedProfileValue(): UserObjectProfileProtectedValue {
    return { $$formatver: 2, linkedContactList: [this.state.careRecipient] };
  }

  /** override */
  protected async addInsertOperationForUserProfileProtected(): Promise<void> {
    const basicProfile = await this.getUserObjectValue(selectUserProfileBasic, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC });
    if (Utils.isNil(basicProfile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Basic profile object could not be fetched.');
    }

    this.state.careRecipient = {
      ...Utils.pick(basicProfile, Constants.PERSON_NAME_KEY_LIST),
      id: this.state.currentUser.id,
      type: 'careRecipient'
    };

    await super.addInsertOperationForUserProfileProtected();
  }

  /** override */
  protected createContactInfoValue(): UserObjectContactInfoValue {
    return {
      $$formatver: 2,
      ...this.payload.contactInfo,
      noNotifyOnNewMessage: ['webPush']
    };
  }

  /** @override */
  protected async createIdsRequestData(): Promise<Api.GetIdsRequestData> {
    const { claims, groupIdList, ids, ...request } = await super.createIdsRequestData();
    const { userClaim } = await this.fetchUserServerRights();

    const idsRequest = ids.ids!.filter(({ type }) => type !== process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN);
    idsRequest!.push({ type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, count: 2 });

    return { ...request, claims: [userClaim], ids: { ...ids, ids: idsRequest } };
  }

  private async fetchUserServerRights(): Promise<UserObjectServerRightsValue> {
    this.logger.info('Fetching server rights of current user.');

    const { accessToken, roleAuthClaim: authState } = this.state;
    const { id: userId } = this.state.currentUser;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS, userId }]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const serverRightsObject = this.findAndCreateUserObject(userObjectList, UserObjectServerRights, { userId });
    if (Utils.isNil(serverRightsObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'User server rights object could not be fetched.');
    }

    this.state.serverRightsObject = serverRightsObject;
    return serverRightsObject.decryptedValue();
  }

  /** override */
  protected async generateIdSequence(): Promise<void> {
    await super.generateIdSequence();

    const { idRecord } = this.state;
    [, this.state.declineCredentialId] = idRecord[process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN];

    const { accessCode, batchUpdateClaims, currentUser, dtServer, roleAuthClaim } = this.state;

    const userClaim = this.findClaim(batchUpdateClaims, { name: 'user', userId: currentUser.id });
    if (Utils.isNil(userClaim)) {
      throw new AppException(Constants.Error.E_CLAIM_MISSING_OR_INVALID);
    }

    const { linkedContactList } = Utils.decodeIdToken(userClaim);
    if (
      !Utils.isNonEmptyArray<Pick<LinkedCaregiverContact, 'id' | 'type'>>(linkedContactList) ||
      linkedContactList[0]!.type !== 'caregiver'
    ) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, '<linkedContactList> is missing or invalid in user claim.');
    }

    const [{ id, type }] = linkedContactList;
    const { role, ...contactInfo } = this.payload.contactInfo;

    this.state.caregiver = {
      ...contactInfo,
      accessCode,
      dtInvited: dtServer.getTime(),
      id,
      status: 'pending',
      type
    };

    batchUpdateClaims.push(roleAuthClaim);
  }

  /** override */
  protected async generateRequestBody(): Promise<void> {
    const { authState, credentialId, keyId, hexSalt } = await this.srpExchangeCredentials();

    await super.generateRequestBody();

    await this.addUpdateOperationForDeclineEmailToken();
    await this.addInsertOperationForGuestPrivateKey(authState, credentialId, keyId, hexSalt); // 111 (Guest)
    await this.addUpdateOperationForProtectedProfile(); // 402
    await this.addUpdateOperationForServerRights(); // 411
    await this.encryptAuditAndClientKeyWithMasterKey(); // 871
  }

  /** @override */
  protected async fetchClientAdminData(): Promise<void> {
    this.logger.info("Fetching API server's current date and time.");
    this.state.dtServer = await this.dispatchFetchServerDateAndTime();
  }

  private async srpExchangeCredentials() {
    this.logger.info('Initiating the sign-in process with exchange of credentials.');

    const { password, username } = this.payload;
    const { currentUser } = this.state;

    const credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN, { username });

    const response = (await this.dispatch(signInAction({ authOnly: true, credentialHash, password, username }))) as SignInSuccessAuthOnly;
    if (Utils.isNil(response) || response.user.id !== currentUser.id) {
      throw new AppException(Constants.Error.E_AUTH_FAIL);
    }

    const decodedIdToken = Utils.decodeIdToken(response.idToken);
    if (Utils.isNil(decodedIdToken)) {
      throw new AppException(Constants.Error.E_AUTH_FAIL_DECODE_ID_TOKEN);
    }

    const decodedToken = Utils.decodeIdToken(decodedIdToken.authState);
    if (Utils.isNil(decodedToken)) {
      throw new AppException(Constants.Error.E_AUTH_FAIL_DECODE_ID_TOKEN);
    }

    return {
      authState: decodedIdToken.authState,
      credentialId: decodedToken.credentialId,
      keyId: decodedToken.keyId,
      hexSalt: response.salt
    };
  }

  // prettier-ignore
  /** @override */ protected async addInsertOperationForClientPrivateKey(): Promise<void> { return; }
  // prettier-ignore
  /** @override */ protected async addUpdateOperationForClientUserList(): Promise<void> { return; }
  // prettier-ignore
  /** @override */ protected async generateSendWelcomeMessagePayload(): Promise<undefined> { return; }

  /** @override */
  protected async addInsertOperationForTemporaryUserKey(): Promise<void> {
    await super.addInsertOperationForTemporaryUserKey();

    const {
      declineCredentialId,
      declineSharedParameters,
      declineToken,
      dtServer,
      exportedPrivateKey,
      masterKeyId: keyId,
      requestBody
    } = this.state;

    const declineParams: EncryptWithParametersAlgorithmParams = {
      type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN,
      parameter1: declineToken,
      parameter2: DEFAULT_ACCESS_CODE_DECLINE_INVITATION,
      hexSalt: declineSharedParameters.salt
    };

    const declineMasterKey = await CryptographicKeyMaster.create(
      keyId,
      undefined,
      0,
      exportedPrivateKey!,
      declineCredentialId,
      declineParams,
      dtServer
    );

    requestBody.insert(declineMasterKey);
  }

  private async addInsertOperationForGuestPrivateKey(
    authState: string,
    credentialId: number,
    keyId: number,
    hexSalt: string
  ): Promise<void> {
    this.logger.info('Adding an update operation to request body for guest private key.');

    const { currentUser, masterKeyId, requestBody } = this.state;
    const { password, username } = this.payload;

    const { keyList } = await this.dispatchFetchObjects({
      authState,
      keysByType: [
        { encryptedForId: credentialId, id: keyId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER },
        { encryptedForId: keyId, id: currentUser.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE }
      ]
    });

    //
    // decrypt and cache master private key
    //
    const masterKeyJson = this.findKey(keyList, { id: keyId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER });
    if (Utils.isNil(masterKeyJson)) {
      throw new AuthException('No matching user master key could be found.');
    } else {
      const masterKeyPrivate = new CryptographicKeyMaster(masterKeyJson);
      const params: EncryptWithParametersAlgorithmParams = {
        type: process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN,
        parameter1: username.trim().toLowerCase(),
        parameter2: Utils.stringOrDefault(password),
        hexSalt
      };
      const jsonWebKey = await masterKeyPrivate.decryptedValue(params);
      const privateKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);
      const cryptoKey = await privateKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPrivateKey(masterKeyPrivate.id, cryptoKey);
    }

    //
    // decrypt and cache user private key
    //
    const userPrivateKeyJson = this.findKey(keyList, { id: currentUser.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE });
    if (Utils.isNil(userPrivateKeyJson)) {
      CryptographicKey.clearPrivateKey(masterKeyJson.id);
      throw new AuthException('No matching user private key could be found.');
    } else {
      const userKeyPrivate = new CryptographicKeyPrivate(userPrivateKeyJson);

      const encryptedForKey = await userKeyPrivate.encryptFor(masterKeyId);
      requestBody.insert(encryptedForKey);

      CryptographicKey.clearPrivateKey(masterKeyJson.id);
    }
  }

  private async encryptAuditAndClientKeyWithMasterKey(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for encrypting audit and client key with master key.');

    const { auditId, clientId, masterKeyId, requestBody, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [
        { id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC },
        { id: auditId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }
      ]
    };

    const { keyList } = await this.dispatchFetchObjects(query);

    const auditPublicKeyJson = this.findKey(keyList, { id: auditId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC });
    if (Utils.isNil(auditPublicKeyJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Audit public key could not be fetched.');
    } else {
      const auditKeyPublic = new CryptographicKeyPublic(auditPublicKeyJson);
      requestBody.insert(await auditKeyPublic.encryptFor(masterKeyId));
    }

    const clientPublicKeyJson = this.findKey(keyList, { id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC });
    if (Utils.isNil(clientPublicKeyJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Client public key could not be fetched.');
    } else {
      const clientKeyPublic = new CryptographicKeyPublic(clientPublicKeyJson);
      requestBody.insert(await clientKeyPublic.encryptFor(masterKeyId));
    }
  }

  private async addUpdateOperationForProtectedProfile() {
    this.logger.info('Adding an update operation to request body for protected profile.');

    const { caregiver, requestBody, successPayload } = this.state;

    const profileObject = await this.getUserObject(selectUserProfileProtected, { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED });
    const profile = UserObjectCache.getValue(profileObject);
    if (Utils.isNil(profileObject) || Utils.isNil(profile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Protected profile data is either missing or invalid.');
    }

    const updatedValue: UserObjectProfileProtectedValue = {
      ...profile,
      linkedContactList: Utils.arrayOrDefault(profile.linkedContactList, EMPTY_ARRAY).concat(caregiver)
    };

    this.logger.debug('updatedProtectedProfile', updatedValue);
    const updatedObject = await profileObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    successPayload.request.userObjects!.ids.push(updatedObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForServerRights() {
    this.logger.info('Adding an update operation to request body for server rights.');

    const { batchUpdateClaims, currentUser, requestBody, serverRightsObject } = this.state;

    const userClaim = this.findClaim(batchUpdateClaims, { name: 'user', userId: currentUser.id });
    if (!Utils.isString(userClaim)) {
      throw new AppException(Constants.Error.E_CLAIM_MISSING_OR_INVALID);
    }

    const serverRightsValue = await serverRightsObject.decryptedValue();
    const updatedValue = { ...serverRightsValue, userClaim };
    const updatedObject = await serverRightsObject.updateValue(updatedValue);

    requestBody.update(updatedObject);
  }

  private async addUpdateOperationForDeclineEmailToken() {
    this.logger.info('Adding an update operation to request body for decline email token credential.');

    const { declineCredentialId, declineSharedParameters, declineToken, dtServer, masterKeyId, inviteeId, requestBody } = this.state;

    const credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, { token: declineToken });
    this.logger.debug('decline credentialHash =', credentialHash);

    const { passwordHash } = await Utils.generatePasswordHash(DEFAULT_ACCESS_CODE_DECLINE_INVITATION, declineSharedParameters.salt);
    this.logger.debug('decline passwordHash =', passwordHash);

    const serverParameters = { verifier: Utils.srpGenerateVerifier(declineSharedParameters.salt, credentialHash, passwordHash) };
    this.logger.debug('decline serverParameters =', serverParameters);

    const emailTokenCredentials = await UserCredentialsEmailToken.UserRegistration.create(
      declineCredentialId,
      undefined,
      inviteeId,
      masterKeyId,
      credentialHash,
      // @ts-expect-error
      declineSharedParameters,
      serverParameters,
      0, // encryptedFor
      dtServer,
      this.credentialExpiry
    );

    requestBody.insert(emailTokenCredentials);
  }

  /** @override */
  protected async sendInvitationEmail(): Promise<void> {
    const { careRecipient, caregiver } = this.state;

    return super.sendInvitationEmail(this.payload.role, caregiver.emailAddress, {
      [EmailTemplateParams.CaregiverFirstName]: caregiver.firstName,
      [EmailTemplateParams.CaregiverLastName]: caregiver.lastName,
      [EmailTemplateParams.FirstName]: careRecipient.firstName,
      [EmailTemplateParams.LastName]: careRecipient.lastName
    });
  }

  /** @override */
  protected async sendInvitationSMS(): Promise<void> {
    const { careRecipient, caregiver } = this.state;

    return super.sendInvitationSMS(this.payload.role, caregiver.cellNumber, {
      [EmailTemplateParams.CaregiverFirstName]: caregiver.firstName,
      [EmailTemplateParams.CaregiverLastName]: caregiver.lastName,
      [EmailTemplateParams.FirstName]: careRecipient.firstName,
      [EmailTemplateParams.LastName]: careRecipient.lastName
    });
  }
}
