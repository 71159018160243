import { MessagingException, Utils } from '@sigmail/common';
import type { DataObjectMsgBodyValue, DataObjectMsgMetadataValue } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { EMPTY_ARRAY } from '../../../constants';
import { guestListObjectSelector as groupGuestListSelector } from '../../../selectors/group-object';
import { BaseSendMessageAction } from './base';

export class AssignMessageAction extends BaseSendMessageAction {
  /** @override */
  protected async dispatchFetchIdsByUsage(requestData: Api.GetIdsRequestData): Promise<Api.GetIdsResponseData> {
    // we will not be creating a new message body but using the message body
    // of the source message; no need to get a new message body ID
    const ids = requestData.ids.ids!.filter(({ type }) => type !== process.env.DATA_OBJECT_TYPE_MSG_BODY);
    requestData = { ...requestData, ids: { ...requestData.ids, ids } };

    const response = await super.dispatchFetchIdsByUsage(requestData);
    return {
      ...response,
      ids: {
        ...response.ids,
        [process.env.DATA_OBJECT_TYPE_MSG_BODY]: EMPTY_ARRAY
      }
    };
  }

  /** @override */
  protected async generateIdSequence(): Promise<void> {
    await super.generateIdSequence();

    // patch to source message's body object ID
    this.state.msgBodyId = this.payload.sourceMessage!.body;
  }

  /** @override */
  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    const { sender, sourceMessage: sourceMsg } = this.payload;
    const { activeGroupId: groupId } = this.state;

    const sourceMsgMetadata = await this.getDataObjectValue<DataObjectMsgMetadataValue>(sourceMsg!.header);
    if (Utils.isNil(sourceMsgMetadata)) {
      throw new MessagingException(`Failed to fetch source message metadata. <id=${sourceMsg!.header}>`);
    }

    const { sender: guestUser } = sourceMsgMetadata;

    const guestContactList = (
      await this.getUserObjectValue(groupGuestListSelector, {
        type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST,
        userId: groupId
      })
    )?.list;

    if (!guestContactList?.some(({ id, type }) => type === 'user' && id === guestUser.id)) {
      throw new MessagingException(`User entry could not be found in guest contact list. <guestUserId=${guestUser.id}>`);
    }

    const metadata = await super.createMessageMetadataValue();
    return { ...metadata, assignedBy: sender.id, sender: guestUser };
  }

  /** @override */
  protected async addInsertOperationForMessageBody(): Promise<void> {
    const { sourceMessage: sourceMsg } = this.payload;
    const { recipientKeyIdList, requestBody } = this.state;

    const sourceMsgBodyObject = await this.getDataObject<DataObjectMsgBodyValue>(sourceMsg!.body);
    if (Utils.isNil(sourceMsgBodyObject)) {
      throw new MessagingException(`Failed to fetch source message body. <id=${sourceMsg!.body}>`);
    }

    const keyList = await sourceMsgBodyObject.generateKeysEncryptedFor(...(recipientKeyIdList as [number, ...Array<number>]));
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }
}
