import { ApiActionPayload } from '@sigmail/app-state';
import { AppException, Constants, SigmailClientId, SigmailGroupId, SigmailUserId, Utils, Writeable } from '@sigmail/common';
import { getLogger, getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectEventLog,
  DataObjectEventLogValue,
  EventLogRecord,
  IDataObject,
  UserObjectEventLog,
  UserObjectEventLogValue,
  ValueFormatVersion
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '..';
import { BatchUpdateRequestBuilder } from '../../utils/batch-update-request-builder';
import { EMPTY_ARRAY } from '../constants';
import { ActionInitParams, BaseAction, BaseActionState, FetchObjectsRequestData } from './base-action';

type EventLogType = Exclude<keyof UserObjectEventLogValue, keyof ValueFormatVersion>;

export interface Payload {
  readonly accessToken?: string;
  readonly authState?: string;
  readonly clearExisting?: boolean;
  readonly dtServer?: Date;
  readonly fetchIds?: (count: number) => Promise<Api.GetIdsResponseData>;
  readonly logger?: ReturnType<typeof getLogger>;
  readonly record: EventLogRecord | ReadonlyArray<EventLogRecord>;
  readonly requestBody: BatchUpdateRequestBuilder;
  readonly successPayload?: ApiActionPayload.BatchQueryDataSuccess;
  readonly userId: SigmailClientId | SigmailGroupId | SigmailUserId;
  readonly userIdType: 'client' | 'group' | 'user';
}

interface PayloadInternal extends Omit<Payload, 'logger' | 'record'> {
  readonly record: Map<EventLogType, Exclude<Payload['record'], EventLogRecord>>;
}

interface State extends BaseActionState {
  dtServer: Date;
  userEventLog: UserObjectEventLogValue;
}

class LogEventAction extends BaseAction<PayloadInternal, State, Array<string>> {
  public constructor(params: ActionInitParams<PayloadInternal>) {
    super(params);

    const { accessToken, authState } = this.payload;
    const { accessToken: token, roleAuthClaim } = this.state;

    this.state.accessToken = Utils.stringOrDefault(accessToken, token);
    this.state.roleAuthClaim = Utils.stringOrDefault(authState, roleAuthClaim);
  }

  /** @override */
  protected async onExecute() {
    await this.createMissingLogObjectsIfAny();

    const { clearExisting, record, requestBody, successPayload } = this.payload;
    const { accessToken, roleAuthClaim: authState, userEventLog } = this.state;

    const claimList: Array<string> = [];
    for (const [eventLogType, recordList] of record) {
      if (recordList.length === 0) continue;

      const dataObjectId = userEventLog[eventLogType]!;
      const query: FetchObjectsRequestData = { authState, dataObjects: { ids: [dataObjectId] }, expectedCount: { claims: 1 } };
      const { claims, dataObjectList } = await this.fetchObjects(accessToken, query);

      const eventLogJson = this.findDataObject(dataObjectList, { id: dataObjectId });
      const eventLogObject = Utils.isNotNil(eventLogJson) ? new DataObjectEventLog(eventLogJson) : undefined;
      if (Utils.isNil(eventLogObject)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Event log data object is either missing or invalid.');
      }

      const eventLog = await eventLogObject.decryptedValue();
      claimList.push(...claims);

      this.logger.info(`Adding an update operation to request body for event log data object. <type=${eventLogType}>`);
      const updatedList = (clearExisting === true ? (EMPTY_ARRAY as typeof eventLog.list) : eventLog.list).concat(recordList);
      const updatedObject = await eventLogObject.updateValue({ ...eventLog, list: updatedList });
      requestBody.update(updatedObject);

      if (Utils.isNotNil(successPayload)) {
        successPayload.request.dataObjects!.ids.push(updatedObject.id);
        successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
      }
    }

    return claimList;
  }

  private async createMissingLogObjectsIfAny(): Promise<void> {
    const { fetchIds, record, successPayload, userId } = this.payload;
    const { accessToken, roleAuthClaim: authState } = this.state;

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      const { userObjectList, serverDateTime } = await this.fetchObjects(accessToken, {
        authState,
        userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_EVENT_LOG, userId }]
      });

      const userEventLogJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_EVENT_LOG, userId });
      const userEventLogObject = Utils.isNotNil(userEventLogJson) ? new UserObjectEventLog(userEventLogJson) : undefined;
      if (Utils.isNil(userEventLogObject)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, `Event log user object could not be fetched.`);
      }

      if (Utils.isNotNil(successPayload)) {
        successPayload.request.userObjects!.ids.push(userEventLogObject.id);
        successPayload.response.userObjects!.push(userEventLogJson!);
      }

      this.state.dtServer = Utils.dateOrDefault(this.payload.dtServer, this.deserializeServerDateTime(serverDateTime));
      this.state.userEventLog = await userEventLogObject.decryptedValue();

      //
      // prepare a count of 312 data objects we would possibly need to create
      //
      const missingEventLogTypeList: Array<EventLogType> = [];
      for (const eventLogType of record.keys()) {
        if (!DataObjectEventLog.isValidId(this.state.userEventLog[eventLogType])) {
          missingEventLogTypeList.push(eventLogType);
        }
      }

      // required 312 data objects already exist for provided event classes?
      if (missingEventLogTypeList.length === 0) return;

      if (typeof fetchIds !== 'function') {
        throw new Error('Invalid payload; expected argument <fetchIds> to be a function.');
      }

      const { auditId, clientId, ownerId } = Utils.decodeIdToken(authState) as Record<string, number>;
      const { authState: batchUpdateAuthState, idsClaim, ids: idRecord } = await fetchIds(missingEventLogTypeList.length);
      const idSequence = Utils.makeSequence(idRecord[process.env.DATA_OBJECT_TYPE_EVENT_LOG]);
      const requestBody = new BatchUpdateRequestBuilder();

      //
      // build a list of data objects (312) to insert and prepare the updated
      // value of user object (425)
      //
      const updatedValue: Writeable<UserObjectEventLogValue> = { ...this.state.userEventLog };
      const dataObjectList: Array<IDataObject<DataObjectEventLogValue>> = [];
      const INITIAL_VALUE: DataObjectEventLogValue = { $$formatver: 1, list: EMPTY_ARRAY };
      for (const eventLogType of missingEventLogTypeList) {
        const { value: id } = idSequence.next();
        const obj = await DataObjectEventLog.create(id, undefined, 1, INITIAL_VALUE, ownerId, userId, this.state.dtServer);
        dataObjectList.push(obj);
        updatedValue[eventLogType] = id;
      }

      //
      // add insert operations to request body for data objects and keys
      //
      const keyList = await Promise.all(
        dataObjectList.map((obj) =>
          obj
            .generateKeysEncryptedFor(auditId, clientId)
            .then((list) => list.concat(userId === clientId ? null : obj[Constants.$$CryptographicKey]))
        )
      );

      requestBody.insert(dataObjectList).insert(Utils.flatten(keyList).filter(Utils.isNotNil)); // 312(s) and 151s

      //
      // add update operation to request body for event log user object
      //
      const updatedObject = await userEventLogObject.updateValue(updatedValue);
      requestBody.update(updatedObject); // 425

      if (Utils.isNotNil(successPayload)) {
        successPayload.request.userObjects!.ids.push(updatedObject.id);
        successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
      }

      try {
        await this.batchUpdateData(accessToken, { authState: batchUpdateAuthState, claims: [idsClaim], ...requestBody.build() });
        this.state.userEventLog = updatedValue;

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }
  }
}

const Logger = getLoggerWithPrefix('Action', 'logEventAction:');

export const logEventAction = ({ logger, record: recordFromPayload, ...payload }: Payload): AppThunk<Promise<Array<string>>> => {
  return (dispatch, getState, { apiService }) => {
    const recordList = Utils.isArray<EventLogRecord>(recordFromPayload)
      ? recordFromPayload
      : Utils.isNonArrayObjectLike<EventLogRecord>(recordFromPayload)
      ? [recordFromPayload]
      : (EMPTY_ARRAY as ReadonlyArray<EventLogRecord>);

    const record = recordList.reduce((map, record) => {
      const { code } = record;

      let eventLogType: EventLogType;
      if (Constants.EventLogCode.isNil(code)) {
        eventLogType = 'session';
      } else {
        switch (code) {
          case Constants.EventLogCode.AccountSetup:
          case Constants.EventLogCode.ChangePassword:
          case Constants.EventLogCode.ChangeUsername:
          case Constants.EventLogCode.RevokeInvitation:
          case Constants.EventLogCode.SendInvitation:
          case Constants.EventLogCode.UpdateMFA:
          case Constants.EventLogCode.UpdatePreference:
          case Constants.EventLogCode.UpdateProfile:
            eventLogType = 'account';
            break;
          case Constants.EventLogCode.EConsultReceived:
          case Constants.EventLogCode.EConsultResponded:
          case Constants.EventLogCode.EConsultSent:
            eventLogType = 'eConsult';
            break;
          case Constants.EventLogCode.MessageAssigned:
          case Constants.EventLogCode.MessageCategoryChanged:
          case Constants.EventLogCode.MessageForwarded:
          case Constants.EventLogCode.MessageMoved:
          case Constants.EventLogCode.MessageRecalled:
          case Constants.EventLogCode.MessageReceived:
          case Constants.EventLogCode.MessageResponded:
          case Constants.EventLogCode.MessageSent:
          case Constants.EventLogCode.MessageSentToEMR:
          case Constants.EventLogCode.MessageSentToHRM:
            eventLogType = 'message';
            break;
          case Constants.EventLogCode.ReferralAccepted:
          case Constants.EventLogCode.ReferralDeclined:
          case Constants.EventLogCode.ReferralReceived:
          case Constants.EventLogCode.ReferralSent:
            eventLogType = 'referral';
            break;
          case Constants.EventLogCode.SessionAuth:
            eventLogType = 'session';
            break;
          default:
            throw new Error(`Unhandled case - ${code}.`);
        }
      }

      const list = Utils.arrayOrDefault<EventLogRecord>(map.get(eventLogType));
      list.push(record);
      map.set(eventLogType, list);

      return map;
    }, new Map() as Map<EventLogType, Array<EventLogRecord>>);

    const action = new LogEventAction({
      apiService,
      dispatch,
      getState,
      logger: Logger,
      payload: { ...payload, record }
    });

    return action.execute();
  };
};
