import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AppThunk } from '../../..';
import { accessTokenSelector } from '../../../selectors/auth';

export const accuroEMRCheckAuthStatus = (payload: ApiActionPayload.AccuroEMRCheckAuthStatus): AppThunk<Promise<boolean>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'accuroEMRCheckAuthStatus:');

    Logger.info('== BEGIN ==');
    try {
      const apiAccessToken = Utils.stringOrDefault(payload.accessToken, accessTokenSelector(getState()));
      return await apiService.accuroEMRCheckAuthStatus(apiAccessToken, payload.clientId);
    } finally {
      Logger.info('== END ==');
    }
  };
};
