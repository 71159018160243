import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Constants, ReadonlyPartialRecord, Utils } from '@sigmail/common';
import { AccountI18n, FormInputErrorMessageI18n } from '@sigmail/i18n';
import { enCA, frCA } from 'date-fns/locale';
import { FieldState } from 'final-form';
import { TOptions } from 'i18next';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { HEALTH_INSURANCE_PLAN_NUMBER_PLACEHOLDER } from '../../../constants';
import * as FormInputConstraint from '../../../constants/form-input-constraint';
import * as FormInputMask from '../../../constants/form-input-mask';
import * as FormInputPattern from '../../../constants/form-input-pattern';
import { FrenchCanada } from '../../../constants/language-codes';
import { withTranslation } from '../../../i18n';
import accountI18n from '../../../i18n/account';
import { I18N_NS_ACCOUNT, I18N_NS_GLOBAL } from '../../../i18n/config/namespace-identifiers';
import globalI18n from '../../../i18n/global';
import { FieldConfig } from '../../shared/form';
import { ValidationErrorResult, nullValidator } from '../../shared/form/field-validator';
import {
  DatePickerFieldProps,
  FieldProps,
  FormComponent,
  FormComponentProps,
  FormComponentState
} from '../../shared/form/form.component';
import { SelectFieldProps as BaseSelectFieldProps } from '../../shared/form/select-field.component';
import style from './send-guest-account-invitation.module.css';

const { sendGuestAccountInvitation: i18n } = accountI18n.form;

export type FieldsetName = keyof typeof i18n;

export type FieldNameBirthDateGender = keyof typeof i18n.fieldsetBirthDateGender.formField;
export type FieldNameContact = keyof typeof i18n.fieldsetContact.formField;
export type FieldNameFullName = keyof typeof i18n.fieldsetName.formField;
export type FieldNameHealthInsurance = keyof typeof i18n.fieldsetHealthInsurance.formField;
export type FieldNameNotifyBy = keyof typeof i18n.fieldsetNotifyBy.formField;

export type FieldName =
  | FieldNameBirthDateGender
  | FieldNameContact
  | FieldNameFullName
  | FieldNameHealthInsurance
  | FieldNameNotifyBy;

export type FormValues = Record<Exclude<FieldName, 'birthDate' | 'notifyBy'>, string> &
  Record<Extract<FieldName, 'birthDate'>, Date> &
  Record<Extract<FieldName, 'notifyBy'>, AccountI18n.SendAccountInvitationNotifyBy>;

const FIELD_SET_LIST: ReadonlyArray<Readonly<[FieldsetName, ReadonlyArray<FieldName>]>> = [
  ['fieldsetName', ['firstName', 'lastName']],
  ['fieldsetBirthDateGender', ['birthDate', 'gender']],
  ['fieldsetContact', ['homeNumber', 'cellNumber', 'emailAddress']],
  ['fieldsetNotifyBy', ['notifyBy']],
  ['fieldsetHealthInsurance', ['jurisdiction', 'planNumber']]
];

interface SelectFieldProps extends Omit<BaseSelectFieldProps<string, false>, 'inputState'> {
  config?: FieldConfig<string> | undefined;
  render?: ((props: BaseSelectFieldProps<string, false>) => React.ReactNode) | undefined;
}

//
//#region field validators
const cellNumberRequiredValidator = (value: string, { notifyBy }: FormValues) =>
  value.length > 0 || notifyBy === 'both' || notifyBy === 'sms';
const cellNumberPatternValidator = (value: string, { notifyBy }: FormValues) =>
  value.length > 0 || notifyBy === 'both' || notifyBy === 'sms' ? FormInputPattern.PhoneNumber : nullValidator();
const emailAddressRequiredValidator = (value: string, { notifyBy }: FormValues) =>
  value.length > 0 || notifyBy === 'both' || notifyBy === 'email';
const emailAddressLengthValidator = (value: string, { notifyBy }: FormValues) =>
  value.length > 0 || notifyBy === 'both' || notifyBy === 'email' ? FormInputConstraint.EmailAddress : nullValidator();
const emailAddressPatternValidator = (value: string, { notifyBy }: FormValues) =>
  value.length > 0 || notifyBy === 'both' || notifyBy === 'email' ? FormInputPattern.EmailAddress : nullValidator();
//#endregion
//

//
//#region FIELD_PROPS
const FIELD_PROPS: Readonly<
  Record<Exclude<FieldName, 'birthDate' | 'gender' | 'jurisdiction' | 'notifyBy'>, FieldProps> &
    Record<Extract<FieldName, 'birthDate'>, DatePickerFieldProps> &
    Record<Extract<FieldName, 'gender' | 'jurisdiction' | 'notifyBy'>, SelectFieldProps>
> = {
  firstName: {
    autoComplete: 'off',
    inputProps: { 'aria-required': true },
    label: i18n.fieldsetName.formField.firstName.label,
    placeholder: i18n.fieldsetName.formField.firstName.placeholder,
    config: {
      required: true,
      length: FormInputConstraint.FirstName,
      pattern: FormInputPattern.FirstName
    }
  },
  lastName: {
    autoComplete: 'off',
    inputProps: { 'aria-required': true },
    label: i18n.fieldsetName.formField.lastName.label,
    placeholder: i18n.fieldsetName.formField.lastName.placeholder,
    config: {
      required: true,
      length: FormInputConstraint.LastName,
      pattern: FormInputPattern.LastName
    }
  },
  birthDate: {
    autoComplete: 'off',
    inputProps: { 'aria-required': true },
    label: i18n.fieldsetBirthDateGender.formField.birthDate.label,
    placeholder: i18n.fieldsetBirthDateGender.formField.birthDate.placeholder,
    config: {
      required: { typeOf: 'date' }
    }
  },
  gender: {
    autoComplete: 'off',
    inputProps: { 'aria-required': true },
    label: i18n.fieldsetBirthDateGender.formField.gender.label,
    placeholder: i18n.fieldsetBirthDateGender.formField.gender.placeholder,
    config: { required: true }
  },
  homeNumber: {
    autoComplete: 'off',
    inputProps: { inputMode: 'tel' },
    label: i18n.fieldsetContact.formField.homeNumber.label,
    maskedInputProps: { mask: 'phoneNumber' },
    placeholder: i18n.fieldsetContact.formField.homeNumber.placeholder,
    config: { pattern: FormInputPattern.PhoneNumber }
  },
  cellNumber: {
    autoComplete: 'off',
    inputProps: { inputMode: 'tel' },
    label: i18n.fieldsetContact.formField.cellNumber.label,
    maskedInputProps: { mask: 'phoneNumber' },
    placeholder: i18n.fieldsetContact.formField.cellNumber.placeholder,
    config: {
      required: cellNumberRequiredValidator,
      pattern: cellNumberPatternValidator
    }
  },
  emailAddress: {
    autoComplete: 'off',
    inputProps: { inputMode: 'email' },
    label: i18n.fieldsetContact.formField.emailAddress.label,
    placeholder: i18n.fieldsetContact.formField.emailAddress.placeholder,
    config: {
      required: emailAddressRequiredValidator,
      length: emailAddressLengthValidator,
      pattern: emailAddressPatternValidator
    }
  },
  jurisdiction: {
    autoComplete: 'off',
    inputProps: { 'aria-required': true },
    label: i18n.fieldsetHealthInsurance.formField.jurisdiction.label,
    placeholder: i18n.fieldsetHealthInsurance.formField.jurisdiction.placeholder,
    config: {
      required: true,
      validateFields: ['planNumber']
    }
  },
  notifyBy: {
    label: i18n.fieldsetNotifyBy.formField.notifyBy.label,
    placeholder: i18n.fieldsetNotifyBy.formField.notifyBy.placeholder,
    config: {
      validateFields: ['cellNumber', 'emailAddress']
    }
  },
  planNumber: {
    autoComplete: 'off',
    label: i18n.fieldsetHealthInsurance.formField.planNumber.label,
    placeholder: i18n.fieldsetHealthInsurance.formField.planNumber.placeholder,
    config: {
      required: (_, { jurisdiction }) => Utils.isString(jurisdiction) && jurisdiction.startsWith('CAN$'),
      pattern: (_, { jurisdiction }) => {
        let pattern: string | undefined = undefined;
        if (Utils.isString(jurisdiction) && jurisdiction.startsWith('CAN$')) {
          const provinceCode = jurisdiction.slice(4);
          pattern = FormInputPattern.HealthPlanNumber.CAN[provinceCode];
        }
        return Utils.isString(pattern) ? pattern : FormInputPattern.NothingOrNonWhitespaceStrict;
      }
    }
  }
};
//#endregion
//

export type SendGuestAccountInvitationFormClassKey = 'inputRoot';

export interface Props extends FormComponentProps<FieldName, FormValues> {
  readonly classes?: ReadonlyPartialRecord<SendGuestAccountInvitationFormClassKey, string> | undefined;
}

interface ComponentProps extends Props, WithTranslation {}

interface State extends FormComponentState<FieldName, FormValues> {}

class SendGuestAccountInvitationFormComponent extends FormComponent<
  FieldsetName,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes'
    );

    const initialValues: FormValues = {
      ...Utils.mapValues(Utils.omit(FIELD_PROPS, ['birthDate', 'gender', 'jurisdiction', 'notifyBy']), () => ''),
      birthDate: null!,
      gender: Constants.Gender.Unknown,
      jurisdiction: `CAN$${Constants.CanadianProvinceCode.Ontario}`,
      notifyBy: 'email'
    };

    this.createForm({ initialValues });
  }

  /** @override */
  public componentDidUpdate(prevProps: Readonly<ComponentProps>, prevState: Readonly<State>) {
    super.componentDidUpdate(prevProps, prevState);

    const { jurisdiction, planNumber } = this.state;
    const { jurisdiction: prevJurisdiction } = prevState;

    if (
      Utils.isNotNil(jurisdiction) &&
      Utils.isNotNil(prevJurisdiction) &&
      jurisdiction.value !== prevJurisdiction.value
    ) {
      planNumber?.change('');
    }
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-invite-guest-${this.formIdSuffix}`;
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetName: FieldsetName, ...args: any[]) {
    return fieldsetName === 'fieldsetHealthInsurance'
      ? this.props.t(i18n.fieldsetHealthInsurance.label!)
      : super.getFieldsetLabel(fieldsetName, ...args);
  }

  /** @override */
  protected getFieldType(fieldName: FieldName, ...args: any[]) {
    switch (fieldName) {
      case 'birthDate':
        return 'date';
      case 'gender':
      case 'jurisdiction':
      case 'notifyBy':
        return 'select';
      default:
        return super.getFieldType(fieldName, ...args);
    }
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { t } = this.props;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    switch (fieldName) {
      case 'gender': {
        const selectFieldProps: SelectFieldProps = {
          getOptionLabel: (option) => {
            const gender = globalI18n.genderList.find(({ code }) => code === option);
            return Utils.isNotNil(gender) && t(gender.label);
          },
          options: globalI18n.genderList.map(({ code }) => code)
        };

        fieldProps = { ...fieldProps, ...selectFieldProps };
        break;
      }
      case 'jurisdiction': {
        const selectFieldProps: Partial<SelectFieldProps> = {
          getOptionLabel: (option) => {
            const jurisdiction = globalI18n.healthPlanJurisdictionList.find(({ code }) => code === option);
            return Utils.isNotNil(jurisdiction) && t(jurisdiction.label);
          },
          options: globalI18n.healthPlanJurisdictionList.map(({ code }) => code)
        };

        fieldProps = { ...fieldProps, ...selectFieldProps };
        break;
      }
      case 'notifyBy': {
        const options = i18n.fieldsetNotifyBy.formField.notifyBy.options;
        const selectFieldProps: SelectFieldProps = {
          getOptionLabel: (option) => t(options.find(({ codedValue }) => codedValue === option)!.label),
          options: options.map(({ codedValue }) => codedValue)
        };

        fieldProps = { ...fieldProps, ...selectFieldProps };
        break;
      }
      case 'planNumber': {
        const { value: jurisdiction } = this.state.jurisdiction;
        const maskedInputProps: FieldProps['maskedInputProps'] = { mask: false };

        if (Utils.isString(jurisdiction) && jurisdiction.startsWith('CAN$')) {
          const provinceCode = jurisdiction.slice(4);
          const planNumberPlaceholder = HEALTH_INSURANCE_PLAN_NUMBER_PLACEHOLDER.CAN[provinceCode];
          if (Utils.isString(planNumberPlaceholder)) placeholder = planNumberPlaceholder;

          const mask = FormInputMask.HealthPlanNumber.CAN[provinceCode];
          if (Utils.isNotNil(mask)) {
            maskedInputProps.guide = false;
            maskedInputProps.mask = mask;
            if (provinceCode === Constants.CanadianProvinceCode.Ontario) {
              maskedInputProps.pipe = (value) => (value.endsWith('-') ? value.slice(0, value.length - 1) : value);
            }
          }
        }

        fieldProps = { ...fieldProps, maskedInputProps };
        break;
      }
      default: {
        break;
      }
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined = undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    if (!Utils.isString(errorMessageI18n)) return error;

    let tOptions: TOptions | undefined = undefined;
    if (error.key === 'length') {
      if (fieldName === 'firstName' || fieldName === 'lastName' || fieldName === 'emailAddress') {
        const { min: MIN_LENGTH, max: MAX_LENGTH } = error.length;
        tOptions = { MIN_LENGTH, MAX_LENGTH };
      }
    }

    return t(errorMessageI18n, tOptions);
  }

  /** @override */
  protected renderForm(...args: any[]) {
    const { language: locale } = this.props.i18n;

    return (
      <MuiPickersUtilsProvider locale={locale === FrenchCanada ? frCA : enCA} utils={DateFnsUtils}>
        {super.renderForm(...args)}
      </MuiPickersUtilsProvider>
    );
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]) {
    const { classes } = this.props;

    const fieldNode = super.renderField(fieldName, ...args);
    if (Utils.isNil(fieldNode) || fieldNode === false) return null;

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<string>) {
    let { error, value } = fieldState;

    if (Utils.isNotNil(error)) error = this.getFieldErrorMessage(fieldName, error);
    if (fieldName === 'planNumber' && Utils.isString(value)) value = value.toUpperCase();

    return super.setFieldState(fieldName, { ...fieldState, error, value });
  }
}

const ns = [I18N_NS_GLOBAL, I18N_NS_ACCOUNT];

export const SendGuestAccountInvitationForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['input-root']
    } as ComponentProps['classes']);

    return <SendGuestAccountInvitationFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

SendGuestAccountInvitationForm.displayName = 'SendGuestAccountInvitationForm';

SendGuestAccountInvitationForm.defaultProps = {
  className: style.root
};
