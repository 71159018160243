import { AppUser, AppUserGroup, Constants, Nullable, SigmailClientId, SigmailGroupId, Utils } from '@sigmail/common';
import { GridOptions } from 'ag-grid-community';
import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../../../app-state/actions/constants';
import { EMPTY_PLAIN_OBJECT } from '../../../app-state/constants';
import { SearchPatientRecord, useDispatchFetchObjects, UseDispatchFetchObjectsParams } from '../../../app-state/hooks';
import { activeGroupIdSelector } from '../../../app-state/selectors';
import { collectionListObjectSelector as collectionListSelector } from '../../../app-state/selectors/client-object';
import { preferencesObjectSelector as groupPrefsSelector } from '../../../app-state/selectors/group-object';
import { clientIdSelector } from '../../../app-state/selectors/user-object';
import { UserObjectCache } from '../../../app-state/user-objects-slice/cache';
import { DialogAccept, DialogReject } from '../../../constants/action-ids';
import { useTranslation } from '../../../i18n';
import accountI18n from '../../../i18n/account';
import { I18N_NS_ACCOUNT } from '../../../i18n/config/namespace-identifiers';
import * as AccuroEMRUtils from '../../../utils/accuro-emr';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { DialogActionsAcceptReject } from '../../shared/dialog/actions/accept-reject.component';
import { DialogBox, DialogBoxProps } from '../../shared/dialog/dialog-box.component';
import {
  FieldSearchPatientList,
  Props as FieldSearchPatientListProps
} from '../../shared/form/search-patient-list-field.component';
import { SelectPatientRecordGrid } from '../../shared/select-patient-record-grid.component';
import { SendGuestAccountInvitationForm as Form } from '../form/send-guest-account-invitation.component';
import { useSendGuestAccountInvitationFormState } from '../hooks';
import { OnClickActionHandler } from '../types';
import style from './send-guest-account-invitation.module.css';

const { sendGuestAccountInvitation: i18n } = accountI18n.dialog;

type DialogProps = Omit<
  React.PropsWithoutRef<DialogBoxProps>,
  'ActionsProps' | 'action' | 'body' | 'ContentProps' | 'TitleProps' | 'title' | 'titleId'
>;

export interface Props extends DialogProps {
  ActionsProps?: never;
  actions?: never;
  body?: never;
  ContentProps?: never;
  onClickAction?: Nullable<OnClickActionHandler>;
  TitleProps?: never;
  title?: never;
  titleId?: never;
}

interface State {
  readonly searchInProgress?: boolean;
  readonly searchResultList?: ReadonlyArray<SearchPatientRecord>;
  readonly selectedSearchResult?: SearchPatientRecord;
}

const INITIAL_STATE: State = EMPTY_PLAIN_OBJECT;

const INITIAL_SEARCH_FIELD_PROPS: Pick<
  FieldSearchPatientListProps,
  'disableAccuroEMR' | 'disableCollection' | 'disableOscarEMR'
> = {
  disableAccuroEMR: true,
  disableCollection: true,
  disableOscarEMR: true
};

export const DialogSendGuestAccountInvitation = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      ActionsProps: _omitted$1,
      actions: _omitted$2,
      body: _omitted$3,
      ContentProps: _omitted$4,
      classes: classesFromProps,
      onClickAction,
      onClose,
      open: isDialogOpen,
      TitleProps: _omitted$5,
      title: _omitted$6,
      titleId: _omitted$7,
      ...rootProps
    },
    ref
  ) => {
    // prettier-ignore
    const { i18n: { language: locale }, t } = useTranslation([I18N_NS_ACCOUNT]);
    const fetchObjects = useDispatchFetchObjects();

    const clientId = useSelector(clientIdSelector);
    const prevClientId = React.useRef<SigmailClientId>();
    const collectionObject = useSelector(collectionListSelector)(clientId);
    const collection = UserObjectCache.getValue(collectionObject);

    const groupId = useSelector(activeGroupIdSelector);
    const prevGroupId = React.useRef<SigmailGroupId>();
    const groupPrefsObject = useSelector(groupPrefsSelector)(groupId);
    const groupPrefs = UserObjectCache.getValue(groupPrefsObject);

    const [searchFieldProps, setSearchFieldProps] = React.useState(INITIAL_SEARCH_FIELD_PROPS);

    const [{ searchResultList, selectedSearchResult, ...state }, setState] = React.useState<State>(INITIAL_STATE);
    const resetState = React.useCallback(() => setState(INITIAL_STATE), []);

    const isSearchInProgress = state.searchInProgress === true;
    const hasSearchResults = Utils.isArray<NonNullable<typeof searchResultList>[0]>(searchResultList);
    const { formApi, formId, formSubmitting, props: formProps, setFormRef } = useSendGuestAccountInvitationFormState(
      isDialogOpen && { actionClickHandler: onClickAction, locale }
    );

    React.useEffect(() => {
      setSearchFieldProps(INITIAL_SEARCH_FIELD_PROPS);

      if (!isDialogOpen) {
        prevClientId.current = undefined;
        prevGroupId.current = undefined;
        resetState();
        return;
      }

      if (prevClientId.current === clientId && prevGroupId.current === groupId) return;
      prevClientId.current = clientId;
      prevGroupId.current = groupId;

      if (Utils.isNotNil(collection) && Utils.isNotNil(groupPrefs)) return;

      const userObjectsByType: NonNullable<UseDispatchFetchObjectsParams['userObjectsByType']> = [];
      if (Utils.isNil(collection) && AppUser.isValidId(clientId))
        userObjectsByType.push({ type: process.env.CLIENT_OBJECT_TYPE_COLLECTION_LIST, userId: clientId });
      if (Utils.isNil(groupPrefs) && AppUserGroup.isValidId(groupId))
        userObjectsByType.push({ type: process.env.GROUP_OBJECT_TYPE_PREFERENCES, userId: groupId });

      if (userObjectsByType.length > 0) {
        const promise = Utils.makeCancelablePromise(fetchObjects({ userObjectsByType }));
        promise.promise.catch(Utils.noop);
        return () => promise.cancel();
      }
    }, [clientId, collection, fetchObjects, groupId, groupPrefs, isDialogOpen, resetState]);

    React.useEffect(() => {
      if (!isDialogOpen) return;

      let { disableAccuroEMR, disableCollection, disableOscarEMR } = INITIAL_SEARCH_FIELD_PROPS;
      disableAccuroEMR = disableCollection = disableOscarEMR = true;

      if (
        Utils.isNotNil(collection) &&
        collection.list.some((item) => item.collectionType === 'fullPatientList' && item.collections.length > 0)
      ) {
        disableCollection = false;
      }

      if (Utils.isNotNil(groupPrefs) && Utils.isNotNil(groupPrefs.integrations)) {
        // const { accuroEMR: accuro, oscar } = groupPrefs.integrations;
        const { accuroEMR: accuro } = groupPrefs.integrations;
        disableAccuroEMR =
          !Utils.isNonArrayObjectLike<typeof accuro>(accuro) || !Utils.isNonArrayObjectLike(accuro.oauthParams);
        // disableOscarEMR =
        //   !Utils.isNonArrayObjectLike<typeof oscar>(oscar) || !Utils.isNonArrayObjectLike(oscar.oauthParams);
      }

      setSearchFieldProps({ disableAccuroEMR, disableCollection, disableOscarEMR });
    }, [collection, groupPrefs, isDialogOpen]);

    const onRecordSelect = React.useMemo<GridOptions['onRowSelected']>(
      () =>
        !hasSearchResults || isSearchInProgress
          ? undefined
          : ({ data }) => setState((state) => ({ ...state, selectedSearchResult: data })),
      [hasSearchResults, isSearchInProgress]
    );

    const onDialogClose = React.useMemo<DialogBoxProps['onClose'] | undefined>(
      () =>
        isDialogOpen
          ? (event, reason) => {
              if (reason === 'backdropClick') return;
              if (formSubmitting && reason === 'escapeKeyDown') return;
              if (typeof onClose !== 'function') return;

              onClose(event, reason);
            }
          : undefined,
      [formSubmitting, isDialogOpen, onClose]
    );

    const batchUpdateForm = React.useCallback(
      ({ record: data }: SearchPatientRecord) => {
        if (formSubmitting || Utils.isNil(formApi)) return;

        const record = (Utils.isNonArrayObjectLike(data?.demographics)
          ? data.demographics
          : EMPTY_PLAIN_OBJECT) as NonNullable<SearchPatientRecord['record']['demographics']>;

        let jurisdiction = Utils.trimOrDefault(AccuroEMRUtils.selectHealthPlanJurisdiction(record));
        if (jurisdiction.length === 0) jurisdiction = DEFAULT_HEALTH_PLAN_JURISDICTION;
        if (!Object.values<string>(Constants.HealthPlanJurisdiction).includes(jurisdiction)) {
          jurisdiction = '';
        }

        formApi.batch(() => {
          formApi.change('firstName', Utils.trimOrDefault(AccuroEMRUtils.selectFirstName(record)));
          formApi.change('lastName', Utils.trimOrDefault(AccuroEMRUtils.selectLastName(record)));
          formApi.change('birthDate', AccuroEMRUtils.selectBirthDate(record)!);
          formApi.change('gender', AccuroEMRUtils.selectGender(record));
          formApi.change('homeNumber', AccuroEMRUtils.selectFormattedPhoneNumber(record, 'HomePhone'));
          formApi.change('cellNumber', AccuroEMRUtils.selectFormattedPhoneNumber(record, 'CellPhone'));
          formApi.change('emailAddress', Utils.trimOrDefault(record.email?.address));
          formApi.change('jurisdiction', jurisdiction);
        });

        window.setTimeout(() => {
          formApi.change('planNumber', AccuroEMRUtils.selectFormattedHealthPlanNumber(record, jurisdiction));
        });
      },
      [formApi, formSubmitting]
    );

    const onDialogActionClick = React.useMemo<React.MouseEventHandler<HTMLButtonElement> | undefined>(
      () =>
        isDialogOpen && !formSubmitting
          ? (event) => {
              event.preventDefault();
              event.stopPropagation();

              const actionId = event.currentTarget.getAttribute('data-action-id');
              if (!Utils.isString(actionId)) return;

              if (actionId === DialogReject) {
                if (typeof onClickAction === 'function') {
                  onClickAction(actionId);
                }
                return;
              }

              if (actionId === DialogAccept && Utils.isNotNil(selectedSearchResult)) {
                batchUpdateForm(selectedSearchResult);
                resetState();
              }
            }
          : undefined,
      [batchUpdateForm, resetState, formSubmitting, isDialogOpen, onClickAction, selectedSearchResult]
    );

    const onSearchEnd = React.useCallback<NonNullable<FieldSearchPatientListProps['onSearchEnd']>>(
      ({ list }) => {
        if (list.length > 1) return setState({ searchResultList: list });
        if (list.length === 1) batchUpdateForm(list[0]);

        resetState();
      },
      [batchUpdateForm, resetState]
    );

    const onSearchStart = React.useCallback(() => {
      setState({ searchInProgress: true });
      batchUpdateForm({ record: EMPTY_PLAIN_OBJECT } as SearchPatientRecord);
    }, [batchUpdateForm]);

    return (
      <DialogBox
        {...rootProps}
        actions={
          isDialogOpen && (
            <DialogActionsAcceptReject
              AcceptActionProps={{
                children: t(resolveActionLabel(i18n.action[hasSearchResults ? 'select' : 'accept'], DialogAccept)),
                disabled: formSubmitting || isSearchInProgress,
                form: Utils.stringOrDefault(!hasSearchResults && formId, undefined!),
                onClick: hasSearchResults ? onDialogActionClick : undefined,
                progress: formSubmitting
              }}
              RejectActionProps={{
                children: t(resolveActionLabel(i18n.action.reject, DialogReject)),
                disabled: formSubmitting || isSearchInProgress,
                onClick: onDialogActionClick
              }}
            />
          )
        }
        body={
          isDialogOpen && (
            <React.Fragment>
              <Form
                {...formProps}
                disabled={formProps.disabled || isSearchInProgress}
                hidden={hasSearchResults && !isSearchInProgress}
                ref={setFormRef}
              />

              {hasSearchResults && !isSearchInProgress && (
                <SelectPatientRecordGrid GridProps={{ onRowSelected: onRecordSelect }} rowData={searchResultList} />
              )}
            </React.Fragment>
          )
        }
        ContentProps={{
          classes: { root: style['dialog-content'] }
        }}
        onClose={onDialogClose}
        open={isDialogOpen}
        ref={ref}
        TitleProps={{ classes: { root: style['dialog-title'] } }}
        title={
          isDialogOpen && (
            <React.Fragment>
              <div
                dangerouslySetInnerHTML={{
                  __html: t(i18n.title[!hasSearchResults ? 'invite' : 'select'])
                }}
              />

              {Utils.isNotNil(formApi) && !formSubmitting && (
                <FieldSearchPatientList
                  {...searchFieldProps}
                  onSearchClear={resetState}
                  onSearchEnd={onSearchEnd}
                  onSearchStart={onSearchStart}
                />
              )}
            </React.Fragment>
          )
        }
      />
    );
  }
);

DialogSendGuestAccountInvitation.displayName = 'DialogSendGuestAccountInvitation';
DialogSendGuestAccountInvitation.defaultProps = { maxWidth: 'sm' };
