import { Utils } from '@sigmail/common';
import { FieldValidator } from 'final-form';

type GetFieldValidator<FieldValue> = (...args: any[]) => FieldValidator<FieldValue>;

export type ValidationErrorKey =
  | 'valueMissing'
  | 'range'
  | 'length'
  | 'tooShort'
  | 'tooLong'
  | 'patternMismatch'
  | 'badInput';
export const VALIDATION_ERROR_VALUE_MISSING = 'valueMissing';
export const VALIDATION_ERROR_VALUE_OUT_OF_RANGE = 'range';
export const VALIDATION_ERROR_LENGTH_OUT_OF_RANGE = 'length';
export const VALIDATION_ERROR_TOO_SHORT = 'tooShort';
export const VALIDATION_ERROR_TOO_LONG = 'tooLong';
export const VALIDATION_ERROR_PATTERN_MISMATCH = 'patternMismatch';
export const VALIDATION_ERROR_BAD_INPUT = 'badInput';

export type ValidationErrorResult<
  Key extends string = ValidationErrorKey,
  Value extends boolean | object = boolean | object
> = {
  key: Key;
} & Partial<{ [K in Key]: Value | undefined }>;

export const ERROR_RESULT_VALUE_MISSING: ValidationErrorResult<'valueMissing', true> = {
  key: VALIDATION_ERROR_VALUE_MISSING
};

export const ERROR_RESULT_PATTERN_MISMATCH: ValidationErrorResult<'patternMismatch', true> = {
  key: VALIDATION_ERROR_PATTERN_MISMATCH
};

export const ERROR_RESULT_BAD_INPUT: ValidationErrorResult<'badInput', true> = { key: VALIDATION_ERROR_BAD_INPUT };

export const ERROR_RESULT_VALUE_OUT_OF_RANGE = Utils.memoize(
  <T extends number | Date>(
    min: T,
    max: T,
    actual: T | undefined
  ): ValidationErrorResult<'range', { min: T; max: T; actual: T | undefined }> => ({
    key: VALIDATION_ERROR_VALUE_OUT_OF_RANGE,
    [VALIDATION_ERROR_VALUE_OUT_OF_RANGE]: { min, max, actual }
  }),
  (...args: any[]) => args.map((arg) => (arg instanceof Date ? arg.getTime() : arg)).join(' ')
);

export const ERROR_RESULT_LENGTH_OUT_OF_RANGE = Utils.memoize(
  (
    min: number,
    max: number,
    actual: number | undefined
  ): ValidationErrorResult<'length', { min: number; max: number; actual: number | undefined }> => ({
    key: VALIDATION_ERROR_LENGTH_OUT_OF_RANGE,
    [VALIDATION_ERROR_LENGTH_OUT_OF_RANGE]: { min, max, actual }
  }),
  (...args: any[]) => args.join(' ')
);

export const ERROR_RESULT_TOO_SHORT = Utils.memoize(
  <T extends number | Date>(
    min: T,
    actual: T | undefined
  ): ValidationErrorResult<'tooShort', { min: T; actual: T | undefined }> => ({
    key: VALIDATION_ERROR_TOO_SHORT,
    [VALIDATION_ERROR_TOO_SHORT]: { min, actual }
  }),
  (...args: any[]) => args.map((arg) => (arg instanceof Date ? arg.getTime() : arg)).join(' ')
);

export const ERROR_RESULT_TOO_LONG = Utils.memoize(
  <T extends number | Date>(
    max: T,
    actual: T | undefined
  ): ValidationErrorResult<'tooLong', { max: T; actual: T | undefined }> => ({
    key: VALIDATION_ERROR_TOO_LONG,
    [VALIDATION_ERROR_TOO_LONG]: { max, actual }
  }),
  (...args: any[]) => args.map((arg) => (arg instanceof Date ? arg.getTime() : arg)).join(' ')
);

const isFiniteNumber = (value?: any): value is number => typeof value === 'number' && Number.isFinite(value);
const isNonArrayObjectLike = (value?: any): boolean => value != null && !Array.isArray(value);
const isDate = (value?: any): value is Date => value instanceof Date;
const isValidDate = (value?: any): value is Date => isDate(value) && !Number.isNaN(value.getTime());

function validateRange(value: any, rangeValue: any, rangeValueType: any) {
  if (rangeValueType === 'minValue' || rangeValueType === 'maxValue') {
    if (isFiniteNumber(value) && isFiniteNumber(rangeValue)) {
      return rangeValueType === 'minValue' ? value >= rangeValue : value <= rangeValue;
    }
  } else if (rangeValueType === 'minDate' || rangeValueType === 'maxDate') {
    if (isValidDate(value) && isValidDate(rangeValue)) {
      return rangeValueType === 'minDate'
        ? value.getTime() >= rangeValue.getTime()
        : value.getTime() <= rangeValue.getTime();
    }
  }
  return false;
}

export const nullValidator: GetFieldValidator<any> = () => () => null;

export const required: GetFieldValidator<string | null | undefined> = (options?: any) => {
  const opts: { ignoreNil: boolean; trimValue: boolean } = { ignoreNil: false, trimValue: true };
  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
    opts.trimValue = options.trimValue === true;
  }

  return (value, _allValues, _meta) => {
    if (typeof value !== 'string') {
      return value == null && opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
    }

    let str = value;
    if (opts.trimValue) str = value.trim();

    return str.length > 0 ? null : ERROR_RESULT_VALUE_MISSING;
  };
};

export const requiredTrue: GetFieldValidator<boolean | null | undefined> = (options?: any) => {
  const opts: { ignoreNil: boolean } = { ignoreNil: false };
  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
  }

  return (value, _allValues, _meta) => {
    if (typeof value !== 'boolean') {
      return value == null && opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
    }

    return value === true ? null : ERROR_RESULT_VALUE_MISSING;
  };
};

export const requiredDate: GetFieldValidator<Date | null | undefined> = (options?: any) => {
  const opts: { ignoreNil: boolean } = { ignoreNil: false };
  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
  }

  return (value, _allValues, _meta) => {
    if (value == null) {
      return opts.ignoreNil ? null : ERROR_RESULT_VALUE_MISSING;
    }

    return isValidDate(value) ? null : ERROR_RESULT_BAD_INPUT;
  };
};

export const min: GetFieldValidator<number | string | null | undefined> = (minValue: number, options?: any) => {
  if (!isFiniteNumber(minValue)) {
    throw new TypeError('Expected <minValue> to be a finite number.');
  }

  const opts: { ignoreNil: boolean; ignoreEmptyString: boolean; trimValue: boolean } = {
    ignoreNil: true,
    ignoreEmptyString: true,
    trimValue: true
  };

  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
    opts.ignoreEmptyString = options.ignoreEmptyString === true;
    opts.trimValue = options.trimValue === true;
  }

  return (value, _allValues, _meta) => {
    if (value == null && opts.ignoreNil) return null;

    if (typeof value === 'string' && opts.ignoreEmptyString && (opts.trimValue ? value.trim() : value).length === 0) {
      return null;
    }

    const num = typeof value === 'string' && value.trim().length > 0 ? Number(value) : value;

    if (!isFiniteNumber(num)) return ERROR_RESULT_BAD_INPUT;
    return validateRange(num, minValue, 'minValue') ? null : ERROR_RESULT_TOO_SHORT(minValue, num);
  };
};

export const max: GetFieldValidator<number | string | null | undefined> = (maxValue: number, options?: any) => {
  if (!isFiniteNumber(maxValue)) {
    throw new TypeError('Expected <maxValue> to be a finite number.');
  }

  const opts: { ignoreNil: boolean; ignoreEmptyString: boolean; trimValue: boolean } = {
    ignoreNil: true,
    ignoreEmptyString: true,
    trimValue: true
  };

  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
    opts.ignoreEmptyString = options.ignoreEmptyString === true;
    opts.trimValue = options.trimValue === true;
  }

  return (value, _allValues, _meta) => {
    if (value == null && opts.ignoreNil) return null;

    if (typeof value === 'string' && opts.ignoreEmptyString && (opts.trimValue ? value.trim() : value).length === 0) {
      return null;
    }

    const num = typeof value === 'string' && value.trim().length > 0 ? Number(value) : value;

    if (!isFiniteNumber(num)) return ERROR_RESULT_BAD_INPUT;
    return validateRange(num, maxValue, 'maxValue') ? null : ERROR_RESULT_TOO_LONG(maxValue, num);
  };
};

export const minDate: GetFieldValidator<string | Date | null | undefined> = (minDate: Date, options?: any) => {
  if (!isValidDate(minDate)) {
    throw new TypeError('Expected <minDate> to be a valid instance of [Date].');
  }

  const opts: { ignoreNil: boolean; ignoreEmptyString: boolean; trimValue: boolean } = {
    ignoreNil: true,
    ignoreEmptyString: true,
    trimValue: true
  };

  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
    opts.ignoreEmptyString = options.ignoreEmptyString === true;
    opts.trimValue = options.trimValue === true;
  }

  return (value, _allValues, _meta) => {
    if (value == null) {
      return opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
    }

    if (typeof value === 'string' && opts.ignoreEmptyString && (opts.trimValue ? value.trim() : value).length === 0) {
      return null;
    }

    const dt = new Date(value);
    if (!isValidDate(dt)) return ERROR_RESULT_BAD_INPUT;
    return validateRange(dt, minDate, 'minDate') ? null : ERROR_RESULT_TOO_SHORT(minDate, dt);
  };
};

export const maxDate: GetFieldValidator<string | Date | null | undefined> = (maxDate: Date, options?: any) => {
  if (!isValidDate(maxDate)) {
    throw new TypeError('Expected <maxDate> to be a valid instance of [Date].');
  }

  const opts: { ignoreNil: boolean; ignoreEmptyString: boolean; trimValue: boolean } = {
    ignoreNil: true,
    ignoreEmptyString: true,
    trimValue: true
  };

  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
    opts.ignoreEmptyString = options.ignoreEmptyString === true;
    opts.trimValue = options.trimValue === true;
  }

  return (value, _allValues, _meta) => {
    if (value == null) {
      return opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
    }

    if (typeof value === 'string' && opts.ignoreEmptyString && (opts.trimValue ? value.trim() : value).length === 0) {
      return null;
    }

    const dt = new Date(value);
    if (!isValidDate(dt)) return ERROR_RESULT_BAD_INPUT;
    return validateRange(dt, maxDate, 'maxDate') ? null : ERROR_RESULT_TOO_LONG(maxDate, dt);
  };
};

export const range: GetFieldValidator<number | Date | null | undefined> = <T extends number | Date>(
  minValue: T,
  maxValue: T,
  options?: any
) => {
  const opts: { timeOnly: boolean } = { timeOnly: false };

  if (isNonArrayObjectLike(options)) {
    opts.timeOnly = options.timeOnly === true;
  }

  let minValidator: FieldValidator<any>;
  let maxValidator: FieldValidator<any>;
  if (isDate(minValue) && isDate(maxValue)) {
    if (opts.timeOnly) {
      minValidator = minTime(minValue, options);
      maxValidator = maxTime(maxValue, options);
    } else {
      minValidator = minDate(minValue, options);
      maxValidator = maxDate(maxValue, options);
    }
  } else {
    minValidator = min(minValue, options);
    maxValidator = max(maxValue, options);
  }

  return (value, allValues, meta) => {
    let result = minValidator(value, allValues, meta);
    if (result === null) result = maxValidator(value, allValues, meta);
    return result === null ? null : ERROR_RESULT_VALUE_OUT_OF_RANGE(minValue, maxValue, result.actual);
  };
};

const timeToMilliseconds = (value: Date) =>
  value.getHours() * 60 * 60 * 1000 +
  value.getMinutes() * 60 * 1000 +
  value.getSeconds() * 1000 +
  value.getMilliseconds();

export const minTime: GetFieldValidator<string | Date | null | undefined> = (minTime: Date, options?: any) => {
  if (!isValidDate(minTime)) {
    throw new TypeError('Expected <minTime> to be a valid instance of [Date].');
  }

  const minValue = timeToMilliseconds(minTime);

  const opts: { ignoreNil: boolean; ignoreEmptyString: boolean; trimValue: boolean } = {
    ignoreNil: true,
    ignoreEmptyString: true,
    trimValue: true
  };

  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
    opts.ignoreEmptyString = options.ignoreEmptyString === true;
    opts.trimValue = options.trimValue === true;
  }

  return (value, _allValues, _meta) => {
    if (value == null) {
      return opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
    }

    if (typeof value === 'string' && opts.ignoreEmptyString && (opts.trimValue ? value.trim() : value).length === 0) {
      return null;
    }

    const dt = new Date(value);
    if (!isValidDate(dt)) return ERROR_RESULT_BAD_INPUT;

    const time = timeToMilliseconds(dt);
    return validateRange(time, minValue, 'minValue') ? null : ERROR_RESULT_TOO_SHORT(minValue, time);
  };
};

export const maxTime: GetFieldValidator<string | Date | null | undefined> = (maxTime: Date, options?: any) => {
  if (!isValidDate(maxTime)) {
    throw new TypeError('Expected <maxTime> to be a valid instance of [Date].');
  }

  const maxValue = timeToMilliseconds(maxTime);

  const opts: { ignoreNil: boolean; ignoreEmptyString: boolean; trimValue: boolean } = {
    ignoreNil: true,
    ignoreEmptyString: true,
    trimValue: true
  };

  if (isNonArrayObjectLike(options)) {
    opts.ignoreNil = options.ignoreNil === true;
    opts.ignoreEmptyString = options.ignoreEmptyString === true;
    opts.trimValue = options.trimValue === true;
  }

  return (value, _allValues, _meta) => {
    if (value == null) {
      return opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
    }

    if (typeof value === 'string' && opts.ignoreEmptyString && (opts.trimValue ? value.trim() : value).length === 0) {
      return null;
    }

    const dt = new Date(value);
    if (!isValidDate(dt)) return ERROR_RESULT_BAD_INPUT;

    const time = timeToMilliseconds(dt);
    return validateRange(time, maxValue, 'maxValue') ? null : ERROR_RESULT_TOO_LONG(maxValue, time);
  };
};

export const minLength: GetFieldValidator<{ length: number } | null | undefined> = (
  minLength: number,
  options?: any
) => {
  if (
    typeof minLength !== 'number' ||
    minLength < 0 ||
    minLength > Number.MAX_SAFE_INTEGER ||
    Math.floor(minLength) !== minLength
  ) {
    throw new TypeError(`Expected <minLength> to be an integer between 0 and ${Number.MAX_SAFE_INTEGER}.`);
  }

  const opts: { trimValue: boolean; ignoreNil: boolean } = { trimValue: true, ignoreNil: true };
  if (isNonArrayObjectLike(options)) {
    opts.trimValue = options.trimValue === true;
    opts.ignoreNil = options.ignoreNil === true;
  }

  return (value, _allValues, _meta) => {
    const typeOfValue = typeof value;
    if (typeOfValue !== 'string') {
      if (value == null) return opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
      if (typeOfValue !== 'object' || !('length' in value)) return ERROR_RESULT_BAD_INPUT;
    }

    let { length } = value!;
    if (typeOfValue === 'string' && opts.trimValue) {
      length = (value as string).trim().length;
    }

    return validateRange(length, minLength, 'minValue') ? null : ERROR_RESULT_TOO_SHORT(minLength, length);
  };
};

export const maxLength: GetFieldValidator<{ length: number } | null | undefined> = (
  maxLength: number,
  options?: any
) => {
  if (
    typeof maxLength !== 'number' ||
    maxLength < 0 ||
    maxLength > Number.MAX_SAFE_INTEGER ||
    Math.floor(maxLength) !== maxLength
  ) {
    throw new TypeError(`Expected <maxLength> to be an integer between 0 and ${Number.MAX_SAFE_INTEGER}.`);
  }

  const opts: { trimValue: boolean; ignoreNil: boolean } = { trimValue: true, ignoreNil: true };
  if (isNonArrayObjectLike(options)) {
    opts.trimValue = options.trimValue === true;
    opts.ignoreNil = options.ignoreNil === true;
  }

  return (value, _allValues, _meta) => {
    const typeOfValue = typeof value;
    if (typeOfValue !== 'string') {
      if (value == null) return opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
      if (typeOfValue !== 'object' || !('length' in value)) return ERROR_RESULT_BAD_INPUT;
    }

    let { length } = value!;
    if (typeOfValue === 'string' && opts.trimValue) {
      length = (value as string).trim().length;
    }

    return validateRange(length, maxLength, 'maxValue') ? null : ERROR_RESULT_TOO_LONG(maxLength, length);
  };
};

export const length: GetFieldValidator<{ length: number } | null | undefined> = (
  minValue: number,
  maxValue: number,
  options?: any
) => {
  const minValidator = minLength(minValue, options);
  const maxValidator = maxLength(maxValue, options);

  return (value, allValues, meta) => {
    let result = minValidator(value, allValues, meta);
    if (result === null) result = maxValidator(value, allValues, meta);
    return result === null ? null : ERROR_RESULT_LENGTH_OUT_OF_RANGE(minValue, maxValue, result.actual);
  };
};

export const pattern: GetFieldValidator<string> = (pattern: string | RegExp, options?: any) => {
  const re = typeof pattern === 'string' ? RegExp(pattern, 'u') : pattern;
  if (!(re instanceof RegExp)) {
    throw new TypeError('Expected <pattern> to be a string or an instance of [RegExp].');
  }

  const opts: { trimValue: boolean; ignoreNil: boolean; ignoreEmptyString: boolean } = {
    trimValue: true,
    ignoreNil: true,
    ignoreEmptyString: true
  };

  if (isNonArrayObjectLike(options)) {
    opts.trimValue = options.trimValue === true;
    opts.ignoreNil = options.ignoreNil === true;
    opts.ignoreEmptyString = options.ignoreEmptyString === true;
  }

  return (value, _allValues, _meta) => {
    if (typeof value !== 'string') {
      return value == null && opts.ignoreNil ? null : ERROR_RESULT_BAD_INPUT;
    }

    const str = opts.trimValue ? value.trim() : value;
    return (str.length === 0 && opts.ignoreEmptyString) || re.test(str) ? null : ERROR_RESULT_PATTERN_MISMATCH;
  };
};

export const compose: GetFieldValidator<any> = (...validatorList: ReadonlyArray<FieldValidator<any>>) => (
  value,
  allValues,
  meta
) => {
  for (const validator of validatorList) {
    if (typeof validator !== 'function') continue;

    const result = validator(value, allValues, meta);
    if (result != null) return result;
  }
  return null;
};

export const composeAsync: GetFieldValidator<any> = (...validatorList: ReadonlyArray<FieldValidator<any>>) => async (
  value,
  allValues,
  meta
) => {
  for (const validator of validatorList) {
    if (typeof validator !== 'function') continue;

    const result = await Promise.resolve(validator(value, allValues, meta));
    if (result != null) return result;
  }
  return null;
};
