import jwtDecode from 'jwt-decode';
import { isNonArrayObjectLike, isString } from '.';
import { AppException } from '../app-exception';
import { E_AUTH_FAIL_DECODE_ID_TOKEN } from '../constants/error';
import type { Json, SigmailKeyId, SigmailObjectId, SigmailObjectTypeCode, SigmailUserId } from '../types';

interface RequiredIdTokenProps {
  authState: string;
  credentialId: SigmailObjectId;
  keyId: SigmailKeyId;
  type: SigmailObjectTypeCode; // credential type
  userId: SigmailUserId;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export interface JwtIdTokenValue extends RequiredIdTokenProps {
  [key: string]: Json;
}

const PROPS_NUMERIC: ReadonlyArray<keyof Pick<RequiredIdTokenProps, `${'credential' | 'key' | 'user'}Id` | 'type'>> = [
  'credentialId',
  'keyId',
  'type',
  'userId'
];

export function decodeIdToken(token: string): JwtIdTokenValue {
  let idToken: JwtIdTokenValue | undefined;

  // prettier-ignore
  try { idToken = jwtDecode<JwtIdTokenValue>(token); }
  catch { /* ignore */ }

  if (!isNonArrayObjectLike<typeof idToken>(idToken)) {
    throw new AppException(E_AUTH_FAIL_DECODE_ID_TOKEN, 'ID Token is either missing or invalid.');
  }

  /* eslint-disable no-param-reassign */
  return PROPS_NUMERIC.reduce((obj, prop) => {
    const value = obj[prop];
    obj[prop] = isString(value) ? Number.parseInt(value, 10) : value;
    return obj;
  }, idToken);
  /* eslint-enable no-param-reassign */
}
