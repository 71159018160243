import React from 'react';
import { discardDraftAction } from '../../../../app-state/actions/messaging/discard-draft-action';
import { useAppState } from '../../../../app-state/hooks';
import { ActionData } from '../../types';
import { BaseContextValue } from '../context';

export interface ActionDataDiscardDraft extends ActionData {
  messageList: ReturnType<BaseContextValue['getSelectedMessageList']>;
}

export const useDiscardDraftActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({ failCallback, messageList, successCallback }: ActionDataDiscardDraft) => {
      let value: any;

      try {
        value = await appDispatch(discardDraftAction({ msgMetadataId: messageList.map(({ header }) => header) }));
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
