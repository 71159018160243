import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogOneTimeMessageAccessCode } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.OneTimeMessage.AccessCode = {
  action: {
    accept: {
      label: gettext('messaging', 'accept', 'oneTimeMessageAccessDialogActionLabel'),
      context: DialogOneTimeMessageAccessCode
    },
    reject: {
      label: gettext('messaging', 'reject', 'oneTimeMessageAccessDialogActionLabel'),
      context: DialogOneTimeMessageAccessCode
    }
  },
  body: gettext('messaging', 'body', 'oneTimeMessageAccessDialog', 'safe-html'),
  title: gettext('messaging', 'title', 'oneTimeMessageAccessDialog', 'safe-html')
};

export default i18n;
