import { MultiFactorAuthI18n } from '@sigmail/i18n';
import { gettext } from '..';
import disableMfa from './dialog-disable-mfa';
import enableMfa from './dialog-enable-mfa';
import mfaDisabled from './dialog-mfa-disabled';
import selectMethod from './form-select-method';
import verifyCode from './form-verify-code';

interface I18N {
  verifyMfaCommons: MultiFactorAuthI18n.VerifyMfaCommons;
  dialog: {
    enableMfa: MultiFactorAuthI18n.Dialog.EnableMFA;
    disableMfa: MultiFactorAuthI18n.Dialog.DisableMFA;
    mfaDisabled: MultiFactorAuthI18n.Dialog.MFADisabled;
  };
  form: {
    selectMethod: MultiFactorAuthI18n.Form.SelectMethod;
    verifyCode: MultiFactorAuthI18n.Form.VerifyCode;
  };
}

const i18n: I18N = {
  verifyMfaCommons: {
    leadText: {
      // Translators: (HTML) Message displayed to user indicating the phone \
      // number on which a text message containing the MFA code has been sent.
      // Translators: Available tokens: {{MFA_CONTACT}}
      // Translators: E.x.: A text message with the code was sent to {{MFA_CONTACT}}.
      sms: gettext('mfa', 'verifyMfa', 'otpSentViaSMS', 'safe-html'),

      // Translators: (HTML) Message displayed to user indicating the email \
      // address on which a message containing the MFA code has been sent.
      // Translators: Available tokens: {{MFA_CONTACT}}
      // Translators: E.x.: An email with the code was sent to {{MFA_CONTACT}}.
      email: gettext('mfa', 'verifyMfa', 'otpSentViaEmail', 'safe-html')
    },
    // Translators: Text to be displayed along with the Resend Code action. \
    // For example, "Did not receive your code? {{RESEND_CODE_ACTION}}"
    // Translators: Available token(s): {{RESEND_CODE_ACTION}}
    resendCode: gettext('mfa', 'verifyMfa', 'resendCodeText'),
    action: {
      resendCode: {
        // Translators: Label for the Resend Code action of Sign In form
        label: gettext('mfa', 'verifyMfa', 'actionLabelResendCode')
      }
    }
  },
  dialog: {
    enableMfa,
    disableMfa,
    mfaDisabled
  },
  form: {
    selectMethod,
    verifyCode
  }
};

export default i18n;
