import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogAcceptReferral } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.Referral.Accept = {
  action: {
    accept: {
      context: DialogAcceptReferral,
      // Translators: Label for the Proceed action of Accept Referral dialog
      label: gettext('messaging', 'accept', 'dialogAcceptReferralActionLabel')
    },
    reject: {
      context: DialogAcceptReferral,
      // Translators: Label for the Cancel action of Accept Referral dialog
      label: gettext('messaging', 'reject', 'dialogAcceptReferralActionLabel')
    }
  },
  // Translators: (HTML) Body of the Accept Referral dialog
  body: gettext('messaging', 'body', 'dialogAcceptReferral', 'safe-html'),
  form: {
    fieldsetConsultDate: {
      formField: {
        consultDate: {
          error: {
            // Translators: Error message displayed when the entered value is \
            // not a valid date, for example, an invalid data type, or 2021-02-09
            badInput: gettext('messaging', 'consultDateBadInput', 'formAcceptReferralError'),
            // Translators: Error message displayed when Consult Date in \
            // Accept Referral dialog is out of acceptable range
            // Translators: Available tokens: {{MIN_DATE}}
            tooShort: gettext('messaging', 'consultDateTooShort', 'formAcceptReferralError')
          },
          // Translators: Label for the Consult Date input in Accept Referral dialog
          label: gettext('messaging', 'consultDate', 'formAcceptReferralInputLabel')
        }
      }
    },
    fieldsetGroup: {
      formField: {
        group: {
          error: {
            // Translators: Error message displayed when no circle of care \
            // group is selected in Accept Referral dialog
            valueMissing: gettext('messaging', 'groupRequired', 'formAcceptReferralError')
          },
          // Translators: Label for the Group input in Accept Referral dialog
          label: gettext('messaging', 'group', 'formAcceptReferralInputLabel'),
          localeText: {
            // Translators: Label for the Close Popup icon of Group input
            closePopup: gettext('messaging', 'closeGroupPopup', 'formAcceptReferralLocaleText'),
            // Translators: Text to display when there are no groups to show \
            // in Group input
            noOptions: gettext('messaging', 'noGroups', 'formAcceptReferralLocaleText'),
            // Translators: Label for the Open Popup icon of Group input
            openPopup: gettext('messaging', 'openGroupPopup', 'formAcceptReferralLocaleText')
          }
        }
      }
    }
  },
  // Translators: (HTML) Title for the Referral Proposed Date Of Consult dialog
  title: gettext('messaging', 'title', 'dialogAcceptReferral', 'safe-html')
};

export default i18n;
