import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { CopdAssessmentView } from '../../constants/action-context';

const i18n: HealthDataI18n.View.CopdAssessment = {
  action: {
    cancel: {
      label: gettext('health-data', 'cancel', 'copdAssessmentViewActionLabel'),
      context: CopdAssessmentView
    },
    submit: {
      label: gettext('health-data', 'submit', 'copdAssessmentViewActionLabel'),
      context: CopdAssessmentView
    }
  },
  heading: gettext('health-data', 'heading', 'copdAssessmentView', 'safe-html'),
  leadText: gettext('health-data', 'leadText', 'copdAssessmentView', 'safe-html')
};

export default i18n;
