import { ReadonlyPartialRecord, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import {
  ICellRendererParams,
  ValueFormatterParams as AgGridValueFormatterParams,
  ValueGetterParams as AgGridValueGetterParams
} from 'ag-grid-community';
import { AgGridColumn as GridColumn, AgGridColumnProps as GridColumnProps } from 'ag-grid-react';
import { WithT } from 'i18next';
import React from 'react';
import { SearchPatientRecord } from '../../app-state/hooks';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import * as AccuroEMRUtils from '../../utils/accuro-emr';
import { Grid, Props as BaseGridProps } from './grid/grid.component';
import style from './select-patient-record-grid.module.css';

const { selectPatientRecord: i18n } = globalI18n.grid;

type TContext = WithT & { locale: string };
type TRowData = SearchPatientRecord;

export interface CellRendererParams extends ICellRendererParams {
  context: TContext;
  data: TRowData;
}

interface ValueFormatterParams extends Omit<AgGridValueFormatterParams, 'context' | 'data' | 'value'> {
  context: TContext;
  data: TRowData;
  value: unknown;
}

interface ValueGetterParams extends Omit<AgGridValueGetterParams, 'context' | 'data' | 'getValue'> {
  context: TContext;
  data: TRowData;
  getValue: (field: string) => string;
}

type CellRendererFunc = (params: CellRendererParams) => HTMLElement | string;
type GetValueFormatterParams = string | ((params: ValueFormatterParams) => string) | undefined;
type GetValueGetterParams = string | ((params: ValueGetterParams) => unknown) | undefined;
export type ColumnId = keyof typeof i18n.columnHeader | 'node';

const COL_ID_BIRTH_DATE: Extract<ColumnId, 'birthDate'> = 'birthDate';
const COL_ID_CELL_PHONE: Extract<ColumnId, 'cellPhone'> = 'cellPhone';
const COL_ID_FIRST_NAME: Extract<ColumnId, 'firstName'> = 'firstName';
const COL_ID_FULL_NAME: Extract<ColumnId, 'fullName'> = 'fullName';
const COL_ID_GENDER: Extract<ColumnId, 'gender'> = 'gender';
const COL_ID_HEALTH_INSURANCE: Extract<ColumnId, 'healthInsurance'> = 'healthInsurance';
const COL_ID_HEALTH_PLAN_JURISDICTION: Extract<ColumnId, 'healthPlanJurisdiction'> = 'healthPlanJurisdiction';
const COL_ID_HEALTH_PLAN_NUMBER: Extract<ColumnId, 'healthPlanNumber'> = 'healthPlanNumber';
const COL_ID_HOME_PHONE: Extract<ColumnId, 'homePhone'> = 'homePhone';
const COL_ID_LAST_NAME: Extract<ColumnId, 'lastName'> = 'lastName';
const COL_ID_NODE: Extract<ColumnId, 'node'> = 'node';
const COL_ID_PHONE: Extract<ColumnId, 'phone'> = 'phone';
const COL_ID_RECORD_SOURCE: Extract<ColumnId, 'source'> = 'source';

const ORDERED_COLUMN_LIST: ReadonlyArray<ColumnId> = [
  COL_ID_FULL_NAME,
  COL_ID_GENDER,
  COL_ID_PHONE,
  COL_ID_HEALTH_INSURANCE,
  COL_ID_RECORD_SOURCE
];

const getHealthPlanJurisdictionLabel = (value?: unknown): string => {
  const index = globalI18n.healthPlanJurisdictionList.findIndex(({ code }) => code === value);
  return Utils.stringOrDefault(index > -1 && globalI18n.healthPlanJurisdictionList[index].label);
};

const getRecordSource = (data: TRowData) => Utils.stringOrDefault<TRowData['source']>(data.source);

const CELL_RENDERER: ReadonlyPartialRecord<ColumnId, CellRendererFunc> = {
  [COL_ID_FULL_NAME]: ({ data }) => {
    const record = data.record.demographics;
    const fullName = AccuroEMRUtils.selectJoinedFullName(record);
    const birthDate = AccuroEMRUtils.selectFormattedBirthDate(record);
    return birthDate.length === 0
      ? fullName
      : `<em class="span-primary">${fullName}</em><em class="span-secondary">${birthDate}</em>`;
  },

  [COL_ID_HEALTH_INSURANCE]: ({ context: { t }, data }) => {
    const record = data.record.demographics;
    const planNumber = Utils.trimOrDefault(AccuroEMRUtils.selectHealthPlanNumber(record));
    const jurisdiction = t(getHealthPlanJurisdictionLabel(AccuroEMRUtils.selectHealthPlanJurisdiction(record)));
    return jurisdiction.length === 0
      ? planNumber
      : `<em class="span-primary">${planNumber}</em><em class="span-secondary">${jurisdiction}</em>`;
  },

  [COL_ID_PHONE]: ({ data: { record } }) => {
    const cellPhone = Utils.trimOrDefault(AccuroEMRUtils.selectPhoneNumber(record.demographics, 'CellPhone')?.number);
    const homePhone = Utils.trimOrDefault(AccuroEMRUtils.selectPhoneNumber(record.demographics, 'HomePhone')?.number);
    return [cellPhone, homePhone]
      .filter((value) => value.length > 0)
      .map((value, index, arr) =>
        arr.length === 1 ? value : `<em class="span-${index === 0 ? 'primary' : 'secondary'}">${value}</em>`
      )
      .join('');
  }
};

const VALUE_FORMATTER: ReadonlyPartialRecord<ColumnId, GetValueFormatterParams> = {
  [COL_ID_BIRTH_DATE]: ({ data: { record } }) => AccuroEMRUtils.selectFormattedBirthDate(record.demographics),

  [COL_ID_CELL_PHONE]: ({ data: { record } }) =>
    Utils.trimOrDefault(AccuroEMRUtils.selectPhoneNumber(record.demographics, 'CellPhone')?.number),

  [COL_ID_FIRST_NAME]: ({ data: { record } }) =>
    Utils.trimOrDefault(AccuroEMRUtils.selectFirstName(record.demographics)),

  [COL_ID_FULL_NAME]: ({ value }) => AccuroEMRUtils.selectJoinedFullName(value as Api.AccuroEMRPersonNameDTO),

  [COL_ID_GENDER]: ({ context: { t }, data: { record } }) => {
    const gender = AccuroEMRUtils.selectGender(record.demographics);
    return t(globalI18n.genderList.find(({ code }) => code === gender)!.label);
  },

  [COL_ID_HEALTH_PLAN_JURISDICTION]: ({ context: { t }, data: { record } }) =>
    t(getHealthPlanJurisdictionLabel(AccuroEMRUtils.selectHealthPlanJurisdiction(record.demographics))),

  [COL_ID_HEALTH_PLAN_NUMBER]: ({ data: { record } }) =>
    Utils.trimOrDefault(AccuroEMRUtils.selectHealthPlanNumber(record.demographics)),

  [COL_ID_HOME_PHONE]: ({ data: { record } }) =>
    Utils.trimOrDefault(AccuroEMRUtils.selectPhoneNumber(record.demographics, 'HomePhone')?.number),

  [COL_ID_LAST_NAME]: ({ data: { record } }) => Utils.trimOrDefault(AccuroEMRUtils.selectLastName(record.demographics)),

  [COL_ID_RECORD_SOURCE]: ({ data }) => {
    switch (getRecordSource(data)) {
      case 'accuroEMR':
        return 'Accuro';
      case 'oscarEMR':
        return 'Oscar';
      default:
        return 'Other';
    }
  }
};

const VALUE_GETTER: ReadonlyPartialRecord<ColumnId, GetValueGetterParams> = {
  [COL_ID_BIRTH_DATE]: '',
  [COL_ID_CELL_PHONE]: '',
  [COL_ID_FIRST_NAME]: '',
  [COL_ID_FULL_NAME]: '',
  [COL_ID_GENDER]: '',
  [COL_ID_HEALTH_INSURANCE]: '',
  [COL_ID_HEALTH_PLAN_JURISDICTION]: '',
  [COL_ID_HEALTH_PLAN_NUMBER]: '',
  [COL_ID_HOME_PHONE]: '',
  [COL_ID_LAST_NAME]: '',
  [COL_ID_NODE]: '',
  [COL_ID_PHONE]: '',
  [COL_ID_RECORD_SOURCE]: ''
};

const DEFAULT_GRID_OPTIONS: NonNullable<BaseGridProps['gridOptions']> = {
  animateRows: true,
  colResizeDefault: 'shift',
  defaultColDef: {
    resizable: true,
    sortable: false,
    suppressMenu: true,
    suppressMovable: true
  },
  enableBrowserTooltips: true,
  getRowNodeId: ({ record: { uuid } }: TRowData) => uuid,
  headerHeight: 32,
  immutableData: true,
  rowHeight: 44,
  rowSelection: 'single',
  suppressCellSelection: true,
  suppressLoadingOverlay: true,
  valueCache: true
};

type HtmlDivElementProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'children'>;

export interface Props extends HtmlDivElementProps {
  children?: never;
  GridProps?: Omit<BaseGridProps, 'children' | 'gridOptions' | 'localeText' | 'rowData'>;
  rowData?: ReadonlyArray<TRowData>;
}

export const SelectPatientRecordGrid = React.forwardRef<HTMLDivElement, Props>(
  ({ children, GridProps, rowData, ...rootProps }, ref) => {
    // prettier-ignore
    const { i18n: { language: locale }, t } = useTranslation([I18N_NS_GLOBAL]);

    const gridContext = React.useMemo<TContext>(() => ({ locale, t }), [locale, t]);

    const gridProps = React.useMemo<typeof GridProps>(() => {
      if (Utils.isNil(GridProps)) return GridProps;

      const { children, gridOptions, rowData, ...props } = GridProps as React.PropsWithChildren<BaseGridProps>;
      return props;
    }, [GridProps]);

    return (
      <div ref={ref} {...rootProps}>
        <Grid
          className={style.grid}
          {...DEFAULT_GRID_OPTIONS}
          context={gridContext}
          {...gridProps}
          localeText={React.useMemo(() => Utils.mapValues(i18n.localeText, (v) => t(v)), [t])}
          rowData={rowData as Array<TRowData>}
        >
          {ORDERED_COLUMN_LIST.map((colId) => {
            const isNodeIdColumn = colId === COL_ID_NODE;
            const isGenderColumn = !isNodeIdColumn && colId === COL_ID_GENDER;
            const isRecordSourceColumn = !isNodeIdColumn && !isGenderColumn && colId === COL_ID_RECORD_SOURCE;

            let flex: GridColumnProps['flex'];
            let headerName: NonNullable<GridColumnProps['headerName']> = '';
            let width: GridColumnProps['width'];
            if (isNodeIdColumn) {
              width = 42;
            } else {
              headerName = t(i18n.columnHeader[colId]);
              if (isGenderColumn) width = 80;
              else if (isRecordSourceColumn) width = 64;
              else flex = 2;
            }

            return (
              <GridColumn
                cellRenderer={CELL_RENDERER[colId]}
                checkboxSelection={isNodeIdColumn}
                colId={colId}
                flex={flex}
                headerName={headerName}
                key={colId}
                valueFormatter={VALUE_FORMATTER[colId]}
                valueGetter={VALUE_GETTER[colId]}
                width={width}
              />
            );
          })}
        </Grid>
      </div>
    );
  }
);

SelectPatientRecordGrid.displayName = 'SelectPatientRecordGrid';
