/* eslint-disable no-redeclare */
import { getInvalidInputElementList } from './get-invalid-input-element-list';

export function focusFirstInvalidInputElement(): HTMLElement | null;
export function focusFirstInvalidInputElement(parentNode: ParentNode | null | undefined): HTMLElement | null;
export function focusFirstInvalidInputElement(scrollIntoView: boolean): HTMLElement | null;
export function focusFirstInvalidInputElement(
  parentNode: ParentNode | null | undefined,
  scrollIntoView: boolean
): HTMLElement | null;

export function focusFirstInvalidInputElement(...args: any[]): HTMLElement | null {
  const parentNode = (args.length > 0 && typeof args[0] === 'object' && args[0]) || document;

  const [firstInvalidInput] = getInvalidInputElementList(parentNode);
  if (firstInvalidInput) {
    firstInvalidInput.focus();

    const scrollIntoView =
      (args.length === 1 && typeof args[0] === 'boolean' && args[0]) || (args.length > 1 && !!args[1]);

    if (scrollIntoView) {
      firstInvalidInput.scrollIntoView({ behavior: 'auto', block: 'start' });
    }

    return firstInvalidInput;
  }

  return null;
}
