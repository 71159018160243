import { MultiFactorAuthI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogDisableMfaFormSignIn, DialogDisableMfaFormVerifyMfaCode } from '../../constants/action-context';

const i18n: MultiFactorAuthI18n.Dialog.DisableMFA = {
  title: {
    // Translators: (HTML) Title for Disable MFA dialog's Verify ID step
    signIn: gettext('mfa', 'disableMfaDialog', 'titleSignIn', 'safe-html'),
    // Translators: (HTML) Title for Disable MFA dialog's Verify Code step
    verifyMfa: gettext('mfa', 'disableMfaDialog', 'titleVerifyMfa', 'safe-html')
  },
  body: {
    // Translators: (HTML) Body for Disable MFA dialog's Verify ID step
    signIn: gettext('mfa', 'disableMfaDialog', 'bodySignIn', 'safe-html')
  },
  action: {
    signIn: {
      reject: {
        // Translators: Label for the Cancel action of Disable MFA dialog's \
        // Verify ID step
        label: gettext('mfa', 'disableMfaDialog', 'actionLabelCancelSignIn'),
        context: DialogDisableMfaFormSignIn
      },
      accept: {
        // Translators: Label for the Submit action of Disable MFA dialog's \
        // Verify ID step
        label: gettext('mfa', 'disableMfaDialog', 'actionLabelSubmitSignIn'),
        context: DialogDisableMfaFormSignIn
      }
    },
    verifyMfa: {
      reject: {
        // Translators: Label for the Cancel action of Disable MFA dialog's \
        // Verify Code step
        label: gettext('mfa', 'disableMfaDialog', 'actionLabelCancelVerifyMfa'),
        context: DialogDisableMfaFormVerifyMfaCode
      },
      accept: {
        // Translators: Label for the Submit action of Disable MFA dialog's \
        // Verify Code step
        label: gettext('mfa', 'disableMfaDialog', 'actionLabelSubmitVerifyMfa'),
        context: DialogDisableMfaFormVerifyMfaCode
      }
    }
  }
};

export default i18n;
