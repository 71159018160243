export function readFileAsArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('error', () => reject(reader.error));
    reader.addEventListener(
      'load',
      () => reader.readyState === FileReader.DONE && resolve(reader.result as ArrayBuffer)
    );
    reader.readAsArrayBuffer(blob);
  });
}
