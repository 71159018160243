import React from 'react';
import { updateMessageReadStateAction } from '../../../../app-state/actions/messaging/update-message-read-state-action';
import { useAppState } from '../../../../app-state/hooks';
import { ActionData } from '../../types';
import { BaseContextValue } from '../context';

export interface ActionDataMarkMessageReadUnread extends ActionData {
  currentFolder?: BaseContextValue['currentFolder'];
  markUnread: boolean;
  messageList: ReturnType<BaseContextValue['getSelectedMessageList']>;
}

export const useMarkMessageReadUnreadActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({
      currentFolder,
      failCallback,
      markUnread: unread,
      messageList,
      successCallback
    }: ActionDataMarkMessageReadUnread) => {
      let value: any;

      try {
        value = await appDispatch(
          updateMessageReadStateAction({
            folderKey: currentFolder?.folderKey!,
            parentFolderKey: currentFolder?.parentFolderKey,
            msgMetadataId: messageList.map(({ header }) => header),
            unread
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
