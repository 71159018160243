import { Constants } from '@sigmail/common';

const A_TO_Z = /[A-Za-z]/;
const O_TO_9 = /[0-9]/;

// prettier-ignore
export const HealthPlanNumber: Readonly<Record<string, Readonly<Record<string, Array<string | RegExp> | false>>>> = {
  CAN: {
    [Constants.CanadianProvinceCode.Alberta]: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.BritishColumbia]: [/9/, O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.Manitoba]: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.NewBrunswick]: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.NewfoundlandAndLabrador]: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.NovaScotia]: [O_TO_9, O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.NorthwestTerritories]: [/[DdMmNnTt]/, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.Nunavut]: [O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.Ontario]: [O_TO_9, O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', A_TO_Z, A_TO_Z],
    [Constants.CanadianProvinceCode.PrinceEdwardIsland]: [O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.Quebec]: [A_TO_Z, A_TO_Z, A_TO_Z, A_TO_Z, '-', O_TO_9, O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.Saskatchewan]: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    [Constants.CanadianProvinceCode.Yukon]: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9]
  }
};

export const PostalCode: Array<string | RegExp> = [A_TO_Z, O_TO_9, A_TO_Z, ' ', O_TO_9, A_TO_Z, O_TO_9];

// prettier-ignore
export const PhoneNumber: Array<string | RegExp> = [
  '(', /[1-9]/, O_TO_9, O_TO_9, ')', ' ', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, O_TO_9
];

export const FaxNumber = PhoneNumber;

export const Age = [O_TO_9, O_TO_9, O_TO_9];

export const BloodPressure = [O_TO_9, O_TO_9, O_TO_9];

export const Height = {
  CMS: [O_TO_9, O_TO_9, O_TO_9],
  FEET: [/[1-9]/, "'", ' ', O_TO_9, O_TO_9, '"']
};

export const HeartRate = [O_TO_9, O_TO_9, O_TO_9];

export const Weight = [O_TO_9, O_TO_9, O_TO_9];
