import type { ReadonlyMessageBodyReferral, SigmailObjectId } from '@sigmail/common';
import type { EventLogRecordReferral } from '@sigmail/objects';

type EventLogRecordValueReferral = EventLogRecordReferral['value'];
type ReferralMsgBodyValue = ReadonlyMessageBodyReferral['messageForm']['value'];

export type Params = Readonly<
  [msgMetadataId: SigmailObjectId, msgBodyId: SigmailObjectId, msgBody: ReferralMsgBodyValue]
>;

export const newEventLogRecordValueReferral = (...params: Params): EventLogRecordValueReferral => {
  const [msgMetadataId, msgBodyId, { referToList, referrer, patient }] = params;

  return {
    body: msgBodyId,
    guestUserId: patient.id,
    header: msgMetadataId,
    referToList,
    referrerId: referrer.id
  };
};
