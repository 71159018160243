import { HRMI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';

const i18n: HRMI18n.Form = {
  fieldsetGroup: {
    patient: {
      // Translators: Short label for the HRM form's Patient fieldset group
      shortLabel: gettext('hrm', 'shortLabel', 'hrmFormFieldsetGroupPatient'),
      // Translators: Full label for the HRM form's Patient fieldset group
      fullLabel: gettext('hrm', 'fullLabel', 'hrmFormFieldsetGroupPatient'),
      fieldsetName: {
        formField: {
          firstName: {
            // Translators: Label for the Patient's First Name input on HRM form
            label: gettext('hrm', 'firstName', 'hrmFormInputLabel'),
            error: {
              // Translators: Error displayed when Patient's First Name input \
              // on HRM form is left blank
              valueMissing: gettext('hrm', 'firstNameRequired', 'hrmFormError'),
              // Translators: Error displayed when Patient's First Name input \
              // on HRM form doesn't satisfy minimum/maximum length requirement
              // Translators: Token(s) available: {{MIN_LENGTH}}, {{MAX_LENGTH}}
              length: gettext('hrm', 'firstNameLength', 'hrmFormError'),
              // Translators: Error displayed when Patient's First Name input \
              // on HRM form contains invalid characters
              patternMismatch: gettext('hrm', 'firstNamePattern', 'hrmFormError')
            }
          },
          lastName: {
            // Translators: Label for the Patient's Last Name input on HRM form
            label: gettext('hrm', 'lastName', 'hrmFormInputLabel'),
            error: {
              // Translators: Error displayed when Patient's Last Name input \
              // on HRM form is left blank
              valueMissing: gettext('hrm', 'lastNameRequired', 'hrmFormError'),
              // Translators: Error displayed when Patient's Last Name input \
              // on HRM form doesn't satisfy minimum/maximum length requirement
              // Translators: Token(s) available: {{MIN_LENGTH}}, {{MAX_LENGTH}}
              length: gettext('hrm', 'lastNameLength', 'hrmFormError'),
              // Translators: Error displayed when Patient's Last Name input \
              // on HRM form contains invalid characters
              patternMismatch: gettext('hrm', 'lastNamePattern', 'hrmFormError')
            }
          }
        }
      },
      fieldsetGender: {
        formField: {
          gender: {
            // Translators: Label for the Patient's Gender input on HRM form
            label: gettext('hrm', 'gender', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Patient's Gender input on HRM form
              valueMissing: gettext('hrm', 'genderRequired', 'hrmFormError')
            }
          }
        }
      },
      fieldsetBirthDate: {
        formField: {
          birthDate: {
            // Translators: Label for the Patient's Birth Date input on HRM form
            label: gettext('hrm', 'birthDate', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no date is \
              // selected for Patient's Birth Date input on HRM form
              valueMissing: gettext('hrm', 'birthDateRequired', 'hrmFormError'),
              // Translators: Error message displayed when date entered for \
              // Patient's Birth Date input on HRM form is invalid. For e.x., \
              // 2022-02-30
              badInput: gettext('hrm', 'birthDateBadInput', 'hrmFormError')
            }
          }
        }
      },
      fieldsetHealthPlan: {
        formField: {
          hpn: {
            // Translators: Label for the Patient's Health Plan Number input \
            // on HRM form
            label: gettext('hrm', 'healthPlanNumber', 'hrmFormInputLabel'),
            error: {
              // Translators: Error displayed when Patient's Health Plan \
              // Number input on HRM form is left blank
              valueMissing: gettext('hrm', 'healthPlanNumberRequired', 'hrmFormError'),
              // Translators: Error displayed when value entered in Patient's \
              // Health Plan Number input on HRM form does not match the \
              // expected format
              patternMismatch: gettext('hrm', 'healthPlanNumberPattern', 'hrmFormError')
            }
          },
          hpnVersionCode: {
            // Translators: Label for the Patient's Health Plan Number Version \
            // Code input on HRM form
            label: gettext('hrm', 'hpnVersionCode', 'hrmFormInputLabel'),
            error: {
              // Translators: Error displayed when Patient's Health Plan \
              // Number Version Code input on HRM form is left blank
              valueMissing: gettext('hrm', 'hpnVersionCodeRequired', 'hrmFormError')
            }
          }
        }
      },
      fieldsetAddress: {
        // Translators: Label for the Patient's Address fieldset
        label: gettext('hrm', 'label', 'hrmFormFieldsetPatientAddress'),
        formField: {
          addressType: {
            // Translators: Label for the Patient's Address Type input on HRM \
            // form
            label: gettext('hrm', 'addressType', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Patient's Address Type input on HRM form
              valueMissing: gettext('hrm', 'addressTypeRequired', 'hrmFormError')
            }
          },
          address: {
            // Translators: Label for the Patient's Address input on HRM form
            label: gettext('hrm', 'address', 'hrmFormInputLabel')
          },
          city: {
            // Translators: Label for the Patient's City input on HRM form
            label: gettext('hrm', 'city', 'hrmFormInputLabel')
          },
          province: {
            // Translators: Label for the Patient's Province input on HRM form
            label: gettext('hrm', 'province', 'hrmFormInputLabel')
          },
          postalCode: {
            // Translators: Label for the Patient's Postal Code input on HRM \
            // form
            label: gettext('hrm', 'postalCode', 'hrmFormInputLabel')
          }
        }
      },
      fieldsetContact: {
        label: 'Contact',
        formField: {
          contactType: {
            // Translators: Label for the Patient's Contact Type input on HRM \
            // form
            label: gettext('hrm', 'contactType', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Patient's Contact Type input on HRM form
              valueMissing: gettext('hrm', 'contactTypeRequired', 'hrmFormError')
            }
          },
          contactNumber: {
            // Translators: Label for the Patient's Contact Number input on \
            // HRM form
            label: gettext('hrm', 'contactNumber', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when Patient's Contact \
              // Number input on HRM form is left blank
              valueMissing: gettext('hrm', 'contactNumberRequired', 'hrmFormError')
            }
          }
        }
      },
      fieldsetDeceased: {
        formField: {
          deceased: {
            // Translators: Label for the Patient's Deceased checkbox input on \
            // HRM form
            label: gettext('hrm', 'deceased', 'hrmFormInputLabel')
          }
        }
      }
    },
    encounter: {
      // Translators: Short label for the HRM form's Encounter fieldset group
      shortLabel: gettext('hrm', 'shortLabel', 'hrmFormFieldsetGroupEncounter'),
      // Translators: Full label for the HRM form's Encounter fieldset group
      fullLabel: gettext('hrm', 'fullLabel', 'hrmFormFieldsetGroupEncounter'),
      fieldsetEncounter: {
        formField: {
          encounterClass: {
            // Translators: Label for the Encounter Class input on HRM form
            label: gettext('hrm', 'encounterClass', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Encounter Class input on HRM form
              valueMissing: gettext('hrm', 'encounterClassRequired', 'hrmFormError')
            }
          },
          encounterStatus: {
            // Translators: Label for the Encounter Status input on HRM form
            label: gettext('hrm', 'encounterStatus', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Encounter Status input on HRM form
              valueMissing: gettext('hrm', 'encounterStatusRequired', 'hrmFormError')
            }
          },
          startDate: {
            // Translators: Label for the Encounter Start Date And Time input \
            // on HRM form
            label: gettext('hrm', 'encounterStartDate', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when Encounter Start \
              // Date And Time input on HRM form is left blank
              valueMissing: gettext('hrm', 'encounterStartDateRequired', 'hrmFormError'),
              // Translators: Error message displayed when Encounter Start \
              // Date And Time input on HRM form is out of range
              // Token(s) available: {{MIN}}, {{MAX}}
              range: gettext('hrm', 'encounterStartDateRange', 'hrmFormError'),
              // Translators: Error message displayed when value entered for \
              // Encounter Start Date And Time input on HRM form is invalid
              badInput: gettext('hrm', 'encounterStartDateBadInput', 'hrmFormError')
            }
          },
          endDate: {
            // Translators: Label for the Encounter End Date And Time input \
            // on HRM form
            label: gettext('hrm', 'encounterEndDate', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when Encounter End \
              // Date And Time input on HRM form is left blank
              valueMissing: gettext('hrm', 'encounterEndDateRequired', 'hrmFormError'),
              // Translators: Error message displayed when Encounter End \
              // Date And Time input on HRM form is out of range
              // Token(s) available: {{MIN}}, {{MAX}}
              range: gettext('hrm', 'encounterEndDateRange', 'hrmFormError'),
              // Translators: Error message displayed when value entered for \
              // Encounter End Date And Time input on HRM form is invalid
              badInput: gettext('hrm', 'encounterEndDateBadInput', 'hrmFormError')
            }
          }
        }
      }
    },
    diagnosis: {
      // Translators: Short label for the HRM form's Diagnosis fieldset group
      shortLabel: gettext('hrm', 'shortLabel', 'hrmFormFieldsetGroupDiagnosis'),
      // Translators: Full label for the HRM form's Diagnosis fieldset group
      fullLabel: gettext('hrm', 'fullLabel', 'hrmFormFieldsetGroupDiagnosis'),
      fieldsetDiagnosis: {
        formField: {
          loincType: {
            // Translators: Label for the Diagnosis Report Type input on HRM \
            // form
            label: gettext('hrm', 'loincType', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Diagnosis Report Type input on HRM form
              valueMissing: gettext('hrm', 'loincTypeRequired', 'hrmFormError')
            }
          },
          category: {
            // Translators: Label for the Diagnosis Category input on HRM form
            label: gettext('hrm', 'diagnosisCategory', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Diagnosis Category input on HRM form
              valueMissing: gettext('hrm', 'diagnosisCategoryRequired', 'hrmFormError')
            }
          },
          diagnosisStatus: {
            // Translators: Label for the Diagnosis Status input on HRM form
            label: gettext('hrm', 'diagnosisStatus', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Diagnosis Status input on HRM form
              valueMissing: gettext('hrm', 'diagnosisStatusRequired', 'hrmFormError')
            }
          },
          urgent: {
            // Translators: Label for the Diagnosis Urgency input on HRM form
            label: gettext('hrm', 'diagnosisUrgency', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Diagnosis Urgency input on HRM form
              valueMissing: gettext('hrm', 'diagnosisUrgencyRequired', 'hrmFormError')
            }
          },
          conclusion: {
            // Translators: Label for the Diagnosis Conclusion input on HRM \
            // form
            label: gettext('hrm', 'diagnosisConclusion', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when Diagnosis \
              // Conclusion input on HRM form is left blank
              valueMissing: gettext('hrm', 'diagnosisConclusionRequired', 'hrmFormError')
            }
          },
          issuedDate: {
            // Translators: Label for the Diagnosis Issued Date And Time input \
            // on HRM form
            label: gettext('hrm', 'diagnosisIssuedDate', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when Diagnosis Issued \
              // Date And Time input on HRM form is left blank
              valueMissing: gettext('hrm', 'diagnosisIssuedDateRequired', 'hrmFormError'),
              // Translators: Error message displayed when Diagnosis Issued \
              // Date And Time input on HRM form is out of range
              // Token(s) available: {{MIN}}, {{MAX}}
              range: gettext('hrm', 'diagnosisIssuedDateRange', 'hrmFormError'),
              // Translators: Error message displayed when value entered for \
              // Diagnosis Issued Date And Time input on HRM form is invalid
              badInput: gettext('hrm', 'diagnosisIssuedDateBadInput', 'hrmFormError')
            }
          },
          effectiveDate: {
            // Translators: Label for the Diagnosis Effective Date And Time \
            // input on HRM form
            label: gettext('hrm', 'diagnosisEffectiveDate', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when Diagnosis Effective \
              // Date And Time input on HRM form is left blank
              valueMissing: gettext('hrm', 'diagnosisEffectiveDateRequired', 'hrmFormError'),
              // Translators: Error message displayed when Diagnosis Effective \
              // Date And Time input on HRM form is out of range
              // Token(s) available: {{MIN}}, {{MAX}}
              range: gettext('hrm', 'diagnosisEffectiveDateRange', 'hrmFormError'),
              // Translators: Error message displayed when value entered for \
              // Diagnosis Effective Date And Time input on HRM form is invalid
              badInput: gettext('hrm', 'diagnosisEffectiveDateBadInput', 'hrmFormError')
            }
          }
        }
      }
    },
    notes: {
      // Translators: Short label for the HRM form's Notes fieldset group
      shortLabel: gettext('hrm', 'shortLabel', 'hrmFormFieldsetGroupNotes'),
      // Translators: Full label for the HRM form's Notes fieldset group
      fullLabel: gettext('hrm', 'fullLabel', 'hrmFormFieldsetGroupNotes'),
      fieldsetNotes: {
        formField: {
          notes: {
            // Translators: Label for the Notes input on HRM form
            label: gettext('hrm', 'notes', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when value entered in \
              // Notes input on HRM form exceeds the maximum length allowed
              // Translators: Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('hrm', 'notesTooLong', 'hrmFormError')
            }
          }
        }
      }
    },
    document: {
      // Translators: Short label for the HRM form's Documents fieldset group
      shortLabel: gettext('hrm', 'shortLabel', 'hrmFormFieldsetGroupDocuments'),
      // Translators: Full label for the HRM form's Documents fieldset group
      fullLabel: gettext('hrm', 'fullLabel', 'hrmFormFieldsetGroupDocuments'),
      fieldsetRecipients: {
        formField: {
          recipient: {
            // Translators: Label for the Recipients input on HRM form
            label: gettext('hrm', 'recipients', 'hrmFormInputLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Recipients input on HRM form
              // Translators: Token(s) available: {{count}}
              tooShort: ngettext('hrm', 'recipientsTooShort', 'hrmFormError')
            }
          }
        }
      }
    }
  },
  diagnosisCategoryLabelFormat: gettext('hrm', 'diagnosisCategoryLabelFormat', 'hrmForm'),
  messageNoNotesAdded: gettext('hrm', 'messageNoNotesAdded', 'hrmForm')
};

export default i18n;
