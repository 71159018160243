import { createSlice } from '@reduxjs/toolkit';
import { CollectionObjectId, CollectionObjectVersion, StoreStateCollectionObjects } from '@sigmail/app-state';
import { Map as ImmutableMap } from 'immutable';
import { signOutAction } from '../actions';
import { authFailure } from '../auth-slice';
import { setStateReducer } from './set-state-reducer';

export const INITIAL_STATE: StoreStateCollectionObjects = ImmutableMap<CollectionObjectId, CollectionObjectVersion>();

const collectionObjectsSlice = createSlice({
  name: 'collectionObjects',
  initialState: INITIAL_STATE,
  reducers: {
    setState: setStateReducer
  },
  extraReducers: (builder) => {
    builder.addCase(signOutAction, () => INITIAL_STATE);
    builder.addCase(authFailure, () => INITIAL_STATE);
  }
});

export const { setState } = collectionObjectsSlice.actions;
export const { reducer: collectionObjectsReducer } = collectionObjectsSlice;
