import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { PartialRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n } from '@sigmail/i18n';
import {
  KCCQPhysicalLimitationData,
  KCCQQualityOfLifeData,
  KCCQSocialLimitationData,
  KCCQSymptomFrequencyData
} from '@sigmail/objects';
import { FieldState } from 'final-form';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { EMPTY_PLAIN_OBJECT } from '../../../app-state/constants';
import { withTranslation } from '../../../i18n';
import { I18N_NS_HEALTH_DATA } from '../../../i18n/config/namespace-identifiers';
import healthDataI18n from '../../../i18n/health-data';
import * as MathUtils from '../../../utils/math';
import { ValidationErrorResult } from '../../shared/form/field-validator';
import { FieldProps, FormComponent, FormComponentProps, FormComponentState } from '../../shared/form/form.component';
import style from './cardiomyopathy-questionnaire.module.css';

const { cardiomyopathyQuestionnaire: i18n } = healthDataI18n.form;

export type FieldsetName = keyof typeof i18n;
export type FieldNamePhysicalLimitation = keyof typeof i18n.fieldsetPhysicalLimitation.formField;
export type FieldNameQualityOfLife = keyof typeof i18n.fieldsetQualityOfLife.formField;
export type FieldNameSocialLimitation = keyof typeof i18n.fieldsetSocialLimitation.formField;
export type FieldNameSymptomFrequency = keyof typeof i18n.fieldsetSymptomFrequency.formField;

export type FieldName =
  | FieldNamePhysicalLimitation
  | FieldNameQualityOfLife
  | FieldNameSocialLimitation
  | FieldNameSymptomFrequency;

export type FormValues = Record<FieldNamePhysicalLimitation, KCCQPhysicalLimitationData['codedValue']> &
  Record<FieldNameQualityOfLife, KCCQQualityOfLifeData['codedValue']> &
  Record<FieldNameSocialLimitation, KCCQSocialLimitationData['codedValue']> &
  Record<FieldNameSymptomFrequency, KCCQSymptomFrequencyData['codedValue']>;

export const FIELD_SET_LIST: ReadonlyArray<Readonly<[FieldsetName, ReadonlyArray<FieldName>]>> = [
  ['fieldsetPhysicalLimitation', ['shower', 'walk', 'jog']],
  ['fieldsetSymptomFrequency', ['swelling', 'fatigue', 'dyspnea', 'orthopnea']],
  ['fieldsetQualityOfLife', ['enjoymentLoss', 'chronicity']],
  ['fieldsetSocialLimitation', ['rec', 'household', 'social']]
];

const FIELD_PROPS: Readonly<Record<FieldName, FieldProps>> = FIELD_SET_LIST.reduce((props, [fieldset, fieldList]) => {
  return fieldList.reduce((_, fieldName) => {
    const options = (i18n[fieldset].formField as any)[fieldName].options;
    props[fieldName] = {
      config: {
        range: {
          min: options[0].codedValue,
          max: options[options.length - 1].codedValue
        }
      },
      label: (i18n[fieldset].formField as any)[fieldName].label
    };
    return props;
  }, props);
}, {} as Record<FieldName, FieldProps>);

export type CardiomyopathyQuestionnaireFormClassKey =
  | 'control'
  | 'controlLabel'
  | 'inputRoot'
  | `radio${'Group' | 'Icon' | 'Label'}`;

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<CardiomyopathyQuestionnaireFormClassKey, string> | undefined;
}

interface ComponentProps extends Props, WithTranslation {}
interface State extends FormComponentState<FieldName, FormValues> {}

const INITIAL_VALUES: Readonly<FormValues> = FIELD_SET_LIST.reduce(
  (value, [, fieldNameList]) =>
    fieldNameList.reduce((_, fieldName) => {
      value[fieldName] = 0 as any;
      return value;
    }, value),
  {} as FormValues
);

class CardiomyopathyQuestionnaireFormComponent extends FormComponent<
  FieldsetName,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes'
    );

    this.createForm({ initialValues: INITIAL_VALUES });

    this.scrollIntoView = this.scrollIntoView.bind(this);
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-kccq-${this.formIdSuffix}`;
  }

  /** @override */
  protected getFormSubscription(...args: any[]) {
    return { ...super.getFormSubscription(...args), hasValidationErrors: true };
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetName: FieldsetName) {
    const label = Utils.trimOrDefault(i18n[fieldsetName].label);
    return label.length > 0 ? this.props.t(label) : null;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldProps() {
    return EMPTY_PLAIN_OBJECT;
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n.valueMissing;
        }
      }
    }

    return Utils.isString(errorMessageI18n) ? t(errorMessageI18n) : error;
  }

  /** @override */
  protected renderForm(...args: any[]) {
    const formNode = super.renderForm(...args);

    const progress =
      100 *
      MathUtils.avg(
        FIELD_SET_LIST.flatMap(([, fieldNameList]) =>
          fieldNameList.map((fieldName) => Number(this.state[fieldName]?.value > 0))
        )
      );

    return (
      <React.Fragment>
        {progress !== 100 && <LinearProgress value={progress} variant="determinate" />}
        {formNode}
      </React.Fragment>
    );
  }

  /** @override */
  protected renderFieldset(fieldsetName: FieldsetName, ...args: any[]) {
    const index = FIELD_SET_LIST.findIndex(([fieldset]) => fieldset === fieldsetName);
    if (index > 0) {
      const [fieldName] = FIELD_SET_LIST[index - 1][1].slice(-1);
      if (Utils.isString(fieldName) === true && this.state[fieldName].value === INITIAL_VALUES[fieldName]) {
        // skip rendering until all questions from previous set have been
        // answered
        return null;
      }
    }
    return super.renderFieldset(fieldsetName, ...args);
  }

  /** @override */
  protected renderField(fieldName: FieldName) {
    let fieldsetIndex = -1;
    let fieldIndex = -1;

    for (const [, fieldNameList] of FIELD_SET_LIST) {
      ++fieldsetIndex;
      fieldIndex = fieldNameList.indexOf(fieldName);
      if (fieldIndex > -1) break;
    }

    if (fieldIndex === -1) return null;

    const { classes, disabled, t } = this.props;
    const inputState = this.state[fieldName];

    const [fieldsetName, fieldNameList] = FIELD_SET_LIST[fieldsetIndex];
    if (fieldIndex > 0) {
      const prevFieldName = fieldNameList[fieldIndex - 1];
      if (this.state[prevFieldName].value === INITIAL_VALUES[prevFieldName]) {
        // skip rendering until all of the previous questions from this set
        // have been answered
        return null;
      }
    }

    const error = inputState.error;

    const fieldNode = (
      <React.Fragment>
        <FormControl
          classes={{ root: classes!.control }}
          disabled={disabled}
          ref={
            (fieldsetIndex > 0 || fieldIndex > 0) && inputState.value === INITIAL_VALUES[fieldName]
              ? this.scrollIntoView
              : Utils.noop
          }
        >
          <FormLabel classes={{ root: classes!.controlLabel }} component="div">
            {t(FIELD_PROPS[fieldName].label as string)}
          </FormLabel>

          <RadioGroup
            classes={{ root: classes!.radioGroup }}
            onChange={(_, value) => {
              if (disabled !== true) {
                this.form.change(fieldName, Number(value) as any);
              }
            }}
            value={inputState.value}
          >
            {(i18n[fieldsetName].formField as any)[fieldName].options.map(
              ({ codedValue, label }: { codedValue: number; label: string }) => (
                <FormControlLabel
                  classes={{ label: classes!.radioLabel }}
                  control={<Radio classes={{ root: classes!.radioIcon }} color="primary" size="small" />}
                  key={codedValue}
                  label={t(label)}
                  value={codedValue}
                />
              )
            )}
          </RadioGroup>
        </FormControl>

        {Utils.isString(error) && <FormHelperText error={true}>{error}</FormHelperText>}
      </React.Fragment>
    );

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<any>) {
    let { error } = fieldState;

    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    this.setState({ areValidationErrorsVisible: false });
    return super.setFieldState(fieldName, { ...fieldState, error });
  }

  private scrollIntoView(element: HTMLElement | null): void {
    if (!Utils.isNil(element)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}

const ns = [I18N_NS_HEALTH_DATA];

export const CardiomyopathyQuestionnaireForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      control: style.radio,
      controlLabel: style['group-label'],
      inputRoot: style['form-input-group'],
      radioGroup: style.group,
      radioIcon: style.icon,
      radioLabel: style.label
    } as ComponentProps['classes']);

    return <CardiomyopathyQuestionnaireFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

CardiomyopathyQuestionnaireForm.displayName = 'CardiomyopathyQuestionnaireForm';
CardiomyopathyQuestionnaireForm.defaultProps = { className: style.form };
