import { InputBaseComponentProps } from '@material-ui/core';
import { Value } from '@material-ui/lab';
import MuiAutocomplete, { AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab/Autocomplete';
import { FieldState } from 'final-form';
import React from 'react';
import { MutableRef } from 'sigmail';
import { Field, FieldProps } from '.';
import { isArray, isNonArrayObjectLike } from './utils';

export interface AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'defaultValue' | 'innerRef' | 'options' | 'ref' | 'renderInput' | 'value'
  > {
  error?: boolean | undefined;
  helperText?: React.ReactNode;
  inputProps?: InputBaseComponentProps | undefined;
  inputState: FieldState<Value<T, Multiple, DisableClearable, FreeSolo>>;
  label?: FieldProps['label'];
  name?: string;
  onClickPopupIcon?: React.MouseEventHandler<HTMLElement> | undefined;
  options: ReadonlyArray<T>;
  readOnly?: boolean | undefined;
  renderInput?: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderInput'] | undefined;
  variant?: FieldProps['variant'];
}

const PREVENT_DEFAULT = (event: Pick<React.SyntheticEvent, 'preventDefault'>) => event.preventDefault();

const MUI_CLASS_NAME_POPUP_ICON = 'MuiAutocomplete-popupIndicator';

const AutocompleteFieldComponent = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: MutableRef<HTMLDivElement>
) => {
  const {
    error,
    inputProps,
    inputState,
    helperText,
    label,
    name: fieldName,
    onChange,
    onClickPopupIcon,
    options,
    placeholder,
    readOnly,
    variant,
    ...rootProps
  } = props;

  const handleChange = React.useCallback<NonNullable<typeof onChange>>(
    (event, value, reason, details) => {
      if (readOnly === true) return;

      if (typeof onChange === 'function') {
        onChange(event, value, reason, details);
        return;
      }

      inputState.change(value);
    },
    [inputState, onChange, readOnly]
  );

  const defaultInputRenderer = React.useCallback<NonNullable<typeof props['renderInput']>>(
    ({ inputProps: autocompleteInputProps, InputProps, ...params }) => {
      const inputFieldProps = { ...inputProps, ...autocompleteInputProps };

      if (
        typeof onClickPopupIcon === 'function' &&
        isNonArrayObjectLike<any>((InputProps?.endAdornment as any)?.props)
      ) {
        const {
          props: { children, ...endAdornmentProps }
        } = InputProps.endAdornment as any;

        if (isArray<any>(children)) {
          const index = children.findIndex((child) => child?.props.className === MUI_CLASS_NAME_POPUP_ICON);
          if (index > -1) {
            const popupIcon = children[index];

            const endAdornmentChildren = children.slice();
            endAdornmentChildren[index] = React.cloneElement(popupIcon, { onClick: onClickPopupIcon });

            const endAdornment = React.cloneElement(
              InputProps.endAdornment as React.ReactElement<any>,
              endAdornmentProps,
              endAdornmentChildren
            );

            InputProps = { ...InputProps, endAdornment };
          }
        }
      }

      return (
        <Field
          inputProps={inputFieldProps}
          inputState={inputState}
          label={label}
          placeholder={placeholder}
          name={fieldName}
          onChange={PREVENT_DEFAULT}
          variant={variant}
          {...params}
          InputProps={InputProps}
        />
      );
    },
    [fieldName, inputProps, inputState, label, onClickPopupIcon, placeholder, variant]
  );

  const autocompleteProps: MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> = {
    renderInput: defaultInputRenderer,
    onBlur: inputState.blur,
    onFocus: inputState.focus,
    options: options as Array<T>,
    ...rootProps,
    onChange: handleChange,
    value: inputState.value
  };

  return <MuiAutocomplete ref={ref} {...autocompleteProps} />;
};

export const AutocompleteField = React.forwardRef(AutocompleteFieldComponent) as <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo> & React.RefAttributes<HTMLDivElement>
) => React.ReactElement<AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>> | null;

(AutocompleteField as any).displayName = 'AutocompleteField';
(AutocompleteField as any).defaultProps = { disablePortal: true };
