import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Dialog.SendCaregiverAccountReachedLimit = {
  action: {
    accept: {
      // Translators: Label for the Accept action of Send Caregiver Account \
      // Reached Limit dialog
      label: gettext('account', 'accept', 'sendCaregiverAccountReachedLimitDialogActionLabel')
    }
  },
  // Translators: (HTML) Body for the Send Caregiver Account Reached Limit \
  // dialog
  body: gettext('account', 'body', 'sendCaregiverAccountReachedLimitDialog', 'safe-html'),
  // Translators: (HTML) Title for the Send Caregiver Account Reached Limit \
  // Dialog
  title: gettext('account', 'title', 'sendCaregiverAccountReachedLimitDialog', 'safe-html')
};

export default i18n;
