import { MemberRole, Utils } from '@sigmail/common';
import { AccountI18n } from '@sigmail/i18n';
import { EventLogRecord, ServerParamsEmailToken, SharedParamsEmailTokenUserRegistration } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import * as EmailTemplateParams from '../../../constants/email-template-params';
import { generateCredentialHash } from '../../../utils/generate-credential-hash';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { ActionInitParams } from '../base-action';
import { AUTH_STATE_SEND_MESSAGE } from '../constants/auth-state-identifier';
import { sendTemplatedEmailMessageAction } from '../email/send-templated-email-message-action';
import { newNotificationAction } from '../notifications/new-notification-action';

export interface AccountInvitationActionPayload {
  readonly notifyBy: AccountI18n.SendAccountInvitationNotifyBy;
  readonly role: MemberRole;
}

export interface AccountInvitationActionState extends AuthenticatedActionState {
  accessCode: string;
  credentialHash: string;
  dtServer: Date;
  eventLogRecordList: Array<EventLogRecord>;
  isAccessCodeRandom: boolean;
  serverParameters: ServerParamsEmailToken;
  sharedParameters: SharedParamsEmailTokenUserRegistration;
  token: string;
}

export abstract class BaseAccountInvitationAction<
  P extends AccountInvitationActionPayload = AccountInvitationActionPayload,
  S extends AccountInvitationActionState = AccountInvitationActionState,
  R = string
> extends AuthenticatedAction<P, S, R> {
  public constructor(params: ActionInitParams<P>) {
    super(params);

    this.state.eventLogRecordList = [];
  }

  protected generateSharedParameters(): void {
    this.logger.info('Generating shared parameters.');

    const { role: roleId } = this.payload;
    this.state.sharedParameters = this.generateUserRegistrationSharedParameters(roleId);

    this.logger.debug('sharedParameters =', this.state.sharedParameters);
  }

  protected generateEmailToken(): void {
    this.logger.info('Generating email token.');

    const { sharedParameters } = this.state;
    this.state.token = this.generateUserRegistrationEmailToken(sharedParameters);

    this.logger.debug('token =', this.state.token);
  }

  protected generateAccessCode(healthPlanJurisdiction?: string, healthPlanNumber?: string): void {
    this.logger.info('Generating access code.');

    const { role: roleId } = this.payload;
    [this.state.accessCode, this.state.isAccessCodeRandom] = this.generateUserRegistrationAccessCode(
      roleId,
      healthPlanJurisdiction,
      healthPlanNumber
    );

    this.logger.debug(`accessCode = ${this.state.accessCode}, isRandom = ${this.state.isAccessCodeRandom}`);
  }

  protected async generateServerParameters(): Promise<void> {
    this.logger.info('Generating server parameters.');

    const {
      sharedParameters: { salt: hexSalt },
      accessCode
    } = this.state;

    this.state.credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, { token: this.state.token });
    this.logger.debug('credentialHash =', this.state.credentialHash);

    const { passwordHash } = await Utils.generatePasswordHash(accessCode, hexSalt);
    this.logger.debug('passwordHash =', passwordHash);

    const verifier = Utils.srpGenerateVerifier(hexSalt, this.state.credentialHash, passwordHash);
    this.state.serverParameters = { verifier };

    this.logger.debug('serverParameters =', this.state.serverParameters);
  }

  protected sendInvitationEmail(
    role: string,
    emailAddress?: string,
    templateData?: Api.TemplatedEmailMessage['personalizations'][0]['dynamicTemplateData']
  ): Promise<void> {
    const { notifyBy } = this.payload;

    const notifyByEmail = notifyBy === 'both' || notifyBy === 'email';
    emailAddress = Utils.trimOrDefault(notifyByEmail && emailAddress);
    if (emailAddress.length === 0) return Promise.resolve();

    this.logger.info('Sending an account setup invitation email.');

    try {
      let templateId: Api.TemplatedEmailMessage['template_id'];
      if (Utils.isGuestRole(role)) {
        templateId = 'd-cfa7296ed3b24559adbb4aa1eee494ae';
      } else if (Utils.isNonGuestRole(role)) {
        if (Utils.isCaregiverRole(role)) {
          templateId = 'd-ybvh8n4baqtkd6n1pclvkjds58d9oful';
        } else {
          templateId = 'd-89ad01b26530462eac8bb344179abc47';
        }
      } else {
        this.logger.warn('Unable to determine account setup invitation email template ID.');
        return Promise.resolve();
      }

      const message: Api.TemplatedEmailMessage = {
        template_id: templateId,
        from: { name: 'noreply@sigmail.ca', email: 'noreply@sigmail.ca' },
        personalizations: [
          {
            to: [{ name: emailAddress, email: emailAddress }],
            dynamicTemplateData: {
              [EmailTemplateParams.RegistrationLink]: `${process.env.HOST_URL}?token=${this.state.token}`,
              ...templateData
            }
          }
        ]
      };

      return this.dispatch(sendTemplatedEmailMessageAction(message));
    } catch (error) {
      this.logger.warn('Error sending an account setup invitation email:', error);
      return Promise.resolve();
    }
  }

  protected async sendInvitationSMS(
    role: string,
    phoneNumber?: string,
    templateData?: Api.NewNotificationRequestData['notificationList'][0]['dynamicTemplateData']
  ): Promise<void> {
    const { notifyBy } = this.payload;
    const { dtServer, roleAuthClaim: authState } = this.state;

    const notifyBySMS = notifyBy === 'both' || notifyBy === 'sms';
    phoneNumber = Utils.trimOrDefault(notifyBySMS && phoneNumber).replaceAll(/\D/g, '');
    if (phoneNumber.length === 0) return;

    this.logger.info('Sending an account setup invitation sms.');

    try {
      const { ids: idRecord } = await this.dispatchFetchIdsByUsage({
        authState,
        state: AUTH_STATE_SEND_MESSAGE,
        ids: {
          ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_METADATA }]
        }
      });

      const [id] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA];

      let templateId: Api.NewNotificationRequestData['notificationList'][0]['templateId'];
      if (Utils.isGuestRole(role)) {
        templateId = 'd-cfa7296ed3b24559adbb4aa1eee494ae';
      } else if (Utils.isNonGuestRole(role)) {
        if (Utils.isCaregiverRole(role)) {
          templateId = 'd-ybvh8n4baqtkd6n1pclvkjds58d9oful';
        } else {
          templateId = 'd-89ad01b26530462eac8bb344179abc47';
        }
      }

      if (!Utils.isString(templateId)) {
        this.logger.warn('Unable to determine account setup invitation SMS template ID.');
        return;
      }

      return this.dispatch(
        newNotificationAction({
          notificationList: [
            {
              dynamicTemplateData: {
                [EmailTemplateParams.RegistrationLink]: `${process.env.HOST_URL}?token=${this.state.token}`,
                ...templateData
              },
              id,
              notifyAt: dtServer.toISOString(),
              phoneNumber,
              templateId
            }
          ]
        })
      );
    } catch (error) {
      this.logger.warn('Error sending an account setup invitation SMS:', error);
      return Promise.resolve();
    }
  }

  protected get credentialExpiry(): Date {
    const dtExpiry = new Date(this.state.dtServer.getTime());
    dtExpiry.setDate(dtExpiry.getDate() + 30); // 30 days
    return dtExpiry;
  }
}
