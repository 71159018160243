import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { ComposeMessageView } from '../../constants/action-context';
import dialogRecipientsRemoved from './dialog-recipients-removed';
import dialogMsgAlreadyRepliedTo from './dialog-message-already-replied-to';
import dialogReplyDisabledBySelf from './dialog-reply-disabled-by-self';
import dialogReplyDisabledBySender from './dialog-reply-disabled-by-sender';
import dialogReplyWindowClosed from './dialog-reply-window-closed';

const i18n: MessagingI18n.View.ComposeMessage = {
  action: {
    submit: {
      // Translators: Label for the Submit action in Compose Message view
      label: gettext('messaging', 'submit', 'composeMessageViewActionLabel'),
      context: ComposeMessageView
    },
    close: {
      // Translators: Label for the Close action in Compose Message view
      label: gettext('messaging', 'close', 'composeMessageViewActionLabel'),
      context: ComposeMessageView
    },
    attach: {
      // Translators: Label for the Attach Documents action in Compose \
      // Message view
      label: gettext('messaging', 'attach', 'composeMessageViewActionLabel'),
      context: ComposeMessageView
    },
    saveAsDraft: {
      // Translators: Label for the Save As Draft action in Compose Message view
      label: gettext('messaging', 'saveAsDraft', 'composeMessageViewActionLabel'),
      context: ComposeMessageView
    },
    discardDraft: {
      // Translators: Label for the Discard Draft action in Compose Message view
      label: gettext('messaging', 'discardDraft', 'composeMessageViewActionLabel'),
      context: ComposeMessageView
    }
  },
  alert: {
    initial: {
      // Translators: (HTML) Note displayed in New Message view when a brand \
      // new message is being composed and is marked as Billable.
      billable: gettext('messaging', 'initialBillable', 'composeMessageViewAlert', 'safe-html'),
      // Translators: (HTML) Note displayed in New Message view when a brand \
      // new message is being composed and is marked as Billable and \
      // Do Not Reply.
      billableDoNotReply: gettext('messaging', 'initialBillableDoNotReply', 'composeMessageViewAlert', 'safe-html'),
      // Translators: (HTML) Note displayed in New Message view when a brand \
      // new message is being composed and is marked as Billable and Private.
      billablePrivate: gettext('messaging', 'initialBillablePrivate', 'composeMessageViewAlert', 'safe-html'),
      // Translators: (HTML) Note displayed in New Message view when a brand \
      // new message is being composed and is marked as Confidential.
      // Translators: !!! DO NOT TRANSLATE !!!
      confidential: '',
      // Translators: (HTML) Note displayed in New Message view when a brand \
      // new message is being composed and is marked as Do Not Reply.
      doNotReply: gettext('messaging', 'initialDoNotReply', 'composeMessageViewAlert', 'safe-html'),
      // Translators: !!! DO NOT TRANSLATE !!!
      normal: '',
      // Translators: (HTML) Note displayed in New Message view when a brand \
      // new message is being composed and is marked as Personal.
      // Translators: !!! DO NOT TRANSLATE !!!
      personal: '',
      // Translators: (HTML) Note displayed in New Message view when a brand \
      // new message is being composed and is marked as Private.
      private: gettext('messaging', 'initialPrivate', 'composeMessageViewAlert', 'safe-html')
    },
    reply: {
      // Translators: (HTML) Note displayed in New Message view when message \
      // being composed is a reply and is marked as Confidential.
      // Translators: !!! DO NOT TRANSLATE !!!
      confidential: '',
      // Translators: !!! DO NOT TRANSLATE !!!
      normal: '',
      // Translators: (HTML) Note displayed in New Message view when message \
      // being composed is a reply and is marked as Personal.
      // Translators: !!! DO NOT TRANSLATE !!!
      personal: '',
      private: {
        // Translators: (HTML) Note displayed to users of role GUEST in New \
        // Message view when message being composed is a reply and is marked \
        // as Private.
        guest: gettext('messaging', 'replyPrivateGuest', 'composeMessageViewAlert', 'safe-html'),
        // Translators: (HTML) Note displayed to users of role NON-GUEST in \
        // New Message view when message being composed is a reply and is \
        // marked as Private.
        nonGuest: gettext('messaging', 'replyPrivateNonGuest', 'composeMessageViewAlert', 'safe-html')
      }
    }
  },
  dialog: {
    msgAlreadyRepliedTo: dialogMsgAlreadyRepliedTo,
    recipientsRemoved: dialogRecipientsRemoved,
    replyDisabledBySelf: dialogReplyDisabledBySelf,
    replyDisabledBySender: dialogReplyDisabledBySender,
    replyWindowClosed: dialogReplyWindowClosed
  },
  ermFolder: {
    // Translators: Label for the select EMR Folder prefix action in Compose \
    //Message view
    prefix: gettext('messaging', 'emrFolderPrefix', 'composeMessageViewActionLabel'),
    suffix: {
      // Translators: Label for the select EMR Folder suffix action in Compose \
      //Message view
      label: gettext('messaging', 'emrFolderSuffix', 'composeMessageViewActionLabel'),
      context: ComposeMessageView
    }
  },
  // Translators: Title for the Compose Message view
  title: gettext('messaging', 'title', 'composeMessageView')
};

export default i18n;
