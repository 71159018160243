/* eslint-disable no-control-regex */

import { Nullable, Utils } from '@sigmail/common';
import { Encoder } from '@sigmail/crypto';

export const sanitizeFilename = (
  filename?: unknown,
  replacement?: Nullable<string>,
  maxLength?: Nullable<number>
): string => {
  replacement = Utils.stringOrDefault(replacement);

  const sanitizedFilename = Utils.trimOrDefault(filename)
    // illegal characters
    .replace(/[/?<>\\:*|"]/g, replacement)
    // control characters
    .replace(/[\x00-\x1f\x80-\x9f]/g, replacement)
    // reserved characters (UNIX-based systems)
    .replace(/^\.+$/, replacement)
    // reserved characters (Window-based systems)
    .replace(/^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i, replacement)
    // trailing characters
    .replace(/[.\s]+$/u, replacement);

  const utf8 = Encoder.UTF8.encode(sanitizedFilename);
  return Encoder.UTF8.decode(utf8.slice(0, Math.max(1, Math.min(Utils.numberOrDefault(maxLength, 255), 255))));
};
