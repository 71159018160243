import { combineReducers } from '@reduxjs/toolkit';
import { activeGroupReducer } from './active-group-slice';
import { authStateReducer } from './auth-slice';
import { caregiverReducer } from './caregiver-slice';
import { collectionObjectsReducer } from './collection-objects-slice';
import { dataObjectsReducer } from './data-objects-slice';
import { reminderNotificationReducer } from './reminder-notification-slice';
import { sessionReducer } from './session-slice';
import { userObjectReducer } from './user-objects-slice';

export const rootReducer = combineReducers({
  activeGroup: activeGroupReducer,
  auth: authStateReducer,
  caregiver: caregiverReducer,
  collectionObjects: collectionObjectsReducer,
  dataObjects: dataObjectsReducer,
  reminderNotification: reminderNotificationReducer,
  sessionId: sessionReducer,
  userObjects: userObjectReducer
});

export type RootState = ReturnType<typeof rootReducer>;
