/* eslint-disable no-redeclare */

import { Constants, GenderIdentity, Utils } from '@sigmail/common';
import { CollectionObjectPatientRecordListValue } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { EMPTY_PLAIN_OBJECT } from '../../app-state/constants';

type CollectionPatientRecord = CollectionObjectPatientRecordListValue['list'][0];

const isCollectionPatientRecordLike = (record: unknown): record is CollectionPatientRecord =>
  Utils.isNonArrayObjectLike<CollectionPatientRecord>(record) && Utils.isInteger(record.recordId);

const isOscarEMRPatientRecordLike = (record: unknown): record is Api.OscarEMRPatientRecord =>
  Utils.isNonArrayObjectLike<Api.OscarEMRPatientRecord>(record) && Utils.isInteger(record.demographicNo);

export function toAccuroEMRPatientRecord(
  record: CollectionPatientRecord,
  uuid?: Api.AccuroEMRPatientRecord['uuid']
): Api.AccuroEMRPatientRecord;

export function toAccuroEMRPatientRecord(
  record: Api.OscarEMRPatientRecord,
  uuid?: Api.AccuroEMRPatientRecord['uuid']
): Api.AccuroEMRPatientRecord;

export function toAccuroEMRPatientRecord(
  record: unknown,
  uuid?: Api.AccuroEMRPatientRecord['uuid']
): Api.AccuroEMRPatientRecord {
  if (isCollectionPatientRecordLike(record)) {
    let gender = Utils.trimOrDefault(record.gender).toLowerCase() as GenderIdentity;
    if (gender !== Constants.Gender.Male && gender !== Constants.Gender.Female) {
      gender = gender.length === 0 ? Constants.Gender.Unknown : Constants.Gender.Other;
    }

    return {
      demographics: {
        birthday: record.dateOfBirth,
        email: { address: record.email },
        firstName: record.firstName,
        gender,
        healthCard: {
          location: Constants.HealthPlanJurisdiction.Ontario,
          phn: record.hcNumber
        },
        middleName: record.middleName,
        lastName: record.lastName
      },
      patientId: record.recordId,
      uuid
    };
  }

  if (isOscarEMRPatientRecordLike(record)) {
    let gender = Utils.trimOrDefault(record.sexDesc).toLowerCase() as GenderIdentity;
    if (gender !== Constants.Gender.Male && gender !== Constants.Gender.Female) {
      gender = gender.length === 0 ? Constants.Gender.Unknown : Constants.Gender.Other;
    }

    return {
      demographics: {
        addresses: [
          {
            city: record.address?.city,
            location: record.address?.province,
            postalZip: record.address?.postal,
            street: record.address?.address
          }
        ],
        birthday: record.dob,
        email: { address: record.email },
        firstName: record.firstName,
        gender,
        healthCard: {
          location: record.hcType,
          phn: record.hin
        },
        lastName: record.lastName,
        phones: [
          {
            contactType: 'HomePhone',
            number: record.phone
          }
        ]
      },
      patientId: record.demographicNo,
      uuid
    };
  }

  return EMPTY_PLAIN_OBJECT;
}
