import { BASE_OPTION_REFINERS } from '@fullcalendar/react';
import { Utils } from '@sigmail/common';
import { getLogger } from '@sigmail/logging';
import { enablePatches as immerEnablePatches } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Cache as CollectionObjectCacheImpl, CollectionObjectCache } from './app-state/collection-objects-slice/cache';
import { Cache as DataObjectCacheImpl, DataObjectCache } from './app-state/data-objects-slice/cache';
import { Cache as UserObjectCacheImpl, UserObjectCache } from './app-state/user-objects-slice/cache';
import './i18n';
import './index.css';

immerEnablePatches();

getLogger('BaseHttpService').setLevel('WARN');
getLogger('HttpService').setLevel('WARN');
getLogger('ApiService').setLevel('WARN');
getLogger('Action').setLevel('WARN');
getLogger('Reducer').setLevel('WARN');
getLogger('Util').setLevel('WARN');
getLogger('Migration').setLevel('WARN');

declare const window: any;
declare const module: any;

(BASE_OPTION_REFINERS as any).schedulerLicenseKey = String;

//
// Disable React Devtools in Production
//
if (process.env.REACT_APP_ENV === 'production') {
  const REACT_DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;
  if (typeof REACT_DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(REACT_DEV_TOOLS)) {
      REACT_DEV_TOOLS[key] = typeof value === 'function' ? Utils.noop : null;
    }
  }
}

//
// Enable some debugging helper methods in non-prod environments
//
if (process.env.REACT_APP_ENV !== 'production') {
  window.__DEV_SIGMAIL__ = {
    dumpCollectionObjectCache: (CollectionObjectCache as CollectionObjectCacheImpl).dumpToConsoleLog.bind(
      CollectionObjectCache
    ),
    dumpDataObjectCache: (DataObjectCache as DataObjectCacheImpl).dumpToConsoleLog.bind(DataObjectCache),
    dumpUserObjectCache: (UserObjectCache as UserObjectCacheImpl).dumpToConsoleLog.bind(UserObjectCache)
  };
}

function renderApp() {
  const { App } = require('./app/app.component');
  ReactDOM.render(
    <React.Suspense fallback={null}>
      <App />
    </React.Suspense>,
    document.getElementById('root')
  );
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/app.component', renderApp);
}

renderApp();
