import { GroupsI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';

const i18n: GroupsI18n.Dialog.TransferUsersToGroup = {
  // Translators: (HTML) Title for the Transfer Users To Group dialog
  title: gettext('groups', 'title', 'transferUsersToGroupDialog', 'safe-html'),
  // Translators: (HTML) Body for the Transfer Users To Group dialog
  body: ngettext('groups', 'body', 'transferUsersToGroupDialog', 'safe-html'),
  // Translators: (HTML) A note explaining the Transfer Users To Group
  // functionality
  // Translators: Token(s) available: {{GROUP}}
  note: ngettext('groups', 'note', 'transferUsersToGroupDialog', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Transfer Users To Group \
      // dialog
      label: gettext('groups', 'reject', 'transferUsersToGroupDialogActionLabel')
    },
    accept: {
      // Translators: Label for the Submit action of Transfer Users To Group \
      // dialog
      label: gettext('groups', 'accept', 'transferUsersToGroupDialogActionLabel')
    },
    dismiss: {
      // Translators: Label for the Close action of Transfer Users To Group \
      // dialog
      label: gettext('groups', 'dismiss', 'transferUsersToGroupDialogActionLabel')
    }
  }
};

export default i18n;
