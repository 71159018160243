import { Utils, Writeable } from '@sigmail/common';
import {
  CarePlanArrayListSectionItem,
  CarePlanMedicationCoordination,
  CarePlanPersonalInfo,
  CarePlanRecentHospitalVisit,
  CarePlanSectionIdAllergyList,
  CarePlanSectionIdCareTeam,
  CarePlanSectionIdGoalList,
  CarePlanSectionIdHealthAssessmentList,
  CarePlanSectionIdHealthIssueList,
  CarePlanSectionIdMedicationCoordination,
  CarePlanSectionIdMedicationList,
  CarePlanSectionIdPersonalInfo,
  CarePlanSectionIdRecentHospitalVisit,
  CarePlanSectionIdSubject,
  CarePlanSectionIdSupportingInfo,
  CarePlanSectionWithoutChangeset,
  CarePlanSubject,
  CarePlanSupportingInfo
} from '@sigmail/objects';
import { EMPTY_ARRAY } from '../../app-state/constants';
import { FormValues } from '../../app/shared/form/edit-care-plan/types';
import { toCarePlanDate } from './to-care-plan-date';

type CarePlanAllergy = CarePlanArrayListSectionItem<CarePlanSectionIdAllergyList>;
type CarePlanGoal = CarePlanArrayListSectionItem<CarePlanSectionIdGoalList>;
type CarePlanHealthAssessment = CarePlanArrayListSectionItem<CarePlanSectionIdHealthAssessmentList>;
type CarePlanHealthIssue = CarePlanArrayListSectionItem<CarePlanSectionIdHealthIssueList>;
type CarePlanMedication = CarePlanArrayListSectionItem<CarePlanSectionIdMedicationList>;

type CarePlanSectionMedicationCoordination = Writeable<
  Extract<CarePlanSectionWithoutChangeset, { sectionId: CarePlanSectionIdMedicationCoordination }>
>;

type CarePlanSectionPersonalInfo = Writeable<
  Extract<CarePlanSectionWithoutChangeset, { sectionId: CarePlanSectionIdPersonalInfo }>
>;

type CarePlanSectionRecentHospitalVisit = Writeable<
  Extract<CarePlanSectionWithoutChangeset, { sectionId: CarePlanSectionIdRecentHospitalVisit }>
>;

type CarePlanSectionSubject = Writeable<
  Extract<CarePlanSectionWithoutChangeset, { sectionId: CarePlanSectionIdSubject }>
>;

type CarePlanSectionSupportingInfo = Writeable<
  Extract<CarePlanSectionWithoutChangeset, { sectionId: CarePlanSectionIdSupportingInfo }>
>;

type CareTeamMember = CarePlanArrayListSectionItem<CarePlanSectionIdCareTeam>;

function fromAllergyList(value: FormValues['allergyList']): Array<CarePlanAllergy> {
  return value.map<CarePlanAllergy>(({ allergyIdentifier, allergySymptoms, deleted }) => ({
    deleted,
    identifier: nonEmptyStringOrUndefined(allergyIdentifier),
    symptoms: nonEmptyStringOrUndefined(allergySymptoms)
  }));
}

function fromCareTeam(value: FormValues['careTeam']): Array<CareTeamMember> {
  return value.map<CareTeamMember>(
    ({ deleted, memberContact, memberFirstName, memberLastName, memberOrganization, memberRole }) => ({
      contact: nonEmptyStringOrUndefined(memberContact),
      deleted,
      firstName: nonEmptyStringOrUndefined(memberFirstName),
      lastName: nonEmptyStringOrUndefined(memberLastName),
      organization: nonEmptyStringOrUndefined(memberOrganization),
      role: nonEmptyStringOrUndefined(memberRole)
    })
  );
}

export function toCarePlanSectionList(formValues: FormValues): Array<CarePlanSectionWithoutChangeset> {
  let allergyList: Array<CarePlanAllergy> = EMPTY_ARRAY;
  let careTeam: Array<CareTeamMember> = EMPTY_ARRAY;
  let goalList: Array<CarePlanGoal> = EMPTY_ARRAY;
  let healthAssessmentList: Array<CarePlanHealthAssessment> = EMPTY_ARRAY;
  let healthIssueList: Array<CarePlanHealthIssue> = EMPTY_ARRAY;
  let medicationCoordination: CarePlanSectionMedicationCoordination = { sectionId: 'medicationCoordination' };
  let medicationList: Array<CarePlanMedication> = EMPTY_ARRAY;
  let personalInfo: CarePlanSectionPersonalInfo = { sectionId: 'personalInfo' };
  let recentHospitalVisit: CarePlanSectionRecentHospitalVisit = { sectionId: 'recentHospitalVisit' };
  let subject: CarePlanSectionSubject = { sectionId: 'subject' };
  let supportingInfo: CarePlanSectionSupportingInfo = { sectionId: 'supportingInfo' };

  for (const key of Object.keys(formValues) as Array<keyof typeof formValues>) {
    if (Utils.startsWith(key, 'plan')) continue;
    const { [key]: value } = formValues;

    if (key === 'allergyList') {
      allergyList = fromAllergyList(value as typeof formValues.allergyList);
    } else if (key === 'careTeam') {
      careTeam = fromCareTeam(value as typeof formValues.careTeam);
    } else if (key === 'goalList') {
      goalList = fromGoalList(value as typeof formValues.goalList);
    } else if (key === 'healthAssessmentList') {
      healthAssessmentList = fromHealthAssessmentList(value as typeof formValues.healthAssessmentList);
    } else if (key === 'healthIssueList') {
      healthIssueList = fromHealthIssueList(value as typeof formValues.healthIssueList);
    } else if (key === 'medicationList') {
      medicationList = fromMedicationList(value as typeof formValues.medicationList);
    } else if (Utils.startsWith(key, 'about')) {
      const sectionKey = Utils.camelCase(key.slice(5)) as keyof CarePlanPersonalInfo;
      personalInfo[sectionKey] = value as CarePlanPersonalInfo[typeof sectionKey];
    } else if (Utils.startsWith(key, 'med')) {
      const sectionKey = Utils.camelCase(key.slice(3)) as keyof CarePlanMedicationCoordination;
      medicationCoordination[sectionKey] = value as CarePlanMedicationCoordination[typeof sectionKey];
    } else if (Utils.startsWith(key, 'visit')) {
      const sectionKey = Utils.camelCase(key.slice(5)) as keyof CarePlanRecentHospitalVisit;
      if (sectionKey === 'date' || sectionKey === 'discharge') {
        if (value !== null) {
          recentHospitalVisit[sectionKey] = toCarePlanDate(value as Date);
        }
      } else {
        recentHospitalVisit[sectionKey] = value as CarePlanRecentHospitalVisit[typeof sectionKey];
      }
    } else if (key === 'healthConcern' || key === 'priority') {
      supportingInfo[key] = value as CarePlanSupportingInfo[typeof key];
    } else {
      if (key === 'birthDate') {
        if (value !== null) {
          subject[key] = toCarePlanDate(value as Date);
        }
      } else {
        subject[key] = value as NonNullable<CarePlanSubject[typeof key]>;
      }
    }
  }
  return [
    subject,
    supportingInfo,
    { sectionId: 'careTeam', list: careTeam },
    { sectionId: 'healthIssueList', list: healthIssueList },
    personalInfo,
    { sectionId: 'goalList', list: goalList },
    medicationCoordination,
    { sectionId: 'allergyList', list: allergyList },
    { sectionId: 'medicationList', list: medicationList },
    { sectionId: 'healthAssessmentList', list: healthAssessmentList },
    recentHospitalVisit
  ];
}

function fromGoalList(value: FormValues['goalList']): Array<CarePlanGoal> {
  return value.map<CarePlanGoal>(
    ({ deleted, goalActionPlan, goalCoordinator, goalDate, goalDetails, goalIdentifier }) => ({
      actionPlan: nonEmptyStringOrUndefined(goalActionPlan),
      coordinator: nonEmptyStringOrUndefined(goalCoordinator),
      date: toCarePlanDate(goalDate),
      deleted,
      details: nonEmptyStringOrUndefined(goalDetails),
      identifier: nonEmptyStringOrUndefined(goalIdentifier)
    })
  );
}

function fromHealthAssessmentList(value: FormValues['healthAssessmentList']): Array<CarePlanHealthAssessment> {
  return value.map<CarePlanHealthAssessment>(
    ({ deleted, healthAssessmentDate, healthAssessmentIdentifier, healthAssessmentNotes }) => ({
      date: toCarePlanDate(healthAssessmentDate),
      deleted,
      identifier: nonEmptyStringOrUndefined(healthAssessmentIdentifier),
      notes: nonEmptyStringOrUndefined(healthAssessmentNotes)
    })
  );
}

function fromHealthIssueList(value: FormValues['healthIssueList']): Array<CarePlanHealthIssue> {
  return value.map<CarePlanHealthIssue>(({ deleted, healthIssueDetails, healthIssueIdentifier }) => ({
    details: nonEmptyStringOrUndefined(healthIssueDetails),
    deleted,
    identifier: nonEmptyStringOrUndefined(healthIssueIdentifier)
  }));
}

function fromMedicationList(value: FormValues['medicationList']): Array<CarePlanMedication> {
  return value.map<CarePlanMedication>(
    ({ deleted, drugDose, drugFrequency, drugName, drugNotes, drugPrescriber, drugReason, drugStart }) => ({
      deleted,
      dose: nonEmptyStringOrUndefined(drugDose),
      frequency: nonEmptyStringOrUndefined(drugFrequency),
      name: nonEmptyStringOrUndefined(drugName),
      notes: nonEmptyStringOrUndefined(drugNotes),
      prescriber: nonEmptyStringOrUndefined(drugPrescriber),
      reason: nonEmptyStringOrUndefined(drugReason),
      start: toCarePlanDate(drugStart)
    })
  );
}

function nonEmptyStringOrUndefined(value: unknown): string | undefined {
  return Utils.isString(value) && value.length > 0 ? value : undefined;
}
