import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import { Constants, MessagingException, SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectMsgFolder,
  DataObjectMsgFolderExt,
  DataObjectMsgFolderExtValue,
  DataObjectMsgFolderValue,
  DataObjectMsgMetadata,
  EventLogRecord,
  FolderItem,
  IDataObject,
  MessageFolderItemCount,
  MessageFolderListItem
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { Set as ImmutableSet } from 'immutable';
import { AppThunk } from '../..';
import { MessageFlags } from '../../../app/messaging/utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { DataObjectCache } from '../../data-objects-slice/cache';
import * as DataObjectSelectors from '../../selectors/data-object';
import * as GroupObjectSelectors from '../../selectors/group-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { MAX_MESSAGE_FOLDER_ITEM_COUNT } from '../constants';
import { AUTH_STATE_LOG_EVENT, AUTH_STATE_MOVE_MESSAGE } from '../constants/auth-state-identifier';
import { logEventAction, Payload as LogEventActionPayload } from '../log-event-action';
import { createMessageFolderExtensionAction } from './create-message-folder-extension-action';
import { updateMessageFolderAction } from './update-msg-folder-action';

interface Payload extends MessagingActionPayload.MoveToArchivedMessages {}

interface State extends AuthenticatedActionState {
  archivedMsgFolder: FolderItem;
  batchUpdateClaims: Array<string>;
  dtServer: Date;
  logRecordList: Array<EventLogRecord>;
  msgFolderItemList: Array<MessageFolderListItem>;
  msgMetadataIdSet: ImmutableSet<SigmailObjectId>;
  requestBody: BatchUpdateRequestBuilder;
  sourceFolderId: SigmailObjectId;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

class MoveToArchivedMessagesAction extends AuthenticatedAction<Payload, State> {
  protected async onExecute() {
    const { folderKey, parentFolderKey, msgMetadataId } = this.payload;

    if (
      folderKey === Constants.MessageSubFolderKey.Archived ||
      (folderKey !== Constants.MessageFolderKey.Inbox &&
        folderKey !== Constants.MessageFolderKey.GroupInbox &&
        parentFolderKey !== Constants.MessageFolderKey.Inbox &&
        parentFolderKey !== Constants.MessageFolderKey.GroupInbox)
    ) {
      throw new MessagingException(
        Constants.Error.E_MESSAGING_FAIL,
        `Operation is not supported for this folder. (folderKey=${folderKey}, parentFolderKey=${parentFolderKey})`
      );
    }

    // get rid of duplicate metadata IDs (if any)
    this.state.msgMetadataIdSet = ImmutableSet(Utils.isArray<SigmailObjectId>(msgMetadataId) ? msgMetadataId : [msgMetadataId]);
    if (this.state.msgMetadataIdSet.isEmpty()) {
      this.logger.info('No items to move; call ignored.');
      return;
    }

    // make sure all of the metadata IDs supplied are valid
    const firstInvalidId = this.state.msgMetadataIdSet.find((metadataId) => !DataObjectMsgMetadata.isValidId(metadataId));
    if (!Utils.isUndefined(firstInvalidId)) {
      throw new MessagingException(
        Constants.Error.E_MESSAGING_FAIL_MSG_METADATA_ID,
        `Message metadata ID <${firstInvalidId}> is not valid.`
      );
    }

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.generateRequestBody();

        const query: Api.EnterStateRequestData = {
          authState: this.state.roleAuthClaim,
          state: AUTH_STATE_MOVE_MESSAGE
        };

        const { authState: batchUpdateAuthState } = await this.dispatchEnterState(query);
        const { batchUpdateClaims: claims, requestBody, successPayload } = this.state;
        await this.dispatchBatchUpdateData({ authState: batchUpdateAuthState, claims, ...requestBody.build() });

        try {
          await this.dispatchBatchQueryDataSuccess(successPayload);
        } catch (error) {
          this.logger.warn('Error manually updating app state:', error);
          /* ignore */
        }

        try {
          await this.dispatch(createMessageFolderExtensionAction({ folderKey, parentFolderKey }));
        } catch (error) {
          this.logger.warn('Error creating message folder extension:', error);
          /* ignore */
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; batch update operation will be retried.');
      }
    }
  }

  private async generateRequestBody(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;

    this.state.batchUpdateClaims = [];
    this.state.dtServer = await this.dispatchFetchServerDateAndTime();
    this.state.logRecordList = [];
    this.state.msgFolderItemList = [];

    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };

    await this.dispatch(
      updateMessageFolderAction({
        folderKey,
        parentFolderKey,
        requestBody: this.state.requestBody,
        successPayload: this.state.successPayload,
        applyUpdate: this.applySourceMsgFolderUpdate.bind(this)
      })
    );

    await this.addUpdateOperationForArchivedMsgFolder(); // 251/252s
    await this.addInsertOperationForUserEventLog(); // 425
  }

  private applySourceMsgFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    itemCount: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { dtServer, msgFolderItemList, msgMetadataIdSet, logRecordList } = this.state;

    if (meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER) {
      const { folderKey, parentFolderKey } = this.payload;

      let archivedMsgFolder: FolderItem | undefined;
      if (folderKey === Constants.MessageFolderKey.Inbox || parentFolderKey === Constants.MessageFolderKey.Inbox) {
        const msgFolderMap = UserObjectSelectors.messageFolderMapSelector(this.getRootState())();
        archivedMsgFolder = msgFolderMap[Constants.MessageFolderKey.Inbox]?.children?.archived;
      } else if (folderKey === Constants.MessageFolderKey.GroupInbox || parentFolderKey === Constants.MessageFolderKey.GroupInbox) {
        const msgFolderMap = GroupObjectSelectors.messageFolderMapSelector(this.getRootState())();
        archivedMsgFolder = msgFolderMap[Constants.MessageFolderKey.GroupInbox]?.children?.archived;
      }

      if (!DataObjectMsgFolder.isValidId(archivedMsgFolder?.id)) {
        throw new MessagingException(
          Constants.Error.E_MESSAGING_FAIL_FOLDER_ID,
          'Archived message folder ID is either missing or invalid.'
        );
      }

      this.state.archivedMsgFolder = archivedMsgFolder!;
      this.state.sourceFolderId = meta.folderOrExtId;
    }

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    const idsFound: SigmailObjectId[] = [];
    for (const msgMetadataId of msgMetadataIdSet) {
      this.logger.info(`Locating item with message metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);

      const index = folderData.findIndex(({ header }) => header === msgMetadataId);
      if (index === -1) continue;
      idsFound.push(msgMetadataId);

      const message = folderData[index];
      const { isMarkedAsRead, isImportant, hasReminder, isBillable, isMessageFormReferral } = MessageFlags(message);

      const { all, important, reminder, billable, referral } = itemCount;
      --all.total;
      all.unread -= +!isMarkedAsRead;
      important.total -= +isImportant;
      important.unread -= +(isImportant && !isMarkedAsRead);
      reminder.total -= +hasReminder;
      reminder.unread -= +(hasReminder && !isMarkedAsRead);
      billable.total -= +isBillable;
      billable.unread -= +(isBillable && !isMarkedAsRead);
      referral.total -= +isMessageFormReferral;
      referral.unread -= +(isMessageFormReferral && !isMarkedAsRead);

      msgFolderItemList.push(...folderData.splice(index, 1));
      result.updated = true;

      logRecordList.push(
        this.newEventLogRecordValue(
          dtServer,
          Constants.EventLogCode.MessageMoved,
          message,
          this.state.archivedMsgFolder.id,
          this.state.sourceFolderId
        )
      );
    }

    this.state.msgMetadataIdSet = msgMetadataIdSet.subtract(idsFound);

    result.done = msgMetadataIdSet.isEmpty();
    return result;
  }

  private async addUpdateOperationForArchivedMsgFolder(): Promise<void> {
    if (!Utils.isNonEmptyArray(this.state.msgFolderItemList)) {
      return;
    }

    this.logger.info('Adding an update operation to request body for Archived message folder.');

    const { archivedMsgFolder, roleAuthClaim: authState, msgFolderItemList, requestBody, successPayload } = this.state;

    let msgFolderItem: FolderItem | undefined = archivedMsgFolder;
    this.logger.info(`Fetching data for message folder. (ID=${msgFolderItem.id})`);
    await this.dispatchFetchObjects({ authState, dataObjects: { ids: [msgFolderItem.id] } });

    let dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
    const baseFolderObject = dataObjectByIdSelector<DataObjectMsgFolderValue>(msgFolderItem.id);
    const baseFolder = DataObjectCache.getValue(baseFolderObject);
    if (Utils.isNil(baseFolderObject) || Utils.isNil(baseFolder)) {
      throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_DATA_INVALID);
    } else if (Utils.isNotNil(baseFolder.next)) {
      msgFolderItem = { id: baseFolder.next, type: process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT };
    } else {
      msgFolderItem = undefined;
    }

    //
    // [1] build a collection of all message items i.e. the base folder and all
    //     of it's extensions
    //
    msgFolderItemList.push(...baseFolder.data);
    const msgFolderExtObjectList: Array<[IDataObject<DataObjectMsgFolderExtValue>, DataObjectMsgFolderExtValue]> = [];
    while (Utils.isNotNil(msgFolderItem)) {
      this.logger.info(`Fetching data for message folder extension. (ID=${msgFolderItem.id})`);
      await this.dispatchFetchObjects({ authState, dataObjects: { ids: [msgFolderItem.id] } });

      dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
      const folderExtObject = dataObjectByIdSelector<DataObjectMsgFolderExtValue>(msgFolderItem.id);
      const folderExt = DataObjectCache.getValue(folderExtObject);
      if (Utils.isNil(folderExtObject) || Utils.isNil(folderExt)) {
        throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_DATA_INVALID);
      }

      msgFolderExtObjectList.push([folderExtObject, folderExt]);
      msgFolderItemList.push(...folderExt.data);

      if (DataObjectMsgFolderExt.isValidId(folderExt.next)) {
        msgFolderItem = { id: folderExt.next, type: process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT };
      } else {
        msgFolderItem = undefined;
      }
    }
    // [/1]

    //
    // [2] sort messages in descending order
    //
    msgFolderItemList.sort(({ timestamp: timestamp1 }, { timestamp: timestamp2 }) => {
      const ts1 = Utils.isInteger(timestamp1) ? timestamp1 : timestamp1.createdAt!;
      const ts2 = Utils.isInteger(timestamp2) ? timestamp2 : timestamp2.createdAt!;
      return ts1 === ts2 ? 0 : ts1 < ts2 ? 1 : -1;
    });
    // [/2]

    //
    // [3] recalculate counts
    //
    const updatedItemCount: MessageFolderItemCount = {
      all: { total: msgFolderItemList.length, unread: 0 },
      billable: { total: 0, unread: 0 },
      important: { total: 0, unread: 0 },
      reminder: { total: 0, unread: 0 },
      referral: { total: 0, unread: 0 }
    };

    Utils.transform(
      msgFolderItemList,
      (count, message) => {
        const { isMarkedAsRead, isImportant, hasReminder, isBillable, isMessageFormReferral } = MessageFlags(message);
        count.all.unread += +!isMarkedAsRead;
        count.important.total += +isImportant;
        count.important.unread += +(isImportant && !isMarkedAsRead);
        count.reminder.total += +hasReminder;
        count.reminder.unread += +(hasReminder && !isMarkedAsRead);
        count.billable.total += +isBillable;
        count.billable.unread += +(isBillable && !isMarkedAsRead);
        count.referral.total += +isMessageFormReferral;
        count.referral.unread += +(isMessageFormReferral && !isMarkedAsRead);
      },
      updatedItemCount
    );
    // [/3]

    //
    // [4] iterate through extension folders in reverse order and update their
    // values by taking a chunk of items (@see MAX_MESSAGE_FOLDER_ITEM_COUNT)
    // from the end of msgFolderListItem array and populating those as
    // extension folder's data
    //
    for (let index = msgFolderExtObjectList.length - 1; index >= 0; index--) {
      const [obj, value] = msgFolderExtObjectList[index];

      const updatedValue: DataObjectMsgFolderExtValue = {
        ...value,
        data: msgFolderItemList.splice(-MAX_MESSAGE_FOLDER_ITEM_COUNT)
      };

      const updatedObject = await obj.updateValue(updatedValue);
      requestBody.update(updatedObject);

      successPayload.request.dataObjects!.ids.push(obj.id);
      successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
    }
    // [/4]

    //
    // [5] any left over data will be populated in the base folder
    //
    const updatedValueBaseFolder: DataObjectMsgFolderValue = {
      ...baseFolder,
      data: msgFolderItemList,
      itemCount: updatedItemCount
    };

    const updatedObjectBaseFolder = await baseFolderObject.updateValue(updatedValueBaseFolder);
    requestBody.update(updatedObjectBaseFolder);

    successPayload.request.dataObjects!.ids.push(baseFolderObject.id);
    successPayload.response.dataObjects!.push(updatedObjectBaseFolder.toApiFormatted());
    // [/5]
  }

  private async addInsertOperationForUserEventLog(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;
    const {
      activeGroupId,
      batchUpdateClaims,
      currentUser,
      dtServer,
      logRecordList,
      requestBody,
      roleAuthClaim: authState,
      successPayload
    } = this.state;

    if (!Utils.isNonEmptyArray<EventLogRecord>(logRecordList)) return;
    this.logger.info('Adding an insert operation to request body for user event log.');

    const idList = [currentUser.id];
    if (folderKey === Constants.MessageFolderKey.GroupInbox || parentFolderKey === Constants.MessageFolderKey.GroupInbox) {
      idList.push(activeGroupId);
    }

    const actionPayload: LogEventActionPayload = {
      dtServer,
      fetchIds: (count) => {
        return this.dispatchFetchIdsByUsage({
          authState,
          state: AUTH_STATE_LOG_EVENT,
          ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
        });
      },
      logger: this.logger,
      record: logRecordList,
      requestBody,
      successPayload,
      userId: 0,
      userIdType: 'user'
    };

    for (const userId of idList) {
      let { userIdType } = actionPayload;
      if (userId === activeGroupId) userIdType = 'group';
      const claims = await this.dispatch(logEventAction({ ...actionPayload, userId, userIdType }));
      batchUpdateClaims.push(...claims);
    }
  }
}

export const moveToArchivedMessagesAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'moveToArchivedMessagesAction:');

    const action = new MoveToArchivedMessagesAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
