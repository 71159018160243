import { Snackbar } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import React from 'react';

export const ErrorMessageSnackbar: React.FC<{
  closeText: string;
  message: string;
  open: boolean;
  onClose: NonNullable<AlertProps['onClose']>;
  severity?: AlertProps['severity'];
}> = React.memo(({ message, open, onClose, closeText, severity }) => (
  <Snackbar
    autoHideDuration={10000}
    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    open={open}
    onClose={onClose}
  >
    <Alert elevation={6} severity={severity || 'error'} variant="filled" onClose={onClose} closeText={closeText}>
      {message}
    </Alert>
  </Snackbar>
));
