const CHAR_CODE_TO_HEX_MAP = new Array<string>(0xff + 1);

for (let charCode = 0; charCode <= 0xff; ++charCode) {
  const hexCharCode = charCode.toString(16).padStart(2, '0');
  CHAR_CODE_TO_HEX_MAP[charCode] = hexCharCode;
}

export function uint8ArrayToHex(bytes: Uint8Array): string {
  const { length: N } = bytes;

  const hexCharCodeList = new Array<string>(N);
  for (let index = 0; index < N; ++index) {
    hexCharCodeList[index] = CHAR_CODE_TO_HEX_MAP[bytes[index]!]!;
  }

  return hexCharCodeList.join('');
}
