import type { Nullable } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { getPushSubscription } from './get-push-subscription';

export async function unsubscribeFromPushService(
  subscription?: Nullable<PushSubscription>
): Promise<[boolean, PushSubscription | null]> {
  try {
    if (Utils.isNil(subscription)) {
      subscription = await getPushSubscription();
    }

    if (Utils.isNotNil(subscription)) {
      const success = await subscription.unsubscribe();
      return [success, subscription];
    }
  } catch {
    /* ignore */
  }
  return [false, subscription!];
}
