import { StoreStateReminderNotification } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { addMilliseconds, isBefore, set } from 'date-fns';
import { REMINDER_NOTIFICATION_RETRY_DELAY, SET_MILLISECONDS_SECONDS_TO_NULL } from '../../constants';

const Logger = getLoggerWithPrefix('Reducer', 'closeReminderNotificationReducer:');

export const closeReminderNotificationReducer = (state: StoreStateReminderNotification) => {
  try {
    Logger.info('== BEGIN ==');

    if (Utils.isNil(state.active)) return state;

    let copyQueueList = [...state.queueList].filter(
      ({ eventObjectId }) => eventObjectId !== state.active!.eventObjectId
    );

    const nextReminder = addMilliseconds(
      set(Date.now(), SET_MILLISECONDS_SECONDS_TO_NULL).getTime(),
      REMINDER_NOTIFICATION_RETRY_DELAY
    ).getTime();

    // re-add to queue if next reminder is before event start
    if (isBefore(nextReminder, state.active.start)) {
      copyQueueList.push({
        ...state.active,
        remindAt: nextReminder
      });
    }

    return { ...state, active: null, queueList: copyQueueList };
  } finally {
    Logger.info('== END ==');
  }
};
