import type { PersonName, SigmailObjectTypeCode } from '@sigmail/common';
import { AppException, Constants, Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectHrmUserListValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_HRM_USER_LIST;

/** @public */
export class DataObjectHrmUserList extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.list);
  }

  public override async decryptedValue(): Promise<DV> {
    let value = await super.decryptedValue();

    const mappedList = value.list
      .map<typeof value.list[0]>((hrmUser) => {
        const splitted = hrmUser.name.split(',').reverse();
        const personName: Pick<PersonName, 'firstName' | 'lastName'> = {
          firstName: Utils.trimOrDefault(splitted[0]),
          lastName: Utils.trimOrDefault(splitted[1])
        };
        return { ...hrmUser, ...personName, name: Utils.joinPersonName(personName) };
      })
      .sort((user1, user2) => user1.name.localeCompare(user2.name, 'en'));

    const Class = this.constructor as typeof DataObjectHrmUserList;
    value = this.freezeValue({ ...value, list: mappedList });
    Class.DecryptedValueCache.set(this, value);

    return value;
  }

  public updateValue(_newValue: DV): Promise<DataObjectHrmUserList> {
    //
    // XXX this object is updated by Admin Portal and never by the client-side
    //

    // const Class = this.constructor as typeof DataObjectHrmUserList;
    // return Class.updateValue(this, newValue);
    throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE);
  }
}
