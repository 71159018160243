import { GlobalI18n } from "@sigmail/i18n";
import { gettext } from "..";

const i18n: GlobalI18n['membershipStatus'] = {
  // Translators: Label for the Active drop-down option of Membership status
  active: gettext('global', 'active', 'membershipStatus'),
  // Translators: Label for the Inactive drop-down option of Membership Status
  inactive: gettext('global', 'inactive', 'membershipStatus'),
  // Translators: Label for the Pending drop-down option of Membership Status
  pending: gettext('global', 'pending', 'membershipStatus'),
  // Translators: Label for the Expired drop-down option of Membership Status
  expired: gettext('global', 'expired', 'membershipStatus')
};

export default i18n;
