import { Constants, MessagingException, NonNullableProps, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import * as HealthDataConstants from '../../../../app/health-data/constants';
import { KCCQDataUtil } from '../../../../utils/health-data';
import {
  basicProfileObjectSelector as selectBasicProfile,
  protectedProfileObjectSelector as selectProtectedProfile
} from '../../../selectors/user-object';
import { ActionInitParams } from '../../base-action';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../../constants';
import { BaseSubmitHealthDataAction, BaseSubmitHealthDataActionPayload, BaseSubmitHealthDataActionState } from './base';

type IsaacKccqDatasetItem = { key: string; value: number };

interface IsaacObservationKccq extends Omit<Api.IsaacObservation, 'dataset'> {
  dataset: ReadonlyArray<Readonly<IsaacKccqDatasetItem>>;
}

export type SubmitKccqHealthDataActionPayload = BaseSubmitHealthDataActionPayload;
export type SubmitKccqHealthDataActionState = BaseSubmitHealthDataActionState;

export class SubmitKccqHealthDataAction<
  P extends SubmitKccqHealthDataActionPayload = SubmitKccqHealthDataActionPayload,
  S extends SubmitKccqHealthDataActionState = SubmitKccqHealthDataActionState
> extends BaseSubmitHealthDataAction<P, S> {
  public constructor(params: ActionInitParams<P>) {
    super(params);

    const { dataForm } = params.payload;
    if (dataForm !== HealthDataConstants.DataFormNameKCCQ) {
      throw new MessagingException(
        `Invalid payload; expected <dataForm> to be <${HealthDataConstants.DataFormNameKCCQ}>; was <${dataForm}>`
      );
    }
  }

  /** @override */
  protected async onExecute(...args: Array<any>) {
    await super.onExecute(...args);

    this.logger.info('Submitting KCCQ health data to ISAAC servers.');

    const { kccq, onBehalfOf } = this.payload as NonNullableProps<P>;
    const { accessToken, clientId, currentUser, dtServer, msgMetadataId } = this.state;

    const guestUserId = Utils.isNil(onBehalfOf) ? currentUser.id : onBehalfOf.id;
    const basicProfile = await this.getUserObjectValue(selectBasicProfile, {
      fetch: true,
      type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC,
      userId: guestUserId
    });
    const protectedProfile = await this.getUserObjectValue(selectProtectedProfile, {
      fetch: true,
      type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
      userId: guestUserId
    });

    if (Utils.isNil(basicProfile) || Utils.isNil(protectedProfile)) {
      this.logger.warn('Failed to fetch basic/protected profile of current user.');
      return;
    }

    const { firstName, lastName } = basicProfile;
    const { birthDate: dob, gender: genderId, healthCardNumber } = protectedProfile;
    const dtBirth = Utils.isString(dob) && new Date(dob);
    const birthDate = Utils.isValidDate(dtBirth)
      ? ([`${dtBirth.getFullYear()}`, `${dtBirth.getMonth() + 1}`, `${dtBirth.getDate()}`] as const)
          .map((value) => (value.length < 2 ? value.padStart(2, '0') : value))
          .join('-')
      : '';
    let hcn = Utils.trimOrDefault(healthCardNumber).replace(/[^0-9]/g, '');
    let hpj = Utils.trimOrDefault(protectedProfile.healthPlanJurisdiction);
    if (hpj.length === 0) hpj = DEFAULT_HEALTH_PLAN_JURISDICTION;
    if (hpj !== Constants.HealthPlanJurisdiction.Ontario) {
      hcn = '1';
    } else if (hcn.length === 0) {
      hcn = '0';
    }
    const gender: IsaacObservationKccq['patient']['gender'] =
      genderId === Constants.Gender.Female ? 'F' : (genderId === Constants.Gender.Male && 'M') || 'O';

    const score = new KCCQDataUtil().calculateScore(kccq, 'all');
    const requestData: IsaacObservationKccq = {
      assessmentDt: dtServer.toISOString(),
      clientId,
      dataset: [
        ...kccq.pl.map<IsaacKccqDatasetItem>(({ activity: key, codedValue: value }) => ({ key, value })),
        ...kccq.ql.map<IsaacKccqDatasetItem>(({ question: key, codedValue: value }) => ({ key, value })),
        ...kccq.sf.map<IsaacKccqDatasetItem>(({ symptom: key, codedValue: value }) => ({ key, value })),
        ...kccq.sl.map<IsaacKccqDatasetItem>(({ activity: key, codedValue: value }) => ({ key, value })),
        { key: 'PhyLimitScore', value: score.pl },
        { key: 'QOLScore', value: score.ql },
        { key: 'SymFreqScore', value: score.sf },
        { key: 'SocLimitScore', value: score.sl },
        { key: 'SummaryScore', value: score.summary }
      ],
      id: msgMetadataId,
      patient: { birthDate, firstName, gender, hcn, lastName, userId: currentUser.id },
      uServiceId: 'KCCQ12'
    };

    // NOTE: we don't await for the promise
    void this.apiService.isaacObservation(accessToken, requestData).catch((error) => {
      this.logger.warn('Operation failed.', error);
    });
  }
}
