import type { SigmailObjectTypeCode } from '@sigmail/common';
import { NotificationObject } from '.';
import { DataObjectMsgReadReceipt } from '../data-object/msg-read-receipt';
import type { NotificationObjectReadReceiptValue as DV } from '../types';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_READ_RECEIPT;

/** @public */
export class NotificationObjectReadReceipt extends NotificationObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return DataObjectMsgReadReceipt.isValidId(dv.header);
  }
}
