import { Utils } from '@sigmail/common';
import { getLogger } from '@sigmail/logging';
import React from 'react';
import { Participant, Track } from 'twilio-video';
import { TrackPublication } from './track-publication.component';
import { ITrackPublication } from './types';

const Logger = getLogger('MeetingRoomTrackPublicationList');

export interface Props {
  children?: never;
  participant: Participant;
  isLocalParticipant?: boolean | null | undefined;
  videoOnly?: boolean | null | undefined;
  videoPriority?: Track.Priority | null | undefined;
}

interface State {
  participant: Participant;
  filteredPublicationList: ReadonlyArray<ITrackPublication>;
}

class TrackPublicationListComponent extends React.PureComponent<Props, State> {
  public static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: State): Partial<State> | null {
    const { participant } = nextProps;

    if (participant !== prevState.participant) {
      const publicationList = Array.from(nextProps.participant.tracks.values() as IterableIterator<ITrackPublication>);
      const filteredPublicationList = publicationList.filter(({ trackName }) => !trackName.includes('screen'));
      return { filteredPublicationList, participant };
    }

    return null;
  }

  protected readonly addEventListeners: (participant: Participant) => void;
  protected readonly removeEventListeners: (participant: Participant) => void;

  public constructor(props: Props) {
    super(props);

    this.state = {
      participant: null!,
      filteredPublicationList: []
    };

    this.onTrackPublished = this.onTrackPublished.bind(this);
    this.onTrackUnpublished = this.onTrackUnpublished.bind(this);

    this.addEventListeners = (participant) => {
      Logger.info(
        `Adding event listeners for participant(identity=${participant.identity}, sid=${participant.sid}) - trackPublished, trackUnpublished`
      );
      participant.on('trackPublished', this.onTrackPublished);
      participant.on('trackUnpublished', this.onTrackUnpublished);
    };

    this.removeEventListeners = (participant) => {
      Logger.info(
        `Removing event listeners for participant(identity=${participant.identity}, sid=${participant.sid}) - trackPublished, trackUnpublished`
      );
      participant.off('trackPublished', this.onTrackPublished);
      participant.off('trackUnpublished', this.onTrackUnpublished);
    };

    this.addEventListeners(props.participant);
  }

  public getSnapshotBeforeUpdate(prevProps: Readonly<Props>): any {
    const { participant } = this.props;
    const { participant: prevParticipant } = prevProps;

    if (prevParticipant !== participant) {
      Logger.info(`Participant changed: identity=${participant.identity}, sid=${participant.sid}`);
      this.removeEventListeners(prevParticipant);
    }

    return null;
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    const { participant } = this.props;
    const { participant: prevParticipant } = prevProps;

    if (prevParticipant !== participant) {
      this.addEventListeners(participant);
    }

    if (this.state.filteredPublicationList !== prevState.filteredPublicationList) {
      Logger.info('Filtered publication list changed');
      Logger.info('BEGIN FILTERED PUBLICATION LIST ====');
      this.state.filteredPublicationList.forEach((publication) =>
        Logger.info(`kind=${publication.kind}, trackName=${publication.trackName}`)
      );
      Logger.info('END FILTERED PUBLICATION LIST ====');
    }
  }

  public componentWillUnmount(): void {
    this.removeEventListeners(this.props.participant);
  }

  public render(): React.ReactNode {
    const { isLocalParticipant, videoOnly, videoPriority } = this.props;
    const { filteredPublicationList, participant } = this.state;

    if (Utils.isNil(participant)) return null;

    return (
      <React.Fragment>
        {filteredPublicationList.map((publication) => (
          <TrackPublication
            key={`${participant.sid}-${publication.kind}`}
            publication={publication}
            isLocalParticipant={isLocalParticipant}
            videoOnly={videoOnly}
            videoPriority={videoPriority}
          />
        ))}
      </React.Fragment>
    );
  }

  protected onTrackPublished(publication: ITrackPublication): void {
    this.setState(({ filteredPublicationList }) => {
      if (!publication.trackName.includes('screen')) {
        return { filteredPublicationList: filteredPublicationList.concat(publication) };
      }
      return null;
    });
  }

  protected onTrackUnpublished(publication: ITrackPublication): void {
    this.setState(({ filteredPublicationList }) => {
      const index = filteredPublicationList.indexOf(publication);
      if (index !== -1) {
        const newFilteredPublicationList = filteredPublicationList.slice();
        newFilteredPublicationList.splice(index, 1);
        return { filteredPublicationList: newFilteredPublicationList };
      }
      return null;
    });
  }
}

export const TrackPublicationList = TrackPublicationListComponent;
(TrackPublicationList as any).displayName = 'TrackPublicationList';
