import { PartialRecord, ReadonlyRecord, Utils } from '@sigmail/common';
import { GridLocaleTextKey } from '@sigmail/i18n';
import { ComponentStateChangedEvent as BaseComponentStateChangedEvent } from 'ag-grid-community';
import { AgGridReact as AgGrid, AgGridReactProps as AgGridProps } from 'ag-grid-react';
import clsx from 'clsx';
import React from 'react';
import './grid.theme.css';

export type GridClassKey = 'theme';

export type ChangedComponentState<T = unknown> = Record<`${'current' | 'previous'}Value`, T>;
export type ComponentStateChangedEvent = BaseComponentStateChangedEvent & PartialRecord<string, ChangedComponentState>;
type ComponentStateChangedEventHandler = NonNullable<AgGridProps['onComponentStateChanged']>;
type RowDataUpdatedEvent = Parameters<NonNullable<AgGridProps['onRowDataUpdated']>>[0] & {
  rowData: Array<any> | undefined;
};
type RowDataUpdatedEventHandler = (event: RowDataUpdatedEvent) => void;

export interface Props
  extends Omit<
    AgGridProps,
    'localeText' | 'onComponentStateChanged' | 'onRowDataChanged' | 'onRowDataUpdated' | 'ref'
  > {
  children?: React.ReactNode;
  className?: string;
  classes?: PartialRecord<GridClassKey, string>;
  localeText: ReadonlyRecord<GridLocaleTextKey, string>;
  onComponentStateChanged?: (event: ComponentStateChangedEvent) => ReturnType<ComponentStateChangedEventHandler>;
  onRowDataUpdated?: RowDataUpdatedEventHandler;
}

export const DEFAULT_CLASSES: Required<NonNullable<Props['classes']>> = {
  theme: 'ag-theme-material'
};

const GridRoot: React.FC<Props> = ({
  className,
  classes: classesFromProps,
  onComponentStateChanged,
  onGridReady,
  onRowDataUpdated,
  onSelectionChanged,
  rowData,
  ...rootProps
}) => {
  const classes = Utils.defaults({}, classesFromProps as unknown, DEFAULT_CLASSES);
  const initialRowData = React.useRef(rowData);

  const componentStateChangedEventHandler = React.useMemo(
    (): ComponentStateChangedEventHandler => (event: ComponentStateChangedEvent) => {
      if (typeof onComponentStateChanged === 'function') {
        onComponentStateChanged(event as ComponentStateChangedEvent);
      }

      if ('rowData' in event) {
        const { api, columnApi } = event;

        if (typeof onRowDataUpdated === 'function') {
          const rowData = (event.rowData as ChangedComponentState<Array<any>>).currentValue;
          onRowDataUpdated({ api, columnApi, rowData, type: 'rowDataUpdated' });
        }

        if (typeof onSelectionChanged === 'function') {
          onSelectionChanged({ api, columnApi, type: 'selectionChanged' });
        }
      }
    },
    [onComponentStateChanged, onRowDataUpdated, onSelectionChanged]
  );

  const gridReadyEventHandler = React.useMemo(
    (): AgGridProps['onGridReady'] => (event) => {
      if (typeof onGridReady === 'function') {
        onGridReady(event);
      }

      componentStateChangedEventHandler({
        ...event,
        type: 'componentStateChanged',
        // @ts-expect-error TS2345: Object literal may only specify known properties
        rowData: {
          currentValue: rowData,
          previousValue: initialRowData.current
        }
      });
    },
    [componentStateChangedEventHandler, onGridReady, rowData]
  );

  return (
    <React.Suspense fallback={null}>
      <div className={clsx(className, classes.theme)}>
        <AgGrid
          {...rootProps}
          onComponentStateChanged={componentStateChangedEventHandler}
          onGridReady={gridReadyEventHandler}
          onRowDataUpdated={onRowDataUpdated}
          onSelectionChanged={onSelectionChanged}
          rowData={rowData}
        />
      </div>
    </React.Suspense>
  );
};

GridRoot.displayName = 'GridRoot';

export const Grid = React.memo(GridRoot);
Grid.displayName = 'Grid';
