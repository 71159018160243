import { GroupsI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';

const i18n: GroupsI18n.Dialog.RemoveUsersFromGroup = {
  // Translators: (HTML) Title for the Remove Users From Group dialog
  title: gettext('groups', 'title', 'removeUsersFromGroupDialog', 'safe-html'),
  // Translators: (HTML) Body for the Remove Users From Group dialog
  body: ngettext('groups', 'body', 'removeUsersFromGroupDialog', 'safe-html'),
  // Translators: (HTML) A note explaining the Remove Users From Group
  // functionality
  note: ngettext('groups', 'note', 'removeUsersFromGroupDialog', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Remove Users From Group \
      // dialog
      label: gettext('groups', 'reject', 'removeUsersFromGroupDialogActionLabel')
    },
    accept: {
      // Translators: Label for the Submit action of Remove Users From Group \
      // dialog
      label: gettext('groups', 'accept', 'removeUsersFromGroupDialogActionLabel')
    },
    dismiss: {
      // Translators: Label for the Close action of Remove Users From Group \
      // dialog
      label: gettext('groups', 'dismiss', 'removeUsersFromGroupDialogActionLabel')
    }
  }
};

export default i18n;
