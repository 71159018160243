import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: ContactListI18n.View.ContactListView['dialog']['contactDetails'] = {
  // Translators: (HTML) Title for the Contact Details dialog
  title: gettext('contact-list', 'title', 'contactDetailsDialog', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Contact Details \
      // Group dialog
      label: gettext('contact-list', 'accept', 'contactDetailsDialogActionLabel')
    }
  }
};

export default i18n;
