/* eslint-disable @typescript-eslint/no-inferrable-types */

/** The default facility code. */
export const FACILITY_NULL: number = 0;

/** The source of error code is the application's HTTP service. */
export const FACILITY_HTTP_SERVICE: number = 1;

/** The source of error code is the application's API service. */
export const FACILITY_API_SERVICE: number = 2;

/** The source of error code is the Authentication related modules. */
export const FACILITY_AUTH: number = 3;

/** The source of error code is the Messaging module. */
export const FACILITY_MESSAGING: number = 4;

/** The source of error code is the Scheduling module. */
export const FACILITY_SCHEDULING: number = 5;

/** The source of error code is the Crypto module. */
export const FACILITY_CRYPTO: number = 6;

/** The source of error code is the client application. */
export const FACILITY_CLIENT: number = 0x3ff;
