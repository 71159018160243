import type { InstituteInfo } from '../types';
import { POSTAL_ADDRESS_KEY_LIST } from './postal-address-key-list';

export const INSTITUTE_INFO_KEY_LIST: ReadonlyArray<keyof InstituteInfo> = Object.freeze([
  'name',
  'phoneNumber',
  'faxNumber',
  'emailAddress',
  ...POSTAL_ADDRESS_KEY_LIST
]);
