import type { NonNullableProps, SigmailObjectTypeCode } from '@sigmail/common';
import { UserObject } from '.';
import { DataObjectEventLog } from '../data-object/event-log';
import type { UserObjectEventLogValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_EVENT_LOG;

/** @public */
export class UserObjectEventLog extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    const { $$formatver, ...data } = dv;

    return (Object.keys(data) as ReadonlyArray<keyof NonNullableProps<typeof data>>).every((key) =>
      DataObjectEventLog.isValidId(dv[key])
    );
  }

  public override updateValue(newValue: DV): Promise<UserObjectEventLog> {
    const Class = this.constructor as typeof UserObjectEventLog;
    return Class.updateValue(this, newValue);
  }
}
