import React from 'react';
import { cancelHrmMessageAction } from '../../../../app-state/actions/messaging/cancel-hrm-message-action';
import { useAppState } from '../../../../app-state/hooks';
import { ActionData } from '../../types';
import { BaseContextValue, OpenedMessageViewState } from '../context';

export interface ActionDataCancelHrmMessage extends ActionData {
  currentFolder?: BaseContextValue['currentFolder'] | undefined;
  sourceMessage?: Pick<OpenedMessageViewState['openedMessage'], 'body' | 'header'> | undefined;
}

export const useCancelHrmMessageActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({ currentFolder, failCallback, sourceMessage, successCallback }: ActionDataCancelHrmMessage) => {
      let value: any;

      try {
        value = await appDispatch(
          cancelHrmMessageAction({
            folderKey: currentFolder!.folderKey,
            parentFolderKey: currentFolder!.parentFolderKey,
            msgMetadataId: sourceMessage!.header,
            msgBodyId: sourceMessage!.body
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
