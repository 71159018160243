import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectMsgMetadataValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_MSG_METADATA;

/** @public */
export class DataObjectMsgMetadata extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      Utils.isNonArrayObjectLike(dv.sender) &&
      Utils.isInteger(dv.sentAtUtc) &&
      Utils.isString(dv.subjectLine) &&
      (Utils.isUndefined(dv.sensitivity) || Utils.isString(dv.sensitivity)) &&
      Utils.isArray(dv.recipientList) &&
      (dv.readReceiptId === 0 || this.isValidId(dv.readReceiptId)) &&
      Utils.isString(dv.importance) &&
      (Utils.isUndefined(dv.billable) || typeof dv.billable === 'boolean') &&
      (Utils.isUndefined(dv.documentList) || Utils.isArray(dv.documentList))
    );
  }

  public static override isValidVersion(value: unknown): value is number {
    return value === 0;
  }

  public updateValue(newValue: DV): Promise<DataObjectMsgMetadata> {
    const Class = this.constructor as typeof DataObjectMsgMetadata;
    return Class.updateValue(this, newValue);
  }
}
