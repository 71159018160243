import { ApiActionPayload } from '@sigmail/app-state';
import { AppException, Constants, SigmailAuditId, SigmailClientId, SigmailUserId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  IUserObject,
  UserObjectEConsult,
  UserObjectEConsultValue,
  UserObjectProfileBasic,
  UserObjectProfileBasicValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, BaseActionState } from '../base-action';
import { AUTH_STATE_CREATE_E_CONSULT_OBJECT_MIGRATION } from '../constants/auth-state-identifier';

interface Payload {
  accessToken: string;
  auditId: SigmailAuditId;
  clientId: SigmailClientId;
  roleAuthClaim: string;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
  userId: SigmailUserId;
}

interface State extends BaseActionState {
  basicProfileObject: IUserObject<UserObjectProfileBasicValue>;
  basicProfile: UserObjectProfileBasicValue;
  dtServer: Date;
  requestBody: BatchUpdateRequestBuilder;
  batchUpdateAuthState: string;
  batchUpdateClaims: Array<string>;
}

class MigrationCreateEConsultObject extends BaseAction<Payload, State> {
  /** @override */
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        const { roleAuthClaim, accessToken, userId, successPayload } = this.payload;

        const { userObjectList, serverDateTime } = await this.fetchObjects(accessToken, {
          authState: roleAuthClaim,
          userObjectsByType: [
            { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId },
            { type: process.env.USER_OBJECT_TYPE_E_CONSULT, userId }
          ],
          expectedCount: { userObjectsByType: 1 }
        });

        const basicProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId });
        if (Utils.isNil(basicProfileJson)) {
          throw new Api.MalformedResponseException('No matching user basic profile object could be found.');
        } else {
          const basicProfileObject = new UserObjectProfileBasic(basicProfileJson);
          this.state.basicProfileObject = basicProfileObject;
          this.state.basicProfile = await basicProfileObject.decryptedValue();
        }

        const { $$formatver } = this.state.basicProfile as UserObjectProfileBasicValue<any>;
        if ($$formatver !== 4) {
          if ($$formatver > 4) {
            this.logger.info(`Call ignored; migration is not required. ($$formatver = ${$$formatver})`);
            return;
          }
          throw new AppException(Constants.Error.S_ERROR, `Expected <$$formatver> to be 4; was ${$$formatver}`);
        }

        const eConsultJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_E_CONSULT, userId });
        if (Utils.isNotNil(eConsultJson)) {
          throw new AppException(Constants.Error.S_ERROR, `An existing e-consult object was found. <ID=${eConsultJson.id}>`);
        }

        this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
        await this.generateRequestBody();

        const { batchUpdateAuthState: authState, batchUpdateClaims: claims, requestBody, basicProfileObject } = this.state;
        await this.batchUpdateData(accessToken, { authState, claims, ...requestBody.build() });

        // manually patch successPayload's data with updated objects
        do {
          const userObjectList = [basicProfileObject];
          const { userObjectsByType } = successPayload.response;
          for (const obj of userObjectList) {
            const index = this.findUserObjectIndex(userObjectsByType!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              userObjectsByType![index] = obj.toApiFormatted();
            }
          }
        } while (false);

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    await this.addInsertOperationForEConsultObject(); // 424
    await this.addUpdateOperationForBasicProfile(); // 401
  }

  private async addInsertOperationForEConsultObject(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for e-consult object.');

    const { accessToken, roleAuthClaim: authState, userId, clientId, auditId } = this.payload;
    const { dtServer, requestBody } = this.state;

    const { authState: batchUpdateAuthState, claims: batchUpdateClaims, ids: idRecord } = await this.fetchIdsByUsage(accessToken, {
      authState,
      state: AUTH_STATE_CREATE_E_CONSULT_OBJECT_MIGRATION,
      ids: { ids: [{ type: process.env.USER_OBJECT_TYPE_E_CONSULT }] }
    });

    this.state.batchUpdateAuthState = batchUpdateAuthState;
    this.state.batchUpdateClaims = batchUpdateClaims.slice();

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_E_CONSULT];
    const value: UserObjectEConsultValue = { $$formatver: 1, list: [] };
    this.logger.debug({ id, ...value });
    const eConsultObject = await UserObjectEConsult.create(id, undefined, 1, value, userId, userId, dtServer);
    const keyList = await eConsultObject.generateKeysEncryptedFor(clientId, auditId);

    requestBody.insert(eConsultObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addUpdateOperationForBasicProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for basic profile object.');

    const { basicProfile, basicProfileObject, requestBody } = this.state;

    const updatedValue: typeof basicProfile = { ...basicProfile, $$formatver: 5 };
    this.logger.debug({ updatedValue });
    const updatedObject = await basicProfileObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.basicProfileObject = updatedObject;
  }
}

export const migration_createEConsultObject = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'createEConsultObject:');

    const migration = new MigrationCreateEConsultObject({ payload, dispatch, getState, apiService, logger: Logger });
    return migration.execute();
  };
};
