import { EventLogRecordNil } from '@sigmail/objects';

export {
  newEventLogRecordValueAccountSetup,
  newEventLogRecordValueChangePassword,
  newEventLogRecordValueChangeUsername,
  newEventLogRecordValueRevokeInvitation,
  newEventLogRecordValueSendCaregiverInvitation,
  newEventLogRecordValueSendGroupInvitation,
  newEventLogRecordValueSendInvitation,
  newEventLogRecordValueUpdateMFA,
  newEventLogRecordValueUpdatePreference,
  newEventLogRecordValueUpdateProfile
} from './account';
export type {
  AccountSetupParams as NewEventLogRecordValueAccountSetupParams,
  ChangePasswordParams as NewEventLogRecordValueChangePasswordParams,
  ChangeUsernameParams as NewEventLogRecordValueChangeUsernameParams,
  RevokeInvitationParams as NewEventLogRecordValueRevokeInvitationParams,
  SendCaregiverInvitationParams as NewEventLogRecordValueSendCaregiverInvitationParams,
  SendGroupInvitationParams as NewEventLogRecordValueSendGroupInvitationParams,
  SendInvitationParams as NewEventLogRecordValueSendInvitationParams,
  UpdateMfaParams as NewEventLogRecordValueUpdateMfaParams,
  UpdatePreferenceParams as NewEventLogRecordValueUpdatePreferenceParams,
  UpdateProfileParams as NewEventLogRecordValueUpdateProfileParams
} from './account';
export { newEventLogRecordValueConsultation } from './consultation';
export type { Params as NewEventLogRecordValueConsultationParams } from './consultation';
export {
  newEventLogRecordValueCategoryChanged,
  newEventLogRecordValueMoveMessage,
  newEventLogRecordValueRecallMessage,
  newEventLogRecordValueReceiveMessage,
  newEventLogRecordValueSendMessage,
  newEventLogRecordValueSendToHRM
} from './message';
export type {
  CategoryChangedParams as NewEventLogRecordValueCategoryChangedParams,
  MoveMessageParams as NewEventLogRecordValueMoveMessageParams,
  RecallMessageParams as NewEventLogRecordValueRecallMessageParams,
  ReceivedMessageParams as NewEventLogRecordValueReceivedMessageParams,
  SendMessageParams as NewEventLogRecordValueSendMessageParams,
  SendToHrmParams as newEventLogRecordValueSendToHRMParams
} from './message';
export { newEventLogRecordValueReferral } from './referral';
export type { Params as NewEventLogRecordValueReferralParams } from './referral';
export { newEventLogRecordValueSessionAuth } from './session';
export type { Params as NewEventLogRecordValueSessionAuthParams } from './session';

export type NewEventLogRecordValueNilParams = Readonly<[value: EventLogRecordNil['value']]>;

export const newEventLogRecordValueNil = (
  ...params: NewEventLogRecordValueNilParams
): NewEventLogRecordValueNilParams[0] => params[0];
