import { MessagingI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import { DialogRecipientsRemoved } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeMessage['dialog']['recipientsRemoved'] = {
  // Translators: (HTML) Title for the Recipients Removed dialog
  title: gettext('messaging', 'title', 'dialogRecipientsRemoved', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of Recipients \
  // Removed dialog
  body: ngettext('messaging', 'body', 'dialogRecipientsRemoved', 'safe-html'),
  // Translators: (HTML) Text message listing some of the reasons due to which \
  // recipients may be removed from the list
  reason: gettext('messaging', 'removalReason', 'dialogRecipientsRemoved', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Proceed action of Recipients Removed dialog
      label: gettext('messaging', 'accept', 'dialogRecipientsRemovedActionLabel'),
      context: DialogRecipientsRemoved
    }
  }
};

export default i18n;
