import type { SigmailObjectTypeCode } from '@sigmail/common';
import { GroupObject } from '.';
import type { GroupObjectAccessRightsValue as DV } from '../types';

const TYPE = process.env.GROUP_OBJECT_TYPE_ACCESS_RIGHTS;

/** @public */
export class GroupObjectAccessRights extends GroupObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    return super.isValidDecryptedValue(value);
  }

  public updateValue(newValue: DV): Promise<GroupObjectAccessRights> {
    const Class = this.constructor as typeof GroupObjectAccessRights;
    return Class.updateValue(this, newValue);
  }
}
