import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { PartialRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n } from '@sigmail/i18n';
import { enCA, frCA } from 'date-fns/locale';
import { FieldState } from 'final-form';
import { TOptions } from 'i18next';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { BillingFileEndDate, BillingFileStartDate } from '../../constants/form-input-constraint';
import { FrenchCanada } from '../../constants/language-codes';
import { withTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { FieldConfig } from '../shared/form';
import { ValidationErrorResult } from '../shared/form/field-validator';
import {
  DatePickerFieldProps,
  FormComponent,
  FormComponentProps,
  FormComponentState
} from '../shared/form/form.component';
import { SelectFieldProps as BaseSelectFieldProps } from '../shared/form/select-field.component';
import style from './form-billing-file.module.css';

const { billingFile: i18n } = globalI18n.form;

const FILE_TYPE_OPTION_LIST: ReadonlyArray<typeof i18n.fieldsetFileType.formField.fileType.options[0]> = [
  { code: '', label: i18n.fieldsetFileType.formField.fileType.placeholder! },
  ...i18n.fieldsetFileType.formField.fileType.options
];

export type FieldsetKey = Extract<keyof typeof i18n, `fieldset${string}`>;
export type FieldNameDateRangeFieldset = keyof typeof i18n.fieldsetDateRange.formField;
export type FieldNameFileTypeFieldset = keyof typeof i18n.fieldsetFileType.formField;

export type FieldName = FieldNameDateRangeFieldset | FieldNameFileTypeFieldset;
export type DateValueFieldName = FieldNameDateRangeFieldset;
export type StringValueFieldName = FieldNameFileTypeFieldset;

export type FormValues = Record<StringValueFieldName, string> & Record<DateValueFieldName, Date>;

export const INITIAL_VALUES = Object.freeze<FormValues>({ endDate: null!, fileType: '', startDate: null! });

const FIELD_SET_LIST: ReadonlyArray<Readonly<[FieldsetKey, ReadonlyArray<FieldName>]>> = [
  ['fieldsetDateRange', ['startDate', 'endDate']],
  ['fieldsetFileType', ['fileType']]
];

interface SelectFieldProps extends Omit<BaseSelectFieldProps<string, false>, 'inputState'> {
  config?: FieldConfig<string> | undefined;
  render?: ((props: BaseSelectFieldProps<string, false>) => React.ReactNode) | undefined;
}

const FIELD_PROPS: Record<StringValueFieldName, SelectFieldProps> & Record<DateValueFieldName, DatePickerFieldProps> = {
  endDate: {
    label: i18n.fieldsetDateRange.formField.endDate.label,
    placeholder: i18n.fieldsetDateRange.formField.endDate.placeholder,
    config: {
      required: { typeOf: 'date' },
      range: {
        min: (_, { startDate }) =>
          Utils.isValidDate(startDate) && startDate >= BillingFileStartDate.min ? startDate : BillingFileEndDate.min,
        max: BillingFileEndDate.max
      }
    }
  },
  fileType: {
    autoComplete: 'off',
    displayEmpty: true,
    getOptionKey: (option) => (option.length === 0 ? 'unselected' : option),
    getOptionLabel: (option) => FILE_TYPE_OPTION_LIST.find(({ code }) => code === option)!.label,
    inputProps: { 'aria-required': true },
    label: i18n.fieldsetFileType.formField.fileType.label,
    options: FILE_TYPE_OPTION_LIST.map(({ code }) => code),
    config: { required: true }
  },
  startDate: {
    label: i18n.fieldsetDateRange.formField.startDate.label,
    placeholder: i18n.fieldsetDateRange.formField.startDate.placeholder,
    config: {
      required: { typeOf: 'date' },
      range: BillingFileStartDate,
      validateFields: ['endDate']
    }
  }
};

export type BillingFileFormClassKey = 'inputRoot';

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<BillingFileFormClassKey, string> | undefined;
  formError?: string | null | undefined;
  SummaryNode?: React.ReactNode | undefined;
}

interface ComponentProps extends Props, WithTranslation {}
interface State extends FormComponentState<FieldName, FormValues> {}

class BillingFileFormComponent extends FormComponent<
  FieldsetKey,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public static getDerivedStateFromProps(nextProps: Readonly<ComponentProps>, prevState: State): Partial<State> | null {
    if (prevState.areValidationErrorsVisible !== true && Utils.isString(nextProps.formError)) {
      return { areValidationErrorsVisible: true };
    }
    return null;
  }

  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes',
      'formError',
      'SummaryNode'
    );

    let initialValues = INITIAL_VALUES;
    if (FILE_TYPE_OPTION_LIST.length === 2) {
      initialValues = { ...initialValues, fileType: FILE_TYPE_OPTION_LIST[1].code };
    }

    this.createForm({ initialValues });
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-billing-file-${this.formIdSuffix}`;
  }

  /** @override */
  protected getFormSubscription(...args: any[]) {
    return { ...super.getFormSubscription(...args), values: true };
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetKey: FieldsetKey) {
    const { label } = i18n[fieldsetKey];
    return Utils.isString(label) ? this.props.t(label) : null;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldType(fieldName: FieldName, ...args: any[]) {
    switch (fieldName) {
      case 'endDate':
      case 'startDate':
        return 'date';
      case 'fileType':
        return 'select';
      default:
        break;
    }

    return super.getFieldType(fieldName, ...args);
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { t, formError } = this.props;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    switch (fieldName) {
      case 'endDate':
      case 'startDate': {
        const datePickerProps = { ...(fieldProps as DatePickerFieldProps) };
        datePickerProps.minDate = BillingFileStartDate.min;

        fieldProps = datePickerProps;
        break;
      }
      case 'fileType': {
        if (Utils.isString(formError) && copyOfDefaultProps.error === false && !Utils.isString(helperText)) {
          copyOfDefaultProps.error = true;
          helperText = formError;
        }
        break;
      }
      default: {
        break;
      }
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const {
      t,
      i18n: { language: locale }
    } = this.props;

    let errorMessageI18n: string | undefined = undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    if (!Utils.isString(errorMessageI18n)) return error;

    let tOptions: TOptions | undefined = undefined;
    if (error.key === 'range' && (fieldName === 'endDate' || fieldName === 'startDate')) {
      const MIN_DATE = Utils.DATE_FORMAT_FULL_NO_TIME(locale).format(error.range.min);
      const MAX_DATE = Utils.DATE_FORMAT_FULL_NO_TIME(locale).format(error.range.max);
      tOptions = { MIN_DATE, MAX_DATE };
    }

    return t(errorMessageI18n, tOptions);
  }

  /** @override */
  protected renderForm() {
    const { language: locale } = this.props.i18n;

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider locale={locale === FrenchCanada ? frCA : enCA} utils={DateFnsUtils}>
          {super.renderForm()}
        </MuiPickersUtilsProvider>
        {this.props.SummaryNode}
      </React.Fragment>
    );
  }

  /** @override */
  protected renderField(fieldName: FieldName) {
    const { classes } = this.props;

    let fieldNode = super.renderField(fieldName);
    if (Utils.isNil(fieldNode) || fieldNode === false) return null;

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  /** @override */
  protected onChangeDateField(fieldName: FieldName, value: Date | null) {
    let dt = value;
    if (Utils.isValidDate(dt)) {
      if (fieldName === 'startDate') {
        dt.setHours(0, 0, 0, 0);
      } else {
        dt.setHours(23, 59, 59, 999);
      }
    }
    return super.onChangeDateField(fieldName, dt);
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, state: FieldState<any>) {
    let { error, ...fieldState } = state;
    error = this.getFieldErrorMessage(fieldName, error);
    return super.setFieldState(fieldName, { ...fieldState, error });
  }
}

const ns = [I18N_NS_GLOBAL];

export const BillingFileForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['form-input-group']
    } as ComponentProps['classes']);

    return <BillingFileFormComponent {...rootProps} classes={classes} innerRef={ref} />;
  })
);

BillingFileForm.displayName = 'BillingFileForm';
BillingFileForm.defaultProps = { className: style.form };
