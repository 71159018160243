import type { EncapsulatedKey } from '@sigmail/common';
import { Algorithm } from '.';
import { E_METHOD_NOT_IMPL } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';
import type { EncryptObjectAlgorithm, SymmetricKey } from '../types';

/** @public */
export class NonEncryptedObjectAlgorithmImpl extends Algorithm<any, any> implements EncryptObjectAlgorithm {
  public constructor() {
    super('NonEncryptedObjectAlgorithm', null);
  }

  public decrypt(__UNUSED_key: EncapsulatedKey, data: string, __UNUSED_version: number): Promise<any> {
    return Promise.resolve(JSON.parse(data));
  }

  public deriveKey(__UNUSED_key: EncapsulatedKey, __UNUSED_version: number): Promise<SymmetricKey> {
    return Promise.reject(new SigmailCryptoException(E_METHOD_NOT_IMPL, 'Method not implemented.'));
  }

  public encrypt(__UNUSED_key: EncapsulatedKey, data: any, __UNUSED_version: number): Promise<string> {
    return Promise.resolve(JSON.stringify(data));
  }

  public generateKey(): Promise<EncapsulatedKey> {
    return Promise.resolve(new Uint8Array());
  }
}
