import { MessagingActionPayload } from '@sigmail/app-state';
import { Constants, MessageFormName, MessagingException, ReadonlyMessageBodyReferral, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { DataObject, DataObjectMsgMetadataValue, DataObjectSigmailGlobalContactListValue } from '@sigmail/objects';
import { UseTranslationResponse } from 'react-i18next';
import { AppDispatch, AppThunk } from '../../..';
import { GUEST_CONTACT_GROUP, SIGMAIL_GROUP_PREFIX } from '../../../../constants/medical-institute-user-group-type-identifier';
import { ContactListItemUtil } from '../../../../utils/contact-list';
import { DataObjectCache } from '../../../data-objects-slice/cache';
import { RootState } from '../../../root-reducer';
import { authClaimSelector, currentUserIdSelector } from '../../../selectors/auth';
import { globalContactListIdSelector } from '../../../selectors/client-object';
import { dataObjectByIdSelector } from '../../../selectors/data-object';
import { ActionInitParams } from '../../base-action';
import { batchQueryDataAction } from '../../batch-query-data-action';
import { isMessageSensitivityNormal, isValidMessageSensitivity } from '../base-messaging-action';
import { AssignMessageAction } from './assign-message';
import { BaseSendMessageAction, BaseSendMessagePayload } from './base';
import { SendMessageToBillingProviderAction } from './billing-provider';
import { SendConsultationRequestAction } from './consultation-request';
import { Payload as SendEventMessagePayload, SendEventAction } from './event';
import { SendEventAttendanceMessageAction } from './event-attendance';
import { Payload as SendEventResponsePayload, SendEventResponseAction } from './event-response';
import { SendGroupInviteMessageAction } from './group-invite';
import { Payload as SendHealthDataRequestMessagePayload, SendHealthDataRequestMessageAction } from './health-data-request';
import { SendMessageToMailingListAction } from './mailing-list';
import { Payload as SendOneTimeMessageActionPayload, SendOneTimeMessageAction } from './one-time';
import { SendReferralMessageAction } from './referral';
import { Payload as SendReferralResponsePayload, SendReferralResponseAction } from './referral-response';
import { SendAsSigMailGroup } from './send-as-sigmail-group';

export { BillingNumberMissingError } from './billing-provider';

interface Payload extends MessagingActionPayload.SendMessage {
  translation?: UseTranslationResponse;
}

type RecipientListFlags = Record<`is${'CurrentUser' | 'BillingProvider' | 'GuestContactGroup' | 'Sender'}Included`, boolean>;

const RECIPIENT_LIST_FLAG_LIST: ReadonlyArray<keyof RecipientListFlags> = [
  'isCurrentUserIncluded',
  'isBillingProviderIncluded',
  'isGuestContactGroupIncluded',
  'isSenderIncluded'
];

const Logger = getLoggerWithPrefix('Action', 'sendMessageAction:');

const buildRecipientList = async ({
  primaryRecipientList,
  secondaryRecipientList
}: Payload): Promise<BaseSendMessagePayload['recipientList']> => {
  const recipientList = [primaryRecipientList, secondaryRecipientList].reduce((result, list) => {
    const entityType = list === primaryRecipientList ? 'primary' : 'secondary';

    const entityList = list.map<typeof result[0]>(({ keyId, ...contact }) => {
      let accountStatus: typeof result[0]['entity']['accountStatus'];
      if (contact.type === 'user' && (contact.userData as any).accountStatus === 'pending') {
        accountStatus = 'pending';
      }

      const recipient = ContactListItemUtil.toMessageRecipient(contact, entityType);
      return { entity: { ...recipient, accountStatus, keyId } };
    });

    result.push(...entityList);
    return result;
  }, [] as Array<BaseSendMessagePayload['recipientList'][0]>);

  return recipientList;
};

const buildRecipientListForReferralResponse = async (
  { sourceMessage }: Payload,
  dispatch: AppDispatch,
  getState: () => RootState
): Promise<BaseSendMessagePayload['recipientList']> => {
  const { header: sourceMsgMetadataId } = sourceMessage!;

  const contactListId = globalContactListIdSelector(getState())!;
  let dataObjectSelector = dataObjectByIdSelector(getState());
  let contactListObject = dataObjectSelector<DataObjectSigmailGlobalContactListValue>(contactListId);
  let contactList = DataObjectCache.getValue(contactListObject)!;
  let msgMetadataObject = dataObjectSelector<DataObjectMsgMetadataValue>(sourceMsgMetadataId);
  let msgMetadata = DataObjectCache.getValue(msgMetadataObject)!;

  const idList = [Utils.isNil(contactList) && contactListId, Utils.isNil(msgMetadata) && sourceMsgMetadataId].filter(DataObject.isValidId);
  if (idList.length > 0) {
    const authState = authClaimSelector(getState());
    await dispatch(batchQueryDataAction({ query: { authState, dataObjects: { ids: idList } } }));

    dataObjectSelector = dataObjectByIdSelector(getState());
    contactListObject = dataObjectSelector<DataObjectSigmailGlobalContactListValue>(contactListId!);
    // eslint-disable-next-line require-atomic-updates
    contactList = DataObjectCache.getValue(contactListObject)!;
    msgMetadataObject = dataObjectSelector<DataObjectMsgMetadataValue>(sourceMsgMetadataId);
    msgMetadata = DataObjectCache.getValue(msgMetadataObject)!;

    if (Utils.isNil(contactList)) {
      throw new MessagingException('Global contact list could not be fetched.');
    }

    if (Utils.isNil(msgMetadata)) {
      throw new MessagingException('Global contact list could not be fetched.');
    }
  }

  const replyTo = Utils.arrayOrDefault<NonNullable<typeof msgMetadata.replyTo>[0]>(msgMetadata.replyTo);
  if (replyTo.length === 0) replyTo.push(msgMetadata.sender);

  return replyTo.map(({ type, id }) => {
    const recipient = contactList.list.find((contact) => contact.type === type && contact.id === id);
    if (Utils.isNil(recipient)) {
      throw new MessagingException("Recipient contact's entry could not be found in global contact list.");
    }

    return { entity: ContactListItemUtil.toMessageRecipient(recipient, 'primary') };
  });
};

export const sendMessageAction = (payload: Payload): AppThunk<Promise<void>> => {
  return async (dispatch, getState, { apiService }) => {
    const {
      circleOfCareGroupId,
      documentList,
      folderKey,
      messageBody,
      messageKind,
      onBehalfOf,
      oneTimeExpire,
      parentFolderKey,
      selectedProviderFolder,
      sendAsSigMailGroup,
      sendToEMR,
      sendToHRM,
      sender,
      sourceMessage,
      subjectLine,
      translation
    } = payload;

    // use <default> when messageFormName is not set
    let messageFormName: MessageFormName = (messageBody as any).messageForm?.name!;
    if (Utils.isNil(messageFormName)) {
      messageFormName = Constants.MessageFormName.Default;
    }

    const isMessageFormDefault = Utils.isMessageFormNameDefault(messageFormName);
    const isMessageFormEvent = !isMessageFormDefault && Utils.isMessageFormNameEvent(messageFormName);
    const isEventResponse = isMessageFormEvent && Utils.isNotNil((messageBody as any).messageForm.value.response);
    const isMessageFormEventAttendance =
      !isMessageFormDefault && !isMessageFormEvent && Utils.isMessageFormNameEventAttendance(messageFormName);
    const isMessageFormReferral =
      !isMessageFormDefault && !isMessageFormEvent && !isMessageFormEventAttendance && Utils.isMessageFormNameReferral(messageFormName);
    const isReferralResponse =
      isMessageFormReferral && Utils.isNotNil((messageBody as ReadonlyMessageBodyReferral).messageForm.value.response);
    const isAcceptedReferral =
      isReferralResponse && (messageBody as ReadonlyMessageBodyReferral).messageForm.value.response!.status === 'accepted';
    const isOneTimeIncluded = isMessageFormDefault && Utils.isString(oneTimeExpire);
    const isSendAsSigMailGroup = isMessageFormDefault && Utils.isString(sendAsSigMailGroup);

    // messages only of <default> form can be assigned, forwarded, replied to, etc.
    if (!isMessageFormDefault && Utils.isNotNil(messageKind)) {
      throw new MessagingException('Invalid payload; expected <messageKind> to be nil.');
    }

    let { flags, sensitivity } = payload as Pick<BaseSendMessagePayload, 'flags' | 'sensitivity'>;
    const isBillingSubmission = (flags as any)?.billing === true;
    flags = { billable: flags?.billable === true, doNotReply: flags?.doNotReply === true, important: flags?.important === true };
    sensitivity = isValidMessageSensitivity(sensitivity) ? sensitivity : 'normal';

    const isMessageKindAssign = messageKind === 'assign';
    const isMessageKindDraft = !isMessageKindAssign && messageKind === 'draft';
    const isMessageKindForward = !isMessageKindAssign && !isMessageKindDraft && messageKind === 'forward';
    const isMessageKindReply =
      !isMessageKindAssign && !isMessageKindDraft && !isMessageKindForward && (messageKind === 'reply' || messageKind === 'replyAll');
    if (isMessageKindAssign) {
      // messages only from Group Inbox (or it's subfolder) can be assigned
      if (![folderKey, parentFolderKey].includes(Constants.MessageFolderKey.GroupInbox)) {
        throw new MessagingException(
          `Invalid payload; expected <folderKey> or <parentFolderKey> to be <${Constants.MessageFolderKey.GroupInbox}>.`
        );
      }

      // sensitivity for an assigned message cannot be anything other than normal
      if (!isMessageSensitivityNormal(sensitivity)) {
        throw new MessagingException('Invalid payload; expected <sensitivity> to be <normal>.');
      }
    } else if (isMessageKindForward || isMessageKindReply) {
      if (!Utils.isString(folderKey)) {
        throw new MessagingException('Invalid payload; expected <folderKey> to be of type string.');
      }
    }

    let isConsultationResponse = false;
    if (isMessageKindAssign || isMessageKindDraft || isMessageKindForward || isMessageKindReply || isReferralResponse) {
      // sourceMessage can't be nil if message kind is specified, or message
      // is a response to a referral
      if (Utils.isNil(sourceMessage)) {
        throw new MessagingException('Invalid payload; expected <sourceMessage> to not be nil.');
      }

      isConsultationResponse = isMessageKindReply && Utils.isConsultationMessageForm(sourceMessage.messageForm);
    } else if (Utils.isNotNil(sourceMessage)) {
      throw new MessagingException('Invalid payload; expected <sourceMessage> to be nil.');
    }

    let recipientList: BaseSendMessagePayload['recipientList'];
    if (isReferralResponse) {
      if (isAcceptedReferral && !Utils.isInteger(circleOfCareGroupId)) {
        throw new MessagingException('Invalid payload; expected <circleOfCareGroupId> to be an integer.');
      }

      recipientList = await buildRecipientListForReferralResponse(payload, dispatch, getState);

      // for referral responses, recipient list can't be empty
      if (recipientList.length === 0) {
        throw new MessagingException('Invalid payload; recipient list is empty.');
      }
    } else {
      if (Utils.isNotNil(circleOfCareGroupId)) {
        throw new MessagingException('Invalid payload; expected <circleOfCareGroupId> to be nil.');
      }

      // combine both primary and secondary recipient lists
      recipientList = await buildRecipientList(payload);

      // for non-referral-response messages, call is simply ignored if recipient
      // list is empty; typically, this shouldn't be the case
      if (recipientList.length === 0) {
        Logger.warn('Call ignored; recipient list is empty.');
        return;
      }
    }

    if (Utils.isNotNil(onBehalfOf)) {
      if (!isMessageFormDefault) {
        throw new MessagingException(`Invalid payload; expected <messageForm> to be <${Constants.MessageFormName.Default}>.`);
      }

      if (isMessageKindAssign || isMessageKindForward || isMessageKindReply) {
        throw new MessagingException('Invalid payload; expected <messageKind> to be nil.');
      }

      if (!isMessageSensitivityNormal) {
        throw new MessagingException('Invalid payload; expected <sensitivity> to be <normal>.');
      }

      if (onBehalfOf.id === sender.id) {
        throw new MessagingException('Invalid payload; onBehalfOf.id cannot be the same as sender.id.');
      }

      if (recipientList.some(({ entity: { id } }) => id === onBehalfOf.id)) {
        throw new MessagingException('Invalid payload; onBehalfOf.id cannot be included in the recipient list.');
      }
    }

    const currentUserId = currentUserIdSelector(getState());
    const { isCurrentUserIncluded, isBillingProviderIncluded, isGuestContactGroupIncluded } = recipientList.reduce(
      (flags, { entity: recipient }) => {
        const recipientFlags: typeof flags = {
          isCurrentUserIncluded: recipient.id === currentUserId,
          isBillingProviderIncluded: recipient.id === process.env.ACCOUNT_ID_BILLING_PROVIDER,
          isGuestContactGroupIncluded: recipient.type === 'group' && recipient.groupType === GUEST_CONTACT_GROUP,
          isSenderIncluded: recipient.id === sender.id
        };

        RECIPIENT_LIST_FLAG_LIST.forEach((key) => (flags[key] = flags[key] || recipientFlags[key]));
        return flags;
      },
      {
        isCurrentUserIncluded: false,
        isBillingProviderIncluded: false,
        isGuestContactGroupIncluded: false,
        isSenderIncluded: false
      } as RecipientListFlags
    );

    if (!isGuestContactGroupIncluded) {
      let guestContactCount = 0;
      for (const { entity } of recipientList) {
        guestContactCount += Number(entity.type === 'user' && Utils.isGuestRole(entity.role) && entity.isOneTimeContact !== true);
      }

      if (guestContactCount > 1) {
        throw new MessagingException(
          `Invalid payload; expected at most one guest role entry in recipient list, found ${guestContactCount}.`
        );
      }
    }

    if (isMessageFormDefault) {
      // when message is being sent to either billing provider or a guest
      // contact group, no other contacts may be included in the recipient list
      if ((isBillingProviderIncluded || isGuestContactGroupIncluded) && recipientList.length !== 1) {
        throw new MessagingException('Expected at most one recipient entry.');
      }
    } else {
      // current user's entry may be included in the recipient list only if
      // messageFormName is set to <default> or <event>
      if (isCurrentUserIncluded && !isMessageFormEvent && !isMessageFormEventAttendance) {
        throw new MessagingException(
          `Current user may not be included in the recipient list when <messageFormName> is ${messageFormName}.`
        );
      }

      // recipient list may have a Billing Provider ID entry only if
      // messageFormName is set to <default>
      if (isBillingProviderIncluded) {
        throw new MessagingException(`DoctorCare may not be included in the recipient list when <messageFormName> is ${messageFormName}.`);
      }

      // recipient list may have a guest contact group entry only if
      // messageFormName is set to <default>
      if (isGuestContactGroupIncluded) {
        throw new MessagingException(
          `Guest contact group may not be included in the recipient list when <messageFormName> is ${messageFormName}.`
        );
      }
    }

    const params: ActionInitParams<BaseSendMessagePayload> = {
      apiService,
      dispatch,
      getState,
      logger: Logger,
      payload: {
        circleOfCareGroupId,
        documentList,
        flags,
        folderKey,
        messageBody,
        messageKind,
        messageFormName,
        oneTimeExpire,
        parentFolderKey,
        recipientList,
        selectedProviderFolder,
        sendToEMR,
        sendToHRM,
        sender,
        sensitivity,
        sourceMessage,
        subjectLine
      }
    };

    let action: BaseSendMessageAction;
    switch (messageFormName) {
      case Constants.MessageFormName.Default: {
        // when the message being sent is the one which was previously saved as
        // draft, then current user is expected to be the sender of the message
        if (isMessageKindDraft && sender.id !== currentUserId) {
          throw new MessagingException('Expected sender to be the current user.');
        }

        if (isMessageKindAssign) {
          action = new AssignMessageAction(params);
        } else if (isGuestContactGroupIncluded) {
          action = new SendMessageToMailingListAction(params);
        } else if (isConsultationResponse) {
          // SendConsultationRequestAction not only handles sending a new
          // message of form <eConsult> but it also handles replies to a
          // consultation request message
          action = new SendConsultationRequestAction({
            ...params,
            payload: { ...params.payload, isReplyToConsultationRequest: isConsultationResponse }
          });
        } else if (isBillingProviderIncluded && isBillingSubmission) {
          action = new SendMessageToBillingProviderAction(params);
        } else if (isOneTimeIncluded) {
          action = new SendOneTimeMessageAction({
            ...params,
            payload: { ...params.payload, translation }
          } as ActionInitParams<SendOneTimeMessageActionPayload>);
        } else if (isSendAsSigMailGroup) {
          if (!sendAsSigMailGroup.startsWith(SIGMAIL_GROUP_PREFIX)) {
            throw new MessagingException(
              Constants.Error.S_ERROR,
              `Invalid payload; expected <groupType> to be a string starting with "${SIGMAIL_GROUP_PREFIX}"; was ${sendAsSigMailGroup}`
            );
          }

          action = new SendAsSigMailGroup({
            ...params,
            payload: {
              ...params.payload,
              groupType: sendAsSigMailGroup
            }
          });
          break;
        } else {
          action = new BaseSendMessageAction(params);
        }

        break;
      }
      case Constants.MessageFormName.EConsult: {
        action = new SendConsultationRequestAction(params);
        break;
      }
      case Constants.MessageFormName.Event: {
        if (isEventResponse) {
          action = new SendEventResponseAction(params as ActionInitParams<SendEventResponsePayload>);
        } else {
          action = new SendEventAction(params as ActionInitParams<SendEventMessagePayload>);
        }

        break;
      }
      case Constants.MessageFormName.EventAttendance: {
        action = new SendEventAttendanceMessageAction(params);
        break;
      }
      case Constants.MessageFormName.HealthDataRequest: {
        action = new SendHealthDataRequestMessageAction(params as ActionInitParams<SendHealthDataRequestMessagePayload>);
        break;
      }
      case Constants.MessageFormName.JoinGroupInvitation: {
        action = new SendGroupInviteMessageAction(params);
        break;
      }
      case Constants.MessageFormName.Referral: {
        if (isReferralResponse) {
          action = new SendReferralResponseAction(params as ActionInitParams<SendReferralResponsePayload>);
        } else {
          action = new SendReferralMessageAction(params);
        }
        break;
      }
      default: {
        throw new MessagingException(`Unhandled case - ${messageFormName}`);
      }
    }

    return action.execute();
  };
};
