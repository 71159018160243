import type { MemberRole } from '../types';

export const ROLE_ID_ADMIN_MESSAGING_USER: Extract<MemberRole, 'adminMessagingUser'> = 'adminMessagingUser';
export const ROLE_ID_ADMIN_PHYSICIAN: Extract<MemberRole, 'admin'> = 'admin';
export const ROLE_ID_ADMIN_STAFF: Extract<MemberRole, 'adminStaff'> = 'adminStaff';
export const ROLE_ID_BASIC_PHYSICIAN: Extract<MemberRole, 'basicPhysician'> = 'basicPhysician';
export const ROLE_ID_CAREGIVER: Extract<MemberRole, 'caregiver'> = 'caregiver';
export const ROLE_ID_GUEST: Extract<MemberRole, 'patient'> = 'patient';
export const ROLE_ID_JUNIOR_PHYSICIAN: Extract<MemberRole, 'physician'> = 'physician';
export const ROLE_ID_MESSAGING_USER: Extract<MemberRole, 'messagingUser'> = 'messagingUser';
export const ROLE_ID_SENIOR_PHYSICIAN: Extract<MemberRole, 'physician+'> = 'physician+';
export const ROLE_ID_SIGMAIL_ADMIN: Extract<MemberRole, 'sigMailAdmin'> = 'sigMailAdmin';
export const ROLE_ID_STAFF: Extract<MemberRole, 'staff'> = 'staff';

// eslint-disable-next-line @typescript-eslint/ban-types
export const ROLE_ID_LIST: ReadonlyArray<MemberRole | (string & {})> = [
  ROLE_ID_ADMIN_MESSAGING_USER,
  ROLE_ID_ADMIN_PHYSICIAN,
  ROLE_ID_ADMIN_STAFF,
  ROLE_ID_BASIC_PHYSICIAN,
  ROLE_ID_CAREGIVER,
  ROLE_ID_GUEST,
  ROLE_ID_JUNIOR_PHYSICIAN,
  ROLE_ID_MESSAGING_USER,
  ROLE_ID_SENIOR_PHYSICIAN,
  ROLE_ID_SIGMAIL_ADMIN,
  ROLE_ID_STAFF
];
