import type {
  AlgorithmCode,
  EncapsulatedKey,
  EncryptWithParametersAlgorithmCode,
  Nullable,
  SigmailObjectId,
  SigmailObjectTypeCode
} from '@sigmail/common';
import { Utils } from '@sigmail/common';
import type { EncryptWithParametersAlgorithmParams } from '@sigmail/crypto';
import { Algorithm, Constants, getAlgorithm, Hash, SigmailCryptoException } from '@sigmail/crypto';
import { CryptographicKey } from '.';

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_RECOVERY;

/** @public */
export class CryptographicKeyRecovery extends CryptographicKey<JsonWebKey> {
  protected static override get DEFAULT_CODE(): EncryptWithParametersAlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS as EncryptWithParametersAlgorithmCode;
  }

  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static async generateKey(
    keyCode: AlgorithmCode,
    keyParams: Array<EncryptWithParametersAlgorithmParams>
  ): Promise<EncapsulatedKey> {
    if (!Algorithm.isValidEncryptWithParametersCode(keyCode)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }

    const algorithm = getAlgorithm(keyCode);

    let encapsulatedKey = new Uint8Array();
    for (const params of keyParams) {
      const key = await algorithm.generateKey(params);
      const mergedKey = new Uint8Array(encapsulatedKey.length + key.length);
      mergedKey.set(encapsulatedKey);
      mergedKey.set(key, encapsulatedKey.length);
      encapsulatedKey = mergedKey;
    }

    return Hash.SHA256(encapsulatedKey);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public static async create(
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number,
    value: JsonWebKey,
    encryptedFor: number,
    params: Array<EncryptWithParametersAlgorithmParams>,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  ) {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;

    if (!Algorithm.isValidEncryptWithParametersCode(keyCode)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }

    const encapsulatedKey = await this.generateKey(keyCode, params);
    const algorithm = getAlgorithm(keyCode);
    const encryptedValue = await algorithm.encrypt(encapsulatedKey, value, version);

    const args = [id, keyCode, version, encryptedValue, encryptedFor, createdAtUtc, expiredAtUtc];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Reflect.construct(this, args);
  }

  public async decryptedValue(params: Array<EncryptWithParametersAlgorithmParams>): Promise<JsonWebKey> {
    if (!Algorithm.isValidEncryptWithParametersCode(this.code)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }

    const Class = this.constructor as typeof CryptographicKeyRecovery;
    const encapsulatedKey = await Class.generateKey(this.code, params);
    const algorithm = getAlgorithm(this.code);

    return algorithm.decrypt(encapsulatedKey, this.value, this.version) as JsonWebKey;
  }
}
