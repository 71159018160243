import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.View.JoinGroupInvitation = {
  action: {
    acceptJoinGroupInvitation: {
      // Translators: Label for the Accept Invitation action in Join Group \
      // Invitation message view
      label: gettext('messaging', 'accept', 'joinGroupInvitationMessageViewActionLabel')
    },
    declineJoinGroupInvitation: {
      // Translators: Label for the Decline Invitation action in Join Group \
      // Invitation message view
      label: gettext('messaging', 'decline', 'joinGroupInvitationMessageViewActionLabel')
    }
  },
  dataGroup: {
    inviter: {
      // Translators: Label for the Inviter data group in Join Group \
      // Invitation message view
      label: gettext('messaging', 'inviter', 'joinGroupInvitationMessageViewDataGroupLabel'),
      item: {
        // Translators: Label for the Inviter's First Name data group item in \
        // Join Group Invitation message view
        firstName: gettext('messaging', 'firstNameInviter', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Inviter's Last Name data group item in \
        // Join Group Invitation message view
        lastName: gettext('messaging', 'lastNameInviter', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Inviter's Office Number data group item \
        // in Join Group Invitation message view
        officeNumber: gettext('messaging', 'officeNumberInviter', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Inviter's Cell Number group item in \
        // Join Group Invitation message view
        cellNumber: gettext('messaging', 'cellNumberInviter', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Inviter's Institute Name data group item \
        // in Join Group Invitation message view
        institute: gettext('messaging', 'instituteNameInviter', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Inviter's Institute Address data group \
        // item in Join Group Invitation message view
        instituteAddress: gettext('messaging', 'instituteAddressInviter', 'joinGroupInvitationMessageViewDataGroupItemLabel')
      }
    },
    group: {
      // Translators: Label for the Group Details data group in Join Group \
      // Invitation message view
      label: gettext('messaging', 'group', 'joinGroupInvitationMessageViewDataGroupLabel'),
      item: {
        // Translators: Label for the Group Name data group item in Join Group \
        // Invitation message view
        name: gettext('messaging', 'groupName', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Group's Institute Name data group item \
        // in Join Group Invitation message view
        institute: gettext('messaging', 'instituteNameGroup', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Group's Institute Address data group \
        // item in Join Group Invitation message view
        address: gettext('messaging', 'instituteAddressGroup', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Group's Province data group item in \
        // Join Group Invitation message view
        addressLevel1: gettext('messaging', 'addressLevel1Group', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Group's City data group item in Join \
        // Group Invitation message view
        addressLevel2: gettext('messaging', 'addressLevel2Group', 'joinGroupInvitationMessageViewDataGroupItemLabel'),
        // Translators: Label for the Group's Phone Number group item in \
        // Join Group Invitation message view
        phoneNumber: gettext('messaging', 'phoneNumberGroup', 'joinGroupInvitationMessageViewDataGroupItemLabel')
      }
    }
  }
};

export default i18n;
