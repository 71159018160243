import { Constants, Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { useDispatchFetchUserObjects } from '../../../app-state/hooks';
import { currentUserIdSelector, selectAccessRight } from '../../../app-state/selectors/auth';
import { messageFolderMapSelector as userMessageFolderMapSelector } from '../../../app-state/selectors/user-object';
import { UNRESOLVED_RESOURCE } from '../../../constants';

export interface UserMessageFolderMapResource
  extends ISuspenseResource<ReturnType<ReturnType<typeof userMessageFolderMapSelector>>> {}

export const useUserMessageFolderMapResource = () => {
  const [resource, setResource] = React.useState<UserMessageFolderMapResource>(UNRESOLVED_RESOURCE);
  const canAccessMailbox = useSelector(selectAccessRight)('accessMailbox');

  const userId = useSelector(currentUserIdSelector);
  const folderMap = useSelector(userMessageFolderMapSelector)();
  const dispatchFetchUserObjects = useDispatchFetchUserObjects();

  const prevFolderMap = React.useRef<typeof folderMap>();
  const isFetchDispatched = React.useRef<boolean>(false);
  React.useEffect(() => {
    if (canAccessMailbox) {
      if (Utils.isNil(folderMap[Constants.MessageFolderKey.Inbox])) {
        prevFolderMap.current = undefined;
        if (isFetchDispatched.current === false && Utils.isInteger(userId)) {
          isFetchDispatched.current = true;
          dispatchFetchUserObjects({
            userObjects: [{ type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST, userId }]
          }).finally(() => {
            isFetchDispatched.current = false;
          });
        }
        return setResource(UNRESOLVED_RESOURCE);
      }
    }

    if (prevFolderMap.current !== folderMap) {
      prevFolderMap.current = folderMap;
      setResource({ value: () => folderMap });
    }
  }, [canAccessMailbox, dispatchFetchUserObjects, folderMap, userId]);
  return resource;
};
