import { isNonArrayObjectLike, trimOrDefault } from '.';
import { POSTAL_ADDRESS_KEY_LIST } from '../constants/postal-address-key-list';
import type { NullableProps, PostalAddress } from '../types';

export function joinPostalAddress(props?: unknown): string {
  if (!isNonArrayObjectLike<NullableProps<PostalAddress>>(props)) return '';

  return POSTAL_ADDRESS_KEY_LIST.reduce<Array<string>>((values, key) => {
    const value = trimOrDefault(props[key]);
    if (value.length > 0) values.push(value);
    return values;
  }, []).join('\x20');
}
