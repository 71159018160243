import { Constants } from '@sigmail/common';

export class TextStatistics {
  //
  // @see https://github.com/apache/tika/blob/main/tika-core/src/main/java/org/apache/tika/detect/TextStatistics.java
  //

  private readonly counts = new Array(256).fill(0);
  private total = 0;

  public count(byte: number): number;

  public count(from: number, to: number): number;

  public count(...args: [from: number, to?: number]): number {
    const from = args[0]!;
    const to = args.length < 2 ? from + 1 : args[1]!;

    if (!Number.isInteger(from) || Math.floor(from) !== from) {
      throw new TypeError('Argument <from> must be a valid integer.');
    }

    if (from < 0 || from > 255) {
      throw new TypeError('Argument <from> must be between 0 and 255.');
    }

    if (!Number.isInteger(to) || Math.floor(to) !== to) {
      throw new TypeError('Argument <to> must be a valid integer.');
    }

    if (to < 0 || to > 256) {
      throw new TypeError('Argument <to> must be between 0 and 256.');
    }

    let c = 0;
    for (let byte = from; byte < to; byte++) {
      c = c + this.counts[byte & 0xff];
    }

    return c;
  }

  public countSafeControl(): number {
    return (
      this.count(0x09) + // horizontal tab
      this.count(0x0a) + // line feed
      this.count(0x0c) + // form feed
      this.count(0x0d) + // carriage return
      this.count(0x1b) // escape
    );
  }

  public addData(data: Uint8Array, offset: number, length: number): void {
    if (!Number.isInteger(offset) || Math.floor(offset) !== offset) {
      throw new TypeError('Argument <offset> must be a valid integer.');
    }

    if (offset < 0) {
      throw new TypeError('Argument <offset> may not be less than zero.');
    }

    if (!Number.isInteger(length) || Math.floor(length) !== length) {
      throw new TypeError('Argument <length> must be a valid integer.');
    }

    if (length < 0) {
      throw new TypeError('Argument <length> may not be less than zero.');
    }

    const { length: byteLength } = data;

    for (let index = 0; index < length; index++) {
      if (offset + index >= byteLength) break;

      const byte = data[offset + index] & 0xff;
      this.counts[byte] = this.counts[byte] + 1;
      this.total = this.total + 1;
    }
  }

  public isMostlyAscii(): boolean {
    const control = this.count(0x00, 0x20);
    const ascii = this.count(0x20, 0x80);
    const safe = this.countSafeControl();

    // pass if bytes seen were mostly plain text (i.e. < 2% control, > 90% ASCII range)
    return this.total > 0 && (control - safe) * 100 < this.total * 2 && (ascii + safe) * 100 > this.total * 90;
  }

  public looksLikeUTF8(): boolean {
    const control = this.count(0x00, 0x20);
    let utf8 = this.count(0x20, 0x80);
    const safe = this.countSafeControl();

    const continuation = this.count(0x80, 0xc0);
    let expectedContinuation = 0;
    const leading = [this.count(0xc0, 0xe0), this.count(0xe0, 0xf0), this.count(0xf0, 0xf8)];
    for (let i = 0; i < leading.length; i++) {
      utf8 = utf8 + leading[i];
      expectedContinuation = expectedContinuation + (i + 1) * leading[i];
    }

    return (
      utf8 > 0 &&
      continuation <= expectedContinuation &&
      continuation >= expectedContinuation - 3 &&
      this.count(0xf8, 0x100) === 0 &&
      (control - safe) * 100 < utf8 * 2
    );
  }
}

export const detectFileType = (data: Uint8Array): string => {
  let mime = 'application/octet-stream';

  if (data.length >= 16 && data[0] === 0x89) {
    //
    // possibly PNG
    //

    const view = new DataView(data.buffer);
    const magic1 = view.getUint32(0);
    const magic2 = view.getUint32(4);
    const chunkName = view.getUint32(12);

    if (
      magic1 === 0x89504e47 /* 0x89, P, N, G */ &&
      magic2 === 0x0d0a1a0a /* CR, LF, SUB, LF */ &&
      chunkName === 0x49484452 /* I, H, D, R */
    ) {
      mime = Constants.MimeType.PNG;
    }
  } else if (data.length >= 4 && data[0] === 0x25) {
    //
    // possibly PDF / AI (Adobe Illustrator)
    //

    const view = new DataView(data.buffer);
    const magic = view.getUint32(0);

    if (magic === 0x25504446 /* %, P, D, F */) {
      return Constants.MimeType.PDF;
    }
  } else if (data.length >= 3 && data[0] === 0xff) {
    //
    // possibly JPEG
    //

    const view = new DataView(data.buffer);
    const magic1 = view.getUint16(0);
    const magic2 = view.getUint8(2);

    if (magic1 === 0xffd8 && magic2 === 0xff) {
      return Constants.MimeType.JPEG;
    }
  } else if (data.length > 0) {
    //
    // check for possible plain text data
    //

    const stats = new TextStatistics();
    stats.addData(data, 0, 512);
    if (stats.isMostlyAscii() || stats.looksLikeUTF8()) {
      mime = Constants.MimeType.TEXT_PLAIN;
    }
  }

  return mime;
};
