const VALID_DATA_URI_REGEX = /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z0-9-.!#$%*+.{}|~`]+=[a-z0-9-.!#$%*+.{}()|~`]+)*)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*?)$/i;

export type ParsedDataUri = {
  base64: boolean;
  data: string;
  mediaTypeParameters: Record<string, string>;
  rawData: any;
} & (
  | { isValid: false; contentType: undefined; mediaType: undefined }
  | { isValid: true; contentType: string; mediaType: string }
);

export function parseDataUri(data: any): ParsedDataUri {
  const parsed = {
    base64: false,
    data: '',
    isValid: VALID_DATA_URI_REGEX.test(data),
    mediaTypeParameters: {},
    rawData: data
  } as ParsedDataUri;

  if (!parsed.isValid) return parsed;

  const parts = data.trim().match(VALID_DATA_URI_REGEX);
  if (parts[1]) {
    parsed.mediaType = parts[1].toLowerCase();

    const mediaTypeParts = parsed.mediaType!.split(';');
    parsed.contentType = mediaTypeParts[0];

    mediaTypeParts.slice(1).forEach((attribute: string) => {
      const p = attribute.split('=');
      parsed.mediaTypeParameters[p[0]] = p[1];
    });
  }

  parsed.base64 = !!parts[parts.length - 2];
  parsed.data = parts[parts.length - 1] || '';

  return parsed;
}
