import { Utils } from '@sigmail/common';
import i18next, { StringMap, TFunction, TOptions } from 'i18next';
import XhrBackend from 'i18next-xhr-backend';
import React from 'react';
import {
  initReactI18next,
  useTranslation as i18nextUseTranslation,
  UseTranslationOptions,
  UseTranslationResponse,
  withTranslation as i18nextWithTranslation,
  WithTranslation
} from 'react-i18next';
import { MutableRef } from 'sigmail';
import { Default as DefaultLanguageCode } from '../constants/language-codes';
import i18nConfig, { I18N_CONTEXT_SEPARATOR, I18N_NS_SEPARATOR } from './config';
import { I18N_NS_GLOBAL } from './config/namespace-identifiers';

const REGEX_VALID_DOMAIN_NAME = /^[a-z0-9_.-]+$/i;
const I18N_T_PREFIX = 'T$';

export function ngettext(...args: Parameters<typeof gettext>): ReturnType<typeof gettext> {
  return gettext(...args);
}

export function gettext(
  domain: string | undefined,
  context: string | undefined,
  singular_key: string,
  _flags?: string | undefined,
  _comment?: string | undefined
) {
  const ns = (typeof domain === 'string' && domain) || I18N_NS_GLOBAL;
  if (!REGEX_VALID_DOMAIN_NAME.test(ns)) {
    throw new Error('Invalid domain name.');
  }

  let keySuffix = '';
  if (typeof context === 'string' && typeof I18N_CONTEXT_SEPARATOR === 'string') {
    const trimmedContext = context.trim();
    if (trimmedContext.length > 0) {
      keySuffix = I18N_CONTEXT_SEPARATOR + context.trim();
    }
  }

  return I18N_T_PREFIX + ns + I18N_NS_SEPARATOR + singular_key + keySuffix;
}

export function $t(t: TFunction, key: string | string[], options?: TOptions<StringMap> | string): string {
  const keys: string[] = [...(Utils.isArray(key) ? key : [key])].map((value) =>
    Utils.isString(value) && value.startsWith(I18N_T_PREFIX) ? value.substring(I18N_T_PREFIX.length) : value
  );
  return t(keys, options);
}

export function useTranslation(...args: Parameters<typeof i18nextUseTranslation>): UseTranslationResponse {
  const { t: i18nextT, i18n, ready } = i18nextUseTranslation(...args);

  const prevI18nextT = React.useRef<typeof i18nextT>();
  const prevT = React.useRef<typeof i18nextT>();
  return React.useMemo(() => {
    let { current: t } = prevI18nextT;
    if (t !== i18nextT) {
      t = $t.bind(null, i18nextT);
      prevI18nextT.current = i18nextT;
      prevT.current = t;
    } else {
      t = prevT.current;
    }

    return Object.assign([t, i18n, ready] as UseTranslationResponse, { t, i18n, ready });
  }, [i18nextT, i18n, ready]);
}

export function withTranslation(...args: Parameters<typeof i18nextWithTranslation>): ReturnType<typeof i18nextWithTranslation> {
  // @ts-expect-error
  return <P extends WithTranslation, T = unknown>(WrappedComponent: React.ComponentType<P>) => {
    function ComponentWithTranslation(props: P) {
      let options: UseTranslationOptions | undefined = undefined;
      const { i18n: i18nFromProps, useSuspense, forwardedRef } = props as any;
      if (Utils.isNotNil(i18nFromProps) || typeof useSuspense === 'boolean') {
        options = {};
        if (Utils.isNotNil(i18nFromProps)) options.i18n = i18nFromProps;
        if (useSuspense === true) options.useSuspense = useSuspense;
      }

      const [t, i18n, ready] = i18nextUseTranslation(args[0], options);
      const passDownProps: any = { ...Utils.omit(props, 'forwardedRef'), t: $t.bind(null, t), i18n, tReady: ready };
      if (forwardedRef) {
        passDownProps[args[1]?.withRef === true ? 'ref' : 'forwardedRef'] = forwardedRef;
      }

      return React.createElement(WrappedComponent, passDownProps);
    }

    const wrappedComponentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'Unknown';
    ComponentWithTranslation.displayName = `WithTranslationComponent(${wrappedComponentDisplayName})`;
    (ComponentWithTranslation as any).WrappedComponent = WrappedComponent;

    if (args[1]?.withRef === true) {
      function ComponentWithTranslationAndRef(props: P, ref: MutableRef<T>) {
        return React.createElement(ComponentWithTranslation, Object.assign({}, props, { forwardedRef: ref }));
      }
      return React.forwardRef(ComponentWithTranslationAndRef);
    }

    return ComponentWithTranslation;
  };
}

const i18nInstance = i18next
  .createInstance()
  .use({
    type: 'languageDetector',
    async: false,
    init: () => {},
    detect: () => DefaultLanguageCode, // TODO revise
    cacheUserLanguage: () => {}
  })
  .use(XhrBackend)
  .use(initReactI18next);

i18nInstance.init(i18nConfig);

export default i18nInstance;
