import { deriveSession } from 'secure-remote-password/client';
import type { SrpSession } from '../types';

export function srpDeriveSession(
  clientSecretEphemeral: string,
  serverPublicEphemeral: string,
  salt: string,
  username: string,
  privateKey: string
): SrpSession {
  return deriveSession(clientSecretEphemeral, serverPublicEphemeral, salt, username, privateKey);
}
