import { createSlice } from '@reduxjs/toolkit';
import { StoreStateUserObjects, UserObjectsRecord, UserObjectType } from '@sigmail/app-state';
import Immutable from 'immutable';
import { signOutAction } from '../actions';
import { authFailure } from '../auth-slice';
import { setStateReducer } from './set-state-reducer';

export const INITIAL_STATE: StoreStateUserObjects = Immutable.Map<UserObjectType, UserObjectsRecord>();

const userObjectsSlice = createSlice({
  name: 'userObjects',
  initialState: INITIAL_STATE,
  reducers: {
    setState: setStateReducer
  },
  extraReducers: (builder) => {
    builder.addCase(signOutAction, () => INITIAL_STATE);
    builder.addCase(authFailure, () => INITIAL_STATE);
  }
});

export const { setState } = userObjectsSlice.actions;
export const { reducer: userObjectReducer } = userObjectsSlice;
