function cssPercentage(num) {
  return `${Number(Math.round(num + 'e6') + 'e-6')}%`;
}

// IMPORTANT value MUST be an even number
const GRID_GUTTER_WIDTH = 24; // in pixels

const col = {
  position: 'relative',
  paddingLeft: `${GRID_GUTTER_WIDTH / 2}px`,
  paddingRight: `${GRID_GUTTER_WIDTH / 2}px`,
  width: '100%'
};

const colUtilities = {
  '.row': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: `-${GRID_GUTTER_WIDTH / 2}px`,
    marginRight: `-${GRID_GUTTER_WIDTH / 2}px`
  },
  '.col': col,
  '.col-equal': {
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '100%'
  }
};

for (let i = 1; i <= 12; i++) {
  const per = cssPercentage((i / 12) * 100);

  colUtilities[`.col-${i}`] = Object.assign({}, col, {
    flex: `0 0 ${per}`,
    maxWidth: per
  });

  colUtilities[`.offset-${i}`] = {
    marginLeft: per
  };
}

module.exports = {
  GRID_GUTTER_WIDTH,
  default: ({ addUtilities }) => addUtilities(colUtilities)
};
