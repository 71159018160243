import { AppException, AppUser, Constants, ReadonlyMessageBodyReferral, SigmailUserId, Utils } from '@sigmail/common';
import { UserContactListItem } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { sendMessageAction } from '../../../../app-state/actions/messaging/send-message-action';
import { useAppState } from '../../../../app-state/hooks';
import { activeGroupIdSelector } from '../../../../app-state/selectors';
import { personNameSelector } from '../../../../app-state/selectors/user-object';
import { CIRCLE_OF_CARE } from '../../../../constants/medical-institute-user-group-type-identifier';
import { FormValues } from '../../forms/compose-referral-form.component';
import { DocumentListItem } from '../../hooks';
import { ActionData } from '../../types';

export interface ActionDataSendReferral extends ActionData, FormValues {
  documentList: ReadonlyArray<DocumentListItem>;
}

export const useSendReferralActionHandler = (currentUserId: SigmailUserId) => {
  const [rootState, appDispatch] = useAppState();
  const activeGroupId = useSelector(activeGroupIdSelector);
  const senderName = personNameSelector(rootState)(/***/);

  return React.useCallback(
    async ({
      ccReplyToSharedInbox,
      department: specialty,
      documentList,
      failCallback,
      guest,
      icdCode,
      notes,
      reason,
      recipient,
      successCallback,
      urgent
    }: ActionDataSendReferral) => {
      // NOTE: subject line is intentionally not translated
      const subjectLine = `Referral for ${Utils.joinPersonName((guest as UserContactListItem).userData)}`;

      const messageBody: ReadonlyMessageBodyReferral = {
        messageForm: {
          name: Constants.MessageFormName.Referral,
          value: {
            ccReplyToGroupInbox:
              ccReplyToSharedInbox === true
                ? [{ type: 'group', id: activeGroupId, groupType: CIRCLE_OF_CARE }]
                : undefined,
            icdCode,
            notes,
            patient: { type: 'user', id: guest.id },
            reason,
            referrer: { type: 'user', id: currentUserId },
            referToList: [Utils.omit(recipient, recipient.type === 'group' ? 'groupData' : 'userData')],
            specialty,
            urgent
          }
        }
      };

      let value: any;

      try {
        if (!AppUser.isValidId(currentUserId)) {
          throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID);
        }

        value = await appDispatch(
          sendMessageAction({
            sender: { type: 'user', id: currentUserId, ...senderName },
            subjectLine,
            primaryRecipientList: [recipient],
            secondaryRecipientList: [],
            documentList,
            messageBody
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [activeGroupId, appDispatch, currentUserId, senderName]
  );
};
