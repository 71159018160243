import React from 'react';
import { saveAsDraftAction } from '../../../../app-state/actions/messaging/save-as-draft-action';
import { useAppState, useCurrentUser } from '../../../../app-state/hooks';
import { FieldNameOptionsFieldset, FormValues } from '../../forms/compose-message-form.component';
import { DocumentListItem } from '../../hooks';
import { ActionData } from '../../types';
import { ComposeMessageViewState, MessageKind } from '../context';

export interface ActionDataSaveAsDraft
  extends ActionData,
    Omit<FormValues, Exclude<FieldNameOptionsFieldset, 'sensitivity'>> {
  documentList: ReadonlyArray<DocumentListItem>;
  flags: Pick<FormValues, Exclude<FieldNameOptionsFieldset, 'sensitivity'>>;
  messageKind?: MessageKind;
  sourceMessage?: ComposeMessageViewState['sourceMessage'];
}

export const useSaveAsDraftActionHandler = (currentUser: ReturnType<typeof useCurrentUser>) => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({
      documentList,
      failCallback,
      flags,
      messageBody,
      messageKind,
      primary: primaryRecipientList,
      secondary: secondaryRecipientList,
      sensitivity,
      sourceMessage,
      subjectLine,
      successCallback
    }: ActionDataSaveAsDraft) => {
      const { id, prefix, firstName, middleName, lastName, suffix } = currentUser!;

      let value: any;
      try {
        value = await appDispatch(
          saveAsDraftAction({
            documentList,
            flags,
            messageBody: { data: messageBody },
            messageKind: messageKind as Exclude<typeof messageKind, 'assign'>,
            primaryRecipientList,
            secondaryRecipientList,
            sender: { type: 'user', id, prefix, firstName, middleName, lastName, suffix },
            sensitivity,
            sourceMessage,
            subjectLine
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch, currentUser]
  );
};
