import { Utils } from '@sigmail/common';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { useThrowAsyncError } from '.';
import { EMPTY_ARRAY } from '../app-state/constants';
import {
  useContactList,
  UseContactListParams,
  UseContactListResult,
  useDispatchFetchObjects,
  UseDispatchFetchObjectsParams
} from '../app-state/hooks';
import { UNRESOLVED_RESOURCE } from '../constants';

export type ContactListResource = ISuspenseResource<ContactListResourceValue>;
export type ContactListResourceValue = UseContactListResult[0];
export interface UseContactListResourceParams extends Omit<UseContactListParams, 'forceInclude'> {
  forceInclude?: UseContactListParams['forceInclude'] | ISuspenseResource<UseContactListParams['forceInclude']>;
}

export const useContactListResource = (
  params?: UseContactListResourceParams | null | undefined
): ContactListResource => {
  const throwAsyncError = useThrowAsyncError();
  const [, forceUpdate] = React.useReducer((key: number) => ++key, 0);

  const forceInclude = params?.forceInclude;
  const [forcedContactListLoading, forcedContactList] = React.useMemo<
    [loading: boolean, contactList: UseContactListParams['forceInclude']]
  >(() => {
    let contactList: UseContactListParams['forceInclude'];

    try {
      if (Utils.isNonArrayObjectLike<ISuspenseResource<any>>(forceInclude)) {
        contactList = forceInclude.value();
      } else {
        contactList = forceInclude;
      }
    } catch (error) {
      if (error instanceof Promise) {
        return [true, EMPTY_ARRAY];
      }
      throw error;
    }

    return [false, contactList];
  }, [forceInclude]);

  const [contactList, contactListQuery] = useContactList({ ...params, forceInclude: forcedContactList });

  const { dataObjects, userObjectsByType } = contactListQuery;
  const dispatchFetchObjects = useDispatchFetchObjects();

  return React.useMemo((): ContactListResource => {
    if (forcedContactListLoading) return UNRESOLVED_RESOURCE;

    const query: UseDispatchFetchObjectsParams = { dataObjects: { ids: [] }, userObjectsByType: [] };
    if (Utils.isNotNil(dataObjects)) query.dataObjects!.ids = dataObjects.ids;
    if (Utils.isNotNil(userObjectsByType)) query.userObjectsByType!.push(...userObjectsByType);

    if (query.dataObjects!.ids.length > 0 || query.userObjectsByType!.length > 0) {
      dispatchFetchObjects(query).then(forceUpdate, throwAsyncError);
      return UNRESOLVED_RESOURCE;
    }

    if (Utils.isNil(contactList)) {
      return UNRESOLVED_RESOURCE;
    }

    return { value: () => contactList };
  }, [contactList, dataObjects, dispatchFetchObjects, forcedContactListLoading, throwAsyncError, userObjectsByType]);
};
