import { createSlice } from '@reduxjs/toolkit';
import { DataObjectId, DataObjectVersion, StoreStateDataObjects } from '@sigmail/app-state';
import { Map as ImmutableMap } from 'immutable';
import { signOutAction } from '../actions';
import { authFailure } from '../auth-slice';
import { setStateReducer } from './set-state-reducer';

export const INITIAL_STATE: StoreStateDataObjects = ImmutableMap<DataObjectId, DataObjectVersion>();

const dataObjectsSlice = createSlice({
  name: 'dataObjects',
  initialState: INITIAL_STATE,
  reducers: {
    setState: setStateReducer
  },
  extraReducers: (builder) => {
    builder.addCase(signOutAction, () => INITIAL_STATE);
    builder.addCase(authFailure, () => INITIAL_STATE);
  }
});

export const { setState } = dataObjectsSlice.actions;
export const { reducer: dataObjectsReducer } = dataObjectsSlice;
