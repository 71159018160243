import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogEMRNoMatchingPatientRecord } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.EMR.NoMatchingPatientRecord = {
  // Translators: (HTML) Title for the No Matching Patient Record dialog
  title: gettext('messaging', 'title', 'dialogEMRNoMatchingPatientRecord', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of No Matching \
  // Patient Record dialog
  body: gettext('messaging', 'body', 'dialogEMRNoMatchingPatientRecord', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of No Matching Patient Record \
      // dialog
      label: gettext('messaging', 'accept', 'dialogEMRNoMatchingPatientRecordActionLabel'),
      context: DialogEMRNoMatchingPatientRecord
    }
  }
};

export default i18n;
