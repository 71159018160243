import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogEmrConfiguration, DialogEmrConfigurationAccuro, DialogEmrConfigurationOscar } from '../../constants/action-context';

const i18n: AccountI18n.Dialog.EMRConfiguration = {
  accuro: {
    action: {
      accept: {
        // Translators: Label for the Submit action of Accuro EMR \
        // Configuration dialog
        label: gettext('account', 'accept', 'accuroEMRConfigurationDialogActionLabel'),
        context: DialogEmrConfigurationAccuro
      },
      reject: {
        // Translators: Label for the Cancel action of Accuro EMR \
        // Configuration dialog
        label: gettext('account', 'reject', 'accuroEMRConfigurationDialogActionLabel'),
        context: DialogEmrConfigurationAccuro
      },
      testConnection: {
        // Translators: Label for the Test Connection action of Accuro EMR \
        // Configuration dialog
        label: gettext('account', 'test', 'accuroEMRConfigurationDialogActionLabel'),
        context: DialogEmrConfigurationAccuro
      }
    },
    // Translators: (HTML) Title for Accuro EMR Configuration dialog
    title: gettext('account', 'title', 'accuroEMRConfigurationDialog', 'safe-html')
  },
  action: {
    reject: {
      // Translators: Label for the Cancel action of EMR Configuration dialog
      label: gettext('account', 'reject', 'emrConfigurationDialogActionLabel'),
      context: DialogEmrConfiguration
    }
  },
  connectionMessage: {
    // Translators: Message displayed when EMR Connection fails
    error: gettext('account', 'error', 'emrConfigurationDialogConnectionMessage'),
    // Translators: Message displayed when EMR Connection succeeds
    success: gettext('account', 'success', 'emrConfigurationDialogConnectionMessage')
  },
  oscar: {
    action: {
      accept: {
        // Translators: Label for the Submit action of Oscar EMR \
        // Configuration dialog
        label: gettext('account', 'accept', 'oscarEMRConfigurationDialogActionLabel'),
        context: DialogEmrConfigurationOscar
      },
      reject: {
        // Translators: Label for the Cancel action of Oscar EMR \
        // Configuration dialog
        label: gettext('account', 'reject', 'oscarEMRConfigurationDialogActionLabel'),
        context: DialogEmrConfigurationOscar
      },
      testConnection: {
        // Translators: Label for the Test Connection action of Oscar EMR \
        // Configuration dialog
        label: gettext('account', 'test', 'oscarEMRConfigurationDialogActionLabel'),
        context: DialogEmrConfigurationOscar
      }
    },
    // Translators: (HTML) Title for Oscar EMR Configuration dialog
    title: gettext('account', 'title', 'oscarEMRConfigurationDialog', 'safe-html')
  },
  // Translators: (HTML) Generic title for the EMR Configuration dialog
  title: gettext('account', 'title', 'emrConfigurationDialog', 'safe-html')
};

export default i18n;
