import { Utils } from '@sigmail/common';
import { TFunction } from 'i18next';
import { formatCarePlanDate, isCarePlanDateLike } from '.';
import globalI18n from '../../i18n/global';

export function formatCarePlanSectionFieldValue(
  value: unknown,
  valueType?: 'addressLevel1' | 'gender' | 'healthPlanJurisdiction',
  t?: TFunction
): string {
  if (Utils.isNotNil(valueType)) {
    t = typeof t === 'function' ? t : Utils.stringOrDefault;
    if (valueType === 'addressLevel1') {
      const match = globalI18n.addressLevel1.CAN.find(({ code }) => code === value);
      if (Utils.isNotNil(match)) value = t(Utils.stringOrDefault(match.abbr, match.name));
    } else if (valueType === 'gender') {
      const match = globalI18n.genderList.find(({ code }) => code === value);
      if (Utils.isNotNil(match)) value = t(match.label);
    } else if (valueType === 'healthPlanJurisdiction') {
      const match = globalI18n.healthPlanJurisdictionList.find(({ code }) => code === value);
      if (Utils.isNotNil(match)) value = t(Utils.stringOrDefault(match.abbr, match.label));
    }
  }
  return Utils.stringOrDefault(isCarePlanDateLike(value) ? formatCarePlanDate(value) : value);
}
