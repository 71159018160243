import {
  AppUser,
  Constants,
  Nullable,
  ReadonlyMessageBodyReferral,
  ReferralMessageStatus,
  SigmailGroupId,
  SigmailUserId,
  Utils
} from '@sigmail/common';
import React from 'react';
import { sendMessageAction } from '../../../../app-state/actions/messaging/send-message-action';
import { EMPTY_ARRAY } from '../../../../app-state/constants';
import { useAppState } from '../../../../app-state/hooks';
import { personNameSelector } from '../../../../app-state/selectors/user-object';
import { ActionData } from '../../types';
import { BaseContextValue, OpenedMessageViewState } from '../context';

type ReadonlyMessageBodyReferralValue = ReadonlyMessageBodyReferral['messageForm']['value'];

export interface ActionDataSendReferralResponse extends ActionData, Partial<Pick<BaseContextValue, 'currentFolder'>> {
  consultDate?: Nullable<Date>;
  groupId: SigmailGroupId;
  reason: string;
  sourceMessage?: OpenedMessageViewState['openedMessage'];
  status: ReferralMessageStatus;
}

export const useSendReferralResponseActionHandler = (currentUserId: SigmailUserId) => {
  const [rootState, appDispatch] = useAppState();
  const senderName = personNameSelector(rootState)(/***/);

  return React.useCallback(
    async ({
      consultDate,
      currentFolder,
      failCallback,
      groupId,
      reason: declineReason,
      sourceMessage,
      status,
      successCallback
    }: ActionDataSendReferralResponse) => {
      let promise: Promise<any>;
      if (!AppUser.isValidId(currentUserId)) {
        promise = Promise.reject(new Error('Invalid currentUserId.'));
      } else if (Utils.isNil(currentFolder)) {
        promise = Promise.reject(new Error('currentFolder == null'));
      } else if (Utils.isNil(sourceMessage)) {
        promise = Promise.reject(new Error('sourceMessage == null'));
      } else {
        const { folderKey, parentFolderKey } = currentFolder;
        const { body: msgBodyId, header: msgMetadataId, subject } = sourceMessage;
        let reason: string = undefined!;
        let consultDateUtc: number | undefined;

        if (status === 'accepted' && consultDate instanceof Date) {
          consultDateUtc = consultDate.getTime();
        } else if (status === 'declined') {
          reason = declineReason;
        }

        const response: NonNullable<ReadonlyMessageBodyReferralValue['response']> = {
          msg: { header: msgMetadataId, body: msgBodyId },
          reason,
          consultDateUtc,
          status,
          user: { type: 'user', id: currentUserId, userData: senderName }
        };

        promise = appDispatch(
          sendMessageAction({
            circleOfCareGroupId: groupId,
            folderKey,
            messageBody: {
              messageForm: {
                name: Constants.MessageFormName.Referral,
                value: { response } as ReadonlyMessageBodyReferral['messageForm']['value']
              }
            },
            parentFolderKey,
            primaryRecipientList: EMPTY_ARRAY,
            secondaryRecipientList: EMPTY_ARRAY,
            sender: { type: 'user', id: currentUserId, ...senderName },
            sourceMessage,
            subjectLine: Utils.stringOrDefault(subject)
          })
        );
      }

      let value: any;

      try {
        value = await promise;
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch, currentUserId, senderName]
  );
};
