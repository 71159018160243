/* eslint-disable no-unused-vars */

import type {
  AlgorithmCode,
  EncapsulatedKey,
  EncryptAsymmetricKeyAlgorithmCode,
  Nullable,
  SigmailKeyId,
  SigmailObjectId
} from '@sigmail/common';
import { AppException, Constants, Utils } from '@sigmail/common';
import { Algorithm, Constants as CryptoConstants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import type {
  ApiFormattedCryptographicKey,
  ApiFormattedVersionedSigmailObject,
  EncryptAsymmetricKeyParams,
  EncryptEncapsulatedKeyParams,
  ICryptographicKey,
  ICryptographicKeyPrivate,
  ICryptographicKeyPublic,
  IVersionedSigmailObject
} from '../types';
import { VersionedSigmailObject } from '../versioned-sigmail-object';

const ErrorConstants = { ...Constants.Error, ...CryptoConstants.Error };

const PROPS: ReadonlyArray<
  keyof Omit<ICryptographicKey<JsonWebKey | EncapsulatedKey>, keyof IVersionedSigmailObject<unknown> | 'decryptedValue'>
> = ['value', 'encryptedForId', 'createdAtUtc', 'expiredAtUtc'];

const API_FORMATTED_PROPS: ReadonlyArray<
  keyof Omit<ApiFormattedCryptographicKey, keyof ApiFormattedVersionedSigmailObject>
> = ['encryptedForId', 'value', 'createdAtUtc', 'expiredAtUtc'];

/**
 * TODO document
 *
 * @typeParam DV - Type of decrypted value. `JsonWebKey` or `EncapsulatedKey`
 * @public
 */
export abstract class CryptographicKey<DV extends JsonWebKey | EncapsulatedKey>
  extends VersionedSigmailObject<DV>
  implements ICryptographicKey<DV>
{
  private static readonly KnownPrivateKeys = new Map<SigmailKeyId, CryptoKey>();
  private static readonly KnownPublicKeys = new Map<SigmailKeyId, CryptoKey>();

  private static readonly privateKeyAlgo = getAlgorithm(
    process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE as EncryptAsymmetricKeyAlgorithmCode
  );

  private static readonly publicKeyAlgo = getAlgorithm(
    process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC as EncryptAsymmetricKeyAlgorithmCode
  );

  public static encryptedForIds(): IterableIterator<SigmailKeyId> {
    return this.KnownPrivateKeys.keys();
  }

  public static getPrivateKey(keyId: SigmailKeyId): CryptoKey {
    if (!this.hasPrivateKey(keyId)) {
      throw new AppException(ErrorConstants.S_ERROR, `Key could not be found. (keyId: ${keyId})`);
    }
    return this.KnownPrivateKeys.get(keyId)!;
  }

  public static hasPrivateKey(keyId: SigmailKeyId): boolean {
    return this.KnownPrivateKeys.has(keyId);
  }

  public static setPrivateKey(keyId: SigmailKeyId, privateKey: CryptoKey): void {
    this.KnownPrivateKeys.set(keyId, privateKey);
  }

  public static clearPrivateKey(keyId: SigmailKeyId): boolean {
    return this.KnownPrivateKeys.delete(keyId);
  }

  public static clearAllPrivateKeys(): void {
    this.KnownPrivateKeys.clear();
  }

  public static getPublicKey(keyId: SigmailKeyId): CryptoKey {
    if (!this.hasPublicKey(keyId)) {
      throw new AppException(ErrorConstants.S_ERROR, `Key could not be found. (keyId: ${keyId})`);
    }
    return this.KnownPublicKeys.get(keyId)!;
  }

  public static hasPublicKey(keyId: SigmailKeyId): boolean {
    return this.KnownPublicKeys.has(keyId);
  }

  public static setPublicKey(keyId: SigmailKeyId, publicKey: CryptoKey): void {
    this.KnownPublicKeys.set(keyId, publicKey);
  }

  public static clearPublicKey(keyId: SigmailKeyId): boolean {
    return this.KnownPublicKeys.delete(keyId);
  }

  public static clearAllPublicKeys(): void {
    this.KnownPublicKeys.clear();
  }

  public static async cache(key: ICryptographicKeyPrivate | ICryptographicKeyPublic): Promise<void> {
    if (
      key.type !== process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE &&
      key.type !== process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC
    ) {
      throw new SigmailCryptoException(ErrorConstants.E_INVALID_KEY);
    }

    const jsonWebKey = await key.decryptedValue();
    if (key.type === process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE) {
      const cryptoKey = await this.privateKeyAlgo.importKey(jsonWebKey);
      this.setPrivateKey(key.id, cryptoKey);
    } else {
      const cryptoKey = await this.publicKeyAlgo.importKey(jsonWebKey);
      this.setPublicKey(key.id, cryptoKey);
    }
  }

  /** Determines if the given value is a valid encrypted value. */
  public static isValidEncryptedValue(value: unknown): value is string {
    return Utils.isString(value);
  }

  /** Determines if the given value is a valid creation date. */
  public static isValidCreationDate(value: unknown): value is Date {
    return Utils.isValidDate(value);
  }

  /**
   * Determines if the given value is a valid expiry date. `null` is considered
   * a valid value.
   */
  public static isValidExpiryDate(value: unknown): value is Date | null {
    return value === null || Utils.isValidDate(value);
  }

  public static override isAssignableFrom(value: unknown): value is ICryptographicKey<JsonWebKey | EncapsulatedKey> {
    if (!(super.isAssignableFrom(value) && Utils.every(PROPS, Utils.partial(Utils.has, value)))) return false;

    const obj = value as ICryptographicKey<JsonWebKey | EncapsulatedKey>;
    return (
      this.isValidEncryptedValue(obj.value) &&
      this.isValidId(obj.encryptedForId) &&
      this.isValidCreationDate(obj.createdAtUtc) &&
      this.isValidExpiryDate(obj.expiredAtUtc) &&
      typeof obj.decryptedValue === 'function'
    );
  }

  public static override isApiFormatted(value: unknown): value is ApiFormattedCryptographicKey {
    if (!(super.isApiFormatted(value) && Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, value)))) {
      return false;
    }

    const obj = value as ApiFormattedCryptographicKey;
    return (
      Utils.isString(obj.value) &&
      Utils.isNumber(obj.encryptedForId) &&
      Utils.isString(obj.createdAtUtc) &&
      (obj.expiredAtUtc === null || Utils.isString(obj.expiredAtUtc))
    );
  }

  protected static encryptAsymmetricKey(key: JsonWebKey, params: EncryptAsymmetricKeyParams): Promise<string> {
    if (!this.isValidId(params.encryptedFor)) {
      throw new AppException(ErrorConstants.E_INVALID_OBJECT_ID, 'Invalid ID. (Parameter: encryptedFor)');
    }

    if (!Algorithm.isValidEncryptAsymmetricKeyCode(params.keyCode)) {
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    }

    const version = Utils.isNil(params.keyVersion) ? 0 : params.keyVersion;
    if (!this.isValidVersion(version)) {
      throw new AppException(ErrorConstants.E_INVALID_OBJECT_VERSION);
    }

    const algorithm = getAlgorithm(params.keyCode);
    const publicKey = this.getPublicKey(params.encryptedFor);
    return algorithm.encrypt({ publicKey }, key, version);
  }

  protected static encryptEncapsulatedKey(key: EncapsulatedKey, params: EncryptEncapsulatedKeyParams): Promise<string> {
    if (!this.isValidId(params.encryptedFor)) {
      throw new AppException(ErrorConstants.E_INVALID_OBJECT_ID, 'Invalid ID. (Parameter: encryptedFor)');
    }

    if (!Algorithm.isValidEncryptEncapsulatedKeyCode(params.keyCode)) {
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    }

    const version = Utils.isNil(params.keyVersion) ? 0 : params.keyVersion;
    if (!this.isValidVersion(version)) {
      throw new AppException(ErrorConstants.E_INVALID_OBJECT_VERSION);
    }

    const algorithm = getAlgorithm(params.keyCode);
    const publicKey = this.getPublicKey(params.encryptedFor);
    return algorithm.encrypt({ publicKey }, key, version);
  }

  public readonly createdAtUtc: Date;
  public readonly encryptedForId: SigmailKeyId;
  public readonly expiredAtUtc: Date | null;
  public readonly value: string;

  public constructor(
    id: SigmailObjectId,
    code: AlgorithmCode,
    version: number,
    value: string,
    encryptedForId: SigmailKeyId,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  );

  public constructor(obj: ApiFormattedCryptographicKey);

  public constructor(...args: Array<unknown>) {
    super(...(args.length === 1 ? args : [undefined, ...args]));

    const Class = this.constructor as typeof CryptographicKey;
    if (Class === CryptographicKey) throw new TypeError('Initialization error.');

    const obj = args[0] as ApiFormattedCryptographicKey;
    const value = args.length === 1 ? obj.value : args[3];
    const encryptedForId = args.length === 1 ? obj.encryptedForId : args[4];
    const createdAtUtc = args.length === 1 ? new Date(obj.createdAtUtc) : args[5];
    const expiredAtUtc = args.length === 1 ? obj.expiredAtUtc : (args[6] as Nullable<Date>);
    const expiryDate = Utils.isNil(expiredAtUtc) ? null : new Date(expiredAtUtc);

    if (!Class.isValidEncryptedValue(value)) throw new AppException(ErrorConstants.E_INVALID_OBJECT_VALUE);
    if (!Class.isValidId(encryptedForId)) throw new AppException(ErrorConstants.E_INVALID_USER_OR_GROUP_ID);
    if (!Class.isValidCreationDate(createdAtUtc)) throw new AppException(ErrorConstants.E_INVALID_CREATION_DATE);
    if (!Class.isValidExpiryDate(expiryDate)) throw new AppException(ErrorConstants.E_INVALID_EXPIRY_DATE);

    this.createdAtUtc = createdAtUtc;
    this.encryptedForId = encryptedForId;
    this.expiredAtUtc = expiryDate;
    this.value = value;
  }

  public override equals(other: unknown): other is ICryptographicKey<JsonWebKey | EncapsulatedKey> {
    return (
      super.equals(other) &&
      this.encryptedForId === (other as ICryptographicKey<JsonWebKey | EncapsulatedKey>).encryptedForId
    );
  }

  public override hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.encryptedForId)) | 0;
    return hashed;
  }

  public abstract decryptedValue(...args: Array<unknown>): Promise<DV>;

  public override toApiFormatted(): ApiFormattedCryptographicKey {
    const { code, id, type, version } = super.toApiFormatted();

    return {
      code,
      createdAtUtc: this.createdAtUtc.toISOString(),
      encryptedForId: this.encryptedForId,
      expiredAtUtc: Utils.stringOrDefault(this.expiredAtUtc?.toISOString(), null!),
      id,
      type,
      value: this.value,
      version
    };
  }
}
