import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../../http/service-exception';
import type { Api, Http } from '../../types';
import { URL_HRM_SEND_REPORT as API_URL } from '../constants';
import { MalformedResponseException } from '../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../service-exception';

const REG_EX_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const SUCCESS_RESPONSE_PROPS: ReadonlyArray<keyof Api.HrmSendReportResponseData> = [
  'statusCode',
  'resourceType',
  'id',
  'type'
];

const Logger = getLoggerWithPrefix('ApiService', 'hrmSendReport:');

export async function apiHrmSendReport(
  this: Api.Service,
  accessToken: string,
  query: Api.HrmSendReportRequestData,
  init?: Http.RequestInit
): Promise<Api.HrmSendReportResponseData> {
  try {
    Logger.info('== BEGIN ==');
    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.HrmSendReportResponseData>(requestUrl, JSON.stringify(query), {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status === 200) {
          const json = await Utils.tryGetResponseJson<Api.HrmSendReportResponseData>(response, undefined);
          if (isValidResponseData(json)) return json;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status >= 400 && response.status < 500) {
          const responseBody = await response.clone().text();
          throw new ApiServiceException(response, responseBody);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}

function isValidResponseData(responseJson?: unknown): responseJson is Api.HrmSendReportResponseData {
  return (
    Utils.isNonArrayObjectLike<Api.HrmSendReportResponseData>(responseJson) &&
    Utils.every(SUCCESS_RESPONSE_PROPS, Utils.partial(Utils.has, responseJson)) &&
    responseJson.statusCode === 200 &&
    Utils.isString(responseJson.resourceType) &&
    Utils.isString(responseJson.id) &&
    REG_EX_UUID.test(responseJson.id) &&
    Utils.isString(responseJson.type)
  );
}
