import { CarePlanI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: CarePlanI18n.Form.EditCarePlan = {
  action: {
    addSection: {
      // Translators: Label for the Add Another Section action of Edit Care Plan form
      label: gettext('care-plan', 'addSection', 'editCarePlanFormActionLabel')
    }
  },
  fieldsetGroup: {
    allergyList: {
      action: {
        addRow: {
          // Translators: Label for the Add Allergy action of Edit Care Plan form
          label: gettext('care-plan', 'addAllergy', 'editCarePlanFormFieldsetGroupActionLabel')
        },
        removeRow: {
          // Translators: Label for the Remove Allergy action of Edit Care Plan form
          label: gettext('care-plan', 'removeAllergy', 'editCarePlanFormFieldsetGroupActionLabel')
        }
      },
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Allergy List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientAllergyList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Allergy List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myAllergyList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetAllergy: {
        formField: {
          allergyIdentifier: {
            error: {
              // Translators: Error message displayed when Patient's Allergy Identifier input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              tooLong: gettext('care-plan', 'allergyIdLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Allergy Identifier input of Edit Care Plan form
            label: gettext('care-plan', 'allergyId', 'editCarePlanFormInputLabel')
          },
          allergySymptoms: {
            error: {
              // Translators: Error message displayed when Patient's Allergy Symptoms input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              tooLong: gettext('care-plan', 'allergySymptomsLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Allergy Symptoms input of Edit Care Plan form
                return gettext('care-plan', 'patientAllergySymptoms', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Allergy Symptoms input of Edit Care Plan form
                return gettext('care-plan', 'myAllergySymptoms', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Allergy Symptoms input of Edit Care Plan form
            label: gettext('care-plan', 'allergySymptoms', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Allergy List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientAllergyList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Allergy List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myAllergyList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Allergy List fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'allergyList', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    careTeam: {
      action: {
        addRow: {
          // Translators: Label for the Add Care Team Member action of Edit Care Plan form
          label: gettext('care-plan', 'addCareTeamMember', 'editCarePlanFormFieldsetGroupActionLabel')
        },
        removeRow: {
          // Translators: Label for the Remove Care Team Member action of Edit Care Plan form
          label: gettext('care-plan', 'removeCareTeamMember', 'editCarePlanFormFieldsetGroupActionLabel')
        }
      },
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Care Team fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientCareTeam', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Care Team fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myCareTeam', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetMember: {
        formField: {
          memberContact: {
            error: {
              // Translators: Error message displayed when Care Team Member's Contact Number input on Edit Care Plan \
              // form doesn't match the expected format
              patternMismatch: gettext('care-plan', 'memberContactPattern', 'editCarePlanFormError')
            },
            // Translators: Label for the Care Team Member's Contact Number input of Edit Care Plan form
            label: gettext('care-plan', 'memberContact', 'editCarePlanFormInputLabel')
          },
          memberFirstName: {
            error: {
              // Translators: Error message displayed when Care Team Member's First Name input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'memberFirstNameLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Care Team Member's First Name input of Edit Care Plan form
            label: gettext('care-plan', 'memberFirstName', 'editCarePlanFormInputLabel')
          },
          memberLastName: {
            error: {
              // Translators: Error message displayed when Care Team Member's Last Name input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'memberLastNameLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Care Team Member's Last Name input of Edit Care Plan form
            label: gettext('care-plan', 'memberLastName', 'editCarePlanFormInputLabel')
          },
          memberOrganization: {
            error: {
              // Translators: Error message displayed when Care Team Member's Organization input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'memberOrganizationLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Member's Organization input of Edit Care Plan form
            label: gettext('care-plan', 'memberOrganization', 'editCarePlanFormInputLabel')
          },
          memberRole: {
            error: {
              // Translators: Error message displayed when Care Team Member's Role input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'memberRoleLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Care Team Member's Role input of Edit Care Plan form
                return gettext('care-plan', 'patientTeamMemberRole', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Care Team Member's Role input of Edit Care Plan form
                return gettext('care-plan', 'myTeamMemberRole', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Care Team Member's Role input of Edit Care Plan form
            label: gettext('care-plan', 'memberRole', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Care Team fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientCareTeam', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Care Team fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myCareTeam', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Care Team fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'careTeam', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    goalList: {
      action: {
        addRow: {
          // Translators: Label for the Add Goal action of Edit Care Plan form
          label: gettext('care-plan', 'addGoal', 'editCarePlanFormFieldsetGroupActionLabel')
        },
        removeRow: {
          // Translators: Label for the Remove Goal action of Edit Care Plan form
          label: gettext('care-plan', 'removeGoal', 'editCarePlanFormFieldsetGroupActionLabel')
        }
      },
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Goal List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientGoalList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Goal List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myGoalList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetGoal: {
        formField: {
          goalActionPlan: {
            error: {
              // Translators: Error message displayed when Patient's Goal Action Plan input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              tooLong: gettext('care-plan', 'goalActionPlanLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Goal Action Plan input of Edit Care Plan form
                return gettext('care-plan', 'patientGoalActionPlan', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Goal Action Plan input of Edit Care Plan form
                return gettext('care-plan', 'myGoalActionPlan', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Goal Action Plan input of Edit Care Plan form
            label: gettext('care-plan', 'goalActionPlan', 'editCarePlanFormInputLabel')
          },
          goalCoordinator: {
            error: {
              // Translators: Error message displayed when Patient's Goal Coordinator input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              tooLong: gettext('care-plan', 'goalCoordinatorLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Goal Coordinator input of Edit Care Plan form
                return gettext('care-plan', 'patientGoalCoordinator', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Goal Coordinator input of Edit Care Plan form
                return gettext('care-plan', 'myGoalCoordinator', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Goal Coordinator input of Edit Care Plan form
            label: gettext('care-plan', 'goalCoordinator', 'editCarePlanFormInputLabel')
          },
          goalDate: {
            error: {
              // Translators: Error message displayed when date entered in Patient's Goal Date input on Edit Care \
              // Plan form is not a valid date
              badInput: gettext('care-plan', 'goalDateInvalid', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Goal Date input of Edit Care Plan form
            label: gettext('care-plan', 'goalDate', 'editCarePlanFormInputLabel'),
            // Translators: Hint for the Patient's Goal Date input of Edit Care Plan form
            placeholder: gettext('care-plan', 'goalDate', 'editCarePlanFormInputHint')
          },
          goalDetails: {
            error: {
              // Translators: Error message displayed when Patient's Goal Details input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              tooLong: gettext('care-plan', 'goalDetailsLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Goal Details input of Edit Care Plan form
                return gettext('care-plan', 'patientGoalDetails', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Goal Details input of Edit Care Plan form
                return gettext('care-plan', 'myGoalDetails', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Goal Details input of Edit Care Plan form
            label: gettext('care-plan', 'goalDetails', 'editCarePlanFormInputLabel')
          },
          goalIdentifier: {
            error: {
              // Translators: Error message displayed when Patient's Goal Identifier input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              tooLong: gettext('care-plan', 'goalIdLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Goal Identifier input of Edit Care Plan form
                return gettext('care-plan', 'patientGoalId', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Goal Identifier input of Edit Care Plan form
                return gettext('care-plan', 'myGoalId', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Goal Identifier input of Edit Care Plan form
            label: gettext('care-plan', 'goalId', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Goal List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientGoalList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Goal List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myGoalList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Goal List fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'goalList', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    healthAssessmentList: {
      action: {
        addRow: {
          // Translators: Label for the Add Health Assessment action of Edit Care Plan form
          label: gettext('care-plan', 'addHealthAssessment', 'editCarePlanFormFieldsetGroupActionLabel')
        },
        removeRow: {
          // Translators: Label for the Remove Health Assessment action of Edit Care Plan form
          label: gettext('care-plan', 'removeHealthAssessment', 'editCarePlanFormFieldsetGroupActionLabel')
        }
      },
      description: () => {
        // Translators: (HTML) Description for the Health Assessment List fieldset group of Edit Care Plan form
        return gettext('care-plan', 'healthAssessmentList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
      },
      fieldsetHealthAssessment: {
        formField: {
          healthAssessmentDate: {
            error: {
              // Translators: Error message displayed when date entered in Patient's Health Assessment Date \
              // input on Edit Care Plan form is not a valid date
              badInput: gettext('care-plan', 'healthAssessmentDateInvalid', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Health Assessment Date input of Edit \
                // Care Plan form
                return gettext('care-plan', 'patientHealthAssessmentDate', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Health Assessment Date input of Edit \
                // Care Plan form
                return gettext('care-plan', 'myHealthAssessmentDate', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Health Assessment Date input of Edit Care Plan form
            label: gettext('care-plan', 'healthAssessmentDate', 'editCarePlanFormInputLabel'),
            // Translators: Hint for the Patient's Health Assessment Date input of Edit Care Plan form
            placeholder: gettext('care-plan', 'healthAssessmentDate', 'editCarePlanFormInputHint')
          },
          healthAssessmentIdentifier: {
            error: {
              // Translators: Error message displayed when Patient's Health Assessment Identifier input on Edit Care \
              // Plan form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'healthAssessmentIdLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Health Assessment Identifier input of \
                // Edit Care Plan form
                return gettext('care-plan', 'patientHealthAssessmentId', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Health Assessment Identifier input of \
                // Edit Care Plan form
                return gettext('care-plan', 'myHealthAssessmentId', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Health Assessment Identifier input of Edit Care Plan form
            label: gettext('care-plan', 'healthAssessmentId', 'editCarePlanFormInputLabel')
          },
          healthAssessmentNotes: {
            error: {
              // Translators: Error message displayed when Patient's Health Assessment Notes input on Edit Care \
              // Plan form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'healthAssessmentNotesLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Health Assessment Notes input of Edit Care \
                // Plan form
                return gettext('care-plan', 'patientHealthAssessmentNotes', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Health Assessment Notes input of Edit Care \
                // Plan form
                return gettext('care-plan', 'myHealthAssessmentNotes', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Health Assessment Notes input of Edit Care Plan form
            label: gettext('care-plan', 'healthAssessmentNotes', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Health Assessment List fieldset group of Edit Care \
          // Plan form
          return gettext('care-plan', 'patientHealthAssessmentList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Health Assessment List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myHealthAssessmentList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Health Assessment List fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'healthAssessmentList', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    healthIssueList: {
      action: {
        addRow: {
          // Translators: Label for the Add Health Issue action of Edit Care Plan form
          label: gettext('care-plan', 'addHealthIssue', 'editCarePlanFormFieldsetGroupActionLabel')
        },
        removeRow: {
          // Translators: Label for the Remove Health Issue action of Edit Care Plan form
          label: gettext('care-plan', 'removeHealthIssue', 'editCarePlanFormFieldsetGroupActionLabel')
        }
      },
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Health Issue List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientHealthIssueList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Health Issue List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myHealthIssueList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetHealthIssue: {
        formField: {
          healthIssueDetails: {
            error: {
              // Translators: Error message displayed when Patient's Health Issue Details input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'healthIssueDetailsLength', 'editCarePlanFormError')
            },
            helperText: () => {
              // Translators: Helper text associated with the Patient's Health Issue Details input of Edit Care \
              // Plan form
              return gettext('care-plan', 'healthIssueDetails', 'editCarePlanFormInputHelperText');
            },
            // Translators: Label for the Patient's Health Issue Details input of Edit Care Plan form
            label: gettext('care-plan', 'healthIssueDetails', 'editCarePlanFormInputLabel')
          },
          healthIssueIdentifier: {
            error: {
              // Translators: Error message displayed when Patient's Health Issue Identifier input on Edit Care \
              // Plan form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'healthIssueIdLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Health Issue Identifier input of Edit Care \
                // Plan form
                return gettext('care-plan', 'patientHealthIssueId', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Health Issue Identifier input of Edit Care \
                // Plan form
                return gettext('care-plan', 'myHealthIssueId', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Health Issue Identifier input of Edit Care Plan form
            label: gettext('care-plan', 'healthIssueId', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Health Issue List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientHealthIssueList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Health Issue List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myHealthIssueList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Health Issue List fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'healthIssueList', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    medicationCoordination: {
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Medication Coordination fieldset group of Edit \
          // Care Plan form
          return gettext('care-plan', 'patientMedCoordination', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Medication Coordination fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myMedCoordination', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetMedication: {
        formField: {
          medAids: {
            error: {
              // Translators: Error message displayed when Patient's Medication Aids input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'medAidsLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Medication Aids input of Edit Care Plan form
                return gettext('care-plan', 'patientMedAids', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Medication Aids input of Edit Care Plan form
                return gettext('care-plan', 'myMedAids', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Medication Aids input of Edit Care Plan form
            label: gettext('care-plan', 'medAids', 'editCarePlanFormInputLabel')
          },
          medChallenges: {
            error: {
              // Translators: Error message displayed when Patient's Medication Challenges input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'medChallengesLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Medication Challenges input of Edit Care \
                // Plan form
                return gettext('care-plan', 'patientMedChallenges', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Medication Challenges input of Edit Care Plan form
                return gettext('care-plan', 'myMedChallenges', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Medication Challenges input of Edit Care Plan form
            label: gettext('care-plan', 'medChallenges', 'editCarePlanFormInputLabel')
          },
          medHelper: {
            error: {
              // Translators: Error message displayed when Patient's Medication Helper input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'medHelperLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Medication Helper input of Edit Care Plan form
                return gettext('care-plan', 'patientMedHelper', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Medication Helper input of Edit Care Plan form
                return gettext('care-plan', 'myMedHelper', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Medication Helper input of Edit Care Plan form
            label: gettext('care-plan', 'medHelper', 'editCarePlanFormInputLabel')
          },
          medSource: {
            error: {
              // Translators: Error message displayed when Patient's Medication Source input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'medSourceLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Medication Source input of Edit Care Plan form
                return gettext('care-plan', 'patientMedSource', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Medication Source input of Edit Care Plan form
                return gettext('care-plan', 'myMedSource', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Medication Source input of Edit Care Plan form
            label: gettext('care-plan', 'medSource', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Medication Coordination fieldset group of Edit Care \
          // Plan form
          return gettext('care-plan', 'patientMedCoordination', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Medication Coordination fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myMedCoordination', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Medication Coordination fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'medCoordination', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    medicationList: {
      action: {
        addRow: {
          // Translators: Label for the Add Drug action of Edit Care Plan form
          label: gettext('care-plan', 'addDrug', 'editCarePlanFormFieldsetGroupActionLabel')
        },
        removeRow: {
          // Translators: Label for the Remove Drug action of Edit Care Plan form
          label: gettext('care-plan', 'removeDrug', 'editCarePlanFormFieldsetGroupActionLabel')
        }
      },
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Medication List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientDrugList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Medication List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myDrugList', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetDrug: {
        formField: {
          drugDose: {
            error: {
              // Translators: Error message displayed when Patient's Drug Dose input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'drugDoseLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Drug Dose input of Edit Care Plan form
            label: gettext('care-plan', 'drugDose', 'editCarePlanFormInputLabel')
          },
          drugFrequency: {
            error: {
              // Translators: Error message displayed when Patient's Drug Frequency input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'drugFrequencyLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Drug Frequency input of Edit Care Plan form
            label: gettext('care-plan', 'drugFrequency', 'editCarePlanFormInputLabel')
          },
          drugName: {
            error: {
              // Translators: Error message displayed when Patient's Drug Name input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'drugNameLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Drug Name input of Edit Care Plan form
            label: gettext('care-plan', 'drugName', 'editCarePlanFormInputLabel')
          },
          drugNotes: {
            error: {
              // Translators: Error message displayed when Patient's Drug Notes input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'drugNotesLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Drug Notes input of Edit Care Plan form
            label: gettext('care-plan', 'drugNotes', 'editCarePlanFormInputLabel')
          },
          drugPrescriber: {
            error: {
              // Translators: Error message displayed when Patient's Drug Prescriber input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'drugPrescriberLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Drug Prescriber input of Edit Care Plan form
            label: gettext('care-plan', 'drugPrescriber', 'editCarePlanFormInputLabel')
          },
          drugReason: {
            error: {
              // Translators: Error message displayed when Patient's Drug Reason input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'drugReasonLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Drug Reason input of Edit Care Plan form
            label: gettext('care-plan', 'drugReason', 'editCarePlanFormInputLabel')
          },
          drugStart: {
            error: {
              // Translators: Error message displayed when date entered in Patient's Drug Start Date \
              // input on Edit Care Plan form is not a valid date
              badInput: gettext('care-plan', 'drugStartDateInvalid', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Drug Start Date input of Edit Care Plan form
            label: gettext('care-plan', 'drugStartDate', 'editCarePlanFormInputLabel'),
            // Translators: Hint for the Patient's Drug Start Date input of Edit Care Plan form
            placeholder: gettext('care-plan', 'drugStartDate', 'editCarePlanFormInputHint')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Medication List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientDrugList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Medication List fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myDrugList', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Medication List fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'drugList', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    personalInfo: {
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Personal Info fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientPersonalInfo', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Personal Info fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myPersonalInfo', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetPersonalInfo: {
        formField: {
          aboutCaregiverIssues: {
            error: {
              // Translators: Error message displayed when Patient's Caregiver Issues input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'caregiverIssuesLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Caregiver Issues input of Edit Care Plan form
                return gettext('care-plan', 'patientCaregiverIssues', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Caregiver Issues input of Edit Care Plan form
                return gettext('care-plan', 'myCaregiverIssues', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Caregiver Issues input of Edit Care Plan form
            label: gettext('care-plan', 'caregiverIssues', 'editCarePlanFormInputLabel')
          },
          aboutEmployment: {
            error: {
              // Translators: Error message displayed when Patient's Employment input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'employmentLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Employment input of Edit Care Plan form
                return gettext('care-plan', 'patientEmployment', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Employment input of Edit Care Plan form
                return gettext('care-plan', 'myEmployment', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Employment input of Edit Care Plan form
            label: gettext('care-plan', 'employment', 'editCarePlanFormInputLabel')
          },
          aboutFoodSecurity: {
            error: {
              // Translators: Error message displayed when Patient's Food Security input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'foodSecurityLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Food Security input of Edit Care Plan form
                return gettext('care-plan', 'patientFoodSecurity', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Food Security input of Edit Care Plan form
                return gettext('care-plan', 'myFoodSecurity', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Food Security input of Edit Care Plan form
            label: gettext('care-plan', 'foodSecurity', 'editCarePlanFormInputLabel')
          },
          aboutHealthKnowledge: {
            error: {
              // Translators: Error message displayed when Patient's Health Knowledge input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'healthKnowledgeLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Health Knowledge input of Edit Care Plan form
                return gettext('care-plan', 'patientHealthKnowledge', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Health Knowledge input of Edit Care Plan form
                return gettext('care-plan', 'myHealthKnowledge', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Health Knowledge input of Edit Care Plan form
            label: gettext('care-plan', 'healthKnowledge', 'editCarePlanFormInputLabel')
          },
          aboutHousing: {
            error: {
              // Translators: Error message displayed when Patient's Housing input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'housingLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Housing input of Edit Care Plan form
                return gettext('care-plan', 'patientHousing', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Housing input of Edit Care Plan form
                return gettext('care-plan', 'myHousing', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Housing input of Edit Care Plan form
            label: gettext('care-plan', 'housing', 'editCarePlanFormInputLabel')
          },
          aboutIncome: {
            error: {
              // Translators: Error message displayed when Patient's Income input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'incomeLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Income input of Edit Care Plan form
                return gettext('care-plan', 'patientIncome', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Income input of Edit Care Plan form
                return gettext('care-plan', 'myIncome', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Income input of Edit Care Plan form
            label: gettext('care-plan', 'income', 'editCarePlanFormInputLabel')
          },
          aboutLegalConcerns: {
            error: {
              // Translators: Error message displayed when Patient's Legal Concerns input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'legalConcernsLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Legal Concerns input of Edit Care Plan form
                return gettext('care-plan', 'patientLegalConcerns', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Legal Concerns input of Edit Care Plan form
                return gettext('care-plan', 'myLegalConcerns', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Legal Concerns input of Edit Care Plan form
            label: gettext('care-plan', 'legalConcerns', 'editCarePlanFormInputLabel')
          },
          aboutNewInCountry: {
            error: {
              // Translators: Error message displayed when Patient's New In Country input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'newInCountryLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's New In Country input of Edit Care Plan form
                return gettext('care-plan', 'patientNewInCountry', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My New In Country input of Edit Care Plan form
                return gettext('care-plan', 'myNewInCountry', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's New In Country input of Edit Care Plan form
            label: gettext('care-plan', 'newInCountry', 'editCarePlanFormInputLabel')
          },
          aboutOther: {
            error: {
              // Translators: Error message displayed when Patient's Other Information input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'otherInfoLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Other Information input of Edit Care Plan form
                return gettext('care-plan', 'patientOtherInfo', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Other Information input of Edit Care Plan form
                return gettext('care-plan', 'myOtherInfo', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Other Information input of Edit Care Plan form
            label: gettext('care-plan', 'otherInfo', 'editCarePlanFormInputLabel')
          },
          aboutSocialNetwork: {
            error: {
              // Translators: Error message displayed when Patient's Social Network input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'socialNetworkLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Social Network input of Edit Care Plan form
                return gettext('care-plan', 'patientSocialNetwork', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Social Network input of Edit Care Plan form
                return gettext('care-plan', 'mySocialNetwork', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Social Network input of Edit Care Plan form
            label: gettext('care-plan', 'socialNetwork', 'editCarePlanFormInputLabel')
          },
          aboutSpiritualAffiliation: {
            error: {
              // Translators: Error message displayed when Patient's Spiritual Affiliation input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'spiritualAffiliationLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Spiritual Affiliation input of Edit Care \
                // Plan form
                return gettext('care-plan', 'patientSpiritualAffiliation', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Spiritual Affiliation input of Edit Care Plan form
                return gettext('care-plan', 'mySpiritualAffiliation', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Spiritual Affiliation input of Edit Care Plan form
            label: gettext('care-plan', 'spiritualAffiliation', 'editCarePlanFormInputLabel')
          },
          aboutTransportation: {
            error: {
              // Translators: Error message displayed when Patient's Transportation input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'transportationLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Transportation input of Edit Care Plan form
                return gettext('care-plan', 'patientTransportation', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Transportation input of Edit Care Plan form
                return gettext('care-plan', 'myTransportation', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Transportation input of Edit Care Plan form
            label: gettext('care-plan', 'transportation', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Personal Info fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientPersonalInfo', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Personal Info fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myPersonalInfo', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      shortLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Short label for the Patient's Personal Info fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientPersonalInfo', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Short label for the My Personal Info fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myPersonalInfo', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html');
        }
        return '';
      }
    },
    plan: {
      fieldsetPlan: {
        formField: {
          planDescription: {
            error: {
              // Translators: Error message displayed when Plan Description input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'planDescriptionLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Plan Description input of Edit Care Plan form
            label: gettext('care-plan', 'planDescription', 'editCarePlanFormInputLabel')
          },
          planTitle: {
            error: {
              // Translators: Error message displayed when Plan Title input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'planTitleLength', 'editCarePlanFormError'),
              valueMissing: gettext('care-plan', 'planTitleRequired', 'editCarePlanFormError')
            },
            // Translators: Label for the Plan Title input of Edit Care Plan form
            label: gettext('care-plan', 'planTitle', 'editCarePlanFormInputLabel')
          }
        }
      },
      // Translators: (HTML) Full label for the Care Plan fieldset group of Edit Care Plan form
      fullLabel: () => gettext('care-plan', 'plan', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html'),
      // Translators: (HTML) Short label for the Care Plan fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'plan', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    recentHospitalVisit: {
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Recent Hospital Visit fieldset group of Edit Care \
          // Plan form
          return gettext('care-plan', 'patientRecentHospitalVisit', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Recent Hospital Visit fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myRecentHospitalVisit', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetVisit: {
        formField: {
          visitComments: {
            error: {
              // Translators: Error message displayed when Recent Hospital Visit Comments input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'visitCommentsLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Recent Hospital Visit Comments input of \
                // Edit Care Plan form
                return gettext('care-plan', 'patientHospitalVisitComments', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Recent Hospital Visit Comments input of Edit \
                // Care Plan form
                return gettext('care-plan', 'myHospitalVisitComments', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Recent Hospital Visit Comments input of Edit Care Plan form
            label: gettext('care-plan', 'visitComments', 'editCarePlanFormInputLabel')
          },
          visitDate: {
            error: {
              // Translators: Error message displayed when value entered in Recent Hospital Visit Date input of Edit \
              // Care Plan form is not a valid date
              badInput: gettext('care-plan', 'visitDateInvalid', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Recent Hospital Visit Date input of Edit \
                // Care Plan form
                return gettext('care-plan', 'patientHospitalVisitDate', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Recent Hospital Visit Date input of Edit Care \
                // Plan form
                return gettext('care-plan', 'myHospitalVisitDate', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Recent Hospital Visit Date input of Edit Care Plan form
            label: gettext('care-plan', 'visitDate', 'editCarePlanFormInputLabel'),
            // Translators: Hint for the Recent Hospital Visit Date input of Edit Care Plan form
            placeholder: gettext('care-plan', 'visitDate', 'editCarePlanFormInputHint')
          },
          visitDescription: {
            error: {
              // Translators: Error message displayed when Recent Hospital Visit Description input on Edit Care Plan \
              // form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'visitDescriptionLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Recent Hospital Visit Description input \
                // of Edit Care Plan form
                return gettext('care-plan', 'patientHospitalVisitDescription', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Recent Hospital Visit Description input of Edit \
                // Care Plan form
                return gettext('care-plan', 'myHospitalVisitDescription', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Recent Hospital Visit Description input of Edit Care Plan form
            label: gettext('care-plan', 'visitDescription', 'editCarePlanFormInputLabel')
          },
          visitDischarge: {
            error: {
              // Translators: Error message displayed when date entered in Recent Hospital Visit Discharge Date \
              // input on Edit Care Plan form doesn't satisfy the minimum value requirement
              // Translators: Token(s) available: {{MIN_DATE}}
              tooShort: gettext('care-plan', 'visitDischargeTooShort', 'editCarePlanFormError'),
              // Translators: Error message displayed when value entered in Recent Hospital Visit Discharge Date \
              // input on Edit Care Plan form is not a valid date
              badInput: gettext('care-plan', 'visitDischargeInvalid', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Recent Hospital Visit Discharge Date \
                // input of Edit Care Plan form
                return gettext('care-plan', 'patientHospitalVisitDischarge', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Recent Hospital Visit Discharge Date input of \
                // Edit Care Plan form
                return gettext('care-plan', 'myHospitalVisitDischarge', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Recent Hospital Visit Discharge Date input of Edit Care Plan form
            label: gettext('care-plan', 'visitDischarge', 'editCarePlanFormInputLabel'),
            // Translators: Hint for the Recent Hospital Visit Discharge Date input of Edit Care Plan form
            placeholder: gettext('care-plan', 'visitDischarge', 'editCarePlanFormInputHint')
          },
          visitDuration: {
            error: {
              // Translators: Error message displayed when Recent Hospital Visit Duration input on Edit Care \
              // Plan form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'visitDurationLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Recent Hospital Visit Duration input of Edit \
                // Care Plan form
                return gettext('care-plan', 'patientHospitalVisitDuration', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Recent Hospital Visit Duration input of Edit Care \
                // Plan form
                return gettext('care-plan', 'myHospitalVisitDuration', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Recent Hospital Visit Duration input of Edit Care Plan form
            label: gettext('care-plan', 'visitDuration', 'editCarePlanFormInputLabel')
          },
          visitHospitalName: {
            error: {
              // Translators: Error message displayed when Hospital Name input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'visitHospitalNameLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Hospital Name input of Edit Care Plan form
                return gettext('care-plan', 'patientHospitalName', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Hospital Name input of Edit Care Plan form
                return gettext('care-plan', 'myHospitalName', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Hospital Name input of Edit Care Plan form
            label: gettext('care-plan', 'visitHospitalName', 'editCarePlanFormInputLabel')
          },
          visitReason: {
            error: {
              // Translators: Error message displayed when Recent Hospital Visit Reason input on Edit Care \
              // Plan form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'visitReasonLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Recent Hospital Visit Reason input of Edit \
                // Care Plan form
                return gettext('care-plan', 'patientHospitalVisitReason', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the My Recent Hospital Visit Reason input of Edit Care \
                // Plan form
                return gettext('care-plan', 'myHospitalVisitReason', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Recent Hospital Visit Reason input of Edit Care Plan form
            label: gettext('care-plan', 'visitReason', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Recent Hospital Visit fieldset group of Edit Care \
          // Plan form
          return gettext('care-plan', 'patientRecentHospitalVisit', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Recent Hospital Visit fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myRecentHospitalVisit', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      shortLabel: () => {
        // Translators: (HTML) Short label for the Recent Hospital Visit fieldset group of Edit Care Plan form
        return gettext('care-plan', 'recentHospitalVisit', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html');
      }
    },
    subject: {
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Identifiers fieldset of Edit Care Plan form
          return gettext('care-plan', 'patientIdentifiers', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Identifiers fieldset of Edit Care Plan form
          return gettext('care-plan', 'myIdentifiers', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetAddress: {
        formField: {
          addressLevel1: {
            // Translators: Label for the Patient's Province input of Edit Care Plan form
            label: gettext('care-plan', 'addressLevel1', 'editCarePlanFormInputLabel'),
            // Translators: Label for the Select option of Patient's Province input on Edit Care Plan form
            placeholder: gettext('care-plan', 'selectAddressLevel1', 'editCarePlanFormOptionLabel')
          },
          addressLevel2: {
            error: {
              // Translators: Error message displayed when Patient's City input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'addressLevel2Length', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's City input of Edit Care Plan form
            label: gettext('care-plan', 'addressLevel2', 'editCarePlanFormInputLabel')
          },
          addressLine1: {
            error: {
              // Translators: Error message displayed when Patient's Address Line 1 input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'addressLine1Length', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Address Line 1 input of Edit Care Plan form
            label: gettext('care-plan', 'addressLine1', 'editCarePlanFormInputLabel')
          },
          addressLine2: {
            error: {
              // Translators: Error message displayed when Patient's Address Line 2 input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'addressLine2Length', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Address Line 2 input of Edit Care Plan form
            label: gettext('care-plan', 'addressLine2', 'editCarePlanFormInputLabel')
          },
          postalCode: {
            error: {
              // Translators: Error message displayed when Patient's Postal Code input on Edit Care Plan form \
              // doesn't match the expected format
              patternMismatch: gettext('care-plan', 'postalCodePattern', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Postal Code input of Edit Care Plan form
            label: gettext('care-plan', 'postalCode', 'editCarePlanFormInputLabel')
          }
        }
      },
      fieldsetAncestry: {
        formField: {
          ancestry: {
            error: {
              // Translators: Error message displayed when Patient's Ancestry input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'ancestryLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Ancestry input of Edit Care Plan form
            label: gettext('care-plan', 'ancestry', 'editCarePlanFormInputLabel')
          },
          indigenous: {
            error: {
              // Translators: Error message displayed when Patient's Indigenous input on Edit Care Plan form doesn't \
              // satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'indigenousLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Indigenous input of Edit Care Plan form
            label: gettext('care-plan', 'indigenous', 'editCarePlanFormInputLabel')
          }
        }
      },
      fieldsetCommunication: {
        formField: {
          communication: {
            error: {
              // Translators: Error message displayed when Patient's Communication Accommodation input on Edit Care \
              // Plan form doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'communicationLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Communication Accommodation input of Edit Care Plan form
            label: gettext('care-plan', 'communication', 'editCarePlanFormInputLabel')
          },
          language: {
            error: {
              // Translators: Error message displayed when Patient's Language Preference input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'languageLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Language Preference input of Edit Care Plan form
            label: gettext('care-plan', 'language', 'editCarePlanFormInputLabel')
          }
        }
      },
      fieldsetGender: {
        formField: {
          gender: {
            // Translators: Label for the Patient's Gender input of Edit Care Plan form
            label: gettext('care-plan', 'gender', 'editCarePlanFormInputLabel'),
            // Translators: Label for the Select option of Patient's Gender input on Edit Care Plan form
            placeholder: gettext('care-plan', 'selectGender', 'editCarePlanFormOptionLabel')
          },
          preferredPronoun: {
            error: {
              // Translators: Error message displayed when Patient's Preferred Pronoun input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'preferredPronounLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Preferred Pronoun input of Edit Care Plan form
                return gettext('care-plan', 'patientPreferredPronoun', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Preferred Pronoun input of Edit Care Plan form
                return gettext('care-plan', 'myPreferredPronoun', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Preferred Pronoun input of Edit Care Plan form
            label: gettext('care-plan', 'preferredPronoun', 'editCarePlanFormInputLabel')
          }
        }
      },
      fieldsetHealthPlan: {
        formField: {
          healthPlanJurisdiction: {
            // Translators: Label for the Patient's Health Plan Jurisdiction input of Edit Care Plan form
            label: gettext('care-plan', 'hpj', 'editCarePlanFormInputLabel'),
            // Translators: Label for the Select option of Patient's Health Plan Jurisdiction input on Edit Care \
            // Plan form
            placeholder: gettext('care-plan', 'selectHpj', 'editCarePlanFormOptionLabel')
          },
          healthPlanNumber: {
            error: {
              // Translators: Error message displayed when Patient's Health Plan Number input on Edit Care Plan form \
              // doesn't match the expected format
              patternMismatch: gettext('care-plan', 'hpnPattern', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Health Plan Number input of Edit Care Plan form
            label: gettext('care-plan', 'hpn', 'editCarePlanFormInputLabel')
          }
        }
      },
      fieldsetNameBirthDate: {
        formField: {
          birthDate: {
            error: {
              // Translators: Error message displayed when value entered in Patient's Birth Date input on Edit Care \
              // Plan form is not a valid date
              badInput: gettext('care-plan', 'birthDateInvalid', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Birth Date input of Edit Care Plan form
            label: gettext('care-plan', 'birthDate', 'editCarePlanFormInputLabel'),
            // Translators: Hint for the Patient's Birth Date input of Edit Care Plan form
            placeholder: gettext('care-plan', 'birthDate', 'editCarePlanFormInputHint')
          },
          firstName: {
            error: {
              // Translators: Error message displayed when Patient's First Name input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'firstNameLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's First Name input of Edit Care Plan form
            label: gettext('care-plan', 'firstName', 'editCarePlanFormInputLabel')
          },
          lastName: {
            error: {
              // Translators: Error message displayed when Patient's Last Name input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'lastNameLength', 'editCarePlanFormError')
            },
            // Translators: Label for the Patient's Last Name input of Edit Care Plan form
            label: gettext('care-plan', 'lastName', 'editCarePlanFormInputLabel')
          },
          preferredName: {
            error: {
              // Translators: Error message displayed when Patient's Preferred Name input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'preferredNameLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Preferred Name input of Edit Care Plan form
                return gettext('care-plan', 'patientPreferredName', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Preferred Name input of Edit Care Plan form
                return gettext('care-plan', 'myPreferredName', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Preferred Name input of Edit Care Plan form
            label: gettext('care-plan', 'preferredName', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Identifiers fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientIdentifiers', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Identifiers fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myIdentifiers', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Identifiers fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'identifiers', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    },
    supportingInfo: {
      description: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Description for the Patient's Priority And Concern fieldset group of Edit Care \
          // Plan form
          return gettext('care-plan', 'patientConcern', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Description for the My Priority And Concern fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myConcern', 'editCarePlanFormFieldsetGroupDescription', 'safe-html');
        }
        return '';
      },
      fieldsetPriorityAndConcern: {
        formField: {
          healthConcern: {
            error: {
              // Translators: Error message displayed when Patient's Health Concern input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'healthConcernLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Health Concern input of Edit Care Plan form
                return gettext('care-plan', 'patientHealthConcern', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Health Concern input of Edit Care Plan form
                return gettext('care-plan', 'myHealthConcern', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Health Concern input of Edit Care Plan form
            label: gettext('care-plan', 'healthConcern', 'editCarePlanFormInputLabel')
          },
          priority: {
            error: {
              // Translators: Error message displayed when Patient's Priority input on Edit Care Plan form \
              // doesn't satisfy the maximum length requirement
              // Token(s) available: {{MAX_LENGTH}}
              tooLong: gettext('care-plan', 'priorityLength', 'editCarePlanFormError')
            },
            helperText: (context) => {
              if (context === 'other') {
                // Translators: Helper text associated with the Patient's Priority input of Edit Care Plan form
                return gettext('care-plan', 'patientPriority', 'editCarePlanFormInputHelperText');
              } else if (context === 'self') {
                // Translators: Helper text associated with the Patient's Priority input of Edit Care Plan form
                return gettext('care-plan', 'myPriority', 'editCarePlanFormInputHelperText');
              }
              return '';
            },
            // Translators: Label for the Patient's Priority input of Edit Care Plan form
            label: gettext('care-plan', 'priority', 'editCarePlanFormInputLabel')
          }
        }
      },
      fullLabel: (context) => {
        if (context === 'other') {
          // Translators: (HTML) Full label for the Patient's Priority And Concern fieldset group of Edit Care Plan form
          return gettext('care-plan', 'patientConcern', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        } else if (context === 'self') {
          // Translators: (HTML) Full label for the My Priority And Concern fieldset group of Edit Care Plan form
          return gettext('care-plan', 'myConcern', 'editCarePlanFormFieldsetGroupFullLabel', 'safe-html');
        }
        return '';
      },
      // Translators: (HTML) Short label for the Priority And Concern fieldset group of Edit Care Plan form
      shortLabel: () => gettext('care-plan', 'concern', 'editCarePlanFormFieldsetGroupShortLabel', 'safe-html')
    }
  }
};

export default i18n;
