import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectCircleOfCareValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE;

/** @public */
export class UserObjectCircleOfCare extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.list);
  }

  public override updateValue(newValue: DV): Promise<UserObjectCircleOfCare> {
    const Class = this.constructor as typeof UserObjectCircleOfCare;
    return Class.updateValue(this, newValue);
  }
}
