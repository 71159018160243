const hasOwn = Object.prototype.hasOwnProperty;
const getTag = Object.prototype.toString;

export const isObjectLike = (value?: any) => typeof value === 'object' && value !== null;
export const has = (obj: any, key: PropertyKey) => isObjectLike(obj) && hasOwn.call(obj, key);

export const isArray = <T>(value?: any): value is T[] => Array.isArray(value);
export const isDate = (value?: any): value is Date => isObjectLike(value) && getTag.call(value) === '[object Date]';
export const isNil = (value?: any): value is null | undefined => value == null;
export const isNonArrayObjectLike = <T extends object>(value?: any): value is NonNullable<T> =>
  isObjectLike(value) && !isArray(value);
export const isNonEmptyArray = <T>(value?: any): value is T[] => isArray(value) && value.length > 0;
export const isNotNil = <T>(value?: any): value is NonNullable<T> => value != null;
export const isString = (value?: any): value is string =>
  typeof value === 'string' || (isNonArrayObjectLike(value) && getTag.call(value) === '[object String]');
export const isUndefined = (value?: any): value is undefined => typeof value === 'undefined';
export const isValidDate = (value?: any): value is Date => isDate(value) && !Number.isNaN(value.getTime());
export const noop = (...args: any[]): any => {};

export const isPromiseLike = (value?: any): value is PromiseLike<any> =>
  isNotNil<Function>(value) &&
  (typeof value === 'object' || typeof value === 'function') &&
  typeof value.then === 'function';
