/* eslint-disable no-unused-vars */

import type { AlgorithmCode, Nullable, SigmailKeyId, SigmailObjectId, SigmailOwnerId } from '@sigmail/common';
import { AppException, Constants, Utils } from '@sigmail/common';
import { Algorithm } from '@sigmail/crypto';
import { CryptographicKeyEncapsulated } from '../cryptographic-key/encapsulated-key';
import { SigmailObject } from '../sigmail-object';
import type {
  ApiFormattedCollectionObject,
  ApiFormattedCryptographicKey,
  ApiFormattedVersionedSigmailObject,
  ICollectionObject,
  ICryptographicKeyEncapsulated,
  IVersionedSigmailObject,
  ValueFormatVersion
} from '../types';
import { VersionedSigmailObject } from '../versioned-sigmail-object';

/** @public */
export type CollectionObjectValueType<T extends CollectionObject<ValueFormatVersion>> = T extends CollectionObject<
  infer DV
>
  ? DV
  : never;

const PROPS: ReadonlyArray<
  keyof Omit<
    ICollectionObject<ValueFormatVersion>,
    keyof IVersionedSigmailObject<unknown> | 'generateKeysEncryptedFor' | 'updateValue' | 'decryptedValue'
  >
> = ['value', 'ownerId', 'collectionId', 'page', 'createdAtUtc', 'expiredAtUtc'];

const API_FORMATTED_PROPS: ReadonlyArray<
  keyof Omit<ApiFormattedCollectionObject, keyof ApiFormattedVersionedSigmailObject>
> = ['ownerId', 'collectionId', 'page', 'key', 'value', 'createdAtUtc', 'expiredAtUtc'];

/** @public */
export abstract class CollectionObject<DV extends ValueFormatVersion>
  extends VersionedSigmailObject<DV>
  implements ICollectionObject<DV>
{
  protected static readonly DecryptedValueCache = new WeakMap<CollectionObject<any>, unknown>();

  protected static override get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_OBJECT as AlgorithmCode;
  }

  public static override isValidAlgorithmCode(value: unknown): value is AlgorithmCode {
    return Algorithm.isValidNonEncryptedObjectCode(value) || Algorithm.isValidEncryptObjectCode(value);
  }

  /** Determines if the given value is a valid encrypted value. */
  public static isValidEncryptedValue(value: unknown): value is string {
    return Utils.isString(value);
  }

  /**
   * Override this method in derived classes to provide a correct/more specific
   * implementation of validation checks for the decrypted value of an object.
   */
  protected static isValidDecryptedValue(value: unknown): boolean {
    if (!Utils.isNonArrayObjectLike<ValueFormatVersion>(value)) return false;

    const { $$formatver } = value;
    return Utils.isUndefined($$formatver) || (Utils.isInteger($$formatver) && $$formatver > 0);
  }

  /** Determines if the given value is a valid creation date. */
  public static isValidCreationDate(value: unknown): value is Date {
    return Utils.isValidDate(value);
  }

  /**
   * Determines if the given value is a valid expiry date. `null` is considered
   * a valid value.
   */
  public static isValidExpiryDate(value: unknown): value is Date | null {
    return value === null || Utils.isValidDate(value);
  }

  public static isValidCollectionId(value: unknown): value is SigmailObjectId {
    return SigmailObject.isValidId(value);
  }

  public static isValidPageNumber(value: unknown): value is number {
    return Utils.isInteger(value) && value > 0;
  }

  protected static isKeyAssignableFrom(key: unknown): key is ICryptographicKeyEncapsulated {
    return CryptographicKeyEncapsulated.isAssignableFrom(key);
  }

  protected static isKeyApiFormatted(key: unknown): key is ApiFormattedCryptographicKey {
    return CryptographicKeyEncapsulated.isApiFormatted(key);
  }

  protected static keyFromApiFormatted(key: unknown): ICryptographicKeyEncapsulated {
    return new CryptographicKeyEncapsulated(key as ApiFormattedCryptographicKey);
  }

  public static override isAssignableFrom(value: unknown): value is ICollectionObject<ValueFormatVersion> {
    if (!(super.isAssignableFrom(value) && Utils.every(PROPS, Utils.partial(Utils.has, value)))) return false;

    const obj = value as ICollectionObject<ValueFormatVersion>;
    return (
      this.isValidEncryptedValue(obj.value) &&
      this.isValidId(obj.ownerId) &&
      this.isValidCollectionId(obj.collectionId) &&
      this.isValidPageNumber(obj.page) &&
      this.isValidCreationDate(obj.createdAtUtc) &&
      this.isValidExpiryDate(obj.expiredAtUtc) &&
      (obj[Constants.$$CryptographicKey] === null || this.isKeyAssignableFrom(obj[Constants.$$CryptographicKey])) &&
      typeof obj.generateKeysEncryptedFor === 'function' &&
      typeof obj.updateValue === 'function' &&
      typeof obj.decryptedValue === 'function'
    );
  }

  public static override isApiFormatted(value: unknown): value is ApiFormattedCollectionObject {
    if (!(super.isApiFormatted(value) && Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, value)))) {
      return false;
    }

    const obj = value as ApiFormattedCollectionObject;
    return (
      Utils.isString(obj.value) &&
      Utils.isNumber(obj.ownerId) &&
      Utils.isNumber(obj.collectionId) &&
      Utils.isNumber(obj.page) &&
      Utils.isString(obj.createdAtUtc) &&
      (obj.expiredAtUtc === null || Utils.isString(obj.expiredAtUtc)) &&
      (obj.key === null || this.isKeyApiFormatted(obj.key))
    );
  }

  public static async create<T extends CollectionObject<any>>(
    this: new (...args: Array<any>) => T,
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number,
    value: CollectionObjectValueType<T>,
    ownerId: SigmailOwnerId,
    collectionId: number,
    page: number,
    encryptedFor: SigmailKeyId,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  ): Promise<T> {
    const Class = this as unknown as typeof CollectionObject;
    if (Class === CollectionObject) throw new TypeError('Illegal method invocation.');

    const objectCode = Utils.isUndefined(code) ? Class.DEFAULT_CODE : code;
    const key = await CryptographicKeyEncapsulated.createForObject(
      { type: Class.TYPE, id, code: objectCode, version } as CollectionObject<ValueFormatVersion>,
      encryptedFor,
      createdAtUtc
    );
    const encryptedValue = await Class.encryptObjectValue(value, { key, objectCode, objectVersion: version });

    const args = [
      id,
      objectCode,
      version,
      encryptedValue,
      ownerId,
      collectionId,
      page,
      key,
      createdAtUtc,
      expiredAtUtc
    ];

    return Reflect.construct(Class, args) as T;
  }

  protected static async updateValue<T extends CollectionObject<any>>(
    this: new (...args: Array<any>) => T,
    obj: T,
    newValue: CollectionObjectValueType<T>
  ): Promise<T> {
    const Class = this as unknown as typeof CollectionObject;
    if (Class === CollectionObject || Class !== obj.constructor) {
      throw new TypeError('Illegal method invocation.');
    }

    if (obj.type !== Class.TYPE) {
      throw new AppException(Constants.Error.E_UNKNOWN_OBJECT_TYPE);
    }

    if (obj.version === 0) {
      throw new AppException(Constants.Error.S_ERROR, 'Attempt to update the value of an immutable user object.');
    }

    const {
      id,
      code,
      ownerId,
      collectionId,
      page,
      [Constants.$$CryptographicKey]: key,
      createdAtUtc,
      expiredAtUtc
    } = obj;

    const version = obj.version + 1;
    const value = await Class.encryptObjectValue(newValue, { key, objectCode: code, objectVersion: version });
    const args = [id, code, version, value, ownerId, collectionId, page, key, createdAtUtc, expiredAtUtc];
    return Reflect.construct(Class, args) as T;
  }

  public readonly [Constants.$$CryptographicKey]!: ICryptographicKeyEncapsulated | null;
  public readonly collectionId: number;
  public readonly createdAtUtc: Date;
  public readonly expiredAtUtc: Date | null;
  public readonly ownerId: number;
  public readonly page: number;
  public readonly value: string;

  public constructor(
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number,
    value: string,
    ownerId: SigmailOwnerId,
    collectionId: number,
    page: number,
    key: ICryptographicKeyEncapsulated | null,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  );

  public constructor(obj: ApiFormattedCollectionObject);

  public constructor(...args: Array<unknown>) {
    super(...(args.length === 1 ? args : [undefined, ...args]));

    const Class = this.constructor as typeof CollectionObject;
    if (Class === CollectionObject) throw new TypeError('Initialization error.');

    const obj = args[0] as ApiFormattedCollectionObject;
    const value = args.length === 1 ? obj.value : args[3];
    const ownerId = args.length === 1 ? obj.ownerId : args[4];
    const collectionId = args.length === 1 ? obj.collectionId : args[5];
    const page = args.length === 1 ? obj.page : args[6];
    const apiFormattedKey = args.length === 1 ? obj.key : args[7];
    const createdAtUtc = args.length === 1 ? new Date(obj.createdAtUtc) : args[8];
    const expiredAtUtc = args.length === 1 ? obj.expiredAtUtc : (args[9] as Nullable<Date>);
    const expiryDate = Utils.isNil(expiredAtUtc) ? null : new Date(expiredAtUtc);

    const key =
      // eslint-disable-next-line no-nested-ternary
      apiFormattedKey === null
        ? null
        : Class.isKeyAssignableFrom(apiFormattedKey)
        ? apiFormattedKey
        : Class.keyFromApiFormatted(apiFormattedKey);

    if (!Class.isValidEncryptedValue(value)) throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE);
    if (!Class.isValidId(ownerId)) throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Invalid owner ID.');
    if (!Class.isValidCollectionId(collectionId))
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Invalid collection ID.');
    if (!Class.isValidPageNumber(page))
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Invalid page number.');
    if (!Class.isValidCreationDate(createdAtUtc)) throw new AppException(Constants.Error.E_INVALID_CREATION_DATE);
    if (!Class.isValidExpiryDate(expiryDate)) throw new AppException(Constants.Error.E_INVALID_EXPIRY_DATE);

    this[Constants.$$CryptographicKey] = key;
    this.collectionId = collectionId;
    this.createdAtUtc = createdAtUtc;
    this.expiredAtUtc = expiryDate;
    this.ownerId = ownerId;
    this.page = page;
    this.value = value;
  }

  protected freezeValue(decryptedValue: DV): DV {
    return Utils.deepFreeze(decryptedValue);
  }

  public abstract updateValue(newValue: DV): Promise<CollectionObject<DV>>;

  public override equals(other: unknown): other is ICollectionObject<DV> {
    return super.equals(other) && this.ownerId === (other as ICollectionObject<DV>).ownerId;
  }

  public override hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.ownerId)) | 0;
    return hashed;
  }

  public async generateKeysEncryptedFor(
    ...ids: [number, ...Array<number>]
  ): Promise<Array<ICryptographicKeyEncapsulated | null>>;

  public async generateKeysEncryptedFor(
    createdAtUtc: Date,
    ...ids: [number, ...Array<number>]
  ): Promise<Array<ICryptographicKeyEncapsulated | null>>;

  public async generateKeysEncryptedFor(
    ...ids: [Date | number, ...Array<number>]
  ): Promise<Array<ICryptographicKeyEncapsulated | null>> {
    const createdAtUtc = Utils.isDate(ids[0]) ? (ids.shift() as Date) : undefined;
    const keys = new Array<ICryptographicKeyEncapsulated | null>(ids.length);

    if (ids.length > 0) {
      keys.fill(null);

      if (!Algorithm.isValidNonEncryptedObjectCode(this.code)) {
        for (let index = 0; index < ids.length; index++) {
          const key = await this[Constants.$$CryptographicKey]!.encryptFor(
            (ids as Array<number>)[index]!,
            createdAtUtc
          );
          keys[index] = key;
        }
      }
    }

    return keys;
  }

  public async decryptedValue(): Promise<DV> {
    const Class = this.constructor as typeof CollectionObject;

    let value = Class.DecryptedValueCache.get(this) as DV | undefined;
    if (Utils.isUndefined(value)) {
      value = await Class.decryptObjectValue<DV>(this.value, {
        key: this[Constants.$$CryptographicKey],
        objectCode: this.code,
        objectVersion: this.version
      });

      if (!Class.isValidDecryptedValue(value)) {
        const errorMessage = [
          'Decrypted value failed the validation check.',
          `type=${this.type}`,
          `id=${this.id}`,
          `code=${this.code}`,
          `version=${this.version}`,
          `key.type=${this[Constants.$$CryptographicKey]?.type}`,
          `key.code=${this[Constants.$$CryptographicKey]?.code}`
        ];

        // eslint-disable-next-line no-console
        console.warn(errorMessage.concat('value=').join('\n'), value);

        throw new AppException(
          Constants.Error.E_INVALID_OBJECT_VALUE,
          `${errorMessage[0]} (${errorMessage.slice(1).join(', ')})`
        );
      }

      value = this.freezeValue(value);
      Class.DecryptedValueCache.set(this, value);
    }

    return value;
  }

  public override toApiFormatted(): ApiFormattedCollectionObject {
    const { code, id, type, version } = super.toApiFormatted();

    const key = this[Constants.$$CryptographicKey];
    let apiFormattedKey: ApiFormattedCryptographicKey | null = null;
    if (key !== null) {
      apiFormattedKey = key.toApiFormatted();
    }

    return {
      code,
      collectionId: this.collectionId,
      createdAtUtc: this.createdAtUtc.toISOString(),
      expiredAtUtc: Utils.stringOrDefault(this.expiredAtUtc?.toISOString(), null!),
      id,
      key: apiFormattedKey,
      ownerId: this.ownerId,
      page: this.page,
      type,
      value: this.value,
      version
    };
  }
}
