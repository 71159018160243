import { AppException, Constants, GenderIdentity, HRM, Utils } from '@sigmail/common';
import { FACILITY_HRM } from '../../../constants/error-facility';
import * as FormInputConstraint from '../../../constants/form-input-constraint';
import { HealthPlanNumber as HealthPlanNumberMask } from '../../../constants/form-input-mask';
import * as FormInputPattern from '../../../constants/form-input-pattern';
import i18n from '../../../i18n/HRM/form';
import { DatePickerFieldProps, DateTimePickerFieldProps, FieldProps } from '../../shared/form/form.component';
import * as HrmFormType from '../types/HRM';

export const HRM_COUNTRY_CODE_CANADA: Extract<HRM.CountryCode, 'CAN'> = 'CAN';
export const HRM_PROVINCE_CODE_ONTARIO: Extract<HRM.AddressState, 'ON'> = 'ON';

export const DEFAULT_GENDER: GenderIdentity = Constants.Gender.Unknown;
export const DEFAULT_ADDRESS_TYPE: HRM.AddressType = 'home';
export const DEFAULT_CONTACT_TYPE: HRM.ContactNumberType = 'mobile';
export const DEFAULT_COUNTRY_CODE: HRM.CountryCode = HRM_COUNTRY_CODE_CANADA;
export const DEFAULT_DECEASED_STATUS: boolean = false;
export const DEFAULT_DIAGNOSIS_CATEGORY: HRM.DiagnosticReportCategoryCode = 'OTH';
export const DEFAULT_DIAGNOSIS_LOINC_TYPE: HRM.DiagnosisLoincType = 'note';
export const DEFAULT_DIAGNOSIS_STATUS: HRM.DiagnosisStatus = 'final';
export const DEFAULT_DIAGNOSIS_URGENCY = false;
export const DEFAULT_ENCOUNTER_CLASS: HRM.EncounterClass = 'virtual';
export const DEFAULT_ENCOUNTER_STATUS: HRM.EncounterStatus = 'planned';
export const DEFAULT_PROVINCE: HRM.AddressState = HRM_PROVINCE_CODE_ONTARIO;

export const DIAGNOSIS_STATUS_CORRECTED: HRM.DiagnosisStatus = 'corrected';

export const MAX_ATTACHMENT_COUNT = 8;
export const MAX_TOTAL_ATTACHMENT_SIZE = 5 * 1024 * 1024;

export const ACCEPTABLE_FILE_EXT: Array<string> = ['txt', 'pdf', 'jpg', 'jpeg', 'png'];

export const ACCEPTABLE_MIME_LIST: Array<HRM.DocumentMimeType> = [
  Constants.MimeType.JPEG,
  Constants.MimeType.PDF,
  Constants.MimeType.PNG,
  Constants.MimeType.TEXT_PLAIN
];

const MAKE_ERROR_CODE = (code: number) => Utils.MAKE_ERROR_CODE(Constants.Error.SEVERITY_ERROR, FACILITY_HRM, code);

export const GuestProfileLoadError = new AppException(MAKE_ERROR_CODE(1));
export const InactiveLicenseError = new AppException(MAKE_ERROR_CODE(2));
export const MaxAttachmentSizeError = new AppException(MAKE_ERROR_CODE(3));
export const MissingCellNumberError = new AppException(MAKE_ERROR_CODE(4));
export const MissingLicenseNumberError = new AppException(MAKE_ERROR_CODE(5));
export const NonOntarioHpnError = new AppException(MAKE_ERROR_CODE(6));
export const SenderListLoadError = new AppException(MAKE_ERROR_CODE(7));
export const SenderProfileLoadError = new AppException(MAKE_ERROR_CODE(8));
export const UnauthorizedSenderError = new AppException(MAKE_ERROR_CODE(9));
export const UnsupportedDocRemovedError = new AppException(MAKE_ERROR_CODE(10));
export const UnsupportedMessageFormError = new AppException(MAKE_ERROR_CODE(11));
export const UserListLoadError = new AppException(MAKE_ERROR_CODE(12));

export const { fieldsetGroup: fieldsetGroupI18n } = i18n;

export const FIELD_SET_GROUP_LIST: ReadonlyArray<
  Readonly<
    [
      HrmFormType.FieldsetGroupKey,
      ReadonlyArray<Readonly<[HrmFormType.FieldsetKey, ReadonlyArray<HrmFormType.FieldName>]>>
    ]
  >
> = [
  [
    'patient',
    [
      ['fieldsetHealthPlan', ['hpn']],
      ['fieldsetName', ['firstName', 'lastName']],
      ['fieldsetGender', ['gender']],
      ['fieldsetBirthDate', ['birthDate']],
      ['fieldsetAddress', ['addressType', 'address', 'city', 'province', 'postalCode']],
      ['fieldsetContact', ['contactType', 'contactNumber']],
      ['fieldsetDeceased', ['deceased']]
    ]
  ],
  ['encounter', [['fieldsetEncounter', ['startDate', 'endDate', 'encounterClass', 'encounterStatus']]]],
  [
    'diagnosis',
    [
      [
        'fieldsetDiagnosis',
        // ['loincType', 'category', 'diagnosisStatus', 'urgent', 'conclusion', 'issuedDate', 'effectiveDate']
        ['loincType', 'category', 'diagnosisStatus', 'conclusion', 'issuedDate', 'effectiveDate']
      ]
    ]
  ],
  ['notes', [['fieldsetNotes', ['notes']]]],
  ['document', [['fieldsetRecipients', ['recipient']]]]
];

export const FIELD_SET_LIST = Utils.flatten(FIELD_SET_GROUP_LIST.map(([_, fieldsetList]) => fieldsetList));

const STRING_VALUE_FIELD_NAME_LIST: ReadonlyArray<HrmFormType.StringValueFieldName> = [
  'firstName',
  'lastName',
  'gender',
  'hpn',
  'addressType',
  'address',
  'city',
  'province',
  'postalCode',
  'contactType',
  'contactNumber',
  'encounterClass',
  'encounterStatus',
  'loincType',
  'category',
  'diagnosisStatus',
  'conclusion',
  'notes'
];

const BOOLEAN_VALUE_FIELD_NAME_LIST: ReadonlyArray<HrmFormType.BooleanValueFieldName> = ['deceased'];

const DATE_VALUE_FIELD_NAME_LIST: ReadonlyArray<HrmFormType.DateValueFieldName> = ['birthDate'];

const DATE_TIME_VALUE_FIELD_NAME_LIST: ReadonlyArray<HrmFormType.DateTimeValueFieldName> = [
  'startDate',
  'endDate',
  'issuedDate',
  'effectiveDate'
];

export const FIELD_PROPS = {
  //
  // STRING_VALUE_FIELD_NAME_LIST
  //
  ...STRING_VALUE_FIELD_NAME_LIST.reduce(
    (fieldProps, fieldName) => {
      fieldProps[fieldName] = { autoComplete: 'off', ...fieldProps[fieldName] };
      return fieldProps;
    },
    {
      firstName: {
        label: i18n.fieldsetGroup.patient.fieldsetName.formField.firstName.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetName.formField.firstName.placeholder,
        config: {
          required: true,
          length: FormInputConstraint.FirstName,
          pattern: FormInputPattern.FirstName
        }
      },
      lastName: {
        label: i18n.fieldsetGroup.patient.fieldsetName.formField.lastName.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetName.formField.lastName.placeholder,
        config: {
          required: true,
          length: FormInputConstraint.LastName,
          pattern: FormInputPattern.LastName
        }
      },
      gender: {
        select: true,
        label: i18n.fieldsetGroup.patient.fieldsetGender.formField.gender.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetGender.formField.gender.placeholder
      },
      hpn: {
        inputProps: { readOnly: true },
        label: i18n.fieldsetGroup.patient.fieldsetHealthPlan.formField.hpn.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetHealthPlan.formField.hpn.placeholder,
        config: { required: true }
      },
      addressType: {
        select: true,
        label: i18n.fieldsetGroup.patient.fieldsetAddress.formField.addressType.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetAddress.formField.addressType.placeholder
      },
      address: {
        label: i18n.fieldsetGroup.patient.fieldsetAddress.formField.address.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetAddress.formField.address.placeholder
      },
      city: {
        label: i18n.fieldsetGroup.patient.fieldsetAddress.formField.city.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetAddress.formField.city.placeholder
      },
      province: {
        select: true,
        label: i18n.fieldsetGroup.patient.fieldsetAddress.formField.province.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetAddress.formField.province.placeholder
      },
      postalCode: {
        label: i18n.fieldsetGroup.patient.fieldsetAddress.formField.postalCode.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetAddress.formField.postalCode.placeholder,
        maskedInputProps: { mask: 'postalCode', guide: false }
      },
      contactType: {
        select: true,
        label: i18n.fieldsetGroup.patient.fieldsetContact.formField.contactType.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetContact.formField.contactType.placeholder
      },
      contactNumber: {
        inputProps: { inputMode: 'tel' },
        label: i18n.fieldsetGroup.patient.fieldsetContact.formField.contactNumber.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetContact.formField.contactNumber.placeholder,
        maskedInputProps: { mask: 'phoneNumber', guide: false },
        config: { pattern: FormInputPattern.PhoneNumber }
      },
      encounterClass: {
        select: true,
        label: i18n.fieldsetGroup.encounter.fieldsetEncounter.formField.encounterClass.label,
        placeholder: i18n.fieldsetGroup.encounter.fieldsetEncounter.formField.encounterClass.placeholder
      },
      encounterStatus: {
        select: true,
        label: i18n.fieldsetGroup.encounter.fieldsetEncounter.formField.encounterStatus.label,
        placeholder: i18n.fieldsetGroup.encounter.fieldsetEncounter.formField.encounterStatus.placeholder
      },
      loincType: {
        select: true,
        label: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.loincType.label,
        placeholder: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.loincType.placeholder
      },
      category: {
        select: true,
        label: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.category.label,
        placeholder: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.category.placeholder
      },
      diagnosisStatus: {
        select: true,
        label: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.diagnosisStatus.label,
        placeholder: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.diagnosisStatus.placeholder
      },
      conclusion: {
        label: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.conclusion.label,
        placeholder: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.conclusion.placeholder
      },
      notes: {
        label: i18n.fieldsetGroup.notes.fieldsetNotes.formField.notes.label,
        placeholder: i18n.fieldsetGroup.notes.fieldsetNotes.formField.notes.placeholder,
        inputProps: { readOnly: false },
        multiline: true,
        rows: 8,
        rowsMax: 8
      }
    } as Record<HrmFormType.StringValueFieldName, FieldProps>
  ),
  //
  // BOOLEAN_VALUE_FIELD_NAME_LIST
  //
  ...BOOLEAN_VALUE_FIELD_NAME_LIST.reduce(
    (fieldProps, fieldName) => {
      fieldProps[fieldName] = {
        fullWidth: false,
        size: 'small',
        ...fieldProps[fieldName]
      };
      return fieldProps;
    },
    {
      deceased: {
        label: i18n.fieldsetGroup.patient.fieldsetDeceased.formField.deceased.label
      }
    } as Record<HrmFormType.BooleanValueFieldName, FieldProps>
  ),
  //
  // DATE_VALUE_FIELD_NAME_LIST
  //
  ...DATE_VALUE_FIELD_NAME_LIST.reduce(
    (fieldProps, fieldName) => {
      fieldProps[fieldName] = {
        ...fieldProps[fieldName],
        config: {
          required: { typeOf: 'date' },
          ...fieldProps[fieldName]?.config
        }
      };
      return fieldProps;
    },
    {
      birthDate: {
        label: i18n.fieldsetGroup.patient.fieldsetBirthDate.formField.birthDate.label,
        placeholder: i18n.fieldsetGroup.patient.fieldsetBirthDate.formField.birthDate.placeholder
      }
    } as Record<HrmFormType.DateValueFieldName, DatePickerFieldProps>
  ),
  //
  // DATE_TIME_VALUE_FIELD_NAME_LIST
  //
  ...DATE_TIME_VALUE_FIELD_NAME_LIST.reduce(
    (fieldProps, fieldName) => {
      fieldProps[fieldName] = {
        ...fieldProps[fieldName],
        config: {
          required: { typeOf: 'date' },
          ...fieldProps[fieldName]?.config
        }
      };
      return fieldProps;
    },
    {
      startDate: {
        label: i18n.fieldsetGroup.encounter.fieldsetEncounter.formField.startDate.label,
        placeholder: i18n.fieldsetGroup.encounter.fieldsetEncounter.formField.startDate.placeholder,
        config: {
          range: FormInputConstraint.HrmStartDate,
          validateFields: ['endDate']
        }
      },
      endDate: {
        label: i18n.fieldsetGroup.encounter.fieldsetEncounter.formField.endDate.label,
        placeholder: i18n.fieldsetGroup.encounter.fieldsetEncounter.formField.endDate.placeholder,
        config: {
          range: (_, { startDate }) => ({
            min: Utils.isValidDate(startDate) ? startDate : FormInputConstraint.HrmEndDate.min,
            max: FormInputConstraint.HrmEndDate.max
          })
        }
      },
      issuedDate: {
        label: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.effectiveDate.label,
        placeholder: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.effectiveDate.placeholder,
        config: { range: FormInputConstraint.HrmIssuedDate }
      },
      effectiveDate: {
        label: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.issuedDate.label,
        placeholder: i18n.fieldsetGroup.diagnosis.fieldsetDiagnosis.formField.issuedDate.placeholder,
        config: { range: FormInputConstraint.HrmEffectiveDate }
      }
    } as Record<HrmFormType.DateTimeValueFieldName, DateTimePickerFieldProps>
  ),
  recipient: {
    disableClearable: true,
    exclude: 'inactive',
    includeInputInList: false,
    ListboxProps: {
      style: { maxHeight: '30vh', position: 'relative' }
    },
    multiple: true,
    openOnFocus: false,
    renderInputParams: {
      inputProps: {
        'aria-required': true
      },
      label: i18n.fieldsetGroup.document.fieldsetRecipients.formField.recipient.label,
      placeholder: i18n.fieldsetGroup.document.fieldsetRecipients.formField.recipient.placeholder
    },
    config: {
      minLength: FormInputConstraint.HrmRecipientList.minLength
    }
  } as HrmFormType.AutocompleteHrmUserListProps
};

export const FIELD_PROPS_NON_SIGMAIL_GUEST: typeof FIELD_PROPS = {
  ...FIELD_PROPS,
  hpn: {
    ...FIELD_PROPS.hpn,
    inputProps: { ...FIELD_PROPS.hpn.inputProps, readOnly: false },
    maskedInputProps: {
      guide: false,
      mask: HealthPlanNumberMask.CAN[Constants.CanadianProvinceCode.Ontario],
      pipe: (value) => (value.endsWith('-') ? value.slice(0, value.length - 1) : value)
    },
    config: {
      ...FIELD_PROPS.hpn.config,
      pattern: FormInputPattern.HealthPlanNumber.CAN[Constants.CanadianProvinceCode.Ontario]
    }
  }
};
