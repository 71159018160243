import { AppException, Constants, PersonName, Utils } from '@sigmail/common';
import {
  ClientObjectContactList,
  ClientObjectContactListValue,
  GroupObjectProfileBasic,
  GroupObjectProfileBasicValue,
  IUserObject,
  UserObjectContactInfoValue,
  UserObjectProfileBasicValue,
  UserObjectProfileProtectedValue
} from '@sigmail/objects';
import {
  contactListObjectSelector as clientContactListObjectSelector,
  globalContactListIdSelector
} from '../../../selectors/client-object';
import { basicProfileObjectSelector as groupBasicProfileObjectSelector } from '../../../selectors/group-object';
import { FetchObjectsRequestData } from '../../base-action';
import { BaseSendAccountInvitationAction, BaseSendAccountInvitationPayload, UserObjectDataUpdater } from './base';

export interface ContactInfoBasic
  extends Pick<
    UserObjectContactInfoValue,
    keyof PersonName | `${'cell' | 'fax' | 'office'}Number` | 'emailAddress' | 'officeNumberExt' | 'role' | 'specialty'
  > {}

export interface ContactInfoProtected {}

export interface Payload extends BaseSendAccountInvitationPayload {
  readonly profileData: Readonly<{
    basic: ContactInfoBasic;
    protected: ContactInfoProtected;
  }>;
}

export class SendNonGuestAccountInvitationAction extends BaseSendAccountInvitationAction<Payload> {
  /** override */
  protected async fetchClientAdminData(): Promise<void> {
    await super.fetchClientAdminData();

    const { clientId, roleAuthClaim: authState } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        { type: process.env.CLIENT_OBJECT_TYPE_CONFIGURATION, userId: clientId },
        { type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST, userId: clientId },
        { type: process.env.CLIENT_OBJECT_TYPE_PROFILE, userId: clientId },
        { type: process.env.CLIENT_OBJECT_TYPE_USER_LIST, userId: clientId }
      ]
    };

    const { userObjectList } = await this.dispatchFetchObjects(query);
    if (query.userObjectsByType!.some(({ type }) => !userObjectList.some((obj) => obj.type === type))) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch one or more of the required client objects.');
    }

    this.state.globalContactListId = globalContactListIdSelector(this.getRootState())!;
  }

  /** override */
  protected createBasicProfileValue(): UserObjectProfileBasicValue {
    const { groupList, profileData } = this.payload;

    return {
      $$formatver: 5,
      ...profileData.basic,
      memberOf: groupList.map(({ groupData, ...group }) => group)
    };
  }

  /** override */
  protected createProtectedProfileValue(): UserObjectProfileProtectedValue {
    return { $$formatver: 2, ...this.payload.profileData.protected };
  }

  /** override */
  protected createContactInfoValue(): UserObjectContactInfoValue {
    const { institute, profileData } = this.payload;

    return {
      $$formatver: 2,
      ...profileData.basic,
      ...profileData.protected,
      institute,
      noNotifyOnNewMessage: ['webPush']
    };
  }

  /** override */
  protected async generateRequestBody() {
    await super.generateRequestBody();

    await this.addUpdateOperationForGroupProfileBasic(); // 431
    await this.addUpdateOperationForClientContactList(); // 466
  }

  private async addUpdateOperationForGroupProfileBasic(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group profile.');

    const { groupList } = this.payload;
    const { inviteeId, requestBody, successPayload } = this.state;

    for (const { id: groupId } of groupList) {
      const applyUpdate = async (profileObject: IUserObject<GroupObjectProfileBasicValue>) => {
        const profile = await profileObject.decryptedValue();

        const updatedList = profile.memberList.concat({ type: 'user', id: inviteeId });
        const updatedValue: GroupObjectProfileBasicValue = { ...profile, memberList: updatedList };
        this.logger.debug(updatedValue);

        return profileObject.updateValue(updatedValue);
      };

      const profileObject = await this.getUserObject(groupBasicProfileObjectSelector, {
        type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC,
        userId: groupId
      });

      if (Utils.isNil(profileObject)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Group basic profile could not be fetched.');
      }

      const profileObjectKey = profileObject[Constants.$$CryptographicKey];
      const dataUpdater: UserObjectDataUpdater = async (profileJson, { userObjects }) => {
        let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === profileJson.id);
        if (index === -1) {
          throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; group profile could not be found in request body.');
        }

        const key = profileObjectKey === null ? null : profileObjectKey.toApiFormatted();
        const profileObject = new GroupObjectProfileBasic({ ...profileJson, key });
        const updatedObject = await applyUpdate(profileObject);
        userObjects![index].data = updatedObject;

        index = successPayload.response.userObjects!.findIndex(({ id }) => id === profileJson.id);
        if (index !== -1) {
          successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
        }
      };

      const updatedObject = await applyUpdate(profileObject);
      requestBody.update(updatedObject, dataUpdater);

      successPayload.request.userObjects!.ids.push(profileObject.id);
      successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
    }
  }

  private async addUpdateOperationForClientContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for client contact list.');

    const { profileData } = this.payload;
    const { clientId, inviteeId, requestBody, successPayload } = this.state;

    const applyUpdate = async (contactListObject: IUserObject<ClientObjectContactListValue>) => {
      const contactList = await contactListObject.decryptedValue();

      const updatedValue: ClientObjectContactListValue = {
        ...contactList,
        list: contactList.list.concat({
          type: 'user',
          id: inviteeId,
          userData: {
            ...profileData.basic,
            ...profileData.protected,
            noNotifyOnNewMessage: ['webPush']
          }
        })
      };
      this.logger.debug(updatedValue);

      return contactListObject.updateValue(updatedValue);
    };

    const contactListObject = await this.getUserObject(clientContactListObjectSelector, { userId: clientId });
    if (Utils.isNil(contactListObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Client contact list is either missing or invalid.');
    }

    const contactListObjectKey = contactListObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (contactListJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === contactListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; client contact list could not be found in request body.');
      }

      const key = contactListObjectKey === null ? null : contactListObjectKey.toApiFormatted();
      const contactListObject = new ClientObjectContactList({ ...contactListJson, key });
      const updatedObject = await applyUpdate(contactListObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === contactListJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(contactListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(contactListObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }
}
