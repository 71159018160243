import { CardContent, CardHeader, Collapse } from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { personNameSelector, userRoleSelector } from '../../../app-state/selectors/user-object';
import { AppDrawer as ActionContextAppDrawer } from '../../../constants/action-context';
import * as ActionId from '../../../constants/action-ids';
import { useNextId } from '../../../hooks';
import { useTranslation } from '../../../i18n';
import { I18N_NS_GLOBAL } from '../../../i18n/config/namespace-identifiers';
import globalI18n from '../../../i18n/global';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { MemberAvatar } from '../../shared/member-avatar.component';
import style from './app-drawer.module.css';

export type MenuGroupKey =
  | typeof ActionId.UserAccountMenu
  | typeof ActionId.Mailbox
  | typeof ActionId.ContactList
  | typeof ActionId.Schedule;

export interface Props extends React.ComponentPropsWithoutRef<'div'> {
  onToggle: { bivarianceHack(event: {}, group: MenuGroupKey): void }['bivarianceHack'];
  open: boolean;
  variant: MenuGroupKey;
}

export const AppDrawerMenuGroup = React.memo<Props>(({ children, onToggle, open, variant, ...rootProps }) => {
  const { t } = useTranslation([I18N_NS_GLOBAL]);
  const { firstName, fullName, lastName } = useSelector(personNameSelector)(/***/);
  const roleId = useSelector(userRoleSelector)(/***/);
  const cardContentId = useNextId('app-drawer-collapse-');

  let title: React.ReactNode;
  switch (variant) {
    case ActionId.ContactList:
    case ActionId.Mailbox:
    case ActionId.Schedule:
      title = t(resolveActionLabel(globalI18n.action[variant], variant, ActionContextAppDrawer));
      break;
    case ActionId.UserAccountMenu:
      title = (
        <React.Fragment>
          <MemberAvatar firstName={firstName} lastName={lastName} license={Utils.getLicenseTypeFromRole(roleId)} />
          <span styleName="style.username">{fullName}</span>
        </React.Fragment>
      );
      break;
    default:
      break;
  }

  const onClick = React.useMemo(() => onToggle.bind(null, {}, variant), [onToggle, variant]);

  return (
    <div styleName="style.menu-group" {...rootProps}>
      <CardHeader
        action={<ExpandIcon />}
        aria-controls={cardContentId}
        aria-expanded={open}
        classes={{ action: style.action, root: style.header }}
        disableTypography={true}
        onClick={onClick}
        tabIndex={0}
        title={title}
      />

      <Collapse in={open} timeout="auto">
        <CardContent id={cardContentId} classes={{ root: style.content }}>
          {children}
        </CardContent>
      </Collapse>
    </div>
  );
});

AppDrawerMenuGroup.displayName = 'AppDrawerMenuGroup';
