import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogHrmMissingLicenseNumber } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeHrmMessage['dialog']['missingLicenseNumber'] = {
  // Translators: (HTML) Title for the Missing License Number dialog in \
  // Compose HRM Message view
  title: gettext('messaging', 'title', 'dialogHrmMissingLicenseNumber', 'safe-html'),
  // Translators: (HTML) Message displayed in the body of Missing License \
  // Number dialog in Compose HRM Message view
  body: gettext('messaging', 'body', 'dialogHrmMissingLicenseNumber', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Missing License Number \
      // dialog in Compose HRM Message view
      label: gettext('messaging', 'accept', 'dialogHrmMissingLicenseNumberActionLabel'),
      context: DialogHrmMissingLicenseNumber
    }
  }
};

export default i18n;
