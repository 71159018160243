import {
  ActionPayloadAuthSuccess,
  ActionPayloadSignIn,
  ApiActionPayload,
  SignInSuccess,
  SignInSuccessAuthOnly,
  SrpVerifyCredentialResponseData
} from '@sigmail/app-state';
import {
  AppException,
  AppUser,
  AuthException,
  CalendarEvent,
  Constants,
  SigmailClientId,
  SigmailKeyId,
  SigmailObjectId,
  SigmailUserId,
  SrpEphemeral,
  Utils,
  Writeable
} from '@sigmail/common';
import { EncryptWithParametersAlgorithmParams, getAlgorithm } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedDataObject,
  ApiFormattedUserObject,
  BaseEventLogRecord,
  CalendarEventMetadata,
  ClientAccessRights,
  ClientObjectConfiguration,
  CryptographicKey,
  CryptographicKeyMaster,
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  DataObjectMsgFolder,
  DataObjectSigmailGlobalContactList,
  DataObjectSigmailGlobalContactListValue,
  EventLogRecordSessionAuth,
  GroupContactListItem,
  GroupObjectFolderList,
  GroupObjectFolderListValue,
  IUserObject,
  UserCredentials,
  UserCredentialsMfaLogin,
  UserObjectAccessRights,
  UserObjectAccessRightsValue,
  UserObjectContactList,
  UserObjectFolderList,
  UserObjectFolderListValue,
  UserObjectPreferences,
  UserObjectProfileBasic,
  UserObjectProfileBasicValue,
  UserObjectProfilePrivate,
  UserObjectProfileProtected,
  UserObjectSchedule,
  UserObjectScheduleValue,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { isBefore } from 'date-fns';
import { IAuthenticationData } from 'sigmail';
import { authSuccess } from '.';
import { AppThunk } from '..';
import { CIRCLE_OF_CARE, SIGMAIL_MAIN_GROUP, SIGMAIL_REPORT_GROUP } from '../../constants/medical-institute-user-group-type-identifier';
import { AuthenticationData } from '../../core/authentication-data';
import { BatchUpdateRequestBuilder } from '../../utils/batch-update-request-builder';
import { processNotificationsAction as processAccountNotificationsAction } from '../actions/account/process-notifications-action';
import { ActionInitParams, BaseAction, BaseActionState, FetchObjectsRequestData } from '../actions/base-action';
import { batchQuerySuccessAction } from '../actions/batch-query-success-action';
import { AUTH_STATE_AUTHORIZED_AS_ROLE, AUTH_STATE_LOG_EVENT } from '../actions/constants/auth-state-identifier';
import { createSigMailGroupAction } from '../actions/groups/create-sigmail-group-action';
import { logEventAction } from '../actions/log-event-action';
import { migration_createEConsultObject } from '../actions/migrations/create-e-consult-object';
import { migration_createScheduleObject } from '../actions/migrations/create-schedule-object';
import { migration_eventLogInitialization } from '../actions/migrations/event-log-initialization';
import { migration_multiGroupRelease } from '../actions/migrations/multi-group-release';
import { srpExchangeCredentialAction } from '../actions/SRP/srp-exchange-credential-action';
import { srpVerifyCredentialAction } from '../actions/SRP/srp-verify-credential-action';
import { authFailure } from '../auth-slice';
import { setCaregiverAuthClaim } from '../caregiver-slice';
import { queueReminderNotification } from '../reminder-notification-slice';
import { sessionIdSelector } from '../selectors';

type EventLogRecordValueSessionAuth = EventLogRecordSessionAuth['value'];

interface State extends BaseActionState {
  accessRightsObject: IUserObject<UserObjectAccessRightsValue>;
  basicProfile: UserObjectProfileBasicValue;
  protectedProfileObject: UserObjectProfileProtected;
  circleOfCareGroupList: ReadonlyArray<NonNullable<UserObjectProfileBasicValue['memberOf']>[0]>;
  clientEphemeral: SrpEphemeral;
  clientRights: ClientAccessRights;
  credentialId: SigmailObjectId;
  credentialType: number;
  currentVersionClaim: string;
  dtServer: Date;
  eventLogRecord: Writeable<Omit<EventLogRecordValueSessionAuth, 'aid' | 'cid' | 'oid' | 'ua' | 'uid'>>;
  fetchMfaData: boolean;
  globalContactList: DataObjectSigmailGlobalContactListValue;
  globalContactListId: SigmailObjectId;
  keyId: SigmailKeyId;
  loggedInClaim: string;
  mfaData: Readonly<Pick<IAuthenticationData, 'otpClaim'> & Pick<SignInSuccess, 'mfaCredentialId'>>;
  msgFolderJsonList: Array<ApiFormattedDataObject>;
  nonce: string;
  notificationCountClaim: string;
  schedule: UserObjectScheduleValue;
  serverEphemeralPublic: string;
  serverRights: UserObjectServerRightsValue;
  sharedParameters: SignInSuccess['sharedParameters'];
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
  userId: SigmailUserId;
  userKeyPublic: CryptographicKeyPublic;
  verifyCredentialsResponseData: SrpVerifyCredentialResponseData;
}

type StageIdentifier =
  | 'SrpExchange'
  | 'SrpVerification'
  | 'ExtractIdToken'
  | 'FetchMfaData'
  | 'ValidateIds'
  | 'PreloadUserObjects'
  | 'FetchRoleAuthClaim'
  | 'FetchClientAuditKeys'
  | 'FetchGroupListAndKeys'
  | 'EncryptScheduleObject'
  | 'PreloadGroupObjects'
  | 'PreloadGroupMsgFolders'
  | 'PreloadClientObjects'
  | 'FetchGclKeys'
  | 'CreateSigMailReportGroup'
  | 'PreloadUserMsgFolders'
  | 'ApplyMigrations'
  | 'PreInitializeSession'
  | 'AppStateUpdate';

const SIGMAIL_GROUP_LIST: ReadonlyArray<{ readonly groupName: string; readonly groupType: string }> = [
  {
    groupName: 'SigMail',
    groupType: SIGMAIL_MAIN_GROUP
  },
  {
    groupName: 'SigMail Report',
    groupType: SIGMAIL_REPORT_GROUP
  }
];

const StageEnum: Readonly<Record<StageIdentifier, `${number}`>> = {
  SrpExchange: '01',
  SrpVerification: '11',
  ExtractIdToken: '21',
  FetchMfaData: '31',
  ValidateIds: '41',
  PreloadUserObjects: '51',
  FetchRoleAuthClaim: '61',
  FetchClientAuditKeys: '71',
  FetchGroupListAndKeys: '81',
  EncryptScheduleObject: '91',
  PreloadGroupObjects: '101',
  PreloadGroupMsgFolders: '111',
  PreloadClientObjects: '121',
  FetchGclKeys: '131',
  CreateSigMailReportGroup: '132',
  PreloadUserMsgFolders: '141',
  ApplyMigrations: '151',
  PreInitializeSession: '161',
  AppStateUpdate: '171'
};

const flattenUTCEvents = (utcEvents: UserObjectScheduleValue['events']): ReadonlyArray<CalendarEventMetadata> => {
  return Object.keys(utcEvents).flatMap((index) => {
    const nextUTCEvents = utcEvents[+index];
    if (Utils.isNonArrayObjectLike<UserObjectScheduleValue['events']>(nextUTCEvents)) {
      return flattenUTCEvents(nextUTCEvents);
    }

    return (nextUTCEvents as unknown) as ReadonlyArray<CalendarEventMetadata>;
  });
};

class SignInAction extends BaseAction<ActionPayloadSignIn, State, (SignInSuccess | SignInSuccessAuthOnly) | undefined> {
  private readonly privateKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);

  public constructor(params: ActionInitParams<ActionPayloadSignIn>) {
    super(params);

    if (params.payload.authOnly !== true) {
      const { attempt, credentialHash } = params.payload;
      this.state.eventLogRecord = {
        attempt,
        credentialHash,
        result: 'FAILURE',
        role: undefined!
      };
    }

    this.state.dtServer = new Date();
  }

  protected async onExecute() {
    let authSuccessPayload: ActionPayloadAuthSuccess | null = null;
    let stage = StageEnum.SrpExchange;

    try {
      await this.srpExchangeCredentials();

      stage = StageEnum.SrpVerification;
      await this.srpVerifyCredentials();

      stage = StageEnum.ExtractIdToken;
      this.extractIdTokenData();

      if (this.state.fetchMfaData === true) {
        stage = StageEnum.FetchMfaData;
        await this.fetchMfaData();
      }

      if (this.payload.authOnly === true) {
        this.logger.info('Authentication succeeded.');

        const authOnlySuccessPayload: SignInSuccessAuthOnly = {
          ...this.state.verifyCredentialsResponseData,
          ...this.state.mfaData,
          loggedInClaim: this.state.loggedInClaim,
          user: this.state.currentUser,
          sharedParameters: this.state.sharedParameters
        };

        return authOnlySuccessPayload;
      }

      stage = StageEnum.ValidateIds;
      if (!CryptographicKey.isValidId(this.state.keyId)) throw new AuthException(Constants.Error.E_AUTH_FAIL_KEY_ID);
      if (!UserCredentials.isValidId(this.state.credentialId)) throw new AuthException(Constants.Error.E_AUTH_FAIL_CREDENTIAL_ID);
      if (!AuthenticationData.isValidAuthClaim(this.state.loggedInClaim)) throw new AuthException(Constants.Error.E_AUTH_FAIL_AUTH_STATE);

      stage = StageEnum.PreloadUserObjects;
      await this.preloadUserObjects();

      stage = StageEnum.FetchRoleAuthClaim;
      await this.fetchRoleAuthorizationClaim();

      stage = StageEnum.FetchClientAuditKeys;
      await this.fetchClientAndAuditKeys();

      stage = StageEnum.FetchGroupListAndKeys;
      await this.fetchCircleOfCareGroupListAndKeys();

      stage = StageEnum.EncryptScheduleObject;
      await this.encryptScheduleObjectForUserKey();

      if (this.state.clientRights.accessGroupMailbox === true) {
        stage = StageEnum.PreloadGroupObjects;
        await this.preloadGroupFolderListObjects();

        stage = StageEnum.PreloadGroupMsgFolders;
        await this.preloadGroupMessageFolders();
      }

      if (this.state.clientRights.accessClientContacts === true || this.state.clientRights.accessClientUserList === true) {
        stage = StageEnum.PreloadClientObjects;
        await this.preloadClientObjects();
      }

      if (this.state.clientRights.accessGlobalContacts === true) {
        stage = StageEnum.FetchGclKeys;
        await this.fetchGlobalContactListKeys();

        stage = StageEnum.CreateSigMailReportGroup;
        await this.createSigMailGroup();
      }

      if (!Utils.isCaregiverRole(this.state.basicProfile.role)) {
        stage = StageEnum.PreloadUserMsgFolders;
        await this.preloadUserMessageFolders();
      }

      stage = StageEnum.ApplyMigrations;
      await this.applyMigrations();

      const { mfaCredentialId, ...mfaData } = this.state.mfaData;
      authSuccessPayload = {
        ...this.state.verifyCredentialsResponseData,
        ...mfaData,
        activeCircleOfCareGroupId: this.state.activeGroupId,
        authClaim: this.state.roleAuthClaim,
        ncClaim: this.state.notificationCountClaim,
        user: this.state.currentUser
      };
    } catch (error) {
      if (this.payload.authOnly === true) {
        this.logger.info('Authentication failed.');
        return;
      }

      let { reason } = this.state.eventLogRecord;
      if (Utils.isNonArrayObjectLike<Error>(error)) {
        reason = error.message;
        this.logger.error(error, reason);
      }

      let errorCode = Constants.Error.E_AUTH_FAIL;
      if (error instanceof Error && error.name === 'InvalidTokenError') {
        errorCode = Constants.Error.E_AUTH_FAIL_DECODE_ID_TOKEN;
      } else if (error instanceof AppException) {
        errorCode = error.errorCode;
      }

      this.state.eventLogRecord.reason = reason;
      this.state.eventLogRecord.stage = stage;
      await this.logSessionAuthEvent(stage);
      this.dispatch(authFailure({ errorCode }));

      return;
    }

    this.logger.info('Authentication succeeded.');
    try {
      stage = StageEnum.PreInitializeSession;
      const { beforeInitializeSession } = this.payload;
      if (typeof beforeInitializeSession === 'function') {
        try {
          await Promise.resolve(beforeInitializeSession(authSuccessPayload));
        } catch (error) {
          this.logger.warn('beforeInitializeSession failed with an error', error);
          throw error;
        }
      }

      try {
        stage = StageEnum.AppStateUpdate;
        await this.dispatch(batchQuerySuccessAction(this.state.successPayload));
      } catch (error) {
        this.logger.warn('batchQuerySuccessAction failed with an error', error);
        throw error;
      }

      this.dispatch(authSuccess(authSuccessPayload));

      if (Utils.isCaregiverRole(this.state.basicProfile.role)) {
        this.dispatch(setCaregiverAuthClaim(authSuccessPayload.authClaim));
      }

      const { schedule } = this.state;
      if (Utils.isNotNil(schedule)) {
        const upcomingEventsList = flattenUTCEvents(schedule.events).filter(
          (event): event is CalendarEventMetadata & CalendarEvent =>
            !event.allDay && !event.cancelled && isBefore(this.state.dtServer.getTime(), event.end)
        );

        this.dispatch(
          queueReminderNotification(
            upcomingEventsList.map(({ eventObjectId, start, title }) => ({
              eventObjectId,
              start,
              title
            }))
          )
        );
      }

      this.state.sessionId = sessionIdSelector(this.getRootState());
      this.state.eventLogRecord.result = 'SUCCESS';
      this.state.eventLogRecord.stage = undefined;
      await this.logSessionAuthEvent(stage);

      const signInSuccessPayload: SignInSuccess = {
        ...authSuccessPayload,
        mfaCredentialId: this.state.mfaData.mfaCredentialId,
        sharedParameters: this.state.sharedParameters
      };

      if (this.state.clientRights.accessCircleOfCare === true) {
        /* -NO- await */ this.dispatch(
          processAccountNotificationsAction({
            type: 'profileUpdate'
          })
        );
      }

      const { basicProfile } = this.state;
      if (Utils.isCaregiverRole(basicProfile.role) || Utils.isGuestRole(basicProfile.role)) {
        /* -NO- await */ this.dispatch(
          processAccountNotificationsAction({
            type: 'caregiverUpdate'
          })
        );
      }

      return signInSuccessPayload;
    } catch (error) {
      let reason: string | undefined;
      if (Utils.isNonArrayObjectLike<Error>(error)) {
        reason = error.message;
        this.logger.error(error, reason);
      }

      let errorCode = Constants.Error.E_AUTH_FAIL;
      if (error instanceof AppException) {
        errorCode = error.errorCode;
      }

      this.state.eventLogRecord.reason = reason;
      this.state.eventLogRecord.stage = stage;
      await this.logSessionAuthEvent(stage);
      this.dispatch(authFailure({ errorCode }));

      return;
    }
  }

  private async srpExchangeCredentials(): Promise<void> {
    this.logger.info('Initiating the sign-in process with exchange of credentials.');

    const { credentialHash } = this.payload;
    const { nonce, clientEphemeral, counterpartB, shared } = await this.dispatch(srpExchangeCredentialAction({ credentialHash }));
    this.logger.debug('serverEphemeralPublic =', counterpartB);

    this.state.nonce = nonce;
    this.state.clientEphemeral = clientEphemeral;
    this.state.serverEphemeralPublic = counterpartB;

    const sharedParams = JSON.parse(shared) as State['sharedParameters'];
    if (!Utils.isNonArrayObjectLike(sharedParams) || !AuthenticationData.isValidSalt(sharedParams.salt)) {
      throw new AuthException(Constants.Error.E_AUTH_FAIL_SALT);
    }

    this.state.sharedParameters = sharedParams;
    this.logger.debug('salt =', sharedParams.salt);
  }

  private async srpVerifyCredentials(): Promise<void> {
    this.logger.info("Credential exchange succeeded; continuing with client's session proof verification.");

    const { credentialHash, password } = this.payload;
    const { nonce, clientEphemeral, serverEphemeralPublic, sharedParameters } = this.state;
    const { salt: hexSalt } = sharedParameters;

    const trimmedPassword = (Utils.isString(password) && password.trim()) || '';
    const { passwordHash } = await Utils.generatePasswordHash(trimmedPassword, hexSalt);

    const verifyCredentialsResponseData = await this.dispatch(
      srpVerifyCredentialAction({
        sessionId: nonce,
        salt: hexSalt,
        credentialHash,
        passwordHash,
        clientEphemeralSecret: clientEphemeral.secret,
        serverEphemeralPublic
      })
    );

    this.state.verifyCredentialsResponseData = verifyCredentialsResponseData;
    this.state.accessToken = verifyCredentialsResponseData.accessToken;
  }

  private extractIdTokenData(): void {
    this.logger.info('Extracting user ID, key ID, and credential ID out of the JWT ID token.');

    const { idToken } = this.state.verifyCredentialsResponseData;

    const decodedIdToken = Utils.decodeIdToken(idToken);
    if (!Utils.isValidJwtToken(decodedIdToken.authState, 'id')) {
      throw new AuthException(Constants.Error.E_AUTH_FAIL_AUTH_STATE);
    }
    this.logger.debug('decodedIdToken =', decodedIdToken);

    const decodedAuthState = Utils.decodeIdToken(decodedIdToken.authState);
    if (!AppUser.isValidId(decodedAuthState.userId)) {
      throw new AuthException(Constants.Error.E_AUTH_FAIL_USER_ID);
    }
    this.logger.debug('decodedAuthState =', decodedAuthState);

    const { credentialId, keyId, name, type: credentialType, userId } = decodedAuthState;
    // =========================================================================
    // leave the following commented code intact as a reminder that
    // we do NOT perform these validations here intentionally
    // =========================================================================
    // if (!CryptographicKey.isValidId(keyId)) throw new AuthException(Constants.Error.E_AUTH_FAIL_KEY_ID);
    // if (!UserCredentials.isValidId(credentialId)) throw new AuthException(Constants.Error.E_AUTH_FAIL_CREDENTIAL_ID);

    this.state.dtServer = new Date((decodedIdToken.iat as number) * 1000);
    this.state.currentUser = { type: 'user', id: userId };
    this.state.userId = userId;
    this.state.keyId = keyId;
    this.state.credentialId = credentialId;
    this.state.credentialType = credentialType;
    this.state.fetchMfaData =
      credentialType === process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN ||
      (credentialType === process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN && name === 'passwordReset');
    this.state.loggedInClaim = decodedIdToken.authState;
  }

  private async fetchMfaData(): Promise<void> {
    this.logger.info('Fetching any MFA data associated with the credential.');

    const {
      loggedInClaim: authState,
      userId,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      userCredentialsByType: [{ userId, type: process.env.USER_CREDENTIALS_TYPE_MFA_LOGIN }],
      expectedCount: { userCredentialsByType: null }
    };

    const { credentialList } = await this.fetchObjects(accessToken, query);
    if (credentialList.length === 0) {
      this.state.mfaData = { mfaCredentialId: null };
      return;
    }

    const mfaJson = this.findCredentials(credentialList, { type: process.env.USER_CREDENTIALS_TYPE_MFA_LOGIN, userId });
    if (Utils.isNil(mfaJson)) {
      throw new AuthException('No matching MFA credential object could be found.');
    }

    const mfaSharedParams = JSON.parse(mfaJson.sharedParameters);
    if (!UserCredentialsMfaLogin.isValidSharedParameters(mfaSharedParams)) {
      throw new AuthException(Constants.Error.E_AUTH_FAIL_SHARED_PARAMS, 'MFA shared parameters are either missing or invalid.');
    }

    this.state.mfaData = {
      mfaCredentialId: mfaJson.id,
      otpClaim: mfaSharedParams.otpClaim
    };

    this.logger.info('MFA record found. Credential ID =', mfaJson.id);
    this.logger.info('otpClaim =', mfaSharedParams.otpClaim);
  }

  private async preloadUserObjects(): Promise<void> {
    this.logger.info('Preloading some of the user objects which may be required immediately after sign-in.');

    const {
      userId,
      keyId,
      credentialId,
      loggedInClaim: authState,
      verifyCredentialsResponseData: { accessToken },
      sharedParameters: { salt: hexSalt }
    } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      encryptedFor: { ids: [userId] },
      keysByType: [
        { id: keyId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER, encryptedForId: credentialId },
        { id: userId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, encryptedForId: keyId },
        { id: userId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }
      ],
      userObjectsByType: [
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC },
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED },
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE },
        { userId, type: process.env.USER_OBJECT_TYPE_FOLDER_LIST },
        { userId, type: process.env.USER_OBJECT_TYPE_SCHEDULE },
        { userId, type: process.env.USER_OBJECT_TYPE_CONTACT_INFO },
        { userId, type: process.env.USER_OBJECT_TYPE_CONTACT_LIST },
        { userId, type: process.env.USER_OBJECT_TYPE_GUEST_DATA },
        { userId, type: process.env.USER_OBJECT_TYPE_PREFERENCES },
        { userId, type: process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS },
        { userId, type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS }
      ],
      expectedCount: { claims: 1 }
    };

    const { keyList, userObjectList, claims, serverDateTime } = await this.fetchObjects(accessToken, query);
    const currentVersionClaim = this.findClaim(claims, { name: 'currentVersion' });
    if (Utils.isNil(currentVersionClaim)) {
      throw new AuthException(Constants.Error.E_AUTH_FAIL_AUTH_STATE, 'Current version claim is either missing or invalid.');
    }

    this.state.currentVersionClaim = currentVersionClaim;
    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);

    //
    // decrypt and cache master private key
    //
    const masterKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER, id: keyId });
    if (Utils.isNil(masterKeyJson)) {
      throw new AuthException('No matching user master key could be found.');
    } else {
      const masterKeyPrivate = new CryptographicKeyMaster(masterKeyJson);
      const trimmedPassword = (Utils.isString(this.payload.password) && this.payload.password.trim()) || '';
      const params: EncryptWithParametersAlgorithmParams = {
        type: process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN,
        parameter1: this.payload.username, // IMPORTANT: use username as-is, do NOT trim() or toLowerCase() etc
        parameter2: trimmedPassword,
        hexSalt
      };
      this.logger.debug('Decryption params', JSON.stringify(params));
      const jsonWebKey = await masterKeyPrivate.decryptedValue(params);
      this.logger.debug('User master key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.privateKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPrivateKey(masterKeyPrivate.id, cryptoKey);
    }

    //
    // decrypt and cache user private key
    //
    const userPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: userId });
    if (Utils.isNil(userPrivateKeyJson)) {
      CryptographicKey.clearPrivateKey(masterKeyJson.id);
      throw new AuthException('No matching user private key could be found.');
    } else {
      const userKeyPrivate = new CryptographicKeyPrivate(userPrivateKeyJson);
      await CryptographicKey.cache(userKeyPrivate);
      CryptographicKey.clearPrivateKey(masterKeyJson.id);
    }

    //
    // decrypt and cache user public key
    //
    const userPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: userId });
    if (Utils.isNil(userPublicKeyJson)) {
      throw new AuthException('No matching user public key could be found.');
    } else {
      const userKeyPublic = new CryptographicKeyPublic(userPublicKeyJson);
      await CryptographicKey.cache(userKeyPublic);

      this.state.userKeyPublic = userKeyPublic;
    }

    //
    // decrypt and save basic profile for later use
    //
    const basicProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId });
    if (Utils.isNil(basicProfileJson)) {
      throw new AuthException('No matching user basic profile object could be found.');
    } else {
      const basicProfileObject = new UserObjectProfileBasic(basicProfileJson);
      this.state.basicProfile = await basicProfileObject.decryptedValue();
      this.state.eventLogRecord.role = this.state.basicProfile.role;
      this.logger.debug('basicProfile', JSON.stringify(this.state.basicProfile));
    }

    //
    // decrypt and save protected profile for later use
    //
    const protectedProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED, userId });
    if (Utils.isNil(protectedProfileJson)) {
      throw new AuthException('No matching user protected profile object could be found.');
    } else {
      this.state.protectedProfileObject = new UserObjectProfileProtected(protectedProfileJson);
    }

    //
    // decrypt and extract audit/client/owner ID from private profile
    //
    const privateProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId });
    if (Utils.isNil(privateProfileJson)) {
      throw new AuthException('No matching user private profile object could be found.');
    } else {
      const privateProfileObject = new UserObjectProfilePrivate(privateProfileJson);
      const privateProfile = await privateProfileObject.decryptedValue();
      this.state.auditId = privateProfile.auditId;
      this.state.clientId = privateProfile.clientId;
      this.state.ownerId = privateProfile.ownerId;
      this.logger.debug('privateProfile', JSON.stringify(privateProfile));
    }

    //
    // decrypt and save user schedule for later use
    //
    const scheduleJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_SCHEDULE, userId });
    if (Utils.isNotNil(scheduleJson)) {
      const scheduleObject = new UserObjectSchedule(scheduleJson);
      const schedule = await scheduleObject.decryptedValue();
      this.state.schedule = schedule;
    }

    //
    // save access rights object as is (i.e. encrypted) for later use
    //
    const index = this.findUserObjectIndex(userObjectList, { type: process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS, userId });
    const accessRightsJson = index === -1 ? undefined : userObjectList[index];
    if (Utils.isNil(accessRightsJson)) {
      throw new AuthException('No matching user access rights object could be found.');
    } else {
      const accessRightsObject = new UserObjectAccessRights(accessRightsJson);
      this.state.accessRightsObject = accessRightsObject;
      const { clientRights } = await accessRightsObject.decryptedValue();
      this.state.clientRights = clientRights;
    }

    //
    // decrypt and save server rights for later use
    //
    const serverRightsJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS, userId });
    if (Utils.isNil(serverRightsJson)) {
      throw new AuthException('No matching user server rights object could be found.');
    } else {
      const serverRightsObject = new UserObjectServerRights(serverRightsJson);
      this.state.serverRights = await serverRightsObject.decryptedValue();
      this.logger.debug('serverRights', JSON.stringify(this.state.serverRights));
    }

    this.state.successPayload = {
      request: { userObjectsByType: query.userObjectsByType!, dataObjects: { ids: [] } },
      response: { userObjectsByType: userObjectList, dataObjects: [], serverDateTime: '' }
    };
  }

  private async fetchRoleAuthorizationClaim(): Promise<void> {
    this.logger.info('Fetching the role authorization claim.');

    const {
      basicProfile: { role: roleId },
      loggedInClaim,
      currentVersionClaim,
      serverRights: { userClaim },
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    const authorizedAsRoleState = AUTH_STATE_AUTHORIZED_AS_ROLE[roleId];
    if (!Utils.isString(authorizedAsRoleState)) {
      throw new AuthException('Role authorization state name could not be determined.');
    }

    const query: Api.EnterStateRequestData = {
      authState: loggedInClaim,
      claims: [currentVersionClaim, userClaim],
      state: authorizedAsRoleState
    };

    const { authState } = await this.enterState(accessToken, query);
    if (!AuthenticationData.isValidAuthClaim(authState)) {
      throw new AuthException(Constants.Error.E_AUTH_FAIL_AUTH_STATE, 'Role authorization claim is either missing or invalid.');
    }

    this.state.roleAuthClaim = authState;

    if (!Utils.isCaregiverRole(roleId)) {
      try {
        this.state.notificationCountClaim = (
          await this.enterState(accessToken, {
            authState,
            state: 'notificationCount',
            userId: this.state.userId
          })
        ).authState;
      } catch (error) {
        this.logger.warn('Failed to fetch notification count claim.', error);
        /* ignore */
      }
    }
  }

  private async fetchClientAndAuditKeys(): Promise<void> {
    this.logger.info('Fetching audit public key, and client public and private keys.');

    const {
      basicProfile: { role: roleId },
      roleAuthClaim: authState,
      verifyCredentialsResponseData: { accessToken },
      clientId,
      auditId
    } = this.state;

    const isUserRoleNonGuest = Utils.isNonGuestRole(roleId) && !Utils.isCaregiverRole(roleId);
    const encryptedForId = isUserRoleNonGuest ? clientId : undefined;
    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [
        isUserRoleNonGuest ? { id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE } : undefined,
        { id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, encryptedForId },
        { id: auditId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, encryptedForId }
      ].filter(Utils.isNotNil)
    };

    const { keyList } = await this.fetchObjects(accessToken, query);

    if (isUserRoleNonGuest) {
      //
      // decrypt and cache client private key
      //
      const clientPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: clientId });
      if (Utils.isNil(clientPrivateKeyJson)) {
        throw new AuthException('No matching client private key could be found.');
      } else {
        const clientKeyPrivate = new CryptographicKeyPrivate(clientPrivateKeyJson);
        await CryptographicKey.cache(clientKeyPrivate);
      }
    }

    //
    // decrypt and cache client public key
    //
    const clientPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: clientId });
    if (Utils.isNil(clientPublicKeyJson)) {
      throw new AuthException('No matching client public key could be found.');
    } else {
      const clientKeyPublic = new CryptographicKeyPublic(clientPublicKeyJson);
      await CryptographicKey.cache(clientKeyPublic);
    }

    //
    // decrypt and cache audit public key
    //
    const auditPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: auditId });
    if (Utils.isNil(auditPublicKeyJson)) {
      throw new AuthException('No matching audit public key could be found.');
    } else {
      const auditKeyPublic = new CryptographicKeyPublic(auditPublicKeyJson);
      await CryptographicKey.cache(auditKeyPublic);
    }
  }

  private async fetchCircleOfCareGroupListAndKeys(): Promise<void> {
    this.logger.info('Fetching private and public keys of all circle of care groups user is a member of.');

    const {
      basicProfile,
      successPayload: { response },
      userId,
      roleAuthClaim: authState,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    let groupList: State['circleOfCareGroupList'] = [];
    if (Utils.isGuestRole(basicProfile.role)) {
      const contactListJson = this.findUserObject(response.userObjectsByType!, { type: process.env.USER_OBJECT_TYPE_CONTACT_LIST, userId });
      if (Utils.isNil(contactListJson)) {
        throw new AuthException('No matching user contact list object could be found.');
      }
      const contactListObject = new UserObjectContactList(contactListJson);
      const { contacts: contactList } = await contactListObject.decryptedValue();
      groupList = contactList.filter(
        (contact): contact is GroupContactListItem => contact.type === 'group' && contact.groupType === CIRCLE_OF_CARE
      );
    } else if (Utils.isCaregiverRole(basicProfile.role)) {
      return;
    } else if (Utils.isNonGuestRole(basicProfile.role) && Utils.isNonEmptyArray(basicProfile.memberOf)) {
      groupList = basicProfile.memberOf.filter(({ groupType }) => groupType === CIRCLE_OF_CARE);
    }

    this.state.circleOfCareGroupList = groupList;
    if (groupList.length === 0) {
      throw new AuthException("User's circle of care group list is empty.");
    }

    this.state.activeGroupId = groupList[0].id;
    const userPreferencesJson = this.findUserObject(response.userObjectsByType, { type: process.env.USER_OBJECT_TYPE_PREFERENCES, userId });
    if (Utils.isNotNil(userPreferencesJson)) {
      try {
        const userPreferencesObject = new UserObjectPreferences(userPreferencesJson);
        const { lastActiveGroupId } = await userPreferencesObject.decryptedValue();
        if (Utils.isInteger(lastActiveGroupId) && groupList.some(({ id }) => id === lastActiveGroupId)) {
          this.state.activeGroupId = lastActiveGroupId;
        }
      } catch (error) {
        this.logger.warn('Error determining last active group ID:', error);
        /* ignore */
      }
    }

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: Utils.flatten(
        groupList.map(({ id }) => [
          { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id },
          { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id, encryptedForId: id }
        ])
      )
    };

    const { keyList } = await this.fetchObjects(accessToken, query);
    for (const groupKeyJson of keyList) {
      if (groupKeyJson.type === process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE) {
        const groupKeyPrivate = new CryptographicKeyPrivate(groupKeyJson);
        await CryptographicKey.cache(groupKeyPrivate);
      } else if (groupKeyJson.type === process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC) {
        const groupKeyPublic = new CryptographicKeyPublic(groupKeyJson);
        await CryptographicKey.cache(groupKeyPublic);
      } else {
        throw new AuthException('Invalid/unknown object type.');
      }
    }
  }

  private async encryptScheduleObjectForUserKey(): Promise<void> {
    this.logger.info('Encrypting schedule object for user key (if required).');

    const {
      roleAuthClaim: authState,
      userId,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    let query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ userId, type: process.env.USER_OBJECT_TYPE_SCHEDULE }],
      expectedCount: { userObjectsByType: null }
    };

    let scheduleJson: ApiFormattedUserObject | undefined = undefined;
    try {
      const { userObjectList } = await this.fetchObjects(accessToken, query);
      scheduleJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_SCHEDULE, userId });
    } catch {
      /* ignore */
    }

    if (Utils.isNil(scheduleJson)) return;

    const scheduleObject = new UserObjectSchedule(scheduleJson);

    query = {
      authState,
      keysByType: [{ id: scheduleObject.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_ENCAPSULATED, encryptedForId: userId }],
      expectedCount: { keysByType: null }
    };

    const { keyList } = await this.fetchObjects(accessToken, query);

    const keyJsonForUser = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_ENCAPSULATED, encryptedForId: userId });
    if (Utils.isNil(keyJsonForUser)) {
      const keyListToInsert = (await scheduleObject.generateKeysEncryptedFor(userId)).filter(Utils.isNotNil);
      if (keyListToInsert.length > 0) {
        const requestBody: Api.BatchUpdateRequestData = {
          authState,
          keys: keyListToInsert.map((key) => ({ operation: 'insert', data: key }))
        };
        await this.batchUpdateData(accessToken, requestBody);
      }
    }
  }

  private async preloadGroupFolderListObjects(): Promise<void> {
    this.logger.info('Preloading folder list objects for all circle of care groups user is a member of.');

    const {
      roleAuthClaim: authState,
      circleOfCareGroupList,
      verifyCredentialsResponseData: { accessToken },
      successPayload: { request, response }
    } = this.state;

    // fetch group folder list
    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: circleOfCareGroupList.map(({ id: userId }) => ({ type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST, userId })),
      expectedCount: { userObjectsByType: null }
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    request.userObjectsByType!.push(...query.userObjectsByType!);
    response.userObjectsByType!.push(...userObjectList);
  }

  private async preloadGroupMessageFolders(): Promise<void> {
    this.logger.info('Trying to preload message folder data for active circle of care group.');

    const {
      successPayload: { request, response },
      activeGroupId,
      roleAuthClaim: authState,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    try {
      const folderListJson = this.findUserObject(response.userObjectsByType, {
        type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST,
        userId: activeGroupId
      });

      if (Utils.isNil(folderListJson)) {
        throw new AuthException(`Group folder list object is either missing or invalid. (groupId=${activeGroupId})`);
      }

      const folderListObject = new GroupObjectFolderList(folderListJson);
      const folderList: GroupObjectFolderListValue<1 | 2> = await folderListObject.decryptedValue();
      let messageFolderIds: Array<number> = [];
      if (Utils.isUndefined(folderList.$$formatver) || folderList.$$formatver === 1) {
        messageFolderIds = Object.values(folderList)
          .map((folder) => folder?.id)
          .filter(DataObjectMsgFolder.isValidId);
      } else if (folderList.$$formatver >= 2) {
        messageFolderIds = Utils.flatten(
          Object.values(folderList.msg).map(({ id, children }) => {
            const ids: Array<number | undefined> = [id];
            if (!Utils.isUndefined(children)) {
              ids.push(...Object.values(children).map((folder) => folder?.id));
            }
            return ids.filter(DataObjectMsgFolder.isValidId);
          })
        );
      }

      const query: FetchObjectsRequestData = {
        authState,
        dataObjects: { ids: messageFolderIds },
        expectedCount: { dataObjects: null }
      };

      const { dataObjectList } = await this.fetchObjects(accessToken, query);

      (this.state.msgFolderJsonList || (this.state.msgFolderJsonList = [])).push(...dataObjectList);

      request.dataObjects!.ids.push(...messageFolderIds);
      response.dataObjects!.push(...dataObjectList);
    } catch (error) {
      this.logger.warn(error);
      /* ignore */
    }
  }

  private async preloadClientObjects(): Promise<void> {
    this.logger.info('Trying to preload client user and contact list objects.');

    const {
      roleAuthClaim: authState,
      clientId,
      clientRights,
      verifyCredentialsResponseData: { accessToken },
      successPayload: { request, response }
    } = this.state;

    try {
      const query: FetchObjectsRequestData = {
        authState,
        userObjectsByType: [
          { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_EVENT_LOG },
          { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_PROFILE },
          { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONFIGURATION },
          { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST },
          { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_USER_LIST }
        ],
        expectedCount: { userObjectsByType: null }
      };

      if (clientRights.accessClientCollectionList === true) {
        query.userObjectsByType!.push({ userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_COLLECTION_LIST });
      }

      const { userObjectList } = await this.fetchObjects(accessToken, query);

      const clientConfigObject = this.findAndCreateUserObject(userObjectList, ClientObjectConfiguration, { userId: clientId });
      if (Utils.isNil(clientConfigObject)) {
        throw new AuthException('No matching client configuration object could be found.');
      } else {
        const clientConfig = await clientConfigObject.decryptedValue();
        this.state.globalContactListId = clientConfig.globalContactListId;
      }

      request.userObjectsByType!.push(...query.userObjectsByType!);
      response.userObjectsByType!.push(...userObjectList);
    } catch (error) {
      this.logger.warn(error);
      /* ignore */
    }
  }

  private async fetchGlobalContactListKeys(): Promise<void> {
    this.logger.info('Fetching global contact list public and private keys.');

    const {
      roleAuthClaim: authState,
      globalContactListId: keyId,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      dataObjects: { ids: [keyId] },
      keysByType: [
        { id: keyId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE },
        { id: keyId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, encryptedForId: keyId }
      ]
    };

    const { dataObjectList, keyList } = await this.fetchObjects(accessToken, query);

    const dataObject = this.findDataObject(dataObjectList, { id: keyId });
    if (Utils.isNotNil(dataObject)) {
      void new DataObjectSigmailGlobalContactList(dataObject)
        .decryptedValue()
        .then((value) => void (this.state.globalContactList = value), Utils.noop);
    }

    //
    // decrypt and cache global contact list private key
    //
    const contactListPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: keyId });
    if (Utils.isNil(contactListPrivateKeyJson)) {
      throw new AuthException('No matching global contact list private key could be found.');
    } else {
      const contactListKeyPrivate = new CryptographicKeyPrivate(contactListPrivateKeyJson);
      await CryptographicKey.cache(contactListKeyPrivate);
    }

    //
    // decrypt and cache global contact list public key
    //
    const contactListPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: keyId });
    if (Utils.isNil(contactListPublicKeyJson)) {
      throw new AuthException('No matching global contact list public key could be found.');
    } else {
      const contactListKeyPublic = new CryptographicKeyPublic(contactListPublicKeyJson);
      await CryptographicKey.cache(contactListKeyPublic);
    }
  }

  private async createSigMailGroup(): Promise<void> {
    if (Utils.isNil(this.state.globalContactList)) return;

    const { accessToken: apiAccessToken, currentUser, globalContactList, roleAuthClaim } = this.state;
    const { id: currentUserId } = currentUser;
    const { list } = globalContactList;

    for (const { groupName, groupType } of SIGMAIL_GROUP_LIST) {
      this.logger.info(`Creating a group of type "${groupType}" in global contact list (if it doesn't exist already).`);

      const index = list.findIndex((contact) => contact.type === 'group' && contact.groupType === groupType);
      if (index >= 1) {
        const { id: groupId } = list[index];
        this.logger.info(`Operation skipped; an entry with "${groupType}" group type was found in global contact list (ID=${groupId}).`);
        return;
      }

      try {
        await this.dispatch(
          createSigMailGroupAction({
            apiAccessToken,
            currentUserId,
            groupName,
            groupType,
            roleAuthClaim
          })
        );
      } catch (error) {
        this.logger.warn('Failed to create SigMail Report Group:', error);
        /* ignore */
      }
    }
  }

  private async preloadUserMessageFolders(): Promise<void> {
    this.logger.info('Trying to preload user message folder data.');

    const {
      roleAuthClaim: authState,
      verifyCredentialsResponseData: { accessToken },
      successPayload: { request, response }
    } = this.state;

    try {
      const folderListJson = this.findUserObject(response.userObjectsByType, { type: process.env.USER_OBJECT_TYPE_FOLDER_LIST });
      if (Utils.isNil(folderListJson)) {
        throw new AuthException('No matching user folder list object could be found.');
      }

      const folderListObject = new UserObjectFolderList(folderListJson);
      const folderList: UserObjectFolderListValue<1 | 2> = await folderListObject.decryptedValue();
      let messageFolderIds: Array<number> = [];
      if (Utils.isUndefined(folderList.$$formatver) || folderList.$$formatver === 1) {
        messageFolderIds = Object.values(folderList)
          .map((folder) => folder?.id)
          .filter(DataObjectMsgFolder.isValidId);
      } else if (folderList.$$formatver >= 2) {
        messageFolderIds = Utils.flatten(
          Object.values(folderList.msg).map(({ id, children }) => {
            const ids: Array<number | undefined> = [id];
            if (!Utils.isUndefined(children)) {
              ids.push(...Object.values(children).map((folder) => folder?.id));
            }
            return ids.filter(DataObjectMsgFolder.isValidId);
          })
        );
      }

      const query: FetchObjectsRequestData = {
        authState,
        dataObjects: { ids: messageFolderIds },
        expectedCount: { dataObjects: null }
      };

      const { dataObjectList } = await this.fetchObjects(accessToken, query);

      (this.state.msgFolderJsonList || (this.state.msgFolderJsonList = [])).push(...dataObjectList);

      request.dataObjects!.ids.push(...messageFolderIds);
      response.dataObjects!.push(...dataObjectList);
    } catch (error) {
      this.logger.warn(error);
      /* ignore */
    }
  }

  private async applyMigrations(): Promise<void> {
    // =========================================================================
    // ............................... IMPORTANT ...............................
    // =========================================================================
    //
    // Changing the order of migrations may possibly cause data corruption; be
    // absolutely sure of what you're doing before making any changes
    //
    // =========================================================================

    const {
      activeGroupId,
      auditId,
      basicProfile: { role: roleId },
      clientId,
      globalContactListId,
      roleAuthClaim,
      successPayload,
      userId,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    const isUserRoleCaregiver = Utils.isCaregiverRole(roleId);
    const isUserRolePhysician = !isUserRoleCaregiver && Utils.isPhysicianRole(roleId);
    const isUserRoleNonGuest = isUserRolePhysician || (!isUserRoleCaregiver && Utils.isNonGuestRole(roleId));
    if (isUserRolePhysician) {
      const payload = { accessToken, auditId, clientId, roleAuthClaim, successPayload, userId };

      await this.dispatch(migration_createScheduleObject({ ...payload, groupId: activeGroupId }));
      await this.dispatch(migration_createEConsultObject(payload));
      await this.dispatch(migration_multiGroupRelease({ ...payload, globalContactListId }));
    }

    if (isUserRoleNonGuest) {
      await this.dispatch(migration_eventLogInitialization({ accessToken, roleAuthClaim, roleId, userId }));
    }
  }

  private async logSessionAuthEvent(stage: string): Promise<void> {
    const { accessToken, basicProfile, clientId, dtServer, eventLogRecord, roleAuthClaim: authState, userId: currentUserId } = this.state;

    if (!AppUser.isValidId(currentUserId)) {
      return;
    }

    const record = this.newEventLogRecordValue(dtServer, Constants.EventLogCode.SessionAuth, eventLogRecord);
    if (Number.parseInt(stage, 10) <= Number.parseInt(StageEnum.FetchRoleAuthClaim, 10)) {
      try {
        const { localStorage: storage } = window;
        storage.setItem(`sigmail_log_record_${dtServer.getTime()}`, JSON.stringify(record));
      } catch {
        this.logger.warn('Local storage does not seem to be available.');
        /* ignore */
      }
      return;
    }

    const isUserRoleNonGuest = Utils.isNonGuestRole(basicProfile.role) && !Utils.isCaregiverRole(basicProfile.role);
    const userIdList = Utils.arrayOrDefault<SigmailClientId | SigmailUserId>(isUserRoleNonGuest && [clientId]).concat(currentUserId);

    const recordList = [record];
    try {
      const { localStorage: storage } = window;
      for (let index = storage.length - 1; index >= 0; index--) {
        try {
          const key = Utils.stringOrDefault(storage.key(index));
          if (!key.startsWith('sigmail_log_record_')) continue;

          const value = Utils.trimOrDefault(storage.getItem(key));
          if (value.length === 0) continue;

          const item = JSON.parse(value) as BaseEventLogRecord & EventLogRecordSessionAuth;
          if (item.code !== Constants.EventLogCode.SessionAuth || item.value.uid !== currentUserId) {
            continue;
          }

          recordList.push(item);
          storage.removeItem(key);
        } catch {
          /* ignore */
          continue;
        }
      }
    } catch {
      /* ignore */
    }

    for (const userId of userIdList) {
      this.logger.info(`Attempting to log the session auth event. <userId=${userId}>`);

      const requestBody = new BatchUpdateRequestBuilder();
      const successPayload: ApiActionPayload.BatchQueryDataSuccess = {
        request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
        response: { dataObjects: [], userObjects: [], serverDateTime: dtServer.toISOString() }
      };

      try {
        const claims = await this.dispatch(
          logEventAction({
            accessToken,
            authState,
            dtServer,
            fetchIds: (count) => {
              return this.fetchIdsByUsage(accessToken, {
                authState,
                state: AUTH_STATE_LOG_EVENT,
                ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
              });
            },
            logger: this.logger,
            record: recordList,
            requestBody,
            successPayload,
            userId,
            userIdType: userId === clientId ? 'client' : 'user'
          })
        );

        const { authState: batchUpdateAuthState } = await this.enterState(accessToken, {
          authState,
          state: AUTH_STATE_LOG_EVENT
        });

        await this.batchUpdateData(accessToken, { authState: batchUpdateAuthState, claims, ...requestBody.build() });

        try {
          await this.dispatch(batchQuerySuccessAction(successPayload));
        } catch (error) {
          this.logger.warn('Error manually updating app state:', error);
          /* ignore */
        }
      } catch (error) {
        this.logger.warn(`Failed to log session auth event. <userId=${userId}>`, error);
        /* ignore */
      }
    }
  }
}

export const signInAction = (payload: ActionPayloadSignIn): AppThunk<Promise<(SignInSuccess | SignInSuccessAuthOnly) | undefined>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'signInAction:');

    const action = new SignInAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
