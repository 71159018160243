import { Utils } from '@sigmail/common';
import { CarePlan, CarePlanSectionId } from '@sigmail/objects';

export function getCarePlanSectionLastUpdate(
  plan: CarePlan,
  sectionId: CarePlanSectionId
): [CarePlan['lastUpdated'][0], CarePlan['lastUpdated'][0]['changeset'][0]] | [undefined, undefined] {
  for (const update of plan.lastUpdated) {
    const change = update.changeset.find(({ path }) => (path as Array<string>).includes(`section/${sectionId}`));
    if (Utils.isNotNil(change)) {
      return [update, change];
    }
  }
  return [undefined, undefined];
}
