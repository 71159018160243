import {
  HealthDataFormNameBPReading,
  HealthDataFormNameCardiacIntake,
  HealthDataFormNameCopdAssessment,
  HealthDataFormNameJointSurgery,
  HealthDataFormNameKCCQ,
  HealthDataFormNameVitals
} from '@sigmail/common';
import { BloodPressureCategory, CopdAssessmentCategory } from '@sigmail/objects';

export const DataFormCssClassPrefix: string = 'data-form-';

export const DataBPReadingCategory: ReadonlyArray<BloodPressureCategory> = [
  'lowNormal',
  'normal',
  'highNormal',
  'hypertension1',
  'hypertension2',
  'hypertension3'
];

export const DataCopdAssessmentCategory: ReadonlyArray<CopdAssessmentCategory> = ['low', 'medium', 'high', 'veryHigh'];

export const DataFormNameBPReading: HealthDataFormNameBPReading = 'bpReading';
export const DataFormNameCardiacIntake: HealthDataFormNameCardiacIntake = 'cardiacIntake';
export const DataFormNameCopdAssessment: HealthDataFormNameCopdAssessment = 'copdAssessment';
export const DataFormNameJointSurgery: HealthDataFormNameJointSurgery = 'jointSurgery';
export const DataFormNameKCCQ: HealthDataFormNameKCCQ = 'kccq';
export const DataFormNameVitals: HealthDataFormNameVitals = 'vitals';

export const VITALS_FORM_SLIDER_INPUT_STEP = 10;
export const CARDIAC_FORM_SLIDER_INPUT_STEP = 1;

export const SPLIT_BY_CAPITAL_REGEX = /(?=[A-Z])/;
