import { createSelector } from '@reduxjs/toolkit';
import { StoreStateAuthorization, StoreStateCaregiver } from '@sigmail/app-state';
import { Constants, Utils } from '@sigmail/common';
import { ClientAccessRights, UserObjectAccessRightsValue } from '@sigmail/objects';
import { toDate } from 'date-fns';
import { EMPTY_ARRAY } from '../constants';
import { RootState } from '../root-reducer';
import { UserObjectCache } from '../user-objects-slice/cache';
import { createUserObjectSelector } from './utils';

function clamp(num: number, min: number, max: number) {
  return Math.max(min, Math.min(num, max));
}

export const MAX_VALUE_ATTACHMENT_COUNT = 128;
export const MAX_VALUE_RECALL_MESSAGE_WINDOW = 365000;
export const MAX_VALUE_REPLY_MESSAGE_WINDOW = 365;

const EIGHT_MB = 8388608;
const TWENTY_FIVE_MB = 26214400;

const IGNORE_LOGGED_IN_AUTH_CLAIM: ReadonlyArray<string> = ['oneTimeMessage'];

/** Selector to get the current authentication state from the store. */
const authStateSelector: Reselect.Selector<RootState, StoreStateAuthorization> = (state) => state.auth;

/** Selector to get the current caregiver state from the store. */
const caregiverStateSelector: Reselect.Selector<RootState, StoreStateCaregiver> = (state) => state.caregiver;

/** Selector to extract the Bearer access token from auth state. */
export const accessTokenSelector = createSelector(authStateSelector, (state) => state.accessToken);

/** Selector to extract the salt value from auth state. */
export const saltValueSelector = createSelector(authStateSelector, (state) => state.salt);

/** Selector to extract the role authorization claim of the logged-in user. */
export const authClaimSelector = createSelector(authStateSelector, (state) => state.authClaim);

/** Selector to decoded authClaim */
const decodedAuthClaimSelector = createSelector(authClaimSelector, (roleAuthClaim) => {
  try {
    return Utils.decodeIdToken(roleAuthClaim);
  } catch {
    /* ignore */
  }

  return undefined;
});

/**
 * Selector to extract the AppUser class' instance from auth state. If no user
 * is logged-in, `undefined` is returned.
 */
export const currentUserSelector = createSelector(
  decodedAuthClaimSelector,
  (decodedToken): StoreStateAuthorization['user'] =>
    Utils.isNotNil(decodedToken) ? { type: 'user', id: decodedToken.userId } : undefined
);

/**
 * Selector to extract the ID of the currently logged-in user. If no user is
 * logged-in, `undefined` is returned.
 */
export const currentUserIdSelector = createSelector(currentUserSelector, (user) => user?.id);

/** Selector to determine whether a user is currently logged-in or not. */
export const isUserLoggedInSelector = createSelector(
  decodedAuthClaimSelector,
  (decodedToken) => Utils.isNotNil(decodedToken) && !IGNORE_LOGGED_IN_AUTH_CLAIM.includes(decodedToken.name as string)
);

/** Selector to extract OTP claim of the logged-in user. */
export const otpClaimSelector = createSelector(authStateSelector, (state) => state.otpClaim);

/** Selector to determine if user is in caregiver mode  */
export const caregiverModeSelector = createSelector(caregiverStateSelector, ({ mode }) => mode);

/** Selector to extract caregiver auth claim copy */
export const caregiverAuthClaimSelector = createSelector(caregiverStateSelector, ({ authClaim }) => authClaim);

/** Selector to extract the result code of the last authentication attempt. */
export const lastAuthErrorCodeSelector = createSelector(authStateSelector, (state) => state.lastAuthErrorCode);

/**
 * Selector to extract the access rights object of the currently logged-in
 * user.
 */
export const accessRightsObjectSelector = createUserObjectSelector<UserObjectAccessRightsValue>(
  process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS
);

type AccessRightId = keyof Required<ClientAccessRights>;

type BooleanAccessRightId =
  | {
      [K in AccessRightId]: NonNullable<ClientAccessRights[K]> extends boolean ? K : never;
    }[AccessRightId]
  | 'attachDocumentsToMessage'
  | `recall${'Event' | 'Message'}`;

type RoleListAccessRightId = Extract<AccessRightId, `${`${'' | 'de'}activate` | 'invite'}Member`>;

export const accessRightRoleIdList = (
  id: RoleListAccessRightId,
  rights: ClientAccessRights | undefined
): ReadonlyArray<string> => {
  const value = getRawAccessRightValue(id, rights);
  return Utils.arrayOrDefault<string>(value === true ? Constants.ROLE_ID_LIST : value, EMPTY_ARRAY);
};

export const canActivateMemberOfRole = (role: string, rights: ClientAccessRights | undefined): boolean =>
  accessRightRoleIdList('activateMember', rights).includes(role);

export const canDeactivateMemberOfRole = (role: string, rights: ClientAccessRights | undefined): boolean =>
  accessRightRoleIdList('deactivateMember', rights).includes(role);

export const canInviteMemberOfRole = (role: string, rights: ClientAccessRights | undefined): boolean =>
  accessRightRoleIdList('inviteMember', rights).includes(role);

export const canRecallMessage = (
  dtNow: Date | number,
  dtSentAt: Date | number,
  rights: ClientAccessRights | undefined
): boolean => {
  const recallWindowInDays = getRecallMessageWindow(rights);

  if (recallWindowInDays === 0) return false;
  if (recallWindowInDays === MAX_VALUE_RECALL_MESSAGE_WINDOW) return true;

  return toDate(dtSentAt).getTime() + recallWindowInDays * 24 * 60 * 60 * 1000 > toDate(dtNow).getTime();
};

export const canReplyToMessage = (
  dtNow: Date | number,
  dtSentAt: Date | number,
  rights: ClientAccessRights | undefined
): boolean => {
  const replyWindowInDays = getReplyMessageWindow(rights);
  if (replyWindowInDays === 0 || replyWindowInDays === MAX_VALUE_REPLY_MESSAGE_WINDOW) return true;

  return toDate(dtSentAt).getTime() + replyWindowInDays * 24 * 60 * 60 * 1000 > toDate(dtNow).getTime();
};

export const getAccessRight = (id: BooleanAccessRightId, rights: ClientAccessRights | undefined): boolean => {
  if (id === 'attachDocumentsToMessage') return getMaxAttachmentCount(rights) > 0;
  if (Utils.startsWith(id, 'recall')) return getRecallMessageWindow(rights) > 0;

  return getRawAccessRightValue(id, rights) === true;
};

export const getMaxAttachmentCount = (rights: ClientAccessRights | undefined): number => {
  const value = getRawAccessRightValue('attachDocumentsToMessage', rights);
  const count = Utils.numberOrDefault(value === true && MAX_VALUE_ATTACHMENT_COUNT, Number(value) || 0);
  return Math.floor(clamp(count, 0, MAX_VALUE_ATTACHMENT_COUNT));
};

export const getMaxPerFileAttachmentSize = (rights: ClientAccessRights | undefined): number => {
  if (getMaxAttachmentCount(rights) === 0) return 0;

  const sizeInBytes = Number(getRawAccessRightValue('maxPerFileMsgAttachmentSize', rights)) || 0;
  return Math.floor(clamp(sizeInBytes, 0, EIGHT_MB));
};

export const getMaxTotalAttachmentSize = (rights: ClientAccessRights | undefined): number => {
  if (getMaxAttachmentCount(rights) === 0) return 0;

  const sizeInBytes = Number(getRawAccessRightValue('maxTotalMsgAttachmentSize', rights)) || 0;
  return Math.floor(clamp(sizeInBytes, 0, TWENTY_FIVE_MB));
};

export const getMessageAttachmentRights = (rights: ClientAccessRights | undefined) =>
  ({
    maxCount: getMaxAttachmentCount(rights),
    maxPerFileSize: getMaxPerFileAttachmentSize(rights),
    maxTotalSize: getMaxTotalAttachmentSize(rights)
  } as const);

export const getRawAccessRightValue = <Id extends AccessRightId>(
  id: Id,
  rights: ClientAccessRights | undefined
): ClientAccessRights[Id] => {
  // =================================================================
  // TODO REMOVE THIS STATEMENT ONCE CARE PLAN DELETION IS IMPLEMENTED
  // =================================================================
  if (id === 'deleteCarePlan') return false as ClientAccessRights[typeof id];
  if (id === 'deleteMessage' && process.env.REACT_APP_ENV === 'local') return true as ClientAccessRights[typeof id];

  return rights?.[id];
};

export const getRecallMessageWindow = (rights: ClientAccessRights | undefined): number => {
  const value = getRawAccessRightValue('recallMessageWindow', rights);
  const days = Utils.numberOrDefault(value === true && MAX_VALUE_RECALL_MESSAGE_WINDOW, Number(value) || 0);
  return Math.floor(clamp(days, 0, MAX_VALUE_RECALL_MESSAGE_WINDOW));
};

export const getReplyMessageWindow = (rights: ClientAccessRights | undefined): number => {
  const days = Number(getRawAccessRightValue('replyMessageWindow', rights)) || 0;
  return Math.floor(clamp(days, 0, MAX_VALUE_REPLY_MESSAGE_WINDOW));
};

export const selectAccessRight = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => (id: BooleanAccessRightId): boolean => getAccessRight(id, accessRights?.clientRights)
);

export const selectAccessRightRoleIdList = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => (id: RoleListAccessRightId) => accessRightRoleIdList(id, accessRights?.clientRights)
);

export const selectCanActivateMemberOfRole = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => (role: string): boolean => canActivateMemberOfRole(role, accessRights?.clientRights)
);

export const selectCanDeactivateMemberOfRole = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => (role: string): boolean => canDeactivateMemberOfRole(role, accessRights?.clientRights)
);

export const selectCanInviteMemberOfRole = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => (role: string): boolean => canInviteMemberOfRole(role, accessRights?.clientRights)
);

export const selectCanRecallMessage = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => (dtNow: Date | number, dtSentAt: Date | number) =>
    canRecallMessage(dtNow, dtSentAt, accessRights?.clientRights)
);

export const selectCanReplyToMessage = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => (dtNow: Date | number, dtSentAt: Date | number) =>
    canReplyToMessage(dtNow, dtSentAt, accessRights?.clientRights)
);

export const selectMaxAttachmentCount = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => getMaxAttachmentCount(accessRights?.clientRights)
);

export const selectMaxPerFileAttachmentSize = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => getMaxPerFileAttachmentSize(accessRights?.clientRights)
);

export const selectMaxTotalAttachmentSize = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => getMaxTotalAttachmentSize(accessRights?.clientRights)
);

export const selectMessageAttachmentRights = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => getMessageAttachmentRights(accessRights?.clientRights)
);

export const selectRawAccessRightValue = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => <Id extends AccessRightId>(id: Id) => getRawAccessRightValue(id, accessRights?.clientRights)
);

export const selectRecallMessageWindow = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => getRecallMessageWindow(accessRights?.clientRights)
);

export const selectReplyMessageWindow = createSelector(
  (state: RootState) => UserObjectCache.getValue(accessRightsObjectSelector(state)(/***/)),
  (accessRights) => getReplyMessageWindow(accessRights?.clientRights)
);
