import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../../../http/service-exception';
import type { Api, Http } from '../../../types';
import { URL_OSCAR_EMR_SEARCH_PATIENT as API_URL } from '../../constants';
import { MalformedResponseException } from '../../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../../service-exception';

const Logger = getLoggerWithPrefix('ApiService', 'oscarEMRSearchPatient:');

export async function apiOscarEMRSearchPatient(
  this: Api.Service,
  accessToken: string,
  query: Api.OscarEMRSearchPatientRequest,
  init?: Http.RequestInit
): Promise<Api.OscarEMRSearchPatientResponse> {
  try {
    Logger.info('== BEGIN ==');
    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.OscarEMRSearchPatientResponse>(requestUrl, JSON.stringify(query), {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status === 200) {
          const json = await Utils.tryGetResponseJson<Api.OscarEMRSearchPatientResponse>(response, undefined);
          if (isValidResponseData(json)) return json;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status >= 400 && response.status < 500) {
          const responseBody = await response.clone().text();
          throw new ApiServiceException(response, responseBody);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}

function isValidOscarEMRPatientRecord(record?: unknown): record is Api.OscarEMRPatientRecord {
  return (
    Utils.isNonArrayObjectLike<Api.OscarEMRPatientRecord>(record) &&
    Utils.isInteger(record.demographicNo) &&
    Utils.isString(record.firstName) &&
    Utils.isString(record.lastName) &&
    Utils.isString(record.dobYear) &&
    Utils.isString(record.dobMonth) &&
    Utils.isString(record.dobDay)
  );
}

function isValidResponseData(responseJson?: unknown): responseJson is Api.OscarEMRSearchPatientResponse {
  return Utils.isArray(responseJson) && responseJson.every(isValidOscarEMRPatientRecord);
}
