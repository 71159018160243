import { SigmailGroupId, SigmailObjectId, SigmailUserId } from '@sigmail/common';
import {
  EventLogRecordValueAccountSetup,
  EventLogRecordValueChangePassword,
  EventLogRecordValueChangeUsername,
  EventLogRecordValueRevokeInvitation,
  EventLogRecordValueSendInvitation,
  EventLogRecordValueUpdateMFA,
  EventLogRecordValueUpdatePreference,
  EventLogRecordValueUpdateProfile
} from '@sigmail/objects';

export type AccountSetupParams = readonly [credentialId: SigmailObjectId, inviteeId: SigmailUserId];
export type ChangeUsernameParams = readonly [credentialId: SigmailObjectId];
export type ChangePasswordParams = readonly [credentialId?: SigmailObjectId];
export type RevokeInvitationParams = AccountSetupParams;

export type SendCaregiverInvitationParams = readonly [credentialId: SigmailObjectId, inviteeId: SigmailUserId];

export type SendGroupInvitationParams = readonly [
  credentialId: SigmailObjectId,
  inviteeId: SigmailUserId,
  groupIdList: ReadonlyArray<SigmailGroupId>
];

export type SendInvitationParams = readonly [
  credentialId: SigmailObjectId,
  inviteeId: SigmailUserId,
  groupIdList: ReadonlyArray<SigmailGroupId>,
  role: Extract<EventLogRecordValueSendInvitation, { resend?: boolean }>['role'],
  resend?: true
];

export type UpdateMfaParams = readonly [op: EventLogRecordValueUpdateMFA['op'], who?: 'user'];

export type UpdatePreferenceParams = readonly [
  code: EventLogRecordValueUpdatePreference['code'],
  currentValue: unknown,
  previousValue: unknown
];

export type UpdateProfileParams = readonly [list: EventLogRecordValueUpdateProfile['list'], guest?: SigmailUserId];

export function newEventLogRecordValueAccountSetup(...params: AccountSetupParams): EventLogRecordValueAccountSetup {
  return {
    crid: params[0],
    iid: params[1]
  };
}

export function newEventLogRecordValueChangeUsername(
  ...params: ChangeUsernameParams
): EventLogRecordValueChangeUsername {
  return { crid: params[0] };
}

export function newEventLogRecordValueChangePassword(
  ...params: ChangePasswordParams
): EventLogRecordValueChangePassword {
  return { crid: params[0] };
}

export function newEventLogRecordValueRevokeInvitation(
  ...params: RevokeInvitationParams
): EventLogRecordValueRevokeInvitation {
  return {
    crid: params[0],
    iid: params[1]
  };
}

export function newEventLogRecordValueSendCaregiverInvitation(
  ...params: SendCaregiverInvitationParams
): EventLogRecordValueSendInvitation {
  return {
    crid: params[0],
    iid: params[1],
    role: 'caregiver',
    type: 'account'
  };
}

export function newEventLogRecordValueSendGroupInvitation(
  ...params: SendGroupInvitationParams
): EventLogRecordValueSendInvitation {
  return {
    crid: params[0],
    gid: params[2],
    iid: params[1],
    type: 'group'
  };
}

export function newEventLogRecordValueSendInvitation(
  ...params: SendInvitationParams
): EventLogRecordValueSendInvitation {
  return {
    crid: params[0],
    gid: params[2],
    iid: params[1],
    resend: params[4],
    role: params[3],
    type: 'account'
  };
}

export function newEventLogRecordValueUpdateMFA(...params: UpdateMfaParams): EventLogRecordValueUpdateMFA {
  return {
    op: params[0],
    who: params[0] === 'disable' ? params[1] : undefined
  };
}

export function newEventLogRecordValueUpdatePreference(
  ...params: UpdatePreferenceParams
): EventLogRecordValueUpdatePreference {
  return {
    code: params[0],
    cv: JSON.stringify(params[1]),
    pv: JSON.stringify(params[2])
  };
}

export function newEventLogRecordValueUpdateProfile(...params: UpdateProfileParams): EventLogRecordValueUpdateProfile {
  return { guest: params[1], list: params[0] };
}
