import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { getRoutePath } from '../../app/routes';
import IconClientContactListPrimary from '../../assets/images/icon-client-contact-list-primary.min.svg';
import IconClientContactListSecondary from '../../assets/images/icon-client-contact-list-secondary.min.svg';
import IconGlobalGroupContactListPrimary from '../../assets/images/icon-global-group-contact-list-primary.min.svg';
import IconGlobalGroupContactListSecondary from '../../assets/images/icon-global-group-contact-list-secondary.min.svg';
import IconGlobalUserContactListPrimary from '../../assets/images/icon-global-user-contact-list-primary.min.svg';
import IconGlobalUserContactListSecondary from '../../assets/images/icon-global-user-contact-list-secondary.min.svg';
import IconGuestContactListPrimary from '../../assets/images/icon-guest-contact-list-primary.min.svg';
import IconGuestContactListSecondary from '../../assets/images/icon-guest-contact-list-secondary.min.svg';
import { ContactList } from '../../constants/action-context';
import { ContactListClient, ContactListGlobalGroups, ContactListGlobalUsers, ContactListGroupGuest } from '../../constants/action-ids';
import { EnglishCanada, FrenchCanada } from '../../constants/language-codes';
import {
  ROUTE_CONTACT_LIST_CLIENT,
  ROUTE_CONTACT_LIST_GLOBAL_GROUPS,
  ROUTE_CONTACT_LIST_GLOBAL_USERS,
  ROUTE_CONTACT_LIST_GUEST
} from '../../constants/route-identifiers';

const i18n: ContactListI18n.Root['navAction'] = {
  [ContactListGlobalUsers]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: IconGlobalUserContactListPrimary,
        [FrenchCanada]: IconGlobalUserContactListPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: IconGlobalUserContactListSecondary,
        [FrenchCanada]: IconGlobalUserContactListSecondary
      }
    },
    // Translators: Label for the Global User Contact List navigation action
    label: gettext('contact-list', 'globalUsers', 'contactListNavActionLabel'),
    to: getRoutePath(ROUTE_CONTACT_LIST_GLOBAL_USERS),
    context: ContactList
  },
  [ContactListGlobalGroups]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: IconGlobalGroupContactListPrimary,
        [FrenchCanada]: IconGlobalGroupContactListPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: IconGlobalGroupContactListSecondary,
        [FrenchCanada]: IconGlobalGroupContactListSecondary
      }
    },
    // Translators: Label for the Global Group Contact List navigation action
    label: gettext('contact-list', 'globalGroups', 'contactListNavActionLabel'),
    to: getRoutePath(ROUTE_CONTACT_LIST_GLOBAL_GROUPS),
    context: ContactList
  },
  [ContactListClient]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: IconClientContactListPrimary,
        [FrenchCanada]: IconClientContactListPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: IconClientContactListSecondary,
        [FrenchCanada]: IconClientContactListSecondary
      }
    },
    // Translators: Label for the Client Contact List navigation action
    label: gettext('contact-list', 'clientContacts', 'contactListNavActionLabel'),
    to: getRoutePath(ROUTE_CONTACT_LIST_CLIENT),
    context: ContactList
  },
  [ContactListGroupGuest]: {
    iconPrimary: {
      alt: '',
      src: {
        [EnglishCanada]: IconGuestContactListPrimary,
        [FrenchCanada]: IconGuestContactListPrimary
      }
    },
    iconSecondary: {
      alt: '',
      src: {
        [EnglishCanada]: IconGuestContactListSecondary,
        [FrenchCanada]: IconGuestContactListSecondary
      }
    },
    // Translators: Label for the Guest Contact List navigation action
    label: gettext('contact-list', 'guestContacts', 'contactListNavActionLabel'),
    to: getRoutePath(ROUTE_CONTACT_LIST_GUEST),
    context: ContactList
  }
};

export default i18n;
