import { Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../../http/service-exception';
import type { Api, Http } from '../../types';
import { URL_SRP_VERIFY_CREDENTIALS as API_URL } from '../constants';
import { MalformedResponseException } from '../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../service-exception';

const PROPS: ReadonlyArray<keyof Api.SrpVerifyCredentialResponseData> = [
  'scope',
  'token_type',
  'access_token',
  'expires_in',
  'refresh_token',
  'id_token'
];

const Logger = getLoggerWithPrefix('ApiService', 'srpVerifyCredential:');

export async function apiSrpVerifyCredential(
  this: Api.Service,
  sessionId: string,
  credentialHash: string,
  credentialProof: string,
  init?: Http.RequestInit
): Promise<Api.SrpVerifyCredentialResponseData> {
  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    const formData = [
      `nonce=${encodeURIComponent(sessionId)}`,
      `username=${encodeURIComponent(credentialHash)}`,
      `password=${encodeURIComponent(credentialProof)}`,
      'grant_type=password'
    ].join('&');

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.SrpVerifyCredentialResponseData>(requestUrl, formData, {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status === 200) {
          const json = await Utils.tryGetResponseJson<Api.SrpVerifyCredentialResponseData>(response);
          if (isValidApiResponse(json)) return json;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status >= 400 && response.status < 500) {
          const responseBody = await response.clone().text();
          throw new ApiServiceException(response, Constants.Error.E_AUTH_FAIL, responseBody);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}

function isValidApiResponse(json: unknown): json is Api.SrpVerifyCredentialResponseData {
  // make sure response JSON is an object and has at least all of the expected properties
  if (
    Utils.isNonArrayObjectLike<Api.SrpVerifyCredentialResponseData>(json) &&
    Utils.every(PROPS, Utils.partial(Utils.has, json))
  ) {
    // make sure all of the properties are of the expected data types
    return PROPS.every((p) => (p === 'expires_in' ? Utils.isInteger(json[p]) && json[p] > 0 : Utils.isString(json[p])));
  }
  return false;
}
