import { Nullable, Utils } from '@sigmail/common';
import { FormInputI18n } from '@sigmail/i18n';
import { FieldState } from 'final-form';
import { EMPTY_ARRAY } from '../../../../app-state/constants';
import {
  FirstName as FirstNameConstraint,
  LastName as LastNameConstraint
} from '../../../../constants/form-input-constraint';
import {
  HealthPlanNumber as HealthPlanNumberPattern,
  PhoneNumber as PhoneNumberPattern,
  PostalCode as PostalCodePattern
} from '../../../../constants/form-input-pattern';
import formI18n from '../../../../i18n/care-plan/form-edit-care-plan';
import globalI18n from '../../../../i18n/global';
import {
  minDate as minDateValidator,
  nullValidator,
  pattern as patternValidator,
  requiredDate
} from '../field-validator';
import { FieldName, FieldProps, FieldsetGroupKey, FieldsetKey, FormValues, StringValueFieldName } from './types';

const { fieldsetGroup: i18n } = formI18n;

export const DEFAULT_INITIAL_VALUES: FormValues = {
  aboutCaregiverIssues: '',
  aboutEmployment: '',
  aboutFoodSecurity: '',
  aboutHealthKnowledge: '',
  aboutHousing: '',
  aboutIncome: '',
  aboutLegalConcerns: '',
  aboutNewInCountry: '',
  aboutOther: '',
  aboutSocialNetwork: '',
  aboutSpiritualAffiliation: '',
  aboutTransportation: '',
  addressLevel1: '',
  addressLevel2: '',
  addressLine1: '',
  addressLine2: '',
  allergyList: EMPTY_ARRAY,
  ancestry: '',
  birthDate: null,
  careTeam: EMPTY_ARRAY,
  communication: '',
  firstName: '',
  gender: '',
  goalList: EMPTY_ARRAY,
  healthConcern: '',
  healthAssessmentList: EMPTY_ARRAY,
  healthIssueList: EMPTY_ARRAY,
  healthPlanJurisdiction: '',
  healthPlanNumber: '',
  indigenous: '',
  language: '',
  lastName: '',
  medAids: '',
  medChallenges: '',
  medHelper: '',
  medSource: '',
  medicationList: EMPTY_ARRAY,
  planDescription: '',
  planTitle: '',
  postalCode: '',
  preferredName: '',
  preferredPronoun: '',
  priority: '',
  visitComments: '',
  visitDate: null,
  visitDescription: '',
  visitDischarge: null,
  visitDuration: '',
  visitHospitalName: '',
  visitReason: ''
};

export const STRING_VALUE_FIELD_NAME_LIST: ReadonlyArray<StringValueFieldName> = [
  'aboutCaregiverIssues',
  'aboutEmployment',
  'aboutFoodSecurity',
  'aboutHealthKnowledge',
  'aboutHousing',
  'aboutIncome',
  'aboutLegalConcerns',
  'aboutNewInCountry',
  'aboutOther',
  'aboutSocialNetwork',
  'aboutSpiritualAffiliation',
  'aboutTransportation',
  'addressLevel2',
  'addressLine1',
  'addressLine2',
  'ancestry',
  'communication',
  'firstName',
  'healthConcern',
  'healthPlanNumber',
  'indigenous',
  'language',
  'lastName',
  'medAids',
  'medChallenges',
  'medHelper',
  'medSource',
  'planDescription',
  'planTitle',
  'postalCode',
  'preferredName',
  'preferredPronoun',
  'priority',
  'visitComments',
  'visitDescription',
  'visitDuration',
  'visitHospitalName',
  'visitReason'
];

export const FIELD_SET_GROUP_LIST: ReadonlyArray<
  Readonly<[FieldsetGroupKey, ReadonlyArray<Readonly<[FieldsetKey, ReadonlyArray<FieldName>]>>]>
> = [
  ['plan', [['fieldsetPlan', ['planTitle', 'planDescription']]]],
  [
    'subject',
    [
      ['fieldsetNameBirthDate', ['firstName', 'lastName', 'preferredName', 'birthDate']],
      ['fieldsetGender', ['gender', 'preferredPronoun']],
      ['fieldsetAddress', ['addressLine1', 'addressLine2', 'addressLevel2', 'addressLevel1', 'postalCode']],
      ['fieldsetHealthPlan', ['healthPlanJurisdiction', 'healthPlanNumber']],
      ['fieldsetAncestry', ['ancestry', 'indigenous']],
      ['fieldsetCommunication', ['language', 'communication']]
    ]
  ],
  ['supportingInfo', [['fieldsetPriorityAndConcern', ['priority', 'healthConcern']]]],
  ['careTeam', [['fieldsetMember', EMPTY_ARRAY]]],
  ['healthIssueList', [['fieldsetHealthIssue', EMPTY_ARRAY]]],
  [
    'personalInfo',
    [
      [
        'fieldsetPersonalInfo',
        [
          'aboutIncome',
          'aboutEmployment',
          'aboutHousing',
          'aboutTransportation',
          'aboutFoodSecurity',
          'aboutSocialNetwork',
          'aboutHealthKnowledge',
          'aboutNewInCountry',
          'aboutLegalConcerns',
          'aboutSpiritualAffiliation',
          'aboutCaregiverIssues',
          'aboutOther'
        ]
      ]
    ]
  ],
  ['goalList', [['fieldsetGoal', EMPTY_ARRAY]]],
  ['medicationCoordination', [['fieldsetMedication', ['medSource', 'medAids', 'medHelper', 'medChallenges']]]],
  ['allergyList', [['fieldsetAllergy', EMPTY_ARRAY]]],
  ['medicationList', [['fieldsetDrug', EMPTY_ARRAY]]],
  ['healthAssessmentList', [['fieldsetHealthAssessment', EMPTY_ARRAY]]],
  [
    'recentHospitalVisit',
    [
      [
        'fieldsetVisit',
        [
          'visitHospitalName',
          'visitReason',
          'visitDescription',
          'visitDate',
          'visitDischarge',
          // 'visitDuration',
          'visitComments'
        ]
      ]
    ]
  ]
];

const validateOptionalDate = requiredDate({ ignoreNil: true });

export const FIELD_PROPS: FieldProps = {
  ...STRING_VALUE_FIELD_NAME_LIST.reduce(
    (fieldProps, fieldName) => {
      let label: string | undefined;
      let placeholder: string | undefined;

      const fieldI18n = getFieldI18n(fieldName);
      if (Utils.isNotNil(fieldI18n)) {
        label = fieldI18n.label;
        placeholder = fieldI18n.placeholder;
      }

      fieldProps[fieldName] = {
        autoComplete: 'off',
        config: {
          maxLength: 250,
          ...fieldProps[fieldName]?.config
        },
        label,
        placeholder,
        ...fieldProps[fieldName]
      };

      return fieldProps;
    },
    {
      aboutCaregiverIssues: {
        multiline: true,
        rows: 4,
        rowsMax: 4
      },
      aboutOther: {
        multiline: true,
        rows: 4,
        rowsMax: 4
      },
      addressLevel2: {
        autoComplete: 'address-level2',
        config: {
          maxLength: 40
        }
      },
      addressLine1: {
        autoComplete: 'address-line1',
        config: {
          maxLength: 40
        }
      },
      addressLine2: {
        autoComplete: 'address-line2',
        config: {
          maxLength: 40
        }
      },
      firstName: {
        autoComplete: 'given-name',
        config: {
          maxLength: FirstNameConstraint.maxLength
        }
      },
      healthConcern: {
        config: {
          maxLength: 500
        },
        multiline: true,
        rows: 4,
        rowsMax: 4
      },
      healthPlanNumber: {
        config: {
          getValidator: () => (
            value: FormValues['healthPlanNumber'],
            allValues: FormValues,
            meta?: FieldState<typeof value>
          ) => {
            const jurisdiction = Utils.trimOrDefault(allValues.healthPlanJurisdiction);
            if (jurisdiction.startsWith('CAN$')) {
              const provinceCode = jurisdiction.slice(4);
              const pattern = Utils.stringOrDefault(HealthPlanNumberPattern.CAN[provinceCode]);
              if (pattern.length > 0) {
                return patternValidator(pattern)(value, allValues, meta);
              }
            }
            return null;
          }
        }
      },
      lastName: {
        autoComplete: 'family-name',
        config: {
          maxLength: LastNameConstraint.maxLength
        }
      },
      planTitle: {
        config: {
          required: true,
          maxLength: 250
        }
      },
      planDescription: {
        config: {
          maxLength: 500
        },
        multiline: true,
        rows: 6,
        rowsMax: 6
      },
      postalCode: {
        autoComplete: 'postal-code',
        config: {
          pattern: PostalCodePattern
        },
        maskedInputProps: {
          guide: false,
          mask: 'postalCode'
        }
      },
      preferredName: {
        config: {
          maxLength: FirstNameConstraint.maxLength + LastNameConstraint.maxLength
        }
      },
      preferredPronoun: {
        config: {
          maxLength: 40
        }
      },
      priority: {
        config: {
          maxLength: 500
        },
        multiline: true,
        rows: 4,
        rowsMax: 4
      },
      visitComments: {
        multiline: true,
        rows: 3,
        rowsMax: 3
      }
    } as Pick<FieldProps, StringValueFieldName>
  ),

  addressLevel1: {
    autoComplete: 'off',
    displayEmpty: true,
    getOptionKey: (option) => (option.length === 0 ? 'unselected' : option),
    getOptionLabel: (option) =>
      option.length === 0
        ? i18n.subject.fieldsetAddress.formField.addressLevel1.placeholder
        : globalI18n.addressLevel1.CAN.find(({ code }) => code === option)?.abbr,
    label: i18n.subject.fieldsetAddress.formField.addressLevel1.label,
    multiple: false,
    options: ['', ...globalI18n.addressLevel1.CAN.map(({ code }) => code)],
    sort: false
  },

  allergyIdentifier: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.allergyList.fieldsetAllergy.formField.allergyIdentifier.label,
    placeholder: i18n.allergyList.fieldsetAllergy.formField.allergyIdentifier.placeholder
  },

  allergySymptoms: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.allergyList.fieldsetAllergy.formField.allergySymptoms.label,
    placeholder: i18n.allergyList.fieldsetAllergy.formField.allergySymptoms.placeholder
  },

  birthDate: {
    InputLabelProps: { shrink: true },
    autoComplete: 'bday',
    config: {
      required: () => validateOptionalDate
    },
    label: i18n.subject.fieldsetNameBirthDate.formField.birthDate.label,
    placeholder: i18n.subject.fieldsetNameBirthDate.formField.birthDate.placeholder
  },

  drugDose: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.medicationList.fieldsetDrug.formField.drugDose.label,
    placeholder: i18n.medicationList.fieldsetDrug.formField.drugDose.placeholder
  },

  drugFrequency: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.medicationList.fieldsetDrug.formField.drugFrequency.label,
    placeholder: i18n.medicationList.fieldsetDrug.formField.drugFrequency.placeholder
  },

  drugName: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.medicationList.fieldsetDrug.formField.drugName.label,
    placeholder: i18n.medicationList.fieldsetDrug.formField.drugName.placeholder
  },

  drugNotes: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.medicationList.fieldsetDrug.formField.drugNotes.label,
    placeholder: i18n.medicationList.fieldsetDrug.formField.drugNotes.placeholder
  },

  drugPrescriber: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.medicationList.fieldsetDrug.formField.drugPrescriber.label,
    placeholder: i18n.medicationList.fieldsetDrug.formField.drugPrescriber.placeholder
  },

  drugReason: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.medicationList.fieldsetDrug.formField.drugReason.label,
    placeholder: i18n.medicationList.fieldsetDrug.formField.drugReason.placeholder
  },

  drugStart: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      required: () => validateOptionalDate
    },
    label: i18n.medicationList.fieldsetDrug.formField.drugStart.label,
    placeholder: i18n.medicationList.fieldsetDrug.formField.drugStart.placeholder
  },

  gender: {
    autoComplete: 'off',
    displayEmpty: true,
    getOptionKey: (option) => (option.length === 0 ? 'unselected' : option),
    getOptionLabel: (option) =>
      option.length === 0
        ? i18n.subject.fieldsetGender.formField.gender.placeholder
        : globalI18n.genderList.find(({ code }) => code === option)?.label,
    label: i18n.subject.fieldsetGender.formField.gender.label,
    multiple: false,
    options: ['', ...globalI18n.genderList.map(({ code }) => code)],
    sort: false
  },

  goalActionPlan: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.goalList.fieldsetGoal.formField.goalActionPlan.label,
    placeholder: i18n.goalList.fieldsetGoal.formField.goalActionPlan.placeholder
  },

  goalCoordinator: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.goalList.fieldsetGoal.formField.goalCoordinator.label,
    placeholder: i18n.goalList.fieldsetGoal.formField.goalCoordinator.placeholder
  },

  goalDate: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      required: () => validateOptionalDate
    },
    label: i18n.goalList.fieldsetGoal.formField.goalDate.label,
    placeholder: i18n.goalList.fieldsetGoal.formField.goalDate.placeholder
  },

  goalDetails: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.goalList.fieldsetGoal.formField.goalDetails.label,
    placeholder: i18n.goalList.fieldsetGoal.formField.goalDetails.placeholder
  },

  goalIdentifier: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.goalList.fieldsetGoal.formField.goalIdentifier.label,
    placeholder: i18n.goalList.fieldsetGoal.formField.goalIdentifier.placeholder
  },

  healthAssessmentDate: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      required: () => validateOptionalDate
    },
    label: i18n.healthAssessmentList.fieldsetHealthAssessment.formField.healthAssessmentDate.label,
    placeholder: i18n.healthAssessmentList.fieldsetHealthAssessment.formField.healthAssessmentDate.placeholder
  },

  healthAssessmentIdentifier: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.healthAssessmentList.fieldsetHealthAssessment.formField.healthAssessmentIdentifier.label,
    placeholder: i18n.healthAssessmentList.fieldsetHealthAssessment.formField.healthAssessmentIdentifier.placeholder
  },

  healthAssessmentNotes: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.healthAssessmentList.fieldsetHealthAssessment.formField.healthAssessmentNotes.label,
    placeholder: i18n.healthAssessmentList.fieldsetHealthAssessment.formField.healthAssessmentNotes.placeholder
  },

  healthIssueDetails: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.healthIssueList.fieldsetHealthIssue.formField.healthIssueDetails.label,
    placeholder: i18n.healthIssueList.fieldsetHealthIssue.formField.healthIssueDetails.placeholder
  },

  healthIssueIdentifier: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 250
    },
    label: i18n.healthIssueList.fieldsetHealthIssue.formField.healthIssueIdentifier.label,
    placeholder: i18n.healthIssueList.fieldsetHealthIssue.formField.healthIssueIdentifier.placeholder
  },

  healthPlanJurisdiction: {
    autoComplete: 'off',
    config: { validateFields: ['healthPlanNumber'] },
    displayEmpty: true,
    getOptionKey: (option) => (option.length === 0 ? 'unselected' : option),
    getOptionLabel: (option) =>
      option.length === 0
        ? i18n.subject.fieldsetHealthPlan.formField.healthPlanJurisdiction.placeholder
        : globalI18n.healthPlanJurisdictionList.find(({ code }) => code === option)?.label,
    label: i18n.subject.fieldsetHealthPlan.formField.healthPlanJurisdiction.label,
    multiple: false,
    options: ['', ...globalI18n.healthPlanJurisdictionList.map(({ code }) => code)],
    sort: false
  },

  memberContact: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      pattern: PhoneNumberPattern
    },
    inputMode: 'tel',
    label: i18n.careTeam.fieldsetMember.formField.memberContact.label,
    maskedInputProps: {
      guide: false,
      mask: 'phoneNumber'
    },
    placeholder: i18n.careTeam.fieldsetMember.formField.memberContact.placeholder
  },

  memberFirstName: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: FirstNameConstraint.maxLength
    },
    label: i18n.careTeam.fieldsetMember.formField.memberFirstName.label,
    placeholder: i18n.careTeam.fieldsetMember.formField.memberFirstName.placeholder
  },

  memberLastName: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: LastNameConstraint.maxLength
    },
    label: i18n.careTeam.fieldsetMember.formField.memberLastName.label,
    placeholder: i18n.careTeam.fieldsetMember.formField.memberLastName.placeholder
  },

  memberOrganization: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 80
    },
    label: i18n.careTeam.fieldsetMember.formField.memberOrganization.label,
    placeholder: i18n.careTeam.fieldsetMember.formField.memberOrganization.placeholder
  },

  memberRole: {
    InputLabelProps: { className: 'sr-only', shrink: true },
    InputProps: { notched: false },
    autoComplete: 'off',
    config: {
      maxLength: 40
    },
    label: i18n.careTeam.fieldsetMember.formField.memberRole.label,
    placeholder: i18n.careTeam.fieldsetMember.formField.memberRole.placeholder
  },

  visitDate: {
    InputLabelProps: { shrink: true },
    autoComplete: 'off',
    config: {
      required: () => validateOptionalDate,
      validateFields: ['visitDischarge']
    },
    label: i18n.recentHospitalVisit.fieldsetVisit.formField.visitDate.label,
    placeholder: i18n.recentHospitalVisit.fieldsetVisit.formField.visitDate.placeholder
  },

  visitDischarge: {
    InputLabelProps: { shrink: true },
    autoComplete: 'off',
    config: {
      min: (_, { visitDate }) => (Utils.isValidDate(visitDate) ? minDateValidator(visitDate) : nullValidator())
    },
    label: i18n.recentHospitalVisit.fieldsetVisit.formField.visitDischarge.label,
    placeholder: i18n.recentHospitalVisit.fieldsetVisit.formField.visitDischarge.placeholder
  }
};

export const FIELD_SET_LIST = FIELD_SET_GROUP_LIST.flatMap(
  ([, fieldsetList]) => fieldsetList
) as typeof FIELD_SET_GROUP_LIST[0][1];

export function findFieldsetGroupListIndices(
  fieldsetGroupName?: Nullable<FieldsetGroupKey>,
  fieldsetName?: Nullable<FieldsetKey>,
  fieldName?: Nullable<FieldName>
): [fieldsetGroupIndex: number, fieldsetIndex: number, fieldIndex: number] {
  let fieldsetGroupIndex = -1;
  let fieldsetIndex = -1;
  let fieldIndex = -1;

  for (let i = 0; i < FIELD_SET_GROUP_LIST.length; i++) {
    const [groupName, fieldsetList] = FIELD_SET_GROUP_LIST[i];
    if (Utils.isNotNil(fieldsetGroupName)) {
      if (groupName === fieldsetGroupName) {
        fieldsetGroupIndex = i;
      } else {
        continue;
      }
    }

    if (Utils.isNil(fieldsetName) && Utils.isNil(fieldName)) {
      break;
    }

    for (let j = 0; j < fieldsetList.length; j++) {
      const [setName, fieldList] = fieldsetList[j];
      if (Utils.isNotNil(fieldsetName)) {
        if (setName === fieldsetName) {
          fieldsetGroupIndex = i;
          fieldsetIndex = j;
        } else {
          continue;
        }
      }

      if (Utils.isNil(fieldName)) break;

      fieldIndex = fieldList.indexOf(fieldName);
      if (fieldIndex > -1) {
        fieldsetGroupIndex = i;
        fieldsetIndex = j;
        break;
      }
    }

    if (Utils.isNil(fieldName) || fieldIndex > -1) break;
  }

  return [fieldsetGroupIndex, fieldsetIndex, fieldIndex];
}

export function getFieldI18n(
  fieldName: FieldName,
  fieldsetName?: Nullable<FieldsetKey>,
  fieldsetGroupName?: Nullable<FieldsetGroupKey>
): (FormInputI18n & { helperText?: (context: 'other' | 'self') => string }) | undefined {
  const [groupIndex, fieldsetIndex, fieldIndex] = findFieldsetGroupListIndices(
    fieldsetGroupName,
    fieldsetName,
    fieldName
  );

  if (fieldIndex > -1) {
    const [fieldsetGroupName, fieldsetList] = FIELD_SET_GROUP_LIST[groupIndex];
    const [fieldsetName] = fieldsetList[fieldsetIndex];
    return (i18n[fieldsetGroupName] as any)[fieldsetName].formField[fieldName];
  }

  return undefined;
}
