import { LinearProgress } from '@material-ui/core';
import { ApiActionPayload } from '@sigmail/app-state';
import { Nullable, Utils, Writeable } from '@sigmail/common';
import { Api, EMRPatientRecord } from '@sigmail/services';
import { WithT } from 'i18next';
import React from 'react';
import { ISuspenseResource } from 'sigmail';
import { ACCURO_EMR_ERROR_CODE_DUPLICATE_DOCUMENT } from '../../../../app-state/actions/EMR/send-data-to-emr-action';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../../../../app-state/constants';
import { DialogEMRProviderIdMissing } from '../../../../constants/action-context';
import { DialogAccept, DialogReject } from '../../../../constants/action-ids';
import globalI18n from '../../../../i18n/global';
import messagingI18n from '../../../../i18n/messaging';
import { resolveActionLabel } from '../../../../utils/resolve-action-label';
import { CircularProgress } from '../../../shared/circular-progress.component';
import { DialogActionsAcceptReject } from '../../../shared/dialog/actions/accept-reject.component';
import { DialogActionsAccept } from '../../../shared/dialog/actions/accept.component';
import { DialogBoxProps } from '../../../shared/dialog/dialog-box.component';
import dialogBoxStyle from '../../../shared/dialog/dialog-box.module.css';
import { SelectAccuroEMRProviderFolder } from '../../../shared/select-accuro-emr-provider-folder.component';
import {
  Props as SelectPatientRecordGridProps,
  SelectPatientRecordGrid
} from '../../../shared/select-patient-record-grid.component';
import { BaseContextValue } from '../context';
import { MessageFolderGridItem } from '../folder-item-grid/types';
import style from '../folder-view.module.css';

const { EMR: dialogI18n } = messagingI18n.dialog;

export interface Params extends WithT {
  docUploadStatus?: Nullable<ReadonlyArray<UploadStatusListItem>>;
  documentList?: Nullable<MessageFolderGridItem['documentList']>;
  isUploadComplete?: Nullable<boolean>;
  onClose?: DialogBoxProps['onClose'];
  onDialogActionClick?: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  openedDialog?: Nullable<Extract<NonNullable<BaseContextValue['openedDialog']>, `emr${string}`>>;
  patientRecord?: Nullable<
    Readonly<{
      list: ReadonlyArray<SearchPatientRecord>;
      select: (record: Nullable<SearchPatientRecord>) => void;
    }>
  >;
  providerFolder?: Nullable<
    Readonly<{
      listResource: ISuspenseResource<readonly [boolean, Api.AccuroEMRProviderFolderListResponse['folders']]>;
      select: (folder: Nullable<Api.AccuroEMRProviderFolderListItem>) => void;
    }>
  >;
}

export type Result = DialogBoxProps;

type SearchPatientRecord = Omit<EMRPatientRecord, 'source'> & { readonly source: string };
type UploadStatusListItem = Parameters<NonNullable<ApiActionPayload.SendDataToEMR['onChangeUploadStatus']>>[0][0];

const useSelectPatientRecordDialogProps = (
  { onDialogActionClick: onClick, openedDialog, patientRecord, t }: Params,
  result: Result
): Result => {
  const isDialogOpen = openedDialog === 'emrSelectPatientRecord';
  const [selectedRecord, setSelectedRecord] = React.useState<Nullable<SearchPatientRecord>>(null);

  const onPatientRecordGridRowSelect = React.useCallback<
    NonNullable<NonNullable<SelectPatientRecordGridProps['GridProps']>['onRowSelected']>
  >(({ api }) => {
    const [selectedNode] = api.getSelectedRows();
    return setSelectedRecord(Utils.isNil(selectedNode) ? null : selectedNode);
  }, []);

  const { list: patientRecordList, select: selectRecord } = (Utils.isNil(patientRecord)
    ? EMPTY_PLAIN_OBJECT
    : patientRecord) as NonNullable<Params['patientRecord']>;

  const onDialogActionClick = React.useMemo<React.MouseEventHandler<HTMLButtonElement> | undefined>(
    () =>
      isDialogOpen && (typeof onClick === 'function' || typeof selectRecord === 'function')
        ? (event) => {
            if (typeof onClick === 'function') {
              onClick(event);
            }

            const actionId = event.currentTarget.getAttribute('data-action-id');
            const isActionDialogAccept = actionId === DialogAccept;
            if (isActionDialogAccept && Utils.isNil(selectedRecord)) return;

            if (typeof selectRecord === 'function') {
              selectRecord(isActionDialogAccept ? selectedRecord : null);
            }

            setSelectedRecord(null);
          }
        : undefined,
    [isDialogOpen, onClick, selectRecord, selectedRecord]
  );

  return React.useMemo<Result>(() => {
    if (!isDialogOpen) return result;

    const { selectPatientRecord: i18n } = globalI18n.dialog;

    result.actions = (
      <DialogActionsAcceptReject
        AcceptActionProps={{
          children: t(resolveActionLabel(i18n.action.accept, DialogAccept)),
          disabled: Utils.isNil(selectedRecord),
          type: 'button'
        }}
        RejectActionProps={{ children: t(resolveActionLabel(i18n.action.reject, DialogReject)) }}
        onAccept={onDialogActionClick}
        onReject={onDialogActionClick}
      />
    );

    result.body = (
      <SelectPatientRecordGrid
        GridProps={{ onRowSelected: onPatientRecordGridRowSelect }}
        rowData={patientRecordList}
      />
    );

    result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };
    return result;
  }, [isDialogOpen, onDialogActionClick, onPatientRecordGridRowSelect, patientRecordList, result, selectedRecord, t]);
};

const useSelectAccuroEMRProviderFolderDialogProps = (
  { onDialogActionClick: onClick, openedDialog, providerFolder, t }: Params,
  result: Result
): Result => {
  const isDialogOpen = openedDialog === 'emrSelectProviderFolder';
  const [selectedFolder, setSelectedFolder] = React.useState('');

  const { listResource, select: selectFolder } = (Utils.isNil(providerFolder)
    ? EMPTY_PLAIN_OBJECT
    : providerFolder) as NonNullable<Params['providerFolder']>;

  let isListLoading = false;
  let [authStatus, folderList]: ReturnType<typeof listResource.value> = [false, EMPTY_ARRAY];
  if (Utils.isNotNil(listResource)) {
    try {
      [authStatus, folderList] = listResource.value();
    } catch (error) {
      isListLoading = error instanceof Promise;
    }
  }

  const onDialogActionClick = React.useMemo<React.MouseEventHandler<HTMLButtonElement> | undefined>(
    () =>
      isDialogOpen && (typeof onClick === 'function' || typeof selectFolder === 'function')
        ? (event) => {
            if (typeof onClick === 'function') {
              onClick(event);
            }

            const actionId = event.currentTarget.getAttribute('data-action-id');
            const isActionDialogAccept = actionId === DialogAccept;
            let folder: Writeable<Api.AccuroEMRProviderFolderListItem> | undefined;
            if (isActionDialogAccept) {
              const [folderId, subFolderId] = selectedFolder.split(',');
              folder = folderList.find(({ id }) => id.toString(10) === folderId);

              if (Utils.isNotNil(folder)) {
                let { subFolders } = folder;

                if (Utils.isString(subFolderId) && Utils.isArray(subFolders)) {
                  const subFolder = subFolders.find(({ id }) => id.toString(10) === subFolderId);
                  if (Utils.isNotNil(subFolder)) {
                    subFolders = [{ ...subFolder, subFolders: undefined }];
                  }
                } else {
                  subFolders = EMPTY_ARRAY;
                }

                folder = { ...folder, subFolders };
              } else {
                return;
              }
            }

            if (typeof selectFolder === 'function') {
              selectFolder(folder);
            }

            setSelectedFolder('');
          }
        : undefined,
    [folderList, isDialogOpen, onClick, selectFolder, selectedFolder]
  );

  const onFolderSelect = React.useMemo(
    () => (isDialogOpen ? (_: unknown, folderId: string) => setSelectedFolder(folderId) : undefined),
    [isDialogOpen]
  );

  return React.useMemo<Result>(() => {
    if (!isDialogOpen) return result;

    const { selectEMRProviderFolder: i18n } = globalI18n.dialog;
    const actionLabelAccept = t(resolveActionLabel(i18n.action.accept, DialogAccept));
    const actionLabelReject = t(resolveActionLabel(i18n.action.reject, DialogReject));
    const isFolderSelected = /^\d+/.test(selectedFolder);

    result.actions = (
      <DialogActionsAcceptReject
        AcceptActionProps={{ children: actionLabelAccept, disabled: !isFolderSelected, type: 'button' }}
        RejectActionProps={{ children: actionLabelReject }}
        onAccept={onDialogActionClick}
        onReject={onDialogActionClick}
      />
    );

    result.body = (
      <React.Suspense fallback={<CircularProgress />}>
        <SelectAccuroEMRProviderFolder
          listResource={listResource}
          multiSelect={false}
          onFolderSelect={onFolderSelect}
          selected={selectedFolder}
        />
      </React.Suspense>
    );

    result.maxWidth = 'sm';
    result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };

    if (authStatus !== true) {
      result.actions = (
        <button
          className={dialogBoxStyle['action-reject']}
          data-action-id={DialogReject}
          disabled={isListLoading}
          onClick={onDialogActionClick}
          type="button"
        >
          {actionLabelReject}
        </button>
      );

      if (!isListLoading) {
        result.body = null;
        result.ContentProps = { dangerouslySetInnerHTML: { __html: t(i18n.body.tokenExpired) } };
      }
    }

    return result;
  }, [
    authStatus,
    isDialogOpen,
    isListLoading,
    listResource,
    onDialogActionClick,
    onFolderSelect,
    result,
    selectedFolder,
    t
  ]);
};

const useUploadProgressDialogProps = (
  { docUploadStatus, documentList, isUploadComplete, onDialogActionClick: onClick, openedDialog, t }: Params,
  result: Result
): Result => {
  const isDialogOpen = openedDialog === 'emrUploadProgress';

  const uploadDialogBody = React.useMemo<React.ReactNode>(() => {
    if (!isDialogOpen) return null;

    const uploadStatus = Utils.arrayOrDefault<UploadStatusListItem>(docUploadStatus);
    const docList = Utils.arrayOrDefault<NonNullable<typeof documentList>[0]>(documentList);

    const { uploadProgress: i18n } = dialogI18n;
    let body = t(i18n.message.progress.uploadBody);

    let progress = 0;
    if (uploadStatus.length > 0 && docList.length > 0) {
      const totalCount = docList.length + 1;
      const uploadCount = uploadStatus.length;

      progress = Math.max(0, Math.min(100, (uploadCount / totalCount) * 100));

      if (uploadCount < totalCount) {
        body = t(i18n.message.progress.uploadDocument, { DOC_NAME: docList[uploadCount - 1].name });
      } else {
        body = '';
      }
    }

    if (isUploadComplete === true) {
      progress = 100;
      body = '';

      const skippedUploads = docList.filter((_, index) => uploadStatus[index + 1] === null).map(({ name }) => name);
      const failedUploads = docList.filter((_, index) => uploadStatus[index + 1] === false).map(({ name }) => name);
      const duplicateUploads = docList
        .filter((_, index) => uploadStatus[index + 1] === ACCURO_EMR_ERROR_CODE_DUPLICATE_DOCUMENT)
        .map(({ name }) => name);

      // message body upload status
      if (uploadStatus[0] !== true) body += t(i18n.message.error.uploadBody);

      // skipped uploads
      let count = skippedUploads.length;
      let DOC_LIST = count > 0 && skippedUploads.join(', ');
      if (Utils.isString(DOC_LIST)) body += t(i18n.message.error.unsupportedDocument, { count, DOC_LIST });

      // failed uploads
      count = failedUploads.length;
      DOC_LIST = count > 0 && failedUploads.join(', ');
      if (Utils.isString(DOC_LIST)) body += t(i18n.message.error.uploadDocument, { count, DOC_LIST });

      // duplicate uploads
      count = duplicateUploads.length;
      DOC_LIST = count > 0 && duplicateUploads.join(', ');
      if (Utils.isString(DOC_LIST)) body += t(i18n.message.error.duplicateDocument, { count, DOC_LIST });

      // success message
      if (body.length === 0) body = t(i18n.message.success);
    }

    return (
      <React.Fragment>
        <LinearProgress variant="determinate" value={progress} />
        <div
          className={style['emr-upload-status']}
          dangerouslySetInnerHTML={{ __html: body }}
          style={{ marginTop: '1rem' }}
        />
      </React.Fragment>
    );
  }, [isDialogOpen, docUploadStatus, documentList, t, isUploadComplete]);

  return React.useMemo<Result>(() => {
    if (!isDialogOpen) return result;

    const { uploadProgress: i18n } = dialogI18n;

    result.actions = (
      <DialogActionsAccept
        AcceptActionProps={{
          children: t(resolveActionLabel(i18n.action.accept, DialogAccept)),
          disabled: isUploadComplete !== true,
          onClick
        }}
      />
    );

    result.body = uploadDialogBody;
    result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };

    return result;
  }, [isDialogOpen, isUploadComplete, onClick, result, t, uploadDialogBody]);
};

export const useEMRDialogProps = (params: Params): Result => {
  const { onClose, onDialogActionClick: onClick, open, openedDialog, t } = params;

  const result = React.useMemo<Result>(
    () => ({ disableBackdropClick: true, disableEscapeKeyDown: true, open, onClose }),
    [onClose, open]
  );

  const selectPatientRecordDialogProps = useSelectPatientRecordDialogProps(params, result);
  const selectProviderFolderDialogProps = useSelectAccuroEMRProviderFolderDialogProps(params, result);
  const uploadProgressDialogProps = useUploadProgressDialogProps(params, result);

  return React.useMemo((): Result => {
    if (result.open !== true) return result;

    if (
      openedDialog === 'emrAccuroProviderIdMissing' ||
      openedDialog === 'emrNoMatchingPatientRecord' ||
      openedDialog === 'emrOAuthParamsMissing' ||
      openedDialog === 'emrOscarProviderIdMissing' ||
      openedDialog === 'emrTokenExpired'
    ) {
      let actionContext: string | undefined;
      let body = '';
      let i18n: Omit<typeof dialogI18n.noMatchingPatientRecord, 'body'>;
      if (openedDialog === 'emrNoMatchingPatientRecord') {
        i18n = dialogI18n.noMatchingPatientRecord;
        body = dialogI18n.noMatchingPatientRecord.body;
      } else if (openedDialog === 'emrOAuthParamsMissing') {
        i18n = dialogI18n.OAuthParamsMissing;
        body = dialogI18n.OAuthParamsMissing.body;
      } else if (openedDialog === 'emrAccuroProviderIdMissing') {
        actionContext = `${DialogEMRProviderIdMissing}/accuro`;
        i18n = dialogI18n.providerIdMissing;
        body = dialogI18n.providerIdMissing.body.accuro;
      } else if (openedDialog === 'emrOscarProviderIdMissing') {
        actionContext = `${DialogEMRProviderIdMissing}/oscar`;
        i18n = dialogI18n.providerIdMissing;
        body = dialogI18n.providerIdMissing.body.oscar;
      } else {
        i18n = dialogI18n.tokenExpired;
        body = dialogI18n.tokenExpired.body;
      }

      result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };
      result.ContentProps = { dangerouslySetInnerHTML: { __html: t(body) } };
      result.actions = (
        <DialogActionsAccept
          AcceptActionProps={{
            children: t(resolveActionLabel(i18n.action.accept, DialogAccept, actionContext)),
            onClick
          }}
        />
      );
    } else if (openedDialog === 'emrSelectPatientRecord') {
      return selectPatientRecordDialogProps;
    } else if (openedDialog === 'emrSelectProviderFolder') {
      return selectProviderFolderDialogProps;
    } else if (openedDialog === 'emrUploadProgress') {
      return uploadProgressDialogProps;
    }

    return result;
  }, [
    onClick,
    openedDialog,
    result,
    selectPatientRecordDialogProps,
    selectProviderFolderDialogProps,
    t,
    uploadProgressDialogProps
  ]);
};
