/**
 * Match only non-control characters that have been assigned and are UTF-8 valid.
 *
 * `\P{Cc}`: Do NOT match control characters.
 *
 * `\P{Cn}`: Do NOT match unassigned characters.
 *
 * `\P{Cs}`: Do NOT match UTF-8-invalid characters.
 */
export const NonControl_Assigned_And_Utf8Valid = '^[\\P{Cc}\\P{Cn}\\P{Cs}]+$';

/**
 * Match only non-whitespace characters but allow any leading and/or
 * trailing whitespace.
 */
export const AnythingButWhitespaceLoose = '^\\s*[^\\s]+\\s*$';

/**
 * Match only non-whitespace characters. Do NOT even allow any leading and/or
 * trailing whitespace.
 */
export const AnythingButWhitespaceStrict = '^[^\\s]+$';

/**
 * Match an empty string (''), or match only non-whitespace characters except
 * if it's at the beginning or end of the string.
 */
export const NothingOrNonWhitespaceLoose = `(^$)|${AnythingButWhitespaceLoose}`;

/**
 * Match an empty string (''), or match only non-whitespace characters.
 */
export const NothingOrNonWhitespaceStrict = `(^$)|${AnythingButWhitespaceStrict}`;

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const InstituteName = NonControl_Assigned_And_Utf8Valid;

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const GroupName = NonControl_Assigned_And_Utf8Valid;

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const GuestContactGroupName = NonControl_Assigned_And_Utf8Valid;

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const FirstName = NonControl_Assigned_And_Utf8Valid;

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const LastName = NonControl_Assigned_And_Utf8Valid;

/** Match North American phone number format - (555) 555-1234 */
export const PhoneNumber = '^\\(\\d{3}\\) \\d{3}-\\d{4}$';
export const PhoneNumberExtension = '^[\\d#*]+$';
export const FaxNumber = PhoneNumber;
export const EmailAddress = '^\\s*[\\w\\-][\\w\\.\\-]*@[a-zA-Z0-9][a-zA-Z0-9\\-]*(\\.[a-zA-Z0-9]+)+\\s*$';

// export const OhipBillingNumber = '^[A-Za-z0-9]{4}-\\d{6}-\\d{2}$';
export const OhipBillingNumber = NonControl_Assigned_And_Utf8Valid;

export const HealthPlanNumber: Readonly<Record<string, Readonly<Record<string, string | undefined>>>> = {
  CAN: {
    AB: '^\\d{3}-\\d{3}-\\d{3}(-\\d{2})?$',
    BC: '^9\\d{3}-\\d{3}-\\d{3}$',
    MB: '^\\d{3}-\\d{3}(-\\d{3})?$',
    NB: '^\\d{3}-\\d{3}-\\d{3}$',
    NL: '^\\d{3}-\\d{3}-\\d{3}-\\d{3}$',
    NS: '^\\d{4}-\\d{3}-\\d{3}$',
    NT: '^(D|d|M|m|N|n|T|t)\\d{7}$',
    NU: '^\\d{9}$',
    ON: '^\\d{4}-\\d{3}-\\d{3}(-[A-Za-z]{2})?$',
    PE: '^\\d{8}\\d?$',
    QC: '[A-Za-z]{4}-\\d{4}-\\d{4}',
    SK: '^\\d{3}-\\d{3}-\\d{2}\\d?$',
    YT: '^\\d{3}-\\d{3}-\\d{3}$'
  }
};

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const Username = NonControl_Assigned_And_Utf8Valid;
export const Password = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@[-`{|}~])(?=.{12,64})';

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const SecurityAnswer = NonControl_Assigned_And_Utf8Valid;

/** Equivalent to `AnythingButWhitespaceStrict`. */
export const AccessCode = AnythingButWhitespaceStrict;

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const MessageSubjectLine = NonControl_Assigned_And_Utf8Valid;

export const AccuroEmrClientId = '^[A-Za-z0-9]+$';
export const AccuroEmrClientSecret = '^[A-Za-z0-9]+$';
export const AccuroEmrUUID = '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$';
export const OscarEmrClientKey = '^[A-Za-z0-9]+$';
export const OscarEmrClientSecret = '^[A-Za-z0-9]+$';

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const EventTitle = NonControl_Assigned_And_Utf8Valid;
/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const EventDescription = NonControl_Assigned_And_Utf8Valid;

export const BirthDate = '^(d{4})-(d{2})-(d{2})$';

/** Equivalent to `NonControl_Assigned_And_Utf8Valid`. */
export const HealthDataRequestPhysicianNote = NonControl_Assigned_And_Utf8Valid;

export const NumbersOnlyStrict = /^[0-9]+$/;
export const PostalCode = '^[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]$';
