import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.View.BPReading = {
  action: {
    cancel: {
      // Translators: Label for the CANCEL action of Blood Pressure Reading view
      label: gettext('health-data', 'cancel', 'bpReadingViewActionLabel')
    },
    submit: {
      // Translators: Label for the SUBMIT action of Blood Pressure Reading view
      label: gettext('health-data', 'submit', 'bpReadingViewActionLabel')
    }
  },
  // Translators: (HTML) Title for the Blood Pressure Reading view
  heading: gettext('health-data', 'heading', 'bpReadingView', 'safe-html'),
  // Translators: (HTML) Lead text displayed on the Blood Pressure Reading view
  leadText: gettext('health-data', 'leadText', 'bpReadingView', 'safe-html')
};

export default i18n;
