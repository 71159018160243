import { SchedulingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: SchedulingI18n.ErrorMessage = {
  // Translators: Error message displayed when user tries to recall an event \
  // past the event's end date and time
  RecallWindowClosed: gettext('scheduling', 'scheduling', 'eventRecallWindowClosed'),
  // Translators: Error message displayed when user tries to ... TODO
  EventDetailsAccessDenied: gettext('scheduling', 'scheduling', 'eventDetailsAccessDenied'),
  // Translators: Error message displayed when participant is already \
  // connected to the meeting room
  AlreadyConnected: gettext('scheduling', 'meetingRoomError', 'alreadyConnected'),
  // Translators: Error message displayed when meeting's data could not be \
  // fetched from the API server
  EventObjectFetchFailure: gettext('scheduling', 'meetingRoomError', 'eventObjectFetchFailure'),
  // Translators: Error message displayed when a participant tries to join a \
  // meeting before it's start time
  MeetingNotStarted: gettext('scheduling', 'meetingRoomError', 'meetingNotStarted'),
  // Translators: Error message displayed when a participant tries to join a \
  // meeting which has already expired
  ExpiredMeeting: gettext('scheduling', 'meetingRoomError', 'expiredMeeting'),
  // Translators: Error message displayed when API call to get meeting room \
  // access token fails with an error
  AccessTokenFetchFailure: gettext('scheduling', 'meetingRoomError', 'accessTokenFetchFailure'),
  // Translators: Error message displayed when no audio input devices are \
  // available
  AudioInputDeviceMissing: gettext('scheduling', 'meetingRoomError', 'audioInputDeviceMissing'),
  // Translators: Error message displayed when a participant has the \
  // microphone access blocked in their browser settings
  MicrophonePermissionDenied: gettext('scheduling', 'meetingRoomError', 'microphonePermissionDenied'),
  // Translators: Error message displayed when no video input devices are \
  // available
  VideoInputDeviceMissing: gettext('scheduling', 'meetingRoomError', 'videoInputDeviceMissing'),
  // Translators: Error message displayed when a participant has the \
  // camera access blocked in their browser settings
  CameraPermissionDenied: gettext('scheduling', 'meetingRoomError', 'cameraPermissionDenied'),
  // Translators: Error message displayed when a participant has both the \
  // microphone and camera access blocked in their browser settings
  MediaPermissionDenied: gettext('scheduling', 'meetingRoomError', 'mediaPermissionDenied')
};

export default i18n;
