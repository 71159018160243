import type {
  AlgorithmCode,
  EncryptAsymmetricKeyAlgorithmCode,
  Nullable,
  SigmailKeyId,
  SigmailObjectId,
  SigmailObjectTypeCode
} from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { Algorithm, Constants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import { CryptographicKey } from '.';
import type { ICryptographicKeyPrivate } from '../types';

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE;

/** @public */
export class CryptographicKeyPrivate extends CryptographicKey<JsonWebKey> implements ICryptographicKeyPrivate {
  protected static override get DEFAULT_CODE(): EncryptAsymmetricKeyAlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE as EncryptAsymmetricKeyAlgorithmCode;
  }

  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  public static async create(
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number,
    value: JsonWebKey,
    encryptedFor: SigmailKeyId,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  ): Promise<CryptographicKeyPrivate> {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;
    const encryptedValue = await this.encryptAsymmetricKey(value, { encryptedFor, keyCode, keyVersion: version });

    const args = [id, keyCode, version, encryptedValue, encryptedFor, createdAtUtc, expiredAtUtc];
    return Reflect.construct(this, args) as CryptographicKeyPrivate;
  }

  public static async encryptFor(
    key: ICryptographicKeyPrivate,
    id: number,
    createdAtUtc?: Date
  ): Promise<ICryptographicKeyPrivate> {
    const { id: keyId, createdAtUtc: dtCreate } = key;
    const jsonWebKey = await key.decryptedValue();
    return this.create(keyId, undefined, 0, jsonWebKey, id, Utils.dateOrDefault(createdAtUtc, dtCreate));
  }

  public encryptFor(id: SigmailKeyId, createdAtUtc?: Date): Promise<ICryptographicKeyPrivate> {
    const Class = this.constructor as typeof CryptographicKeyPrivate;
    return Class.encryptFor(this, id, createdAtUtc);
  }

  public decryptedValue(): Promise<JsonWebKey> {
    if (!Algorithm.isValidEncryptAsymmetricKeyCode(this.code)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }

    const algorithm = getAlgorithm(this.code);
    const Class = this.constructor as typeof CryptographicKeyPrivate;
    const cryptoKey = Class.getPrivateKey(this.encryptedForId);

    return algorithm.decrypt({ privateKey: cryptoKey }, this.value, this.version);
  }
}
