import { ApiActionPayload, GroupActionPayload, MessagingActionPayload, SignInSuccessAuthOnly } from '@sigmail/app-state';
import { AppException, Constants, MemberRole, ReadonlyMessageBodyJoinGroupInvitation, Utils } from '@sigmail/common';
import { EncryptWithParametersAlgorithmParams, getAlgorithm } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  CryptographicKey,
  CryptographicKeyMaster,
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  DataObjectMsgBody,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  GroupObjectContactInfo,
  GroupObjectContactInfoValue,
  GroupObjectProfileBasic,
  GroupObjectProfileBasicValue,
  GroupObjectServerRights,
  IUserCredentials,
  IUserObject,
  MessageFolderListItem,
  MessageTimestampRecord,
  UserCredentials,
  UserCredentialsEmailToken,
  UserObjectProfileBasic,
  UserObjectProfileBasicValue,
  UserObjectSchedule,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { IAuthenticationData } from 'sigmail';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { generateCredentialHash } from '../../../utils/generate-credential-hash';
import { authSuccess } from '../../auth-slice';
import { signInAction } from '../../auth-slice/sign-in-action';
import {
  basicProfileObjectSelector as groupBasicProfileObjectSelector,
  contactInfoObjectSelector as groupContactInfoObjectSelector
} from '../../selectors/group-object';
import {
  basicProfileObjectSelector as userBasicProfileObjectSelector,
  contactInfoObjectSelector as userContactInfoObjectSelector
} from '../../selectors/user-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';
import { DEFAULT_ACCESS_CODE_JOIN_GROUP_INVITATION } from '../constants';
import { AUTH_STATE_AUTHORIZED_AS_ROLE, AUTH_STATE_RESPOND_TO_JOIN_GROUP_INVITATION } from '../constants/auth-state-identifier';
import { updateMessageFolderAction } from '../messaging/update-msg-folder-action';

interface Payload extends GroupActionPayload.RespondToJoinCircleOfCareGroupInvitation {}

const RESPONSE_ACCEPT: Extract<Payload['response'], 'accept'> = 'accept';
const RESPONSE_DECLINE: Extract<Payload['response'], 'decline'> = 'decline';
const EXPECTED_ROLE_LIST = [Constants.ROLE_ID_ADMIN_PHYSICIAN, Constants.ROLE_ID_SENIOR_PHYSICIAN, Constants.ROLE_ID_JUNIOR_PHYSICIAN].join(
  ', '
);

type ReadonlyMessageBodyJoinGroupInvitationValue = ReadonlyMessageBodyJoinGroupInvitation['messageForm']['value'];

interface State extends AuthenticatedActionState {
  msgBodyValue: ReadonlyMessageBodyJoinGroupInvitationValue;
  salt: string;
  requestBody: BatchUpdateRequestBuilder;
  requestBodyMsgFolderUpdate: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
  tempMasterKeyId: number;
  credentialId: number;
  dtServer: Date;
  groupClaim: string;
  serverRightsObject: IUserObject<UserObjectServerRightsValue>;
  serverRights: UserObjectServerRightsValue;
  currentVersionClaim: string;
  roleId: string;
}

type UserObjectDataUpdater = Api.DataUpdater<IUserObject<any>>;

class RespondToJoinGroupInvitationAction extends AuthenticatedAction<Payload, State> {
  private static readonly privateKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);

  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();

    const { folderKey, parentFolderKey, msgMetadataId, msgBodyId, response } = this.payload;
    const { roleAuthClaim } = this.state;

    let { role } = Utils.decodeIdToken(roleAuthClaim);
    if (role === 'adminPhysician') role = Constants.ROLE_ID_ADMIN_PHYSICIAN;
    if (!EXPECTED_ROLE_LIST.includes(role as MemberRole)) {
      throw new AppException(Constants.Error.S_ERROR, `Expected current user role to be ${EXPECTED_ROLE_LIST}; was <${role}>'`);
    }

    if (
      Utils.isNotNil(folderKey) &&
      folderKey !== Constants.MessageFolderKey.Inbox &&
      parentFolderKey !== Constants.MessageFolderKey.Inbox
    ) {
      throw new AppException(
        Constants.Error.S_ERROR,
        `Operation is not supported for this folder. (folderKey=${folderKey}, parentFolderKey=${String(parentFolderKey)})`
      );
    }

    if (!DataObjectMsgMetadata.isValidId(msgMetadataId)) {
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Message metadata ID is either missing or invalid.');
    } else if (!DataObjectMsgBody.isValidId(msgBodyId)) {
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Message body ID is either missing or invalid.');
    } else if (response !== RESPONSE_ACCEPT && response !== RESPONSE_DECLINE) {
      throw new AppException(Constants.Error.S_ERROR, `<response> must be one of: ${RESPONSE_ACCEPT}, ${RESPONSE_DECLINE}`);
    }

    return result;
  }

  /** @override */
  protected async onExecute() {
    const { response } = this.payload;

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.authenticateEmailToken();

        this.state.requestBody = new BatchUpdateRequestBuilder();
        this.state.requestBodyMsgFolderUpdate = new BatchUpdateRequestBuilder();

        this.state.successPayload = {
          request: { userObjects: { ids: [] }, dataObjects: { ids: [] } },
          response: { userObjects: [], dataObjects: [], serverDateTime: '' }
        };

        if (response === RESPONSE_ACCEPT) {
          await this.fetchTemporaryMasterKey();
          await this.fetchGroupPrivateKeyAndEncryptForUser();

          await Promise.all([
            this.addUpdateOperationForGroupProfile(), // 431
            this.addUpdateOperationForGroupContactInfo(), // 435
            this.addUpdateOperationForUserProfile() // 401
          ]);
        }

        await Promise.all([
          this.fetchGroupClaim(), // 441
          this.fetchUserServerRights() // 411
        ]);

        const {
          accessToken,
          msgBodyValue,
          groupClaim,
          serverRights,
          currentVersionClaim,
          serverRightsObject,
          credentialId,
          currentUser: { id: userId },
          dtServer,
          requestBody,
          successPayload
        } = this.state;

        const { authState: batchUpdateAuthState, claims: batchUpdateClaims } = await this.enterState(accessToken, {
          authState: this.state.roleAuthClaim,
          state: AUTH_STATE_RESPOND_TO_JOIN_GROUP_INVITATION,
          claims: [msgBodyValue.claim, groupClaim, serverRights.userClaim, currentVersionClaim]
        });

        if (!Utils.isArray<string>(batchUpdateClaims) || batchUpdateClaims.length !== 1) {
          throw new Api.MalformedResponseException('<claims> was expected to be an array (of length 1).');
        }

        if (response === RESPONSE_ACCEPT) {
          const [userClaim] = batchUpdateClaims;
          const updatedServerRightsObject = await serverRightsObject.updateValue({ ...serverRights, userClaim });
          requestBody.update(updatedServerRightsObject);
        }

        requestBody.expire({ type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, id: credentialId, userId } as IUserCredentials, dtServer);

        await this.addUpdateOperationsForMessageFolder(); // 251/252s

        const mutations = requestBody.build();
        const msgFolderMutations = this.state.requestBodyMsgFolderUpdate.build();
        if (msgFolderMutations.dataObjects!.length > 0) {
          mutations.dataObjects = msgFolderMutations.dataObjects;
        }

        await this.batchUpdateData(accessToken, {
          authState: batchUpdateAuthState,
          claims: [...batchUpdateClaims, groupClaim],
          ...mutations
        });

        if (response === RESPONSE_ACCEPT) {
          const { roleAuthClaim: authState, roleId, serverRights, currentVersionClaim } = this.state;
          await this.fetchUserServerRights();

          const { authState: authClaim } = await this.enterState(accessToken, {
            authState,
            state: AUTH_STATE_AUTHORIZED_AS_ROLE[roleId]!,
            claims: [serverRights.userClaim, currentVersionClaim]
          });

          try {
            this.dispatch(
              authSuccess({
                ...(this.getRootState().auth as IAuthenticationData),
                authClaim,
                activeCircleOfCareGroupId: this.getRootState().activeGroup
              })
            );
          } catch (error) {
            this.logger.warn('Error updating auth slice in app state:', error);
          }
        }

        try {
          await this.dispatchBatchQueryDataSuccess(successPayload);
        } catch (error) {
          this.logger.warn('Error manually updating app state:', error);
          /* ignore */
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      } finally {
        const { tempMasterKeyId, msgBodyValue } = this.state;
        if (CryptographicKeyMaster.isValidId(tempMasterKeyId)) CryptographicKey.clearPrivateKey(tempMasterKeyId);
        if (CryptographicKeyPrivate.isValidId(msgBodyValue?.group.id)) CryptographicKey.clearPrivateKey(msgBodyValue.group.id);
      }
    }
  }

  private async authenticateEmailToken(): Promise<void> {
    this.logger.info('Authenticating email token.');

    const { msgMetadataId, msgBodyId } = this.payload;

    const msgMetadata = await this.getDataObjectValue<DataObjectMsgMetadataValue>(msgMetadataId);
    const msgBody = (await this.getDataObjectValue<DataObjectMsgBodyValue>(msgBodyId)) as
      | ReadonlyMessageBodyJoinGroupInvitation
      | undefined;
    if (Utils.isNil(msgMetadata) || Utils.isNil(msgBody)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Message metadata and/or body is either missing or invalid.');
    }

    const msgBodyValue = msgBody.messageForm.value as ReadonlyMessageBodyJoinGroupInvitationValue;

    const { token: username } = msgBodyValue;
    const password = DEFAULT_ACCESS_CODE_JOIN_GROUP_INVITATION;
    const credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, { token: username });
    const authResponse = await this.dispatch(signInAction({ credentialHash, username, password, authOnly: true })).then(
      (response) => response as SignInSuccessAuthOnly | undefined
    );
    if (Utils.isNil(authResponse)) throw new AppException(Constants.Error.E_AUTH_FAIL);

    const { sharedParameters, user: authUser, loggedInClaim: authClaim } = authResponse;
    if (!UserCredentialsEmailToken.JoinGroupInvitation.isValidSharedParameters(sharedParameters)) {
      throw new AppException(Constants.Error.E_AUTH_FAIL_SHARED_PARAMS);
    }

    if (authUser.id !== this.state.currentUser.id) {
      throw new AppException(Constants.Error.E_AUTH_FAIL_USER_ID);
    }

    const decodedAuthClaim = Utils.decodeIdToken(authClaim);
    this.logger.debug('decodedAuthClaim =', decodedAuthClaim);

    const { keyId, credentialId } = decodedAuthClaim;
    if (!CryptographicKey.isValidId(keyId)) throw new AppException(Constants.Error.E_AUTH_FAIL_KEY_ID);
    if (!UserCredentials.isValidId(credentialId)) throw new AppException(Constants.Error.E_AUTH_FAIL_CREDENTIAL_ID);

    this.state.msgBodyValue = msgBodyValue;
    this.state.salt = sharedParameters.salt;
    this.state.tempMasterKeyId = keyId;
    this.state.credentialId = credentialId;
  }

  private async fetchTemporaryMasterKey(): Promise<void> {
    this.logger.info('Fetching the temporary master key.');

    const { roleAuthClaim: authState, tempMasterKeyId: keyId, credentialId, accessToken, msgBodyValue, salt } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [{ type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER, id: keyId, encryptedForId: credentialId }]
    };

    const { keyList } = await this.fetchObjects(accessToken, query);

    const masterKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER, id: keyId });
    if (Utils.isNil(masterKeyJson)) {
      throw new Api.MalformedResponseException('Temporary key could not be fetched.');
    } else {
      const params: EncryptWithParametersAlgorithmParams = {
        type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN,
        parameter1: msgBodyValue.token,
        parameter2: DEFAULT_ACCESS_CODE_JOIN_GROUP_INVITATION,
        hexSalt: salt
      };

      const masterKey = new CryptographicKeyMaster(masterKeyJson);
      const jsonWebKey = await masterKey.decryptedValue(params);
      this.logger.debug('Temporary master key:', JSON.stringify(jsonWebKey));
      const Class = this.constructor as typeof RespondToJoinGroupInvitationAction;
      const cryptoKey = await Class.privateKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPrivateKey(masterKey.id, cryptoKey);
    }
  }

  private async fetchGroupPrivateKeyAndEncryptForUser(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for encryption of group private key with user ID.');

    const { msgBodyValue, roleAuthClaim: authState, tempMasterKeyId: keyId, accessToken, currentUser, requestBody } = this.state;
    const { id: groupId } = msgBodyValue.group;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [
        { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: groupId, encryptedForId: keyId },
        { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: groupId, encryptedForId: groupId }
      ],
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_SCHEDULE, userId: currentUser.id }]
    };

    const { keyList, userObjectList } = await this.fetchObjects(accessToken, query);

    const groupPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: groupId });
    const groupPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: groupId });
    if (Utils.isNil(groupPrivateKeyJson) || Utils.isNil(groupPublicKeyJson)) {
      throw new Api.MalformedResponseException('Group private/public key could not be fetched.');
    } else {
      const scheduleJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_SCHEDULE, userId: currentUser.id });
      if (Utils.isNil(scheduleJson)) {
        throw new Api.MalformedResponseException('Schedule object could not be fetched.');
      }

      const groupPrivateKey = new CryptographicKeyPrivate(groupPrivateKeyJson);
      const groupPublicKey = new CryptographicKeyPublic(groupPublicKeyJson);
      await CryptographicKey.cache(groupPrivateKey);
      await CryptographicKey.cache(groupPublicKey);

      const groupKeyEncryptedForUser = await Promise.all([
        groupPrivateKey.encryptFor(currentUser.id),
        new UserObjectSchedule(scheduleJson).generateKeysEncryptedFor(groupId).then(([key]) => key)
      ]);

      requestBody.insert(groupKeyEncryptedForUser.filter(Utils.isNotNil));
    }
  }

  private async fetchGroupClaim(): Promise<void> {
    this.logger.info('Fetching the server rights claim associated with the group.');

    const { roleAuthClaim: authState, msgBodyValue, accessToken } = this.state;
    const { id: groupId } = msgBodyValue.group;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS, userId: groupId }]
    };

    const { userObjectList, serverDateTime } = await this.fetchObjects(accessToken, query);

    const serverRightsJson = this.findUserObject(userObjectList, { type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS, userId: groupId });
    if (Utils.isNil(serverRightsJson)) {
      throw new Api.MalformedResponseException('Group server rights object could not be fetched.');
    } else {
      const serverRightsObject = new GroupObjectServerRights(serverRightsJson);
      const { groupClaim } = await serverRightsObject.decryptedValue();
      this.logger.debug('Group claim:', groupClaim);

      this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
      this.state.groupClaim = groupClaim;
    }
  }

  private async fetchUserServerRights(): Promise<void> {
    this.logger.info('Fetching server rights object and version claim of current user.');

    const { accessToken, roleAuthClaim: authState } = this.state;
    const { id: userId } = this.state.currentUser;

    const { userObjectList, claims } = await this.fetchObjects(accessToken, {
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS, userId }],
      expectedCount: { claims: 1 }
    });

    if (claims.length !== 1) {
      throw new Api.MalformedResponseException('Current version claim is missing.');
    }

    const serverRightsJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS, userId });
    if (Utils.isNil(serverRightsJson)) {
      throw new Api.MalformedResponseException(`Server rights object could not be fetched. <userId=${userId}>`);
    } else {
      const serverRightsObject = new UserObjectServerRights(serverRightsJson);
      const serverRights = await serverRightsObject.decryptedValue();

      this.state.serverRightsObject = serverRightsObject;
      this.state.serverRights = serverRights;
      [this.state.currentVersionClaim] = claims;
    }
  }

  private async addUpdateOperationForGroupProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group profile.');

    const { currentUser, msgBodyValue, requestBody, successPayload } = this.state;

    const applyUpdate = async (profileObject: IUserObject<GroupObjectProfileBasicValue>) => {
      const profile = await profileObject.decryptedValue();

      const updatedList = profile.memberList.slice();
      const index = updatedList.findIndex(({ type, id }) => type === 'user' && id === currentUser.id);
      if (index > -1) updatedList.splice(index, 1);
      updatedList.push(currentUser);

      const updatedValue: GroupObjectProfileBasicValue = { ...profile, memberList: updatedList };
      this.logger.debug(updatedValue);

      return profileObject.updateValue(updatedValue);
    };

    const profileObject = await this.getUserObject(groupBasicProfileObjectSelector, {
      type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC,
      userId: msgBodyValue.group.id
    });

    if (Utils.isNil(profileObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Group basic profile could not be fetched.');
    }

    const profileObjectKey = profileObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (profileJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === profileJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; group profile could not be found in request body.');
      }

      const key = profileObjectKey === null ? null : profileObjectKey.toApiFormatted();
      const profileObject = new GroupObjectProfileBasic({ ...profileJson, key });
      const updatedObject = await applyUpdate(profileObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === profileJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(profileObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(profileObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForGroupContactInfo(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group contact info.');

    const { currentUser, msgBodyValue, requestBody, successPayload } = this.state;

    const applyUpdate = async (groupContactInfoObject: IUserObject<GroupObjectContactInfoValue>) => {
      const groupContactInfo = await groupContactInfoObject.decryptedValue();
      const { newMessageEmailNotificationList: list } = groupContactInfo;

      const userContactInfo = await this.getUserObjectValue(userContactInfoObjectSelector, {
        type: process.env.USER_OBJECT_TYPE_CONTACT_INFO
      });

      if (Utils.isNil(userContactInfo)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'User contact info is either missing or invalid.');
      }

      const updatedList = Utils.isArray<NonNullable<typeof list>[0]>(list) ? list.slice() : [];
      const index = updatedList.findIndex(({ id }) => id === currentUser.id);
      if (index > -1) updatedList.splice(index, 1);
      const { prefix, firstName, middleName, lastName, suffix, emailAddress } = userContactInfo;
      updatedList.push({ ...currentUser, prefix, firstName, middleName, lastName, suffix, emailAddress });

      const updatedValue: GroupObjectContactInfoValue = { ...groupContactInfo, newMessageEmailNotificationList: updatedList };
      this.logger.debug(updatedValue);

      return groupContactInfoObject.updateValue(updatedValue);
    };

    const contactInfoObject = await this.getUserObject(groupContactInfoObjectSelector, {
      type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO,
      userId: msgBodyValue.group.id
    });

    if (Utils.isNil(contactInfoObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Group contact info could not be fetched.');
    }

    const contactInfoObjectKey = contactInfoObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (contactInfoJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === contactInfoJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; group contact info could not be found in request body.');
      }

      const key = contactInfoObjectKey === null ? null : contactInfoObjectKey.toApiFormatted();
      const contactInfoObject = new GroupObjectContactInfo({ ...contactInfoJson, key });
      const updatedObject = await applyUpdate(contactInfoObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === contactInfoJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(contactInfoObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(contactInfoObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForUserProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for user profile.');

    const { msgBodyValue, requestBody, successPayload } = this.state;
    const { group } = msgBodyValue;

    const applyUpdate = (profileObject: IUserObject<UserObjectProfileBasicValue>, profile: UserObjectProfileBasicValue) => {
      const { memberOf: list } = profile;
      const updatedList = Utils.isArray<NonNullable<typeof list>[0]>(list) ? list.slice() : [];
      const index = updatedList.findIndex(
        ({ type, groupType, id }) => type === group.type && groupType === group.groupType && id === group.id
      );
      if (index > -1) updatedList.splice(index, 1);
      updatedList.push(group);

      const updatedValue: UserObjectProfileBasicValue = { ...profile, memberOf: updatedList };
      this.logger.debug(updatedValue);

      return profileObject.updateValue(updatedValue);
    };

    const profileObject = await this.getUserObject(userBasicProfileObjectSelector, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC });
    if (Utils.isNil(profileObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'User basic profile could not be fetched.');
    }

    const profileObjectKey = profileObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (profileJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === profileJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; user profile could not be found in request body.');
      }

      const key = profileObjectKey === null ? null : profileObjectKey.toApiFormatted();
      const profileObject = new UserObjectProfileBasic({ ...profileJson, key });
      const profile = await profileObject.decryptedValue();
      const updatedObject = await applyUpdate(profileObject, profile);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === profileJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const profile = await profileObject.decryptedValue();
    const updatedObject = await applyUpdate(profileObject, profile);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(profileObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());

    this.state.roleId = profile.role;
  }

  private async addUpdateOperationsForMessageFolder(): Promise<void> {
    this.logger.info('Adding an update operation to request body for the message folder.');

    const { folderKey, parentFolderKey } = this.payload;
    const { requestBodyMsgFolderUpdate } = this.state;

    // we keep this success payload separate and merge it ONLY IF one of the
    // following operations succeed
    const successPayload: State['successPayload'] = {
      request: { dataObjects: { ids: [] } },
      response: { dataObjects: [], serverDateTime: '' }
    };

    try {
      await this.dispatch(
        updateMessageFolderAction({
          folderKey,
          parentFolderKey,
          requestBody: requestBodyMsgFolderUpdate,
          successPayload,
          applyUpdate: this.applyMessageFolderUpdate.bind(this)
        })
      );

      // look in ARCHIVED folder only if message wasn't found in INBOX
      if (!Utils.isNonEmptyArray(requestBodyMsgFolderUpdate.build().dataObjects)) {
        await this.dispatch(
          updateMessageFolderAction({
            folderKey: Constants.MessageSubFolderKey.Archived,
            parentFolderKey: Constants.MessageFolderKey.Inbox,
            requestBody: requestBodyMsgFolderUpdate,
            successPayload,
            applyUpdate: this.applyMessageFolderUpdate.bind(this)
          })
        );
      }

      if (successPayload.request.dataObjects!.ids.length > 0) {
        this.state.successPayload.request.dataObjects = successPayload.request.dataObjects;
        this.state.successPayload.response.dataObjects = successPayload.response.dataObjects;
      }
    } catch (error) {
      this.logger.warn('updateMessageFolderAction failed with an error:', error);
    }
  }

  private applyMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    _UNUSED_itemCount: any,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { msgMetadataId, msgBodyId, response } = this.payload;
    const { dtServer } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    this.logger.info(
      `Locating item with message metadata ID <${msgMetadataId}> and message body ID <${msgBodyId}> in ${folderOrExt} <${meta.folderOrExtId}>.`
    );

    const index = folderData.findIndex(({ header, body }) => header === msgMetadataId && body === msgBodyId);
    if (index === -1) return result;

    const message = folderData[index];
    let timestamp: MessageTimestampRecord;
    if (Utils.isInteger(message.timestamp)) {
      timestamp = { createdAt: message.timestamp };
    } else {
      timestamp = { ...message.timestamp };
    }

    timestamp[response === RESPONSE_ACCEPT ? 'acceptedAt' : 'declinedAt'] = dtServer.getTime();

    folderData[index] = { ...message, timestamp };

    result.updated = true;
    result.done = true;

    return result;
  }
}

export const respondToJoinGroupInvitationAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'respondToJoinGroupInvitationAction:');

    const action = new RespondToJoinGroupInvitationAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
