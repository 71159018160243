import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionPayloadAuthFailure, ActionPayloadAuthSuccess } from '@sigmail/app-state';
import { AppUserGroup, Utils } from '@sigmail/common';
import { signOutAction } from '../actions';

export const INITIAL_STATE: number = 0;

const authFailure = createAction<ActionPayloadAuthFailure>('auth/authFailure');
const authSuccess = createAction<ActionPayloadAuthSuccess>('auth/authSuccess');

const activeGroupSlice = createSlice({
  name: 'activeGroup',
  initialState: INITIAL_STATE,
  reducers: {
    setActiveGroup: (__UNUSED_state, action: PayloadAction<number>) => action.payload
  },
  extraReducers: (builder) => {
    builder.addCase(signOutAction, () => INITIAL_STATE);
    builder.addCase(authFailure, () => INITIAL_STATE);
    builder.addCase(authSuccess, (state, { payload }) => {
      if (Utils.isNonArrayObjectLike(payload) && AppUserGroup.isValidId(payload.activeCircleOfCareGroupId)) {
        return payload.activeCircleOfCareGroupId;
      }
      return state;
    });
  }
});

export const { setActiveGroup } = activeGroupSlice.actions;
export const { reducer: activeGroupReducer } = activeGroupSlice;
