import { Utils } from '@sigmail/common';
import { E_MALFORMED_RESPONSE } from './constants/error';
import { ServiceException as ApiServiceException } from './service-exception';

const DEFAULT_ERROR_MESSAGE = 'API service error: Malformed/unexpected response data.';

/** @public */
export class MalformedResponseException extends ApiServiceException {
  public constructor(message: string);
  public constructor(errorCode?: number, message?: string);
  public constructor(response: Response, message?: string);
  public constructor(response?: Response, errorCode?: number, message?: string);

  public constructor(...args: Array<unknown>) {
    const params: [Response, number, string] = [Response.error(), E_MALFORMED_RESPONSE, DEFAULT_ERROR_MESSAGE];

    if (args.length >= 1) {
      if (args[0] instanceof Response) params[0] = args[0];

      if (args.length >= 2) {
        if (Utils.isNumber(args[1])) params[1] = args[1];

        if (args.length >= 3) {
          if (Utils.isString(args[2])) params[2] = args[2];
        } else {
          if (Utils.isNumber(args[0])) params[1] = args[0];
          if (Utils.isString(args[1])) params[2] = args[1];
        }
      } else if (Utils.isNumber(args[0])) {
        params[1] = args[0];
      } else if (Utils.isString(args[0])) {
        params[2] = args[0];
      }
    }

    params[2] = `<HTTP ${(args[0] as Response).status}> ${params[2]}`;
    super(...params);

    this.name = 'MalformedApiResponseException';
  }
}
