import { Nullable, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';

interface AccuroEMRFirstNameRecord {
  readonly firstName?: Nullable<string>;
}

export const selectFirstName = (record: Nullable<AccuroEMRFirstNameRecord>): string | null =>
  Utils.isNonArrayObjectLike<Api.AccuroEMRPersonNameDTO>(record) && Utils.isString(record.firstName)
    ? record.firstName
    : null;
