import { Utils } from '@sigmail/common';
import { conformToMask } from 'react-text-mask';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../app-state/actions/constants';
import { HealthPlanNumber as HealthPlanNumberMask } from '../constants/form-input-mask';

export function formatHealthPlanNumber(value?: unknown, jurisdiction?: unknown): string {
  const planNumber = Utils.trimOrDefault(value);
  const planJurisdiction = Utils.trimOrDefault(arguments.length < 2 ? DEFAULT_HEALTH_PLAN_JURISDICTION : jurisdiction);

  const [level1, level2] = planJurisdiction.split('$').map((level) => level.trim());
  let mask: Array<string | RegExp> | false | undefined;
  if (Utils.isNonArrayObjectLike(HealthPlanNumberMask[level1])) {
    mask = HealthPlanNumberMask[level1][level2];
  }

  if (Utils.isArray<string | RegExp>(mask)) {
    let { conformedValue } = conformToMask(planNumber, mask, { guide: false });
    return conformedValue.slice(0, conformedValue.length - Number(conformedValue.endsWith('-')));
  }

  return planNumber;
}
