import { ApiActionPayload, SrpVerifyCredentialResponseData } from '@sigmail/app-state';
import { AppException, Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';

export const srpVerifyCredentialAction = ({
  sessionId,
  salt,
  credentialHash,
  passwordHash,
  clientEphemeralSecret,
  serverEphemeralPublic
}: ApiActionPayload.SrpVerifyCredential): AppThunk<Promise<SrpVerifyCredentialResponseData>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'srpVerifyCredentialAction:');

    Logger.info('== BEGIN ==');
    try {
      const privateKey = Utils.srpDerivePrivateKey(salt, credentialHash, passwordHash);
      Logger.debug('privateKey =', privateKey);

      const session = Utils.srpDeriveSession(clientEphemeralSecret, serverEphemeralPublic, salt, credentialHash, privateKey);
      Logger.debug('session =', JSON.stringify(session));

      for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
        try {
          const responseJson = await apiService.srpVerifyCredential(sessionId, credentialHash, session.proof);

          // convert all keys to camel case
          const authData = (Utils.mapKeys(
            { ...responseJson, salt },
            Utils.rearg(Utils.camelCase, 1)
          ) as unknown) as SrpVerifyCredentialResponseData;

          return authData;
        } catch (error) {
          if (error instanceof Api.ServiceException && attempt === 1) {
            Logger.debug(`API returned HTTP ${error.response.status}; operation will be retried.`);
            continue;
          }
          throw error;
        }
      }

      throw new AppException(Constants.Error.E_AUTH_FAIL);
    } finally {
      Logger.info('== END ==');
    }
  };
};
