import { Nullable, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import { EMPTY_ARRAY } from '../../app-state/constants';

interface AccuroEMRContactRecord {
  readonly phones?: Nullable<ReadonlyArray<Api.AccuroEMRPhoneDTO>>;
}

type AccuroEMRContactType = 'CellPhone' | 'HomePhone' | 'OfficePhone' | 'WorkPhone';

export const selectPhoneNumber = (
  record: Nullable<AccuroEMRContactRecord>,
  contactType: AccuroEMRContactType
): Api.AccuroEMRPhoneDTO | null => {
  const phoneList = Utils.arrayOrDefault<Api.AccuroEMRPhoneDTO>(
    Utils.isNonArrayObjectLike<AccuroEMRContactRecord>(record) && record.phones,
    EMPTY_ARRAY
  );
  const index = phoneList.findIndex(
    (entry) => Utils.isNonArrayObjectLike<typeof entry>(entry) && entry.contactType === contactType
  );
  return index === -1 ? null : phoneList[index];
};
