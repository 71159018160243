import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.Form.OneTimeMessage.VerifyPhoneNumber = {
  fieldsetPhoneNumber: {
    formField: {
      phoneNumber: {
        label: gettext('messaging', 'phoneNumber', 'oneTimeMessageVerifyPhoneNumberFormFieldLabel'),
        error: {
          mismatch: gettext('messaging', 'phoneNumberMismatch', 'oneTimeMessageVerifyPhoneNumberFormError'),
          patternMismatch: gettext('messaging', 'phoneNumberPattern', 'oneTimeMessageVerifyPhoneNumberFormError'),
          valueMissing: gettext('messaging', 'phoneNumberRequired', 'oneTimeMessageVerifyPhoneNumberFormError')
        }
      }
    }
  }
};

export default i18n;
