import { createSelector } from '@reduxjs/toolkit';
import { StoreStateUserObjects as StoreStateGroupObjects } from '@sigmail/app-state';
import { IAppUserGroup, Utils } from '@sigmail/common';
import {
  DataObjectMsgFolder,
  GroupMessageFolderItem,
  GroupObjectAccessRightsValue,
  GroupObjectContactInfoValue,
  GroupObjectFolderListValue,
  GroupObjectFolderListValue_v2,
  GroupObjectGuestListValue,
  GroupObjectPreferencesValue,
  GroupObjectProfileBasicValue,
  GroupObjectServerRightsValue,
  IUserObject as IGroupObject,
  UserObjectEventLogValue as GroupObjectEventLogValue,
  ValueFormatVersion
} from '@sigmail/objects';
import { defaultMemoize } from 'reselect';
import { activeGroupIdSelector } from '.';
import { DEFAULT_JOIN_GROUP_INVITATION_EXPIRY_IN_DAYS } from '../../constants';
import { GROUP_OBJECT_TYPE_CODE_LIST } from '../constants';
import { RootState } from '../root-reducer';
import { UserObjectCache as GroupObjectCache } from '../user-objects-slice/cache';

const TYPE_CODE_LIST: ReadonlyArray<string> = GROUP_OBJECT_TYPE_CODE_LIST.map(String);
const TYPE_MSG_FOLDER = process.env.DATA_OBJECT_TYPE_MSG_FOLDER;

const groupObjectMapSelector = createSelector<RootState, StoreStateGroupObjects, StoreStateGroupObjects>(
  (state) => state.userObjects,
  (userObjectMap) => userObjectMap.filter((_, key) => TYPE_CODE_LIST.includes(key))
);

const createGroupObjectsByTypeSelector = createSelector(groupObjectMapSelector, (groupObjectMap) =>
  defaultMemoize((objectType: number) => groupObjectMap.get(objectType.toString(10)))
);

const createGroupObjectSelector = <DV extends ValueFormatVersion>(objectType: number) => {
  return createSelector(
    (state) => createGroupObjectsByTypeSelector(state)(objectType),
    activeGroupIdSelector,
    (groupIdMap, activeGroupId) =>
      Utils.memoize((groupId?: number | undefined): IGroupObject<DV> | undefined => {
        const key = Utils.isInteger(groupId) ? groupId : activeGroupId;
        if (!Utils.isInteger(key)) return undefined;

        const objectId = groupIdMap?.get(key.toString())?.keySeq().first();
        return GroupObjectCache.find<IGroupObject<DV>>(Number(objectId))?.[0];
      })
  );
};

/**
 * Selector to extract the basic profile object associated with the circle of
 * care group the current user is a member of.
 */
export const basicProfileObjectSelector = createGroupObjectSelector<GroupObjectProfileBasicValue>(
  process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC
);

/**
 * Selector to extract the folder list object associated with the circle of
 * care group the current user is a member of.
 */
export const folderListObjectSelector = createGroupObjectSelector<GroupObjectFolderListValue>(
  process.env.GROUP_OBJECT_TYPE_FOLDER_LIST
);

/**
 * Selector to extract the contact info object associated with the circle of
 * care group the current user is a member of.
 */
export const contactInfoObjectSelector = createGroupObjectSelector<GroupObjectContactInfoValue>(
  process.env.GROUP_OBJECT_TYPE_CONTACT_INFO
);

/**
 * Selector to extract the preferences object associated with the circle of
 * care group the current user is a member of.
 */
export const preferencesObjectSelector = createGroupObjectSelector<GroupObjectPreferencesValue>(
  process.env.GROUP_OBJECT_TYPE_PREFERENCES
);

/**
 * Selector to extract the access rights object associated with the circle of
 * care group the current user is a member of.
 */
export const accessRightsObjectSelector = createGroupObjectSelector<GroupObjectAccessRightsValue>(
  process.env.GROUP_OBJECT_TYPE_ACCESS_RIGHTS
);

/**
 * Selector to extract the server rights object associated with the circle of
 * care group the current user is a member of.
 */
export const serverRightsObjectSelector = createGroupObjectSelector<GroupObjectServerRightsValue>(
  process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS
);

/**
 * Selector to extract the guest list object associated with the circle of
 * care group the current user is a member of.
 */
export const guestListObjectSelector = createGroupObjectSelector<GroupObjectGuestListValue>(
  process.env.GROUP_OBJECT_TYPE_GUEST_LIST
);

export const eventLogObjectSelector = createGroupObjectSelector<GroupObjectEventLogValue>(
  process.env.GROUP_OBJECT_TYPE_EVENT_LOG
);

/** TODO document */
export const memberListSelector = createSelector(basicProfileObjectSelector, (selectGroupProfileBasic) =>
  Utils.memoize((groupId?: IAppUserGroup['id']) => {
    const groupProfileObject = selectGroupProfileBasic(groupId);
    return GroupObjectCache.getValue(groupProfileObject)?.memberList;
  })
);

/**
 * Selector to extract a map of all message folders associated with the circle
 * of care group the current user is a member of.
 */
export const messageFolderMapSelector = createSelector(folderListObjectSelector, (selectFolderListObject) =>
  Utils.memoize((groupId?: number): { [folderKey: string]: GroupMessageFolderItem | undefined } => {
    const folderListObject = selectFolderListObject(groupId);
    const { $$formatver, ...folderList } = GroupObjectCache.getValue(
      folderListObject,
      {} as GroupObjectFolderListValue
    );

    return Utils.transform(
      (folderList as GroupObjectFolderListValue_v2).msg,
      (folderItemMap, folder, folderKey) => {
        if (
          Utils.isNonArrayObjectLike(folder) &&
          DataObjectMsgFolder.isValidId(folder.id) &&
          folder.type === TYPE_MSG_FOLDER
        ) {
          folderItemMap[folderKey] = folder;
        }
      },
      {} as { [folderKey: string]: GroupMessageFolderItem | undefined }
    );
  })
);

export const preferencesSelector = createSelector(preferencesObjectSelector, (selectPreferencesObject) => {
  const preferencesObject = selectPreferencesObject();
  const preferences = GroupObjectCache.getValue(preferencesObject, {} as GroupObjectPreferencesValue);

  type IntegrationsRecord = GroupObjectPreferencesValue['integrations'];
  let oscarEmrOAuthParams: NonNullable<NonNullable<IntegrationsRecord>['oscar']>['oauthParams'] = undefined;

  const { integrations } = preferences;
  if (Utils.isNonArrayObjectLike<NonNullable<IntegrationsRecord>>(integrations)) {
    const { oscar } = integrations;
    if (Utils.isNonArrayObjectLike<NonNullable<NonNullable<IntegrationsRecord>['oscar']>>(oscar)) {
      oscarEmrOAuthParams = oscar.oauthParams;
    }
  }

  let { joinInvitationExpiry } = preferences;
  if (!Utils.isInteger(joinInvitationExpiry)) {
    joinInvitationExpiry = DEFAULT_JOIN_GROUP_INVITATION_EXPIRY_IN_DAYS;
  }

  joinInvitationExpiry = Math.max(0, Math.min(joinInvitationExpiry!, 366));

  return { oscarEmrOAuthParams, joinInvitationExpiry };
});
