import type { SigmailObjectTypeCode } from '@sigmail/common';
import { NotificationObject } from '.';
import { ClientObject } from '../client-object';
import type { NotificationObjectClientRegistrationValue as DV } from '../types';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_CLIENT_REGISTRATION;

/** @public */
export class NotificationObjectClientRegistration extends NotificationObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return ClientObject.isValidId(dv.clientId);
  }
}
