import { AppException, Constants, SigmailKeyId, SigmailObjectId, SigmailUserId, Utils } from '@sigmail/common';
import { EncryptWithParametersAlgorithmParams, getAlgorithm } from '@sigmail/crypto';
import {
  CryptographicKey,
  CryptographicKeyAudit,
  CryptographicKeyMaster,
  CryptographicKeyPublic,
  NotificationObjectIncomingMessage,
  OneTimeMessageExpirePeriod,
  ServerParamsEmailToken,
  SharedParamsEmailTokenOneTimeMessage,
  UserCredentialsEmailToken,
  UserObjectContactInfo,
  UserObjectContactInfoValue,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { addDays, addMonths, addWeeks, addYears } from 'date-fns';
import { UseTranslationResponse } from 'react-i18next';
import * as EmailTemplateParams from '../../../../constants/email-template-params';
import { EnglishCanada, FrenchCanada } from '../../../../constants/language-codes';
import { I18N_NS_MESSAGING } from '../../../../i18n/config/namespace-identifiers';
import messagingI18n from '../../../../i18n/messaging';
import { BatchUpdateRequestBuilder } from '../../../../utils/batch-update-request-builder';
import { generateRandomAccessCode } from '../../../../utils/generate-access-code';
import { generateCredentialHash } from '../../../../utils/generate-credential-hash';
import { generateEmailToken } from '../../../../utils/generate-email-token';
import { contactInfoObjectSelector as selectGroupContactInfo } from '../../../selectors/group-object';
import { AUTH_STATE_CREATE_AS_ROLE, AUTH_STATE_ENABLE_MFA } from '../../constants/auth-state-identifier';
import { sendTemplatedEmailMessageAction } from '../../email/send-templated-email-message-action';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';

const isOneTimeContact = ({ entity }: State['recipientList'][0]) => entity.isOneTimeContact === true;

export interface Payload extends Omit<BaseSendMessagePayload, 'oneTimeExpire'> {
  oneTimeExpire: NonNullable<BaseSendMessagePayload['oneTimeExpire']>;
  translation: UseTranslationResponse;
}

interface State extends BaseSendMessageState {
  accessCode: string;
  cacheMasterKeyId: Array<SigmailKeyId>;
  cacheTokenList: Array<Record<'id' | 'value', string>>;
  credentialHash: string;
  credentialId: SigmailObjectId;
  inviteeId: SigmailUserId;
  masterKeyId: SigmailKeyId;
  otpClaim: string;
  serverParameters: ServerParamsEmailToken;
  sharedParameters: SharedParamsEmailTokenOneTimeMessage;
  token: string;
}

export class SendOneTimeMessageAction extends BaseSendMessageAction<Payload, State> {
  private readonly asymmetricKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);

  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();

    const { translation } = this.payload;
    await translation.i18n.reloadResources(FrenchCanada, I18N_NS_MESSAGING);

    this.state.dtServer = await this.dispatchFetchServerDateAndTime();

    return result;
  }

  /** @override */
  protected async buildRecipientKeyIdList(): Promise<State['recipientKeyIdList']> {
    const { sender } = this.payload;
    const { recipientList } = this.state;

    return Utils.filterMap(
      recipientList.filter((recipient) => !isOneTimeContact(recipient)),
      ({ entity: { keyId, id } }) => id !== sender.id && (CryptographicKeyPublic.isValidId(keyId) ? keyId : id)
    );
  }

  private generateAccessCode() {
    this.logger.info('Generating access code.');

    this.state.accessCode = generateRandomAccessCode().join('');

    this.logger.debug('accessCode =', this.state.accessCode);
  }

  private generateEmailToken(): void {
    this.logger.info('Generating a one-time email token.');

    const { sharedParameters } = this.state;
    const token = generateEmailToken(sharedParameters);
    this.state.token = token;

    this.logger.debug('token =', this.state.token);
  }

  private async generateServerParameters(password: string) {
    this.logger.info('Generating server parameters for one-time email token.');

    const { token, sharedParameters } = this.state;

    this.state.credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, { token });
    this.logger.debug('credentialHash =', this.state.credentialHash);

    const { salt: hexSalt } = sharedParameters;
    const { passwordHash } = await Utils.generatePasswordHash(password, hexSalt);
    this.logger.debug('passwordHash =', passwordHash);

    const verifier = Utils.srpGenerateVerifier(hexSalt, this.state.credentialHash, passwordHash);
    this.state.serverParameters = { verifier };
    this.logger.debug('serverParameters =', this.state.serverParameters);
  }

  private generateSharedParameters({ ld }: Pick<SharedParamsEmailTokenOneTimeMessage, 'ld'>): void {
    this.logger.info('Generating shared parameters for one-time email token.');

    this.state.sharedParameters = {
      ld,
      otpClaim: this.state.otpClaim,
      response: 'oneTimeMessage',
      salt: Utils.generateSalt('hex')
    };

    this.logger.debug('sharedParameters =', this.state.sharedParameters);
  }

  /** @override */
  protected async onExecute(...args: any[]) {
    const { recipientList } = this.state;

    try {
      this.state.cacheMasterKeyId = [];
      this.state.cacheTokenList = [];

      const oneTimeRecipientList = recipientList.filter(isOneTimeContact);
      for (const recipient of oneTimeRecipientList) {
        this.state.requestBody = new BatchUpdateRequestBuilder();

        const { entity } = recipient;
        if (Utils.isNil(entity.cellNumber) || Utils.isNil(entity.emailAddress)) break;

        /** preExecute */
        await this.enterStateForOTPClaim(entity.cellNumber);
        this.generateSharedParameters({ ld: entity.cellNumber.slice(-4) });
        this.generateEmailToken();
        this.generateAccessCode();
        await this.generateServerParameters(entity.cellNumber);

        this.state.cacheTokenList.push({ id: entity.emailAddress, value: this.state.token });

        /** generateIdSequence */
        await this.generateUserIdSequence();
        this.state.recipientKeyIdList.push(this.state.masterKeyId);
        const index = recipientList.findIndex(({ entity: { id } }) => id === recipient.entity.id);
        if (index !== -1) {
          recipientList[index] = {
            entity: {
              ...recipientList[index].entity,
              id: this.state.inviteeId,
              keyId: this.state.masterKeyId
            }
          };
        }

        /** generateRequestBody */
        await this.addInsertOperationForTemporaryUserKey(entity.cellNumber); // 112
        await this.addInsertOperationForUserServerRights(); // 411
        await this.addInsertOperationForUserContactInfo(recipient); // 405
        await this.addInsertOperationForEmailTokenAndAuditKey(entity.cellNumber); // 871

        /** execute */
        const { batchUpdateAuthState: authState, batchUpdateClaims: claims, requestBody } = this.state;
        await this.dispatchBatchUpdateData({ authState, claims, ...requestBody.build() });
      }

      this.state.recipientList = recipientList;
      await super.onExecute(...args);
    } catch (error) {
      this.logger.error('Error sending one-time message:', error);
      throw error;
    } finally {
      for (const keyId in this.state.cacheMasterKeyId) {
        if (CryptographicKeyMaster.isValidId(keyId)) {
          CryptographicKey.clearPrivateKey(keyId);
          CryptographicKey.clearPublicKey(keyId);
        }
      }
    }
  }

  /** @override */
  protected async generateRequestBody() {
    await super.generateRequestBody();
    await this.sendEmailNotification();
  }

  /** @override */
  protected async addInsertOperationsForRecipientNotification(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for recipient notification.');

    const { onBehalfOf, sender } = this.payload;
    const { dtServer, idRecord, recipientList, requestBody } = this.state;

    const idSequence = Utils.makeSequence(idRecord[process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE]);

    const data = await this.createNotificationValue();
    for (const { entity } of recipientList) {
      const { value: id } = idSequence.next();
      const { id: encryptedForId } = Utils.isNil(onBehalfOf) ? sender : onBehalfOf;
      const notificationObject = await NotificationObjectIncomingMessage.create(
        id,
        undefined,
        0,
        data,
        entity.id,
        encryptedForId,
        0,
        dtServer,
        entity.isOneTimeContact ? this.objectDtExpiry : undefined
      );
      requestBody.insert(notificationObject);
    }
  }

  private async enterStateForOTPClaim(phoneNumber: string): Promise<void> {
    const { roleAuthClaim: authState } = this.state;

    const { claims } = await this.dispatchEnterState({
      authState,
      state: AUTH_STATE_ENABLE_MFA,
      // @ts-expect-error
      method: 'sms',
      phoneNumber
    });

    const otpClaim = this.findClaim(claims, { name: 'otp' });
    if (!Utils.isString(otpClaim)) {
      throw new Api.MalformedResponseException('Claim is either missing or invalid. <name="otp">');
    }

    this.state.otpClaim = otpClaim;
  }

  private async generateUserIdSequence(): Promise<void> {
    const { roleAuthClaim: authState } = this.state;

    const query: Api.GetIdsRequestData = {
      authState,
      state: AUTH_STATE_CREATE_AS_ROLE[Constants.ROLE_ID_GUEST]!,
      ids: {
        usages: [{ usage: 'userId' }],
        ids: [
          { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER },

          { type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN },

          { type: process.env.USER_OBJECT_TYPE_CONTACT_INFO },
          { type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS }
        ]
      }
    };

    const { authState: batchUpdateAuthState, claims: batchUpdateClaims, ids: idRecord } = await this.dispatchFetchIdsByUsage(query);

    this.state.batchUpdateAuthState = batchUpdateAuthState;
    this.state.batchUpdateClaims = batchUpdateClaims.slice();
    this.state.idRecord = idRecord;
    [this.state.masterKeyId] = idRecord[process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER];
    [this.state.credentialId] = idRecord[process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN];
    [this.state.inviteeId] = idRecord.userId;

    this.logger.debug({
      masterKeyId: this.state.masterKeyId,
      credentialId: this.state.credentialId,
      inviteeId: this.state.inviteeId
    });
  }

  private createContactInfoValue({ entity }: State['recipientList'][0]): UserObjectContactInfoValue {
    const { accessCode } = this.state;

    return {
      $$formatver: 2,
      firstName: entity.emailAddress!,
      lastName: '',
      role: Constants.ROLE_ID_GUEST,
      emailAddress: entity.emailAddress!,
      healthCardNumber: accessCode
    };
  }

  private async addInsertOperationForUserContactInfo(recipient: State['recipientList'][0]): Promise<void> {
    this.logger.info('Adding an insert operation to request body for contact info.');

    const { auditId, clientId, dtServer, idRecord, inviteeId, masterKeyId: keyId, requestBody } = this.state;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_CONTACT_INFO];
    const value = this.createContactInfoValue(recipient);

    this.logger.debug({ id, ...value });

    const contactInfoObject = await UserObjectContactInfo.create(id, undefined, 1, value, inviteeId, keyId, dtServer);
    const keyList = await contactInfoObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(contactInfoObject[Constants.$$CryptographicKey]);
    requestBody.insert(contactInfoObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addInsertOperationForEmailTokenAndAuditKey(password: string): Promise<void> {
    this.logger.info('Adding an insert operation to request body for email token credentials');

    const {
      auditId,
      credentialHash,
      credentialId,
      dtServer,
      inviteeId,
      masterKeyId: keyId,
      serverParameters,
      sharedParameters,
      requestBody,
      token
    } = this.state;

    const emailTokenCredentials = await UserCredentialsEmailToken.UserRegistration.create(
      credentialId,
      undefined,
      inviteeId,
      keyId,
      credentialHash,
      // @ts-expect-error
      sharedParameters,
      serverParameters,
      0,
      dtServer,
      this.objectDtExpiry
    );
    requestBody.insert(emailTokenCredentials);

    const params: EncryptWithParametersAlgorithmParams = {
      type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN,
      parameter1: token,
      parameter2: password,
      hexSalt: sharedParameters.salt
    };

    const auditKey = await CryptographicKeyAudit.createForCredential(credentialId, params, auditId, dtServer);
    requestBody.insert(auditKey);
  }

  private async addInsertOperationForTemporaryUserKey(password: string) {
    this.logger.info('Adding an insert operation to request body for a temporary user key.');

    const { credentialId, dtServer, masterKeyId: keyId, sharedParameters, token, requestBody } = this.state;

    const { privateKey, exportedPrivateKey, publicKey, exportedPublicKey } = await this.asymmetricKeyAlgo.generateKey();
    this.logger.debug('User private JWK', JSON.stringify(exportedPrivateKey));
    this.logger.debug('User public JWK', JSON.stringify(exportedPublicKey));
    CryptographicKey.setPrivateKey(keyId, privateKey!);
    CryptographicKey.setPublicKey(keyId, publicKey!);

    const params: EncryptWithParametersAlgorithmParams = {
      type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN,
      parameter1: token,
      parameter2: password,
      hexSalt: sharedParameters.salt
    };

    const masterKey = await CryptographicKeyMaster.create(keyId, undefined, 0, exportedPrivateKey!, credentialId, params, dtServer);
    requestBody.insert(masterKey);
  }

  private async addInsertOperationForUserServerRights(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for user server rights.');

    const { auditId, batchUpdateClaims, clientId, dtServer, idRecord, inviteeId, masterKeyId: keyId, requestBody } = this.state;

    const userClaim = this.findClaim(batchUpdateClaims, { name: 'user', userId: this.state.inviteeId });
    if (!Utils.isValidJwtToken(userClaim, 'id')) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'User claim is either missing or invalid.');
    }

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_SERVER_RIGHTS];
    const value: UserObjectServerRightsValue = { $$formatver: 1, userClaim };
    this.logger.debug({ id, ...value });

    const serverRightsObject = await UserObjectServerRights.create(id, undefined, 1, value, inviteeId, keyId, dtServer);
    const keyList = await serverRightsObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(serverRightsObject[Constants.$$CryptographicKey]);
    requestBody.insert(serverRightsObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async sendEmailNotification(): Promise<void> {
    this.logger.info('Sending a one-time link email.');

    const { oneTimeExpire, translation } = this.payload;
    const { activeGroupId, recipientList, cacheTokenList } = this.state;

    const groupContactInfo = await this.getUserObjectValue(selectGroupContactInfo, {
      type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO,
      userId: activeGroupId
    });
    if (Utils.isNil(groupContactInfo)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, `Group contact info is either missing or invalid.`);
    }

    const expireValue = +oneTimeExpire.slice(0, -1);
    const expirePeriod = oneTimeExpire.slice(-1) as OneTimeMessageExpirePeriod;

    const expireLabelI18n = messagingI18n.view.oneTimeMessage.expireLabel[expirePeriod];
    const expireLabelEN = translation.t(expireLabelI18n, { count: expireValue, lng: EnglishCanada });
    const expireLabelFR = translation.t(expireLabelI18n, { count: expireValue, lng: FrenchCanada });

    const personalizations = recipientList
      .filter(isOneTimeContact)
      .map(({ entity }) => {
        if (Utils.isNil(entity.cellNumber) || Utils.isNil(entity.emailAddress)) return undefined!;

        const token = cacheTokenList.find(({ id }) => id === entity.emailAddress);
        if (Utils.isNil(token)) return undefined!;

        return {
          to: [{ name: entity.emailAddress, email: entity.emailAddress }],
          dynamicTemplateData: {
            [EmailTemplateParams.ClinicName]: Utils.stringOrDefault(groupContactInfo.institute?.name, groupContactInfo.groupName),
            [EmailTemplateParams.MessageLink]: `${process.env.HOST_URL}?token=${token.value}`,
            [EmailTemplateParams.MessageValidPeriod]: expireLabelEN,
            [EmailTemplateParams.MessageValidPeriodFr]: expireLabelFR,
            [EmailTemplateParams.PhoneNumberEnd]: entity.cellNumber.slice(-4)
          }
        };
      })
      .filter(Boolean);

    const message: Api.TemplatedEmailMessage = {
      template_id: 'd-7wvhanpmby3esccf2e7dezvx2mqumldz',
      from: { name: 'noreply@sigmail.ca', email: 'noreply@sigmail.ca' },
      personalizations
    };

    this.dispatch(sendTemplatedEmailMessageAction(message));
  }

  private get objectDtExpiry(): Date {
    const { oneTimeExpire } = this.payload;
    const { dtServer } = this.state;

    let dtExpiry = new Date(dtServer.getTime());

    const value = parseInt(oneTimeExpire.slice(0, -1));
    const period = oneTimeExpire.slice(-1) as OneTimeMessageExpirePeriod;

    switch (period) {
      case 'D':
        dtExpiry = addDays(dtExpiry, value);
        break;
      case 'W':
        dtExpiry = addWeeks(dtExpiry, value);
        break;
      case 'M':
        dtExpiry = addMonths(dtExpiry, value);
        break;
      case 'Y':
        dtExpiry = addYears(dtExpiry, value);
        break;
      default:
        break;
    }

    return dtExpiry;
  }

  /** @override */
  protected async sendNewMessageNotifications(): Promise<ReadonlyArray<Promise<void>>> {
    const { recipientList } = this.state;
    this.state.recipientList = recipientList.filter((recipient) => !isOneTimeContact(recipient));

    return await super.sendNewMessageNotifications();
  }
}
