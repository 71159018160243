import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import IconAddress from '../../assets/images/icon-address.min.svg';
import IconCellPhone from '../../assets/images/icon-cell-phone.min.svg';
import IconDoctor from '../../assets/images/icon-doctor.svg';
import IconFax from '../../assets/images/icon-fax.min.svg';
import IconPhone from '../../assets/images/icon-phone.min.svg';
import { ComposeReferralView } from '../../constants/action-context';
import { EnglishCanada, FrenchCanada } from '../../constants/language-codes';

const i18n: MessagingI18n.View.ComposeReferral = {
  // Translators: Title for the Compose Referral view
  title: gettext('messaging', 'title', 'composeReferralView'),
  infoCard: {
    guest: {
      // Translators: Title for the Guest Info Card in Compose Referral view
      title: gettext('messaging', 'title', 'composeReferralViewGuestInfoCard'),
      // Translators: ARIA label for the Close action of Guest Info Card in \
      // Compose Referral view
      ariaLabelClose: gettext('messaging', 'close', 'composeReferralViewGuestInfoCardAriaLabel'),
      // Translators: ARIA label for the Open action of Guest Info Card in \
      // Compose Referral view
      ariaLabelOpen: gettext('messaging', 'open', 'composeReferralViewGuestInfoCardAriaLabel'),
      dataLabel: {
        // Translators: Label for the Full Patient Name data displayed in the \
        // Guest Info Card in Compose Referral view
        name: gettext('messaging', 'name', 'composeReferralViewGuestInfoCardDataLabel'),
        // Translators: Label for the Patient's Birth Date data displayed in \
        // the Guest Info Card in Compose Referral view
        birthDate: gettext('messaging', 'birthDate', 'composeReferralViewGuestInfoCardDataLabel'),
        // Translators: Label for the Patient's Health Plan Number data \
        // displayed in the Guest Info Card in Compose Referral view
        healthPlanNumber: gettext('messaging', 'healthPlanNumber', 'composeReferralViewGuestInfoCardDataLabel')
      },
      dataIcon: {
        cellNumber: {
          // Translators: Label for the Patient's Cell Number data displayed in \
          // the Guest Info Card in Compose Referral view
          alt: gettext('messaging', 'cellNumber', 'composeReferralViewGuestInfoCardDataLabel'),
          src: { [EnglishCanada]: IconCellPhone, [FrenchCanada]: IconCellPhone }
        },
        homeNumber: {
          // Translators: Label for the Patient's Home Number data displayed in \
          // the Guest Info Card in Compose Referral view
          alt: gettext('messaging', 'homeNumber', 'composeReferralViewGuestInfoCardDataLabel'),
          src: { [EnglishCanada]: IconPhone, [FrenchCanada]: IconPhone }
        },
        address: {
          // Translators: Label for the Patient's Address data displayed in the \
          // Guest Info Card in Compose Referral view
          alt: gettext('messaging', 'address', 'composeReferralViewGuestInfoCardDataLabel'),
          src: { [EnglishCanada]: IconAddress, [FrenchCanada]: IconAddress }
        }
      },
      // Translators: Default value displayed for missing data in the Guest \
      // Info Card in Compose Referral view
      notSetValue: gettext('messaging', 'notSetValue', 'composeReferralViewGuestInfoCard')
    },
    referTo: {
      // Translators: Title for the Refer To Info Card in Compose Referral view
      title: gettext('messaging', 'title', 'composeReferralViewReferToInfoCard'),
      // Translators: ARIA label for the Close action of Refer To Info Card in \
      // Compose Referral view
      ariaLabelClose: gettext('messaging', 'close', 'composeReferralViewReferToInfoCardAriaLabel'),
      // Translators: ARIA label for the Open action of Refer To Info Card in \
      // Compose Referral view
      ariaLabelOpen: gettext('messaging', 'open', 'composeReferralViewReferToInfoCardAriaLabel'),
      userDataLabel: {
        // Translators: Label for the Full Physician Name data displayed in \
        // the Refer To Info Card in Compose Referral view
        name: gettext('messaging', 'name', 'composeReferralViewReferToInfoCardDataLabel'),
        // Translators: Label for the Physician's Academic Degrees data \
        // displayed in the Refer To Info Card in Compose Referral view
        degrees: gettext('messaging', 'degrees', 'composeReferralViewReferToInfoCardDataLabel'),
        // Translators: Label for the Physician's Specialty data displayed in \
        // the Refer To Info Card in Compose Referral view
        specialty: gettext('messaging', 'specialty', 'composeReferralViewReferToInfoCardDataLabel')
      },
      userDataIcon: {
        officeNumber: {
          // Translators: Label for the Physician's Office Number data \
          // displayed in the Refer To Info Card in Compose Referral view
          alt: gettext('messaging', 'officeNumber', 'composeReferralViewReferToInfoCardDataLabel'),
          src: { [EnglishCanada]: IconPhone, [FrenchCanada]: IconPhone }
        },
        faxNumber: {
          // Translators: Label for the Physician's Fax Number data displayed in \
          // the Refer To Info Card in Compose Referral view
          alt: gettext('messaging', 'faxNumber', 'composeReferralViewReferToInfoCardDataLabel'),
          src: { [EnglishCanada]: IconFax, [FrenchCanada]: IconFax }
        },
        licenseNumber: {
          // Translators: Label for the Physician's License Number data \
          // displayed in the Refer To Info Card in Compose Referral view
          alt: gettext('messaging', 'licenseNumber', 'composeReferralViewReferToInfoCardDataLabel'),
          src: { [EnglishCanada]: IconDoctor, [FrenchCanada]: IconDoctor }
        },
        address: {
          // Translators: Label for the Physician's Address data displayed in the \
          // Refer To Info Card in Compose Referral view
          alt: gettext('messaging', 'address', 'composeReferralViewReferToInfoCardDataLabel'),
          src: { [EnglishCanada]: IconAddress, [FrenchCanada]: IconAddress }
        }
      },
      groupDataLabel: {
        // Translators: Label for the Group Name data displayed in the Refer \
        // To Info Card in Compose Referral view
        groupName: gettext('messaging', 'groupName', 'composeReferralViewReferToInfoCardDataLabel'),
        // Translators: Label for the Group's Institute Name data displayed in \
        // the Refer To Info Card in Compose Referral view
        instituteName: gettext('messaging', 'instituteName', 'composeReferralViewReferToInfoCardDataLabel')
      },
      groupDataIcon: {
        phoneNumber: {
          // Translators: Label for the Group's Phone Number data displayed \
          // in the Refer To Info Card in Compose Referral view
          alt: gettext('messaging', 'institutePhoneNumber', 'composeReferralViewReferToInfoCardDataLabel'),
          src: { [EnglishCanada]: IconPhone, [FrenchCanada]: IconPhone }
        },
        faxNumber: {
          // Translators: Label for the Group's Fax Number data displayed in \
          // the Refer To Info Card in Compose Referral view
          alt: gettext('messaging', 'instituteFaxNumber', 'composeReferralViewReferToInfoCardDataLabel'),
          src: { [EnglishCanada]: IconFax, [FrenchCanada]: IconFax }
        },
        address: {
          // Translators: Label for the Group's Address data displayed in the \
          // Refer To Info Card in Compose Referral view
          alt: gettext('messaging', 'instituteAddress', 'composeReferralViewReferToInfoCardDataLabel'),
          src: { [EnglishCanada]: IconAddress, [FrenchCanada]: IconAddress }
        }
      },
      // Translators: Default value displayed for missing data in the Refer To \
      // Info Card in Compose Referral view
      notSetValue: gettext('messaging', 'notSetValue', 'composeReferralViewReferToInfoCard')
    }
  },
  action: {
    submit: {
      // Translators: Label for the Submit action in Compose Referral view
      label: gettext('messaging', 'submit', 'composeReferralViewActionLabel'),
      context: ComposeReferralView
    },
    close: {
      // Translators: Label for the Close action in Compose Referral view
      label: gettext('messaging', 'close', 'composeReferralViewActionLabel'),
      context: ComposeReferralView
    },
    attach: {
      // Translators: Label for the Attach Documents action in Compose \
      // Referral view
      label: gettext('messaging', 'attach', 'composeReferralViewActionLabel'),
      context: ComposeReferralView
    }
  }
};

export default i18n;
