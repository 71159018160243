import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectEncounterValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_ENCOUNTER;
const REGEX_NUMERIC_KEY = /^[1-9][0-9]+$/;

/** @public */
export class UserObjectEncounter extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    const { $$formatver, $encounterIndex, ...encounterMap } = dv;

    return (
      Utils.isNonArrayObjectLike($encounterIndex) &&
      Object.keys(encounterMap).every((key) => REGEX_NUMERIC_KEY.test(key) && Utils.isNonArrayObjectLike(dv[+key]))
    );
  }

  public override updateValue(newValue: DV): Promise<UserObjectEncounter> {
    const Class = this.constructor as typeof UserObjectEncounter;
    return Class.updateValue(this, newValue);
  }
}
