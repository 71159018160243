import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: ContactListI18n.View.ContactListView['form']['createGuestContactGroup'] = {
  fieldsetDefault: {
    formField: {
      groupName: {
        // Translators: Label for the Group Name input
        label: gettext('contact-list', 'groupName', 'createGuestContactGroupFormInputLabel'),
        error: {
          // Translators: Error displayed if Group Name field is left blank
          valueMissing: gettext('contact-list', 'groupNameRequired', 'createGuestContactGroupFormError'),
          // Translators: Error displayed if Group Name field doesn't satisfy \
          // either the minimum or maximum length constraint.
          // Token(s) available: MIN_LENGTH, MAX_LENGTH
          length: gettext('contact-list', 'groupNameLength', 'createGuestContactGroupFormError'),
          // Translators: Error displayed if Group Name field contains invalid \
          // characters
          patternMismatch: gettext('contact-list', 'groupNamePattern', 'createGuestContactGroupFormError')
        }
      }
    }
  }
};

export default i18n;
