import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectDocFolderValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_DOC_FOLDER;

/** @public */
export class DataObjectDocFolder extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.data) && (dv.next === null || this.isValidId(dv.next));
  }

  public updateValue(newValue: DV): Promise<DataObjectDocFolder> {
    const Class = this.constructor as typeof DataObjectDocFolder;
    return Class.updateValue(this, newValue);
  }
}
