import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogEMROAuthParamsMissing } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.EMR.OAuthParamsMissing = {
  // Translators: (HTML) Title for the Missing EMR Configuration dialog
  title: gettext('messaging', 'title', 'dialogEMROAuthParamsMissing', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of Missing EMR \
  // Configuration dialog
  body: gettext('messaging', 'body', 'dialogEMROAuthParamsMissing', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Missing EMR \
      // Configuration dialog
      label: gettext('messaging', 'accept', 'dialogEMROAuthParamsMissingActionLabel'),
      context: DialogEMROAuthParamsMissing
    }
  }
};

export default i18n;
