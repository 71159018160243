import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.View.OneTimeMessage.VerifyPhoneNumber = {
  action: {
    submit: {
      label: gettext('messaging', 'submit', 'oneTimeMessageVerifyPhoneNumberViewActionLabel')
    }
  },
  body: gettext('messaging', 'body', 'oneTimeMessageVerifyPhoneNumberView', 'safe-html'),
  title: gettext('messaging', 'title', 'oneTimeMessageVerifyPhoneNumberView', 'safe-html')
};

export default i18n;
