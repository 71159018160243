import type { Nullable } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { KeyDerivationFunction } from '.';
import { E_FAIL } from '../constants';
import * as Encoder from '../encoder';
import { SigmailCryptoException } from '../SigmailCryptoException';

/** @internal */
export interface TrivialKdfParams {
  /** extract bits starting at this location */
  startOffset: number;

  /** Size, in bits, of the output. */
  outLength: number;
}

const DEFAULT_PARAMS: TrivialKdfParams = {
  startOffset: 0,
  outLength: 256
};

/**
 * Defines the primitive TKDF (Trivial Key Derivation Function) to generate random bytes.
 *
 * This extract a length of bytes from an input value
 *
 * @author Kim Birchard <kbirchard@sigmahealthtech.com>
 * @internal
 */
export class TKDF extends KeyDerivationFunction {
  private readonly params: TrivialKdfParams;

  public constructor(params?: Partial<TrivialKdfParams>) {
    super('TKDF');

    this.params = Utils.defaults({}, params, DEFAULT_PARAMS);
  }

  /** @inheritdoc */
  public derive(seed: string | Uint8Array, __UNUSED_version?: Nullable<number | Uint8Array>): Promise<Uint8Array> {
    let encodedSeed: Uint8Array;

    if (Utils.isString(seed)) {
      encodedSeed = Encoder.UTF8.encode(seed);
    } else if (seed instanceof Uint8Array) {
      encodedSeed = seed;
    } else {
      throw new SigmailCryptoException(
        E_FAIL,
        'Value was expected to be of type <string> or <Uint8Array>. (Parameter name: seed)'
      );
    }

    let { startOffset, outLength } = this.params;
    startOffset /= 8; // convert to bytes
    outLength /= 8; // convert to bytes

    if (encodedSeed.length < startOffset + outLength) {
      throw new SigmailCryptoException(
        E_FAIL,
        `Provided seed is too short, ${startOffset} + ${outLength} > ${encodedSeed.length}`
      );
    }

    return Promise.resolve(encodedSeed.slice(startOffset, startOffset + outLength));
  }
}
