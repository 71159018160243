import type {
  AlgorithmCode,
  JsonObject,
  Nullable,
  SigmailGroupId,
  SigmailKeyId,
  SigmailObjectId,
  SigmailUserId
} from '@sigmail/common';
import { AppException, AppUserOrUserGroup, Constants, Utils } from '@sigmail/common';
import { Algorithm } from '@sigmail/crypto';
import { SigmailObject } from '../sigmail-object';
import type {
  ApiFormattedSigmailObject,
  ApiFormattedUserCredentials,
  ISigmailObject,
  IUserCredentials
} from '../types';

/** @public */
export type ServerParamsValueType<T extends UserCredentials<any, any>> = T extends UserCredentials<any, infer DVServer>
  ? DVServer
  : never;

/** @public */
export type SharedParamsValueType<T extends UserCredentials<any, any>> = T extends UserCredentials<infer DVShared, any>
  ? DVShared
  : never;

const PROPS: ReadonlyArray<keyof Omit<IUserCredentials, keyof ISigmailObject<unknown> | 'decryptedValue'>> = [
  'userId',
  'keyId',
  'credentialHash',
  'sharedParameters',
  'serverParameters',
  'createdAtUtc',
  'expiredAtUtc'
];

const API_FORMATTED_PROPS: ReadonlyArray<keyof Omit<ApiFormattedUserCredentials, keyof ApiFormattedSigmailObject>> = [
  'userId',
  'keyId',
  'credentialHash',
  'sharedParameters',
  'serverParameters',
  'createdAtUtc',
  'expiredAtUtc'
];

/**
 * TODO document
 *
 * @typeParam DVShared - Type of shared parameters.
 * @typeParam DVServer - Type of server parameters
 * @public
 */
export abstract class UserCredentials<DVShared = JsonObject, DVServer = JsonObject>
  extends SigmailObject<string>
  implements IUserCredentials<DVShared, DVServer>
{
  protected static override get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT;
  }

  public static override isValidAlgorithmCode(value: unknown): value is AlgorithmCode {
    return Algorithm.isValidNonEncryptedObjectCode(value);
  }

  /** Determines if the given value is a valid key ID. */
  public static isValidKeyId(value: unknown): value is SigmailKeyId {
    return Utils.isInteger(value) && value >= 0;
  }

  /** Determines if the given value is a valid credential hash. */
  public static isValidCredentialHash(value: unknown): value is string {
    return Utils.isString(value);
  }

  /** Determines if the given value is a valid encrypted value. */
  public static isValidEncryptedValue(value: unknown): value is string {
    return Utils.isString(value);
  }

  /** Determines if the given value is a valid shared parameters value. */
  public static isValidSharedParameters(value: unknown): NonNullable<unknown> {
    return Utils.isNonArrayObjectLike(value);
  }

  /** Determines if the given value is a valid server parameters value. */
  public static isValidServerParameters(value: unknown): NonNullable<unknown> {
    return Utils.isNonArrayObjectLike(value);
  }

  /** Determines if the given value is a valid creation date. */
  public static isValidCreationDate(value: unknown): value is Date {
    return Utils.isValidDate(value);
  }

  /**
   * Determines if the given value is a valid expiry date. `null` is considered
   * a valid value.
   */
  public static isValidExpiryDate(value: unknown): value is Date | null {
    return value === null || Utils.isValidDate(value);
  }

  public static override isAssignableFrom(value: unknown): value is IUserCredentials {
    if (!(super.isAssignableFrom(value) && Utils.every(PROPS, Utils.partial(Utils.has, value)))) return false;

    const obj = value as IUserCredentials;
    return (
      AppUserOrUserGroup.isValidId(obj.userId) &&
      this.isValidKeyId(obj.keyId) &&
      this.isValidCredentialHash(obj.credentialHash) &&
      this.isValidSharedParameters(obj.sharedParameters) &&
      this.isValidServerParameters(obj.serverParameters) &&
      this.isValidCreationDate(obj.createdAtUtc) &&
      this.isValidExpiryDate(obj.expiredAtUtc) &&
      typeof obj.decryptedValue === 'function'
    );
  }

  public static override isApiFormatted(value: unknown): value is ApiFormattedUserCredentials {
    if (!(super.isApiFormatted(value) && Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, value)))) {
      return false;
    }

    const obj = value as ApiFormattedUserCredentials;
    return (
      Utils.isNumber(obj.userId) &&
      Utils.isNumber(obj.keyId) &&
      Utils.isString(obj.credentialHash) &&
      Utils.isString(obj.sharedParameters) &&
      Utils.isString(obj.serverParameters) &&
      Utils.isString(obj.createdAtUtc) &&
      (obj.expiredAtUtc === null || Utils.isString(obj.expiredAtUtc))
    );
  }

  public static async create<T extends UserCredentials<any, any>>(
    this: new (...args: Array<any>) => T,
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    userId: SigmailGroupId | SigmailUserId,
    keyId: SigmailKeyId,
    credentialHash: string,
    sharedParameters: SharedParamsValueType<T>,
    serverParameters: ServerParamsValueType<T>,
    _encryptedFor: SigmailKeyId,
    createdAtUtc: Date,
    expiredAtUtc?: Nullable<Date>
  ): Promise<T> {
    const Class = this as unknown as typeof UserCredentials;
    if (Class === UserCredentials) throw new TypeError('Illegal method invocation.');

    const objectCode = Utils.isUndefined(code) ? Class.DEFAULT_CODE : code;
    const serverParams = await Class.encryptObjectValue(serverParameters, { objectCode });
    const sharedParams = await Class.encryptObjectValue(sharedParameters, { objectCode });

    // prettier-ignore
    const args = [id, objectCode, userId, keyId, credentialHash, sharedParams, serverParams, createdAtUtc, expiredAtUtc];
    return Reflect.construct(Class, args) as T;
  }

  public readonly createdAtUtc: Date;
  public readonly credentialHash: string;
  public readonly expiredAtUtc: Date | null;
  public readonly keyId: number;
  public readonly serverParameters: string;
  public readonly sharedParameters: string;
  public readonly userId: number;

  public constructor(
    id: number,
    code: AlgorithmCode | undefined,
    userId: number,
    keyId: number,
    credentialHash: string,
    sharedParameters: string,
    serverParameters: string,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  );

  public constructor(obj: ApiFormattedUserCredentials);

  public constructor(...args: Array<unknown>) {
    super(...(args.length === 1 ? args : [undefined, ...args]));

    const Class = this.constructor as typeof UserCredentials;
    if (Class === UserCredentials) throw new TypeError('Initialization error.');

    const obj = args[0] as ApiFormattedUserCredentials;
    const userId = args.length === 1 ? obj.userId : args[2];
    const keyId = args.length === 1 ? obj.keyId : args[3];
    const credentialHash = args.length === 1 ? obj.credentialHash : args[4];
    const sharedParameters = args.length === 1 ? obj.sharedParameters : args[5];
    const serverParameters = args.length === 1 ? obj.serverParameters : args[6];
    const createdAtUtc = args.length === 1 ? new Date(obj.createdAtUtc) : args[7];
    const expiredAtUtc = args.length === 1 ? obj.expiredAtUtc : (args[8] as Nullable<Date>);
    const expiryDate = Utils.isNil(expiredAtUtc) ? null : new Date(expiredAtUtc);

    if (!AppUserOrUserGroup.isValidId(userId)) throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID);
    if (!Class.isValidKeyId(keyId)) throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Invalid key ID.');
    if (!Class.isValidCredentialHash(credentialHash))
      throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE, 'Invalid credential hash.');
    if (!Class.isValidEncryptedValue(sharedParameters))
      throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE, 'Invalid shared parameters.');
    if (!Class.isValidEncryptedValue(serverParameters))
      throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE, 'Invalid server parameters.');
    if (!Class.isValidCreationDate(createdAtUtc)) throw new AppException(Constants.Error.E_INVALID_CREATION_DATE);
    if (!Class.isValidExpiryDate(expiryDate)) throw new AppException(Constants.Error.E_INVALID_EXPIRY_DATE);

    this.createdAtUtc = createdAtUtc;
    this.credentialHash = credentialHash;
    this.expiredAtUtc = expiryDate;
    this.keyId = keyId;
    this.serverParameters = serverParameters;
    this.sharedParameters = sharedParameters;
    this.userId = userId;
  }

  public override equals(other: unknown): other is IUserCredentials {
    if (!super.equals(other)) return false;

    const obj = other as IUserCredentials;
    return (
      this.credentialHash === obj.credentialHash &&
      this.keyId === obj.keyId &&
      this.serverParameters === obj.serverParameters &&
      this.sharedParameters === obj.sharedParameters &&
      this.userId === obj.userId
    );
  }

  public override hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.userId)) | 0;
    hashed = (31 * hashed + Utils.hashNumber(this.keyId)) | 0;
    hashed = (31 * hashed + Utils.hashString(this.credentialHash)) | 0;
    hashed = (31 * hashed + Utils.hashString(this.sharedParameters)) | 0;
    hashed = (31 * hashed + Utils.hashString(this.serverParameters)) | 0;
    return hashed;
  }

  protected freezeValue(value: DVServer): DVServer;

  protected freezeValue(value: DVShared): DVShared;

  protected freezeValue(value: DVServer | DVShared): DVServer | DVShared;

  protected freezeValue(value: DVServer | DVShared): DVServer | DVShared {
    return Utils.deepFreeze(value);
  }

  public async decryptedValue(which: 'shared'): Promise<DVShared>;

  public async decryptedValue(which: 'server'): Promise<DVServer>;

  public async decryptedValue(...args: Array<unknown>): Promise<DVShared | DVServer> {
    const which = args.length > 0 ? (args[0] as string) : '';
    if (which !== 'shared' && which !== 'server') {
      throw new AppException(Constants.Error.S_ERROR, 'Missing or invalid argument(s).');
    }

    const Class = this.constructor as typeof UserCredentials;
    let value = (await Class.decryptObjectValue<unknown>(
      which === 'shared' ? this.sharedParameters : this.serverParameters,
      { objectCode: this.code }
    )) as DVServer | DVShared;

    const isValid =
      (which === 'shared' && Class.isValidSharedParameters(value)) ||
      (which === 'server' && Class.isValidServerParameters(value));

    if (!isValid) {
      const errorMessage = [
        'Decrypted value failed the validation check.',
        `type=${this.type}`,
        `id=${this.id}`,
        `code=${this.code}`
      ];

      // eslint-disable-next-line no-console
      console.warn(errorMessage.concat('value=').join('\n'), value);

      throw new AppException(
        Constants.Error.E_INVALID_OBJECT_VALUE,
        `${errorMessage[0]} (${errorMessage.slice(1).join(', ')})`
      );
    }

    value = this.freezeValue(value);
    return value;
  }

  public override toApiFormatted(): ApiFormattedUserCredentials {
    const { code, id, type } = super.toApiFormatted();

    return {
      code,
      createdAtUtc: this.createdAtUtc.toISOString(),
      credentialHash: this.credentialHash,
      expiredAtUtc: Utils.stringOrDefault(this.expiredAtUtc?.toISOString(), null!),
      id,
      keyId: this.keyId,
      serverParameters: this.serverParameters,
      sharedParameters: this.sharedParameters,
      type,
      userId: this.userId
    };
  }
}
