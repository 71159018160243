import type { PayloadAction } from '@reduxjs/toolkit';
import type { IPushNotificationBuilder, NewMsgNotify, PayloadNewMsgNotify } from './types';

export class NewMsgPushNotificationBuilder implements IPushNotificationBuilder<NewMsgNotify, PayloadNewMsgNotify> {
  private readonly payload: PayloadNewMsgNotify = { title: '' };

  public build(): PayloadAction<PayloadNewMsgNotify, NewMsgNotify> {
    return { payload: this.payload, type: 'NEW_MSG_NOTIFY' };
  }

  public set<T extends keyof PayloadNewMsgNotify>(key: T, value: PayloadNewMsgNotify[T]): this {
    this.payload[key] = value;
    return this;
  }
}
