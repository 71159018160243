const ENCODER = new TextEncoder();
const DECODER = new TextDecoder(undefined, { fatal: true });

/** @public */
export function decode(value?: Uint8Array): string {
  return DECODER.decode(value);
}

/** @public */
export function encode(value?: string | undefined): Uint8Array {
  return ENCODER.encode(value);
}
