import { GroupActionPayload } from '@sigmail/app-state';
import React from 'react';
import { respondToJoinGroupInvitationAction } from '../../../../app-state/actions/groups/respond-to-join-group-invitation-action';
import { useAppState } from '../../../../app-state/hooks';
import { ActionData } from '../../types';
import { BaseContextValue, OpenedMessageViewState } from '../context';

export interface ActionDataRespondToJoinGroupInvitation
  extends ActionData,
    Pick<GroupActionPayload.RespondToJoinCircleOfCareGroupInvitation, 'response'> {
  currentFolder: BaseContextValue['currentFolder'];
  sourceMessage?: Pick<OpenedMessageViewState['openedMessage'], 'body' | 'header'> | undefined;
}

export const useRespondToJoinGroupInvitationActionHandler = () => {
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({
      currentFolder,
      failCallback,
      response,
      sourceMessage,
      successCallback
    }: ActionDataRespondToJoinGroupInvitation) => {
      let value: any;

      try {
        value = await appDispatch(
          respondToJoinGroupInvitationAction({
            folderKey: currentFolder.folderKey,
            msgBodyId: sourceMessage?.body!,
            msgMetadataId: sourceMessage?.header!,
            parentFolderKey: currentFolder.parentFolderKey,
            response
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch]
  );
};
