import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.View.Activity = {
  // Translators: Label for the Activity View under My Account
  label: gettext('account', 'activity', 'accountViewLabel'),
  timePeriodLabel: {
    consultationChart: {
      // Translators: Label for <Last Month> Time Period of Consultation chart \
      // in Activity View under My Account
      lastMonth: gettext('account', 'lastMonth', 'consultationChartTimePeriodLabel'),
      // Translators: Label for <Last Week> Time Period of Consultation chart \
      // in Activity View under My Account
      lastWeek: gettext('account', 'lastWeek', 'consultationChartTimePeriodLabel'),
      // Translators: Label for <This Month> Time Period of Consultation chart \
      // in Activity View under My Account
      thisMonth: gettext('account', 'thisMonth', 'consultationChartTimePeriodLabel'),
      // Translators: Label for <This Week> Time Period of Consultation chart \
      // in Activity View under My Account
      thisWeek: gettext('account', 'thisWeek', 'consultationChartTimePeriodLabel'),
      // Translators: Label for <This Year> Time Period of Consultation chart \
      // in Activity View under My Account
      thisYear: gettext('account', 'thisYear', 'consultationChartTimePeriodLabel')
    }
  }
};

export default i18n;
