/* eslint-disable no-unused-vars */

import type { AlgorithmCode, SigmailObjectId, SigmailObjectTypeCode } from '@sigmail/common';
import { AppException, Constants, Utils } from '@sigmail/common';
import { SigmailObject } from './sigmail-object';
import type {
  ApiFormattedSigmailObject,
  ApiFormattedVersionedSigmailObject,
  ISigmailObject,
  IVersionedSigmailObject
} from './types';

const PROPS: ReadonlyArray<keyof Omit<IVersionedSigmailObject<unknown>, keyof ISigmailObject<unknown>>> = ['version'];

const API_FORMATTED_PROPS: ReadonlyArray<
  keyof Omit<ApiFormattedVersionedSigmailObject, keyof ApiFormattedSigmailObject>
> = ['version'];

/**
 * TODO document
 *
 * @typeParam DV - Type of decrypted value
 * @public
 */
export abstract class VersionedSigmailObject<DV> extends SigmailObject<DV> implements IVersionedSigmailObject<DV> {
  /** Determines if the given value is a valid version. */
  public static isValidVersion(value: unknown): value is number {
    return Utils.isInteger(value) && value >= 0 && value <= Constants.MAX_VALUE_OBJECT_VERSION;
  }

  public static override isAssignableFrom(value: unknown): value is IVersionedSigmailObject<unknown> {
    if (!(super.isAssignableFrom(value) && Utils.every(PROPS, Utils.partial(Utils.has, value)))) return false;

    const obj = value as IVersionedSigmailObject<unknown>;
    return this.isValidVersion(obj.version);
  }

  public static override isApiFormatted(value: unknown): value is ApiFormattedVersionedSigmailObject {
    if (!(super.isApiFormatted(value) && Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, value)))) {
      return false;
    }

    const obj = value as ApiFormattedVersionedSigmailObject;
    return Utils.isNumber(obj.version);
  }

  public readonly version: number;

  /**
   * Initializes a new instance of the `SigmailObject` class.
   *
   * @param type - Type code of this object, or `undefined` to use the default.
   * @param id - ID of this object.
   * @param code - Code identifying the algorithm used to encrypt the value of
   * this object, or `undefined` to use the default code defined for this type.
   * @param version - Version of this object.
   *
   * @throws {@link AppException} if one or more of the arguments are invalid.
   */
  protected constructor(
    type: SigmailObjectTypeCode | undefined,
    id: SigmailObjectId,
    code: AlgorithmCode | undefined,
    version: number
  );

  protected constructor(obj: ApiFormattedVersionedSigmailObject);

  protected constructor(...args: Array<unknown>);

  protected constructor(...args: Array<unknown>) {
    super(...args);

    const Class = this.constructor as typeof VersionedSigmailObject;
    if (Class === VersionedSigmailObject) throw new TypeError('Initialization error.');

    const version = args.length === 1 ? (args[0] as Record<string, unknown>)['version'] : args[3];
    if (!Class.isValidVersion(version)) throw new AppException(Constants.Error.E_INVALID_OBJECT_VERSION);

    this.version = version;
  }

  public override equals(other: unknown): other is IVersionedSigmailObject<DV> {
    return super.equals(other) && this.version === (other as IVersionedSigmailObject<unknown>).version;
  }

  public override hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.version)) | 0;
    return hashed;
  }

  public override toApiFormatted(): ApiFormattedVersionedSigmailObject {
    const apiFormatted = super.toApiFormatted();
    return { ...apiFormatted, version: this.version };
  }
}
