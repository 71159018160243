import { Utils } from '@sigmail/common';
import { RouteProps } from 'sigmail';
import * as ActionId from '../constants/action-ids';
import * as RouteId from '../constants/route-identifiers';

const BASE_PATH = '/';
const PATH_SIGN_IN = `${BASE_PATH}login/`;
const PATH_CHANGE_USERNAME = `${BASE_PATH}username/change/`;
const PATH_RESET_PASSWORD = `${BASE_PATH}password/reset/`;
const PATH_TERMS_AND_CONDITIONS = `${BASE_PATH}conditions/`;
const PATH_PRIVACY_POLICY = `${BASE_PATH}privacy/`;
const PATH_FAQ = `${BASE_PATH}faq/`;
const PATH_CONTACT_US = `${BASE_PATH}contact/`;

const BASE_PATH_ACCOUNT = `${BASE_PATH}account/`;
const PATH_ACCOUNT_MANAGE_CAREGIVERS = `${BASE_PATH_ACCOUNT}caregivers/`;
const PATH_ACCOUNT_MANAGE_CARE_PLANS = `${BASE_PATH_ACCOUNT}careplans/`;
const PATH_ACCOUNT_MANAGE_GROUPS = `${BASE_PATH_ACCOUNT}groups/`;
const PATH_ACCOUNT_MANAGE_MEMBERS = `${BASE_PATH_ACCOUNT}members/`;
const PATH_ACCOUNT_MANAGE_SELF = `${BASE_PATH_ACCOUNT}profile/`;
const PATH_ACCOUNT_BULK_INVITE_GUESTS = `${BASE_PATH_ACCOUNT}members/bulk-invite/guest/`;

const PATH_ACCOUNT_SETUP = `${BASE_PATH_ACCOUNT}setup/`;
const PATH_ACCOUNT_CAREGIVER_SETUP = `${BASE_PATH_ACCOUNT}caregiver/setup/`;

const BASE_PATH_MAIL = `${BASE_PATH}mail/`;
const PATH_MAIL_INBOX = `${BASE_PATH_MAIL}inbox/`;
const PATH_MAIL_INBOX_SHARED = `${BASE_PATH_MAIL}shared/`;
const PATH_MAIL_REMINDER = `${BASE_PATH_MAIL}reminder/`;
const PATH_MAIL_CONSULTATION = `${BASE_PATH_MAIL}consultation/`;
const PATH_MAIL_IMPORTANT = `${BASE_PATH_MAIL}important/`;
const PATH_MAIL_IMPORTANT_SHARED = `${BASE_PATH_MAIL}shared/important/`;
const PATH_MAIL_REFERRALS = `${BASE_PATH_MAIL}referrals/`;
const PATH_MAIL_REFERRALS_SHARED = `${BASE_PATH_MAIL}shared/referrals/`;
const PATH_MAIL_ARCHIVED = `${BASE_PATH_MAIL}archived/`;
const PATH_MAIL_ARCHIVED_SHARED = `${BASE_PATH_MAIL}shared/archived/`;
const PATH_MAIL_TRASH = `${BASE_PATH_MAIL}trash/`;
const PATH_MAIL_TRASH_SHARED = `${BASE_PATH_MAIL}shared/trash/`;
const PATH_MAIL_SECURE = `${BASE_PATH_MAIL}secure/`;
const PATH_MAIL_SENT = `${BASE_PATH_MAIL}sent/`;
const PATH_MAIL_DRAFTS = `${BASE_PATH_MAIL}drafts/`;
const PATH_MAIL_USER_DEFINED_FOLDER = `${BASE_PATH_MAIL}u/:folderId/:subFolderId/`;

const BASE_PATH_SCHEDULE = `${BASE_PATH}schedule/`;
const PATH_SCHEDULE_OWN = `${BASE_PATH_SCHEDULE}my/`;
const PATH_SCHEDULE_GROUP = `${BASE_PATH_SCHEDULE}group/`;

const BASE_PATH_CONTACT_LIST = `${BASE_PATH}contacts/`;
const BASE_PATH_CONTACT_LIST_GLOBAL = `${BASE_PATH_CONTACT_LIST}global/`;
const PATH_CONTACT_LIST_GLOBAL_USERS = `${BASE_PATH_CONTACT_LIST_GLOBAL}users`;
const PATH_CONTACT_LIST_GLOBAL_GROUPS = `${BASE_PATH_CONTACT_LIST_GLOBAL}groups`;
const PATH_CONTACT_LIST_CLIENT = `${BASE_PATH_CONTACT_LIST}client/`;
const PATH_CONTACT_LIST_GUEST = `${BASE_PATH_CONTACT_LIST}guest/`;

export const ROUTES: ReadonlyArray<RouteProps> = [
  /* HOME PAGE *****************************************************************
   */
  {
    routeId: RouteId.ROUTE_APP,
    path: BASE_PATH,
    exact: false,
    childRoutes: [
      { routeId: RouteId.ROUTE_APP_ROOT, path: BASE_PATH, exact: true },
      {
        routeId: RouteId.ROUTE_SIGN_IN,
        actionId: ActionId.SignIn,
        path: PATH_SIGN_IN,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_CHANGE_USERNAME,
        actionId: ActionId.ChangeUsername,
        path: PATH_CHANGE_USERNAME,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_RESET_PASSWORD,
        actionId: ActionId.ResetPassword,
        path: PATH_RESET_PASSWORD,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_TERMS_AND_CONDITIONS,
        actionId: ActionId.TermsAndConditions,
        path: PATH_TERMS_AND_CONDITIONS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_PRIVACY_POLICY,
        actionId: ActionId.PrivacyPolicy,
        path: PATH_PRIVACY_POLICY,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_FAQ,
        actionId: ActionId.FAQ,
        path: PATH_FAQ,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_CONTACT_US,
        actionId: ActionId.ContactUs,
        path: PATH_CONTACT_US,
        exact: true
      }
    ]
  },

  /* ACCOUNT *******************************************************************
   */
  {
    routeId: RouteId.ROUTE_ACCOUNT,
    path: BASE_PATH_ACCOUNT,
    exact: false,
    childRoutes: [
      { routeId: RouteId.ROUTE_ACCOUNT_ROOT, path: BASE_PATH_ACCOUNT, exact: true },
      {
        routeId: RouteId.ROUTE_ACCOUNT_MANAGE_SELF,
        actionId: ActionId.AccountManageSelf,
        path: PATH_ACCOUNT_MANAGE_SELF,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_ACCOUNT_MANAGE_CAREGIVERS,
        actionId: ActionId.AccountManageCaregivers,
        path: PATH_ACCOUNT_MANAGE_CAREGIVERS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_ACCOUNT_MANAGE_CARE_PLANS,
        actionId: ActionId.AccountManageCarePlans,
        path: PATH_ACCOUNT_MANAGE_CARE_PLANS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_ACCOUNT_MANAGE_MEMBERS,
        actionId: ActionId.AccountManageMembers,
        path: PATH_ACCOUNT_MANAGE_MEMBERS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_ACCOUNT_MANAGE_GROUPS,
        actionId: ActionId.AccountManageGroups,
        path: PATH_ACCOUNT_MANAGE_GROUPS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_ACCOUNT_BULK_INVITE_GUESTS,
        actionId: ActionId.BulkInviteGuests,
        path: PATH_ACCOUNT_BULK_INVITE_GUESTS,
        exact: true
      },

      /* ACCOUNT SETUP *********************************************************
       */
      {
        routeId: RouteId.ROUTE_ACCOUNT_SETUP,
        path: PATH_ACCOUNT_SETUP,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_ACCOUNT_CAREGIVER_SETUP,
        path: PATH_ACCOUNT_CAREGIVER_SETUP,
        exact: true
      }
    ]
  },

  /* MESSAGING PAGES ***********************************************************
   */
  {
    routeId: RouteId.ROUTE_MAIL,
    path: BASE_PATH_MAIL,
    exact: false,
    childRoutes: [
      { routeId: RouteId.ROUTE_MAIL_ROOT, path: BASE_PATH_MAIL, exact: true },

      {
        routeId: RouteId.ROUTE_MAIL_ARCHIVED,
        actionId: ActionId.ArchivedMessages,
        path: PATH_MAIL_ARCHIVED,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_ARCHIVED_SHARED,
        actionId: ActionId.ArchivedMessagesShared,
        path: PATH_MAIL_ARCHIVED_SHARED,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_CONSULTATION,
        actionId: ActionId.ConsultationMessages,
        path: PATH_MAIL_CONSULTATION,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_DRAFTS,
        actionId: ActionId.DraftMessages,
        path: PATH_MAIL_DRAFTS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_IMPORTANT,
        actionId: ActionId.ImportantMessages,
        path: PATH_MAIL_IMPORTANT,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_IMPORTANT_SHARED,
        actionId: ActionId.ImportantMessagesShared,
        path: PATH_MAIL_IMPORTANT_SHARED,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_INBOX,
        actionId: ActionId.ReceivedMessages,
        path: PATH_MAIL_INBOX,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_INBOX_SHARED,
        actionId: ActionId.ReceivedMessagesShared,
        path: PATH_MAIL_INBOX_SHARED,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_REMINDER,
        actionId: ActionId.ReminderMessages,
        path: PATH_MAIL_REMINDER,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_REFERRALS,
        actionId: ActionId.ReferralMessages,
        path: PATH_MAIL_REFERRALS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_REFERRALS_SHARED,
        actionId: ActionId.ReferralMessagesShared,
        path: PATH_MAIL_REFERRALS_SHARED,
        exact: true
      },
      { routeId: RouteId.ROUTE_MAIL_SECURE, path: PATH_MAIL_SECURE, exact: true },
      {
        routeId: RouteId.ROUTE_MAIL_SENT,
        actionId: ActionId.SentMessages,
        path: PATH_MAIL_SENT,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_TRASH,
        actionId: ActionId.DeletedMessages,
        path: PATH_MAIL_TRASH,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_TRASH_SHARED,
        actionId: ActionId.DeletedMessagesShared,
        path: PATH_MAIL_TRASH_SHARED,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_MAIL_USER_DEFINED_FOLDER,
        path: PATH_MAIL_USER_DEFINED_FOLDER,
        exact: true
      }
    ]
  },

  /* SCHEDULING PAGES ************************************************************
   */
  {
    routeId: RouteId.ROUTE_SCHEDULE,
    path: BASE_PATH_SCHEDULE,
    exact: false,
    childRoutes: [
      { routeId: RouteId.ROUTE_SCHEDULE_ROOT, path: BASE_PATH_SCHEDULE, exact: true },
      {
        routeId: RouteId.ROUTE_SCHEDULE_OWN,
        actionId: ActionId.ScheduleOwn,
        path: PATH_SCHEDULE_OWN,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_SCHEDULE_GROUP,
        actionId: ActionId.ScheduleGroup,
        path: PATH_SCHEDULE_GROUP,
        exact: true
      }
    ]
  },

  /* CONTACT LIST PAGES ********************************************************
   */
  {
    routeId: RouteId.ROUTE_CONTACT_LIST,
    path: BASE_PATH_CONTACT_LIST,
    exact: false,
    childRoutes: [
      { routeId: RouteId.ROUTE_CONTACT_LIST_ROOT, path: BASE_PATH_CONTACT_LIST, exact: true },
      {
        routeId: RouteId.ROUTE_CONTACT_LIST_GLOBAL_USERS,
        actionId: ActionId.ContactListGlobalUsers,
        path: PATH_CONTACT_LIST_GLOBAL_USERS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_CONTACT_LIST_GLOBAL_GROUPS,
        actionId: ActionId.ContactListGlobalGroups,
        path: PATH_CONTACT_LIST_GLOBAL_GROUPS,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_CONTACT_LIST_CLIENT,
        actionId: ActionId.ContactListClient,
        path: PATH_CONTACT_LIST_CLIENT,
        exact: true
      },
      {
        routeId: RouteId.ROUTE_CONTACT_LIST_GUEST,
        actionId: ActionId.ContactListGroupGuest,
        path: PATH_CONTACT_LIST_GUEST,
        exact: true
      }
    ]
  }
];

export function getRouteProps(routeId: string, ...args: any[]): RouteProps | undefined {
  let childRouteId = routeId;

  let [parentRouteId, routeList] = args as [string, ReadonlyArray<RouteProps>];
  if (!Utils.isString(parentRouteId)) parentRouteId = '';
  if (!Utils.isArray(routeList)) routeList = ROUTES;

  const indexOfPathSeparator = childRouteId.indexOf('/');
  if (indexOfPathSeparator > 0 && indexOfPathSeparator !== childRouteId.length - 1) {
    parentRouteId =
      ((parentRouteId.length > 0 && parentRouteId + '/') || '') + childRouteId.substring(0, indexOfPathSeparator);

    const parentRoute = routeList.find((route) => route.routeId === parentRouteId);
    if (Utils.isNotNil(parentRoute) && Utils.isArray<RouteProps>(parentRoute.childRoutes)) {
      routeList = parentRoute.childRoutes;
      childRouteId = childRouteId.substring(indexOfPathSeparator + 1);
      return getRouteProps(childRouteId, parentRouteId, routeList);
    }

    return undefined;
  }

  if (parentRouteId.length > 0) {
    childRouteId = parentRouteId + '/' + childRouteId;
  }

  return routeList.find((route) => route.routeId === childRouteId);
}

export function getRoutePath(routeId: string): string {
  const route = getRouteProps(routeId);
  if (Utils.isNonArrayObjectLike<RouteProps>(route)) {
    const { path } = route;
    if (Utils.isString(path)) return path;
    if (Utils.isArray<string>(path) && Utils.isString(path[0])) return path[0];
  }
  return '';
}
