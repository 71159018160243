import { encodeBase64 } from 'bcryptjs';
import { isString } from '.';
import { bcryptHash } from './bcrypt-hash';
import { generateSalt, PREFIX as BCRYPT_SALT_PREFIX } from './generate-salt';
import { uint8ArrayFromHex } from './uint8-array-from-hex';

interface GeneratePasswordHashReturnType {
  hexSalt: string;
  passwordHash: string;
}

export async function generatePasswordHash(
  password: string,
  hexSalt?: string
): Promise<GeneratePasswordHashReturnType> {
  if (!isString(password)) {
    throw new TypeError('Argument <password> must be of type string.');
  }

  // generate salt (if required)
  const hexEncodedSalt = isString(hexSalt) ? hexSalt : generateSalt('hex');

  // generate password hash
  const b64Salt = encodeBase64(uint8ArrayFromHex(hexEncodedSalt), 16);
  const passwordHash = await bcryptHash(password, BCRYPT_SALT_PREFIX.concat(b64Salt));

  return { hexSalt: hexEncodedSalt, passwordHash };
}
