import { createSelector } from '@reduxjs/toolkit';
import { StoreStateUserObjects as StoreStateClientObjects } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import {
  ClientObjectCollectionListValue,
  ClientObjectConfigValue,
  ClientObjectContactListValue,
  ClientObjectProfileValue,
  ClientObjectUserListValue,
  IUserObject as IClientObject,
  UserObjectEventLogValue as ClientObjectEventLogValue,
  ValueFormatVersion
} from '@sigmail/objects';
import { defaultMemoize } from 'reselect';
import { CLIENT_OBJECT_TYPE_CODE_LIST } from '../constants';
import { RootState } from '../root-reducer';
import { UserObjectCache as ClientObjectCache } from '../user-objects-slice/cache';
import { clientIdSelector } from './user-object';

const TYPE_CODE_LIST: ReadonlyArray<string> = CLIENT_OBJECT_TYPE_CODE_LIST.map(String);

const clientObjectMapSelector = createSelector<RootState, StoreStateClientObjects, StoreStateClientObjects>(
  (state) => state.userObjects,
  (userObjectMap) => userObjectMap.filter((_, key) => TYPE_CODE_LIST.includes(key))
);

const createClientObjectsByTypeSelector = createSelector(clientObjectMapSelector, (clientObjectMap) =>
  defaultMemoize((objectType: number) => clientObjectMap.get(objectType.toString(10)))
);

const createClientObjectSelector = <DV extends ValueFormatVersion>(objectType: number) => {
  return createSelector(
    (state) => createClientObjectsByTypeSelector(state)(objectType),
    clientIdSelector,
    (clientIdMap, currentClientId) =>
      Utils.memoize((clientId?: number | undefined): IClientObject<DV> | undefined => {
        const key = Utils.isInteger(clientId) ? clientId : currentClientId;
        if (!Utils.isInteger(key)) return undefined;

        const objectId = clientIdMap?.get(key.toString())?.keySeq().first();
        return ClientObjectCache.find<IClientObject<DV>>(Number(objectId))?.[0];
      })
  );
};

export const profileObjectSelector = createClientObjectSelector<ClientObjectProfileValue>(
  process.env.CLIENT_OBJECT_TYPE_PROFILE
);

export const configurationObjectSelector = createClientObjectSelector<ClientObjectConfigValue>(
  process.env.CLIENT_OBJECT_TYPE_CONFIGURATION
);

export const contactListObjectSelector = createClientObjectSelector<ClientObjectContactListValue>(
  process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST
);

export const userListObjectSelector = createClientObjectSelector<ClientObjectUserListValue>(
  process.env.CLIENT_OBJECT_TYPE_USER_LIST
);

export const collectionListObjectSelector = createClientObjectSelector<ClientObjectCollectionListValue>(
  process.env.CLIENT_OBJECT_TYPE_COLLECTION_LIST
);

export const eventLogObjectSelector = createClientObjectSelector<ClientObjectEventLogValue>(
  process.env.CLIENT_OBJECT_TYPE_EVENT_LOG
);

/**
 * Selector to extract the global contact list ID associated with the client
 * the currently logged-in user is a member of.
 */
export const globalContactListIdSelector = createSelector(configurationObjectSelector, (configurationObjectSelector):
  | number
  | undefined => {
  const clientConfigObject = configurationObjectSelector();
  return ClientObjectCache.getValue(clientConfigObject)?.globalContactListId;
});

type ClientLicenseCounts = {
  pro: { max: number; active: number; pending: number; inactive: number };
  standard: { max: number; active: number; pending: number; inactive: number };
  guest: { max: number; active: number; pending: number; inactive: number };
};

export const licenseCountsSelector = createSelector(
  configurationObjectSelector,
  userListObjectSelector,
  (clientConfigSelector, clientUserListSelector): ClientLicenseCounts => {
    const licenseCounts: ClientLicenseCounts = {
      pro: { max: Infinity, active: 0, pending: 0, inactive: 0 },
      standard: { max: Infinity, active: 0, pending: 0, inactive: 0 },
      guest: { max: Infinity, active: 0, pending: 0, inactive: 0 }
    };

    const clientConfigObject = clientConfigSelector();
    const { licenses } = ClientObjectCache.getValue(clientConfigObject, {} as ClientObjectConfigValue);

    if (Utils.isNonArrayObjectLike<typeof licenses>(licenses)) {
      const { pro, standard, guest } = licenses;

      if (Utils.isInteger(pro) && pro >= 0) licenseCounts.pro.max = pro;
      if (Utils.isInteger(standard) && standard >= 0) licenseCounts.standard.max = standard;
      if (Utils.isInteger(guest) && guest >= 0) licenseCounts.guest.max = guest;
    }

    const clientUserListObject = clientUserListSelector();
    const { active, pending, inactive } = ClientObjectCache.getValue(
      clientUserListObject,
      {} as ClientObjectUserListValue
    );

    Utils.transform(
      { active, pending, inactive },
      (counts, list, key: Exclude<keyof ClientLicenseCounts['pro'], 'max'>) => {
        if (Utils.isArray<typeof list[0]>(list)) {
          list.forEach(({ role: roleId }) => {
            const licenseType = Utils.getLicenseTypeFromRole(roleId);
            if (Utils.isProLicense(licenseType)) {
              ++counts.pro[key];
            } else if (Utils.isStandardLicense(licenseType)) {
              ++counts.standard[key];
            } else if (Utils.isGuestLicense(licenseType)) {
              ++counts.guest[key];
            }
          });
        }
      },
      licenseCounts
    );

    return licenseCounts;
  }
);
