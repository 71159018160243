import { Nullable, ReadonlyPartialRecord, ReadonlyRecord, Utils } from '@sigmail/common';
import { HealthDataI18n } from '@sigmail/i18n';
import {
  KCCQFormData,
  KCCQPhysicalLimitationData,
  KCCQQualityOfLifeData,
  KCCQSocialLimitationData,
  KCCQSymptomFrequencyData,
  UserObjectHealthDataValue
} from '@sigmail/objects';
import { endOfDay } from 'date-fns';
import { WithT } from 'i18next';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import { DataFormCssClassPrefix, DataFormNameKCCQ } from '../../../app/health-data/constants';
import {
  FIELD_SET_LIST as KCCQ_FIELD_SET_LIST,
  FormValues
} from '../../../app/health-data/forms/cardiomyopathy-questionnaire.component';
import { MAX_SAFE_TIMESTAMP, MIN_SAFE_TIMESTAMP } from '../../../constants';
import { EnglishCanada } from '../../../constants/language-codes';
import healthDataI18n from '../../../i18n/health-data';
import { dateToUtcValues } from '../../date-to-utc-values';
import { avg } from '../../math';

export interface ChartDataParams {
  readonly extent?: Readonly<[number, number]>;
  readonly timestampList?: ReadonlyArray<number>;
}

export type DomainScoresChartData = Record<keyof KCCQFormData, Array<{ score: number; ts: number }>>;

type HealthData = NonNullable<UserObjectHealthDataValue['kccq']>;

export type QuestionnaireChartData = Record<
  | KCCQFormData['pl'][0]['activity']
  | KCCQFormData['ql'][0]['question']
  | KCCQFormData['sf'][0]['symptom']
  | KCCQFormData['sl'][0]['activity'],
  Array<{ codedValue: number; ts: number }>
>;

export type Score = Record<keyof KCCQFormData | 'summary', number>;

export type SummaryScoreChartData = ReadonlyArray<ReadonlyRecord<'score' | 'ts', number>>;

const CHART_TYPE_DOMAIN: Extract<HealthDataI18n.KCCQChartType, 'domain'> = 'domain';
const CHART_TYPE_SUMMARY: Extract<HealthDataI18n.KCCQChartType, 'summary'> = 'summary';
const CHART_TYPE_QUESTIONNAIRE: Extract<HealthDataI18n.KCCQChartType, 'questionnaire'> = 'questionnaire';

export const NULL_DOMAIN_SCORES_CHART_DATA: {
  readonly [K in keyof DomainScoresChartData]: ReadonlyArray<Readonly<DomainScoresChartData[K][0]>>;
} = {
  pl: [],
  ql: [],
  sf: [],
  sl: []
};

export const NULL_QUESTIONNAIRE_DATA: {
  readonly [K in keyof QuestionnaireChartData]: ReadonlyArray<Readonly<QuestionnaireChartData[K][0]>>;
} = {
  jog: [],
  shower: [],
  walk: [],
  enjoymentLoss: [],
  chronicity: [],
  orthopnea: [],
  swelling: [],
  dyspnea: [],
  fatigue: [],
  household: [],
  rec: [],
  social: []
};

const PL_KEY_LIST: ReadonlyArray<KCCQPhysicalLimitationData['activity']> = ['jog', 'shower', 'walk'];
const QL_KEY_LIST: ReadonlyArray<KCCQQualityOfLifeData['question']> = ['chronicity', 'enjoymentLoss'];
const SF_KEY_LIST: ReadonlyArray<KCCQSymptomFrequencyData['symptom']> = ['dyspnea', 'fatigue', 'orthopnea', 'swelling'];
const SL_KEY_LIST: ReadonlyArray<KCCQSocialLimitationData['activity']> = ['household', 'rec', 'social'];

const calculatePLScore = (data: KCCQFormData['pl']): number => {
  const rescaled = Utils.filterMap(data, ({ codedValue }) => codedValue !== 6 && 100 * ((codedValue - 1) / 4));
  return rescaled.length > 2 ? Math.round(avg(rescaled)) : 0;
};

const calculateQLScore = (data: KCCQFormData['ql']): number => {
  const rescaled = data.map(({ codedValue }) => 100 * ((codedValue - 1) / 4));
  return rescaled.length > 1 ? Math.round(avg(rescaled)) : 0;
};

const calculateSFScore = (data: KCCQFormData['sf']): number => {
  const rescaled = data.map((entry) => {
    if (entry.symptom === 'swelling' || entry.symptom === 'orthopnea') {
      return 100 * ((entry.codedValue - 1) / 4);
    }

    // prettier-ignore
    switch (entry.codedValue) {
      case 2: return 16.667;
      case 3: return 33.333;
      case 4: return 50;
      case 5: return 66.667;
      case 6: return 83.333;
      case 7: return 100;
      default: return 0;
    }
  });

  return rescaled.length > 2 ? Math.round(avg(rescaled)) : 0;
};

const calculateSLScore = (data: KCCQFormData['sl']): number => {
  const rescaled = Utils.filterMap(data, ({ codedValue }) => codedValue !== 6 && 100 * ((codedValue - 1) / 4));
  return rescaled.length > 2 ? Math.round(avg(rescaled)) : 0;
};

export class KCCQDataUtil {
  private readonly data: KCCQFormData | undefined;

  public constructor(data?: Nullable<KCCQFormData>) {
    if (Utils.isNil(data)) return;

    const Class = this.constructor as typeof KCCQDataUtil;
    if (!Class.isValid(data)) throw new TypeError('Invalid KCCQ data.');

    this.data = data;
  }

  public static buildChartData(
    chartType: typeof CHART_TYPE_DOMAIN,
    data?: UserObjectHealthDataValue,
    params?: ReadonlyPartialRecord<'from' | 'to', Date>
  ): DomainScoresChartData;

  public static buildChartData(
    chartType: typeof CHART_TYPE_SUMMARY,
    data?: UserObjectHealthDataValue,
    params?: ReadonlyPartialRecord<'from' | 'to', Date>
  ): SummaryScoreChartData;

  public static buildChartData(
    chartType: typeof CHART_TYPE_QUESTIONNAIRE,
    data?: UserObjectHealthDataValue,
    params?: ReadonlyPartialRecord<'from' | 'to', Date>
  ): QuestionnaireChartData;

  public static buildChartData(
    chartType: HealthDataI18n.KCCQChartType,
    data?: UserObjectHealthDataValue,
    params?: ReadonlyPartialRecord<'from' | 'to', Date>
  ): DomainScoresChartData | QuestionnaireChartData | SummaryScoreChartData;

  public static buildChartData(
    chartType: unknown,
    data: UserObjectHealthDataValue,
    params?: ReadonlyPartialRecord<'from' | 'to', Date>
  ): unknown {
    let from = params?.from?.getTime() as number;
    from = Math.min(
      Math.max(MIN_SAFE_TIMESTAMP, Utils.numberOrDefault(Utils.isInteger(from) && from, MIN_SAFE_TIMESTAMP)),
      MAX_SAFE_TIMESTAMP
    );

    let to = params?.to?.getTime() as number;
    to = endOfDay(
      Math.min(Math.max(from, Utils.numberOrDefault(Utils.isInteger(to) && to, MAX_SAFE_TIMESTAMP)), MAX_SAFE_TIMESTAMP)
    ).getTime();

    const timestampList: Array<number> = [];
    for (const requestId in data.$index) {
      const [indexOfRequest, responseList] = data.$index[+requestId]!;
      if (data.requestList![indexOfRequest].form !== DataFormNameKCCQ) continue;

      timestampList.push(...responseList.filter((ts) => ts >= from && ts <= to));
    }

    switch (chartType) {
      case CHART_TYPE_DOMAIN:
        return this.buildDomainScoresChartData(data.kccq, { extent: [from, to], timestampList });
      case CHART_TYPE_SUMMARY:
        return this.buildSummaryScoreChartData(data.kccq, { extent: [from, to], timestampList });
      case CHART_TYPE_QUESTIONNAIRE:
        return this.buildQuestionnaireChartData(data.kccq, { extent: [from, to], timestampList });
      default: {
        if (process.env.REACT_APP_ENV === 'local') {
          throw new Error(`Unhandled case - ${chartType}`);
        }
        return EMPTY_ARRAY;
      }
    }
  }

  public static buildDomainScoresChartData(data?: HealthData, params?: ChartDataParams): DomainScoresChartData {
    const chartData = Utils.mapValues(NULL_DOMAIN_SCORES_CHART_DATA, (v) => [] as Array<typeof v[0]>);

    const tsList = Utils.arrayOrDefault<number>(params?.timestampList);
    if (Utils.isNotNil(data) && tsList.length > 0) {
      const kccqDataUtil = new KCCQDataUtil();
      for (const ts of tsList) {
        const [utcYear, utcMonth, utcDate] = dateToUtcValues(ts);
        const score = kccqDataUtil.calculateScore(data[utcYear]![utcMonth]![utcDate]!);
        (Object.keys(chartData) as Array<keyof typeof chartData>).forEach(
          (scale) => void chartData[scale].push({ score: score[scale], ts })
        );
      }
    }

    return chartData;
  }

  public static buildQuestionnaireChartData = (data?: HealthData, params?: ChartDataParams): QuestionnaireChartData => {
    const chartData = Utils.mapValues(NULL_QUESTIONNAIRE_DATA, (v) => [] as Array<typeof v[0]>);

    const tsList = Utils.arrayOrDefault<number>(params?.timestampList);
    if (Utils.isNotNil(data) && tsList.length > 0) {
      for (const ts of tsList) {
        const [utcYear, utcMonth, utcDate] = dateToUtcValues(ts);
        const { pl, ql, sf, sl } = data[utcYear]![utcMonth]![utcDate]!;

        void [pl, ql, sf, sl].reduce((d, scale) => {
          if (scale === pl || scale === sl) {
            scale.forEach(({ activity, codedValue }) => void d[activity].push({ codedValue, ts }));
          } else if (scale === ql) {
            scale.forEach(({ codedValue, question }) => void d[question].push({ codedValue, ts }));
          } else if (scale === sf) {
            scale.forEach(({ codedValue, symptom }) => void d[symptom].push({ codedValue, ts }));
          }
          return d;
        }, chartData);
      }
    }

    return chartData;
  };

  public static buildSummaryScoreChartData = (data?: HealthData, params?: ChartDataParams): SummaryScoreChartData => {
    const chartData: Array<SummaryScoreChartData[0]> = [];

    const tsList = Utils.arrayOrDefault<number>(params?.timestampList);
    if (Utils.isNotNil(data) && tsList.length > 0) {
      const kccqDataUtil = new KCCQDataUtil();
      for (const ts of tsList) {
        const [utcYear, utcMonth, utcDate] = dateToUtcValues(ts);
        const score = kccqDataUtil.calculateScore(data[utcYear]![utcMonth]![utcDate]!, 'summary');
        chartData.push({ score, ts });
      }
    }

    return chartData;
  };

  public static calculateHealthStatus(summaryScore: number): string {
    if (summaryScore >= 75 && summaryScore <= 100) return 'Excellent';
    if (summaryScore >= 50 && summaryScore <= 74) return 'Good';
    if (summaryScore >= 25 && summaryScore <= 49) return 'Fair';
    if (summaryScore >= 0 && summaryScore <= 24) return 'Poor';
    return '';
  }

  public static createDataFromFormValues(values: FormValues): KCCQFormData {
    return {
      pl: PL_KEY_LIST.map((activity) => ({ activity, codedValue: values[activity] })),
      ql: QL_KEY_LIST.map((question) => ({ question, codedValue: values[question] })),
      sf: SF_KEY_LIST.map((symptom) => ({ symptom, codedValue: values[symptom] as any })),
      sl: SL_KEY_LIST.map((activity) => ({ activity, codedValue: values[activity] }))
    };
  }

  public static getHealthStatusCssClass(summaryScore: number): string {
    if (summaryScore >= 50 && summaryScore <= 100) return 'good';
    if (summaryScore >= 25 && summaryScore <= 49) return 'fair';
    if (summaryScore >= 0 && summaryScore <= 24) return 'poor';
    return '';
  }

  public static isValid(data?: null | undefined | unknown): data is KCCQFormData {
    return (
      Utils.isNonArrayObjectLike<KCCQFormData>(data) &&
      Utils.isArray(data.pl) &&
      Utils.isArray(data.ql) &&
      Utils.isArray(data.sf) &&
      Utils.isArray(data.sl)
    );
  }

  public calculateScore(scale?: 'all'): Score;
  public calculateScore(data: KCCQFormData, scale?: 'all'): Score;
  public calculateScore(scale: keyof Score): number;
  public calculateScore(data: KCCQFormData, scale: keyof Score): number;
  public calculateScore(arg0?: unknown, arg1?: Nullable<keyof Score | 'all'>): number | Score {
    let data: KCCQFormData;
    let scale: NonNullable<typeof arg1>;

    if (Utils.isString(arg0)) {
      data = this.data!;
      scale = arg0 as typeof scale;
    } else {
      data = arg0 as KCCQFormData;
      scale = arg1!;
    }

    const Class = this.constructor as typeof KCCQDataUtil;
    if (!Class.isValid(data)) throw new TypeError('Invalid KCCQ data.');

    const score: Score = { pl: 0, ql: 0, sf: 0, sl: 0, summary: 0 };
    scale = Utils.stringOrDefault<NonNullable<typeof scale>>(scale, 'all');
    const all = scale === 'all' || scale === 'summary';

    if (all || scale === 'pl') score.pl = calculatePLScore(data.pl);
    if (all || scale === 'ql') score.ql = calculateQLScore(data.ql);
    if (all || scale === 'sf') score.sf = calculateSFScore(data.sf);
    if (all || scale === 'sl') score.sl = calculateSLScore(data.sl);
    score.summary = Math.round(avg([score.pl, score.ql, score.sf, score.sl]));

    return scale === 'all' ? score : score[scale];
  }

  // @ts-expect-error TS2394
  public toScoreHtml(t: WithT['t']): string;
  public toScoreHtml(data: KCCQFormData, t: WithT['t']): string;
  public toScoreHtml(arg0: unknown, __UNUSED_arg1: WithT['t']): string {
    const Class = this.constructor as typeof KCCQDataUtil;
    const data = typeof arg0 === 'function' ? this.data! : (arg0 as KCCQFormData);
    const score = this.calculateScore(data);

    return [
      `<table class="${DataFormCssClassPrefix}${DataFormNameKCCQ}-score">`,
      // --
      // caption
      '<caption>Score</caption>',
      // header
      '<thead>',
      `<th>Domain</th><th>Score</th>`,
      '</thead>',
      // body
      '<tbody>',
      `<tr><th scope="row">Physical Limitation</th><td>${score.pl}</td></tr>`,
      `<tr><th scope="row">Symptom Frequency</th><td>${score.sf}</td></tr>`,
      `<tr><th scope="row">Quality Of Life</th><td>${score.ql}</td></tr>`,
      `<tr><th scope="row">Social Limitation</th><td>${score.sl}</td></tr>`,
      '</tbody>',
      // small gap
      '<tbody aria-hidden="true"><tr><td colspan="2" style="padding:2px"/></tr></tbody>',
      // footer
      `<tfoot class="${Class.getHealthStatusCssClass(score.summary)}">`,
      `<tr><th scope="row">Summary Score</th><td>${score.summary}</td></tr>`,
      `<tr><th scope="row">Health Status</th><td>${Class.calculateHealthStatus(score.summary)}</td></tr>`,
      '</tfoot>',
      // --
      '</table>'
    ].join('');
  }

  // @ts-expect-error TS2394
  public toQuestionnaireHtml(t: WithT['t']): string;
  public toQuestionnaireHtml(data: KCCQFormData, t: WithT['t']): string;
  public toQuestionnaireHtml(arg0: unknown, arg1: WithT['t']): string {
    let data: KCCQFormData;
    let t: typeof arg1;

    if (typeof arg0 === 'function') {
      data = this.data!;
      t = arg0 as WithT['t'];
    } else {
      data = arg0 as KCCQFormData;
      t = arg1;
    }

    const { cardiomyopathyQuestionnaire: i18n } = healthDataI18n.form;

    const tbodyList = KCCQ_FIELD_SET_LIST.map<string>(([fieldsetName, fieldNameList]) => {
      const isFieldsetPL = fieldsetName === 'fieldsetPhysicalLimitation';
      const isFieldsetQL = !isFieldsetPL && fieldsetName === 'fieldsetQualityOfLife';
      const isFieldsetSL = !isFieldsetPL && !isFieldsetQL && fieldsetName === 'fieldsetSocialLimitation';
      const isFieldsetSF =
        !isFieldsetPL && !isFieldsetQL && !isFieldsetSL && fieldsetName === 'fieldsetSymptomFrequency';

      let key: keyof KCCQFormData;
      if (isFieldsetPL) key = 'pl';
      else if (isFieldsetQL) key = 'ql';
      else if (isFieldsetSL) key = 'sl';
      else if (isFieldsetSF) key = 'sf';
      else return '';

      const columnList = fieldNameList.map<string>((fieldName) => {
        const header = t((i18n[fieldsetName].formField as any)[fieldName].label, { language: EnglishCanada });
        const { codedValue } = (data[key] as ReadonlyArray<any>).find((item) => {
          return item[isFieldsetPL || isFieldsetSL ? 'activity' : isFieldsetQL ? 'question' : 'symptom'] === fieldName;
        })!;
        const choice = t(
          (i18n[fieldsetName].formField as any)[fieldName].options.find(
            (option: any) => option.codedValue === codedValue
          )!.label,
          { language: EnglishCanada }
        );

        return `<th scope="row">${header}</th><td>${choice}</td>`;
      });

      if (isFieldsetPL || isFieldsetSL) {
        const header = t(i18n[fieldsetName].label!, { language: EnglishCanada });
        const rowList = `<tr class="indent-1">${columnList.join('</tr><tr class="indent-1">')}</tr>`;
        return `<tbody><tr><th scope="col">${header}</th><td/></tr>${rowList}</tbody>`;
      } else {
        const rowList = `<tr>${columnList.join('</tr><tr>')}</tr>`;
        return `<tbody>${rowList}</tbody>`;
      }
    }).join('');

    const caption = '<caption>Questionnaire</caption>';
    const thead = '<thead><th>Question</th><th>Answer</th></thead>';

    return `<table class="${DataFormCssClassPrefix}${DataFormNameKCCQ}">${caption}${thead}${tbodyList}</table>`;
  }

  // @ts-expect-error TS2394
  public toHtml(t: WithT['t']): string;
  public toHtml(data: KCCQFormData, t: WithT['t']): string;
  public toHtml(data: unknown, t: WithT['t']): string {
    const scoreHtml = this.toScoreHtml(data as KCCQFormData, t);
    const questionnaireHtml = this.toQuestionnaireHtml(data as KCCQFormData, t);

    return scoreHtml.concat(questionnaireHtml);
  }
}
