import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import type { ApiFormattedDataObject, HrmSenderListItem } from '@sigmail/objects';
import { DataObjectHrmSenderList } from '@sigmail/objects';
import type { Api, Http } from '../../types';
import { MalformedResponseException } from '../malformed-response-exception';

const OBJECT_ID_HRM_SENDER_LIST = 3203;
const OBJECT_TYPE_HRM_SENDER_LIST = process.env.DATA_OBJECT_TYPE_HRM_SENDER_LIST;

const Logger = getLoggerWithPrefix('ApiService', 'hrmGetSenderList:');

export async function apiHrmGetSenderList(
  this: Api.Service,
  accessToken: string,
  authState: string,
  init?: Http.RequestInit
): Promise<ReadonlyArray<HrmSenderListItem>> {
  try {
    Logger.info('== BEGIN ==');

    Logger.info('Initiating HTTP request.');
    const { dataObjects } = await this.batchQueryData(
      accessToken,
      { authState, dataObjects: { ids: [OBJECT_ID_HRM_SENDER_LIST] } },
      init
    );

    let json: ApiFormattedDataObject | undefined;
    if (Utils.isNonEmptyArray(dataObjects)) {
      json = dataObjects.find(
        ({ id, type }) => id === OBJECT_ID_HRM_SENDER_LIST && type === OBJECT_TYPE_HRM_SENDER_LIST
      );
    }

    if (Utils.isNil(json)) {
      Logger.warn('Operation failed. (Malformed/unexpected response data)');
      throw new MalformedResponseException('Failed to fetch HRM sender list data object.');
    }

    const hrmSenderListObject = new DataObjectHrmSenderList(json);
    const { list: hrmSenderList } = await hrmSenderListObject.decryptedValue();
    return hrmSenderList;
  } finally {
    Logger.info('== END ==');
  }
}
