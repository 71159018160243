import type {
  MessageFormNameConsultationRequest,
  MessageFormNameDefault,
  MessageFormNameEncounter,
  MessageFormNameEvent,
  MessageFormNameEventAttendance,
  MessageFormNameHealthDataRequest,
  MessageFormNameHrm,
  MessageFormNameJoinGroupInvitation,
  MessageFormNameReferral
} from '../types';

export const Default: MessageFormNameDefault = 'default';
export const Consultation: MessageFormNameConsultationRequest = 'eConsult';
/** @deprecated use {@link Consultation} */
export const EConsult = Consultation;
export const Encounter: MessageFormNameEncounter = 'encounter';
export const Event: MessageFormNameEvent = 'event';
export const EventAttendance: MessageFormNameEventAttendance = 'eventAttendance';
export const HealthDataRequest: MessageFormNameHealthDataRequest = 'healthDataRequest';
export const HRM: MessageFormNameHrm = 'hrm';
export const JoinGroupInvitation: MessageFormNameJoinGroupInvitation = 'joinGroupInvitation';
export const Referral: MessageFormNameReferral = 'referral';
