import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogMessageAlreadyReplyTo } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeMessage['dialog']['msgAlreadyRepliedTo'] = {
  // Translators: (HTML) Title for the Message Already Replied To dialog
  title: gettext('messaging', 'title', 'dialogMessageAlreadyRepliedTo', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of Message \
  // Already Replied To dialog
  body: gettext('messaging', 'body', 'dialogMessageAlreadyRepliedTo', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Proceed action of Message Already Replied \
      // To dialog
      label: gettext('messaging', 'accept', 'dialogMessageAlreadyRepliedToActionLabel'),
      context: DialogMessageAlreadyReplyTo
    }
  }
};

export default i18n;
