import { Utils } from '@sigmail/common';
import { selectAddress } from '.';

export const selectJoinedAddress = (...args: Parameters<typeof selectAddress>): string =>
  Utils.joinPostalAddress(
    Utils.mapKeys(selectAddress(...args), (value, key) => {
      if (!Utils.isString(value)) return key;
      if (key === 'street') return 'addressLine1';
      if (key === 'location') return 'addressLevel1';
      if (key === 'city') return 'addressLevel2';
      if (key === 'postalZip') return 'postalCode';
    })
  );
