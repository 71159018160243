import type { SigmailObjectTypeCode } from '@sigmail/common';
import { AppUser, Utils } from '@sigmail/common';
import { UserObject } from '.';
import { DataObjectSigmailGlobalContactList } from '../data-object/sigmail-global-contact-list';
import type { UserObjectProfilePrivateValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE;

/** @public */
export class UserObjectProfilePrivate extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      super.isValidDecryptedValue(value) &&
      AppUser.isValidId(dv.adminId) &&
      AppUser.isValidId(dv.clientId) &&
      AppUser.isValidId(dv.ownerId) &&
      AppUser.isValidId(dv.auditId) &&
      (Utils.isUndefined(dv.globalContactListId) ||
        DataObjectSigmailGlobalContactList.isValidId(dv.globalContactListId))
    );
  }

  public override updateValue(newValue: DV): Promise<UserObjectProfilePrivate> {
    const Class = this.constructor as typeof UserObjectProfilePrivate;
    return Class.updateValue(this, newValue);
  }
}
