import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import {
  AppException,
  AppUser,
  Constants,
  EventLogRecordCodeMessageAssigned,
  EventLogRecordCodeMessageForwarded,
  EventLogRecordCodeMessageResponded,
  EventLogRecordCodeMessageSent,
  MemberRole,
  MessageFormName,
  MessageHeader,
  MessageSensitivity,
  MessagingException,
  PersonName,
  ReadonlyMessageBodyDefault,
  ReadonlyMessageBodyReferral,
  ReadonlyPartialRecord,
  SigmailGroupId,
  SigmailKeyId,
  SigmailObjectId,
  SigmailUserId,
  Utils
} from '@sigmail/common';
import {
  CryptographicKey,
  CryptographicKeyPublic,
  DataObjectDocBody,
  DataObjectDocMetadata,
  DataObjectDocMetadataValue,
  DataObjectMsgBody,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  DataObjectMsgReadReceipt,
  DataObjectMsgReadReceiptValue,
  LinkedMessageListItem,
  MessageFolderItemCount,
  MessageFolderListItem,
  MessageTimestampRecord,
  NotificationObjectIncomingMessage,
  NotificationObjectIncomingMessageValue,
  UserObjectContactInfoValue,
  ValueFormatVersion
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { startOfDay } from 'date-fns';
import sanitizeHtml from 'sanitize-html';
import * as HrmConstants from '../../../../app/messaging/constants/HRM';
import {
  ActionData,
  createMessageBody,
  createPdfFile
} from '../../../../app/messaging/folder-view/hooks/use-send-hrm-message-action-handler';
import * as EmailTemplateParams from '../../../../constants/email-template-params';
import { BatchUpdateRequestBuilder } from '../../../../utils/batch-update-request-builder';
import { NewEventLogRecordValueSendMessageParams } from '../../../../utils/event-log';
import { parseDataUri } from '../../../../utils/parse-data-uri';
import { PushNotificationBuilder } from '../../../../utils/push-notification';
import { readBlobAsDataUri } from '../../../../utils/read-file-as-data-uri';
import { EMPTY_ARRAY } from '../../../constants';
import { Cache as DataObjectCache } from '../../../data-objects-slice/cache';
import { accessTokenSelector } from '../../../selectors/auth';
import { contactInfoObjectSelector as groupContactInfoSelector } from '../../../selectors/group-object';
import {
  basicProfileObjectSelector as selectUserProfileBasic,
  contactInfoObjectSelector as selectUserContactInfo,
  protectedProfileObjectSelector as selectUserProfileProtected,
  selectContactNumber,
  selectPersonName,
  selectPostalAddress,
  selectRoleBasedData,
  userRoleSelector
} from '../../../selectors/user-object';
import { UserObjectCache } from '../../../user-objects-slice/cache';
import { AuthenticatedActionState } from '../../authenticated-action';
import { ActionInitParams, FetchObjectsRequestData } from '../../base-action';
import { SANITIZER_OPTIONS } from '../../constants';
import { AUTH_STATE_LOG_EVENT, AUTH_STATE_SEND_MESSAGE } from '../../constants/auth-state-identifier';
import { sendTemplatedEmailMessageAction } from '../../email/send-templated-email-message-action';
import { sendDataToEmrAction } from '../../EMR/send-data-to-emr-action';
import { fetchUserObjectsAction } from '../../fetch-user-objects-action';
import { hrmGetUserListAction } from '../../HRM/get-user-list-action';
import { logEventAction, Payload as LogEventActionPayload } from '../../log-event-action';
import { newNotificationAction } from '../../notifications/new-notification-action';
import { BaseMessagingAction, isMessageSensitivityNormal } from '../base-messaging-action';
import { createMessageFolderExtensionAction } from '../create-message-folder-extension-action';
import { discardDraftAction } from '../discard-draft-action';
import { updateMessageFolderAction } from '../update-msg-folder-action';

export interface BaseSendMessagePayload
  extends Omit<MessagingActionPayload.SendMessage, 'flags' | 'primaryRecipientList' | 'secondaryRecipientList' | 'sensitivity'> {
  readonly flags: Required<NonNullable<MessagingActionPayload.SendMessage['flags']>>;
  readonly messageFormName: MessageFormName;
  readonly recipientList: ReadonlyArray<{
    readonly entity: Readonly<
      MessageHeader['recipientList'][0]['entity'] & {
        accountStatus?: 'pending';
        isOneTimeContact?: boolean;
        role?: string;
      }
    >;
  }>;
  readonly sensitivity: MessageSensitivity;
}

export interface BaseSendMessageState extends AuthenticatedActionState {
  batchUpdateAuthState: string;
  batchUpdateClaims: Array<string>;
  documentList: Array<Required<MessageFolderListItem>['documentList'][0]>;
  dtServer: Date;
  idRecord: Api.GetIdsResponseData['ids'];
  isGroupMsgFolder: boolean;
  isMessageKindAssign: boolean;
  isMessageKindDraft: boolean;
  isMessageKindForward: boolean;
  isMessageKindReply: boolean;
  isMessageKindReplyAll: boolean;
  msgBodyId: SigmailObjectId;
  msgMetadata: DataObjectMsgMetadataValue;
  msgMetadataId: SigmailObjectId;
  msgReadReceiptId: SigmailObjectId;
  recipientKeyIdList: Array<SigmailKeyId>;
  recipientList: Array<BaseSendMessagePayload['recipientList'][0]>;
  requestBody: BatchUpdateRequestBuilder;
  senderRole: string | undefined;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

export class BaseSendMessageAction<
  P extends BaseSendMessagePayload = BaseSendMessagePayload,
  S extends BaseSendMessageState = BaseSendMessageState
> extends BaseMessagingAction<P, S> {
  public constructor(params: ActionInitParams<P>) {
    super(params);

    const { folderKey, parentFolderKey, recipientList, sensitivity, sender } = params.payload;
    if (!isMessageSensitivityNormal(sensitivity) && recipientList.length !== 1) {
      throw new MessagingException('Expected at most one recipient entry.');
    }

    this.state.isGroupMsgFolder =
      folderKey === Constants.MessageFolderKey.GroupInbox || parentFolderKey === Constants.MessageFolderKey.GroupInbox;
    this.state.senderRole = userRoleSelector(params.getState())((sender.type === 'user' && sender.id) as number);

    const messageKindList: ReadonlyArray<P['messageKind']> = ['assign', 'draft', 'forward', 'reply', 'replyAll'];
    [
      this.state.isMessageKindAssign,
      this.state.isMessageKindDraft,
      this.state.isMessageKindForward,
      this.state.isMessageKindReply,
      this.state.isMessageKindReplyAll
    ] = messageKindList.reduce<[boolean, boolean, boolean, boolean, boolean]>(
      (tuple, kind, index) => {
        tuple[index] = !tuple.includes(true) && this.payload.messageKind === kind;
        return tuple;
      },
      [false, false, false, false, false]
    );
  }

  /** @override */
  protected async preExecute() {
    const result = await super.preExecute();
    this.validateMessageFormName();

    this.state.recipientList = await this.buildRecipientList();
    if (this.state.recipientList.length === 0) return false;

    this.state.recipientKeyIdList = await this.buildRecipientKeyIdList();
    return result;
  }

  protected validateMessageFormName(): void {
    const { messageFormName } = this.payload;
    if (!Utils.isMessageFormNameDefault(messageFormName)) {
      throw new MessagingException(`Expected message form name to be <${Constants.MessageFormName.Default}>; was <${messageFormName}>`);
    }
  }

  protected async buildRecipientList(): Promise<BaseSendMessageState['recipientList']> {
    return this.payload.recipientList.slice();
  }

  protected async buildRecipientKeyIdList(): Promise<BaseSendMessageState['recipientKeyIdList']> {
    const { sender } = this.payload;
    const { recipientList } = this.state;

    return Utils.filterMap(
      recipientList,
      ({ entity: { keyId, id } }) => id !== sender.id && (CryptographicKeyPublic.isValidId(keyId) ? keyId : id)
    );
  }

  protected async resetActionState(): Promise<void> {
    this.state.batchUpdateClaims = [];
    this.state.documentList = [];
    this.state.dtServer = await this.dispatchFetchServerDateAndTime();
  }

  /** @override */
  protected async onExecute(...args: any[]) {
    if (args.length > 0 && args[0] === false) return;

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.resetActionState();

        await this.generateIdSequence();

        this.initializeRequestBodyAndSuccessPayload();
        await this.fetchRecipientPublicKeys();
        await this.generateRequestBody();

        const { batchUpdateAuthState: authState, batchUpdateClaims: claims, requestBody } = this.state;
        await this.dispatchBatchUpdateData({ authState, claims, ...requestBody.build() });

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }

    try {
      await this.onBatchUpdateDataSuccess();
    } catch (error) {
      this.logger.warn('Error during onBatchUpdateDataSuccess:', error);
      /* ignore */
    }

    try {
      await this.dispatchBatchQueryDataSuccess(this.state.successPayload);
    } catch (error) {
      this.logger.warn('Error manually updating app state:', error);
      /* ignore */
    }

    try {
      await this.dispatch(createMessageFolderExtensionAction({ folderKey: Constants.MessageFolderKey.Sent }));
    } catch (error) {
      this.logger.warn('Error creating message folder extension:', error);
      /* ignore */
    }
  }

  protected async createIdsRequestData(): Promise<Api.GetIdsRequestData> {
    const { documentList } = this.payload;
    const { recipientList } = this.state;

    let documentIdList: Api.GetIdsRequestData['ids']['ids'] = [];
    if (Utils.isNonEmptyArray(documentList)) {
      const { length: count } = documentList.filter((doc) => doc instanceof File);
      if (count > 0) {
        documentIdList = [
          { count, type: process.env.DATA_OBJECT_TYPE_DOC_METADATA },
          { count, type: process.env.DATA_OBJECT_TYPE_DOC_BODY }
        ];
      }
    }

    return {
      authState: this.state.roleAuthClaim,
      state: AUTH_STATE_SEND_MESSAGE,
      ids: {
        ids: [
          { type: process.env.DATA_OBJECT_TYPE_MSG_METADATA },
          { type: process.env.DATA_OBJECT_TYPE_MSG_BODY },
          { type: process.env.DATA_OBJECT_TYPE_MSG_READ_RECEIPT },
          {
            type: process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE,
            count: recipientList.length
          },
          ...documentIdList
        ]
      }
    };
  }

  protected async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const query = await this.createIdsRequestData();
    const { authState, claims, ids: idRecord } = await this.dispatchFetchIdsByUsage(query);

    this.state.batchUpdateAuthState = authState;
    this.state.batchUpdateClaims.push(...claims);
    this.state.idRecord = idRecord;

    [this.state.msgMetadataId] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA];
    [this.state.msgBodyId] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_BODY];
    [this.state.msgReadReceiptId] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_READ_RECEIPT];
  }

  protected initializeRequestBodyAndSuccessPayload(): void {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };
  }

  protected async fetchRecipientPublicKeys(): Promise<void> {
    const { roleAuthClaim: authState, recipientKeyIdList } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: recipientKeyIdList.map((keyId) => ({ type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: keyId }))
    };
    if (query.keysByType!.length === 0) return;

    this.logger.info('Fetching public keys of all of the recipients.');
    const { keyList } = await this.dispatchFetchObjects(query);
    await Promise.all(keyList.map((keyJson) => CryptographicKey.cache(new CryptographicKeyPublic(keyJson))));
  }

  protected async generateRequestBody(): Promise<void> {
    await this.addInsertOperationsForSenderPublicKey(); // 101s

    await this.addInsertOperationsForAttachedDocumentList(); // 301/302

    await this.addInsertOperationForMessageReadReceipt(); // 203
    await this.addInsertOperationForMessageMetadata(); // 201
    await this.addInsertOperationForMessageBody(); // 202

    await this.addInsertOperationsForRecipientNotification(); // 501
    await this.addUpdateOperationForSentMessagesFolder(); // 251/252
    await this.addUpdateOperationForCurrentMessageFolder(); // 251/252

    if (this.state.isMessageKindDraft) {
      const { header: msgMetadataId } = this.payload.sourceMessage!;
      const { requestBody, successPayload } = this.state;

      await this.dispatch(discardDraftAction({ msgMetadataId, requestBody, successPayload }));
    }

    await this.addUpdateOperationForUserEventLog(); // 425
  }

  protected async addInsertOperationsForSenderPublicKey(): Promise<void> {
    const { sensitivity, sender } = this.payload;
    const { recipientList, requestBody, roleAuthClaim: authState } = this.state;

    if (isMessageSensitivityNormal(sensitivity)) return;

    this.logger.info("Adding an insert operation for each recipient to request body for sender's public key.");
    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [{ type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: sender.id }]
    };

    const { keyList } = await this.dispatchFetchObjects(query);

    const publicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: sender.id });
    if (Utils.isNil(publicKeyJson)) {
      throw new MessagingException("Sender's public key could not be fetched.");
    } else {
      const publicKeyObject = new CryptographicKeyPublic(publicKeyJson);
      CryptographicKey.cache(publicKeyObject);

      const publicKeyList = await Promise.all(
        recipientList.map(({ entity: { id } }) =>
          id === sender.id || id === publicKeyObject.encryptedForId ? Promise.resolve(null) : publicKeyObject.encryptFor(id)
        )
      );

      requestBody.insert(publicKeyList.filter(Utils.isNotNil));
    }
  }

  protected async addInsertOperationsForAttachedDocumentList(): Promise<void> {
    const { documentList: attachedDocumentList, onBehalfOf, sender } = this.payload;
    if (!Utils.isNonEmptyArray<NonNullable<BaseSendMessagePayload['documentList']>>(attachedDocumentList)) {
      return;
    }

    this.logger.info("Adding an insert operation each to request body for every attached document's metadata, body, and associated keys.");

    const { auditId, clientId, dtServer, ownerId, recipientKeyIdList, recipientList, requestBody, roleAuthClaim: authState } = this.state;
    const idSeqDocMetadata = Utils.makeSequence(this.state.idRecord[process.env.DATA_OBJECT_TYPE_DOC_METADATA]);
    const idSeqDocBody = Utils.makeSequence(this.state.idRecord[process.env.DATA_OBJECT_TYPE_DOC_BODY]);

    const encryptedFor = recipientKeyIdList.concat(auditId) as [number, ...Array<number>];
    if (recipientList.length === 1 && recipientList[0].entity.accountStatus === 'pending') {
      encryptedFor.push(clientId);
    }

    const apiAccessToken = accessTokenSelector(this.getRootState());
    for (let index = 0; index < attachedDocumentList.length; index++) {
      const doc = attachedDocumentList[index];

      if (doc instanceof File) {
        const docMetadata: DataObjectDocMetadataValue = {
          $$formatver: 1,
          name: doc.name,
          size: doc.size,
          docType: `attachment${index + 1}`
        };
        const { value: docMetadataId } = idSeqDocMetadata.next();
        const docMetadataObject = await DataObjectDocMetadata.create(
          docMetadataId,
          undefined,
          0,
          docMetadata,
          ownerId,
          sender.id,
          dtServer
        );

        const docContents = await readBlobAsDataUri(doc);
        const docBody: DataObjectMsgBodyValue = { $$formatver: 1, data: docContents };
        const { value: docBodyId } = idSeqDocBody.next();
        const docBodyObject = await DataObjectDocBody.create(docBodyId, undefined, 0, docBody, ownerId, sender.id, dtServer);

        const objectList = [docMetadataObject, docBodyObject];
        const docMetadataKeyList = await docMetadataObject.generateKeysEncryptedFor(...encryptedFor);
        const docBodyKeyList = await docBodyObject.generateKeysEncryptedFor(...encryptedFor);

        if (Utils.isNil(onBehalfOf)) {
          docMetadataKeyList.push(docMetadataObject[Constants.$$CryptographicKey]);
          docBodyKeyList.push(docBodyObject[Constants.$$CryptographicKey]);
        }

        requestBody.insert(objectList);
        requestBody.insert(docMetadataKeyList.filter(Utils.isNotNil));
        requestBody.insert(docBodyKeyList.filter(Utils.isNotNil));

        this.state.documentList!.push({ metadata: docMetadataId, body: docBodyId, name: doc.name, size: doc.size });
      } else if (!!!doc.deleted) {
        const { metadata: docMetadataId, body: docBodyId } = doc;

        const { dataObjectList } = await this.fetchObjects(apiAccessToken, {
          authState,
          dataObjects: { ids: [docMetadataId, docBodyId] },
          expectedCount: { dataObjects: null }
        });

        const docMetadataJson = this.findDataObject(dataObjectList, { type: process.env.DATA_OBJECT_TYPE_DOC_METADATA, id: docMetadataId });
        if (Utils.isNil(docMetadataJson)) {
          throw new Api.MalformedResponseException("Attached document's metadata object could not be fetched.");
        }

        const docBodyJson = this.findDataObject(dataObjectList, { type: process.env.DATA_OBJECT_TYPE_DOC_BODY, id: docBodyId });
        if (Utils.isNil(docBodyJson)) {
          throw new Api.MalformedResponseException("Attached document's body object could not be fetched.");
        }

        const docMetadataObject = new DataObjectDocMetadata(docMetadataJson);
        const docBodyObject = new DataObjectDocBody(docBodyJson);

        const docMetadataKeyList = await docMetadataObject.generateKeysEncryptedFor(...encryptedFor);
        const docBodyKeyList = await docBodyObject.generateKeysEncryptedFor(...encryptedFor);
        requestBody.insert(docMetadataKeyList.filter(Utils.isNotNil));
        requestBody.insert(docBodyKeyList.filter(Utils.isNotNil));

        this.state.documentList!.push(Utils.omit(doc, 'deleted'));
      }
    }
  }

  protected async createMessageReadReceiptValue(): Promise<DataObjectMsgReadReceiptValue> {
    const { dtServer, recipientList } = this.state;

    return {
      $$formatver: 1,
      data: recipientList.map(({ entity: { id: recipientId } }) => ({
        recipientId,
        receivedAtUtc: dtServer.getTime(),
        readAtUtc: null
      }))
    };
  }

  protected async addInsertOperationForMessageReadReceipt(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for message read receipt and associated keys.');

    const { onBehalfOf, sender } = this.payload;
    const { auditId, clientId, dtServer, msgReadReceiptId: id, ownerId, recipientKeyIdList, recipientList, requestBody } = this.state;

    const encryptedFor = recipientKeyIdList.concat(auditId) as [number, ...Array<number>];
    if (recipientList.length === 1 && recipientList[0].entity.accountStatus === 'pending') {
      encryptedFor.push(clientId);
    }

    const data = await this.createMessageReadReceiptValue();
    this.logger.debug({ id, ...data });

    const readReceiptObject = await DataObjectMsgReadReceipt.create(id, undefined, 1, data, ownerId, sender.id, dtServer);
    const keyList = await readReceiptObject.generateKeysEncryptedFor(...encryptedFor);
    if (Utils.isNil(onBehalfOf)) keyList.push(readReceiptObject[Constants.$$CryptographicKey]);
    requestBody.insert(readReceiptObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  protected async getGuestUserForMetadataValue(
    guestContactInfo?: ReadonlyPartialRecord<Exclude<keyof UserObjectContactInfoValue, keyof ValueFormatVersion>, unknown>
  ): Promise<DataObjectMsgMetadataValue['guestUser']> {
    const { messageBody, messageFormName, onBehalfOf, sender, sourceMessage } = this.payload;
    const { recipientList, senderRole } = this.state;

    let guestUserId: SigmailUserId | undefined;
    if (Utils.isNil(guestContactInfo)) {
      if (Utils.isNotNil(sourceMessage?.guestUser)) {
        return sourceMessage!.guestUser;
      }

      if (Utils.isGuestRole(senderRole)) {
        guestUserId = sender.id;
      } else if (Utils.isNotNil(onBehalfOf)) {
        guestUserId = onBehalfOf.id;
      } else if (Utils.isMessageFormNameReferral(messageFormName)) {
        guestUserId = (messageBody as ReadonlyMessageBodyReferral).messageForm.value.patient.id;
      } else {
        guestUserId = recipientList.find(({ entity }) => entity.type === 'user' && Utils.isGuestRole(entity.role))?.entity.id;
      }

      if (AppUser.isValidId(guestUserId)) {
        try {
          await this.dispatch(
            fetchUserObjectsAction({
              objectByType: [
                { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId: guestUserId },
                { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED, userId: guestUserId }
              ]
            })
          );

          guestContactInfo = {
            ...UserObjectCache.getValue(selectUserProfileBasic(this.getRootState())(guestUserId)),
            ...UserObjectCache.getValue(selectUserProfileProtected(this.getRootState())(guestUserId))
          };
        } catch {
          /* ignore */
        }
      } else {
        guestUserId = undefined;
      }
    }

    if (Utils.isNotNil(guestContactInfo)) {
      const contactData = {
        ...selectContactNumber(guestContactInfo),
        ...selectPersonName(guestContactInfo),
        ...selectPostalAddress(guestContactInfo),
        ...selectRoleBasedData(guestContactInfo, guestContactInfo)
      } as const;

      if (contactData.role === (Constants.ROLE_ID_GUEST as Extract<MemberRole, 'patient'>)) {
        let address: NonNullable<DataObjectMsgMetadataValue['guestUser']>['address'];
        const postalAddress = Utils.pick(contactData, Constants.POSTAL_ADDRESS_KEY_LIST);
        if (Object.values(postalAddress).some((value) => value.length > 0)) {
          address = Object.fromEntries(Object.entries(postalAddress).filter(([, value]) => value.length > 0));
        }

        let birthDate: NonNullable<DataObjectMsgMetadataValue['guestUser']>['birthDate'];
        if (contactData.birthDate.length > 0) {
          birthDate = contactData.birthDate;
          if (/^\d{4}-\d{2}-\d{2}$/.test(birthDate)) {
            const [yyyy, mm, dd] = birthDate.split('-').map(Number);
            birthDate = new Date(yyyy, mm - 1, dd).toISOString();
          }
        }

        let contact: NonNullable<DataObjectMsgMetadataValue['guestUser']>['contact'];
        if (contactData.cellNumber.length > 0) {
          contact = { type: 'mobile', value: contactData.cellNumber };
        } else if (contactData.homeNumber.length > 0) {
          contact = { type: 'home', value: contactData.homeNumber };
        }

        const contactName: Pick<NonNullable<DataObjectMsgMetadataValue['guestUser']>, keyof PersonName> = {
          firstName: '',
          lastName: '',
          ...Object.fromEntries(
            Object.entries(Utils.pick(contactData, Constants.PERSON_NAME_KEY_LIST)).filter(([, value]) => value.length > 0)
          )
        };

        return {
          address,
          birthDate,
          contact,
          ...contactName,
          gender: contactData.gender,
          healthPlan: {
            jurisdiction: contactData.healthPlanJurisdiction,
            number: contactData.healthCardNumber
          },
          id: guestUserId
        };
      }
    }

    return undefined;
  }

  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    const { flags, messageFormName, onBehalfOf, sender, sensitivity, sourceMessage, subjectLine } = this.payload;
    const { dtServer, documentList, msgBodyId, msgMetadataId, msgReadReceiptId, recipientList } = this.state;

    return {
      $$formatver: 1,
      billable: flags.billable || undefined,
      billedByMsg: flags.billable ? [msgMetadataId, msgBodyId] : sourceMessage?.billedByMsg,
      documentList: documentList!,
      guestUser: await this.getGuestUserForMetadataValue(),
      importance: flags.important ? 'high' : 'normal',
      messageForm: { name: messageFormName },
      readReceiptId: msgReadReceiptId,
      recipientList: recipientList.map(
        ({ entity: { accountStatus, cellNumber, emailAddress, keyId, languagePreference, role, webPushSubscriberId, ...entity } }) => ({
          entity
        })
      ),
      replyTo: flags.doNotReply ? EMPTY_ARRAY : undefined,
      sender: Utils.isNil(onBehalfOf) ? sender : onBehalfOf,
      sentOnBehalfBy: Utils.isNil(onBehalfOf) ? undefined : (sender as typeof onBehalfOf),
      sensitivity,
      sentAtUtc: dtServer.getTime(),
      subjectLine: subjectLine.trim()
    };
  }

  protected async addInsertOperationForMessageMetadata(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for message metadata and associated keys.');

    const { onBehalfOf, sender } = this.payload;
    const { auditId, clientId, dtServer, msgMetadataId: id, ownerId, recipientKeyIdList, recipientList, requestBody } = this.state;

    const encryptedFor = recipientKeyIdList.concat(auditId) as [number, ...Array<number>];
    if (recipientList.length === 1 && recipientList[0].entity.accountStatus === 'pending') {
      encryptedFor.push(clientId);
    }

    const data = await this.createMessageMetadataValue();
    this.logger.debug({ id, ...data });

    const msgMetadataObject = await DataObjectMsgMetadata.create(id, undefined, 0, data, ownerId, sender.id, dtServer);
    const keyList = await msgMetadataObject.generateKeysEncryptedFor(...encryptedFor);
    if (Utils.isNil(onBehalfOf)) keyList.push(msgMetadataObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgMetadataObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.msgMetadata = data;
  }

  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    const { messageBody, onBehalfOf, sender } = this.payload;

    let { data } = messageBody as ReadonlyMessageBodyDefault;
    if (Utils.isNotNil(onBehalfOf)) {
      const name = Utils.joinPersonName(sender);
      data = [
        '<p><strong style="color: rgb(230, 0, 0);"><em>',
        `*** This message was sent by ${name} on behalf of the patient. ***`,
        '</em></strong></p>',
        '<p><br></p>',
        data
      ].join('');
    }

    return {
      $$formatver: 1,
      data: sanitizeHtml(data, SANITIZER_OPTIONS)
    };
  }

  protected async addInsertOperationForMessageBody(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for message body and associated keys.');

    const { onBehalfOf, sender } = this.payload;
    const { auditId, clientId, dtServer, msgBodyId: id, ownerId, recipientKeyIdList, recipientList, requestBody } = this.state;

    const encryptedFor = recipientKeyIdList.concat(auditId) as [number, ...Array<number>];
    if (recipientList.length === 1 && recipientList[0].entity.accountStatus === 'pending') {
      encryptedFor.push(clientId);
    }

    const data = await this.createMessageBodyValue();
    this.logger.debug({ id, ...data });

    const msgBodyObject = await DataObjectMsgBody.create(id, undefined, 0, data, ownerId, sender.id, dtServer);
    const keyList = await msgBodyObject.generateKeysEncryptedFor(...encryptedFor);
    if (Utils.isNil(onBehalfOf)) keyList.push(msgBodyObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgBodyObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  protected async createNotificationValue(): Promise<NotificationObjectIncomingMessageValue> {
    const { msgBodyId: body, msgMetadataId: header } = this.state;

    return { $$formatver: 1, header, body };
  }

  protected async addInsertOperationsForRecipientNotification(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for recipient notification.');

    const { onBehalfOf, sender } = this.payload;
    const { dtServer, idRecord, recipientList, requestBody } = this.state;

    const idSequence = Utils.makeSequence(idRecord[process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE]);

    const data = await this.createNotificationValue();
    for (const { entity } of recipientList) {
      const { value: id } = idSequence.next();
      const { id: encryptedForId } = Utils.isNil(onBehalfOf) ? sender : onBehalfOf;
      const notificationObject = await NotificationObjectIncomingMessage.create(
        id,
        undefined,
        0,
        data,
        entity.id,
        encryptedForId,
        0,
        dtServer
      );
      requestBody.insert(notificationObject);
    }
  }

  protected async addUpdateOperationForSentMessagesFolder(): Promise<void> {
    this.logger.info('Adding an update operation to request body for sent message folder.');

    const { requestBody, successPayload } = this.state;

    await this.dispatch(
      updateMessageFolderAction({
        folderKey: Constants.MessageFolderKey.Sent,
        requestBody,
        successPayload,
        applyUpdate: this.applySentMessagesFolderUpdate.bind(this)
      })
    );
  }

  protected createLinkedMessageListForSentMessage(): MessageFolderListItem['linkedMessageList'] {
    const { sourceMessage: sourceMsg } = this.payload;
    const {
      isMessageKindAssign,
      isMessageKindDraft,
      isMessageKindForward,
      isMessageKindReply,
      isMessageKindReplyAll,
      recipientList: recipients
    } = this.state;

    const recipientList = recipients.map(({ entity: { id, type } }) => ({ id, type }));
    const sender = { id: this.payload.sender.id, type: this.payload.sender.type };
    let sourceMessage: Pick<MessageFolderListItem, 'body' | 'header'> = undefined!;
    if (isMessageKindAssign || isMessageKindDraft || isMessageKindForward || isMessageKindReply || isMessageKindReplyAll) {
      sourceMessage = { body: sourceMsg!.body, header: sourceMsg!.header };
    }

    let linkedMessageList: MessageFolderListItem['linkedMessageList'];
    if (isMessageKindAssign) {
      linkedMessageList = [
        {
          assignedBy: sender.id,
          assigneeList: recipientList.map(({ id }) => id),
          rel: 'assignOf',
          sourceMessage
        }
      ];
    } else if (isMessageKindDraft) {
      const { linkedMessageList: savedLinkedMessageList } = sourceMsg!;
      if (Utils.isArray<LinkedMessageListItem>(savedLinkedMessageList) && savedLinkedMessageList.length === 1) {
        const [linkedMsg] = savedLinkedMessageList;
        if (linkedMsg.rel === 'forwardOf' || linkedMsg.rel === 'replyOf') {
          // recipient list could've been changed from the time message was
          // saved as a draft
          linkedMessageList = [{ ...linkedMsg, recipientList }];
        }
      }
    } else if (isMessageKindForward) {
      linkedMessageList = [{ recipientList, rel: 'forwardOf', sender, sourceMessage }];
    } else if (isMessageKindReply || isMessageKindReplyAll) {
      linkedMessageList = [{ recipientList, rel: 'replyOf', sender, sourceMessage }];
    }

    return linkedMessageList;
  }

  protected createSentMessage(): MessageFolderListItem {
    const { flags, messageFormName, sendToEMR, sendToHRM, sensitivity, sourceMessage, subjectLine: subject } = this.payload;
    const { documentList, dtServer, msgBodyId, msgMetadata, msgMetadataId, recipientList } = this.state;

    return {
      body: msgBodyId,
      billedByMsg: flags.billable ? [msgMetadataId, msgBodyId] : sourceMessage?.billedByMsg,
      documentList,
      entity: recipientList.map(({ entity: recipient }) =>
        recipient.type === 'group' ? recipient.groupName : Utils.joinPersonName(recipient)
      ),
      extraData: null,
      flags: {
        billable: flags.billable || undefined,
        importance: flags.important ? 'high' : 'normal',
        markedAsRead: true
      },
      guestUser: msgMetadata.guestUser,
      header: msgMetadataId,
      linkedMessageList: this.createLinkedMessageListForSentMessage(),
      messageForm: { name: messageFormName },
      sensitivity,
      snippet: null,
      subject,
      timestamp: {
        createdAt: dtServer.getTime(),
        firstReadAt: dtServer.getTime(),
        sentToEMRAt: Utils.numberOrDefault(sendToEMR === true && dtServer.getTime(), undefined),
        sentToHRMAt: Utils.numberOrDefault(sendToHRM === true && dtServer.getTime(), undefined)
      }
    };
  }

  protected async applySentMessagesFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    itemCount: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ) {
    const { flags, folderKey, messageFormName } = this.payload;
    const { isMessageKindAssign, isMessageKindForward, isMessageKindReply, isMessageKindReplyAll } = this.state;

    let done = false;
    let updated = false;
    if (meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER) {
      const sentMessage = this.createSentMessage();
      folderData.unshift(sentMessage);

      ++itemCount.all.total;
      itemCount.important.total += +flags.important;
      itemCount.billable.total += +flags.billable;
      itemCount.referral.total += +Utils.isMessageFormNameReferral(messageFormName);

      done = updated = true;
    }

    if (
      folderKey === Constants.MessageFolderKey.Sent &&
      (isMessageKindAssign || isMessageKindForward || isMessageKindReply || isMessageKindReplyAll)
    ) {
      if (isMessageKindAssign) {
        throw new MessagingException('Operation <assign> is not supported for Sent message folder.');
      }

      done = false;

      const result = await Promise.resolve(this.applyCurrentMessageFolderUpdate(folderData, itemCount, meta));
      if (Utils.isNonArrayObjectLike<Exclude<typeof result, void>>(result)) {
        done = result.done === true;
        updated = updated || result.updated === true;
      }
    }

    return { done, updated };
  }

  protected async addUpdateOperationForCurrentMessageFolder(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;
    const {
      isMessageKindAssign,
      isMessageKindForward,
      isMessageKindReply,
      isMessageKindReplyAll,
      requestBody,
      successPayload
    } = this.state;

    if (folderKey === Constants.MessageFolderKey.Sent) {
      // if current folder is Sent, the update is handled by
      // applySentMessagesFolderUpdate() method instead
      return;
    }

    if (!isMessageKindAssign && !isMessageKindForward && !isMessageKindReply && !isMessageKindReplyAll) {
      return;
    }

    this.logger.info('Adding an update operation to request body for current message folder.');

    await this.dispatch(
      updateMessageFolderAction({
        folderKey: folderKey!,
        parentFolderKey,
        requestBody,
        successPayload,
        applyUpdate: this.applyCurrentMessageFolderUpdate.bind(this)
      })
    );
  }

  protected applyCurrentMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    _: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ): MessagingActionPayload.ApplyMessageFolderUpdateResult {
    const { sender: msgSender } = this.payload;
    const {
      isMessageKindAssign,
      isMessageKindForward,
      isMessageKindReply,
      isMessageKindReplyAll,
      dtServer,
      recipientList: recipients
    } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    const { header: msgMetadataId } = this.payload.sourceMessage!;
    this.logger.info(`Locating item with message metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);
    const index = folderData.findIndex(({ header }) => header === msgMetadataId);
    if (index === -1) return result;

    const message = folderData[index];

    let newTimestamp: MessageTimestampRecord;
    if (Utils.isInteger(message.timestamp)) {
      newTimestamp = {
        createdAt: message.timestamp,
        firstReadAt: dtServer.getTime() // mark as read as well
      };
    } else {
      let { firstReadAt } = message.timestamp;
      if (Utils.isUndefined(firstReadAt)) {
        firstReadAt = dtServer.getTime(); // mark as read as well
      }
      newTimestamp = { ...message.timestamp, firstReadAt };
    }

    const recipientList = recipients.map(({ entity: { id, type } }) => ({ id, type }));
    const sender = { id: msgSender.id, type: msgSender.type };

    let linkedMessageList = message.linkedMessageList as Array<LinkedMessageListItem> | undefined;
    let timestampKey: Extract<keyof MessageTimestampRecord, `${'assigned' | 'forwarded' | 'repliedTo'}At`> | undefined;
    if (isMessageKindAssign || isMessageKindForward || isMessageKindReply || isMessageKindReplyAll) {
      const targetMessage: Pick<MessageFolderListItem, 'body' | 'header'> = {
        body: this.state.msgBodyId,
        header: this.state.msgMetadataId
      };

      linkedMessageList = Utils.arrayOrDefault<LinkedMessageListItem>(linkedMessageList?.slice());
      if (isMessageKindAssign) {
        linkedMessageList.unshift({
          assignedBy: sender.id,
          assigneeList: recipientList.map(({ id }) => id),
          rel: 'assignedTo',
          targetMessage
        });
        timestampKey = 'assignedAt';
      } else if (isMessageKindForward) {
        linkedMessageList.unshift({ recipientList, rel: 'forwarded', sender, targetMessage });
        timestampKey = 'forwardedAt';
      } else if (isMessageKindReply || isMessageKindReplyAll) {
        linkedMessageList.unshift({ recipientList, rel: 'replied', sender, targetMessage });
        timestampKey = 'repliedToAt';
      }
    }

    if (Utils.isNotNil(timestampKey)) {
      newTimestamp[timestampKey] = dtServer.getTime();
    }

    folderData[index] = {
      ...message,
      linkedMessageList,
      flags: { ...message.flags, markedAsRead: true },
      timestamp: newTimestamp
    };

    result.updated = true;
    result.done = true;

    return result;
  }

  protected async addUpdateOperationForUserEventLog(): Promise<void> {
    this.logger.info('Adding an update operation to request body for user event log.');

    const { folderKey, flags, messageFormName, parentFolderKey, sensitivity, sourceMessage } = this.payload;
    const {
      activeGroupId,
      currentUser,
      documentList,
      dtServer,
      isGroupMsgFolder,
      isMessageKindAssign,
      isMessageKindForward,
      isMessageKindReply,
      isMessageKindReplyAll,
      msgBodyId,
      msgMetadataId,
      recipientList,
      requestBody,
      roleAuthClaim: authState,
      successPayload
    } = this.state;

    let code:
      | EventLogRecordCodeMessageAssigned
      | EventLogRecordCodeMessageForwarded
      | EventLogRecordCodeMessageResponded
      | EventLogRecordCodeMessageSent = Constants.EventLogCode.MessageSent;
    let folderId: SigmailObjectId | undefined;

    if (isMessageKindAssign || isMessageKindForward || isMessageKindReply || isMessageKindReplyAll) {
      ({ id: folderId } = await this.findMessageFolder(folderKey!, parentFolderKey));

      if (isMessageKindAssign) {
        code = Constants.EventLogCode.MessageAssigned;
      } else if (isMessageKindForward) {
        code = Constants.EventLogCode.MessageForwarded;
      } else if (isMessageKindReply || isMessageKindReplyAll) {
        code = Constants.EventLogCode.MessageResponded;
      }
    }

    const params: NewEventLogRecordValueSendMessageParams = [
      { billable: flags.billable, doNotReply: flags.doNotReply, important: flags.important, sensitivity },
      { documentList, messageFormName, msgBodyId, msgMetadataId, recipientList },
      sourceMessage,
      folderId
    ];

    const idList = [currentUser.id];
    if (
      isGroupMsgFolder &&
      (isMessageKindAssign ||
        isMessageKindForward ||
        isMessageKindReply ||
        isMessageKindReplyAll ||
        Utils.isMessageFormNameHealthDataRequest(messageFormName))
    ) {
      idList.push(activeGroupId);
    }

    const actionPayload: LogEventActionPayload = {
      dtServer,
      fetchIds: (count) => {
        return this.dispatchFetchIdsByUsage({
          authState,
          state: AUTH_STATE_LOG_EVENT,
          ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
        });
      },
      logger: this.logger,
      record: this.newEventLogRecordValue(dtServer, code, ...params),
      requestBody,
      successPayload,
      userId: 0,
      userIdType: 'user'
    };

    for (const userId of idList) {
      let { userIdType } = actionPayload;
      if (userId === activeGroupId) userIdType = 'group';
      const claims = await this.dispatch(logEventAction({ ...actionPayload, userId, userIdType }));
      this.state.batchUpdateClaims.push(...claims);
    }
  }

  protected async sendNewMessageSMSNotifications(): Promise<ReadonlyArray<Promise<void>>> {
    this.logger.info('Sending SMS notifications to all recipients who do not have it disabled.');

    try {
      const { dtServer, roleAuthClaim: authState } = this.state;

      const notificationList: Api.NewNotificationRequestData['notificationList'] = [];
      for (const {
        entity: { cellNumber: phoneNumber, id: recipientId, role, type }
      } of this.state.recipientList) {
        if (type !== 'user') continue;

        if (Utils.isGuestRole(role)) {
          try {
            const contactInfo = await this.getUserObjectValue(selectUserContactInfo, {
              fetch: true,
              type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
              userId: recipientId
            });
            if (Utils.isNil(contactInfo?.linkedContactList)) continue;

            for (const linkedContact of contactInfo!.linkedContactList) {
              if (linkedContact.type !== 'caregiver') continue;

              if (
                Utils.isInteger(linkedContact.dtEnd) ||
                !Utils.isInteger(linkedContact.dtStart) ||
                Utils.isNil(linkedContact.notifyOnNewMessage) ||
                !linkedContact.notifyOnNewMessage.includes('sms')
              ) {
                continue;
              }

              const caregiverPhoneNumber = Utils.trimOrDefault(linkedContact.cellNumber);
              if (caregiverPhoneNumber.length === 0) continue;

              const guestName = Utils.joinPersonName({ firstName: contactInfo!.firstName, lastName: contactInfo!.lastName });
              notificationList.push({
                id: 0, // will be replaced later when IDs are available
                message: `SigMail: ${guestName} has received a secure encrypted message in their mailbox.`,
                notifyAt: dtServer.toISOString(),
                phoneNumber: caregiverPhoneNumber
              });
            }
          } catch {
            /* ignore */
          }
        }

        if (!Utils.isString(phoneNumber) || phoneNumber.length === 0) continue;

        notificationList.push({
          id: 0, // will be replaced later when IDs are available
          message: 'SigMail: You have received a secure encrypted message in your mailbox.',
          notifyAt: dtServer.toISOString(),
          phoneNumber
        });
      }

      if (notificationList.length === 0) return Promise.resolve(EMPTY_ARRAY);

      const { ids: idRecord } = await this.dispatchFetchIdsByUsage({
        authState,
        state: AUTH_STATE_SEND_MESSAGE,
        ids: {
          ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_METADATA, count: notificationList.length }]
        }
      });

      return notificationList.map((item, index) =>
        this.dispatch(
          newNotificationAction({
            notificationList: [{ ...item, id: idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA][index] }]
          })
        ).catch((error) => {
          this.logger.warn('Error sending SMS notifications:', error);
          /* ignore */
        })
      );
    } catch (error) {
      this.logger.warn('Error sending SMS notifications:', error);
      return Promise.resolve(EMPTY_ARRAY);
    }
  }

  protected async sendNewMessagePushNotifications(): Promise<void> {
    this.logger.info('Sending push notifications to all recipients who do not have it disabled.');

    try {
      const { dtServer, roleAuthClaim: authState } = this.state;

      const notificationList = Utils.filterMap<
        BaseSendMessageState['recipientList'][0],
        Api.NewNotificationRequestData['notificationList'][0]
      >(this.state.recipientList, ({ entity: { webPushSubscriberId, type } }) => {
        if (type !== 'user' || !AppUser.isValidId(webPushSubscriberId)) return false;

        return {
          id: 0, // will be replaced later when IDs are available
          notifyAt: dtServer.toISOString(),
          payload: JSON.stringify(PushNotificationBuilder('NEW_MSG_NOTIFY').set('timestamp', dtServer.getTime()).build()),
          userId: webPushSubscriberId,
          webpush: true
        };
      });

      if (notificationList.length === 0) return;

      const { ids: idRecord } = await this.dispatchFetchIdsByUsage({
        authState,
        state: AUTH_STATE_SEND_MESSAGE,
        ids: {
          ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_METADATA, count: notificationList.length }]
        }
      });

      return this.dispatch(
        newNotificationAction({
          notificationList: notificationList.map((item, index) => ({
            ...item,
            id: idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA][index]
          }))
        })
      ).catch((error) => {
        this.logger.warn('Error sending push notifications:', error);
        /* ignore */
      });
    } catch (error) {
      this.logger.warn('Error sending push notifications:', error);
      /* ignore */
    }
  }

  protected async createTemplatedEmailRequestBody(
    { entity: recipient }: S['recipientList'][0],
    templateData?: Api.TemplatedEmailMessage['personalizations'][0]['dynamicTemplateData']
  ): Promise<Api.TemplatedEmailMessage | void> {
    if (recipient.type !== 'user') return;

    const emailAddress = Utils.trimOrDefault(recipient.emailAddress);
    if (emailAddress.length === 0) return;

    return {
      template_id: 'd-b20ca48db02d433eb825a22a88cec85d',
      from: { name: 'noreply@sigmail.ca', email: 'noreply@sigmail.ca' },
      personalizations: [
        {
          to: [{ name: emailAddress, email: emailAddress }],
          dynamicTemplateData: {
            [EmailTemplateParams.FirstName]: recipient.firstName,
            [EmailTemplateParams.LastName]: recipient.lastName,
            [EmailTemplateParams.LoginLink]: process.env.HOST_URL,
            ...templateData
          }
        }
      ]
    };
  }

  protected async sendNewMessageEmailNotifications(): Promise<ReadonlyArray<Promise<void>>> {
    this.logger.info('Sending email notifications (Inbox) to all recipients who do not have it disabled.');

    const promiseList: Array<Promise<void>> = [];
    for (const recipient of this.state.recipientList) {
      const requestBody = await this.createTemplatedEmailRequestBody(recipient);
      if (!Utils.isNonArrayObjectLike<Api.TemplatedEmailMessage>(requestBody)) continue;

      promiseList.push(
        this.dispatch(sendTemplatedEmailMessageAction(requestBody)).catch((error) => {
          this.logger.warn('Error sending email notification:', error);
          return Promise.resolve();
        })
      );
    }

    for (const {
      entity: { id: recipientId, role, type }
    } of this.state.recipientList) {
      if (type !== 'user' || !Utils.isGuestRole(role)) continue;

      try {
        const contactInfo = await this.getUserObjectValue(selectUserContactInfo, {
          fetch: true,
          type: process.env.USER_OBJECT_TYPE_CONTACT_INFO,
          userId: recipientId
        });
        if (Utils.isNil(contactInfo?.linkedContactList)) continue;

        for (const linkedContact of contactInfo!.linkedContactList) {
          if (linkedContact.type !== 'caregiver') continue;

          if (
            Utils.isInteger(linkedContact.dtEnd) ||
            !Utils.isInteger(linkedContact.dtStart) ||
            Utils.isNil(linkedContact.notifyOnNewMessage) ||
            !linkedContact.notifyOnNewMessage.includes('email')
          ) {
            continue;
          }

          const emailAddress = Utils.trimOrDefault(linkedContact.emailAddress);
          if (emailAddress.length === 0) continue;

          const emailTemplate: Api.TemplatedEmailMessage = {
            from: { name: 'noreply@sigmail.ca', email: 'noreply@sigmail.ca' },
            personalizations: [
              {
                to: [{ name: emailAddress, email: emailAddress }],
                dynamicTemplateData: {
                  [EmailTemplateParams.CaregiverFirstName]: linkedContact.firstName,
                  [EmailTemplateParams.CaregiverLastName]: linkedContact.lastName,
                  [EmailTemplateParams.FirstName]: contactInfo!.firstName,
                  [EmailTemplateParams.LastName]: contactInfo!.lastName,
                  [EmailTemplateParams.LoginLink]: process.env.HOST_URL
                }
              }
            ],
            template_id: 'd-f373d27d7754729bcab69fca8dbcc'
          };

          promiseList.push(
            this.dispatch(sendTemplatedEmailMessageAction(emailTemplate)).catch((error) => {
              this.logger.warn('Error sending email notification:', error);
              return Promise.resolve();
            })
          );
        }
      } catch {
        /* ignore */
      }
    }

    return promiseList;
  }

  protected async sendNewGroupMessageNotifications(): Promise<ReadonlyArray<Promise<void>>> {
    this.logger.info('Sending email and SMS notifications (Group Inbox) to all recipients who do not have it disabled.');

    const { dtServer, recipientList, roleAuthClaim: authState } = this.state;

    const groupIdList = Utils.filterMap<BaseSendMessageState['recipientList'][0], SigmailGroupId>(
      recipientList,
      ({ entity: { id, type } }) => type === 'group' && id
    );

    if (groupIdList.length === 0) return EMPTY_ARRAY;

    await this.dispatchFetchObjects({
      authState,
      userObjectsByType: groupIdList.map((groupId) => ({ type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO, userId: groupId }))
    }).catch(Utils.noop);

    const promiseList: Array<Promise<void>> = [];
    for (const groupId of groupIdList) {
      try {
        const contactInfo = await this.getUserObjectValue(groupContactInfoSelector, {
          type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO,
          userId: groupId
        });

        if (Utils.isNil(contactInfo)) continue;

        const { newMessageEmailNotificationList, newMessageSMSNotificationList, newMessageWebPushNotificationMap } = contactInfo;

        if (Utils.isNotNil(newMessageEmailNotificationList)) {
          for (const user of newMessageEmailNotificationList!) {
            promiseList.push(
              this.dispatch(
                sendTemplatedEmailMessageAction({
                  template_id: 'd-8475a63cd24d449db95c7020a5fcd350',
                  from: { name: 'noreply@sigmail.ca', email: 'noreply@sigmail.ca' },
                  personalizations: [
                    {
                      to: [{ name: user.emailAddress, email: user.emailAddress }],
                      dynamicTemplateData: {
                        [EmailTemplateParams.FirstName]: user.firstName,
                        [EmailTemplateParams.LastName]: user.lastName,
                        [EmailTemplateParams.LoginLink]: process.env.HOST_URL
                      }
                    }
                  ]
                })
              ).catch((error) => {
                this.logger.warn('Error sending email notification:', error);
                return Promise.resolve();
              })
            );
          }
        }

        type TNewNotificationRequestData = Api.NewNotificationRequestData['notificationList'][0];

        if (Utils.isNotNil(newMessageSMSNotificationList)) {
          const notificationList = Utils.filterMap<typeof newMessageSMSNotificationList[0], TNewNotificationRequestData>(
            contactInfo!.newMessageSMSNotificationList!,
            (entry) => {
              const phoneNumber = Utils.trimOrDefault(entry.cellNumber).replaceAll(/\D/g, '');
              return (
                phoneNumber.length > 0 && {
                  id: 0, // will be replaced later when IDs are available
                  message: 'SigMail: You have received a secure encrypted message in your SigMail shared inbox.',
                  notifyAt: dtServer.toISOString(),
                  phoneNumber
                }
              );
            }
          );

          if (notificationList.length > 0) {
            try {
              const { ids: idRecord } = await this.dispatchFetchIdsByUsage({
                authState,
                state: AUTH_STATE_SEND_MESSAGE,
                ids: {
                  ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_METADATA, count: notificationList.length }]
                }
              });

              promiseList.push(
                this.dispatch(
                  newNotificationAction({
                    notificationList: notificationList.map((item, index) => ({
                      ...item,
                      id: idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA][index]
                    }))
                  })
                ).catch((error) => {
                  this.logger.warn('Error sending SMS notifications:', error);
                  return Promise.resolve();
                })
              );
            } catch {
              /* ignore */
            }
          }
        }

        if (Utils.isNotNil(newMessageWebPushNotificationMap)) {
          const notificationList = Object.keys(newMessageWebPushNotificationMap!).map<TNewNotificationRequestData>((userId) => {
            const webPushSubscriberId = +userId;

            return {
              id: 0, // will be replaced later when IDs are available
              notifyAt: dtServer.toISOString(),
              payload: JSON.stringify(
                PushNotificationBuilder('NEW_MSG_NOTIFY').set('data', { group: true }).set('timestamp', dtServer.getTime()).build()
              ),
              userId: webPushSubscriberId,
              webpush: true
            };
          });

          if (notificationList.length > 0) {
            try {
              const { ids: idRecord } = await this.dispatchFetchIdsByUsage({
                authState,
                state: AUTH_STATE_SEND_MESSAGE,
                ids: {
                  ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_METADATA, count: notificationList.length }]
                }
              });

              promiseList.push(
                this.dispatch(
                  newNotificationAction({
                    notificationList: notificationList.map((item, index) => ({
                      ...item,
                      id: idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA][index]
                    }))
                  })
                ).catch((error) => {
                  this.logger.warn('Error sending push notifications:', error);
                  return Promise.resolve();
                })
              );
            } catch {
              /* ignore */
            }
          }
        }

        if (Utils.isNotNil(contactInfo?.newMessageWebPushNotificationMap)) {
          const notificationList = Object.keys(contactInfo!.newMessageWebPushNotificationMap!).map<
            Api.NewNotificationRequestData['notificationList'][0]
          >((userId) => {
            const webPushSubscriberId = +userId;

            return {
              id: 0, // will be replaced later when IDs are available
              notifyAt: dtServer.toISOString(),
              payload: JSON.stringify(
                PushNotificationBuilder('NEW_MSG_NOTIFY').set('data', { group: true }).set('timestamp', dtServer.getTime()).build()
              ),
              userId: webPushSubscriberId,
              webpush: true
            };
          });

          if (notificationList.length > 0) {
            const { ids: idRecord } = await this.dispatchFetchIdsByUsage({
              authState: this.state.roleAuthClaim,
              state: AUTH_STATE_SEND_MESSAGE,
              ids: {
                ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_METADATA, count: notificationList.length }]
              }
            });

            promiseList.push(
              this.dispatch(
                newNotificationAction({
                  notificationList: notificationList.map((item, index) => ({
                    ...item,
                    id: idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA][index]
                  }))
                })
              ).catch((error) => {
                this.logger.warn('Error sending push notifications:', error);
                return Promise.resolve();
              })
            );
          }
        }
      } catch (error) {
        this.logger.warn('Error sending email notification:', error);
        /* ignore */
      }
    }

    return promiseList;
  }

  protected async sendNewMessageNotifications(): Promise<ReadonlyArray<Promise<void>>> {
    return [
      ...(await this.sendNewMessageSMSNotifications()),
      this.sendNewMessagePushNotifications(),
      ...(await this.sendNewMessageEmailNotifications()),
      ...(await this.sendNewGroupMessageNotifications())
    ];
  }

  private async sendMessageToEMR(): Promise<void> {
    if (this.payload.sendToEMR !== true) return;
    this.logger.info('Sending message to EMR.');

    const { documentList, msgBodyId, msgMetadata, msgMetadataId, recipientList } = this.state;

    const guestUserId = recipientList.find(({ entity: { role } }) => Utils.isGuestRole(role))?.entity.id;
    return this.dispatch(
      sendDataToEmrAction({
        data: {
          body: msgBodyId,
          documentList,
          header: msgMetadataId,
          messageForm: msgMetadata.messageForm,
          subject: msgMetadata.subjectLine
        },
        guestUserId,
        onRefreshAccessToken: ({ authUrl }, done) => {
          const windowHandle = window.open(authUrl, 'EMRAuthWindow', 'innerHeight=480,innerWidth=640');
          if (Utils.isNil(windowHandle)) return done();

          windowHandle.focus();
          const timerHandle = setInterval(() => {
            if (windowHandle!.closed) {
              clearInterval(timerHandle);
              done();
            }
          });
        },
        onSelectPatientRecord: (_, done) => done(),
        onSelectProviderFolder: (_, done) => done(this.payload.selectedProviderFolder)
      })
    );
  }

  private async sendMessageToHRM() {
    const { documentList, messageBody: body, sendToHRM } = this.payload;
    if (sendToHRM !== true) return;

    this.logger.info('Sending message to HRM');

    const { accessToken, clientId, currentUser, dtServer, msgBodyId, recipientList } = this.state;

    const userProtectedProfile = await this.getUserObjectValue(selectUserProfileProtected, {
      type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
      userId: currentUser.id
    });

    const hrmUserList = await this.dispatch(hrmGetUserListAction());
    const hrmUserReceiver = hrmUserList.find(({ id }) => id === userProtectedProfile?.licenseNumber);
    if (Utils.isNil(hrmUserReceiver)) throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID);

    const guestUserId = recipientList.find(({ entity: { role } }) => Utils.isGuestRole(role))?.entity.id;
    if (!AppUser.isValidId(guestUserId)) throw new AppException(Constants.Error.S_ERROR);

    const basicProfile = await this.getUserObjectValue(selectUserProfileBasic, {
      type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC,
      userId: guestUserId
    });

    const protectedProfile = await this.getUserObjectValue(selectUserProfileProtected, {
      type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
      userId: guestUserId
    });

    if (Utils.isNil(basicProfile) || Utils.isNil(protectedProfile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID);
    }

    const dtBirth = Utils.dateOrDefault(Utils.isString(protectedProfile.birthDate) && new Date(protectedProfile.birthDate), null!);
    const dtToday = startOfDay(dtServer);

    const { addressLevel1, addressLevel2, addressLine1, addressLine2, postalCode } = basicProfile;
    let addressLevel1Uppercased = addressLevel1?.toUpperCase();
    if (addressLevel1Uppercased?.startsWith('ONT')) addressLevel1Uppercased = 'ON';

    const values: ActionData['formValues'] = {
      address: Utils.joinPostalAddress({ addressLine1, addressLine2 }),
      addressType: HrmConstants.DEFAULT_ADDRESS_TYPE,
      birthDate: dtBirth,
      category: HrmConstants.DEFAULT_DIAGNOSIS_CATEGORY,
      city: Utils.stringOrDefault(addressLevel2),
      conclusion: '',
      contactNumber: Utils.stringOrDefault(basicProfile.cellNumber),
      contactType: HrmConstants.DEFAULT_CONTACT_TYPE,
      deceased: HrmConstants.DEFAULT_DECEASED_STATUS,
      diagnosisStatus: HrmConstants.DEFAULT_DIAGNOSIS_STATUS,
      effectiveDate: dtToday,
      encounterClass: HrmConstants.DEFAULT_ENCOUNTER_CLASS,
      encounterStatus: HrmConstants.DEFAULT_ENCOUNTER_STATUS,
      endDate: dtToday,
      firstName: basicProfile.firstName,
      gender: Utils.stringOrDefault(protectedProfile.gender, HrmConstants.DEFAULT_GENDER),
      hpn: Utils.stringOrDefault(protectedProfile.healthCardNumber),
      issuedDate: dtToday,
      lastName: basicProfile.lastName,
      loincType: HrmConstants.DEFAULT_DIAGNOSIS_LOINC_TYPE,
      notes: (body as ReadonlyMessageBodyDefault).data,
      postalCode: Utils.stringOrDefault(postalCode),
      province: Utils.stringOrDefault(addressLevel1Uppercased),
      recipient: [hrmUserReceiver as ActionData['formValues']['recipient'][0]],
      startDate: dtToday
    };

    const messageBody = createMessageBody(values, hrmUserList, hrmUserReceiver, guestUserId);
    const pdfFile = await createPdfFile(Utils.arrayOrDefault(documentList), messageBody.notes, new DataObjectCache());
    const dataUri = parseDataUri(await readBlobAsDataUri(pdfFile));

    messageBody.document.docs.push({
      title: pdfFile.name,
      contentType: Constants.MimeType.PDF,
      language: 'en',
      createdDate: dtServer.toISOString(),
      data: dataUri.data
    });

    const { notes, ...msgBody } = messageBody;

    const patientAddress = messageBody.patient.address.map((address) => ({
      ...address,
      line: address.line[0].length === 0 ? ['NA'] : address.line,
      city: address.city.length === 0 ? 'NA' : address.city,
      postalCode: address.postalCode.length === 0 ? 'M1M1M1' : address.postalCode
    }));

    const requestData: Api.HrmSendReportRequestData = {
      ...msgBody,
      clientId,
      patient: {
        ...messageBody.patient,
        address: patientAddress
      } as Api.HrmSendReportRequestData['patient'],
      requestId: msgBodyId,
      userId: currentUser.id
    };

    return this.apiService.hrmSendReport(accessToken, requestData);
  }

  protected async onBatchUpdateDataSuccess<T = void>(): Promise<T> {
    const promiseList = await this.sendNewMessageNotifications();
    await Promise.allSettled([this.sendMessageToHRM(), this.sendMessageToEMR(), ...promiseList]);

    return (Promise.resolve() as unknown) as Promise<T>;
  }
}
