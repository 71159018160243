import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import {
  AppException,
  Constants,
  MessagingException,
  ReadonlyMessageBodyEvent,
  SigmailObjectId,
  SigmailUserId,
  Utils,
  Writeable
} from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedNotificationObject,
  CalendarEventMetadata,
  CalendarEventRecord,
  DataObjectCalendarEvent,
  DataObjectCalendarEventValue,
  DataObjectMsgBody,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  DataObjectMsgReadReceipt,
  DataObjectMsgReadReceiptValue,
  EventLogRecord,
  GroupObjectProfileBasicValue,
  IDataObject,
  IUserObject,
  MessageFolderItemCount,
  MessageFolderListItem,
  NotificationObjectRecallMessage,
  NotificationObjectRecallMessageValue,
  UserObjectScheduleValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { Set as ImmutableSet } from 'immutable';
import { AppThunk } from '../..';
import { MessageFlags } from '../../../app/messaging/utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { calendarEventEndTime } from '../../../utils/calendar-event-end-time';
import { calendarEventStartTime } from '../../../utils/calendar-event-start-time';
import { dateToUtcValues } from '../../../utils/date-to-utc-values';
import { addEventToDismissedList } from '../../reminder-notification-slice';
import { selectCanRecallMessage } from '../../selectors/auth';
import { basicProfileObjectSelector as groupBasicProfileObjectSelector } from '../../selectors/group-object';
import { scheduleObjectSelector as userScheduleObjectSelector } from '../../selectors/user-object';
import { UserObjectCache } from '../../user-objects-slice/cache';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';
import { AUTH_STATE_LOG_EVENT, AUTH_STATE_RECALL_MESSAGE } from '../constants/auth-state-identifier';
import { logEventAction } from '../log-event-action';
import { cancelNotificationAction } from '../notifications/cancel-notification-action';
import { updateMessageFolderAction } from './update-msg-folder-action';

type Payload = MessagingActionPayload.RecallMessage;

interface State extends AuthenticatedActionState {
  batchUpdateAuthState: string;
  batchUpdateClaims: Array<string>;
  calendarEventRecord: Partial<Record<SigmailUserId, CalendarEventRecord>>;
  dtServer: Date;
  groupProfile: GroupObjectProfileBasicValue;
  msgMetadataIdSet: ImmutableSet<SigmailObjectId>;
  logRecordList: Array<EventLogRecord>;
  notificationJsonList: Array<ApiFormattedNotificationObject>;
  reminderIdListToCancel: ReadonlyArray<SigmailObjectId>;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

type MarkMessageObjectsExpiredResult = [
  msgMetadataObject: IDataObject<DataObjectMsgMetadataValue>,
  msgBodyObject: IDataObject<DataObjectMsgBodyValue>,
  msgReadReceiptObject: IDataObject<DataObjectMsgReadReceiptValue>,
  calendarEventObject: IDataObject<DataObjectCalendarEventValue>
];

class RecallMessagesAction extends AuthenticatedAction<Payload, State> {
  protected async onExecute() {
    const { folderKey, msgMetadataId } = this.payload;
    if (folderKey !== Constants.MessageFolderKey.Sent) {
      throw new AppException(Constants.Error.S_ERROR, 'Currently, recall is only allowed in SENT message folder.');
    }

    // get rid of duplicate metadata IDs (if any)
    this.state.msgMetadataIdSet = ImmutableSet(Utils.isArray<SigmailObjectId>(msgMetadataId) ? msgMetadataId : [msgMetadataId]);
    if (this.state.msgMetadataIdSet.isEmpty()) {
      this.logger.info('No items to recall; call ignored.');
      return;
    }

    // make sure all of the metadata IDs supplied are valid
    const firstInvalidId = this.state.msgMetadataIdSet.find((id) => !DataObjectMsgMetadata.isValidId(id));
    if (!Utils.isUndefined(firstInvalidId)) {
      throw new MessagingException(
        Constants.Error.E_MESSAGING_FAIL_MSG_METADATA_ID,
        `Message metadata ID <${firstInvalidId}> is not valid.`
      );
    }

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchRecallMessageWindow();

        await this.generateRequestBody();

        const { requestBody, batchUpdateClaims: claims, batchUpdateAuthState: authState, successPayload } = this.state;
        const mutations = requestBody.build();
        if (
          Utils.isNonEmptyArray(mutations.dataObjects) ||
          Utils.isNonEmptyArray(mutations.userObjects) ||
          Utils.isNonEmptyArray(mutations.notificationObjects)
        ) {
          await this.dispatchBatchUpdateData({ authState, claims, ...mutations });

          try {
            await this.dispatchBatchQueryDataSuccess(successPayload);
          } catch (error) {
            this.logger.warn('Error manually updating app state:', error);
            /* ignore */
          }

          if (Utils.isNonEmptyArray<number>(this.state.reminderIdListToCancel)) {
            try {
              const ids = this.state.reminderIdListToCancel as Array<number>;
              await this.dispatch(cancelNotificationAction({ ids }));
            } catch (error) {
              this.logger.warn('Error cancelling event reminders:', error);
              /* ignore */
            }
          }
        } else {
          this.logger.warn('Call ignored; nothing to update.');
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; batch update operation will be retried.');
        this.state.calendarEventRecord = {};
        this.state.notificationJsonList = [];
      }
    }
  }

  private async fetchRecallMessageWindow(): Promise<void> {
    this.logger.info('Fetching latest user access rights.');

    const {
      roleAuthClaim: authState,
      currentUser: { id: userId }
    } = this.state;

    const { serverDateTime } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS, userId }]
    });

    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
  }

  private async generateRequestBody(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;

    this.state.batchUpdateClaims = [];
    this.state.calendarEventRecord = {};
    this.state.logRecordList = [];

    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };

    await this.dispatch(
      updateMessageFolderAction({
        folderKey,
        parentFolderKey,
        requestBody: this.state.requestBody,
        successPayload: this.state.successPayload,
        applyUpdate: this.applyMessageFolderUpdate.bind(this)
      })
    );

    const updatedScheduleObjectList = await Promise.all(
      Utils.transform(
        this.state.calendarEventRecord,
        (list, calendarEventRecord, attendeeId) => {
          const scheduleObject = userScheduleObjectSelector(this.getRootState())(+attendeeId);
          const scheduleValue = UserObjectCache.getValue(scheduleObject);
          if (Utils.isNil(scheduleObject) || Utils.isNil(scheduleValue)) {
            throw new MessagingException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Schedule object is either missing or invalid.');
          }

          const updatedValue: UserObjectScheduleValue = { ...scheduleValue, events: calendarEventRecord! };
          list.push(scheduleObject.updateValue(updatedValue));
        },
        [] as Array<Promise<IUserObject<UserObjectScheduleValue>>>
      )
    );

    if (updatedScheduleObjectList.length > 0) {
      this.state.requestBody.update(updatedScheduleObjectList);

      for (const updatedScheduleObject of updatedScheduleObjectList) {
        this.state.successPayload.request.userObjects!.ids.push(updatedScheduleObject.id);
        this.state.successPayload.response.userObjects!.push(updatedScheduleObject.toApiFormatted());
      }
    }

    await this.addInsertOperationForRecallMessageNotifications(); // 502s
    await this.addInsertOperationForUserEventLog(); // 425
  }

  private async applyMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    itemCount: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ) {
    const { msgMetadataIdSet, dtServer, currentUser } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    const idsFound: Array<SigmailObjectId> = [];
    for (const msgMetadataId of msgMetadataIdSet) {
      this.logger.info(`Locating message with metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);

      const index = folderData.findIndex(({ header }) => header === msgMetadataId);
      if (index === -1) continue;
      idsFound.push(msgMetadataId);

      const message = folderData[index];
      let { timestamp } = message;
      const msgFlags = MessageFlags(message);
      const { isMessageFormDefault, isMessageFormEvent, isRecalled } = msgFlags;

      // already marked as recalled?
      if (isRecalled) {
        this.logger.warn('Message is already marked as recalled; skipped.');
        continue;
      }

      // make sure message is of form <default> or <event>
      if (!isMessageFormDefault && !isMessageFormEvent) {
        const expected = [Constants.MessageFormName.Default, Constants.MessageFormName.Event];
        this.logger.warn(`Expected messageFormName to be one of <${expected.join('>, <')}>; was <${message.messageForm?.name}>.`);
        continue;
      }

      if (!isMessageFormEvent) {
        // make sure message can still be recalled
        const sentAtUtc = Utils.isInteger(timestamp) ? timestamp : timestamp.createdAt!;
        const canRecallMessage = selectCanRecallMessage(this.getRootState());
        if (!canRecallMessage(dtServer, sentAtUtc)) {
          this.logger.warn("Message's recall window is closed; skipped.");
          continue;
        }
      }

      const [msgMetadataObject, msgBodyObject, msgReadReceiptObject, calendarEventObject] = await this.markMessageDataObjectsExpired(
        message
      );

      if (
        Utils.isNil(msgMetadataObject) ||
        Utils.isNil(msgBodyObject) ||
        Utils.isNil(msgReadReceiptObject) ||
        (isMessageFormEvent && Utils.isNil(calendarEventObject))
      ) {
        continue;
      }

      // prepare a recall message notification for each recipient
      let { recipientList } = await msgMetadataObject.decryptedValue();

      let eventData: Writeable<Pick<NotificationObjectRecallMessageValue, 'eventTime' | 'eventObjectId'>> = {};
      if (isMessageFormEvent) {
        const calendarEvent = await calendarEventObject.decryptedValue();
        eventData.eventObjectId = calendarEventObject.id;
        eventData.eventTime = calendarEventStartTime(calendarEvent);

        const isUserInAttendeeList = calendarEvent.extendedProps.attendeeList.find(
          ({ id, type }) => id === currentUser.id && type === currentUser.type
        );
        if (!calendarEvent.allDay && isUserInAttendeeList) {
          this.dispatch(addEventToDismissedList([calendarEventObject.id]));
        }

        recipientList = recipientList.filter(({ entity: { id: recipientId } }) => recipientId !== currentUser.id);
      }

      if (recipientList.length > 0) {
        this.state.notificationJsonList = Utils.arrayOrDefault(this.state.notificationJsonList);

        const value: NotificationObjectRecallMessageValue = {
          $$formatver: 1,
          header: msgMetadataObject.id,
          body: msgBodyObject.id,
          readReceipt: msgReadReceiptObject.id,
          ...eventData
        };

        for (const { entity: recipient } of recipientList) {
          const notificationObject = await NotificationObjectRecallMessage.create(
            1, // we will patch it later in addInsertOperationForRecallMessageNotifications method
            undefined, // code
            0, // version
            value,
            recipient.id,
            currentUser.id,
            0, // encryptedFor
            dtServer
          );

          this.state.notificationJsonList.push(notificationObject.toApiFormatted());
        }
      }

      const { isMarkedAsRead, isImportant, isBillable, hasReminder } = msgFlags;
      const { all, important, reminder, billable } = itemCount;
      all.unread -= +!isMarkedAsRead;
      important.unread -= +(isImportant && !isMarkedAsRead);
      billable.unread -= +(isBillable && !isMarkedAsRead);
      reminder.unread -= +(hasReminder && !isMarkedAsRead);

      const recalledAt = dtServer.getTime();
      const firstReadAt = Utils.numberOrDefault(!Utils.isInteger(timestamp) && timestamp.firstReadAt, dtServer.getTime());
      if (Utils.isInteger(timestamp)) {
        timestamp = { createdAt: timestamp, firstReadAt, recalledAt };
      } else {
        timestamp = { ...timestamp, firstReadAt, recalledAt };
      }

      // eslint-disable-next-line require-atomic-updates
      folderData[index] = {
        ...message,
        timestamp,
        flags: Utils.omit({ ...message.flags, markedAsRead: true /* a recalled message cannot be in an unread state */ }, 'recalled')
      };

      result.updated = true;

      this.state.logRecordList.push(this.newEventLogRecordValue(dtServer, Constants.EventLogCode.MessageRecalled, message));
    }

    this.state.msgMetadataIdSet = msgMetadataIdSet.subtract(idsFound);

    result.done = msgMetadataIdSet.isEmpty();
    return result;
  }

  private async markMessageDataObjectsExpired({
    header: msgMetadataId,
    body: msgBodyId,
    messageForm
  }: MessageFolderListItem): Promise<MarkMessageObjectsExpiredResult> {
    const { accessToken, dtServer, requestBody, roleAuthClaim: authState, successPayload } = this.state;

    let msgMetadataObject: IDataObject<DataObjectMsgMetadataValue> | undefined;
    let msgBodyObject: IDataObject<DataObjectMsgBodyValue> | undefined;
    let msgReadReceiptObject: IDataObject<DataObjectMsgReadReceiptValue> | undefined;
    let calendarEventObject: IDataObject<DataObjectCalendarEventValue> | undefined;

    const result: MarkMessageObjectsExpiredResult = [msgMetadataObject!, msgBodyObject!, msgReadReceiptObject!, calendarEventObject!];

    let query: FetchObjectsRequestData = {
      authState,
      dataObjects: { ids: [msgMetadataId, msgBodyId] },
      expectedCount: { dataObjects: null }
    };

    let { dataObjectList } = await this.fetchObjects(accessToken, query);
    const msgMetadataJson = this.findDataObject(dataObjectList, { id: msgMetadataId });
    const msgBodyJson = this.findDataObject(dataObjectList, { id: msgBodyId });
    if (Utils.isNotNil(msgMetadataJson)) msgMetadataObject = new DataObjectMsgMetadata(msgMetadataJson);
    if (Utils.isNotNil(msgBodyJson)) msgBodyObject = new DataObjectMsgBody(msgBodyJson);

    if (Utils.isNil(msgMetadataObject) || Utils.isNil(msgBodyObject)) {
      this.logger.warn('Message metadata and/or body object could not be fetched.');
      return result;
    }

    result[0] = msgMetadataObject;
    result[1] = msgBodyObject;

    const { readReceiptId, documentList } = await msgMetadataObject.decryptedValue();

    query = {
      authState,
      dataObjects: { ids: [readReceiptId] },
      expectedCount: { dataObjects: null }
    };

    dataObjectList = (await this.fetchObjects(accessToken, query)).dataObjectList;
    const msgReadReceiptJson = this.findDataObject(dataObjectList, { id: readReceiptId });
    if (Utils.isNotNil(msgReadReceiptJson)) msgReadReceiptObject = new DataObjectMsgReadReceipt(msgReadReceiptJson);

    if (Utils.isNil(msgReadReceiptObject)) {
      this.logger.warn('Message read receipt object could not be fetched.');
      return result;
    } else {
      result[2] = msgReadReceiptObject;
    }

    const { isMessageFormEvent } = MessageFlags({ messageForm });
    if (isMessageFormEvent) {
      const msgBody = await msgBodyObject.decryptedValue();
      const { eventObjectId } = (msgBody as ReadonlyMessageBodyEvent).messageForm.value;

      query = {
        authState,
        dataObjects: { ids: [eventObjectId] },
        expectedCount: { dataObjects: null, claims: 1 }
      };

      const response = await this.fetchObjects(accessToken, query);
      dataObjectList = response.dataObjectList;
      if (Utils.isNonEmptyArray<string>(response.claims)) {
        this.state.batchUpdateClaims.push(...response.claims);
      }

      const calendarEventJson = this.findDataObject(dataObjectList, { id: eventObjectId });
      calendarEventObject = Utils.isNil(calendarEventJson) ? undefined : new DataObjectCalendarEvent(calendarEventJson);
      if (Utils.isNil(calendarEventObject)) {
        this.logger.warn('Calendar event object could not be fetched.');
        return result;
      }

      const calendarEvent = await calendarEventObject.decryptedValue();
      const dtEventEnd = new Date(calendarEventEndTime(calendarEvent));
      if (dtServer > dtEventEnd) {
        this.logger.warn(`Message's recall window closed on ${dtEventEnd.toISOString()}; skipped.`);
        return result;
      }

      this.state.reminderIdListToCancel = Utils.flatten(
        calendarEvent.extendedProps.attendeeList.map(({ reminderList }) =>
          Utils.arrayOrDefault<NonNullable<typeof reminderList>[0]>(reminderList)
        )
      ).map(({ id }) => id);

      await this.updateCircleOfCareGroupCalendarEventRecords(eventObjectId, calendarEvent);

      result[3] = calendarEventObject;

      const { msgMetadataId: _m, msgBodyId: _b, ...extendedProps } = calendarEvent.extendedProps;
      const updatedValue: DataObjectCalendarEventValue = {
        ...calendarEvent,
        extendedProps: { ...extendedProps, dtCancelled: dtServer.getTime() }
      };
      const updatedObject = await calendarEventObject.updateValue(updatedValue);
      requestBody.update(updatedObject);

      successPayload.request.dataObjects!.ids.push(updatedObject.id);
      successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
    }

    requestBody.expire(result.slice(0, 3), dtServer);

    // XXX: because we are not pushing the corresponding data objects in
    // successPayload's response, they will be removed from app state if present
    successPayload.request.dataObjects!.ids.push(msgMetadataId, msgBodyId, readReceiptId);
    if (Utils.isNonEmptyArray(documentList)) {
      // also include in this list the metadata and body ID of each attached
      // document so that they're also removed from app state if present
      successPayload.request.dataObjects!.ids.push(...Utils.flatten(documentList.map(({ metadata, body }) => [metadata, body])));
    }

    return result;
  }

  private async updateCircleOfCareGroupCalendarEventRecords(
    eventObjectId: number,
    eventObjectValue: DataObjectCalendarEventValue
  ): Promise<void> {
    const { activeGroupId, roleAuthClaim: authState } = this.state;

    let { groupProfile } = this.state;
    if (Utils.isNil(groupProfile)) {
      groupProfile = (await this.getUserObjectValue(groupBasicProfileObjectSelector, {
        type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC,
        userId: activeGroupId
      }))!;

      if (Utils.isNil(groupProfile)) {
        throw new MessagingException("Group's basic profile is either missing or invalid.");
      }

      this.state.groupProfile = groupProfile;
    }

    const dtEventStart = calendarEventStartTime(eventObjectValue);
    const [utcYear, utcMonth, utcDate] = dateToUtcValues(dtEventStart);

    for (const { id: attendeeId } of eventObjectValue.extendedProps.attendeeList) {
      const isAttendeeGroupMember = groupProfile.memberList.some(({ id: userId }) => userId === attendeeId);
      if (!isAttendeeGroupMember) continue;

      let calendarEventRecord = this.state.calendarEventRecord[attendeeId];
      let dayEventList: Array<CalendarEventMetadata> | undefined = undefined;
      if (Utils.isNil(calendarEventRecord)) {
        const { claims } = await this.dispatchFetchObjects({
          authState,
          userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_SCHEDULE, userId: attendeeId }],
          expectedCount: { claims: 1 }
        });

        if (Utils.isNonEmptyArray<string>(claims)) {
          this.state.batchUpdateClaims.push(...claims);
        }

        const scheduleObject = userScheduleObjectSelector(this.getRootState())(attendeeId);
        const scheduleValue = UserObjectCache.getValue(scheduleObject);
        if (Utils.isNotNil(scheduleValue)) {
          calendarEventRecord = { ...(scheduleValue.events as CalendarEventRecord) };
          dayEventList = calendarEventRecord[utcYear]?.[utcMonth]?.[utcDate]?.slice();
        }
      } else {
        dayEventList = calendarEventRecord[utcYear]?.[utcMonth]?.[utcDate];
      }

      if (Utils.isNil(calendarEventRecord)) {
        throw new MessagingException('Schedule object is either missing or invalid.');
      } else {
        this.state.calendarEventRecord[attendeeId] = calendarEventRecord;

        if (Utils.isNonEmptyArray<NonNullable<typeof dayEventList>[0]>(dayEventList)) {
          const index = dayEventList.findIndex(({ eventObjectId: id }) => id === eventObjectId);
          if (index > -1) {
            dayEventList[index] = { ...dayEventList[index], cancelled: true };
            calendarEventRecord[utcYear] = {
              ...calendarEventRecord[utcYear]!,
              [utcMonth]: {
                ...calendarEventRecord[utcYear]![utcMonth]!,
                [utcDate]: dayEventList
              }
            };
          }
        }
      }
    }
  }

  private async addInsertOperationForRecallMessageNotifications(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for recall message notifications.');

    const { roleAuthClaim: authState, notificationJsonList, requestBody } = this.state;

    let ids: Api.GetIdsRequestData['ids']['ids'];
    if (Utils.isNonEmptyArray<ApiFormattedNotificationObject>(notificationJsonList)) {
      ids = [{ type: process.env.NOTIFICATION_OBJECT_TYPE_RECALL_MESSAGE, count: notificationJsonList.length }];
    }

    if (Utils.isArray<NonNullable<typeof ids>[0]>(ids)) {
      const query: Api.GetIdsRequestData = { authState, state: AUTH_STATE_RECALL_MESSAGE, ids: { ids } };
      const { authState: batchUpdateAuthState, idsClaim, ids: idRecord } = await this.dispatchFetchIdsByUsage(query);
      this.state.batchUpdateAuthState = batchUpdateAuthState;
      this.state.batchUpdateClaims.push(idsClaim);

      notificationJsonList.forEach((apiFormattedObject, index) => {
        const notificationJson: ApiFormattedNotificationObject = {
          ...apiFormattedObject,
          id: idRecord[process.env.NOTIFICATION_OBJECT_TYPE_RECALL_MESSAGE][index]
        };

        requestBody.insert(new NotificationObjectRecallMessage(notificationJson));
      });
    } else {
      const query: Api.EnterStateRequestData = { authState, state: AUTH_STATE_RECALL_MESSAGE };
      const { authState: batchUpdateAuthState } = await this.dispatchEnterState(query);
      this.state.batchUpdateAuthState = batchUpdateAuthState;
    }
  }

  private async addInsertOperationForUserEventLog(): Promise<void> {
    if (!Utils.isNonEmptyArray<EventLogRecord>(this.state.logRecordList)) {
      return;
    }

    this.logger.info('Adding an insert operation to request body for user event log.');

    const { currentUser, dtServer, logRecordList, requestBody, roleAuthClaim: authState, successPayload } = this.state;

    const claims = await this.dispatch(
      logEventAction({
        dtServer,
        fetchIds: (count) => {
          return this.dispatchFetchIdsByUsage({
            authState,
            state: AUTH_STATE_LOG_EVENT,
            ids: { ids: [{ type: process.env.DATA_OBJECT_TYPE_EVENT_LOG, count }] }
          });
        },
        logger: this.logger,
        record: logRecordList,
        requestBody,
        successPayload,
        userId: currentUser.id,
        userIdType: 'user'
      })
    );

    this.state.batchUpdateClaims.push(...claims);
  }
}

export const recallMessagesAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'recallMessagesAction:');

    const action = new RecallMessagesAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
