import { Utils } from '@sigmail/common';
import {
  DataObjectMsgFolder,
  DataObjectMsgFolderExt,
  DataObjectMsgFolderExtValue,
  DataObjectMsgFolderValue,
  IDataObject
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import React from 'react';
import { DataObjectCache } from '../data-objects-slice/cache';
import { useDispatchFetchDataObjects, UseDispatchFetchDataObjectsParam } from './use-dispatch-fetch-data-objects';

const msgFolderDataReducer = (
  accumulator: Array<DataObjectMsgFolderValue | DataObjectMsgFolderExtValue>,
  folderId: number,
  queryDispatcher: ReturnType<typeof useDispatchFetchDataObjects>,
  resolve: (value: Array<DataObjectMsgFolderValue | DataObjectMsgFolderExtValue>) => void,
  reject: (reason?: any) => void
): void => {
  const msgFolder = DataObjectCache.getValue<IDataObject<DataObjectMsgFolderExtValue>>(folderId);
  if (Utils.isNotNil(msgFolder)) {
    accumulator.push(msgFolder);
    if (msgFolder.next === null) {
      resolve(accumulator);
    } else {
      msgFolderDataReducer(accumulator, msgFolder.next, queryDispatcher, resolve, reject);
    }
    return;
  }

  const query: UseDispatchFetchDataObjectsParam = { ids: [folderId] };
  queryDispatcher(query).then((response) => {
    if (
      !Utils.isNonEmptyArray<NonNullable<typeof response.dataObjects>[0]>(response.dataObjects) ||
      response.dataObjects.length !== 1
    ) {
      reject(new Api.MalformedResponseException());
      return;
    }

    const [dataObjectJson] = response.dataObjects;
    let msgFolderObject: IDataObject<DataObjectMsgFolderValue> | IDataObject<DataObjectMsgFolderExtValue>;
    if (dataObjectJson.type === process.env.DATA_OBJECT_TYPE_MSG_FOLDER) {
      msgFolderObject = new DataObjectMsgFolder(dataObjectJson);
    } else {
      msgFolderObject = new DataObjectMsgFolderExt(dataObjectJson);
    }

    msgFolderObject.decryptedValue().then((msgFolderOrExt) => {
      accumulator.push(msgFolderOrExt);
      if (msgFolderOrExt.next !== null) {
        msgFolderDataReducer(accumulator, msgFolderOrExt.next, queryDispatcher, resolve, reject);
        return;
      }

      resolve(accumulator);
    }, reject);
  });
};

export const useDispatchFetchMsgFolders = () => {
  const dispatchFetchDataObjects = useDispatchFetchDataObjects();

  return React.useCallback(
    (folderId: number) => {
      return new Promise<Array<DataObjectMsgFolderValue | DataObjectMsgFolderExtValue>>((resolve, reject) => {
        msgFolderDataReducer([], folderId, dispatchFetchDataObjects, resolve, reject);
      });
    },
    [dispatchFetchDataObjects]
  );
};
