// ============================================================================
// IMPORTANT - .json/.md files under %PUBLIC_URL%/locale/{{lng}}/ directory
// must have the same names as the namespace names defined below
//
export const I18N_NS_ACCOUNT: string = 'account';
export const I18N_NS_ACCOUNT_SETUP: string = 'account-setup';
export const I18N_NS_CALENDAR_EVENT_FORM: string = 'calendar-event-form';
export const I18N_NS_CARE_PLAN: string = 'care-plan';
export const I18N_NS_CONTACT_LIST: string = 'contact-list';
export const I18N_NS_CONTACT_US_FORM: string = 'contact-us-form';
export const I18N_NS_DASHBOARD: string = 'dashboard';
export const I18N_NS_CONSULTATION_REQUEST_DIAGNOSTIC_CODE: string = 'consultation-request-diagnostic-code';
export const I18N_NS_FAQ: string = 'faq';
export const I18N_NS_GLOBAL: string = 'global';
export const I18N_NS_GROUPS: string = 'groups';
export const I18N_NS_HEALTH_DATA: string = 'health-data';
export const I18N_NS_HRM: string = 'hrm';
export const I18N_NS_MEMBERS: string = 'members';
export const I18N_NS_MESSAGING: string = 'messaging';
export const I18N_NS_MFA: string = 'mfa';
export const I18N_NS_PRIVACY_POLICY: string = 'privacy-policy';
export const I18N_NS_RESET_PASSWORD: string = 'reset-password';
export const I18N_NS_SCHEDULING: string = 'scheduling';
export const I18N_NS_TERMS_AND_CONDITIONS: string = 'terms-conditions';
//
// ============================================================================
