import { Constants, MessageSender, ReadonlyMessageBodyHealthDataRequest, Utils } from '@sigmail/common';
import { ContactListItem } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { sendMessageAction } from '../../../../app-state/actions/messaging/send-message-action';
import { useAppState } from '../../../../app-state/hooks';
import { activeGroupIdSelector } from '../../../../app-state/selectors';
import { FormValues } from '../../../health-data/forms/health-data-request.component';
import { ActionData } from '../../types';

export interface ActionDataSendHealthDataRequest extends ActionData, FormValues {
  recipient: ContactListItem;
  sender: MessageSender;
}

export const useSendHealthDataRequestActionHandler = () => {
  const [, appDispatch] = useAppState();
  const activeGroupId = useSelector(activeGroupIdSelector);

  return React.useCallback(
    async ({
      dataForm: form,
      endDate,
      failCallback,
      frequency,
      note,
      recipient,
      sender,
      startDate,
      successCallback,
      weekdays
    }: ActionDataSendHealthDataRequest) => {
      // NOTE: subject line is intentionally not translated
      const subjectLine = 'Request to submit questionnaire';

      note = Utils.trimOrDefault(note);
      const messageBody: ReadonlyMessageBodyHealthDataRequest = {
        messageForm: {
          name: Constants.MessageFormName.HealthDataRequest,
          value: {
            form,
            frequency: frequency === '' ? undefined : frequency,
            note: note.length === 0 ? undefined : note,
            replyToGroupId: activeGroupId,
            start: startDate.getTime(),
            until: endDate?.getTime(),
            weekdays
          }
        }
      };

      let value: any;

      try {
        value = await appDispatch(
          sendMessageAction({
            sender,
            subjectLine,
            primaryRecipientList: [recipient],
            secondaryRecipientList: [],
            messageBody
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [activeGroupId, appDispatch]
  );
};
