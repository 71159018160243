import { Utils } from '@sigmail/common';
import { HttpService } from '../http/http-service';
import type { Api } from '../types';
import { apiBatchQueryData } from './batch-query-data';
import { apiBatchUpdateData } from './batch-update-data';
import { apiAccuroEMRAddDocument } from './EMR/accuro/add-document';
import { apiAccuroEMRCheckAuthStatus } from './EMR/accuro/check-auth-status';
import { apiAccuroEMRDocumentList } from './EMR/accuro/document-list';
import { apiAccuroEMRFetchDocument } from './EMR/accuro/fetch-document';
import { apiAccuroEMRGetOAuthUrl } from './EMR/accuro/get-oauth-url';
import { apiAccuroEMRProviderFolderList } from './EMR/accuro/provider-folder-list';
import { apiAccuroEMRProviderList } from './EMR/accuro/provider-list';
import { apiAccuroEMRSearchPatient } from './EMR/accuro/search-patient';
import { apiOscarEMRAddDocument } from './EMR/oscar/add-document';
import { apiOscarEMRCheckAuthStatus } from './EMR/oscar/check-auth-status';
import { apiOscarEMRGetOAuthUrl } from './EMR/oscar/get-oauth-url';
import { apiOscarEMRGetProviderNumber } from './EMR/oscar/get-provider-number';
import { apiOscarEMRSearchPatient } from './EMR/oscar/search-patient';
import { apiEnterState } from './enter-state';
import { apiGenerateIcsUrl } from './generate-ics-url';
import { apiGetGeoLocation } from './get-geo-location';
import { apiGetIDs } from './get-ids';
import { apiGetIDsByUsage } from './get-ids-by-usage';
import { apiGetVideoSignature } from './get-video-signature';
import { apiGetVideoToken } from './get-video-token';
import { apiHrmGetSenderList } from './hrm/get-sender-list';
import { apiHrmGetUserList } from './hrm/get-user-list';
import { apiHrmSendReport } from './hrm/send-report';
import { apiIsaacObservation } from './ISAAC/observation';
import { apiCancelNotification } from './notification/cancel';
import { apiNewNotification } from './notification/new';
import { apiUpdateNotification } from './notification/update';
import { apiSendOTP } from './otp/send';
import { apiVerifyOTP } from './otp/verify';
import { apiSendEmailMessage } from './send-email-message';
import { apiSendTemplatedEmailMessage } from './send-templated-email-message';
import { apiSrpExchangeCredential } from './srp/exchange-credential';
import { apiSrpVerifyCredential } from './srp/verify-credential';
import { apiSubscribeWebPush } from './webpush/subscribe';
import { apiUnsubscribeWebPush } from './webpush/unsubscribe';

type ApiBaseUrlAndAuthKeyTuple = [baseUrl: string, authKey: string];

const API_KIND_LIST: ReadonlyArray<Api.Kind> = ['authyApi', 'coreApi', 'emailApi', 'otpApi'];

export class ApiService implements Api.Service {
  public readonly authKey: Readonly<Record<Api.Kind, string>>;
  public readonly baseUrl: Readonly<Record<Api.Kind, string>>;
  public readonly httpService = new HttpService();

  public readonly accuroEMRAddDocument: Api.Service['accuroEMRAddDocument'];
  public readonly accuroEMRCheckAuthStatus: Api.Service['accuroEMRCheckAuthStatus'];
  public readonly accuroEMRDocumentList: Api.Service['accuroEMRDocumentList'];
  public readonly accuroEMRFetchDocument: Api.Service['accuroEMRFetchDocument'];
  public readonly accuroEMRGetOAuthUrl: Api.Service['accuroEMRGetOAuthUrl'];
  public readonly accuroEMRProviderFolderList: Api.Service['accuroEMRProviderFolderList'];
  public readonly accuroEMRProviderList: Api.Service['accuroEMRProviderList'];
  public readonly accuroEMRSearchPatient: Api.Service['accuroEMRSearchPatient'];

  public readonly batchQueryData: Api.Service['batchQueryData'];
  public readonly batchUpdateData: Api.Service['batchUpdateData'];

  public readonly cancelNotification: Api.Service['cancelNotification'];

  public readonly enterState: Api.Service['enterState'];

  public readonly generateIcsUrl: Api.Service['generateIcsUrl'];

  public readonly getGeoLocation: Api.Service['getGeoLocation'];

  public readonly getIds: Api.Service['getIds'];
  public readonly getIdsByUsage: Api.Service['getIdsByUsage'];

  public readonly getVideoSignature: Api.Service['getVideoSignature'];
  public readonly getVideoToken: Api.Service['getVideoToken'];

  public readonly isaacObservation: Api.Service['isaacObservation'];

  public readonly hrmGetSenderList: Api.Service['hrmGetSenderList'];
  public readonly hrmGetUserList: Api.Service['hrmGetUserList'];
  public readonly hrmSendReport: Api.Service['hrmSendReport'];

  public readonly newNotification: Api.Service['newNotification'];

  public readonly oscarEMRAddDocument: Api.Service['oscarEMRAddDocument'];
  public readonly oscarEMRCheckAuthStatus: Api.Service['oscarEMRCheckAuthStatus'];
  public readonly oscarEMRGetOAuthUrl: Api.Service['oscarEMRGetOAuthUrl'];
  public readonly oscarEMRGetProviderNumber: Api.Service['oscarEMRGetProviderNumber'];
  public readonly oscarEMRSearchPatient: Api.Service['oscarEMRSearchPatient'];

  public readonly sendEmailMessage: Api.Service['sendEmailMessage'];
  public readonly sendOTP: Api.Service['sendOTP'];
  public readonly sendTemplatedEmailMessage: Api.Service['sendTemplatedEmailMessage'];

  public readonly srpExchangeCredential: Api.Service['srpExchangeCredential'];
  public readonly srpVerifyCredential: Api.Service['srpVerifyCredential'];

  public readonly subscribeWebPush: Api.Service['subscribeWebPush'];
  public readonly unsubscribeWebPush: Api.Service['unsubscribeWebPush'];

  public readonly updateNotification: Api.Service['updateNotification'];

  public readonly verifyOTP: Api.Service['verifyOTP'];

  public constructor(baseUrl: Record<Api.Kind, string>, authKey?: Record<string, string>) {
    if (!Utils.isNonArrayObjectLike(baseUrl)) {
      throw new TypeError('Invalid argument value.');
    }

    const [
      [baseUrlAuthyApi, authKeyAuthyApi],
      [baseUrlCoreApi, authKeyCoreApi],
      [baseUrlEmailApi, authKeyEmailApi],
      [baseUrlOtpApi, authKeyOtpApi]
    ] = API_KIND_LIST.map<ApiBaseUrlAndAuthKeyTuple>((key) => {
      const trimmedUrl = Utils.trimOrDefault(baseUrl[key]);
      const trimmedAuthKey = Utils.trimOrDefault(Utils.isNonArrayObjectLike<typeof authKey>(authKey) && authKey[key]);

      if (!trimmedUrl.endsWith('/')) {
        throw new TypeError(`URL must end with a path separator. <url=${JSON.stringify(trimmedUrl)}>`);
      }

      return [trimmedUrl, trimmedAuthKey];
    }) as [ApiBaseUrlAndAuthKeyTuple, ApiBaseUrlAndAuthKeyTuple, ApiBaseUrlAndAuthKeyTuple, ApiBaseUrlAndAuthKeyTuple];

    this.baseUrl = {
      authyApi: baseUrlAuthyApi,
      coreApi: baseUrlCoreApi,
      emailApi: baseUrlEmailApi,
      otpApi: baseUrlOtpApi
    };

    this.authKey = {
      authyApi: authKeyAuthyApi,
      coreApi: authKeyCoreApi,
      emailApi: authKeyEmailApi,
      otpApi: authKeyOtpApi
    };

    this.accuroEMRAddDocument = apiAccuroEMRAddDocument.bind(this);
    this.accuroEMRCheckAuthStatus = apiAccuroEMRCheckAuthStatus.bind(this);
    this.accuroEMRDocumentList = apiAccuroEMRDocumentList.bind(this);
    this.accuroEMRFetchDocument = apiAccuroEMRFetchDocument.bind(this);
    this.accuroEMRGetOAuthUrl = apiAccuroEMRGetOAuthUrl.bind(this);
    this.accuroEMRProviderFolderList = apiAccuroEMRProviderFolderList.bind(this);
    this.accuroEMRProviderList = apiAccuroEMRProviderList.bind(this);
    this.accuroEMRSearchPatient = apiAccuroEMRSearchPatient.bind(this);

    this.batchQueryData = apiBatchQueryData.bind(this);
    this.batchUpdateData = apiBatchUpdateData.bind(this);

    this.cancelNotification = apiCancelNotification.bind(this);

    this.enterState = apiEnterState.bind(this);

    this.generateIcsUrl = apiGenerateIcsUrl.bind(this);

    this.getGeoLocation = apiGetGeoLocation.bind(this);

    this.getIds = apiGetIDs.bind(this);
    this.getIdsByUsage = apiGetIDsByUsage.bind(this);

    this.getVideoSignature = apiGetVideoSignature.bind(this);
    this.getVideoToken = apiGetVideoToken.bind(this);

    this.hrmGetSenderList = apiHrmGetSenderList.bind(this);
    this.hrmGetUserList = apiHrmGetUserList.bind(this);
    this.hrmSendReport = apiHrmSendReport.bind(this);

    this.isaacObservation = apiIsaacObservation.bind(this);

    this.newNotification = apiNewNotification.bind(this);

    this.oscarEMRAddDocument = apiOscarEMRAddDocument.bind(this);
    this.oscarEMRCheckAuthStatus = apiOscarEMRCheckAuthStatus.bind(this);
    this.oscarEMRGetOAuthUrl = apiOscarEMRGetOAuthUrl.bind(this);
    this.oscarEMRGetProviderNumber = apiOscarEMRGetProviderNumber.bind(this);
    this.oscarEMRSearchPatient = apiOscarEMRSearchPatient.bind(this);

    this.sendEmailMessage = apiSendEmailMessage.bind(this);
    this.sendOTP = apiSendOTP.bind(this);
    this.sendTemplatedEmailMessage = apiSendTemplatedEmailMessage.bind(this);

    this.srpExchangeCredential = apiSrpExchangeCredential.bind(this);
    this.srpVerifyCredential = apiSrpVerifyCredential.bind(this);

    this.subscribeWebPush = apiSubscribeWebPush.bind(this);
    this.unsubscribeWebPush = apiUnsubscribeWebPush.bind(this);

    this.updateNotification = apiUpdateNotification.bind(this);

    this.verifyOTP = apiVerifyOTP.bind(this);
  }
}
