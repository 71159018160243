import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.Form.BPReadingHealthDataParameters = {
  fieldsetDateRange: {
    formField: {
      from: {
        error: {
          // Translators: Error displayed when an invalid date is entered for \
          // From Date input in BP Reading Health Data Parameters form
          badInput: gettext('health-data', 'fromInvalid', 'bpReadingHealthDataParametersFormError'),
          // Translators: Error displayed when no date is selected for From \
          // Date input in BP Reading Health Data Parameters form
          valueMissing: gettext('health-data', 'fromRequired', 'bpReadingHealthDataParametersFormError')
        },
        // Translators: Label for the From Date input in BP Reading Health \
        // Data Parameters form
        label: gettext('health-data', 'from', 'bpReadingHealthDataParametersFormInputLabel')
      },
      to: {
        error: {
          // Translators: Error displayed when an invalid date is entered for \
          // TO Date input in BP Reading Health Data Parameters form
          badInput: gettext('health-data', 'toInvalid', 'bpReadingHealthDataParametersFormError'),
          // Translators: Error displayed when no date is selected for TO Date \
          // input in BP Reading Data Parameters form
          valueMissing: gettext('health-data', 'toRequired', 'bpReadingHealthDataParametersFormError')
        },
        // Translators: Label for the TO Date input in BP Reading Health Data \
        // Parameters form
        label: gettext('health-data', 'to', 'bpReadingHealthDataParametersFormInputLabel')
      }
    }
  }
};

export default i18n;
