import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { PartialRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n, HealthDataI18n } from '@sigmail/i18n';
import { CopdAssessmentFormData } from '@sigmail/objects';
import { FieldState } from 'final-form';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { EMPTY_PLAIN_OBJECT } from '../../../app-state/constants';
import { withTranslation } from '../../../i18n';
import { I18N_NS_HEALTH_DATA } from '../../../i18n/config/namespace-identifiers';
import healthDataI18n from '../../../i18n/health-data';
import { range as rangeValidator, ValidationErrorResult } from '../../shared/form/field-validator';
import { FieldProps, FormComponent, FormComponentProps, FormComponentState } from '../../shared/form/form.component';
import style from './copd-assessment.module.css';

const { copdAssessment: i18n } = healthDataI18n.form;

export type FieldsetName = keyof typeof i18n;

export type FieldNameActivities = keyof typeof i18n.fieldsetActivities.formField;
export type FieldNameChestTightness = keyof typeof i18n.fieldsetChestTightness.formField;
export type FieldNameConfidence = keyof typeof i18n.fieldsetConfidence.formField;
export type FieldNameCough = keyof typeof i18n.fieldsetCough.formField;
export type FieldNameDyspnea = keyof typeof i18n.fieldsetDyspnea.formField;
export type FieldNameEnergy = keyof typeof i18n.fieldsetEnergy.formField;
export type FieldNameSleep = keyof typeof i18n.fieldsetSleep.formField;
export type FieldNameSputum = keyof typeof i18n.fieldsetSputum.formField;

export type FieldName =
  | FieldNameActivities
  | FieldNameChestTightness
  | FieldNameConfidence
  | FieldNameCough
  | FieldNameDyspnea
  | FieldNameEnergy
  | FieldNameSleep
  | FieldNameSputum;

export type FormValues = Record<FieldName, CopdAssessmentFormData['activities']>;

export const FIELD_SET_LIST: ReadonlyArray<[FieldsetName, ReadonlyArray<FieldName>]> = [
  ['fieldsetCough', ['cough']],
  ['fieldsetSputum', ['sputum']],
  ['fieldsetChestTightness', ['chestTightness']],
  ['fieldsetDyspnea', ['dyspnea']],
  ['fieldsetActivities', ['activities']],
  ['fieldsetConfidence', ['confidence']],
  ['fieldsetSleep', ['sleep']],
  ['fieldsetEnergy', ['energy']]
];

const FIELD_PROPS: Readonly<Record<FieldName, FieldProps>> = FIELD_SET_LIST.reduce((props, [fieldset, fieldList]) => {
  return fieldList.reduce((_, fieldName) => {
    const options = (i18n[fieldset].formField as any)[fieldName].options;
    props[fieldName] = {
      config: {
        range: () =>
          rangeValidator(options[0].codedValue, options[options.length - 1].codedValue, {
            ignoreNil: false
          })
      }
    };
    return props;
  }, props);
}, {} as Record<FieldName, FieldProps>);

export type CopdAssessmentFormClassKey = 'control' | 'controlLabel' | 'inputRoot' | `radio${'Group' | 'Icon'}`;

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<CopdAssessmentFormClassKey, string> | undefined;
}

type ComponentProps = Props & WithTranslation;
type State = FormComponentState<FieldName, FormValues>;

const INITIAL_VALUES: Readonly<FormValues> = FIELD_SET_LIST.reduce(
  (value, [, fieldNameList]) =>
    fieldNameList.reduce((_, fieldName) => {
      value[fieldName] = null!;
      return value;
    }, value),
  {} as FormValues
);

class CopdAssessmentFormComponent extends FormComponent<
  FieldsetName,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes'
    );

    this.createForm({ initialValues: INITIAL_VALUES });

    this.scrollIntoView = this.scrollIntoView.bind(this);
  }

  /** @override */
  protected get formId() {
    return Utils.stringOrDefault(this.props.id, `form-copd-assessment-${this.formIdSuffix}`);
  }

  /** @override */
  protected getFormSubscription(...args: any[]) {
    return { ...super.getFormSubscription(...args), hasValidationErrors: true };
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetName: FieldsetName) {
    const label = Utils.trimOrDefault(i18n[fieldsetName].label);
    return label.length > 0 ? this.props.t(label) : null;
  }

  /** @override */
  protected getFieldProps() {
    return EMPTY_PLAIN_OBJECT;
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n.valueMissing;
        }
      }
    }

    return Utils.isString(errorMessageI18n) ? t(errorMessageI18n) : error;
  }

  /** @override */
  protected renderForm(...args: any[]) {
    const formNode = super.renderForm(...args);

    return <React.Fragment>{formNode}</React.Fragment>;
  }

  /** @override */
  protected renderFieldset(fieldsetName: FieldsetName, ...args: any[]) {
    const index = FIELD_SET_LIST.findIndex(([fieldset]) => fieldset === fieldsetName);
    if (index > 0) {
      const [fieldName] = FIELD_SET_LIST[index - 1][1].slice(-1);
      if (Utils.isString(fieldName) === true && this.state[fieldName].value === INITIAL_VALUES[fieldName]) {
        return null;
      }
    }

    return super.renderFieldset(fieldsetName, ...args);
  }

  /** @override */
  protected renderField(fieldName: FieldName) {
    let fieldsetIndex = -1;
    let fieldIndex = -1;

    for (const [, fieldNameList] of FIELD_SET_LIST) {
      ++fieldsetIndex;
      fieldIndex = fieldNameList.indexOf(fieldName);
      if (fieldIndex > -1) break;
    }

    if (fieldIndex === -1) return null;

    const [fieldsetName, fieldNameList] = FIELD_SET_LIST[fieldsetIndex];
    if (fieldIndex > 0) {
      const prevFieldName = fieldNameList[fieldIndex - 1];
      if (this.state[prevFieldName].value === INITIAL_VALUES[prevFieldName]) {
        return null;
      }
    }

    const { classes, disabled, t } = this.props;
    const { change, error, value } = this.state[fieldName];

    const options = (i18n[fieldsetName].formField as any)[fieldName]
      .options as HealthDataI18n.Form.CopdAssessmentFormInputOption['options'];

    const fieldNode = (
      <React.Fragment>
        <FormControl
          classes={{ root: classes!.control }}
          disabled={disabled}
          ref={
            (fieldsetIndex > 0 || fieldIndex > 0) && value === INITIAL_VALUES[fieldName]
              ? this.scrollIntoView
              : Utils.noop
          }
        >
          <FormLabel classes={{ root: classes!.controlLabel }} component="div">
            {t(options[0].label)}
          </FormLabel>

          <RadioGroup
            classes={{ root: classes!.radioGroup }}
            onChange={(_, newValue) => change(Number(newValue))}
            row={true}
            value={value}
          >
            {options.map(({ codedValue, label }, index: number) => (
              <FormControlLabel
                control={
                  <Radio
                    classes={{ root: classes!.radioIcon }}
                    inputProps={{ 'aria-label': t(label) }}
                    icon={<span>{index}</span>}
                    checkedIcon={<span>{index}</span>}
                  />
                }
                key={codedValue}
                label=""
                value={codedValue}
              />
            ))}
          </RadioGroup>

          <FormLabel classes={{ root: classes!.controlLabel }} component="div">
            {t(options[options.length - 1].label)}
          </FormLabel>
        </FormControl>

        {Utils.isString(error) && <FormHelperText error={true}>{error}</FormHelperText>}
      </React.Fragment>
    );

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<any>) {
    let { error } = fieldState;

    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    this.setState({ areValidationErrorsVisible: false });
    return super.setFieldState(fieldName, { ...fieldState, error });
  }

  private scrollIntoView(element: HTMLElement | null): void {
    if (!Utils.isNil(element)) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}

const ns = [I18N_NS_HEALTH_DATA];

export const CopdAssessmentForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      control: style.radio,
      controlLabel: style['group-label'],
      inputRoot: style['form-input-group'],
      radioGroup: style.group,
      radioIcon: style.icon
    } as ComponentProps['classes']);

    return <CopdAssessmentFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

CopdAssessmentForm.displayName = 'CopdAssessmentForm';
CopdAssessmentForm.defaultProps = { className: style.form };
