import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import { Constants, MessagingException, ReadonlyMessageBodyHrm, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectDocBodyValue,
  DataObjectMsgBodyValue,
  MessageFolderItemCount,
  MessageFolderListItem,
  MessageTimestampRecord
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { DataObjectCache } from '../../data-objects-slice/cache';
import * as DataObjectSelectors from '../../selectors/data-object';
import { AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';
import { AUTH_STATE_SEND_HRM_MESSAGE } from '../constants/auth-state-identifier';
import { BaseMessagingAction } from './base-messaging-action';
import { createMessageFolderExtensionAction } from './create-message-folder-extension-action';
import { updateMessageFolderAction } from './update-msg-folder-action';

interface Payload extends MessagingActionPayload.CancelHrmMessage {}

interface State extends AuthenticatedActionState {
  dtServer: Date;
  msgBody: DataObjectMsgBodyValue;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

class CancelHrmMessageAction extends BaseMessagingAction<Payload, State> {
  protected async onExecute(...args: any[]) {
    if (args.length > 0 && args[0] === false) return;

    await this.fetchExistingMessageBody();
    await this.sendCancelRequestToHRM();

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchServerTime();
        this.initializeRequestBodyAndSuccessPayload();
        await this.generateRequestBody();

        const query: Api.EnterStateRequestData = {
          authState: this.state.roleAuthClaim,
          state: AUTH_STATE_SEND_HRM_MESSAGE
        };

        const { authState } = await this.dispatchEnterState(query);

        const { requestBody } = this.state;
        await this.dispatchBatchUpdateData({ authState, ...requestBody.build() });

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }

    try {
      await this.dispatchBatchQueryDataSuccess(this.state.successPayload);
    } catch (error) {
      this.logger.warn('Error manually updating app state:', error);
      /* ignore */
    }

    try {
      await this.dispatch(createMessageFolderExtensionAction({ folderKey: Constants.MessageFolderKey.Sent }));
    } catch (error) {
      this.logger.warn('Error creating message folder extension:', error);
      /* ignore */
    }
  }

  private async fetchServerTime(): Promise<void> {
    const { serverDateTime } = await this.dispatchFetchObjects({
      authState: this.state.roleAuthClaim,
      userObjectsByType: [{ userId: this.state.currentUser.id, type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE }],
      expectedCount: { userObjectsByType: null }
    });
    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
  }

  private async fetchExistingMessageBody(): Promise<void> {
    this.logger.info("Fetching existing message's body.");

    const { msgBodyId } = this.payload;
    const { roleAuthClaim: authState } = this.state;

    let dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
    let msgBodyObject = dataObjectByIdSelector<DataObjectMsgBodyValue>(msgBodyId);
    let msgBody = DataObjectCache.getValue(msgBodyObject);
    if (Utils.isNil(msgBody)) {
      const query: FetchObjectsRequestData = {
        authState,
        dataObjects: { ids: [msgBodyId] }
      };

      await this.dispatchFetchObjects(query);

      dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
      msgBodyObject = dataObjectByIdSelector<DataObjectMsgBodyValue>(msgBodyId);
      msgBody = DataObjectCache.getValue(msgBodyObject);
    }

    if (Utils.isNil(msgBody) || (msgBody as ReadonlyMessageBodyHrm).messageForm?.name !== 'hrm') {
      throw new MessagingException(Constants.Error.E_DATA_MISSING_OR_INVALID, "Existing message's body is either missing or invalid");
    }

    this.state.msgBody = msgBody;
  }

  private async sendCancelRequestToHRM(): Promise<void> {
    const { value: hrmMessage } = (this.state.msgBody as ReadonlyMessageBodyHrm).messageForm;
    this.logger.info(`Sending a cancel request to HRM for request ID <${hrmMessage.id}>.`);

    const docBodyList: Array<DataObjectDocBodyValue> = [];
    if (hrmMessage.document.docs.length > 0) {
      let dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());

      const docBodyIdList = hrmMessage.document.docs
        .filter((doc) => {
          const docBodyObject = dataObjectByIdSelector<DataObjectDocBodyValue>(doc.bodyId);
          return Utils.isNil(docBodyObject);
        })
        .map((doc) => doc.bodyId);

      if (docBodyIdList.length > 0) {
        const query: FetchObjectsRequestData = {
          authState: this.state.roleAuthClaim,
          dataObjects: { ids: docBodyIdList }
        };

        await this.dispatchFetchObjects(query);
        dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
      }

      for (const doc of hrmMessage.document.docs) {
        const docBodyObject = dataObjectByIdSelector<DataObjectDocBodyValue>(doc.bodyId);
        const docBody = DataObjectCache.getValue(docBodyObject);
        if (Utils.isNil(docBody)) {
          throw new MessagingException(
            Constants.Error.E_DATA_MISSING_OR_INVALID,
            `Existing message's attached document (ID=${doc.bodyId}) is either missing or invalid.`
          );
        }
        docBodyList.push(docBody);
      }
    }

    const requestData: Api.HrmSendReportRequestData = {
      clientId: this.state.clientId,
      diagnostic: {
        ...hrmMessage.diagnostic,
        status: 'cancelled'
      },
      document: {
        ...hrmMessage.document,
        authors: hrmMessage.document.authors.slice(),
        docs: docBodyList.map(({ data }, index) => {
          const doc = hrmMessage.document.docs[index];
          return {
            title: doc.title,
            contentType: doc.contentType,
            language: 'en',
            createdDate: doc.createdDate,
            data: data ? data.split(';')[1].split(',')[1] : ''
          };
        }),
        recipients: hrmMessage.document.recipients.slice()
      },
      encounter: hrmMessage.encounter,
      order: hrmMessage.order,
      patient: {
        ...hrmMessage.patient,
        address: hrmMessage.patient.address.map((address) => ({ ...address, line: address.line.slice() })),
        telecom: hrmMessage.patient.telecom.slice()
      },
      practitioners: hrmMessage.practitioners.slice(),
      requestId: hrmMessage.id,
      userId: this.state.currentUser.id
    };

    await this.apiService.hrmSendReport(this.getRootState().auth.accessToken, requestData);
  }

  private initializeRequestBodyAndSuccessPayload(): void {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] } },
      response: { dataObjects: [], serverDateTime: '' }
    };
  }

  private async generateRequestBody(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;
    const { requestBody, successPayload } = this.state;

    await this.dispatch(
      updateMessageFolderAction({
        folderKey,
        parentFolderKey,
        requestBody,
        successPayload,
        applyUpdate: this.applyMessageFolderUpdate.bind(this)
      })
    );
  }

  private async applyMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    _: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ) {
    const { msgMetadataId } = this.payload;
    const { dtServer } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    this.logger.info(`Locating item with message metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);
    const index = folderData.findIndex(({ header }) => header === msgMetadataId);
    if (index === -1) return result;

    const message = folderData[index];

    let newTimestamp: MessageTimestampRecord;
    if (Utils.isInteger(message.timestamp)) {
      newTimestamp = { createdAt: message.timestamp, canceledAt: dtServer.getTime() };
    } else {
      newTimestamp = { ...message.timestamp, canceledAt: dtServer.getTime() };
    }

    folderData[index] = { ...message, timestamp: newTimestamp };

    result.updated = true;
    result.done = true;

    return result;
  }
}

export const cancelHrmMessageAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'cancelHrmMessageAction:');

    const action = new CancelHrmMessageAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
