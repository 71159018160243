import { Constants, JsonPrimitive, Nullable, Utils } from '@sigmail/common';
import { Encoder, Hash } from '@sigmail/crypto';
import {
  CollectionObjectPatientRecordList,
  CollectionObjectPatientRecordListValue,
  FullPatientListCollectionItem
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISuspenseResource } from 'sigmail';
import { useDispatchFetchObjects } from '.';
import { UNRESOLVED_RESOURCE } from '../../constants';
import { formatHealthPlanNumber } from '../../utils/format-health-plan-number';
import { CollectionObjectCache as Cache } from '../collection-objects-slice/cache';
import { EMPTY_ARRAY } from '../constants';
import { collectionListObjectSelector as collectionListSelector } from '../selectors/client-object';
import { clientIdSelector } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';

export interface Params {
  readonly healthPlanNumber?: Nullable<string>;
}

export type Resource = ISuspenseResource<ResourceValue>;

export interface ResourceValue {
  readonly list: CollectionObjectPatientRecordListValue['list'];
}

const cancelable = Utils.makeCancelablePromise;

// ==
// **STOP**
// do NOT change the order of keys unless you're absolutely sure of what
// you're doing
const BASE_KEY_LIST_ORDERED: ReadonlyArray<keyof FullPatientListCollectionItem> = ['collectionId', 'type', 'hashSalt'];
// ==

async function calculatePageNumber(
  value: Record<string, JsonPrimitive | ReadonlyArray<JsonPrimitive>>
): Promise<number> {
  if (!Utils.isNonArrayObjectLike<FullPatientListCollectionItem>(value)) {
    throw new TypeError('Invalid argument.');
  }

  const keySet = new Set(BASE_KEY_LIST_ORDERED);
  if (Utils.isNonEmptyArray<string>(value.indexedAttributes)) {
    value.indexedAttributes.forEach((key) => {
      if (Utils.isString(key)) {
        const trimmedKey = key.trim() as typeof BASE_KEY_LIST_ORDERED[0];
        if (trimmedKey.length > 0) keySet.add(trimmedKey);
      }
    });
  }

  const stringified = JSON.stringify(Utils.mapValues(Object.fromEntries(keySet.entries()), (prop) => value[prop]));
  const byteArray = await Hash.SHA256(Encoder.UTF8.encode(stringified));
  const uint32BE = new DataView(byteArray.buffer.slice(0, 4)).getUint32(0, /* littleEndian := */ false);
  return (uint32BE % value.numPages) + 1;
}

const DEFAULT_RESOURCE_VALUE: ResourceValue = { list: EMPTY_ARRAY };
const DEFAULT_RESOURCE: Resource = { value: (): ResourceValue => DEFAULT_RESOURCE_VALUE };

export const useSearchPatientListCollectionResource = (params?: Nullable<Params> | false): Resource => {
  const noop = !Utils.isNonArrayObjectLike<Params>(params);
  const planNumber = Utils.stringOrDefault(!noop && params.healthPlanNumber).replaceAll(/\D/g, '');
  const [resource, setResource] = React.useState<Resource>(UNRESOLVED_RESOURCE);

  const clientId = useSelector(clientIdSelector)!;
  const prevClientId = React.useRef<typeof clientId>();
  const collectionObject = useSelector(collectionListSelector)(Utils.numberOrDefault(!noop && clientId));
  const collection = UserObjectCache.getValue(collectionObject);

  const fetchObjects = useDispatchFetchObjects();

  React.useEffect(() => {
    if (noop) {
      prevClientId.current = undefined;
      return setResource(UNRESOLVED_RESOURCE);
    }

    if (prevClientId.current !== clientId) {
      prevClientId.current = clientId;

      const fetchCollectionObject = async (): Promise<void> => {
        try {
          const { userObjectsByType: userObjectList } = await fetchObjects({
            userObjectsByType: [{ type: process.env.CLIENT_OBJECT_TYPE_COLLECTION_LIST, userId: clientId }]
          });

          if (Utils.isNonEmptyArray<NonNullable<typeof userObjectList>[0]>(userObjectList)) {
            return;
          }
        } catch {
          /* ignore */
        }

        setResource(DEFAULT_RESOURCE);
      };

      if (Utils.isNil(collection)) {
        const promise = cancelable(fetchCollectionObject());
        promise.promise.catch(Utils.noop);
        return () => promise.cancel();
      }
    }

    setResource(UNRESOLVED_RESOURCE);

    const promise = cancelable(buildPatientList());
    promise.promise.then((list) => {
      setResource(list === DEFAULT_RESOURCE_VALUE.list ? DEFAULT_RESOURCE : { value: (): ResourceValue => ({ list }) });
    }, Utils.noop);

    async function buildPatientList(): Promise<ResourceValue['list']> {
      try {
        const patientListCollection = collection?.list.find((item) => item.collectionType === 'fullPatientList');
        const collectionItem = patientListCollection?.collections[0];

        if (Utils.isNotNil(collectionItem) && planNumber.length > 0) {
          const page = await calculatePageNumber({ ...collectionItem, hcNumber: planNumber });

          const query: NonNullable<Api.BatchQueryRequestData['collectionObjectsByType']>[0] = {
            collectionId: collectionItem.collectionId,
            page,
            type: collectionItem.type
          };

          const cached = Cache.find((entry) =>
            Utils.every(query, (value, key) => entry[key as keyof typeof query] === value)
          );

          const objectId = await (Utils.isNil(cached)
            ? fetchObjects({ collectionObjectsByType: [query] }).then(
                ({ collectionObjectsByType }) => collectionObjectsByType?.[0].id,
                Utils.noop
              )
            : Promise.resolve(cached[0].id));

          const patientList = Cache.getValue<CollectionObjectPatientRecordList>(objectId!)?.list;
          if (Utils.isNonEmptyArray<NonNullable<typeof patientList>[0]>(patientList)) {
            const list: Array<typeof patientList[0]> = [];

            for (const record of patientList) {
              if (promise.hasCanceled) break;
              if (!record.hcNumber.startsWith(planNumber)) continue;
              if (!Utils.isInteger(record.recordId)) continue;

              let { dateOfBirth, gender, hcNumber, hcVersion } = record;
              if (gender !== Constants.Gender.Male && gender !== Constants.Gender.Female) {
                gender = gender.trim().length === 0 ? Constants.Gender.Unknown : Constants.Gender.Other;
              }

              hcNumber = formatHealthPlanNumber(`${hcNumber}-${hcVersion}`, Constants.HealthPlanJurisdiction.Ontario);

              dateOfBirth = Utils.trimOrDefault(dateOfBirth);
              const dtParts = dateOfBirth.match(/^\d{4}-\d{2}-\d{2}$/)?.slice(1);
              if (Utils.isArray<string>(dtParts) && dtParts.length === 3 && dtParts.every(Utils.isString)) {
                const [yyyy, mm, dd] = dtParts.map(Number);
                const dt = new Date(yyyy, mm - 1, dd);
                if (Utils.isValidDate(dt)) dateOfBirth = dt.toISOString();
              }

              list.push({ ...record, gender, hcNumber });
            }

            if (list.length > 0) {
              return list;
            }
          }
        }
      } catch {
        /* ignore */
      }
      return DEFAULT_RESOURCE_VALUE.list;
    }

    return () => promise.cancel();
  }, [clientId, collection, fetchObjects, noop, planNumber]);

  return resource;
};
