import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: Array<HealthDataI18n.Form.CardiacIntakeFormOption<HealthDataI18n.CardiacIntakeFormPeriod>> = [
  {
    codedValue: 'D',
    label: gettext('health-data', 'days', 'cardiacIntakeFormDataPeriod')
  },
  {
    codedValue: 'W',
    label: gettext('health-data', 'weeks', 'cardiacIntakeFormDataPeriod')
  },
  {
    codedValue: 'M',
    label: gettext('health-data', 'months', 'cardiacIntakeFormDataPeriod')
  },
  {
    codedValue: 'Y',
    label: gettext('health-data', 'years', 'cardiacIntakeFormDataPeriod')
  }
];

export default i18n;
