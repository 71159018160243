import type {
  MessageSubFolderKeyArchived,
  MessageSubFolderKeyDeleted,
  UserDefinedMessageSubFolderKeyPrefix
} from '../types';

export const Archived: MessageSubFolderKeyArchived = 'archived';
export const Deleted: MessageSubFolderKeyDeleted = 'deleted';

export const UserDefinedKeyPrefix: UserDefinedMessageSubFolderKeyPrefix = '$u_';
