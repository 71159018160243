import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogConfirmCancelHrmMessage } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.Confirmation.HrmCancelMessage = {
  // Translators: (HTML) Title for the Confirm HRM Message Cancellation dialog
  title: gettext('messaging', 'title', 'dialogConfirmHrmCancel', 'safe-html'),
  // Translators: (HTML) Confirmation message to be displayed in the body of \
  // Confirm HRM Message Cancellation dialog
  body: gettext('messaging', 'body', 'dialogConfirmHrmCancel', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Confirm HRM Message \
      // Cancellation dialog
      label: gettext('messaging', 'reject', 'dialogConfirmHrmCancelActionLabel'),
      context: DialogConfirmCancelHrmMessage
    },
    accept: {
      // Translators: Label for the Proceed action of Confirm HRM Message \
      // Cancellation dialog
      label: gettext('messaging', 'accept', 'dialogConfirmHrmCancelActionLabel'),
      context: DialogConfirmCancelHrmMessage
    }
  }
};

export default i18n;
