import { ApiActionPayload } from '@sigmail/app-state';
import { AppException, Constants, IAppUserOrUserGroup, Utils, ValueObject } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  GroupObjectGuestList,
  GroupObjectGuestListValue,
  GroupObjectProfileBasic,
  GroupObjectProfileBasicValue,
  IUserObject,
  UserObjectSchedule,
  UserObjectScheduleValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, BaseActionState, FetchObjectsRequestData } from '../base-action';
import { AUTH_STATE_CREATE_SCHEDULE_OBJECT_MIGRATION } from '../constants/auth-state-identifier';

interface Payload {
  roleAuthClaim: string;
  userId: number;
  accessToken: string;
  clientId: number;
  auditId: number;
  groupId: number;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

interface State extends BaseActionState {
  dtServer: Date;
  groupProfileBasicObject: IUserObject<GroupObjectProfileBasicValue>;
  groupProfileBasic: GroupObjectProfileBasicValue;
  groupGuestListObject: IUserObject<GroupObjectGuestListValue>;
  groupGuestList: GroupObjectGuestListValue;
  requestBody: BatchUpdateRequestBuilder;
  idsClaim: string;
  batchUpdateAuthState: string;
}

function userIdReducer(list: Array<number>, entry: Omit<IAppUserOrUserGroup, keyof ValueObject>): typeof list {
  if (entry.type === 'user') list.push(entry.id);
  return list;
}

class MigrationCreateScheduleObject extends BaseAction<Payload, State> {
  /** @override */
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchGroupProfileAndGuestList();

        const { $$formatver } = this.state.groupProfileBasic;
        if (Utils.isNotNil($$formatver) && $$formatver > 2) {
          this.logger.info(`Call ignored; migration is not required. ($$formatver = ${$$formatver})`);
          return;
        }

        await this.generateRequestBody();

        const { accessToken, successPayload } = this.payload;
        const { batchUpdateAuthState: authState, idsClaim, requestBody, groupProfileBasicObject } = this.state;
        await this.batchUpdateData(accessToken, { authState, claims: [idsClaim], ...requestBody.build() });

        // manually patch successPayload's data with updated objects
        do {
          const userObjectList = [groupProfileBasicObject];
          const { userObjectsByType } = successPayload.response;
          for (const obj of userObjectList) {
            const index = this.findUserObjectIndex(userObjectsByType!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              userObjectsByType![index] = obj.toApiFormatted();
            }
          }
        } while (false);

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchGroupProfileAndGuestList(): Promise<void> {
    this.logger.info('Fetching the latest group basic profile, and group guest list object.');

    const { roleAuthClaim: authState, groupId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        { userId: groupId, type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC },
        { userId: groupId, type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST }
      ]
    };

    const { serverDateTime, userObjectList: groupObjectList } = await this.fetchObjects(accessToken, query);
    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);

    const groupProfileJson = this.findUserObject(groupObjectList, { type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC, userId: groupId });
    if (Utils.isNil(groupProfileJson)) {
      throw new Api.MalformedResponseException('Group basic profile object could not be fetched.');
    } else {
      const groupProfileBasicObject = new GroupObjectProfileBasic(groupProfileJson);
      this.state.groupProfileBasicObject = groupProfileBasicObject;
      this.state.groupProfileBasic = await groupProfileBasicObject.decryptedValue();
    }

    const groupGuestListJson = this.findUserObject(groupObjectList, { type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST, userId: groupId });
    if (Utils.isNil(groupGuestListJson)) {
      throw new Api.MalformedResponseException('Group guest list object could not be fetched.');
    } else {
      const groupGuestListObject = new GroupObjectGuestList(groupGuestListJson);
      this.state.groupGuestListObject = groupGuestListObject;
      this.state.groupGuestList = await groupGuestListObject.decryptedValue();
    }
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    await this.addInsertOperationForUserSchedule();
    await this.addUpdateOperationForGroupProfile();
  }

  private async addInsertOperationForUserSchedule(): Promise<void> {
    this.logger.info("Adding an insert operation each to request body for every user's schedule object.");

    const { accessToken, roleAuthClaim: authState, auditId, clientId, userId: currentUserId, groupId } = this.payload;
    const { groupGuestList, groupProfileBasic, dtServer, requestBody } = this.state;

    // filter guest list so that only group's own patients i.e. non-referred
    // patients are included in the migration
    const guestList = groupGuestList.list.filter((guest) => guest.type === 'user' && Utils.isNil(guest.userData.memberType));

    const userIdList = guestList.reduce(userIdReducer, groupProfileBasic.memberList.reduce(userIdReducer, [] as Array<number>));
    if (userIdList.length === 0) {
      // it's an error because at least current user's entry should've been
      // present; so unless it's some kind of data corruption, control should
      // never reach here
      throw new AppException(Constants.Error.S_ERROR, 'User list is empty.');
    }

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: userIdList.map((userId) => ({ userId, type: process.env.USER_OBJECT_TYPE_SCHEDULE })),
      expectedCount: { userObjectsByType: 0 }
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);
    if (userObjectList.length > 0) {
      throw new AppException(
        Constants.Error.S_ERROR,
        `One or more existing schedule objects were found.\nUser IDs: ${userObjectList.map(({ userId }) => userId).join(', ')}`
      );
    }

    const { idsClaim, authState: batchUpdateAuthState, ids: idRecord } = await this.fetchIdsByUsage(accessToken, {
      authState,
      state: AUTH_STATE_CREATE_SCHEDULE_OBJECT_MIGRATION,
      ids: {
        ids: [{ type: process.env.USER_OBJECT_TYPE_SCHEDULE, count: userIdList.length }]
      }
    });

    this.state.idsClaim = idsClaim;
    this.state.batchUpdateAuthState = batchUpdateAuthState;

    const value: UserObjectScheduleValue = { $$formatver: 1, workSchedule: {}, events: {} };
    const idSequence = Utils.makeSequence(idRecord[process.env.USER_OBJECT_TYPE_SCHEDULE]);
    for (const userId of userIdList) {
      const encryptedFor: [number, ...Array<number>] = [auditId, clientId, groupId];
      if (userId === currentUserId) encryptedFor.push(currentUserId);

      const { value: id } = idSequence.next();
      const scheduleObject = await UserObjectSchedule.create(id, undefined, 1, value, userId, encryptedFor.pop()!, dtServer);
      const keyList = await scheduleObject.generateKeysEncryptedFor(...encryptedFor);
      keyList.push(scheduleObject[Constants.$$CryptographicKey]);
      requestBody.insert(scheduleObject);
      requestBody.insert(keyList.filter(Utils.isNotNil));
    }
  }

  private async addUpdateOperationForGroupProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group profile.');

    const { groupProfileBasic, groupProfileBasicObject, requestBody } = this.state;

    const updatedValue: GroupObjectProfileBasicValue = { ...groupProfileBasic, $$formatver: 3 };
    const updatedObject = await groupProfileBasicObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.groupProfileBasicObject = updatedObject;
  }
}

export const migration_createScheduleObject = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'createScheduleObject');

    const migration = new MigrationCreateScheduleObject({ payload, dispatch, getState, apiService, logger: Logger });
    return migration.execute();
  };
};
