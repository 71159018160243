import { AppException, AppUser, Constants, IAppUser, JsonObject, Nullable, Utils, ValueObject } from '@sigmail/common';
import { IAuthenticationData } from 'sigmail';

// IMPORTANT sequence must match the argument sequence in AuthenticationData constructor
const PROPS: ReadonlyArray<keyof IAuthenticationData> = [
  'scope',
  'tokenType',
  'accessToken',
  'expiresIn',
  'refreshToken',
  'idToken',
  'salt',
  'user',
  'authClaim',
  'otpClaim',
  'ncClaim'
];

// otpClaim and ncClaim are optional
const REQUIRED_PROPS = PROPS.slice(0, PROPS.length - 2);

const REGEX_VALID_SALT = /^[A-Fa-f0-9]+$/;

export class AuthenticationData implements IAuthenticationData {
  public static isValidScope(value: any): value is string {
    return Utils.isString(value);
  }

  public static isValidTokenType(value: any): value is string {
    return Utils.isString(value) && value.toLowerCase() === 'bearer';
  }

  public static isValidAccessToken(value: any): value is string {
    return Utils.isValidJwtToken(value, 'bearer');
  }

  public static isValidExpiry(value: any): value is number {
    return Utils.isInteger(value) && value > 0;
  }

  public static isValidRefreshToken(value: any): value is string {
    return Utils.isValidJwtToken(value, 'bearer');
  }

  public static isValidIdToken(value: any): value is string {
    return Utils.isValidJwtToken(value, 'id');
  }

  public static isValidSalt(value: any): value is string {
    return REGEX_VALID_SALT.test(value);
  }

  public static isValidUser(value: any): value is Omit<IAppUser, keyof ValueObject> {
    return Utils.isNonArrayObjectLike<IAppUser>(value) && value.type === 'user' && AppUser.isValidId(value.id);
  }

  public static isValidAuthClaim(value: any): value is string {
    return Utils.isValidJwtToken(value, 'id');
  }

  public static isValidOtpClaim(value: any): value is string {
    return Utils.isValidJwtToken(value, 'id');
  }

  public static isValidNotificationCountClaim(value: any): value is string {
    return Utils.isValidJwtToken(value, 'id');
  }

  public static isAssignableFrom(obj: any): obj is IAuthenticationData {
    return (
      Utils.isNonArrayObjectLike<IAuthenticationData>(obj) &&
      Utils.every(REQUIRED_PROPS, Utils.partial(Utils.has, obj)) &&
      this.isValidScope(obj.scope) &&
      this.isValidTokenType(obj.tokenType) &&
      this.isValidAccessToken(obj.accessToken) &&
      this.isValidExpiry(obj.expiresIn) &&
      this.isValidRefreshToken(obj.refreshToken) &&
      this.isValidIdToken(obj.idToken) &&
      this.isValidSalt(obj.salt) &&
      this.isValidUser(obj.user) &&
      this.isValidAuthClaim(obj.authClaim) &&
      (Utils.isNil(obj.otpClaim) || this.isValidOtpClaim(obj.otpClaim)) &&
      (Utils.isNil(obj.ncClaim) || this.isValidNotificationCountClaim(obj.ncClaim))
    );
  }

  public readonly accessToken: string;
  public readonly authClaim: string;
  public readonly expiresIn: number;
  public readonly idToken: string;
  public readonly ncClaim: Nullable<string>;
  public readonly otpClaim: Nullable<string>;
  public readonly refreshToken: string;
  public readonly salt: string;
  public readonly scope: string;
  public readonly tokenType: string;
  public readonly user: Omit<IAppUser, keyof ValueObject>;

  public constructor(
    scope: string,
    tokenType: string,
    accessToken: string,
    expiresIn: number,
    refreshToken: string,
    idToken: string,
    salt: string,
    user: IAppUser,
    authClaim: string,
    otpClaim?: Nullable<string>,
    ncClaim?: Nullable<string>
  );

  public constructor(other: JsonObject);
  public constructor(...args: Array<any>);

  public constructor(...args: Array<any>) {
    const Class = this.constructor as typeof AuthenticationData;

    let other: JsonObject = {};
    if (args.length === 1) {
      other = args[0];
    } else if (args.length === PROPS.length) {
      PROPS.reduce((authData, prop, index) => {
        authData[prop] = args[index];
        return authData;
      }, other);
    }

    if (!Class.isAssignableFrom(other)) {
      throw new AppException(Constants.Error.S_ERROR, 'Invalid argument value.');
    }

    this.scope = other.scope;
    this.tokenType = other.tokenType;
    this.accessToken = other.accessToken;
    this.expiresIn = other.expiresIn;
    this.refreshToken = other.refreshToken;
    this.idToken = other.idToken;
    this.salt = other.salt;
    this.user = { id: other.user.id, type: 'user' };
    this.authClaim = other.authClaim;
    this.otpClaim = other.otpClaim;
    this.ncClaim = other.ncClaim;
  }
}
