import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.HtmlEditorToolbarActionI18n = {
  bold: {
    // Translators: Label for the Bold action of HTML editor toolbar
    label: gettext('messaging', 'bold', 'htmlEditorToolbarActionLabel')
  },
  italic: {
    // Translators: Label for the Italic action of HTML editor toolbar
    label: gettext('messaging', 'italic', 'htmlEditorToolbarActionLabel')
  },
  underline: {
    // Translators: Label for the Underline action of HTML editor toolbar
    label: gettext('messaging', 'underline', 'htmlEditorToolbarActionLabel')
  },
  textColor: {
    // Translators: Label for the Text Color action of HTML editor toolbar
    label: gettext('messaging', 'textColor', 'htmlEditorToolbarActionLabel')
  },
  backgroundColor: {
    // Translators: Label for the Background Color action of HTML editor toolbar
    label: gettext('messaging', 'backgroundColor', 'htmlEditorToolbarActionLabel')
  },
  subscript: {
    // Translators: Label for the Subscript action of HTML editor toolbar
    label: gettext('messaging', 'subscript', 'htmlEditorToolbarActionLabel')
  },
  superscript: {
    // Translators: Label for the Superscript action of HTML editor toolbar
    label: gettext('messaging', 'superscript', 'htmlEditorToolbarActionLabel')
  },
  alignLeft: {
    // Translators: Label for the Align Left action of HTML editor toolbar
    label: gettext('messaging', 'alignLeft', 'htmlEditorToolbarActionLabel')
  },
  alignCenter: {
    // Translators: Label for the Align Center action of HTML editor toolbar
    label: gettext('messaging', 'alignCenter', 'htmlEditorToolbarActionLabel')
  },
  alignRight: {
    // Translators: Label for the Align Right action of HTML editor toolbar
    label: gettext('messaging', 'alignRight', 'htmlEditorToolbarActionLabel')
  },
  alignJustified: {
    // Translators: Label for the Align Justified action of HTML editor toolbar
    label: gettext('messaging', 'alignJustified', 'htmlEditorToolbarActionLabel')
  },
  orderedList: {
    // Translators: Label for the Ordered List action of HTML editor toolbar
    label: gettext('messaging', 'orderedList', 'htmlEditorToolbarActionLabel')
  },
  bulletedList: {
    // Translators: Label for the Unordered List action of HTML editor toolbar
    label: gettext('messaging', 'bulletedList', 'htmlEditorToolbarActionLabel')
  },
  // decreaseIndent: {
  //   // Translators: Label for the Decrease Indent action of HTML editor toolbar
  //   label: gettext('messaging', 'decreaseIndent', 'htmlEditorToolbarActionLabel')
  // },
  // increaseIndent: {
  //   // Translators: Label for the Increase Indent action of HTML editor toolbar
  //   label: gettext('messaging', 'increaseIndent', 'htmlEditorToolbarActionLabel')
  // },
  // link: {
  //   // Translators: Label for the Link action of HTML editor toolbar
  //   label: gettext('messaging', 'link', 'htmlEditorToolbarActionLabel')
  // },
  clearFormatting: {
    // Translators: Label for the Clear Formatting action of HTML editor toolbar
    label: gettext('messaging', 'clearFormatting', 'htmlEditorToolbarActionLabel')
  }
  // important: {
  //   // Translators: Label for the Important action of HTML editor toolbar
  //   label: gettext('messaging', 'important', 'htmlEditorToolbarActionLabel')
  // },
  // billable: {
  //   // Translators: Label for the Billable action of HTML editor toolbar
  //   label: gettext('messaging', 'billable', 'htmlEditorToolbarActionLabel')
  // }
};

export default i18n;
