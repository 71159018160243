import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { MessageActionToolbar } from '../../constants/action-context';

const i18n: MessagingI18n.MessageToolbarActionI18n = {
  assignCategory: {
    // Translators: Label for tbe Assign Category action
    label: gettext('messaging', 'assignCategory', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  assignMessageTo: {
    // Translators: Label for tbe Assign Message To action
    label: gettext('messaging', 'assignMessageTo', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  composeConsultationRequest: {
    // Translators: Label for the New Consultation action
    label: gettext('messaging', 'composeConsultationRequest', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  composeMessage: {
    // Translators: Label for the New Message action
    label: gettext('messaging', 'composeMessage', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  reply: {
    // Translators: Label for the Reply To Message action
    label: gettext('messaging', 'reply', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  replyAll: {
    // Translators: Label for the Reply All action
    label: gettext('messaging', 'replyAll', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  forward: {
    // Translators: Label for the Forward Message action
    label: gettext('messaging', 'forward', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  sendMessageToEMR: {
    // Translators: Label for the Send Message To EMR action
    label: gettext('messaging', 'sendMessageToEMR', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  markRead: {
    // Translators: Label for the Mark Message As Read action
    label: gettext('messaging', 'markRead', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  markUnread: {
    // Translators: Label for the Mark Message As Unread action
    label: gettext('messaging', 'markUnread', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  recall: {
    // Translators: Label for the Recall Message action
    label: gettext('messaging', 'recall', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  archive: {
    // Translators: Label for the Archive Message action
    label: gettext('messaging', 'archive', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  composeReferral: {
    // Translators: Label for the Compose Referral Message action
    label: gettext('messaging', 'composeReferral', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  composeHrmMessage: {
    // Translators: Label for the Compose HRM Message action
    label: gettext('messaging', 'composeHrmMessage', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  deleteMessage: {
    // Translators: Label for the Delete Message action
    label: gettext('messaging', 'deleteMessage', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  discardDraft: {
    // Translators: Label for the Discard Draft Message action
    label: gettext('messaging', 'discardDraft', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  print: {
    // Translators: Label for the Print Message action
    label: gettext('messaging', 'print', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  },
  closeMessage: {
    // Translators: Label for the Close Message action
    label: gettext('messaging', 'closeMessage', 'messageToolbarActionLabel'),
    context: MessageActionToolbar
  }
};

export default i18n;
