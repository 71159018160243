module.exports = ({ addUtilities }) => {
  addUtilities({
    '.embed-responsive': {
      position: 'relative',
      display: 'block',
      width: '100%',
      padding: 0,
      overflow: 'hidden',

      '&::before': {
        display: 'block',
        content: ''
      }
    },
    '.embed-responsive-item': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 0
    }
  });
};
