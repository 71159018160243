import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.View.OneTimeMessage.VerifyAccessCode = {
  action: {
    resend: {
      prefix: gettext('messaging', 'resendPrefix', 'oneTimeMessageVerifyAccessCodeViewActionLabel'),
      label: gettext('messaging', 'resend', 'oneTimeMessageVerifyAccessCodeViewActionLabel')
    },
    submit: {
      label: gettext('messaging', 'submit', 'oneTimeMessageVerifyAccessCodeViewActionLabel')
    }
  },
  body: gettext('messaging', 'body', 'oneTimeMessageVerifyAccessCodeView', 'safe-html'),
  title: gettext('messaging', 'title', 'oneTimeMessageVerifyAccessCodeView', 'safe-html')
};

export default i18n;
