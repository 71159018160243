import React from 'react';
import type { Workbox } from 'workbox-window';
import { useInterval } from '../app-state/hooks';
import { getDisplayMode } from '../app/layout/context';
import * as sw from '../serviceWorker';

const WORKER_UPDATE_CHECK_DELAY =
  process.env.REACT_APP_ENV === 'production' ? 86400000 /* 24 hours */ : 300000; /* 5 minutes */

export const useServiceWorker = (): Workbox | null => {
  const [serviceWorker, setServiceWorker] = React.useState<Workbox | null>(null);

  useInterval(
    React.useCallback(() => void (serviceWorker !== null && serviceWorker.update()), [serviceWorker]),
    serviceWorker === null ? null : WORKER_UPDATE_CHECK_DELAY
  );

  React.useEffect(() => {
    const displayMode = getDisplayMode();
    sw.register({
      queryPeriodicSyncPermission: displayMode === 'standalone' || displayMode === 'window-controls-overlay'
    }).then(setServiceWorker, (): void => setServiceWorker(null));
  }, []);

  return serviceWorker;
};
