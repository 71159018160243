import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { GroupObject } from '.';
import type { GroupObjectPreferencesValue as DV } from '../types';

const TYPE = process.env.GROUP_OBJECT_TYPE_PREFERENCES;

/** @public */
export class GroupObjectPreferences extends GroupObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      (Utils.isNil(dv.integrations) || Utils.isNonArrayObjectLike(dv.integrations)) &&
      (Utils.isNil(dv.joinInvitationExpiry) || Utils.isInteger(dv.joinInvitationExpiry)) &&
      (Utils.isNil(dv.messageCategoryList) || Utils.isArray(dv.messageCategoryList))
    );
  }

  public updateValue(newValue: DV): Promise<GroupObjectPreferences> {
    const Class = this.constructor as typeof GroupObjectPreferences;
    return Class.updateValue(this, newValue);
  }
}
