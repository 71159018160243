import { AccountActionPayload } from '@sigmail/app-state';
import { AppException, AppUserGroup, Constants, InstituteInfo, MemberRole, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ClientObjectProfile, GroupContactListItem, GroupObjectContactInfo, GroupObjectServerRights } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../../..';
import { CIRCLE_OF_CARE } from '../../../../constants/medical-institute-user-group-type-identifier';
import { EMPTY_PLAIN_OBJECT } from '../../../constants';
import { accessTokenSelector, authClaimSelector, currentUserSelector } from '../../../selectors/auth';
import { clientIdSelector } from '../../../selectors/user-object';
import { ActionInitParams, FetchObjectsRequestData } from '../../base-action';
import { batchQueryDataAction } from '../../batch-query-data-action';
import { createCircleOfCareGroupAction } from '../../groups/create-circle-of-care-group-action';
import { BaseSendAccountInvitationAction, BaseSendAccountInvitationPayload } from './base';
import { ContactInfoBasic as CaregiverContactInfoBasic, SendCaregiverAccountInvitationAction } from './caregiver';
import {
  ContactInfoBasic as GuestContactInfoBasic,
  ContactInfoProtected as GuestContactInfoProtected,
  SendGuestAccountInvitationAction
} from './guest';
import {
  ContactInfoBasic as NonGuestContactInfoBasic,
  ContactInfoProtected as NonGuestContactInfoProtected,
  SendNonGuestAccountInvitationAction
} from './non-guest';

type Payload = AccountActionPayload.SendAccountInvitation;

type CombinedPayload = Omit<Payload, 'role'> &
  Omit<AccountActionPayload.SendCaregiverAccountInvitation, 'role'> &
  Omit<AccountActionPayload.SendGuestAccountInvitation, 'role'> &
  Omit<AccountActionPayload.SendNonGuestAccountInvitation, 'role'> & { readonly role: MemberRole };

const Logger = getLoggerWithPrefix('Action', 'sendAccountInvitationAction:');

const trimmedStringOrNil = (value?: any): string => Utils.trimOrDefault(value, undefined!);

const GUEST_PROFILE_DATA_BASIC: ReadonlyArray<keyof GuestContactInfoBasic> = [
  ...Constants.PERSON_NAME_KEY_LIST,
  'cellNumber',
  'emailAddress',
  'homeNumber',
  'role'
];

const GUEST_PROFILE_DATA_PROTECTED: ReadonlyArray<keyof GuestContactInfoProtected> = [
  'birthDate',
  'gender',
  'healthCardNumber',
  'healthPlanJurisdiction'
];

const NON_GUEST_PROFILE_DATA_BASIC: ReadonlyArray<keyof NonGuestContactInfoBasic> = [
  ...Constants.PERSON_NAME_KEY_LIST,
  'cellNumber',
  'emailAddress',
  'faxNumber',
  'officeNumber',
  'officeNumberExt',
  'role',
  'specialty'
];

const CAREGIVER_PROFILE_DATA_BASIC: ReadonlyArray<keyof CaregiverContactInfoBasic> = [
  ...Constants.PERSON_NAME_KEY_LIST,
  'cellNumber',
  'emailAddress',
  'role',
  'relationship'
];

async function buildGroupContactList({
  dispatch,
  getState,
  payload
}: ActionInitParams<Pick<Payload, 'groupList'>>): Promise<Pick<BaseSendAccountInvitationPayload, 'groupClaimList' | 'groupList'>> {
  Logger.info('Building a contact list of all groups the invited user will become a member of.');

  const { groupList } = payload;

  const accessToken = accessTokenSelector(getState());
  const authState = authClaimSelector(getState());
  const clientId = clientIdSelector(getState())!;
  const currentUser = currentUserSelector(getState())!;

  const query: FetchObjectsRequestData = {
    authState,
    userObjectsByType: [{ type: process.env.CLIENT_OBJECT_TYPE_PROFILE, userId: clientId }]
  };

  const groupClaimList: Array<string> = [];
  for (const group of groupList) {
    let { id: groupId, name: groupName } = group;

    // GK@20220414: AppUserGroup.isValidId() is not used intentionally!
    if (typeof groupId !== 'undefined') {
      query.userObjectsByType!.push({ type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS, userId: groupId });
    } else {
      const groupClaim = await dispatch!(
        createCircleOfCareGroupAction({
          apiAccessToken: accessToken,
          currentUserId: currentUser.id,
          groupName,
          roleAuthClaim: authState
        })
      );

      const { groupId: id } = Utils.decodeIdToken(groupClaim);
      if (!AppUserGroup.isValidId(id)) {
        throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Group ID could not be extracted from group claim.');
      }

      groupId = id;
      groupClaimList.push(groupClaim);
    }

    query.userObjectsByType!.push({ type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO, userId: groupId });
  }

  const { userObjectsByType: userObjectList } = await dispatch!(batchQueryDataAction({ accessToken, query }));
  if (!Utils.isArray<NonNullable<typeof userObjectList>[0]>(userObjectList)) {
    throw new Api.MalformedResponseException('Failed to fetch required objects.');
  }

  let institute: InstituteInfo | undefined;
  const groupContactInfoList: Array<GroupObjectContactInfo> = [];
  for (const json of userObjectList) {
    if (json.type === process.env.CLIENT_OBJECT_TYPE_PROFILE) {
      const clientProfile = await new ClientObjectProfile(json).decryptedValue();
      institute = Utils.pick(clientProfile, Constants.INSTITUTE_INFO_KEY_LIST);
      continue;
    }

    if (json.type === process.env.GROUP_OBJECT_TYPE_CONTACT_INFO) {
      groupContactInfoList.push(new GroupObjectContactInfo(json));
      continue;
    }

    const groupServerRightsObject = new GroupObjectServerRights(json);
    const { groupClaim } = await groupServerRightsObject.decryptedValue();
    groupClaimList.push(groupClaim);
  }

  const groupContactList = await Promise.all(
    groupContactInfoList.map<Promise<GroupContactListItem>>(async (groupContactInfoObject) => {
      const { groupName } = await groupContactInfoObject.decryptedValue();
      return {
        type: 'group',
        groupType: CIRCLE_OF_CARE,
        id: groupContactInfoObject.userId,
        groupData: { groupName, institute }
      };
    })
  );

  return { groupClaimList, groupList: groupContactList };
}

export const sendAccountInvitationAction = (basePayload: Payload): AppThunk<Promise<string>> => {
  return async (dispatch, getState, { apiService }) => {
    const params: Omit<ActionInitParams<BaseSendAccountInvitationPayload>, 'payload'> = {
      apiService,
      dispatch,
      getState,
      logger: Logger
    };

    const isInviteeRoleGuest = Utils.isGuestRole(basePayload.role);
    const isInviteeRoleCaregiver = !isInviteeRoleGuest && Utils.isCaregiverRole(basePayload.role);
    const isInviteeRoleNonGuest = !isInviteeRoleGuest && !isInviteeRoleCaregiver && Utils.isNonGuestRole(basePayload.role);

    let action: BaseSendAccountInvitationAction;
    let payload: BaseSendAccountInvitationPayload = {
      groupClaimList: [],
      groupList: [],
      institute: EMPTY_PLAIN_OBJECT as InstituteInfo,
      notifyBy: basePayload.notifyBy,
      role: basePayload.role
    };

    if (!isInviteeRoleCaregiver) {
      const { groupClaimList, groupList } = await buildGroupContactList({ ...params, payload: basePayload });

      if (!Utils.isNonEmptyArray<typeof groupList[0]>(groupList)) {
        throw new AppException(Constants.Error.S_ERROR, '<groupList> must be a non-empty array.');
      } else if (isInviteeRoleGuest && groupList.length !== 1) {
        throw new AppException(Constants.Error.S_ERROR, 'Expected <groupList.length> to be 1.');
      }

      payload = {
        ...payload,
        groupClaimList,
        groupList,
        institute: groupList[0].groupData.institute!
      };
    }

    if (isInviteeRoleGuest) {
      action = new SendGuestAccountInvitationAction({
        ...params,
        payload: {
          ...payload,
          maskedBirthDate: (basePayload as CombinedPayload).maskedBirthDate!,
          profileData: {
            basic: Utils.mapValues(Utils.pick(basePayload as CombinedPayload, GUEST_PROFILE_DATA_BASIC), trimmedStringOrNil),
            protected: Utils.mapValues(Utils.pick(basePayload as CombinedPayload, GUEST_PROFILE_DATA_PROTECTED))
          }
        }
      });
    } else if (isInviteeRoleNonGuest) {
      action = new SendNonGuestAccountInvitationAction({
        ...params,
        payload: {
          ...payload,
          notifyBy: 'email',
          profileData: {
            basic: Utils.mapValues(Utils.pick(basePayload as CombinedPayload, NON_GUEST_PROFILE_DATA_BASIC), trimmedStringOrNil),
            protected: EMPTY_PLAIN_OBJECT as NonGuestContactInfoProtected
          }
        }
      });
    } else if (isInviteeRoleCaregiver) {
      action = new SendCaregiverAccountInvitationAction({
        ...params,
        payload: {
          ...payload,
          contactInfo: Utils.mapValues(Utils.pick(basePayload as CombinedPayload, CAREGIVER_PROFILE_DATA_BASIC), trimmedStringOrNil),
          password: (basePayload as CombinedPayload).password,
          username: (basePayload as CombinedPayload).username
        }
      });
    } else {
      throw new Error(`Unhandled case - ${basePayload.role}`);
    }

    return action.execute();
  };
};
