import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { NotificationObject } from '.';
import { DataObjectCalendarEvent } from '../data-object/calendar-event';
import { DataObjectMsgBody } from '../data-object/msg-body';
import { DataObjectMsgMetadata } from '../data-object/msg-metadata';
import { DataObjectMsgReadReceipt } from '../data-object/msg-read-receipt';
import type { NotificationObjectRecallMessageValue as DV } from '../types';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_RECALL_MESSAGE;

/** @public */
export class NotificationObjectRecallMessage extends NotificationObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      DataObjectMsgMetadata.isValidId(dv.header) &&
      DataObjectMsgBody.isValidId(dv.body) &&
      DataObjectMsgReadReceipt.isValidId(dv.readReceipt) &&
      ((Utils.isUndefined(dv.eventTime) && Utils.isUndefined(dv.eventObjectId)) ||
        (Utils.isInteger(dv.eventTime) && DataObjectCalendarEvent.isValidId(dv.eventObjectId)))
    );
  }
}
