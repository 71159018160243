import type {
  AlgorithmCode,
  EncryptAsymmetricKeyAlgorithmCode,
  EncryptEncapsulatedKeyAlgorithmCode,
  EncryptObjectAlgorithmCode,
  EncryptWithParametersAlgorithmCode,
  NonEncryptedObjectAlgorithmCode
} from '@sigmail/common';
import { E_UNEXPECTED_ALGORITHM, E_UNKNOWN_ALGORITHM_CODE } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';
import type {
  AlgorithmLike,
  EncryptAsymmetricKeyAlgorithm,
  EncryptEncapsulatedKeyAlgorithm,
  EncryptObjectAlgorithm,
  EncryptWithParametersAlgorithm
} from '../types';
import { EncryptAsymmetricKeyAlgorithmImpl } from './encrypt-asymmetric-key';
import { EncryptEncapsulatedKeyAlgorithmImpl } from './encrypt-encapsulated-key';
import { EncryptObjectAlgorithmImpl } from './encrypt-object-algorithm';
import { EncryptWithParametersAlgorithmImpl } from './encrypt-with-parameters';
import { NonEncryptedObjectAlgorithmImpl } from './non-encrypted-object-algorithm';

// eslint-disable-next-line no-unused-vars
type AlgorithmClassImplTuple = [new (...args: Array<any>) => AlgorithmLike, AlgorithmLike];

/** A map of known algorithm codes to their base class and implementation. */
const AlgorithmMap: ReadonlyMap<AlgorithmCode, AlgorithmClassImplTuple> = new Map<
  AlgorithmCode,
  AlgorithmClassImplTuple
>([
  [
    process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT,
    [NonEncryptedObjectAlgorithmImpl, new NonEncryptedObjectAlgorithmImpl()]
  ],
  [
    process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC,
    [EncryptAsymmetricKeyAlgorithmImpl, new EncryptAsymmetricKeyAlgorithmImpl()]
  ],
  [
    process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS,
    [EncryptWithParametersAlgorithmImpl, new EncryptWithParametersAlgorithmImpl('EncryptWithParametersAlgorithmImpl')]
  ],
  [
    process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE,
    [EncryptAsymmetricKeyAlgorithmImpl, new EncryptAsymmetricKeyAlgorithmImpl()]
  ],
  [
    process.env.ALGORITHM_CODE_ENCRYPT_ENCAPSULATED_KEY,
    [EncryptEncapsulatedKeyAlgorithmImpl, new EncryptEncapsulatedKeyAlgorithmImpl()]
  ],
  [process.env.ALGORITHM_CODE_ENCRYPT_OBJECT, [EncryptObjectAlgorithmImpl, new EncryptObjectAlgorithmImpl()]]
]);

/* eslint-disable no-unused-vars */
/** @public */
export function getAlgorithm(code: EncryptAsymmetricKeyAlgorithmCode): EncryptAsymmetricKeyAlgorithm;

/** @public */
export function getAlgorithm(code: EncryptWithParametersAlgorithmCode): EncryptWithParametersAlgorithm;

/** @public */
export function getAlgorithm(code: EncryptEncapsulatedKeyAlgorithmCode): EncryptEncapsulatedKeyAlgorithm;

/** @public */
export function getAlgorithm(
  code: NonEncryptedObjectAlgorithmCode | EncryptObjectAlgorithmCode
): EncryptObjectAlgorithm;
/* eslint-enable no-unused-vars */

/** @public */
// eslint-disable-next-line no-redeclare
export function getAlgorithm(code: AlgorithmCode): AlgorithmLike {
  const algoCode = +code as AlgorithmCode;

  if (!AlgorithmMap.has(algoCode)) {
    throw new SigmailCryptoException(E_UNKNOWN_ALGORITHM_CODE);
  }

  const [AlgorithmClass, algorithm] = AlgorithmMap.get(algoCode)!;
  if (!(algorithm instanceof AlgorithmClass)) {
    throw new SigmailCryptoException(E_UNEXPECTED_ALGORITHM);
  }

  return algorithm;
}
