import { SchedulingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogConfirmRecallEvent } from '../../constants/action-context';

const i18n: SchedulingI18n.Dialog.VideoConsent = {
  // Translators: (HTML) Title for the Video Consent dialog
  title: gettext('scheduling', 'videoConsentDialog', 'title', 'safe-html'),
  // Translators: (HTML) Message displayed to inform user that ... TODO
  body: gettext('scheduling', 'videoConsentDialog', 'body', 'safe-html'),
  // Translators: Label displayed for the "I consent to the video meeting" \
  // input
  inputLabelConsent: gettext('scheduling', 'videoConsentDialog', 'inputLabelConsent'),
  // Translators: Label displayed for the "I do not consent to video \
  // meeting" input
  inputLabelNoConsent: gettext('scheduling', 'videoConsentDialog', 'inputLabelNoConsent'),
  // Translators: ... TODO
  notes: gettext('scheduling', 'videoConsentDialog', 'notes'),
  action: {
    reject: {
      // Translators: Label for the Cancel action
      label: gettext('scheduling', 'videoConsentDialog', 'actionLabelReject'),
      context: DialogConfirmRecallEvent
    },
    accept: {
      // Translators: Label for the Confirm action
      label: gettext('scheduling', 'videoConsentDialog', 'actionLabelAccept'),
      context: DialogConfirmRecallEvent
    }
  }
};

export default i18n;
