import { Chip, ChipProps } from '@material-ui/core';
import { Localization } from '@material-ui/core/locale';
import { CountryCodeCanada, Utils } from '@sigmail/common';
import React from 'react';
import { MutableRef } from 'sigmail';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../../../app-state/actions/constants';
import { FrenchCanada } from '../../../constants/language-codes';
import { ContactListResource } from '../../../hooks';
import { useTranslation } from '../../../i18n';
import { I18N_NS_GLOBAL } from '../../../i18n/config/namespace-identifiers';
import globalI18n from '../../../i18n/global';
import { ContactListItemUtil } from '../../../utils/contact-list';
import { getSpecialtyListByRole } from '../../../utils/get-specialty-list-by-role';
import { MemberAvatar } from '../member-avatar.component';
import { AutocompleteField, AutocompleteFieldProps } from './autocomplete-field.component';

export type ContactListOption = ReturnType<ContactListResource['value']>[0];

export interface AutoCompleteContactListFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
> extends Omit<
    AutocompleteFieldProps<ContactListOption, Multiple, DisableClearable, /* FreeSolo := */ false>,
    'freeSolo' | 'ref'
  > {}

const frCA: NonNullable<NonNullable<Localization['props']>>['MuiAutocomplete'] = {
  clearText: 'Vider',
  closeText: 'Fermer',
  loadingText: 'Chargement...',
  noOptionsText: 'Pas de résultats',
  openText: 'Ouvrir'
};

const AutoCompleteContactListFieldComponent = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>(
  {
    clearText,
    closeText,
    loadingText,
    noOptionsText,
    openText,
    ...props
  }: AutoCompleteContactListFieldProps<Multiple, DisableClearable>,
  ref: MutableRef<HTMLDivElement>
) => {
  // prettier-ignore
  const { i18n: { language: locale }, t } = useTranslation([I18N_NS_GLOBAL]);

  const localeProps = Utils.defaults(
    {},
    { clearText, closeText, loadingText, noOptionsText, openText },
    (locale === FrenchCanada ? frCA : undefined) as unknown
  );

  const defaultOptionLabelGetter = React.useCallback<Required<typeof props>['getOptionLabel']>(
    (contact) =>
      contact.type === 'user' && contact.userData.isOneTimeContact === true
        ? contact.userData.emailAddress
        : ContactListItemUtil.getFullName(contact),
    []
  );

  const defaultOptionSelectedGetter = React.useCallback<Required<typeof props>['getOptionSelected']>(
    (option, value) => option.type === value.type && option.id === value.id,
    []
  );

  const defaultOptionRenderer = React.useCallback<Required<typeof props>['renderOption']>(
    (option) => {
      let specialty: string | undefined = undefined;
      let addressLevel1: string | undefined = undefined;
      let addressLevel2: string | undefined = undefined;

      if (option.type === 'user') {
        if (Utils.isGuestRole(option.userData.role)) {
          const { healthCardNumber: maskedHealthPlanNumber } = option.userData;
          if (Utils.isString(maskedHealthPlanNumber) && maskedHealthPlanNumber.trim().length > 0) {
            let { healthPlanJurisdiction: jurisdiction } = option.userData;
            if (!Utils.isString(jurisdiction)) {
              jurisdiction = DEFAULT_HEALTH_PLAN_JURISDICTION;
            }

            let index = globalI18n.healthPlanJurisdictionList.findIndex(({ code }) => code === jurisdiction);
            if (index !== -1) {
              jurisdiction = t(globalI18n.healthPlanJurisdictionList[index].label);
            } else {
              const [countryCode, stateCode] = jurisdiction.split('$') as [CountryCodeCanada, string];
              jurisdiction = '';
              if (
                Utils.isString(countryCode) &&
                Utils.isString(stateCode) &&
                Utils.isArray(globalI18n.addressLevel1[countryCode])
              ) {
                index = globalI18n.addressLevel1[countryCode].findIndex(({ code }) => code === stateCode);
                if (index !== -1) {
                  jurisdiction = t(globalI18n.addressLevel1[countryCode][index].abbr);
                }
              }
            }

            if (jurisdiction!.length > 0) {
              specialty = `${maskedHealthPlanNumber} (${jurisdiction})`;
            } else {
              specialty = maskedHealthPlanNumber;
            }
          }
        } else {
          specialty = option.userData.specialty;

          if (Utils.isString(specialty)) {
            const specialtyList = getSpecialtyListByRole(option.userData.role);
            const index = specialtyList.findIndex(({ code }) => code === specialty);
            specialty = index === -1 ? undefined : t(specialtyList[index]!.label);
          }
        }

        addressLevel1 = option.userData.addressLevel1;
        addressLevel2 = option.userData.addressLevel2;
      } else if (option.type === 'group' && Utils.isString(option.groupData.institute?.name)) {
        specialty = option.groupData.institute!.name;
        addressLevel2 = option.groupData.institute?.addressLevel2;
        addressLevel1 = option.groupData.institute?.addressLevel1;
      }

      const primaryText = defaultOptionLabelGetter(option);
      const secondaryText = Utils.joinPostalAddress({ addressLine2: specialty, addressLevel2, addressLevel1 });

      return (
        <React.Fragment>
          <div className="MuiAutocomplete-optionAvatar">
            {option.type === 'group' ? (
              <MemberAvatar firstName={option.groupData?.groupName} />
            ) : (
              <MemberAvatar
                firstName={option.userData?.firstName}
                lastName={option.userData?.lastName}
                license={Utils.getLicenseTypeFromRole(option.userData.role)}
              />
            )}
          </div>

          <div className="MuiAutocomplete-optionLabel" title={`${primaryText} (${secondaryText})`}>
            <span className="MuiAutocomplete-optionLabelPrimaryText" title={primaryText}>
              {primaryText}
            </span>
            <span className="MuiAutocomplete-optionLabelSecondaryText" title={secondaryText}>
              {secondaryText}
            </span>
          </div>
        </React.Fragment>
      );
    },
    [t, defaultOptionLabelGetter]
  );

  const readOnly = props.readOnly === true;
  const defaultTagRenderer = React.useCallback<Required<typeof props>['renderTags']>(
    (value, getTagProps) => {
      return value.map((option, index) => {
        let { onDelete, ...tagProps } = getTagProps({ index }) as any;
        if (readOnly) onDelete = undefined;

        const props: ChipProps = {
          disabled: readOnly,
          key: option.id,
          label: defaultOptionLabelGetter(option),
          variant: 'outlined',
          ...tagProps,
          onDelete
        };

        if (option.type === 'group') {
          props.avatar = <MemberAvatar firstName={option.groupData?.groupName} />;
        } else {
          props.avatar = (
            <MemberAvatar
              firstName={option.userData?.firstName}
              lastName={option.userData?.lastName}
              license={Utils.getLicenseTypeFromRole(option.userData?.role)}
            />
          );
        }

        return <Chip {...props} />;
      });
    },
    [defaultOptionLabelGetter, readOnly]
  );

  return (
    <AutocompleteField
      ref={ref}
      getOptionLabel={defaultOptionLabelGetter}
      getOptionSelected={defaultOptionSelectedGetter}
      renderOption={defaultOptionRenderer}
      renderTags={defaultTagRenderer}
      {...props}
      {...localeProps}
      freeSolo={false}
    />
  );
};

export const AutocompleteContactListField = React.forwardRef(AutoCompleteContactListFieldComponent) as <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined
>(
  props: Omit<AutocompleteFieldProps<ContactListOption, Multiple, DisableClearable, false>, 'freeSolo'> &
    React.RefAttributes<HTMLDivElement>
) => React.ReactElement<AutocompleteFieldProps<ContactListOption, Multiple, DisableClearable, false>> | null;

(AutocompleteContactListField as any).displayName = 'AutocompleteContactListField';
