import { ApiActionPayload } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ApiFormattedUserObject, IUserObject, ValueFormatVersion } from '@sigmail/objects';
import { AppThunk } from '..';
import { UserObjectCache } from '../user-objects-slice/cache';
import { fetchObjectsAction, FetchObjectsActionResult } from './fetch-objects-action';

export type Payload = ApiActionPayload.FetchUserObjects;

type TByType = NonNullable<ApiActionPayload.BatchQueryData['query']['userObjectsByType']>[0];
type ObjectByTypeTuple = Exclude<Exclude<Payload['objectByType'], TByType>[0], TByType>;

const LOGGER = getLoggerWithPrefix('Action', 'fetchUserObjectsAction:');

export const fetchUserObjectsAction = ({
  cache,
  expectedCount,
  fetch,
  logger,
  objectByType,
  ...payload
}: Payload): AppThunk<Promise<FetchObjectsActionResult>> => async (dispatch): Promise<FetchObjectsActionResult> => {
  if (Utils.isNil(logger)) {
    logger = LOGGER;
    logger.info('== BEGIN ==');
  }

  try {
    if (Utils.isNil(fetch)) fetch = 'cacheMiss';

    const list = (Utils.isNonArrayObjectLike<TByType>(objectByType) ? [objectByType] : objectByType).map<ObjectByTypeTuple>((id) =>
      Utils.isNonArrayObjectLike<TByType>(id) ? [id, fetch!] : id
    );

    const objectsToFetch: Array<TByType> = [];
    const cachedUserObjectList: Array<ApiFormattedUserObject> = [];
    for (const [obj, fetch] of list) {
      let userObject: Readonly<[IUserObject<ValueFormatVersion>, ValueFormatVersion]> | undefined;

      if (fetch !== true) {
        userObject = cache?.find(({ type, userId }) => type === obj.type && userId === obj.userId);
        if (Utils.isNil(userObject) && cache !== UserObjectCache) {
          userObject = UserObjectCache.find(({ type, userId }) => type === obj.type && userId === obj.userId);
          if (Utils.isNotNil(userObject)) {
            cache?.add(...userObject);
            cachedUserObjectList.push(userObject[0].toApiFormatted());
          }
        }
      }

      if (Utils.isNil(userObject) && fetch !== false) {
        objectsToFetch.push(obj);
      }
    }

    const response = await dispatch(
      fetchObjectsAction({
        cache: cache === null ? null : { UserObjectCache: cache },
        expectedCount: { userObjectsByType: expectedCount },
        userObjectsByType: objectsToFetch,
        logger,
        ...payload
      })
    );

    return { ...response, userObjectList: response.userObjectList.concat(cachedUserObjectList) };
  } finally {
    if (logger !== LOGGER) logger.info('== END ==');
  }
};
