import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { EMPTY_ARRAY } from '../../app-state/constants';

const i18n: MessagingI18n.Form.ComposeConsultationRequest = {
  fieldsetGroup: {
    patient: {
      // Translators: Short label for the Consultation Request form's Patient \
      // fieldset group
      shortLabel: gettext('messaging', 'shortLabel', 'consultationRequestFormFieldsetGroupPatient'),
      // Translators: Full label for the Consultation Request form's Patient \
      // fieldset group
      fullLabel: gettext('messaging', 'fullLabel', 'consultationRequestFormFieldsetGroupPatient'),
      fieldsetSearch: {
        formField: {
          searchByHpn: {
            // Translators: Label for the Search By Health Plan Number input \
            // on Consultation Request form
            label: gettext('messaging', 'searchByHpn', 'consultationRequestFormInputLabel')
          }
        }
      },
      fieldsetPatientName: {
        formField: {
          patientFirstName: {
            // Translators: Label for the Patient's First Name input on \
            // Consultation Request form
            label: gettext('messaging', 'patientFirstName', 'consultationRequestFormInputLabel'),
            error: {
              // Translators: Error displayed when Patient's First Name input \
              // on Consultation Request form is left blank
              valueMissing: gettext('messaging', 'patientFirstNameRequired', 'consultationRequestFormError'),
              // Translators: Error displayed when Patient's First Name input \
              // on Consultation Request form doesn't satisfy minimum/maximum \
              // length requirement
              // Translators: Token(s) available: {{MIN_LENGTH}}, {{MAX_LENGTH}}
              length: gettext('messaging', 'patientFirstNameLength', 'consultationRequestFormError'),
              // Translators: Error displayed when Patient's First Name input \
              // on Consultation Request form contains invalid characters
              patternMismatch: gettext('messaging', 'patientFirstNamePattern', 'consultationRequestFormError')
            }
          },
          patientLastName: {
            // Translators: Label for the Patient's Last Name input on \
            // Consultation Request form
            label: gettext('messaging', 'patientLastName', 'consultationRequestFormInputLabel'),
            error: {
              // Translators: Error displayed when Patient's Last Name input \
              // on Consultation Request form is left blank
              valueMissing: gettext('messaging', 'patientLastNameRequired', 'consultationRequestFormError'),
              // Translators: Error displayed when Patient's Last Name input \
              // on Consultation Request form doesn't satisfy minimum/maximum \
              // length requirement
              // Translators: Token(s) available: {{MIN_LENGTH}}, {{MAX_LENGTH}}
              length: gettext('messaging', 'patientLastNameLength', 'consultationRequestFormError'),
              // Translators: Error displayed when Patient's Last Name input \
              // on Consultation Request form contains invalid characters
              patternMismatch: gettext('messaging', 'patientLastNamePattern', 'consultationRequestFormError')
            }
          }
        }
      },
      fieldsetGender: {
        formField: {
          gender: {
            // Translators: Label for the Patient's Gender input on \
            // Consultation Request form
            label: gettext('messaging', 'gender', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Select Patient's Gender dropdown \
            // option on Consultation Request form
            placeholder: gettext('messaging', 'selectGender', 'consultationRequestFormOptionLabel'),
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Patient's Gender input on Consultation Request \
              // form
              valueMissing: gettext('messaging', 'genderRequired', 'consultationRequestFormError')
            }
          }
        }
      },
      fieldsetBirthDate: {
        formField: {
          birthDate: {
            // Translators: Label for the Patient's Birth Date input on \
            // Consultation Request form
            label: gettext('messaging', 'birthDate', 'consultationRequestFormInputLabel'),
            error: {
              // Translators: Error message displayed when no date is \
              // selected for Patient's Birth Date input on Consultation \
              // Request form
              valueMissing: gettext('messaging', 'birthDateRequired', 'consultationRequestFormError'),
              // Translators: Error message displayed when date entered for \
              // Patient's Birth Date input on Consultation Request form is invalid. \
              // For e.x., 2022-02-30
              badInput: gettext('messaging', 'birthDateBadInput', 'consultationRequestFormError')
            }
          }
        }
      },
      fieldsetHealthPlan: {
        formField: {
          hpj: {
            // Translators: Label for the Patient's Health Plan Jurisdiction \
            // input on Consultation Request form
            label: gettext('messaging', 'healthPlanJurisdiction', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Select Health Plan Jurisdiction \
            // dropdown option on Consultation Request form
            placeholder: gettext('messaging', 'selectHealthPlanJurisdiction', 'consultationRequestFormOptionLabel'),
            error: {
              // Translators: Error displayed when no option is selected for \
              // Patient's Health Plan Jurisdiction input on Consultation \
              // Request form
              valueMissing: gettext('messaging', 'healthPlanJurisdictionRequired', 'consultationRequestFormError')
            }
          },
          hpn: {
            // Translators: Label for the Patient's Health Plan Number input \
            // on Consultation Request form
            label: gettext('messaging', 'healthPlanNumber', 'consultationRequestFormInputLabel'),
            error: {
              // Translators: Error displayed when Patient's Health Plan \
              // Number input on Consultation Request form is left blank
              valueMissing: gettext('messaging', 'healthPlanNumberRequired', 'consultationRequestFormError'),
              // Translators: Error displayed when value entered in Patient's \
              // Health Plan Number input on Consultation Request form does \
              // not match the expected format
              patternMismatch: gettext('messaging', 'healthPlanNumberPattern', 'consultationRequestFormError')
            }
          }
        }
      },
      fieldsetPatientAddress: {
        // Translators: Label for the Consultation Request form's Patient's \
        // Address fieldset
        label: gettext('messaging', 'label', 'consultationRequestFormFieldsetPatientAddress'),
        formField: {
          addressType: {
            // Translators: Label for the Patient's Address Type input on \
            // Consultation Request form
            label: gettext('messaging', 'addressType', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Select Patient's Address Type \
            // dropdown option on Consultation Request form
            placeholder: gettext('messaging', 'selectAddressType', 'consultationRequestFormOptionLabel'),
            options: [
              {
                code: 'home',
                // Translators: Label for the Patient's Home Address Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'addressTypeHome', 'consultationRequestFormOptionLabel')
              },
              {
                code: 'work',
                // Translators: Label for the Patient's Work Address Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'addressTypeWork', 'consultationRequestFormOptionLabel')
              },
              {
                code: 'temp',
                // Translators: Label for the Patient's Temporary Address Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'addressTypeTemp', 'consultationRequestFormOptionLabel')
              },
              {
                code: 'old',
                // Translators: Label for the Patient's Old Address Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'addressTypeOld', 'consultationRequestFormOptionLabel')
              }
            ],
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Patient's Address Type input on Consultation \
              // Request form
              valueMissing: gettext('messaging', 'addressTypeRequired', 'consultationRequestFormError')
            }
          },
          patientAddressLine1: {
            // Translators: Label for the Patient's Address Line 1 input on \
            // Consultation Request form
            label: gettext('messaging', 'patientAddressLine1', 'consultationRequestFormInputLabel')
          },
          patientAddressLine2: {
            // Translators: Label for the Patient's Address Line 2 input on \
            // Consultation Request form
            label: gettext('messaging', 'patientAddressLine2', 'consultationRequestFormInputLabel')
          },
          patientAddressLevel1: {
            // Translators: Label for the Patient's Province input on \
            // Consultation Request form
            label: gettext('messaging', 'patientAddressLevel1', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Select Patient's Province dropdown \
            // option on Consultation Request form
            placeholder: gettext('messaging', 'selectPatientAddressLevel1', 'consultationRequestFormOptionLabel')
          },
          patientAddressLevel2: {
            // Translators: Label for the Patient's City input on Consultation \
            // Request form
            label: gettext('messaging', 'patientAddressLevel2', 'consultationRequestFormInputLabel')
          },
          patientPostalCode: {
            // Translators: Label for the Patient's Postal Code input on \
            // Consultation Request form
            label: gettext('messaging', 'patientPostalCode', 'consultationRequestFormInputLabel')
          }
        }
      },
      fieldsetContact: {
        label: gettext('messaging', 'label', 'consultationRequestFormFieldsetPatientContact'),
        formField: {
          contactType: {
            // Translators: Label for the Patient's Contact Type input on \
            // Consultation Request form
            label: gettext('messaging', 'contactType', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Select Patient's Contact Type \
            // dropdown option on Consultation Request form
            placeholder: gettext('messaging', 'selectContactType', 'consultationRequestFormOptionLabel'),
            options: [
              {
                code: 'home',
                // Translators: Label for the Patient's Home Contact Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'contactTypeHome', 'consultationRequestFormOptionLabel')
              },
              {
                code: 'work',
                // Translators: Label for the Patient's Work Contact Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'contactTypeWork', 'consultationRequestFormOptionLabel')
              },
              {
                code: 'mobile',
                // Translators: Label for the Patient's Mobile Contact Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'contactTypeMobile', 'consultationRequestFormOptionLabel')
              },
              {
                code: 'temp',
                // Translators: Label for the Patient's Temporary Contact Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'contactTypeTemp', 'consultationRequestFormOptionLabel')
              },
              {
                code: 'old',
                // Translators: Label for the Patient's Old Contact Type \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'contactTypeOld', 'consultationRequestFormOptionLabel')
              }
            ],
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Patient's Contact Type input on Consultation \
              // Request form
              valueMissing: gettext('messaging', 'contactTypeRequired', 'consultationRequestFormError')
            }
          },
          contactNumber: {
            // Translators: Label for the Patient's Contact Number input on \
            // Consultation Request form
            label: gettext('messaging', 'contactNumber', 'consultationRequestFormInputLabel'),
            error: {
              // Translators: Error message displayed when Patient's Contact \
              // Number input on Consultation Request form is left blank
              valueMissing: gettext('messaging', 'contactNumberRequired', 'consultationRequestFormError')
            }
          }
        }
      }
    },
    consultant: {
      // Translators: Short label for the Consultation Request form's \
      // Consultant fieldset group
      shortLabel: gettext('messaging', 'shortLabel', 'consultationRequestFormFieldsetGroupConsultant'),
      // Translators: Full label for the Consultation Request form's \
      // Consultant fieldset group
      fullLabel: gettext('messaging', 'fullLabel', 'consultationRequestFormFieldsetGroupConsultant'),
      fieldsetConsultant: {
        formField: {
          consultant: {
            // Translators: Label for the Select Consultant input on \
            // Consultation Request form
            label: gettext('messaging', 'consultant', 'consultationRequestFormInputLabel'),
            error: {
              // Translators: Error message displayed when no consultant is \
              // selected on Consultation Request form
              valueMissing: gettext('messaging', 'consultantRequired', 'consultationRequestFormError'),
              // Translators: Error message displayed when selected consultant \
              // does not have the OHIP Billing Number set in their profile
              billingNumberMissing: gettext('messaging', 'billingNumberRequired', 'consultationRequestFormError')
            },
            localeText: {
              // Translators: Label for the Close Popup icon of Select \
              // Consultant input
              closePopup: gettext('messaging', 'closeConsultantPopup', 'consultationRequestFormLocaleText'),
              // Translators: Text to display when there are no contacts to \
              // show in Select Consultant input
              noOptions: gettext('messaging', 'noConsultants', 'consultationRequestFormLocaleText'),
              // Translators: Label for the Open Popup icon of Select \
              // Consultant input
              openPopup: gettext('messaging', 'openConsultantPopup', 'consultationRequestFormLocaleText')
            }
          }
        }
      },
      fieldsetConsultantName: {
        formField: {
          consultantFirstName: {
            // Translators: Label for the Consultant's First Name input on \
            // Consultation Request form
            label: gettext('messaging', 'consultantFirstName', 'consultationRequestFormInputLabel')
          },
          consultantLastName: {
            // Translators: Label for the Consultant's Last Name input on \
            // Consultation Request form
            label: gettext('messaging', 'consultantLastName', 'consultationRequestFormInputLabel')
          }
        }
      },
      fieldsetSpecialty: {
        formField: {
          specialty: {
            // Translators: Label for the Consultant's Specialty input on \
            // Consultation Request form
            label: gettext('messaging', 'specialty', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Specialty Not Set dropdown option on \
            // Consultation Request form
            placeholder: gettext('messaging', 'specialtyNotSet', 'consultationRequestFormInputLabel')
          },
          otherSpecialties: {
            // Translators: Label for the Consultant's Other Specialties input \
            // on Consultation Request form
            label: gettext('messaging', 'otherSpecialties', 'consultationRequestFormInputLabel')
          }
        }
      },
      fieldsetOther: {
        formField: {
          officeNumber: {
            // Translators: Label for the Consultant's Office Number input on \
            // Consultation Request form
            label: gettext('messaging', 'officeNumber', 'consultationRequestFormInputLabel')
          },
          officeNumberExt: {
            // Translators: Label for the Consultant's Office Number Extension \
            // input on Consultation Request form
            label: gettext('messaging', 'officeNumberExt', 'consultationRequestFormInputLabel')
          },
          faxNumber: {
            // Translators: Label for the Consultant's Fax Number input on \
            // Consultation Request form
            label: gettext('messaging', 'faxNumber', 'consultationRequestFormInputLabel')
          },
          cellNumber: {
            // Translators: Label for the Consultant's Cell Number input on \
            // Consultation Request form
            label: gettext('messaging', 'cellNumber', 'consultationRequestFormInputLabel')
          },
          billingNumber: {
            label: ''
          }
        }
      },
      fieldsetConsultantAddress: {
        // Translators: Label for the Consultant's Address fieldset
        label: gettext('messaging', 'label', 'consultationRequestFormFieldsetConsultantAddress'),
        formField: {
          consultantAddress: {
            // Translators: Label for the Consultant's Address input on \
            // Consultation Request form
            label: gettext('messaging', 'consultantAddress', 'consultationRequestFormInputLabel')
          },
          consultantAddressLevel1: {
            // Translators: Label for the Consultant's Province input on \
            // Consultation Request form
            label: gettext('messaging', 'consultantAddressLevel1', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Consultant's Province Not Set \
            // dropdown option on Consultation Request form
            placeholder: gettext('messaging', 'consultantAddressLevel1NotSet', 'consultationRequestFormInputLabel')
          },
          consultantAddressLevel2: {
            // Translators: Label for the Consultant's City input on \
            // Consultation Request form
            label: gettext('messaging', 'consultantAddressLevel2', 'consultationRequestFormInputLabel')
          },
          consultantPostalCode: {
            // Translators: Label for the Consultant's Postal Code input on \
            // Consultation Request form
            label: gettext('messaging', 'consultantPostalCode', 'consultationRequestFormInputLabel')
          }
        }
      }
    },
    service: {
      // Translators: Short label for the Consultation Request form's Service \
      // fieldset group
      shortLabel: gettext('messaging', 'shortLabel', 'consultationRequestFormFieldsetGroupService'),
      // Translators: Full label for the Consultation Request form's Service \
      // fieldset group
      fullLabel: gettext('messaging', 'fullLabel', 'consultationRequestFormFieldsetGroupService'),
      fieldsetService: {
        formField: {
          reason: {
            // Translators: Label for the Reason For Consultation input on \
            // Consultation Request form
            label: gettext('messaging', 'reason', 'consultationRequestFormInputLabel'),
            error: {
              // Translators: Error message displayed when Reason for \
              // Consultation input on Consultation Request form is left blank
              valueMissing: gettext('messaging', 'reasonRequired', 'consultationRequestFormError')
            }
          },
          diagnosticCode: {
            // Translators: Label for the Diagnostic Code input on \
            // Consultation Request form
            label: gettext('messaging', 'diagnosticCode', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Select Diagnostic Code dropdown \
            // option on Consultation Request form
            placeholder: gettext('messaging', 'selectDiagnosticCode', 'consultationRequestFormOptionLabel'),
            options: EMPTY_ARRAY,
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Diagnostic Code input on Consultation Request form
              valueMissing: gettext('messaging', 'diagnosticCodeRequired', 'consultationRequestFormError')
            }
          },
          serviceCode: {
            // Translators: Label for the Service Code input on \
            // Consultation Request form
            label: gettext('messaging', 'serviceCode', 'consultationRequestFormInputLabel'),
            // Translators: Label for the Select Service Code dropdown \
            // option on Consultation Request form
            placeholder: gettext('messaging', 'selectServiceCode', 'consultationRequestFormOptionLabel'),
            options: [
              {
                code: 'K730',
                // Translators: Label for the Service Code K730 - Phone \
                // Consult dropdown option on Consultation Request form
                label: gettext('messaging', 'serviceCodeK730', 'consultationRequestFormOptionLabel')
              },
              {
                code: 'K738',
                // Translators: Label for the Service Code K738 - E-Consult \
                // dropdown option on Consultation Request form
                label: gettext('messaging', 'serviceCodeK738', 'consultationRequestFormOptionLabel')
              }
            ],
            error: {
              // Translators: Error message displayed when no option is \
              // selected for Service Code input on Consultation Request form
              valueMissing: gettext('messaging', 'serviceCodeRequired', 'consultationRequestFormError')
            }
          },
          message: {
            // Translators: Label for the Message input on Consultation Request form
            label: gettext('messaging', 'message', 'consultationRequestFormInputLabel'),
            // Translators: Placeholder for the Message input on Consultation \
            // Request form
            placeholder: gettext('messaging', 'message', 'consultationRequestFormInputHint'),
            // Translators: (HTML) Initial value displayed for the Message \
            // input on Consultation Request form
            initialValue: gettext('messaging', 'message', 'consultationRequestFormInputInitialValue', 'safe-html'),
            error: {
              // Translators: Error message displayed when Message input on \
              // Consultation Request form is left blank
              valueMissing: gettext('messaging', 'messageRequired', 'consultationRequestFormError')
            }
          }
        }
      }
    },
    documents: {
      // Translators: Short label for the Consultation Request form's \
      // Documents fieldset group
      shortLabel: gettext('messaging', 'shortLabel', 'consultationRequestFormFieldsetGroupDocuments'),
      // Translators: Full label for the Consultation Request form's \
      // Documents fieldset group
      fullLabel: gettext('messaging', 'fullLabel', 'consultationRequestFormFieldsetGroupDocuments'),
      fieldsetDocuments: {
        formField: {
          documentList: {
            label: ''
          }
        }
      }
    }
  }
};

export default i18n;
