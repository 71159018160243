import { Nullable, Utils } from '@sigmail/common';

export function dateToUtcValues(
  date?: Nullable<Date | number | string>
): [
  utcFullYear: number,
  utcMonth: number,
  utcDate: number,
  utcHours: number,
  utcMinutes: number,
  utcSeconds: number,
  utcMilliseconds: number
] {
  const dt = Utils.isDate(date) ? date : Utils.isNotNil(date) && new Date(date);
  if (!Utils.isValidDate(dt)) return ([] as unknown) as ReturnType<typeof dateToUtcValues>;

  return [
    dt.getUTCFullYear(),
    dt.getUTCMonth(),
    dt.getUTCDate(),
    dt.getUTCHours(),
    dt.getUTCMinutes(),
    dt.getUTCSeconds(),
    dt.getUTCMilliseconds()
  ];
}
