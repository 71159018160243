import { AnyAction } from '@reduxjs/toolkit';
import { Constants, Utils } from '@sigmail/common';
import React from 'react';
import { useEncounterMetadataResource, useMessageBodyResource } from '../../message-view/hooks';
import { ActionData } from '../../types';
import { ComposeMessageViewState } from '../context';
import { replyToMessageAction } from '../context/actions';

export interface ActionDataReplyToMessage extends ActionData, Pick<ComposeMessageViewState, 'sourceMessage'> {
  dispatch: React.Dispatch<AnyAction>;
  messageKind?: Extract<NonNullable<ComposeMessageViewState['messageKind']>, 'reply' | 'replyAll'> | undefined;
  setActionData: React.Dispatch<ActionData>;
}

export const EncounterStoppedError = new Error();
EncounterStoppedError.name = 'EncounterStoppedError';

export const useReplyToMessageActionHandler = () => {
  const [msgBodyId, setMessageBodyId] = React.useState<number>(undefined!);
  const [encounterMetadataId, setEncounterMetadataId] = React.useState<number>(undefined!);

  const msgBodyResource = useMessageBodyResource(Constants.MessageFormName.Encounter, msgBodyId);
  const encounterMetadataResource = useEncounterMetadataResource(encounterMetadataId);

  return React.useCallback(
    (actionData: ActionDataReplyToMessage) => {
      const { dispatch, failCallback, messageKind, setActionData, sourceMessage, successCallback } = actionData;
      const isReplyToMessage = messageKind === 'reply' || messageKind === 'replyAll';
      const isEncounterMessage = sourceMessage?.msgFlags.isMessageFormEncounter === true;

      if (isEncounterMessage && isReplyToMessage) {
        const { body: sourceMsgBodyId } = sourceMessage!;

        if (msgBodyId !== sourceMsgBodyId) {
          setMessageBodyId(sourceMsgBodyId);
          setActionData({ ...actionData });
          return;
        }

        try {
          const { metadataId } = msgBodyResource.value();
          if (encounterMetadataId !== metadataId) {
            setEncounterMetadataId(metadataId);
            setActionData({ ...actionData });
            return;
          }

          const { timestamp } = encounterMetadataResource.value();
          if (!Utils.isInteger(timestamp.stoppedAt)) {
            throw EncounterStoppedError;
          }
        } catch (error) {
          if (error instanceof Promise) {
            setActionData({ ...actionData });
            return;
          }

          failCallback?.(error);
          return;
        }
      }

      successCallback?.();
      dispatch(replyToMessageAction(messageKind === 'replyAll' ? messageKind : undefined));
    },
    [msgBodyId, encounterMetadataId, encounterMetadataResource, msgBodyResource]
  );
};
