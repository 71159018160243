import { Nullable, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import { EMPTY_ARRAY } from '../../app-state/constants';

interface AccuroEMRAddressRecord {
  readonly addresses?: Nullable<ReadonlyArray<Api.AccuroEMRAddressDTO>>;
}

type AccuroEMRAddressType = 'Business' | 'Mailing' | 'Residence';

export const selectAddress = (
  record: Nullable<AccuroEMRAddressRecord>,
  addressType: AccuroEMRAddressType
): Api.AccuroEMRAddressDTO | null => {
  const addressList = Utils.arrayOrDefault<Api.AccuroEMRAddressDTO>(
    Utils.isNonArrayObjectLike<AccuroEMRAddressRecord>(record) && record.addresses,
    EMPTY_ARRAY
  );
  const index = addressList.findIndex(
    (entry) => Utils.isNonArrayObjectLike<typeof entry>(entry) && entry.type === addressType
  );
  return index === -1 ? null : addressList[index];
};
