import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectFolderListValue as DV, UserObjectFolderListValue } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_FOLDER_LIST;

/** @public */
export class UserObjectFolderList extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv1 = value as UserObjectFolderListValue<1>;
    if (Utils.isUndefined(dv1.$$formatver) || dv1.$$formatver === 1) {
      return (
        Utils.isNonArrayObjectLike(dv1.inbox) &&
        Utils.isNonArrayObjectLike(dv1.sent) &&
        Utils.isNonArrayObjectLike(dv1.drafts)
      );
    }

    const dv = value as DV;
    return dv.$$formatver >= 2 && Utils.isNonArrayObjectLike(dv.msg) && Utils.isNonArrayObjectLike(dv.doc);
  }

  public override updateValue(newValue: DV): Promise<UserObjectFolderList> {
    const Class = this.constructor as typeof UserObjectFolderList;
    return Class.updateValue(this, newValue);
  }
}
