import { ContactListI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import contactCardDataLabel from './contact-card-data-label';
import confirmOperationDialog from './dialog-confirmation';
import contactDetailsDialog from './dialog-contact-details';
import contactListGridFilterDialog from './dialog-contact-list-grid-filter';
import createGuestContactGroupDialog from './dialog-create-guest-contact-group';
import contactListGridFilterForm from './form-contact-list-grid-filter';
import createGuestContactGroupForm from './form-create-guest-contact-group';
import editGuestProfileDialog from './dialog-edit-guest-profile';

const i18n: ContactListI18n.View.ContactListView = {
  heading: {
    // Translators: (HTML) Heading for the Client Contact List view
    clientContacts: gettext('contact-list', 'clientContacts', 'contactListViewHeading', 'safe-html'),
    // Translators: (HTML) Heading for the Global Groups Contact List view
    globalGroups: gettext('contact-list', 'globalGroups', 'contactListViewHeading', 'safe-html'),
    // Translators: (HTML) Heading for the Global User Contact List view
    globalUsers: gettext('contact-list', 'globalUsers', 'contactListViewHeading', 'safe-html'),
    groupMemberList: '',
    // Translators: (HTML) Heading for the Guest Contact List view
    guestContacts: gettext('contact-list', 'guestContacts', 'contactListViewHeading', 'safe-html'),
    // Translators: (HTML) Heading for the Manage Caregivers List view
    manageCaregivers: gettext('contact-list', 'manageCaregivers', 'contactListViewHeading', 'safe-html'),
    // Translators: (HTML) Heading for the Manage Groups List view
    manageGroups: gettext('contact-list', 'manageGroups', 'contactListViewHeading', 'safe-html'),
    // Translators: (HTML) Heading for the Manage Members List view
    manageMembers: gettext('contact-list', 'manageMembers', 'contactListViewHeading', 'safe-html')
  },
  contactCardDataLabel,
  dialog: {
    confirmOperation: confirmOperationDialog,
    contactDetails: contactDetailsDialog,
    createGuestContactGroup: createGuestContactGroupDialog,
    editGuestProfile: editGuestProfileDialog,
    gridFilter: contactListGridFilterDialog
  },
  form: {
    createGuestContactGroup: createGuestContactGroupForm,
    gridFilter: contactListGridFilterForm
  },
  grid: {
    columnHeaderLabel: {
      // Translators: Label for the Academic Degrees column header of \
      // Contact List Grid
      academicDegrees: gettext('contact-list', 'academicDegrees', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Account Status column header of Contact \
      // List Grid
      accountStatus: gettext('contact-list', 'accountStatus', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Account Access Code column header of \
      // Contact List Grid
      accessCode: gettext('contact-list', 'accessCode', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Address column header of Contact List Grid
      address: gettext('contact-list', 'address', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Province column header of Contact List \
      // Grid
      addressLevel1: gettext('contact-list', 'addressLevel1', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the City column header of Contact List Grid
      addressLevel2: gettext('contact-list', 'addressLevel2', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Age column header of Contact List Grid
      age: gettext('contact-list', 'age', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Birth Date column header of Contact List \
      // Grid
      birthDate: gettext('contact-list', 'birthDate', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the End Date column header of \
      // Contact List Grid
      endDate: gettext('contact-list', 'endDate', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Relationship column header of Contact List \
      //  Grid
      relationship: gettext('contact-list', 'relationship', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Start Date column header of Contact List \
      // Grid
      startDate: gettext('contact-list', 'startDate', 'contactListGridColumnHeaderLabel'),
      activationDate: 'Start date',
      // Translators: Label for the Cell Number column header of Contact \
      // List Grid
      cellNumber: gettext('contact-list', 'cellNumber', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Member Of column header of Contact List Grid
      circleOfCareGroupCount: gettext('contact-list', 'memberOf', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Email Address column header of Contact \
      // List Grid
      emailAddress: gettext('contact-list', 'emailAddress', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Name column header of Contact List Grid
      entity: gettext('contact-list', 'entity', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Gender column header of Contact List Grid
      gender: gettext('contact-list', 'gender', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Health Plan Jurisdiction column header of \
      // Contact List Grid
      healthPlanJurisdiction: gettext('contact-list', 'healthPlanJurisdiction', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Health Plan Number column header of \
      // Contact List Grid
      healthPlanNumber: gettext('contact-list', 'healthPlanNumber', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Home Number column header of Contact \
      // List Grid
      homeNumber: gettext('contact-list', 'homeNumber', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Institute Address column header of \
      // Contact List Grid
      institutionAddress: gettext('contact-list', 'institutionAddress', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Institute Name column header of Contact \
      // List Grid
      institutionName: gettext('contact-list', 'institutionName', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Institute Phone Number column header \
      // of Contact List Grid
      institutionPhoneNumber: gettext('contact-list', 'institutionPhoneNumber', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the License column header of Contact List Grid
      license: gettext('contact-list', 'license', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Office Number column header of Contact \
      // List Grid
      officeNumber: gettext('contact-list', 'officeNumber', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Other Specialties column header of \
      // Contact List Grid
      otherSpecialties: gettext('contact-list', 'otherSpecialties', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Registration Date column header of \
      // Contact List Grid
      registrationDate: gettext('contact-list', 'registrationDate', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Role column header of Contact List Grid
      role: gettext('contact-list', 'role', 'contactListGridColumnHeaderLabel'),
      // Translators: Label for the Specialty column header of Contact List \
      // Grid
      specialty: gettext('contact-list', 'specialty', 'contactListGridColumnHeaderLabel')
    },
    // Translators: Value displayed for the Member Of column of Contact List \
    // Grid
    // Translators: Token(s) available: {{count}}
    columnValueMemberOf: ngettext('contact-list', 'memberOf', 'contactListGridColumnValue'),
    localeText: {
      // Translators: Message, for screen readers, explaining which key can be \
      // pressed to deselect a row in the Contact List grid
      ariaRowDeselect: gettext('contact-list', 'ariaRowDeselect', 'contactListGridLocaleText'),
      // Translators: Message, for screen readers, explaining which key can be \
      // pressed to select a row in the Contact List grid
      ariaRowSelect: gettext('contact-list', 'ariaRowSelect', 'contactListGridLocaleText'),
      // Translators: Message, for screen readers, explaining which key can be \
      // pressed to toggle all rows selection in the Contact List grid
      ariaRowSelectAll: gettext('contact-list', 'ariaRowSelectAll', 'contactListGridLocaleText'),
      // Translators: Message, for screen readers, explaining which key can be \
      // pressed to toggle a single row selection in the Contact List grid
      ariaRowToggleSelection: gettext('contact-list', 'ariaRowToggleSelection', 'contactListGridLocaleText'),
      // Translators: Message displayed in the grid when there are no rows \
      // available to show in the Contact List grid
      noRowsToShow: gettext('contact-list', 'noRowsToShow', 'contactListGridLocaleText')
    },
    // Translators: Text displayed when a column's value is not set in Contact \
    // List Grid
    notSetValue: gettext('contact-list', 'notSet', 'contactListGridColumnValue'),
    subheaderLabel: {
      // Translators: Label for the Group Member Count subheader of Contact \
      // List Grid
      // Token(s) available: {{count}}
      groupMemberCount: ngettext('contact-list', 'groupMemberCount', 'contactListGridSubheaderLabel')
    }
  }
};

export default i18n;
