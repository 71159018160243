import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';
import formDataCardiacPeriod from './form-data-cardiac-intake';

const i18n: HealthDataI18n.Form.CardiacIntake = {
  fieldsetArrhythmia: {
    label: gettext('health-data', 'arrhythmia', 'cardiacIntakeFormFieldsetLabel'),
    formField: {
      arrhythmia: {
        label: gettext('health-data', 'arrhythmia', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'arrhythmiaRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'no', 'cardiacIntakeFormArrhythmiaOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'yes', 'cardiacIntakeFormArrhythmiaOptionLabel')
          }
        ]
      },
      arrhythmiaCharacter: {
        label: gettext('health-data', 'arrhythmiaCharacter', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'arrhythmiaCharacterRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 1,
            label: gettext('health-data', 'heartRacing', 'cardiacIntakeFormArrhythmiaCharacterOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'skipping', 'cardiacIntakeFormArrhythmiaCharacterOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'irregularHeartBeat', 'cardiacIntakeFormArrhythmiaCharacterOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'strongHeartBeat', 'cardiacIntakeFormArrhythmiaCharacterOptionLabel')
          }
        ]
      },
      arrhythmiaDevelopment: {
        label: gettext('health-data', 'arrhythmiaDevelopment', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'arrhythmiaDevelopmentRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noChange', 'cardiacIntakeFormArrhythmiaDevelopmentOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'gettingWorse', 'cardiacIntakeFormArrhythmiaDevelopmentOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'gettingBetter', 'cardiacIntakeFormArrhythmiaDevelopmentOptionLabel')
          }
        ]
      },
      arrhythmiaFrequency: {
        label: gettext('health-data', 'arrhythmiaFrequency', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'arrhythmiaFrequencyRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noPattern', 'cardiacIntakeFormArrhythmiaFrequencyOptionLabel')
          },
          {
            codedValue: -1,
            label: gettext('health-data', 'constant', 'cardiacIntakeFormArrhythmiaFrequencyOptionLabel')
          },
          {
            codedValue: 'other',
            label: gettext('health-data', 'other', 'cardiacIntakeFormArrhythmiaFrequencyOptionLabel')
          }
        ]
      },
      arrhythmiaOnset: {
        label: gettext('health-data', 'arrhythmiaOnset', 'cardiacIntakeFormInputLabel'),
        error: {
          valueMissing: gettext('health-data', 'arrhythmiaOnsetRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'arrhythmiaOnsetPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      arrhythmiaOtherFrequency: {
        label: '',
        placeholder: gettext('health-data', 'arrhythmiaOtherFrequency', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'arrhythmiaOtherFrequencyRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'arrhythmiaOtherFrequencyPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      arrhythmiaOtherReliefFactor: {
        label: '',
        placeholder: gettext('health-data', 'arrhythmiaOtherReliefFactor', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'arrhythmiaOtherReliefFactorRequired', 'cardiacIntakeFormError')
        }
      },
      arrhythmiaReliefFactor: {
        label: gettext('health-data', 'arrhythmiaReliefFactor', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'arrhythmiaReliefFactorRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'rest',
            label: gettext('health-data', 'rest', 'cardiacIntakeFormArrhythmiaReliefFactorOptionLabel')
          },
          {
            codedValue: 'other',
            label: gettext('health-data', 'other', 'cardiacIntakeFormArrhythmiaReliefFactorOptionLabel')
          }
        ]
      },
      arrhythmiaStart: {
        label: gettext('health-data', 'arrhythmiaStart', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'arrhythmiaStartRequired', 'cardiacIntakeFormError')
        },
        options: [
          { codedValue: 1, label: gettext('health-data', 'gradually', 'cardiacIntakeFormArrhythmiaStartOptionLabel') },
          { codedValue: 2, label: gettext('health-data', 'suddenly', 'cardiacIntakeFormArrhythmiaStartOptionLabel') }
        ]
      },
      arrhythmiaStop: {
        label: gettext('health-data', 'arrhythmiaStop', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'arrhythmiaStopRequired', 'cardiacIntakeFormError')
        },
        options: [
          { codedValue: 1, label: gettext('health-data', 'gradually', 'cardiacIntakeFormArrhythmiaStopOptionLabel') },
          { codedValue: 2, label: gettext('health-data', 'suddenly', 'cardiacIntakeFormArrhythmiaStopOptionLabel') }
        ]
      },
      arrhythmiaSymptoms: {
        label: gettext('health-data', 'arrhythmiaSymptoms', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'arrhythmiaSymptomsRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'chestPain',
            label: gettext('health-data', 'chestPain', 'cardiacIntakeFormArrhythmiaSymptomsOptionLabel')
          },
          {
            codedValue: 'dyspnea',
            label: gettext('health-data', 'dyspnea', 'cardiacIntakeFormArrhythmiaSymptomsOptionLabel')
          },
          {
            codedValue: 'dizzy',
            label: gettext('health-data', 'dizzy', 'cardiacIntakeFormArrhythmiaSymptomsOptionLabel')
          },
          {
            codedValue: 'syncope',
            label: gettext('health-data', 'syncope', 'cardiacIntakeFormArrhythmiaSymptomsOptionLabel')
          }
        ]
      },
      arrhythmiaTrigger: {
        label: gettext('health-data', 'arrhythmiaTrigger', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'arrhythmiaTriggerRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'none',
            label: gettext('health-data', 'none', 'cardiacIntakeFormArrhythmiaTriggerOptionLabel')
          },
          {
            codedValue: 'activity',
            label: gettext('health-data', 'activity', 'cardiacIntakeFormArrhythmiaTriggerOptionLabel')
          },
          {
            codedValue: 'caffeine',
            label: gettext('health-data', 'caffeine', 'cardiacIntakeFormArrhythmiaTriggerOptionLabel')
          },
          {
            codedValue: 'chocolate',
            label: gettext('health-data', 'chocolate', 'cardiacIntakeFormArrhythmiaTriggerOptionLabel')
          },
          {
            codedValue: 'alcohol',
            label: gettext('health-data', 'alcohol', 'cardiacIntakeFormArrhythmiaTriggerOptionLabel')
          },
          {
            codedValue: 'stress',
            label: gettext('health-data', 'stress', 'cardiacIntakeFormArrhythmiaTriggerOptionLabel')
          },
          {
            codedValue: 'medication',
            label: gettext('health-data', 'medication', 'cardiacIntakeFormArrhythmiaTriggerOptionLabel')
          },
          {
            codedValue: 'random',
            label: gettext('health-data', 'random', 'cardiacIntakeFormArrhythmiaTriggerOptionLabel')
          }
        ]
      }
    }
  },
  fieldsetDyspnea: {
    label: gettext('health-data', 'dyspnea', 'cardiacIntakeFormFieldsetLabel'),
    formField: {
      dyspnea: {
        label: gettext('health-data', 'dyspnea', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'dyspneaRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'no', 'cardiacIntakeFormDyspneaOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'yes', 'cardiacIntakeFormDyspneaOptionLabel')
          }
        ]
      },
      dyspneaDevelopment: {
        label: gettext('health-data', 'dyspneaDevelopment', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'dyspneaDevelopmentRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noChange', 'cardiacIntakeFormDyspneaDevelopmentOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'gettingWorse', 'cardiacIntakeFormDyspneaDevelopmentOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'gettingBetter', 'cardiacIntakeFormDyspneaDevelopmentOptionLabel')
          }
        ]
      },
      dyspneaFrequency: {
        label: gettext('health-data', 'dyspneaFrequency', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'dyspneaFrequencyRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noPattern', 'cardiacIntakeFormDyspneaFrequencyOptionLabel')
          },
          {
            codedValue: -1,
            label: gettext('health-data', 'constant', 'cardiacIntakeFormDyspneaFrequencyOptionLabel')
          },
          {
            codedValue: 'other',
            label: gettext('health-data', 'other', 'cardiacIntakeFormDyspneaFrequencyOptionLabel')
          }
        ]
      },
      dyspneaOtherFrequency: {
        label: '',
        placeholder: gettext('health-data', 'dyspneaOtherFrequency', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'dyspneaOtherFrequencyRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'dyspneaOtherFrequencyPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      dyspneaOnset: {
        label: gettext('health-data', 'dyspneaOnset', 'cardiacIntakeFormInputLabel'),
        error: {
          valueMissing: gettext('health-data', 'dyspneaOnsetRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'dyspneaOnsetPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      dyspneaReliefFactor: {
        label: gettext('health-data', 'dyspneaReliefFactor', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'dyspneaReliefFactorRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'rest',
            label: gettext('health-data', 'rest', 'cardiacIntakeFormDyspneaReliefFactorOptionLabel')
          },
          {
            codedValue: 'inhaler',
            label: gettext('health-data', 'inhaler', 'cardiacIntakeFormDyspneaReliefFactorOptionLabel')
          }
        ]
      },
      dyspneaStart: {
        label: gettext('health-data', 'dyspneaStart', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'dyspneaStartRequired', 'cardiacIntakeFormError')
        },
        options: [
          { codedValue: 1, label: gettext('health-data', 'gradually', 'cardiacIntakeFormDyspneaStartOptionLabel') },
          { codedValue: 2, label: gettext('health-data', 'suddenly', 'cardiacIntakeFormDyspneaStartOptionLabel') }
        ]
      },
      dyspneaSymptoms: {
        label: gettext('health-data', 'dyspneaSymptoms', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'dyspneaSymptomsRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'heaviness',
            label: gettext('health-data', 'heaviness', 'cardiacIntakeFormDyspneaSymptomsOptionLabel')
          },
          {
            codedValue: 'arrhythmia',
            label: gettext('health-data', 'arrhythmia', 'cardiacIntakeFormDyspneaSymptomsOptionLabel')
          },
          {
            codedValue: 'dizzy',
            label: gettext('health-data', 'dizzy', 'cardiacIntakeFormDyspneaSymptomsOptionLabel')
          },
          {
            codedValue: 'syncope',
            label: gettext('health-data', 'syncope', 'cardiacIntakeFormDyspneaSymptomsOptionLabel')
          },
          {
            codedValue: 'cough',
            label: gettext('health-data', 'cough', 'cardiacIntakeFormDyspneaSymptomsOptionLabel')
          },
          {
            codedValue: 'phlegm',
            label: gettext('health-data', 'phlegm', 'cardiacIntakeFormDyspneaSymptomsOptionLabel')
          },
          {
            codedValue: 'fever',
            label: gettext('health-data', 'fever', 'cardiacIntakeFormDyspneaSymptomsOptionLabel')
          },
          {
            codedValue: 'swell',
            label: gettext('health-data', 'swell', 'cardiacIntakeFormDyspneaSymptomsOptionLabel')
          }
        ]
      },
      dyspneaTrigger: {
        label: gettext('health-data', 'dyspneaTrigger', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'dyspneaTriggerRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'none',
            label: gettext('health-data', 'none', 'cardiacIntakeFormDyspneaTriggerOptionLabel')
          },
          {
            codedValue: 'activity',
            label: gettext('health-data', 'activity', 'cardiacIntakeFormDyspneaTriggerOptionLabel')
          },
          {
            codedValue: 'talk',
            label: gettext('health-data', 'talk', 'cardiacIntakeFormDyspneaTriggerOptionLabel')
          },
          {
            codedValue: 'random',
            label: gettext('health-data', 'random', 'cardiacIntakeFormDyspneaTriggerOptionLabel')
          }
        ]
      }
    }
  },
  fieldsetLegPain: {
    label: gettext('health-data', 'legPain', 'cardiacIntakeFormFieldsetLabel'),
    formField: {
      legPain: {
        label: gettext('health-data', 'legPain', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'legPainRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'no', 'cardiacIntakeFormLegPainOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'yes', 'cardiacIntakeFormLegPainOptionLabel')
          }
        ]
      },
      legPainCharacter: {
        label: gettext('health-data', 'legPainCharacter', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'legPainCharacterRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 1,
            label: gettext('health-data', 'stabbing', 'cardiacIntakeFormLegPainCharacterOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'shootingPainDownLeg', 'cardiacIntakeFormLegPainCharacterOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'tenderness', 'cardiacIntakeFormLegPainCharacterOptionLabel')
          },
          {
            codedValue: 4,
            label: gettext('health-data', 'swelling', 'cardiacIntakeFormLegPainCharacterOptionLabel')
          }
        ]
      },
      legPainFrequency: {
        label: gettext('health-data', 'legPainFrequency', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'legPainFrequencyRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noPattern', 'cardiacIntakeFormLegPainFrequencyOptionLabel')
          },
          {
            codedValue: -1,
            label: gettext('health-data', 'constant', 'cardiacIntakeFormLegPainFrequencyOptionLabel')
          },
          {
            codedValue: -2,
            label: gettext('health-data', 'variable', 'cardiacIntakeFormLegPainFrequencyOptionLabel')
          },
          {
            codedValue: 'other',
            label: gettext('health-data', 'other', 'cardiacIntakeFormLegPainFrequencyOptionLabel')
          }
        ]
      },
      legPainDevelopment: {
        label: gettext('health-data', 'legPainDevelopment', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'legPainDevelopmentRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noChange', 'cardiacIntakeFormLegPainDevelopmentOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'gettingWorse', 'cardiacIntakeFormLegPainDevelopmentOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'gettingBetter', 'cardiacIntakeFormLegPainDevelopmentOptionLabel')
          }
        ]
      },
      legPainLocation: {
        label: gettext('health-data', 'legPainLocation', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'legPainLocationRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'buttock',
            label: gettext('health-data', 'buttock', 'cardiacIntakeFormLegPainLocationOptionLabel')
          },
          {
            codedValue: 'upperLeg',
            label: gettext('health-data', 'upperLeg', 'cardiacIntakeFormLegPainLocationOptionLabel')
          },
          {
            codedValue: 'lowerLeg',
            label: gettext('health-data', 'lowerLeg', 'cardiacIntakeFormLegPainLocationOptionLabel')
          },
          {
            codedValue: 'foot',
            label: gettext('health-data', 'foot', 'cardiacIntakeFormLegPainLocationOptionLabel')
          }
        ]
      },
      legPainOtherFrequency: {
        label: '',
        placeholder: gettext('health-data', 'legPainOtherFrequency', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'legPainOtherFrequencyRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'legPainOtherFrequencyPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      legPainOnset: {
        label: gettext('health-data', 'legPainOnset', 'cardiacIntakeFormInputLabel'),
        error: {
          valueMissing: gettext('health-data', 'legPainOnsetRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'legPainOnsetPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      legPainSymptoms: {
        label: gettext('health-data', 'legPainSymptoms', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'legPainSymptomsRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'numb',
            label: gettext('health-data', 'numb', 'cardiacIntakeFormLegPainSymptomsOptionLabel')
          },
          {
            codedValue: 'weak',
            label: gettext('health-data', 'weak', 'cardiacIntakeFormLegPainSymptomsOptionLabel')
          }
        ]
      },
      legPainTrigger: {
        label: gettext('health-data', 'legPainTrigger', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'legPainTriggerRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'none',
            label: gettext('health-data', 'none', 'cardiacIntakeFormLegPainTriggerOptionLabel')
          },
          {
            codedValue: 'sleep',
            label: gettext('health-data', 'sleep', 'cardiacIntakeFormLegPainTriggerOptionLabel')
          },
          {
            codedValue: 'walk',
            label: gettext('health-data', 'walk', 'cardiacIntakeFormLegPainTriggerOptionLabel')
          },
          {
            codedValue: 'sit',
            label: gettext('health-data', 'sit', 'cardiacIntakeFormLegPainTriggerOptionLabel')
          },
          {
            codedValue: 'certainMovement',
            label: gettext('health-data', 'certainMovement', 'cardiacIntakeFormLegPainTriggerOptionLabel')
          }
        ]
      }
    }
  },
  fieldsetMedications: {
    label: gettext('health-data', 'medications', 'cardiacIntakeFormFieldsetLabel'),
    formField: {
      medications: {
        label: gettext('health-data', 'medications', 'cardiacIntakeFormInputLabel'),
        placeholder: gettext('health-data', 'medications', 'cardiacIntakeFormInputHint')
      }
    }
  },
  fieldsetNotes: {
    label: gettext('health-data', 'notes', 'cardiacIntakeFormFieldsetLabel'),
    formField: {
      notes: {
        label: gettext('health-data', 'notes', 'cardiacIntakeFormInputLabel'),
        placeholder: gettext('health-data', 'notes', 'cardiacIntakeFormInputHint')
      }
    }
  },
  fieldsetPain: {
    label: gettext('health-data', 'pain', 'cardiacIntakeFormFieldsetLabel'),
    formField: {
      pain: {
        label: gettext('health-data', 'pain', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'painRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'no', 'cardiacIntakeFormPainOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'yes', 'cardiacIntakeFormPainOptionLabel')
          }
        ]
      },
      painCharacter: {
        label: gettext('health-data', 'painCharacter', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'painCharacterRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 1,
            label: gettext('health-data', 'heaviness', 'cardiacIntakeFormPainCharacterOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'stabbing', 'cardiacIntakeFormPainCharacterOptionLabel')
          }
        ]
      },
      painDevelopment: {
        label: gettext('health-data', 'painDevelopment', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'painDevelopmentRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noChange', 'cardiacIntakeFormPainDevelopmentOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'gettingWorse', 'cardiacIntakeFormPainDevelopmentOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'gettingBetter', 'cardiacIntakeFormPainDevelopmentOptionLabel')
          }
        ]
      },
      painFrequency: {
        label: gettext('health-data', 'painFrequency', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'painFrequencyRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noPattern', 'cardiacIntakeFormPainFrequencyOptionLabel')
          },
          {
            codedValue: -1,
            label: gettext('health-data', 'constant', 'cardiacIntakeFormPainFrequencyOptionLabel')
          },
          {
            codedValue: 'other',
            label: gettext('health-data', 'other', 'cardiacIntakeFormPainFrequencyOptionLabel')
          }
        ]
      },
      painLocation: {
        label: gettext('health-data', 'painLocation', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'painLocationRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'chestCenter',
            label: gettext('health-data', 'chestCenter', 'cardiacIntakeFormPainLocationOptionLabel')
          },
          {
            codedValue: 'chestLeft',
            label: gettext('health-data', 'chestLeft', 'cardiacIntakeFormPainLocationOptionLabel')
          },
          {
            codedValue: 'chestRight',
            label: gettext('health-data', 'chestRight', 'cardiacIntakeFormPainLocationOptionLabel')
          },
          {
            codedValue: 'chestEntire',
            label: gettext('health-data', 'chestEntire', 'cardiacIntakeFormPainLocationOptionLabel')
          },
          {
            codedValue: 'stomach',
            label: gettext('health-data', 'stomach', 'cardiacIntakeFormPainLocationOptionLabel')
          },
          {
            codedValue: 'neck',
            label: gettext('health-data', 'neck', 'cardiacIntakeFormPainLocationOptionLabel')
          },
          {
            codedValue: 'armLeft',
            label: gettext('health-data', 'armLeft', 'cardiacIntakeFormPainLocationOptionLabel')
          },
          {
            codedValue: 'armRight',
            label: gettext('health-data', 'armRight', 'cardiacIntakeFormPainLocationOptionLabel')
          }
        ]
      },
      painOnset: {
        label: gettext('health-data', 'painOnset', 'cardiacIntakeFormInputLabel'),
        error: {
          valueMissing: gettext('health-data', 'painOnsetRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'painOnsetPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      painOtherFrequency: {
        label: '',
        placeholder: gettext('health-data', 'painOtherFrequency', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'painOtherFrequencyRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'painOtherFrequencyPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      painOtherReliefFactor: {
        label: '',
        placeholder: gettext('health-data', 'painOtherReliefFactor', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'painOtherReliefFactorRequired', 'cardiacIntakeFormError')
        }
      },
      painOtherTrigger: {
        label: '',
        placeholder: gettext('health-data', 'painOtherTrigger', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'painOtherTriggerRequired', 'cardiacIntakeFormError')
        }
      },
      painRadiation: {
        label: gettext('health-data', 'painRadiation', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'painRadiationRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'none',
            label: gettext('health-data', 'none', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'armLeft',
            label: gettext('health-data', 'armLeft', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'armRight',
            label: gettext('health-data', 'armRight', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'shoulderLeft',
            label: gettext('health-data', 'shoulderLeft', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'shoulderRight',
            label: gettext('health-data', 'shoulderRight', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'chestRight',
            label: gettext('health-data', 'chestRight', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'jaw',
            label: gettext('health-data', 'jaw', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'neck',
            label: gettext('health-data', 'neck', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'stomach',
            label: gettext('health-data', 'stomach', 'cardiacIntakeFormPainRadiationOptionLabel')
          },
          {
            codedValue: 'back',
            label: gettext('health-data', 'back', 'cardiacIntakeFormPainRadiationOptionLabel')
          }
        ]
      },
      painReliefFactor: {
        label: gettext('health-data', 'painReliefFactor', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'painReliefFactorRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'nothing',
            label: gettext('health-data', 'nothing', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'rest',
            label: gettext('health-data', 'rest', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'exercise',
            label: gettext('health-data', 'exercise', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'lean',
            label: gettext('health-data', 'lean', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'inhaler',
            label: gettext('health-data', 'inhaler', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'nitroglycerine',
            label: gettext('health-data', 'nitroglycerine', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'stretch',
            label: gettext('health-data', 'stretch', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'heatColdPack',
            label: gettext('health-data', 'heatColdPack', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'deepBreathe',
            label: gettext('health-data', 'deepBreathe', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          },
          {
            codedValue: 'other',
            label: gettext('health-data', 'other', 'cardiacIntakeFormPainReliefFactorOptionLabel')
          }
        ]
      },
      painSeverity: {
        label: gettext('health-data', 'painSeverity', 'cardiacIntakeFormInputLabel'),
        rangeLabel: gettext('health-data', 'painSeverity', 'cardiacIntakeFormInputRangeLabel'),
        error: {
          tooShort: gettext('health-data', 'painSeverityRequired', 'cardiacIntakeFormError')
        }
      },
      painStart: {
        label: gettext('health-data', 'painStart', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'painStartRequired', 'cardiacIntakeFormError')
        },
        options: [
          { codedValue: 1, label: gettext('health-data', 'gradually', 'cardiacIntakeFormPainStartOptionLabel') },
          { codedValue: 2, label: gettext('health-data', 'suddenly', 'cardiacIntakeFormPainStartOptionLabel') }
        ]
      },
      painSymptoms: {
        label: gettext('health-data', 'painSymptoms', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'painSymptomsRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'none',
            label: gettext('health-data', 'none', 'cardiacIntakeFormPainSymptomsOptionLabel')
          },
          {
            codedValue: 'dyspnea',
            label: gettext('health-data', 'dyspnea', 'cardiacIntakeFormPainSymptomsOptionLabel')
          },
          {
            codedValue: 'sweat',
            label: gettext('health-data', 'sweat', 'cardiacIntakeFormPainSymptomsOptionLabel')
          },
          {
            codedValue: 'jawNeckPain',
            label: gettext('health-data', 'jawNeckPain', 'cardiacIntakeFormPainSymptomsOptionLabel')
          },
          {
            codedValue: 'armPainLeft',
            label: gettext('health-data', 'armPainLeft', 'cardiacIntakeFormPainSymptomsOptionLabel')
          },
          {
            codedValue: 'armPainRight',
            label: gettext('health-data', 'armPainRight', 'cardiacIntakeFormPainSymptomsOptionLabel')
          },
          {
            codedValue: 'nausea',
            label: gettext('health-data', 'nausea', 'cardiacIntakeFormPainSymptomsOptionLabel')
          },
          {
            codedValue: 'arrhythmia',
            label: gettext('health-data', 'arrhythmia', 'cardiacIntakeFormPainSymptomsOptionLabel')
          }
        ]
      },
      painTrigger: {
        label: gettext('health-data', 'painTrigger', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'painTriggerRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'nothing',
            label: gettext('health-data', 'nothing', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'activity',
            label: gettext('health-data', 'activity', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'stress',
            label: gettext('health-data', 'stress', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'cold',
            label: gettext('health-data', 'cold', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'prePostMeal',
            label: gettext('health-data', 'prePostMeal', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'lieDown',
            label: gettext('health-data', 'lieDown', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'lean',
            label: gettext('health-data', 'lean', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'press',
            label: gettext('health-data', 'press', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'medication',
            label: gettext('health-data', 'medication', 'cardiacIntakeFormPainTriggerOptionLabel')
          },
          {
            codedValue: 'other',
            label: gettext('health-data', 'other', 'cardiacIntakeFormPainTriggerOptionLabel')
          }
        ]
      },
      painSmokingHistory: {
        label: gettext('health-data', 'painSmokingHistory', 'cardiacIntakeFormInputLabel'),
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'never', 'cardiacIntakeFormPainSmokingHistoryOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'current', 'cardiacIntakeFormPainSmokingHistoryOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'former', 'cardiacIntakeFormPainSmokingHistoryOptionLabel')
          }
        ],
        error: {
          badInput: gettext('health-data', 'painSmokingHistoryBadInput', 'cardiacIntakeFormError')
        }
      },
      painSmokingHistoryCurrentFrequency: {
        label: gettext('health-data', 'painSmokingHistoryCurrentFrequency', 'cardiacIntakeFormInputLabel'),
        placeholder: gettext('health-data', 'painSmokingHistoryCurrentFrequency', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'painSmokingHistoryCurrentFrequencyRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'painSmokingHistoryCurrentFrequencyPattern', 'cardiacIntakeFormError')
        }
      },
      painSmokingHistoryCurrentPeriod: {
        label: gettext('health-data', 'painSmokingHistoryCurrentPeriod', 'cardiacIntakeFormInputLabel'),
        placeholder: gettext('health-data', 'painSmokingHistoryCurrentPeriod', 'cardiacIntakeFormInputHint'),
        options: formDataCardiacPeriod as any,
        error: {
          valueMissing: gettext('health-data', 'painSmokingHistoryCurrentPeriodRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'painSmokingHistoryCurrentPeriodPattern', 'cardiacIntakeFormError')
        }
      },
      painSmokingHistoryFormerFrequency: {
        label: gettext('health-data', 'painSmokingHistoryFormerFrequency', 'cardiacIntakeFormInputLabel'),
        placeholder: gettext('health-data', 'painSmokingHistoryFormerFrequency', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'painSmokingHistoryFormerFrequencyRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'painSmokingHistoryFormerFrequencyPattern', 'cardiacIntakeFormError')
        }
      },
      painSmokingHistoryFormerPeriod: {
        label: gettext('health-data', 'painSmokingHistoryFormerPeriod', 'cardiacIntakeFormInputLabel'),
        placeholder: gettext('health-data', 'painSmokingHistoryFormerPeriod', 'cardiacIntakeFormInputHint'),
        options: formDataCardiacPeriod as any,
        error: {
          valueMissing: gettext('health-data', 'painSmokingHistoryFormerPeriodRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'painSmokingHistoryFormerPeriodPattern', 'cardiacIntakeFormError')
        }
      },
      painSmokingHistoryFormerPeriodQuit: {
        label: gettext('health-data', 'painSmokingHistoryFormerPeriodQuit', 'cardiacIntakeFormInputLabel'),
        placeholder: gettext('health-data', 'painSmokingHistoryFormerPeriodQuit', 'cardiacIntakeFormInputHint'),
        options: formDataCardiacPeriod as any,
        error: {
          valueMissing: gettext('health-data', 'painSmokingHistoryFormerPeriodQuitRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'painSmokingHistoryFormerPeriodQuitPattern', 'cardiacIntakeFormError')
        }
      }
    }
  },
  fieldsetSyncope: {
    label: gettext('health-data', 'syncope', 'cardiacIntakeFormFieldsetLabel'),
    formField: {
      syncope: {
        label: gettext('health-data', 'syncope', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'syncopeRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'no', 'cardiacIntakeFormSyncopeOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'yes', 'cardiacIntakeFormSyncopeOptionLabel')
          }
        ]
      },
      syncopeCharacter: {
        label: gettext('health-data', 'syncopeCharacter', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'syncopeCharacterRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 1,
            label: gettext('health-data', 'feelLikeFainting', 'cardiacIntakeFormSyncopeCharacterOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'spinning', 'cardiacIntakeFormSyncopeCharacterOptionLabel')
          },
          {
            codedValue: 3,
            label: gettext('health-data', 'faintingWithLossOfConsciousness', 'cardiacIntakeFormSyncopeCharacterOptionLabel')
          }
        ]
      },
      syncopeDevelopment: {
        label: gettext('health-data', 'syncopeDevelopment', 'cardiacIntakeFormInputLabel'),
        error: {
          range: gettext('health-data', 'syncopeDevelopmentRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noChange', 'cardiacIntakeFormSyncopeDevelopmentOptionLabel')
          },
          {
            codedValue: 1,
            label: gettext('health-data', 'gettingWorse', 'cardiacIntakeFormSyncopeDevelopmentOptionLabel')
          },
          {
            codedValue: 2,
            label: gettext('health-data', 'gettingBetter', 'cardiacIntakeFormSyncopeDevelopmentOptionLabel')
          }
        ]
      },
      syncopeFrequency: {
        label: gettext('health-data', 'syncopeFrequency', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'syncopeFrequencyRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 0,
            label: gettext('health-data', 'noPattern', 'cardiacIntakeFormSyncopeFrequencyOptionLabel')
          },
          {
            codedValue: -1,
            label: gettext('health-data', 'constant', 'cardiacIntakeFormSyncopeFrequencyOptionLabel')
          },
          {
            codedValue: 'other',
            label: gettext('health-data', 'other', 'cardiacIntakeFormSyncopeFrequencyOptionLabel')
          }
        ]
      },
      syncopeOtherFrequency: {
        label: '',
        placeholder: gettext('health-data', 'syncopeOtherFrequency', 'cardiacIntakeFormInputHint'),
        error: {
          valueMissing: gettext('health-data', 'syncopeOtherFrequencyRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'syncopeOtherFrequencyPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      syncopeOnset: {
        label: gettext('health-data', 'syncopeOnset', 'cardiacIntakeFormInputLabel'),
        error: {
          valueMissing: gettext('health-data', 'syncopeOnsetRequired', 'cardiacIntakeFormError'),
          patternMismatch: gettext('health-data', 'syncopeOnsetPattern', 'cardiacIntakeFormError')
        },
        options: formDataCardiacPeriod as any
      },
      syncopeTrigger: {
        label: gettext('health-data', 'syncopeTrigger', 'cardiacIntakeFormInputLabel'),
        error: {
          badInput: gettext('health-data', 'syncopeTriggerRequired', 'cardiacIntakeFormError')
        },
        options: [
          {
            codedValue: 'none',
            label: gettext('health-data', 'none', 'cardiacIntakeFormSyncopeTriggerOptionLabel')
          },
          {
            codedValue: 'stand',
            label: gettext('health-data', 'stand', 'cardiacIntakeFormSyncopeTriggerOptionLabel')
          },
          {
            codedValue: 'lookUp',
            label: gettext('health-data', 'lookUp', 'cardiacIntakeFormSyncopeTriggerOptionLabel')
          },
          {
            codedValue: 'turnHead',
            label: gettext('health-data', 'turnHead', 'cardiacIntakeFormSyncopeTriggerOptionLabel')
          },
          {
            codedValue: 'postMeal',
            label: gettext('health-data', 'postMeal', 'cardiacIntakeFormSyncopeTriggerOptionLabel')
          },
          {
            codedValue: 'skipMeal',
            label: gettext('health-data', 'skipMeal', 'cardiacIntakeFormSyncopeTriggerOptionLabel')
          },
          {
            codedValue: 'dehydration',
            label: gettext('health-data', 'dehydration', 'cardiacIntakeFormSyncopeTriggerOptionLabel')
          }
        ]
      }
    }
  }
};

export default i18n;
