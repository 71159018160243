import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { ClientObject } from '.';
import type { ClientObjectProfileValue as DV } from '../types';

const TYPE = process.env.CLIENT_OBJECT_TYPE_PROFILE;

/** @public */
export class ClientObjectProfile extends ClientObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.auditId) && Utils.isString(dv.name);
  }

  public updateValue(newValue: DV): Promise<ClientObjectProfile> {
    const Class = this.constructor as typeof ClientObjectProfile;
    return Class.updateValue(this, newValue);
  }
}
