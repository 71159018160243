import * as ErrorCode from './constants/error';
import type { ReadonlyPartialRecord } from './types';
import { CODE, FACILITY, isString, SEVERITY, stringOrDefault } from './utils';

const DEFAULT_ERROR_MESSAGE_MAP: ReadonlyPartialRecord<number, string> = {
  [ErrorCode.E_NOT_IMPLEMENTED]: 'Not implemented yet.',

  [ErrorCode.E_INVALID_OBJECT_ID]: 'Invalid object ID.',
  [ErrorCode.E_UNKNOWN_OBJECT_TYPE]: 'Invalid/unknown object type.',
  [ErrorCode.E_INVALID_OBJECT_VERSION]: 'Invalid object version.',
  [ErrorCode.E_INVALID_OBJECT_VALUE]: 'Invalid object value.',
  [ErrorCode.E_INVALID_USER_OR_GROUP_ID]: 'Invalid user or user group ID.',
  [ErrorCode.E_INVALID_CREATION_DATE]: 'Invalid creation date.',
  [ErrorCode.E_INVALID_EXPIRY_DATE]: 'Invalid expiry date.',
  [ErrorCode.E_DATA_MISSING_OR_INVALID]: 'Required data is either missing or invalid.',
  [ErrorCode.E_INVALID_GROUP_TYPE]: 'Invalid group type.',
  [ErrorCode.E_CLAIM_MISSING_OR_INVALID]: 'Claim is either missing or invalid.',

  [ErrorCode.E_AUTH_FAIL]: 'Authentication failed.',
  [ErrorCode.E_AUTH_FAIL_SALT]: 'Salt value is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_DECODE_ID_TOKEN]: 'Failed to decode ID token.',
  [ErrorCode.E_AUTH_FAIL_CREDENTIAL_ID]: 'Credential ID is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_KEY_ID]: 'Key ID is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_USER_ID]: 'User ID is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_AUTH_STATE]: 'Auth state is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_SHARED_PARAMS]: 'Shared parameters are either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_CREDENTIAL_TYPE]: 'Credential type is either missing or invalid.',

  [ErrorCode.E_MESSAGING_FAIL]: 'Messaging error: Operation failed.',
  [ErrorCode.E_MESSAGING_FAIL_FOLDER_DATA_INVALID]: 'Message folder data is either missing or invalid.',
  [ErrorCode.E_MESSAGING_FAIL_FOLDER_ID]: 'Message folder ID is either missing or invalid.',
  [ErrorCode.E_MESSAGING_FAIL_OWNER_ID]: 'Owner ID is either missing or invalid.',
  [ErrorCode.E_MESSAGING_FAIL_MSG_METADATA_ID]: 'Message metadata ID is either missing or invalid.',
  [ErrorCode.E_MESSAGING_FAIL_MSG_BODY_ID]: 'Message body ID is either missing or invalid.'
};

/**
 * Serves as the base class for SigMail's application-defined exceptions.
 *
 * @public
 */
export class AppException extends Error {
  /**
   * Error code associated with this exception instance.
   * 
   * Error codes are packed as follows:
   * ```
   * +----------+-----------+----------------+
   * | Severity |  Facility |     Code       |
   * +----------+-----------+----------------+
   * |     33222|22222221111|1111110000000000|
   * |     10987|65432109876|5432109876543210|
   * +----------+-----------+----------------+
   * ```
   */
  public readonly errorCode: number;

  /**
   * Initializes a new instance of the `AppException` class with a specified
   * error code, and optionally with a specified error message.
   *
   * @param errorCode - A coded numeric value.
   * @param message - A message that describes the error.
   */
  public constructor(errorCode: number, message?: string) {
    const errorMessage = [
      `S${SEVERITY(errorCode)}-F${FACILITY(errorCode)}-C${CODE(errorCode)}`,
      stringOrDefault(message, DEFAULT_ERROR_MESSAGE_MAP[errorCode])
    ]
      .filter(isString)
      .join(': ');

    super(errorMessage);

    this.errorCode = errorCode;
    this.name = 'AppException';
  }

  /**
   * Gets the coded numeric value identifying a particular condition in the 
   * context of the `facility`.
   */
  public get code(): number {
    return CODE(this.errorCode);
  }
  
  /**
   * Gets a numeric value identifying the part of the system for which the
   * `code` applies.
   */
  public get facility(): number {
    return FACILITY(this.errorCode);
  }

  /**
   * Gets a numeric value which indicates whether the operation succeeded or
   * failed with an error/warning.
   */
  public get severity(): number {
    return SEVERITY(this.errorCode);
  }
}
