import { Nullable, Utils } from '@sigmail/common';
import { ContactListItemExt } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  BatchQueryRequest,
  compareContactListItem,
  ExcludeListItem,
  filterContactList,
  FilterFunction
} from '../../utils/contact-list';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { contactListObjectSelector } from '../selectors/user-object';
import { UserObjectCache } from '../user-objects-slice/cache';
import { useCurrentUser } from './use-current-user';

export interface UseGuestContactListParams {
  exclude?: Nullable<ReadonlyArray<Nullable<ExcludeListItem>>>;
  filterFn?: Nullable<FilterFunction<ContactListItemExt>>;
  forceFetch?: Nullable<boolean>;
  groups?: Nullable<boolean>;
  unsorted?: Nullable<boolean>;
  users?: Nullable<boolean>;
}

export type UseGuestContactListResult = [list: ReadonlyArray<ContactListItemExt>, query: BatchQueryRequest];

export const useGuestContactList = (params?: Nullable<UseGuestContactListParams>) => {
  const currentUser = useCurrentUser();
  const guestContactListSelector = useSelector(contactListObjectSelector);
  const guestContactListObject = guestContactListSelector(/***/);
  const guestContactList = params?.forceFetch === true ? undefined : UserObjectCache.getValue(guestContactListObject);

  const includeGroupList = params?.groups === true;
  const includeUserList = params?.users === true;
  const exclude = params?.exclude;
  const filterFn = typeof params?.filterFn === 'function' ? params!.filterFn! : filterContactList;
  const unsorted = params?.unsorted === true;

  return React.useMemo((): UseGuestContactListResult => {
    let contactList: ReadonlyArray<ContactListItemExt> = EMPTY_ARRAY;
    const userObjects: Required<BatchQueryRequest>['userObjectsByType'] = [];

    do {
      if (!includeUserList && !includeGroupList) break;
      if (Utils.isNil(currentUser)) break;
      if (!Utils.isGuestRole(currentUser.role)) break;
      if (userObjects.length > 0) break;

      if (Utils.isNil(guestContactList)) {
        userObjects.push({ type: process.env.USER_OBJECT_TYPE_CONTACT_LIST, userId: currentUser.id });
        break;
      }

      const { contacts: list } = guestContactList;
      contactList = list.reduce((result, contact) => {
        if ((contact.type === 'group' && includeGroupList) || (contact.type === 'user' && includeUserList)) {
          result.push(contact);
        }
        return result;
      }, [] as Array<typeof contactList[0]>);

      contactList = filterFn(contactList, { exclude });

      if (!unsorted && contactList.length > 1) {
        contactList = contactList.slice().sort(compareContactListItem);
      }
    } while (false);

    let query: UseGuestContactListResult[1] = EMPTY_PLAIN_OBJECT;
    if (userObjects.length > 0) query = { userObjectsByType: userObjects };

    return [contactList, query];
  }, [guestContactList, currentUser, exclude, filterFn, includeGroupList, includeUserList, unsorted]);
};
