import { IAppUser, Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../selectors/auth';
import {
  ContactNumberAttributes,
  contactNumberSelector,
  PersonNameAttributes,
  personNameSelector,
  PostalAddressAttributes,
  postalAddressSelector,
  RoleBasedDataAttributes,
  roleBasedDataSelector
} from '../selectors/user-object';

export type IUserWithDataAttributes = Pick<IAppUser, 'id'> &
  PersonNameAttributes &
  PostalAddressAttributes &
  ContactNumberAttributes &
  RoleBasedDataAttributes;

export function useCurrentUser(): IUserWithDataAttributes | undefined {
  const user = useSelector(currentUserSelector);
  const nameSelector = useSelector(personNameSelector);
  const addressSelector = useSelector(postalAddressSelector);
  const contactSelector = useSelector(contactNumberSelector);
  const otherDataSelector = useSelector(roleBasedDataSelector);

  return React.useMemo(
    () =>
      Utils.isNil(user)
        ? user
        : {
            id: user.id,
            ...nameSelector(/***/),
            ...addressSelector(/***/),
            ...contactSelector(/***/),
            ...otherDataSelector(/***/)
          },
    [user, nameSelector, addressSelector, contactSelector, otherDataSelector]
  );
}
