import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { DataObject } from '.';
import type { DataObjectMsgFolderValue, DataObjectMsgFolderValue as DV } from '../types';

const TYPE = process.env.DATA_OBJECT_TYPE_MSG_FOLDER;

/** @public */
export class DataObjectMsgFolder extends DataObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;

    const result = Utils.isArray(dv.data) && (dv.next === null || this.isValidId(dv.next));
    if (result) {
      const dv1 = dv as unknown as DataObjectMsgFolderValue<1>;
      if (Utils.isUndefined(dv1.$$formatver) || dv1.$$formatver === 1) {
        return Utils.isInteger(dv1.totalItemCount);
      }
      return Utils.isNonArrayObjectLike(dv.itemCount);
    }

    return false;
  }

  public updateValue(newValue: DV): Promise<DataObjectMsgFolder> {
    const Class = this.constructor as typeof DataObjectMsgFolder;
    return Class.updateValue(this, newValue);
  }
}
