import type { SigmailObjectTypeCode } from '@sigmail/common';
import { AppUserGroup, Utils } from '@sigmail/common';
import { GroupObject } from '.';
import type { GroupObjectContactInfoValue as DV } from '../types';

const TYPE = process.env.GROUP_OBJECT_TYPE_CONTACT_INFO;

/** @public */
export class GroupObjectContactInfo extends GroupObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      AppUserGroup.isValidId(dv.groupId) &&
      Utils.isString(dv.groupName) &&
      (Utils.isUndefined(dv.institute) || Utils.isNonArrayObjectLike(dv.institute)) &&
      (Utils.isUndefined(dv.newMessageEmailNotificationList) || Utils.isArray(dv.newMessageEmailNotificationList)) &&
      (Utils.isUndefined(dv.newMessageSMSNotificationList) || Utils.isArray(dv.newMessageSMSNotificationList)) &&
      (Utils.isUndefined(dv.newMessageWebPushNotificationMap) ||
        Utils.isNonArrayObjectLike(dv.newMessageWebPushNotificationMap))
    );
  }

  public updateValue(newValue: DV): Promise<GroupObjectContactInfo> {
    const Class = this.constructor as typeof GroupObjectContactInfo;
    return Class.updateValue(this, newValue);
  }
}
