import {
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  RadioProps,
  Slider,
  Step,
  StepLabel,
  Stepper,
  Tooltip
} from '@material-ui/core';
import { ArrowDropDown as IconArrowDropDown } from '@material-ui/icons';
import { PartialRecord, ReadonlyRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n, HealthDataI18n } from '@sigmail/i18n';
import {
  CardiacIntakeFormArrhythmiaData,
  CardiacIntakeFormDyspneaData,
  CardiacIntakeFormLegPainData,
  CardiacIntakeFormPainData,
  CardiacIntakeFormSyncopeData,
  CardiacSmokingPeriod,
  CardiacSmokingQuitPeriod,
  CardiacSymptomOnset
} from '@sigmail/objects';
import { FieldState, FormSubscription } from 'final-form';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { EMPTY_ARRAY } from '../../../app-state/constants';
import { CardiacFormPainSeverity } from '../../../constants/form-input-constraint';
import * as FormInputPattern from '../../../constants/form-input-pattern';
import { withTranslation } from '../../../i18n';
import { I18N_NS_HEALTH_DATA } from '../../../i18n/config/namespace-identifiers';
import healthDataI18n from '../../../i18n/health-data';
import { CardiacIntakeDataUtil } from '../../../utils/health-data/cardiac-intake';
import { nullValidator, range as rangeValidator, ValidationErrorResult } from '../../shared/form/field-validator';
import { FieldProps, FormComponent, FormComponentProps, FormComponentState } from '../../shared/form/form.component';
import { MenuButton } from '../../shared/menu-button.component';
import { CARDIAC_FORM_SLIDER_INPUT_STEP, SPLIT_BY_CAPITAL_REGEX } from '../constants';
import style from './cardiac-intake.module.css';

const { cardiacIntake: i18n } = healthDataI18n.form;
const { cardiacIntake: viewI18n } = healthDataI18n.view;

export type FieldsetName = keyof typeof i18n;

export type FieldNameArrhythmia = keyof typeof i18n.fieldsetArrhythmia.formField;
export type FieldNameDyspnea = keyof typeof i18n.fieldsetDyspnea.formField;
export type FieldNameLegPain = keyof typeof i18n.fieldsetLegPain.formField;
export type FieldNameMedications = keyof typeof i18n.fieldsetMedications.formField;
export type FieldNameNotes = keyof typeof i18n.fieldsetNotes.formField;
export type FieldNamePain = keyof typeof i18n.fieldsetPain.formField;
export type FieldNameSyncope = keyof typeof i18n.fieldsetSyncope.formField;

export type FieldName =
  | FieldNameArrhythmia
  | FieldNameDyspnea
  | FieldNameLegPain
  | FieldNameMedications
  | FieldNameNotes
  | FieldNamePain
  | FieldNameSyncope;

type FieldNamePeriod = Extract<
  FieldName,
  | `${string}${`Onset` | `OtherFrequency`}`
  | `painSmokingHistory${`CurrentPeriod` | `FormerPeriod` | `FormerPeriodQuit`}`
>;

type CardiacIntakeArrhythmiaData<
  FieldNames extends FieldName,
  FieldKey extends keyof CardiacIntakeFormArrhythmiaData
> = Record<FieldNames, CardiacIntakeFormArrhythmiaData[FieldKey]>;

type CardiacIntakeDyspneaData<
  FieldNames extends FieldName,
  FieldKey extends keyof CardiacIntakeFormDyspneaData
> = Record<FieldNames, CardiacIntakeFormDyspneaData[FieldKey]>;

type CardiacIntakeLegPainData<
  FieldNames extends FieldName,
  FieldKey extends keyof CardiacIntakeFormLegPainData
> = Record<FieldNames, CardiacIntakeFormLegPainData[FieldKey]>;

type CardiacIntakePainData<FieldNames extends FieldName, FieldKey extends keyof CardiacIntakeFormPainData> = Record<
  FieldNames,
  FieldKey extends 'radiation'
    ? Exclude<CardiacIntakeFormPainData[FieldKey], false> | 'none'
    : CardiacIntakeFormPainData[FieldKey]
>;

type CardiacIntakeSyncopeData<
  FieldNames extends FieldName,
  FieldKey extends keyof CardiacIntakeFormSyncopeData
> = Record<FieldNames, CardiacIntakeFormSyncopeData[FieldKey]>;

type ArrayFormValues = Record<
  Exclude<Extract<FieldName, `${string}${'ReliefFactor' | 'Symptoms' | 'Trigger'}`>, `${string}Other${string}`>,
  Array<string>
>;
type BinaryFormValues = Record<Extract<FieldName, 'arrhythmia' | 'dyspnea' | 'legPain' | 'pain' | 'syncope'>, 0 | 1>;
type NumberFormValues = Record<
  Extract<FieldName, 'painSmokingHistoryCurrentFrequency' | 'painSmokingHistoryFormerFrequency'>,
  number
>;
type StringFormValues = Record<Extract<FieldName, 'medications' | 'notes' | `${string}Other${string}`>, string>;
type ArrhythmiaCharacterFormValues = CardiacIntakeArrhythmiaData<'arrhythmiaCharacter', 'character'>;
type ArrhythmiaDevelopmentFormValues = CardiacIntakeArrhythmiaData<'arrhythmiaDevelopment', 'development'>;
type ArrhythmiaFrequencyFormValues = CardiacIntakeArrhythmiaData<'arrhythmiaFrequency', 'frequency'>;
type ArrhythmiaOnsetFormValues = CardiacIntakeArrhythmiaData<'arrhythmiaOnset', 'onset'>;
type ArrhythmiaStartFormValues = CardiacIntakeArrhythmiaData<'arrhythmiaStart', 'start'>;
type ArrhythmiaStopFormValues = CardiacIntakeArrhythmiaData<'arrhythmiaStop', 'stop'>;
type DyspneaDevelopmentFormValues = CardiacIntakeDyspneaData<'dyspneaDevelopment', 'development'>;
type DyspneaFrequencyFormValues = CardiacIntakeDyspneaData<'dyspneaFrequency', 'frequency'>;
type DyspneaOnsetFormValues = CardiacIntakeDyspneaData<'dyspneaOnset', 'onset'>;
type DyspneaStartFormValues = CardiacIntakeDyspneaData<'dyspneaStart', 'start'>;
type LegPainCharacterFormValues = CardiacIntakeLegPainData<'legPainCharacter', 'character'>;
type LegPainDevelopmentFormValues = CardiacIntakeLegPainData<'legPainDevelopment', 'development'>;
type LegPainFrequencyFormValues = CardiacIntakeLegPainData<'legPainFrequency', 'frequency'>;
type LegPainLocationFormValues = CardiacIntakeLegPainData<'legPainLocation', 'location'>;
type LegPainOnsetFormValues = CardiacIntakeLegPainData<'legPainOnset', 'onset'>;
type PainCharacterFormValues = CardiacIntakePainData<'painCharacter', 'character'>;
type PainDevelopmentFormValues = CardiacIntakePainData<'painDevelopment', 'development'>;
type PainFrequencyFormValues = CardiacIntakePainData<'painFrequency', 'frequency'>;
type PainLocationFormValues = CardiacIntakePainData<'painLocation', 'location'>;
type PainOnsetFormValues = CardiacIntakePainData<'painOnset', 'onset'>;
type PainRadiationFormValues = CardiacIntakePainData<'painRadiation', 'radiation'>;
type PainSeverityFormValues = CardiacIntakePainData<'painSeverity', 'severity'>;
type PainSmokingHistoryValues = Record<Extract<FieldName, 'painSmokingHistory'>, 0 | 1 | 2>;
type PainSmokingHistoryPeriodValues = Record<
  Extract<FieldName, 'painSmokingHistoryCurrentPeriod' | 'painSmokingHistoryFormerPeriod'>,
  CardiacSmokingPeriod
>;
type PainSmokingHistoryPeriodQuiteValues = Record<
  Extract<FieldName, 'painSmokingHistoryFormerPeriodQuit'>,
  CardiacSmokingQuitPeriod
>;
type PainStartFormValues = CardiacIntakePainData<'painStart', 'start'>;
type SyncopeCharacterFormValues = CardiacIntakeSyncopeData<'syncopeCharacter', 'character'>;
type SyncopeDevelopmentFormValues = CardiacIntakeSyncopeData<'syncopeDevelopment', 'development'>;
type SyncopeFrequencyFormValues = CardiacIntakeSyncopeData<'syncopeFrequency', 'frequency'>;
type SyncopeOnsetFormValues = CardiacIntakeSyncopeData<'syncopeOnset', 'onset'>;

export type FormValues = ArrayFormValues &
  BinaryFormValues &
  NumberFormValues &
  StringFormValues &
  ArrhythmiaCharacterFormValues &
  ArrhythmiaDevelopmentFormValues &
  ArrhythmiaFrequencyFormValues &
  ArrhythmiaOnsetFormValues &
  ArrhythmiaStartFormValues &
  ArrhythmiaStopFormValues &
  DyspneaDevelopmentFormValues &
  DyspneaFrequencyFormValues &
  DyspneaOnsetFormValues &
  DyspneaStartFormValues &
  LegPainCharacterFormValues &
  LegPainDevelopmentFormValues &
  LegPainFrequencyFormValues &
  LegPainLocationFormValues &
  LegPainOnsetFormValues &
  PainCharacterFormValues &
  PainDevelopmentFormValues &
  PainFrequencyFormValues &
  PainLocationFormValues &
  PainOnsetFormValues &
  PainRadiationFormValues &
  PainSeverityFormValues &
  PainSmokingHistoryValues &
  PainSmokingHistoryPeriodValues &
  PainSmokingHistoryPeriodQuiteValues &
  PainStartFormValues &
  SyncopeCharacterFormValues &
  SyncopeDevelopmentFormValues &
  SyncopeFrequencyFormValues &
  SyncopeOnsetFormValues;

type FieldsetList = ReadonlyArray<[FieldsetName, ReadonlyArray<FieldName>]>;

export const FIELD_SET_LIST: FieldsetList = [
  [
    'fieldsetPain',
    [
      'pain',
      'painOnset',
      'painLocation',
      'painRadiation',
      'painCharacter',
      'painSeverity',
      'painStart',
      'painFrequency',
      'painOtherFrequency',
      'painDevelopment',
      'painTrigger',
      'painOtherTrigger',
      'painReliefFactor',
      'painOtherReliefFactor',
      'painSymptoms',
      'painSmokingHistory',
      'painSmokingHistoryCurrentFrequency',
      'painSmokingHistoryCurrentPeriod',
      'painSmokingHistoryFormerFrequency',
      'painSmokingHistoryFormerPeriod',
      'painSmokingHistoryFormerPeriodQuit'
    ]
  ],
  [
    'fieldsetDyspnea',
    [
      'dyspnea',
      'dyspneaOnset',
      'dyspneaStart',
      'dyspneaFrequency',
      'dyspneaOtherFrequency',
      'dyspneaDevelopment',
      'dyspneaTrigger',
      'dyspneaReliefFactor',
      'dyspneaSymptoms'
    ]
  ],
  [
    'fieldsetArrhythmia',
    [
      'arrhythmia',
      'arrhythmiaCharacter',
      'arrhythmiaOnset',
      'arrhythmiaStart',
      'arrhythmiaStop',
      'arrhythmiaFrequency',
      'arrhythmiaOtherFrequency',
      'arrhythmiaDevelopment',
      'arrhythmiaTrigger',
      'arrhythmiaReliefFactor',
      'arrhythmiaOtherReliefFactor',
      'arrhythmiaSymptoms'
    ]
  ],
  [
    'fieldsetSyncope',
    [
      'syncope',
      'syncopeCharacter',
      'syncopeOnset',
      'syncopeFrequency',
      'syncopeOtherFrequency',
      'syncopeDevelopment',
      'syncopeTrigger'
    ]
  ],
  [
    'fieldsetLegPain',
    [
      'legPain',
      'legPainOnset',
      'legPainFrequency',
      'legPainOtherFrequency',
      'legPainCharacter',
      'legPainLocation',
      'legPainDevelopment',
      'legPainTrigger',
      'legPainSymptoms'
    ]
  ],
  ['fieldsetMedications', ['medications']],
  ['fieldsetNotes', ['notes']]
];

const excludeOtherField = (fieldNameList: ReadonlyArray<FieldName>) =>
  fieldNameList.filter(
    (fieldName) =>
      ![
        'arrhythmiaOnset',
        'arrhythmiaOtherFrequency',
        'arrhythmiaOtherReliefFactor',
        'dyspneaOnset',
        'dyspneaOtherFrequency',
        'legPainOnset',
        'legPainOtherFrequency',
        'painOnset',
        'painOtherFrequency',
        'painOtherReliefFactor',
        'painOtherTrigger',
        'syncopeOnset',
        'syncopeOtherFrequency'
      ].includes(fieldName)
  );

const excludePainSmokingHistoryOtherField = (value: 0 | 1 | 2, fieldNameList: ReadonlyArray<FieldName>) => {
  const excludeField: Array<FieldName> = [];

  if (value !== 1) {
    excludeField.push('painSmokingHistoryCurrentFrequency', 'painSmokingHistoryCurrentPeriod');
  }

  if (value !== 2) {
    excludeField.push(
      'painSmokingHistoryFormerFrequency',
      'painSmokingHistoryFormerPeriod',
      'painSmokingHistoryFormerPeriodQuit'
    );
  }

  return fieldNameList.filter((fieldName) => !excludeField.includes(fieldName));
};

type FieldPropsRecord = Record<FieldName, FieldProps>;

const FIELD_PROPS: Readonly<FieldPropsRecord> = {
  ...FIELD_SET_LIST.reduce(
    (passDownProps, [fieldset, fieldNameList]) =>
      fieldNameList.reduce((fieldProps, fieldName) => {
        const fieldsetI18n = i18n[fieldset] as any;

        let label: string = fieldsetI18n.formField[fieldName].label;
        const placeholder: string | undefined = fieldsetI18n.formField[fieldName].placeholder;
        if (
          label.length === 0 ||
          fieldName.endsWith('Onset') ||
          fieldName === 'medications' ||
          fieldName === 'notes' ||
          fieldName === 'painSmokingHistoryCurrentFrequency' ||
          fieldName === 'painSmokingHistoryCurrentPeriod' ||
          fieldName === 'painSmokingHistoryFormerFrequency' ||
          fieldName === 'painSmokingHistoryFormerPeriod' ||
          fieldName === 'painSmokingHistoryFormerPeriodQuit'
        )
          label = undefined!;

        const fieldPrefix = fieldName.startsWith('legPain')
          ? 'legPain'
          : (fieldName.split(SPLIT_BY_CAPITAL_REGEX)[0] as FieldName);

        let config = fieldProps[fieldName]?.config;
        if ((['arrhythmia', 'dyspnea', 'legPain', 'pain', 'syncope'] as ReadonlyArray<FieldName>).includes(fieldName)) {
          config = {
            range: () => rangeValidator(0, 1, { ignoreNil: false }),
            validateFields: fieldNameList.filter((field) => field !== fieldName)
          };
        }

        if (
          ([
            'arrhythmiaFrequency',
            'dyspneaFrequency',
            'legPainFrequency',
            'legPainLocation',
            'painRadiation',
            'painFrequency',
            'syncopeFrequency'
          ] as ReadonlyArray<FieldName>).includes(fieldName)
        ) {
          config = {
            required: (value, values) => (values[fieldPrefix] === 1 ? Utils.isNil(value) : nullValidator())
          };
        }

        if (
          ([
            'arrhythmiaCharacter',
            'arrhythmiaStart',
            'arrhythmiaStop',
            'arrhythmiaDevelopment',
            'dyspneaDevelopment',
            'dyspneaStart',
            'legPainCharacter',
            'legPainDevelopment',
            'painCharacter',
            'painDevelopment',
            'painStart',
            'syncopeCharacter',
            'syncopeDevelopment'
          ] as ReadonlyArray<FieldName>).includes(fieldName)
        ) {
          let { options } = fieldsetI18n.formField[fieldName];
          options = options.flatMap(({ codedValue }: any) => codedValue).sort((a: number, b: number) => a - b);

          config = {
            range: (_, values) =>
              values[fieldPrefix] === 1
                ? rangeValidator(options[0], options[options.length - 1], { ignoreNil: false })
                : nullValidator()
          };
        }

        if (
          ([
            'painLocation',
            'painDevelopment',
            'painReliefFactor',
            'painSymptoms',
            'painTrigger',
            'dyspneaTrigger',
            'dyspneaReliefFactor',
            'dyspneaSymptoms',
            'arrhythmiaTrigger',
            'arrhythmiaReliefFactor',
            'arrhythmiaSymptoms',
            'syncopeTrigger',
            'legPainTrigger',
            'legPainSymptoms'
          ] as ReadonlyArray<FieldName>).includes(fieldName)
        ) {
          config = {
            required: (value, values) => values[fieldPrefix] === 1 && Utils.isArray(value) && value.length === 0
          };
        }

        if (fieldName === 'painSmokingHistoryCurrentFrequency' || fieldName === 'painSmokingHistoryCurrentPeriod') {
          config = {
            required: (_, values) => values.painSmokingHistory === 1,
            pattern: (_, values) => (values.painSmokingHistory === 1 ? FormInputPattern.NumbersOnlyStrict : '.+')
          };
        }

        if (
          fieldName === 'painSmokingHistoryFormerFrequency' ||
          fieldName === 'painSmokingHistoryFormerPeriod' ||
          fieldName === 'painSmokingHistoryFormerPeriodQuit'
        ) {
          config = {
            required: (_, values) => values.painSmokingHistory === 2,
            pattern: (_, values) => (values.painSmokingHistory === 2 ? FormInputPattern.NumbersOnlyStrict : '.+')
          };
        }

        if (fieldName.endsWith('Onset')) {
          config = {
            required: (_, values) => values[fieldPrefix] === 1,
            pattern: (_, values) => {
              return values[fieldPrefix] === 1 ? FormInputPattern.NumbersOnlyStrict : '.+';
            }
          };
        }

        if (fieldName.endsWith('Onset')) {
          config = {
            required: (_, values) => values[fieldPrefix] === 1,
            pattern: (_, values) => {
              return values[fieldPrefix] === 1 ? FormInputPattern.NumbersOnlyStrict : '.+';
            }
          };
        }

        if (fieldName.endsWith('OtherFrequency')) {
          config = {
            required: (_, values) => {
              const otherValue = values[fieldName.replace('Other', '') as FieldName];
              return values[fieldPrefix] === 1 && Utils.isString(otherValue) && otherValue === 'other';
            },
            pattern: (_, values) => {
              const otherValue = values[fieldName.replace('Other', '') as FieldName];
              if (values[fieldPrefix] === 1 && Utils.isString(otherValue) && otherValue === 'other') {
                return FormInputPattern.NumbersOnlyStrict;
              }

              return '.+';
            }
          };
        }

        if (fieldName.endsWith('OtherReliefFactor') || fieldName.endsWith('OtherTrigger')) {
          config = {
            required: (_, values) => {
              const otherValue = values[fieldName.replace('Other', '') as FieldName];
              return Utils.isArray(otherValue) && otherValue.includes('other');
            }
          };
        }

        if (
          (fieldName.endsWith('ReliefFactor') && !fieldName.endsWith('OtherReliefFactor')) ||
          (fieldName.endsWith('Trigger') && !fieldName.endsWith('OtherTrigger'))
        ) {
          const fieldToValidate = `${fieldPrefix}Other${fieldName.replace(fieldPrefix, '')}` as FieldName;
          if (fieldNameList.includes(fieldToValidate)) {
            config = {
              ...config,
              validateFields: [fieldToValidate]
            };
          }
        }

        if (
          fieldName.endsWith('Frequency') &&
          !fieldName.endsWith('OtherFrequency') &&
          fieldName !== 'painSmokingHistoryCurrentFrequency' &&
          fieldName !== 'painSmokingHistoryFormerFrequency'
        ) {
          config = {
            ...config,
            validateFields: [`${fieldPrefix}Other${fieldName.replace(fieldPrefix, '')}`]
          };
        }

        fieldProps[fieldName] = { label, placeholder, ...fieldProps[fieldName], config };
        return fieldProps;
      }, passDownProps),
    ({
      medications: {
        multiline: true,
        rows: 8,
        rowsMax: 8
      },
      notes: {
        multiline: true,
        rows: 8,
        rowsMax: 8
      }
    } as unknown) as FieldPropsRecord
  )
};

const INITIAL_VALUES: FormValues = {
  arrhythmia: null!,
  arrhythmiaCharacter: null!,
  arrhythmiaDevelopment: null!,
  arrhythmiaFrequency: null!,
  arrhythmiaOnset: '' as CardiacSymptomOnset,
  arrhythmiaOtherFrequency: '',
  arrhythmiaOtherReliefFactor: '',
  arrhythmiaReliefFactor: EMPTY_ARRAY,
  arrhythmiaStart: null!,
  arrhythmiaStop: null!,
  arrhythmiaSymptoms: EMPTY_ARRAY,
  arrhythmiaTrigger: EMPTY_ARRAY,
  dyspnea: null!,
  dyspneaDevelopment: null!,
  dyspneaFrequency: null!,
  dyspneaOnset: '' as CardiacSymptomOnset,
  dyspneaOtherFrequency: '',
  dyspneaReliefFactor: EMPTY_ARRAY,
  dyspneaStart: null!,
  dyspneaSymptoms: EMPTY_ARRAY,
  dyspneaTrigger: EMPTY_ARRAY,
  legPain: null!,
  legPainCharacter: null!,
  legPainDevelopment: null!,
  legPainFrequency: null!,
  legPainLocation: null!,
  legPainOnset: '' as CardiacSymptomOnset,
  legPainOtherFrequency: '',
  legPainSymptoms: EMPTY_ARRAY,
  legPainTrigger: EMPTY_ARRAY,
  medications: '',
  notes: '',
  pain: null!,
  painCharacter: null!,
  painDevelopment: null!,
  painFrequency: null!,
  painLocation: EMPTY_ARRAY,
  painOnset: '' as CardiacSmokingPeriod,
  painOtherFrequency: '',
  painOtherReliefFactor: '',
  painOtherTrigger: '',
  painRadiation: null!,
  painReliefFactor: EMPTY_ARRAY,
  painSeverity: CardiacFormPainSeverity.min as CardiacIntakeFormPainData['severity'],
  painStart: null!,
  painSymptoms: EMPTY_ARRAY,
  painTrigger: EMPTY_ARRAY,
  painSmokingHistory: null!,
  painSmokingHistoryCurrentFrequency: ('' as unknown) as number,
  painSmokingHistoryCurrentPeriod: '' as CardiacSmokingPeriod,
  painSmokingHistoryFormerFrequency: ('' as unknown) as number,
  painSmokingHistoryFormerPeriod: '' as CardiacSmokingPeriod,
  painSmokingHistoryFormerPeriodQuit: '' as CardiacSmokingQuitPeriod,
  syncope: null!,
  syncopeCharacter: null!,
  syncopeDevelopment: null!,
  syncopeFrequency: null!,
  syncopeOnset: '' as CardiacSymptomOnset,
  syncopeOtherFrequency: '',
  syncopeTrigger: EMPTY_ARRAY
};

export type CardiacIntakeFormClassKey =
  | 'inputRoot'
  | `control${`Group` | `GroupLabel` | `Label` | `Root`}`
  | 'periodLabel'
  | `slider${'Group' | 'Label' | 'RangeLabel' | 'Root'}`
  | 'stepper';

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<CardiacIntakeFormClassKey, string> | undefined;
}

type ComponentProps = Props & WithTranslation;
interface State extends FormComponentState<FieldName, FormValues> {
  periodField: ReadonlyRecord<
    FieldNamePeriod | 'painSmokingHistoryCurrentPeriod' | 'painSmokingHistoryFormerPeriod',
    Readonly<{ isOpen: boolean; codedValue: HealthDataI18n.CardiacIntakeFormPeriod }>
  >;
}

const DEFAULT_PERIOD_FIELD_D: State['periodField']['arrhythmiaOnset'] = { isOpen: false, codedValue: 'D' };
const DEFAULT_PERIOD_FIELD_M: State['periodField']['arrhythmiaOnset'] = { isOpen: false, codedValue: 'M' };
const DEFAULT_PERIOD_FIELD_Y: State['periodField']['arrhythmiaOnset'] = { isOpen: false, codedValue: 'Y' };

class CardiacIntakeFormComponent extends FormComponent<
  FieldsetName,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes'
    );

    this.state = {
      ...this.state,
      periodField: {
        arrhythmiaOnset: DEFAULT_PERIOD_FIELD_D,
        arrhythmiaOtherFrequency: DEFAULT_PERIOD_FIELD_D,
        dyspneaOnset: DEFAULT_PERIOD_FIELD_D,
        dyspneaOtherFrequency: DEFAULT_PERIOD_FIELD_D,
        legPainOnset: DEFAULT_PERIOD_FIELD_D,
        legPainOtherFrequency: DEFAULT_PERIOD_FIELD_D,
        painOnset: DEFAULT_PERIOD_FIELD_D,
        painOtherFrequency: DEFAULT_PERIOD_FIELD_D,
        painSmokingHistoryCurrentPeriod: DEFAULT_PERIOD_FIELD_M,
        painSmokingHistoryFormerPeriod: DEFAULT_PERIOD_FIELD_M,
        painSmokingHistoryFormerPeriodQuit: DEFAULT_PERIOD_FIELD_Y,
        syncopeOnset: DEFAULT_PERIOD_FIELD_D,
        syncopeOtherFrequency: DEFAULT_PERIOD_FIELD_D
      }
    };

    this.createForm({
      initialValues: INITIAL_VALUES
    });

    this.getFieldsetProgress = this.getFieldsetProgress.bind(this);
    this.onChangePeriodField = this.onChangePeriodField.bind(this);
    this.onTogglePeriodMenu = this.onTogglePeriodMenu.bind(this);
  }

  /** @override */
  protected get formId() {
    return Utils.stringOrDefault(this.props.id, `form-cardiac-intake-${this.formIdSuffix}`);
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldsetLabel(fieldsetName: FieldsetName) {
    if (fieldsetName !== 'fieldsetMedications' && fieldsetName !== 'fieldsetNotes') return null;

    const label = Utils.trimOrDefault(i18n[fieldsetName].label);
    return label.length > 0 ? this.props.t(label) : null;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldType(fieldName: FieldName, ...args: any[]) {
    if (
      fieldName === 'painLocation' ||
      fieldName.endsWith('Symptoms') ||
      (fieldName.endsWith('ReliefFactor') && !fieldName.endsWith('OtherReliefFactor')) ||
      (fieldName.endsWith('Trigger') && !fieldName.endsWith('OtherTrigger'))
    ) {
      return 'checkbox';
    }

    return super.getFieldType(fieldName, ...args);
  }

  /** @override */
  protected getFormSubscription(...args: any[]): Readonly<FormSubscription> {
    return { ...super.getFormSubscription(...args), values: true };
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { t } = this.props;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    if (
      fieldName.endsWith('OtherFrequency') ||
      fieldName.endsWith('Onset') ||
      fieldName === 'painSmokingHistoryCurrentPeriod' ||
      fieldName === 'painSmokingHistoryFormerPeriod' ||
      fieldName === 'painSmokingHistoryFormerPeriodQuit'
    ) {
      const [fieldsetName] = FIELD_SET_LIST.find(([, fieldNameList]) => fieldNameList.includes(fieldName))!;
      const { options } = (i18n[fieldsetName].formField as any)[fieldName];

      fieldProps = {
        ...fieldProps,
        InputProps: {
          ...fieldProps.InputProps,
          endAdornment: this.renderPeriodAdornment(fieldName as FieldNamePeriod, options)
        }
      };
    }

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined;
    if (Utils.isNonArrayObjectLike<ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];

        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    return Utils.isString(errorMessageI18n) ? t(errorMessageI18n) : error;
  }

  /** @override */
  protected renderForm(...args: any[]) {
    const { classes, t } = this.props;
    const formNode = super.renderForm(...args);

    let activeStep = 0;
    FIELD_SET_LIST.forEach((_, index) => {
      const [toComplete, totalAnswered] = this.getFieldsetProgress(index);
      if (toComplete === totalAnswered) ++activeStep;
    });

    return (
      <React.Fragment>
        <Stepper activeStep={activeStep} alternativeLabel classes={{ root: classes!.stepper }}>
          {FIELD_SET_LIST.filter(
            ([fieldsetName]) => fieldsetName !== 'fieldsetMedications' && fieldsetName !== 'fieldsetNotes'
          ).map(([fieldsetName], index) => {
            const [toComplete, totalAnswered] = this.getFieldsetProgress(index);

            return (
              <Step key={fieldsetName} completed={toComplete === totalAnswered}>
                <StepLabel>
                  {t(viewI18n.stepper[fieldsetName as Exclude<FieldsetName, `fieldset${`Medications` | `Notes`}`>])}
                  <br />

                  {totalAnswered > 0 && totalAnswered < toComplete
                    ? t(viewI18n.stepperLabel, { LBOUND: totalAnswered - 1, UBOUND: toComplete - 1 })
                    : null}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {formNode}
      </React.Fragment>
    );
  }

  /** @override */
  protected getFieldNameList(
    fieldsetName?: FieldsetName | undefined,
    ...args: any[]
  ): ReadonlyArray<FieldName> | undefined {
    let fieldNameList = super.getFieldNameList(fieldsetName, ...args);
    if (Utils.isNonEmptyArray(fieldNameList) && fieldsetName === 'fieldsetPain') {
      fieldNameList = excludePainSmokingHistoryOtherField(this.state.painSmokingHistory?.value, fieldNameList);
    }
    return fieldNameList;
  }

  /**@override */
  protected renderFieldset(fieldsetName: FieldsetName, ...args: any[]) {
    const prevFieldsetIndex = FIELD_SET_LIST.findIndex(([fieldset]) => fieldset === fieldsetName) - 1;

    const isFieldsetText = fieldsetName === 'fieldsetMedications' || fieldsetName === 'fieldsetNotes';

    if (prevFieldsetIndex > -1 && !isFieldsetText) {
      const [prevFieldsetName] = FIELD_SET_LIST[prevFieldsetIndex];

      const prevFieldNameList = this.getFieldNameList(prevFieldsetName)!;

      const prevFieldsetPrefix = prevFieldsetName.replace('fieldset', '');
      const isPrevFieldsetAnswered = this.state[Utils.camelCase(prevFieldsetPrefix) as FieldName]?.value;

      if (isPrevFieldsetAnswered !== 0) {
        const [prevFieldName] = prevFieldNameList.slice(-1);
        if (this.state[prevFieldName]?.value === INITIAL_VALUES[prevFieldName]) return null;
      }
    } else if (isFieldsetText) {
      const legPainValue = this.state.legPain?.value;
      if (
        Utils.isNil(legPainValue) ||
        (legPainValue === 1 && this.state.legPainSymptoms?.value === INITIAL_VALUES.legPainSymptoms)
      ) {
        return null;
      }
    }

    return super.renderFieldset(fieldsetName, ...args);
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]): React.ReactNode {
    let fieldsetIndex = -1;
    let fieldIndex = -1;

    const isOtherField =
      fieldName.endsWith('OtherFrequency') ||
      fieldName.endsWith('OtherReliefFactor') ||
      fieldName.endsWith('OtherTrigger');
    const isOnsetField =
      fieldName === 'arrhythmiaOnset' ||
      fieldName === 'dyspneaOnset' ||
      fieldName === 'legPainOnset' ||
      fieldName === 'painOnset' ||
      fieldName === 'syncopeOnset';
    const isTextField = fieldName === 'medications' || fieldName === 'notes';

    for (const [fieldsetName] of FIELD_SET_LIST) {
      ++fieldsetIndex;

      const fieldNameList = [...this.getFieldNameList(fieldsetName)!];
      fieldIndex = excludeOtherField(fieldNameList).indexOf(fieldName);
      if (fieldIndex > -1) break;
    }

    if (fieldIndex === -1 && !isOtherField && !isOnsetField) return null;

    const { classes, t } = this.props;

    if (!isTextField) {
      const fieldPrefix = CardiacIntakeDataUtil.getFieldPrefix(fieldName);

      if (
        fieldName !== fieldPrefix &&
        fieldName.startsWith(fieldPrefix) &&
        !this.state[fieldPrefix as FieldName]?.value
      ) {
        return;
      }
    }

    if (isOtherField) {
      const otherValue = this.state[fieldName.replace('Other', '') as FieldName].value;
      if (
        (Utils.isNil(otherValue) || Utils.isString(otherValue) || Utils.isNumber(otherValue)) &&
        otherValue !== 'other'
      ) {
        return;
      } else if (Utils.isArray(otherValue) && !otherValue.includes('other')) {
        return;
      }
    }

    let [fieldsetName] = FIELD_SET_LIST[fieldsetIndex];
    let fieldNameList = this.getFieldNameList(fieldsetName)!;

    if (isOnsetField) {
      fieldsetIndex = -1;
      for (const [, fieldNameList] of FIELD_SET_LIST) {
        ++fieldsetIndex;
        fieldIndex = fieldNameList.indexOf(fieldName);
        if (fieldIndex > -1) break;
      }

      [fieldsetName] = FIELD_SET_LIST[fieldsetIndex];
      fieldNameList = this.getFieldNameList(fieldsetName)!;
    }

    if (fieldIndex > 0 && !isTextField) {
      const prevFieldName = isOnsetField
        ? fieldNameList[fieldIndex - 1]
        : excludeOtherField(fieldNameList)[fieldIndex - 1];
      const prevFieldValue = this.state[prevFieldName]?.value;

      if (prevFieldValue === INITIAL_VALUES[prevFieldName]) return;
    }

    let fieldNode: React.ReactNode;
    switch (fieldName) {
      case 'arrhythmia':
      case 'arrhythmiaCharacter':
      case 'arrhythmiaStart':
      case 'arrhythmiaStop':
      case 'arrhythmiaFrequency':
      case 'arrhythmiaDevelopment':
      case 'dyspnea':
      case 'dyspneaStart':
      case 'dyspneaFrequency':
      case 'dyspneaDevelopment':
      case 'legPain':
      case 'legPainFrequency':
      case 'legPainCharacter':
      case 'legPainLocation':
      case 'legPainDevelopment':
      case 'pain':
      case 'painRadiation':
      case 'painCharacter':
      case 'painStart':
      case 'painFrequency':
      case 'painDevelopment':
      case 'syncope':
      case 'syncopeCharacter':
      case 'syncopeFrequency':
      case 'syncopeDevelopment':
      case 'painSmokingHistory': {
        const { options } = (i18n as any)[fieldsetName].formField[fieldName];
        fieldNode = this.radioFieldRenderer(fieldName, options);
        break;
      }
      case 'arrhythmiaOnset':
      case 'dyspneaOnset':
      case 'legPainOnset':
      case 'painOnset':
      case 'syncopeOnset': {
        fieldNode = (
          <React.Fragment>
            <span className={classes!.periodLabel}>{t((i18n as any)[fieldsetName].formField[fieldName].label)}</span>
            {super.renderField(fieldName, ...args)}
          </React.Fragment>
        );
        break;
      }
      case 'painSeverity': {
        fieldNode = this.sliderFieldRenderer(fieldName);
        break;
      }
      default: {
        fieldNode = super.renderField(fieldName, ...args);
        break;
      }
    }

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  /**@override */
  protected checkboxFieldRenderer({ name }: CheckboxProps): React.ReactNode {
    const fieldName = name as FieldName;

    const fieldsetIndex = FIELD_SET_LIST.findIndex(([_, fieldNameList]) => fieldNameList.includes(fieldName));
    if (fieldsetIndex === -1) return null;

    const [fieldsetName] = FIELD_SET_LIST[fieldsetIndex];

    const { options } = (i18n[fieldsetName].formField as any)[fieldName];

    const { classes, disabled, readOnly, t } = this.props;
    const { error, ...inputState } = this.state[fieldName];
    const value = inputState.value;

    return (
      <FormControl
        aria-disabled={disabled}
        aria-invalid={Utils.isString(error)}
        aria-required="true"
        classes={{ root: classes!.controlRoot }}
        key={fieldName}
        disabled={disabled}
      >
        <FormLabel classes={{ root: classes!.controlLabel }} component="div">
          {t(FIELD_PROPS[fieldName].label as string)}
        </FormLabel>

        {options.map(({ codedValue, label }: any) => (
          <div key={codedValue} className={classes!.controlGroup}>
            {super.checkboxFieldRenderer({
              checked: value.includes(codedValue),
              color: 'primary',
              'data-value': codedValue,
              disabled: disabled === true,
              readOnly: readOnly === true,
              error,
              label: t(label),
              onChange: () => {
                const inputState = this.state[fieldName];

                let value = inputState.value;
                if (value.includes(codedValue)) {
                  value = value.filter((option: any) => option !== codedValue);
                } else {
                  value = [...value, codedValue].sort();
                }

                inputState.change(value);
              }
            })}
          </div>
        ))}

        {Utils.isString(error) && <FormHelperText error={true}>{error}</FormHelperText>}
      </FormControl>
    );
  }

  private renderPeriodAdornment(
    fieldName: FieldNamePeriod,
    options: ReadonlyArray<HealthDataI18n.Form.CardiacIntakeFormOption<HealthDataI18n.CardiacIntakeFormPeriod>>
  ) {
    const { t } = this.props;
    const { periodField } = this.state;

    const { isOpen, codedValue } = periodField[fieldName];

    const excludeOptions: Array<typeof codedValue> = [];
    switch (fieldName) {
      case 'painSmokingHistoryCurrentPeriod':
      case 'painSmokingHistoryFormerPeriod':
        excludeOptions.push('D', 'W');
        break;
      case 'painSmokingHistoryFormerPeriodQuit':
        excludeOptions.push('D', 'M', 'W');
        break;
      default:
        break;
    }

    return (
      <InputAdornment position="end">
        <MenuButton
          anchorElProps={{
            children: (
              <React.Fragment>
                <span>{t(options.find((option) => option.codedValue === codedValue)!.label)}</span>
                <IconArrowDropDown fontSize="small" />
              </React.Fragment>
            ),
            onClick: () => this.onTogglePeriodMenu(fieldName)
          }}
          onClose={() => this.onTogglePeriodMenu(fieldName)}
          open={isOpen}
        >
          {options
            .filter(({ codedValue }) => !excludeOptions.includes(codedValue))
            .map(({ codedValue, label }) => (
              <MenuItem onClick={() => this.onChangePeriodField(fieldName, codedValue)}>{t(label)}</MenuItem>
            ))}
        </MenuButton>
      </InputAdornment>
    );
  }

  private radioFieldRenderer(
    fieldName: FieldName,
    options: ReadonlyArray<HealthDataI18n.Form.CardiacIntakeFormOption<number | string>>
  ): React.ReactNode {
    const { classes, disabled, t } = this.props;
    const { change, error, value } = this.state[fieldName];

    const radioNodeList = options.map(({ codedValue, label: optionLabel }) => {
      const radioProps: RadioProps = { color: 'primary', name: fieldName, size: 'small' };
      const label = t(optionLabel);

      return <FormControlLabel control={<Radio {...radioProps} />} key={codedValue} label={label} value={codedValue} />;
    });

    return (
      <FormControl
        aria-disabled={disabled}
        aria-invalid={Utils.isString(error)}
        aria-required="true"
        classes={{ root: classes!.controlRoot }}
        key={fieldName}
        disabled={disabled}
      >
        <FormLabel classes={{ root: classes!.controlLabel }} component="div">
          {t(FIELD_PROPS[fieldName].label as string)}
        </FormLabel>

        <RadioGroup
          classes={{ root: classes!.controlGroup }}
          onChange={(_, newValue) =>
            change(newValue !== 'other' && Utils.isInteger(options[0].codedValue) ? Number(newValue) : newValue)
          }
          value={value}
        >
          {radioNodeList}
        </RadioGroup>

        {Utils.isString(error) && <FormHelperText error={true}>{error}</FormHelperText>}
      </FormControl>
    );
  }

  private sliderFieldRenderer(fieldName: Extract<FieldName, 'painSeverity'>): React.ReactNode {
    const { classes, disabled, t } = this.props;
    const { change, error, value } = this.state[fieldName];

    const rangeLabel = CardiacIntakeDataUtil.getDataRangeLabel(fieldName, value, t);

    return (
      <FormControl
        aria-disabled={disabled}
        aria-required="true"
        classes={{ root: classes!.sliderRoot }}
        key={fieldName}
        disabled={disabled}
      >
        <FormLabel classes={{ root: classes!.sliderLabel }} component="div">
          {t(FIELD_PROPS[fieldName].label as string)}
        </FormLabel>

        <div className={classes!.sliderGroup}>
          <Slider
            {...CardiacIntakeDataUtil.getDataRange(fieldName)}
            onChange={(_, value) => change(value)}
            step={CARDIAC_FORM_SLIDER_INPUT_STEP}
            ValueLabelComponent={({ children, open }) => (
              <Tooltip enterTouchDelay={0} open={open} placement="top" title={rangeLabel}>
                {children}
              </Tooltip>
            )}
            value={value}
            valueLabelDisplay="auto"
          />

          <span className={classes!.sliderRangeLabel}>{rangeLabel}</span>
        </div>

        {Utils.isString(error) && <FormHelperText error={true}>{error}</FormHelperText>}
      </FormControl>
    );
  }

  private getFieldsetProgress(fieldsetIndex: number) {
    const [fieldsetName] = FIELD_SET_LIST[fieldsetIndex];
    let fieldNameList = this.getFieldNameList(fieldsetName)!;
    fieldNameList = fieldNameList.filter(
      (fieldName) =>
        ![
          'arrhythmiaOtherFrequency',
          'arrhythmiaOtherReliefFactor',
          'dyspneaOtherFrequency',
          'legPainOtherFrequency',
          'painOtherFrequency',
          'painOtherReliefFactor',
          'painOtherTrigger',
          'syncopeOtherFrequency'
        ].includes(fieldName)
    );

    let totalAnswered = 0;

    const fieldsetValue = this.state[Utils.camelCase(fieldsetName.replace('fieldset', '')) as FieldName]?.value;
    if (fieldsetValue) {
      for (const fieldName of fieldNameList) {
        const fieldValue = this.state[fieldName]?.value;
        let otherFieldName: FieldName | undefined;
        let otherValue: unknown;

        if (fieldValue === 'other' || (Utils.isArray(fieldValue) && fieldValue.length === 1)) {
          const fieldPrefix = CardiacIntakeDataUtil.getFieldPrefix(fieldName);
          otherFieldName = `${fieldPrefix}Other${fieldName.replace(fieldPrefix, '')}` as FieldName;
          otherValue = this.state[otherFieldName]?.value;
        }

        if (Utils.isArray(fieldValue)) {
          if (fieldValue.length === 1) {
            if (fieldValue[0] === 'other') {
              if (Utils.isNotNil(otherFieldName) && otherValue !== INITIAL_VALUES[otherFieldName]) {
                totalAnswered += 1;
              }
            } else {
              totalAnswered += 1;
            }
          } else {
            totalAnswered += Number(fieldValue.length > 0);
          }
        } else if (fieldValue === 'other') {
          if (otherFieldName && otherValue !== INITIAL_VALUES[otherFieldName]) {
            totalAnswered += 1;
          }
        } else if (Utils.isNotNil(fieldValue) && fieldValue !== INITIAL_VALUES[fieldName]) {
          totalAnswered += 1;
        }
      }
    } else if (fieldsetValue === 0) {
      totalAnswered = fieldNameList.length;
    }

    return [fieldNameList.length, totalAnswered];
  }

  private onTogglePeriodMenu(fieldName: FieldNamePeriod) {
    this.setState(({ periodField }) => ({
      periodField: {
        ...periodField,
        [fieldName]: {
          ...periodField[fieldName],
          isOpen: !periodField[fieldName].isOpen
        }
      }
    }));
  }

  private onChangePeriodField(fieldName: FieldNamePeriod, codedValue: HealthDataI18n.CardiacIntakeFormPeriod) {
    this.setState(({ periodField }) => ({
      periodField: { ...periodField, [fieldName]: { isOpen: false, codedValue } }
    }));
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<any>) {
    let { error } = fieldState;

    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    return super.setFieldState(fieldName, { ...fieldState, error });
  }

  protected formSubmitHandler(values: FormValues) {
    const { periodField } = this.state;

    const formValues = Utils.mapValues(values, (value, fieldName: FieldName) => {
      if (fieldName === 'painRadiation') {
        return value === 'none' ? false : value;
      } else if (
        (fieldName.endsWith('OtherFrequency') ||
          fieldName.endsWith('Onset') ||
          fieldName === 'painSmokingHistoryCurrentPeriod' ||
          fieldName === 'painSmokingHistoryFormerPeriod' ||
          fieldName === 'painSmokingHistoryFormerPeriodQuit') &&
        Utils.isNotNil(value)
      ) {
        const periodValue = periodField[fieldName as FieldNamePeriod].codedValue;
        value = `${value}${periodValue}`;
      }

      if (Utils.isString(value) && value.length === 0) {
        value = undefined!;
      }

      return value;
    });

    return super.formSubmitHandler((formValues as unknown) as FormValues);
  }
}

const ns = [I18N_NS_HEALTH_DATA];

export const CardiacIntakeForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['form-input-group'],
      controlRoot: style['form-control'],
      controlLabel: style.label,
      controlGroup: style['group'],
      periodLabel: style['period-label'],
      sliderRoot: style.slider,
      sliderLabel: style.label,
      sliderGroup: style.group,
      sliderRangeLabel: style['range-label'],
      stepper: style.stepper
    } as ComponentProps['classes']);

    return <CardiacIntakeFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

CardiacIntakeForm.displayName = 'CardiacIntakeForm';
CardiacIntakeForm.defaultProps = { className: style.form };
