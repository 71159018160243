import { IDataObjectCache, MessagingActionPayload } from '@sigmail/app-state';
import { AppUser, Constants, GenderIdentity, HRM, SigmailObjectId, SigmailUserId, Utils } from '@sigmail/common';
import { DataObjectHrmUserListValue, HrmActiveUserListItem } from '@sigmail/objects';
import React from 'react';
import { store } from '../../../../app-state';
import { fetchDataObjectsAction } from '../../../../app-state/actions/fetch-data-objects-action';
import { sendHrmMessageAction } from '../../../../app-state/actions/messaging/send-hrm-message-action';
import { EMPTY_ARRAY } from '../../../../app-state/constants';
import {
  Cache as DataObjectCache,
  DataObjectCache as GlobalDataObjectCache
} from '../../../../app-state/data-objects-slice/cache';
import { InvalidDataURLError, PdfDocument } from '../../../../core/pdf-document';
import { parseDataUri } from '../../../../utils/parse-data-uri';
import { readBlobAsDataUri } from '../../../../utils/read-file-as-data-uri';
import {
  MaxAttachmentSizeError,
  MAX_TOTAL_ATTACHMENT_SIZE as HRM_MAX_TOTAL_ATTACHMENT_SIZE
} from '../../constants/HRM';
import { ActionData as BaseActionData, ParentMessageFolderKey } from '../../types';
import { FormValues, HrmDocument } from '../../types/HRM';
import * as HrmUtils from '../../utils/HRM';
import { MessageFolderGridItem } from '../folder-item-grid/types';

export interface ActionData extends BaseActionData {
  documentList: ReadonlyArray<HrmDocument>;
  folderKey?: string;
  formValues: FormValues;
  guestUserId?: SigmailUserId;
  hrmUser: HrmActiveUserListItem;
  hrmUserList: DataObjectHrmUserListValue['list'];
  parentFolderKey?: ParentMessageFolderKey;
  scopedDataObjectCache?: IDataObjectCache;
  sourceMessage?: MessageFolderGridItem;
}

const REGULATORY_URL_NP = 'http://ehealthontario.ca/API/FHIR/NamingSystem/ca-on-license-physician';
const REGULATORY_URL_MD = 'http://ehealthontario.ca/API/FHIR/NamingSystem/ca-on-license-nurse';

const fetchDocumentObjects = async (
  documentList: ActionData['documentList'],
  cache: IDataObjectCache
): Promise<void> => {
  const objectId = Utils.filterMap<HrmDocument, SigmailObjectId>(documentList, (doc) => {
    if (doc instanceof File || cache.has(doc.body)) return false;

    const obj = GlobalDataObjectCache.find(doc.body);
    if (Utils.isNotNil(obj)) {
      cache.add(...obj);
      return false;
    }

    return doc.body;
  });

  if (objectId.length > 0) {
    await store.dispatch(fetchDataObjectsAction({ cache, objectId }));
  }
};

export const createMessageBody = (
  values: ActionData['formValues'],
  hrmUserList: ActionData['hrmUserList'],
  hrmUser: ActionData['hrmSender'],
  guestUserId?: number
): MessagingActionPayload.SendHrmMessage['messageBody'] => {
  const { length: recipientCount } = values.recipient;

  const hcnNumber = values.hpn.replaceAll(/\D/g, '');
  const hcnVersionNumber = values.hpn.replaceAll(/[^a-z]/gi, '');
  const patientId = AppUser.isValidId(guestUserId) ? guestUserId.toString(10) : `HPN${hcnNumber}${hcnVersionNumber}`;

  const phoneNumber = (values.contactNumber.match(/[0-9]/g) || []).join('');
  const telecom = Utils.arrayOrDefault<HRM.ContactPoint>(
    phoneNumber.length > 0 && [{ use: values.contactType, phoneNumber }],
    EMPTY_ARRAY
  );

  return {
    order: {
      ordererId: hrmUser.id,
      ordererDisplay: hrmUser.name
    },
    patient: {
      mrNumber: `MR-${patientId}`,
      id: patientId,
      name: { firstName: values.firstName, lastName: values.lastName },
      birthDate: Utils.isValidDate(values.birthDate)
        ? `${values.birthDate.getFullYear()}-${(values.birthDate.getMonth() + 1)
            .toString(10)
            .padStart(2, '0')}-${values.birthDate.getDate().toString(10).padStart(2, '0')}`
        : '',
      gender: values.gender as GenderIdentity,
      hcnNumber,
      hcnVersionNumber,
      address: [
        {
          use: values.addressType as HRM.AddressType,
          line: [values.address],
          city: values.city,
          province: values.province,
          postalCode: (values.postalCode.match(/[a-z0-9]/gi) || []).join(''),
          country: 'CAN'
        }
      ],
      telecom,
      deceasedBoolean: values.deceased
    },
    encounter: {
      visitNumber: `VN${values.startDate!.toISOString()}`,
      encounterClass: values.encounterClass as HRM.EncounterClass,
      status: values.encounterStatus as HRM.EncounterStatus,
      startDt: values.startDate!.toISOString(),
      endDt: values.endDate!.toISOString()
    },
    diagnostic: {
      performerId: hrmUser.id,
      categoryCode: values.category as HRM.DiagnosticReportCategoryCode,
      loincType: values.loincType as HRM.DiagnosisLoincType,
      status: values.diagnosisStatus as HRM.DiagnosisStatus,
      // isUrgency: values.urgent,
      isUrgency: false,
      conclusion: values.conclusion,
      effectiveDateTime: values.effectiveDate!.toISOString(),
      issuedDateTime: values.issuedDate!.toISOString()
    },
    notes: values.notes,
    document: {
      status: 'current',
      authors: [hrmUser.id],
      recipients: values.recipient.map(({ id }) => id),
      docs: []
    },
    practitioners: values.recipient
      .concat(hrmUser)
      .reduce<MessagingActionPayload.SendHrmMessage['messageBody']['practitioners']>(
        (practitionerList, recipient, index) => {
          if (index !== recipientCount && recipient.id === hrmUser.id) return practitionerList;

          const entryIndex = HrmUtils.findActiveHrmUserIndex(hrmUserList, recipient.id);
          if (entryIndex > -1) {
            const entry = hrmUserList[entryIndex];

            const code = entry.id.length <= 6 ? 'MD' : 'NP';
            const regulatoryURL = code === 'MD' ? REGULATORY_URL_NP : REGULATORY_URL_MD;

            practitionerList.push({
              practitionerId: entry.id,
              regulatoryIssuedId: entry.id,
              firstName: entry.firstName,
              lastName: entry.lastName,
              code,
              regulatoryURL
            });
          }

          return practitionerList;
        },
        []
      )
  };
};

export const createPdfFile = async (
  documentList: ActionData['documentList'],
  notes: string,
  cache: IDataObjectCache
): Promise<File> => {
  const pdfDocument = PdfDocument.createWithHeader(
    'This document has been sent using {{PRODUCT_NAME}} powered by {{COMPANY_NAME}}.'
  );

  await pdfDocument.addHtml(notes);
  for (const attachment of documentList) {
    await pdfDocument.addSigMailMessageAttachment(attachment, { cache });
  }

  const buffer = await pdfDocument.getBuffer();
  if (buffer.byteLength > HRM_MAX_TOTAL_ATTACHMENT_SIZE) {
    throw MaxAttachmentSizeError;
  }

  return new File([buffer], `SigMail_HRM_pdf_combined_${Date.now()}.pdf`, { type: Constants.MimeType.PDF });
};

export const useSendHrmMessageActionHandler = () =>
  React.useCallback(async (actionData: ActionData): Promise<void> => {
    const { documentList, failCallback, formValues, guestUserId, hrmUser, hrmUserList, successCallback } = actionData;

    const dataObjectCache = Utils.isNotNil(actionData.scopedDataObjectCache)
      ? actionData.scopedDataObjectCache
      : new DataObjectCache();

    let value: any;

    try {
      await fetchDocumentObjects(documentList, dataObjectCache);

      const messageBody = createMessageBody(formValues, hrmUserList, hrmUser, guestUserId);
      const pdfFile = await createPdfFile(documentList, formValues.notes, dataObjectCache);
      const dataUri = parseDataUri(await readBlobAsDataUri(pdfFile));
      if (!dataUri.isValid) throw InvalidDataURLError;

      messageBody.document.docs.push({
        title: pdfFile.name,
        contentType: Constants.MimeType.PDF,
        language: 'en',
        createdDate: new Date().toISOString(),
        data: dataUri.data
      });

      let { folderKey, parentFolderKey, sourceMessage } = actionData;
      let sourceHrmMessage: ActionData['sourceMessage'];
      if (sourceMessage?.msgFlags.isMessageFormHRM === true) {
        sourceHrmMessage = sourceMessage;

        folderKey = undefined;
        parentFolderKey = undefined;
        sourceMessage = undefined;
      }

      value = await store.dispatch(
        sendHrmMessageAction({ folderKey, messageBody, parentFolderKey, sourceHrmMessage, sourceMessage })
      );
    } catch (error) {
      failCallback?.(error);
      return;
    }

    successCallback?.(value);
  }, []);
