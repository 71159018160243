import { SchedulingI18n } from '@sigmail/i18n';
import { gettext, ngettext } from '..';
import { DialogConfirmRecallEvent } from '../../constants/action-context';

const i18n: SchedulingI18n.Dialog.ConfirmEventRecall = {
  // Translators: (HTML) Title for the Confirm Event Recall dialog
  title: gettext('scheduling', 'confirmEventRecallDialog', 'title', 'safe-html'),
  // Translators: (HTML) Confirmation message to be displayed in the body \
  // of Confirm Event Recall dialog, for e.x., Are you sure you want to \
  // delete this event?
  body: ngettext('scheduling', 'confirmEventRecallDialog', 'body', 'safe-html'),
  // Translators: (HTML) Message to inform user that deletion will fail \
  // for events which are past their end date and time
  noteEventMayNotRecall: gettext('scheduling', 'confirmEventRecallDialog', 'noteEventMayNotRecall', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action
      label: gettext('scheduling', 'confirmEventRecallDialog', 'actionLabelReject'),
      context: DialogConfirmRecallEvent
    },
    accept: {
      // Translators: Label for the Confirm action
      label: gettext('scheduling', 'confirmEventRecallDialog', 'actionLabelAccept'),
      context: DialogConfirmRecallEvent
    }
  }
};

export default i18n;
