import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectContactListValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_CONTACT_LIST;

/** @public */
export class UserObjectContactList extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isArray(dv.recent) && Utils.isArray(dv.contacts);
  }

  public override updateValue(newValue: DV): Promise<UserObjectContactList> {
    const Class = this.constructor as typeof UserObjectContactList;
    return Class.updateValue(this, newValue);
  }
}
