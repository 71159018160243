import type {
  AlgorithmCode,
  EncryptAsymmetricKeyAlgorithmCode,
  EncryptEncapsulatedKeyAlgorithmCode,
  EncryptObjectAlgorithmCode,
  EncryptWithParametersAlgorithmCode,
  NonEncryptedObjectAlgorithmCode
} from '@sigmail/common';
import { Encryptor } from '../encryptor';
import type { AsymmetricEncryptor, IAlgorithm, SymmetricEncryptor } from '../types';

const VALID_NON_ENCRYPTED_OBJECT_ALGORITHM_CODE_LIST: ReadonlyArray<NonEncryptedObjectAlgorithmCode> = [
  process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT
];

const VALID_ENCRYPT_ASYMMETRIC_KEY_ALGORITHM_CODE_LIST: ReadonlyArray<EncryptAsymmetricKeyAlgorithmCode> = [
  process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC,
  process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE
];

const VALID_ENCRYPT_WITH_PARAMS_ALGORITHM_CODE_LIST: ReadonlyArray<EncryptWithParametersAlgorithmCode> = [
  process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS
];

const VALID_ENCRYPT_ENCAPSULATED_KEY_ALGORITHM_CODE_LIST: ReadonlyArray<EncryptEncapsulatedKeyAlgorithmCode> = [
  process.env.ALGORITHM_CODE_ENCRYPT_ENCAPSULATED_KEY
];

const VALID_ENCRYPT_OBJECT_ALGORITHM_CODE_LIST: ReadonlyArray<EncryptObjectAlgorithmCode> = [
  process.env.ALGORITHM_CODE_ENCRYPT_OBJECT
];

/** A list of known algorithm codes. */
const VALID_ALGORITHM_CODE_LIST: ReadonlyArray<AlgorithmCode> = [
  ...VALID_NON_ENCRYPTED_OBJECT_ALGORITHM_CODE_LIST,
  ...VALID_ENCRYPT_ASYMMETRIC_KEY_ALGORITHM_CODE_LIST,
  ...VALID_ENCRYPT_WITH_PARAMS_ALGORITHM_CODE_LIST,
  ...VALID_ENCRYPT_ENCAPSULATED_KEY_ALGORITHM_CODE_LIST,
  ...VALID_ENCRYPT_OBJECT_ALGORITHM_CODE_LIST
];

/** @public */
export abstract class Algorithm<
    E extends SymmetricEncryptor | AsymmetricEncryptor,
    D,
    K = ReturnType<E['generateKey']> extends Promise<infer U> ? U : ReturnType<E['generateKey']>
  >
  extends Encryptor
  implements IAlgorithm<K, D>
{
  public static readonly isValidNonEncryptedObjectCode = (value: unknown): value is NonEncryptedObjectAlgorithmCode =>
    // @ts-expect-error TS2345
    VALID_NON_ENCRYPTED_OBJECT_ALGORITHM_CODE_LIST.includes(value);

  public static readonly isValidEncryptAsymmetricKeyCode = (
    value: unknown
  ): value is EncryptAsymmetricKeyAlgorithmCode =>
    // @ts-expect-error TS2345
    VALID_ENCRYPT_ASYMMETRIC_KEY_ALGORITHM_CODE_LIST.includes(value);

  public static readonly isValidEncryptWithParametersCode = (
    value: unknown
  ): value is EncryptWithParametersAlgorithmCode =>
    // @ts-expect-error TS2345
    VALID_ENCRYPT_WITH_PARAMS_ALGORITHM_CODE_LIST.includes(value);

  public static readonly isValidEncryptEncapsulatedKeyCode = (
    value: unknown
  ): value is EncryptEncapsulatedKeyAlgorithmCode =>
    // @ts-expect-error TS2345
    VALID_ENCRYPT_ENCAPSULATED_KEY_ALGORITHM_CODE_LIST.includes(value);

  public static readonly isValidEncryptObjectCode = (value: unknown): value is EncryptObjectAlgorithmCode =>
    // @ts-expect-error TS2345
    VALID_ENCRYPT_OBJECT_ALGORITHM_CODE_LIST.includes(value);

  public static readonly isValidCode = (value: unknown): value is AlgorithmCode =>
    // @ts-expect-error TS2345
    VALID_ALGORITHM_CODE_LIST.includes(value);

  /* eslint-disable no-unused-vars */
  protected constructor(name: string, protected readonly encryptor: E) {
    super(name);
  }

  public abstract generateKey(...args: Array<unknown>): Promise<K>;

  public abstract encrypt(key: K, data: D, version: number): Promise<string>;

  public abstract decrypt(key: K, data: string, version: number): Promise<D>;
  /* eslint-enable no-unused-vars */
}
