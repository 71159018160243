import { AppException, Constants, Utils } from '@sigmail/common';
import {
  CryptographicKey,
  CryptographicKeyPublic,
  DataObjectMsgMetadataValue,
  DataObjectSigmailGlobalContactListValue
} from '@sigmail/objects';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';

interface Payload extends BaseSendMessagePayload {
  groupType: string;
}

interface State extends BaseSendMessageState {
  group: Extract<DataObjectSigmailGlobalContactListValue['list'][0], { type: 'group' }>;
}

export class SendAsSigMailGroup extends BaseSendMessageAction<Payload, State> {
  /** @override */
  protected async generateIdSequence() {
    await super.generateIdSequence();
    this.state.msgReadReceiptId = 0;

    await this.fetchSigmailGroup();
    await this.fetchSigmailGroupPublicKey();
  }

  /** @override */
  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    const { groupData, ...group } = this.state.group;

    const metadata = await super.createMessageMetadataValue();
    return { ...metadata, sender: { ...group, groupName: groupData.groupName } };
  }

  /** @override */
  protected addInsertOperationForMessageReadReceipt(): Promise<void> {
    return Promise.resolve();
  }

  /** @override */
  protected addUpdateOperationForSentMessagesFolder(): Promise<void> {
    return Promise.resolve();
  }

  private async fetchSigmailGroup(): Promise<void> {
    const { groupType } = this.payload;
    const { globalContactListId } = this.state;

    const globalContactList = await this.getDataObjectValue<DataObjectSigmailGlobalContactListValue>(globalContactListId, { fetch: true });
    if (Utils.isNil(globalContactList)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Global contact list is either missing or invalid.');
    }

    const sigMailGroup = globalContactList.list.find((contact) => contact.type === 'group' && contact.groupType === groupType) as
      | State['group']
      | undefined;
    if (Utils.isNil(sigMailGroup)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to find SigMail Group in global contact list.');
    }

    this.state.group = sigMailGroup;
    this.state.recipientKeyIdList.concat(sigMailGroup.id);
  }

  private async fetchSigmailGroupPublicKey(): Promise<void> {
    const { group, roleAuthClaim: authState } = this.state;

    const { keyList } = await this.dispatchFetchObjects({
      authState,
      keysByType: [{ id: group.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }]
    });

    const groupPublicKeyJson = this.findKey(keyList, { id: group.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC });
    if (Utils.isNil(groupPublicKeyJson)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Failed to fetch public key of SigMail Group.');
    } else {
      const groupKeyPublic = new CryptographicKeyPublic(groupPublicKeyJson);
      await CryptographicKey.cache(groupKeyPublic);
    }
  }
}
