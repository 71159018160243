import { ReadonlyPartialRecord, Utils } from '@sigmail/common';
import React from 'react';
import { useInterval } from '../../app-state/hooks';

/**
 * Options such as the window's default size and position, whether or not to
 * open a minimal popup window, and so forth.
 *
 * **IMPORTANT:** Browser may ignore/override some of these features.
 */
export interface WindowFeatures {
  /**
   * Height, in pixels, of the content area, including scrollbars. The minimum
   * required value is 100.
   */
  readonly innerHeight?: number;

  /**
   * Width, in pixels, of the content area, including scrollbars. The minimum
   * required value is 100.
   */
  readonly innerWidth?: number;

  /**
   * If `true`, the new window opened will have the menu bar present.
   */
  readonly menubar?: boolean;

  /**
   * If `true`, the new window will not have access to the originating window
   * via `Window.opener` and returns `null`. Non-empty target names, other than
   * `_top`, `_self`, and `_parent`, are treated like `_blank` in terms of
   * deciding whether to open a new browsing context.
   */
  readonly noOpener?: boolean;

  /**
   * If `true`, the browser will omit the `Referer` header, as well as set
   * `noOpener` to `true`.
   */
  readonly noReferrer?: boolean;

  /**
   * If `true`, the new window opened is resizable.
   */
  readonly resizable?: boolean;

  /**
   * If `true`, it requests that a minimal popup window be used. The UI features
   * included in the popup window will be automatically decided by the browser,
   * generally including an address bar only.
   *
   * If `false`, there are no window features declared, the new browsing context
   * will be a tab.
   */
  readonly popup?: boolean;

  /**
   * Distance, in pixels, from the left side of the work area as defined by the
   * user's operating system where the new window will be generated.
   */
  readonly screenX?: number;

  /**
   * Distance, in pixels, from the top side of the work area as defined by the
   * user's operating system where the new window will be generated.
   */
  readonly screenY?: number;

  /**
   * If `true`, the new window opened will have the scroll bars present.
   */
  readonly scrollbars?: boolean;

  /**
   * If `true`, the new window opened will have the status bar present.
   */
  readonly statusbar?: boolean;

  /**
   * If `true`, the new window opened will have the tool bar present.
   */
  readonly toolbar?: boolean;
}

export interface Props extends WindowFeatures {
  readonly children?: never;

  /**
   * Options such as the window's default size and position, whether or not to
   * open a minimal popup window, and so forth.
   */
  readonly features?: ReadonlyPartialRecord<string, unknown>;

  /**
   * Callback fired when window could not be opened; possibly because of a popup
   * blocker preventing the opening.
   */
  readonly onBlock?: () => void;

  /**
   * Callback fire when the window is closed via user interaction.
   */
  readonly onClose?: () => void;

  /**
   * A string, without whitespace, specifying the name of the browsing context
   * the resource is being loaded into. If the name doesn't identify an existing
   * context, a new context is created and given the specified name.
   *
   * The special target keywords, `_self`, `_blank`, `_parent`, and `_top`, can
   * also be used.
   */
  readonly target?: string;

  /**
   * URL or path of the resource to be loaded. If omitted, or an empty string
   * (`""`) is specified, a blank page is opened into the targeted browsing
   * context.
   */
  readonly url?: string;
}

const featuresToString = (value?: unknown): string => {
  let features: Array<string> = [];
  if (Utils.isNonArrayObjectLike<Record<string, boolean | number>>(value)) {
    features.push(
      ...Object.keys(value).reduce<Array<string>>((list, option) => {
        switch (option) {
          case 'menubar':
          case 'noOpener':
          case 'noReferrer':
          case 'popup':
          case 'resizable':
          case 'scrollbars':
          case 'statusbar':
          case 'toolbar': {
            if (typeof value[option] === 'boolean') {
              list.push(option === 'statusbar' ? 'status' : option.toLowerCase());
            }
            break;
          }
          case 'innerHeight':
          case 'innerWidth':
          case 'screenX':
          case 'screenY': {
            if (Utils.isInteger(value[option])) {
              list.push(`${option}=${value[option]}`);
            }
            break;
          }
          default: {
            list.push(`${option}=${String(value[option])}`);
            break;
          }
        }
        return list;
      }, [])
    );
  }
  return features.join(',');
};

export const BrowserWindow = React.forwardRef<WindowProxy, Props>(
  ({ children: _omitted$1, features: otherFeatures, onBlock, onClose, target, url, ...features }, ref) => {
    const windowFeatures = featuresToString({ ...features, ...otherFeatures });
    const [windowRef, setWindowRef] = React.useState<WindowProxy | null>(null);

    const checkWindowClosed = React.useCallback(() => {
      if (Utils.isNotNil(windowRef) && windowRef.closed) {
        setWindowRef(null);
        if (typeof onClose === 'function') {
          onClose();
        }
      }
    }, [onClose, windowRef]);

    useInterval(checkWindowClosed, Utils.isNil(windowRef) ? null : 1000);

    React.useEffect(
      () => {
        const handle = window.open(url, target, windowFeatures);
        if (Utils.isNil(handle)) {
          if (typeof onBlock === 'function') {
            onBlock();
          }
        } else {
          setWindowRef(handle);
          return () => handle.close();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [] /* blank intentionally */
    );

    React.useEffect(() => {
      if (typeof ref === 'function') {
        ref(windowRef);
      } else if (Utils.isNonArrayObjectLike<Exclude<typeof ref, Function>>(ref)) {
        ref.current = windowRef;
      }
    }, [ref, windowRef]);

    return null;
  }
);
