import type { SigmailClientId } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../../../http/service-exception';
import type { Api, Http } from '../../../types';
import { URL_ACCURO_EMR_ADD_DOCUMENT as API_URL } from '../../constants';
import { MalformedResponseException } from '../../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../../service-exception';

type ApiRequest = Api.AccuroEMRAddDocumentRequest;
type ApiResponse = Api.AccuroEMRAddDocumentResponse;

interface Request extends Omit<ApiRequest, 'document' | 'clientId'> {
  document: Omit<ApiRequest['document'], 'content' | 'fileName'> & {
    attachments: Array<Pick<ApiRequest['document'], 'content' | 'fileName'>>;
  };
  userId: SigmailClientId;
}

interface Response {
  readonly documentIds: Readonly<[number]>;
}

const Logger = getLoggerWithPrefix('ApiService', 'accuroEMRAddDocument:');

export async function apiAccuroEMRAddDocument(
  this: Api.Service,
  accessToken: string,
  { clientId, document: { content, description, fileName, fromType, priority, ...document }, ...query }: ApiRequest,
  init?: Http.RequestInit
): Promise<ApiResponse> {
  try {
    Logger.info('== BEGIN ==');
    const requestUrl = this.baseUrl.coreApi.concat(API_URL);

    const requestHeaders = new Headers(init?.headers);
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    description = Utils.trimOrDefault(description).slice(0, 500);
    if (description.length === 0) description = undefined;
    if (!Utils.isString(fromType)) fromType = 'Physician';
    if (priority !== 2 && priority !== 3) priority = 1;

    const requestData: Request = {
      ...query,
      document: {
        ...document,
        attachments: [{ content, fileName }],
        description,
        fromType,
        priority
      },
      userId: clientId
    };

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<ApiResponse>(requestUrl, JSON.stringify(requestData), {
      ...init,
      cache: Utils.stringOrDefault<RequestCache>(init?.cache, 'no-store'),
      headers: requestHeaders,

      async responseParser(response) {
        if (response.status === 200) {
          const json = await Utils.tryGetResponseJson<Response>(response, undefined);
          if (isValidResponseData(json)) {
            const [documentId] = json.documentIds;
            return { documentId };
          }

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status >= 400 && response.status < 500) {
          const responseBody = await response.clone().text();
          throw new ApiServiceException(response, responseBody);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}

function isValidResponseData(responseJson?: unknown): responseJson is Response {
  return true;
  // return (
  //   Utils.isNonArrayObjectLike<{ documentIds: unknown }>(responseJson) &&
  //   Utils.isArray(responseJson.documentIds) &&
  //   responseJson.documentIds.length === 1 &&
  //   Utils.isInteger(responseJson.documentIds[0])
  // );
}
