import { Utils } from '@sigmail/common';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route as ReactRouterRoute } from 'react-router';
import { RouteProps } from 'sigmail';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';

const { documentTitle: i18n } = globalI18n;

export const Route: React.FC<RouteProps> = ({
  actionId: _omitted$1,
  routeId,
  childRoutes: _omitted$2,
  ...routeProps
}) => {
  // prettier-ignore
  const { i18n: { language: locale }, t } = useTranslation([I18N_NS_GLOBAL]);

  let prefix = '';
  let suffix = '';
  let documentTitle = t(Utils.trimOrDefault(i18n[routeId]));
  if (documentTitle.length > 0) {
    prefix = t(Utils.trimOrDefault(i18n.prefix));
    suffix = t(Utils.trimOrDefault(i18n.suffix));
  } else {
    documentTitle = t(globalI18n.productName);
  }
  window.document.title = [prefix, documentTitle, suffix].filter(Boolean).join(' ');

  return (
    <React.Fragment>
      {React.useMemo((): React.ReactNode => {
        const elRouteTitle = document.getElementById('sm-route-title');
        if (elRouteTitle === null || routeId.indexOf('/') === -1) return null;

        const nodeList = documentTitle
          .toLocaleUpperCase(locale)
          .split(' ')
          .map<React.ReactNode>((text, index) => (
            <React.Fragment key={`${routeId}-${index}`}>
              <span>{text}</span>{' '}
            </React.Fragment>
          ));

        return nodeList.length === 0 ? null : ReactDOM.createPortal(nodeList, elRouteTitle);
      }, [documentTitle, locale, routeId])}

      <ReactRouterRoute {...routeProps} />
    </React.Fragment>
  );
};

Route.displayName = 'Route';
