import { WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Utils } from '@sigmail/common';
import React from 'react';
import { DialogAccept } from '../../../../constants/action-ids';
import { SubmitButton } from '../../submit-button.component';
import style from '../dialog-box.module.css';

export interface AcceptActionProps extends React.ComponentPropsWithoutRef<typeof SubmitButton> {
  actionId?: string | undefined;
  innerRef?: React.Ref<HTMLButtonElement> | undefined;
}

export type DialogActionsAcceptClassKey = 'actionAccept';

const styles = createStyles<DialogActionsAcceptClassKey, {}>({
  actionAccept: {}
});

export interface DialogActionsAcceptProps extends WithStyles<typeof styles> {
  children?: never;
  onAccept?: React.MouseEventHandler<HTMLButtonElement>;
  AcceptActionProps?: AcceptActionProps;
}

class DialogActionsAcceptComponent extends React.PureComponent<DialogActionsAcceptProps> {
  public render(): React.ReactNode {
    const { classes, AcceptActionProps, onAccept } = this.props;

    let { actionId: actionIdAccept, innerRef, ...acceptActionProps } = AcceptActionProps || {};
    if (!Utils.has(AcceptActionProps, 'actionId')) actionIdAccept = DialogAccept;

    acceptActionProps = {
      className: [style['action-accept'], classes.actionAccept].filter(Boolean).join(' '),
      onClick: onAccept,
      ...acceptActionProps
    };

    return <SubmitButton data-action-id={actionIdAccept} ref={innerRef} {...acceptActionProps} />;
  }
}

export const DialogActionsAccept = withStyles(styles)(DialogActionsAcceptComponent);
DialogActionsAccept.displayName = 'DialogActionsAccept';
