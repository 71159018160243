/* eslint-disable @typescript-eslint/no-inferrable-types */

/** Indicates a success result. */
export const SEVERITY_SUCCESS: number = 0;

/**
 * Indicates a severe error result which will prevent the application from
 * continuing.
 */
export const SEVERITY_FATAL: number = 1;

/** Indicates an error result from which recovery is likely possible. */
export const SEVERITY_ERROR: number = 2;

/** Indicates a warning result. */
export const SEVERITY_WARN: number = 3;
