import { HealthDataI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: HealthDataI18n.Form.CardiomyopathyQuestionnaire = {
  fieldsetPhysicalLimitation: {
    // Translators: Label for the Physical Limitation fieldset in \
    // Cardiomyopathy Questionnaire form
    label: gettext('health-data', 'physicalLimitation', 'kccqFormFieldsetLabel'),
    formField: {
      jog: {
        // Translators: Label for the Jogging Activity input in Cardiomyopathy \
        // Questionnaire form
        label: gettext('health-data', 'jog', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Extremely Limited option of Jogging \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'jog1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Quite A Bit Limited option of \
            // Jogging Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'jog2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Moderately Limited option of Jogging \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'jog3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Slightly Limited option of Jogging \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'jog4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Not At All Limited option of Jogging \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'jog5', 'kccqFormOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Limited For Other Reasons option of \
            // Jogging Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'jog6', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Jogging Activity input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'jogRequired', 'kccqFormError')
        }
      },
      shower: {
        // Translators: Label for the Showering Activity input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'shower', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Extremely Limited option of \
            // Showering Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'shower1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Quite A Bit Limited option of \
            // Showering Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'shower2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Moderately Limited option of \
            // Showering Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'shower3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Slightly Limited option of Showering \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'shower4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Not At All Limited option of \
            // Showering Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'shower5', 'kccqFormOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Limited For Other Reasons option of \
            // Showering Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'shower6', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Showering Activity input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'showerRequired', 'kccqFormError')
        }
      },
      walk: {
        // Translators: Label for the Walking Activity input in Cardiomyopathy \
        // Questionnaire form
        label: gettext('health-data', 'walk', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Extremely Limited option of Walking \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'walk1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Quite A Bit Limited option of \
            // Walking Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'walk2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Moderately Limited option of Walking \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'walk3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Slightly Limited option of Walking \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'walk4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Not At All Limited option of Walking \
            // Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'walk5', 'kccqFormOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Limited For Other Reasons option of \
            // Walking Activity input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'walk6', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Walking Activity input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'walkRequired', 'kccqFormError')
        }
      }
    }
  },
  fieldsetQualityOfLife: {
    formField: {
      chronicity: {
        // Translators: Label for the Chronicity Impact input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'chronicity', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Completely Dissatisfied option of \
            // Chronicity Impact input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'chronicity1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Mostly Dissatisfied option of \
            // Chronicity Impact input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'chronicity2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Fairly Satisfied option of \
            // Chronicity Impact input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'chronicity3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Mostly Satisfied option of \
            // Chronicity Impact input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'chronicity4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Completely Satisfied option of \
            // Chronicity Impact input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'chronicity5', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Chronicity Impact input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'chronicityRequired', 'kccqFormError')
        }
      },
      enjoymentLoss: {
        // Translators: Label for the Loss Of Enjoyment input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'enjoymentLoss', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Extremely Limited option of \
            // Loss Of Enjoyment input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'enjoymentLoss1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Quite A Bit Limited option of \
            // Loss Of Enjoyment input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'enjoymentLoss2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Moderately Limited option of \
            // Loss Of Enjoyment input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'enjoymentLoss3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Slightly Limited option of Loss Of \
            // Enjoyment input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'enjoymentLoss4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Not At All Limited option of \
            // Loss Of Enjoyment input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'enjoymentLoss5', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Loss Of Enjoyment input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'enjoymentLossRequired', 'kccqFormError')
        }
      }
    }
  },
  fieldsetSocialLimitation: {
    // Translators: Label for the Social Limitation fieldset in \
    // Cardiomyopathy Questionnaire form
    label: gettext('health-data', 'socialLimitation', 'kccqFormFieldsetLabel'),
    formField: {
      household: {
        // Translators: Label for the Household Activities input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'household', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Extremely Limited option of \
            // Household Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'household1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Quite A Bit Limited option of \
            // Household Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'household2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Moderately Limited option of \
            // Household Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'household3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Slightly Limited option of \
            // Household Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'household4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Not At All Limited option of \
            // Household Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'household5', 'kccqFormOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Limited For Other Reasons option of \
            // Household Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'household6', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Household Activities input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'householdRequired', 'kccqFormError')
        }
      },
      rec: {
        // Translators: Label for the Recreational Activities input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'rec', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Extremely Limited option of \
            // Recreational Activities input in Cardiomyopathy Questionnaire \
            // form
            label: gettext('health-data', 'rec1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Quite A Bit Limited option of \
            // Recreational Activities input in Cardiomyopathy Questionnaire \
            // form
            label: gettext('health-data', 'rec2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Moderately Limited option of \
            // Recreational Activities input in Cardiomyopathy Questionnaire \
            // form
            label: gettext('health-data', 'rec3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Slightly Limited option of \
            // Recreational Activities input in Cardiomyopathy Questionnaire \
            // form
            label: gettext('health-data', 'rec4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Not At All Limited option of \
            // Recreational Activities input in Cardiomyopathy Questionnaire \
            // form
            label: gettext('health-data', 'rec5', 'kccqFormOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Limited For Other Reasons option of \
            // Recreational Activities input in Cardiomyopathy Questionnaire \
            // form
            label: gettext('health-data', 'rec6', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Recreational Activities input in Cardiomyopathy Questionnaire \
          // form
          valueMissing: gettext('health-data', 'recRequired', 'kccqFormError')
        }
      },
      social: {
        // Translators: Label for the Social Activities input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'social', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Extremely Limited option of \
            // Social Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'social1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Quite A Bit Limited option of \
            // Social Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'social2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the Moderately Limited option of \
            // Social Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'social3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Slightly Limited option of \
            // Social Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'social4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Not At All Limited option of \
            // Social Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'social5', 'kccqFormOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Limited For Other Reasons option of \
            // Social Activities input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'social6', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Social Activities input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'socialRequired', 'kccqFormError')
        }
      },
    }
  },
  fieldsetSymptomFrequency: {
    formField: {
      dyspnea: {
        // Translators: Label for the Dyspnea Symptom input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'dyspnea', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the All Of The Time option of Dyspnea \
            // Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'dyspnea1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Several Times A Day option of \
            // Dyspnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'dyspnea2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the At Least Once A Day option of \
            // Dyspnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'dyspnea3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the 3 Or More Times A Week option of \
            // Dyspnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'dyspnea4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the 1-2 Times A Week option of \
            // Dyspnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'dyspnea5', 'kccqFormOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Less Than Once A Week option of \
            // Dyspnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'dyspnea6', 'kccqFormOptionLabel')
          },
          {
            codedValue: 7,
            // Translators: Label for the Never Over The Past Two Weeks option \
            // of Dyspnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'dyspnea7', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Dyspnea Symptom input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'dyspneaRequired', 'kccqFormError')
        }
      },
      fatigue: {
        // Translators: Label for the Fatigue Symptom input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'fatigue', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the All Of The Time option of Fatigue \
            // Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'fatigue1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the Several Times A Day option of \
            // Fatigue Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'fatigue2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the At Least Once A Day option of \
            // Fatigue Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'fatigue3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the 3 Or More Times A Week option of \
            // Fatigue Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'fatigue4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the 1-2 Times A Week option of \
            // Fatigue Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'fatigue5', 'kccqFormOptionLabel')
          },
          {
            codedValue: 6,
            // Translators: Label for the Less Than Once A Week option of \
            // Fatigue Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'fatigue6', 'kccqFormOptionLabel')
          },
          {
            codedValue: 7,
            // Translators: Label for the Never Over The Past Two Weeks option \
            // of Fatigue Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'fatigue7', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Fatigue Symptom input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'fatigueRequired', 'kccqFormError')
        }
      },
      orthopnea: {
        // Translators: Label for the Orthopnea Symptom input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'orthopnea', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Every Night option of Orthopnea \
            // Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'orthopnea1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the 3 Or More Times A Week option of \
            // Orthopnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'orthopnea2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the 1-2 Times A Week option of \
            // Orthopnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'orthopnea3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Less Than Once A Week option of \
            // Orthopnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'orthopnea4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Never Over The Past Two Weeks option \
            // of Orthopnea Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'orthopnea5', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Orthopnea Symptom input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'orthopneaRequired', 'kccqFormError')
        }
      },
      swelling: {
        // Translators: Label for the Swelling Symptom input in \
        // Cardiomyopathy Questionnaire form
        label: gettext('health-data', 'swelling', 'kccqFormInputLabel'),
        options: [
          {
            codedValue: 1,
            // Translators: Label for the Every Morning option of Swelling \
            // Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'swelling1', 'kccqFormOptionLabel')
          },
          {
            codedValue: 2,
            // Translators: Label for the 3 Or More Times A Week option of \
            // Swelling Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'swelling2', 'kccqFormOptionLabel')
          },
          {
            codedValue: 3,
            // Translators: Label for the 1-2 Times A Week option of \
            // Swelling Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'swelling3', 'kccqFormOptionLabel')
          },
          {
            codedValue: 4,
            // Translators: Label for the Less Than Once A Week option of \
            // Swelling Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'swelling4', 'kccqFormOptionLabel')
          },
          {
            codedValue: 5,
            // Translators: Label for the Never Over The Past Two Weeks option \
            // of Swelling Symptom input in Cardiomyopathy Questionnaire form
            label: gettext('health-data', 'swelling5', 'kccqFormOptionLabel')
          }
        ],
        error: {
          // Translators: Error message displayed when no option is selected \
          // for Swelling Symptom input in Cardiomyopathy Questionnaire form
          valueMissing: gettext('health-data', 'swellingRequired', 'kccqFormError')
        }
      },
    }
  }
};

export default i18n;
