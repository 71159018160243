import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import {
  AppException,
  AppUser,
  Constants,
  GenderIdentity,
  MessageHeader,
  Nullable,
  ReadonlyMessageBodyConsultation,
  ReadonlyMessageBodyDefault,
  ReadonlyMessageBodyHrm,
  ReadonlyMessageBodyReferral,
  ReadonlyPartialRecord,
  SigmailUserId,
  Utils
} from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectDocBody,
  DataObjectMsgBodyValue,
  DataObjectMsgMetadataValue,
  UserObjectProfileBasicValue,
  UserObjectProfileProtectedValue,
  ValueFormatVersion
} from '@sigmail/objects';
import { Api, EMRCode, EMRDocument, EMRPatientRecord } from '@sigmail/services';
import { v4 as uuid } from 'uuid';
import { AppThunk } from '../..';
import { MessageFlags } from '../../../app/messaging/utils';
import { FACILITY_SEND_TO_EMR } from '../../../constants/error-facility';
import { PdfDocument } from '../../../core/pdf-document';
import {
  mimeToDocumentFileType as mimeToAccuroDocumentFileType,
  toAccuroEMRPatientRecord,
  tokenExpiryToDays as accuroTokenExpiryToDays
} from '../../../utils/accuro-emr';
import { getRandomBytes } from '../../../utils/get-random-bytes';
import { parseDataUri } from '../../../utils/parse-data-uri';
import { sanitizeFilename } from '../../../utils/sanitize-file-name';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../../constants';
import { DataObjectCache } from '../../data-objects-slice/cache';
import { dataObjectByIdSelector } from '../../selectors/data-object';
import { preferencesObjectSelector as selectGroupPreferences } from '../../selectors/group-object';
import {
  basicProfileObjectSelector as selectUserProfileBasic,
  protectedProfileObjectSelector as selectUserProfileProtected
} from '../../selectors/user-object';
import { UserObjectCache } from '../../user-objects-slice/cache';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { setLastSentToEMRFlagAction } from '../messaging/set-last-sent-to-emr-flag-action';
import { accuroEMRAddDocument } from './accuro/add-document';
import { accuroEMRCheckAuthStatus } from './accuro/check-auth-status';
import { accuroEMRGetOAuthUrl } from './accuro/get-oauth-url';
import { accuroEMRProviderFolderList } from './accuro/provider-folder-list';
import { accuroEMRSearchPatient } from './accuro/search-patient';
import { oscarEMRAddDocument } from './oscar/add-document';
import { oscarEMRCheckAuthStatus } from './oscar/check-auth-status';
import { oscarEMRGetOAuthUrl } from './oscar/get-oauth-url';
import { oscarEMRGetProviderNumber } from './oscar/get-provider-number';
import { oscarEMRSearchPatient } from './oscar/search-patient';

type P = ApiActionPayload.SendDataToEMR;

interface S extends AuthenticatedActionState {
  dtServer: Date;
  emrAuthParams: Api.AccuroEMROAuthParams | Api.OscarEMROAuthParams;
  emrCode: EMRCode;
  emrPatientRecord: EMRPatientRecord;
  emrProviderFolder: Api.AccuroEMRProviderFolderListItem;
  emrProviderId: number | string;
  isAuthenticated: boolean;
  lastSentToEmrPayload: MessagingActionPayload.SetLastSentToEmrFlag | undefined;
  uploadStatus: Array<Parameters<NonNullable<P['onChangeUploadStatus']>>[0][0]>;
}

type SigMailMessageLike = Exclude<NonNullable<P['data']>, EMRDocument>;

export const ACCURO_EMR_ERROR_CODE_DUPLICATE_DOCUMENT = 40001 as const;

const EMR_MIME_LIST_MAP: ReadonlyPartialRecord<EMRCode, ReadonlyArray<string>> = {
  accuro: [Constants.MimeType.JPEG, Constants.MimeType.PDF, Constants.MimeType.PNG, Constants.MimeType.TEXT_PLAIN],

  oscar: [
    Constants.MimeType.JPEG,
    Constants.MimeType.PDF,
    Constants.MimeType.PNG,
    Constants.MimeType.TEXT_HTML,
    Constants.MimeType.TEXT_PLAIN
  ]
};

const MAKE_ERROR_CODE = (code: number) => Utils.MAKE_ERROR_CODE(Constants.Error.SEVERITY_ERROR, FACILITY_SEND_TO_EMR, code);
export const EMRGuestProfileLoadFailedError = new AppException(MAKE_ERROR_CODE(1));
export const EMRMsgContentLoadFailedError = new AppException(MAKE_ERROR_CODE(2));
export const EMRNoAccuroProviderIdError = new AppException(MAKE_ERROR_CODE(3));
export const EMRNoOscarProviderIdError = new AppException(MAKE_ERROR_CODE(4));
export const EMRNoMatchingPatientRecordError = new AppException(MAKE_ERROR_CODE(5));
export const EMRNoSelectedFolderError = new AppException(MAKE_ERROR_CODE(6));
export const EMRNoSelectedPatientError = new AppException(MAKE_ERROR_CODE(7));
export const EMROAuthFailedError = new AppException(MAKE_ERROR_CODE(8));
export const EMROAuthParamsMissingError = new AppException(MAKE_ERROR_CODE(9));
export const EMRTokenExpiredError = new AppException(MAKE_ERROR_CODE(10));

const EMRDuplicateAccuroDocument = new AppException(MAKE_ERROR_CODE(11));
const EMRInvalidDocumentContent = new AppException(MAKE_ERROR_CODE(12));
const EMRUnsupportedMessageBody = new AppException(MAKE_ERROR_CODE(13));
const EMRUnsupportedDocumentMime = new AppException(MAKE_ERROR_CODE(14));

const createPdfDocumentWithHeader = () =>
  PdfDocument.createWithHeader('This document has been sent using {{PRODUCT_NAME}} powered by {{COMPANY_NAME}}.');

const isSigMailMessageLike = (value: unknown): value is SigMailMessageLike =>
  Utils.isNonArrayObjectLike<SigMailMessageLike>(value) && Utils.isInteger(value.body) && Utils.isInteger(value.header);

const newUUID = uuid.bind(null, { rng: () => getRandomBytes(16) });

class SendDataToEmrAction extends AuthenticatedAction<P, S> {
  /** @override */
  protected async onExecute(): Promise<void> {
    if (Utils.isNil(this.payload.data)) return;

    this.state.dtServer = await this.dispatchFetchServerDateAndTime();

    await this.fetchAuthParams();
    if (this.state.emrCode !== 'accuro' && this.state.emrCode !== 'oscar') {
      throw EMROAuthParamsMissingError;
    }

    await this.checkAuthStatus();
    if (this.state.isAuthenticated !== true) {
      await this.refreshAccessToken();
      await this.checkAuthStatus();
    }

    if (this.state.isAuthenticated !== true) {
      throw EMRTokenExpiredError;
    }

    await this.fetchEmrPatientRecord();
    if (Utils.isNil(this.state.emrPatientRecord)) {
      throw EMRNoMatchingPatientRecordError;
    }

    await this.fetchProviderId();
    if (this.state.emrCode === 'accuro') {
      if (!Utils.isInteger(this.state.emrProviderId)) {
        throw EMRNoAccuroProviderIdError;
      }
    } else if (this.state.emrCode === 'oscar') {
      if (!Utils.isString(this.state.emrProviderId)) {
        throw EMRNoOscarProviderIdError;
      }
    }

    if (this.state.emrCode === 'accuro') {
      let folder: Nullable<S['emrProviderFolder']>;
      try {
        const { onSelectProviderFolder } = this.payload;
        const { clientId, emrAuthParams, emrCode } = this.state;

        const providerId = this.state.emrProviderId as number;

        const providerFolderList = (await this.dispatch(accuroEMRProviderFolderList({ clientId, providerId }))).folders;
        folder = await new Promise<Nullable<Api.AccuroEMRProviderFolderListItem>>((resolve) =>
          onSelectProviderFolder({ emrAuthParams, emrCode, providerFolderList, providerId }, resolve)
        );
      } catch {
        /* ignore */
      }

      if (Utils.isNil(folder)) {
        throw EMRNoSelectedFolderError;
      }

      this.state.emrProviderFolder = folder;
    }

    await this.postData();

    if (Utils.isNotNil(this.state.lastSentToEmrPayload)) {
      try {
        await this.dispatch(
          setLastSentToEMRFlagAction({
            ...this.state.lastSentToEmrPayload,
            logRecord: {
              ...this.state.lastSentToEmrPayload.logRecord,
              to: this.state.emrProviderId
            }
          })
        );
      } catch {
        /* ignore */
      }
    }
  }

  private async fetchAuthParams(): Promise<void> {
    this.logger.info('Fetching group preferences to extract EMR auth params.');

    const { activeGroupId } = this.state;

    try {
      const groupPrefs = await this.getUserObjectValue(selectGroupPreferences, {
        claims: EMPTY_ARRAY,
        fetch: true,
        type: process.env.GROUP_OBJECT_TYPE_PREFERENCES,
        userId: activeGroupId
      });

      if (Utils.isNotNil(groupPrefs?.integrations)) {
        const { accuroEMR, oscar } = groupPrefs!.integrations!;

        if (Utils.isNotNil(accuroEMR) && Utils.isNonArrayObjectLike(accuroEMR?.oauthParams)) {
          this.logger.info('Accuro EMR configuration found in group preferences.');

          const oauthParams: Api.AccuroEMROAuthParams = {
            baseUri: accuroEMR.oauthParams!.uriBase,
            clientId: accuroEMR.oauthParams!.clientId,
            clientSecret: accuroEMR.oauthParams!.clientSecret,
            tokenExpiry: accuroTokenExpiryToDays(accuroEMR.oauthParams!.tokenExpiry),
            uuid: accuroEMR.oauthParams!.uuid
          };

          this.state.emrAuthParams = oauthParams;
          this.state.emrCode = 'accuro';
        } else if (Utils.isNotNil(oscar) && Utils.isNonArrayObjectLike(oscar.oauthParams)) {
          this.logger.info('Oscar EMR configuration found in group preferences.');

          const oauthParams: Api.OscarEMROAuthParams = {
            authorizationUri: oscar.oauthParams!.uriAuthorize,
            clientKey: oscar.oauthParams!.clientKey,
            clientSecret: oscar.oauthParams!.clientSecret,
            name: oscar.oauthParams!.clientName,
            temporaryCredentialUri: oscar.oauthParams!.uriInitRequest,
            tokenUri: oscar.oauthParams!.uriGetToken
          };

          this.state.emrAuthParams = oauthParams;
          this.state.emrCode = 'oscar';
        }
      }
    } catch {
      /* ignore */
    }
  }

  private async checkAuthStatus(): Promise<void> {
    this.logger.info('Checking EMR authentication status.');

    const { clientId, currentUser, emrAuthParams, emrCode } = this.state;

    let isAuthenticated = false;
    try {
      if (emrCode === 'accuro') {
        isAuthenticated = await this.dispatch(accuroEMRCheckAuthStatus({ clientId }));
      } else if (emrCode === 'oscar') {
        isAuthenticated = await this.dispatch(
          oscarEMRCheckAuthStatus({
            oauthParams: emrAuthParams as Api.OscarEMROAuthParams,
            userId: currentUser.id
          })
        );
      }
    } catch {
      /* ignore */
    }
    this.state.isAuthenticated = isAuthenticated;
  }

  private async refreshAccessToken(): Promise<void> {
    const { onRefreshAccessToken } = this.payload;
    if (typeof onRefreshAccessToken !== 'function') return;

    this.logger.info('Invoking provided callback to refresh access token.');

    const { clientId, currentUser, emrAuthParams, emrCode } = this.state;

    try {
      let authUrl = '';

      if (emrCode === 'accuro') {
        authUrl = await this.dispatch(
          accuroEMRGetOAuthUrl({
            clientId,
            oauthParams: emrAuthParams as Api.AccuroEMROAuthParams
          })
        );
      } else if (emrCode === 'oscar') {
        authUrl = await this.dispatch(
          oscarEMRGetOAuthUrl({
            oauthParams: emrAuthParams as Api.OscarEMROAuthParams,
            userId: currentUser.id
          })
        );
      }

      if (authUrl.length > 0) {
        await new Promise<void>((resolve) => onRefreshAccessToken({ emrAuthParams, emrCode, authUrl }, resolve));
      }
    } catch {
      /* ignore */
    }
  }

  private async fetchEmrPatientRecord(): Promise<void> {
    const { data, onSelectPatientRecord } = this.payload;
    const { clientId, currentUser, emrAuthParams, emrCode, roleAuthClaim: authState } = this.state;

    let guestUser: Partial<NonNullable<MessageHeader['guestUser']>> | undefined;
    let { guestUserId } = this.payload;
    const isSigMailMessage = isSigMailMessageLike(data);

    let msgBody: DataObjectMsgBodyValue | undefined;
    let msgMetadata: DataObjectMsgMetadataValue | undefined;
    if (isSigMailMessage) {
      const { body: msgBodyId, header: msgMetadataId } = data;
      this.logger.info(`Fetching message objects. (metadataId=${msgMetadataId}, bodyID=${msgBodyId})`);

      try {
        await this.dispatchFetchObjects({
          authState,
          dataObjects: { ids: [msgBodyId, msgMetadataId] }
        });
      } catch {
        /* ignore */
      }

      const selectDataObject = dataObjectByIdSelector(this.getRootState());
      msgBody = DataObjectCache.getValue(selectDataObject<DataObjectMsgBodyValue>(msgBodyId));
      msgMetadata = DataObjectCache.getValue(selectDataObject<DataObjectMsgMetadataValue>(msgMetadataId));
    }

    if (Utils.isNotNil(guestUserId)) {
      const [profileBasic, profileProtected] = await this.fetchGuestUserProfile(guestUserId);
      if (Utils.isNil(profileBasic) || Utils.isNil(profileProtected)) {
        throw EMRGuestProfileLoadFailedError;
      }

      guestUser = this.createGuestUserFromProfile(guestUserId, profileBasic, profileProtected);
    } else if (isSigMailMessage && Utils.isNotNil(msgMetadata)) {
      guestUser = await (Utils.isNotNil(msgMetadata.guestUser)
        ? this.createGuestUserFromMetadataGuest(msgMetadata.guestUser)
        : this.createGuestUserFromMessage(msgMetadata, msgBody));
    }

    if (isSigMailMessage && Utils.isString(this.payload.folderKey)) {
      this.state.lastSentToEmrPayload = {
        folderKey: this.payload.folderKey,
        logRecord: {
          emr: this.state.emrCode,
          guest: {
            dob: guestUser?.birthDate,
            fn: guestUser?.firstName,
            hpj: guestUser?.healthPlan?.jurisdiction,
            hpn: guestUser?.healthPlan?.number,
            id: guestUser?.id,
            ln: guestUser?.lastName,
            ph: guestUser?.contact?.value,
            sex: guestUser?.gender
          },
          src: [data.header, data.body, 0 /* folder ID will be patched later in setLastSentToEMRFlagAction */],
          srcForm: Utils.isReferralMessageForm(data.messageForm) ? Constants.MessageFormName.Referral : Constants.MessageFormName.Default,
          to: '' /* provider ID will be patched later before dispatching setLastSentToEMRFlagAction */
        },
        msgMetadataId: data.header,
        parentFolderKey: this.payload.parentFolderKey
      };
    }

    let recordList: ReadonlyArray<EMRPatientRecord> = EMPTY_ARRAY;
    try {
      const usedUuidSet = new Set<string>();
      const getUUID = (): string => {
        let id: string;
        // prettier-ignore
        do { id = newUUID(); } while (usedUuidSet.has(id));
        usedUuidSet.add(id);
        return id;
      };

      if (emrCode === 'accuro') {
        const hpn = Utils.trimOrDefault(guestUser?.healthPlan?.number).replace(/\D/g, '');
        this.logger.info(`Initiating patient record search in Accuro EMR for health plan number "${hpn}".`);

        recordList = await this.dispatch(accuroEMRSearchPatient({ clientId, hpn })).then((list) =>
          list.map<EMRPatientRecord>((record) => ({
            record: { ...record, uuid: getUUID() },
            source: 'accuroEMR'
          }))
        );
      } else if (emrCode === 'oscar') {
        let dob = '';

        if (Utils.isString(guestUser?.birthDate)) {
          const dtBirth = new Date(guestUser!.birthDate!);
          dob = `${dtBirth.getFullYear()}-${(dtBirth.getMonth() + 1).toString(10).padStart(2, '0')}-${dtBirth
            .getDate()
            .toString(10)
            .padStart(2, '0')}`;
        }
        const lastName = Utils.trimOrDefault(guestUser?.lastName);

        this.logger.info(`Initiating patient record search in Oscar EMR for birth date "${dob}" and last name "${lastName}".`);

        recordList = await this.dispatch(
          oscarEMRSearchPatient({
            dob,
            hcn: undefined!,
            lastName,
            oauthParams: emrAuthParams as Api.OscarEMROAuthParams,
            userId: currentUser.id
          })
        ).then((list) =>
          list.map<EMRPatientRecord>((record) => ({
            record: toAccuroEMRPatientRecord(record, getUUID()) as EMRPatientRecord['record'],
            source: 'oscarEMR'
          }))
        );
      }

      let { length: N } = recordList;
      if (N === 0 && emrCode === 'accuro') {
        recordList = [{ record: { uuid: newUUID() }, source: 'accuroEMR' }];
        N = 1;
      }

      if (N === 1) {
        this.state.emrPatientRecord = recordList[0];
      } else if (N > 1) {
        const emrPatientRecord = await new Promise<Nullable<EMRPatientRecord>>((resolve) =>
          onSelectPatientRecord({ emrAuthParams, emrCode, patientRecordList: recordList }, resolve)
        );

        if (Utils.isNotNil(emrPatientRecord)) {
          this.state.emrPatientRecord = emrPatientRecord;
        }
      }
    } catch (error) {
      if (error === EMRNoSelectedPatientError) {
        throw error;
      }
      /* ignore */
    }
  }

  private async fetchGuestUserProfile(
    userId: SigmailUserId
  ): Promise<[UserObjectProfileBasicValue | undefined, UserObjectProfileProtectedValue | undefined]> {
    this.logger.info(`Fetching patient's basic and protected profile objects. <userID=${userId}>`);

    try {
      await this.dispatchFetchObjects({
        authState: this.state.roleAuthClaim,
        userObjectsByType: [
          { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId },
          { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED, userId }
        ]
      });
    } catch {
      /* ignore */
    }

    return [
      UserObjectCache.getValue(selectUserProfileBasic(this.getRootState())(userId)),
      UserObjectCache.getValue(selectUserProfileProtected(this.getRootState())(userId))
    ];
  }

  private createGuestUserFromProfile(
    userId: SigmailUserId | undefined,
    profileBasic: Partial<Omit<UserObjectProfileBasicValue, keyof ValueFormatVersion>> | undefined,
    profileProtected: Partial<Omit<UserObjectProfileProtectedValue, keyof ValueFormatVersion>> | undefined
  ): Partial<NonNullable<MessageHeader['guestUser']>> {
    profileBasic = (Utils.isNil(profileBasic) ? EMPTY_PLAIN_OBJECT : profileBasic) as NonNullable<typeof profileBasic>;
    profileProtected = (Utils.isNil(profileProtected) ? EMPTY_PLAIN_OBJECT : profileProtected) as NonNullable<typeof profileProtected>;

    return {
      birthDate: profileProtected?.birthDate,
      contact: {
        value: Utils.stringOrDefault(profileBasic.cellNumber, profileBasic.homeNumber)
      },
      firstName: profileBasic.firstName,
      gender: profileProtected.gender,
      healthPlan: {
        jurisdiction: profileProtected.healthPlanJurisdiction,
        number: profileProtected.healthCardNumber!
      },
      lastName: profileBasic.lastName
    };
  }

  private async createGuestUserFromMetadataGuest(
    metadataGuest: NonNullable<MessageHeader['guestUser']>
  ): Promise<Partial<NonNullable<MessageHeader['guestUser']>> | undefined> {
    let profileBasic: UserObjectProfileBasicValue | undefined;
    let profileProtected: UserObjectProfileProtectedValue | undefined;
    if (AppUser.isValidId(metadataGuest.id)) {
      [profileBasic, profileProtected] = await this.fetchGuestUserProfile(metadataGuest.id);
    }

    return this.createGuestUserFromProfile(
      metadataGuest.id,
      {
        ...profileBasic,
        cellNumber: Utils.stringOrDefault(
          Utils.stringOrDefault(profileBasic?.cellNumber, profileBasic?.homeNumber),
          metadataGuest.contact?.value
        ),
        firstName: Utils.stringOrDefault(profileBasic?.firstName, metadataGuest.firstName),
        lastName: Utils.stringOrDefault(profileBasic?.lastName, metadataGuest.lastName)
      },
      {
        ...profileProtected,
        birthDate: Utils.stringOrDefault(profileProtected?.birthDate, metadataGuest.birthDate),
        gender: Utils.stringOrDefault(profileProtected?.gender, metadataGuest.gender),
        healthCardNumber: Utils.stringOrDefault(profileProtected?.healthCardNumber, metadataGuest.healthPlan.number),
        healthPlanJurisdiction: Utils.stringOrDefault(profileProtected?.healthPlanJurisdiction, metadataGuest.healthPlan.jurisdiction)
      }
    );
  }

  private async createGuestUserFromMessage(
    msgMetadata: DataObjectMsgMetadataValue,
    msgBody?: DataObjectMsgBodyValue
  ): Promise<Partial<NonNullable<MessageHeader['guestUser']>> | undefined> {
    try {
      const {
        isMessageFormDefault,
        isMessageFormEConsult,
        isMessageFormEvent,
        isMessageFormHRM,
        isMessageFormHealthDataRequest,
        isMessageFormReferral
      } = MessageFlags(msgMetadata);

      if (isMessageFormEConsult) {
        const patient = (msgBody as ReadonlyMessageBodyConsultation | undefined)?.messageForm.value.patient;
        return this.createGuestUserFromProfile(
          undefined,
          { ...patient, cellNumber: patient?.contactNumber },
          { ...patient, gender: patient?.gender as GenderIdentity, healthCardNumber: patient?.healthPlanNumber }
        );
      }

      if (isMessageFormHRM) {
        const patient = (msgBody as ReadonlyMessageBodyHrm | undefined)?.messageForm.value.patient;
        return this.createGuestUserFromProfile(
          undefined,
          {
            ...patient,
            cellNumber: patient?.telecom?.[0]?.phoneNumber,
            firstName: patient?.name?.firstName,
            lastName: patient?.name?.lastName
          },
          { ...patient, healthCardNumber: patient?.hcnNumber }
        );
      }

      let guestUserId: SigmailUserId | undefined;
      if (isMessageFormDefault) {
        guestUserId = msgMetadata.sender.id;
        if (Utils.isNotNil(msgMetadata.sentOnBehalfBy)) {
          guestUserId = msgMetadata.sentOnBehalfBy.id;
        }
      } else if (isMessageFormEvent) {
        guestUserId = msgMetadata.recipientList.find(({ entity }) => entity.type === 'user' && Utils.isGuestRole(entity.role))?.entity.id!;
      } else if (isMessageFormHealthDataRequest) {
        guestUserId = msgMetadata.recipientList[0].entity.id;
      } else if (isMessageFormReferral) {
        guestUserId = (msgBody as ReadonlyMessageBodyReferral).messageForm.value.patient.id;
      }

      if (AppUser.isValidId(guestUserId)) {
        const [profileBasic, profileProtected] = await this.fetchGuestUserProfile(guestUserId);
        if (Utils.isNil(profileBasic) || Utils.isNil(profileProtected)) {
          throw EMRGuestProfileLoadFailedError;
        }

        return this.createGuestUserFromProfile(guestUserId, profileBasic, profileProtected);
      }
    } catch (error) {
      if (error === EMRGuestProfileLoadFailedError) {
        throw error;
      }
      /* ignore */
    }
  }

  private async fetchProviderId(): Promise<void> {
    this.logger.info('Fetching EMR provider ID.');

    try {
      const { currentUser, emrAuthParams, emrCode } = this.state;

      if (emrCode === 'accuro') {
        const protectedProfile = await this.getUserObjectValue(selectUserProfileProtected, {
          fetch: true,
          type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
          userId: currentUser.id
        });

        this.state.emrProviderId = protectedProfile?.accuroProviderId!;
      } else if (emrCode === 'oscar') {
        this.state.emrProviderId = await this.dispatch(
          oscarEMRGetProviderNumber({
            oauthParams: emrAuthParams as Api.OscarEMROAuthParams,
            userId: currentUser.id
          })
        );
      }
    } catch {
      /* ignore */
    }
  }

  private async postData(): Promise<void> {
    this.state.uploadStatus = [];

    try {
      const { data } = this.payload;
      if (isSigMailMessageLike(data)) {
        const { isMessageFormDefault, isMessageFormEConsult, isMessageFormHRM, isMessageFormReferral } = MessageFlags(data);
        if (isMessageFormEConsult || isMessageFormHRM || isMessageFormReferral) {
          // mark upload status of message body to be successful for consultation,
          // HRM, and referral messages without actually POSTing anything to EMR
          this.updateUploadStatus(undefined, false);

          // we will instead create and POST a PDF containing a combination of
          // message body and any documents attached to the message
          let document = await this.createSigMailMessagePdf(data);
          const timestamp = this.state.dtServer.getTime();

          if (isMessageFormEConsult) {
            document = {
              ...document,
              description: `[SigMail] Consultation PDF Combined ${timestamp}`,
              fileName: `SigMail_Consultation_pdf_combined_${timestamp}.pdf`
            };
          } else if (isMessageFormHRM) {
            document = {
              ...document,
              description: `[SigMail] HRM PDF Combined ${timestamp}`,
              fileName: `SigMail_HRM_pdf_combined_${timestamp}.pdf`
            };
          } else if (isMessageFormReferral) {
            document = {
              ...document,
              description: `[SigMail] Referral PDF Combined ${timestamp}`,
              fileName: `SigMail_Referral_pdf_combined_${timestamp}.pdf`
            };
          }

          await this.postDocument(document);
          this.updateUploadStatus(undefined, true);
        } else {
          const documentList = Utils.arrayOrDefault(data.documentList, EMPTY_ARRAY);
          let uploadError: unknown;

          try {
            if (isMessageFormDefault) {
              await this.postMessageBody(data);
            } else {
              throw EMRUnsupportedMessageBody;
            }
          } catch (error) {
            uploadError = error;
          }

          this.updateUploadStatus(uploadError, documentList.length === 0);

          for (let index = 0; index < documentList.length; index++) {
            try {
              uploadError = undefined;
              await this.postMessageAttachment(data, index);
            } catch (error) {
              uploadError = error;
            }
            this.updateUploadStatus(uploadError, index === documentList.length - 1);
          }
        }

        return;
      }

      const { content, fileName, mime } = data!;
      await this.postDocument({ content, fileName, mime });
      return this.updateUploadStatus(undefined, true);
    } catch (error) {
      this.updateUploadStatus(error, true);
    }
  }

  private async createSigMailMessagePdf(message: SigMailMessageLike): Promise<EMRDocument> {
    const { t } = this.payload;
    const timestamp = this.state.dtServer.getTime();

    const pdfDocument = createPdfDocumentWithHeader();
    await pdfDocument.addSigMailMessage(message, { t });
    const content = await pdfDocument.getBase64();

    return {
      content,
      description: `[SigMail] PDF Combined ${timestamp}`,
      fileName: `SigMail_pdf_combined_${timestamp}.pdf`,
      mime: Constants.MimeType.PDF
    };
  }

  private async postMessageBody(message: SigMailMessageLike): Promise<void> {
    this.logger.info(`Sending message body to EMR. <bodyID=${message.body}>`);

    const { emrCode } = this.state;

    const selectDataObject = dataObjectByIdSelector(this.getRootState());
    const msgBody = DataObjectCache.getValue(selectDataObject<DataObjectMsgBodyValue>(message.body));

    let document: EMRDocument = { content: '', fileName: '', mime: '' };
    const content = Utils.trimOrDefault((msgBody as ReadonlyMessageBodyDefault | undefined)?.data);
    if (content.length > 0) {
      let subjectLine = Utils.trimOrDefault(message.subject).replace(/^[.\s]+/u, '');
      if (subjectLine.length === 0) subjectLine = `SigMail_message_${message.body}`;

      if (emrCode === 'accuro') {
        const pdfDocument = createPdfDocumentWithHeader();
        await pdfDocument.addHtml(content);

        document = {
          content: await pdfDocument.getBase64(),
          description: subjectLine,
          fileName: `${subjectLine}.pdf`,
          mime: Constants.MimeType.PDF
        };
      } else if (emrCode === 'oscar') {
        document = {
          content: window.btoa(content),
          description: subjectLine,
          fileName: `${subjectLine}.html`,
          mime: Constants.MimeType.TEXT_HTML
        };
      }
    }

    return this.postDocument(document);
  }

  private async postMessageAttachment(message: SigMailMessageLike, index: number): Promise<void> {
    const { accessToken, roleAuthClaim: authState } = this.state;

    const attachment = message.documentList![index]!;

    const { dataObjectList } = await this.fetchObjects(accessToken, {
      authState,
      dataObjects: { ids: [attachment.body] }
    });

    const docBodyObject = new DataObjectDocBody(dataObjectList.find(({ id }) => id === attachment.body)!);
    const docBody = await docBodyObject.decryptedValue();
    const dataUri = parseDataUri(docBody.data);
    if (!dataUri.isValid) throw EMRInvalidDocumentContent;

    const document: EMRDocument = { content: dataUri.data, fileName: attachment.name, mime: dataUri.contentType };
    return this.postDocument(document);
  }

  private async postDocumentToAccuroEmr({ content, description, fileName, mime }: EMRDocument): Promise<void> {
    this.logger.info('Sending a document to Accuro EMR.');

    const { clientId, emrPatientRecord, emrProviderFolder, emrProviderId: fromId } = this.state;

    const folderId = emrProviderFolder.id;
    const subtypeId = emrProviderFolder.subFolders?.[0]?.id;

    const isDocumentTypeText = mime === Constants.MimeType.TEXT_PLAIN;
    const isDocumentTypePNG = !isDocumentTypeText && mime === Constants.MimeType.PNG;
    if (isDocumentTypeText || isDocumentTypePNG) {
      const pdfDocument = createPdfDocumentWithHeader();
      if (isDocumentTypeText) {
        await pdfDocument.addText(window.atob(content));
      } else {
        await pdfDocument.addImage(`data:${Constants.MimeType.PNG};base64,${content}`);
      }

      let index = fileName.lastIndexOf('.');
      if (index === -1) index = fileName.length;

      content = await pdfDocument.getBase64();
      fileName = `${fileName.slice(0, index)}.pdf`;
      mime = Constants.MimeType.PDF;
    }

    const requestBody: ApiActionPayload.AccuroEMRAddDocument = {
      clientId,
      document: {
        content,
        description: `[SigMail] ${Utils.trimOrDefault(description, fileName)}`.slice(0, 500),
        fileName: sanitizeFilename(fileName, '_', 250),
        fileType: mimeToAccuroDocumentFileType(mime)!,
        folderId,
        fromId: fromId as number,
        fromType: 'Physician',
        priority: 1,
        subtypeId
      },
      patientId: emrPatientRecord.record.patientId!
    };

    try {
      await this.dispatch(accuroEMRAddDocument(requestBody));
    } catch (error) {
      if (error instanceof Api.ServiceException && error.response.status === 400) {
        const errorResponse = await error.response.clone().json();
        if (
          Utils.isNonArrayObjectLike<{ code: string }>(errorResponse) &&
          errorResponse.code === String(ACCURO_EMR_ERROR_CODE_DUPLICATE_DOCUMENT)
        ) {
          throw EMRDuplicateAccuroDocument;
        }
      }
      throw error;
    }
  }

  private async postDocumentToOscarEmr(document: EMRDocument): Promise<void> {
    this.logger.info('Sending a document to Oscar EMR.');

    const { currentUser, dtServer, emrPatientRecord, emrProviderId: providerNo } = this.state;

    const fileName = sanitizeFilename(document.fileName, '_', 250);
    const description = `[SigMail] ${Utils.trimOrDefault(document.description, fileName)}`.slice(0, 500);

    const requestBody: ApiActionPayload.OscarEMRAddDocument = {
      contentType: document.mime as Api.OscarEMRDocumentMimeType,
      demographicNo: emrPatientRecord.record.patientId!,
      description,
      docClass: 'Consultant Report',
      fileContents: document.content,
      fileName,
      observationDate: dtServer.toISOString().slice(0, 10),
      providerNo: providerNo as string,
      type: 'consult',
      userId: currentUser.id
    };

    await this.dispatch(oscarEMRAddDocument(requestBody));
  }

  private async postDocument(document: EMRDocument): Promise<void> {
    this.logger.info('Sending a document to EMR.');

    if (document.content.length === 0) throw EMRInvalidDocumentContent;

    const { emrCode } = this.state;
    const ACCEPTABLE_MIME_LIST = Utils.arrayOrDefault<string>(EMR_MIME_LIST_MAP[emrCode], EMPTY_ARRAY);
    if (!ACCEPTABLE_MIME_LIST.includes(document.mime)) throw EMRUnsupportedDocumentMime;

    if (emrCode === 'accuro') {
      return this.postDocumentToAccuroEmr(document);
    } else if (emrCode === 'oscar') {
      return this.postDocumentToOscarEmr(document);
    }
  }

  private updateUploadStatus(uploadError: unknown, uploadComplete: boolean): void {
    try {
      const { uploadStatus } = this.state;

      let status: S['uploadStatus'][0] = Utils.isUndefined(uploadError);
      if (status === false) {
        if (uploadError === EMRInvalidDocumentContent || uploadError === EMRUnsupportedDocumentMime) {
          status = null;
        } else if (uploadError === EMRDuplicateAccuroDocument) {
          status = ACCURO_EMR_ERROR_CODE_DUPLICATE_DOCUMENT;
        }
      }

      uploadStatus.push(status);

      const { onChangeUploadStatus } = this.payload;
      return void (typeof onChangeUploadStatus === 'function' && onChangeUploadStatus(uploadStatus, uploadComplete));
    } catch {
      /* ignore */
    }
  }
}

const Logger = getLoggerWithPrefix('Action', 'sendDataToEmrAction:');

export const sendDataToEmrAction = (payload: ApiActionPayload.SendDataToEMR): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const action = new SendDataToEmrAction({ apiService, dispatch, getState, logger: Logger, payload });
    return action.execute();
  };
};
