import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogEMRProviderIdMissing } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.EMR.ProviderIdMissing = {
  // Translators: (HTML) Title for the EMR Provider ID Missing dialog
  title: gettext('messaging', 'title', 'dialogEMRProviderIdMissing', 'safe-html'),
  body: {
    // Translators: (HTML) Message to be displayed in the body of EMR Provider \
    // ID Missing dialog when selected EMR is Accuro
    accuro: gettext('messaging', 'bodyAccuroEMR', 'dialogEMRProviderIdMissing', 'safe-html'),
    // Translators: (HTML) Message to be displayed in the body of EMR Provider \
    // ID Missing dialog when selected EMR is Oscar
    oscar: gettext('messaging', 'bodyOscarEMR', 'dialogEMRProviderIdMissing', 'safe-html')
  },
  action: {
    accept: {
      // Translators: Label for the Close action of EMR Provider ID Missing \
      // dialog
      label: gettext('messaging', 'accept', 'dialogEMRProviderIdMissingActionLabel'),
      context: DialogEMRProviderIdMissing
    }
  }
};

export default i18n;
