import { Constants, MessagingException, ReadonlyMessageBodyHealthDataRequest, Utils } from '@sigmail/common';
import {
  DataObjectMsgBodyValue,
  DataObjectMsgMetadataValue,
  GroupObjectServerRights,
  IUserObject,
  NotificationObjectIncomingMessage,
  NotificationObjectIncomingMessageValue,
  UserObjectHealthDataValue,
  UserObjectProfileBasic
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { endOfDay } from 'date-fns';
import { MAX_SAFE_TIMESTAMP } from '../../../../constants';
import { FACILITY_SEND_HEALTH_DATA_REQUEST } from '../../../../constants/error-facility';
import { EMPTY_ARRAY } from '../../../constants';
import { basicProfileObjectSelector, healthDataObjectSelector } from '../../../selectors/user-object';
import { UserObjectCache } from '../../../user-objects-slice/cache';
import { ActionInitParams } from '../../base-action';
import { AUTH_STATE_SEND_HEALTH_DATA_REQUEST_MESSAGE } from '../../constants/auth-state-identifier';
import { newNotificationAction } from '../../notifications/new-notification-action';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';
import { healthDataRequestConflictException } from './utils/health-data-request-conflict-exception';

export class HealthDataRequestConflictException extends MessagingException {
  public constructor(public readonly conflictSource: NonNullable<UserObjectHealthDataValue['requestList']>[0]) {
    super(Utils.MAKE_ERROR_CODE(Constants.Error.SEVERITY_ERROR, FACILITY_SEND_HEALTH_DATA_REQUEST, 1));
  }
}

export interface Payload extends Omit<BaseSendMessagePayload, 'messageBody'> {
  readonly messageBody: ReadonlyMessageBodyHealthDataRequest;
}

export interface State extends BaseSendMessageState {
  groupClaim: string;
  healthDataObject: IUserObject<UserObjectHealthDataValue>;
  healthData: UserObjectHealthDataValue;
}

export class SendHealthDataRequestMessageAction<P extends Payload = Payload, S extends State = State> extends BaseSendMessageAction<P, S> {
  public constructor(params: ActionInitParams<P>) {
    super(params);

    const { recipientList } = params.payload;
    if (recipientList.length !== 1) {
      throw new MessagingException('Invalid payload; expected at most one recipient entry.');
    }
  }

  /** @override */
  protected async resetActionState(): Promise<void> {
    await super.resetActionState();

    await this.fetchGroupClaim();

    const { id: userId } = this.payload.recipientList[0].entity;
    const { roleAuthClaim: authState } = this.state;

    const { claims } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [{ type: process.env.USER_OBJECT_TYPE_HEALTH_DATA, userId }],
      expectedCount: { claims: 1 }
    });

    if (claims.length !== 1) {
      throw new MessagingException(`Expected <claims> to be of size 1; was ${claims.length}.`);
    }

    const healthDataObject = await this.getUserObject(healthDataObjectSelector, { userId });
    const healthData = UserObjectCache.getValue(healthDataObject);
    if (Utils.isNil(healthDataObject) || Utils.isNil(healthData)) {
      throw new MessagingException(`Failed to fetch recipient's health data. <recipientId=${userId}>`);
    }

    this.state.batchUpdateClaims.push(...claims);
    this.state.healthDataObject = healthDataObject;
    this.state.healthData = healthData;

    const { value: msgBody } = this.payload.messageBody.messageForm;
    const currentRequest = {
      ...msgBody,
      until: Utils.numberOrDefault(msgBody.until, Utils.isNil(msgBody.frequency) ? endOfDay(msgBody.start).getTime() : MAX_SAFE_TIMESTAMP)
    };

    healthDataRequestConflictException({
      currentRequest,
      healthData
    });
  }

  /** @override */
  protected async onExecute(...args: any[]) {
    await super.onExecute(...args);

    try {
      await this.scheduleReminderForRecipient();
    } catch (error) {
      this.logger.warn('Error while scheduling a reminder for recipient:', error);
      /* ignore */
    }
  }

  /** @override */
  protected validateMessageFormName(): void {
    const { messageFormName } = this.payload;
    if (!Utils.isMessageFormNameHealthDataRequest(messageFormName)) {
      throw new MessagingException(
        `Expected message form name to be <${Constants.MessageFormName.HealthDataRequest}>; was <${messageFormName}>`
      );
    }
  }

  private async fetchGroupClaim(): Promise<void> {
    const { activeGroupId: groupId, roleAuthClaim: authState } = this.state;

    this.logger.info(`Fetching group claim. <groupId=${groupId}>`);
    const { userObjectList } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [{ type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS, userId: groupId }]
    });

    const json = this.findUserObject(userObjectList, { type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS, userId: groupId });
    if (Utils.isNil(json)) {
      throw new MessagingException('Failed to fetch claim of the active group.');
    } else {
      const serverRightsObject = new GroupObjectServerRights(json);
      const { groupClaim } = await serverRightsObject.decryptedValue();

      this.state.batchUpdateClaims.push(groupClaim);
      this.state.groupClaim = groupClaim;
    }
  }

  /** @override */
  protected async createIdsRequestData() {
    const data = await super.createIdsRequestData();

    const ids = data.ids.ids!.slice();
    const index = ids.findIndex(({ type }) => type === NotificationObjectIncomingMessage.TYPE);

    // request an extra ID for reminder
    ids.splice(index, 1, {
      type: NotificationObjectIncomingMessage.TYPE,
      count: Utils.numberOrDefault(ids[index].count) + 1
    });

    return { ...data, state: AUTH_STATE_SEND_HEALTH_DATA_REQUEST_MESSAGE, ids: { ids } };
  }

  /** @override */
  protected async generateRequestBody() {
    await super.generateRequestBody();

    await this.addUpdateOperationForHealthData(); // 421
  }

  /** @override */
  protected async createMessageMetadataValue(): Promise<DataObjectMsgMetadataValue> {
    const { messageBody } = this.payload;

    const data = await super.createMessageMetadataValue();
    return { ...data, sentAtUtc: messageBody.messageForm.value.start };
  }

  /** @override */
  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    return { $$formatver: 1, ...this.payload.messageBody };
  }

  /** @override */
  protected async createNotificationValue(): Promise<NotificationObjectIncomingMessageValue> {
    const { messageBody } = this.payload;
    const { msgMetadataId: header, msgBodyId: body } = this.state;

    return {
      $$formatver: 1,
      header,
      body,
      messageForm: {
        name: Constants.MessageFormName.HealthDataRequest,
        value: { ts: messageBody.messageForm.value.start }
      }
    };
  }

  private async addUpdateOperationForHealthData(): Promise<void> {
    const { id: guestUserId } = this.payload.recipientList[0].entity;
    const { healthDataObject, healthData, msgMetadataId: requestId, requestBody, successPayload } = this.state;

    if (!Utils.isInteger(healthData.$index[requestId])) {
      this.logger.info(`Adding an update operation to request body for recipient's health data. <recipientId=${guestUserId}>`);

      const { form, frequency, note, start, until, weekdays } = this.payload.messageBody.messageForm.value;
      const updatedRequestList = Utils.arrayOrDefault<NonNullable<typeof healthData.requestList>[0]>(healthData.requestList).concat({
        form,
        frequency,
        note,
        start,
        until,
        weekdays
      });
      const indexOfRequestEntry = updatedRequestList.length - 1;
      const updatedValue: typeof healthData = {
        ...healthData,
        $index: {
          ...healthData.$index,
          [requestId]: [indexOfRequestEntry, EMPTY_ARRAY]
        },
        requestList: updatedRequestList
      };

      const updatedObject = await healthDataObject.updateValue(updatedValue);
      requestBody.update(updatedObject);

      successPayload.request.userObjects!.ids.push(updatedObject.id);
      successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
    }
  }

  /** @override */
  protected async sendNewMessageNotifications() {
    const { start: ts } = this.payload.messageBody.messageForm.value;
    const { dtServer } = this.state;

    return dtServer.getTime() < ts ? EMPTY_ARRAY : super.sendNewMessageNotifications();
  }

  private async scheduleReminderForRecipient(): Promise<void> {
    const { value: msgBody } = this.payload.messageBody.messageForm;
    const { entity: guestUser } = this.payload.recipientList[0];
    const { groupClaim } = this.state;

    const basicProfile = await this.getUserObjectValue(basicProfileObjectSelector, {
      claims: [groupClaim],
      fetch: true,
      type: UserObjectProfileBasic.TYPE,
      userId: guestUser.id
    });

    const cellNumber = Utils.stringOrDefault(basicProfile?.cellNumber);
    const emailAddress = Utils.trimOrDefault(basicProfile?.emailAddress, Utils.stringOrDefault(guestUser.emailAddress));
    if (cellNumber.length === 0 && emailAddress.length === 0) {
      this.logger.warn("Neither guest user's cell number nor email address is available to set a reminder.");
      return;
    }

    const idRecord = this.state.idRecord[NotificationObjectIncomingMessage.TYPE];
    const reminder: Api.NewNotificationRequestData['notificationList'][0] = {
      countryCode: null,
      emailAddress: emailAddress.length === 0 ? null : emailAddress,
      id: idRecord[idRecord.length - 1],
      message:
        "This is an automated reminder for a health data submission requested by your healthcare provider. Please sign in to SigMail and submit if you haven't already.",
      notifyAt: new Date(msgBody.start).toISOString(),
      phoneNumber: cellNumber.length === 0 ? null : cellNumber,
      subjectLine: 'SigMail health data submission reminder'
    };

    await this.dispatch(newNotificationAction({ notificationList: [reminder] }));
  }
}
