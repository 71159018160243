import { Utils } from '@sigmail/common';
import React from 'react';
import { MutableRef } from 'sigmail';
import { Track } from 'twilio-video';
import { IVideoTrack } from './types';
import style from './video-track.module.css';

type HtmlVideoElementProps = JSX.IntrinsicElements['video'];

export interface Props extends Omit<HtmlVideoElementProps, 'ref' | 'children'> {
  children?: never;
  innerRef?: MutableRef<HTMLVideoElement>;
  track: IVideoTrack;
  priority?: Track.Priority | null | undefined;
}

function attachElement(el: HTMLVideoElement, track: IVideoTrack, priority?: Props['priority']): void {
  track.attach(el);
  el.muted = true;

  if (!Utils.isUndefined(priority)) {
    const { setPriority } = track;
    if (typeof setPriority === 'function') {
      setPriority.call(track, priority);
    }
  }
}

function detachElement(el: HTMLVideoElement, track: IVideoTrack): void {
  track.detach(el);
  el.srcObject = null;

  const { setPriority } = track;
  if (typeof setPriority === 'function') {
    setPriority.call(track, null);
  }
}

class VideoTrackComponent extends React.PureComponent<Props> {
  private ref: React.MutableRefObject<HTMLVideoElement | null> = { current: null };

  public constructor(props: Props) {
    super(props);

    this.componentDidUpdate = Utils.noop;
    this.setRef = this.setRef.bind(this);
  }

  public getSnapshotBeforeUpdate(prevProps: Readonly<Props>): any {
    const { track, priority } = this.props;
    const { track: prevTrack } = prevProps;

    const { current: el } = this.ref;
    if (prevTrack !== track && el !== null) {
      detachElement(el, prevTrack);
      attachElement(el, track, priority);
    }

    return null;
  }

  public componentWillUnmount(): void {
    this.setRef(null);
  }

  public render(): React.ReactNode {
    const { children, innerRef, priority, track, ...props } = this.props;

    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <video ref={this.setRef} {...props} />;
  }

  protected setRef(instance: HTMLVideoElement | null): void {
    const { track, priority, innerRef } = this.props;
    const { current: prevInstance } = this.ref;

    this.ref.current = instance;

    if (instance === null) {
      if (prevInstance !== null) {
        detachElement(prevInstance, track);
      }
    } else if (instance !== prevInstance) {
      attachElement(instance, track, priority);
    }

    if (typeof innerRef === 'function') {
      innerRef(instance);
    } else if (typeof innerRef === 'object' && innerRef != null) {
      innerRef.current = instance;
    }
  }
}

export const VideoTrack = React.forwardRef<HTMLVideoElement, Props>((props, ref) => (
  <VideoTrackComponent innerRef={ref} {...props} />
));

VideoTrack.displayName = 'VideoTrack';

VideoTrack.defaultProps = {
  className: style.root
};
