import { Constants, ReadonlyPartialRecord, Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';

const MIME_TO_FILE_TYPE_MAP: ReadonlyPartialRecord<string, Api.AccuroEMRDocumentFileType> = {
  [Constants.MimeType.GIF]: 'gif',
  [Constants.MimeType.JPEG]: 'jpg',
  [Constants.MimeType.MS_EXCEL_XLS]: 'xls',
  [Constants.MimeType.MS_WORD_DOC]: 'doc',
  [Constants.MimeType.PDF]: 'pdf',
  [Constants.MimeType.TEXT_HTML]: 'html'
};

export const mimeToDocumentFileType = (mime?: unknown): Api.AccuroEMRDocumentFileType | null =>
  Utils.stringOrDefault<Api.AccuroEMRDocumentFileType>(MIME_TO_FILE_TYPE_MAP[Utils.stringOrDefault(mime)], null!);
