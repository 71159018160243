import type { SigmailObjectTypeCode } from '@sigmail/common';
import { Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectScheduleValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_SCHEDULE;

/** @public */
export class UserObjectSchedule extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return Utils.isNonArrayObjectLike(dv.workSchedule);
  }

  public override updateValue(newValue: DV): Promise<UserObjectSchedule> {
    const Class = this.constructor as typeof UserObjectSchedule;
    return Class.updateValue(this, newValue);
  }
}
