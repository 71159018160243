import { ApiActionPayload } from '@sigmail/app-state';
import { AppException, Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedCollectionObject,
  ApiFormattedDataObject,
  ApiFormattedUserObject,
  ClientObjectCollectionList,
  ClientObjectConfiguration,
  ClientObjectContactList,
  ClientObjectProfile,
  ClientObjectUserList,
  CollectionObject,
  CollectionObjectPatientRecordList,
  DataObject,
  DataObjectCalendarEvent,
  DataObjectDocBody,
  DataObjectDocFolder,
  DataObjectDocMetadata,
  DataObjectEncounterMetadata,
  DataObjectEventLog,
  DataObjectHrmUserList,
  DataObjectMsgBody,
  DataObjectMsgFolder,
  DataObjectMsgFolderExt,
  DataObjectMsgMetadata,
  DataObjectMsgReadReceipt,
  DataObjectSigmailGlobalContactList,
  GroupObjectAccessRights,
  GroupObjectContactInfo,
  GroupObjectFolderList,
  GroupObjectGuestList,
  GroupObjectPreferences,
  GroupObjectProfileBasic,
  GroupObjectServerRights,
  ICollectionObject,
  IDataObject,
  IUserObject,
  UserObject,
  UserObjectAccessRights,
  UserObjectCarePlans,
  UserObjectCircleOfCare,
  UserObjectContactInfo,
  UserObjectContactList,
  UserObjectEConsult,
  UserObjectEncounter,
  UserObjectEventLog,
  UserObjectFolderList,
  UserObjectHealthData,
  UserObjectPreferences,
  UserObjectProfileBasic,
  UserObjectProfilePrivate,
  UserObjectProfileProtected,
  UserObjectRegistrationDetails,
  UserObjectSchedule,
  UserObjectServerRights,
  ValueFormatVersion
} from '@sigmail/objects';
import Immutable from 'immutable';
import { AppThunk } from '..';
import { setState as setCollectionObjectsState } from '../collection-objects-slice';
import { CollectionObjectCache as GlobalCollectionObjectCache } from '../collection-objects-slice/cache';
import { EMPTY_PLAIN_OBJECT } from '../constants';
import { setState as setDataObjectsState } from '../data-objects-slice';
import { DataObjectCache as GlobalDataObjectCache } from '../data-objects-slice/cache';
import { setState as setUserObjectsState } from '../user-objects-slice';
import { UserObjectCache as GlobalUserObjectCache } from '../user-objects-slice/cache';

const GlobalCache: Readonly<Required<ApiActionPayload.BatchQueryDataSuccess['cache']>> = {
  CollectionObjectCache: GlobalCollectionObjectCache,
  DataObjectCache: GlobalDataObjectCache,
  UserObjectCache: GlobalUserObjectCache
};

const COLLECTION_OBJECT_TYPE_TO_CLASS_MAP: {
  [typeCode: number]: new <DV extends ValueFormatVersion>(obj: ApiFormattedCollectionObject) => CollectionObject<DV>;
} = {
  [process.env.COLLECTION_OBJECT_TYPE_PATIENT_RECORD_LIST]: CollectionObjectPatientRecordList
};

const DATA_OBJECT_TYPE_TO_CLASS_MAP: {
  [typeCode: number]: new <DV extends ValueFormatVersion>(obj: ApiFormattedDataObject) => DataObject<DV>;
} = {
  [process.env.DATA_OBJECT_TYPE_DOC_BODY]: DataObjectDocBody,
  [process.env.DATA_OBJECT_TYPE_DOC_FOLDER]: DataObjectDocFolder,
  [process.env.DATA_OBJECT_TYPE_DOC_METADATA]: DataObjectDocMetadata,
  [process.env.DATA_OBJECT_TYPE_MSG_BODY]: DataObjectMsgBody,
  [process.env.DATA_OBJECT_TYPE_MSG_FOLDER]: DataObjectMsgFolder,
  [process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT]: DataObjectMsgFolderExt,
  [process.env.DATA_OBJECT_TYPE_MSG_METADATA]: DataObjectMsgMetadata,
  [process.env.DATA_OBJECT_TYPE_MSG_READ_RECEIPT]: DataObjectMsgReadReceipt,
  [process.env.DATA_OBJECT_TYPE_ENCOUNTER_METADATA]: DataObjectEncounterMetadata,
  [process.env.DATA_OBJECT_TYPE_CALENDAR_EVENT]: DataObjectCalendarEvent,
  [process.env.DATA_OBJECT_TYPE_EVENT_LOG]: DataObjectEventLog,
  [process.env.DATA_OBJECT_TYPE_HRM_USER_LIST]: DataObjectHrmUserList,
  [process.env.DATA_OBJECT_TYPE_SIGMAIL_GLOBAL_CONTACT_LIST]: DataObjectSigmailGlobalContactList
};

const USER_OBJECT_TYPE_TO_CLASS_MAP: {
  [typeCode: number]: new <DV extends ValueFormatVersion>(obj: ApiFormattedUserObject) => UserObject<DV>;
} = {
  [process.env.USER_OBJECT_TYPE_PROFILE_BASIC]: UserObjectProfileBasic,
  [process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED]: UserObjectProfileProtected,
  [process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE]: UserObjectProfilePrivate,
  [process.env.USER_OBJECT_TYPE_FOLDER_LIST]: UserObjectFolderList,
  [process.env.USER_OBJECT_TYPE_CONTACT_INFO]: UserObjectContactInfo,
  [process.env.USER_OBJECT_TYPE_CONTACT_LIST]: UserObjectContactList,
  // [process.env.USER_OBJECT_TYPE_GUEST_DATA]: UserObjectGuestData,
  [process.env.USER_OBJECT_TYPE_PREFERENCES]: UserObjectPreferences,
  [process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS]: UserObjectAccessRights,
  [process.env.USER_OBJECT_TYPE_SERVER_RIGHTS]: UserObjectServerRights,
  [process.env.USER_OBJECT_TYPE_SCHEDULE]: UserObjectSchedule,
  [process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE]: UserObjectCircleOfCare,
  [process.env.USER_OBJECT_TYPE_REGISTRATION_DETAILS]: UserObjectRegistrationDetails,
  [process.env.USER_OBJECT_TYPE_CARE_PLANS]: UserObjectCarePlans,
  [process.env.USER_OBJECT_TYPE_CONSULTATION]: UserObjectEConsult,
  [process.env.USER_OBJECT_TYPE_ENCOUNTER]: UserObjectEncounter,
  [process.env.USER_OBJECT_TYPE_EVENT_LOG]: UserObjectEventLog,
  [process.env.USER_OBJECT_TYPE_HEALTH_DATA]: UserObjectHealthData,

  [process.env.CLIENT_OBJECT_TYPE_PROFILE]: ClientObjectProfile,
  [process.env.CLIENT_OBJECT_TYPE_CONFIGURATION]: ClientObjectConfiguration,
  [process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST]: ClientObjectContactList,
  [process.env.CLIENT_OBJECT_TYPE_USER_LIST]: ClientObjectUserList,
  [process.env.CLIENT_OBJECT_TYPE_COLLECTION_LIST]: ClientObjectCollectionList,

  [process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC]: GroupObjectProfileBasic,
  [process.env.GROUP_OBJECT_TYPE_FOLDER_LIST]: GroupObjectFolderList,
  [process.env.GROUP_OBJECT_TYPE_CONTACT_INFO]: GroupObjectContactInfo,
  [process.env.GROUP_OBJECT_TYPE_PREFERENCES]: GroupObjectPreferences,
  [process.env.GROUP_OBJECT_TYPE_ACCESS_RIGHTS]: GroupObjectAccessRights,
  [process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS]: GroupObjectServerRights,
  [process.env.GROUP_OBJECT_TYPE_GUEST_LIST]: GroupObjectGuestList
};

function fromApiFormattedDataObject<DV extends ValueFormatVersion = ValueFormatVersion>(obj: ApiFormattedDataObject): IDataObject<DV> {
  const DataObjectClass = DATA_OBJECT_TYPE_TO_CLASS_MAP[obj.type];
  if (DataObjectClass) return new DataObjectClass(obj);
  throw new AppException(Constants.Error.E_UNKNOWN_OBJECT_TYPE);
}

function fromApiFormattedUserObject<DV extends ValueFormatVersion = ValueFormatVersion>(obj: ApiFormattedUserObject): IUserObject<DV> {
  const UserObjectClass = USER_OBJECT_TYPE_TO_CLASS_MAP[obj.type];
  if (UserObjectClass) return new UserObjectClass(obj);
  throw new AppException(Constants.Error.E_UNKNOWN_OBJECT_TYPE);
}

function fromApiFormattedCollectionObject<DV extends ValueFormatVersion = ValueFormatVersion>(
  obj: ApiFormattedCollectionObject
): ICollectionObject<DV> {
  const CollectionObjectClass = COLLECTION_OBJECT_TYPE_TO_CLASS_MAP[obj.type];
  if (CollectionObjectClass) return new CollectionObjectClass(obj);
  throw new AppException(Constants.Error.E_UNKNOWN_OBJECT_TYPE);
}

export const batchQuerySuccessAction = (payload: ApiActionPayload.BatchQueryDataSuccess): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const Logger = getLoggerWithPrefix('Action', 'batchQuerySuccessAction:');

    Logger.info('== BEGIN ==');
    try {
      let nextState = { ...getState() };
      let wasUserObjectsStateAltered = false;
      let wasDataObjectsStateAltered = false;
      let wasCollectionObjectsStateAltered = false;

      const { request, response, cache: ScopedCache } = payload;
      let objectIdsToDelete = Immutable.Set<string>().asMutable();

      const fetchedUserObjectList: IUserObject<ValueFormatVersion>[] = [];
      if (!Utils.isNil(request.userObjects) && Utils.isPlainObject(request.userObjects)) {
        if (Utils.isArray(request.userObjects.ids) && request.userObjects.ids.length > 0) {
          objectIdsToDelete.concat(request.userObjects.ids.map(String));
          if (Utils.isArray(response.userObjects)) {
            fetchedUserObjectList.push(...response.userObjects.map(fromApiFormattedUserObject));
          }
        }
      }

      if (Utils.isArray(request.userObjectsByType) && request.userObjectsByType.length > 0) {
        request.userObjectsByType.forEach(({ type, userId }) => {
          const map = nextState.userObjects.getIn([type.toString(10), userId.toString(10)]);
          if (Immutable.isMap(map)) {
            objectIdsToDelete.concat(Immutable.Set.fromKeys(map));
          }
        });
        if (Utils.isArray(response.userObjectsByType)) {
          fetchedUserObjectList.push(...response.userObjectsByType.map(fromApiFormattedUserObject));
        }
      }

      let decryptedValues: Array<ValueFormatVersion> = [];
      try {
        const promiseList = await Promise.allSettled(fetchedUserObjectList.map((obj) => obj.decryptedValue()));
        promiseList.reduce((list, promise, index) => {
          if (promise.status === 'rejected') {
            const obj = fetchedUserObjectList[index];
            Logger.warn(`Error decrypting user object <type=${obj.type}, id=${obj.id}, userId=${obj.userId}>:`, promise.reason);
            list.push(EMPTY_PLAIN_OBJECT as ValueFormatVersion);
          } else {
            list.push(promise.value);
          }
          return list;
        }, decryptedValues);
      } catch (error) {
        Logger.warn(error);
      }

      let { UserObjectCache } = GlobalCache;
      if (decryptedValues.length === fetchedUserObjectList.length) {
        if (Utils.isNonArrayObjectLike<NonNullable<typeof ScopedCache>>(ScopedCache) && Utils.isNotNil(ScopedCache.UserObjectCache)) {
          UserObjectCache = ScopedCache.UserObjectCache;
        }

        nextState.userObjects = nextState.userObjects.withMutations((map) => {
          fetchedUserObjectList.forEach((obj, index) => {
            const objectId = obj.id.toString(10);

            //
            // if we requested for an ID and it wasn't retrieved by the API,
            // that may possibly indicate that the record doesn't exist in the
            // database anymore, or user no more has access to that user object,
            // or whatever else the reason may be. If we currently have an object
            // with a matching ID in our application state, it must be removed.
            //
            // So, what we're doing here is to remove the ID of any object
            // found in the fetched response from objectIdsToDelete; that way,
            // whatever IDs are left in that set are the only ones removed later
            // during the app state cleanup phase
            //
            objectIdsToDelete.delete(objectId);

            map.setIn([obj.type.toString(10), obj.userId.toString(10), objectId], obj.version);

            const decryptedValue = decryptedValues[index];
            UserObjectCache.add(obj, decryptedValue);
          });

          //
          // app state cleanup
          //
          objectIdsToDelete.forEach((idToDelete) => {
            for (const [objectType, userIdMap] of nextState.userObjects) {
              let found = false;

              for (const [userId, objectIdMap] of userIdMap) {
                found = objectIdMap.has(idToDelete);
                if (found) {
                  map.deleteIn([objectType, userId, idToDelete]);
                  UserObjectCache.delete(Number(idToDelete));
                  break;
                }
              }

              if (found) {
                break;
              }
            }
          });

          wasUserObjectsStateAltered = map.wasAltered();
        });
      }

      objectIdsToDelete.clear();

      let fetchedDataObjectList: IDataObject<ValueFormatVersion>[] = [];
      if (!Utils.isNil(request.dataObjects) && Utils.isPlainObject(request.dataObjects)) {
        if (Utils.isArray(request.dataObjects.ids) && request.dataObjects.ids.length > 0) {
          objectIdsToDelete.concat(request.dataObjects.ids.map(String));
          if (Utils.isArray(response.dataObjects)) {
            fetchedDataObjectList.push(
              ...response.dataObjects
                // .filter((obj) => obj.type !== process.env.DATA_OBJECT_TYPE_DOC_BODY && obj.type !== process.env.DATA_OBJECT_TYPE_MSG_BODY)
                .map(fromApiFormattedDataObject)
            );
          }
        }
      }

      decryptedValues = [];
      try {
        const promiseList = await Promise.allSettled(fetchedDataObjectList.map((obj) => obj.decryptedValue()));
        promiseList.reduce((list, promise, index) => {
          if (promise.status === 'rejected') {
            const obj = fetchedDataObjectList[index];
            Logger.warn(`Error decrypting data object <type=${obj.type}, id=${obj.id}, ownerId=${obj.ownerId}>:`, promise.reason);
            list.push(EMPTY_PLAIN_OBJECT as ValueFormatVersion);
          } else {
            list.push(promise.value);
          }
          return list;
        }, decryptedValues);
      } catch (error) {
        Logger.warn(error);
      }

      let { DataObjectCache } = GlobalCache;
      if (decryptedValues.length === fetchedDataObjectList.length) {
        if (Utils.isNonArrayObjectLike<NonNullable<typeof ScopedCache>>(ScopedCache) && Utils.isNotNil(ScopedCache.DataObjectCache)) {
          DataObjectCache = ScopedCache.DataObjectCache;
        }

        // eslint-disable-next-line require-atomic-updates
        nextState.dataObjects = nextState.dataObjects.withMutations((map) => {
          fetchedDataObjectList.forEach((obj, index) => {
            const objectId = obj.id.toString(10);
            objectIdsToDelete.delete(objectId);

            map.set(objectId, obj.version);

            const decryptedValue = decryptedValues[index];
            DataObjectCache.add(obj, decryptedValue);
          });

          objectIdsToDelete.forEach((objectId) => DataObjectCache.delete(Number(objectId)));
          map.deleteAll(objectIdsToDelete);
          wasDataObjectsStateAltered = map.wasAltered();
        });
      }

      objectIdsToDelete.clear();

      let fetchedCollectionObjectList: ICollectionObject<ValueFormatVersion>[] = [];
      if (Utils.isNonEmptyArray(request.collectionObjectsByType)) {
        // objectIdsToDelete.concat(request.collectionObjectsByType.map(({ type: id }) => id.toString(10)));
        if (Utils.isNonEmptyArray(response.collectionObjectsByType)) {
          fetchedCollectionObjectList.push(...response.collectionObjectsByType.map(fromApiFormattedCollectionObject));
        }
      }

      decryptedValues = [];
      try {
        const promiseList = await Promise.allSettled(fetchedCollectionObjectList.map((obj) => obj.decryptedValue()));
        promiseList.reduce((list, promise, index) => {
          if (promise.status === 'rejected') {
            const obj = fetchedCollectionObjectList[index];
            Logger.warn(`Error decrypting collection object <type=${obj.type}, id=${obj.id}, ownerId=${obj.ownerId}>:`, promise.reason);
            list.push(EMPTY_PLAIN_OBJECT as ValueFormatVersion);
          } else {
            list.push(promise.value);
          }
          return list;
        }, decryptedValues);
      } catch (error) {
        Logger.warn(error);
      }

      let { CollectionObjectCache } = GlobalCache;
      if (decryptedValues.length === fetchedCollectionObjectList.length) {
        if (Utils.isNonArrayObjectLike<NonNullable<typeof ScopedCache>>(ScopedCache) && Utils.isNotNil(ScopedCache.CollectionObjectCache)) {
          CollectionObjectCache = ScopedCache.CollectionObjectCache;
        }

        // eslint-disable-next-line require-atomic-updates
        nextState.collectionObjects = nextState.collectionObjects.withMutations((map) => {
          fetchedCollectionObjectList.forEach((obj, index) => {
            const objectId = obj.id.toString(10);
            objectIdsToDelete.delete(objectId);

            map.set(objectId, obj.version);

            const decryptedValue = decryptedValues[index];
            CollectionObjectCache.add(obj, decryptedValue);
          });

          objectIdsToDelete.forEach((objectId) => CollectionObjectCache.delete(Number(objectId)));
          map.deleteAll(objectIdsToDelete);
          wasCollectionObjectsStateAltered = map.wasAltered();
        });
      }

      if (wasUserObjectsStateAltered && UserObjectCache === GlobalUserObjectCache) {
        dispatch(setUserObjectsState(nextState.userObjects));
      }

      if (wasDataObjectsStateAltered && DataObjectCache === GlobalDataObjectCache) {
        dispatch(setDataObjectsState(nextState.dataObjects));
      }

      if (wasCollectionObjectsStateAltered && CollectionObjectCache === GlobalCollectionObjectCache) {
        dispatch(setCollectionObjectsState(nextState.collectionObjects));
      }
    } finally {
      Logger.info('== END ==');
    }
  };
};
