import React from 'react';

export const useThrowAsyncError = () => {
  const [, setError] = React.useState<any>(undefined!);

  return React.useCallback((error: any) => {
    setError(() => {
      throw error;
    });
  }, []);
};
