import { ContactListI18n } from '@sigmail/i18n';
import contactToolbarAction from './contact-toolbar-action';
import navAction from './nav-action';
import contactListView from './view-contact-list';
import healthDataView from './view-health-data';

const i18n: ContactListI18n.Root = {
  navAction,
  contactToolbarAction,
  view: {
    contactList: contactListView,
    healthData: healthDataView
  }
};

export default i18n;
