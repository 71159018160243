import { ApiActionPayload } from '@sigmail/app-state';
import { SigmailObjectId, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ApiFormattedDataObject, IDataObject, ValueFormatVersion } from '@sigmail/objects';
import { AppThunk } from '..';
import { DataObjectCache } from '../data-objects-slice/cache';
import { fetchObjectsAction, FetchObjectsActionResult } from './fetch-objects-action';

export type Payload = ApiActionPayload.FetchDataObjects;

type ObjectIdTuple = Exclude<Exclude<Payload['objectId'], SigmailObjectId>[0], SigmailObjectId>;

const LOGGER = getLoggerWithPrefix('Action', 'fetchDataObjectsAction:');

export const fetchDataObjectsAction = ({
  cache,
  expectedCount,
  fetch,
  logger,
  objectId,
  ...payload
}: Payload): AppThunk<Promise<FetchObjectsActionResult>> => async (dispatch): Promise<FetchObjectsActionResult> => {
  if (Utils.isNil(logger)) {
    logger = LOGGER;
    logger.info('== BEGIN ==');
  }

  try {
    if (Utils.isNil(fetch)) fetch = 'cacheMiss';

    const list = (Utils.isInteger(objectId) ? [objectId] : objectId).map<ObjectIdTuple>((id) => (Utils.isInteger(id) ? [id, fetch!] : id));

    const idsToFetch = new Set<SigmailObjectId>();
    const cachedDataObjectList: Array<ApiFormattedDataObject> = [];
    for (const [objectId, fetch] of list) {
      let dataObject: Readonly<[IDataObject<ValueFormatVersion>, ValueFormatVersion]> | undefined;

      if (fetch !== true) {
        dataObject = cache?.find(({ id }) => id === objectId);
        if (Utils.isNil(dataObject) && cache !== DataObjectCache) {
          dataObject = DataObjectCache.find(({ id }) => id === objectId);
          if (Utils.isNotNil(dataObject)) {
            cache?.add(...dataObject);
            cachedDataObjectList.push(dataObject[0].toApiFormatted());
          }
        }
      }

      if (Utils.isNil(dataObject) && fetch !== false) {
        idsToFetch.add(objectId);
      }
    }

    const response = await dispatch(
      fetchObjectsAction({
        cache: cache === null ? null : { DataObjectCache: cache },
        expectedCount: { dataObjects: expectedCount },
        dataObjects: { ids: Array.from(idsToFetch.values()) },
        logger,
        ...payload
      })
    );

    return { ...response, dataObjectList: response.dataObjectList.concat(cachedDataObjectList) };
  } finally {
    if (logger !== LOGGER) logger.info('== END ==');
  }
};
