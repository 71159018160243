import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: ContactListI18n.View.HealthDataView = {
  action: {
    goBack: {
      // Translators: Label for the Back To Contacts action in Health Data view
      label: gettext('contact-list', 'goBack', 'healthDataViewActionLabel')
    },
    print: {
      // Translators: Label for the Print action in Health Data view
      label: gettext('contact-list', 'print', 'healthDataViewActionLabel')
    }
  },
  // Translators: (HTML) Page heading for the Health Data view
  heading: gettext('contact-list', 'heading', 'healthDataView', 'safe-html'),
  section: {
    dataViewer: {
      message: {
        // Translators: Message displayed in the Viewer section of Health Data \
        // view when no data is available to display for the selected contact
        dataNotAvailable: gettext('contact-list', 'dataNotAvailable', 'healthDataViewerSectionMessage')
      }
    },
    parameters: {
      // Translators: (HTML) Heading for the Parameters section in Health Data \
      // view
      heading: gettext('contact-list', 'parameters', 'healthDataViewSectionHeading', 'safe-html')
    },
    requestType: {
      // Translators: (HTML) Heading for the Request Type section in Health \
      // Data view
      heading: gettext('contact-list', 'requestType', 'healthDataViewSectionHeading', 'safe-html')
    }
  }
};

export default i18n;
