import { ApiActionPayload, MessagingActionPayload } from '@sigmail/app-state';
import { Constants, GroupMessageFolderKey, SigmailObjectId, UserMessageFolderKey, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedNotificationObject,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  GroupMessageFolderItem,
  MessageFolderItemCount,
  MessageFolderListItem,
  MessageTimestampRecord,
  NotificationObjectReadReceipt,
  NotificationObjectReadReceiptValue,
  UserMessageFolderItem
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { Set as ImmutableSet } from 'immutable';
import { AppThunk } from '../..';
import { MessageFlags } from '../../../app/messaging/utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { DataObjectCache } from '../../data-objects-slice/cache';
import * as DataObjectSelectors from '../../selectors/data-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { ActionInitParams } from '../base-action';
import { GROUP_MESSAGE_FOLDER_PREFIX } from '../constants';
import { AUTH_STATE_UPDATE_MESSAGE_READ_STATE } from '../constants/auth-state-identifier';
import { updateMessageFolderAction } from './update-msg-folder-action';

// Unique collection of all top-level message folder keys
const MESSAGE_FOLDER_KEY_SET: ReadonlySet<string> = new Set<UserMessageFolderKey | GroupMessageFolderKey>([
  Constants.MessageFolderKey.Inbox,
  Constants.MessageFolderKey.Sent,
  Constants.MessageFolderKey.Drafts,
  Constants.MessageFolderKey.GroupInbox
]);

interface Payload extends MessagingActionPayload.UpdateMessageReadState {}

interface State extends AuthenticatedActionState {
  batchUpdateAuthState: string;
  batchUpdateClaims: Array<string>;
  dtServer: Date;
  msgFolder: UserMessageFolderItem | GroupMessageFolderItem;
  msgMetadataIdSet: ImmutableSet<SigmailObjectId>;
  notificationJsonList: Array<ApiFormattedNotificationObject>;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ApiActionPayload.BatchQueryDataSuccess;
}

class UpdateMessageReadStateAction extends AuthenticatedAction<Payload, State> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params);

    const { msgMetadataId } = this.payload;
    this.state.msgMetadataIdSet = ImmutableSet(
      Utils.filterMap(Utils.flatten([msgMetadataId]), (id) => DataObjectMsgMetadata.isValidId(id) && id)
    );
  }

  protected async onExecute() {
    if (this.state.msgMetadataIdSet.isEmpty()) {
      this.logger.info('Call ignored; message metadata ID set is empty.');
      return;
    }

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        this.state.dtServer = await this.dispatchFetchServerDateAndTime();
        await this.generateRequestBody();

        const { batchUpdateAuthState: authState, batchUpdateClaims: claims, requestBody, successPayload } = this.state;
        const mutations = requestBody.build();
        if (Utils.isArray(mutations.dataObjects) || Utils.isArray(mutations.notificationObjects)) {
          await this.dispatchBatchUpdateData({ authState, claims, ...mutations });

          try {
            await this.dispatchBatchQueryDataSuccess(successPayload);
          } catch (error) {
            this.logger.warn('Error manually updating app state:', error);
            /* ignore */
          }
        } else {
          this.logger.warn('Call ignored; nothing to update.');
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; batch update operation will be retried.');
        this.state.notificationJsonList = [];
      }
    }
  }

  private async generateRequestBody(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;

    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] } },
      response: { dataObjects: [], serverDateTime: '' }
    };

    this.state.notificationJsonList = [];
    this.state.batchUpdateClaims = [];

    await this.dispatch(
      updateMessageFolderAction({
        folderKey,
        parentFolderKey,
        requestBody: this.state.requestBody,
        successPayload: this.state.successPayload,
        applyUpdate: this.applyMessageFolderUpdate.bind(this)
      })
    );

    await this.addInsertOperationForReadReceiptNotifications(); // 551
  }

  private async applyMessageFolderUpdate(
    folderData: Array<MessageFolderListItem>,
    itemCount: MessageFolderItemCount,
    meta: MessagingActionPayload.ApplyMessageFolderUpdateMeta
  ) {
    const { folderKey, parentFolderKey } = this.payload;
    const { activeGroupId, currentUser, dtServer, msgMetadataIdSet, roleAuthClaim: authState } = this.state;

    const isBaseMsgFolder = meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER;
    const result: MessagingActionPayload.ApplyMessageFolderUpdateResult = { updated: false, done: false };

    if (isBaseMsgFolder) {
      (Object.keys(itemCount) as Array<keyof typeof itemCount>).reduce((count, key) => {
        count[key] = { total: 0, unread: 0 };
        return count;
      }, itemCount);
      result.updated = true;
    }

    const indexSet = new Set<number>();
    for (let index = 0; index < folderData.length; index++) {
      const message = folderData[index];
      const { hasReminder, isBillable, isImportant, isMarkedAsRead, isMessageFormReferral } = MessageFlags(message);
      let unread = !isMarkedAsRead;

      if (msgMetadataIdSet.has(message.header)) {
        unread = this.payload.unread;
        if (unread === isMarkedAsRead) {
          indexSet.add(index);
        }
      }

      itemCount.all.total += 1;
      itemCount.all.unread += Number(unread);
      itemCount.billable.total += Number(isBillable);
      itemCount.billable.unread += Number(isBillable && unread);
      itemCount.important.total += Number(isImportant);
      itemCount.important.total += Number(isImportant && unread);
      itemCount.referral.total += Number(isMessageFormReferral);
      itemCount.referral.unread += Number(isMessageFormReferral && unread);
      itemCount.reminder.total += Number(hasReminder);
      itemCount.reminder.unread += Number(hasReminder && unread);
    }

    const idsFound: Array<SigmailObjectId> = [];
    for (const index of indexSet) {
      if (index === -1) continue;

      const message = folderData[index];
      const { header: msgMetadataId } = message;
      idsFound.push(msgMetadataId);

      const markedAsRead = !this.payload.unread;
      let readAtTimestamp = Utils.isInteger(message.timestamp) ? null : message.timestamp.firstReadAt;
      if (Utils.isNil(readAtTimestamp)) readAtTimestamp = message.flags?.readAtTimestamp;
      do {
        if (!markedAsRead || Utils.isNotNil(readAtTimestamp)) {
          break;
        }

        this.logger.info('No previous read receipt record was found.');
        this.logger.info('Adding an insert operation to request body for read receipt notification.');

        let dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
        let msgMetadataObject = dataObjectByIdSelector<DataObjectMsgMetadataValue>(msgMetadataId);
        if (Utils.isNil(msgMetadataObject)) {
          await this.dispatchFetchObjects({ authState, dataObjects: { ids: [msgMetadataId] } });

          dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
          msgMetadataObject = dataObjectByIdSelector<DataObjectMsgMetadataValue>(msgMetadataId);
          if (Utils.isNil(msgMetadataObject)) {
            this.logger.warn('Skipping read receipt notification creation; metadata object could not be fetched.');
            break;
          }
        }

        const { sender } = DataObjectCache.getValue(msgMetadataObject, {} as DataObjectMsgMetadataValue);
        if (!Utils.isNonArrayObjectLike(sender) || (sender.type !== 'group' && sender.type !== 'user')) {
          this.logger.warn('Skipping read receipt notification creation; sendingUserId could not be determined.');
          break;
        }

        let sendingUserId = currentUser.id;
        if (MESSAGE_FOLDER_KEY_SET.has(folderKey)) {
          if (folderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX)) {
            sendingUserId = activeGroupId;
          }
        } else if (Utils.isString(parentFolderKey) && parentFolderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX)) {
          sendingUserId = activeGroupId;
        }

        readAtTimestamp = dtServer.getTime();

        const value: NotificationObjectReadReceiptValue = { $$formatver: 1, header: msgMetadataId };
        const notificationObject = await NotificationObjectReadReceipt.create(
          1, // we will patch it later in addInsertOperationForReadReceiptNotifications method
          undefined, // code
          0, // version
          value,
          sender.id,
          sendingUserId,
          0, // encryptedFor
          dtServer
        );

        this.state.notificationJsonList.push(notificationObject.toApiFormatted());
      } while (false);

      let timestamp: Readonly<MessageTimestampRecord>;
      if (Utils.isInteger(message.timestamp)) {
        timestamp = { createdAt: message.timestamp, firstReadAt: readAtTimestamp! };
      } else {
        timestamp = { ...message.timestamp, firstReadAt: readAtTimestamp! };
      }

      // eslint-disable-next-line require-atomic-updates
      folderData[index] = {
        ...message,
        timestamp,
        flags: { ...Utils.omit(message.flags, 'readAtTimestamp'), markedAsRead }
      };

      result.updated = true;
    }

    if (idsFound.length > 0) {
      this.state.msgMetadataIdSet = msgMetadataIdSet.subtract(idsFound);
    }

    return result;
  }

  private async addInsertOperationForReadReceiptNotifications(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for read receipt notifications.');

    const { notificationJsonList, requestBody } = this.state;

    if (!Utils.isNonEmptyArray(notificationJsonList)) {
      const requestData: Api.EnterStateRequestData = {
        authState: this.state.roleAuthClaim,
        state: AUTH_STATE_UPDATE_MESSAGE_READ_STATE
      };

      const { authState } = await this.dispatchEnterState(requestData);
      this.state.batchUpdateAuthState = authState;

      this.logger.info('Notification list is empty; call ignored.');
      return;
    }

    const query: Api.GetIdsRequestData = {
      authState: this.state.roleAuthClaim,
      state: AUTH_STATE_UPDATE_MESSAGE_READ_STATE,
      ids: {
        ids: [
          {
            type: process.env.NOTIFICATION_OBJECT_TYPE_READ_RECEIPT,
            count: notificationJsonList.length
          }
        ]
      }
    };

    const { authState, idsClaim, ids: idRecord } = await this.dispatchFetchIdsByUsage(query);
    this.state.batchUpdateAuthState = authState;
    this.state.batchUpdateClaims = [idsClaim];

    notificationJsonList.forEach((apiFormattedObject, index) => {
      const notificationJson: ApiFormattedNotificationObject = {
        ...apiFormattedObject,
        id: idRecord[process.env.NOTIFICATION_OBJECT_TYPE_READ_RECEIPT][index]
      };

      requestBody.insert(new NotificationObjectReadReceipt(notificationJson));
    });
  }
}

export const updateMessageReadStateAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'updateMessageReadStateAction:');

    const action = new UpdateMessageReadStateAction({
      payload: { ...payload, unread: payload.unread === true },
      dispatch,
      getState,
      apiService,
      logger: Logger
    });

    return action.execute();
  };
};
