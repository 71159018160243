import {
  at,
  camelCase,
  cloneDeep,
  debounce,
  defaults,
  defaultsDeep,
  every,
  findKey,
  flatten,
  forOwn,
  has,
  invoke,
  invokeMap,
  isArray as _isArray,
  isDate,
  isFinite as _isFinite,
  isInteger as _isInteger,
  isNaN,
  isNil,
  isNumber,
  isObject,
  isObjectLike,
  isPlainObject,
  isString,
  isUndefined,
  mapKeys,
  mapValues,
  memoize,
  noop,
  omit,
  partial,
  pick,
  rearg,
  transform
} from 'lodash-es';
import { LICENSE_TYPE_GUEST, LICENSE_TYPE_PRO, LICENSE_TYPE_STANDARD } from '../constants/license-type-identifier';
import * as MessageFormNameConstants from '../constants/message-form-name';
import {
  ROLE_ID_ADMIN_MESSAGING_USER,
  ROLE_ID_ADMIN_PHYSICIAN,
  ROLE_ID_ADMIN_STAFF,
  ROLE_ID_BASIC_PHYSICIAN,
  ROLE_ID_CAREGIVER,
  ROLE_ID_GUEST,
  ROLE_ID_JUNIOR_PHYSICIAN,
  ROLE_ID_MESSAGING_USER,
  ROLE_ID_SENIOR_PHYSICIAN,
  ROLE_ID_SIGMAIL_ADMIN,
  ROLE_ID_STAFF
} from '../constants/role-identifier';
import type {
  CancelablePromise,
  MemberLicense,
  MemberRole,
  MessageFormName,
  MessageFormNameConsultationRequest,
  MessageFormNameDefault,
  MessageFormNameEncounter,
  MessageFormNameEvent,
  MessageFormNameEventAttendance,
  MessageFormNameHealthDataRequest,
  MessageFormNameHrm,
  MessageFormNameJoinGroupInvitation,
  MessageFormNameReferral,
  NonArrayObjectLike,
  Nullable
} from '../types';
import { DATE_FORMAT_FULL_NO_TIME } from './format-timestamp';

const isArray = _isArray as {
  (value?: unknown): value is Array<unknown> | ReadonlyArray<unknown>;
  <T>(value?: unknown): value is Array<T> | ReadonlyArray<T>;
};

const isFinite = _isFinite as (value?: unknown) => value is number;
const isInteger = _isInteger as (value?: unknown) => value is number;

export {
  at,
  camelCase,
  cloneDeep,
  debounce,
  defaults,
  defaultsDeep,
  every,
  findKey,
  flatten,
  has,
  invoke,
  invokeMap,
  isArray,
  isDate,
  isFinite,
  isInteger,
  isNaN,
  isNil,
  isNumber,
  isObject,
  isObjectLike,
  isPlainObject,
  isString,
  isUndefined,
  mapKeys,
  mapValues,
  memoize,
  noop,
  omit,
  partial,
  pick,
  rearg,
  transform
};

function smi(i32: number): number {
  // https://github.com/immutable-js/immutable-js/blob/master/src/Math.js

  // v8 has an optimization for storing 31-bit signed numbers.
  // Values which have either 00 or 11 as the high order bits qualify.
  // This function drops the highest order bit in a signed number, maintaining
  // the sign bit.
  return ((i32 >>> 1) & 0x40000000) | (i32 & 0xbfffffff);
}

export const SEVERITY = (code: number): number => code >> 27;
export const FACILITY = (code: number): number => (code >> 16) & 0x000007ff;
export const CODE = (code: number): number => code & 0x0000ffff;

//
// Util
//
export function arrayOrDefault<T>(value?: unknown, defaultValue?: Array<T>): Array<T> {
  if (isArray<T>(value)) return value as Array<T>;
  return arguments.length < 2 ? ([] as Array<T>) : defaultValue!;
}

export function boolOrDefault(value?: unknown, defaultValue?: boolean): boolean {
  if (typeof value === 'boolean') return value;
  return arguments.length >= 2 && defaultValue!;
}

export function dateOrDefault(value?: unknown, defaultValue?: Date): Date {
  if (isDate(value)) return value;
  return arguments.length < 2 ? new Date() : defaultValue!;
}

export function numberOrDefault<T extends number>(value?: unknown, defaultValue?: T): T {
  return (isNumber(value) ? value : arguments.length < 2 ? 0 : defaultValue) as T;
}

export function stringOrDefault<T extends string>(value?: unknown, defaultValue?: T): T {
  return (isString(value) ? value : arguments.length < 2 ? '' : defaultValue) as T;
}

//
// Lang
//
export const isNonArrayObjectLike = <T>(value?: unknown): value is NonArrayObjectLike<T> =>
  isObjectLike(value) && !isArray(value);
export const isNonEmptyArray = ((value: unknown) => isArray(value) && value.length > 0) as {
  (value?: unknown): value is [unknown, ...Array<unknown>] | readonly [unknown, ...Array<unknown>];
  <T>(value?: unknown): value is [T, ...Array<T>] | readonly [T, ...Array<T>];
};
export const isNotNil = <T>(value?: Nullable<T>): value is NonNullable<T> => !isNil(value);
export const isValidDate = (value?: unknown): value is Date => isDate(value) && !isNaN(value.getTime());

//
// Array
//
/* eslint-disable no-unused-vars */
export function filterMap<T, U>(
  array: ReadonlyArray<T>,
  callbackFn: (currentValue: T, currentIndex: number, arr: ReadonlyArray<T>) => false | U,
  thisArg?: unknown
): Array<U>;

export function filterMap<T, U>(
  array: Array<T>,
  callbackFn: (currentValue: T, currentIndex: number, arr: Array<T>) => false | U,
  thisArg?: unknown
): Array<U>;

export function filterMap<T, U>(
  array: Array<T> | ReadonlyArray<T>,
  callbackFn: (currentValue: T, currentIndex: number, arr: Array<T>) => false | U,
  thisArg?: unknown
): Array<U> {
  return (array as Array<T>).reduce<Array<U>>((list, value, index, arr): typeof list => {
    const mappedValue = callbackFn.call(thisArg, value, index, arr);
    if (mappedValue !== false) list.push(mappedValue);
    return list;
  }, []);
}
/* eslint-enable no-unused-vars */

export function* makeSequence<T extends ReadonlyArray<unknown>>(values: T): Generator<T[0], T[0]> {
  let iterationCount = 0;
  for (const value of values) {
    iterationCount += 1;
    yield value;
  }
  return iterationCount;
}

export const shuffle = <T extends Array<unknown>>(array: T): T => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

//
// Object
//
export const deepFreeze = <T>(value: T): Readonly<T> => {
  if (!isObjectLike(value) || Object.isFrozen(value)) return value;
  // prettier-ignore
  forOwn(value, (ownPropValue) => { deepFreeze(ownPropValue); });
  return Object.freeze(value);
};

//
// Number
//
// Compress arbitrarily large numbers into smi hashes.
export const hashNumber = (n: number): number => {
  if (n !== n || n === Infinity) {
    return 0;
  }

  let hash = n | 0;
  if (hash !== n) {
    hash ^= n * 0xffffffff;
  }

  while (n > 0xffffffff) {
    n /= 0xffffffff;
    hash ^= n;
  }

  return smi(hash);
};

//
// String
//
export function endsWith<T extends string>(value: unknown, match: T): value is `${string}${T}` {
  return isString(value) && value.endsWith(match);
}

export function startsWith<T extends string>(value: unknown, match: T): value is `${T}${string}` {
  return isString(value) && value.startsWith(match);
}

export function trimOrDefault(value?: unknown, defaultValue?: string): string {
  const args: Parameters<typeof stringOrDefault> = [value];
  if (arguments.length > 1) args.push(defaultValue);
  const result = stringOrDefault(...args);
  return isString(result) ? result.trim() : defaultValue!;
}

export const trim = (value?: unknown): string => {
  const result = trimOrDefault(value, null!);
  if (result === null) {
    throw new TypeError('Invalid argument; expected <value> to be of type string.');
  }
  return result;
};

export const hashString = (value: string): number => {
  // https://github.com/immutable-js/immutable-js/blob/master/src/Hash.js

  // This is the hash from JVM
  // The hash code for a string is computed as
  // s[0] * 31 ^ (n - 1) + s[1] * 31 ^ (n - 2) + ... + s[n - 1],
  // where s[i] is the ith character of the string and n is the length of
  // the string. We "mod" the result to make it between 0 (inclusive) and 2^31
  // (exclusive) by dropping high bits.
  let hashed = 0;
  for (let index = 0; index < value.length; index++) {
    hashed = (31 * hashed + value.charCodeAt(index)) | 0;
  }
  return smi(hashed);
};

export const maskEmailAddress = (emailAddress: string): string =>
  emailAddress.replace(
    /^(.)(.*)(.@)([^.]*)(.*)$/,
    /* eslint-disable */
    (__, a, b, c, d, e) => a + b.replace(/./g, '*') + c + d.replace(/^(.).*(.)$$/g, '$1*****$2') + e
    /* eslint-enable */
  );

export const maskPhoneNumber = (phoneNumber: string): string => phoneNumber.replace(/\d(?=\d{2})/g, '*');

const REGEX_ISO_DATE_LIKE = /^[+-]?\d{1,6}-([0][1-9]|1[0-2])-(0[1-9]|[1-2]\d|3[0-1])$/;
// const REGEX_STARTS_WITH_ISO_LIKE = /^[+-]?\d{1,6}-(0[1-9]|1[0-2])-(0[1-9]|[1-2]\d|3[0-1])T[0-2]\d:[0-5]\d:[0-5]\d/;

export const maskBirthDate = (birthDate: string | number | Date, locale: string): string => {
  let dtBirth: Date;
  if (isString(birthDate) && REGEX_ISO_DATE_LIKE.test(birthDate)) {
    const dateParts = birthDate
      // eslint-disable-next-line @typescript-eslint/prefer-string-starts-ends-with
      .slice(Number(birthDate[0] === '+' || birthDate[0] === '-'))
      .split('-')
      .map(Number) as [number, number, number];
    dateParts[1] -= 1; // months are zero-indexed
    dtBirth = new Date(...dateParts);
  } else {
    dtBirth = new Date(birthDate);
  }

  if (isValidDate(dtBirth)) {
    const dtFormatter = DATE_FORMAT_FULL_NO_TIME(locale);
    return dtFormatter
      .formatToParts(dtBirth)
      .map((part) => (part.type === 'month' ? Array<string>(part.value.length).fill('X').join('') : part.value))
      .join('');
  }
  return '';
};

/* eslint-disable no-nested-ternary */
export function maskHealthPlanNumber(planNumber: string): string {
  const trimmedPlanNumber = trimOrDefault(planNumber);
  if (trimmedPlanNumber.length === 0) return '';

  const len = trimmedPlanNumber.length;
  const limit = Math.floor(len / 2);
  const start = Math.ceil(limit / 2);

  return trimmedPlanNumber
    .split('')
    .map((c, index) => (index < start || index > start + limit - 1 ? c : /[\d-]/.test(c) ? 'x' : c))
    .join('');
}
/* eslint-enable no-nested-ternary */

export function maskCellNumber(phoneNumber: string): string {
  const digits = arrayOrDefault<string>(isString(phoneNumber) && phoneNumber.match(/[0-9]/g)).join('');
  return digits.length !== 10 ? phoneNumber : `${digits.slice(0, 3)}xxx${digits.slice(6)}`;
}

export const isSigMailAdminRole = (roleId: unknown): roleId is Extract<MemberRole, 'sigMailAdmin'> =>
  roleId === ROLE_ID_SIGMAIL_ADMIN;

export const isAdminPhysicianRole = (roleId: unknown): roleId is Extract<MemberRole, 'admin'> =>
  roleId === ROLE_ID_ADMIN_PHYSICIAN;

export const isBasicPhysicianRole = (roleId: unknown): roleId is Extract<MemberRole, 'basicPhysician'> =>
  roleId === ROLE_ID_BASIC_PHYSICIAN;

export const isSeniorPhysicianRole = (roleId: unknown): roleId is Extract<MemberRole, 'physician+'> =>
  roleId === ROLE_ID_SENIOR_PHYSICIAN;

export const isJuniorPhysicianRole = (roleId: unknown): roleId is Extract<MemberRole, 'physician'> =>
  roleId === ROLE_ID_JUNIOR_PHYSICIAN;

export const isPhysicianRole = (
  roleId: unknown
): roleId is Extract<MemberRole, 'admin' | 'basicPhysician' | 'physician' | 'physician+'> =>
  isAdminPhysicianRole(roleId) ||
  isBasicPhysicianRole(roleId) ||
  isSeniorPhysicianRole(roleId) ||
  isJuniorPhysicianRole(roleId);

export const isAdminStaffRole = (roleId: unknown): roleId is Extract<MemberRole, 'adminStaff'> =>
  roleId === ROLE_ID_ADMIN_STAFF;

export const isBasicStaffRole = (roleId: unknown): roleId is Extract<MemberRole, 'staff'> => roleId === ROLE_ID_STAFF;

export const isStaffRole = (roleId: unknown): roleId is Extract<MemberRole, 'adminStaff' | 'staff'> =>
  isAdminStaffRole(roleId) || isBasicStaffRole(roleId);

export const isAdminMessagingUserRole = (roleId: unknown): roleId is Extract<MemberRole, 'adminMessagingUser'> =>
  roleId === ROLE_ID_ADMIN_MESSAGING_USER;

export const isBasicMessagingUserRole = (roleId: unknown): roleId is Extract<MemberRole, 'messagingUser'> =>
  roleId === ROLE_ID_MESSAGING_USER;

export const isMessagingUserRole = (
  roleId: unknown
): roleId is Extract<MemberRole, 'adminMessagingUser' | 'messagingUser'> =>
  isAdminMessagingUserRole(roleId) || isBasicMessagingUserRole(roleId);

export const isCaregiverRole = (roleId: unknown): roleId is Extract<MemberRole, 'caregiver'> =>
  roleId === ROLE_ID_CAREGIVER;

export const isGuestRole = (roleId: unknown): roleId is Extract<MemberRole, 'patient'> => roleId === ROLE_ID_GUEST;

export const isNonGuestRole = (
  roleId: unknown
): roleId is Extract<
  MemberRole,
  | 'admin'
  | 'adminMessagingUser'
  | 'adminStaff'
  | 'basicPhysician'
  | 'caregiver'
  | 'physician'
  | 'messagingUser'
  | 'physician+'
  | 'sigMailAdmin'
  | 'staff'
> =>
  isCaregiverRole(roleId) ||
  isSigMailAdminRole(roleId) ||
  isPhysicianRole(roleId) ||
  isStaffRole(roleId) ||
  isMessagingUserRole(roleId);

export const isProLicense = (licenseType: unknown): licenseType is Extract<MemberLicense, 'pro'> =>
  licenseType === LICENSE_TYPE_PRO;

export const isStandardLicense = (licenseType: unknown): licenseType is Extract<MemberLicense, 'standard'> =>
  licenseType === LICENSE_TYPE_STANDARD;

export const isGuestLicense = (licenseType: unknown): licenseType is Extract<MemberLicense, 'guest'> =>
  licenseType === LICENSE_TYPE_GUEST;

export const isMessageFormNameConsultation = (value?: unknown): value is MessageFormNameConsultationRequest =>
  value === MessageFormNameConstants.Consultation;
export const isMessageFormNameDefault = (value?: unknown): value is MessageFormNameDefault =>
  isNil(value) || value === MessageFormNameConstants.Default;
/** @deprecated use {@link isMessageFormNameConsultation} */
export const isMessageFormNameEConsult = isMessageFormNameConsultation;
export const isMessageFormNameEncounter = (value?: unknown): value is MessageFormNameEncounter =>
  value === MessageFormNameConstants.Encounter;
export const isMessageFormNameEvent = (value?: unknown): value is MessageFormNameEvent =>
  value === MessageFormNameConstants.Event;
export const isMessageFormNameEventAttendance = (value?: unknown): value is MessageFormNameEventAttendance =>
  value === MessageFormNameConstants.EventAttendance;
export const isMessageFormNameHealthDataRequest = (value?: unknown): value is MessageFormNameHealthDataRequest =>
  value === MessageFormNameConstants.HealthDataRequest;
export const isMessageFormNameHRM = (value?: unknown): value is MessageFormNameHrm =>
  value === MessageFormNameConstants.HRM;
export const isMessageFormNameJoinGroupInvitation = (value?: unknown): value is MessageFormNameJoinGroupInvitation =>
  value === MessageFormNameConstants.JoinGroupInvitation;
export const isMessageFormNameReferral = (value?: unknown): value is MessageFormNameReferral =>
  value === MessageFormNameConstants.Referral;

export const isMessageFormNameValid = (value?: unknown): value is MessageFormName =>
  isMessageFormNameDefault(value) ||
  isMessageFormNameConsultation(value) ||
  isMessageFormNameEncounter(value) ||
  isMessageFormNameEvent(value) ||
  isMessageFormNameEventAttendance(value) ||
  isMessageFormNameHealthDataRequest(value) ||
  isMessageFormNameHRM(value) ||
  isMessageFormNameJoinGroupInvitation(value) ||
  isMessageFormNameReferral(value);

// eslint-disable-next-line no-unused-vars
const isMessageFormWithName = (equalityComparer: (value?: unknown) => boolean, value?: unknown): boolean =>
  isNonArrayObjectLike<{ name: string }>(value) && equalityComparer(value.name);

const _isDefaultMessageForm = (value?: unknown): boolean =>
  isNil(value) || (isNonArrayObjectLike<{ name: string }>(value) && isMessageFormNameDefault(value.name));
const _isConsultationMessageForm = isMessageFormWithName.bind(null, isMessageFormNameConsultation);
const _isEncounterMessageForm = isMessageFormWithName.bind(null, isMessageFormNameEncounter);
const _isEventMessageForm = isMessageFormWithName.bind(null, isMessageFormNameEvent);
const _isEventAttendanceMessageForm = isMessageFormWithName.bind(null, isMessageFormNameEventAttendance);
const _isHealthDataRequestMessageForm = isMessageFormWithName.bind(null, isMessageFormNameHealthDataRequest);
const _isHrmMessageForm = isMessageFormWithName.bind(null, isMessageFormNameHRM);
const _isJoinGroupInvitationMessageForm = isMessageFormWithName.bind(null, isMessageFormNameJoinGroupInvitation);
const _isReferralMessageForm = isMessageFormWithName.bind(null, isMessageFormNameReferral);

type AssertMessageForm<T extends MessageFormName> = (value?: unknown) => value is { name: T };

export const isConsultationMessageForm =
  _isConsultationMessageForm as AssertMessageForm<MessageFormNameConsultationRequest>;
export const isDefaultMessageForm = _isDefaultMessageForm as (
  value?: unknown
) => value is { name: MessageFormNameDefault } | undefined;
/** @deprecated use {@link isConsultationMessageForm} */
export const isEConsultMessageForm = isConsultationMessageForm;
export const isEncounterMessageForm = _isEncounterMessageForm as AssertMessageForm<MessageFormNameEncounter>;
export const isEventMessageForm = _isEventMessageForm as AssertMessageForm<MessageFormNameEvent>;
export const isEventAttendanceMessageForm =
  _isEventAttendanceMessageForm as AssertMessageForm<MessageFormNameEventAttendance>;
export const isHealthDataRequestMessageForm =
  _isHealthDataRequestMessageForm as AssertMessageForm<MessageFormNameHealthDataRequest>;
export const isHrmMessageForm = _isHrmMessageForm as AssertMessageForm<MessageFormNameHrm>;
export const isJoinGroupInvitationMessageForm =
  _isJoinGroupInvitationMessageForm as AssertMessageForm<MessageFormNameJoinGroupInvitation>;
export const isReferralMessageForm = _isReferralMessageForm as AssertMessageForm<MessageFormNameReferral>;

export function getLicenseTypeFromRole(roleId: unknown): string {
  let licenseType = '';
  if (isCaregiverRole(roleId) || isGuestRole(roleId)) {
    licenseType = LICENSE_TYPE_GUEST;
  } else if (isNonGuestRole(roleId)) {
    licenseType = LICENSE_TYPE_PRO;
    if (isBasicPhysicianRole(roleId) || isJuniorPhysicianRole(roleId) || isMessagingUserRole(roleId)) {
      licenseType = LICENSE_TYPE_STANDARD;
    }
  }
  return licenseType;
}

export async function sleep(ms?: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, ms);
  });
}

//
// Promise
//
export const makeCancelablePromise = <T = void>(promise: Promise<T>): CancelablePromise<T> => {
  let isPending_ = true;
  let hasCanceled_ = false;
  let result: unknown;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (value) => {
        isPending_ = false;
        if (hasCanceled_) {
          result = { isCanceled: true };
          reject(result);
        } else {
          result = value;
          resolve(value);
        }
      },
      (error) => {
        isPending_ = false;
        if (hasCanceled_) {
          result = { isCanceled: true };
          reject(result);
        } else {
          result = error;
          reject(error);
        }
      }
    );
  });

  result = wrappedPromise;

  return {
    promise: wrappedPromise,

    get isPending(): boolean {
      return isPending_;
    },

    get hasCanceled(): boolean {
      return hasCanceled_;
    },

    value(): T {
      if (!isPending_ && !hasCanceled_) {
        return result as T;
      }
      throw result;
    },

    cancel(): void {
      if (isPending_) {
        hasCanceled_ = true;
        isPending_ = false;
      }
    }
  };
};
