import { PartialRecord, Utils } from '@sigmail/common';
import { FormInputErrorMessageI18n } from '@sigmail/i18n';
import { FieldState } from 'final-form';
import { TOptions } from 'i18next';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import * as FormInputConstraint from '../../../constants/form-input-constraint';
import { withTranslation } from '../../../i18n';
import { I18N_NS_MESSAGING } from '../../../i18n/config/namespace-identifiers';
import messagingI18n from '../../../i18n/messaging';
import * as FieldValidators from '../../shared/form/field-validator';
import { FieldProps, FormComponent, FormComponentProps, FormComponentState } from '../../shared/form/form.component';
import style from './decline-referral-form.module.css';

const { form: i18n } = messagingI18n.dialog.referral.decline;

export type FieldsetKey = keyof typeof i18n;
export type FieldNameReasonFieldset = keyof typeof i18n.fieldsetReason.formField;
export type FieldName = FieldNameReasonFieldset;
export type FormValues = Record<FieldName, string>;

const FIELD_SET_LIST: ReadonlyArray<Readonly<[FieldsetKey, ReadonlyArray<FieldName>]>> = [
  ['fieldsetReason', ['reason']]
];

const FIELD_PROPS: Record<FieldName, FieldProps> = {
  reason: {
    autoComplete: 'off',
    inputProps: { 'aria-required': true },
    label: i18n.fieldsetReason.formField.reason.label,
    multiline: true,
    placeholder: i18n.fieldsetReason.formField.reason.placeholder,
    rows: 4,
    rowsMax: 4,
    config: {
      required: true,
      maxLength: FormInputConstraint.ReferralDeclineReason.maxLength
    }
  }
};

export type DeclineReferralFormClassKey = 'inputRoot';

export interface Props extends FormComponentProps<FieldName, FormValues> {
  classes?: PartialRecord<DeclineReferralFormClassKey, string> | undefined;
}

interface ComponentProps extends Props, WithTranslation {}
interface State extends FormComponentState<FieldName, FormValues> {}

class DeclineReferralFormComponent extends FormComponent<
  FieldsetKey,
  FieldName,
  FormValues,
  FormValues,
  ComponentProps,
  State
> {
  public constructor(props: ComponentProps) {
    super(props);

    this.propsToOmit.push(
      // WithTranslation
      't',
      'tReady',
      'i18n',

      // Props
      'classes'
    );

    this.createForm({ initialValues: { reason: '' } });
  }

  /** @override */
  protected get formId() {
    return Utils.isString(this.props.id) ? this.props.id : `form-referral-decline-reason-${this.formIdSuffix}`;
  }

  /** @override */
  protected getFieldsetList() {
    return FIELD_SET_LIST;
  }

  /** @override */
  protected getFieldConfig(fieldName: FieldName) {
    return FIELD_PROPS[fieldName].config;
  }

  /** @override */
  protected getFieldProps(fieldName: FieldName, defaultProps: { [prop: string]: any }) {
    const { t } = this.props;

    const copyOfDefaultProps = { ...defaultProps };
    const { helperText: defaultHelperText } = copyOfDefaultProps;
    let { helperText, label, placeholder, ...fieldProps } = FIELD_PROPS[fieldName] as any;
    let ariaLabel = fieldProps['aria-label'];
    let ariaPlaceholder = fieldProps['aria-placeholder'];

    if (Utils.isString(label)) label = t(label);
    if (Utils.isString(ariaLabel)) ariaLabel = t(ariaLabel);
    if (Utils.isString(placeholder)) placeholder = t(placeholder);
    if (Utils.isString(ariaPlaceholder)) ariaPlaceholder = t(ariaPlaceholder);
    if (Utils.isNil(helperText) || helperText === false) helperText = defaultHelperText;

    return {
      ...copyOfDefaultProps,
      ...fieldProps,
      'aria-label': ariaLabel,
      'aria-placeholder': ariaPlaceholder,
      helperText,
      label,
      placeholder
    };
  }

  private getFieldErrorMessage(fieldName: FieldName, error: any): string {
    const { t } = this.props;

    let errorMessageI18n: string | undefined = undefined;
    if (Utils.isNonArrayObjectLike<FieldValidators.ValidationErrorResult>(error)) {
      const [fieldsetName] =
        this.getFieldsetList().find(([_, fieldNameList]) => fieldNameList.includes(fieldName)) || [];
      if (Utils.isString(fieldsetName) === true) {
        const { error: errorI18n } = (i18n[fieldsetName!] as any).formField[fieldName];
        if (Utils.isNonArrayObjectLike<FormInputErrorMessageI18n>(errorI18n)) {
          errorMessageI18n = errorI18n[error.key];
        }
      }
    }

    if (!Utils.isString(errorMessageI18n)) return error;

    let tOptions: TOptions = undefined!;
    if (error.key === FieldValidators.VALIDATION_ERROR_TOO_LONG && fieldName === 'reason') {
      tOptions = { MAX_LENGTH: error[error.key].max };
    }

    return t(errorMessageI18n, tOptions);
  }

  /** @override */
  protected renderField(fieldName: FieldName, ...args: any[]) {
    const { classes } = this.props;

    const fieldNode = super.renderField(fieldName, ...args);
    if (Utils.isNil(fieldNode) || fieldNode === false) return null;

    return (
      <div key={fieldName} className={classes!.inputRoot}>
        {fieldNode}
      </div>
    );
  }

  /** @override */
  protected setFieldState(fieldName: FieldName, fieldState: FieldState<any>) {
    let { error } = fieldState;

    if (Utils.isNotNil(error)) {
      error = this.getFieldErrorMessage(fieldName, error);
    }

    return super.setFieldState(fieldName, { ...fieldState, error });
  }
}

const ns = [I18N_NS_MESSAGING];

export const DeclineReferralForm = withTranslation(ns, { withRef: true })(
  React.forwardRef<HTMLFormElement, ComponentProps>((props, ref) => {
    const { classes: classesProp, ...rootProps } = props;

    const classes = Utils.defaults({} as ComponentProps['classes'], classesProp, {
      inputRoot: style['form-input-group']
    } as ComponentProps['classes']);

    return <DeclineReferralFormComponent classes={classes} innerRef={ref} {...rootProps} />;
  })
);

DeclineReferralForm.displayName = 'DeclineReferralForm';
DeclineReferralForm.defaultProps = { className: style.form };
