import { StoreStateReminderNotification } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { REMINDER_NOTIFICATION_STORAGE_KEY_DISMISSED } from '../../constants';

const Logger = getLoggerWithPrefix('Reducer', 'dismissReminderNotificationReducer:');

export const dismissReminderNotificationReducer = (state: StoreStateReminderNotification) => {
  try {
    Logger.info('== BEGIN ==');

    if (Utils.isNil(state.active)) return state;

    const dismissedList = [...state.dismissedList, state.active.eventObjectId];
    const queueList = [...state.queueList].filter(({ eventObjectId }) => eventObjectId !== state.active!.eventObjectId);

    window.sessionStorage.setItem(REMINDER_NOTIFICATION_STORAGE_KEY_DISMISSED, JSON.stringify(dismissedList));

    return { ...state, active: null, dismissedList, queueList };
  } finally {
    Logger.info('== END ==');
  }
};
