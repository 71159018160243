import { Constants, JsonObject, MessagingException, ReadonlyMessageBodyEvent, Utils } from '@sigmail/common';
import { DataObjectMsgBodyValue } from '@sigmail/objects';
import { ActionInitParams } from '../../base-action';
import { BaseSendMessageAction, BaseSendMessagePayload, BaseSendMessageState } from './base';

export interface Payload extends Omit<BaseSendMessagePayload, 'messageBody'> {
  messageBody: ReadonlyMessageBodyEvent;
}

type State = BaseSendMessageState;

export class SendEventResponseAction extends BaseSendMessageAction<Payload, State> {
  public constructor(params: ActionInitParams<Payload>) {
    super(params);

    const { messageBody, messageFormName } = params.payload;
    if (!Utils.isMessageFormNameEvent(messageFormName)) return;

    const { response } = messageBody.messageForm.value;
    if (Utils.isNil(response)) {
      throw new MessagingException('Event response data is either missing or invalid.');
    }
  }

  /** @override */
  protected validateMessageFormName() {
    const { messageFormName } = this.payload;
    if (!Utils.isMessageFormNameEvent(messageFormName)) {
      throw new MessagingException(`Expected message form name to be <${Constants.MessageFormName.Event}>; was <${messageFormName}>`);
    }
  }

  /** @override */
  protected async generateIdSequence() {
    await super.generateIdSequence();
    this.state.msgReadReceiptId = 0;
  }

  /** @override */
  protected async createMessageBodyValue(): Promise<DataObjectMsgBodyValue> {
    const { value: eventValue } = this.payload.messageBody.messageForm;

    return {
      $$formatver: 1,
      messageForm: {
        name: Constants.MessageFormName.Event,
        value: eventValue
      }
    };
  }

  /** @override */
  protected createSentMessage() {
    const message = super.createSentMessage();

    const { messageBody } = this.payload;
    const { dtServer } = this.state;

    const { response } = messageBody.messageForm.value;

    return {
      ...message,
      extraData: {
        response: ({
          ...response,
          decidedAt: dtServer.getTime()
        } as unknown) as JsonObject
      }
    };
  }

  /** @override */
  protected addInsertOperationForMessageReadReceipt(): Promise<void> {
    return Promise.resolve();
  }

  /** @override */
  protected addUpdateOperationForSentMessagesFolder(): Promise<void> {
    return Promise.resolve();
  }
}
