import { PartialRecord, Utils } from '@sigmail/common';
import { MessagingI18n } from '@sigmail/i18n';
import React from 'react';
import FolderIconUserDefinedPrimary from '../../../assets/images/folder-icon-user-defined-primary.min.svg';
import FolderIconUserDefinedSecondary from '../../../assets/images/folder-icon-user-defined-secondary.min.svg';
import { ReceivedMessages } from '../../../constants/action-ids';
import { EnglishCanada, FrenchCanada } from '../../../constants/language-codes';
import { ROUTE_MAIL_USER_DEFINED_FOLDER } from '../../../constants/route-identifiers';
import { useServiceWorker } from '../../../hooks';
import messagingI18n from '../../../i18n/messaging';
import { useLayoutContext } from '../../layout/context';
import layoutStyle from '../../layout/layout.module.css';
import { getRoutePath } from '../../routes';
import { GroupMessageFolderMapResource, UserMessageFolderMapResource } from '../hooks';
import { MessageFolderListItem } from './folder-list-item.component';
import { MessageFolderChildItem, MessageFolderItem, useMessageFolderItemList } from './hooks';

const {
  navAction: { composeConsultationRequest, composeHrmMessage, composeMessage, ...i18n }
} = messagingI18n;

type HtmlUListElementProps = Omit<React.ComponentPropsWithoutRef<'ul'>, 'children'>;

export type MessageFolderListClassKey = 'listItem' | 'anchor' | 'folderName' | 'folderItemCount';

export interface Props extends HtmlUListElementProps {
  children?: never;
  classes?: PartialRecord<MessageFolderListClassKey, string>;
  groupMessageFolderMapResource: GroupMessageFolderMapResource;
  onNavLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
  userMessageFolderMapResource: UserMessageFolderMapResource;
}

const DEFAULT_CLASSES: Record<MessageFolderListClassKey, string> = {
  listItem: layoutStyle.li,
  anchor: layoutStyle.anchor,
  folderName: layoutStyle['span-primary'],
  folderItemCount: layoutStyle['span-secondary']
};

export const MessageFolderList = React.forwardRef<HTMLUListElement, Props>(
  (
    {
      children,
      classes: classesProp,
      className,
      groupMessageFolderMapResource,
      onNavLinkClick,
      userMessageFolderMapResource,
      ...props
    },
    ref
  ) => {
    const classes = React.useMemo(() => Utils.defaults({}, classesProp as unknown, DEFAULT_CLASSES), [classesProp]);

    const layoutContext = useLayoutContext();
    const isDisplayModeStandalone = layoutContext?.displayMode === 'standalone';
    const isDisplayModeWindowControlsOverlay =
      !isDisplayModeStandalone && layoutContext?.displayMode === 'window-controls-overlay';
    const worker = useServiceWorker();
    const prevItemCount = React.useRef<number>();

    const renderMessageFolderListItem = React.useCallback(
      (folderItem: MessageFolderItem): React.ReactNode => {
        const { actionId, isUserDefinedFolder, children: subFolderItemList, itemCount } = folderItem;

        if (actionId === ReceivedMessages) {
          if (!isDisplayModeStandalone && !isDisplayModeWindowControlsOverlay) {
            prevItemCount.current = undefined;
          } else if (prevItemCount.current !== itemCount) {
            prevItemCount.current = itemCount;

            if (window.Notification.permission === 'default') {
              window.Notification.requestPermission().then(() => {
                prevItemCount.current = undefined;
              }, Utils.noop);
            } else {
              worker?.messageSW({ type: 'SET_APP_BADGE', payload: { count: itemCount } });
            }
          }
        }

        let action = { id: actionId, ...i18n[actionId as Exclude<MessagingI18n.NavActionId, `compose${string}`>] };
        if (isUserDefinedFolder === true) {
          const { folderId, parentFolderId } = folderItem as MessageFolderChildItem;
          const path = getRoutePath(ROUTE_MAIL_USER_DEFINED_FOLDER);
          action = {
            id: undefined!,
            iconPrimary: {
              alt: '',
              src: { [EnglishCanada]: FolderIconUserDefinedPrimary, [FrenchCanada]: FolderIconUserDefinedPrimary }
            },
            iconSecondary: {
              alt: '',
              src: { [EnglishCanada]: FolderIconUserDefinedSecondary, [FrenchCanada]: FolderIconUserDefinedSecondary }
            },
            label: actionId,
            to: path.replace(':folderId', parentFolderId.toString(10)).replace(':subFolderId', folderId.toString(10))
          };
        }

        const subFolderNodeList = Utils.isNonEmptyArray<MessageFolderChildItem>(subFolderItemList) && (
          <ul className={className}>{subFolderItemList.map(renderMessageFolderListItem)}</ul>
        );

        return (
          Utils.isNotNil(action) && (
            <MessageFolderListItem
              AnchorProps={{ className: classes.anchor, onClick: onNavLinkClick }}
              action={action}
              classes={{ folderName: classes.folderName, folderItemCount: classes.folderItemCount }}
              className={classes.listItem}
              folderItemCount={itemCount}
              key={actionId}
            >
              {subFolderNodeList}
            </MessageFolderListItem>
          )
        );
      },
      [
        className,
        classes.anchor,
        classes.folderItemCount,
        classes.folderName,
        classes.listItem,
        isDisplayModeStandalone,
        isDisplayModeWindowControlsOverlay,
        onNavLinkClick,
        worker
      ]
    );

    const messageFolderItemList = useMessageFolderItemList(groupMessageFolderMapResource, userMessageFolderMapResource);

    return Utils.isNonEmptyArray(messageFolderItemList) ? (
      <ul className={className} ref={ref} {...props}>
        {messageFolderItemList.map(renderMessageFolderListItem)}
      </ul>
    ) : null;
  }
);

MessageFolderList.displayName = 'MessageFolderList';
MessageFolderList.defaultProps = { className: layoutStyle.ul };
