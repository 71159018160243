import { InitOptions } from 'i18next';
import { EnglishCanada, FrenchCanada } from '../../constants/language-codes';
import backend from './backend';

// export const I18N_KEY_SEPARATOR: InitOptions['keySeparator'] = '.';
export const I18N_KEY_SEPARATOR: InitOptions['keySeparator'] = false;
export const I18N_NS_SEPARATOR: string = ':';
export const I18N_CONTEXT_SEPARATOR: string = '_';
export const I18N_PLURAL_SEPARATOR: string = '|';

/****************************** IMPORTANT **************************************
 *
 * make sure you know what you're doing before changing the following config
 *
 ******************************************************************************/
const i18nInitOptions: InitOptions = {
  debug: false,

  initImmediate: false,
  keySeparator: I18N_KEY_SEPARATOR,
  nsSeparator: I18N_NS_SEPARATOR,
  contextSeparator: I18N_CONTEXT_SEPARATOR,
  pluralSeparator: I18N_PLURAL_SEPARATOR,

  appendNamespaceToMissingKey: false,
  fallbackLng: false,
  fallbackNS: false,
  load: 'currentOnly',
  ns: [],
  supportedLngs: [EnglishCanada, FrenchCanada],

  joinArrays: false,
  returnObjects: false,
  interpolation: {
    escapeValue: false
  },

  react: {
    useSuspense: true
  },

  backend
};

export default i18nInitOptions;
