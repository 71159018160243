import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogHrmNonOntarioHpn } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeHrmMessage['dialog']['nonOntarioHpn'] = {
  // Translators: (HTML) Title for the Non-Ontario HPN dialog in Compose \
  // HRM Message view
  title: gettext('messaging', 'title', 'dialogHrmNonOntarioHpn', 'safe-html'),
  // Translators: (HTML) Message displayed in the body of Non-Ontario HPN \
  // dialog in Compose HRM Message view
  body: gettext('messaging', 'body', 'dialogHrmNonOntarioHpn', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Non-Ontario HPN dialog in \
      // Compose HRM Message view
      label: gettext('messaging', 'accept', 'dialogHrmNonOntarioHpnActionLabel'),
      context: DialogHrmNonOntarioHpn
    }
  }
};

export default i18n;
