import { AppUser, Utils } from '@sigmail/common';
import { GroupObjectPreferencesValue } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { accuroEMRGetOAuthUrl } from '../../../app-state/actions/EMR/accuro/get-oauth-url';
import { oscarEMRGetOAuthUrl } from '../../../app-state/actions/EMR/oscar/get-oauth-url';
import { useAppState } from '../../../app-state/hooks';
import { currentUserIdSelector } from '../../../app-state/selectors/auth';
import { clientIdSelector } from '../../../app-state/selectors/user-object';
import { tokenExpiryToDays } from '../../../utils/accuro-emr';

type AccuroEMROAuthParams = NonNullable<
  NonNullable<NonNullable<GroupObjectPreferencesValue['integrations']>['accuroEMR']>['oauthParams']
>;

type OscarEMROAuthParams = NonNullable<
  NonNullable<NonNullable<GroupObjectPreferencesValue['integrations']>['oscar']>['oauthParams']
>;

export interface UseTestEMRConnectionResult {
  (oauthParams: AccuroEMROAuthParams): Promise<string | false>;
  (oauthParams: OscarEMROAuthParams): Promise<string | false>;
}

export function useTestEMRConnection(): UseTestEMRConnectionResult {
  const [, appDispatch] = useAppState();
  const clientId = useSelector(clientIdSelector);
  const currentUserId = useSelector(currentUserIdSelector);

  return React.useCallback(
    (oauthParams: unknown): Promise<string | false> => {
      let promise: Promise<string>;
      if (
        AppUser.isValidId(clientId) &&
        AppUser.isValidId(currentUserId) &&
        Utils.isNonArrayObjectLike<AccuroEMROAuthParams | OscarEMROAuthParams>(oauthParams)
      ) {
        if ('uriBase' in oauthParams) {
          const { clientId: accuroClientId, clientSecret, tokenExpiry, uriBase, uuid } = Utils.mapValues(
            oauthParams,
            Utils.stringOrDefault
          );

          promise = appDispatch(
            accuroEMRGetOAuthUrl({
              clientId,
              oauthParams: {
                baseUri: uriBase,
                clientId: accuroClientId,
                clientSecret,
                tokenExpiry: tokenExpiryToDays(tokenExpiry),
                uuid
              }
            })
          );
        } else {
          const {
            clientKey,
            clientName,
            clientSecret,
            uriAuthorize: authorizationUri,
            uriGetToken: tokenUri,
            uriInitRequest: temporaryCredentialUri
          } = Utils.mapValues(oauthParams, Utils.stringOrDefault);

          promise = appDispatch(
            oscarEMRGetOAuthUrl({
              oauthParams: {
                authorizationUri,
                clientKey,
                clientSecret,
                name: clientName,
                temporaryCredentialUri,
                tokenUri
              },
              userId: currentUserId
            })
          );
        }
      } else {
        promise = Promise.reject();
      }

      return promise.then(
        (authUrl) => authUrl,
        () => false
      );
    },
    [appDispatch, clientId, currentUserId]
  );
}
