import { ApiActionPayload } from '@sigmail/app-state';
import { SigmailObjectId, Utils, Writeable } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ApiFormattedDataObject, CryptographicKey, IDataObject } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '..';
import { EMPTY_ARRAY } from '../constants';
import { DataObjectCache } from '../data-objects-slice/cache';
import { accessTokenSelector, authClaimSelector } from '../selectors/auth';
import { batchQuerySuccessAction } from './batch-query-success-action';

export const batchQueryDataAction = (payload: ApiActionPayload.BatchQueryData): AppThunk<Promise<Api.BatchQueryResponseData>> => {
  return async (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'batchQueryDataAction:');

    Logger.info('== BEGIN ==');
    let responseJson = {} as Writeable<Api.BatchQueryResponseData>;
    let success = false;
    try {
      const apiAccessToken = Utils.stringOrDefault(payload.accessToken, accessTokenSelector(getState()));

      let { authState, dataObjects, encryptedFor } = payload.query;
      authState =
        (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') && authState === null
          ? undefined!
          : Utils.stringOrDefault(authState, authClaimSelector(getState()));

      if (Utils.isUndefined(encryptedFor)) {
        encryptedFor = {
          ids: Array.from(CryptographicKey.encryptedForIds())
        };
      }

      // create a list of immutable data objects which are already cached;
      // if found, we can skip batch query for those and use cached data instead
      const [cachedDataObjectList, idsToFetch] = Utils.arrayOrDefault<SigmailObjectId>(dataObjects?.ids, EMPTY_ARRAY).reduce<
        [Array<ApiFormattedDataObject>, Array<SigmailObjectId>]
      >(
        (result, id) => {
          const match = DataObjectCache.find<IDataObject<any>>(id);
          if (Utils.isNil(match) || match[0].version > 0) {
            result[1].push(id);
          } else {
            result[0].push(match[0].toApiFormatted());
          }
          return result;
        },
        [[], []]
      );

      dataObjects = idsToFetch.length > 0 ? { ids: idsToFetch } : undefined;
      const query: Api.BatchQueryRequestData = { ...payload.query, authState, dataObjects, encryptedFor };
      responseJson = await apiService.batchQueryData(apiAccessToken, query);

      // if we skipped fetch for one or more immutable data objects earlier because
      // they were available in cache, we add those manually to response JSON
      if (cachedDataObjectList.length > 0) {
        responseJson = {
          ...responseJson,
          dataObjects: Utils.arrayOrDefault<ApiFormattedDataObject>(responseJson.dataObjects, EMPTY_ARRAY).concat(cachedDataObjectList)
        };
      }

      success = true;
    } finally {
      if (success && payload.cache !== null) {
        await dispatch(
          batchQuerySuccessAction({
            request: payload.query,
            response: responseJson as ApiActionPayload.BatchQueryDataSuccess['response'],
            cache: payload.cache
          })
        );
      }
      Logger.info('== END ==');
    }
    return responseJson;
  };
};
