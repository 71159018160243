import { GroupsI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: GroupsI18n.Form.InviteUsersToGroup = {
  fieldsetDefault: {
    formField: {
      contact: {
        error: {
          // Translators: Error message displayed when no contact is selected
          tooShort: gettext('groups', 'contactListTooShort', 'inviteUsersToGroupFormError')
        },
        // Translators: Label for the Contact input
        label: gettext('groups', 'contact', 'inviteUsersToGroupFormInputLabel'),
        localeText: {
          // Translators: Label for the Close Popup icon of Contact input
          closePopup: gettext('messaging', 'closeContactPopup', 'inviteUsersToGroupFormLocaleText'),
          // Translators: Text to display when there are no contacts to show \
          // in Contact input
          noOptions: gettext('messaging', 'noContacts', 'inviteUsersToGroupFormLocaleText'),
          // Translators: Label for the Open Popup icon of Contact input
          openPopup: gettext('messaging', 'openContactPopup', 'inviteUsersToGroupFormLocaleText')
        }
      },
      group: {
        error: {
          // Translators: Error message displayed when no group is selected
          valueMissing: gettext('groups', 'groupRequired', 'inviteUsersToGroupFormError')
        },
        // Translators: Label for the Group input
        label: gettext('groups', 'group', 'inviteUsersToGroupFormInputLabel'),
        localeText: {
          // Translators: Label for the Close Popup icon of Group input
          closePopup: gettext('messaging', 'closeGroupPopup', 'inviteUsersToGroupFormLocaleText'),
          // Translators: Text to display when there are no groups to show \
          // in Group input
          noOptions: gettext('messaging', 'noGroups', 'inviteUsersToGroupFormLocaleText'),
          // Translators: Label for the Open Popup icon of Group input
          openPopup: gettext('messaging', 'openGroupPopup', 'inviteUsersToGroupFormLocaleText')
        }
      }
    }
  }
};

export default i18n;
