import { AccountI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: AccountI18n.Form.NewPassword = {
  fieldsetCriteria: {
    formField: {
      maxLength: {
        // Translators: Text for password criteria indicating the maximum \
        // length requirement.
        // Translators: Token available: {{LENGTH}}
        label: gettext('account', 'maxLength', 'passwordCriteria')
      },
      minLength: {
        // Translators: Text for password criteria indicating the minimum \
        // length requirement.
        // Translators: Token available: {{LENGTH}}
        label: gettext('account', 'minLength', 'passwordCriteria')
      },
      minOneNumberAndSymbol: {
        // Translators: (HTML) Text for password criteria indicating that at \
        // least one number and one symbol is required
        // Translators: Token available: {{SYMBOL_LIST}}
        label: gettext('account', 'minOneNumberAndSymbol', 'passwordCriteria', 'safe-html')
      },
      upperLowerChars: {
        // Translators: Text for password criteria indicating that both \
        // uppercase and lowercase characters are required
        label: gettext('account', 'upperLowerChars', 'passwordCriteria')
      }
    },
    // Translators: Label for the Password Criteria legend
    label: gettext('account', 'legend', 'passwordCriteria')
  },
  fieldsetPassword: {
    formField: {
      confirmPassword: {
        // Translators: ARIA-label for the Show Password icon
        ariaLabelShowPassword: gettext('account', 'showConfirmPassword', 'newPasswordFormAriaLabel'),
        // Translators: ARIA-label for the Hide Password icon
        ariaLabelHidePassword: gettext('account', 'hideConfirmPassword', 'newPasswordFormAriaLabel'),
        error: {
          // Translators: Error message displayed when Confirm Password is \
          // left blank
          valueMissing: gettext('account', 'confirmPasswordRequired', 'newPasswordFormError')
        },
        // Translators: Label for the Confirm Password input
        label: gettext('account', 'confirmPassword', 'newPasswordFormInputLabel')
      },
      password: {
        // Translators: ARIA-label for the Hide Password icon
        ariaLabelHidePassword: gettext('account', 'hidePassword', 'newPasswordFormAriaLabel'),
        // Translators: ARIA-label for the Show Password icon
        ariaLabelShowPassword: gettext('account', 'showPassword', 'newPasswordFormAriaLabel'),
        error: {
          // Translators: Error message displayed password contains either \
          // leading or trailing white-space characters
          leadingTrailingSpace: gettext('account', 'passwordLeadingTrailingSpace', 'newPasswordFormError'),
          // Translators: Error message displayed when Password does not \
          // satisfy length requirement
          // Translators: Available tokens: {{MIN_LENGTH}}, {{MAX_LENGTH}}
          length: gettext('account', 'passwordLength', 'newPasswordFormError'),
          // Translators: Error message displayed when Password does not \
          // match with the value entered in Confirm Password input
          passwordMismatch: gettext('account', 'passwordMismatch', 'newPasswordFormError'),
          // Translators: Error message displayed when Password does not \
          // satisfy the criteria required
          patternMismatch: gettext('account', 'passwordPattern', 'newPasswordFormError'),
          // Translators: Error message displayed when Password is left blank
          valueMissing: gettext('account', 'passwordRequired', 'newPasswordFormError')
        },
        // Translators: Label for the Password input
        label: gettext('account', 'password', 'newPasswordFormInputLabel')
      }
    }
  }
};

export default i18n;
