import type { SigmailObjectTypeCode } from '@sigmail/common';
import { AppUserGroup, Utils } from '@sigmail/common';
import { UserObject } from '.';
import type { UserObjectPreferencesValue as DV } from '../types';

const TYPE = process.env.USER_OBJECT_TYPE_PREFERENCES;

/** @public */
export class UserObjectPreferences extends UserObject<DV> {
  public static override get TYPE(): SigmailObjectTypeCode {
    return TYPE;
  }

  protected static override isValidDecryptedValue(value: unknown): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const dv = value as DV;
    return (
      (Utils.isNil(dv.languagePreference) || Utils.isString(dv.languagePreference)) &&
      (Utils.isNil(dv.lastActiveGroupId) || AppUserGroup.isValidId(dv.lastActiveGroupId)) &&
      (Utils.isNil(dv.messageCategoryList) || Utils.isArray(dv.messageCategoryList)) &&
      (Utils.isNil(dv.messageSignature) || Utils.isString(dv.messageSignature)) &&
      (Utils.isNil(dv.noNotifyOnNewMessage) ||
        typeof dv.noNotifyOnNewMessage === 'boolean' ||
        Utils.isString(dv.noNotifyOnNewMessage) ||
        Utils.isArray(dv.noNotifyOnNewMessage)) &&
      (Utils.isNil(dv.pushSubscriptionList) || Utils.isArray(dv.pushSubscriptionList)) &&
      (Utils.isNil(dv.referralExpiry) || Utils.isInteger(dv.noNotifyOnNewMessage))
    );
  }

  public override updateValue(newValue: DV): Promise<UserObjectPreferences> {
    const Class = this.constructor as typeof UserObjectPreferences;
    return Class.updateValue(this, newValue);
  }
}
