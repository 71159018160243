import { AppUserGroup, MembershipStatus, Nullable, Utils } from '@sigmail/common';
import { ContactListItemExt, IUserObject, UserObjectContactInfoValue } from '@sigmail/objects';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFlattenedClientUserList } from '.';
import { useThrowAsyncError } from '../../hooks';
import membershipStatusI18n from '../../i18n/global/membership-status';
import {
  BatchQueryRequest,
  compareContactListItem,
  ExcludeListItem,
  filterContactList,
  FilterFunction
} from '../../utils/contact-list';
import { EMPTY_ARRAY, EMPTY_PLAIN_OBJECT } from '../constants';
import { activeGroupIdSelector } from '../selectors';
import { memberListSelector as groupMemberListSelector } from '../selectors/group-object';
import { Cache as UserObjectCache, UserObjectCache as GlobalUserObjectCache } from '../user-objects-slice/cache';
import { useDispatchFetchUserObjects } from './use-dispatch-fetch-user-objects';

export interface UseGroupMemberContactListParams {
  cache?: typeof GlobalUserObjectCache;
  exclude?: Nullable<ReadonlyArray<Nullable<ExcludeListItem>>>;
  filterFn?: Nullable<FilterFunction<ContactListItemExt>>;
  forceFetch?: Nullable<boolean>;
  groupId?: Nullable<number>;
  unsorted?: Nullable<boolean>;
  users?: Nullable<boolean | MembershipStatus>;
}

export type UseGroupMemberContactListResult = [list: ReadonlyArray<ContactListItemExt>, query: BatchQueryRequest];

const TYPE_CONTACT_INFO = process.env.USER_OBJECT_TYPE_CONTACT_INFO;
const TYPE_GROUP_PROFILE = process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC;

const isValidMembershipStatusKey = (value?: any): value is MembershipStatus =>
  Object.keys(membershipStatusI18n).includes(value);

export const useGroupMemberContactList = (params?: Nullable<UseGroupMemberContactListParams>) => {
  const [key, setKey] = React.useState(0);
  const scopedUserObjectCache = React.useRef(new UserObjectCache());
  const cache = Utils.isNotNil(params?.cache) ? params!.cache! : scopedUserObjectCache.current;
  const includeUserList = params?.users === true || isValidMembershipStatusKey(params?.users) ? params!.users! : false;
  const exclude = params?.exclude;
  const filterFn = typeof params?.filterFn === 'function' ? params!.filterFn! : filterContactList;
  const unsorted = params?.unsorted === true;

  const [flattenedUserList, userListQuery] = useFlattenedClientUserList({
    disabledMembershipList: true,
    forceFetch: params?.forceFetch === true,
    users: includeUserList
  });

  const memberListSelector = useSelector(groupMemberListSelector);
  let groupId = useSelector(activeGroupIdSelector);
  if (Utils.isNotNil(params?.groupId)) groupId = params!.groupId!;
  const memberList = params?.forceFetch === true ? undefined : memberListSelector(groupId);

  const dispatchFetchUserObjects = useDispatchFetchUserObjects();
  const throwAsyncError = useThrowAsyncError();

  return React.useMemo((): UseGroupMemberContactListResult => {
    let contactList: ReadonlyArray<ContactListItemExt> = EMPTY_ARRAY;

    const userObjects: Required<BatchQueryRequest>['userObjectsByType'] = [];
    if (Utils.isArray(userListQuery.userObjectsByType)) {
      userObjects.push(...userListQuery.userObjectsByType);
    }

    do {
      if (!includeUserList) break;
      if (!AppUserGroup.isValidId(groupId)) break;
      if (userObjects.length > 0) break;

      // dummy condition to quiet eslint from complaining about an unnecessary
      // dependency
      if (key < 0) break;

      if (Utils.isNil(memberList)) {
        userObjects.push({ type: TYPE_GROUP_PROFILE, userId: groupId });
      } else {
        const contactInfoObjectsToFetch = memberList.reduce((list, { id: memberId }) => {
          let obj = cache.find(({ type, userId }) => type === TYPE_CONTACT_INFO && userId === memberId);
          if (Utils.isNil(obj)) {
            obj = GlobalUserObjectCache.find(({ type, userId }) => type === TYPE_CONTACT_INFO && userId === memberId);
            if (Utils.isNotNil(obj)) {
              cache.add(...obj);
            } else {
              list.push({ type: TYPE_CONTACT_INFO, userId: memberId });
            }
          }
          return list;
        }, [] as typeof userObjects);

        if (contactInfoObjectsToFetch.length > 0) {
          contactList = undefined!;
          dispatchFetchUserObjects({ userObjects: contactInfoObjectsToFetch }, cache).then(
            () => setKey((prevKey) => ++prevKey),
            throwAsyncError
          );
          break;
        }
      }

      if (userObjects.length > 0) break;

      contactList = memberList!.reduce((list, { id: memberId }) => {
        const index = flattenedUserList.findIndex(({ id }) => id === memberId);
        if (index === -1) return list;

        const { timestamp: accountTimestamp, ...extUserData } = flattenedUserList[index];
        if (includeUserList === true || extUserData.accountStatus === includeUserList) {
          let [, contactInfo] = cache.find<IUserObject<UserObjectContactInfoValue>>(
            ({ type, userId }) => type === TYPE_CONTACT_INFO && userId === memberId
          )!;

          if (Utils.isNil(contactInfo)) {
            contactInfo = {} as UserObjectContactInfoValue;
          }

          list.push({
            type: 'user',
            id: memberId,
            userData: {
              ...Utils.omit(contactInfo, '$$formatver'),
              ...extUserData,
              accountTimestamp
            }
          });
        }

        return list;
      }, [] as Array<typeof contactList[0]>);

      contactList = filterFn(contactList, { exclude });

      if (!unsorted && contactList.length > 1) {
        contactList = contactList.slice().sort(compareContactListItem);
      }
    } while (false);

    let query: UseGroupMemberContactListResult[1] = EMPTY_PLAIN_OBJECT;
    if (userObjects.length > 0) query = { userObjectsByType: userObjects };

    return [contactList, query];
  }, [
    cache,
    dispatchFetchUserObjects,
    exclude,
    filterFn,
    flattenedUserList,
    groupId,
    includeUserList,
    key,
    memberList,
    throwAsyncError,
    unsorted,
    userListQuery.userObjectsByType
  ]);
};
