import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MessagingI18n.Form.OneTimeMessage.AccessCode = {
  fieldsetExpire: {
    label: gettext('messaging', 'expire', 'oneTimeMessageAccessCodeFormFieldsetLabel'),
    formField: {
      expire: {
        label: '',
        placeholder: gettext('messaging', 'expire', 'oneTimeMessageAccessCodeFormFieldHint'),
        error: {
          tooLong: gettext('messaging', 'expireTooLong', 'oneTimeMessageAccessCodeFormError'),
          tooShort: gettext('messaging', 'expireTooShort', 'oneTimeMessageAccessCodeFormError'),
          valueMissing: gettext('messaging', 'expireRequired', 'oneTimeMessageAccessCodeFormError')
        },
        options: [
          {
            codedValue: 'D',
            label: gettext('messaging', 'days', 'oneTimeMessageAccessCodeFormFieldExpireOption')
          },
          {
            codedValue: 'W',
            label: gettext('messaging', 'weeks', 'oneTimeMessageAccessCodeFormFieldExpireOption')
          },
          {
            codedValue: 'M',
            label: gettext('messaging', 'months', 'oneTimeMessageAccessCodeFormFieldExpireOption')
          },
          {
            codedValue: 'Y',
            label: gettext('messaging', 'years', 'oneTimeMessageAccessCodeFormFieldExpireOption')
          }
        ]
      }
    }
  },
  fieldsetPhoneNumber: {
    label: gettext('messaging', 'phoneNumber', 'oneTimeMessageAccessCodeFormFieldsetLabel'),
    formField: {
      phoneNumber: {
        label: '',
        placeholder: gettext('messaging', 'phoneNumber', 'oneTimeMessageAccessCodeFormFieldHint'),
        error: {
          patternMismatch: gettext('messaging', 'phoneNumberPattern', 'oneTimeMessageAccessCodeFormError'),
          unique: gettext('messaging', 'phoneNumberUnique', 'oneTimeMessageAccessCodeFormError'),
          valueMissing: gettext('messaging', 'phoneNumberRequired', 'oneTimeMessageAccessCodeFormError')
        }
      }
    }
  }
};

export default i18n;
