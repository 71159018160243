import { CircularProgress } from '@material-ui/core';
import { ActionPayloadAuthSuccess, ActionPayloadSignIn, MFAActionPayload } from '@sigmail/app-state';
import { AppException, CancelablePromise, Constants, Utils } from '@sigmail/common';
import { SubmissionErrors } from 'final-form';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { connect, ConnectedProps as ReduxConnectedProps } from 'react-redux';
import { Redirect } from 'react-router';
import { IAuthenticationData } from 'sigmail';
import { AppDispatch } from '../../../app-state';
import { sendOTPAction } from '../../../app-state/actions/MFA/send-otp-action';
import { verifyOTPAction } from '../../../app-state/actions/MFA/verify-otp-action';
import { signInAction } from '../../../app-state/auth-slice/sign-in-action';
import { RootState } from '../../../app-state/root-reducer';
import * as RootSelectors from '../../../app-state/selectors';
import * as AuthSelectors from '../../../app-state/selectors/auth';
import { FormCancel, FormSubmit, ResetPassword } from '../../../constants/action-ids';
import { ROUTE_RESET_PASSWORD } from '../../../constants/route-identifiers';
import { withTranslation } from '../../../i18n';
import { I18N_NS_GLOBAL, I18N_NS_MFA } from '../../../i18n/config/namespace-identifiers';
import globalI18n from '../../../i18n/global';
import { generateCredentialHash } from '../../../utils/generate-credential-hash';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { getRoutePath } from '../../routes';
import { ErrorMessageSnackbar } from '../../shared/error-message-snackbar.component';
import {
  FormValues as SignInFormValues,
  SignInCredentialsForm as SignInForm
} from '../../shared/form-sign-in-credentials.component';
import {
  ERROR_RESULT_OTP_CODE_MISMATCH,
  FormValues as VerifyMfaFormValues,
  Props as VerifyMfaFormProps,
  VerifyMfaCodeForm as VerifyMfaForm
} from '../../shared/form-verify-mfa-code.component';
import { FormComponentProps, FormStateChangeEvent } from '../../shared/form/form.component';
import { RouterNavLink, RouterNavLinkProps } from '../../shared/router-nav-link.component';
import { SubmitButton } from '../../shared/submit-button.component';
import style from './sign-in-page.module.css';

export interface Props {
  children?: never;
}

const { signInPage: i18n } = globalI18n;
const TOKEN_RESEND_CODE = '{{RESEND_CODE_ACTION}}';

const mapStateToProps = (state: RootState) => ({
  isUserLoggedIn: AuthSelectors.isUserLoggedInSelector(state),
  lastAuthErrorCode: AuthSelectors.lastAuthErrorCodeSelector(state),
  otpClaim: AuthSelectors.otpClaimSelector(state),
  homeRoutePath: RootSelectors.homeRoutePathSelector(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dispatch,

  /** Dispatches the {@link signInAction}. */
  dispatchSignInAction(
    payload: SignInFormValues,
    attempt: number,
    beforeInitializeSession?: ActionPayloadSignIn['beforeInitializeSession']
  ) {
    let { username, password } = payload;

    username = username.trim().toLowerCase();
    const credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN, { username });
    return dispatch(signInAction({ attempt, username, password, credentialHash, beforeInitializeSession }));
  },

  dispatchSendOTP(payload: MFAActionPayload.SendOTP) {
    return dispatch(sendOTPAction(payload));
  },

  dispatchVerifyOTP(payload: MFAActionPayload.VerifyOTP) {
    return dispatch(verifyOTPAction(payload));
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ConnectedProps = Omit<ReduxConnectedProps<typeof withConnect>, keyof Props>;

interface ComponentProps extends Props, ConnectedProps, WithTranslation {}

interface State {
  accessToken: IAuthenticationData['accessToken'];
  activeFormId?: string | undefined;
  activeStep: 'signIn' | 'verifyMfa';
  attempt: number;
  continueSignIn: (...args: any) => any;
  isFormSubmitting: boolean;
  otpClaim: string;
  sendOTPPromise?: CancelablePromise<any> | null | undefined;
  snackbarMessage?: string | null | undefined;
}

class SignInPageComponent extends React.PureComponent<ComponentProps, State> {
  private readonly refForm: React.MutableRefObject<HTMLFormElement | null> = { current: null };

  public constructor(props: ComponentProps) {
    super(props);

    this.state = {
      accessToken: '',
      activeStep: 'signIn',
      attempt: 1,
      continueSignIn: Utils.noop,
      isFormSubmitting: false,
      otpClaim: ''
    };

    this.setFormRef = this.setFormRef.bind(this);
    this.onResetPasswordClick = this.onResetPasswordClick.bind(this);
    this.onClickActionResendCode = this.onClickActionResendCode.bind(this);
    this.onFormStateChange = this.onFormStateChange.bind(this);
    this.onFormSubmitSignIn = this.onFormSubmitSignIn.bind(this);
    this.mfaCodeValidator = this.mfaCodeValidator.bind(this);
    this.beforeInitializeSession = this.beforeInitializeSession.bind(this);
    this.resetSnackbar = this.resetSnackbar.bind(this);
    this.navigateToHome = this.navigateToHome.bind(this);
  }

  public componentDidUpdate(_: Readonly<Props>, { activeStep: previousStep }: Readonly<State>): void {
    const { activeStep } = this.state;

    if (previousStep === 'signIn' && activeStep === 'verifyMfa') {
      this.dispatchSendOTP();
    }
  }

  public render(): React.ReactNode {
    const { isUserLoggedIn, homeRoutePath, t } = this.props;
    const { activeStep, isFormSubmitting, sendOTPPromise, otpClaim, snackbarMessage } = this.state;

    if (isUserLoggedIn) {
      return <Redirect to={homeRoutePath} />;
    }

    let heading1: string;
    let leadText: string | undefined = undefined;

    let FormComponent: React.ComponentType<FormComponentProps<any, any>>;
    const formProps: FormComponentProps<any, any> = {
      innerRef: this.setFormRef,
      onFormStateChange: this.onFormStateChange
    };

    switch (activeStep) {
      case 'signIn': {
        heading1 = t(i18n.signInForm.heading1);
        leadText = t(i18n.signInForm.leadText);

        FormComponent = SignInForm;
        formProps.styleName = 'style.form-sign-in';
        formProps.onSubmit = this.onFormSubmitSignIn;

        break;
      }
      case 'verifyMfa': {
        heading1 = t(i18n.verifyMfaForm.heading1);

        const { method, email, phoneNumber } = Utils.decodeIdToken(otpClaim);
        if (method === 'sms' && Utils.isString(phoneNumber)) {
          leadText = t(i18n.verifyMfaForm.leadText.sms, { MFA_CONTACT: Utils.maskPhoneNumber(phoneNumber) });
        } else if (method === 'email' && Utils.isString(email)) {
          leadText = t(i18n.verifyMfaForm.leadText.email, { MFA_CONTACT: Utils.maskEmailAddress(email) });
        }

        FormComponent = VerifyMfaForm;
        formProps.disabled = isFormSubmitting || Utils.isNotNil(sendOTPPromise);
        formProps.styleName = 'style.form-verify-mfa';
        (formProps as VerifyMfaFormProps).codeValidator = this.mfaCodeValidator;
        formProps.onSubmit = this.state.continueSignIn;

        break;
      }
      default:
        return null;
    }

    return (
      <React.Fragment>
        <ErrorMessageSnackbar
          message={snackbarMessage!}
          open={Utils.isString(snackbarMessage)}
          onClose={this.resetSnackbar}
          closeText={t(globalI18n.ariaLabelClosePopup)}
        />

        <div className={style.root}>
          <div className="row">
            <div
              styleName="style.heading1"
              role="heading"
              aria-level={1}
              dangerouslySetInnerHTML={{ __html: heading1 }}
            />

            {Utils.isString(leadText) && (
              <div styleName="style.leadText" dangerouslySetInnerHTML={{ __html: leadText }} />
            )}

            <FormComponent {...formProps} />

            {this.renderResendCodeAction()}
            {activeStep === 'verifyMfa' && this.renderCancelAction()}
            {this.renderSubmitAction()}
            {this.renderResetPasswordAction()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  private renderResendCodeAction(): React.ReactNode {
    const { t } = this.props;
    const { activeStep, isFormSubmitting, sendOTPPromise } = this.state;

    if (activeStep !== 'verifyMfa') return null;

    const isSendCodeInProgress = Utils.isNotNil(sendOTPPromise);
    const className: string | undefined = isFormSubmitting || isSendCodeInProgress ? 'disabled' : undefined;
    const resendCodeText = t(i18n.verifyMfaForm.resendCode, { RESEND_CODE_ACTION: TOKEN_RESEND_CODE });
    const index = resendCodeText.indexOf(TOKEN_RESEND_CODE);

    let textToPrepend = '';
    let textToAppend = '';
    if (index !== -1) {
      textToPrepend = resendCodeText.slice(0, index).trim();
      textToAppend = resendCodeText.slice(index + TOKEN_RESEND_CODE.length).trim();
    }

    return (
      <div styleName="style.wrapper-action-resend-code">
        {textToPrepend.length > 0 && <span styleName="style.before">{textToPrepend}</span>}
        <a className={className} styleName="style.action-resend-code" href="/" onClick={this.onClickActionResendCode}>
          <span>{t(resolveActionLabel(i18n.verifyMfaForm.action.resendCode))}</span>
        </a>
        {textToAppend.length > 0 && <span styleName="style.after">{textToAppend}</span>}
        {isSendCodeInProgress && <CircularProgress color="primary" size="0.75rem" />}
      </div>
    );
  }

  private renderCancelAction(): React.ReactNode {
    const { isFormSubmitting, sendOTPPromise } = this.state;

    return (
      <button
        data-action-id={FormCancel}
        disabled={isFormSubmitting || Utils.isNotNil(sendOTPPromise)}
        onClick={this.navigateToHome}
        styleName="style.btn-cancel"
        type="button"
      >
        {this.props.t(resolveActionLabel(i18n.verifyMfaForm.action.cancel, FormCancel))}
      </button>
    );
  }

  private renderSubmitAction(): React.ReactNode {
    const { activeStep, isFormSubmitting, sendOTPPromise, activeFormId } = this.state;

    let actionId = FormSubmit;
    let actionLabel = i18n.signInForm.action.submit;
    let disabled = isFormSubmitting;

    if (activeStep === 'verifyMfa') {
      actionLabel = i18n.verifyMfaForm.action.submit;
      disabled = isFormSubmitting || Utils.isNotNil(sendOTPPromise);
    }

    return (
      <SubmitButton
        data-action-id={actionId}
        disabled={disabled}
        form={activeFormId}
        progress={isFormSubmitting}
        styleName="style.btn-submit"
      >
        {this.props.t(resolveActionLabel(actionLabel, actionId))}
      </SubmitButton>
    );
  }

  private renderResetPasswordAction(): React.ReactNode {
    const { activeStep, isFormSubmitting } = this.state;

    if (activeStep !== 'signIn') return null;

    const className = [style['action-forgot-password'], isFormSubmitting && 'disabled'];
    const actionProps: RouterNavLinkProps = {
      className: className.filter(Boolean).join(' '),
      to: getRoutePath(ROUTE_RESET_PASSWORD),
      onClick: this.onResetPasswordClick
    };

    return (
      <RouterNavLink {...actionProps} data-action-id={ResetPassword}>
        {this.props.t(resolveActionLabel(i18n.signInForm.action.resetPassword, ResetPassword))}
      </RouterNavLink>
    );
  }

  private setFormRef(instance: HTMLFormElement | null): void {
    this.refForm.current = instance;
    this.setState({ activeFormId: instance?.id });
  }

  private onResetPasswordClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    const { isFormSubmitting } = this.state;

    if (isFormSubmitting) {
      event.preventDefault();
      event.stopPropagation();

      return;
    }
  }

  private onClickActionResendCode(event: React.MouseEvent<HTMLAnchorElement>): void {
    const { isFormSubmitting, sendOTPPromise } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (isFormSubmitting || Utils.isNotNil(sendOTPPromise)) {
      return;
    }

    this.dispatchSendOTP();
  }

  private onFormStateChange({ formState: { submitting } }: FormStateChangeEvent): void {
    this.setState({ isFormSubmitting: submitting === true });
  }

  private async onFormSubmitSignIn(values: SignInFormValues): Promise<SubmissionErrors> {
    const { dispatchSignInAction } = this.props;
    const { attempt } = this.state;

    try {
      const response = await dispatchSignInAction(values, attempt, this.beforeInitializeSession);
      if (this.props.lastAuthErrorCode !== Constants.Error.S_OK) {
        throw new AppException(Constants.Error.E_AUTH_FAIL);
      }

      this.setState({ accessToken: response!.accessToken });
    } catch {
      this.setState(({ attempt }, { t }) => ({
        attempt: attempt + 1,
        snackbarMessage: t(i18n.signInForm.errorMessageAuthFail)
      }));
    }

    return;
  }

  private async mfaCodeValidator(code: VerifyMfaFormValues['code']): Promise<any> {
    const { dispatchVerifyOTP } = this.props;
    const { accessToken, otpClaim: authState } = this.state;

    try {
      await dispatchVerifyOTP({ accessToken, authState, code });
    } catch {
      return ERROR_RESULT_OTP_CODE_MISMATCH;
    }

    return null;
  }

  private dispatchSendOTP(): void {
    this.setState(({ accessToken, otpClaim: authState }, { dispatchSendOTP }) => {
      const sendOTPPromise = Utils.makeCancelablePromise(dispatchSendOTP({ accessToken, authState }));
      sendOTPPromise.promise
        .then(() => this.setState({ sendOTPPromise: null }))
        .catch((error) => {
          if (Utils.isNonArrayObjectLike<{ isCanceled: boolean }>(error) && error.isCanceled === true) return;

          this.setState({ sendOTPPromise: null });
        });
      return { sendOTPPromise };
    });
  }

  private beforeInitializeSession(authData: ActionPayloadAuthSuccess): Promise<void> {
    const { accessToken, otpClaim } = authData;

    if (Utils.isValidJwtToken(otpClaim, 'id')) {
      return new Promise((resolve) => {
        this.setState({ activeStep: 'verifyMfa', accessToken, otpClaim, continueSignIn: resolve });
      });
    }

    return Promise.resolve();
  }

  private resetSnackbar(): void {
    this.setState({ snackbarMessage: undefined });
  }

  private navigateToHome(): void {
    window.location.href = '/';
  }
}

export const SignInPage = withConnect(withTranslation([I18N_NS_GLOBAL, I18N_NS_MFA])(SignInPageComponent));
