import { GroupsI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { GroupActionToolbar } from '../../constants/action-context';

const i18n: GroupsI18n.GroupToolbarActionI18n = {
  assignUsersToGroup: {
    // Translators: Label for the Assign Users To Group action
    label: gettext('groups', 'assignUsersToGroup', 'groupToolbarActionLabel'),
    context: GroupActionToolbar
  },
  inviteUsersToGroup: {
    // Translators: Label for the Invite Users To Group action
    label: gettext('groups', 'inviteUsersToGroup', 'groupToolbarActionLabel'),
    context: GroupActionToolbar
  },
  removeUsersFromGroup: {
    // Translators: Label for the Remove Users From Group action
    label: gettext('groups', 'removeUsersFromGroup', 'groupToolbarActionLabel'),
    context: GroupActionToolbar
  },
  transferUsersToGroup: {
    // Translators: Label for the Transfer Users To Group action
    label: gettext('groups', 'transferUsersToGroup', 'groupToolbarActionLabel'),
    context: GroupActionToolbar
  }
};

export default i18n;
