import { createSelector } from '@reduxjs/toolkit';
import { SigmailGroupId, Utils } from '@sigmail/common';
import { getRoutePath } from '../../app/routes';
import * as RouteId from '../../constants/route-identifiers';
import { RootState } from '../root-reducer';
import { isUserLoggedInSelector, selectAccessRight } from './auth';
import { userRoleSelector } from './user-object';

export const homeRoutePathSelector = createSelector(
  isUserLoggedInSelector,
  selectAccessRight,
  userRoleSelector,
  (isUserLoggedIn, hasAccessRight, selectUserRole) => {
    let path = getRoutePath(RouteId.ROUTE_SIGN_IN);
    if (isUserLoggedIn) {
      path = getRoutePath(RouteId.ROUTE_APP_ROOT);

      const userRoleId = selectUserRole(/***/);
      if (!Utils.isCaregiverRole(userRoleId)) {
        if (hasAccessRight('accessMailbox') || hasAccessRight('accessGroupMailbox')) {
          path = getRoutePath(RouteId.ROUTE_MAIL);
        } else if (hasAccessRight('accessMemberManagement')) {
          path = getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_MEMBERS);
        } else if (hasAccessRight('accessOwnAccount')) {
          path = getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_SELF);
        }
      }
    }
    return path;
  }
);

export const activeGroupIdSelector: Reselect.Selector<RootState, SigmailGroupId> = (state) => state.activeGroup;

/** Selector to extract the session ID from app state. */
export const sessionIdSelector: Reselect.Selector<RootState, string> = (state) => state.sessionId;
