import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogConsultationRequestMissingBillingNumber } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeConsultationRequest['dialog']['missingBillingNumber'] = {
  // Translators: (HTML) Title for the Missing Billing Number dialog in \
  // Compose Consultation Request view
  title: gettext('messaging', 'title', 'dialogConsultationRequestMissingBillingNumber', 'safe-html'),
  // Translators: (HTML) Message displayed in the body of Missing Billing \
  // Number dialog in Compose Consultation Request view
  body: gettext('messaging', 'body', 'dialogConsultationRequestMissingBillingNumber', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Close action of Missing Billing Number \
      // dialog in Compose Consultation Request view
      label: gettext('messaging', 'accept', 'dialogConsultationRequestMissingBillingNumberActionLabel'),
      context: DialogConsultationRequestMissingBillingNumber
    }
  }
};

export default i18n;
