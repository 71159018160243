import { Backdrop as MuiBackdrop, BackdropProps, Theme, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = ({ zIndex }: Theme) =>
  createStyles({
    root: { zIndex: zIndex.drawer + 1 }
  });

export interface Props extends Omit<BackdropProps, 'ref' | 'classes'> {}

interface ComponentProps extends Props, WithStyles<typeof styles> {}

const BackdropComponent: React.FC<ComponentProps> = (props) => <MuiBackdrop {...props} />;
BackdropComponent.defaultProps = { open: false };

export const Backdrop = React.memo(withStyles(styles)(BackdropComponent));
Backdrop.displayName = 'Backdrop';
