import { MessagingActionPayload } from '@sigmail/app-state';
import React from 'react';
import { sendMessageAction } from '../../../../app-state/actions/messaging/send-message-action';
import { useAppState, useCurrentUser } from '../../../../app-state/hooks';
import { useTranslation } from '../../../../i18n';
import { I18N_NS_MESSAGING } from '../../../../i18n/config/namespace-identifiers';
import { FieldNameOptionsFieldset, FormValues } from '../../forms/compose-message-form.component';
import { DocumentListItem } from '../../hooks';
import { ActionData } from '../../types';
import { BaseContextValue, ComposeMessageViewState } from '../context';

export interface ActionDataSendMessage
  extends ActionData,
    Omit<FormValues, Exclude<FieldNameOptionsFieldset, 'sensitivity'>>,
    Partial<Pick<BaseContextValue, 'currentFolder'>>,
    Pick<ComposeMessageViewState, 'sourceMessage'> {
  documentList: ReadonlyArray<DocumentListItem>;
  flags: Pick<FormValues, Exclude<FieldNameOptionsFieldset, 'sensitivity'>>;
  messageKind?: MessagingActionPayload.SendMessage['messageKind'];
}

export const useSendMessageActionHandler = (currentUser: ReturnType<typeof useCurrentUser>) => {
  const translation = useTranslation([I18N_NS_MESSAGING]);
  const [, appDispatch] = useAppState();

  return React.useCallback(
    async ({
      currentFolder,
      documentList,
      failCallback,
      flags,
      messageBody,
      messageKind,
      oneTimeExpire,
      primary: primaryRecipientList,
      secondary: secondaryRecipientList,
      selectedProviderFolder,
      sendToEMR,
      sendToHRM,
      sensitivity,
      sourceMessage,
      subjectLine,
      successCallback
    }: ActionDataSendMessage) => {
      const { id, prefix, firstName, middleName, lastName, suffix } = currentUser!;

      let value: any;
      try {
        value = await appDispatch(
          sendMessageAction({
            documentList,
            flags,
            folderKey: currentFolder?.folderKey,
            parentFolderKey: currentFolder?.parentFolderKey,
            primaryRecipientList,
            messageBody: { data: messageBody },
            messageKind,
            oneTimeExpire,
            secondaryRecipientList,
            selectedProviderFolder,
            sendToEMR,
            sendToHRM,
            sender: { type: 'user', id, prefix, firstName, middleName, lastName, suffix },
            sensitivity,
            sourceMessage,
            subjectLine,
            translation
          })
        );
      } catch (error) {
        failCallback?.(error);
        return;
      }

      successCallback?.(value);
    },
    [appDispatch, currentUser, translation]
  );
};
