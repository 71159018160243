import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogConfirmSendToEMR } from '../../constants/action-context';

const i18n: MessagingI18n.Dialog.Confirmation.SendMessageToEMR = {
  // Translators: (HTML) Title for the Confirm Send Message To EMR dialog
  title: gettext('messaging', 'title', 'dialogConfirmSendToEMR', 'safe-html'),
  // Translators: (HTML) Confirmation message to be displayed in the body of \
  // Confirm Send Message To EMR dialog
  body: gettext('messaging', 'body', 'dialogConfirmSendToEMR', 'safe-html'),
  action: {
    reject: {
      // Translators: Label for the Cancel action of Confirm Send Message To \
      // EMR dialog
      label: gettext('messaging', 'reject', 'dialogConfirmSendToEMRActionLabel'),
      context: DialogConfirmSendToEMR
    },
    accept: {
      // Translators: Label for the Proceed action of Confirm Send Message To \
      // EMR dialog
      label: gettext('messaging', 'accept', 'dialogConfirmSendToEMRActionLabel'),
      context: DialogConfirmSendToEMR
    }
  }
};

export default i18n;
