import { Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccessRightRoleIdList } from '../../../app-state/selectors/auth';
import { UNRESOLVED_RESOURCE } from '../../../constants';
import { SendNonGuestAccountInvitation } from '../../../constants/action-ids';
import { CIRCLE_OF_CARE } from '../../../constants/medical-institute-user-group-type-identifier';
import { useContactListResource } from '../../../hooks';
import { Props as FormProps } from '../form/send-non-guest-account-invitation.component';
import { OnClickActionHandler } from '../types';
import { ActionDataSendNonGuestAccountInvitation } from './use-send-non-guest-account-invitation-action-handler';

export interface UseSendNonGuestAccountInvitationFormStateParams {
  actionClickHandler?: OnClickActionHandler | null | undefined;
}

export interface UseSendNonGuestAccountInvitationFormStateResult {
  formId: string | undefined;
  formLoading: boolean;
  formSubmitting: boolean;
  props: FormProps;
  setFormRef: React.RefCallback<HTMLFormElement> | undefined;
}

export const useSendNonGuestAccountInvitationFormState = (
  params?: UseSendNonGuestAccountInvitationFormStateParams | undefined
): UseSendNonGuestAccountInvitationFormStateResult => {
  const { actionClickHandler } = { ...params! };
  const noop = Utils.isNil(params);
  const [formId, setFormId] = React.useState<string>();
  const [formSubmitting, setFormSubmitting] = React.useState(false);

  const onFormStateChange = React.useMemo<FormProps['onFormStateChange']>(() => {
    return noop ? undefined : ({ formState: { submitting } }) => setFormSubmitting(submitting === true);
  }, [noop]);

  const onSubmit = React.useMemo<FormProps['onSubmit']>(() => {
    return noop
      ? undefined
      : ({ groups, role, specialty, ...values }) => {
          if (typeof actionClickHandler !== 'function') return;

          const actionData: ActionDataSendNonGuestAccountInvitation = {
            ...values,
            groupList: groups.map<ActionDataSendNonGuestAccountInvitation['groupList'][0]>((group) => ({
              id: group.newGroup !== true ? group.groupId : undefined,
              name: group.newGroup === true ? group.groupName : undefined!
            })),
            role: role as ActionDataSendNonGuestAccountInvitation['role'],
            specialty: specialty.slice(3), // get rid of MD_ or NP_ prefix,
            notifyBy: 'email'
          };

          return Promise.resolve(actionClickHandler(SendNonGuestAccountInvitation, actionData));
        };
  }, [actionClickHandler, noop]);

  const setFormRef = React.useMemo<React.RefCallback<HTMLFormElement> | undefined>(() => {
    return noop ? undefined : (form) => setFormId(form?.id);
  }, [noop]);

  const clientContactListResource = useContactListResource(
    noop
      ? undefined
      : {
          include: {
            clientContactList: { groups: true }
          }
        }
  );

  const memberOfGroupListResource = useContactListResource(
    noop
      ? undefined
      : {
          include: {
            membershipGroupContactList: { groupType: CIRCLE_OF_CARE }
          }
        }
  );

  const groupListResource = React.useMemo<FormProps['groupListResource']>(() => {
    try {
      const clientContactList = clientContactListResource.value();
      const memberOfGroupList = memberOfGroupListResource.value();

      return {
        value: () =>
          clientContactList.reduce((list, contact) => {
            if (contact.type === 'group' && contact.groupType === CIRCLE_OF_CARE) {
              const selectable = memberOfGroupList.some(({ id }) => id === contact.id);
              list.push({ groupId: contact.id, groupName: contact.groupData.groupName, selectable });
            }
            return list;
          }, [] as Array<ReturnType<FormProps['groupListResource']['value']>[0]>)
      };
    } catch (error) {
      if (error instanceof Promise) {
        return UNRESOLVED_RESOURCE;
      }
      throw error;
    }
  }, [clientContactListResource, memberOfGroupListResource]);

  const roleIdList = useSelector(selectAccessRightRoleIdList)('inviteMember');
  const roleList = React.useMemo<ReadonlyArray<string>>(
    () => roleIdList.filter((role) => !Utils.isGuestRole(role) && !Utils.isCaregiverRole(role)),
    [roleIdList]
  );

  return React.useMemo(() => {
    return {
      formId,
      formLoading: groupListResource === UNRESOLVED_RESOURCE,
      formSubmitting,
      props: {
        disabled: formSubmitting,
        groupListResource,
        onFormStateChange,
        onSubmit,
        roleList
      },
      setFormRef
    };
  }, [formId, formSubmitting, groupListResource, onFormStateChange, onSubmit, roleList, setFormRef]);
};
