import { WithT } from 'i18next';
import React from 'react';
import { DialogAccept, DialogReject } from '../../../../constants/action-ids';
import { CIRCLE_OF_CARE } from '../../../../constants/medical-institute-user-group-type-identifier';
import { useContactListResource } from '../../../../hooks';
import messagingI18n from '../../../../i18n/messaging';
import { resolveActionLabel } from '../../../../utils/resolve-action-label';
import { CircularProgress } from '../../../shared/circular-progress.component';
import { DialogActionsAcceptReject } from '../../../shared/dialog/actions/accept-reject.component';
import { DialogBoxProps } from '../../../shared/dialog/dialog-box.component';
import { AcceptReferralForm, Props as AcceptReferralFormProps } from '../../forms/accept-referral-form.component';
import { DeclineReferralForm, Props as DeclineReferralFormProps } from '../../forms/decline-referral-form.component';

const { referral: dialogI18n } = messagingI18n.dialog;

export interface UseReferralDialogPropsParams extends WithT {
  onClose?: DialogBoxProps['onClose'] | undefined;
  onDialogActionClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  onDeclineReferralFormSubmit?: DeclineReferralFormProps['onSubmit'] | undefined;
  onAcceptReferralFormSubmit?: AcceptReferralFormProps['onSubmit'] | undefined;
  open: boolean;
  openedDialog?: `referral${Capitalize<keyof typeof dialogI18n>}` | undefined;
}

export const useReferralDialogProps = ({
  onDialogActionClick: onClick,
  onDeclineReferralFormSubmit,
  onAcceptReferralFormSubmit,
  onClose,
  open,
  openedDialog,
  t
}: UseReferralDialogPropsParams) => {
  const [formId, setFormId] = React.useState<string>();

  const clientGroupContactListResource = useContactListResource({
    include: {
      membershipGroupContactList: openedDialog !== 'referralAccept' ? null : { groupType: CIRCLE_OF_CARE }
    }
  });

  const resetFormId = React.useCallback<React.RefCallback<HTMLFormElement>>((instance) => setFormId(instance?.id), []);

  return React.useMemo(() => {
    const result: DialogBoxProps = { open, onClose };
    if (open !== true) return result;

    switch (openedDialog) {
      case 'referralAccept':
      case 'referralDecline': {
        const isDeclineDialogOpen = openedDialog === 'referralDecline';
        const i18n = dialogI18n[isDeclineDialogOpen ? 'decline' : 'accept'];

        result.disableBackdropClick = true;
        result.TitleProps = { dangerouslySetInnerHTML: { __html: t(i18n.title) } };
        result.body = isDeclineDialogOpen ? (
          <React.Fragment>
            <div
              dangerouslySetInnerHTML={{ __html: t(i18n.body) }}
              style={{ fontSize: '90%', marginBottom: '1.5rem' }}
            />
            <DeclineReferralForm ref={resetFormId} onSubmit={onDeclineReferralFormSubmit} />
          </React.Fragment>
        ) : (
          <React.Suspense fallback={<CircularProgress />}>
            <div
              dangerouslySetInnerHTML={{ __html: t(i18n.body) }}
              style={{ fontSize: '90%', marginBottom: '1.5rem' }}
            />
            <AcceptReferralForm
              contactListResource={clientGroupContactListResource}
              ref={resetFormId}
              onSubmit={onAcceptReferralFormSubmit}
            />
          </React.Suspense>
        );
        result.actions = (
          <DialogActionsAcceptReject
            AcceptActionProps={{ children: t(resolveActionLabel(i18n.action.accept, DialogAccept)), form: formId }}
            RejectActionProps={{
              children: t(resolveActionLabel(i18n.action.reject, DialogReject)),
              form: formId,
              onClick
            }}
          />
        );
        break;
      }
      default: {
        if (process.env.REACT_APP_ENV === 'local') {
          throw new Error(`Unhandled case - <${openedDialog}>`);
        }
        break;
      }
    }

    return result;
  }, [
    clientGroupContactListResource,
    formId,
    onAcceptReferralFormSubmit,
    onClick,
    onClose,
    onDeclineReferralFormSubmit,
    open,
    openedDialog,
    resetFormId,
    t
  ]);
};
