import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogReplyDisabledBySelf } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeMessage['dialog']['replyDisabledBySelf'] = {
  // Translators: (HTML) Title for the Reply Disabled By Self dialog
  title: gettext('messaging', 'title', 'dialogReplyDisabledBySelf', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of Reply Disabled \
  // By Self dialog
  body: gettext('messaging', 'body', 'dialogReplyDisabledBySelf', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Proceed action of Reply Disabled By Self \
      // dialog
      label: gettext('messaging', 'accept', 'dialogReplyDisabledBySelfActionLabel'),
      context: DialogReplyDisabledBySelf
    }
  }
};

export default i18n;
