import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '..';
import { DialogReplyDisabledBySender } from '../../constants/action-context';

const i18n: MessagingI18n.View.ComposeMessage['dialog']['replyDisabledBySender'] = {
  // Translators: (HTML) Title for the Reply Disabled By Sender dialog
  title: gettext('messaging', 'title', 'dialogReplyDisabledBySender', 'safe-html'),
  // Translators: (HTML) Message to be displayed in the body of Reply Disabled \
  // By Sender dialog
  body: gettext('messaging', 'body', 'dialogReplyDisabledBySender', 'safe-html'),
  action: {
    accept: {
      // Translators: Label for the Proceed action of Reply Disabled By Sender \
      // dialog
      label: gettext('messaging', 'accept', 'dialogReplyDisabledBySenderActionLabel'),
      context: DialogReplyDisabledBySender
    }
  }
};

export default i18n;
