import { ReadonlyMessageBodyHealthDataRequest, Utils } from '@sigmail/common';
import { add as addDuration, endOfWeek, getDaysInMonth, startOfMonth, startOfWeek } from 'date-fns';
import { MAX_SAFE_TIMESTAMP, MIN_SAFE_TIMESTAMP } from '../constants';

export type RecurrenceFrequency = NonNullable<
  ReadonlyMessageBodyHealthDataRequest['messageForm']['value']['frequency']
>;

export interface RecurringDatesParams {
  readonly callback?: ((date: Date) => void) | undefined;
  readonly count?: number | undefined;
  readonly frequency?: RecurrenceFrequency | undefined;
  readonly interval?: number | undefined;
  readonly max?: Date | undefined;
  readonly min?: Date | undefined;
  readonly weekdays?: ReadonlyArray<number> | undefined;
}

export function recurringDates(start: Date, params?: RecurringDatesParams | undefined) {
  let dt = new Date(start);
  const dayOfMonth = dt.getDate();

  const callback = typeof params?.callback === 'function' && params.callback;
  const count = Math.min(
    Math.max(0, Utils.numberOrDefault(params?.count, Number.MAX_SAFE_INTEGER)),
    Number.MAX_SAFE_INTEGER
  );
  const frequency = Utils.stringOrDefault<RecurrenceFrequency>(params?.frequency);
  const interval = Math.max(1, Utils.numberOrDefault(params?.interval));
  const max = Utils.dateOrDefault(params?.max, new Date(MAX_SAFE_TIMESTAMP));
  const min = Utils.dateOrDefault(params?.min, new Date(MIN_SAFE_TIMESTAMP));

  if (!Utils.isValidDate(max)) throw new RangeError('Invalid value. (Parameter: max)');
  if (!Utils.isValidDate(min)) throw new RangeError('Invalid value. (Parameter: min)');
  if (min > max) throw new RangeError('Invalid value. (Parameter: min/max)');

  const repeatDaily = frequency === 'DAILY';
  const repeatMonthly = !repeatDaily && frequency === 'MONTHLY';
  const repeatWeekly = !repeatDaily && !repeatMonthly && frequency === 'WEEKLY';
  const repeatYearly = !repeatDaily && !repeatMonthly && !repeatWeekly && frequency === 'YEARLY';
  const repeatNever = !repeatDaily && !repeatMonthly && !repeatWeekly && !repeatYearly;
  const weekdays = Utils.arrayOrDefault<number>(repeatWeekly && params?.weekdays).filter((day) => day >= 0 && day <= 6);

  const list: Array<Date> = [];
  let { length: N } = list;
  do {
    if (N > count) break;

    const daysToAdd: Array<Date> = [];

    if (repeatDaily || repeatNever) {
      daysToAdd.push(new Date(dt));
    } else if (repeatMonthly || repeatYearly) {
      let day = dayOfMonth;
      if (day > 28) {
        const maxDay = getDaysInMonth(dt);
        if (day > maxDay) day = maxDay;
      }
      dt.setDate(day);

      daysToAdd.push(new Date(dt));
    } else if (repeatWeekly) {
      if (weekdays.length === 0) {
        break;
      }

      const dtWeekStart = startOfWeek(new Date(dt));
      const dtWeekEnd = endOfWeek(dtWeekStart);
      const daysOfWeek = recurringDates(dtWeekStart, { frequency: 'DAILY', max: dtWeekEnd });
      daysToAdd.push(...daysOfWeek.filter((d) => weekdays.includes(d.getDay())));
    }

    for (const entry of daysToAdd) {
      if (N > count) break;
      if (entry < min || entry > max) continue;

      if (callback === false) {
        N = list.push(entry);
      } else {
        callback(entry);
        ++N;
      }
    }

    if (repeatDaily || repeatWeekly) {
      dt = addDuration(dt, {
        days: Number(repeatDaily) * interval,
        weeks: Number(repeatWeekly) * interval
      });
    } else if (repeatMonthly || repeatYearly) {
      dt = addDuration(startOfMonth(dt), {
        months: Number(repeatMonthly) * interval,
        years: Number(repeatYearly) * interval
      });
    } else {
      break;
    }
  } while (dt <= max);

  return list;
}
