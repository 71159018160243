import { version as uuidVersion } from 'uuid';

export const isValidSessionId = (value?: string): value is string => {
  try {
    return uuidVersion(value!) === 4;
  } catch {
    /* ignore */
  }
  return false;
};
