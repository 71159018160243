import { Utils } from '@sigmail/common';
import React from 'react';
import { useSelector } from 'react-redux';
import { Action } from 'sigmail';
import { EMPTY_ARRAY } from '../../app-state/constants';
import { isUserLoggedInSelector } from '../../app-state/selectors/auth';
import * as ActionId from '../../constants/action-ids';
import { useTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { NavAction, NavActionList } from '../shared/nav-action-list.component';
import style from './app-footer.module.css';

type HtmlFooterElementProps = Omit<React.ComponentPropsWithoutRef<'footer'>, 'children'>;

export interface Props extends HtmlFooterElementProps {
  children?: never;
}

export const AppFooter = React.forwardRef<HTMLElement, Props>(({ children, ...rootProps }, ref) => {
  const { t } = useTranslation([I18N_NS_GLOBAL]);
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);

  const onClickNavLink = React.useMemo<NavAction['onClick']>(
    () =>
      isUserLoggedIn
        ? undefined
        : (event) => {
            const actionId = event.currentTarget.getAttribute('data-action-id');
            switch (actionId) {
              case ActionId.FAQ:
              case ActionId.PrivacyPolicy:
              case ActionId.TermsAndConditions: {
                event.preventDefault();
                event.stopPropagation();

                const { href } = globalI18n.action[actionId] as Action;
                if (Utils.isString(href)) {
                  window.location.href = href;
                }

                break;
              }
              default: {
                break;
              }
            }
          },
    [isUserLoggedIn]
  );

  const footerNavActionList = React.useMemo<ReadonlyArray<NavAction>>(
    () =>
      isUserLoggedIn
        ? EMPTY_ARRAY
        : [ActionId.FAQ, ActionId.TermsAndConditions, ActionId.PrivacyPolicy].map<NavAction>((actionId) => ({
            ...globalI18n.action[actionId],
            actionId,
            exact: true,
            onClick: onClickNavLink
          })),
    [isUserLoggedIn, onClickNavLink]
  );

  return footerNavActionList.length === 0 ? null : (
    <footer ref={ref} {...rootProps}>
      <div className="container-fluid">
        <NavActionList actionList={footerNavActionList} classes={{ anchor: style.anchor }} styleName="style.ul" />
        <div dangerouslySetInnerHTML={{ __html: t(globalI18n.copyrightNotice) }} styleName="style.copyright" />
      </div>
    </footer>
  );
});

AppFooter.displayName = 'AppFooter';
AppFooter.defaultProps = { className: style.root };
