import { CaseReducerMfaEnableSuccess } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';

const Logger = getLoggerWithPrefix('Reducer', 'mfaEnableSuccessReducer:');

export const mfaEnableSuccessReducer: CaseReducerMfaEnableSuccess = (state, action) => {
  try {
    Logger.info('== BEGIN ==');

    const { otpClaim } = action.payload;
    if (!Utils.isValidJwtToken(otpClaim, 'id')) {
      Logger.warn('Invalid payload.');
      return state;
    }

    return { ...state, otpClaim };
  } finally {
    Logger.info('== END ==');
  }
};
