import { Checkbox, CheckboxProps, FormControlLabel, MenuItem, SelectProps as MuiSelectProps } from '@material-ui/core';
import { Utils } from '@sigmail/common';
import { FieldState } from 'final-form';
import React from 'react';
import { MutableRef } from 'sigmail';
import { FieldProps } from '.';
import { useTranslation } from '../../../i18n';
import { Field } from './field.component';
import { isNonEmptyArray, isNotNil, isString } from './utils';

export type SelectFieldValue<T, Multiple extends boolean | undefined> = Multiple extends undefined | false
  ? T
  : Array<T> | ReadonlyArray<T>;

export type SelectFieldCheckbox<Multiple extends boolean | undefined> = Multiple extends undefined | false
  ? false
  : boolean;

export type SelectFieldChangeEventHandler<T, Multiple extends boolean | undefined> = (
  event: React.ChangeEvent<{}>,
  value: SelectFieldValue<T, Multiple>
) => void;

export interface SortCompareFunction<T> {
  (option1: T, option2: T): number;
}

export interface SelectFieldProps<T, Multiple extends boolean | undefined>
  extends Omit<
    MuiSelectProps,
    'defaultValue' | 'innerRef' | 'multiple' | 'onChange' | 'onKeyDown' | 'onKeyUp' | 'ref' | 'renderValue' | 'value'
  > {
  checkbox?: SelectFieldCheckbox<Multiple> | undefined;
  CheckboxProps?: CheckboxProps | undefined;
  // defaultValue?: SelectFieldValue<T, Multiple> | undefined;
  getOptionKey?: ((option: T) => React.Key) | undefined;
  getOptionLabel?: ((option: T) => React.ReactNode) | undefined;
  inputState: FieldState<SelectFieldValue<T, Multiple>>;
  isOptionDisabled?: ((option: T) => boolean) | undefined;
  multiple?: Multiple;
  onChange?: SelectFieldChangeEventHandler<T, Multiple> | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement> | undefined;
  onKeyUp?: React.KeyboardEventHandler<HTMLElement> | undefined;
  options?: Array<T> | ReadonlyArray<T> | undefined;
  renderValue?: ((value: SelectFieldValue<T, Multiple>) => React.ReactNode) | undefined;
  sort?: boolean | SortCompareFunction<T> | undefined;
  // value?: SelectFieldValue<T, Multiple> | undefined;
}

const MUI_SELECT_PROP_LIST: ReadonlyArray<
  Exclude<
    keyof MuiSelectProps,
    | keyof FieldProps
    | 'MenuProps'
    | 'SelectDisplayProps'
    | 'defaultValue'
    | 'multiple'
    | 'native'
    | 'readOnly'
    | 'ref'
    | 'renderValue'
    | 'value'
  >
> = [
  'IconComponent',
  'autoWidth',
  'disableUnderline',
  'displayEmpty',
  'endAdornment',
  'input',
  'inputComponent',
  'labelId',
  'labelWidth',
  'onClose',
  'onOpen',
  'open',
  'renderSuffix',
  'rowsMin',
  'startAdornment'
];

const SelectFieldComponent = <T, Multiple extends boolean | undefined>(
  props: SelectFieldProps<T, Multiple>,
  ref: MutableRef<HTMLDivElement>
) => {
  // prettier-ignore
  const { t, i18n: { language: locale } } = useTranslation();

  const {
    checkbox,
    CheckboxProps,
    getOptionKey,
    getOptionLabel,
    isOptionDisabled,
    MenuProps,
    multiple,
    native,
    onChange,
    options,
    renderValue,
    sort,
    SelectDisplayProps,
    ...fieldProps
  } = Utils.omit(props, MUI_SELECT_PROP_LIST);

  const optionLabelGetter = React.useCallback(
    (option: SelectFieldValue<T, false>): React.ReactNode => {
      const label = getOptionLabel?.(option);
      return Utils.isString(label) ? t(label) : label;
    },
    [getOptionLabel, t]
  );

  const sortCompareFn = React.useCallback(
    (option1: T, option2: T) => {
      const label1 = optionLabelGetter(option1);
      const label2 = optionLabelGetter(option2);
      return isString(label1) && isString(label2) ? label1.localeCompare(label2, locale) : 0;
    },
    [locale, optionLabelGetter]
  );

  const optionList = React.useMemo(() => {
    if ((sort === true || typeof sort === 'function') && isNonEmptyArray<T>(options)) {
      const copyOfOptions = options.slice();
      return copyOfOptions.sort(sort === true ? sortCompareFn : sort);
    }
    return options;
  }, [options, sort, sortCompareFn]);

  const handleChange = React.useCallback<React.ChangeEventHandler<any>>(
    (event) => {
      if (props.disabled === true || props.readOnly === true) {
        event.preventDefault();
        return;
      }

      if (typeof onChange !== 'function') {
        return;
      } else if (multiple !== true || native !== true) {
        onChange(event, event.target.value);
        return;
      }

      const { options } = event.target;
      const values = Array.from<any>(options).reduce((list, { selected, value }) => {
        if (selected === true) {
          list.push(value);
        }
        return list;
      }, [] as any[]);

      return onChange(event, values);
    },
    [multiple, native, onChange, props.disabled, props.readOnly]
  );

  const valueRenderer = React.useMemo(() => {
    if (typeof renderValue !== 'function') {
      return multiple === true ? (selected: Array<T>) => selected.map(optionLabelGetter).join(', ') : optionLabelGetter;
    }
    return renderValue;
  }, [multiple, optionLabelGetter, renderValue]);

  const hasErrorMessage = isNotNil(fieldProps.inputState.error);
  const hasError = fieldProps.error === true || (fieldProps.error !== false && hasErrorMessage);

  const selectFieldProps: MuiSelectProps = {
    MenuProps: {
      getContentAnchorEl: null,
      ...MenuProps,
      anchorOrigin: { horizontal: 'right', vertical: 'bottom', ...MenuProps?.anchorOrigin },
      transformOrigin: { horizontal: 'right', vertical: 'top', ...MenuProps?.transformOrigin },
      style: { maxHeight: '320px', ...MenuProps?.style }
    },
    SelectDisplayProps: { 'aria-invalid': hasError, ...SelectDisplayProps },
    multiple,
    native,
    renderValue: valueRenderer as Required<MuiSelectProps>['renderValue'],
    ...Utils.pick(props, MUI_SELECT_PROP_LIST)
  };

  if ((props.disabled === true || props.readOnly === true) && !Utils.has(selectFieldProps, 'IconComponent')) {
    selectFieldProps.IconComponent = 'div';
  }

  const { value } = fieldProps.inputState;
  const isChecked =
    multiple === true && checkbox === true && isNonEmptyArray(value)
      ? (option: T) => value.indexOf(option) > -1
      : () => false;

  let { children } = fieldProps;
  if (!('children' in fieldProps) && isNonEmptyArray(optionList)) {
    children = optionList.map((option) => {
      let labelNode = optionLabelGetter(option);
      if (checkbox === true && multiple === true) {
        labelNode = (
          <FormControlLabel control={<Checkbox checked={isChecked(option)} {...CheckboxProps} />} label={labelNode} />
        );
      }

      const optionProps: any = { disabled: isOptionDisabled?.(option), key: getOptionKey?.(option), value: option };
      if (native !== true) optionProps.dense = true;
      return React.createElement(native === true ? 'option' : MenuItem, optionProps, labelNode);
    });
  }

  if (selectFieldProps.displayEmpty === true) {
    (fieldProps as any).InputLabelProps = {
      shrink: true,
      ...(fieldProps as any).InputLabelProps
    };
  }

  return (
    <Field
      ref={ref}
      {...fieldProps}
      children={children}
      SelectProps={selectFieldProps}
      select={true}
      onChange={handleChange}
    />
  );
};

export const SelectField = React.forwardRef(SelectFieldComponent) as <T, Multiple extends boolean | undefined>(
  props: SelectFieldProps<T, Multiple> & React.RefAttributes<HTMLDivElement>
) => React.ReactElement<SelectFieldProps<T, Multiple>> | null;

(SelectField as any).displayName = '';

(SelectField as any).defaultProps = {
  checkbox: false,
  multiple: false,
  native: false,
  getOptionKey: String,
  getOptionLabel: String
};
