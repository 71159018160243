import { SEVERITY_ERROR, SEVERITY_FATAL, SEVERITY_SUCCESS, SEVERITY_WARN } from '../constants/error/severity';

/**
 * @param severity - Severity. Must be either 0 (success), 1 (fatal), 2 (error),
 * or 3 (warning).
 * @param facility - Source of the error. Must be between 0 and 2047.
 * @param code - Error code. Must be between 0 and 65535.
 */
export function MAKE_ERROR_CODE(severity: number, facility: number, code: number): number {
  if (
    severity !== SEVERITY_SUCCESS &&
    severity !== SEVERITY_FATAL &&
    severity !== SEVERITY_ERROR &&
    severity !== SEVERITY_WARN
  ) {
    throw new Error(
      `Severity must be either ${SEVERITY_SUCCESS}, ${SEVERITY_FATAL}, ${SEVERITY_ERROR}, or ${SEVERITY_WARN}.`
    );
  }

  const f = facility & 0x000007ff;
  const c = code & 0x0000ffff;
  return (severity << 27) | (f << 16) | c;
}
