interface ResponseLike {
  /* eslint-disable no-unused-vars */
  clone: (this: ResponseLike) => ResponseLike;
  json: (this: ResponseLike) => Promise<unknown>;
  /* eslint-enable no-unused-vars */
}

export function tryGetResponseJson<T>(response: ResponseLike, defaultValue?: T): Promise<T | undefined> {
  // prettier-ignore
  try { return response.clone().json() as Promise<T>;}
  catch  { /* ignore */ }

  return Promise.resolve(defaultValue);
}
